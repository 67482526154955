import { styled } from "@mui/material/styles";
import { MenuItem, Select } from "@mui/material";
import { cvar } from "styles/colorVariables";

export const StyledSelect = styled(Select)(() => ({
  height: "32px",
  display: "inline-block",
  borderRadius: "4px",
  border: "1px solid var(--botticelli-blue)",
  background: "var(--white)",
  "& > div": {
    padding: 0,
    color: cvar("font-color-eastern-blue"),
    lineHeight: "17px",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Barlow",
    paddingLeft: "8px",
    height: "100%!important" /* Find better way instead of use !important */,
    display: "flex",
    alignItems: "center",
  },
  "& > svg": {
    borderLeft: "1px solid var(--dark-blue)",
  },
  "& > .MuiSelect-iconOpen": {
    borderLeft: "none",
    borderRight: "1px solid var(--dark-blue)",
  },
  "& > fieldset": {
    display: "none",
  },
}));

export const StyledSelectLabel = styled("label")(() => ({
  textTransform: "uppercase",
  textAlign: "left",
  position: "relative",
  color: "var(--nepal-blue)",
  fontSize: "10px",
  fontWeight: "700",
  paddingBottom: "8px",
}));

export const StyledSelectedDefaultValue = styled("div")(() => ({
  color: cvar("font-color-eastern-blue"),
  fontFamily: "Barlow",
  fontWeight: "500",
  lineHeight: "17px",
  textAlign: "left",
  fontSize: "14px",
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  color: cvar("font-color-eastern-blue"),
  fontFamily: "Barlow",
  fontWeight: "500",
  lineHeight: "30px",
  fontSize: "14px",
}));
