import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* toggleM1Advance(action) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/provider_leads/${id}/toggle_advance`);

  if (success && data) {
    yield put(actions.getProjectPayTerms({ projectId: id }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_TOGGLE_M1_ADVANCE,
    });
  }
  return null;
}

export default toggleM1Advance;
