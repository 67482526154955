import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const DesignCompleteIcon = ({ height = 18, width = 18, fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2043_13553)">
        <path
          d="M0.801301 3.36729C0.959855 3.99412 1.28509 4.56717 1.74215 5.0242L2.16062 5.43728L5.44295 2.15517L5.02452 1.74205C4.5671 1.28502 3.99437 0.95982 3.3675 0.801265L0.219078 0.00532785C0.158961 -0.00978934 0.0953279 0.00778879 0.0513825 0.0513825C0.00778879 0.0953279 -0.00978934 0.158961 0.00532785 0.219078L0.801301 3.36729Z"
          fill={fill}
        />
        <path d="M4.50926 7.75459L7.79131 4.47275L6.19437 2.896L2.91211 6.17804L4.50926 7.75459Z" fill={fill} />
        <path d="M10.2441 13.5264L13.5257 10.2448L15.4926 12.2118L12.2111 15.4934L10.2441 13.5264Z" fill={fill} />
        <path
          d="M16.2413 12.958L12.959 16.2401L14.0392 17.3203C14.4776 17.7584 15.0606 17.9999 15.6804 17.9999C16.3002 17.9999 16.8832 17.7584 17.3216 17.3203C18.2262 16.4154 18.2262 14.9431 17.3216 14.0382L16.2413 12.958Z"
          fill={fill}
        />
        <path
          d="M8.11948 9.66403C8.32546 9.45809 8.65937 9.45809 8.86528 9.66403L10.6818 11.4804L11.4812 10.6811L10.4443 9.64431C10.2383 9.4384 10.2383 9.10452 10.4443 8.89854C10.6503 8.69263 10.9842 8.69256 11.1901 8.89854L12.227 9.93533L13.0405 9.12189L11.224 7.30547C11.018 7.09956 11.018 6.76564 11.224 6.5597C11.4299 6.35379 11.7638 6.35372 11.9698 6.5597L13.7863 8.37615L14.5998 7.56271L13.5629 6.52591C13.3569 6.32 13.3569 5.98612 13.5629 5.78014C13.7689 5.57423 14.1028 5.57416 14.3087 5.78014L15.3456 6.81694L16.1591 6.00349L14.3426 4.18704C14.1366 3.98113 14.1366 3.64721 14.3426 3.44127C14.5486 3.23532 14.8825 3.23532 15.0884 3.44127L16.9049 5.25772L17.6509 4.51055C17.876 4.28548 18 3.98619 18 3.66789C18 3.34958 17.876 3.05037 17.6509 2.82526L15.1745 0.349066C14.9494 0.123961 14.6501 0 14.3318 0C14.0135 0 13.7142 0.123926 13.4891 0.349031L0.349031 13.4895C0.123961 13.7145 0 14.0138 0 14.332C0 14.6503 0.123961 14.9496 0.349031 15.1747L2.82551 17.6509C3.05058 17.876 3.34983 18 3.66817 18C3.98651 18 4.28576 17.876 4.51083 17.651L5.25804 16.9039L4.22118 15.8671C4.01523 15.6612 4.01523 15.3272 4.22118 15.1213C4.42712 14.9154 4.76104 14.9154 4.96698 15.1213L6.00384 16.1581L6.81732 15.3446L5.0008 13.5283C4.79486 13.3224 4.79486 12.9884 5.0008 12.7825C5.20675 12.5766 5.54066 12.5766 5.74661 12.7825L7.56313 14.5989L8.37664 13.7854L7.33975 12.7486C7.1338 12.5427 7.1338 12.2089 7.33975 12.0029C7.54573 11.797 7.87964 11.7969 8.08559 12.0029L9.12248 13.0397L9.93596 12.2262L8.11944 10.4098C7.9135 10.2039 7.9135 9.86998 8.11948 9.66403Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2043_13553">
          <rect width={width} height={height} fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DesignCompleteIcon;
