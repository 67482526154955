import React from "react";
import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";
import { Row, Col, Button, Progress, Tooltip } from "antd";
import { CheckIcon } from "@icons";
import ReactS3Uploader from "react-s3-uploader";
import ApiClient from "util/ApiClient";
import { bytesToSize, fileTypesToPlainText } from "lib/helpers";
import styles from "../FileUploader.module.less";

export default class FileUploaderSimple extends React.Component {
  // A simple s3 uploader button with optional progress bar. Use this in forms where we want just a single file URL associated with a form field. On file select, the button will automatically upload the file and execute callback props.onFinished. Provide the url as a prop to this component to allow upload state to toggle between Upload File and Replace File ui displays.

  constructor(props) {
    super(props);
    this.state = {
      // ready: false,
      status: "loading", // one of ['loading', 'ready', 'inProgress', 'finished', 'error']
      config: {},
      progress: null,
      tooltipOpen: false,
    };
    this.api = new ApiClient({ host: process.env.REACT_APP_POWUR_API_URL });
  }

  componentDidMount() {
    this.api.get("/uploader_config", { mode: this.props.apiMode }).then(
      (res) => {
        this.setState({
          // ready: true,
          status: "ready",
          config: res,
        });
      },
      (res) => {
        console.warn(res.error);
      },
    );

    const input = document.getElementById("upload-input-3RTV9");
    if (input) {
      input.addEventListener("mouseenter", this.handleInputMouseover);
      input.addEventListener("mouseleave", this.handleInputMouseleave);
    }
  }

  componentWillUnmount = () => {
    const input = document.getElementById("upload-input-3RTV9");
    if (input) {
      input.removeEventListener("mouseenter", this.handleInputMouseover);
      input.removeEventListener("mouseleave", this.handleInputMouseleave);
    }
  };

  // eslint-disable-next-line no-unused-vars
  onUploadError = (_message) => {
    this.props.setLoading(false);
    this.setState({ status: "error" });
  };

  onUploadFinish = ({ signedUrl, previewUrl }) => {
    this.props.setLoading(false);
    this.setState({ status: "finished" });
    const url = signedUrl.split("?")[0];
    this.props.onFinished(url, previewUrl);

    setTimeout(() => {
      this.setState({ status: "ready" });
    }, 2000);
  };

  // eslint-disable-next-line no-unused-vars
  onUploadProgress = (percent, _message, _file) => {
    this.setState({ progress: percent, status: "inProgress" });
  };

  onUploadStart = (file, next) => {
    this.props.setLoading(true);
    next(file);
  };

  getSignedUrl = () => (file, callback) => {
    if (this.props.apiMode) {
      this.api
        .get("/uploader_config", {
          mode: this.props.apiMode,
          filename: file.name,
        })
        .then(
          (res) => {
            callback({ signedUrl: res.signed_url, previewUrl: res.get_url });
          },
          (res) => {
            console.warn(res.error);
          },
        );
    }
  };

  buttonDisplay = () => {
    if (this.state.status === "loading") {
      return <LoadingOutlined size="small" />;
    }

    return (
      <Tooltip
        title={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>Accepted file types: {fileTypesToPlainText(this.state.config.accept)}</div>
            <div>Max file size: {bytesToSize(this.state.config.max)}</div>
          </div>
        }
        open={this.state.tooltipOpen}
      >
        {this.props.fileUrl ? this.props.newUploadMessage : this.props.uploadMessage}
      </Tooltip>
    );
  };

  // eslint-disable-next-line no-unused-vars
  handleInputMouseleave = (_event) => {
    const button = document.getElementById("upload-button-5UI1B");
    button.style.backgroundColor = "var(--dark-blue)";
    button.style.borderColor = "var(--dark-blue)";
    this.setState({ tooltipOpen: false });
  };

  // eslint-disable-next-line no-unused-vars
  handleInputMouseover = (_event) => {
    const button = document.getElementById("upload-button-5UI1B");
    button.style.backgroundColor = "#4BBCDE";
    button.style.borderColor = "#4BBCDE";
    this.setState({ tooltipOpen: true });
  };

  render() {
    return (
      <Row style={{ width: "100%" }} className={styles.container} {...this.props.containerProps}>
        <Col
          style={{
            position: "relative",
            minWidth: !this.props.previewMode && "140px",
          }}
          span={this.props.orientation === "horizontal" ? 6 : 24}
        >
          <Button
            style={{
              display: "inline-block",
              width: this.props.orientation === "inline" ? "auto" : "100%",
              minWidth: !this.props.previewMode && "142px",
              height: "32px",
              padding: this.props.orientation === "inline" ? "0px 15px" : 0,
              fontSize: "13px",
              fontWeight: "500",
              background: "var(--dark-blue)",
              borderRadius: "6px",
            }}
            ghost={this.props.orientation === "inline"}
            id="upload-button-5UI1B"
            type="primary"
            {...this.props.buttonProps}
          >
            {this.buttonDisplay()}
          </Button>
          <ReactS3Uploader
            style={{
              cursor: "pointer",
              position: "absolute",
              top: 0,
              right: 0,
              opacity: 0,
            }}
            id="upload-input-3RTV9"
            title=""
            getSignedUrl={this.getSignedUrl()}
            s3path={this.state.config.s3_path}
            accept={this.state.config.accept}
            uploadRequestHeaders={this.state.config.headers}
            preprocess={this.onUploadStart}
            onProgress={this.onUploadProgress}
            onFinish={this.onUploadFinish}
            onError={this.onUploadError}
          />
        </Col>
        <Col
          span={this.props.orientation === "horizontal" ? 18 : 24}
          style={{
            ...(this.props.orientation === "horizontal"
              ? { paddingLeft: "16px" }
              : {
                  marginTop: this.props.orientation === "inline" ? "2px" : "20px",
                  paddingRight: "10px",
                }),
          }}
        >
          {this.state.status === "inProgress" || this.state.status === "finished" ? (
            <div
              style={{
                position: "relative",
              }}
            >
              {this.state.status === "finished" && (
                <div
                  style={
                    this.props.orientation === "inline"
                      ? {
                          fontSize: "12px",
                        }
                      : {
                          position: "absolute",
                          left: 0,
                          top: "-12px",
                          fontSize: "12px",
                        }
                  }
                >
                  File upload complete
                </div>
              )}
              <Progress percent={this.state.progress} size="small" status="active" />
            </div>
          ) : null}
          {this.state.status === "error" && (
            <span
              style={{
                marginTop: "8px",
                color: "var(--guardsman-red)",
                fontSize: "10px",
              }}
              {...this.props.errorProps}
            >
              An error occurred uploading your file.
            </span>
          )}
          {this.props.fileUploadedMsg && this.state.status !== "inProgress" && (
            <div style={{ minWidth: "20%" }} className={styles.successMsgUpload}>
              <CheckIcon backgroundFill="var(--harlequin-green)" fill="var(--white)" />
              <span>File uploaded</span>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

FileUploaderSimple.propTypes = {
  apiMode: PropTypes.string.isRequired,
  setLoading: PropTypes.func,
  onFinished: PropTypes.func.isRequired,
  fileUrl: PropTypes.string,
  previewMode: PropTypes.bool,
  fileUploadedMsg: PropTypes.bool,
  showProgressBar: PropTypes.bool,
  // pass thru props to child elements
  containerProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  buttonProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  errorProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  orientation: PropTypes.oneOf(["horizontal", "vertical", "inline"]),
  newUploadMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  uploadMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // eslint-disable-line react/forbid-prop-types
    PropTypes.node,
  ]),
};

FileUploaderSimple.defaultProps = {
  setLoading: () => null,
  fileUrl: undefined,
  showProgressBar: true,
  fileUploadedMsg: false,
  orientation: "horizontal",
  newUploadMessage: "Upload New File",
  uploadMessage: "Select File",
};
