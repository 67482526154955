import React from "react";
import { PrimaryButton } from "components/mui-styled-components";
import { styled, useTheme } from "@mui/material/styles";
import PlusOutlined from "components/Icons/PlusOutlined";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "rdx";
import LeadToProposalDrawer from "containers/Sales/projects/components/LeadToProposalDrawer";
import useNavigation from "hooks/useNavigation";
import { useHistory } from "react-router-dom";

const AddLeadButton = () => {
  const theme = useTheme();
  const isL2pDrawerOpen = useSelector(selectors.selectLeadToProposalDrawerVisible);
  const history = useHistory();
  const dispatch = useDispatch();
  const { location } = history;
  const currentPath = location.pathname.substring(1);
  const { navigate, override } = useNavigation(currentPath, []);

  const handleAddLeadClick = () => {
    dispatch(actions.setLeadToProposalDrawerVisible(true));
  };

  return (
    <>
      <PrimaryButton
        onClick={handleAddLeadClick}
        sx={{
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: theme.colors["banner-blue-highlighted"],
          },
        }}
      >
        <StyledContainer>
          <PlusOutlined
            width="18"
            height="18"
            fill={`linear-gradient(270.54deg, ${theme.colors["picton-blue"]} 49.88%, ${theme.colors["blue-ribbon"]} 135.73%)`}
            innerFill={theme.colors.white}
          />
          Add Lead
        </StyledContainer>
      </PrimaryButton>
      {isL2pDrawerOpen && <LeadToProposalDrawer key="lead-to-proposal" navigate={navigate} override={override} />}
    </>
  );
};

const StyledContainer = styled("span")({
  display: "flex",
  textAlign: "center",
  alignItems: "center",
});

export default AddLeadButton;
