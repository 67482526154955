import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/platform/messages";

function* toggleReminderNotificationViewedRequest(action) {
  const { id, viewed } = action.payload;

  const { success, data, error } = yield* makeRequest.patch(`/reminder_notifications/${id}`, {
    reminder_notification: { viewed },
  });

  if (success && data) {
    yield all([put(actions.toggleReminderNotificationViewed({ id }))]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_TOGGLING_NOTIFICATION_VIEWED,
    });
  }

  return null;
}

export default toggleReminderNotificationViewedRequest;
