import React from "react";
import PropTypes from "prop-types";

const ChatWindowIcon = (props) => {
  const { height, width, fill } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 35 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.2795 0.5H3.72054C2.7338 0.5 1.78747 0.877903 1.08973 1.55058C0.392 2.22325 1.70564e-05 3.13559 1.70564e-05 4.08689V26.9125C-0.00120443 27.1164 0.0632087 27.3155 0.184371 27.4826C0.305533 27.6496 0.477474 27.7763 0.676476 27.8451C0.78334 27.8789 0.895532 27.8944 1.00794 27.8908C1.16119 27.8933 1.31297 27.8617 1.45143 27.7984C1.5899 27.735 1.7113 27.6416 1.80616 27.5256L6.54137 21.7018H31.2795C32.2662 21.7018 33.2125 21.3239 33.9103 20.6512C34.608 19.9785 35 19.0662 35 18.1149V4.09993C35.0018 3.6278 34.9068 3.15997 34.7207 2.72328C34.5345 2.2866 34.2607 1.88964 33.9151 1.55518C33.5694 1.22073 33.1586 0.955351 32.7064 0.774278C32.2541 0.593205 31.7692 0.499997 31.2795 0.5ZM27.8972 17.0258H7.07577C6.80666 17.0258 6.54857 16.9227 6.35828 16.7393C6.16799 16.5558 6.06108 16.307 6.06108 16.0475C6.06108 15.7881 6.16799 15.5393 6.35828 15.3558C6.54857 15.1724 6.80666 15.0693 7.07577 15.0693H27.9242C28.1933 15.0693 28.4514 15.1724 28.6417 15.3558C28.832 15.5393 28.9389 15.7881 28.9389 16.0475C28.9389 16.307 28.832 16.5558 28.6417 16.7393C28.4514 16.9227 28.1933 17.0258 27.9242 17.0258H27.8972ZM27.8972 12.0693H7.07577C6.80666 12.0693 6.54857 11.9663 6.35828 11.7828C6.16799 11.5994 6.06108 11.3506 6.06108 11.0911C6.06108 10.8317 6.16799 10.5828 6.35828 10.3994C6.54857 10.2159 6.80666 10.1129 7.07577 10.1129H27.9242C28.1933 10.1129 28.4514 10.2159 28.6417 10.3994C28.832 10.5828 28.9389 10.8317 28.9389 11.0911C28.9389 11.3506 28.832 11.5994 28.6417 11.7828C28.4514 11.9663 28.1933 12.0693 27.9242 12.0693H27.8972ZM27.8972 7.1064H15.1933C14.9242 7.1064 14.6661 7.00333 14.4758 6.81988C14.2855 6.63642 14.1786 6.3876 14.1786 6.12816C14.1786 5.86871 14.2855 5.61989 14.4758 5.43643C14.6661 5.25298 14.9242 5.14991 15.1933 5.14991H27.9242C28.1933 5.14991 28.4514 5.25298 28.6417 5.43643C28.832 5.61989 28.9389 5.86871 28.9389 6.12816C28.9389 6.3876 28.832 6.63642 28.6417 6.81988C28.4514 7.00333 28.1933 7.1064 27.9242 7.1064H27.8972Z"
        fill={fill}
      />
    </svg>
  );
};

ChatWindowIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ChatWindowIcon.defaultProps = {
  width: "35",
  height: "28",
  fill: "var(--darker-blue)",
};

export default ChatWindowIcon;
