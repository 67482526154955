import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const Communications = (props) => {
  const { height, width, containerStyles, active, hovered, fill } = props;

  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      {...containerStyles}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 17 25"
        fill="none"
        style={{ transform: "scale(1.5, 1.25)", marginTop: "-4px" }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.14385 0.773438C3.103 0.773438 2.26871 1.65827 2.26871 2.72029V8.53012C2.26871 10.0273 3.43712 11.262 4.89304 11.262C6.34896 11.262 7.51446 10.0273 7.51446 8.53012V3.39594C7.51446 2.89637 6.76527 2.89637 6.76527 3.39594V8.53012C6.76527 9.64374 5.92741 10.5121 4.89304 10.5121C3.85867 10.5121 3.01788 9.64374 3.01788 8.53012V2.72029C3.01788 2.0418 3.52453 1.52262 4.14385 1.52262C4.76319 1.52262 5.26617 2.0418 5.26617 2.72029V8.60474C5.26617 8.84811 5.09732 9.01445 4.89304 9.01445C4.68877 9.01445 4.51698 8.84811 4.51698 8.60474V4.14513C4.51698 3.64555 3.76781 3.64555 3.76781 4.14513V8.60474C3.76781 9.23164 4.26722 9.76363 4.89304 9.76363C5.51886 9.76363 6.01536 9.23164 6.01536 8.60474V2.72029C6.01536 1.65827 5.18472 0.773438 4.14385 0.773438ZM1.51952 3.7709C0.904763 3.77246 0.396484 4.27811 0.396484 4.89321V23.6221C0.396484 24.2381 0.906363 24.748 1.52245 24.748H9.38668V18.3792C9.38589 18.1712 9.55472 18.0024 9.76273 18.0032H16.1308V4.89321C16.1318 4.27635 15.6216 3.7709 15.0056 3.7709H8.26363V8.53012C8.26363 10.4161 6.78499 12.0112 4.89304 12.0112C3.01351 12.0112 1.51952 10.417 1.51952 8.53012V3.7709ZM10.1359 9.01372H13.8825C14.371 9.02462 14.371 9.75206 13.8825 9.7629H10.1359C9.64737 9.752 9.64737 9.02456 10.1359 9.01372ZM10.1359 10.8874H13.8825C14.371 10.8983 14.371 11.6258 13.8825 11.6366H10.1359C9.64737 11.6257 9.64737 10.8982 10.1359 10.8874ZM4.89011 12.7604H13.8825C14.371 12.7713 14.371 13.4987 13.8825 13.5096H4.89011C4.37945 13.5209 4.37945 12.749 4.89011 12.7604ZM4.89011 14.6326H13.8825C14.3932 14.6213 14.3932 15.3931 13.8825 15.3818H4.89011C4.37945 15.3931 4.37945 14.6213 4.89011 14.6326ZM4.89011 16.5063H13.8825C14.371 16.5172 14.371 17.2446 13.8825 17.2555H4.89011C4.40162 17.2446 4.40162 16.5171 4.89011 16.5063ZM10.1359 18.7524V24.5293L15.9128 18.7524H10.1359Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

Communications.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

Communications.defaultProps = {
  height: "17",
  width: "25",
  fill: "var(--royal-peacock-blue)",
};

export default Communications;
