import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "../types";
import getSellerDashboard from "./getSellerDashboard";
import getDashboardSlice from "./getDashboardSlice";
import getEarningsBreakdown from "./getEarningsBreakdown";
import getProgressOverTime from "./getProgressOverTime";
import getTeamActivity from "./getTeamActivity";
import getLeadOrigin from "./getLeadOrigin";
import getSalesLeaderboard from "./getSalesLeaderboard";
import getReferralsLeaderboard from "./getReferralsLeaderboard";
import getMentorsLeaderboard from "./getMentorsLeaderboard";
import getDashboardProjectActivity from "./getDashboardProjectActivity";
import getMoreDashboardProjectActivity from "./getMoreDashboardProjectActivity";
// IMPORT_PT (for script, do not remove)

function* watchSellerDashboardSagas() {
  yield trackRequests(takeEvery, types.GET_DASHBOARD_SLICE, getDashboardSlice);
  yield trackRequests(takeEvery, types.GET_LEAD_ORIGIN, getLeadOrigin);
  yield trackRequests(takeEvery, types.GET_EARNINGS_BREAKDOWN, getEarningsBreakdown);
  yield trackRequests(takeEvery, types.GET_PROGRESS_OVER_TIME, getProgressOverTime);
  yield trackRequests(takeEvery, types.GET_TEAM_ACTIVITY, getTeamActivity);
  yield trackRequests(takeEvery, types.GET_CONSULTANT_DASHBOARD, getSellerDashboard);
  yield trackRequests(takeEvery, types.GET_SALES_LEADERBOARD, getSalesLeaderboard);
  yield trackRequests(takeEvery, types.GET_REFERRALS_LEADERBOARD, getReferralsLeaderboard);
  yield trackRequests(takeEvery, types.GET_MENTORS_LEADERBOARD, getMentorsLeaderboard);
  yield trackRequests(takeEvery, types.GET_DASHBOARD_PROJECT_ACTIVITY, getDashboardProjectActivity);
  yield trackRequests(takeEvery, types.GET_MORE_DASHBOARD_PROJECT_ACTIVITY, getMoreDashboardProjectActivity);
  // INSERTION_PT (for script, do not remove)
}

export default watchSellerDashboardSagas;
