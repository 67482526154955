import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/platform/messages";
import { camelizeKeys } from "lib/helpers";

function* getInboxNotifications() {
  const { success, data, error } = yield* makeRequest.get("/messages/unviewed_ticket_count_for_user");

  if (success && data) {
    const out = camelizeKeys(data.body);

    yield all([put(actions.setInboxNotifications(out.totalUnviewedByMeCount))]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_INBOX_NOTIFICATIONS,
    });
  }

  return null;
}

export default getInboxNotifications;
