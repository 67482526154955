import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
// import messages from "rdx/modules/enterpriseOrg/messages";

function* getEnterpriseOrgSeats() {
  const { success, data, error } = yield* makeRequest.get("/seats");

  if (success && data) {
    yield all([put(actions.setEnterpriseOrgSeats(data.body))]);
  } else if (error) {
    return getErrorActions({
      error,
      // message: "",
      // payload: {},
    });
  }

  return null;
}

export default getEnterpriseOrgSeats;
