import React from "react";

interface ClockIconSolidProps {
  detailFill?: string;
  backgroundFill?: string;
  height?: string | number;
  width?: string | number;
}

const ClockIconSolid = ({
  detailFill = "white",
  backgroundFill = "#F56700",
  height = "14",
  width = "13",
}: ClockIconSolidProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.78552" cy="6.99976" r="6.1333" fill={backgroundFill} />
      <rect x="5.85602" y="2.83496" width="1.64576" height="5.43873" rx="0.822881" fill={detailFill} />
      <rect
        x="10.8888"
        y="6.62817"
        width="1.64576"
        height="5.03278"
        rx="0.822881"
        transform="rotate(90 10.8888 6.62817)"
        fill={detailFill}
      />
    </svg>
  );
};

export default ClockIconSolid;
