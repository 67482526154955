import { styled } from "@mui/material/styles";

import { theme } from "styles/themes";

export const PowurPlayVideosContainer = styled("div")(() => ({
  marginTop: "24px",
}));

export const VideoLinkContainer = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "24px",
  width: "100%",
  backgroundColor: theme.colors.white,
  borderRadius: "0px 0px 12px 12px",
  padding: "22px",
}));

export const VideoDetail = styled("div")(() => ({
  width: "216px",
}));

export const VideoLabel = styled("div")(() => ({
  marginTop: "11px",
  color: theme.colors["blue-ribbon"],
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 600,
  cursor: "pointer",
}));
