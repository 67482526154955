import { put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/team/selectors";
import messages from "../messages";

function* updateTeamLead(action) {
  const { leadId } = action.payload;

  const { success, data, error } = yield* makeRequest.post("/team/archive", {
    lead_id: leadId,
  });
  if (success && data) {
    const team = yield select(selectors.getTeamLeads);
    const leadsList = team.leads.filter((t) => t.id !== leadId);

    yield put(actions.setTeamLeadsList({ ...team, leads: leadsList }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_ARCHIVING_TEAM_LEAD,
    });
  }
  return null;
}

export default updateTeamLead;
