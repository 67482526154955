import { FundingProposalListItemT, FundingProposalT } from "types/Project/fundingProposal";

const formatFundingProposal = (updatedProposal: FundingProposalT): FundingProposalListItemT => {
  return {
    id: updatedProposal.id,
    version: updatedProposal.version,
    assignedAt: updatedProposal.assignedAt,
    completedAt: updatedProposal.completedAt,
    canceledAt: updatedProposal.canceledAt,
    createdAt: updatedProposal.createdAt,
    escalatedAt: updatedProposal.escalatedAt,
    statusDisplay: updatedProposal.statusDisplay,
    isLatestVersion: true,
    ...(updatedProposal.assignedTeamMember ? { assignedTeamMember: updatedProposal.assignedTeamMember } : {}),
    ...(updatedProposal.escalator ? { escalator: updatedProposal.escalator } : {}),
    fundingProposalUrl: updatedProposal.fundingProposalUrl,
    project: updatedProposal.project,
    dueAt: updatedProposal.dueAt,
    fundingCompanySlug: updatedProposal.fundingCompanySlug,
    proposalNotes: updatedProposal.proposalNotes,
    adminNotes: updatedProposal.adminNotes,
  };
};

export default formatFundingProposal;
