import { put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import { keysToCamelCase } from "lib/helpers";
import _ from "lodash";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/workflows/selectors";
import messages from "../../messages";

function* reorderDataGroups(action) {
  const { phaseId, milestoneId, order } = action.payload;
  const { success, data, error } = yield* makeRequest.post(
    `/phases/${phaseId}/milestones/${milestoneId}/data_groups/reorder`,
    {
      order,
    },
  );
  if (success && data) {
    const newMilestones = data.body.milestones.map((p) => keysToCamelCase(p));
    const workflow = yield select(selectors.getWorkflowDetails);
    const newWorkflow = _.clone(workflow);
    const newPhases = newWorkflow.phases.map((p) => (p.id === phaseId ? { ...p, milestones: newMilestones } : p));

    yield put(actions.setWorkflowDetails({ ...workflow, phases: newPhases }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_MILESTONE_RANKS,
    });
  }
  return null;
}

export default reorderDataGroups;
