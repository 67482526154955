import { styled } from "@mui/material/styles";

import { theme } from "styles/themes";

export const NevadaNoteContainer = styled("div")(() => ({
  marginTop: "24px",
}));

export const NoteHeader = styled("div")(() => ({
  color: theme.colors["blue-ribbon"],
  fontSize: "24px",
  fontWeight: 500,
  fontFamily: "Poppins",
  marginBottom: "8px",
}));

export const NoteDetails = styled("div")(() => ({
  color: theme.colors["dark-blue"],
  fontSize: "16px",
  fontWeight: 500,
  marginBottom: "32px",
}));
