import React from "react";
import { FormControlLabel, Switch, SwitchProps } from "@mui/material";
import { useController } from "react-hook-form";
import type { Control, UseControllerProps } from "react-hook-form";

import * as S from "./styles";

type TSwitcherController = {
  label: string;
  control: Control;
  handleChange?: (val: boolean) => void;
} & UseControllerProps &
  SwitchProps;

const SwitcherController = ({ label, handleChange, ...rest }: TSwitcherController) => {
  const {
    field: { name, value, onChange, ref },
  } = useController({ name: rest.name, control: rest.control, defaultValue: rest.defaultValue });
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            {...rest}
            name={name}
            value={value}
            ref={ref}
            onChange={(e) => {
              if (handleChange) handleChange(e.target.checked);
              onChange(e);
            }}
            sx={{
              "& > .MuiSwitch-track": {
                background: "var(--nepal-blue)",
                opacity: 100,
              },
              "& > .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                background: "var(--harlequin-green)",
                opacity: 100,
              },
              "& > .Mui-checked>.MuiSwitch-thumb": {
                background: "var(--white)",
              },
            }}
          />
        }
        label={<S.StyledLabel>{label}</S.StyledLabel>}
      />
    </>
  );
};

export default SwitcherController;
