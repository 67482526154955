import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../../messages";
import updateDataGroupsFromRes from "./util";

function* deleteMetaField(action) {
  const { id, dataGroupId } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/data_groups/${dataGroupId}/meta_fields/${id}`, {});
  if (success && data) {
    const groups = yield updateDataGroupsFromRes(data, dataGroupId);
    yield all([
      put(actions.setDataGroups(groups)),
      put(
        actions.newSuccessEvent({
          message: messages.META_FIELD_DELETE_SUCCESS,
          id,
          dataGroupId,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_META_FIELD,
    });
  }
  return null;
}

export default deleteMetaField;
