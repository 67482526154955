import { put, all } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getAdminNoticeDetails(action) {
  const { noticeId } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/notices/${noticeId}`, {});
  if (success && data) {
    const out = keysToCamelCase(_.clone(data.body));
    yield all([put(actions.setAdminNoticeDetails(out))]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ADMIN_NOTICE_DETAILS,
    });
  }
  return null;
}

export default getAdminNoticeDetails;
