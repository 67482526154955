import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* impersonateUser(action) {
  const { userId } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/users/${userId}/impersonate`, {});
  if (success && data) {
    yield put(
      actions.newSuccessEvent({
        message: messages.IMPERSONATE_TOKEN_RECEIVED_SUCCESS,
        payload: data,
      }),
    );
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_IMPERSONATING_USER,
    });
  }

  return null;
}

export default impersonateUser;
