import PropTypes from "prop-types";
import { store } from "rdx/configureStore";
import actions from "rdx/modules/projects/actions";
import inboxActions from "rdx/modules/tickets/actions";
import { keysToCamelCase } from "lib/helpers";
import Lead, { defaults as leadDefaults } from "models/Lead";
import { Base } from "./Base";
import {
  agentDefaults,
  agentSchema,
  closeReasonSchema,
  closeReasonsSchema,
  availableInstallersSchema,
  visionProposalSchema,
  mentorSchema,
  messageCountersSchema,
  powurOsDataSchema,
  providerSchema,
  teamSchema,
  utilitiesSchema,
  preferredInstallersSchema,
} from "./shared";

class ProviderLead extends Base {
  constructor(props = null) {
    if (!props || Object.keys(props).length === 0) super(providerLeadDefaults);
    else super(keysToCamelCase(props));
  }

  updateProviderLead = ({
    existingSystem,
    providerLeadUpdate,
    utilityBillUrl,
    notes,
    enterprise,
    siteInfo,
    proposalSettings = null,
  }) => {
    const { updateProviderLead } = actions;
    store.dispatch(
      updateProviderLead({
        providerLeadId: this.props.id,
        providerLead: providerLeadUpdate,
        utilityBillUrl,
        notes,
        siteInfo,
        enterprise,
        existingSystem,
        ...(proposalSettings !== null ? [proposalSettings] : []),
      }),
    );
  };

  requestProposal = (realTime) => {
    const { requestProposal } = actions;
    store.dispatch(requestProposal({ realTime, id: this.props.id }));
  };

  getProposals = ({ enterprise }) => {
    if (this.props.id) {
      const { getProjectProposals } = actions;
      store.dispatch(getProjectProposals({ id: this.extractProjectId(this.props.id), enterprise }));
    }
  };

  getNotes = (paging) => {
    const { getProjectMessageList } = inboxActions;
    store.dispatch(getProjectMessageList({ project_id: this.props.id, ...paging }));
  };

  getAssets = () => {
    const { getAssets } = actions;
    store.dispatch(getAssets({ leadId: this.props.id }));
  };

  postNewAsset = (fileUrl) => {
    const { postNewAsset } = actions;
    store.dispatch(postNewAsset({ leadId: this.props.id, fileUrl }));
  };

  deleteAsset = (assetId) => {
    const { deleteAsset } = actions;
    store.dispatch(deleteAsset({ leadId: this.props.id, assetId }));
  };

  searchMentors = (query) => {
    const { getMentorsList } = actions;
    store.dispatch(getMentorsList({ ...query, projectId: this.props.id }));
  };

  selectMentor = (mentorId, mentorAlgorithmUsed = false) => {
    const { selectMentor } = actions;
    store.dispatch(
      selectMentor({
        id: this.props.id,
        mentorId,
        mentorAlgorithmUsed,
      }),
    );
  };

  removeMentor = () => {
    const { removeMentor } = actions;
    store.dispatch(removeMentor({ id: this.props.id }));
  };

  removeAmbassador = () => {
    const { removeAmbassador } = actions;
    store.dispatch(removeAmbassador({ id: this.props.id }));
  };

  updateProjectManagement(id, key) {
    const { updateProviderLeadUsers } = actions;

    store.dispatch(
      updateProviderLeadUsers({
        newUserId: id,
        userParamKey: key,
        projectId: this.props.id,
      }),
    );
  }

  close = (closeReasonId, closeNote) => {
    const { closeProject } = actions;
    store.dispatch(closeProject({ id: this.props.id, closeReasonId, closeNote }));
  };

  reopen = () => {
    const { reopenProject } = actions;
    store.dispatch(reopenProject({ id: this.props.id }));
  };

  updatePreSalesMilestones = (data) => {
    const { updatePreSalesMilestones } = actions;
    store.dispatch(
      updatePreSalesMilestones({
        id: this.props.id,
        data,
      }),
    );
  };

  updateLeadInfo = ({ lead, address, enterprise, siteInfo }) => {
    const { updateProjectLeadInfo } = actions;
    store.dispatch(
      updateProjectLeadInfo({
        leadId: this.props.lead.id,
        lead,
        address,
        enterprise,
        siteInfo,
      }),
    );
  };

  requestProposalUnlock = () => {
    const { requestProposalUnlock } = actions;
    store.dispatch(requestProposalUnlock({ id: this.props.id }));
  };

  sendHomeownerInvite = () => {
    const { inviteToMyPowur } = actions;
    store.dispatch(inviteToMyPowur({ id: this.props.id, inviteKind: "homeowner" }));
  };

  downloadAssets = () => {
    const { downloadAssets } = actions;
    store.dispatch(downloadAssets({ projectLeadId: this.props.id }));
  };

  getProjectId = () => {
    return this.extractProjectId(this.props.id);
  };

  extractProjectId = (projectId: number | string): number => {
    if (typeof projectId === "string" && /^\d+_\d+$/.test(projectId)) {
      return Number(projectId.split("_")[1]);
    }

    return Number(projectId);
  };

  static types() {
    return PropTypes.shape({
      agent: agentSchema,
      apiUri: PropTypes.string,
      availableInstallers: availableInstallersSchema,
      canRequestProposal: PropTypes.bool,
      closeAt: PropTypes.string,
      closeNote: PropTypes.string,
      closeReason: closeReasonSchema,
      closeReasons: closeReasonsSchema,
      createdAt: PropTypes.string,
      financing: PropTypes.string,
      hoaName: PropTypes.string,
      hoaPhone: PropTypes.string,
      id: PropTypes.number,
      installerId: PropTypes.number,
      internal: PropTypes.bool,
      kwhConsumption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      lastEvent: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      lastNote: PropTypes.string,
      // see Lead.types()
      lead: Lead.types(), // eslint-disable-line react/forbid-prop-types
      mentor: mentorSchema,
      messageCounters: messageCountersSchema,
      mounting: PropTypes.string,
      offerable: PropTypes.bool,
      powurOsData: powurOsDataSchema,
      preferredInstallers: preferredInstallersSchema,
      proposalRequestAt: PropTypes.string,
      proposalUrl: PropTypes.string,
      provider: providerSchema,
      qualifyComplete: PropTypes.bool,
      userCanRequestProposal: PropTypes.bool,
      proposalNonrequestableReason: PropTypes.string,
      mentorRequirementMet: PropTypes.bool,
      roofAge: PropTypes.string,
      roofType: PropTypes.string,
      servicePanel: PropTypes.string,
      submitAt: PropTypes.string,
      team: teamSchema,
      updatedAt: PropTypes.string,
      utilityCompany: PropTypes.string,
      utilities: utilitiesSchema,
      visionProposal: visionProposalSchema,
    });
  }

  static defaults() {
    return providerLeadDefaults;
  }

  static filterTypes() {
    const filterBody = () =>
      PropTypes.shape({
        name: PropTypes.string,
        values: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        label: PropTypes.string,
      });

    return PropTypes.shape({
      filters: PropTypes.shape({
        stage: filterBody(),
        consultants: filterBody(),
        mentors: filterBody(),
        ambassadors: filterBody(),
      }),
      sorts: PropTypes.arrayOf(PropTypes.string),
    });
  }
}

export const providerLeadDefaults = {
  agent: agentDefaults,
  apiUri: "",
  availableInstallers: [],
  canRequestProposal: null,
  createdAt: "",
  hoaName: "",
  hoaPhone: "",
  id: 0,
  internal: false,
  kwhConsumption: "",
  lastNote: null,
  lastEvent: {
    title: "",
  },
  lead: leadDefaults, // Lead.defaults(),
  mentor: null,
  mounting: "",
  powurOsData: {},
  proposalRequestAt: null,
  provider: {
    id: 0,
    name: "",
  },
  qualifyComplete: null,
  userCanRequestProposal: false,
  proposalNonrequestableReason: "",
  mentorRequirementMet: false,
  roofAge: "",
  roofType: "",
  servicePanel: "",
  roofCheck: false,
  submitAt: "",
  updatedAt: "",
  utilityCompany: "",
  preferredInstallers: [],
  visionProposal: {},
  visionProposalNonrequestableReason: "",
  soloProposalNonrequestableReason: "",
  lightreachProposalNonrequestableReason: "",
  homeowner: {
    address: {
      state: "",
    },
  },
};

export default ProviderLead;
