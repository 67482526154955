import React from "react";
import PropTypes from "prop-types";
import SidebarFilters from "components/PlatformLayout/components/Sidebar/Filters";
import SidebarAutoComplete from "components/PlatformLayout/components/Sidebar/AutoComplete";
import actions from "rdx/modules/team/actions";
import { useSelector, useDispatch } from "react-redux";
import FEATURE_FLAGS from "@flags";

const AmbassadorFilters = (props) => {
  const {
    // filters,
    navigate,
    override,
    // suggestions,
    // setSuggestions,
    // resetSuggestions,
  } = props;
  const menus = [
    // need to fix with back-end to just use location of filtered
    // {
    //   key: "location",
    //   label: "Location",
    //   options: [
    //     {
    //       key: "any-location",
    //       label: "All states/provinces",
    //       default: true,
    //       index: 3,
    //       parentKey: "location",
    //     },
    //   ],
    // },
    {
      key: "sort",
      label: "Sort Type",
      options: [
        {
          key: "newest",
          label: "Sort By Newest",
          default: true,
          index: 4,
          parentKey: "sort",
        },
        {
          key: "name",
          label: "Sort By Name",
          default: false,
          index: 4,
          parentKey: "sort",
        },
        {
          key: "referrals",
          label: "Sort By Referrals",
          default: false,
          index: 4,
          parentKey: "sort",
        },
      ],
    },
    ...(FEATURE_FLAGS.includes("teamActivityFilters")
      ? [
          {
            key: "team_activity",
            label: "Team Activity",
            options: [
              {
                key: "all_team_activity",
                label: "All Team Activity",
                default: true,
                index: 5,
                parentKey: "team_activity",
              },
              {
                key: "ambassadors_this_week",
                label: "New Ambassadors This Week",
                default: false,
                index: 5,
                parentKey: "team_activity",
              },
              {
                key: "ambassadors_last_week",
                label: "New Ambassadors Last Week",
                default: false,
                index: 5,
                parentKey: "team_activity",
              },
            ],
          },
        ]
      : []),
  ];

  // if (filters.states) {
  //   menus[0].options.push(
  //     ...filters.states.map((o) => {
  //       const key = Object.keys(o)[0];
  //       const label = Object.values(o)[0];
  //       return {
  //         key,
  //         label,
  //         default: false,
  //         index: 3,
  //         parentKey: "location",
  //       };
  //     }),
  //   );
  // }

  const suggestions = useSelector((state) => {
    const suggestionState = state.team.consultantsListAutoCompleteSuggestions;
    if (suggestionState) {
      const suggectionArray = [];
      suggestionState.consultant_id.map((x) => {
        suggectionArray.push({
          displayValue: x.fullName,
          key: x.id,
          data: x,
        });
        return "";
      });
      return suggectionArray;
    }
    return [];
  });

  const dispatch = useDispatch();

  return (
    <>
      <SidebarFilters menus={menus} navigate={navigate} override={override} />
      <SidebarAutoComplete
        fieldKey="consultant_id"
        label="Filter by Sponsor"
        dataKey="id"
        requestUrl="/users/project_consultants"
        formatData={null}
        successActions={[actions.setSellersAutocompleteSuggestions]}
        errorMessage="static error"
        debounceTimeout={undefined}
        minCharacters={2}
        suggestions={suggestions}
        navigate={navigate}
        resetSuggestions={() =>
          dispatch(
            actions.setSellersAutocompleteSuggestions({
              key: "consultant_id",
              suggestions: null,
            }),
          )
        }
        usersDisplay
      />{" "}
    </>
  );
};

AmbassadorFilters.propTypes = {
  navigate: PropTypes.func.isRequired,
  override: PropTypes.func.isRequired,
  // filters: User.filterTypes(),
};

export default AmbassadorFilters;
