import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";
import type { RootState as PreSalesProjectStateT } from "./stateTypes";

const initialState: PreSalesProjectStateT = {
  paging: {
    itemCount: 0,
    pageSize: 0,
    pageNumber: 0,
  },
  projects: [],
  selectedPresaleProject: undefined,
};

export default {
  projectPreSales: createReducer<PreSalesProjectStateT>(initialState, {
    [types.SET_PROJECTS_PRE_SALES](state, action) {
      return {
        ...state,
        paging: action.payload.paging,
        projects: action.payload.projects,
      };
    },
    [types.SET_SELECTED_PRE_SALES_PROJECT](state, action) {
      return {
        ...state,
        selectedPresaleProject: action.payload.selectedPresaleProject,
      };
    },
    [types.RESET_PROJECTS_PRE_SALES](state) {
      return {
        ...state,
        projects: initialState.projects,
      };
    },

    [types.UPDATE_PRE_SALES_PROJECT](state, action) {
      const projectDetails = action.payload;

      const projectIndexToUpdate = state.projects.findIndex(
        (project) => project.projectId === projectDetails.projectId,
      );

      if (projectIndexToUpdate === -1) {
        return state;
      }

      const projects = [...state.projects];
      projects[projectIndexToUpdate] = {
        ...projects[projectIndexToUpdate],
        ...projectDetails,
      };

      return {
        ...state,
        projects,
      };
    },

    [types.UPDATE_CRM_ACTIVITY_IN_PRE_SALES_LIST](state, action) {
      const crmActivity = action.payload;

      const projectIndexToUpdate = state.projects.findIndex((project) => project.leadId === crmActivity.leadId);

      if (projectIndexToUpdate === -1) {
        return state;
      }

      const projects = [...state.projects];
      projects[projectIndexToUpdate] = {
        ...projects[projectIndexToUpdate],
        activities: [...projects[projectIndexToUpdate].activities, crmActivity],
      };

      return {
        ...state,
        projects,
      };
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};
