// import content from "containers/Sales/marketing";
import tabs from "containers/Sales/marketing/tabs";
import { OnlineMarketingIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";
import SessionClient from "util/SessionClient";

const content = l(() => import("containers/Sales/marketing/enterpriseIndex"));

const { ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN, ENTERPRISE_LEAD_GENERATOR } = permissionTypes;

const session = new SessionClient();

export default {
  key: "org-marketing",
  title: `${session?.user?.props?.enterpriseName} Marketing`,
  path: "/org-marketing/:tab?",
  linkTarget: "/org-marketing",
  linkTitle: "Marketing",
  linkCategory: "Enterprise",
  icon: OnlineMarketingIcon,
  basePath: "org-marketing",
  requiredParams: ["tab"],
  optionalParams: [],
  tabs,
  permission: [ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN, ENTERPRISE_LEAD_GENERATOR],
  content,
  sidebar: null,
};
