export default {
  SET_CURRENT_USER: "[rbac] SET Current User",
  SET_USER_PERMISSIONS: "[rbac] SET User Permissions",
  SET_NOTICE_VIEWED_BY_USER: "[rbac] SET Notice Viewed By User",
  UPDATE_UNVIEWED_NOTICES: "[rbac] Update Unviewed Notices",
  UPDATE_INBOX_COUNT: "[rbac] Update Inbox Count",
  UPDATE_INBOX_UNVIEWED_MESSAGES_COUNT: "[rbac] Update Inbox Unviewed Messages Count",
  UPDATE_INBOX_UNVIEWED_ARCHIVED_COUNT: "[rbac] Update Inbox Unviewed Archived Count",
  UPDATE_MY_PROJECTS_COUNT: "[rbac] Update My Projects Count",
  CLEAR_RBAC: "[rbac] Clear Rbac",
  // INSERTION_PT (for script, do not remove)
};
