import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* removeAmbassador(action) {
  const { id } = action.payload;

  const { success, data, error } = yield* makeRequest.patch(`/provider_leads/${id}`, {
    ambassador_id: null,
  });
  if (success && data) {
    yield all([
      put(actions.setProjectDetails({ providerLead: keysToCamelCase(data.body) })),
      put(
        actions.newSuccessEvent({
          message: messages.REMOVE_AMBASSADOR_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_SELECTING_AMBASSADOR,
    });
  }
  return null;
}

export default removeAmbassador;
