import { put } from "redux-saga/effects";
import _ from "lodash";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";

import messages from "rdx/modules/projects/messages";

function* getRoofingProposals(action) {
  const { id, isAdmin = false } = action.payload;
  const urlPrefix = isAdmin ? "/projects" : "/provider_leads";

  const { success, data, error } = yield* makeRequest.get(`${urlPrefix}/${id}/roofing_proposals`, {});

  if (success && data) {
    const roofingProposals = data.body.roofing_proposals.map((roofingProposal) => keysToCamelCase(roofingProposal));
    const latestProposal = roofingProposals.find((proposal) => proposal.isLatestVersion);

    yield put(actions.setRoofingProposals(_.orderBy(roofingProposals, ["version"], "desc")));

    if (latestProposal) {
      yield put(actions.setLatestRoofingProposal(latestProposal));
    } else {
      yield put(actions.setLatestRoofingProposal({}));
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROPOSALS,
    });
  }
  return null;
}

export default getRoofingProposals;
