import styled from "@emotion/styled";
import { css } from "@emotion/react";
import FormLabel from "@mui/material/FormLabel";
import { TextareaAutosize } from "@mui/base";
import { cvar } from "styles";

export const formHeaderStyles = css`
  color: ${cvar("blue-ribbon")};
  font-family: "Poppins";
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  margin-left: -4px;
  margin-bottom: 4px;
`;

export const LabelGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledFormLabel = styled(FormLabel)`
  ${formHeaderStyles};
`;

export const StyledTextArea = styled(TextareaAutosize)(() => ({
  border: `1px solid ${cvar("botticelli-blue")}`,
  borderRadius: "4px",
  padding: "5px 12px",
  resize: "none",
  color: cvar("dark-blue"),
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "19px",
  "&::placeholder": {
    fontFamily: "Barlow",
    fontWeight: "500",
    lineHeight: "17px",
    color: cvar("graham-grey"),
  },
  "&:disabled": {
    background: cvar("cotton-boll"),
  },
}));

export const SubLabel = styled.span`
  font-family: "Barlow";
  font-size: 14px;
  line-height: 23px;
  color: ${cvar("dark-periwinkle")};
  margin-bottom: 4px;
`;
