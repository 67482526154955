export default {
  GET_AMBASSADOR_NEW_REGISTRATION_INFO_REQUEST: "[ambassador] GET Ambassador New Registration Info Request",
  SET_AMBASSADOR_NEW_REGISTRATION_INFO: "[ambassador] SET Ambassador New Registration Info",
  GET_AMBASSADOR_APPLICATION_AGREEMENT_REQUEST: "[ambassador] GET Ambassador Application Agreement",
  SET_AMBASSADOR_APPLICATION_AGREEMENT: "[ambassador] SET Ambassador Application Agreement",
  CREATE_AMBASSADOR_NEW_USER_REQUEST: "[ambassador] Create Ambassador New User Request",
  GET_AMBASSADOR_LEADS_REQUEST: "[ambassador] GET Ambassador Leads Request",
  SET_AMBASSADOR_LEADS: "[ambassador] SET Ambassador Leads",
  GET_AMBASSADOR_LEAD_ZIP_LOOKUP_REQUEST: "[ambassador] GET Ambassador Lead Zip Lookup Request",
  GET_STRIPE_SIGNUP_URL_REQUEST: "[ambassador] GET Stripe Signup Url Request",
  GET_STRIPE_LOGIN_URL_REQUEST: "[ambassador] GET Stripe Login Url Request",
  GET_STRIPE_ACCOUNT_INFO_REQUEST: "[ambassador] GET Stripe Account Info Request",
  SET_STRIPE_ACCOUNT_INFO: "[ambassador] SET Stripe Account Info",
  SET_AMBASSADOR_LEAD_ZIP_LOOKUP: "[ambassador] SET Ambassador Lead Zip Lookup",
  RESET_AMBASSADOR_LEAD_ZIP_LOOKUP: "[ambassador] Reset Ambassador Lead Zip Lookup",
  CREATE_AMBASSADOR_LEAD_REQUEST: "[ambassador] Create Ambassador Lead Request",
  // INSERTION_PT (for script, do not remove)
};
