import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "rdx/modules/leads/messages";
import { keysToSnakeCase } from "lib/helpers";
import { ConfirmSiteLocationPayloadT } from "rdx/modules/leads/stateTypes";

type ConfirmSiteLocationPropsT = {
  payload: ConfirmSiteLocationPayloadT;
};

function* confirmSiteLocation(action: ConfirmSiteLocationPropsT) {
  const { siteId, leadId } = action.payload;
  const params = keysToSnakeCase(action.payload);

  const { success, data, error } = yield* makeRequest.patch(`/sites/${siteId}/confirm_location`, params);

  if (success && data) {
    const existingLeadDetails = yield select(selectors.getLeadDetails);
    const l2pDrawerLeadId = yield select(selectors.selectLeadToProposalDrawerLeadId);
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.CONFIRM_SITE_LOCATION_SUCCESS,
        }),
      ),
      ...(existingLeadDetails?.id === leadId
        ? [
            put(actions.setAddressConfirmed()),
            put(actions.setProjectLeadDetails({ lead: { isAddressConfirmed: true } })),
          ]
        : []),
      ...(l2pDrawerLeadId === leadId ? [put(actions.setLeadToProposalDrawerAddressConfirmed())] : []),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CONFIRMING_SITE_LOCATION,
    });
  }

  return null;
}

export default confirmSiteLocation;
