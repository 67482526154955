import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import selectors from "rdx/modules/settings/selectors";
import actions from "rdx/actions";
import messages from "rdx/modules/settings/messages";

function* updateSettingRequest(action) {
  const { success, data, error } = yield* makeRequest.patch(`/system_settings/${action.payload.name}`, {
    value: action.payload.value,
    category: action.payload.category,
  });

  if (success && data) {
    const { setting } = data.body;
    const settings = yield select(selectors.getAdminSettings);
    const newSettings = settings.map((s) => {
      if (s.name === setting.name) {
        return setting;
      }
      return s;
    });
    yield all([
      put(actions.setAdminSettings(newSettings)),
      put(actions.newSuccessEvent({ message: messages.SETTING_UPDATE_SUCCESS })),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_SETTING,
    });
  }

  return null;
}

export default updateSettingRequest;
