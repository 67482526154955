import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import { useParams } from "react-router-dom";
import { formatDateTimeShort } from "lib/helpers";
// import useLoading from "hooks/useLoading";
// import types from "rdx/types";
import { parse } from "query-string";
import { Button, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import styles from "../EnterpriseSidebar.module.less";

const ContractsCSV = (props) => {
  const { startContractsReport, setContractsReport, getContractsReport, contractsReport, search } = props;

  const [pending, setPending] = useState(false);
  const [reportReady, setReportReady] = useState(false);
  const [reportName, setReportName] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  const { tab } = useParams();

  const getReport = (interval, reportId) => {
    setIntervalId(
      setInterval(() => {
        if (reportId) getContractsReport({ reportId });
      }, interval),
    );
  };

  useEffect(() => {
    if (contractsReport?.csv?.url) {
      setPending(false);
      setReportReady(true);
      clearInterval(intervalId);
    } else if (contractsReport?.report?.id && !intervalId) {
      getReport(2000, contractsReport.report.id);
    }
  }, [contractsReport]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reportReady) {
      window.open(contractsReport.csv?.url, "_blank");
      handleReset();
    }
  }, [reportReady]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReset = () => {
    setContractsReport({});
    setReportName(null);
    setReportReady(false);
  };

  const handleStartReport = () => {
    startContractsReport({
      name: reportName,
      ...parse(search),
      tab,
    });
    setPending(true);
  };

  const currentDateTime = () => {
    const now = new Date();
    return formatDateTimeShort(now);
  };

  return (
    <div className={styles.contractsCSV}>
      <div className={styles.label}>Download CSV</div>
      <div className={styles.subLabel}>With Current Filters</div>
      <>
        <Input
          onChange={(e) => setReportName(e.target.value)}
          value={reportName}
          className={styles.input}
          placeholder={`ContractsReport-${currentDateTime()}`}
          suffix={<CloseOutlined className={styles.inputIcon} onClick={(e) => handleReset(e)} />}
        />
        <Button
          className={styles.button}
          type="primary"
          loading={pending}
          // disabled={!reportName}
          onClick={handleStartReport}
        >
          {!pending ? "Generate CSV" : "Generating CSV"}
        </Button>
      </>
    </div>
  );
};

ContractsCSV.propTypes = {
  startContractsReport: PropTypes.func,
  setContractsReport: PropTypes.func,
  getContractsReport: PropTypes.func,
  contractsReport: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  search: PropTypes.string,
};

export default connect(
  getRdxSelectionMapper({
    contractsReport: "getContractsReport",
  }),
  getRdxActionMapper(["startContractsReport", "setContractsReport", "getContractsReport"]),
)(ContractsCSV);
