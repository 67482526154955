import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/preferredPartners/messages";
import { camelizeKeys } from "lib/helpers";

function* getPreferredPartners(action) {
  const { success, data, error } = yield* makeRequest.get("/enterprise_labor_partners", action.payload);
  if (success && data) {
    const list = camelizeKeys(data.body.preferred_partners);
    const paging = camelizeKeys(data.body.paging);
    const recommendedPartners = camelizeKeys(data.body.recommended_labor_partners);
    yield put(actions.setPreferredPartners({ list, paging }));
    yield put(actions.setRecommendedPartners({ recommendedPartners }));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PREFERRED_PARTNERS,
    });
  }

  return null;
}

export default getPreferredPartners;
