// import content from "containers/Enterprise/enterpriseProjects/EnterpriseApplications/EnterpriseApplicationDetails";
// import tabs from "containers/Enterprise/enterpriseProjects/EnterpriseApplications/EnterpriseApplicationDetails/tabs";
import breadcrumbs from "components/PlatformLayout/components/PlatformPageHeader/Breadcrumbs/EnterpriseApplicationBreadCrumbs";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";

const content = l(() => import("containers/Admin/EnterpriseApplications/EnterpriseApplicationsDetails"));

// const breadCrumbs = l(() =>
//   import(
//     "components/PlatformLayout/components/PlatformPageHeader/EnterpriseApplicationBreadCrumbs"
//   ),
// );

const { ENTERPRISES_SUPER_ADMIN } = permissionTypes;

export default {
  key: "enterprise-applications-details",
  title: breadcrumbs,
  path: "/admin-enterprise/enterprise-pro/:appId?",
  // exact: true,
  basePath: "admin-enterprise/enterprise-pro/:appId",
  requiredParams: ["appId"],
  optionalParams: [],
  linkTarget: null,
  linkTitle: null,
  linkCategory: null,
  permission: [ENTERPRISES_SUPER_ADMIN],
  icon: null,
  tabs: null,
  content,
  sidebar: null,
};
