export default {
  GET_INBOX_LIST_REQUEST: "[tickets] GET Inbox List Request",
  SET_INBOX_LIST: "[tickets] SET Inbox List",
  GET_SELECTED_MESSAGE_DETAILS_REQUEST: "[tickets] GET Message Details Request",
  SET_SELECTED_MESSAGE_DETAILS: "[tickets] SET Selected Message Details",
  CREATE_NEW_INBOX_MESSAGE_REQUEST: "[tickets] Create New Inbox Message Request",
  UPDATE_SELECTED_MESSAGE_REQUEST: "[tickets] Update Selected Message Request",
  SET_INBOX_FILTERS: "[tickets] SET Inbox Filters",
  SET_INBOX_AUTOCOMPLETE_SUGGESTIONS: "[tickets] SET Inbox Autocomplete Suggestions",
  DELETE_INBOX_MESSAGE_REQUEST: "[tickets] Delete Inbox Message Request",
  RESET_SELECTED_MESSAGE_DETAILS: "[tickets] Reset Selected Message Details",
  RESET_SELECTED_PROJECT_MESSAGES: "[tickets] Reset Selected Project Messages",
  GET_PROJECT_MESSAGE_LIST_REQUEST: "[tickets] GET Project Message List Request",
  SET_PROJECT_MESSAGE_LIST: "[tickets] SET Project Message List",
  GET_ZENDESK_FORM_DATA_REQUEST: "[tickets] GET Zendesk Form Data Request",
  SET_ZENDESK_FORM_DATA: "[tickets] SET Zendesk Form Data",
  CREATE_NEW_PROJECT_MESSAGE_REQUEST: "[tickets] Create New Project Message Request",
  RESOLVE_INBOX_ISSUE_REQUEST: "[tickets] Resolve Inbox Issue Request",
  REOPEN_INBOX_ISSUE_REQUEST: "[tickets] Reopen Inbox Issue Request",
  ASSIGN_INBOX_ISSUE_REQUEST: "[tickets] Assign Inbox Issue Request",
  SET_INBOX_TAGS: "[tickets] SET Inbox Tags",
  STAR_TICKET_REQUEST: "[tickets] Star Ticket Request",
  UNSTAR_TICKET_REQUEST: "[tickets] Unstar Ticket Request",
  DELETE_PROJECT_MESSAGE_REQUEST: "[tickets] Delete Project Message Request",
  GET_PROJECT_MESSAGE_REPLIES_PREVIEW_REQUEST: "[tickets] GET Project Message Replies Preview Request",
  SET_PROJECT_MESSAGE_REPLIES: "[tickets] SET Project Message Replies",
  GET_ALL_REPLIES_FOR_ISSUE_REQUEST: "[tickets] GET All Replies For Issue Request",
  POST_PROJECT_MESSAGE_REPLY_REQUEST: "[tickets] Post Project Message Reply Request",
  ARCHIVE_MESSAGES_REQUEST: "[tickets] Archive Messages Request",
  UNARCHIVE_MESSAGES_REQUEST: "[tickets] Unarchive Messages Request",
  MARK_MESSAGES_AS_VIEWED_REQUEST: "[tickets] Mark Messages As Viewed Request",
  MARK_MESSAGE_AND_REPLIES_AS_VIEWED_REQUEST: "[tickets] Mark Message And Replies As Viewed Request",
  MARK_MESSAGES_AS_UNVIEWED_REQUEST: "[tickets] Mark Messages As Unviewed Request",
  SET_OBSERVING_ACCOUNT: "[tickets] SET Observing Account",
  // INSERTION_PT (for script, do not remove)
};
