import { permissionTypes } from "hooks/usePermissions";
import { AmbassadorIcon } from "@icons";
import component from "containers/Ambassador/dashboard";
// import l from "@loadable/component";

// const component = l(() => import("containers/Ambassador/dashboard"));

const { POWUR_AMBASSADOR } = permissionTypes;

/* 
  This should probably be refactored, as this is really not a Platform Route, it should just generate an icon
  in our nav that on click executes a history.push("/ambassador/dashboard"), as dashboard is an already existing
  Ambassador Route
*/

export default {
  key: "ambassador-portal",
  title: "Ambassador",
  path: "/ambassador/dashboard/:tab?/:modal?",
  linkTarget: "/ambassador/dashboard",
  linkTitle: "Ambassador",
  linkCategory: "Sales",
  permission: POWUR_AMBASSADOR,
  component,
  icon: AmbassadorIcon,
};
