import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import actions from "rdx/actions";
import Registration from "models/Registration";
import NameAndNumberForm from "./NameAndNumberForm";
import DateOfBirthForm from "./DateOfBirthForm";
import styles from "./ContactInfo.module.less";

const ContactInfo = ({ regInfo, handleContinue, isAmbassador }) => {
  const [nameAndNumber, setNameAndNumber] = useState(!isAmbassador);
  const [formValues, setFormValues] = useState({});
  const dispatch = useDispatch();

  const continueToDob = (v) => {
    setFormValues(v);
    setNameAndNumber(false);
  };

  const continueToNextStep = (v) => {
    formValues.dob = `${v.year}-${v.month}-${v.day}`;
    formValues.phone = `${formValues.phonePrefix} ${formValues.phone}`;
    if (!isAmbassador) {
      dispatch(actions.updateRegistration({ ...formValues, slug: regInfo.slug }));
    }
    handleContinue({ dob: formValues.dob });
  };

  return (
    <div className={styles.contactInfoContainer}>
      {nameAndNumber ? (
        <NameAndNumberForm continueToNextStep={continueToDob} regInfo={regInfo} />
      ) : (
        <DateOfBirthForm continueToNextStep={continueToNextStep} regInfo={regInfo} />
      )}
    </div>
  );
};

ContactInfo.propTypes = {
  regInfo: Registration.types(),
  handleContinue: PropTypes.func,
  isAmbassador: PropTypes.bool,
};

export default ContactInfo;
