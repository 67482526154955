import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

import { theme } from "styles/themes";
import { mq } from "styles";

export const PowurPairTopPicksContainer = styled("div")(() => ({
  height: "100%",
  width: "100%",
}));

export const TopPicksDescription = styled("div")(() => ({
  fontSize: "14px",
  lineHeight: "17px",
  color: theme.colors["darkest-blue"],
  marginLeft: "16px",
  [mq.tabletUp]: {
    marginBottom: "20px",
  },
}));

export const ScrollableList = styled("div")(() => ({
  width: "100%",
  overflow: "auto",
  "&::-webkit-scrollbar": {
    backgroundColor: "transparent",
    height: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.colors["botticelli-blue"],
    borderRadius: "30px",
    width: "347px",
  },
}));

export const MatchContainer = styled(Grid)(() => ({
  margin: "24px 0px",
  marginLeft: "-8px",
  [mq.phoneOnly]: {
    flexDirection: "column",
    marginBottom: "0px",
  },
  [mq.tabletUp]: {
    flexDirection: "row",
    flexWrap: "nowrap",
  },
}));

export const NoMatchesContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  width: "100%",
  height: "225px",
  marginTop: "30px",
  padding: "30px",
  fontFamily: "Poppins",
  textAlign: "center",
  borderRadius: "4px",
  backgroundColor: theme.colors.white,
}));

export const GrayBoldText = styled("div")(() => ({
  textTransform: "uppercase",
  fontWeight: 700,
  fontSize: "16px",
  color: theme.colors["nepal-blue"],
}));

export const BlackBoldText = styled("div")(() => ({
  fontWeight: 700,
  fontSize: "16px",
  color: theme.colors.black,
}));

export const Text = styled("div")(() => ({
  fontSize: "14px",
  lineHeight: "17px",
  color: theme.colors["table-grey"],
  marginLeft: "16px",
  marginBottom: "20px",
  width: "57%",
}));
