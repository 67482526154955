import React from "react";

import Tab from "@mui/material/Tab";

import useWindowResize from "hooks/useWindowResize";

import { cvar } from "styles/colorVariables";
import * as S from "./styles";

type TabsT = {
  tabs: { content: React.ReactNode; label: string }[];
  currentTab: number;
  setCurrentTab: (tab: number) => void;
};

const Tabs = ({ tabs, currentTab, setCurrentTab }: TabsT) => {
  const { isMobileWidth } = useWindowResize();

  return (
    <S.StyledTabs
      sx={{
        ...(isMobileWidth && {
          "& .MuiTabs-scroller": {
            background: cvar("cotton-boll"),
            padding: "3px",
            borderRadius: "4px",
          },
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .MuiTabs-flexContainer": {
            height: "100%",
          },
          "& .MuiButtonBase-root": {
            minHeight: "100%",
            flexGrow: 1,
            margin: "0px",
          },
          "& .Mui-selected": {
            color: `${cvar("white")} !important`,
            background: cvar("blue-ribbon"),
            borderRadius: "4px",
          },
        }),
      }}
      value={currentTab}
      onChange={(event, tab) => setCurrentTab(tab)}
    >
      {tabs.map((tab, idx) => (
        <Tab key={tab.label} value={idx} label={tab.label} />
      ))}
    </S.StyledTabs>
  );
};

export default Tabs;
