import React from "react";
import { Box } from "@mui/material";
import { TrashcanIcon } from "components/Icons";
import { FilterSet } from "./styles";

type FilterSetT = {
  id: number;
  queryUrl: string;
  view: string;
  name: string;
};

type AppliedFiltersPropsT = {
  handleDelete: (id?: number) => void;
  handleClick: (path: string) => void;
  filterSets: FilterSetT[];
  view: string;
};

const FilterSets = ({ handleDelete, handleClick, view, filterSets }: AppliedFiltersPropsT) => {
  return (
    <>
      {filterSets.length > 0 &&
        filterSets
          .filter((filterSet) => filterSet?.view === view)
          .map((filterSet) => (
            <FilterSet
              deleteIcon={
                <Box justifyContent="center" display="flex">
                  <TrashcanIcon />
                </Box>
              }
              onDelete={() => {
                handleDelete(filterSet.id);
              }}
              onClick={() => handleClick(filterSet.queryUrl)}
              key={filterSet.id}
              label={filterSet.name}
            />
          ))}
    </>
  );
};

export default FilterSets;
