import { permissionTypes } from "hooks/usePermissions";

const { PLATFORM_CONFIGURATION } = permissionTypes;

const commonTabs = [
  {
    key: "list",
    label: "Users",
  },
];

const adminTabs = [
  {
    key: "teams",
    label: "Teams",
    permission: PLATFORM_CONFIGURATION,
  },
  {
    key: "roles",
    label: "Roles",
    permission: PLATFORM_CONFIGURATION,
  },
  {
    key: "permissions",
    label: "Permissions",
    permission: PLATFORM_CONFIGURATION,
  },
];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
