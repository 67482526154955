import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Popover, Row, Col, Avatar } from "antd";
import { UserOutlined, HomeOutlined } from "@ant-design/icons";
import CopyCallPhone from "components/CopyContactInfo/CopyCallPhone";
import CopyEmail from "components/CopyContactInfo/CopyEmail";
import { formatPhoneNumber } from "lib/helpers";

import styles from "./PersonPopover.module.less";

const PersonPopover = (props) => {
  const { title, person, children, disabled, projectId, projectRelation, ...rest } = props;

  const history = useHistory();
  const { location } = history || {};
  const { pathname } = location || {};

  // TODO: refactor to single hook ~NH
  const handleRedirectToTicketClick = (e) => {
    e.stopPropagation();

    if (!projectId) {
      throw new Error("PersonPopover Error, projectId is undefined");
    }

    const basePath = pathname.match(/\/.+?\/{1}/s)[0];
    const rectifiedPath = basePath.includes("project") ? basePath : "/projects/";
    const redirectTo = `${rectifiedPath}${projectId}/tickets`;

    history.push(redirectTo);
  };

  const redirectToTicket =
    title === "Project Manager" ||
    title === "Operations Manager" ||
    projectRelation === "Project Manager" ||
    projectRelation === "Operations Manager";

  if (!person) return null;

  const avatars = person.avatar || person.avatarUrls;
  const isHomeowner = title === "Homeowner";

  const contactTextProps = {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "17px",
  };

  const personNameDisplay = () => {
    if (person.firstName && person.lastName) {
      return `${person.firstName} ${person.lastName} (${person.id})`;
    }
    return person.fullName ? `${person.fullName} (${person.id})` : "";
  };

  const popoverContent = disabled ? null : (
    <Row className={styles.mainRow}>
      <Col
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "9px",
        }}
      >
        <Avatar
          size={42}
          shape="circle"
          className={styles.teamBadge}
          src={avatars?.thumb}
          icon={isHomeowner ? <HomeOutlined /> : <UserOutlined />}
        />
      </Col>
      <Col>
        <div className={styles.title}>{title.toUpperCase()}</div>
        <div className={styles.contentRow}>
          <span className={styles.nameText}>{personNameDisplay()}</span>
        </div>
        <div className={styles.contactRow}>
          {redirectToTicket ? (
            <span
              className={styles.ticket}
              onClick={handleRedirectToTicketClick}
              onKeyDown={handleRedirectToTicketClick}
              role="button"
              tabIndex={0}
            >
              Create Ticket
            </span>
          ) : (
            <>
              {person.phone && (
                <div className={styles.phoneContainer}>
                  <CopyCallPhone
                    iconProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    phoneProps={{
                      style: {
                        color: "white",
                        ...contactTextProps,
                      },
                    }}
                    tooltipTimeout={1000}
                    tooltipProps={{
                      placement: "bottom",
                      backgroundColor: "var(--dark-blue)",
                    }}
                    withHover
                    showIcon={false}
                    phone={formatPhoneNumber(person.phone)}
                  />
                </div>
              )}
              <span style={{ width: "10px" }} />
              {person.email && (
                <div className={styles.emailContainer}>
                  <CopyEmail
                    iconProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    emailProps={{
                      style: {
                        color: "white",
                        ...contactTextProps,
                      },
                    }}
                    tooltipTimeout={1000}
                    tooltipProps={{
                      placement: "bottom",
                      backgroundColor: "var(--dark-blue)",
                    }}
                    withHover
                    showIcon={false}
                    email={person.email}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Col>
    </Row>
  );

  return (
    <Popover
      overlayClassName="personPopover"
      placement="topLeft"
      content={popoverContent}
      overlayStyle={disabled ? { display: "none" } : {}}
      {...rest}
    >
      {children}
    </Popover>
  );
};

PersonPopover.defaultProps = {
  title: "",
};

PersonPopover.propTypes = {
  person: PropTypes.shape({
    avatar: PropTypes.shape({
      large: PropTypes.string,
      thumb: PropTypes.string,
      retina: PropTypes.string,
      preview: PropTypes.string,
    }),
    avatarUrls: PropTypes.shape({
      large: PropTypes.string,
      thumb: PropTypes.string,
      retina: PropTypes.string,
      preview: PropTypes.string,
    }),
    email: PropTypes.string,
    fullName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.number,
    phone: PropTypes.string,
    color: PropTypes.string,
    shortName: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.string]),
  title: PropTypes.string,
  projectRelation: PropTypes.string,
  projectId: PropTypes.number,
  // visible: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PersonPopover;
