import React, { CSSProperties, useState } from "react";
import { TextFieldProps } from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import Autocomplete from "@mui/material/Autocomplete";
import { useController } from "react-hook-form";
import { useSmartFormContext } from "components/SmartForm";
import { addressLookup } from "lib/helpers";
import * as S from "./styles";

type SmartFormSingleLineAddressT = {
  name: string;
  clearValues: () => void;
  handleAutocompleteSelect: (event, value) => void;
  autoCompleteDefaultValue?: string;
  defaultSuggestions?: string[];
  prefixIcon?: React.ReactNode;
  helperText?: string | undefined;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  labelStyle?: CSSProperties;
} & TextFieldProps;

const SmartFormSingleLineAddress = ({
  label,
  name,
  helperText,
  error,
  clearValues,
  handleAutocompleteSelect,
  prefixIcon,
  autoCompleteDefaultValue,
  defaultSuggestions = [],
  disabled = false,
  labelStyle = {},
  ...rest
}: SmartFormSingleLineAddressT) => {
  const [suggestions, setSuggestions] = useState<string[]>(defaultSuggestions);
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });

  const handleSelectedOption = (event, value) => {
    handleAutocompleteSelect(event, value);
  };

  const fetchAutocompleteSuggestions = async (value: string) => {
    const addresses = await addressLookup(value);
    setSuggestions(
      addresses.map((address): string => {
        const unit = address.unit ? `${address.unit}, ` : " ";
        return `${address.street_line},${unit}${address.city}, ${address.state}, ${address.zipcode}`;
      }),
    );
  };

  return (
    <>
      <S.LabelGroupWrapper>
        <S.StyledFormLabel style={labelStyle}>{label}</S.StyledFormLabel>
      </S.LabelGroupWrapper>
      <Autocomplete
        options={suggestions}
        disabled={disabled}
        defaultValue={autoCompleteDefaultValue}
        filterOptions={(x) => x}
        freeSolo
        onChange={handleSelectedOption}
        onInputChange={(_, newInputValue, reason) => {
          if (reason === "clear") {
            clearValues();
          } else {
            fetchAutocompleteSuggestions(newInputValue);
          }
        }}
        isOptionEqualToValue={(option, value) => option === value}
        renderOption={(props, option) => (
          <S.StyledAutocompleteOption {...props} key={option}>
            {option}
          </S.StyledAutocompleteOption>
        )}
        renderInput={(params) => {
          const newParams = params;
          if (prefixIcon) {
            newParams.InputProps.startAdornment = (
              <InputAdornment position="start">
                <S.PrefixContainer>{prefixIcon}</S.PrefixContainer>
              </InputAdornment>
            );
          }
          return <S.StyledTextField {...newParams} {...field} {...rest} disabled={disabled} size="small" />;
        }}
      />
      <S.StyledHelperText error={error}>{helperText}</S.StyledHelperText>
    </>
  );
};

export default SmartFormSingleLineAddress;
