import Paper from "@mui/material/Paper";

import { styled } from "@mui/material";
import { theme as ourTheme } from "styles/themes";

import type { CSSProperties } from "react";

type StyledPaperPropsT = {
  header?: boolean;
};

const sharedStyles: CSSProperties = {
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.03)",
  background: ourTheme.colors.white,
  width: "100%",
};

export const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "header" && prop !== "cardStyleOverrides",
})<StyledPaperPropsT>(({ header }) => ({
  ...sharedStyles,
  position: "relative",
  borderRadius: `${header ? "0 0px 12px 12px" : "4px"}`,
}));

export const SubSection = styled(Paper)(() => ({
  ...sharedStyles,
  minHeight: "32px",
  marginBlockEnd: "3px",
  borderRadius: 0,
}));
