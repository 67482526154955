import React from "react";
import { theme } from "styles/themes";

type UtilityIconPropsT = {
  width?: string | number;
  height?: string | number;
  fill?: string;
};

const UtilityIcon = ({ width = "13", height = "13", fill = theme.colors.white }: UtilityIconPropsT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 12" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.54652 5.72869L6.26116 4.76082C6.1855 4.73815 6.12302 4.68631 6.08856 4.6176C6.05328 4.54699 6.05328 4.46479 6.08856 4.39428L7.52291 0.385228C7.56619 0.265578 7.52259 0.132845 7.4158 0.0587263C7.31066 -0.0195754 7.1638 -0.0195754 7.05867 0.0587263L0.363085 5.21322C0.27528 5.27724 0.233558 5.3843 0.255977 5.48807C0.275074 5.59294 0.353018 5.67903 0.458365 5.71149L3.79128 6.69087C3.86912 6.70865 3.93461 6.75909 3.9698 6.8283C3.99958 6.89801 3.99958 6.97621 3.9698 7.04592L2.46996 11.6277C2.43239 11.7511 2.48314 11.8837 2.59492 11.9542C2.64328 11.9831 2.69881 11.9989 2.75569 11.9999C2.82886 12.0014 2.89954 11.9746 2.95205 11.9255L9.64764 6.19832C9.72995 6.13081 9.76659 6.02494 9.74292 5.92336C9.71292 5.83238 9.64017 5.76027 9.54645 5.72871L9.54652 5.72869Z"
        fill={fill}
      />
    </svg>
  );
};

export default UtilityIcon;
