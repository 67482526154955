import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";

export const ModalContent = styled("div")(({ theme }) => ({
  fontSize: "16px",
  color: theme.colors["darkest-blue"],
  marginBottom: "8px",
  fontFamily: "Barlow",
}));

export const TermsAndConditionsContainer = styled("div")(() => ({
  maxWidth: "100%",
  width: "100%",
}));

export const TermsAndConditionsHeader = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "10px",
}));

export const VerificationLabel = styled("div")(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "20px",
  color: theme.colors["blue-ribbon"],
}));

export const OpenNewWindow = styled("div")(({ theme }) => ({
  textAlign: "right",
  fontSize: "16px",
  fontFamily: "Barlow",
  fontStyle: "italic",
  fontWeight: 500,
  lineHeight: "30px",
  color: theme.colors["dark-blue"],
  display: "flex",
  justifyContent: "flex-end",
}));

export const NewWindowLink = styled("span")(() => ({
  cursor: "pointer",
  marginLeft: "8px",
  marginTop: "2px",
}));

export const TermsText = styled("div")(() => ({
  height: "245px",
  margin: "8px 0px",
}));

export const TermsUpdates = styled("div")(() => ({
  fontSize: "16px",
  marginBottom: "8px",
}));

export const MobileTermsUpdates = styled("div")(() => ({
  fontSize: "12px",
}));

export const FontSpan = styled("span")(() => ({
  fontSize: "16px",
}));

export const MobileFontSpan = styled("span")(() => ({
  fontSize: "12px",
  lineHeight: "12px",
}));

export const TermsPdf = styled("iframe")(() => ({
  borderRadius: "3px",
}));

export const MobileTermsContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export const TermsCheckbox = styled(Checkbox)(({ theme }) => ({
  fontFamily: "Poppins",
  color: theme.colors["text-dark"],
  margin: "15px 15px 15px -20px",
}));

export const SelectTermsContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "8px 20px -20px 20px",
}));

export const TermsCheckboxSelect = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  fontFamily: "Barlow",
  color: theme.colors["dark-blue"],
  fontSize: "16px",
  fontWeight: 500,
}));
