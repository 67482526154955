import types from "rdx/modules/complianceIssues/types";
import createAction from "rdx/utils/createAction";

export default {
  createComplianceIssue: (payload) => createAction(types.CREATE_COMPLIANCE_ISSUE_REQUEST, payload),
  updateComplianceIssue: (payload) => createAction(types.UPDATE_COMPLIANCE_ISSUE_REQUEST, payload),
  deleteComplianceIssue: (payload) => createAction(types.DELETE_COMPLIANCE_ISSUE_REQUEST, payload),
  getComplianceIssues: (payload) => createAction(types.GET_COMPLIANCE_ISSUES_REQUEST, payload),
  setComplianceIssues: (payload) => createAction(types.SET_COMPLIANCE_ISSUES, payload),
  // INSERTION_PT (for script, do not remove)
};
