import {
  Dialog,
  styled,
  DialogTitle,
  DialogContent,
  ListItemText,
  listItemTextClasses,
  dialogClasses,
  Button,
  Menu,
  MenuItem,
  Box,
  menuClasses,
  Divider,
  Stack,
  List,
} from "@mui/material";
import { useMuiClass } from "styles";
import { filterStyledProps } from "lib/helpers/filterStyledProps";

export const Info = styled(List)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    gap: "10px",
  },
}));

export const ActionContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  gap: "9px",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    gap: "11px",
    width: "393px",
  },
}));

export const Divide = styled(Divider)(() => ({
  background: "#D6E1F080",
  height: "2px",
  width: "273px",
  borderRadius: "33px",
  margin: "unset",
  transform: "translateX(12px)",
}));

export const MenuContainer = styled(Menu)(() => ({
  [useMuiClass(menuClasses.paper)]: {
    borderRadius: "3px",
    width: "305px",
    maxWidth: "unset",
  },
}));

export const MenuLabel = styled(Box)(({ theme }) => ({
  color: theme.colors["dark-periwinkle"],
  fontWeight: 700,
  fontSize: "14px",
  paddingInlineStart: "12px",
  textTransform: "uppercase",
  height: "25px",
}));

export const MenuOption = styled(MenuItem, { shouldForwardProp: filterStyledProps(["bold"]) })<{ bold?: boolean }>(
  ({ theme, bold }) => ({
    fontSize: "14px",
    paddingInlineStart: "12px",
    fontWeight: 500,
    "&:hover": {
      background: "#0070F412",
    },
    color: theme.colors["dark-blue"],
    ...(bold && { fontWeight: 700 }),
  }),
);

export const RemindMeLater = styled(Button)(() => ({
  flex: 1,
  textTransform: "unset",
  fontWeight: 600,
  fontSize: "14px",
}));
export const FastPay = styled("span")(() => ({
  fontWeight: 300,
  fontStyle: "italic",
}));

export const Content = styled(DialogContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "19px",
  paddingTop: "12px !important",
  [theme.breakpoints.up("md")]: {
    padding: "36px 104px !important",
    gap: "40px",
  },
}));

export const Text = styled(ListItemText)(({ theme }) => ({
  [useMuiClass(listItemTextClasses.primary)]: {
    fontFamily: "Poppins",
    color: theme.colors["congress-blue"],
    fontWeight: 700,
    fontSize: "16px",
    width: "131px",
  },

  [useMuiClass(listItemTextClasses.secondary)]: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: theme.colors["congress-blue"],
    fontWeight: 500,
  },

  [theme.breakpoints.up("md")]: {
    display: "flex",
    alignItems: "center",
    gap: "14px",
    margin: 0,

    [useMuiClass(listItemTextClasses.secondary)]: {
      color: theme.colors["blue-ribbon"],
    },
  },
}));

export const Modal = styled(Dialog)(({ theme }) => ({
  [useMuiClass(dialogClasses.paper)]: {
    display: "flex",
    backgroundColor: theme.colors.white,
    borderRadius: "12px",
    maxWidth: "unset",
  },

  [theme.breakpoints.up("md")]: {
    [useMuiClass(dialogClasses.paper)]: {
      width: "652px",
    },
  },
}));

export const Title = styled(DialogTitle)(({ theme }) => ({
  padding: "16px 20px",
  color: theme.colors["blue-ribbon"],
  background: theme.colors["lavender-phlox"],
  fontWeight: 700,
  fontSize: "18px",
  fontFamily: "Poppins",
  [theme.breakpoints.up("md")]: {
    display: "flex",
    height: "77px",
    justifyContent: "center",
    alignItems: "center",
  },
}));
