import { put, all, select } from "redux-saga/effects";
import selectors from "rdx/modules/platformModules/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/platform/messages";
import { camelizeKeys } from "lib/helpers";

function* toggleModuleActive(action) {
  const { success, data, error } = yield* makeRequest.patch("/enterprise_modules/toggle_active", action.payload);

  if (success && data) {
    const moduleList = yield select(selectors.selectPlatformModules);

    const moduleListOut = moduleList.map((module) => {
      if (module.slug === data.body.module.slug) {
        return camelizeKeys(data.body.module);
      }
      return module;
    });

    yield all([
      put(actions.setPlatformModules({ modules: moduleListOut })),
      put(actions.setModuleDetail(camelizeKeys(data.body))),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.TOGGLE_ACTIVE_ERROR,
    });
  }

  return null;
}

export default toggleModuleActive;
