import styled from "@emotion/styled";
import { css } from "@emotion/react";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";

export const formHeaderStyles = css`
  color: var(--blue-ribbon);
  font-family: "Poppins";
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  margin-left: -4px;
  margin-bottom: 4px;
`;

export const HelperText = styled(FormHelperText)<{ error?: boolean }>`
  color: ${({ error }) => (error ? "red" : "black")};
`;

export const LabelGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledFormLabel = styled(FormLabel)`
  ${formHeaderStyles};
`;

export const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: var(--blue-ribbon);
  }
  padding: 5px;
`;

export const SubLabel = styled.span`
  font-size: 14px;
  line-height: 23px;
  color: var(--dark-periwinkle);
  margin-left: -3px;
  margin-bottom: 4px;
`;

export const RadioLabel = styled.div`
  color: var(--queen-blue);
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;

  span {
    font-weight: 200;
  }
`;
