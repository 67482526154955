import PropTypes from "prop-types";

export const messageCountersSchema = PropTypes.shape({
  id: PropTypes.number,
  childCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  assignedCount: PropTypes.number,
  archivedCount: PropTypes.number,
  viewedCount: PropTypes.number,
  taggedCount: PropTypes.number,
});

export const messageCountersDefaults = {
  id: 0,
  childCount: 0,
  assignedCount: 0,
  archivedCount: 0,
  viewedCount: 0,
  taggedCount: 0,
};
