import { all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import messages from "rdx/modules/homeowner/messages";

function* verifyHomeownerContact(action) {
  const { auth_token, mobile } = action.payload;
  const { success, data, error } = yield* makeRequest.post("/homeowner/verify", { auth_token, mobile });

  if (success && data) {
    yield all([]);
  } else if (error) {
    const errorMessage = messages.ERROR_VERIFYING_HOMEOWNER_CONTACT;
    return getErrorActions({
      error,
      message: errorMessage,
    });
  }

  return null;
}

export default verifyHomeownerContact;
