export default {
  GET_DASHBOARD_NOTIFICATIONS_WIDGET: "[CrmNotifications] GET NOTIFICATIONS",
  SET_DASHBOARD_NOTIFICATIONS: "[CrmNotifications] SET NOTIFICATIONS",

  ARCHIVE_DASHBOARD_NOTIFICATIONS_WIDGET: "[CrmNotifications] ARCHIVE NOTIFICATIONS",
  UNARCHIVE_DASHBOARD_NOTIFICATIONS_WIDGET: "[CrmNotifications] UNARCHIVE NOTIFICATIONS",
  GET_ALL_NOTIFICATIONS: "[CrmNotifications] GET All Notifications",
  SET_ALL_NOTIFICATIONS: "[CrmNotifications] SET All Notifications",
  SET_NOTIFICATION_FILTERS: "[CrmNotifications] SET Notification Filters",
  // INSERTION_PT (for script, do not remove)
};
