import { put, select } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import makeRequest from "rdx/utils/makeRequest";
import _ from "lodash";
import { keysToCamelCase } from "lib/helpers";
import actions from "rdx/actions";
import selectors from "../selectors";
import messages from "../messages";

function* reopenAdminProject(action) {
  const { id } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/projects/${id}/reopen`, { id });
  if (success && data) {
    const out = keysToCamelCase(_.clone(data.body));

    const adminProjectsList = yield select(selectors.getAdminProjectsList);
    const { paging, projects } = adminProjectsList;
    const updatedPaging = { ...paging, itemCount: paging.itemCount - 1 };
    const updatedProjectsList = projects.filter((project) => project.id !== out.id);
    yield put(actions.setAdminProjectsList({ paging: updatedPaging, projects: updatedProjectsList }));

    yield put(actions.setAdminProjectDetails(out));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_REOPENING_ADMIN_PROJECT,
    });
  }
  return null;
}

export default reopenAdminProject;
