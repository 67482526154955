import React from "react";
import { cvar } from "styles";
import { IconT } from "types/utils";

const UnqualifiedLeadsGradient = ({
  height = "28",
  width = "28",
  stopColor = cvar("blue-ribbon"),
  startColor = cvar("picton-blue"),
}: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2055_14058)">
        <path
          d="M6.56824 19.1113L0.240239 25.4393C-0.0801204 25.7597 -0.0801204 26.2791 0.240239 26.5994L1.40033 27.7596C1.55416 27.9134 1.76279 27.9998 1.9804 27.9998C2.19794 27.9998 2.40658 27.9134 2.56047 27.7596L8.88847 21.4316C7.98137 20.8057 7.19408 20.0184 6.56824 19.1113Z"
          fill="url(#paint0_linear_2055_14058)"
        />
        <path
          d="M26.2362 22.4876C25.4589 23.1939 24.4273 23.6248 23.2969 23.6248C22.3437 23.6248 21.437 23.3225 20.6748 22.7506C20.5649 22.6681 20.4595 22.5798 20.358 22.4873C19.3154 23.3404 18.6484 24.6365 18.6484 26.0857V27.1795C18.6484 27.6325 19.0157 27.9998 19.4688 27.9998H27.125C27.578 27.9998 27.9453 27.6325 27.9453 27.1795V26.0857C27.9453 24.6367 27.2786 23.3408 26.2362 22.4876Z"
          fill="url(#paint1_linear_2055_14058)"
        />
        <path
          d="M23.2969 21.9844C24.8046 21.9844 26.0313 20.7577 26.0313 19.25C26.0313 17.7423 24.8046 16.5156 23.2969 16.5156C23.0713 16.5156 22.8522 16.5436 22.6424 16.5954C22.251 17.896 21.5741 19.0741 20.6838 20.0558C21.0286 21.1714 22.0695 21.9844 23.2969 21.9844Z"
          fill="url(#paint2_linear_2055_14058)"
        />
        <path
          d="M26.2909 5.97217C25.5137 6.6784 24.482 7.10934 23.3516 7.10934C22.2215 7.10934 21.1902 6.67862 20.413 5.97282C20.0873 6.23959 19.7981 6.54929 19.5546 6.89365C21.0259 8.04619 22.1314 9.64504 22.6662 11.4843H27.1797C27.6327 11.4843 28 11.1171 28 10.664V9.57028C28 8.12128 27.3333 6.82535 26.2909 5.97217Z"
          fill="url(#paint3_linear_2055_14058)"
        />
        <path
          d="M1.70915 5.97217C0.666695 6.82529 0 8.12128 0 9.57028V10.664C0 11.1171 0.367281 11.4843 0.820313 11.4843H5.33384C5.86863 9.64498 6.97408 8.04619 8.44539 6.89365C8.20192 6.54929 7.91268 6.23959 7.58702 5.97282C6.80985 6.67862 5.7785 7.10934 4.64844 7.10934C3.51805 7.10934 2.48642 6.6784 1.70915 5.97217Z"
          fill="url(#paint4_linear_2055_14058)"
        />
        <path
          d="M4.64844 5.46875C3.1407 5.46875 1.91406 4.24211 1.91406 2.73438C1.91406 1.22664 3.14076 0 4.64844 0C6.15612 0 7.38281 1.22664 7.38281 2.73438C7.38281 4.24211 6.15617 5.46875 4.64844 5.46875Z"
          fill="url(#paint5_linear_2055_14058)"
        />
        <path
          d="M23.3516 5.46875C21.8438 5.46875 20.6172 4.24211 20.6172 2.73438C20.6172 1.22664 21.8438 0 23.3516 0C24.8593 0 26.0859 1.22664 26.0859 2.73438C26.0859 4.24211 24.8593 5.46875 23.3516 5.46875Z"
          fill="url(#paint6_linear_2055_14058)"
        />
        <path
          d="M10.9993 20.7449C11.9166 21.1546 12.9321 21.3828 14 21.3828C15.0679 21.3828 16.0834 21.1546 17.0007 20.7449V19.344C16.9345 17.7261 15.6191 16.4609 14 16.4609C12.3809 16.4609 11.0655 17.7261 10.9993 19.344V20.7449Z"
          fill="url(#paint7_linear_2055_14058)"
        />
        <path
          d="M14 14.8203C14.9061 14.8203 15.6406 14.0858 15.6406 13.1797C15.6406 12.2736 14.9061 11.5391 14 11.5391C13.0939 11.5391 12.3594 12.2736 12.3594 13.1797C12.3594 14.0858 13.0939 14.8203 14 14.8203Z"
          fill="url(#paint8_linear_2055_14058)"
        />
        <path
          d="M14 6.61719C9.92912 6.61719 6.61719 9.92912 6.61719 14C6.61719 16.3139 7.68748 18.3822 9.35867 19.7368V19.3281C9.35867 19.3182 9.35884 19.3082 9.35922 19.2983C9.40313 18.0927 9.90484 16.9664 10.7719 16.1269C11.0388 15.8683 11.3321 15.6459 11.645 15.4611C11.0723 14.8701 10.7188 14.0656 10.7188 13.1797C10.7188 11.3704 12.1907 9.89844 14 9.89844C15.8093 9.89844 17.2812 11.3704 17.2812 13.1797C17.2812 14.0656 16.9277 14.8701 16.3551 15.4611C16.668 15.6459 16.9612 15.8684 17.2281 16.1269C18.0952 16.9665 18.5968 18.0928 18.6407 19.2983C18.6411 19.3083 18.6413 19.3182 18.6413 19.3282V19.7368C20.3125 18.3822 21.3828 16.3139 21.3828 14C21.3828 9.92912 18.0709 6.61719 14 6.61719Z"
          fill="url(#paint9_linear_2055_14058)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2055_14058"
          x1="1.55936"
          y1="15.4078"
          x2="9.40676"
          y2="16.1917"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2055_14058"
          x1="20.2795"
          y1="20.1904"
          x2="28.3405"
          y2="21.5484"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2055_14058"
          x1="21.622"
          y1="14.237"
          x2="26.3451"
          y2="14.6983"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2055_14058"
          x1="21.0363"
          y1="3.67543"
          x2="28.3945"
          y2="4.80155"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2055_14058"
          x1="1.48165"
          y1="3.67543"
          x2="8.8399"
          y2="4.80155"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2055_14058"
          x1="2.87349"
          y1="-2.27865"
          x2="7.7017"
          y2="-1.79637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2055_14058"
          x1="21.5766"
          y1="-2.27865"
          x2="26.4048"
          y2="-1.79637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2055_14058"
          x1="12.0522"
          y1="14.4102"
          x2="17.3253"
          y2="15.0524"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2055_14058"
          x1="12.935"
          y1="10.1719"
          x2="15.832"
          y2="10.4612"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2055_14058"
          x1="9.20765"
          y1="1.15067"
          x2="22.2095"
          y2="2.61234"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <clipPath id="clip0_2055_14058">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UnqualifiedLeadsGradient;
