import { put, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { actions, selectors } from "rdx";
import _ from "lodash";
import { keysToCamelCase, keysToSnakeCase } from "lib/helpers";
import messages from "../messages";

function* getMentors(action) {
  const { requestFreshData = true } = action.payload || {};
  const existingList = yield select(selectors.getMentorsList);
  const nextPage = existingList?.paging?.page_number + 1 || 1;

  const params = {
    limit: 20,
    ...keysToSnakeCase(action.payload),
    ...(!requestFreshData && { p: nextPage }),
  };

  if (!params.sort && !params.filters) params.sort = "mentor_rating";
  const { success, data, error } = yield* makeRequest.get("/users/mentors", params);

  // determines whether to set as full mentor list or powur pair list
  const setMentorListAction = action?.payload?.powurPairForProject ? actions.setPowurPairList : actions.setMentorsList;

  if (success && data) {
    const out = _.clone(data.body);
    if (out.users) {
      out.users = data.body.users.map((user) => {
        const newUser = _.clone(user);
        newUser.metrics = {};
        user.metrics.forEach((metric) => {
          const key = Object.keys(metric)[0];
          const value = Object.values(metric)[0];
          newUser.metrics[key] = value;
        });
        return keysToCamelCase(newUser);
      });

      const { paging, users: newMentorList } = out;
      const concatList = _.cloneDeep(existingList.users).concat(newMentorList);
      const mentorList = requestFreshData ? newMentorList : concatList;
      yield put(setMentorListAction({ paging, users: mentorList }));
    }
    if (out.filters) {
      if (out.filters.service_areas) {
        out.filters.service_areas.sort((a, b) => Object.values(a)[0].localeCompare(Object.values(b)[0]));
      }
      yield put(actions.setMentorFilters({ filters: keysToCamelCase(out.filters) }));
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_MENTOR_LIST,
    });
  }
  return null;
}

export default getMentors;
