import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import { useParams } from "react-router-dom";

import { PlusCircleOutlined, ContainerOutlined } from "@ant-design/icons";
import { Form, Button, Input } from "antd";
import { isEmpty } from "lodash";
import { buttonProps } from "lib/props";

import DataGroup from "models/DataGroup";

import useModalControls from "hooks/useModalControls";

import ModalContainer from "components/Modals/ModalContainer";
import FormErrorMessage from "components/FormElements/FormErrorMessage";
import workflowsTabs from "../../tabs";
import styles from "../../AdminWorkflows.module.less";

const CreateDataGroupModal = ({ dataGroups, createDataGroup }) => {
  const { tab } = useParams();
  const tabKeys = workflowsTabs.map(({ key }) => key);

  const [form] = Form.useForm();

  const { visible, open, close } = useModalControls();

  const handleSubmit = () => {
    form.validateFields().then((values, errors) => {
      if (isEmpty(errors)) {
        createDataGroup({ ...values, rank: dataGroups.length });
      }
      form.resetFields();
      close();
    });
  };

  return (
    <>
      {tab === tabKeys[1] && (
        <Button {...buttonProps(open)} className={styles.addDataGroupButton} icon={<PlusCircleOutlined />}>
          Add New Data Group
        </Button>
      )}
      <ModalContainer
        visible={visible}
        headerIcon={<ContainerOutlined className={styles.blue} />}
        title="Add New Data Group"
        handleCancel={close}
        handleSubmit={handleSubmit}
        submitText="Create"
      >
        <Form form={form} layout="vertical" requiredMark={false} className={styles.createDataGroup}>
          <Form.Item
            name="label"
            label={<span className={styles.label}>Label</span>}
            className={styles.formItem}
            rules={[
              {
                required: true,
                message: <FormErrorMessage message="Data group label required." />,
              },
            ]}
          >
            <Input className={styles.input} />
          </Form.Item>
          <Form.Item
            name="description"
            label={<span className={styles.label}>Description</span>}
            className={styles.formItem}
          >
            <Input className={styles.input} />
          </Form.Item>
        </Form>
      </ModalContainer>
    </>
  );
};

CreateDataGroupModal.propTypes = {
  dataGroups: PropTypes.arrayOf(DataGroup.types()),
  createDataGroup: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    dataGroups: "getDataGroups",
  }),
  getRdxActionMapper(["createDataGroup"]),
)(CreateDataGroupModal);
