import PropTypes from "prop-types";
import { messageTypes } from "rdx/modules/messages/constants";
import _ from "lodash";

class MessageEvent {
  static types() {
    return PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      message: PropTypes.string,
      type: PropTypes.oneOf(_.values(messageTypes)), // ["GENERAL", "ERROR", "SUCCESS"]
      // properties of payload will depend on what ever value is appended to a new event, typically done
      // in a saga on a success event on API request that gives status 200. ensure component that
      // is watching for such requests are consistent with the body of payload in the relevant saga.
      payload: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    });
  }
}

export type MessageEventT<Payload extends object | null = null> = {
  id: string | number;
  message: string;
  type: "GENERAL" | "ERROR" | "SUCCESS";
  payload: Payload;
  error: {
    status: number;
    message: string;
  };
};

export default MessageEvent;
