import React from "react";
import PlusOutlined from "components/Icons/PlusOutlined";
import { Button } from "antd";
import styles from "./OpenDrawerButton.module.less";

interface OpenDrawerButtonPropsI {
  showButton?: boolean;
  handleClick: () => void;
  title?: string;
}

const OpenDrawerButton = ({
  showButton = true,
  handleClick = () => null,
  title = "Add New",
}: OpenDrawerButtonPropsI) => {
  return (
    <Button
      type="default"
      htmlType="button"
      className={showButton ? styles.button : styles.buttonHidden}
      onClick={handleClick}
    >
      <div className={styles.innerContainer}>
        <div className={styles.iconContainer}>
          <PlusOutlined height="11px" width="11px" />
        </div>
        <div className={styles.buttonLabel}>{title}</div>
      </div>
    </Button>
  );
};

export default OpenDrawerButton;
