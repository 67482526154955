import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToSnakeCase } from "lib/helpers";
import SessionClient from "util/SessionClient";
import messages from "../messages";

function* createNewUser(action) {
  const { success, data, error } = yield* makeRequest.post("/users", keysToSnakeCase(action.payload));

  if (success && data) {
    const session = new SessionClient();
    session.setSession(data);
    session.user.initStore(data);

    yield put(
      actions.newSuccessEvent({
        message: messages.CREATE_NEW_USER_SUCCESS,
        payload: data,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_NEW_USER,
    });
  }

  return null;
}

export default createNewUser;
