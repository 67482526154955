import React from "react";
import { theme } from "styles/themes";

type LocationPinPropsT = {
  width?: string | number;
  height?: string | number;
  fill?: string;
};

const LocationPin = ({ width = "13", height = "13", fill = theme.colors.white }: LocationPinPropsT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 12" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.77581 9.22432C1.70031 9.1992 1.61794 9.20515 1.54678 9.24072L0.962509 9.53277C0.512938 9.7576 0.472074 10.4164 0.890355 10.6953L3.29828 12.3005C3.72241 12.5832 4.27737 12.5832 4.70151 12.3005L7.10943 10.695C7.52763 10.4162 7.48662 9.7576 7.03703 9.53277L6.45293 9.24072C6.30477 9.16664 6.12464 9.22666 6.05048 9.37482C5.97648 9.52298 6.03641 9.7031 6.18457 9.77718L6.76867 10.0695C6.83179 10.101 6.83513 10.1567 6.77637 10.1958L4.36845 11.8013C4.14446 11.9506 3.85508 11.9506 3.63109 11.8013L1.22317 10.1961C1.16449 10.1569 1.16784 10.101 1.23087 10.0695L1.81514 9.77719H1.81522C1.9633 9.70311 2.02331 9.52298 1.94923 9.37482C1.91366 9.30367 1.85131 9.24951 1.77581 9.22432Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00017 0.512695C1.917 0.512695 0.22168 2.2078 0.22168 4.29098C0.22168 5.51165 0.74115 6.6014 1.21517 7.60554C1.82882 8.90557 3.49581 11.0307 3.49581 11.0307L3.48317 11.0131C3.72341 11.3734 4.27744 11.3734 4.51768 11.0131L4.50504 11.0307C4.50504 11.0307 6.17168 8.90574 6.78525 7.60576C7.25918 6.60155 7.77874 5.51169 7.77874 4.29098C7.77866 2.20789 6.08329 0.512695 4.00003 0.512695H4.00017ZM4.00017 2.25201C5.12299 2.25201 6.0394 3.16874 6.0394 4.29149C6.0394 5.41424 5.1229 6.3308 4.00017 6.3308C2.87736 6.3308 1.96095 5.4143 1.96095 4.29149C1.96095 3.16874 2.87745 2.25201 4.00017 2.25201Z"
        fill={fill}
      />
    </svg>
  );
};

export default LocationPin;
