import React from "react";
import { cvar } from "styles";

type IconT = {
  height?: string | number;
  width?: string | number;
  dotFill?: string;
  circleFill?: string;
};

const MenuIconFilled = ({
  height = 16,
  width = 16,
  dotFill = cvar("white"),
  circleFill = cvar("blue-ribbon"),
}: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.3335" cy="8.77441" r="8" fill={circleFill} />
      <circle cx="8.3335" cy="4.266674" r="1.33333" fill={dotFill} />
      <circle cx="8.3335" cy="8.5333" r="1.33333" fill={dotFill} />
      <circle cx="8.3335" cy="12.8" r="1.33333" fill={dotFill} />
    </svg>
  );
};

export default MenuIconFilled;
