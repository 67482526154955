import types from "rdx/modules/buildPartnerApplications/types";
import createAction from "rdx/utils/createAction";

export default {
  getBuildPartnerApplications: (payload) => createAction(types.GET_BUILD_PARTNER_APPLICATIONS, payload),

  setBuildPartnerApplications: (payload) => createAction(types.SET_BUILD_PARTNER_APPLICATIONS, payload),

  getBuildPartnerApplicationDetail: (payload) => createAction(types.GET_BUILD_PARTNER_APPLICATION_DETAIL, payload),

  setBuildPartnerApplicationDetail: (payload) => createAction(types.SET_BUILD_PARTNER_APPLICATION_DETAIL, payload),

  approveBuildPartnerApplication: (payload) => createAction(types.APPROVE_BUILD_PARTNER_APPLICATION, payload),

  rejectBuildPartnerApplication: (payload) => createAction(types.REJECT_BUILD_PARTNER_APPLICATION, payload),

  makePendingBuildPartnerApplication: (payload) => createAction(types.MAKE_PENDING_BUILD_PARTNER_APPLICATION, payload),

  createBuildPartnerApplication: (payload) => createAction(types.CREATE_BUILD_PARTNER_APPLICATION, payload),

  updateBuildPartnerApplication: (payload) => createAction(types.UPDATE_BUILD_PARTNER_APPLICATION, payload),

  createTierOneUserRegistration: (payload) => createAction(types.CREATE_TIER_ONE_USER_REGISTRATION, payload),
  // INSERTION_PT (for script, do not remove)
};
