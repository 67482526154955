import { Box, Typography, styled } from "@mui/material";

export const DialogContainer = styled(Box)(({ theme }) => ({
  background: theme.colors["alert-dialog-blue"],
  display: "flex",
  borderRadius: "0.125rem",
  marginBlockEnd: "1.25rem",
  paddingBlock: "1.19rem",
  paddingInlineStart: "0.62rem",
  width: "30.5625rem",
  height: "3.875rem",
  gap: "0.75rem",
  alignItems: "center",
}));

export const DialogText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "1.3125rem",
  color: theme.colors["darkest-blue"],
  span: {
    fontWeight: 600,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.colors["blue-ribbon"],
  fontFamily: "Poppins",
  fontSize: "1.125rem",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "1.3125rem",
  marginBlockEnd: "1.06rem",
  marginBlockStart: ".5rem",
}));
