import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getSellerOrLeadAutoComplete(action) {
  const params = action.payload;

  const { success, data, error } = yield* makeRequest.get("/earnings/search_consultant_or_lead", params);

  if (success && data) {
    if (data.body.results) {
      yield put(actions.setSellerOrLeadAutoComplete(camelizeKeys(data.body.results)));
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_PROJECTS_AUTOCOMPLETE,
    });
  }
  return null;
}

export default getSellerOrLeadAutoComplete;
