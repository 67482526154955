import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../Team.module.less";

const Mission = () => {
  const { t } = useTranslation("team");
  return (
    <div className={styles.missionContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.verticalLine} />
        <section>
          <p className={styles.boldTitle}>{t("mission_body.powur_vision")}</p>
          <p className={styles.vision}>{t("mission_body.accelerate_adoption")}</p>
        </section>
        <div className={styles.verticalLine} />
        <section>
          <p className={styles.boldTitle}>{t("mission_body.powur_mission")}</p>
          <p className={styles.vision}>{t("mission_body.largest_clean_energy_platform")}</p>
        </section>
        <div className={styles.verticalLine} />
        <section>
          <p className={styles.boldTitle}>{t("mission_body.powur_values")}</p>
          <div className={styles.valuesContainer}>
            {t("mission_body.values", { returnObjects: true }).map((s) => (
              <span key={s} className={styles.value}>
                {s}
              </span>
            ))}
          </div>
        </section>
        <div className={styles.verticalLine} />
      </div>
    </div>
  );
};

export default Mission;
