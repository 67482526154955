import { put } from "redux-saga/effects";

import SessionClient from "util/SessionClient";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* logbackUser(action) {
  const { userId } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/users/${userId}/logback`, {});
  if (success && data) {
    const session = new SessionClient();
    session.setSession(data);
    window.location.href = "/users";
    yield put(
      actions.newSuccessEvent({
        message: messages.LOGBACK_TOKEN_RECEIVED_SUCCESS,
        payload: data,
      }),
    );
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_LOGBACKING_USER,
    });
  }

  return null;
}

export default logbackUser;
