import { camelizeKeys } from "lib/helpers";
import { api } from "rdx/services/api";
import SchemaValidator from "util/schema-helpers/SchemaValidator";
import { MarketingResponse, MarketingResponseBody } from "./marketingTypes";
import { MarketingResponseSchema } from "./schemas";

export const marketingApi = api.enhanceEndpoints({ addTagTypes: ["Marketing"] }).injectEndpoints({
  endpoints: (builder) => ({
    getMarketing: builder.query<MarketingResponseBody, void>({
      query: () => "/marketing",
      transformResponse: (res: MarketingResponse) => {
        SchemaValidator.validate<typeof MarketingResponseSchema>({
          schema: MarketingResponseSchema,
          schemaName: "MarketingResponseSchema",
          dto: camelizeKeys(res),
        });
        return camelizeKeys(res.body);
      },
      providesTags: ["Marketing"],
    }),
    updateFacebookPixel: builder.mutation<MarketingResponseBody, NonNullable<MarketingResponseBody["facebookPixelId"]>>(
      {
        query: (facebookPixelId) => ({
          url: "/marketing/update_facebook_pixel",
          method: "PATCH",
          body: { facebook_pixel_id: facebookPixelId },
        }),
        transformResponse: ({ body }: MarketingResponse) => {
          return camelizeKeys(body);
        },
        onQueryStarted: (facebookPixelId, { dispatch, queryFulfilled }) => {
          const res = dispatch(
            marketingApi.util.updateQueryData("getMarketing", undefined, (state) => {
              Object.assign(state, { ...state, facebookPixelId });
            }),
          );
          queryFulfilled.catch(res.undo);
        },
      },
    ),
  }),
});

export const { useGetMarketingQuery, useUpdateFacebookPixelMutation } = marketingApi;
