import React, { CSSProperties } from "react";

import Grid from "@mui/material/Grid";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Tooltip } from "components/mui-styled-components/Tooltip";
import { useController } from "react-hook-form";
import Radio from "@mui/material/Radio";
import Chip from "@mui/material/Chip";
import { UncheckedRadioButton, CheckedRadioButton } from "components/Icons";
import { useSmartFormContext } from "components/SmartForm";
import { cvar } from "styles";
import HelperText from "../HelperText";
import * as S from "./styles";

type RadioOptionsT = {
  label: string | React.ReactNode;
  value: unknown;
  labelSubtext?: string | React.ReactNode;
  textFieldEl?: React.ReactNode;
  showTextFieldEl?: boolean;
  optionDisabled?: boolean;
  tooltipText?: string | React.ReactNode;
  indicatorOnRadio?: boolean;
  indicatorLabel?: string;
  disabledIndicatorLabel?: string;
  indicatorTooltipText?: string | React.ReactNode;
  disabledTooltipText?: string | React.ReactNode;
}[];

type FormRadioGroupProps = {
  mainLabel?: string;
  subLabel?: string | React.ReactElement;
  radioOptions: RadioOptionsT;
  name: string;
  helperText?: string | undefined;
  error?: boolean;
  row?: boolean;
  disabled?: boolean;
  mainLabelStyle?: CSSProperties;
  boxStyle?: CSSProperties;
  isBoxStyle?: boolean;
  radioLabelStyle?: CSSProperties;
  radioBoxPadding?: number;
  indicator?: boolean;
  disabledIndicator?: boolean;
} & RadioGroupProps;

const FormRadioGroup = ({
  mainLabel,
  subLabel,
  radioOptions,
  name,
  helperText,
  error = false,
  row = false,
  disabled = false,
  mainLabelStyle = {},
  isBoxStyle = false,
  boxStyle = {},
  radioLabelStyle = {},
  radioBoxPadding = 0,
  indicator = false,
  disabledIndicator = false,
  ...rest
}: FormRadioGroupProps) => {
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });

  const isButtonSelected = (v) => {
    return field.value === v || field.value === v.toString();
  };

  return (
    <>
      <S.LabelGroupWrapper>
        <S.StyledFormLabel style={mainLabelStyle}>{mainLabel}</S.StyledFormLabel>
        <S.SubLabel>{subLabel}</S.SubLabel>
      </S.LabelGroupWrapper>
      <RadioGroup row={row} style={rest.style || {}} {...field} {...rest}>
        {radioOptions.map(
          (
            {
              label,
              labelSubtext,
              value,
              textFieldEl,
              showTextFieldEl,
              optionDisabled,
              tooltipText,
              indicatorOnRadio,
              indicatorLabel,
              disabledIndicatorLabel,
              indicatorTooltipText,
              disabledTooltipText,
            },
            idx,
          ) => (
            <S.StyledBox
              p={radioBoxPadding}
              isBoxStyle={isBoxStyle}
              isSelected={isButtonSelected(value)}
              key={`${idx + 1}-${value}`}
              style={boxStyle}
            >
              <FormControlLabel
                data-test-id={`${value}-SmartFormRadioButton`}
                key={`label-${idx + 1}-${value}`}
                defaultValue={rest.defaultValue}
                disabled={optionDisabled || disabled}
                value={value}
                sx={{ marginRight: "6px" }}
                control={
                  <Radio
                    icon={<UncheckedRadioButton />}
                    checkedIcon={<CheckedRadioButton stroke={disabled ? cvar("nepal-blue") : cvar("blue-ribbon")} />}
                  />
                }
                label={
                  <Grid container direction="row">
                    <Grid item>
                      <S.RadioLabel style={radioLabelStyle}>
                        {label}
                        {labelSubtext && <span>{labelSubtext}</span>}
                      </S.RadioLabel>
                    </Grid>
                    {indicator && indicatorOnRadio && (
                      <Grid item>
                        <Tooltip
                          placement="top"
                          title={
                            <S.TooltipText>
                              {disabledIndicator ? disabledTooltipText : indicatorTooltipText}
                            </S.TooltipText>
                          }
                        >
                          <Chip
                            label={disabledIndicator ? disabledIndicatorLabel : indicatorLabel}
                            size="small"
                            sx={{
                              backgroundColor: disabledIndicator ? cvar("graham-grey") : cvar("blue-ribbon"),
                              marginLeft: "12px",
                              "& .MuiChip-label": {
                                color: cvar("white"),
                                fontFamily: "Barlow",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "17px",
                              },
                            }}
                          />
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                }
              />
              {tooltipText && (
                <Tooltip placement="top" title={<S.TooltipText>{tooltipText}</S.TooltipText>}>
                  <S.TooltipIcon>!</S.TooltipIcon>
                </Tooltip>
              )}
              {showTextFieldEl && textFieldEl}
            </S.StyledBox>
          ),
        )}
      </RadioGroup>
      <HelperText error={error}>{helperText}</HelperText>
    </>
  );
};

export type { RadioOptionsT };

export default FormRadioGroup;
