import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/adminLeads/messages";
import { keysToCamelCase } from "lib/helpers";

function* getAdminLeadsList(action) {
  const params = {
    sort: "newest",
    admin: true,
    ...action.payload,
  };
  const { success, data, error } = yield* makeRequest.get("/leads", params);

  if (success && data) {
    const leads = data.body.leads.map((lead) => keysToCamelCase(lead));
    const paging = keysToCamelCase(data.body.paging);

    yield put(actions.setAdminLeadsList({ ...data.body, paging, leads }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_ADMIN_LEADS,
    });
  }
  return null;
}

export default getAdminLeadsList;
