import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getEnterpriseCustomFields(payload) {
  const { success, data, error } = yield* makeRequest.get(`/enterprise/${payload.enterpriseId}/lead_custom_fields`);
  if (success && data) {
    const { customFields } = data.body;

    yield put(actions.setEnterpriseCustomFields(camelizeKeys(customFields)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ENTERPRISE_CUSTOM_FIELDS,
    });
  }
  return null;
}

export default getEnterpriseCustomFields;
