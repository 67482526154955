import React from "react";
import { styled } from "@mui/material";
import { useSelector } from "react-redux";
import selectors from "rdx/selectors";
import { theme } from "styles/themes";
import { filterStyledProps } from "lib/helpers/filterStyledProps";
import { AlertIconFilled } from "components/Icons";

const ClosedProjectBanner = () => {
  const shouldDisplayBanner = useSelector(selectors.selectProjectClosedBannerActive);
  const isProjectMiniHeaderActive = useSelector(selectors.selectProjectMiniHeaderActive);

  if (!shouldDisplayBanner) return null;
  return (
    <ClosedProjectBannerContainer isProjectMiniHeaderActive={isProjectMiniHeaderActive}>
      <div style={{ marginRight: "10px" }}>
        <AlertIconFilled />
      </div>
      This project is <span style={{ fontWeight: 600, marginLeft: "5px" }}>Closed</span>
    </ClosedProjectBannerContainer>
  );
};

export default ClosedProjectBanner;

const ClosedProjectBannerContainer = styled("div", {
  shouldForwardProp: filterStyledProps(["isProjectMiniHeaderActive"]),
})(({ isProjectMiniHeaderActive }: { isProjectMiniHeaderActive: boolean }) => ({
  display: "flex",
  background: theme.colors.danger,
  height: "44px",
  width: "100%",
  padding: "10px 14px",
  color: theme.colors.white,
  fontSize: "16px",
  lineHeight: "22px",
  position: "relative",
  marginBottom: isProjectMiniHeaderActive ? "0px" : "-10px",
}));
