import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import { formatDateTimeShort } from "lib/helpers";
// import useLoading from "hooks/useLoading";
// import types from "rdx/types";
import queryString from "query-string";
import { Button, Input } from "antd";
import styles from "../../Users.module.less";

const DownloadCSV = (props) => {
  const { startUsersReport, setUsersReport, getUsersReport, usersReport, search } = props;

  const [pending, setPending] = useState(false);
  const [reportReady, setReportReady] = useState(false);
  const [reportName, setReportName] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  const getReport = (interval, reportId) => {
    setIntervalId(
      setInterval(() => {
        if (reportId) getUsersReport({ reportId });
      }, interval),
    );
  };

  useEffect(() => {
    if (usersReport?.report?.url) {
      setPending(false);
      setReportReady(true);
      clearInterval(intervalId);
    } else if (usersReport?.report?.id && !intervalId) {
      getReport(2000, usersReport.report.id);
    }
  }, [usersReport]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReset = () => {
    setUsersReport({});
    setReportName(null);
    setReportReady(false);
  };

  const handleStartReport = () => {
    startUsersReport({ name: reportName, ...queryString.parse(search) });
    setPending(true);
  };

  const currentDateTime = () => {
    const now = new Date();
    return formatDateTimeShort(now);
  };

  return (
    <div className={styles.reportContainer}>
      <div className={styles.label}>Download CSV</div>
      <div className={styles.subLabel}>With Current Filters</div>
      {!reportReady && (
        <>
          <Input
            onChange={(e) => setReportName(e.target.value)}
            className={styles.input}
            placeholder={`UsersReport-${currentDateTime()}`}
          />
          <Button
            className={styles.button}
            type="primary"
            loading={pending}
            // disabled={!reportName}
            onClick={handleStartReport}
          >
            {!pending ? "Generate User CSV" : "Generating CSV"}
          </Button>
        </>
      )}
      {reportReady && (
        <>
          <Button
            className={styles.button}
            type="primary"
            onClick={() => {
              window.open(usersReport.report?.url, "_blank");
            }}
          >
            Download User CSV
          </Button>
          <Button
            className={styles.button}
            // type="primary"
            onClick={handleReset}
          >
            Restart
          </Button>
        </>
      )}
    </div>
  );
};

DownloadCSV.propTypes = {
  startUsersReport: PropTypes.func,
  setUsersReport: PropTypes.func,
  getUsersReport: PropTypes.func,
  usersReport: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  search: PropTypes.string,
};

export default connect(
  getRdxSelectionMapper({
    usersReport: "getUsersReport",
  }),
  getRdxActionMapper(["startUsersReport", "setUsersReport", "getUsersReport"]),
)(DownloadCSV);
