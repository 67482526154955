import { put } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import makeRequest from "rdx/utils/makeRequest";
import _ from "lodash";
import { keysToCamelCase } from "lib/helpers";
import actions from "rdx/actions";
import messages from "../messages";

function* closeAdminProject(action) {
  const { id, closeReasonId, closeNote } = action.payload;

  const params = {
    id,
    close_reason_id: closeReasonId,
    close_note: closeNote,
  };

  const { success, data, error } = yield* makeRequest.post(`/projects/${id}/close`, params);
  if (success && data) {
    const out = keysToCamelCase(_.clone(data.body));
    yield put(actions.setAdminProjectDetails(out));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CLOSING_PROJECT,
    });
  }
  return null;
}

export default closeAdminProject;
