// import { get } from 'lodash';

export default {
  getTeamLeads: (state) => state.team.leadsList,
  getAmbassadorList: (state) => state.team.ambassadorTeamList,
  getTeamUpline: (state) => state.team.uplineList,
  getTeamUserDetails: (state) => state.team.userDetails.user,
  getTeamUserRecruits: (state) => state.team.userDetails.recruits,
  getEditInviteId: (state) => state.team.editInviteId,
  getInviteDetails: (state) => state.team.inviteDetails,
};
