const yesText = {
  header: "Congratulations, your home qualifies to save with solar!",
  subHeader: "Here is what happens next:",
  content: [
    {
      text: "We will help you set up an appointment with a solar professional, so you can get a personalized Savings Proposal. We will show you exactly how much money you’ll save by switching from your utility company to solar.",
      style: "Normal",
    },
    {
      text: "After that, we can assess your roof orientation, shading, and solar irradiance in your area to make sure you can take advantage of the generous savings.",
      style: "Normal",
    },
    {
      text: "We've designed this process to be VERY EASY for you.",
      style: "Bold",
    },
    {
      text: "We'll be in touch soon!",
      style: "Normal",
    },
    {
      text: "- The Powur Team",
      style: "Normal",
    },
  ],
};

export default yesText;
