import React, { CSSProperties } from "react";
import { TextFieldProps } from "@mui/material/TextField";

import { useController } from "react-hook-form";
import { useSmartFormContext } from "components/SmartForm";
import { cvar } from "styles";
import HelperText from "../HelperText";
import * as S from "./styles";

export type SmartFormSmallNumberFieldProps = {
  label?: string;
  name: string;
  width?: string;
  helperText?: string | undefined;
  error?: boolean;
  disabled?: boolean;
  disableStyleOverride?: boolean;
  labelStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  customColor?: string;
  min?: number;
  max?: number;
} & TextFieldProps;

const SmartFormSmallNumberField = ({
  label,
  name,
  width = "50px",
  helperText,
  error,
  disabled = false,
  disableStyleOverride,
  labelStyle = {},
  containerStyle = {},
  min,
  max,
  ...rest
}: SmartFormSmallNumberFieldProps) => {
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });

  return (
    <S.InputContainer style={containerStyle}>
      <S.LabelGroupWrapper>
        <S.StyledFormLabel style={labelStyle}>{label}</S.StyledFormLabel>
      </S.LabelGroupWrapper>
      <S.StyledTextField
        inputProps={{
          style: {
            color: rest.customColor || cvar("banner-blue"),
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "17px",
            width: `${width}`,
            textAlign: "center",
          },
          min,
          max,
        }}
        type="number"
        disabled={disabled}
        size="small"
        defaultValue={rest.defaultValue}
        sx={{
          backgroundColor: "white",
          borderRadius: "4px",
          marginBottom: "0px",
          ...(disableStyleOverride && {
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: cvar("dark-blue"),
            },
          }),
        }}
        {...field}
        {...rest}
      />
      <HelperText sx={{ marginTop: "-10px" }} error={error}>
        {helperText}
      </HelperText>
    </S.InputContainer>
  );
};

export default SmartFormSmallNumberField;
