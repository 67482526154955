import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { css } from "@emotion/react";
import { cvar } from "styles/colorVariables";

import { useLocation, useHistory } from "react-router-dom";
import projectActions from "rdx/modules/projects/types";
import adminProjectActions from "rdx/modules/adminProjects/types";

import copy from "copy-to-clipboard";
import { FileTextOutlined, setTwoToneColor } from "@ant-design/icons";
import { Button } from "antd";
import { Box, Grid } from "@mui/material";
import { includes } from "lodash";
import { z } from "zod";

import useLoading from "hooks/useLoading";
import { useSmartForm } from "hooks/useSmartForm";
import { useSectionOptions } from "hooks/useSectionOptions";
import useRouteMatcher from "hooks/useRouteMatcher";
import SessionClient from "util/SessionClient";

import ProviderLead from "models/ProviderLead";
import Project from "models/Project";
import Lead from "models/Lead";
import actions from "rdx/actions";

import { useUniqueId } from "hooks/useUniqueId";
import { RadioOptionsT } from "components/mui-styled-components/FormRadioGroup";
import DateRangeSelector from "components/mui-styled-components/DateRangeSelector";
import { CalendarIcon } from "components/Icons";
import FileUploaderDragAndDrop from "components/FileUploader/FileUploaderDragAndDrop";

import SmartForm from "components/SmartForm";
import {
  SmartFormTextarea,
  SmartFormDropdown,
  SmartFormTextField,
  SmartFormCheckbox,
  SmartFormRadioGroup,
} from "components/mui-styled-components/SmartFormElements";
import { AlertModal } from "components/Modals/AlertModal/AlertModal";
import GradientButton from "components/Buttons/GradientButton";
import { StyledGradientButtonBackground } from "components/Drawers/RoofingProposal/styles";
import FastFill from "components/mui-styled-components/SmartFormElements/FastFill";
import FileUploaderSimple from "components/FileUploader/FileUploaderSimple/FileUploaderSimple";
import { BasicCard } from "components/mui-styled-components";
import DetailPageContainer from "components/DetailPageContainer";
import ActionsDropdown from "components/FormElements/ProjectActionsDropdown";
import DesignSurveyContent from "components/mui-styled-components/Modals/DesignTeamSurvey/Content";
import type { KeepStateOptions } from "react-hook-form";
import Spacer from "components/Spacer";
import { isNull, isUndefined } from "types/guards";
import { stringToBool, boolToString } from "lib/helpers";
import AddOnCompatibilityDisclaimer from "./AddOnCompatibilityDisclaimer";
import { fastFillData } from "./fastFillData";
import { DateRangeInputsWrapper, DateRangeInputFrom, DateRangeInputTo } from "./styles";

import * as dropdownOptions from "./dropdownOptions";
import * as S from "./styles";

import styles from "../SiteInfo.module.less";

setTwoToneColor("var(--royal-peacock-blue)");
const darkBlueColor = cvar("nepal-blue");

const otherDetailsValues = [
  "install_on_patio_trellis_or_gazebo_or_similar",
  "install_on_mobile_modular_or_manufactured_home",
];

const schema = z.object({
  utilityBillUrl: z.string().optional(),
  kwhConsumption: z.string().regex(/^\d*$/, { message: "Please enter KWH Consumption in numbers only" }).optional(),
  utilCompany: z.string({ required_error: "Please select a utility company" }),
  roofAge: z.string().optional(),
  roofType: z.string().optional(),
  servicePanel: z.string().optional(),
  mounting: z.string(),
  otherDetails: z
    .object({
      install_on_patio_trellis_or_gazebo_or_similar: z.boolean().optional(),
      install_on_mobile_modular_or_manufactured_home: z.boolean().optional(),
      other_non_solar_construction_in_progress: z.boolean().optional(),
    })
    .optional(),
  hoaName: z.string().optional(),
  hoaPhone: z.union([
    z.string().nullish(),
    z.string().regex(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, {
      message: "Please enter a valid phone number.",
    }),
  ]),
  financing: z.string().optional(),
  workNeeded: z.string().optional(),
  preferredPartnerName: z.string().optional(),
  notes: z.string().optional(), // proposal notes
  installationNotes: z.string().optional(),
  optimization: z.string().optional(),
  streetFacing: z.string().optional(),
  additional: z.string().optional(),
  generatorOnSite: z.string().optional(),
  generatorDetails: z.string().optional(),
  moduleQuantity: z.string().nullable(),
  moduleManufacturer: z.string().nullable(),
  moduleModel: z.string().nullable(),
  inverterQuantity: z.string().nullable(),
  inverterModel: z.string().nullable(),
  inverterManufacturer: z.string().nullable(),
  storageQuantity: z.string().nullable(),
  storageModel: z.string().nullable(),
  storageManufacturer: z.string().nullable(),
  comments: z.string().nullable(),
  hasExistingSystem: z.string().nullable(),
  constructionPlanUrl: z.string().optional(),
  constructionPlanFileSize: z.number().optional(),
  detailsOfConstruction: z.string().optional(),
  estimatedDateFrom: z.string().optional(),
  estimatedDateTo: z.string().optional(),
});

export type SiteInfoFormT = z.infer<typeof schema>;

type PreferredInstaller = {
  id: number;
  name: string;
  osPartnerId: number;
};

const options = [
  "utilityBillInformation",
  "installer",
  "roofInformation",
  "generatorOnSite",
  "existingSystem",
  "constructionDetails",
  "hoaInformation",
  "designTeam",
  "financing",
  "workNeeded",
  "preferredPartner",
  "locationInfo",
  "installerNotes",
  "detailsOfConstruction",
] as const;

export const hasExistingSystemOptions: RadioOptionsT = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

const SiteInfoForm = (props) => {
  const { lead, project, providerLead, drawerWidth } = props;
  const { getActiveKeyProps, getSectionAttrs } = useSectionOptions<typeof options>({
    options,
  });
  const dispatch = useDispatch();
  const { isAdmin } = useRouteMatcher();

  const loading = useLoading({
    watchRequests: [
      projectActions.GET_PROVIDER_LEAD_DETAILS_REQUEST,
      adminProjectActions.GET_ADMIN_PROJECT_DETAILS_REQUEST,
    ],
  });

  const location = useLocation();
  const viewingEnterprise = location.pathname.includes("/org-projects");

  const session = new SessionClient();
  const { isSeatedSeller } = session;

  const details = providerLead?.props || project?.props;
  const [isLegacyCustomUtilityValue, setIsLegacyCustomUtilityValue] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [blockedLocation, setBlockedLocation] = useState<string | null>(null);
  const [utilityBillDownload, setUtilityBillDownload] = useState(details.lead && details.lead.utilityBillUrl);

  const utilButtonTextId = useUniqueId("util-button-text-id-");

  const { methods, getSmartFormProps } = useSmartForm<SiteInfoFormT>({
    schema,
    useFormProps: {
      defaultValues: {
        workNeeded: "",
        installationNotes: "",
        otherDetails: {
          install_on_patio_trellis_or_gazebo_or_similar: false,
          install_on_mobile_modular_or_manufactured_home: false,
          other_non_solar_construction_in_progress: false,
        },
        utilityBillUrl: "",
        kwhConsumption: "",
        utilCompany: "",
        roofAge: "",
        roofType: "",
        servicePanel: "",
        mounting: "roof",
        hoaName: undefined,
        hoaPhone: undefined,
        financing: "",
        preferredPartnerName: "",
        notes: "", // location info
        optimization: "",
        streetFacing: "",
        additional: "",
        generatorOnSite: "",
        generatorDetails: "",
        moduleQuantity: null,
        moduleManufacturer: null,
        moduleModel: null,
        inverterQuantity: null,
        inverterModel: null,
        inverterManufacturer: null,
        storageQuantity: null,
        storageModel: null,
        storageManufacturer: null,
        comments: null,
        hasExistingSystem: null,
        constructionPlanUrl: "",
        constructionPlanFileSize: 0,
        detailsOfConstruction: "",
        estimatedDateFrom: "",
        estimatedDateTo: "",
      },
    },
  });

  const {
    watch,
    reset,
    setValue,
    formState: { errors, isDirty, isSubmitSuccessful },
    handleSubmit,
  } = methods;

  const values = watch();
  const history = useHistory();

  const copyToClipboard = (url) => {
    copy(url);
    dispatch(
      actions.setAlertMessageVisible({
        message: "Link Copied to Clipboard",
        severity: "success",
      }),
    );
  };

  const preferredInstallers: PreferredInstaller[] = useMemo(
    () => details.preferredInstallers || [],
    [details.preferredInstallers],
  );
  const utilities = useMemo(() => details.utilities || [], [details]);
  const disableInstallerOptions = preferredInstallers.length === 0;

  const preferredInstallerChoices = useMemo(
    () => [
      { value: null, label: "Allow Powur to Choose", key: 0 },
      ...preferredInstallers.map((p) => ({
        value: p.osPartnerId,
        label: p.name,
        key: p.id,
      })),
    ],
    [preferredInstallers],
  );

  const utilityChoices = useMemo(
    () =>
      utilities.map((u) => ({
        value: u.external_name || u.externalName,
        label: u.display_name || u.displayName,
        key: u.id,
      })),
    [utilities],
  );

  const includedUtility = includes(
    utilityChoices?.map((u) => u.value),
    details.utilityCompany,
  );
  const [preferredInstallerId, setPreferredInstallerId] = useState(details?.preferredInstallerId);
  const [selectedInstallerName, setSelectedInstallerName] = useState("");

  const handleSelectPreferredChange = (installationPartner: PreferredInstaller | undefined): void => {
    if (installationPartner === undefined) {
      setValue("preferredPartnerName", preferredInstallerChoices[0].label, { shouldDirty: true });
      setPreferredInstallerId(null);
      return;
    }

    setValue("preferredPartnerName", installationPartner.name, { shouldDirty: true });
    setPreferredInstallerId(installationPartner.osPartnerId);
  };

  const resetFormDetails = (resetOptions: KeepStateOptions = {}) => {
    reset(
      {
        installationNotes: details?.installationNotes || undefined,
        otherDetails: {
          install_on_patio_trellis_or_gazebo_or_similar: details?.otherDetails.includes(
            "install_on_patio_trellis_or_gazebo_or_similar",
          ),
          install_on_mobile_modular_or_manufactured_home: details?.otherDetails.includes(
            "install_on_mobile_modular_or_manufactured_home",
          ),
          other_non_solar_construction_in_progress:
            Object.keys(details?.constructionDetails) && Object.keys(details?.constructionDetails).length > 2 && true,
        },
        utilityBillUrl: details?.lead?.utilityBillUrl || undefined,
        kwhConsumption: details.kwhConsumption?.toString() || undefined,
        utilCompany: defaultUtilityValue() || undefined,
        roofAge: details.roofAge || undefined,
        roofType: details.roofType || undefined,
        servicePanel: details.servicePanel || undefined,
        mounting: details.mounting || dropdownOptions.mountingPreference[0].value || undefined,
        hoaName: details.hoaName || undefined,
        hoaPhone: details.hoaPhone || "",
        financing: details.financing || undefined,
        workNeeded: details.workType || undefined,
        preferredPartnerName: defaultPreferredInstallerName || undefined,
        notes: details?.lead?.notes || undefined, // location info
        optimization: details.designAnswers?.optimization || undefined,
        streetFacing: details.designAnswers?.streetFacing || undefined,
        additional: details.designAnswers?.additional || undefined,
        generatorOnSite: details?.generatorOnSite?.toString() || undefined,
        generatorDetails: details?.generatorDetails || undefined,
        moduleQuantity: details?.existingSystem?.moduleQuantity || null,
        moduleManufacturer: details?.existingSystem?.moduleManufacturer || null,
        moduleModel: details?.existingSystem?.moduleModel || null,
        inverterQuantity: details?.existingSystem?.inverterQuantity || null,
        inverterModel: details?.existingSystem?.inverterModel || null,
        inverterManufacturer: details?.existingSystem?.inverterManufacturer || null,
        storageQuantity: details?.existingSystem?.storageQuantity || null,
        storageModel: details?.existingSystem?.storageModel || null,
        storageManufacturer: details?.existingSystem?.storageManufacturer || null,
        comments: details?.existingSystem?.comments || null,
        hasExistingSystem: !isUndefined(details?.existingSystem?.hasExistingSystem)
          ? boolToString(details?.existingSystem?.hasExistingSystem)
          : null,
        constructionPlanUrl: details?.constructionDetails?.constructionPlanUrl || undefined,
        constructionPlanFileSize: details?.constructionDetails?.constructionPlanFileSize || undefined,
        detailsOfConstruction: details?.constructionDetails?.detailsOfConstruction || undefined,
        estimatedDateFrom: details?.constructionDetails?.estimatedDateFrom || undefined,
        estimatedDateTo: details?.constructionDetails?.estimatedDateTo || undefined,
      },
      resetOptions,
    );
  };

  const handleUtilityBillUpload = (url) => {
    setValue("utilityBillUrl", url);
    if (providerLead) {
      providerLead.updateLeadInfo({
        lead: { ...lead.props },
        address: { ...lead.props.address },
        enterprise: viewingEnterprise,
        siteInfo: { utilityBillUrl: url },
      });
    }
    if (project) {
      project.updateLeadInfo({
        lead: { ...lead.props },
        address: { ...lead.props.address },
        enterprise: viewingEnterprise,
        siteInfo: { utilityBillUrl: url },
      });
    }
  };

  const defaultUtilityValue = () => {
    const givenCompanyName = details.utilityCompany || details.utilCompany;
    const approvedUtilCompanyName = utilities.find(
      (company) => company.externalName === givenCompanyName,
    )?.externalName;

    if (givenCompanyName || approvedUtilCompanyName) {
      setIsLegacyCustomUtilityValue(!approvedUtilCompanyName);
    }

    return approvedUtilCompanyName || givenCompanyName;
  };

  const defaultPreferredInstallerName = useMemo(() => {
    return details?.preferredInstallerId
      ? preferredInstallers.find((i) => i.osPartnerId === details?.preferredInstallerId)?.name
      : preferredInstallerChoices[0].label;
  }, [details.preferredInstallerId, preferredInstallerChoices, preferredInstallers]);

  useEffect(() => {
    const installerName =
      preferredInstallers.find((i) => i.osPartnerId === preferredInstallerId)?.name ||
      preferredInstallerChoices[0].label;

    setSelectedInstallerName(installerName);
  }, [preferredInstallerChoices, preferredInstallerId, preferredInstallers]);

  useEffect(() => {
    setPreferredInstallerId(details?.preferredInstallerId);
  }, [details?.preferredInstallerId]);

  useEffect(() => {
    setUtilityBillDownload(lead && lead.props.utilityBillUrl);
  }, [lead, includedUtility]);

  const californiaProject = () => {
    const leadState = details.lead?.address?.state || "";
    return leadState.toUpperCase().includes("CA");
  };

  const getInstallerDisplay = () => {
    const installerName = project?.props?.installer?.name;
    if (isAdmin && installerName) {
      return installerName;
    }
    return "Powur GC";
  };

  const existingSystemValues = {
    moduleQuantity: values.moduleQuantity,
    moduleManufacturer: values.moduleManufacturer,
    moduleModel: values.moduleModel,
    inverterQuantity: values.inverterQuantity,
    inverterModel: values.inverterModel,
    inverterManufacturer: values.inverterManufacturer,
    storageQuantity: values.storageQuantity,
    storageModel: values.storageModel,
    storageManufacturer: values.storageManufacturer,
    comments: values.comments,
    hasExistingSystem: values.hasExistingSystem,
  };

  const providerLeadValues = {
    installationNotes: values.installationNotes,
    kwhConsumption: values.kwhConsumption,
    generatorOnSite: values.generatorOnSite,
    generatorDetails: values.generatorOnSite === "true" ? values.generatorDetails : null,
    preferredInstallerId: disableInstallerOptions ? details?.preferredInstallerId : preferredInstallerId,
    designAnswers: {
      optimization: values.optimization || null,
      streetFacing: values.streetFacing || null,
      additional: values.additional || null,
    },
    constructionDetails:
      values?.otherDetails?.other_non_solar_construction_in_progress === true
        ? {
            detailsOfConstruction: values.detailsOfConstruction || null,
            estimatedDateFrom: values.estimatedDateFrom || null,
            estimatedDateTo: values.estimatedDateTo || null,
            constructionPlanUrl: values.constructionPlanUrl || null,
            constructionPlanFileSize: values.constructionPlanFileSize || null,
          }
        : {},
  };

  // Include only keys present in 'otherDetailsValues' and their corresponding value is truthy
  const otherDetails = Object.entries(values?.otherDetails || [])
    .filter(([key, value]) => otherDetailsValues.includes(key) && value)
    .map(([key]) => key);

  const siteInfoValues = {
    utilityBillUrl: values.utilityBillUrl || null,
    kwhConsumption: values.kwhConsumption || null,
    roofAge: values.roofAge || null,
    roofType: values.roofType || null,
    utilityCompany: values.utilCompany || null,
    workType: values.workNeeded || null,
    servicePanel: values.servicePanel || null,
    mounting: values.mounting || null,
    financing: values.financing || null,
    hoaName: values.hoaName || null,
    hoaPhone: values.hoaPhone,
    otherDetails,
  };
  const onSubmit = (): void => {
    if (providerLead) {
      providerLead.updateProviderLead({
        providerLeadUpdate: providerLeadValues,
        siteInfo: siteInfoValues,
        utilityBillUrl: values.utilityBillUrl,
        notes: values.notes,
        enterprise: viewingEnterprise,
        existingSystem: !isNull(values.hasExistingSystem) && existingSystemValues,
      });
    }
    if (project) {
      project.updateSiteInfo({
        projectUpdate: providerLeadValues,
        siteInfo: siteInfoValues,
        utilityBillUrl: values.utilityBillUrl,
        notes: values.notes,
        existingSystem: !isNull(values.hasExistingSystem) && existingSystemValues,
      });
    }
  };

  const onError = (errorArr) => {
    dispatch(
      actions.setAlertMessageVisible({
        message: `Invalid fields: ${Object.keys(errorArr).join(", ")}`,
        severity: "warning",
      }),
    );
  };

  useEffect(() => {
    if (details?.id) {
      resetFormDetails();
    }
  }, [details]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isSubmitSuccessful) {
      resetFormDetails({ keepValues: true });
    }
  }, [isSubmitSuccessful]); // eslint-disable-line react-hooks/exhaustive-deps

  const utilCompanyHelperText = useMemo(() => {
    const errorMessage = errors.utilCompany?.message;
    if (errorMessage) return errorMessage;
    return isLegacyCustomUtilityValue ? "User-Created Custom Utility" : "";
  }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

  const generatorOnSiteOptions: RadioOptionsT = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ];

  const handleSelection = (value) => {
    const estimatedDateFromValue = value.value[0].value;
    const estimatedDateToValue = value.value[1].value;
    setValue("estimatedDateFrom", estimatedDateFromValue);
    setValue("estimatedDateTo", estimatedDateToValue);
  };

  const postAsset = async ({ url, size }) => {
    setValue("constructionPlanUrl", url, { shouldDirty: true });
    setValue("constructionPlanFileSize", size);
  };

  const getFileNameFromUrl = (fileUrl) => {
    const segments = fileUrl.split("/");
    return segments[segments.length - 1];
  };

  const formatFileSize = (sizeInBytes) => {
    const sizeInMB = sizeInBytes / (1024 * 1024);
    return `${sizeInMB.toFixed(2)} MB`;
  };

  const removeAsset = () => {
    setValue("constructionPlanUrl", "", { shouldDirty: true });
    setValue("constructionPlanFileSize", 0);
  };

  const handleGoBack = () => {
    setCloseModal(false);
  };

  const handleLoseChanges = () => {
    setCloseModal(false);
    if (blockedLocation) {
      history.push(blockedLocation);
    }
    reset();
  };

  useEffect(() => {
    const unblock = history.block((newLocation) => {
      if (isDirty && !closeModal) {
        setBlockedLocation(newLocation?.pathname);
        setCloseModal(true);
        return false;
      }
      return undefined;
    });

    return () => unblock();
  }, [isDirty, closeModal, history]);

  return (
    <DetailPageContainer
      {...getActiveKeyProps()}
      onCancel={resetFormDetails}
      additionalNavItems={<ActionsDropdown />}
      onSave={handleSubmit(onSubmit, onError)}
      submitDisabled={!isDirty}
      formIsTouched={isDirty}
    >
      <FastFill setValue={setValue} fastFillData={fastFillData} />
      {!loading && (
        <div>
          <div {...getSectionAttrs("utilityBillInformation")} />
          <SmartForm
            formProps={{
              css: { width: "100%" },
            }}
            {...getSmartFormProps({})}
          >
            <BasicCard
              refElement={<div {...getSectionAttrs("utilityBillInformation")} />}
              cardStyleOverrides={S.card}
              headerProps={{ title: "Utility Bill Information" }}
              data-test-id="utility-bill-information-section"
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sx={{ marginBottom: "16px" }}>
                  <S.Label>Upload Utility Bill</S.Label>
                  <FileUploaderSimple
                    apiMode="lead_utility_bill"
                    onFinished={handleUtilityBillUpload}
                    fileUrl={watch("utilityBillUrl")}
                    orientation={drawerWidth < 525 ? "vertical" : "horizontal"}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "16px", display: "flex" }}>
                  <Grid item sx={{ width: "100px" }}>
                    {!!utilityBillDownload && (
                      <div className={styles.utilityBillDisplay}>
                        <div className={styles.label}>Utility Bill</div>
                        <div className={styles.utilityBill}>
                          <Button
                            className={styles.downloadButton}
                            type="primary"
                            onClick={() => {
                              window.open(utilityBillDownload, "_blank");
                            }}
                          >
                            <span id={utilButtonTextId}>Download</span>
                          </Button>
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid item sx={{ width: "calc(100% - 100px)" }}>
                    {!!utilityBillDownload && (
                      <div className={styles.utilityBillDisplay}>
                        <div className={styles.label}>URL</div>
                        <div className={styles.utilityBill}>
                          {(() => (
                            <>
                              <div className={styles.utilityBillFilename}>
                                <a href={decodeURI(utilityBillDownload)} className={styles.billLink}>
                                  {decodeURI(utilityBillDownload)}
                                </a>
                              </div>
                              <Button
                                className={styles.copyButton}
                                type="primary"
                                onClick={() => copyToClipboard(decodeURI(utilityBillDownload))}
                              >
                                Copy
                              </Button>
                            </>
                          ))()}
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item sx={{ marginBottom: "16px" }}>
                    <SmartFormTextField
                      name="kwhConsumption"
                      label="Annual Consumption (in kwh)"
                      error={!!errors.kwhConsumption}
                      helperText={errors.kwhConsumption?.message}
                      type="number"
                    />
                  </Grid>
                  <SmartFormDropdown
                    mainLabel="UTILITY COMPANY"
                    name="utilCompany"
                    dropdownOptions={utilityChoices}
                    error={!!errors.utilCompany}
                    helperText={utilCompanyHelperText}
                  />
                </Grid>
              </Grid>
            </BasicCard>
            <BasicCard
              refElement={<div {...getSectionAttrs("installer")} />}
              cardStyleOverrides={S.card}
              headerProps={{ title: "Installer" }}
              data-test-id="installer-section"
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {getInstallerDisplay()}
                </Grid>
              </Grid>
            </BasicCard>
            <BasicCard
              refElement={<div {...getSectionAttrs("roofInformation")} />}
              cardStyleOverrides={S.card}
              headerProps={{ title: "Roof Information" }}
              data-test-id="roof-information-section"
            >
              <Grid container spacing={1}>
                <Grid item xs={6} sx={{ marginBottom: "16px" }}>
                  <SmartFormDropdown
                    mainLabel="Roof Age"
                    name="roofAge"
                    dropdownOptions={dropdownOptions.roofAge}
                    error={!!errors.roofAge}
                    helperText={errors.roofAge?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SmartFormDropdown
                    mainLabel="Roof Type"
                    name="roofType"
                    dropdownOptions={dropdownOptions.roofTypes}
                    error={!!errors.roofType}
                    helperText={errors.roofType?.message}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <SmartFormDropdown
                    mainLabel="Main Service Panel"
                    name="servicePanel"
                    dropdownOptions={dropdownOptions.servicePanels}
                    error={!!errors.servicePanel}
                    helperText={errors.servicePanel?.message}
                  />
                </Grid>
              </Grid>
            </BasicCard>
            <BasicCard
              data-test-id="generator-on-site"
              cardStyleOverrides={S.card}
              headerProps={{ title: "Generator on Site" }}
              refElement={<div {...getSectionAttrs("generatorOnSite")} />}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SmartFormRadioGroup name="generatorOnSite" radioOptions={generatorOnSiteOptions} error={false} row />
                  {values.generatorOnSite === "true" && (
                    <>
                      <S.Text>Please provide additional details below. (Ex: plug-in? part number? type? size?)</S.Text>
                      <SmartFormTextarea name="generatorDetails" customHeight="140px" />
                    </>
                  )}
                </Grid>
              </Grid>
            </BasicCard>
            <BasicCard
              data-test-id="existing-section"
              cardStyleOverrides={S.card}
              headerProps={{ title: "Existing System" }}
              refElement={<div {...getSectionAttrs("existingSystem")} />}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SmartFormRadioGroup name="hasExistingSystem" radioOptions={hasExistingSystemOptions} row />
                  {stringToBool(values?.hasExistingSystem) && (
                    <>
                      <S.Heading>Module</S.Heading>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <SmartFormTextField
                            name="moduleQuantity"
                            label="Module Quantity"
                            error={false}
                            placeholder="Enter Quantity"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <SmartFormTextField
                            name="moduleModel"
                            label="Module Model"
                            error={false}
                            placeholder="Enter Model"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} sx={{ marginTop: "16px" }}>
                        <Grid item xs={12} md={6}>
                          <SmartFormTextField
                            name="moduleManufacturer"
                            label="Module Manufacturer"
                            error={false}
                            placeholder="Enter Manufacturer"
                          />
                        </Grid>
                      </Grid>
                      <S.Heading>Inverter</S.Heading>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <SmartFormTextField
                            name="inverterQuantity"
                            label="Inverter Quantity"
                            error={false}
                            placeholder="Enter Quantity"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <SmartFormTextField
                            name="inverterModel"
                            label="Inverter Model"
                            error={false}
                            placeholder="Enter Model"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} sx={{ marginTop: "16px" }}>
                        <Grid item xs={12} md={6}>
                          <SmartFormTextField
                            name="inverterManufacturer"
                            label="Inverter Manufacturer"
                            error={false}
                            placeholder="Enter Manufacturer"
                          />
                        </Grid>
                      </Grid>
                      <S.Heading>Storage</S.Heading>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <SmartFormTextField
                            name="storageQuantity"
                            label="Storage Quantity"
                            error={false}
                            placeholder="Enter Quantity"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <SmartFormTextField
                            name="storageModel"
                            label="Storage Model"
                            error={false}
                            placeholder="Enter Model"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} sx={{ marginTop: "16px" }}>
                        <Grid item xs={12} md={6}>
                          <SmartFormTextField
                            name="storageManufacturer"
                            label="Storage Manufacturer"
                            error={false}
                            placeholder="Enter Manufacturer"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} sx={{ marginTop: "16px" }}>
                        <Grid item xs={12}>
                          <SmartFormTextarea
                            subLabel="Comments on Existing System:"
                            name="comments"
                            subLabelStyle={{
                              fontWeight: 600,
                              fontSize: "12px",
                              lineHeight: "9px",
                              color: `${cvar("nepal-blue")}`,
                              textTransform: "uppercase",
                              marginBottom: "8px",
                            }}
                            error={!!errors.comments}
                            helperText={errors.comments?.message}
                          />
                        </Grid>
                      </Grid>
                      <Spacer axis="vertical" size={19} />
                      <Grid item xs={12} md={11}>
                        <AddOnCompatibilityDisclaimer />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </BasicCard>
            <BasicCard
              refElement={<div {...getSectionAttrs("constructionDetails")} />}
              cardStyleOverrides={S.card}
              headerProps={{ title: "Construction Details" }}
              data-test-id="construction-details-section"
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <SmartFormDropdown
                    mainLabel="MOUNTING PREFERENCE"
                    name="mounting"
                    dropdownOptions={dropdownOptions.mountingPreference}
                    error={!!errors.mounting}
                    helperText={errors.mounting?.message}
                  />
                </Grid>
              </Grid>
              <div
                css={css`
                  margin-top: 22px;
                `}
              >
                <SmartFormCheckbox
                  name="otherDetails"
                  mainLabel="Other Construction Details"
                  checkboxOptions={dropdownOptions.otherDetails}
                  checkboxLabelStyles={{
                    color: cvar("black"),
                    fontSize: "14px",
                  }}
                  groupContainerStyles={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "7px",
                  }}
                  checkboxStyles={{
                    padding: "2px",
                  }}
                  mainLabelStyle={{
                    marginBottom: "8px",
                  }}
                />

                {values?.otherDetails?.other_non_solar_construction_in_progress && (
                  <Grid marginTop={1} container spacing={2}>
                    <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                      <S.Label>construction plans required</S.Label>
                      {!values?.constructionPlanUrl && (
                        <Grid item xs={12} md={6}>
                          <FileUploaderDragAndDrop apiMode="lead_utility_bill" onUploadSubmit={postAsset} />
                        </Grid>
                      )}
                      {values?.constructionPlanUrl && (
                        <Grid item xs={12} md={7.5}>
                          <S.FileUploader>
                            <S.DisplayFlex>
                              <FileTextOutlined
                                style={{ color: darkBlueColor, fontSize: "26px", marginRight: "16px" }}
                              />
                              <S.DisplayColumn>
                                <S.UploadingFileText>
                                  {getFileNameFromUrl(values?.constructionPlanUrl)}{" "}
                                </S.UploadingFileText>
                                <S.Label>
                                  {values?.constructionPlanFileSize &&
                                    `${formatFileSize(values?.constructionPlanFileSize)}`}
                                </S.Label>
                              </S.DisplayColumn>
                            </S.DisplayFlex>
                            <S.ButtonText onClick={removeAsset}>Remove</S.ButtonText>
                          </S.FileUploader>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ marginBottom: "10px" }}>
                      <S.Label>Estimated completion date</S.Label>
                      <Grid item xs={12}>
                        <DateRangeSelector
                          handleAccept={(value) => {
                            if (value[0] && value[1]) {
                              const sendValue = {
                                id: "custom",
                                label: "Custom",
                                value: [
                                  { id: "from", value: value[0].format("YYYY-MM-DD").toString() },
                                  { id: "to", value: value[1].format("YYYY-MM-DD").toString() },
                                ],
                              };

                              handleSelection(sendValue);
                            }
                          }}
                          renderInput={
                            <Grid item xs={12}>
                              <DateRangeInputsWrapper>
                                <DateRangeInputFrom
                                  name="estimatedDateFrom"
                                  type="button"
                                  disableUnderline
                                  value={values.estimatedDateFrom || "Select Date"}
                                />
                                {values.estimatedDateFrom && (
                                  <Box sx={{ color: cvar("dark-blue"), fontWeight: 600, marginLeft: "-30px" }}>—</Box>
                                )}
                                <DateRangeInputTo
                                  name="estimatedDateTo"
                                  type="button"
                                  disableUnderline
                                  value={values.estimatedDateTo || ""}
                                />
                                <CalendarIcon fill={cvar("dark-blue")} />
                              </DateRangeInputsWrapper>
                            </Grid>
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <S.Label>Details of Type of Construction</S.Label>
                      <SmartFormTextarea name="detailsOfConstruction" customHeight="140px" />
                    </Grid>
                  </Grid>
                )}
              </div>
            </BasicCard>
            <BasicCard
              refElement={<div {...getSectionAttrs("hoaInformation")} />}
              cardStyleOverrides={S.card}
              headerProps={{ title: "HOA Information" }}
              data-test-id="hoa-information-section"
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <SmartFormTextField
                    name="hoaName"
                    label="HOA Name"
                    error={!!errors.hoaName}
                    helperText={errors.hoaName?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SmartFormTextField
                    name="hoaPhone"
                    label="Contact Phone"
                    error={!!errors.hoaPhone}
                    helperText={errors.hoaPhone?.message}
                  />
                </Grid>
              </Grid>
            </BasicCard>
            <BasicCard
              refElement={<div {...getSectionAttrs("designTeam")} />}
              cardStyleOverrides={S.card}
              headerProps={{ title: "Design Team Questions for Your Homeowner" }}
              data-test-id="design-team-questions-section"
            >
              <DesignSurveyContent />
            </BasicCard>
            <BasicCard
              refElement={<div {...getSectionAttrs("financing")} />}
              cardStyleOverrides={S.card}
              headerProps={{ title: "Financing" }}
              data-test-id="financing-section"
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <SmartFormDropdown
                    mainLabel="Financing"
                    name="financing"
                    dropdownOptions={dropdownOptions.financing}
                    error={!!errors.financing}
                    helperText={errors.financing?.message}
                  />
                </Grid>
              </Grid>
            </BasicCard>
            {californiaProject() && (
              <BasicCard
                refElement={<div {...getSectionAttrs("workNeeded")} />}
                cardStyleOverrides={S.card}
                headerProps={{ title: "Work Needed" }}
                data-test-id="work-needed-section"
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <SmartFormDropdown
                      mainLabel="Work"
                      name="workNeeded"
                      dropdownOptions={dropdownOptions.workNeeded}
                      error={!!errors.workNeeded}
                      helperText={errors.workNeeded?.message}
                    />
                  </Grid>
                </Grid>
              </BasicCard>
            )}
            {(viewingEnterprise || isSeatedSeller) && (
              <BasicCard
                refElement={<div {...getSectionAttrs("preferredPartner")} />}
                cardStyleOverrides={S.card}
                headerProps={{ title: "Preferred Partner" }}
                data-test-id="preferred-partner-section"
              >
                <SmartFormDropdown
                  mainLabel="Solar Installation Partner"
                  name="preferredPartnerName"
                  disabled={disableInstallerOptions}
                  renderValue={() => <>{selectedInstallerName}</>}
                  dropdownOptions={preferredInstallerChoices}
                  error={!!errors.preferredPartnerName}
                  helperText={errors.preferredPartnerName?.message}
                  onChange={(e) => {
                    const selectedPartnerId = e.target.value;
                    const selectedPartner = preferredInstallers.find(
                      (option) => option.osPartnerId === selectedPartnerId,
                    );
                    handleSelectPreferredChange(selectedPartner);
                  }}
                />
              </BasicCard>
            )}
            <BasicCard
              refElement={<div {...getSectionAttrs("locationInfo")} />}
              cardStyleOverrides={S.card}
              headerProps={{ title: "Location Info" }}
              data-test-id="location-info-section"
            >
              <SmartFormTextarea
                name="notes"
                mainLabelStyle={{ textTransform: "uppercase" }}
                error={!!errors.notes}
                helperText={errors.notes?.message}
              />
            </BasicCard>
            <BasicCard
              refElement={<div {...getSectionAttrs("installerNotes")} />}
              cardStyleOverrides={S.card}
              headerProps={{ title: "Project Hand-Off Notes" }}
              data-test-id="project-handoff-notes-section"
            >
              <SmartFormTextarea
                name="installationNotes"
                mainLabelStyle={{ textTransform: "uppercase" }}
                error={!!errors.installationNotes}
                helperText={errors.installationNotes?.message}
              />
            </BasicCard>
          </SmartForm>
        </div>
      )}
      <AlertModal
        visible={closeModal}
        title="Unsaved Changes"
        footer={
          <Grid spacing={2} container justifyContent="center">
            <Grid item xs={4}>
              <GradientButton style={{ width: "100%" }} onClick={handleGoBack}>
                <StyledGradientButtonBackground>Go Back</StyledGradientButtonBackground>
              </GradientButton>
            </Grid>
            <Grid item xs={4}>
              <GradientButton onClick={handleLoseChanges}>Yes, Lose Changes</GradientButton>
            </Grid>
          </Grid>
        }
      >
        <Box p={8}>You have unsaved changes, are you sure you want to proceed?</Box>
      </AlertModal>
    </DetailPageContainer>
  );
};

SiteInfoForm.propTypes = {
  lead: PropTypes.instanceOf(Lead),
  providerLead: PropTypes.instanceOf(ProviderLead),
  project: PropTypes.instanceOf(Project),
  drawerWidth: PropTypes.number,
};

export default SiteInfoForm;
