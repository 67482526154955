import React from "react";
import { Button, ButtonProps } from "antd";
import styles from "./GradientGhostButton.module.less";

// This component can be used just like an Antd Button, and Antd Button props can be passed directly to it
interface GradientGhostButtonPropsI extends ButtonProps {
  className?: string;
  children?: string | JSX.Element;
}

const GradientGhostButton = ({ className, children, ...rest }: GradientGhostButtonPropsI) => {
  return (
    <Button className={[className, styles.gradient].join(" ")} {...rest}>
      {children}
    </Button>
  );
};

export default GradientGhostButton;
