import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/projects/messages";
import { PROJECT_BLOCKER_TAGS } from "../../projectBlockers/tags";
import { projectBlockersApi } from "../../projectBlockers/endpoints";

function* sendSalesDesignConfirmation(action) {
  const {
    projectId,
    designApproved,
    designChangeNotes,
    contractPrice,
    acceptanceOption,
    huddleRequested,
    revisionOptionId,
    revisionAttachmentLink,
  } = action.payload;

  const params = {
    sales_design_confirmation: {
      design_approved: designApproved,
      design_change_notes: designChangeNotes,
      contract_price: contractPrice,
      acceptance_option: acceptanceOption,
      huddle_requested: huddleRequested,
      os_revision_option_id: revisionOptionId,
      revision_attachment_link: revisionAttachmentLink,
    },
  };

  const { success, data, error } = yield* makeRequest.post(
    `/change_orders/${projectId}/sales_design_confirmation`,
    params,
  );

  if (success && data) {
    yield all([
      put(actions.setProjectChangeRequestVisible(false)),
      put(
        actions.newSuccessEvent({
          message: messages.SEND_SALES_DESIGN_CONFIRMATION_SUCCESS,
        }),
      ),
      put(projectBlockersApi.util.invalidateTags([PROJECT_BLOCKER_TAGS.PROJECT_BLOCKERS])),
      put(actions.removeChangeOrderBlockerFromModalForAdmin()),
      put(actions.removeChangeOrderBlockerFromModal()),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_SENDING_SALES_DESIGN_CONFIRMATION,
    });
  }

  return null;
}

export default sendSalesDesignConfirmation;
