import React from "react";
import PropTypes from "prop-types";

const BorderedCloseIcon = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="20" height="20" rx="4" stroke={fill} />
      <path
        d="M11.7419 11L15.4729 6.68083C15.5354 6.60905 15.4828 6.5 15.3862 6.5H14.252C14.1852 6.5 14.1212 6.52899 14.0771 6.57868L11 10.1414L7.92285 6.57868C7.88021 6.52899 7.81625 6.5 7.74803 6.5H6.61382C6.51717 6.5 6.46458 6.60905 6.52712 6.68083L10.2581 11L6.52712 15.3192C6.51311 15.3352 6.50412 15.3547 6.50122 15.3756C6.49832 15.3964 6.50164 15.4175 6.51076 15.4366C6.51989 15.4556 6.53446 15.4717 6.55272 15.483C6.57099 15.4942 6.59219 15.5001 6.61382 15.5H7.74803C7.81483 15.5 7.87879 15.471 7.92285 15.4213L11 11.8586L14.0771 15.4213C14.1198 15.471 14.1837 15.5 14.252 15.5H15.3862C15.4828 15.5 15.5354 15.391 15.4729 15.3192L11.7419 11Z"
        fill={fill}
      />
    </svg>
  );
};

BorderedCloseIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

BorderedCloseIcon.defaultProps = {
  fill: "white",
  height: "22",
  width: "22",
};

export default BorderedCloseIcon;
