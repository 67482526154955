import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";

function* getMarketingToken() {
  const { success, data, error } = yield* makeRequest.get("/breakthrough_broker", {});
  if (success && data) {
    yield put(actions.setMarketingToken(data.login_jwt));
  } else {
    return getErrorActions({
      error,
    });
  }

  return null;
}

export default getMarketingToken;
