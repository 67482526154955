import React from "react";
import { useTranslation } from "react-i18next";
import useWindowResize from "hooks/useWindowResize";
import IconBullet from "components/Icons/iconBullet";
import styles from "../../EnterpriseJoin.module.less";

const Features = () => {
  const { t } = useTranslation(["enterprise_join"]);
  const { width } = useWindowResize();

  return (
    <div
      className={styles.featuresContainer}
      style={{
        backgroundImage: `${
          width < 900
            ? "url(/img/enterprise/img_ent-features_mobile_bkgd-img.png)"
            : "url(/img/enterprise/img_entfeatures_bkgd.png)"
        }`,
      }}
    >
      <div className={styles.sectionTitleContainer}>
        <div className={styles.sectionTitle}> {t("enterprise_features.section_title")} </div>
        <div className={styles.line} />
      </div>
      <div className={styles.bulletsContainer}>
        <div className={styles.col}>
          {t("enterprise_features.features_left", { returnObjects: true }).map((str) => (
            <div key={str} className={styles.bullets}>
              <IconBullet />
              <span className={styles.text}>{str}</span>
            </div> // eslint-disable-line
          ))}
          {width < 900 ? (
            <>
              {t("enterprise_features.features_right", {
                returnObjects: true,
              }).map((str) => (
                <div key={str} className={styles.bullets}>
                  <IconBullet />
                  <span className={styles.text}>{str}</span>
                </div> // eslint-disable-line
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className={styles.col}>
          {width > 900 ? (
            <>
              {t("enterprise_features.features_right", {
                returnObjects: true,
              }).map((str) => (
                <div className={styles.bullets}>
                  <IconBullet />
                  <span className={styles.text}>{str}</span>
                </div> // eslint-disable-line
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Features;
