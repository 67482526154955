import types from "rdx/modules/reports/types";
import createAction from "rdx/utils/createAction";

export default {
  startUsersReport: (payload) => createAction(types.START_USERS_REPORT_REQUEST, payload),
  getUsersReport: (payload) => createAction(types.GET_USERS_REPORT_REQUEST, payload),
  setUsersReport: (payload) => createAction(types.SET_USERS_REPORT, payload),
  startUsersContestReport: (payload) => createAction(types.START_USERS_CONTEST_REPORT_REQUEST, payload),
  getUsersContestReport: (payload) => createAction(types.GET_USERS_CONTEST_REPORT_REQUEST, payload),
  setUsersContestReport: (payload) => createAction(types.SET_USERS_CONTEST_REPORT, payload),
  startUserProjectsReport: (payload) => createAction(types.START_USER_PROJECTS_REPORT_REQUEST, payload),
  getUserProjectsReport: (payload) => createAction(types.GET_USER_PROJECTS_REPORT_REQUEST, payload),
  setUserProjectsReport: (payload) => createAction(types.SET_USER_PROJECTS_REPORT, payload),
};
