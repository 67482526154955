import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getCustomFields(action) {
  const { enterpriseId } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/enterprises/${enterpriseId}/lead_custom_fields`, {});

  if (success && data) {
    yield put(actions.setCustomFields(camelizeKeys(data?.body?.custom_fields)));
  } else {
    return getErrorActions({
      error,
      message: messages.GET_CUSTOM_FIELDS_ERROR,
    });
  }
  return null;
}

export default getCustomFields;
