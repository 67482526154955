import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import useWindowResize from "hooks/useWindowResize";
import { PlayButtonIcon } from "components/Icons";

import JoinPowurTodayButton from "../JoinPowurTodayButton";

import * as S from "./styles";

type SetterJoinHeroImagePropsT = {
  handleCreateAccount: () => void;
};

const SetterJoinHeroImage = ({ handleCreateAccount }: SetterJoinHeroImagePropsT) => {
  const { t }: { t: (textRef: string) => string } = useTranslation(["setter_join"]);
  const { isTabletWidth } = useWindowResize();
  const buttonText = isTabletWidth ? t("join_today_button_text") : t("join_today_button_text_long");
  const videoUrl = "https://vimeo.com/1066657273/32930e9c39";
  const statsData = [
    { label: "$50-100", text: t("featured.appointment_held") },
    { label: "3,677", text: t("featured.active_sellers") },
    { label: "$225,308,424", text: t("featured.commissions_paid") },
    { label: "33,523", text: t("featured.installations") },
  ];

  return (
    <S.HeroImageContainer>
      <S.TopRow>
        <S.MaxWidthContainer>
          <Grid container spacing={3} alignItems="center" flexWrap="wrap-reverse">
            <Grid item xs={12} lg={6}>
              <S.TextWrapper container rowGap={{ xs: 5, md: 4 }} columnGap={{ md: 4 }}>
                <S.HeaderText item xs={12} md={9} xl={12}>
                  {t("featured.revolutionizing_home_energy")}
                </S.HeaderText>
                <S.SubheaderText item xs={12} lg={10}>
                  {t("featured.creating_opportunity")}
                </S.SubheaderText>
                <Grid item xs={12}>
                  <JoinPowurTodayButton onClick={handleCreateAccount} buttonText={buttonText} />
                </Grid>
              </S.TextWrapper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <S.VideoContainer>
                <S.Video
                  controls
                  light
                  width="100%"
                  height="100%"
                  playing
                  url={videoUrl}
                  playIcon={<PlayButtonIcon />}
                />
              </S.VideoContainer>
            </Grid>
          </Grid>
        </S.MaxWidthContainer>
      </S.TopRow>
      <S.GradientDivider />
      <S.StatsContainer>
        <S.MaxWidthContainer>
          <Grid container spacing={1}>
            {statsData.map((stat) => (
              <S.Stat item xs={12} sm={3} key={stat.text}>
                <S.StatLabel>{stat.label}</S.StatLabel>
                <S.StatText>{stat.text}</S.StatText>
              </S.Stat>
            ))}
          </Grid>
        </S.MaxWidthContainer>
      </S.StatsContainer>
      <S.GradientDivider />
    </S.HeroImageContainer>
  );
};

export default SetterJoinHeroImage;
