import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

export default {
  profile: createReducer(null, {
    [types.SET_PROFILE](state, action) {
      return action.payload;
    },
  }),
  // INSERTION_PT (for script, do not remove)
};
