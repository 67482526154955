import React from "react";

import * as S from "./styles";

const SelfDesignNotes = () => {
  const designTrainingLink = "https://vimeo.com/user/7907805/folder/12199890?isPrivate=false";
  return (
    <S.NoteContainer>
      <S.NoteSemiBoldFont>
        <S.BoldSpan>Fantastic!</S.BoldSpan> You are electing to perform the design on your own, without assistance from
        the proposal design team. We love that for you! After you create the proposal, you can access it from the
        Project Proposals tab.
      </S.NoteSemiBoldFont>
      <S.NoteSemiBoldFont>
        Sharpen your skills with{" "}
        <S.NoteBoldLink to={{ pathname: designTrainingLink }} target="_blank">
          Vision Design Training
        </S.NoteBoldLink>
      </S.NoteSemiBoldFont>
    </S.NoteContainer>
  );
};

export default SelfDesignNotes;
