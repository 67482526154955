import React from "react";
import { Row } from "antd";
import PropTypes from "prop-types";
import styles from "./SubHeader.module.less";

const SubHeader = (props) => {
  const {
    title,
    containerStyles,
    titleStyles,
    sectionID,
    color = "var(--darker-blue)",
    smallSize = false,
    pill = true,
  } = props;

  return (
    <div id={sectionID} className={styles.subHeaderContainer} style={{ ...containerStyles }}>
      <div
        style={{ backgroundColor: color, display: pill ? "block" : "none" }}
        className={smallSize ? styles.smallPill : styles.pill}
      />
      <Row style={{ color, ...titleStyles }} className={smallSize ? styles.smallTitle : styles.title}>
        <span style={{ ...titleStyles }}>{title}</span>
      </Row>
    </div>
  );
};

SubHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
  smallSize: PropTypes.bool,
  pill: PropTypes.bool,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  titleStyles: PropTypes.objectOf(PropTypes.string),
  sectionID: PropTypes.string,
};

export default SubHeader;
