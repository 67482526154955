import React from "react";
import PropTypes from "prop-types";

const ScriptIcon = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <rect x="2.21167" y="2" width="27.9401" height="28" rx="13.97" fill="var(--lightest-grey)" />
      </g>
      <path
        d="M21.5983 8.5H14.0983C12.9495 8.5 12.015 9.4345 12.015 10.5833V20.1667H9.09831C8.86814 20.1667 8.68164 20.3532 8.68164 20.5833V21.4167C8.68164 22.5655 9.61614 23.5 10.765 23.5H18.265C19.4138 23.5 20.3483 22.5655 20.3483 21.4167V12.6667H23.265C23.4951 12.6667 23.6816 12.4802 23.6816 12.25V10.5833C23.6816 9.4345 22.7471 8.5 21.5983 8.5ZM10.765 22.6667C10.0756 22.6667 9.51497 22.106 9.51497 21.4167V21H16.1816V21.4167C16.1816 21.886 16.3395 22.318 16.6023 22.6667H10.765ZM19.515 10.5833V21.4167C19.515 22.106 18.9543 22.6667 18.265 22.6667C17.5756 22.6667 17.015 22.106 17.015 21.4167V20.5833C17.015 20.3532 16.8285 20.1667 16.5983 20.1667H12.8483V10.5833C12.8483 9.894 13.409 9.33333 14.0983 9.33333H19.9356C19.6728 9.682 19.515 10.114 19.515 10.5833ZM22.8483 11.8333H20.3483V10.5833C20.3483 9.894 20.909 9.33333 21.5983 9.33333C22.2876 9.33333 22.8483 9.894 22.8483 10.5833V11.8333Z"
        fill={fill}
      />
      <path
        d="M13.6816 11.4167C13.6816 11.6468 13.8681 11.8333 14.0982 11.8333H18.2649C18.4951 11.8333 18.6816 11.6468 18.6816 11.4167C18.6816 11.1865 18.4951 11 18.2649 11H14.0982C13.8681 11 13.6816 11.1865 13.6816 11.4167Z"
        fill={fill}
      />
      <path
        d="M18.2649 12.6666H14.0982C13.8681 12.6666 13.6816 12.8531 13.6816 13.0833C13.6816 13.3135 13.8681 13.5 14.0982 13.5H18.2649C18.4951 13.5 18.6816 13.3135 18.6816 13.0833C18.6816 12.8531 18.4951 12.6666 18.2649 12.6666Z"
        fill={fill}
      />
      <path
        d="M18.2649 14.3334H14.0982C13.8681 14.3334 13.6816 14.5199 13.6816 14.75C13.6816 14.9802 13.8681 15.1667 14.0982 15.1667H18.2649C18.4951 15.1667 18.6816 14.9802 18.6816 14.75C18.6816 14.5199 18.4951 14.3334 18.2649 14.3334Z"
        fill={fill}
      />
      <path
        d="M18.2649 16H14.0982C13.8681 16 13.6816 16.1865 13.6816 16.4167C13.6816 16.6468 13.8681 16.8333 14.0982 16.8333H18.2649C18.4951 16.8333 18.6816 16.6468 18.6816 16.4167C18.6816 16.1865 18.4951 16 18.2649 16Z"
        fill={fill}
      />
      <path
        d="M18.2649 17.6666H14.0982C13.8681 17.6666 13.6816 17.8531 13.6816 18.0833C13.6816 18.3135 13.8681 18.5 14.0982 18.5H18.2649C18.4951 18.5 18.6816 18.3135 18.6816 18.0833C18.6816 17.8531 18.4951 17.6666 18.2649 17.6666Z"
        fill={fill}
      />
      <defs>
        <filter
          id="filter0_d"
          x="0.21167"
          y="0.5"
          width="31.9401"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.10 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

ScriptIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ScriptIcon.defaultProps = {
  fill: "var(--dark-blue)",
  height: "33",
  width: "33",
};

export default ScriptIcon;
