import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import { keysToCamelCase } from "lib/helpers";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../../messages";

function* getWorkflow(action) {
  const { workflowId } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/workflows/${workflowId}`, {});
  if (success && data) {
    const workflow = data.body.workflow.map((g) => keysToCamelCase(g));
    yield put(actions.setWorkflowDetails(workflow));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_WORKFLOW_DETAILS,
    });
  }
  return null;
}

export default getWorkflow;
