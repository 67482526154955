import React, { useState } from "react";
import { ListItem, Stack } from "@mui/material";
import { PrimaryButton } from "@mui-styled-components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "rdx/modules/users";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { PastDueHomeownerAppointment } from "rdx/modules/users/types";
import { isPastDueHomeownerAppointment } from "rdx/modules/users/types/guards";
import { actions } from "rdx";

import { isUndefined } from "types/guards";
import * as S from "./styles";

dayjs.extend(utc);
dayjs.extend(timezone);

const MENU_ITEMS = [
  { label: "Completed - Discovery Only", value: "completed" },
  { label: "Disqualified", value: "disqualified" },
  { label: "Homeowner Canceled", value: "homeowner_canceled" },
  { label: "Needs Follow Up", value: "follow_up" },
  { label: "Reschedule", value: undefined },
] as const;

type MenuItemValue = typeof MENU_ITEMS[number]["value"];

export default function CloserDispositionModal() {
  const pastDueAppointment: PastDueHomeownerAppointment | null = useSelector(selectors.getPastDueHomeownerAppointment);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [open, setOpen] = useState(Boolean(pastDueAppointment));

  if (!isPastDueHomeownerAppointment(pastDueAppointment)) {
    return null;
  }

  const details = [
    { primary: "Homeowner:", secondary: `${pastDueAppointment.homeowner} (${pastDueAppointment.projectId})` },
    { primary: "Time:", secondary: `${dayjs.utc(pastDueAppointment.due).local().format("h:mma, MMMM D, YYYY")}` },
    { primary: "Location:", secondary: `${pastDueAppointment.location}` },
    {
      primary: "Setter:",
      secondary: (
        <>
          {pastDueAppointment.setter} {pastDueAppointment.fastPay && <S.FastPay>- Fast Pay On</S.FastPay>}
        </>
      ),
    },
  ];

  const basePayload = {
    activityId: pastDueAppointment.id,
    leadId: pastDueAppointment.leadId,
  };

  const handleMenuSelect = (value: MenuItemValue) => {
    setAnchorEl(null);
    const payload = {
      ...basePayload,
      completeType: value,
      completedAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    dispatch(actions.updateCrmActivity(payload));
  };

  const handleNotYet = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRemindMe = () => {
    const payload = {
      ...basePayload,
      delay: true,
    };

    dispatch(actions.updateCrmActivity(payload));
  };

  const handleProposalPresented = () => {
    const payload = {
      ...basePayload,
      completeType: "proposal_presented",
      completedAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    dispatch(actions.updateCrmActivity(payload));
  };

  const handleReschedule = () => {
    dispatch(
      actions.setCrmActivityDrawerVisible({
        visible: true,
        activityType: "meeting",
        leadId: pastDueAppointment.leadId,
        activityId: pastDueAppointment.id,
      }),
    );
    setOpen(false);
  };

  return (
    <S.Modal open={open}>
      <S.Title>Did you present a proposal to this homeowner?</S.Title>
      <S.Content>
        <S.Info disablePadding>
          {details.map((item) => (
            <ListItem key={item.primary} disablePadding disableGutters>
              <S.Text primary={item.primary} secondary={item.secondary} />
            </ListItem>
          ))}
        </S.Info>
        <Stack useFlexGap sx={{ gap: "13px" }}>
          <S.ActionContainer alignSelf="center" useFlexGap>
            <PrimaryButton onClick={handleNotYet} sx={{ flex: 1 }} variant="outlined">
              No, Not Yet
            </PrimaryButton>
            <S.MenuContainer open={openMenu} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
              <S.MenuLabel>Choose Appointment Outcome</S.MenuLabel>
              <Stack>
                {MENU_ITEMS.map(({ label, value }) => {
                  if (isUndefined(value)) {
                    return (
                      <>
                        <S.Divide />
                        <S.MenuOption bold onClick={handleReschedule} key={label}>
                          {label}
                        </S.MenuOption>
                      </>
                    );
                  }

                  return (
                    <S.MenuOption onClick={() => handleMenuSelect(value)} key={label}>
                      {label}
                    </S.MenuOption>
                  );
                })}
              </Stack>
            </S.MenuContainer>
            <PrimaryButton onClick={handleProposalPresented} sx={{ flex: 1 }} gradient>
              Yes, Proposal Presented
            </PrimaryButton>
          </S.ActionContainer>
          <S.RemindMeLater onClick={handleRemindMe} variant="text">
            Remind Me Later
          </S.RemindMeLater>
        </Stack>
      </S.Content>
    </S.Modal>
  );
}
