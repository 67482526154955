import types from "rdx/modules/enterpriseApplications/types";
import createAction from "rdx/utils/createAction";

export default {
  getEnterpriseApplications: (payload) => createAction(types.GET_ENTERPRISE_APPLICATIONS, payload),

  setEnterpriseApplications: (payload) => createAction(types.SET_ENTERPRISE_APPLICATIONS, payload),

  getEnterpriseApplicationDetail: (payload) => createAction(types.GET_ENTERPRISE_APPLICATION_DETAIL, payload),

  setEnterpriseApplicationDetail: (payload) => createAction(types.SET_ENTERPRISE_APPLICATION_DETAIL, payload),

  approveEnterpriseApplication: (payload) => createAction(types.APPROVE_ENTERPRISE_APPLICATION, payload),

  rejectEnterpriseApplication: (payload) => createAction(types.REJECT_ENTERPRISE_APPLICATION, payload),

  makePendingEnterpriseApplication: (payload) => createAction(types.MAKE_PENDING_ENTERPRISE_APPLICATION, payload),

  createEnterpriseApplication: (payload) => createAction(types.CREATE_ENTERPRISE_APPLICATION, payload),

  updateEnterpriseApplication: (payload) => createAction(types.UPDATE_ENTERPRISE_APPLICATION, payload),

  createTierOneUserRegistration: (payload) => createAction(types.CREATE_ENTERPRISE_TIER_ONE_USER_REGISTRATION, payload),

  getUserLaborApplicationStatus: (payload) => createAction(types.GET_LABOR_APPLICATION_STATUS, payload),

  setLaborApplicationStatus: (payload) => createAction(types.SET_LABOR_APPLICATION_STATUS, payload),

  getEnterpriseApplicationByUser: (payload) => createAction(types.GET_ENTERPRISE_APPLICATION_BY_USER, payload),

  getEntApplicationsCsv: (payload) => createAction(types.GET_ENTERPRISE_APPLICATIONS_CSV, payload),

  setEnterpriseApplicationsCSV: (payload) => createAction(types.SET_ENTERPRISE_APPLICATIONS_CSV, payload),

  getEntApplicationsCsvReport: (payload) => createAction(types.GET_ENT_APPLICATIONS_CSV_REPORT, payload),
  // INSERTION_PT (for script, do not remove)
};
