import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { mq } from "styles";

export const FieldLabel = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 700,
  color: theme.colors["dark-periwinkle"],
  marginBottom: "6px",
  marginRight: "5px",
}));

export const LabelRow = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export const IconContainer = styled("div")(() => ({}));

export const FieldValue = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "20px",
  color: theme.colors["congress-blue"],
}));

export const FieldsContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
}));

export const CustomField = styled("div")(() => ({
  width: "calc(33% - 10px)",
  marginTop: "12px",
  paddingRight: "20px",
  wordBreak: "break-word",
  [mq.tabletDown]: {
    width: "50%",
    marginTop: "8px",
  },
}));
