export default {
  // success
  CREATE_NEW_AMBASSADOR_USER_SUCCESS: "New ambassador user created successfully",
  CREATE_NEW_AMBASSADOR_LEAD_SUCCESS: "New referral created successfully",
  GET_STRIPE_SIGNUP_URL_SUCCESS: "Get Stripe signup URL success",
  GET_STRIPE_LOGIN_URL_SUCCESS: "Get Stripe login URL success",

  // errors
  ERROR_GETTING_AMBASSADOR_REGISTRATION_INFO: "There was an error getting registration information",
  ERROR_GETTING_AMBASSADOR_APPLICATION_AGREEMENT: "There was an error getting application agreement",
  ERROR_CREATING_NEW_AMBASSADOR_USER: "There was an error creating new ambassador user",
  ERROR_CREATING_AMBASSADOR_LEAD: "There was an error creating new referral",
  ERROR_GETTING_AMBASSADOR_LEADS: "There was an error getting ambassador user's leads",
  ERROR_GETTING_STRIPE_SIGNUP_URL: "There was an error redirecting to Stripe signup",
  ERROR_GETTING_STRIPE_LOGIN_URL: "There was an error redirecting to Stripe login",
  ERROR_GETTING_STRIPE_ACCOUNT_INFO: "There was an error getting Stripe account information",
  ERROR_GETTING_ZIP: "There was an error retrieving this zip code.",
};
