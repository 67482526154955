import { put, all, select } from "redux-saga/effects";
import { keysToCamelCase } from "lib/helpers";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "../messages";

function* requestRevision(action) {
  const { id, reason, note, project_id, is_vision } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/proposals/${id}/revision`, {
    reason,
    note,
    project_id,
    is_vision,
  });

  if (success && data) {
    const proposalsList = yield select(selectors.getProjectProposals);
    const newProposal = keysToCamelCase(data.body);
    const out = proposalsList.goSoloProposals.map((p) => {
      if (p.id === newProposal.id) {
        return newProposal;
      }
      return p;
    });

    const lightreachProposals = proposalsList.lightreachProposals.map((proposal) => {
      if (proposal.id === newProposal.id) {
        return newProposal;
      }

      return proposal;
    });

    yield all([
      put(
        actions.setProjectProposals({
          goSoloProposals: out,
          lightreachProposals,
          visionProposal: proposalsList.visionProposal,
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.REVISION_REQUEST_SUBMIT_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_SUBMITTING_PROPOSAL_REVISION,
    });
  }
  return null;
}

export default requestRevision;
