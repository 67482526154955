import { put, select, all } from "redux-saga/effects";
import selectors from "rdx/modules/projects/selectors";
import makeRequest from "rdx/utils/makeRequest";
import { keysToCamelCase } from "lib/helpers";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* postNewNote(action) {
  const { leadId: project_id, body, tags, assignee, parentId: parent_id, fileUrl: file_url } = action.payload;
  const { success, data, error } = yield* makeRequest.post("/inbox", {
    project_id,
    body,
    tags,
    assignee,
    parent_id,
    file_url,
  });
  if (success && data) {
    const notes = yield select(selectors.getNotes);
    notes.push(data.body);
    yield all([
      put(
        actions.setNotes({
          notes: notes && notes.map((n) => keysToCamelCase(n)),
        }),
      ),
      put(actions.newSuccessEvent({ message: messages.POST_NEW_NOTE_SUCCESS })),
    ]);
  } else {
    return getErrorActions({ error, message: messages.ERROR_POSTING_NEW_NOTE });
  }
  return null;
}

export default postNewNote;
