import React from "react";
import { Button } from "antd";
import styles from "./styles.module.less";

interface SocialLoginButtonPropsI {
  provider: "facebook" | "google" | "";
  children: JSX.Element;
}

const SocialLoginButton = ({ provider = "", children }: SocialLoginButtonPropsI) => {
  return (
    <Button
      style={{
        backgroundColor: provider === "facebook" ? "#3E5A97" : "#DC4C3F",
      }}
      className={styles.socialButton}
      type="primary"
    >
      {children}
    </Button>
  );
};

export default SocialLoginButton;
