import React from "react";
import PropTypes from "prop-types";
import { cvar } from "styles";

const CopyToClipboardIcon = (props) => {
  const { height, width, disabled } = props;
  const fillColor = disabled ? cvar("botticelli-blue") : cvar("white");

  return (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4C0 2.89543 0.895431 2 2 2H10C11.1046 2 12 2.89543 12 4V12C12 13.1046 11.1046 14 10 14H2C0.895431 14 0 13.1046 0 12V4ZM5.24983 5.25C5.24983 5.11193 5.36176 5 5.49983 5H6.49983C6.6379 5 6.74983 5.11193 6.74983 5.25V7.24979H8.74959C8.88766 7.24979 8.99959 7.36172 8.99959 7.49979V8.49979C8.99959 8.63786 8.88766 8.74979 8.74959 8.74979H6.74983V10.7496C6.74983 10.8877 6.6379 10.9996 6.49983 10.9996H5.49983C5.36176 10.9996 5.24983 10.8877 5.24983 10.7496V8.74979H3.25C3.11193 8.74979 3 8.63786 3 8.49979V7.49979C3 7.36171 3.11193 7.24979 3.25 7.24979H5.24983V5.25Z"
        fill={fillColor}
      />
      <rect x="2.5" y="0.5" width="11" height="11" rx="1.5" stroke={fillColor} />
    </svg>
  );
};

CopyToClipboardIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool,
};

CopyToClipboardIcon.defaultProps = {
  height: "14",
  width: "14",
};

export default CopyToClipboardIcon;
