import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import messages from "rdx/modules/enrollment/messages";

import actions from "rdx/actions";
import { setEnrollment } from "../slice";

function* renewEnrollment(action) {
  const { payload } = action;

  const { success, data, error } = yield* makeRequest.post(`/services/${payload.service}/enrollment/renew`, {
    service: payload.service,
  });

  if (success && data) {
    yield all([
      put(setEnrollment(data.body.enrollment)),
      put(
        actions.newSuccessEvent({
          message: messages.ENROLLMENT_RENEW_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ENROLLMENT_RENEW_ERROR,
    });
  }
  return null;
}

export default renewEnrollment;
