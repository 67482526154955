import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../Team.module.less";

const HeroImage = () => {
  const { t } = useTranslation("team");
  return (
    <div className={styles.heroImageContainer}>
      <div className={styles.heroImage} />
      <div className={styles.titleOverlay}>
        <div className={styles.textContainer}>
          <span className={styles.mainText}>{t("banner.meet_the_team")}</span>
          <span className={styles.subText}>{t("banner.learn_what_drives_us")}</span>
        </div>
      </div>
    </div>
  );
};

export default HeroImage;
