import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getEnterpriseOrgSeat(action) {
  const { seatId } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/seats/${seatId}`);

  if (success && data) {
    yield put(
      actions.setEnterpriseOrgSeat({
        settings: camelizeKeys(data.body),
      }),
    );
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ENTERPRISE_SETTINGS,
    });
  }
  return null;
}

export default getEnterpriseOrgSeat;
