import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import { Row, Col } from "antd";
// import MessageEvent from "models/MessageEvent";
import SessionClient from "util/SessionClient";
import ModalContainer from "components/Modals/ModalContainer";
import Enrollment from "models/Enrollment";
import User from "models/User";
import styles from "./PowurLiveModal.module.less";

const PowurLiveModal = (props) => {
  const { title, modalOpen, setModalOpen, onClose, currentUser, setAlertMessageVisible } = props;

  const session = new SessionClient();
  const [paymentAccepted, setPaymentAccepted] = useState(false);
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);
  const [enrollmentLoading, setEnrollmentLoading] = useState(false);

  const enrollmentSubmission = async (tokenResponse, tos) => {
    const liveEnrollment = currentUser?.enrollments?.find((e) => e.service?.name === "Powur Live");

    if (liveEnrollment) {
      return new Enrollment(liveEnrollment).renew(liveEnrollment.service.slug);
    }
    return subscribeEnrollment(tokenResponse, tos);
  };

  const subscribeEnrollment = async () => {
    setEnrollmentLoading(true);
    await Enrollment.post({}, null, "powur_live").then(
      (enrollment) => {
        session.setUserProps({
          enrollments: [...session.user.props.enrollments, enrollment.props],
        });
        setEnrollmentLoading(false);
        setAlertMessageVisible({
          message: `You have been enrolled in ${enrollment.props.service.name}. Welcome!`,
          severity: "success",
        });
        setPaymentAccepted(true);
      },
      (res) => {
        console.warn(res?.error);
        setEnrollmentLoading(false);
        setAlertMessageVisible({
          message: `There was an error processing your enrollment request: ${res?.error?.message}`,
          severity: "error",
        });
      },
    );
  };

  const handleClose = () => {
    onClose();
    setPopconfirmVisible(false);
    setPaymentAccepted(false);
    setModalOpen(false);
  };

  const handleSubmit = () => {
    enrollmentSubmission();
  };

  const openPowurLiveInfo = () => {
    window.open("https://powur.live", "blank");
  };

  const confirmContent = (
    <div className={styles.popconfirm}>
      <Row className={styles.title}>Confirm Payment</Row>
      <Row className={styles.subtitle}>
        <span className={styles.bigBold}> Powur Live </span>
        <span>Membership Subscription</span>
      </Row>
      <Row className={styles.info}>
        Powur Live is a subscription service for <span className={styles.bold}>$35.00/month.</span>
      </Row>
      <Row className={styles.info}>Click “Confirm” below to register.</Row>
    </div>
  );

  const afterConfirmContent = (
    <div className={styles.popconfirm}>
      <Row className={styles.title}>Payment Accepted</Row>
      <Row className={styles.subtitle}>
        <span className={styles.bigBold}> Powur Live </span>
        <span>Membership Subscription</span>
      </Row>
      <Row className={styles.checkContainer}>
        <div className={styles.check} />
      </Row>
      <Row className={styles.info}>Check your email for login information.</Row>
    </div>
  );

  return (
    <ModalContainer
      visible={modalOpen}
      title={title || "Powur Live"}
      submitText="Become a Powur Live Member"
      zIndex={1003}
      handleCancel={handleClose}
      handleSubmit={handleSubmit}
      clearText="Learn More"
      handleClear={openPowurLiveInfo}
      withClearButton
      withoutCancel
      withPopconfirm
      popconfirmCancelDisabled={enrollmentLoading}
      popconfirmOkDisabled={enrollmentLoading}
      overridePopconfirmCancel={paymentAccepted}
      popconfirmCancelText="Close"
      confirmText="Confirm"
      confirmContent={paymentAccepted ? afterConfirmContent : confirmContent}
      popconfirmVisible={popconfirmVisible}
      setPopconfirmVisible={setPopconfirmVisible}
      headerClassName={styles.header}
      headingClassName={styles.heading}
      modalClassName={styles.modal}
      centerButtons
      popconfirmClassName={paymentAccepted ? styles.acceptedPopconfirmContainer : styles.popconfirmContainer}
    >
      <div className={styles.advertModal}>
        <div className={styles.backgroundOverlay} />
        <Row className={styles.mainText}>It’s Time to Reach Your Maximum Level of Success</Row>
        <Row className={styles.subText}>
          Get the Life, Business, and Mastery Training you need to reach your goals broadcasted directly to your home,
          from the new Powur Virtual Training Platform.
        </Row>
        <Row className={styles.row}>
          <Col span={5} className={styles.column}>
            Get advanced life, business, and mastery training to become wildly successful.
          </Col>
          <Col span={5} className={styles.column}>
            Learn from the Top-Performers and World-Class Leaders of our industry.
          </Col>
          <Col span={5} className={styles.column}>
            Stay up-to-date with the latest announcements, and product launches.
          </Col>
          <Col span={5} className={styles.column}>
            Create personal and business breakthroughs to exponentially grow your results.
          </Col>
        </Row>
      </div>
    </ModalContainer>
  );
};

PowurLiveModal.propTypes = {
  currentUser: User.types(),
  title: PropTypes.string,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  onClose: PropTypes.func,
  setAlertMessageVisible: PropTypes.func,
  // latestMessage: MessageEvent.types(),
};

PowurLiveModal.defaultProps = {
  onClose: () => null,
};

export default connect(
  getRdxSelectionMapper({
    activeRequests: "getActiveRequests",
    latestMessage: "getLatestMessageEvt",
    currentUser: "getCurrentUser",
  }),
  getRdxActionMapper(["setAlertMessageVisible"]),
)(PowurLiveModal);
