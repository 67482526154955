import { permissionTypes } from "hooks/usePermissions";

const { ENTERPRISE_ADMIN_PRO } = permissionTypes;

const commonTabs = [
  {
    key: "users",
    label: "Users",
  },
  {
    key: "invites",
    label: "Invites",
  },
];

const adminTabs = [
  {
    key: "seat-approvals",
    label: "Seat Approvals",
    permission: ENTERPRISE_ADMIN_PRO,
  },
];

export { commonTabs, adminTabs };
export default [...commonTabs, ...adminTabs];
