import React from "react";
import { cvar } from "styles";

type CheckboxUncheckedT = {
  width?: string | number;
  height?: string | number;
  fill?: string;
  outline?: string;
};

const CheckboxUnchecked = ({
  width = 17,
  height = 17,
  fill = cvar("white"),
  outline = cvar("nepal-blue"),
}: CheckboxUncheckedT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 3.48779C0.5 2.10708 1.61929 0.987793 3 0.987793H15C16.3807 0.987793 17.5 2.10708 17.5 3.48779V15.4878C17.5 16.8685 16.3807 17.9878 15 17.9878H3C1.61929 17.9878 0.5 16.8685 0.5 15.4878V3.48779Z"
        fill={fill}
        stroke={outline}
      />
    </svg>
  );
};

export default CheckboxUnchecked;
