import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  enterpriseProjectsList: {
    projects: [],
    paging: {
      page_size: 0,
      page_number: 1,
      item_count: 0,
    },
    kpi: [],
    filters: {},
    offer: {},
  },
  leadGenPreSalesProjects: {
    paging: {
      pageSize: 0,
      pageNumber: 1,
      itemCount: 0,
    },
    projects: [],
  },
  leadGenPostSalesProjects: {
    paging: {
      pageSize: 0,
      pageNumber: 1,
      itemCount: 0,
    },
    providerLeads: [],
  },
  enterpriseProjectDetails: {},
  enterpriseProjectsFilters: {
    filters: {},
    sorts: [],
  },
  mentors: {
    paging: {
      item_count: 0,
      page_number: 1,
      page_size: 50,
    },
    filters: {},
    users: [],
  },
  eventCounts: [],
  projectsListAutoComplete: {},
  offerLeadGeocode: {},
  notes: [],
  tags: [],
  assets: [],
  proposals: [],
  orgSellers: {
    paging: {
      itemCount: 0,
      pageNumber: 1,
      pageSize: 50,
    },
    users: [],
    filters: {},
    enterpriseUsersFilters: {},
    orgUsersExist: null,
  },
  offerLeadDrawerVisible: false,
  offerLeadConfirmation: {},
  currentOfferDetails: {},
  currentOfferResponse: {},
  contractsReport: {},
  projectLookupOptions: [],
};

export default {
  enterpriseProjects: createReducer(initialState, {
    [types.SET_ENTERPRISE_PROJECTS_LIST](state, action) {
      return {
        ...state,
        enterpriseProjectsList: {
          projects: action.payload.projects,
          paging: action.payload.paging,
          kpi: action.payload.kpi,
          filters: action.payload.filters,
          offer: action.payload.offer,
        },
      };
    },

    [types.RESET_ENTERPRISE_PROJECTS_LIST](state) {
      return {
        ...state,
        enterpriseProjectsList: initialState.enterpriseProjectsList,
      };
    },

    [types.SET_ENTERPRISE_PROJECT_DETAILS](state, action) {
      return {
        ...state,
        enterpriseProjectDetails: action.payload,
      };
    },

    [types.RESET_ENTERPRISE_PROJECT_DETAILS](state) {
      return {
        ...state,
        enterpriseProjectDetails: initialState.enterpriseProjectDetails,
      };
    },

    [types.SET_ENTERPRISE_PROJECTS_FILTERS](state, action) {
      return {
        ...state,
        enterpriseProjectsFilters: action.payload,
      };
    },

    [types.SET_ENTERPRISE_PROJECTS_AUTOCOMPLETE_SUGGESTIONS](state, action) {
      const { key, suggestions } = action.payload;

      if (key) {
        return {
          ...state,
          projectsListAutoComplete: {
            ...state.projectsListAutoComplete,
            [key]: suggestions || [],
          },
        };
      }
      return state;
    },

    [types.SET_ORG_CONSULTANTS](state, action) {
      const { paging, users, filters, enterpriseUsersFilters, orgUsersExist } = action.payload;

      return {
        ...state,
        orgSellers: {
          paging: paging || state.orgSellers.paging,
          users: users || state.orgSellers.users,
          filters: filters || state.orgSellers.filters,
          enterpriseUsersFilters: enterpriseUsersFilters || state.orgSellers.enterpriseUsersFilters,
          orgUsersExist: orgUsersExist || state.orgSellers.orgUsersExist,
        },
      };
    },

    [types.RESET_ORG_CONSULTANTS](state) {
      return {
        ...state,
        orgSellers: initialState.orgSellers,
      };
    },

    [types.SET_OFFER_LEAD_DRAWER_VISIBLE](state, action) {
      return {
        ...state,
        offerLeadDrawerVisible: action.payload,
      };
    },

    [types.SET_OFFER_LEAD_CONFIRMATION](state, action) {
      return {
        ...state,
        offerLeadConfirmation: action.payload,
      };
    },

    [types.RESET_OFFER_LEAD_CONFIRMATION](state) {
      return {
        ...state,
        offerLeadConfirmation: initialState.offerLeadConfirmation,
      };
    },

    [types.SET_ENTERPRISE_OFFER_LEAD_GEOCODE](state, action) {
      return {
        ...state,
        offerLeadGeocode: action.payload,
      };
    },

    [types.SET_ENTERPRISE_LEAD_OFFER_DETAILS](state, action) {
      return {
        ...state,
        currentOfferDetails: action.payload,
      };
    },

    [types.RESET_ENTERPRISE_LEAD_OFFER_DETAILS](state) {
      return {
        ...state,
        currentOfferDetails: {},
      };
    },

    [types.SET_LEAD_OFFER_RESPONSE_DETAILS](state, action) {
      return {
        ...state,
        currentOfferResponse: action.payload,
      };
    },

    [types.RESET_LEAD_OFFER_RESPONSE_DETAILS](state) {
      return {
        ...state,
        currentOfferResponse: {},
      };
    },

    [types.SET_CONTRACTS_REPORT](state, action) {
      return {
        ...state,
        contractsReport: action.payload,
      };
    },

    [types.SET_ENTERPRISE_PROJECT_LOOKUP_OPTIONS](state, action) {
      return {
        ...state,
        projectLookupOptions: action.payload,
      };
    },

    [types.SET_LEAD_GEN_PROJECTS_PRE_SALES](state, action) {
      return {
        ...state,
        leadGenPreSalesProjects: action.payload,
      };
    },

    [types.SET_LEAD_GEN_PROJECTS_POST_SALES](state, action) {
      return {
        ...state,
        leadGenPostSalesProjects: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
