import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/settings/selectors";
import messages from "rdx/modules/settings/messages";
import { camelizeKeys } from "lib/helpers";

function* updatePowurPairWeights(action) {
  const { success, data, error } = yield* makeRequest.patch("/powur_pair_weights", action.payload);

  if (success && data) {
    let out;
    if (data.body.powur_pair_weights) {
      out = camelizeKeys(data.body.powur_pair_weights);
    } else {
      out = yield select(selectors.getPowurPairWeights);
    }
    yield all([
      put(actions.setPowurPairWeights(out)),
      put(
        actions.newSuccessEvent({
          message: messages.POWUR_PAIR_WEIGHTS_UPDATE_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_POWUR_PAIR_WEIGHTS,
    });
  }

  return null;
}

export default updatePowurPairWeights;
