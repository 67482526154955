import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* setUserAsComped(action) {
  const { userId: user_id, serviceSlug } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/services/${serviceSlug}/enrollment/comp`, { user_id });
  if (success && data) {
    const { service } = data.body;
    yield put(
      actions.newSuccessEvent({
        message: messages.setUserAsCompedSuccess(service?.name),
      }),
    );
    if (service?.id === 3) {
      yield put(
        actions.setDetailInUserDetails({
          enrollment: keysToCamelCase(data.body.enrollment),
        }),
      );
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_SETTING_USER_AS_COMPED,
    });
  }

  return null;
}

export default setUserAsComped;
