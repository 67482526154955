export default {
  // EXAMPLE_ACTION_TYPE: "[compensation] Example Action Type",
  GET_PERIODS_REQUEST: "[compensation] GET Periods Request",
  SET_PERIODS: "[compensation] SET Periods",
  GET_PAYMENTS_REQUEST: "[compensation] GET Payments Request",
  SET_PAYMENTS: "[compensation] SET Payments",
  GET_LEDGER_REQUEST: "[compensation] GET Ledger Request",
  GET_LEDGER_CSV_REQUEST: "[compensation] GET Ledger Csv Request",
  GET_ENTERPRISE_LEDGER_CSV_REQUEST: "[compensation] GET Enterprise Ledger Csv Request",
  GET_EARNINGS_CSV_REQUEST: "[compensation] GET Earnings Csv Request",
  GET_LEDGER_CSV_REPORT_REQUEST: "[compensation] GET Ledger Csv Report Request",
  SET_LEDGER_CSV: "[compensation] SET Ledger Csv",
  SET_LEDGER: "[compensation] SET Ledger",
  ADD_PAYMENT_REQUEST: "[compensation] Add Payment Request",
  CLAWBACK_PAYMENT_REQUEST: "[compensation] Clawback Payment Request",
  DELETE_PAYMENT_REQUEST: "[compensation] Delete Payment Request",
  EDIT_PAYMENT_AMOUNT: "[compensation] Edit Payment Amount",
  RECALCULATE_PAYMENT_REQUEST: "[compensation] Recalculate Payment Request",
  DISTRIBUTE_PAYMENT_REQUEST: "[compensation] Distribute Payment Request",
  GET_USER_OR_LEAD_AUTO_COMPLETE: "[compensation] GET User Or Lead Auto Complete",
  SET_USER_OR_LEAD_AUTO_COMPLETE: "[compensation] SET User Or Lead Auto Complete",

  GET_DISTRIBUTIONS_REQUEST: "[compensation] GET Distributions Request",

  SET_DISTRIBUTIONS: "[compensation] SET Distributions",

  GET_PROJECTS_COMPENSATION_LIST: "[compensation] GET Projects Compensation List",

  SET_PROJECTS_COMPENSATION_LIST: "[compensation] SET Projects Compensation List",

  GET_PROJECT_REVENUE_SPLIT: "[compensation] GET Project Revenue Split",

  SET_PROJECT_REVENUE_SPLIT: "[compensation] SET Project Revenue Split",
  // INSERTION_PT (for script, do not remove)
};
