import { put, all, select } from "redux-saga/effects";
import { keysToCamelCase } from "lib/helpers";
import selectors from "rdx/modules/tickets/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/tickets/messages";

function* archiveMessages(action) {
  const { success, data, error } = yield* makeRequest.post("/messages/archive", {
    ...action.payload,
  });

  if (success && data) {
    if (data.body.messages) {
      const newSelectedMessage = {
        ...(yield select(selectors.getSelectedMessage)),
        archived: true,
        ...(action.payload.resolve && { resolved: true }),
      };

      const currentPMList = yield select(selectors.getProjectMessageList);
      const currentIBList = yield select(selectors.getInboxList);
      const updateDetails = (ticketList) => {
        ticketList.forEach((item) => {
          const out = item;
          if (action.payload.message_ids.includes(item.id)) {
            out.archived = true;
            if (action.payload.resolve) {
              out.resolved = true;
            }
          }
          return out;
        });
      };
      updateDetails(currentPMList);
      updateDetails(currentIBList);
      const IBOut = currentIBList.filter((t) => !action.payload.message_ids.includes(t.id));

      if (data.body.taggable_roles || data.body.taggable_users) {
        yield put(
          actions.setInboxTags({
            roleTags: data.body.taggable_roles?.map((r) => ({
              ...keysToCamelCase(r),
              type: "role",
              text: r.description,
            })),
            userTags: data.body.taggable_users?.map((r) => ({
              ...keysToCamelCase(r),
              type: "user",
              text: r.full_name,
            })),
          }),
        );
      }

      yield all([
        put(
          actions.setInboxList({
            paging: data.body.paging,
            list: IBOut,
          }),
        ),
        put(
          actions.setProjectMessageList({
            paging: data.body.paging,
            list: currentPMList,
          }),
        ),
        put(actions.setSelectedMessageDetails(newSelectedMessage)),
        put(
          actions.newSuccessEvent({
            message: messages.ARCHIVE_MESSAGES_SUCCESS,
          }),
        ),
      ]);
    }
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_ARCHIVING_MESSAGES,
    });
  }

  return null;
}

export default archiveMessages;
