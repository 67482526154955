import React from "react";
import PropTypes from "prop-types";
import { Popover, Row, Col, Avatar } from "antd";
import CopyEmail from "components/CopyContactInfo/CopyEmail";
// import { formatPhoneNumber } from "lib/helpers";
import { getBlackOrWhiteContrast } from "lib/helpers";
// import styles from "../styles.module.less";
import User from "models/User";
import styles from "../../UserCards/UserCards.module.less";

const TeamPopover = ({ title, user, children, team }) => {
  return (
    <Popover
      overlayClassName="personPopover"
      placement="bottomLeft"
      content={
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {team && (
              <Avatar
                size={44}
                shape="circle"
                className={styles.teamBadge}
                style={{
                  backgroundColor: `${team.color}`,
                  color: `${team.color ? getBlackOrWhiteContrast(team.color) : "red"}`,
                }}
                icon={team.shortName}
              />
            )}
          </Col>
          <Col>
            <div className={styles.title} style={{ color: "white" }}>
              {title.toUpperCase()}
            </div>
            <div className={styles.contentRow}>
              <span className={styles.bold} style={{ color: "white" }}>
                {user?.fullName ? user.fullName : team.label}
              </span>
              {user?.id ? <> (ID: {user.id})</> : ""}
            </div>

            <div className={styles.contentRow} style={{ color: "white" }}>
              {user?.email || team.email ? (
                <CopyEmail
                  iconProps={{
                    style: {
                      color: "white",
                    },
                  }}
                  emailProps={{
                    style: {
                      color: "white",
                    },
                  }}
                  tooltipTimeout={9000}
                  tooltipProps={{
                    placement: "bottom",
                    backgroundColor: "var(--dark-blue)",
                  }}
                  showText
                  email={user?.email || team.email}
                />
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      }
    >
      {children}
    </Popover>
  );
};

TeamPopover.propTypes = {
  user: User.types(),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  title: PropTypes.string.isRequired,
  // TODO: enumerate exact properties of team
  team: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default TeamPopover;
