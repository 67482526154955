import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

export default {
  publicApiProxy: createReducer(null, {
    [types.SET_APPCLIENT_TOKEN](state, action) {
      return {
        ...state,
        appClientToken: action.payload,
      };
    },
    [types.SET_GOOGLE_AUTHORIZE_URL](state, action) {
      return {
        ...state,
        googleAuthorizeUrl: action.payload,
      };
    },
    [types.SET_GOOGLE_ACCESS_STATUS](state, action) {
      return {
        ...state,
        googleAccessStatus: action.payload,
      };
    },
    [types.SET_GOOGLE_USER](state, action) {
      return {
        ...state,
        googleUser: action.payload,
      };
    },
    [types.SET_USER_EMAIL_INTEGRATION_TYPE_REQUEST](state, action) {
      return {
        ...state,
        emailIntegrationType: action.payload,
      };
    },
  }),
};
