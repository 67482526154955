import { faker } from "@faker-js/faker";
import type { SiteInfoFormT } from "./SiteInfoForm";

export const fastFillData: SiteInfoFormT = {
  installationNotes: "These are installation notes",
  otherDetails: [], // TODO: Complete
  utilityBillUrl:
    "https://powur-private.s3.us-west-1.amazonaws.com/leads/17wca5cudj2obyjtfp0x/utility_bills/Gabby.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIP4IQ77YPIRW5EWA%2F20230427%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230427T230724Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=521dd49fec73850dc1e77564256640c468a646bf990c325bd5d3ddba1a282862",
  kwhConsumption: "12317",
  utilCompany: "Southern California Edison",
  roofAge: "6 - 10",
  roofType: "standard_asphalt",
  servicePanel: "amp_100",
  mounting: "roof",
  hoaName: "Test HOA",
  hoaPhone: faker.phone.number("501-###-####"),
  financing: "cash",
  workNeeded: "solar",
  preferredInstallerId: undefined,
  notes: "Test notes", // proposal notes
  optimization: "1",
  streetFacing: "false",
  additional: "test additional details",
};
