// React
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Button } from "antd";
import { connect } from "react-redux";
import useWindowResize from "hooks/useWindowResize";
import { store } from "rdx/configureStore";
import UserAvatar from "containers/Sales/dashboard/components/Leaderboards/avatar";
import actions from "rdx/modules/users/actions";
import LogbackIcon from "components/Icons/logbackIcon";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";

import SessionClient from "util/SessionClient";

// Styles
import styles from "./ImpersonationBanner.module.less";

const ImpersonationBanner = (props) => {
  const { bypassPaywall, updateBypassPaywall, hideBypass } = props;
  const { width } = useWindowResize();
  const { logbackUser } = actions;
  const session = new SessionClient();
  const { impersonating } = session;
  const [redirect, setRedirect] = useState("");
  const [loggingOut, setLoggingOut] = useState(false);

  const translateEnrollmentstatus = (status) => {
    return status
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleBypassClick = () => {
    updateBypassPaywall();
    setTimeout(() => {
      setRedirect(<Redirect to="/dashboard" />);
    }, 300);
  };

  const handleLogbackClick = () => {
    setLoggingOut(true);
    store.dispatch(logbackUser({ userId: impersonating.id }));
  };

  const showText = () => {
    if (width > 800) {
      return "Show Account";
    }
    return "Account";
  };

  const logbackModal = () => {
    return (
      <div className={styles.logbackModal}>
        <LogbackIcon />
        <div className={styles.logbackText}>Logging Back</div>
        <div className={styles.logbackUser}>{`${impersonating.full_name} (${impersonating.id})`}</div>
      </div>
    );
  };

  const returnText = () => {
    if (width > 800) {
      return `Back to ${impersonating.full_name} (${impersonating.id})`;
    }
    return (
      <span className={styles.returnText}>
        <>Back</>
        <UserAvatar size={18} avatarUrls={impersonating.avatar_urls} />
      </span>
    );
  };

  return (
    <div className={styles.impersonateHeaderContainer}>
      {redirect}
      {loggingOut && logbackModal()}
      <p className={styles.observingTag}>
        {session.user.props.fullName} ({session.user.props.id}) - {translateEnrollmentstatus(session.enrollmentStatus)}
      </p>
      <div className={styles.impersonateButtonContainer}>
        {!(hideBypass || bypassPaywall || session.enrollmentStatus === "active") && (
          <Button
            disabled={loggingOut}
            role="button"
            onClick={handleBypassClick}
            onKeyDown={handleBypassClick}
            className={styles.bypassButton}
          >
            {showText()}
          </Button>
        )}
        <Button
          disabled={loggingOut}
          role="button"
          onClick={handleLogbackClick}
          onKeyDown={handleLogbackClick}
          className={styles.logbackButton}
        >
          {returnText()}
        </Button>
      </div>
    </div>
  );
};

ImpersonationBanner.propTypes = {
  bypassPaywall: PropTypes.bool,
  hideBypass: PropTypes.bool,
  updateBypassPaywall: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    bypassPaywall: "selectBypassPaywall",
  }),
  getRdxActionMapper(["updateBypassPaywall"]),
)(ImpersonationBanner);
