import React from "react";
import { cvar } from "styles";
import { IconT } from "types/utils";

const ProposalsPresentedGradient = ({
  height = "30",
  width = "30",
  stopColor = cvar("blue-ribbon"),
  startColor = cvar("picton-blue"),
}: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2165_14222)">
        <path
          d="M4.63382 27.1007V29.7926C4.63382 29.9073 4.72958 30.0002 4.84791 30.0002H13.7412C13.8593 30.0002 13.9552 29.9073 13.9552 29.7926V27.1007C13.9552 26.9859 13.8593 26.8931 13.7412 26.8931H4.84791C4.72958 26.8931 4.63382 26.9859 4.63382 27.1007ZM6.32859 27.4837C6.75113 27.4744 7.13729 27.714 7.30581 28.0901C7.47411 28.466 7.3909 28.9033 7.09548 29.1965C6.80005 29.4895 6.35096 29.5799 5.95957 29.4252C5.56818 29.2705 5.31227 28.9015 5.31227 28.4915C5.30663 27.9415 5.76116 27.4906 6.32859 27.4837Z"
          fill="url(#paint0_linear_2165_14222)"
        />
        <rect x="12.0512" y="1" width="13.822" height="19.5628" rx="2" fill="url(#paint1_linear_2165_14222)" />
        <path d="M21.9414 4.46045H23.7941" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M14.1301 4.46045H15.9828" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <circle cx="18.8408" cy="4.46113" r="1.68623" stroke="white" strokeWidth="0.75" />
        <path d="M14.9825 7.77539H22.8399" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M14.9825 9.46826H22.8399" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M14.9848 11.1611H22.8422" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M14.9848 12.855H22.8422" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M14.9848 14.5488H22.8422" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M14.9848 16.2397H22.8422" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M14.9848 17.9341H22.8422" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <path
          d="M6.70527 16.5005V25.8569H13.1714V24.7442C13.1714 24.5514 13.3124 24.3951 13.4866 24.3951C14.317 24.3939 14.9899 23.6487 14.991 22.7291V21.1841L13.7756 21.1907H13.7653C13.2301 21.1927 12.7148 20.9647 12.3279 20.5549C12.4823 21.3347 12.3583 22.1502 11.9821 22.8312V23.7366C11.9821 23.9294 11.8411 24.0857 11.6669 24.0857C11.4928 24.0857 11.3516 23.9294 11.3516 23.7366V22.7291C11.3516 22.66 11.3701 22.5927 11.4046 22.5355C11.8192 21.8427 11.8787 20.9613 11.5618 20.2081C11.4253 19.8942 11.3537 19.5506 11.3516 19.2024C11.3516 19.1956 11.3516 19.189 11.3521 19.1822L11.3765 18.716C11.381 18.6306 11.4136 18.5497 11.4684 18.4892L13.3986 16.3523C13.8078 15.8982 13.8078 15.163 13.3986 14.7089L13.2755 14.5723L9.12553 18.2115C9.03728 18.2894 8.91805 18.3092 8.81317 18.2633C8.7081 18.2171 8.63339 18.1126 8.61697 17.989C8.60076 17.8651 8.6455 17.7412 8.73416 17.6639L11.6648 15.0936L11.6486 11.3569L7.12352 15.3475C6.85426 15.6571 6.70424 16.0705 6.70527 16.5005Z"
          fill="url(#paint2_linear_2165_14222)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2165_14222"
          x1="6.26916"
          y1="25.5984"
          x2="13.896"
          y2="27.8839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2165_14222"
          x1="14.4761"
          y1="-7.15116"
          x2="26.7398"
          y2="-6.28564"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2165_14222"
          x1="8.1589"
          y1="5.31527"
          x2="15.5231"
          y2="5.73561"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <clipPath id="clip0_2165_14222">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProposalsPresentedGradient;
