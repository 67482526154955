import { put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { cloneDeep } from "lodash";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "rdx/modules/compensation/messages";

function* deletePayment(action) {
  const { id } = action.payload;

  const { success, data, error } = yield* makeRequest.delete("/compensation/delete_payment_item", {
    payment_item_id: id,
  });

  if (success && data) {
    const ledger = yield select(selectors.getLedger);
    const ledgerCopy = cloneDeep(ledger);

    const newPayments = ledgerCopy.payments
      .map((pmnt) => {
        // Remove the selected payment
        if (pmnt.payment.paymentItemId === id) return null;
        // If the payment was a clawback, set the original payments to 'paid'
        if (pmnt.payment.clawbackPaymentId === id) {
          return {
            ...pmnt,
            payment: { ...pmnt.payment, clawbackable: true, status: "paid" },
          };
        }
        return pmnt;
      })
      .filter((a) => a);

    yield put(actions.setLedger({ ...ledgerCopy, payments: newPayments }));
    yield put(
      actions.newSuccessEvent({
        message: messages.DELETE_PAYMENT_SUCCESS,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_PAYMENT,
    });
  }

  return null;
}

export default deletePayment;
