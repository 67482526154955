import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import SidebarFilters from "components/PlatformLayout/components/Sidebar/Filters";
import SidebarSearch from "components/PlatformLayout/components/Sidebar/Search";
import SidebarAutoComplete from "components/PlatformLayout/components/Sidebar/AutoComplete";

const ProposalsSidebar = (props) => {
  const {
    navigate,
    filters: { filters, sorts },
    search,
    suggestions,
    setSuggestions,
    resetSuggestions,
    tab,
    // loading,
  } = props;

  const selects = useMemo(() => {
    const out = _.filter(filters, (f) => _.isArray(f.values));
    if (sorts && !_.isEmpty(sorts)) {
      out.push({
        name: "sort",
        values: sorts,
        default: sorts[0], // DEFAULT SORT will always be the FIRST item returned from the backend.
      });
    }
    return out;
  }, [filters, sorts]);

  const lookups = useMemo(() => {
    const fields = _.filter(filters, (f) => _.isString(f.values) && f.values.includes("/json_web"));
    return fields.map((f) => (
      <ProposalsAutoComplete
        key={f.name}
        navigate={navigate}
        search={search}
        lookupField={f}
        suggestions={suggestions[f.name]}
        setSuggestions={setSuggestions}
        resetSuggestions={resetSuggestions}
        tab={tab}
      />
    ));
  }, [filters, suggestions, search]); // eslint-disable-line react-hooks/exhaustive-deps

  const filterKeys = useMemo(() => selects.map((s) => s.name), [selects]);
  const filterMenus = useMemo(() => {
    const label = (s, i) => {
      if (s.label) return s.label;
      return filterKeys[i].includes("_id") ? _.startCase(filterKeys[i].replace("_id", "")) : _.startCase(filterKeys[i]);
    };

    return selects.map((s, i) => {
      const out = {
        key: filterKeys[i],
        label: label(s, i),
        options: s.values.map((v) => ({
          key: _.isPlainObject(v) ? _.keys(v)[0] : v,
          label: _.isPlainObject(v) ? _.values(v)[0] : _.startCase(v),
          parentKey: filterKeys[i],
          default: s.default === v,
          index: i,
        })),
      };

      if (out.key === "generated_type") {
        out.options = out.options.map((o) => (o.key === "api" ? { ...o, label: "API" } : o));
      }

      if (!s.default) {
        out.options.unshift({
          key: `${filterKeys[i]}-any`,
          label: "All",
          parentKey: filterKeys[i],
          default: true,
          index: i,
        });
      }

      return out;
    });
  }, [selects, filterKeys]);

  return (
    <>
      <SidebarSearch navigate={navigate} label="Project ID Search" />
      <SidebarFilters menus={filterMenus} navigate={navigate} />
      {lookups}
    </>
  );
};

const ProposalsAutoComplete = ({
  navigate,
  lookupField,
  suggestions,
  resetSuggestions,
  setSuggestions,
  search,
  tab,
}) => {
  const { name, values, label } = lookupField;

  const users = useMemo(() => {
    if (suggestions) {
      return _.uniqBy(
        suggestions.map((u) => ({
          displayValue: u.fullName,
          key: u.id,
          data: u,
        })),
        "key",
      );
    }
    return [];
  }, [suggestions]);

  const generateLabel = () => {
    if (label) return label;
    return name.includes("_id") ? _.startCase(name.replace("_id", "")) : _.startCase(name);
  };

  const requestUrl = () => values.replace("/json_web", "").concat(`?tab=${tab}`);

  return (
    <SidebarAutoComplete
      key={name}
      fieldKey={name}
      dataKey="id"
      label={generateLabel()}
      requestUrl={requestUrl()}
      suggestions={users}
      successActions={[setSuggestions]}
      resetSuggestions={() => resetSuggestions({ key: name, suggestions: null })}
      navigate={navigate}
      usersDisplay
      search={search}
    />
  );
};

ProposalsSidebar.propTypes = {
  navigate: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    filters: PropTypes.shape({
      consultants: PropTypes.shape({
        name: PropTypes.string,
        // values objects are a single key-value pair consisting of
        // a consultant's id as key and name as value
        values: PropTypes.string,
      }),
      generatedType: PropTypes.shape({
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      }),
      version: PropTypes.shape({
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      }),
    }),
    sorts: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  search: PropTypes.string,
  tab: PropTypes.string,
  suggestions: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        fullName: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        vanityName: PropTypes.string,
        subscriptionStatus: PropTypes.string,
        locale: PropTypes.string,
        avatar: PropTypes.shape({
          large: PropTypes.string,
          thumb: PropTypes.string,
          retina: PropTypes.string,
          preview: PropTypes.string,
        }),
      }),
    ),
  ),
  setSuggestions: PropTypes.func.isRequired,
  resetSuggestions: PropTypes.func.isRequired,
  // loading: PropTypes.bool,
};

export default ProposalsSidebar;
