import types from "rdx/modules/consultantDashboard/types";
import createAction from "rdx/utils/createAction";

export default {
  getSellerDashboard: (payload) => createAction(types.GET_CONSULTANT_DASHBOARD, payload),
  setSellerDashboard: (payload) => createAction(types.SET_CONSULTANT_DASHBOARD, payload),
  getDashboardSlice: (payload) => createAction(types.GET_DASHBOARD_SLICE, payload),
  getProgressOverTime: (payload) => createAction(types.GET_PROGRESS_OVER_TIME, payload),
  getTeamActivity: (payload) => createAction(types.GET_TEAM_ACTIVITY, payload),
  getLeadOrigin: (payload) => createAction(types.GET_LEAD_ORIGIN, payload),
  getEarningsBreakdown: (payload) => createAction(types.GET_EARNINGS_BREAKDOWN, payload),
  getSalesLeaderboard: (payload) => createAction(types.GET_SALES_LEADERBOARD, payload),
  getReferralsLeaderboard: (payload) => createAction(types.GET_REFERRALS_LEADERBOARD, payload),
  getMentorsLeaderboard: (payload) => createAction(types.GET_MENTORS_LEADERBOARD, payload),
  getDashboardProjectActivity: (payload) => createAction(types.GET_DASHBOARD_PROJECT_ACTIVITY, payload),
  clearSellerDashboard: (payload) => createAction(types.CLEAR_CONSULTANT_DASHBOARD, payload),
  getMoreDashboardProjectActivity: (payload) => createAction(types.GET_MORE_DASHBOARD_PROJECT_ACTIVITY, payload),
  setMoreDashboardProjectActivity: (payload) => createAction(types.SET_MORE_DASHBOARD_PROJECT_ACTIVITY, payload),
  // INSERTION_PT (for script, do not remove)
};
