import React, { useState } from "react";
import Zoom from "@mui/material/Zoom";
import { useTranslation } from "react-i18next";
import { RightArrowLong } from "components/Icons";
import { theme } from "styles/themes";

import * as S from "./styles";

const SellerTestimonials = () => {
  const { t }: { t: (textRef: string) => string } = useTranslation(["setter_join"]);

  const [displayedTestimonialIdx, setDisplayedTestimonialIdx] = useState<number>(0);
  const [displayTestimonial, setDisplayTestimonial] = useState(true);

  const testimonialData = [
    {
      featuredSnippet: t("testimonials.joel_gosset.featured_snippet"),
      quote: t("testimonials.joel_gosset.quote"),
      name: "Joel Gossett",
      location: "Florida",
      imagePath: "/img/setterJoin/testimonialImages/testimonial_JoelGossett.png",
    },
    {
      featuredSnippet: t("testimonials.patrick_adu.featured_snippet"),
      quote: t("testimonials.patrick_adu.quote"),
      name: "Patrick Adu",
      location: "Virginia",
      imagePath: "/img/setterJoin/testimonialImages/testimonial_PatrickAdu.png",
    },
    {
      featuredSnippet: t("testimonials.jeffrey_martin.featured_snippet"),
      quote: t("testimonials.jeffrey_martin.quote"),
      name: "Jeffrey Martin",
      location: "Michigan",
      imagePath: "/img/setterJoin/testimonialImages/testimonial_JeffreyMartin.png",
    },
    {
      featuredSnippet: t("testimonials.craig_stratton.featured_snippet"),
      quote: t("testimonials.craig_stratton.quote"),
      name: "Craig Stratton",
      location: "Virginia",
      imagePath: "/img/setterJoin/testimonialImages/testimonial_CraigStratton.png",
    },
    {
      featuredSnippet: t("testimonials.binita_shah.featured_snippet"),
      quote: t("testimonials.binita_shah.quote"),
      name: "Binita Shah",
      location: "Illinois",
      imagePath: "/img/setterJoin/testimonialImages/testimonial_BinitaShah.png",
    },
    {
      featuredSnippet: t("testimonials.andrew_drasen.featured_snippet"),
      quote: t("testimonials.andrew_drasen.quote"),
      name: "Andrew Drasen",
      location: "Wisconsin",
      imagePath: "/img/setterJoin/testimonialImages/testimonial_AndrewDrasen.png",
    },
    {
      featuredSnippet: t("testimonials.zachary_bach.featured_snippet"),
      quote: t("testimonials.zachary_bach.quote"),
      name: "Zachary Bach",
      location: "California",
      imagePath: "/img/setterJoin/testimonialImages/testimonial_ZacharyBach.png",
    },
    {
      featuredSnippet: t("testimonials.charles_thompson.featured_snippet"),
      quote: t("testimonials.charles_thompson.quote"),
      name: "Charles Thompson",
      location: "Virginia",
      imagePath: "/img/setterJoin/testimonialImages/testimonial_CharlesThompson.png",
    },
    {
      featuredSnippet: t("testimonials.melissa_dawson.featured_snippet"),
      quote: t("testimonials.melissa_dawson.quote"),
      name: "Melissa Dawson",
      location: "Idaho",
      imagePath: "/img/setterJoin/testimonialImages/testimonial_MelissaDawson.png",
    },
    {
      featuredSnippet: t("testimonials.jonathan_brunasso.featured_snippet"),
      quote: t("testimonials.jonathan_brunasso.quote"),
      name: "Jonathan Brunasso",
      location: "Nevada / Canada",
      imagePath: "/img/setterJoin/testimonialImages/testimonial_JonathanBrunasso.png",
    },
    {
      featuredSnippet: t("testimonials.tom_cotter.featured_snippet"),
      quote: t("testimonials.tom_cotter.quote"),
      name: "Tom Cotter",
      location: "Kansas",
      imagePath: "/img/setterJoin/testimonialImages/testimonial_TomCotter.png",
    },
  ];

  const testimonial = testimonialData[displayedTestimonialIdx];

  const currentPosition = () => {
    const position = (displayedTestimonialIdx + 1).toString();
    if (position.length > 1) return position;

    return `0${position}`;
  };

  const hideAndShowNextTestimonial = () => {
    setDisplayTestimonial(false);
    setTimeout(() => {
      setDisplayTestimonial(true);
    }, 300);
  };

  const handleNext = () => {
    hideAndShowNextTestimonial();

    setTimeout(() => {
      if (displayedTestimonialIdx === testimonialData.length - 1) {
        setDisplayedTestimonialIdx(0);
      } else {
        setDisplayedTestimonialIdx(displayedTestimonialIdx + 1);
      }
    }, 100);
  };

  const handlePrevious = () => {
    hideAndShowNextTestimonial();

    setTimeout(() => {
      if (!displayedTestimonialIdx) {
        setDisplayedTestimonialIdx(testimonialData.length - 1);
      } else {
        setDisplayedTestimonialIdx(displayedTestimonialIdx - 1);
      }
    }, 100);
  };

  return (
    <S.TestimonialContainer>
      <S.MaxWidthContainer>
        <S.CarouselHeader>
          <S.HeaderText>{t("testimonials.what_theyre_saying")}</S.HeaderText>
          <S.ButtonContainer>
            <S.ArrowButton onClick={handlePrevious}>
              <S.RotatedContainer>
                <RightArrowLong width="22px" height="22px" fill={theme.colors["blue-ribbon"]} />
              </S.RotatedContainer>
            </S.ArrowButton>
            <S.ArrowButton onClick={handleNext}>
              <RightArrowLong width="22px" height="22px" fill={theme.colors["blue-ribbon"]} />
            </S.ArrowButton>
          </S.ButtonContainer>
        </S.CarouselHeader>
        <Zoom in={displayTestimonial} timeout={300}>
          <S.TestimonialCard>
            <S.TestimonyRow>
              <S.FeaturedQuote>&quot;{testimonial.featuredSnippet}&quot;</S.FeaturedQuote>
              <S.TestimonialText>{testimonial.quote}</S.TestimonialText>
            </S.TestimonyRow>
            <S.SellerInfoRow>
              <S.SellerNameColumn>
                <S.SmallDivider />
                <div>
                  <S.SellerName>{testimonial.name}</S.SellerName>
                  <S.SellerLocation>{testimonial.location}</S.SellerLocation>
                </div>
              </S.SellerNameColumn>
              <S.ImageContainer key={testimonial.imagePath} src={testimonial.imagePath} alt="Featured Seller" />
            </S.SellerInfoRow>
          </S.TestimonialCard>
        </Zoom>
        <S.CarouselFooter>
          <S.CurrentTestimonialCount>{currentPosition()}</S.CurrentTestimonialCount>/{testimonialData.length}
        </S.CarouselFooter>
      </S.MaxWidthContainer>
    </S.TestimonialContainer>
  );
};

export default SellerTestimonials;
