import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/projects/messages";
import { camelizeKeys } from "lib/helpers";
import SchemaValidator from "util/schema-helpers/SchemaValidator";
import FEATURE_FLAGS from "../../../../util/featureFlags";
import { ProjectContractsResponse } from "../schemas/contracts";

function* getContractDetails({ payload }: { payload: { providerLeadId: number } }) {
  const { providerLeadId } = payload;
  const { data, error } = yield* makeRequest.get(`/change_orders/${providerLeadId}/contracts`);

  const result = SchemaValidator.validate<typeof ProjectContractsResponse>({
    schema: ProjectContractsResponse,
    schemaName: "ProjectContractsResponse",
    dto: camelizeKeys(data),
  });

  if (error && error.status !== 204) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_CONTRACT_DETAILS,
    });
  }

  if (!result.success || !result.data?.body) {
    yield put(actions.setContractTabVisible(false));
    return null;
  }

  if (result.success && result.data.body.contracts.length > 0) {
    if (FEATURE_FLAGS.includes("changeOrder")) {
      yield all([
        put(actions.setContractDetails(result.data.body)),
        put(actions.setContractTabVisible(!!result.data.body.contracts)),
        put(
          actions.setContractAsSoldDetails({
            contract: result.data.body.firstFinalizedContract,
            backBtnTitle: "original",
          }),
        ),
      ]);
    }
    if (!FEATURE_FLAGS.includes("changeOrder")) {
      yield all([
        put(actions.setContractDetails(result.data.body)),
        put(actions.setContractTabVisible(true)),
        put(
          actions.setContractAsSoldDetails({
            contract: result.data.body.firstFinalizedContract,
            backBtnTitle: "original",
          }),
        ),
      ]);
    }
    if (
      result.data.body.pendingDesignChange &&
      result.data.body.salesConsultantInformed &&
      result.data.body.changeOrder
    ) {
      yield put(actions.setProjectChangeRequestVisible(true));
    }
  }

  return null;
}

export default getContractDetails;
