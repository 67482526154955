export default {
  // general
  UPDATE_CUSTOM_FIELD_SUCCESS: "You have successfully updated this custom field.",

  DELETE_CUSTOM_FIELD_SUCCESS: "You have successfully deleted this custom field.",

  CREATE_CUSTOM_FIELD_SUCCESS: "You have successfully created this custom field.",

  // errors
  GET_CUSTOM_FIELDS_ERROR: "There was an error getting custom fields.",

  CREATE_CUSTOM_FIELD_ERROR: "There was an error creating custom fields.",

  GET_CUSTOM_FIELD_ERROR: "There was an error getting this custom field.",

  UPDATE_CUSTOM_FIELD_ERROR: "There was an error updating this custom field.",

  DELETE_CUSTOM_FIELD_ERROR: "There was an error deleting this custom field.",
};
