import React from "react";
import PropTypes from "prop-types";

const DailyCalendar = (props) => {
  const { height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="11" r="11" fill="var(--fresh-air-blue)" />
      <path
        d="M7.58439 12.0421H8.9935C9.12877 12.0421 9.23023 11.9406 9.23023 11.8054V10.8246C9.23023 10.6893 9.12877 10.5879 8.9935 10.5879H7.58439C7.44911 10.5879 7.34766 10.6893 7.34766 10.8246V11.8054C7.34766 11.9294 7.44911 12.0421 7.58439 12.0421Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M15.2941 6.94727V7.53346C15.2941 7.95055 14.9559 8.30001 14.5276 8.30001C14.1105 8.30001 13.761 7.96183 13.761 7.53346V6.94727H12.9494V7.53346C12.9494 7.95055 12.6112 8.30001 12.1828 8.30001C11.7657 8.30001 11.4163 7.96183 11.4163 7.53346V6.94727H10.6046V7.53346C10.6046 7.95055 10.2664 8.30001 9.83805 8.30001C9.42095 8.30001 9.07149 7.96183 9.07149 7.53346V6.94727H8.25984V7.53346C8.25984 7.95055 7.92165 8.30001 7.49329 8.30001C7.06492 8.30001 6.72673 7.96183 6.72673 7.53346V6.94727C6.12927 6.96981 5.64453 7.46582 5.64453 8.07455V14.8721C5.64453 15.4921 6.15181 15.9994 6.77182 15.9994H15.2265C15.8465 15.9994 16.3538 15.4921 16.3538 14.8721V8.07455C16.3763 7.46582 15.8916 6.96981 15.2941 6.94727ZM15.621 14.8721C15.621 15.075 15.4519 15.2441 15.249 15.2441H6.79437C6.59145 15.2441 6.42236 15.075 6.42236 14.8721V9.66403H15.6323V14.8721H15.621Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M10.3227 12.0421H11.7318C11.8671 12.0421 11.9685 11.9406 11.9685 11.8054V10.8246C11.9685 10.6893 11.8671 10.5879 11.7318 10.5879H10.3227C10.1874 10.5879 10.0859 10.6893 10.0859 10.8246V11.8054C10.0859 11.9294 10.1874 12.0421 10.3227 12.0421Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M13.0492 12.0421H14.4583C14.5936 12.0421 14.6951 11.9406 14.6951 11.8054V10.8246C14.6951 10.6893 14.5936 10.5879 14.4583 10.5879H13.0492C12.914 10.5879 12.8125 10.6893 12.8125 10.8246V11.8054C12.8125 11.9294 12.9252 12.0421 13.0492 12.0421Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M7.58439 14.3312H8.9935C9.12877 14.3312 9.23023 14.2297 9.23023 14.0944V13.1137C9.23023 12.9784 9.12877 12.877 8.9935 12.877H7.58439C7.44911 12.877 7.34766 12.9784 7.34766 13.1137V14.0944C7.34766 14.2184 7.44911 14.3312 7.58439 14.3312Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M10.3227 14.3312H11.7318C11.8671 14.3312 11.9685 14.2297 11.9685 14.0944V13.1137C11.9685 12.9784 11.8671 12.877 11.7318 12.877H10.3227C10.1874 12.877 10.0859 12.9784 10.0859 13.1137V14.0944C10.0859 14.2184 10.1874 14.3312 10.3227 14.3312Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M13.0492 14.3312H14.4583C14.5936 14.3312 14.6951 14.2297 14.6951 14.0944V13.1137C14.6951 12.9784 14.5936 12.877 14.4583 12.877H13.0492C12.914 12.877 12.8125 12.9784 12.8125 13.1137V14.0944C12.8125 14.2184 12.9252 14.3312 13.0492 14.3312Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M7.51503 7.89385C7.71794 7.89385 7.87576 7.73602 7.87576 7.53311V6.36073C7.87576 6.15782 7.71794 6 7.51503 6C7.31212 6 7.1543 6.15782 7.1543 6.36073V7.53311C7.1543 7.72475 7.31212 7.89385 7.51503 7.89385Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M9.84901 7.89385C10.0519 7.89385 10.2097 7.73602 10.2097 7.53311V6.36073C10.2097 6.15782 10.0519 6 9.84901 6C9.6461 6 9.48828 6.15782 9.48828 6.36073V7.53311C9.48828 7.72475 9.6461 7.89385 9.84901 7.89385Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M12.1933 7.89385C12.3962 7.89385 12.554 7.73602 12.554 7.53311V6.36073C12.554 6.15782 12.3962 6 12.1933 6C11.9904 6 11.8326 6.15782 11.8326 6.36073V7.53311C11.8213 7.72475 11.9904 7.89385 12.1933 7.89385Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M14.5287 7.89385C14.7316 7.89385 14.8894 7.73602 14.8894 7.53311V6.36073C14.8894 6.15782 14.7316 6 14.5287 6C14.3258 6 14.168 6.15782 14.168 6.36073V7.53311C14.168 7.72475 14.3258 7.89385 14.5287 7.89385Z"
        fill="var(--dark-blue)"
      />
    </svg>
  );
};

DailyCalendar.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

DailyCalendar.defaultProps = {
  height: "22",
  width: "22",
};

export default DailyCalendar;
