import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  org: {
    name: "",
    settings: {
      autoOfferAcceptance: false,
      sellerInvitesAllowed: false,
      recognitionEmails: true,
      compensationEmails: true,
      eventEmails: true,
      promotionEmails: true,
      newsletterEmails: true,
      platformEmails: true,
      trainingEmails: true,
      accountAndTeamEmails: true,
      installerAssignment: null,
      installerAssignmentEmail: null,
      leadGeneratorAmount: null,
      minimumMargin: null,
      minimumMargins: {
        noAdditional: null,
        leadGenerator: null,
        ambassador: null,
      },
      offerPercentage: null,
      payForProposals: null,
    },
    owner: null,
    staff: {
      enterpriseManager: {
        users: null,
      },
      enterpriseSupport: {
        users: null,
      },
      enterpriseLeadGenerator: {
        users: null,
      },
    },
  },
  seat: {
    settings: {
      assignments: [],
      enterpriseSettings: {},
      mergedSettings: {},
    },
  },
  seats: [],
  enterprisesList: [],
  selectedVolumeTiers: [],
};

const cobrandingInitialState = {
  cobrandingDetails: {
    address: "",
    city: "",
    state: "",
    zipCode: "",
    email: "",
    phoneAreaCode: "",
    phone: "",
    facebook: "",
    linkedin: "",
    instagram: "",
    signature: "",
    includeMilestonesPage: false,
    includePowurPage: false,
    includeSolarPage: false,
    customProposalLink: "",
    assets: [],
  },
};

const customFieldsInitialState = {
  customFields: [],
};

export default {
  enterpriseOrg: createReducer(initialState, {
    [types.SET_ENTERPRISE_ORG](state, action) {
      return {
        ...state,
        org: action.payload,
      };
    },
    [types.SET_ENTERPRISE_ORG_SEAT](state, action) {
      return {
        ...state,
        seat: action.payload,
      };
    },
    [types.RESET_ENTERPRISE_ORG_SEAT](state, action) {
      return {
        ...state,
        seat: action.payload,
      };
    },

    [types.SET_ENTERPRISE_ORG_SEATS](state, action) {
      const { seats } = action.payload;
      return {
        ...state,
        seats,
      };
    },

    [types.SET_ENTERPRISES_LIST](state, action) {
      return {
        ...state,
        enterprisesList: action.payload,
      };
    },

    [types.SET_SELECTED_VOLUME_TIERS](state, action) {
      return {
        ...state,
        selectedVolumeTiers: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
  enterpriseCobranding: createReducer(cobrandingInitialState, {
    [types.SET_ENTERPRISE_COBRANDING](state, action) {
      return {
        ...state,
        cobrandingDetails: action.payload,
      };
    },
  }),
  enterpriseCustomFields: createReducer(customFieldsInitialState, {
    [types.SET_ENTERPRISE_CUSTOM_FIELDS](state, action) {
      return {
        ...state,
        customFields: action.payload,
      };
    },
  }),
  enterpriseAssets: createReducer(
    {
      assets: [],
    },
    {
      [types.SET_ENTERPRISE_ASSETS](state, action) {
        return {
          ...state,
          assets: action.payload,
        };
      },
    },
  ),
};
