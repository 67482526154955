import { put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToCamelCase } from "lib/helpers";
import selectors from "rdx/modules/projects/selectors";
import actions from "rdx/actions";
import messages from "../messages";

function* editNote(action) {
  const { leadId, noteId, body, tags } = action.payload;
  const { success, data, error } = yield* makeRequest.patch(`/provider_leads/${leadId}/notes/${noteId}`, {
    body,
    tags,
  });
  if (success && data) {
    const note = data.body;
    const notes = yield select(selectors.getNotes);
    const newNotes = notes.map((n) => {
      if (n.id === note.id) return keysToCamelCase(note);
      return keysToCamelCase(n);
    });
    yield put(actions.setNotes({ notes: newNotes }));
    yield put(actions.newSuccessEvent({ message: messages.EDIT_NOTE_SUCCESS }));
  } else {
    return getErrorActions({ error, message: messages.ERROR_EDITING_NOTE });
  }
  return null;
}

export default editNote;
