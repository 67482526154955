import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const PlusIcon = ({ height = "10", width = "11", fill = cvar("white") }: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 11 10">
      <rect x="4.17383" width="2.43243" height="10" rx="0.5" fill="white" />
      <rect
        x="10.3906"
        y="3.78418"
        width="2.43243"
        height="10"
        rx="0.5"
        transform="rotate(90 10.3906 3.78418)"
        fill={fill}
      />
    </svg>
  );
};

export default PlusIcon;
