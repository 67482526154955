const commonTabs = [
  {
    key: "dashboard",
    label: "Dashboard",
  },
  {
    key: "ledger",
    label: "Ledger",
  },
];

const adminTabs = [];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
