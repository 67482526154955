import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SettingsClient from "util/SettingsClient";
import useWindowResize from "hooks/useWindowResize";
import Counter from "./counter";
import styles from "../../Home.module.less";

const Counters = () => {
  const { t, i18n } = useTranslation("home");
  const urlPrefix = process.env.REACT_APP_S3_URL_PREFIX;
  const { width } = useWindowResize();
  const specialScreenWidth = width <= 1118 && width >= 1026;

  const countInfo = useMemo(() => {
    const out = {};
    const settings = new SettingsClient();
    const setCounts = (metric, key) => {
      const { current, per_second, updated_at } = metric;
      out[key] = {
        initialValue: current,
        rate: per_second,
        start: updated_at,
      };
    };

    if (settings.data?.impact_metrics) {
      const { kwh_produced, savings, carbon_sequestered } = settings.data.impact_metrics;
      if (kwh_produced) {
        setCounts(kwh_produced, "kwh");
      }
      if (savings) {
        setCounts(savings, "savings");
      }
      if (carbon_sequestered) {
        setCounts(carbon_sequestered, "co2");
      }
    }
    return out;
  }, []);

  const getSavingsLabel = useMemo(() => {
    let label = t("impact.savings");
    if (specialScreenWidth && i18n.language === "en") {
      label = label.replace("Powur ", "");
    }
    return label;
  }, [specialScreenWidth, t, i18n.language]);

  return (
    <div className={styles.counters}>
      <div className={styles.innerContainer}>
        <h5 className={styles.heading}>{t("impact.take_your_powur_back")}</h5>
        <h2 className={styles.subheading}>{t("impact.results")}</h2>
        <div className={styles.countersContainer}>
          {countInfo.co2 && (
            <Counter
              id="sequestered"
              title={t("impact.carbon_sequestered")}
              bgImgSrc={`${urlPrefix}/assets/img/home/counter-bg-co2.jpg`}
              {...countInfo.co2}
              suffix={<div className={styles.suffix}>lbs</div>}
            />
          )}
          {countInfo.savings && (
            <Counter
              id="savings"
              title={getSavingsLabel}
              bgImgSrc={`${urlPrefix}/assets/img/home/counter-bg-usd.jpg`}
              {...countInfo.savings}
              prefix={<div className={styles.prefix}>$</div>}
              suffix={<div className={styles.suffix}>{t("impact.usd")}</div>}
            />
          )}
          {countInfo.kwh && (
            <Counter
              id="hwh"
              title={t("impact.kwh_produced")}
              bgImgSrc={`${urlPrefix}/assets/img/home/counter-bg-kwh.jpg`}
              {...countInfo.kwh}
              suffix={<div className={styles.suffix}>{t("impact.kwh")}</div>}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Counters;
