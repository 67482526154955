import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const QualifiedIcon = ({ height = 19, width = 19, fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.86555 11.2505H4.67694C4.03392 11.2505 3.50781 11.7766 3.50781 12.4196V17.3299C3.50781 17.9794 4.03392 18.4991 4.67694 18.4991H5.86555V11.2505Z"
        fill={fill}
      />
      <path
        d="M14.6145 13.3808L13.5363 17.2649C13.335 17.9924 12.679 18.499 11.919 18.499H6.83984V11.2504L8.98324 8.21722C9.21057 7.89896 9.58079 7.7041 9.97699 7.7041C10.6655 7.7041 11.1916 8.26918 11.1916 8.91869C11.1916 9.02911 11.1786 9.13953 11.1461 9.24994L10.581 11.2504H12.9972C14.1079 11.2504 14.9133 12.3091 14.6145 13.3808Z"
        fill={fill}
      />
      <path
        d="M2.65134 3.774L3.13913 4.76191C3.18524 4.85479 3.27358 4.91974 3.37685 4.93468L4.46738 5.09316C4.72589 5.13083 4.82916 5.44845 4.6421 5.63096L3.85294 6.39998C3.7789 6.47273 3.74447 6.57665 3.76201 6.67927L3.94842 7.76526C3.99259 8.02312 3.72239 8.21927 3.49051 8.09781L2.51495 7.58534C2.42336 7.53728 2.3136 7.53728 2.22137 7.58534L1.2458 8.09781C1.01457 8.21927 0.743723 8.02312 0.787889 7.76526L0.9743 6.67927C0.991837 6.57665 0.958062 6.47273 0.883368 6.39998L0.0955085 5.63096C-0.0915513 5.44845 0.0117211 5.13083 0.270227 5.09316L1.36076 4.93468C1.46338 4.91974 1.55237 4.85479 1.59848 4.76191L2.08627 3.774C2.20188 3.53953 2.53573 3.53953 2.65134 3.774Z"
        fill={fill}
      />
      <path
        d="M9.37595 1.17586L9.86374 2.16377C9.90985 2.25665 9.99819 2.3216 10.1015 2.33654L11.192 2.49502C11.4505 2.53269 11.5538 2.8503 11.3667 3.03281L10.5776 3.80184C10.5035 3.87458 10.4691 3.97851 10.4866 4.08113L10.673 5.16711C10.7172 5.42497 10.447 5.62112 10.2151 5.49967L9.23955 4.9872C9.14797 4.93914 9.03821 4.93914 8.94597 4.9872L7.97041 5.49967C7.73918 5.62112 7.46833 5.42497 7.5125 5.16711L7.69891 4.08113C7.71645 3.97851 7.68267 3.87458 7.60798 3.80184L6.82012 3.03281C6.63306 2.8503 6.73633 2.53269 6.99484 2.49502L8.08537 2.33654C8.18799 2.3216 8.27698 2.2573 8.32309 2.16377L8.81088 1.17586C8.92649 0.941381 9.26034 0.941381 9.37595 1.17586Z"
        fill={fill}
      />
      <path
        d="M16.0999 3.774L16.5877 4.76191C16.6338 4.85479 16.7221 4.91974 16.8254 4.93468L17.9159 5.09316C18.1745 5.13083 18.2777 5.44845 18.0907 5.63096L17.3015 6.39998C17.2275 6.47273 17.193 6.57665 17.2106 6.67927L17.397 7.76526C17.4412 8.02312 17.171 8.21927 16.9391 8.09781L15.9635 7.58534C15.8719 7.53728 15.7622 7.53728 15.6699 7.58534L14.6944 8.09781C14.4631 8.21927 14.1923 8.02312 14.2365 7.76526L14.4229 6.67927C14.4404 6.57665 14.4066 6.47273 14.3319 6.39998L13.5428 5.63096C13.3557 5.44845 13.459 5.13083 13.7175 5.09316L14.808 4.93468C14.9106 4.91974 14.9996 4.85544 15.0457 4.76191L15.5335 3.774C15.6504 3.53953 15.9843 3.53953 16.0999 3.774Z"
        fill={fill}
      />
    </svg>
  );
};

export default QualifiedIcon;
