import React from "react";
import { Button } from "antd";
import RightArrow2 from "components/Icons/Arrows/rightArrow2";
import styles from "./styles.module.less";

interface AlertButtonPropsI {
  handleClick: () => void;
  width?: number;
  height?: number;
  buttonText: string;
}

const AlertButton = ({ width = 230, handleClick, height, buttonText }: AlertButtonPropsI) => {
  return (
    <Button style={{ width: `${width}px`, height: `${height}px` }} className={styles.alertButton} onClick={handleClick}>
      <div className={styles.alertText}>{buttonText}</div>
      <RightArrow2 />
    </Button>
  );
};

export default AlertButton;
