import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, AutoComplete } from "antd";
import { addressLookup } from "lib/helpers";

import styles from "./EnterpriseProSignupModal.module.less";

const { Option } = AutoComplete;

const StreetAddressLookup = (props) => {
  const { form } = props;
  const [suggestions, setSuggestions] = useState([]);

  const fetchAddressSuggestions = async (value) => {
    const addresses = await addressLookup(value);

    return setSuggestions(
      addresses.map((suggestion, idx) => (
        <Option
          // eslint-disable-next-line react/no-array-index-key
          key={`${suggestion.street_line} ${suggestion.city} ${idx}`}
          value={`${suggestion.street_line} ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`}
          data={suggestion}
        >
          {suggestion.street_line} {suggestion.city} {suggestion.state}, {suggestion.zipcode}
        </Option>
      )),
    );
  };

  const handleAddressSelect = (_, option) => {
    form.setFieldsValue({
      businessStreet: option.data.street_line,
      businessCity: option.data.city,
      businessState: option.data.state,
      businessZip: option.data.zipcode,
    });
  };

  return (
    <Form.Item
      name="businessStreet"
      rules={[
        {
          required: true,
          message: "Street Address Required",
        },
      ]}
    >
      <AutoComplete
        className={styles.select}
        onSearch={fetchAddressSuggestions}
        onSelect={handleAddressSelect}
        size="large"
      >
        {suggestions}
      </AutoComplete>
    </Form.Item>
  );
};

StreetAddressLookup.propTypes = {
  // antd form instance
  form: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default StreetAddressLookup;
