import React from "react";

import { BannerContainer, IconContainer } from "../styles";
import * as S from "./styles";
import { InfoBannerProps } from "../types";

const InfoBanner = (props: InfoBannerProps) => {
  return (
    <BannerContainer style={props.containerStyles}>
      <IconContainer>{props.icon}</IconContainer>
      <S.TextContainer style={props.textStyles}>
        <S.Title>{props.title}</S.Title>
        <S.Text>{props.text}</S.Text>
      </S.TextContainer>
    </BannerContainer>
  );
};

export default InfoBanner;
