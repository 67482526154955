import React from "react";

type buttonPropsProps = {
  role: string;
  tabIndex: number;
  onClick: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent) => void;
  onKeyDown: (e: React.KeyboardEvent) => void;
};

export default function buttonProps(
  handler: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent) => void,
): buttonPropsProps {
  return {
    role: "button",
    tabIndex: 0,
    onClick: handler,
    onKeyDown: (e: React.KeyboardEvent) => {
      if (e.keyCode !== undefined && e.keyCode === 13) {
        handler(e);
        return;
      }
      if (e.key !== undefined && e.key === "13") {
        handler(e);
      }
    },
  };
}
