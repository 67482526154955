import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";
import types from "rdx/modules/enterpriseApplications/types";
import getEnterpriseApplications from "./getEnterpriseApplications";
import getEnterpriseApplicationDetail from "./getEnterpriseApplicationDetail";
import approveEnterpriseApplication from "./approveEnterpriseApplication";
import rejectEnterpriseApplication from "./rejectEnterpriseApplication";
import makePendingEnterpriseApplication from "./makePendingEnterpriseApplication";
import createEnterpriseApplication from "./createEnterpriseApplication";
import updateEnterpriseApplication from "./updateEnterpriseApplication";
import getEnterpriseApplicationByUser from "./getEnterpriseApplicationByUser";

import createTierOneUserRegistration from "./createTierOneUserRegistration";
import getLaborApplicationStatus from "./getLaborApplicationStatus";
import getEntApplicationsCsv from "./getEntApplicationsCsv";
import getEntApplicationsCsvReport from "./getEntApplicationsCsvReport";
// IMPORT_PT (for script, do not remove)

function* watchEnterpriseApplicationsSagas() {
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_APPLICATIONS, getEnterpriseApplications);

  yield trackRequests(takeEvery, types.GET_ENTERPRISE_APPLICATION_DETAIL, getEnterpriseApplicationDetail);

  yield trackRequests(takeEvery, types.GET_ENTERPRISE_APPLICATION_BY_USER, getEnterpriseApplicationByUser);

  yield trackRequests(takeEvery, types.APPROVE_ENTERPRISE_APPLICATION, approveEnterpriseApplication);

  yield trackRequests(takeEvery, types.REJECT_ENTERPRISE_APPLICATION, rejectEnterpriseApplication);

  yield trackRequests(takeEvery, types.MAKE_PENDING_ENTERPRISE_APPLICATION, makePendingEnterpriseApplication);

  yield trackRequests(takeEvery, types.CREATE_ENTERPRISE_APPLICATION, createEnterpriseApplication);

  yield trackRequests(takeEvery, types.UPDATE_ENTERPRISE_APPLICATION, updateEnterpriseApplication);
  yield trackRequests(takeEvery, types.CREATE_ENTERPRISE_TIER_ONE_USER_REGISTRATION, createTierOneUserRegistration);
  yield trackRequests(takeEvery, types.GET_LABOR_APPLICATION_STATUS, getLaborApplicationStatus);
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_APPLICATIONS_CSV, getEntApplicationsCsv);
  yield trackRequests(takeEvery, types.GET_ENT_APPLICATIONS_CSV_REPORT, getEntApplicationsCsvReport);
  // INSERTION_PT (for script, do not remove)
}

export default watchEnterpriseApplicationsSagas;
