import { call } from "redux-saga/effects";
import ApiClient from "util/ApiClient";

const api = new ApiClient();

export const formatResponse = (response) => {
  if (response.error) {
    const error = { ...response.error, text: response.message };
    return { success: false, error, data: null };
  }
  return { success: true, error: null, data: response };
};

export const formatServerConnectionError = (e) => {
  if (process.env.NODE_ENV === "development") console.warn(e);
  const error = {
    ...e.error,
    text: "There was an error connecting with the server",
  };
  return { success: false, error, data: null };
};

const composeRequestManager = (verb) => {
  function* manageRequest(route, params = {}, options = {}) {
    try {
      const response = yield call(api.invoke.bind(api), route, params, {
        ...options,
        method: verb,
      });
      return formatResponse(response);
    } catch (e) {
      return formatServerConnectionError(e);
    }
  }
  return manageRequest;
};

const makeRequest = {
  delete: composeRequestManager("DELETE"),
  get: composeRequestManager("GET"),
  patch: composeRequestManager("PATCH"),
  post: composeRequestManager("POST"),
  put: composeRequestManager("PUT"),
};

export default makeRequest;
