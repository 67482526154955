import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/workSchedules/types";
import updateWorkSchedules from "./updateWorkSchedules";
import getSchedulesByTeam from "./getSchedulesByTeam";
// IMPORT_PT (for script, do not remove)

function* watchWorkSchedulesSagas() {
  yield trackRequests(takeEvery, types.GET_SCHEDULES_BY_TEAM, getSchedulesByTeam);
  yield trackRequests(takeEvery, types.UPDATE_WORK_SCHEDULES, updateWorkSchedules);
  // INSERTION_PT (for script, do not remove)
}

export default watchWorkSchedulesSagas;
