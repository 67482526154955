// import { get } from "lodash";

export default {
  getEarnings: (state) => state.earnings,
  getSellerOrLeadAutoComplete: (state) => state.earnings.consultantOrLeadAutoComplete,
  selectMonthlyBreakdown12Mos: (state) => state.earnings.dashboard.last12Months,
  selecThisMonthEarnings: (state) => state.earnings.dashboard.thisMonthEarnings,
  selectPowurCreditBalance: (state) => state.earnings.dashboard.creditBalance,
  selectKpi: (state) => state.earnings.kpi,
  selectMonthlyBreakdown: (state) => state.earnings.dashboard.customDateRange,
};
