import React from "react";
import _ from "lodash";
import { Switch, Route } from "react-router-dom";

import SessionClient from "util/SessionClient";

import useGetCurrentRouteInfo from "hooks/useGetCurrentRouteInfo";
import TabBar from "./TabBar";

const TabsControl = () => {
  const { platformRoutes: routes } = useGetCurrentRouteInfo();
  const session = new SessionClient();

  return (
    <Switch>
      {routes.map((r) => {
        return (
          <Route
            {...r}
            render={() => {
              if (r.tabs) {
                const showTabs = _.reject(r.tabs, (t) => {
                  return session.callByDotNotation(t.hideIf);
                });
                return <TabBar newHeader={r.newHeader} tabs={showTabs} basePath={r.basePath} />;
              }
              return null;
            }}
          />
        );
      })}
    </Switch>
  );
};

export default TabsControl;
