import React from "react";
import PropTypes from "prop-types";

const HomeownerIconLarge = (props) => {
  const { fill, height, width, onMouseEnter, onMouseOut } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill={fill} onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} />
      <path
        d="M29.0538 19.3009L21.0033 11.2825L20.4637 10.7447C20.3407 10.623 20.1744 10.5547 20.0011 10.5547C19.8278 10.5547 19.6615 10.623 19.5386 10.7447L10.9484 19.3009C10.8224 19.4259 10.7229 19.5749 10.6556 19.7389C10.5883 19.9029 10.5547 20.0787 10.5567 20.2559C10.5651 20.9868 11.1755 21.5702 11.9089 21.5702H12.7944V28.3325H27.2079V21.5702H28.1121C28.4684 21.5702 28.8038 21.4311 29.0559 21.1798C29.18 21.0565 29.2784 20.9099 29.3453 20.7485C29.4122 20.587 29.4462 20.414 29.4455 20.2393C29.4455 19.8864 29.3059 19.5521 29.0538 19.3009ZM21.1679 26.8376H18.8344V22.6021H21.1679V26.8376ZM25.7078 20.0753V26.8376H22.5013V22.1038C22.5013 21.6449 22.1284 21.2733 21.6679 21.2733H18.3343C17.8739 21.2733 17.501 21.6449 17.501 22.1038V26.8376H14.2945V20.0753H12.2943L20.0032 12.3995L20.4845 12.8791L27.71 20.0753H25.7078Z"
        fill="white"
      />
    </svg>
  );
};

HomeownerIconLarge.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseOut: PropTypes.func,
};

HomeownerIconLarge.defaultProps = {
  fill: "var(--dark-blue)",
  height: "40",
  width: "40",
};

export default HomeownerIconLarge;
