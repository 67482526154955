import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Button } from "antd";
import { TwitterOutlined, LinkedinFilled } from "@ant-design/icons";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";
import styles from "../Education.module.less";

const SocialButtons = ({ copy }) => {
  const shareUrl = `${window.location.origin.toString()}${window.location.pathname.match("/.*/")[0]}top-10-questions/`;
  const linkedinTitle = "The Top Ten Solar Questions Answered";

  return (
    <div>
      <Row className={styles.shareText}>
        {" "}
        <p>Share the video series with your friends on the social media: </p>
      </Row>
      <Row className={styles.socialButtons}>
        <Col span={8}>
          <FacebookShareButton url={shareUrl} style={{ width: "100%" }} quote={copy.facebookText}>
            <Button className={styles.facebook}>
              <div className={styles.icon} />
              <span>Facebook</span>
            </Button>
          </FacebookShareButton>
        </Col>
        <Col span={8}>
          <TwitterShareButton
            url={shareUrl}
            title={copy.twitterTitle}
            style={{ width: "100%" }}
            hashtags={["solarenergy", "sun", "solar"]}
          >
            <Button className={styles.twitter}>
              <TwitterOutlined className={styles.antdIcon} />
              <span>Twitter</span>
            </Button>
          </TwitterShareButton>
        </Col>
        <Col span={8}>
          <LinkedinShareButton url={shareUrl} title={linkedinTitle} style={{ width: "100%" }}>
            <Button className={styles.linkedin}>
              <LinkedinFilled className={styles.antdIcon} />
              <span>LinkedIn</span>
            </Button>
          </LinkedinShareButton>
        </Col>
      </Row>
    </div>
  );
};

SocialButtons.propTypes = {
  copy: PropTypes.shape({
    facebookText: PropTypes.string,
    twitterTitle: PropTypes.string,
    linkedInText: PropTypes.string,
  }),
};

export default SocialButtons;
