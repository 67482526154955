import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/adminReports/messages";
import { camelizeKeys } from "lib/helpers";

function* getPreferredPartnerCsvReport(action) {
  const { reportId } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/reports/${reportId}`);
  if (success && data) {
    yield put(actions.setPreferredPartnerCsv(camelizeKeys(data.body.report)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PREFERRED_PARTNER_CSV_REPORT,
    });
  }
  return null;
}

export default getPreferredPartnerCsvReport;
