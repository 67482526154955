import React from "react";
import PropTypes from "prop-types";

const LightningIcon = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 16 29" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.762728 17.0138H5.39981L3.7423 27.5509C3.71763 27.7219 3.79656 27.893 3.93962 28.0213C4.08268 28.1496 4.30467 28.2235 4.52666 28.2235C4.81277 28.2235 5.07422 28.0952 5.20248 27.8969L15.0291 12.7368C15.1475 12.5502 15.1377 12.3286 14.9995 12.1497C14.8614 11.9708 14.6148 11.862 14.3484 11.862H9.7113L11.3688 1.32494C11.3935 1.15386 11.3146 0.982779 11.1715 0.854468C10.836 0.558965 10.1405 0.625064 9.90863 0.978891L0.0819644 16.139C-0.0364291 16.3256 -0.0265623 16.5473 0.111563 16.7261C0.249689 16.905 0.496342 17.0138 0.762728 17.0138ZM9.33639 4.49771L8.09326 12.3908C8.06859 12.5618 8.13272 12.7329 8.28072 12.8612C8.42378 12.9895 8.63096 13.0634 8.85295 13.0634H13.1102L5.77472 24.3781L7.01785 16.485C7.04252 16.314 6.97839 16.1429 6.83039 16.0146C6.68734 15.8863 6.48015 15.8124 6.25816 15.8124H2.00093L9.33639 4.49771Z"
        fill="white"
      />
    </svg>
  );
};

LightningIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

LightningIcon.defaultProps = {
  fill: "var(--dark-blue)",
  height: "40",
  width: "40",
};

export default LightningIcon;
