import React from "react";
import PropTypes from "prop-types";

const LightbulbIcon = (props) => {
  const { accentColor, mainColor, size, containerStyles } = props;

  return (
    <div
      style={{
        // paddingTop: "3px",
        lineHeight: "10px",
        ...containerStyles,
      }}
    >
      {/* <svg
        width={size}
        height={size}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill={accentColor} />
        <circle cx="24" cy="24" r="18" fill={mainColor} />
        <rect
          x="21.0703"
          y="31.7982"
          width="2"
          height="18"
          rx="0.5"
          transform="rotate(-135 21.0703 31.7982)"
          fill={accentColor}
        />
        <rect
          x="22.4845"
          y="30.3846"
          width="2"
          height="9.99947"
          rx="0.5"
          transform="rotate(135 22.4845 30.3846)"
          fill={accentColor}
        />
      </svg> */}

      <svg width={size + 1} height={size} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14.3354" cy="14" r="14" fill={accentColor} />
        <path
          d="M18.6751 13.5094C18.6751 11.1145 16.7208 9.16016 14.3258 9.16016C11.9309 9.16016 9.97656 11.1145 9.97656 13.5094C9.97656 13.8543 10.0149 14.1992 10.1107 14.5441C10.2256 15.0997 10.4364 15.4446 10.7238 15.9236C10.7813 16.0385 10.8579 16.1535 10.9346 16.2876C11.0304 16.4409 11.107 16.5942 11.2028 16.7283C11.586 17.3605 11.8159 17.7246 11.8159 18.4718V20.2728C11.8159 20.7327 12.1416 21.0967 12.5823 21.1733C12.7739 22.1505 13.3487 22.7636 14.3258 22.7636C15.303 22.7636 15.8969 22.1505 16.0694 21.1733C16.51 21.0967 16.8358 20.7135 16.8358 20.2728V18.4718C16.8358 17.7246 17.0657 17.3414 17.4489 16.7283C17.5255 16.5942 17.6213 16.4409 17.7171 16.2876C17.7938 16.1535 17.8704 16.0385 17.9279 15.9236C18.2153 15.4446 18.426 15.0997 18.541 14.5441C18.6368 14.1992 18.6751 13.8543 18.6751 13.5094ZM16.0885 18.9316H12.6015V18.491C12.6015 18.3952 12.6015 18.3185 12.5823 18.2419H16.0885C16.0885 18.3185 16.0694 18.3952 16.0694 18.491V18.9316H16.0885ZM15.9161 20.4453H12.7356C12.6398 20.4453 12.5823 20.3686 12.5823 20.292V19.698H16.0694V20.292C16.0885 20.3686 16.0119 20.4453 15.9161 20.4453ZM14.3258 22.0164C14.1151 22.0164 13.5786 22.0164 13.3678 21.2117H15.303C15.0922 22.0164 14.5366 22.0164 14.3258 22.0164ZM17.7938 14.3908C17.698 14.8123 17.5447 15.0997 17.2764 15.5404C17.1998 15.6553 17.1423 15.7703 17.0657 15.9044C16.9699 16.0577 16.8932 16.211 16.8166 16.3259C16.5867 16.7091 16.3759 17.054 16.2418 17.4563H12.4482C12.3141 17.054 12.1225 16.7091 11.8734 16.3259C11.7967 16.1918 11.701 16.0577 11.6243 15.9044C11.5477 15.7703 11.471 15.6362 11.4135 15.5404C11.1453 15.0805 10.992 14.8123 10.8962 14.3716C10.8196 14.0842 10.7813 13.7968 10.7813 13.5094C10.7813 11.536 12.3907 9.92655 14.3642 9.92655C16.3376 9.92655 17.947 11.536 17.947 13.5094C17.9087 13.7968 17.8704 14.0842 17.7938 14.3908Z"
          fill={mainColor}
        />
        <path
          d="M22.2195 13.125H20.6293C20.4185 13.125 20.2461 13.2974 20.2461 13.5082C20.2461 13.719 20.4185 13.8914 20.6293 13.8914H22.2195C22.4303 13.8914 22.6027 13.719 22.6027 13.5082C22.6027 13.2974 22.4303 13.125 22.2195 13.125Z"
          fill={mainColor}
        />
        <path
          d="M18.7709 9.4515C18.8667 9.4515 18.9625 9.41318 19.0391 9.33654L20.1696 8.20612C20.3228 8.05284 20.3228 7.82292 20.1696 7.66965C20.0163 7.51637 19.7864 7.51637 19.6331 7.66965L18.5027 8.80007C18.3494 8.95335 18.3494 9.18326 18.5027 9.33654C18.5793 9.41318 18.6751 9.4515 18.7709 9.4515Z"
          fill={mainColor}
        />
        <path
          d="M14.3258 7.59103C14.5366 7.59103 14.709 7.41859 14.709 7.20783V5.61757C14.709 5.40681 14.5366 5.23438 14.3258 5.23438C14.1151 5.23438 13.9426 5.40681 13.9426 5.61757V7.20783C13.9426 7.41859 14.1151 7.59103 14.3258 7.59103Z"
          fill={mainColor}
        />
        <path
          d="M9.61258 9.33654C9.68922 9.41318 9.78501 9.4515 9.88081 9.4515C9.97661 9.4515 10.0724 9.41318 10.1491 9.33654C10.3023 9.18326 10.3023 8.95335 10.1491 8.80007L9.01862 7.66965C8.86534 7.51637 8.63542 7.51637 8.48215 7.66965C8.32887 7.82292 8.32887 8.05284 8.48215 8.20612L9.61258 9.33654Z"
          fill={mainColor}
        />
        <path
          d="M8.04133 13.125H6.45107C6.24031 13.125 6.06787 13.2974 6.06787 13.5082C6.06787 13.719 6.24031 13.8914 6.45107 13.8914H8.04133C8.25208 13.8914 8.42452 13.719 8.42452 13.5082C8.42452 13.2974 8.25208 13.125 8.04133 13.125Z"
          fill={mainColor}
        />
        <path
          d="M9.61258 17.6853L8.48215 18.8157C8.32887 18.969 8.32887 19.1989 8.48215 19.3522C8.55879 19.4288 8.65459 19.4671 8.75039 19.4671C8.84619 19.4671 8.94198 19.4288 9.01862 19.3522L10.1491 18.2217C10.3023 18.0685 10.3023 17.8385 10.1491 17.6853C9.99577 17.532 9.7467 17.532 9.61258 17.6853Z"
          fill={mainColor}
        />
        <path
          d="M19.0391 17.6853C18.8858 17.532 18.6559 17.532 18.5027 17.6853C18.3494 17.8385 18.3494 18.0685 18.5027 18.2217L19.6331 19.3522C19.7097 19.4288 19.8055 19.4671 19.9013 19.4671C19.9971 19.4671 20.0929 19.4288 20.1696 19.3522C20.3228 19.1989 20.3228 18.969 20.1696 18.8157L19.0391 17.6853Z"
          fill={mainColor}
        />
      </svg>
    </div>
  );
};

LightbulbIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mainColor: PropTypes.string,
  accentColor: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

LightbulbIcon.defaultProps = {
  accentColor: "var(--white)",
  mainColor: "var(--primary-lite)",
  size: 28,
};

export default LightbulbIcon;
