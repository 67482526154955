export default {
  // EXAMPLE_ACTION_TYPE: "[module] Example Action Type",

  GET_ADMIN_LEADS_LIST: "[adminLeads] GET Admin Leads List",

  SET_ADMIN_LEADS_LIST: "[adminLeads] SET Admin Leads List",

  SET_ADMIN_LEADS_LIST_FILTERS: "[adminLeads] SET Admin Leads List Filters",
  // INSERTION_PT (for script, do not remove)
};
