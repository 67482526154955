import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const SunIconOutline = ({ width = "16", height = "16", fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 1V2.4M8 13.6V15M2.4 8H1M4.01988 4.01988L3.02993 3.02993M11.9801 4.01988L12.9701 3.02993M4.01988 11.983L3.02993 12.9729M11.9801 11.983L12.9701 12.9729M15 8H13.6M11.5 8C11.5 9.933 9.933 11.5 8 11.5C6.067 11.5 4.5 9.933 4.5 8C4.5 6.067 6.067 4.5 8 4.5C9.933 4.5 11.5 6.067 11.5 8Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SunIconOutline;
