import React from "react";
import PropTypes from "prop-types";

const NeedsAttentionIcon = (props) => {
  const { iconColor, circleColor, height, width, containerStyles, onMouseEnter, onMouseOut } = props;

  return (
    <div style={{ paddingTop: "3px", ...containerStyles }}>
      <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <circle
          cx="9"
          cy="9"
          r="9"
          fill={circleColor}
          onMouseEnter={onMouseEnter}
          onMouseOut={onMouseOut}
          onBlur={onMouseOut}
        />
        <path
          d="M9.313 10.434a.289.289 0 0 0-.072-.11.34.34 0 0 0-.554.11.519.519 0 0 0-.018.064.343.343 0 0 0 .267.398.318.318 0 0 0 .194-.02.294.294 0 0 0 .11-.073.339.339 0 0 0 .073-.369Z"
          fill={iconColor}
        />
        <path
          d="M8.944 7.574a.455.455 0 0 0-.396.505l.207 1.707a.246.246 0 0 0 .488 0L9.45 8.08a.454.454 0 0 0-.506-.506Z"
          fill={iconColor}
        />
        <path
          d="M12.746 11.06 9.687 5.662a.789.789 0 0 0-1.373 0l-3.06 5.398a.789.789 0 0 0 .688 1.179h6.118a.79.79 0 0 0 .687-1.179Zm-.589.446a.11.11 0 0 1-.097.056H5.94a.113.113 0 0 1-.098-.168l3.06-5.399a.113.113 0 0 1 .196 0l3.059 5.4a.11.11 0 0 1 0 .111Z"
          fill={iconColor}
        />
      </svg>
    </div>
  );
};

NeedsAttentionIcon.propTypes = {
  iconColor: PropTypes.string,
  circleColor: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  containerStyles: PropTypes.objectOf(PropTypes.string),
  onMouseEnter: PropTypes.func,
  onMouseOut: PropTypes.func,
};

NeedsAttentionIcon.defaultProps = {
  iconColor: "var(--white)",
  circleColor: "var(--safety-orange)",
  height: "18",
  width: "18",
  containerStyles: {},
};

export default NeedsAttentionIcon;
