import types from "rdx/modules/adminProposals/types";
import createAction from "rdx/utils/createAction";

export default {
  getPendingAdminProposals: (payload) => createAction(types.GET_PENDING_ADMIN_PROPOSALS_LIST, payload),
  setPendingAdminProposals: (payload) => createAction(types.SET_PENDING_ADMIN_PROPOSALS_LIST, payload),
  setPendingAdminProposalsFilters: (payload) => createAction(types.SET_PENDING_ADMIN_PROPOSALS_FILTERS, payload),

  getInProgressAdminProposals: (payload) => createAction(types.GET_IN_PROGRESS_ADMIN_PROPOSALS_LIST, payload),
  setInProgressAdminProposals: (payload) => createAction(types.SET_IN_PROGRESS_ADMIN_PROPOSALS_LIST, payload),
  setInProgressAdminProposalsFilters: (payload) => createAction(types.SET_IN_PROGRESS_ADMIN_PROPOSALS_FILTERS, payload),
  setProposalsAutoComplete: (payload) => createAction(types.SET_PROPOSALS_AUTOCOMPLETE_SUGGESTIONS, payload),

  getCompletedAdminProposals: (payload) => createAction(types.GET_COMPLETED_ADMIN_PROPOSALS_LIST, payload),
  setCompletedAdminProposals: (payload) => createAction(types.SET_COMPLETED_ADMIN_PROPOSALS_LIST, payload),
  setCompletedAdminProposalsFilters: (payload) => createAction(types.SET_COMPLETED_ADMIN_PROPOSALS_FILTERS, payload),

  setAdminProposalsCounts: (payload) => createAction(types.SET_ADMIN_PROPOSALS_COUNTS, payload),

  getAdminProposalDetails: (payload) => createAction(types.GET_ADMIN_PROPOSAL_DETAILS, payload),
  setAdminProposalDetails: (payload) => createAction(types.SET_ADMIN_PROPOSAL_DETAILS, payload),

  createProposal: (payload) => createAction(types.CREATE_PROPOSAL, payload),
  updateProposal: (payload) => createAction(types.UPDATE_PROPOSAL, payload),
  markProposalAsInProgress: (payload) => createAction(types.MARK_PROPOSAL_AS_IN_PROGRESS, payload),
  markProposalAsRejected: (payload) => createAction(types.MARK_PROPOSAL_AS_REJECTED, payload),
  // INSERTION_PT (for script, do not remove)
};
