import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getAdminNoticesList(action) {
  const params = {
    sort: "submit",
    ...action.payload,
  };
  const { success, data, error } = yield* makeRequest.get("/notices", params);
  if (success && data) {
    // populate notices list (for case when req. did not provide a filters param)
    if (data.body.notices) {
      const notices = data.body.notices.map((entry) => keysToCamelCase(entry));
      yield put(actions.setAdminNoticesList({ notices, paging: data.body.paging }));
    }

    // populate notice filters (for case when req. gave param filters=1)
    if (data.body.filters) {
      yield put(actions.setAdminNoticesListFilters(data.body));
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ADMIN_NOTICES_LIST,
    });
  }
  return null;
}

export default getAdminNoticesList;
