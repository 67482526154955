const urlPrefix = process.env.REACT_APP_S3_URL_PREFIX;

const questionsList = [
  {
    number: "01",
    title: "What is Net Metering?",
    trTitleKey: "what_is_net_metering",
    image: `${urlPrefix}/assets/img/faq/faq-1.png`,
    src: `${urlPrefix}/assets/videos/EDUCATIONAL_01_NET_METERING.mp4`,
  },
  {
    number: "02",
    title: "Navigating Solar Warranties",
    trTitleKey: "navigating_solar_warranties",
    image: `${urlPrefix}/assets/img/faq/faq-3.png`,
    src: `${urlPrefix}/assets/videos/EDUCATIONAL_03_WARRANTIES_V2.mp4`,
  },
  {
    number: "03",
    title: "Powur's Mission",
    trTitleKey: "powur_mission",
    image: `${urlPrefix}/assets/img/faq/faq-4.png`,
    src: `${urlPrefix}/assets/videos/EDUCATIONAL_04_HOW_WE_GIVE_BACK.mp4`,
  },
  {
    number: "04",
    title: "Is Solar a Good Investment for My Family?",
    trTitleKey: "is_solar_a_good_investment",
    image: `${urlPrefix}/assets/img/faq/faq-5.png`,
    src: `${urlPrefix}/assets/videos/EDUCATIONAL_05_VALUE_OF_SOLAR_V2.mp4`,
  },
  {
    number: "05",
    title: "How to Determine a Solar Fit for My House?",
    trTitleKey: "determine_a_solar_fit",
    image: `${urlPrefix}/assets/img/faq/faq-6.png`,
    src: `${urlPrefix}/assets/videos/EDUCATIONAL_06_IS_YOUR_HOME_A_GOOD_FIT_V2.mp4`,
  },
  {
    number: "06",
    title: "Can Solar Be Too Expensive?",
    trTitleKey: "solar_too_expensive",
    image: `${urlPrefix}/assets/img/faq/faq-7.png`,
    src: `${urlPrefix}/assets/videos/EDUCATIONAL_07_ZERO_DOWN_FINANCING_V2.mp4`,
  },
  {
    number: "07",
    title: "Demystifying Home Batteries",
    trTitleKey: "demystifying_home_batteries",
    image: `${urlPrefix}/assets/img/faq/faq-8.png`,
    src: `${urlPrefix}/assets/videos/EDUCATIONAL_09_BATTERIES_V2.mp4`,
  },
  {
    number: "08",
    title: "Two Options for Purchasing Your Energy",
    trTitleKey: "two_options_for_purchasing",
    image: `${urlPrefix}/assets/img/faq/faq-9.png`,
    src: `${urlPrefix}/assets/videos/EDUCATIONAL_10_YOUR_OPTIONS_WITH_POWUR_V2.mp4`,
  },
];

export default questionsList;
