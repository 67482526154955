import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "rdx/modules/workSchedules/messages";

function* getSchedulesByTeam(action) {
  const payload = {
    team_id: action.payload.teamId,
  };
  const { success, data, error } = yield* makeRequest.get(`/teams/${payload.team_id}/work_schedules`, payload);

  if (success && data) {
    const out = data.body.work_schedules.map(keysToCamelCase);
    yield all([put(actions.setSchedulesByTeam(out))]);
  } else if (error) {
    const errorMessage = messages.ERROR_GETTING_WORK_SCHEDULES;
    return getErrorActions({
      error,
      message: errorMessage,
    });
  }

  return null;
}

export default getSchedulesByTeam;
