import React from "react";

const SupportIcon = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="71" cy="71" r="71" fill="var(--lightest-grey)" />
      <mask
        id="mask0_4228_3077"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="142"
      >
        <circle cx="71" cy="71" r="71" fill="var(--lightest-grey)" />
      </mask>
      <g mask="url(#mask0_4228_3077)">
        <path
          d="M44.1243 61.8311C42.5471 54.6616 40.5095 47.3316 41.161 40.1016C42.1016 31.3963 53.0776 23.9944 60.7284 24C67.5761 24 74.424 24 81.2716 24C88.923 23.9954 99.8979 31.396 100.839 40.1016C101.491 47.3322 97.8749 61.8276 97.8749 61.8276C100.524 60.0158 107.31 62.634 103.765 70.5939C101.848 74.897 98.9938 76.612 97.24 77.5784C92.6444 90.6219 81.7881 97.0435 71 97.0435C60.2119 97.0435 49.3556 90.6219 44.7599 77.5784C43.0061 76.612 40.1522 74.897 38.2353 70.5939C35.1051 63.5668 40.027 60.7028 43.0465 61.3746C43.4473 61.4638 44.1243 61.8311 44.1243 61.8311Z"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M42.3042 53.623C42.3042 53.623 49.3423 43.5625 73.6085 52.6945C73.6085 52.6945 87.6536 59.9862 100.196 50.0858"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path d="M60.5652 84H81.4348" stroke="var(--royal-peacock-blue)" strokeWidth="3" strokeMiterlimit="10" />
        <path
          d="M71 108.418L86.6523 115.305V101.531L71 108.418Z"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M71.0003 108.418L55.3477 115.305V101.531L71.0003 108.418Z"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M55.3483 107.477L36.2181 109.8C21.8482 111.449 11 123.613 11 138.078V143.998H131V138.078C131 123.613 120.152 111.449 105.782 109.8L86.6521 107.477"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path d="M81.4336 103.465V94.9531" stroke="var(--royal-peacock-blue)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M60.5652 103.472V94.9531" stroke="var(--royal-peacock-blue)" strokeWidth="3" strokeMiterlimit="10" />
      </g>
    </svg>
  );
};

export default SupportIcon;
