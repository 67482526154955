export const roofTypes = [
  {
    value: "standard_asphalt",
    label: "Standard Asphalt",
  },
  { value: "flat_roof", label: "Flat Roof" },
  { value: "concrete_tile", label: "Concrete Tile" },
  { value: "clay_tile", label: "Clay Tile" },
  { value: "steel_shingle", label: "Steel Shingle" },
  { value: "metal", label: "Metal" },
  { value: "other", label: "Other" },
];

export const roofAge = [
  { value: "0 - 5", label: "0-5 Years" },
  { value: "6 - 10", label: "6-10 Years" },
  { value: "11 - 15", label: "11-15 Years" },
  { value: "16 - 20", label: "16-20 Years" },
  { value: "21+", label: "21+ Years" },
];

export const servicePanels = [
  { value: "amp_100", label: "100 Amp" },
  { value: "amp_125", label: "125 Amp" },
  { value: "amp_150", label: "150 Amp" },
  { value: "amp_200", label: "200 Amp" },
  { value: "amp_400", label: "400 Amp" },
];

export const mountingPreference = [
  { value: "roof", label: "Roof" },
  { value: "ground", label: "Ground" },
  { value: "both", label: "Both" },
];

export const financing = [
  { value: "cash", label: "Cash" },
  { value: "loan", label: "Loan" },
  { value: "lease", label: "Lease" },
];

export const workNeeded = [
  { value: "solar", label: "Solar" },
  { value: "solar_roof", label: "Solar + Roof" },
];

export const otherDetails = [
  // TEMP REMOVE PER POWUR 2/6/23
  // {
  //   label: "Reverse tilt on any roof face",
  //   value: "reverse_tilt_on_any_roof_face",
  // },
  {
    label: "Install on mobile, modular, or manufactured home",
    value: "install_on_mobile_modular_or_manufactured_home",
  },
  {
    label: "Install on patio, trellis, or gazebo (or similar)",
    value: "install_on_patio_trellis_or_gazebo_or_similar",
  },
  {
    label: "Other (non-solar) construction in progress",
    value: "other_non_solar_construction_in_progress",
  },
];
