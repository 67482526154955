import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./JoinModal.module.less";

const SuccessModal = (props) => {
  const { visible, setVisible, viewingAmbassadorJoin, viewingEntSellerJoin } = props;

  const { t } = useTranslation(["join", "ambassador", "enterprise_seller_join", "translation"]);

  let paragraph = t("modal.thank_you");

  if (viewingAmbassadorJoin) paragraph = t("ambassador:modal.successfully_registered");

  if (viewingEntSellerJoin) paragraph = t("enterprise_seller_join:modal.congratulations");

  const closeSuccessModal = () => {
    setVisible(false);
  };

  return (
    <Modal
      className={styles.successModal}
      title={
        <>
          <span className={styles.title}>{t("translation:thank_you")}</span>
        </>
      }
      open={visible}
      onCancel={closeSuccessModal}
      maskClosable
      footer={null}
      closeIcon={<CloseOutlined style={{ color: "white" }} />}
    >
      <div className={styles.paragraphContainer} style={{ whiteSpace: "pre-wrap" }}>
        {paragraph}
      </div>
      <div className={styles.footer}>
        <Button type="primary" onClick={closeSuccessModal} className={styles.button}>
          {t("translation:close")}
        </Button>
      </div>
    </Modal>
  );
};

SuccessModal.propTypes = {
  visible: PropTypes.bool,
  viewingAmbassadorJoin: PropTypes.bool,
  viewingEntSellerJoin: PropTypes.bool,
  setVisible: PropTypes.func,
};

export default SuccessModal;
