import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/preferredPartners/types";
import getPreferredPartners from "./getPreferredPartners";
import getPreferredPartnersAdmin from "./getPreferredPartnersAdmin";
import updatePreferredPartner from "./updatePreferredPartner";
import deletePreferredPartner from "./deletePreferredPartner";

import createPreferredPartner from "./createPreferredPartner";
// IMPORT_PT (for script, do not remove)

function* watchPreferredPartnersSagas() {
  yield trackRequests(takeEvery, types.GET_PREFERRED_PARTNERS, getPreferredPartners);
  yield trackRequests(takeEvery, types.GET_PREFERRED_PARTNERS_ADMIN, getPreferredPartnersAdmin);
  yield trackRequests(takeEvery, types.UPDATE_PREFERRED_PARTNER, updatePreferredPartner);
  yield trackRequests(takeEvery, types.DELETE_PREFERRED_PARTNER, deletePreferredPartner);
  yield trackRequests(takeEvery, types.CREATE_PREFERRED_PARTNER, createPreferredPartner);
  // INSERTION_PT (for script, do not remove)
}

export default watchPreferredPartnersSagas;
