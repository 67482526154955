import useRouteMatcher from "hooks/useRouteMatcher";

enum Paths {
  ADMIN_PROJECTS = "/admin-projects",
  PROJECTS = "/projects",
  ORG_PROJECTS = "/org-projects",
}

type ProjectPathsT = Paths.ADMIN_PROJECTS | Paths.PROJECTS | Paths.ORG_PROJECTS;

type PathMatcherResult = {
  projectPath: ProjectPathsT;
};

export default function usePathMatcher(): PathMatcherResult {
  const { isAdmin, isEnterprise } = useRouteMatcher();

  const getProjectPath = (): ProjectPathsT => {
    if (isAdmin) return Paths.ADMIN_PROJECTS;
    if (isEnterprise) return Paths.ORG_PROJECTS;
    return Paths.PROJECTS;
  };

  const projectPath = getProjectPath();

  return { projectPath };
}
