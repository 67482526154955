import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";
import types from "rdx/modules/dashboardUpdates/types";

import getDashboardUpdates from "./getDashboardUpdates";
import getSelectedDashboardUpdate from "./getSelectedDashboardUpdate";
import createDashboardUpdate from "./createDashboardUpdate";
import updateDashboardUpdate from "./updateDashboardUpdate";
import deleteDashboardUpdate from "./deleteDashboardUpdate";

// IMPORT_PT (for script, do not remove)

function* watchDashboardUpdatesSagas() {
  yield trackRequests(takeEvery, types.GET_DASHBOARD_UPDATES, getDashboardUpdates);

  yield trackRequests(takeEvery, types.CREATE_DASHBOARD_UPDATE, createDashboardUpdate);

  yield trackRequests(takeEvery, types.GET_DASHBOARD_UPDATE, getSelectedDashboardUpdate);

  yield trackRequests(takeEvery, types.UPDATE_DASHBOARD_UPDATE, updateDashboardUpdate);

  yield trackRequests(takeEvery, types.DELETE_DASHBOARD_UPDATE, deleteDashboardUpdate);

  // INSERTION_PT (for script, do not remove)
}

export default watchDashboardUpdatesSagas;
