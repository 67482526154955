import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import actionTypes from "rdx/types";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import messages from "rdx/modules/enterprisePersonnel/messages";
import rdxErrorMessages from "rdx/modules/platform/messages";

import { useEffectOnce } from "react-use";

import { parse, stringify } from "query-string";
import { formatDateTimeLong } from "lib/helpers";

import MessageEvent from "models/MessageEvent";

import { Button } from "antd";

import { BellIcon } from "@icons";
import HandsomeHeader from "@drawers/DrawerContainer/HandsomeHeader";
import DrawerContainer from "@drawers/DrawerContainer";

import styles from "./PlatformPageHeader.module.less";

const ReminderNotifications = (props) => {
  const {
    latestMessage,
    getReminderNotifications,
    getReminderNotificationsCount,
    setReminderNotificationsCount,
    reminderNotifications,
    toggleReminderNotificationViewedRequest,
    setAlertMessageVisible,
  } = props;

  // get "has notifications" hint from URL query if available
  const history = useHistory();
  const { search, pathname } = useLocation();
  const parsedSearch = parse(search);
  const { notifications: notificationHintFromUrlQuery } = parsedSearch;

  const [visible, setVisible] = useState(!!notificationHintFromUrlQuery || false);

  useEffectOnce(() => {
    getReminderNotificationsCount();

    // refresh notification count each hour
    const timeout = setTimeout(() => {
      getReminderNotificationsCount();
    }, 1000 * 60 * 60);

    if (notificationHintFromUrlQuery) {
      getReminderNotifications();
    }

    return () => clearTimeout(timeout);
  });

  useEffect(() => {
    const errorMessages = [
      rdxErrorMessages.ERROR_GETTING_REMINDER_NOTIFICATIONS,
      rdxErrorMessages.ERROR_GETTING_REMINDER_NOTIFICATIONS_COUNT,
      rdxErrorMessages.ERROR_TOGGLING_NOTIFICATION_VIEWED,
    ];

    // display error/success messages from redux here
    if (errorMessages.includes(latestMessage.message)) {
      setAlertMessageVisible({
        message: latestMessage.message,
        severity: "error",
      });
    }

    if (latestMessage.message === messages.ACCEPT_SEAT_INVITE_SUCCESS) {
      setAlertMessageVisible({
        message: latestMessage.message,
        severity: "success",
      });
      getReminderNotifications();
    }
  }, [getReminderNotifications, latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBellClick = () => {
    getReminderNotifications();
    setVisible(true);
  };

  const handleNotificationItemClick = (item) => {
    const { routeTo, pendingSeatId, id, viewed } = item || {};
    const joiner = search ? "&" : "?";

    if (pendingSeatId) {
      history.push({
        pathname,
        search: `${search}${joiner}seat_offer_modal=${pendingSeatId}`,
      });
    }

    if (routeTo) {
      if (routeTo.startsWith("?")) {
        history.push({
          pathname,
          search: `${search}${joiner}${routeTo.substring(1)}`,
        });
      } else {
        history.push(routeTo);
      }
    }

    // set notification as viewed and remove 1 from un-viewed notifications count
    if (!viewed) {
      toggleReminderNotificationViewedRequest({ id, viewed: !viewed });
      setReminderNotificationsCount(reminderNotifications.count > 0 ? reminderNotifications.count - 1 : 0);
    }

    setVisible(false);
  };

  const handleDrawerClose = () => {
    if (!notificationHintFromUrlQuery) return;

    delete parsedSearch.notifications;

    setTimeout(() => {
      history.push({
        pathname,
        search: stringify(parsedSearch),
      });
    }, 300);
  };

  const CounterBell = (
    <div
      className={styles.bellContainer}
      onClick={handleBellClick}
      onKeyDown={handleBellClick}
      role="button"
      tabIndex={0}
    >
      <div className={visible ? styles.bellBox : styles.bellBoxTransparent}>
        {reminderNotifications.unviewedCount > 0 && (
          <div className={styles.counter}>
            <span>{reminderNotifications.unviewedCount}</span>
          </div>
        )}
        <BellIcon />
      </div>
    </div>
  );

  if (reminderNotifications.count < 1) return null;

  return (
    <div className={styles.reminderNotificationsBell}>
      {CounterBell}
      <DrawerContainer
        visible={visible}
        setVisible={setVisible}
        width={540}
        onClose={handleDrawerClose}
        headerContent={
          <HandsomeHeader customCloseIcon={CounterBell} closeDrawer={() => setVisible(false)} title="Notifications" />
        }
        contentStyle={{ padding: 0 }}
      >
        <div className={styles.reminderNotificationsContainer}>
          {reminderNotifications &&
            reminderNotifications.notifications.map((rn) => {
              const { viewed } = rn;
              return (
                <Button key={rn.id} className={styles.notificationBox} onClick={() => handleNotificationItemClick(rn)}>
                  <div className={styles.left}>
                    <span className={viewed ? styles.viewedDot : styles.dot} />
                    <span className={viewed ? styles.viewedTitle : styles.title}>{rn.title}</span>
                  </div>
                  <div className={styles.right}>
                    <span className={viewed ? styles.viewedTime : styles.time}>{formatDateTimeLong(rn.updatedAt)}</span>
                  </div>
                </Button>
              );
            })}
        </div>
      </DrawerContainer>
    </div>
  );
};

ReminderNotifications.propTypes = {
  getReminderNotifications: PropTypes.func,
  getReminderNotificationsCount: PropTypes.func,
  setReminderNotificationsCount: PropTypes.func,
  toggleReminderNotificationViewedRequest: PropTypes.func,
  latestMessage: MessageEvent.types(),
  reminderNotifications: PropTypes.shape({
    notifications: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        viewed: PropTypes.bool,
      }),
    ),
    count: PropTypes.number,
    unviewedCount: PropTypes.number,
  }),
  setAlertMessageVisible: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    reminderNotifications: "selectReminderNotifications",
    latestMessage: "getLatestMessageEvt",
  }),
  getRdxActionMapper([
    "getReminderNotifications",
    "getReminderNotificationsCount",
    "setReminderNotificationsCount",
    "toggleReminderNotificationViewedRequest",
    "setAlertMessageVisible",
  ]),
)(ReminderNotifications);
