export default {
  GET_DASHBOARD_PROMOTIONS: "[dashboardPromotions] GET dashboardPromotions",

  SET_DASHBOARD_PROMOTIONS: "[dashboardPromotions] SET dashboardPromotions",

  GET_DASHBOARD_CAROUSEL_PROMOTIONS: "[dashboardCarouselPromotions] GET dashboardCarouselPromotions",

  SET_DASHBOARD_CAROUSEL_PROMOTIONS: "[dashboardCarouselPromotions] SET dashboardCarouselPromotions",

  CREATE_DASHBOARD_PROMOTION: "[dashboardPromotions] POST new dashboard promotion",

  GET_SELECTED_DASHBOARD_PROMOTION: "[dashboardPromotions] GET selected dashboard promotion",

  SET_SELECTED_DASHBOARD_PROMOTION: "[dashboardPromotions] SET selected dashboard promotion",

  UPDATE_SELECTED_DASHBOARD_PROMOTION: "[dashboardPromotions] UPDATE selected dashboard promotion",

  DELETE_SELECTED_DASHBOARD_PROMOTION: "[dashboardPromotions] DELETE selected dashboard promotion",

  // INSERTION_PT (for script, do not remove)
};
