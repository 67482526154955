import React from "react";
import { useTranslation } from "react-i18next";
import useWindowResize from "hooks/useWindowResize";
import NavItem from "./navItem";
import styles from "../../Team.module.less";

const SubNav = () => {
  const { t } = useTranslation("team");
  const { width } = useWindowResize();

  const items = [t("powur_team"), t("vision_mission_values"), t("join_powur")];
  const mobileItems = [t("team"), t("mission"), t("join")];

  return (
    <div className={styles.subNavContainer}>
      <div className={styles.subNav}>
        {items.map((item, i) => (
          <NavItem
            title={width < 500 ? mobileItems[i] : item}
            sectionLink={mobileItems[i].toLowerCase()}
            idx={i}
            key={item}
          />
        ))}
      </div>
    </div>
  );
};

export default SubNav;
