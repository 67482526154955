import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Router from "models/Router";
import { useLocation } from "react-router-dom";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";

import useNavigation from "hooks/useNavigation";

import ProviderLead from "models/ProviderLead";
import Project from "models/Project";

import ResetFiltersButton from "@buttons/ResetFilters";
import ProjectsListSidebar from "components/PlatformLayout/components/Sidebar/Filters/projectFilters";
import ContractsCSV from "./components/ContractsCSV";
import styles from "./EnterpriseSidebar.module.less";

const Sidebar = (props) => {
  const {
    isCollapsed,
    route,
    suggestions,
    enterpriseProjects,
    leads,
    setEnterpriseProjectsListAutoComplete: setSuggestions,
  } = props;

  const [isVisible, setIsVisible] = useState(false);

  const { search, pathname } = useLocation();

  const viewingContracts = pathname.includes("/contracts");
  const viewingLeads = pathname.includes("/leads");

  const { filters } = enterpriseProjects;
  const { filters: leadsFilters } = leads;

  const { basePath, requiredParams, optionalParams } = route;

  const { navigate } = useNavigation(basePath, requiredParams, optionalParams);

  const resetSuggestions = (name) => setSuggestions({ key: name, suggestions: null });

  useEffect(() => {
    if (isCollapsed) {
      setIsVisible(false);
    } else {
      setTimeout(() => setIsVisible(true), 150);
    }
  }, [isCollapsed]);

  const customSearchPopover = (
    <>
      <div className={styles.title}>Search by</div>
      <div className={styles.contentRow}>
        • <span className={styles.bold}>Homeowner </span>
        (First and/or Last Name)
      </div>
      {!viewingLeads && (
        <>
          <div className={styles.contentRow}>
            • <span className={styles.bold}>Homeowner ID </span>
          </div>
          <div className={styles.contentRow}>
            • <span className={styles.bold}>Address </span>
          </div>
        </>
      )}
    </>
  );
  return (
    <div style={{ display: isVisible ? "block" : "none" }} className={styles.enterpriseProjectsSidebar}>
      <div className={styles.filters}>
        <ProjectsListSidebar
          navigate={navigate}
          filters={{
            filters: viewingLeads ? leadsFilters : filters,
            sorts: null,
          }}
          suggestions={suggestions}
          setSuggestions={setSuggestions}
          search={search}
          resetSuggestions={resetSuggestions}
          customSearchPopover={customSearchPopover}
        />
      </div>
      <div className={styles.center}>
        <ResetFiltersButton />
      </div>
      <div className={styles.center}>{viewingContracts && <ContractsCSV />}</div>
    </div>
  );
};

Sidebar.propTypes = {
  route: Router.authRouteTypes(),
  isCollapsed: PropTypes.bool,
  enterpriseProjects: PropTypes.shape({
    projects: PropTypes.arrayOf(ProviderLead.types()),
    paging: PropTypes.shape({
      page_size: PropTypes.number,
      page_number: PropTypes.number,
      item_count: PropTypes.number,
    }),
    kpi: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    filters: Project.filterTypes(),
  }),
  leads: PropTypes.shape({
    leads: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        fullName: PropTypes.string,
        email: PropTypes.string,
        createdAt: PropTypes.string,
        apiUri: PropTypes.string,
        deletable: PropTypes.bool,
        owner: PropTypes.shape({
          id: PropTypes.number,
          fullName: PropTypes.string,
          email: PropTypes.string,
        }),
        lastEvent: PropTypes.shape({
          title: PropTypes.string,
          eventAt: PropTypes.string,
          provider: PropTypes.string,
        }),
      }),
    ),
    paging: PropTypes.shape({
      page_size: PropTypes.number,
      page_number: PropTypes.number,
      item_count: PropTypes.number,
    }),
    filters: Project.filterTypes(),
  }),
  suggestions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  setEnterpriseProjectsListAutoComplete: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    enterpriseProjects: "getEnterpriseProjectsList",
    leads: "getEnterpriseLeads",
    suggestions: "getEnterpriseProjectsAutoComplete",
  }),
  getRdxActionMapper(["setEnterpriseProjectsListAutoComplete"]),
)(Sidebar);
