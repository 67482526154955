import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getStripeInfo() {
  const { success, data, error } = yield* makeRequest.get("/stripe_connect/account", {});

  if (success && data) {
    yield put(actions.setStripeAccountInfo(keysToCamelCase(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_STRIPE_ACCOUNT_INFO,
    });
  }

  return null;
}

export default getStripeInfo;
