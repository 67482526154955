import React from "react";
import PropTypes from "prop-types";

const LifetimeAssignedInstalls = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.88878 14.031L2.64528 18.7357M2.63955 18.7357L1.33301 23.6754H7.71099H14.5417H20.9139L19.6131 18.7357L18.3696 14.031L17.4298 10.4724H13.3841H8.86854H4.82285L3.88305 14.031M19.3209 10.3807L20.2377 9.716L17.4241 7.65878L18.5071 4.31793H15.0173L13.94 1L11.1206 3.05723L8.30122 1L7.22963 4.31793H3.73979L4.82285 7.65304L2.00347 9.70454L2.92034 10.375M14.6964 25.4002L14.8626 27.2913L18.7306 28.0649L18.914 29.1135H3.33293L3.51631 28.0649L7.39008 27.2913L7.55053 25.4002H14.6964ZM7.40154 8.74183C7.52188 8.28913 7.72818 7.85934 8.02616 7.45248C8.64505 6.59292 9.48169 6.07717 10.5246 5.91099C11.5676 5.74481 12.5188 5.97402 13.3784 6.59864C14.1348 7.14876 14.6219 7.86507 14.8397 8.74183H7.40154Z"
        stroke={fill}
        strokeWidth="1.35"
        strokeMiterlimit="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.322 17.9727C13.3006 17.9535 13.2696 17.9496 13.2434 17.9617C13.2172 17.974 13.1999 18 13.1999 18.0293V19.2366H12.3222L11.1873 17.6562L10.4287 18.7121L11.5061 20.2116C11.6215 20.3721 11.809 20.4682 12.0065 20.4682H13.1999V21.6817C13.1999 21.7104 13.2172 21.7366 13.2434 21.749C13.2696 21.7613 13.3006 21.7573 13.322 21.738L15.4805 19.9119C15.4973 19.8981 15.5064 19.8771 15.5064 19.8554C15.5064 19.8336 15.4972 19.8127 15.4805 19.7985L13.322 17.9727Z"
        fill={fill}
      />
      <path
        d="M13.2001 15.3337V16.5471C13.2001 16.5758 13.2174 16.6022 13.2437 16.6144C13.2699 16.6266 13.3008 16.6226 13.3222 16.6036C13.3222 16.6036 15.4639 14.7916 15.4807 14.7774C15.4975 14.7631 15.5066 14.7426 15.5066 14.7208C15.5066 14.6991 15.4974 14.6784 15.4807 14.6643C15.464 14.6501 13.3223 12.8379 13.3223 12.8379C13.3009 12.8192 13.2699 12.8151 13.2438 12.8272C13.2175 12.8392 13.2002 12.8655 13.2002 12.8946V14.1022H11.9759C11.7784 14.1022 11.5908 14.1982 11.4755 14.3586L7.9731 19.2346H6.94752C6.84814 19.2346 6.76758 19.3152 6.76758 19.4145V20.2862C6.76758 20.3856 6.84814 20.4661 6.94752 20.4661H8.2888C8.48629 20.4661 8.67325 20.3699 8.78877 20.2095L12.2914 15.3337L13.2001 15.3337Z"
        fill={fill}
      />
      <path
        d="M9.09567 16.8538L9.85403 15.7983L8.81955 14.3583C8.70412 14.1977 8.51698 14.1016 8.31931 14.1016H6.92018C6.8208 14.1016 6.74023 14.1821 6.74023 14.2815V15.1531C6.74023 15.2525 6.8208 15.3331 6.92018 15.3331H8.00333L9.09567 16.8538Z"
        fill={fill}
      />
    </svg>
  );
};

LifetimeAssignedInstalls.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

LifetimeAssignedInstalls.defaultProps = {
  fill: "var(--eggplant)",
  width: "22",
  height: "30",
};

export default LifetimeAssignedInstalls;
