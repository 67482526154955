import React from "react";
import { IconT } from "types/utils";

const WrenchIcon = ({ width = 34, height = 35, fill = "none", ...rest }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 35" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect y="0.5" width="34" height="35" rx="17" fill="#8B0EA5" fillOpacity="0.13" />
      <g clipPath="url(#clip0_731_2392)">
        <path
          d="M20.9327 15.6696C21.235 15.7617 21.5443 15.8067 21.8515 15.8067C22.67 15.8067 23.4724 15.4863 24.0773 14.8814C25.0202 13.9385 25.2677 12.4965 24.6932 11.2933L24.6712 11.2472L23.2588 12.6596C22.8514 13.067 22.1908 13.067 21.7834 12.6596C21.3759 12.2521 21.3759 11.5915 21.7834 11.1841L23.1756 9.7919L23.1532 9.78175C21.9389 9.22975 20.5011 9.5049 19.5756 10.4665C19.1638 10.8943 18.8832 11.4292 18.7638 12.0133C18.668 12.4824 18.6792 12.9653 18.7949 13.4261L17.6432 14.5842L19.828 16.7803L20.9327 15.6696Z"
          fill="url(#paint0_linear_731_2392)"
        />
        <path
          d="M13.0672 19.3304C11.9597 18.993 10.7547 19.2866 9.92262 20.1186C8.97972 21.0615 8.73228 22.5035 9.30684 23.7067L9.34684 23.7905L10.7412 22.3961C11.1486 21.9887 11.8092 21.9887 12.2167 22.3961C12.6241 22.8036 12.6241 23.4642 12.2167 23.8716L10.863 25.2253C11.2757 25.4101 11.7136 25.5 12.1483 25.5C12.9889 25.4999 13.817 25.1645 14.4244 24.5335C14.8362 24.1057 15.1168 23.5708 15.2362 22.9866C15.332 22.5175 15.3208 22.0347 15.2051 21.5739L16.353 20.4195L14.1682 18.2234L13.0672 19.3304Z"
          fill="url(#paint1_linear_731_2392)"
        />
        <path
          d="M21.7596 19.0151C21.4457 19.0151 21.1376 19.0597 20.8399 19.148L15.3558 13.6354C15.4388 13.346 15.4808 13.0471 15.4808 12.7433C15.4808 10.9566 14.0272 9.50293 12.2404 9.50293C10.4537 9.50293 9 10.9566 9 12.7433C9 14.5301 10.4537 15.9837 12.2404 15.9837C12.5543 15.9837 12.8624 15.9391 13.1602 15.8508L18.6442 21.3634C18.5611 21.6529 18.5192 21.9517 18.5192 22.2555C18.5192 24.0423 19.9728 25.4959 21.7596 25.4959C23.5463 25.4959 25 24.0422 25 22.2555C25 20.4687 23.5464 19.0151 21.7596 19.0151ZM12.2404 14.083C11.5017 14.083 10.9008 13.482 10.9008 12.7433C10.9008 12.0047 11.5017 11.4037 12.2404 11.4037C12.9791 11.4037 13.58 12.0047 13.58 12.7433C13.5801 13.482 12.9791 14.083 12.2404 14.083ZM21.7596 23.5951C21.0209 23.5951 20.42 22.9941 20.42 22.2555C20.42 21.5168 21.0209 20.9158 21.7596 20.9158C22.4983 20.9158 23.0992 21.5168 23.0992 22.2555C23.0992 22.9941 22.4983 23.5951 21.7596 23.5951Z"
          fill="url(#paint2_linear_731_2392)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_731_2392"
          x1="27.0158"
          y1="9.5"
          x2="16.7155"
          y2="16.8818"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9911A3" />
          <stop offset="1" stopColor="#5904AC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_731_2392"
          x1="18.3677"
          y1="18.2234"
          x2="8.07273"
          y2="25.6014"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9911A3" />
          <stop offset="1" stopColor="#5904AC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_731_2392"
          x1="29.384"
          y1="9.50293"
          x2="6.83064"
          y2="25.5053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9911A3" />
          <stop offset="1" stopColor="#5904AC" />
        </linearGradient>
        <clipPath id="clip0_731_2392">
          <rect width="16" height="16" fill="white" transform="translate(9 9.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WrenchIcon;
