import tabs from "containers/Admin/AdminReports/tabs";
import breadCrumbs from "components/PlatformLayout/components/PlatformPageHeader/Breadcrumbs/AdminBreadCrumbs";
import { ReportsIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";

const content = l(() => import("containers/Admin/AdminReports"));

const { PLATFORM_CONFIGURATION, PROJECT_MANAGEMENT, LOGGED_ACTIONS } = permissionTypes;

export default {
  key: "admin-reports",
  title: breadCrumbs,
  path: "/admin-reports/:tab?/:userId?/:drawerTab?",
  exact: true,
  basePath: "admin-reports",
  requiredParams: ["tab"],
  optionalParams: ["userId", "drawerTab"],
  linkTarget: "/admin-reports",
  linkTitle: "Reports",
  linkCategory: "Admin",
  icon: ReportsIcon,
  tabs,
  content,
  permission: [PLATFORM_CONFIGURATION, LOGGED_ACTIONS, PROJECT_MANAGEMENT],
};
