import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";

function* getProjectLookupOptions(action) {
  const { search } = action.payload;

  const { success, data, error } = yield* makeRequest.get(`/provider_leads/projects_search?search=${search}`);

  if (success && data) {
    yield put(actions.setProjectLookupOptions(camelizeKeys(data.body.results)));
  } else if (error) {
    return getErrorActions({
      error,
    });
  }

  return null;
}

export default getProjectLookupOptions;
