import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

import { theme } from "styles/themes";

export const T3PartnerFiltersContainer = styled("div")(() => ({
  backgroundColor: theme.colors.white,
  padding: "16px",
  borderRadius: "0px 0px 12px 12px",
}));

export const ButtonContainer = styled(Grid)(() => ({
  display: "flex",
  alignItems: "flex-end",
  paddingBottom: "3px",
}));
