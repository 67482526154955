import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/projects/messages";
import { camelizeKeys } from "lib/helpers";

function* getKpiData(action) {
  const { userId, page, type } = action.payload;
  const endpoint = `/kpi/${type}`;

  const { success, data, error } = yield* makeRequest.get(endpoint, {
    user_id: userId,
    page_number: page,
  });

  if (success && data) {
    yield put(actions.setKpiData(camelizeKeys(data.body)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_KPI_DATA,
    });
  }
  return null;
}

export default getKpiData;
