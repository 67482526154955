import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/preferredPartners/messages";
import { camelizeKeys, keysToSnakeCase } from "lib/helpers";

function* createPreferredPartner(action) {
  const { onSuccess, ...requestPayload } = action.payload;
  const { success, data, error } = yield* makeRequest.post(
    "/enterprise_labor_partners",
    keysToSnakeCase(requestPayload),
  );

  if (success && data) {
    onSuccess?.();
    const list = camelizeKeys(data.body.preferred_partners);
    const paging = camelizeKeys(data.body.paging);

    yield all([
      put(actions.setPreferredPartners({ list, paging })),
      put(
        actions.newSuccessEvent({
          message: messages.ADD_PREFERRED_PARTNERS_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_PREFERRED_PARTNERS,
    });
  }

  return null;
}

export default createPreferredPartner;
