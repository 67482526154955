import React from "react";
import { useTranslation } from "react-i18next";
import Card from "./card";
import styles from "../../Solar.module.less";

const Process = () => {
  const { t } = useTranslation("whysolar");
  const urlPrefix = process.env.REACT_APP_S3_URL_PREFIX;
  return (
    <div className={styles.process}>
      <div className={styles.innerContainer}>
        <h5 className={styles.heading}>{t("process.how_does_it_work")}</h5>
        <h3 className={styles.subheading}>{t("process.explain")}</h3>
        <div className={styles.cardsContainer}>
          <Card
            title={t("process.collect_utility_bill")}
            imgSrc={`${urlPrefix}/assets/img/why-solar/whysolar_UtilityBill.jpg`}
          />
          <Card
            title={t("process.review_your_proposal")}
            imgSrc={`${urlPrefix}/assets/img/why-solar/whysolar_ReviewProposal.jpg`}
          />
          <Card
            title={t("process.select_best_option")}
            imgSrc={`${urlPrefix}/assets/img/why-solar/whysolar_SelectBest.jpg`}
          />
          <Card
            title={t("process.get_started")}
            imgSrc={`${urlPrefix}/assets/img/why-solar/whysolar_ScheduleInstall.jpg`}
          />
        </div>
      </div>
    </div>
  );
};

export default Process;
