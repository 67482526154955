import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions, selectors } from "rdx";
import actionTypes from "rdx/types";

import useLoading from "hooks/useLoading";
import { useSmartForm } from "hooks/useSmartForm";
import SmartForm from "components/SmartForm";

import { schema, blankFormData, PowurPairFormSchemaT } from "../PowurPairForm/powurPairFormData";
import { T3PartnerT, PowurPairT, PowurPairListT } from "../../types";
import PowurPairForm from "../PowurPairForm";
import PowurPairTopPicks from "../PowurPairTopPicks";

type PowurPairContainerPropsT = {
  selectedT3Partner: T3PartnerT | null;
  setSelectedT3Partner: (t3Partner: PowurPairT | null) => void;
  setOpenFooter: (bool: boolean) => void;
  powurPairFormView: boolean;
  setPowurPairFormView: (bool: boolean) => void;
  handleChangeDetail?: (subtitle?: string) => void;
  handleChangeSubtitle?: (subtitle?: string) => void;
  hideActionButtons?: boolean;
  powurPairSubmitRef?: React.RefObject<HTMLButtonElement>;
  rsmRequirementsPassed?: boolean;
};

const PowurPairContainer = ({
  selectedT3Partner,
  setSelectedT3Partner,
  setOpenFooter,
  powurPairFormView,
  setPowurPairFormView,
  handleChangeSubtitle = () => null,
  handleChangeDetail = () => null,
  hideActionButtons = false,
  powurPairSubmitRef,
  rsmRequirementsPassed = true,
}: PowurPairContainerPropsT) => {
  const dispatch = useDispatch();
  const [powurPairs, setPowurPairs] = useState<PowurPairListT | null>(null);
  const [selectedCriteria, setSelectedCriteria] = useState<PowurPairFormSchemaT>(blankFormData);

  const mentors = useSelector(selectors.selectPowurPairList);
  const projectDetails = useSelector(selectors.getProjectDetails);
  const loading = useLoading({
    watchRequests: [actionTypes.GET_MENTORS_LIST_REQUEST],
  });

  const { methods, getSmartFormProps } = useSmartForm<PowurPairFormSchemaT>({
    schema,
    useFormProps: { defaultValues: blankFormData },
  });

  const { watch, handleSubmit, reset } = methods;
  const values = watch();

  const handleSubmitForm = () => {
    setSelectedCriteria(values);
    const params = { ...values, powurPairForProject: true, projectId: projectDetails.providerLead.id };
    dispatch(actions.getMentorsList(params));

    setPowurPairFormView(false);
    handleChangeDetail("Results");
  };

  useEffect(() => {
    setPowurPairs(mentors);
  }, [mentors]); // eslint-disable-line react-hooks/exhaustive-deps

  const viewPreviousResults = () => {
    setPowurPairFormView(false);
    handleChangeDetail("Results");
  };

  return (
    <SmartForm {...getSmartFormProps({})}>
      {powurPairFormView ? (
        <PowurPairForm
          onSubmit={handleSubmit(handleSubmitForm)}
          powurPairs={!!powurPairs}
          previousResults={viewPreviousResults}
          powurPairSubmitRef={powurPairSubmitRef}
          hideActionButtons={hideActionButtons}
          rsmRequirementsPassed={rsmRequirementsPassed}
        />
      ) : (
        <PowurPairTopPicks
          backToForm={handleChangeSubtitle}
          isLoading={!!loading}
          selectedT3Partner={selectedT3Partner}
          setSelectedT3Partner={setSelectedT3Partner}
          powurPairCriteria={selectedCriteria}
          onSubmit={handleSubmit(handleSubmitForm)}
          setOpenFooter={setOpenFooter}
          powurPairResults={powurPairs}
          resetCriteria={reset}
        />
      )}
    </SmartForm>
  );
};

export default PowurPairContainer;
