export default {
  GET_REGISTRATION_INFO: "[registration] GET Registration Info",

  SET_REGISTRATION_INFO: "[registration] SET Registration Info",

  GET_SEATED_SELLER_REGISTRATION_INFO: "[registration] GET Seated Seller Registration Info",

  SET_SEATED_SELLER_REGISTRATION_INFO: "[registration] SET Seated Seller Registration Info",

  CREATE_NEW_USER: "[registration] POST Create New User",

  REGISTER_NEW_USER: "[registration] POST Register New User",

  RECRUIT_REJECT_PENDING_SEAT: "[registration] PATCH Reject Pending Seat",

  UPDATE_REGISTRATION: "[registration] PATCH Update Registration",
  // INSERTION_PT (for script, do not remove)
};
