import types from "rdx/modules/workSchedules/types";
import createAction from "rdx/utils/createAction";

export default {
  getSchedulesByTeam: (payload) => createAction(types.GET_SCHEDULES_BY_TEAM, payload),
  setSchedulesByTeam: (payload) => createAction(types.SET_SCHEDULES_BY_TEAM, payload),
  updateWorkSchedules: (payload) => createAction(types.UPDATE_WORK_SCHEDULES, payload),
  setWorkSchedules: (payload) => createAction(types.SET_WORK_SCHEDULES, payload),
  // INSERTION_PT (for script, do not remove)
};
