import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";
import types from "rdx/modules/viewedAssets/types";
import getViewedAssets from "./getViewedAssets";
import markAssetAsViewed from "./markAssetAsViewed";

// IMPORT_PT (for script, do not remove)

function* watchViewedAssetsSagas() {
  yield trackRequests(takeEvery, types.GET_VIEWED_ASSETS_TASKS_COMPLETION, getViewedAssets);
  yield trackRequests(takeEvery, types.MARK_TASK_AS_COMPLETE, markAssetAsViewed);

  // INSERTION_PT (for script, do not remove)
}

export default watchViewedAssetsSagas;
