class SettingsClient {
  setSettings(data) {
    const { localStorage } = window;
    localStorage.setItem("settings", JSON.stringify(data.body));
    return new SettingsClient();
  }

  get data() {
    return JSON.parse(localStorage.getItem("settings")) || {};
  }

  clear() {
    localStorage.removeItem("settings");
  }
}

export default SettingsClient;
