import { put, all, select } from "redux-saga/effects";
import { cloneDeep } from "lodash";

import getErrorActions from "rdx/utils/getErrorActions";
import makeRequest from "rdx/utils/makeRequest";
import actions from "rdx/actions";
import leadsSelectors from "rdx/modules/leads/selectors";
import preSalesSelectors from "rdx/modules/projectPreSales/selectors";
import enterpriseSelectors from "rdx/modules/enterpriseLeads/selectors";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* closeLead(action) {
  const { id, closeReasonId, closeNote } = action.payload;
  const params = {
    close_reason_id: closeReasonId,
    close_note: closeNote,
  };

  const { success, data, error } = yield* makeRequest.post(`/leads/${id}/close`, params);

  if (success && data) {
    const currentLeadsState = yield select(leadsSelectors.getLeads);
    const { leads } = currentLeadsState || [];
    const leadDetails = keysToCamelCase(data.body);
    const updatedLeads = leads.map((item) => (item.id === data.body.id ? leadDetails : item));

    const { projects: presalesProjects, paging: presalesPaging } = yield select(preSalesSelectors.getProjectPreSales);
    const idxOfUpdatedLead = presalesProjects.findIndex((proj) => proj.leadId === parseInt(id, 10));
    const updatedPreSales = cloneDeep(presalesProjects);
    updatedPreSales[idxOfUpdatedLead] = {
      ...updatedPreSales[idxOfUpdatedLead],
      closeAt: leadDetails.closeAt,
      closeReasonId: leadDetails.closeReasonId,
    };

    const enterpriseLeads = yield select(enterpriseSelectors.getEnterpriseLeads);
    const entProjectLeads = enterpriseLeads.leads.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          closeAt: leadDetails.closeAt,
        };
      }
      return el;
    });

    yield all([
      put(actions.setLeadDetails(leadDetails)),
      put(
        actions.setProjectsPreSales({
          projects: updatedPreSales,
          paging: presalesPaging,
        }),
      ),
      put(actions.setLeads({ ...currentLeadsState, leads: updatedLeads })),
      put(actions.setEnterpriseLeads({ ...enterpriseLeads, leads: entProjectLeads })),
      put(
        actions.newSuccessEvent({
          message: messages.CLOSE_LEAD_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CLOSING_LEAD,
    });
  }
  return null;
}

export default closeLead;
