import React, { CSSProperties } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { StandardTextFieldProps } from "@mui/material/TextField";

import Spacer from "components/Spacer";

import * as S from "./styles";

type FormTextFieldProps = {
  name: string;
  formLabel?: string;
  ht?: number;
  showSpacer?: boolean;
  labelStyles?: CSSProperties;
} & StandardTextFieldProps;

const FormTextField = ({ showSpacer = true, name, formLabel, labelStyles, ...rest }: FormTextFieldProps) => {
  const { control } = useFormContext();
  return (
    <>
      <S.StyledFormLabel style={labelStyles}>{formLabel}</S.StyledFormLabel>
      {showSpacer && <Spacer size={14} axis="vertical" />}
      <Controller
        name={name}
        control={control}
        defaultValue={rest.defaultValue || ""}
        render={({ field }) => <S.StyledTextField {...field} {...rest} />}
      />
    </>
  );
};

export type { FormTextFieldProps };

export default FormTextField;
