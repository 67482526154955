import { put, all, select } from "redux-saga/effects";
import { keysToCamelCase } from "lib/helpers";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "../messages";

function* requestAdminRevision(action) {
  const { id, reason, note, project_id } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/proposals/${id}/revision`, {
    reason,
    note,
    project_id,
  });

  if (success && data) {
    const proposalsList = yield select(selectors.getAdminProjectProposals);
    const adminListProjects = yield select(selectors.getAdminProjectsList);
    const { projects: adminProjects } = adminListProjects;

    const updatedAdminProject = adminProjects.find((project) => project.id === project_id);

    if (updatedAdminProject) {
      updatedAdminProject.latestProposalRejection = data.body;
    }
    const newProposal = keysToCamelCase(data.body);

    const out = proposalsList?.goSoloProposals.map((p) => {
      if (p.id === newProposal.id) {
        return newProposal;
      }
      return p;
    });

    const lightreachProposals = proposalsList.lightreachProposals.map((proposal) => {
      if (proposal.id === newProposal.id) {
        return newProposal;
      }

      return proposal;
    });

    yield all([
      put(
        actions.setAdminProjectProposals({
          goSoloProposals: out,
          lightreachProposals,
          visionPropsal: proposalsList.visionProposal,
        }),
      ),
      put(
        actions.setAdminProjectsList({
          ...adminProjects,
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.REVISION_REQUEST_SUBMIT_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_REQUESTING_ADMIN_REVISION,
    });
  }
  return null;
}

export default requestAdminRevision;
