import React from "react";
import { styled } from "@mui/material/styles";
import { theme } from "styles/themes";

type MissingSiteInfoBannerPropsT = {
  handleSiteInfoRedirect: () => void;
};

const MissingSiteInfoBanner = ({ handleSiteInfoRedirect }: MissingSiteInfoBannerPropsT) => {
  return (
    <ReqSiteInfoBanner tabIndex={0} role="button" onClick={handleSiteInfoRedirect} onKeyDown={handleSiteInfoRedirect}>
      <ReqSiteInfoIcon>!</ReqSiteInfoIcon>
      <ReqSiteInfoText>
        To submit a proposal request, please enter <Bold>kWh Consumption</Bold> on the <Bold>Qualify Lead</Bold> form
        first
      </ReqSiteInfoText>
    </ReqSiteInfoBanner>
  );
};

export const ReqSiteInfoBanner = styled("div")(() => ({
  position: "relative",
  zIndex: 1000,
  background: theme.colors.bridesmaid,
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.03)",
  borderRadius: "12px",
  padding: "14px 24px",
  marginBottom: "24px",
  marginTom: "16px",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    cursor: "pointer",
    background: theme.colors["fair-pink"],
  },
}));

export const ReqSiteInfoIcon = styled("div")(() => ({
  fontSize: "10px",
  lineHeight: "10px",
  fontWeight: 500,
  color: theme.colors.danger,
  border: `1px solid ${theme.colors.danger}`,
  minWidth: "14px",
  width: "14px",
  height: "14px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "12px",
}));

export const ReqSiteInfoText = styled("div")(() => ({
  fontSize: "14px",
  fontWeight: "500",
  color: theme.colors.danger,
  lineHeight: "17px",
}));

export const Bold = styled("span")(() => ({
  fontWeight: "700",
}));

export default MissingSiteInfoBanner;
