import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";
import { formatCompletedBlocker, removeCoBlockerFromModalList } from "lib/helpers";

const initialState = {
  projectsList: {
    projects: [],
    paging: {
      page_size: 50,
      item_count: 0,
      page_number: 1,
    },
    kpi: [],
  },
  projectsListFilters: {
    filters: {},
    sorts: [],
    autocompletes: {
      users: [],
      locations: [],
      utilities: [],
      installers: [],
      teams: [],
      selectedOption: null,
      selectedOptions: [],
    },
  },
  projectsListAutoComplete: {},
  projectDetails: {},
  projectNotes: [],
  proposals: {
    isCreateVisionReqSuccessful: false,
    goSoloProposals: [],
    lightreachProposals: [],
    visionProposals: [],
    visionProposal: {},
  },
  proposalSettings: {},
  qualifyComplete: false,
  projectLookupOptions: [],
  selectedProjectDetail: {},
};

export default {
  adminProjects: createReducer(initialState, {
    [types.SET_ADMIN_PROJECTS_LIST](state, action) {
      // response to updateProject for just one project update:

      if (action.payload.project) {
        const newState = state.projectsList.projects.map((item) => {
          if (item.id !== action.payload.project.id) {
            return { ...item };
          }

          return {
            ...item,
            ...action.payload.project,
          };
        });

        return {
          ...state,
          projectsList: {
            ...state.projectsList,
            projects: newState,
          },
        };
      }
      // response to getAdminProjectsList
      return {
        ...state,
        projectsList: {
          ...state.projectsList,
          ...action.payload,
        },
      };
    },

    [types.RESET_ADMIN_PROJECTS_LIST](state) {
      return {
        ...state,
        projectsList: initialState.projectsList,
        proposalSettings: initialState.proposalSettings,
      };
    },

    [types.RESET_ADMIN_PROJECT_DETAILS](state) {
      return {
        ...state,
        projectDetails: initialState.projectDetails,
      };
    },

    [types.SET_ADMIN_PROJECTS_LIST_FILTERS](state, action) {
      return {
        ...state,
        projectsListFilters: {
          ...state.projectsListFilters,
          ...action.payload,
        },
      };
    },

    [types.SET_ADMIN_PROJECTS_AUTOCOMPLETE_SUGGESTIONS](state, action) {
      const { key, suggestions } = action.payload;
      if (key) {
        return {
          ...state,
          projectsListAutoComplete: {
            ...state.projectsListAutoComplete,
            [key]: suggestions || [],
          },
        };
      }
      return state;
    },

    [types.SET_ADMIN_PROJECT_DETAILS](state, action) {
      return {
        ...state,
        projectDetails: action.payload,
        proposalSettings: {
          primarySeller: action.payload.agent || null,
          secondarySeller: action.payload.mentor || null,
        },
      };
    },

    [types.SET_PROJECT_MILESTONES](state, action) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          stages: action.payload.stages,
          data: action.payload.data,
        },
      };
    },

    [types.SET_PROJECT_DATES](state, action) {
      return {
        ...state,
        contractOut: action.payload.contractOut,
        proposalPresented: action.payload.proposalPresented,
      };
    },

    [types.SET_ADMIN_PROJECT_NOTES](state, action) {
      return {
        ...state,
        projectNotes: action.payload,
      };
    },

    [types.SET_ADMIN_PROJECT_PROPOSALS](state, action) {
      return {
        ...state,
        proposals: {
          ...state.proposals,
          ...action.payload,
        },
      };
    },
    [types.SET_CREATE_ADMIN_VISION_REQ_SUCCESS](state, action) {
      return {
        ...state,
        proposals: {
          ...state.proposals,
          isCreateVisionReqSuccessful: action.payload,
        },
      };
    },
    [types.SET_ADMIN_PROJECT_ADDRESS_GEOCODE](state, action) {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          lead: {
            ...state.projectDetails.lead,
            address: {
              ...state.projectDetails.lead.address,
              lat: action.payload.lat,
              long: action.payload.lng,
            },
          },
        },
      };
    },
    [types.SET_ADMIN_PROPOSAL_SETTINGS](state, action) {
      return {
        ...state,
        proposalSettings: action.payload,
      };
    },
    [types.SET_USERS_AUTOCOMPLETE_LOOKUP](state, action) {
      return {
        ...state,
        projectsListFilters: {
          ...state.projectsListFilters,
          autocompletes: {
            ...state.projectsListFilters.autocompletes,
            users: action.payload,
          },
        },
      };
    },
    [types.SET_LOCATIONS_AUTOCOMPLETE_LOOKUP](state, action) {
      return {
        ...state,
        projectsListFilters: {
          ...state.projectsListFilters,
          autocompletes: {
            ...state.projectsListFilters.autocompletes,
            locations: action.payload,
          },
        },
      };
    },
    [types.SET_INSTALLER_AUTOCOMPLETE_LOOKUP](state, action) {
      return {
        ...state,
        projectsListFilters: {
          ...state.projectsListFilters,
          autocompletes: {
            ...state.projectsListFilters.autocompletes,
            installers: action.payload,
          },
        },
      };
    },
    [types.SET_TEAMS_AUTOCOMPLETE_LOOKUP](state, action) {
      return {
        ...state,
        projectsListFilters: {
          ...state.projectsListFilters,
          autocompletes: {
            ...state.projectsListFilters.autocompletes,
            teams: action.payload,
          },
        },
      };
    },
    [types.SET_UTILITIES_AUTOCOMPLETE_LOOKUP](state, action) {
      return {
        ...state,
        projectsListFilters: {
          ...state.projectsListFilters,
          autocompletes: {
            ...state.projectsListFilters.autocompletes,
            utilities: action.payload,
          },
        },
      };
    },

    [types.SET_ADMIN_PROJECT_LOOKUP_OPTIONS](state, action) {
      return {
        ...state,
        projectLookupOptions: action.payload,
      };
    },

    [types.SET_SELECTED_PROJECT_DETAIL](state, action) {
      return {
        ...state,
        selectedProjectDetail: action.payload,
      };
    },

    [types.SET_CHANGE_ORDER_BLOCKER_COMPLETE_FOR_ADMIN](state) {
      if (state.projectDetails.crmActivities?.length) {
        const { updatedCrmActivities, updatedHistoricalActivityLog } = formatCompletedBlocker(
          state.projectDetails.crmActivities,
          state.projectDetails.activityLog.historicalData,
        );

        return {
          ...state,
          projectDetails: {
            ...state.projectDetails,
            crmActivities: updatedCrmActivities,
            activityLog: {
              ...state.projectDetails.activityLog,
              historicalData: updatedHistoricalActivityLog,
            },
          },
        };
      }
      return { ...state };
    },

    [types.REMOVE_CO_BLOCKER_FROM_MODAL_FOR_ADMIN](state) {
      if (state.projectDetails.crmActivities?.length) {
        const { updatedCrmActivities } = removeCoBlockerFromModalList(state.projectDetails.crmActivities);

        return {
          ...state,
          projectDetails: {
            ...state.projectDetails,
            crmActivities: updatedCrmActivities,
          },
        };
      }
      return { ...state };
    },
    [types.SET_AUTOCOMPLETE_SELECTED_OPTION](state, action) {
      return {
        ...state,
        projectsListFilters: {
          ...state.projectsListFilters,
          autocompletes: {
            ...state.projectsListFilters.autocompletes,
            selectedOption: action.payload,
          },
        },
      };
    },
    [types.SET_AUTOCOMPLETE_SELECTED_OPTIONS](state, action) {
      return {
        ...state,
        projectsListFilters: {
          ...state.projectsListFilters,
          autocompletes: {
            ...state.projectsListFilters.autocompletes,
            selectedOptions: action.payload,
          },
        },
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
