import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Checkbox } from "antd";
// import Checkbox from "antd";
import { useTranslation } from "react-i18next";
import FEATURE_FLAGS from "@flags";
import styles from "./EnterpriseProSignupModal.module.less";
import OfficersFields from "./OfficersFieldsList";

const OfficersForm = (props) => {
  const { form, formValues, laborPartnerCheck, hideLaborPartner } = props;
  const { TextArea } = Input;
  const { t } = useTranslation(["ent_join_form_labels"]);

  const handleCheckbox = (e) => {
    laborPartnerCheck(e.target.checked);
  };

  return (
    <>
      <Form
        form={form}
        className={styles.formContainer}
        requiredMark={false}
        colon={false}
        initialValues={{
          backgroundChecks: [
            {
              first_name: formValues.current.ownerFirstName,
              last_name: formValues.current.ownerLastName,
              phone: formValues.current.ownerPhone,
              email: formValues.current.ownerEmail,
            },
          ],
        }}
      >
        <div className={styles.subHeaderContainer}>
          <div className={styles.subHeader}>{t("business_details")}</div>
          <div className={styles.underline} />
        </div>
        <span className={styles.inputLabel}>{t("business_model")}</span>
        <div className={styles.inputSubLabel}>{t("business_model_description")}</div>
        <Form.Item
          name="sellerValue"
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: t("seller_value_required"),
            },
          ]}
          message={null}
        >
          <TextArea size="large" className={styles.input} name="last_name" />
        </Form.Item>
        <span className={styles.inputLabel}>{t("primary_owners")}</span>
        <div className={styles.inputSubLabel}>{t("list_owners")}</div>
        <OfficersFields />
      </Form>
      {FEATURE_FLAGS.includes("laborPartnerApplication") && !hideLaborPartner && (
        <div className={styles.laborCheckbox}>
          <Checkbox onChange={handleCheckbox}>{t("labor_partner_checkbox_text")}</Checkbox>
        </div>
      )}
    </>
  );
};

OfficersForm.propTypes = {
  // antd form instance
  form: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  // form.getFieldsValue()
  formValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  laborPartnerCheck: PropTypes.func,
  hideLaborPartner: PropTypes.bool,
};

export default OfficersForm;
