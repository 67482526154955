import createReducer from "rdx/utils/createReducer";
import types from "./types";

const initialState = {
  salesTeams: [],
  availableManagers: [],
  availableSellers: [],
  availableSupport: [],
};

export default {
  salesTeams: createReducer(initialState, {
    [types.SET_AVAILABLE_MANAGERS](state, action) {
      return {
        ...state,
        availableManagers: [...action.payload],
      };
    },
    [types.SET_AVAILABLE_SUPPORT](state, action) {
      return {
        ...state,
        availableSupport: [...action.payload],
      };
    },
    [types.SET_TEAMS](state, action) {
      return {
        ...state,
        salesTeams: action.payload,
      };
    },
    [types.SET_SELLERS](state, action) {
      return {
        ...state,
        availableSellers: action.payload,
      };
    },
    [types.POST_SALES_TEAMS](state, action) {
      return {
        ...state,
        salesTeams: [...state.salesTeams, { ...action.payload }],
      };
    },
    [types.POST_TEAM_ASSIGNMENT](state, action) {
      const teams = [...state.salesTeams].map((team) => {
        if (team.id === action.payload.id) return { ...action.payload };
        return team;
      });
      return {
        ...state,
        salesTeams: teams,
      };
    },

    // INSERTION_PT (for script, do not remove)
  }),
};
