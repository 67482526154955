import React from "react";
import { IconT } from "types/utils";

const FinancialIcon = ({ width = 34, height = 35, fill = "none", ...rest }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 35" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect y="0.5" width="34" height="34" rx="17" fill="#D6E1F0" fillOpacity="0.34" />
      <g clipPath="url(#clip0_755_2284)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 9.5C12.585 9.5 9 13.0848 9 17.5C9 21.9152 12.585 25.5 17 25.5C21.415 25.5 25 21.9152 25 17.5C25 13.0848 21.415 9.5 17 9.5ZM17 10.5C20.8625 10.5 24 13.6365 24 17.5C24 21.3635 20.8625 24.5 17 24.5C13.1375 24.5 10 21.3635 10 17.5C10 13.6365 13.1375 10.5 17 10.5ZM17 11.25C13.55 11.25 10.75 14.0505 10.75 17.5C10.75 20.9495 13.55 23.75 17 23.75C20.45 23.75 23.25 20.9495 23.25 17.5C23.25 14.0505 20.45 11.25 17 11.25ZM16.5 14.5177C15.6525 14.6392 15 15.369 15 16.25C15 17.2157 15.785 18 16.75 18H17.25C17.665 18 18 18.336 18 18.75C18 19.164 17.665 19.5 17.25 19.5H15.25V20.5H16.5V21.25H17.5V20.4823C18.3475 20.3608 19 19.631 19 18.75C19 17.7843 18.215 17 17.25 17H16.75C16.335 17 16 16.664 16 16.25C16 15.836 16.335 15.5 16.75 15.5H18.75V14.5H17.5V13.75H16.5V14.5177Z"
          fill="url(#paint0_linear_755_2284)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_755_2284"
          x1="14.5667"
          y1="25.2037"
          x2="2.54118"
          y2="9.96585"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20C2F1" />
          <stop offset="1" stopColor="#0070F4" />
        </linearGradient>
        <clipPath id="clip0_755_2284">
          <rect width="16" height="16" fill="white" transform="translate(9 9.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FinancialIcon;
