import React from "react";
import { cvar } from "styles";
import { IconT } from "types/utils";

const ContractsSignedGradient = ({
  height = "28",
  width = "28",
  stopColor = cvar("blue-ribbon"),
  startColor = cvar("picton-blue"),
}: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2055_13993)">
        <path
          d="M21.3014 4.78089C21.3014 4.12398 21.0451 3.50558 20.5804 3.04095L18.2605 0.721C17.8023 0.262773 17.1519 0 16.5046 0H16.3636V4.92188H21.3015L21.3014 4.78089Z"
          fill="url(#paint0_linear_2055_13993)"
        />
        <path
          d="M14.9809 16.9278C15.1379 16.4511 15.4119 16.0074 15.7692 15.6477L21.3234 10.1159V6.5625H15.5593C15.1059 6.5625 14.7656 6.1956 14.7656 5.74219V0H2.46094C1.10392 0 0 1.10392 0 2.46094V25.5391C0 26.8961 1.10392 28 2.46094 28H18.8406C20.1976 28 21.3234 26.8961 21.3234 25.5391V19.4167L20.4219 20.2963C20.0566 20.6584 19.6289 20.922 19.1466 21.083L16.5383 21.9538L16.1409 21.9755C15.484 21.9755 14.8624 21.6558 14.4763 21.1207C14.0934 20.588 13.9892 19.8974 14.1975 19.2734L14.9809 16.9278ZM4.10156 14.7656H12.2781C12.7315 14.7656 13.0984 15.1325 13.0984 15.5859C13.0984 16.0394 12.7315 16.4062 12.2781 16.4062H4.10156C3.64815 16.4062 3.28125 16.0394 3.28125 15.5859C3.28125 15.1325 3.64815 14.7656 4.10156 14.7656ZM3.28125 12.3047C3.28125 11.8513 3.64815 11.4844 4.10156 11.4844H12.2781C12.7315 11.4844 13.0984 11.8513 13.0984 12.3047C13.0984 12.7581 12.7315 13.125 12.2781 13.125H4.10156C3.64815 13.125 3.28125 12.7581 3.28125 12.3047ZM3.28125 18.8672C3.28125 18.4138 3.64815 18.0469 4.10156 18.0469H12.2781C12.7315 18.0469 13.0984 18.4138 13.0984 18.8672C13.0984 19.3206 12.7315 19.6875 12.2781 19.6875H4.10156C3.64815 19.6875 3.28125 19.3206 3.28125 18.8672ZM15.5593 22.9688C16.0127 22.9688 16.3796 23.3356 16.3796 23.7891C16.3796 24.2425 16.0128 24.6094 15.5593 24.6094H10.6374C10.184 24.6094 9.81712 24.2425 9.81712 23.7891C9.81712 23.3356 10.184 22.9688 10.6374 22.9688H15.5593ZM4.10156 9.84375C3.64815 9.84375 3.28125 9.47685 3.28125 9.02344C3.28125 8.57002 3.64815 8.20312 4.10156 8.20312H15.5593C16.0127 8.20312 16.3796 8.57002 16.3796 9.02344C16.3796 9.47685 16.0127 9.84375 15.5593 9.84375H4.10156Z"
          fill="url(#paint1_linear_2055_13993)"
        />
        <path
          d="M16.467 17.6533L15.7533 19.7944C15.6464 20.1151 15.9516 20.4202 16.2723 20.3131L18.4121 19.5984L16.467 17.6533Z"
          fill="url(#paint2_linear_2055_13993)"
        />
        <path
          d="M22.8379 10.8994L17.4354 16.3019L19.7654 18.6319C20.9864 17.4106 23.3623 15.0344 25.1676 13.2291L22.8379 10.8994Z"
          fill="url(#paint3_linear_2055_13993)"
        />
        <path
          d="M27.5179 8.54664C27.2296 8.25663 26.805 8.09082 26.3547 8.09082C25.9045 8.09082 25.48 8.25663 25.1899 8.54746L23.9979 9.73948L26.3275 12.0691C26.9801 11.4164 27.4312 10.9654 27.5196 10.8769C28.1604 10.2352 28.1604 9.18987 27.5179 8.54664Z"
          fill="url(#paint4_linear_2055_13993)"
        />
        <path
          d="M27.5195 10.8783L27.5197 10.8782C27.5224 10.8755 27.5236 10.8743 27.5258 10.8721C27.5236 10.8743 27.5224 10.8756 27.5195 10.8783Z"
          fill="url(#paint5_linear_2055_13993)"
        />
        <path
          d="M27.5249 10.8721C27.5274 10.8696 27.5303 10.8667 27.5317 10.8652C27.5302 10.8668 27.5274 10.8696 27.5249 10.8721Z"
          fill="url(#paint6_linear_2055_13993)"
        />
        <path
          d="M27.5316 10.866C27.5385 10.8592 27.5386 10.859 27.5316 10.866V10.866Z"
          fill="url(#paint7_linear_2055_13993)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2055_13993"
          x1="17.2299"
          y1="-2.05078"
          x2="21.5891"
          y2="-1.61394"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2055_13993"
          x1="3.74094"
          y1="-11.6667"
          x2="22.6452"
          y2="-10.2286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2055_13993"
          x1="16.2018"
          y1="16.536"
          x2="18.5685"
          y2="16.7723"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2055_13993"
          x1="18.792"
          y1="7.67755"
          x2="25.6185"
          y2="8.35941"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2055_13993"
          x1="24.7"
          y1="6.43322"
          x2="28.2329"
          y2="6.78822"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2055_13993"
          x1="27.5206"
          y1="10.8695"
          x2="27.5262"
          y2="10.87"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2055_13993"
          x1="27.5261"
          y1="10.8624"
          x2="27.5321"
          y2="10.863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2055_13993"
          x1="27.5325"
          y1="10.8587"
          x2="27.5371"
          y2="10.8591"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <clipPath id="clip0_2055_13993">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContractsSignedGradient;
