class StorageClient {
  setStorage(data, label) {
    const { localStorage } = window;
    const state = JSON.parse(localStorage.getItem(label));
    const newState = { ...state, ...data };
    localStorage.setItem(label, JSON.stringify(newState));
    return new StorageClient();
  }

  get inboxFilters() {
    return JSON.parse(localStorage.getItem("inboxFilters")) || {};
  }

  get projectFilters() {
    return JSON.parse(localStorage.getItem("projectFilters")) || {};
  }

  set chunkErrorWithExpiry(value) {
    if (value === true) {
      // set error yes and expires 10 seconds from now
      this.setStorage({ value, expires: new Date().getTime() + 10000 }, "chunk_failed");
    } else {
      this.clear("chunk_failed");
    }
  }

  get chunkFailed() {
    const chunkFailed = JSON.parse(localStorage.getItem("chunk_failed"));
    if (!chunkFailed) return null;
    if (!chunkFailed.value || !chunkFailed.expires) {
      this.chunkErrorWithExpiry = false;
      return null;
    }
    const isExpired = new Date().getTime() > chunkFailed.expires;
    if (isExpired) {
      this.chunkErrorWithExpiry = false;
      return null;
    }
    return chunkFailed.value;
  }

  clear(label) {
    localStorage.removeItem(label);
  }
}

export default StorageClient;
