export default {
  GET_CONSULTANT_DASHBOARD_SUCCESS: "Seller dashboard retrieved successfully",
  GET_DASHBOARD_SLICE_SUCCESS: "Dashboard route retrieved successfully",
  GET_PROGRESS_OVER_TIME_SUCCESS: "Progress Over Time retrieved successfully",
  GET_EARNINGS_BREAKDOWN_SUCCESS: "Earnings Breakdown retrieved successfully",
  GET_LEAD_ORIGIN_SUCCESS: "Lead Origin retrieved successfully",

  ERROR_GETTING_CONSULTANT_DASHBOARD: "There was an error retrieving the consultant dashboard",
  ERROR_GETTING_DASHBOARD_SLICE: "There was an error retrieving the dashboard route",
  ERROR_GETTING_PROGRESS_OVER_TIME: "There was an error retrieving lead progress information",
  ERROR_GETTING_EARNINGS_BREAKDOWN: "There was an error retrieving earnings breakdown information",
  ERROR_GETTING_LEAD_ORIGIN: "There was an error retrieving lead origin information",
  ERROR_GETTING_SALES_LEADERBOARD: "There was an error retrieving sales leaderboard",
  ERROR_GETTING_REFERRALS_LEADERBOARD: "There was an error retrieving referrals leaderboard",
  ERROR_GETTING_MENTORS_LEADERBOARD: "There was an error retrieving mentors leaderboard",
  ERROR_GETTING_PROJECT_ACTIVITY: "There was an error retrieving project activity",
};
