import { styled } from "@mui/material";
import { cvar } from "styles/colorVariables";

export const BannerContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  padding: "12px 16px",
  borderRadius: "4px",
  backgroundColor: cvar("cotton-boll"),
  marginBottom: "20px",
}));

export const IconContainer = styled("div")(() => ({
  paddingTop: "5px",
}));
