import makeRequest from "rdx/utils/makeRequest";

function* invalidateProposalCache(action) {
  const { projectId } = action.payload;
  yield* makeRequest.post(`/provider_leads/${projectId}/proposals/invalidate_cache`);

  // Intentionally don't show error to user since invalidating cache is not something they have control over.
  return null;
}

export default invalidateProposalCache;
