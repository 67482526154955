import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";
import { FundingProposalsReducerStateT, FundingAdminListT } from "types/Project/fundingProposal";

const initialState: FundingProposalsReducerStateT = {
  adminList: {
    list: [],
    paging: {
      itemCount: 0,
      pageNumber: 0,
      pageSize: 50,
      hasMore: false,
    },
  },
};

export default {
  fundingProposals: createReducer<FundingProposalsReducerStateT>(initialState, {
    [types.SET_ADMIN_FUNDING_PROPOSALS](state, action: { payload: FundingAdminListT }) {
      return {
        ...state,
        adminList: {
          ...state.adminList,
          list: action.payload.list,
          paging: action.payload.paging,
        },
      };
    },
    [types.RESET_FUNDING_PROPOSAL_DATA](state: FundingProposalsReducerStateT) {
      return {
        ...state,
        adminList: initialState.adminList,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
