import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";

import { camelizeKeys } from "lib/helpers";
import actions from "../actions";
import messages from "../messages";

function* getAutocompleteLookup(action) {
  const { params, endPoint, type } = action.payload;
  const { success, data, error } = yield* makeRequest.get(endPoint, params);
  if (success && data) {
    if (["user", "leadOwner", "leadSourceName", "tier3", "orgTeamUser", "teamUser"].includes(type))
      yield put(actions.setUsersAutoCompleteLookup(camelizeKeys(data.body.users)));
    if (type === "location") yield put(actions.setLocationsAutoCompleteLookup(camelizeKeys(data.body.cities)));
    if (type === "installer") yield put(actions.setInstallersAutoCompleteLookup(camelizeKeys(data.body.installers)));
    if (type === "team") yield put(actions.setTeamsAutoCompleteLookup(camelizeKeys(data.body.teams)));
    if (type === "utility") yield put(actions.setUtilitiesAutoCompleteLookup(camelizeKeys(data.body.utilities)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.GET_USERS_AUTOCOMPLETE_ERROR,
    });
  }
  return null;
}

export default getAutocompleteLookup;
