import React from "react";
import PropTypes from "prop-types";

const CaretLeftOutlined = (props) => {
  const { fill, height, width, containerStyles } = props;

  return (
    <div style={{ paddingTop: "0px", paddingRight: "0px", ...containerStyles }}>
      <svg width={width} height={height} viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.761931 3.59456C0.485402 3.79413 0.485402 4.20587 0.761931 4.40544L5.40753 7.75812C5.73821 7.99677 6.20013 7.76048 6.20013 7.35267L6.20013 0.647327C6.20013 0.239523 5.73821 0.00323558 5.40753 0.241885L0.761931 3.59456Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

CaretLeftOutlined.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

CaretLeftOutlined.defaultProps = {
  fill: "var(--dark-blue)",
  width: "7",
  height: "8",
};

export default CaretLeftOutlined;
