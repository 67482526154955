import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { camelizeKeys } from "lib/helpers";
import actions from "rdx/actions";
import messages from "../messages";

function* getProjectInterestRates(action) {
  const { state, price } = action.payload;
  const { success, data, error } = yield* makeRequest.get("/finance_products_hotsheet", {
    state,
    price,
  });
  if (success && data) {
    yield put(actions.setProjectInterestRates(camelizeKeys(data.body)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROJECT_INTEREST_RATES,
    });
  }
  return null;
}

export default getProjectInterestRates;
