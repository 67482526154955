import React, { useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";

import styles from "components/PlatformLayout/components/PlatformPageHeader/PlatformPageHeader.module.less";

const { Title } = Typography;

const SellerDashboardBreadCrumbs = () => {
  const history = useHistory();

  const eventsAndUpdatesPage = !!useRouteMatch("/dashboard/events-and-promos");
  const powurUpdatesPage = !!useRouteMatch("/dashboard/powur-updates");

  const breadcrumb = useCallback(() => {
    if (eventsAndUpdatesPage) {
      return "Events & Promos";
    }
    if (powurUpdatesPage) {
      return "Powur Updates";
    }

    return null;
  }, [eventsAndUpdatesPage, powurUpdatesPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBaseNavigate = () => {
    history.push("/dashboard");
  };

  const textStyle = {
    cursor: "pointer",
    textTransform: "capitalize",
    fontWeight: 600,
  };

  return (
    <>
      <Title className={styles.title} style={textStyle} onClick={handleBaseNavigate}>
        Dashboard
      </Title>
      {breadcrumb() && (
        <>
          <RightOutlined className={styles.title} style={{ margin: "0 10px" }} />
          <Title className={styles.title} style={textStyle}>
            {breadcrumb()}
          </Title>
        </>
      )}
    </>
  );
};

export default SellerDashboardBreadCrumbs;
