import { put } from "redux-saga/effects";
import { camelizeKeys, keysToCamelCase } from "lib/helpers";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { omit } from "lodash";
import actions from "../actions";
import messages from "../messages";

function* getDashboardPromotions(action) {
  const { active } = action.payload;
  const params = omit(action.payload, ["active"]);

  const requestUrl = active ? "/dashboard_promotions/active" : "/dashboard_promotions";

  const { success, data, error } = yield* makeRequest.get(requestUrl, params);

  if (success && data) {
    const out = {};
    const { filters, paging, promotions } = data.body;

    if (filters) {
      out.filters = keysToCamelCase(filters);
    }
    if (paging) {
      out.paging = camelizeKeys(paging);
    }
    if (promotions) {
      out.promotions = camelizeKeys(promotions);
    }

    yield put(actions.setDashboardPromotions(out));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.GET_EVENTS_AND_PROMOS_ERROR,
    });
  }
  return null;
}

export default getDashboardPromotions;
