import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "../types";
import getAdminProjectsList from "./getAdminProjectsList";
import getAdminProjectDetails from "./getAdminProjectDetails";
import getAdminProjectProposals from "./getAdminProjectProposals";
import requestAdminRevision from "./requestAdminRevision";
import getProjectMilestones from "./getProjectMilestones";
import updateProjectMilestones from "./updateProjectMilestones";
import updateProjectManagementUsers from "./updateProjectManagementUsers";
import updateProject from "./updateProject";
import updateAdminProjectLead from "./updateAdminProjectLead";
import getAdminProjectNotes from "./getProjectNotes";
import closeAdminProject from "./closeAdminProject";
import reopenAdminProject from "./reopenAdminProject";
import requestAdminProposal from "./requestAdminProposal";
import requestAdminVisionProposal from "./requestAdminVisionProposal";
import getAutocompleteLookup from "./getLookupAutocompleteFilter";
import requestAdminVisionRevision from "./requestAdminVisionRevision";
import getAdminProjectLookupOptions from "./getAdminProjectLookupOptions";
// IMPORT_PT (for script, do not remove)

function* watchAdminProjectsSagas() {
  yield trackRequests(takeEvery, types.GET_ADMIN_PROJECTS_LIST_REQUEST, getAdminProjectsList);
  yield trackRequests(takeEvery, types.GET_ADMIN_PROJECT_DETAILS_REQUEST, getAdminProjectDetails);
  yield trackRequests(takeEvery, types.UPDATE_PROJECT_MANAGEMENT_USERS_REQUEST, updateProjectManagementUsers);
  yield trackRequests(takeEvery, types.UPDATE_ADMIN_PROJECT_REQUEST, updateProject);
  yield trackRequests(takeEvery, types.UPDATE_ADMIN_PROJECT_LEAD_REQUEST, updateAdminProjectLead);
  yield trackRequests(takeEvery, types.GET_PROJECT_MILESTONES_REQUEST, getProjectMilestones);
  yield trackRequests(takeEvery, types.UPDATE_PROJECT_MILESTONES_REQUEST, updateProjectMilestones);
  yield trackRequests(takeEvery, types.GET_ADMIN_PROJECT_NOTES_REQUEST, getAdminProjectNotes);
  yield trackRequests(takeEvery, types.GET_ADMIN_PROJECT_PROPOSALS_REQUEST, getAdminProjectProposals);
  yield trackRequests(takeEvery, types.ADMIN_REVISION_REQUEST, requestAdminRevision);
  yield trackRequests(takeEvery, types.ADMIN_VISION_REVISION_REQUEST, requestAdminVisionRevision);
  yield trackRequests(takeEvery, types.CLOSE_ADMIN_PROJECT_REQUEST, closeAdminProject);
  yield trackRequests(takeEvery, types.REOPEN_ADMIN_PROJECT_REQUEST, reopenAdminProject);
  yield trackRequests(takeEvery, types.REQUEST_ADMIN_PROPOSAL, requestAdminProposal);
  yield trackRequests(takeEvery, types.REQUEST_ADMIN_VISION_PROPOSAL, requestAdminVisionProposal);
  yield trackRequests(takeEvery, types.GET_AUTOCOMPLETE_LOOKUP, getAutocompleteLookup);
  yield trackRequests(takeEvery, types.GET_ADMIN_PROJECT_LOOKUP_OPTIONS, getAdminProjectLookupOptions);
  // INSERTION_PT (for script, do not remove)
}

export default watchAdminProjectsSagas;
