import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Col, Input, Form } from "antd";
import actions from "rdx/actions";
import ResizableButton from "./resizableButton";
import AnimatedChevrons from "./animatedChevrons";
import styles from "../Education.module.less";

const EmailInput = (props) => {
  const { user: userSlug } = useParams();
  const dispatch = useDispatch();

  const { showHeader } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const [email, setEmail] = useState("");
  const [redBlur, setRedBlur] = useState(false);

  const onChange = (e) => {
    setEmail(e.target.value);
    setRedBlur(false);
  };

  const onSubmit = () => {
    if (!email) {
      dispatch(
        actions.setAlertMessageVisible({
          message: "Please enter an email address to continue!",
          severity: "error",
        }),
      );
      setRedBlur(true);
      return null;
    }
    form.validateFields().then(() => {
      return userSlug
        ? history.push(`/${userSlug}/education/why-solar${search ? `${search}&` : "?"}email=${email}`)
        : history.push(`/education/why-solar${search ? `${search}&` : "?"}email=${email}`);
    });
    return null;
  };

  const colSpan18 = { xs: 24, sm: 24, md: 18, lg: 18, xl: 18 };
  const colSpan6 = { xs: 24, sm: 24, md: 6, lg: 6, xl: 6 };

  return (
    <div className={styles.emailInput}>
      {showHeader && (
        <Row gutter={8} className={styles.headerRow}>
          <Col {...colSpan18}>
            <div className={styles.title}>Just enter your email to get started:</div>
          </Col>
          <Col {...colSpan6} className={styles.arrowCol}>
            <AnimatedChevrons size="small" />
          </Col>
        </Row>
      )}

      <Row gutter={16} className={styles.inputRow}>
        <Col className={styles.inputCol} {...colSpan18}>
          <Form form={form}>
            <Form.Item
              name="email"
              className={styles.formItem}
              validateTrigger={["onSubmit"]}
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input
                placeholder="Enter your Email"
                className={styles.input}
                style={{ boxShadow: redBlur ? "0 0 4px 0px red" : "" }}
                onChange={onChange}
              />
            </Form.Item>
          </Form>
        </Col>

        <Col className={styles.buttonCol} {...colSpan6}>
          <ResizableButton
            content="Watch Now"
            size="small"
            animation="pulse"
            containerClassName={styles.buttonContainer}
            onClick={onSubmit}
          />
        </Col>
      </Row>
    </div>
  );
};

export default EmailInput;
