const commonTabs = [
  {
    key: "workflows",
    label: "Workflows",
  },
  {
    key: "data",
    label: "Data",
  },
];

const adminTabs = [];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
