// React/Redux
import React from "react";
import { Row, Col, Form, Button } from "antd";
import { useTranslation } from "react-i18next";

// Components
import InputLabeled from "components/FormElements/InputLabeled";
import RemoveButton from "components/Buttons/RemoveButton";
import { PlusOutlined } from "components/Icons";

import styles from "./EnterpriseProSignupModal.module.less";

const OfficersFields = () => {
  const { t } = useTranslation(["ent_join_form_labels"]);
  const fieldSet = (field, fields, remove) => {
    const first = !fields.length || !fields.indexOf(field);
    return (
      <div key={field.key} className={styles.fieldsContainer}>
        <Row className={styles.row} gutter={16}>
          <Col span={12}>
            <InputLabeled
              label={t("first_name")}
              itemName={[field.name, "first_name"]}
              rules={[
                {
                  required: true,
                  message: t("first_name_required"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <InputLabeled
              label={t("last_name")}
              itemName={[field.name, "last_name"]}
              rules={[
                {
                  required: true,
                  message: t("last_name_required"),
                },
              ]}
            />
          </Col>
        </Row>
        <Row className={styles.positionHeldRow} gutter={16}>
          <Col span={24}>
            <InputLabeled
              label={t("position_held")}
              itemName={[field.name, "position_held"]}
              rules={[
                {
                  required: true,
                  message: t("position_held_required"),
                },
              ]}
            />
          </Col>
        </Row>
        <Row className={styles.row} gutter={16}>
          <Col span={12}>
            <InputLabeled
              label={t("email")}
              itemName={[field.name, "email"]}
              rules={[
                { required: true, message: t("valid_email") },
                {
                  type: "email",
                  message: t("valid_email"),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <InputLabeled
              label={t("phone")}
              itemName={[field.name, "phone"]}
              rules={[
                {
                  pattern: /^[0-9.\-+\s]+$/,
                  message: t("valid_phone"),
                },
                {
                  required: true,
                  message: t("valid_phone"),
                },
                {
                  min: 7,
                  message: t("valid_phone"),
                },
              ]}
            />
          </Col>
        </Row>
        {!first && (
          <Row className={styles.row} gutter={16}>
            <Col span={24} className={styles.center}>
              <RemoveButton handleClick={() => remove(field.name)} disabled={!fields.indexOf(field)} />
            </Col>
          </Row>
        )}
        {!first && <div className={styles.line} />}
      </div>
    );
  };

  return (
    <Form.List name="backgroundChecks">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => fieldSet(field, fields, remove))}
          <div className={styles.addButtonContainer}>
            <Button onClick={() => add()} className={styles.addButton}>
              <span className={styles.icon}>
                <PlusOutlined fill="var(--blue-ribbon)" />
              </span>
              <span>{t("add_additional")}</span>
            </Button>
          </div>
        </>
      )}
    </Form.List>
  );
};

OfficersFields.propTypes = {};

export default OfficersFields;
