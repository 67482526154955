import styled from "@emotion/styled";
import { Switch, FormControlLabel } from "@mui/material";
import { cvar } from "styles";

export const LabelGroupWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const StyledSectionLabel = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "12px",
  fontWeight: "700",
  lineHeight: "9px",
  marginBottom: "8px",
  textTransform: "uppercase",
  color: cvar("nepal-blue"),
}));

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  "& > .MuiFormControlLabel-label": {
    color: cvar("blue-bayoux"),
    fontWeight: "400",
    fontSize: "16px",
  },
}));

export const StyledSwitch = styled(Switch)(() => ({
  "& > .MuiSwitch-track": {
    background: cvar("nepal-blue"),
    opacity: 100,
  },
  "& > .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    background: cvar("harlequin-green"),
    opacity: 100,
  },
  "& > .Mui-checked>.MuiSwitch-thumb": {
    background: cvar("white"),
    border: `0.5px solid ${cvar("green-haze")}`,
  },
}));
