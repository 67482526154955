import React from "react";
import PowurPairIcon from "components/Icons/PowurPairIcon";
import * as S from "./styles";

type CloserBannerProps = {
  bannerText?: string;
  bannerHeight?: string;
  icon?: React.ReactNode;
};

const CloserBanner = ({ bannerText, bannerHeight = "145px", icon }: CloserBannerProps) => (
  <S.HeaderBanner style={{ height: bannerHeight }}>
    {icon || (
      <S.BannerIconContainer>
        <PowurPairIcon />
      </S.BannerIconContainer>
    )}
    {bannerText && <S.BannerText>{bannerText}</S.BannerText>}
  </S.HeaderBanner>
);

export default CloserBanner;
