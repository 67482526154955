import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "../../Team.module.less";

const UserCard = (props) => {
  const { name, title, linkedInLink, powurLink } = props;

  // Image files must be named [InsertUpperCamelCaseName].jpg and live in public/img/powurLeadership/
  const userClass = name.replace(/\s/g, "");

  return (
    <div className={styles.userCard}>
      <div className={styles.imageContainer}>
        <img
          className={[styles.userImage, styles[userClass]].join(" ")}
          src={`/img/powurLeadership/${userClass}.jpg`}
          alt={name}
        />
      </div>
      <div className={styles.textContainer}>
        <span className={styles.name}>{name}</span>
        <span className={styles.title}>{title}</span>
        {linkedInLink && (
          <Link to={linkedInLink} target="_blank" className={styles.professionalLink}>
            LINKEDIN
          </Link>
        )}
        {powurLink && (
          <>
            <span className={styles.spacer}>|</span>
            <Link to={powurLink} className={styles.professionalLink}>
              POWUR
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

UserCard.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  linkedInLink: PropTypes.string,
  powurLink: PropTypes.string,
};

export default UserCard;
