export default {
  GET_ENTERPRISE_EARNINGS_LIST: "[enterpriseEarnings]: Get Enterprise Earnings List",

  SET_ENTERPRISE_EARNINGS_LIST: "[enterpriseEarnings] SET Enterprise Earnings List",

  GET_ENTERPRISE_COMP_PLAN: "[enterpriseEarnings]: Get Enterprise Comp Plan",

  SET_ENTERPRISE_COMP_PLAN: "[enterpriseEarnings]: SET Enterprise Comp Plan",

  SET_ENTERPRISE_EARNINGS_FILTERS: "[enterpriseEarnings] SET Enterprise Earnings Filters",

  // INSERTION_PT (for script, do not remove)
};
