import React, { useState, useLayoutEffect } from "react";

const WindowDimensionContext = React.createContext({});

const WindowDimensionProvider = ({ children }) => {
  const [dim, setDim] = useState({ width: 0, height: 0 });
  const [isTabletWidth, setIsTabletWidth] = useState(true);
  const [isMobileWidth, setIsMobileWidth] = useState(true);
  const TABLET_BREAK_POINT = 768;
  const MOBILE_BREAK_POINT = 500;

  useLayoutEffect(() => {
    const updateDim = () => {
      setDim({ width: window.innerWidth, height: window.innerHeight });
      setIsTabletWidth(window.innerWidth <= TABLET_BREAK_POINT);
      setIsMobileWidth(window.innerWidth <= MOBILE_BREAK_POINT);
    };

    // initialize
    updateDim();

    window.addEventListener("resize", updateDim);
    return () => window.removeEventListener("resize", updateDim);
  }, []);

  return (
    <WindowDimensionContext.Provider
      value={{
        ...dim,
        isTabletWidth,
        TABLET_BREAK_POINT,
        isMobileWidth,
        MOBILE_BREAK_POINT,
      }}
    >
      {children}
    </WindowDimensionContext.Provider>
  );
};

export default WindowDimensionProvider;

export { WindowDimensionContext };
