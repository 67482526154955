import { put } from "redux-saga/effects";
import { camelizeKeys, keysToCamelCase } from "lib/helpers";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { omit } from "lodash";
import actions from "../actions";
import messages from "../messages";

function* getDashboardUpdates(action) {
  const { active } = action.payload;
  const params = omit(action.payload, ["active"]);

  const requestUrl = active ? "/dashboard_updates/active" : "/dashboard_updates";

  const { success, data, error } = yield* makeRequest.get(requestUrl, params);

  if (success && data) {
    const out = {};
    const { paging, updates, filters } = data.body;

    if (filters) {
      out.filters = keysToCamelCase(filters);
    }
    if (paging) {
      out.paging = camelizeKeys(paging);
    }
    if (updates) {
      out.updates = camelizeKeys(updates);
    }

    yield put(actions.setDashboardUpdates(out));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.GET_DASHBOARD_UPDATES_ERROR,
    });
  }
  return null;
}

export default getDashboardUpdates;
