// import createReducer from 'rdx/utils/createReducer';
import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  reminderNotifications: {
    notifications: [],
    count: 0,
    unviewedCount: 0,
  },
  activateAccountModalVisible: false,
  integrityTrainingModalVisible: false,
  sales: {
    dashboard: {},
    projects: {
      homeownerNotesDrawerVisible: false,
      homeownerNotesLeadId: undefined,

      proposalRequestType: null,
      proposalRequestId: null,
      proposalRequestProjectId: null,
      proposalRequestDrawerDisabled: false,

      projectDetailMiniHeaderActive: false,
      projectClosedBannerActive: false,
      leadToProposalDrawer: {
        isVisible: false,
        leadId: undefined,
        projectId: undefined,
        proposalType: "",
        initialTab: null,
        leadDetails: {},
        projectDetails: {},
        utilityCompanies: [],
        proposalRequestSubmitted: false,
      },
    },
    tickets: {},
    marketing: {},
    earnings: {},
    reports: {},
    team: {
      addLeadDrawerVisible: false,
      inviteDrawerVisible: false,
    },
  },
  powur: {
    dashboard: {},
    users: {},
    projects: {},
    notices: {},
    proposals: {},
    compensation: {},
    workflows: {},
    settings: {},
  },
  personal: {
    profile: {
      profileMentorshipTabVisible: false,
      profileOrganizationTabVisible: false,
    },
    account: {},
  },
  enterprise: {
    compensation: {},
    dashboard: {},
    earnings: {},
    personnel: {},
    projects: {},
    reports: {},
    settings: {},
  },
  gettingStarted: {
    services: [],
  },
  inbox: {
    inboxNotificationsCount: 0,
  },
  backgroundLinesUrl: null,
  containerOverFlow: "auto",
  bannersHeight: 0,
  alertMessage: {
    visible: false,
    message: "",
    severity: "success",
    duration: 2000,
  },
};

export default {
  platform: createReducer(initialState, {
    [types.SET_REMINDER_NOTIFICATIONS](state, action) {
      return {
        ...state,
        reminderNotifications: action.payload,
      };
    },

    [types.SET_REMINDER_NOTIFICATIONS_COUNT](state, action) {
      return {
        ...state,
        reminderNotifications: {
          ...state.reminderNotifications,
          ...action.payload,
        },
      };
    },

    [types.TOGGLE_REMINDER_NOTIFICATION_VIEWED](state, action) {
      const notifications = state.reminderNotifications.notifications.map((notification) =>
        notification.id !== action.payload.id ? notification : { ...notification, viewed: !notification.viewed },
      );
      return {
        ...state,
        reminderNotifications: {
          ...state.reminderNotifications,
          notifications,
        },
      };
    },

    [types.SET_LEAD_TO_PROPOSAL_DRAWER_VISIBLE](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            leadToProposalDrawer: {
              ...state.sales.projects.leadToProposalDrawer,
              isVisible: action.payload,
            },
          },
        },
      };
    },
    [types.SET_LEAD_TO_PROPOSAL_DRAWER_OPEN](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            leadToProposalDrawer: {
              ...state.sales.projects.leadToProposalDrawer,
              leadId: action.payload?.leadId,
              projectId: action.payload?.projectId,
              proposalType: action.payload?.proposalType,
              isVisible: action.payload?.visible,
              initialTab: action.payload?.initialTab || null,
            },
          },
        },
      };
    },
    [types.SET_LEAD_TO_PROPOSAL_DRAWER_LEAD_DETAILS](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            leadToProposalDrawer: {
              ...state.sales.projects.leadToProposalDrawer,
              leadDetails: action.payload,
            },
          },
        },
      };
    },
    [types.SET_LEAD_TO_PROPOSAL_DRAWER_PROJECT_DETAILS](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            leadToProposalDrawer: {
              ...state.sales.projects.leadToProposalDrawer,
              projectDetails: action.payload,
            },
          },
        },
      };
    },
    [types.SET_TEAM_LEAD_DRAWER_VISIBLE](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          team: {
            ...state.sales.team,
            addLeadDrawerVisible: action.payload,
          },
        },
      };
    },

    [types.SET_PROFILE_MENTORSHIP_TAB_VISIBLE](state, action) {
      return {
        ...state,
        personal: {
          ...state.personal,
          profile: {
            ...state.personal.profile,
            profileMentorshipTabVisible: action.payload,
          },
        },
      };
    },

    [types.SET_PROFILE_ORGANIZATION_TAB_VISIBLE](state, action) {
      return {
        ...state,
        personal: {
          ...state.personal,
          profile: {
            ...state.personal.profile,
            profileOrganizationTabVisible: action.payload,
          },
        },
      };
    },
    [types.SET_LEAD_TO_PROPOSAL_DRAWER_LEAD_ID](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            leadToProposalDrawer: {
              ...state.sales.projects.leadToProposalDrawer,
              leadId: action.payload.id,
            },
          },
        },
      };
    },
    [types.SET_LEAD_TO_PROPOSAL_DRAWER_PROJECT_ID](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            leadToProposalDrawer: {
              ...state.sales.projects.leadToProposalDrawer,
              projectId: action.payload.id,
            },
          },
        },
      };
    },
    [types.SET_LEAD_TO_PROPOSAL_DRAWER_PROPOSAL_TYPE](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            leadToProposalDrawer: {
              ...state.sales.projects.leadToProposalDrawer,
              proposalType: action.payload.proposalType,
            },
          },
        },
      };
    },
    [types.SET_LEAD_TO_PROPOSAL_DRAWER_UTILITIES](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            leadToProposalDrawer: {
              ...state.sales.projects.leadToProposalDrawer,
              utilityCompanies: action.payload,
            },
          },
        },
      };
    },
    [types.SET_LEAD_TO_PROPOSAL_DRAWER_LEAD_QUALIFIED](state) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            leadToProposalDrawer: {
              ...state.sales.projects.leadToProposalDrawer,
              leadDetails: {
                ...state.sales.projects.leadToProposalDrawer.leadDetails,
                submitted: true,
                isQualified: true,
              },
            },
          },
        },
      };
    },
    [types.SET_LEAD_TO_PROPOSAL_DRAWER_PROPOSAL_REQUESTED](state) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            leadToProposalDrawer: {
              ...state.sales.projects.leadToProposalDrawer,
              proposalRequestSubmitted: true,
            },
          },
        },
      };
    },
    [types.SET_LEAD_TO_PROPOSAL_DRAWER_ADDRESS_GEOCODE](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            leadToProposalDrawer: {
              ...state.sales.projects.leadToProposalDrawer,
              leadDetails: {
                ...state.sales.projects.leadToProposalDrawer.leadDetails,
                address: {
                  ...state.sales.projects.leadToProposalDrawer.leadDetails.address,
                  lat: action.payload.lat,
                  long: action.payload.lng,
                },
              },
            },
          },
        },
      };
    },
    [types.SET_LEAD_TO_PROPOSAL_DRAWER_ADDRESS_CONFIRMED](state) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            leadToProposalDrawer: {
              ...state.sales.projects.leadToProposalDrawer,
              leadDetails: {
                ...state.sales.projects.leadToProposalDrawer.leadDetails,
                isAddressConfirmed: true,
              },
            },
          },
        },
      };
    },
    [types.CLEAR_LEAD_TO_PROPOSAL_DRAWER](state) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            leadToProposalDrawer: {
              leadId: undefined,
              projectId: undefined,
              proposalType: "",
              initialTab: null,
              leadDetails: {},
              projectDetails: {},
              utilityCompanies: [],
              proposalRequestSubmitted: false,
            },
          },
        },
      };
    },

    [types.SET_PLATFORM_SERVICES](state, action) {
      return {
        ...state,
        gettingStarted: {
          ...state.gettingStarted,
          services: [...action.payload],
        },
      };
    },

    [types.SET_ACTIVATE_ACCOUNT_MODAL_VISIBLE](state, action) {
      return {
        ...state,
        activateAccountModalVisible: action.payload,
      };
    },

    [types.SET_INBOX_NOTIFICATIONS](state, action) {
      return {
        ...state,
        inbox: {
          inboxNotificationsCount: action.payload,
        },
      };
    },

    [types.SET_BACKGROUND_LINES_URL](state, action) {
      return {
        ...state,
        backgroundLinesUrl: action.payload,
      };
    },

    [types.SET_OVERFLOW](state, action) {
      return {
        ...state,
        containerOverFlow: action.payload,
      };
    },

    [types.SET_INVITE_DRAWER_VISIBLE](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          team: {
            ...state.sales.team,
            inviteDrawerVisible: action.payload,
          },
        },
      };
    },

    [types.SET_HOMEOWNER_NOTES_DRAWER_VISIBLE](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            homeownerNotesDrawerVisible: action.payload,
          },
        },
      };
    },
    [types.SET_HOMEOWNER_NOTES_LEAD_ID](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            homeownerNotesLeadId: action.payload.id,
          },
        },
      };
    },
    [types.CLEAR_HOMEOWNER_NOTES_LEAD_ID](state) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,

            homeownerNotesLeadId: initialState.sales.projects.homeownerNotesLeadId,
          },
        },
      };
    },
    [types.SET_BANNERS_HEIGHT](state, action) {
      return {
        ...state,
        bannersHeight: action.payload,
      };
    },

    [types.SET_INTEGRITY_TRAINING_MODAL_VISIBLE](state, action) {
      return {
        ...state,
        integrityTrainingModalVisible: action.payload,
      };
    },

    [types.SET_PROPOSAL_REQUEST_DRAWER_VISIBLE](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            proposalRequestType: action.payload.requestType || null,
            proposalRequestProjectId: action.payload.projectId || null,
            proposalRequestDrawerDisabled: action.payload.disabled || false,
          },
        },
      };
    },
    [types.SET_PROPOSAL_REQUEST_DRAWER_ID](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            proposalRequestId: action.payload,
          },
        },
      };
    },
    [types.CLEAR_PROPOSAL_REQUEST_DRAWER_ID](state) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            proposalRequestId: null,
          },
        },
      };
    },
    [types.SET_ALERT_MESSAGE_VISIBLE](state, action) {
      return {
        ...state,
        alertMessage: {
          visible: true,
          message: action.payload?.message,
          severity: action.payload?.severity,
          duration: action.payload?.duration || 2000,
        },
      };
    },
    [types.CLEAR_ALERT_MESSAGE](state) {
      return {
        ...state,
        alertMessage: initialState.alertMessage,
      };
    },
    [types.SET_PROJECT_MINI_HEADER_ACTIVE](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            projectDetailMiniHeaderActive: action.payload,
          },
        },
      };
    },
    [types.SET_PROJECT_CLOSED_BANNER_ACTIVE](state, action) {
      return {
        ...state,
        sales: {
          ...state.sales,
          projects: {
            ...state.sales.projects,
            projectClosedBannerActive: action.payload,
          },
        },
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
