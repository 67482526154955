import React, { CSSProperties } from "react";
import { TextFieldProps } from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { useController } from "react-hook-form";
import { useSmartFormContext } from "components/SmartForm";
import { cvar } from "styles";
import HelperText from "../HelperText";
import * as S from "./styles";

type SmartFormPrefixInputProps = {
  label?: string;
  name: string;
  width?: string;
  helperText?: string | undefined;
  error?: boolean;
  disabled?: boolean;
  labelStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  prefix?: string;
  placeholder?: string;
} & TextFieldProps;

const SmartFormPrefixInput = ({
  label,
  name,
  width = "100%",
  helperText,
  error,
  disabled = false,
  labelStyle = {},
  containerStyle = {},
  prefix = "",
  placeholder = "",
  ...rest
}: SmartFormPrefixInputProps) => {
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });
  return (
    <S.InputContainer style={containerStyle}>
      <S.LabelGroupWrapper>
        <S.StyledFormLabel style={labelStyle}>{label}</S.StyledFormLabel>
      </S.LabelGroupWrapper>
      <S.StyledTextField
        inputProps={{
          style: {
            color: cvar("dark-blue"),
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "17px",
          },
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <S.StyledPrefix>{prefix}</S.StyledPrefix>
            </InputAdornment>
          ),
        }}
        disabled={disabled}
        size="small"
        defaultValue={rest.defaultValue}
        placeholder={placeholder}
        sx={{
          backgroundColor: "white",
          borderRadius: "4px",
          marginBottom: "0px",
          width: `${width}`,
        }}
        {...field}
        {...rest}
      />
      <HelperText sx={{ marginTop: "-10px" }} error={error}>
        {helperText}
      </HelperText>
    </S.InputContainer>
  );
};

export default SmartFormPrefixInput;
