import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const ProposalRequestCreatedIcon = ({
  width = "114",
  height = "136",
  startColor = cvar("picton-blue"),
  stopColor = cvar("blue-ribbon"),
}: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 136 114">
      <path
        d="M117.736 31.2097L133.344 43.97V106.242C133.344 109.267 130.892 111.719 127.867 111.719H32.5704C29.5457 111.719 27.0938 109.267 27.0938 106.242V43.97L42.7027 31.2097"
        stroke="url(#paint0_linear_8_3256)"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0938 44.3541L80.2187 84.7456L133.344 44.3541"
        stroke="url(#paint1_linear_8_3256)"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.8438 2.28111H112.258C115.283 2.28111 117.735 4.73309 117.735 7.75803V56.3074"
        stroke="url(#paint2_linear_8_3256)"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.7026 56.3074V7.75803C42.7026 4.73336 45.1546 2.28111 48.1796 2.28111H69.5937"
        stroke="url(#paint3_linear_8_3256)"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4844 76.115H27.0937"
        stroke="url(#paint4_linear_8_3256)"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.65625 93.115H17"
        stroke="url(#paint5_linear_8_3256)"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.1137 38.1387C99.1137 48.5741 90.654 57.0337 80.2187 57.0337C69.7834 57.0337 61.3237 48.5741 61.3237 38.1387C61.3237 27.7034 69.7834 19.2438 80.2187 19.2438C90.654 19.2438 99.1137 27.7034 99.1137 38.1387Z"
        stroke="url(#paint6_linear_8_3256)"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.1197 34.025L78.8919 42.2527L74.2031 37.5636"
        stroke="url(#paint7_linear_8_3256)"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.9502 109.591L65.0016 73.5394"
        stroke="url(#paint8_linear_8_3256)"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.487 109.591L95.436 73.5394"
        stroke="url(#paint9_linear_8_3256)"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8_3256"
          x1="66"
          y1="144.5"
          x2="124.564"
          y2="110.892"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8_3256"
          x1="91"
          y1="151"
          x2="144.999"
          y2="47.981"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8_3256"
          x1="108.5"
          y1="113"
          x2="128.471"
          y2="105.79"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8_3256"
          x1="35"
          y1="161.5"
          x2="64.9696"
          y2="157.754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8_3256"
          x1="1.5"
          y1="105"
          x2="0.276803"
          y2="52.6024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8_3256"
          x1="-6.5"
          y1="118.5"
          x2="-14.3975"
          y2="71.9365"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint6_linear_8_3256"
          x1="133.5"
          y1="118.5"
          x2="164.81"
          y2="80.2532"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint7_linear_8_3256"
          x1="91.5"
          y1="73.5"
          x2="110.992"
          y2="56.6528"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint8_linear_8_3256"
          x1="55.5"
          y1="110"
          x2="93.1938"
          y2="94.9108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint9_linear_8_3256"
          x1="101.761"
          y1="124.612"
          x2="130.788"
          y2="110.474"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProposalRequestCreatedIcon;
