import React from "react";
import CloseIcon from "components/Icons/CloseIcon";
import styles from "./styles.module.less";

interface GradientComponentPropsI {
  height: number;
  width: number;
  disabled: boolean;
}

const GradientComponent = ({ height, width, disabled = false }: GradientComponentPropsI) => {
  if (disabled)
    return (
      <span className={styles.disabledGradientButton}>
        <CloseIcon width={width} height={height} />
        <span className={styles.disabledCover} />
      </span>
    );
  return (
    <span className={styles.gradientButton}>
      <CloseIcon width={width} height={height} />
    </span>
  );
};

export default GradientComponent;
