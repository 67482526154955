import React, { useContext, useState } from "react";
// import PropTypes from "prop-types";
import { Row, Typography, Tooltip } from "antd";
import copy from "copy-to-clipboard";
// import useWindowResize from "hooks/useWindowResize";
import ScriptIcon from "components/Icons/scriptIcon";
import QuestionHouseIcon from "components/Icons/questionHouseIcon";
import RightCaretFilled from "components/Icons/Chevrons/RightCaretFilled";
import { AmbassadorDashboardContext as context } from "containers/Ambassador/dashboard";
import { facebookText, twitterText } from "lib/misc/social";
import SocialButtons from "../Social";

const Footer = () => {
  const { user, styles, leads, tabKeys, activeTabKey, canCreateLead } = useContext(context);

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const tooltipTimeout = "1000";

  const handleClick = (e, shareUrl) => {
    setTooltipText("Link copied to clipboard");
    e.stopPropagation();
    copy(shareUrl);
    setTooltipVisible(true);
    setTimeout(() => setTooltipVisible(false), tooltipTimeout);
  };

  const shareUrl = new URL(`${window.location.origin.toString()}/whysolar`);
  if (user) {
    shareUrl.searchParams.append("ref_id", user.props.id);
  }

  const educationUrl = new URL(`${window.location.origin.toString()}/education`);
  if (user) {
    educationUrl.searchParams.append("ref_id", user.props.id);
  }

  const ambassadorScriptLink = "https://docs.google.com/document/d/1_R8LVdBcVZMSyxQ5s0oXrhD25Cgq-U73ncUz8RfTg5s/edit";

  const homeownerFAQLink = "https://docs.google.com/document/d/1K_z_URE3SkKl5mI0bXp7CcVcZ8GewR3jZ2Vkf1NxHXk/edit";

  return (
    <>
      <Row className={styles.prefooter}>
        <div
          className={styles.linkRow}
          onClick={() => window.open(ambassadorScriptLink, "_blank")}
          onKeyDown={() => window.open(ambassadorScriptLink, "_blank")}
          tabIndex={0}
          role="button"
        >
          <div className={styles.linkTitle}>
            <ScriptIcon />
            <div className={styles.name}>Initial Interest Script</div>
          </div>
          <RightCaretFilled />
        </div>
        <div className={styles.divider} />
        <div
          className={styles.linkRow}
          onClick={() => window.open(homeownerFAQLink, "_blank")}
          onKeyDown={() => window.open(homeownerFAQLink, "_blank")}
          tabIndex={0}
          role="button"
        >
          <div className={styles.linkTitle}>
            <QuestionHouseIcon />
            <div className={styles.name}>Homeowner FAQ</div>
          </div>
          <RightCaretFilled />
        </div>
      </Row>
      {canCreateLead && (
        <Row className={styles.footer}>
          <SocialButtons shareUrl={shareUrl} facebookShareText={facebookText} twitterShareText={twitterText} round />
          {activeTabKey !== tabKeys[0] || (activeTabKey === tabKeys[0] && leads.length) ? (
            <Tooltip
              title={tooltipText}
              placement="top"
              trigger="click"
              open={tooltipVisible}
              color="rgba(41, 92, 160, 0.9)"
            >
              <div className={styles.linkDisplay}>
                <Typography.Paragraph
                  ellipsis={{
                    expandable: false,
                    rows: 1,
                  }}
                  onClick={(e) => handleClick(e, shareUrl)}
                >
                  <span className={styles.label}>Referral: </span>
                  <span className={styles.url}>{shareUrl.href}</span>
                </Typography.Paragraph>
              </div>
              <div className={styles.linkDisplay}>
                <Typography.Paragraph
                  ellipsis={{
                    expandable: false,
                    rows: 1,
                  }}
                  onClick={(e) => handleClick(e, educationUrl)}
                >
                  <span className={styles.label}>Education: </span>
                  <span className={styles.url}>{educationUrl.href}</span>
                </Typography.Paragraph>
              </div>
            </Tooltip>
          ) : (
            ""
          )}
        </Row>
      )}
    </>
  );
};

export default Footer;
