import React from "react";
import PropTypes from "prop-types";

const DailyCalendar = (props) => {
  const { height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="11" r="11" fill="var(--dark-blue)" />
      <path
        d="M15.2948 6.94727V7.5335C15.2948 7.95062 14.9566 8.30011 14.5282 8.30011C14.1111 8.30011 13.7616 7.9619 13.7616 7.5335V6.94727H12.9499V7.5335C12.9499 7.95062 12.6117 8.30011 12.1833 8.30011C11.7661 8.30011 11.4167 7.9619 11.4167 7.5335V6.94727H10.6049V7.5335C10.6049 7.95062 10.2667 8.30011 9.83834 8.30011C9.42121 8.30011 9.07173 7.9619 9.07173 7.5335V6.94727H8.26002V7.5335C8.26002 7.95062 7.92181 8.30011 7.49341 8.30011C7.06501 8.30011 6.7268 7.9619 6.7268 7.5335V6.94727C6.1293 6.96981 5.64453 7.46585 5.64453 8.07463V14.8727C5.64453 15.4927 6.15185 16 6.7719 16H15.2272C15.8472 16 16.3545 15.4927 16.3545 14.8727V8.07463C16.3771 7.46585 15.8923 6.96981 15.2948 6.94727ZM15.6217 14.8727C15.6217 15.0756 15.4526 15.2447 15.2497 15.2447H6.79445C6.59152 15.2447 6.42242 15.0756 6.42242 14.8727V9.66422H15.633V14.8727H15.6217Z"
        fill="var(--light-cyan)"
      />
      <path
        d="M7.51505 7.89398C7.71798 7.89398 7.87581 7.73615 7.87581 7.53322V6.36076C7.87581 6.15783 7.71798 6 7.51505 6C7.31213 6 7.1543 6.15783 7.1543 6.36076V7.53322C7.1543 7.72487 7.31213 7.89398 7.51505 7.89398Z"
        fill="var(--light-cyan)"
      />
      <path
        d="M9.84904 7.89398C10.052 7.89398 10.2098 7.73615 10.2098 7.53322V6.36076C10.2098 6.15783 10.052 6 9.84904 6C9.64611 6 9.48828 6.15783 9.48828 6.36076V7.53322C9.48828 7.72487 9.64611 7.89398 9.84904 7.89398Z"
        fill="var(--light-cyan)"
      />
      <path
        d="M12.1933 7.89398C12.3962 7.89398 12.5541 7.73615 12.5541 7.53322V6.36076C12.5541 6.15783 12.3962 6 12.1933 6C11.9904 6 11.8326 6.15783 11.8326 6.36076V7.53322C11.8213 7.72487 11.9904 7.89398 12.1933 7.89398Z"
        fill="var(--light-cyan)"
      />
      <path
        d="M14.5268 7.89398C14.7297 7.89398 14.8875 7.73615 14.8875 7.53322V6.36076C14.8875 6.15783 14.7297 6 14.5268 6C14.3238 6 14.166 6.15783 14.166 6.36076V7.53322C14.166 7.72487 14.3238 7.89398 14.5268 7.89398Z"
        fill="var(--light-cyan)"
      />
      <path
        d="M7.34766 10.8134C7.34766 10.6888 7.4486 10.5879 7.57313 10.5879H14.4726C14.5971 10.5879 14.6981 10.6888 14.6981 10.8134V14.1053C14.6981 14.2298 14.5971 14.3307 14.4726 14.3307H7.57313C7.4486 14.3307 7.34766 14.2298 7.34766 14.1053V10.8134Z"
        fill="var(--light-cyan)"
      />
    </svg>
  );
};

DailyCalendar.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

DailyCalendar.defaultProps = {
  height: "22",
  width: "22",
};

export default DailyCalendar;
