import AmbassadorJoin from "./ambassadorJoin";
import Careers from "./careers";
import EducationIntroduction from "./educationIntroduction";
import EducationTopTenQuestions from "./educationTopTenQuestions";
import EducationWhySolar from "./educationWhySolar";
import EnterpriseJoin from "./enterpriseJoin";
import EnterpriseSellerJoin from "./enterpriseSellerJoin";
import FAQ from "./faq";
import WhySolar from "./whySolar";
import InstallerJoin from "./installerJoin";
import Join from "./join";
import Licenses from "./licenses";
import Password from "./password";
import SubmitRedirectNo from "./submitRedirectNo";
import SubmitRedirectYes from "./submitRedirectYes";
import Team from "./team";
import CreateAccount from "./createAccount";
import ContactVerification from "./contactVerification";

export default [
  AmbassadorJoin,
  Careers,
  EducationIntroduction,
  EducationTopTenQuestions,
  EducationWhySolar,
  EnterpriseJoin,
  EnterpriseSellerJoin,
  FAQ,
  WhySolar,
  InstallerJoin,
  Join,
  Licenses,
  Password,
  SubmitRedirectNo,
  SubmitRedirectYes,
  Team,
  CreateAccount,
  ContactVerification,
];
