import { keysToCamelCase } from "lib/helpers";

const formatDataGroups = (data) => {
  const out = data.body.data_groups.map((g) => keysToCamelCase(g)).sort((a, b) => a.label.localeCompare(b.label));
  for (const g of out) {
    g.metaFields = g.metaFields.map((f) => keysToCamelCase(f)).sort((a, b) => a.id - b.id);
  }
  return out;
};

const formatMilestoneOptions = (data) => {
  return data.body.milestone_options.map((arr) => {
    return {
      id: arr[0],
      label: arr[1],
      prerequisiteId: arr[2],
      rank: arr[3],
      required: arr[4],
      editableBy: arr[5],
      oldField: arr[6],
    };
  });
};

export { formatDataGroups, formatMilestoneOptions };
