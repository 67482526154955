// need to be converted to .ts
import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import PropTypes from "prop-types";
import { isArray } from "lodash";

import dayjs from "dayjs";
import { applyValuesToFilter } from "./DateRangeFilterLogic";

import * as S from "./styles";

const FilterMenuItem = (props) => {
  const { item, selectedItem, queryParams, updateFilters, override } = props;
  const itemsRelatedToDate = ["update_at", "create_at"];
  const itemLabel = item.label === "Updated Date" ? "Last Updated" : item.label;
  const [defaultDateRange, setDefaultDateRange] = useState(
    itemsRelatedToDate.includes(item.type) ? [queryParams.from || null, queryParams.to || null] : null,
  );

  useEffect(() => {
    if (!queryParams.from && !queryParams.to) setDefaultDateRange([null, null]);
    if (queryParams.from && queryParams.to) setDefaultDateRange([queryParams.from, queryParams.to]);
  }, [queryParams.from, queryParams.to]);

  if (!item) return null;

  const activeItem = selectedItem.type === item.type;
  const disableSelection = selectedItem.type === "state" && queryParams.city_name;
  const disableElement = selectedItem.type === "state" && queryParams.state;

  return (
    <S.ItemContainer isHidden={!activeItem}>
      <Grid container flexDirection={item.isReverse ? "column-reverse" : "initial"} maxWidth="323px">
        <Grid item xs={12}>
          <S.ItemSubheader>{itemLabel}</S.ItemSubheader>
          {isArray(item.options) &&
            item.options.map((option) => {
              const [optionDisplay, defaultValue] = Object.values(option);
              const [optionValue] = Object.keys(option);
              const currentParams = queryParams[item.type]?.split(",");

              const active =
                currentParams?.includes(optionValue) ||
                (!queryParams[item.type] && !optionValue) ||
                defaultValue?.join(",") === [queryParams.from, queryParams.to].join(",") ||
                defaultValue?.join(",") === [queryParams.created_at_from, queryParams.created_at_to].join(",");

              const isCustomValue = Boolean(itemsRelatedToDate.includes(item.type) && defaultValue);

              const handleClickAction = () => {
                if (!isCustomValue && optionValue === "all") {
                  override({
                    [item.type]: null,
                  });
                  return;
                }
                if (disableSelection) return;
                if (isCustomValue) applyValuesToFilter(defaultValue, setDefaultDateRange, override);
                else updateFilters(item.type, optionValue);
              };

              return (
                <S.OptionContainer
                  isDisabled={disableSelection}
                  key={optionValue}
                  tabIndex="0"
                  role="button"
                  onClick={handleClickAction}
                  onKeyDown={() => updateFilters(item.type, optionValue)}
                  isActive={active}
                >
                  {optionDisplay}
                </S.OptionContainer>
              );
            })}
        </Grid>
        {item.component && isArray(item.options) && (
          <Grid item xs={12}>
            <Grid mt={1} mb={1} container>
              <Grid alignItems="center" item xs={5}>
                <S.FilterDividerLine />
              </Grid>
              <Grid alignItems="center" item xs={2}>
                <S.FilterDivideText>or</S.FilterDivideText>
              </Grid>
              <Grid alignItems="center" item xs={5}>
                <S.FilterDividerLine />
              </Grid>
            </Grid>
          </Grid>
        )}
        {item.component && (
          <Grid item xs={12}>
            {item.componentHeader && <S.ItemSubheader>{item.componentHeader}</S.ItemSubheader>}
            {React.cloneElement(item.component, {
              disabled: disableElement,
              ...(itemsRelatedToDate.includes(item.type)
                ? {
                    defaultRange: [
                      defaultDateRange[0] ? dayjs(defaultDateRange[0]) : null,
                      defaultDateRange[1] ? dayjs(defaultDateRange[1]) : null,
                    ],
                  }
                : {}),
              ...(item.navigateParam
                ? {
                    handleInputChange: (ids) => {
                      if (ids.length === 0) override({ [item.navigateParam]: null });
                      else override({ [item.navigateParam]: ids });
                    },
                  }
                : {}),
            })}
          </Grid>
        )}
      </Grid>
    </S.ItemContainer>
  );
};

FilterMenuItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    componentHeader: PropTypes.string,
    isReverse: PropTypes.bool,
    navigateParam: PropTypes.string,
    component: PropTypes.node,
    options: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  }),
  selectedItem: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    component: PropTypes.node,
    options: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  }),
  queryParams: PropTypes.objectOf(PropTypes.string),
  updateFilters: PropTypes.func,
  override: PropTypes.func,
};

export default connect(getRdxSelectionMapper({}), getRdxActionMapper(["deleteFilterSet"]))(FilterMenuItem);
