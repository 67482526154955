import { select, put, all } from "redux-saga/effects";
import { keysToSnakeCase, camelizeKeys } from "lib/helpers";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import selectors from "rdx/selectors";
import { omit } from "lodash";
import actions from "rdx/actions";
import messages from "../messages";

function* updateDashboardUpdate(action) {
  const { id } = action.payload;
  const params = omit(action.payload, [id]);

  const { success, data, error } = yield* makeRequest.patch(`/dashboard_updates/${id}`, keysToSnakeCase(params));

  if (success && data) {
    const camelizedResp = camelizeKeys(data.body);
    const list = yield select(selectors.selectDashboardUpdates);

    const out = list.updates.map((item) => {
      if (camelizedResp.id === item.id) {
        return camelizedResp;
      }
      return item;
    });
    yield all([
      put(actions.setDashboardUpdates({ ...list, updates: out })),
      put(actions.setDashboardUpdateId(camelizedResp)),
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_DASHBOARD_UPDATE_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.GET_DASHBOARD_UPDATES_ERROR,
    });
  }
  return null;
}

export default updateDashboardUpdate;
