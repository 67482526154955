import makeRequest from "rdx/utils/makeRequest";
import { put } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* postEnterpriseAsset(action) {
  const { ownerId, urls, name, size } = action.payload;
  const { success, data, error } = yield* makeRequest.post("/enterprise_assets/", {
    enterprise_id: ownerId,
    signed_urls: urls,
    name,
    size,
  });
  if (success && data) {
    yield put(actions.setEnterpriseAssets(data.body.assets));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_POSTING_ENTERPRISE_ASSETS,
    });
  }

  return null;
}

export default postEnterpriseAsset;
