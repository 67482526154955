// React
import React from "react";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import PropTypes from "prop-types";
import { isArray } from "lodash";
import { CaretRightOutlined } from "components/Icons";

import { TabContainer, TabLabel, CountContainer } from "./styles";

const FilterMenu = (props) => {
  const { item, selectedItem, queryParams, setSelectedItem } = props;

  const selected = item.type === selectedItem?.type;
  const disabled = item?.disabled;

  const dateSearchItems = ["pay_period", "created_at", "created_at_from"];

  const sortCount = () => {
    if (typeof item.type === "string") {
      if (dateSearchItems.includes(item.type)) {
        return queryParams[item.type] ? 1 : 0;
      }

      return isArray(queryParams?.[item.type])
        ? queryParams?.[item.type]?.length
        : queryParams?.[item.type]?.split(",").length;
    }

    if (Array.isArray(item.type)) {
      const includesDateItems = dateSearchItems.some((dsi) => item.type.includes(dsi));
      return includesDateItems ? 1 : 0;
    }

    return 0;
  };

  return (
    <TabContainer
      isDisabled={disabled}
      isSelected={selected}
      onClick={disabled ? null : () => setSelectedItem(item)}
      onKeyDown={disabled ? null : () => setSelectedItem(item)}
      tabIndex={0}
      role="button"
    >
      <TabLabel isDisabled={disabled}>{item.label}</TabLabel>
      <CountContainer>
        <TabLabel isDisabled={disabled} isCount={!disabled}>
          <TabLabel isThin>/</TabLabel> {sortCount() || 0}
        </TabLabel>
        <span style={{ marginLeft: "10px" }}>
          <CaretRightOutlined fill={disabled ? "var(--old-grey)" : "var(--dark-blue)"} />
        </span>
      </CountContainer>
    </TabContainer>
  );
};

FilterMenu.propTypes = {
  selectedItem: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    component: PropTypes.node,
    options: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  }),
  item: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    component: PropTypes.node,
    options: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    disabled: PropTypes.bool,
  }),
  queryParams: PropTypes.objectOf(PropTypes.string),
  setSelectedItem: PropTypes.func,
};

export default connect(getRdxSelectionMapper({}), getRdxActionMapper(["deleteFilterSet"]))(FilterMenu);
