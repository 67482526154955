import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/enrollment/actionTypes";

import renewEnrollment from "./renewEnrollment";
import cancelEnrollment from "./cancelEnrollment";
import subscribeEnrollment from "./subscribeEnrollment";
// IMPORT_PT (for script, do not remove)

function* watchEnrollmentSagas() {
  yield trackRequests(takeEvery, types.RENEW_ENROLLMENT, renewEnrollment);
  yield trackRequests(takeEvery, types.CANCEL_ENROLLMENT, cancelEnrollment);
  yield trackRequests(takeEvery, types.SUBSCRIBE_ENROLLMENT, subscribeEnrollment);
  // INSERTION_PT (for script, do not remove)
}

export default watchEnrollmentSagas;
