import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import _ from "lodash";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getUsersList(action) {
  const params = {
    sort: "name",
    ...action.payload,
  };
  const options = {
    accept: "application/json",
    signal: params.signal,
  };
  const { success, data, error } = yield* makeRequest.get("/users", params, options);
  if (success && data) {
    const out = _.clone(data.body);
    if (out.users) {
      out.users = data.body.users.map((user) => {
        const newUser = _.clone(user);
        newUser.metrics = {};
        user.metrics.forEach((metric) => {
          const key = Object.keys(metric)[0];
          const value = Object.values(metric)[0];
          newUser.metrics[key] = value;
        });
        // newUser.upline = newUser.upline.map((u) => keysToCamelCase(u));
        return keysToCamelCase(newUser);
      });
    }
    yield put(actions.setUsersList({ ...out }));
  } else {
    if (params.signal?.aborted) return null;
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_USERS_LIST,
    });
  }
  return null;
}

export default getUsersList;
