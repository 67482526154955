import React from "react";
import PropTypes from "prop-types";

const ArchiveIcon = (props) => {
  const {
    // accentColor,
    mainColor,
    height,
    width,
    containerStyles,
  } = props;

  return (
    <span style={{ paddingTop: "1px", ...containerStyles }}>
      <svg width={width} height={height} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.65204 2.89583H1.82684C1.24543 2.89583 0.773682 3.36758 0.773682 3.94898V11.7742C0.773682 12.3556 1.24543 12.8273 1.82684 12.8273H9.65204C10.2335 12.8273 10.7052 12.3556 10.7052 11.7742V3.94898C10.7052 3.36758 10.2335 2.89583 9.65204 2.89583ZM9.87757 11.7742C9.87757 11.8983 9.77619 11.9997 9.65204 11.9997H1.82684C1.70269 11.9997 1.60131 11.8983 1.60131 11.7742V3.94898C1.60131 3.82484 1.70269 3.72345 1.82684 3.72345H9.65204C9.77619 3.72345 9.87757 3.82484 9.87757 3.94898V11.7742Z"
          fill={mainColor}
        />
        <path
          d="M11.7212 0.826965H3.89599C3.66839 0.826965 3.48218 1.01318 3.48218 1.24078C3.48218 1.46838 3.66839 1.65459 3.89599 1.65459H11.7212C11.8453 1.65459 11.9467 1.75598 11.9467 1.88012V9.70533C11.9467 9.93293 12.1329 10.1191 12.3605 10.1191C12.5881 10.1191 12.7744 9.93293 12.7744 9.70533V1.88012C12.7744 1.29871 12.3026 0.826965 11.7212 0.826965Z"
          fill={mainColor}
        />
        <path
          d="M8.01532 7.4471H6.15316V5.58494C6.15316 5.35734 5.96694 5.17113 5.73935 5.17113C5.51175 5.17113 5.32553 5.35734 5.32553 5.58494V7.4471H3.46337C3.23578 7.4471 3.04956 7.63332 3.04956 7.86091C3.04956 8.08851 3.23578 8.27473 3.46337 8.27473H5.32553V10.1369C5.32553 10.3645 5.51175 10.5507 5.73935 10.5507C5.96694 10.5507 6.15316 10.3645 6.15316 10.1369V8.27473H8.01532C8.24292 8.27473 8.42913 8.08851 8.42913 7.86091C8.42913 7.63332 8.24292 7.4471 8.01532 7.4471Z"
          fill={mainColor}
        />
      </svg>
    </span>
  );
};

ArchiveIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  mainColor: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

ArchiveIcon.defaultProps = {
  mainColor: "var(--royal-peacock-blue)",
  height: "13",
  width: "13",
};

export default ArchiveIcon;
