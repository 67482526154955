import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToSnakeCase, keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* registerNewUser(action) {
  const { success, data, error } = yield* makeRequest.post("/registrations", keysToSnakeCase(action.payload));

  if (success && data) {
    yield put(actions.setRegistrationInfo(keysToCamelCase(data.body)));

    yield put(
      actions.newSuccessEvent({
        message: messages.REGISTER_NEW_USER_SUCCESS,
        payload: data,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_REGISTERING_NEW_USER,
    });
  }

  return null;
}

export default registerNewUser;
