import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* createSms(action) {
  const params = { ...action.payload };
  const { success, data, error } = yield* makeRequest.post("/sms/", params);
  if (success && data) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.SUCCESS_CREATING_SMS,
          payload: data.body,
        }),
      ),
      put(actions.getSmsList()),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_SMS,
    });
  }
  return null;
}

export default createSms;
