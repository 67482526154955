import { selectors } from "rdx";
import React from "react";
import { useSelector } from "react-redux";
import { AppliedFilterChip } from "./styles";

type AppliedFiltersPropsT = {
  handleDelete: (key: string, id: string | number) => void;
};

const AppliedFilters = ({ handleDelete }: AppliedFiltersPropsT) => {
  const preferredView = useSelector(selectors.getPreferredView);
  const earliestProjectDate = "2015-01-01";

  const getFilterLabel = (selectedFilter: string, key: string): string => {
    if (selectedFilter[key]?.chipLabel) return selectedFilter[key]?.chipLabel;

    return selectedFilter[key]?.value
      ?.map((el) => (el.value === earliestProjectDate ? "Before" : el.value))
      .join("...");
  };

  return (
    <>
      {Object.keys(preferredView.filters).length > 0 &&
        Object.keys(preferredView.filters)
          .filter((key) => preferredView.filters[key] !== null)
          .map((key) =>
            Array.isArray(preferredView.filters[key]) ? (
              preferredView.filters[key].map((filter) => (
                <AppliedFilterChip
                  key={`${filter?.id}`}
                  variant="outlined"
                  label={filter?.label}
                  onDelete={() => {
                    handleDelete(key, filter?.id);
                  }}
                />
              ))
            ) : (
              <AppliedFilterChip
                variant="outlined"
                key={`${preferredView.filters[key]?.id}`}
                label={
                  getFilterLabel(preferredView.filters, key) ||
                  preferredView.filters[key]?.chipLabel ||
                  preferredView.filters[key]?.label
                }
                onDelete={() => {
                  handleDelete(key, preferredView.filters[key]?.id);
                }}
              />
            ),
          )}
    </>
  );
};

export default AppliedFilters;
