import { permissionTypes } from "hooks/usePermissions";
import { DecisionMethodNamesT } from "hooks/usePermissions/types";

const { SUNNOVA_DESIGN_TEAM } = permissionTypes;

export type Tab = {
  key: string;
  label: string;
  permission?: DecisionMethodNamesT;
};

const commonTabs: ReadonlyArray<Tab> = [
  {
    key: "to-submit",
    label: "To Submit",
  },
  {
    key: "in-progress",
    label: "In-Progress",
  },
  {
    key: "completed",
    label: "Completed",
  },
  {
    key: "vision",
    label: "Vision",
  },
  {
    key: "funding",
    label: "Funding",
    permission: SUNNOVA_DESIGN_TEAM as DecisionMethodNamesT,
  },
] as const;

const adminTabs: ReadonlyArray<Tab> = [];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
