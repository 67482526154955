import React, { useContext } from "react";

import PropTypes from "prop-types";

import { isEmpty } from "lodash";
import { useMediaQuery } from "@mui/material";

import { LayoutContext } from "components/PlatformLayout";
import { Tooltip } from "components/mui-styled-components/Tooltip";
import { CaretLeftOutlined } from "@icons";
import { CloseOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import GradientButton from "components/Buttons/GradientButton";
import Spacer from "components/Spacer";
import { theme } from "styles/themes";
import Footer from "./Footer";

import styles from "./DetailPageContainer.module.less";

const DetailPageContainer = (props) => {
  const {
    onCancel,
    onSave,
    submitDisabled,
    formIsTouched,
    sectionOptions,
    hideSider,
    children,
    activeSectionKey,
    setActiveSectionKey,
    additionalNavItems,
    handleBackClick,
    withButton = true,
    backButtonStyle,
    customBackBtn,
    disabledReason = null,
  } = props;
  const { contentRef } = useContext(LayoutContext);

  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const withoutNav = isEmpty(sectionOptions);

  const handleNavClick = (section) => {
    const sectionPosition = section?.ref.current.offsetTop;
    contentRef.current.scrollTo({
      left: 0,
      top: sectionPosition,
      behavior: "smooth",
    });

    setActiveSectionKey(section.key);
  };

  const navOptions =
    sectionOptions &&
    sectionOptions.map((section) => {
      const labelClass = activeSectionKey === section.key ? styles.navItemActive : styles.navItem;

      if (section.hidden) {
        return null;
      }
      return (
        <Row
          key={section.key}
          className={labelClass}
          onClick={() => {
            handleNavClick(section);
          }}
        >
          <span>{section.label}</span>
        </Row>
      );
    });

  const leftCol = (
    <>
      <div className={withoutNav ? styles.siderNoNav : styles.sider} style={{ maxHeight: "280px" }}>
        {handleBackClick && !tablet && (
          <div
            className={styles.backButtonContainer}
            onClick={handleBackClick}
            onKeyDown={handleBackClick}
            role="button"
            style={{ ...backButtonStyle }}
            tabIndex={0}
          >
            <CaretLeftOutlined fill="var(--white)" width="10" height="8" containerStyles={{ display: "flex" }} />
            <div className={styles.backButton}>Back</div>
          </div>
        )}
        {customBackBtn && !tablet && customBackBtn}
        {!tablet && !withoutNav && <div className={styles.linkRow}>{navOptions}</div>}
      </div>

      <Spacer axis="vertical" size={20} />
      <div className={withoutNav ? styles.additionalItemsNoNav : styles.additionalItems}>{additionalNavItems}</div>
      {withButton && (
        <div className={styles.buttonRow}>
          <CloseOutlined className={styles.cancelButton} disabled={!formIsTouched} onClick={onCancel} />
          <Tooltip placement="top" title={disabledReason}>
            <div>
              <GradientButton
                type="primary"
                disabled={submitDisabled}
                onClick={onSave}
                form="stripeForm"
                key="submit"
                htmlType="submit"
                css={styles.saveButton}
              >
                Save Updates
              </GradientButton>
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );

  const fullContent = (
    <>
      <Col flex="none" className={styles.colLeft}>
        {leftCol}
      </Col>
      <Col flex="auto" className={styles.colCenter}>
        {children}
      </Col>
    </>
  );

  const mobileContent = (
    <>
      <Col span={24} className={styles.colMobile}>
        <div>{children}</div>
        <Footer>{leftCol}</Footer>
      </Col>
    </>
  );

  return hideSider ? children : <Row className={styles.mainContainer}>{tablet ? mobileContent : fullContent}</Row>;
};

DetailPageContainer.propTypes = {
  submitDisabled: PropTypes.bool,
  formIsTouched: PropTypes.bool,
  // use when shared component doesn't always need sider
  hideSider: PropTypes.bool,
  handleBackClick: PropTypes.func,
  customBackBtn: PropTypes.node,
  // Page content
  children: PropTypes.node,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  // Nav options (proptypes disabled to play nice with TS)
  sectionOptions: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  // Active nav option
  activeSectionKey: PropTypes.string,
  additionalNavItems: PropTypes.node,
  setActiveSectionKey: PropTypes.func,
  withButton: PropTypes.bool,
  backButtonStyle: PropTypes.objectOf(PropTypes.string),
  disabledReason: PropTypes.string,
};

export default DetailPageContainer;
