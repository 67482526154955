import React from "react";
import PropTypes from "prop-types";
import { bytesToSize, fileTypesToPlainText } from "lib/helpers";

import { UploadIcon } from "components/Icons";
import { cvar } from "styles";
import styles from "../styles.module.less";

const Input = (props) => (
  <div className={styles.inputLabelContents}>
    <div className={styles.iconRow}>
      <UploadIcon width={30} height={30} color={cvar("blue-ribbon")} />
    </div>
    <div className={styles.row}>
      <div className={styles.instruction}>Drag and drop files here or Browse Files</div>
    </div>
    <div className={styles.details}>
      Max file size: {bytesToSize(props.max)}, File types: {fileTypesToPlainText(props.accept)}
    </div>
  </div>
);

Input.propTypes = {
  max: PropTypes.number,
  accept: PropTypes.string,
};

export default Input;
