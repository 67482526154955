import React, { useEffect, useRef, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { useController, useForm } from "react-hook-form";
import type { Control, UseControllerProps } from "react-hook-form";

// import SessionClient from "util/SessionClient";

import { RightCaretFilled, LeftCaretFilled } from "components/Icons";
import { CSSInterpolation } from "@emotion/css";
import { SerializedStyles } from "@emotion/react";
import ToolBarComponent from "./toolBar";
import ActionBarComponent from "./ActionBar";
import { sxStyle } from "./styles";

type TDateTimePickerComponent = {
  renderInput: (timeStamp: string) => JSX.Element;
  onSubmit: (selectedTimeStamp: string) => void;
  hideTime?: boolean;
  control?: Control;
  disabled?: boolean;
  styleOverrides?: CSSInterpolation | SerializedStyles;
  customLocation?: { bottom: string; left: string };
} & UseControllerProps;

const DateTimePickerComponent = ({
  renderInput,
  onSubmit,
  disabled,
  hideTime = false,
  customLocation,
  ...rest
}: TDateTimePickerComponent) => {
  const [open, setOpen] = useState(false);
  const renderInputRef = useRef(null);
  const { control: defaultControl } = useForm();
  const {
    field: { ref, value, onChange },
  } = useController({ name: rest.name, control: rest.control || defaultControl, defaultValue: rest.defaultValue });

  useEffect(() => {
    onChange(rest.defaultValue);
  }, [onChange, rest.defaultValue]);

  const handleClick = () => {
    if (disabled) {
      return;
    }
    setOpen(true);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          open={open}
          ref={ref}
          showToolbar
          closeOnSelect={false}
          onClose={() => {
            onChange(rest.defaultValue);
            setOpen(false);
          }}
          onAccept={(val) => {
            onChange(val);
            onSubmit(value.toString());
            setOpen(false);
          }}
          orientation="portrait"
          PaperProps={{
            sx: { ...sxStyle, ...(customLocation || {}) },
          }}
          DialogProps={{
            sx: sxStyle,
          }}
          PopperProps={{
            placement: "auto",
            anchorEl: renderInputRef.current,
          }}
          components={{
            ActionBar: ActionBarComponent,
            RightArrowIcon: RightCaretFilled,
            LeftArrowIcon: LeftCaretFilled,
          }}
          componentsProps={{
            actionBar: { actions: ["cancel", "accept"] },
          }}
          ToolbarComponent={() => {
            return (
              <ToolBarComponent
                hideTime={hideTime}
                updateDateTime={(val) => {
                  onChange(val);
                }}
                selectedDateTime={value.toString()}
              />
            );
          }}
          renderInput={() => (
            <span ref={renderInputRef} role="none" onClick={handleClick}>
              {renderInput(value?.toString())}
            </span>
          )}
          onChange={onChange}
        />
      </LocalizationProvider>
    </>
  );
};

export default DateTimePickerComponent;
