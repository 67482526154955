import { put, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/leads/messages";
import { camelizeKeys, keysToSnakeCase } from "lib/helpers";
import { selectors } from "rdx";
import { cloneDeep } from "lodash";

function* updateLeadOwner(action) {
  const { userId, leadId, onSuccess } = action.payload;
  const { success, data, error } = yield* makeRequest.post(
    `/leads/${leadId}/update_lead_owner`,
    keysToSnakeCase({ userId }),
  );

  if (success && data) {
    onSuccess();
    const leadDetails = camelizeKeys(data.body);

    const currentLeadsState = yield select(selectors.getLeads);
    const { leads } = currentLeadsState || [];
    const [leadSuccess, updatedLeads] = updateLeadOwnerInProjectList(leads, leadId, leadDetails.owner, "id");

    if (leadSuccess) {
      yield put(actions.setLeads({ ...currentLeadsState, leads: updatedLeads }));
    }

    const projectsState = yield select(selectors.getAdminProjectsList);
    const { projects: preSalesProjects } = projectsState;
    const [projectSuccess, updatedPreSales] = updateLeadOwnerInProjectList(
      preSalesProjects,
      leadId,
      leadDetails.owner,
      "leadId",
    );

    if (projectSuccess) {
      yield put(actions.setAdminProjectsList({ ...projectsState, projects: updatedPreSales }));
    }

    yield put(actions.newSuccessEvent({ message: messages.LEAD_OWNER_UPDATED }));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_LEAD_OWNER,
    });
  }

  return null;
}

const updateLeadOwnerInProjectList = (projects, leadId, newLeadOwner, leadIdKey) => {
  const itemIndex = projects.findIndex((project) => project[leadIdKey] === leadId);
  let projectsWereUpdated = false;
  const updatedProjects = cloneDeep(projects);

  if (itemIndex !== -1) {
    const leadUserIndex = findLeadOwnerProjectUser(updatedProjects[itemIndex], "Lead Owner");
    const sellerUserIndex = findLeadOwnerProjectUser(updatedProjects[itemIndex], "Seller");
    const sellerId = updatedProjects[itemIndex].projectUsers[sellerUserIndex].id;
    const leadOwnerId = updatedProjects[itemIndex].projectUsers[leadUserIndex].id;

    const projectUserIndexes = [leadUserIndex];
    if (leadOwnerId === sellerId) projectUserIndexes.push(sellerUserIndex);

    projectUserIndexes.forEach((index) => {
      if (index !== -1) {
        projectsWereUpdated = true;
        updatedProjects[itemIndex].projectUsers[index] = newProjectUser(
          updatedProjects[itemIndex].projectUsers[index],
          newLeadOwner,
        );
      }
    });
  }

  return projectsWereUpdated ? [true, updatedProjects] : [false, null];
};

const newProjectUser = (projectUser, newOwner) => ({
  ...projectUser,
  avatar: newOwner.avatar,
  firstName: newOwner.firstName,
  lastName: newOwner.lastName,
  id: newOwner.id,
  email: newOwner.email,
  phone: newOwner.phone,
});

const findLeadOwnerProjectUser = (project, userRole) =>
  project.projectUsers.findIndex((user) => user.role === userRole);

export default updateLeadOwner;
