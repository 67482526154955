import { useState } from "react";

const useModalControls = (initialState = false) => {
  const [visible, setVisible] = useState(initialState);
  const open = () => setVisible(true);
  const close = () => setVisible(false);
  const toggle = () => setVisible(!visible);
  return { visible, setVisible, open, close, toggle };
};

export default useModalControls;
