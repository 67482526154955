import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/projectPreSales/types";
import getProjectPreSales from "./getProjectPreSalesList";

// IMPORT_PT (for script, do not remove)

function* watchProjectPreSalesSagas() {
  yield trackRequests(takeEvery, types.GET_PROJECTS_PRE_SALES, getProjectPreSales);
  // INSERTION_PT (for script, do not remove)
}

export default watchProjectPreSalesSagas;
