import { all, put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/adminReports/messages";
import { keysToCamelCase } from "lib/helpers";

function* getMessagingMetrics(action) {
  const params = {
    ...(action.payload.from && { start_day: action.payload.from || null }),
    ...(action.payload.to && { end_day: action.payload.to || null }),
    ...(action.payload.start_day && {
      start_day: action.payload.start_day || null,
    }),
  };

  const { success, data, error } = yield* makeRequest.get("/messaging_metrics", params);

  if (success && data) {
    if (data.body.filters) {
      yield put(actions.setMetricsFilters(data.body));
    } else {
      const overallTeams = [];
      const out = data.body.teams.map((team) => {
        if (team.metrics) {
          overallTeams.push(keysToCamelCase(team.metrics));
        }
        const newTeam = team;
        const updatedUsers = newTeam.users.map((user) => {
          return {
            ...keysToCamelCase(user),
            isChild: true,
            label: user.full_name,
            key: `t${team.id}u${user.id}`,
          };
        });
        delete newTeam.users;
        return {
          ...keysToCamelCase(newTeam),
          children: updatedUsers,
          key: team.id,
        };
      });

      const overallMetrics = () => {
        const overallResults = {};
        overallTeams.forEach((team) => {
          for (const [key, value] of Object.entries(team)) {
            if (overallResults[key] && value != null) {
              overallResults[key].push(parseFloat(value));
            } else if (value != null) {
              overallResults[key] = [parseFloat(value)];
            } else {
              return;
            }
          }
        });
        const tabulatedResults = {};
        for (const [key, value] of Object.entries(overallResults)) {
          if (key.includes("Count")) {
            tabulatedResults[key] = value.reduce((a, b) => a + b, 0);
          }
          if (key.includes("Avg")) {
            tabulatedResults[key] = value.reduce((a, b) => a + b, 0) / value.length;
          }
          if (key.includes("Max") || key.includes("Oldest")) {
            tabulatedResults[key] = Math.max(...value);
          }
        }
        return tabulatedResults;
      };

      const overall = {
        label: "overall",
        key: "overall",
        shortName: "O",
        metrics: overallMetrics(),
      };

      out.unshift(overall);

      // const roles = data.body.roles.map((r) => keysToCamelCase(r))
      yield all([
        put(actions.setMessagingMetricsList(out)),
        // put(actions.setMessagingMetricsRoles(roles)),
      ]);
    }
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_MESSAGING_METRICS,
    });
  }
  return null;
}

export default getMessagingMetrics;
