import React, { useEffect, useRef, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { LoginSocialFacebook } from "reactjs-social-login";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Divider } from "antd";
import Checkbox from "antd/lib/checkbox";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import types from "rdx/modules/auth/types";
import messages from "rdx/modules/auth/messages";
import { parse } from "query-string";
import { useLocalStorage } from "react-use";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import useLoading from "hooks/useLoading";
import MessageEvent from "models/MessageEvent";
import SessionClient from "util/SessionClient";
import ApiClient from "util/ApiClient";
import LoginWithIconButton from "components/Buttons/SocialButtons/LoginWithIcon";
import GradientButton from "components/Buttons/GradientButton";
import { EyeIcon, EyeIconDash } from "@icons";
import LoginSocialGoogle from "./LoginSocialGoogle";
import styles from "../../Login.module.less";
import TermsOfServiceModal from "../TermsOfService/TermsOfServiceModal";

const { Item } = Form;

const LoginForm = (props) => {
  const {
    tabKeys,
    loginErrors: { username: usernameError, password: passwordError },
    latestMessage,
    login,
    authUri,
    clearLoginErrors,
    setAlertMessageVisible,
    clearAlertMessage,
  } = props;
  const [isModalVisiable, setIsModalVisiable] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [alr, , removeAlr] = useLocalStorage("alr", null);
  const [authCode, setAuthCode] = useState(null);
  const { t } = useTranslation(["form_labels", "login"]);
  const [form] = Form.useForm();
  const history = useHistory();
  const search = parse(history.location.search);
  const session = new SessionClient();
  const usernameRef = useRef();
  const passwordRef = useRef();
  const loading = !!useLoading({ watchRequests: [types.LOGIN] });

  const handleFinish = (values) => {
    login(values);
  };

  const handleSocialLogin = ({ data, provider }) => {
    const token = data.accessToken;

    login({
      auth_format: provider,
      token,
    });
  };

  useEffect(() => {
    if (!authCode) return;
    login({
      auth_format: "google",
      auth_code: authCode,
    });
  }, [authCode, login]);

  useEffect(() => {
    if (search.code === "undefined") {
      return;
    }
    if (authCode) return;
    setAuthCode(search.code);
  }, [search, authCode]);

  const handleValuesChange = () => {
    if ((usernameError && usernameError.message) || (passwordError && passwordError.message)) {
      clearLoginErrors();
      form.validateFields();
    }
  };

  const existingEmail = useMemo(() => {
    if (search.email === "undefined") {
      return "";
    }
    return search.email;
  }, [search]);

  useEffect(() => {
    if (session.username) {
      form.setFieldsValue({
        username: session.username,
        remember: true,
      });
      passwordRef.current.focus();
    } else {
      usernameRef.current.focus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loading) {
      setAlertMessageVisible({
        message: t("login:logging_in"),
        severity: "info",
      });
    }
  }, [loading, t]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const redirectOnSuccess = () => {
      const api = new ApiClient({ host: process.env.REACT_APP_POWUR_API_URL });

      if (!session.user) {
        return null;
      }

      if (api.isSso() && session.jwt) {
        return api.ssoLogin();
      }
      if (search.SAMLRequest && session.jwt) {
        return api.ssoLogin("zendesk");
      }
      if (session.universityOnly && !session.impersonating) {
        return api.ssoLogin("zendesk");
      }

      let target;
      if (alr) {
        target = alr;
        removeAlr();
      } else if (search.alr && search.alr !== "/login") {
        target = search.alr;
      } else if (session.isAmbassador && !session.isFormerAmbassador) {
        target = "/ambassador/dashboard";
      } else if (session.isEnterpriseStaff) {
        target = "/org-projects/leads";
      } else {
        target = "/dashboard";
      }
      history.push({ pathname: target, search: "" });
      return null;
    };

    if (latestMessage?.payload?.event === messages.LOGIN_SUCCESS_EVENT) {
      // check if accepted_latest_tos
      if (session.user.props.acceptedLatestTos === false) {
        // show the tos modal
        setIsModalVisiable(true);
      } else {
        redirectOnSuccess();
      }
    }

    if (latestMessage?.payload?.event === messages.ACCEPTED_LATEST_TOS_SUCCESS_EVENT) {
      clearAlertMessage();
      redirectOnSuccess();
    }

    if (latestMessage.error?.event === messages.LOGIN_ERROR_EVENT) {
      clearAlertMessage();
      form.validateFields();
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <Form
        form={form}
        onFinish={handleFinish}
        onValuesChange={handleValuesChange}
        className={styles.form}
        layout="vertical"
        requiredMark={false}
        colon={false}
      >
        {existingEmail && <div className={styles.instructions}>{t("login:email_already_exists")}</div>}
        <Item
          className={styles.formItem}
          name="username"
          initialValue={existingEmail || ""}
          label={<span className={styles.formLabel}>Email</span>}
          rules={[
            { required: true, message: t("valid_email") },
            {
              transform: (value) => value.trim(),
              type: "email",
              message: t("valid_email"),
              validateTrigger: ["onSubmit"],
            },
            () => ({
              validator() {
                if (usernameError && usernameError.message) {
                  return Promise.reject(new Error(_.startCase(usernameError.message)));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            className={styles.input}
            addonBefore={<MailOutlined className={styles.icon} />}
            autoComplete="username email"
            ref={(node) => {
              usernameRef.current = node;
            }}
          />
        </Item>
        <Item
          className={styles.formItem}
          name="password"
          label={
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <span className={styles.formLabel}>{t("password")}</span>
              <span
                onClick={() => setPasswordShown(!passwordShown)}
                onKeyDown={() => setPasswordShown(!passwordShown)}
                role="button"
                tabIndex={0}
                style={{ cursor: "pointer" }}
              >
                {passwordShown ? (
                  <EyeIconDash fill="var(--dark-periwinkle)" />
                ) : (
                  <EyeIcon fill="var(--dark-periwinkle)" />
                )}
              </span>
            </div>
          }
          rules={[
            { required: true, message: t("enter_your_password") },
            () => ({
              validator() {
                if (passwordError && passwordError.message) {
                  return Promise.reject(new Error(_.startCase(passwordError.message)));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            className={styles.input}
            addonBefore={<LockOutlined className={styles.icon} />}
            type={passwordShown ? "text" : "password"}
            autoComplete="current-password"
            ref={(node) => {
              passwordRef.current = node;
            }}
          />
        </Item>
        <div className={styles.controlsRow}>
          <Item name="remember" className={styles.formItemInline} valuePropName="checked">
            <Checkbox className={styles.checkbox}>
              <span className={styles.checkLabel}>{t("remember_me")}</span>
            </Checkbox>
          </Item>
          <Button onClick={() => history.push(`/login/${tabKeys[1]}`)} type="info" className={styles.linkButton}>
            {t("forgot_password")}
          </Button>
        </div>
        <div className={styles.loginRow}>
          <GradientButton htmlType="submit" className={styles.submitButton}>
            {t("login")}
          </GradientButton>
        </div>

        <Divider
          style={{
            color: "var(--dark-periwinkle)",
          }}
        >
          or
        </Divider>
        <div className={styles.oauthRow}>
          <div>
            <LoginSocialFacebook appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID} onResolve={handleSocialLogin}>
              <LoginWithIconButton provider="facebook" />
            </LoginSocialFacebook>
          </div>
          <div>
            <LoginSocialGoogle authUri={authUri}>
              <LoginWithIconButton provider="google" />
            </LoginSocialGoogle>
          </div>
        </div>
      </Form>
      <TermsOfServiceModal modalVisible={isModalVisiable} />
    </>
  );
};

LoginForm.propTypes = {
  tabKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  loginErrors: PropTypes.shape({
    username: PropTypes.shape({
      message: PropTypes.string,
    }),
    password: PropTypes.shape({
      message: PropTypes.string,
    }),
  }),
  latestMessage: MessageEvent.types(),
  login: PropTypes.func,
  // authUri: PropTypes.shape({
  //   authorizationUri: PropTypes.string,
  // }),
  authUri: PropTypes.string,
  clearLoginErrors: PropTypes.func,
  setAlertMessageVisible: PropTypes.func,
  clearAlertMessage: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    loginErrors: "getLoginErrors",
    latestMessage: "getLatestMessageEvt",
    activeRequests: "getActiveRequests",
    authUri: "getAuthUri",
  }),
  getRdxActionMapper(["login", "clearLoginErrors", "setAlertMessageVisible", "clearAlertMessage"]),
)(LoginForm);
