import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  usersReport: {},
  usersContestReport: {},
  userProjectsReport: {},
};

export default {
  reports: createReducer(initialState, {
    [types.SET_USERS_REPORT](state, action) {
      return {
        ...state,
        usersReport: action.payload,
      };
    },
    [types.SET_USERS_CONTEST_REPORT](state, action) {
      return {
        ...state,
        usersContestReport: action.payload,
      };
    },
    [types.SET_USER_PROJECTS_REPORT](state, action) {
      return {
        ...state,
        userProjectsReport: action.payload,
      };
    },
  }),
};
