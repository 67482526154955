import React from "react";
import type { GridColDef } from "@mui/x-data-grid-pro";

import MetricsTableView from "components/Tables/MetricsTable";
import { T3PartnerTableDataT } from "components/Drawers/SelectT3PartnerDrawer/types";

type MetricsColPropsT = {
  width?: number;
};

export const MetricsCol = ({ width = 300 }: MetricsColPropsT): GridColDef<T3PartnerTableDataT> => ({
  field: "metrics",
  width,
  renderCell: ({ row }) => {
    const { metrics } = row;
    return (
      <MetricsTableView
        columns={
          metrics && [
            {
              label: "Personal",
              metrics: [
                { label: "Projects", value: metrics.projectCount },
                { label: "Qualified", value: metrics.qualifyCount },
                { label: "Contracts", value: metrics.contractCount },
              ],
            },
            {
              label: "Closer",
              metrics: [
                { label: "Projects", value: metrics.mentorProjectCount },
                { label: "Qualified", value: metrics.mentorQualifyCount },
                { label: "Contracts", value: metrics.mentorContractCount },
              ],
            },
          ]
        }
      />
    );
  },
});
