import { put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import selectors from "rdx/modules/workflows/selectors";
import getErrorActions from "rdx/utils/getErrorActions";
import _ from "lodash";
import actions from "rdx/actions";
import messages from "../../messages";

function* deleteDataGroup(action) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/data_groups/${id}`, {});
  if (success && data) {
    const dataGroups = yield select(selectors.getDataGroups);
    const newGroups = _.clone(dataGroups);
    newGroups.splice(dataGroups.indexOf(dataGroups.find((g) => g.id === id)), 1);
    yield put(actions.setDataGroups(newGroups));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_DATA_GROUP,
    });
  }
  return null;
}

export default deleteDataGroup;
