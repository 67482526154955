import React from "react";
import { Row } from "antd";
import { openLinkInNewTab } from "lib/props";
import InjectZendeskWidget from "components/Injectors/InjectZendeskWidget";
import styles from "../Education.module.less";

const PageFooter = () => {
  return (
    <div className={styles.pageFooter}>
      <InjectZendeskWidget displayWidget />
      <div className={styles.upperSection}>
        <Row className={styles.header}>
          <span>You can count on us. We are proud to be part of:</span>
        </Row>
        <Row className={styles.orgContainer}>
          <div className={styles.orgCol} span={8}>
            <div className={styles.logoRow}>
              <img className={styles.logo} src="/img/bbb_logo.png" alt="bbb logo" />
            </div>
            <p>
              As a BBB Accredited Business, Powur is committed to make a good faith effort to resolve any consumer
              complaints.
            </p>
          </div>
          <div className={styles.orgCol} span={8}>
            <div className={styles.logoRow}>
              <img className={styles.logo} src="/img/inc5000_logo_43.png" alt="inc5000 logo" />
            </div>
            <p>
              As an Inc. 5000 company, Powur is constantly investing in improving our services in service to you and to
              the planet.
            </p>
          </div>
          <div className={styles.orgCol} span={8}>
            <div className={styles.logoRow}>
              <img className={styles.logo} src="/img/b_corp_logo.png" alt="b corp logo" />
            </div>
            <p>
              As a Certified B Corporation, Powur meets the highest standards of verified social and environmental
              performance, and public transparency, to balance profit and purpose.
            </p>
          </div>
        </Row>
      </div>
      <div className={styles.middleLine}> </div>
      <div className={styles.lowerSection}>
        <Row className={styles.logoRow}>
          <div className={styles.powurLogo} />
        </Row>
        <Row>
          <span className={styles.copywriteText}>
            © 2020 Powur. All rights reserved. |{" "}
            <a {...openLinkInNewTab("https://powur.com/privacy-policy")}>Privacy Policy</a> |{" "}
            <a {...openLinkInNewTab("https://powur.com/contact-us")}>Contact</a>
          </span>
        </Row>
      </div>
    </div>
  );
};

export default PageFooter;
