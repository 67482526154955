import PropTypes from "prop-types";

export const teamSchema = PropTypes.shape({
  id: PropTypes.number,
  color: PropTypes.string,
  email: PropTypes.string,
  label: PropTypes.string,
  leadUser: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    lastName: PropTypes.string,
    locale: PropTypes.string,
    phone: PropTypes.string,
    vanityName: PropTypes.string,
  }),
  phone: PropTypes.string,
  shortName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.arrayOf(PropTypes.object), // update this
});
