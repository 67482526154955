import React from "react";
import PropTypes from "prop-types";

const SellerProject = (props) => {
  const { fill, height, width } = props;

  return (
    <div>
      <svg width={width} height={height} viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1.46875" width="18.7121" height="25.0653" rx="1" stroke={fill} strokeWidth="2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.8525 11.0461H7.45226V13.4865C7.45226 13.6313 7.57635 13.7554 7.72112 13.7554H9.52041C9.54109 13.7554 9.58246 13.7554 9.60314 13.7347V11.4804H11.0922V13.7554C11.1129 13.7554 11.1336 13.7554 11.1336 13.7554H12.8501C12.9949 13.7554 13.119 13.6313 13.119 13.4865V11.0461H13.8635C14.091 11.0461 14.2151 10.7566 14.0497 10.5911L10.6165 7.11662C10.5131 7.01321 10.3477 7.01321 10.2443 7.11662L6.66637 10.5911C6.50091 10.7359 6.60432 11.0461 6.8525 11.0461Z"
          fill={fill}
        />
        <rect
          x="13.9219"
          y="16.5273"
          width="1.05192"
          height="7.13229"
          rx="0.525958"
          transform="rotate(90 13.9219 16.5273)"
          fill={fill}
        />
        <rect
          x="13.9219"
          y="18.6523"
          width="1.05192"
          height="7.13229"
          rx="0.525958"
          transform="rotate(90 13.9219 18.6523)"
          fill={fill}
        />
        <rect
          x="13.9219"
          y="20.7773"
          width="1.05192"
          height="7.13229"
          rx="0.525958"
          transform="rotate(90 13.9219 20.7773)"
          fill={fill}
        />
      </svg>
    </div>
  );
};

SellerProject.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

SellerProject.defaultProps = {
  fill: "var(--eggplant)",
  width: "21",
  height: "28",
};

export default SellerProject;
