export default {
  personal: {
    key: "personal",
    idx: 0,
    title: "Apply to Enterprise (1/3)",
    subtitle: "To get started, please enter your information below.",
    advance: true,
    buttonTxt: "next_step",
  },
  business: {
    key: "business",
    idx: 1,
    title: "Apply to Enterprise (2/3)",
    subtitle: "We have a few questions in order to process your application",
    advance: true,
    buttonTxt: "next_step",
  },
  orgOfficers: {
    key: "orgOfficers",
    idx: 2,
    title: "Apply to Enterprise (3/3)",
    subtitle: "We have a few questions in order to process your application",
    submit: true,
    buttonTxt: "Submit",
  },
  confirmation: {
    key: "confirmation",
    idx: 3,
    title: "Thank You!",
    advance: true,
    close: true,
    buttonTxt: "Close",
  },
  laborPartner: {
    key: "laborPartner",
    idx: 4,
    title: "Powur's Builder Partner Program Application",
    subtitle: "We have a few question in order to process your application.",
    laborPartnerForm: true,
    buttonTxt: "Submit",
  },
  laborPartnerConfirm: {
    key: "laborPartnerConfirm",
    idx: 5,
    title: "Thank You!",
    close: true,
    buttonTxt: "Close",
  },
};
