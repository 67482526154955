import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const SilhouetteDoubleGradientIcon = ({
  width = "14",
  height = "13",
  startColor = cvar("picton-blue"),
  stopColor = cvar("blue-ribbon"),
}: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 13">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8946 9.28408C13.1684 9.55859 13.4167 9.85727 13.6368 10.1765L13.637 10.1764C13.7721 10.3799 13.8287 10.6256 13.7963 10.8677C13.7638 11.1099 13.6446 11.332 13.4607 11.4928C13.2767 11.6536 13.0407 11.7421 12.7964 11.7419H11.3016C11.2738 11.4979 11.1867 11.2644 11.0479 11.0619C10.8077 10.7159 10.5368 10.3925 10.2383 10.0953C9.60813 9.46264 8.8614 8.95793 8.03933 8.6091C8.5987 7.96951 8.90551 7.14777 8.90241 6.298V5.10357C8.90106 4.34606 8.65579 3.60912 8.20281 3.00194C7.74982 2.39476 7.11326 1.94964 6.38747 1.73266C6.95622 1.20207 7.71007 0.915279 8.4876 0.933697C9.26513 0.952019 10.0046 1.27408 10.5476 1.83085C11.0907 2.38765 11.3941 3.13488 11.3931 3.91266V5.10876C11.3926 6.12839 10.8719 7.07731 10.0122 7.62538C11.1031 7.91251 12.0981 8.48503 12.8946 9.28408ZM9.85848 10.4732C10.1322 10.7477 10.3807 11.0464 10.6007 11.3656L10.6008 11.3655C10.7369 11.5696 10.7939 11.8162 10.7612 12.0593C10.7285 12.3024 10.6082 12.5252 10.423 12.6859C10.2378 12.8468 10.0003 12.9345 9.75504 12.9328H1.01084C0.761215 12.9322 0.520615 12.8395 0.335106 12.6725C0.149682 12.5054 0.0324005 12.2757 0.00577913 12.0276C-0.0208422 11.7794 0.0451239 11.5301 0.190954 11.3275C0.404512 11.0226 0.644194 10.7367 0.9073 10.4731C1.70618 9.67299 2.70429 9.1009 3.79853 8.81619C2.93101 8.27225 2.40269 7.32175 2.39867 6.29785V5.10343C2.39867 4.03763 2.96734 3.05265 3.89045 2.51973C4.81336 1.98681 5.95072 1.98681 6.87383 2.51973C7.79685 3.05261 8.36552 4.03759 8.36552 5.10343V6.29785C8.36243 7.31985 7.83695 8.26925 6.9726 8.81447C8.06483 9.10111 9.06102 9.67361 9.85848 10.4732Z"
        fill="url(#paint0_linear_681_94527)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_681_94527"
          x1="2.42196"
          y1="-4.06713"
          x2="14.5713"
          y2="-2.67099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SilhouetteDoubleGradientIcon;
