import { CommonTabsT } from "types/utils";
import { permissionTypes } from "hooks/usePermissions";

const { SALES_DASHBOARD_VIEW } = permissionTypes;

const commonTabs: CommonTabsT[] = [
  {
    key: "notifications",
    label: "Notifications",
  },
  {
    key: "task-list",
    label: "Task List",
    breadcrumbLabel: "Activities & Tasks",
    permission: SALES_DASHBOARD_VIEW,
  },
];

const adminTabs = [];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
