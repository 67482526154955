import React, { useState, useEffect, useContext } from "react";
// import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Form, Button, Input, Row, Col } from "antd";
import { FileAddOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { buttonProps, openLinkInNewTab } from "lib/props";
import { actions } from "rdx";
import messages from "rdx/modules/ambassador/messages";
import FileUploader from "components/FileUploader/FileUploader";
import FormErrorMessage from "components/FormElements/FormErrorMessage";
import CopyEmail from "components/CopyContactInfo/CopyEmail";
import { AmbassadorDashboardContext as context } from "containers/Ambassador/dashboard";
import AddressLookupField from "./addressLookupField";

const AddReferral = () => {
  const [form] = Form.useForm();
  const {
    navigate,
    tabKeys,
    styles,
    createAmbassadorLead: createLead,
    zipLookup,
    zipInfo,
    resetZipInfo,
    submitting,
    latestMessage,
  } = useContext(context);
  const [utilityBillDisplay, setUtilityBillDisplay] = useState();
  const [addressSelection, setAddressSelection] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (submitting) {
      dispatch(
        actions.setAlertMessageVisible({
          message: "Submitting. . .",
          severity: "info",
          duration: 6000,
        }),
      );
    }
  }, [submitting]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (latestMessage) {
      if (latestMessage.message === messages.CREATE_NEW_AMBASSADOR_LEAD_SUCCESS) {
        onSubmitSuccess(latestMessage.message);
      } else if (latestMessage.message === messages.ERROR_CREATING_AMBASSADOR_LEAD) {
        onSubmitFailure();
      }
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const onUtilityBillUploadFinish = (url) => {
    form.setFieldsValue({ utilityBill: url });
    setUtilityBillDisplay(url);
  };

  const handleZipLookup = (data) => {
    const zip = typeof data === "object" ? data.target.value : data;

    if (zip.length === 5 && !Number.isNaN(parseInt(zip, 10))) {
      zipLookup({ zip });
    } else {
      resetZipInfo();
    }
  };

  const handleAutocompleteSelect = (_value, option) => {
    handleZipLookup(option.data.zipcode);
    form.setFieldsValue({
      zip: option.data.zipcode,
    });
    setAddressSelection(option.data);
  };

  const onAutocompleteSearch = () => {
    setAddressSelection();
  };

  const handleFormSubmit = (values) => {
    const body = { ...values };
    if (addressSelection) {
      body.street = addressSelection.street_line;
    }

    if (zipInfo?.state) {
      body.state = zipInfo.state;
      body.city = zipInfo.city;
      createLead(body);
    } else {
      dispatch(
        actions.setAlertMessageVisible({
          message: "Please enter a valid US or Canadian postal code.",
          severity: "error",
        }),
      );
    }
  };

  const onSubmitSuccess = (msg) => {
    dispatch(
      actions.setAlertMessageVisible({
        message: msg,
        severity: "success",
      }),
    );
    form.resetFields();
    resetZipInfo();
    setUtilityBillDisplay();
    setAddressSelection();
    navigate({ tab: tabKeys[0] });
  };

  const onSubmitFailure = () => {
    const { error, message: msg } = latestMessage;
    if (error && error.status === "bad_request") {
      dispatch(
        actions.setAlertMessageVisible({
          message: `${msg}: ${error.message}`,
          severity: "error",
        }),
      );
    } else {
      dispatch(
        actions.setAlertMessageVisible({
          message: msg,
          severity: "error",
        }),
      );
    }
  };

  return (
    <div className={styles.addReferralTab}>
      <div className={styles.banner}>Add a Home Owner, Receive $1,000 Referral</div>
      <div className={styles.instructions}>
        Home owner&apos;s utility bill information is optional, and it will help your solar consultant quickly and
        efficiently design the perfect system for your referral.
      </div>
      <Form
        form={form}
        name="add-referral"
        className={styles.form}
        requiredMark={false}
        colon={false}
        layout="vertical"
        onFinish={handleFormSubmit}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name={["firstName"]}
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: <FormErrorMessage message="Referral's first name is required." />,
                },
              ]}
            >
              <Input className={styles.input} placeholder="FIRST NAME" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={["lastName"]}
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: <FormErrorMessage message="Referral's last name is required." />,
                },
              ]}
            >
              <Input className={styles.input} placeholder="LAST NAME" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name={["email"]}
          className={styles.formItem}
          rules={[
            {
              required: true,
              message: <FormErrorMessage message="Referral's email address is required." />,
            },
          ]}
        >
          <Input className={styles.input} placeholder="EMAIL" size="large" />
        </Form.Item>
        <Form.Item
          name={["zip"]}
          className={styles.formItem}
          validateTrigger={["onBlur"]}
          rules={[
            {
              pattern: /^(\d{5}(-\d{4})?|[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] ?\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/i,
              message: <FormErrorMessage message="Please enter a valid US or Canadian postal code." />,
            },
          ]}
        >
          <Input
            className={styles.input}
            placeholder="POSTAL CODE"
            size="large"
            onChange={handleZipLookup}
            maxLength={7}
          />
        </Form.Item>
        <AddressLookupField
          handleAutocompleteSelect={handleAutocompleteSelect}
          onAutocompleteSearch={onAutocompleteSearch}
          zipInfo={zipInfo}
        />
        <Form.Item
          name={["phone"]}
          className={styles.formItem}
          rules={[
            {
              required: true,
              message: <FormErrorMessage message="Referral's phone number is required." />,
            },
          ]}
        >
          <Input className={styles.input} placeholder="PHONE NUMBER" size="large" />
        </Form.Item>
        <Form.Item
          name={["utilityBill"]}
          className={styles.formItem}
          shouldUpdate={(prev, current) => prev.utilityBill !== current.utilityBill}
        >
          <FileUploader
            apiMode="lead_utility_bill"
            containerProps={{
              style: {
                backgroundColor: "transparent",
                border: "1px dashed rgb(217, 217, 217)",
              },
            }}
            customButtonContents={{
              ready: (
                <div className={styles.uploadButton}>
                  <FileAddOutlined className={styles.uploadIcon} />
                  <div className={styles.uploadTitle}>Upload Utility Bill</div>
                </div>
              ),
              finished: (
                <div className={styles.uploadButton}>
                  <CheckCircleOutlined className={styles.uploadIcon} />
                  <div className={styles.uploadTitle}>File Uploaded Successfully</div>
                </div>
              ),
            }}
            height="75px"
            multiple={false}
            onFinished={onUtilityBillUploadFinish}
          />
        </Form.Item>
        {utilityBillDisplay && (
          <CopyEmail
            email={utilityBillDisplay}
            containerProps={{ className: styles.fileUrl }}
            emailProps={{ style: { color: "lightgrey" } }}
            showIcon={false}
          />
        )}
        <Form.Item className={styles.formItem}>
          <Button className={styles.submitButton} type="primary" block htmlType="submit">
            Submit A <span className={styles.bold}>Referral</span>
          </Button>
        </Form.Item>
        <div className={styles.back} {...buttonProps(() => navigate({ tab: tabKeys[0] }))}>
          Back to Dashboard
        </div>
        <div className={styles.instructions}>
          <a
            {...openLinkInNewTab("https://drive.google.com/file/d/11_388UW0mS2dLSaXTkX7Ia63UlJzgygw/view?ts=5ebc3729")}
          >
            Ambassador Program Terms Of Use
          </a>
        </div>
      </Form>
    </div>
  );
};

export default AddReferral;
