import React from "react";
import PropTypes from "prop-types";

const MoneyEnvelopeIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5451 5.88035C14.8761 6.76768 14.8761 8.32052 13.5451 9.31877C13.2124 9.54061 12.8796 9.65152 12.5469 9.76244V10.4279C12.5469 10.9825 11.7705 10.9825 11.7705 10.4279V9.87336C11.6595 9.87336 11.4377 9.87336 11.2159 9.87336V10.4279C11.2159 10.9825 10.5504 10.9825 10.5504 10.4279V9.76244C10.1067 9.65152 9.66303 9.54061 9.33028 9.31877C8.7757 8.8751 8.33203 8.2096 8.33203 7.5441C8.33203 6.65676 9.88487 6.65676 9.88487 7.5441C9.88487 7.76593 10.1067 7.98777 10.3285 8.2096C10.3285 8.2096 10.4395 8.32052 10.5504 8.32052V6.43493C10.1067 6.43493 9.77395 6.2131 9.33028 5.99126C8.1102 4.99301 8.1102 3.44017 9.33028 2.55284C9.66303 2.331 10.1067 2.10917 10.5504 1.99825V1.33275C10.5504 0.889083 11.2159 0.889083 11.2159 1.33275V1.88734C11.4377 1.88734 11.6595 1.88734 11.7705 1.88734V1.33275C11.7705 0.889083 12.5469 0.889083 12.5469 1.33275V2.10917C12.8796 2.22009 13.2124 2.331 13.5451 2.55284C14.2106 2.99651 14.5434 3.55109 14.5434 4.21659C14.5434 5.21484 12.9905 5.21484 12.9905 4.21659C12.9905 3.99476 12.8796 3.77292 12.6578 3.66201C12.5469 3.55109 12.5469 3.55109 12.5469 3.55109V5.43668C12.8796 5.43668 13.2124 5.65851 13.5451 5.88035ZM12.5469 6.8786V8.32052C12.5469 8.2096 12.5469 8.2096 12.6578 8.2096C13.1015 7.87685 13.1015 7.32227 12.6578 6.98952C12.5469 6.8786 12.5469 6.8786 12.5469 6.8786ZM11.7705 8.54235V6.65676C11.6595 6.54585 11.4377 6.54585 11.2159 6.54585V8.54235C11.4377 8.54235 11.6595 8.54235 11.7705 8.54235ZM10.5504 3.55109C10.4395 3.55109 10.3285 3.55109 10.3285 3.66201C9.77395 3.99476 9.77395 4.54934 10.3285 4.88209C10.3285 4.99301 10.4395 4.99301 10.5504 4.99301V3.55109ZM11.7705 3.32926C11.6595 3.32926 11.4377 3.32926 11.2159 3.32926V5.21484C11.4377 5.21484 11.5486 5.21484 11.7705 5.32576V3.32926Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.2"
      />
      <path d="M21.3879 13.6445H18.98V27.2079H21.3879V13.6445Z" fill={fill} stroke={fill} strokeWidth="0.2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2054 15.752C16.429 15.752 15.6526 15.752 14.7652 15.752C14.7652 19.5231 14.7652 23.4052 14.7652 27.2873C15.6526 27.2873 16.429 27.2873 17.2054 27.2873C17.2054 23.4052 17.2054 19.5231 17.2054 15.752Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1015 17.7485C12.325 17.7485 11.5486 17.7485 10.6613 17.7485C10.6613 20.8541 10.6613 24.0707 10.6613 27.2873C11.5486 27.2873 12.325 27.2873 13.1015 27.2873C13.1015 24.0707 13.1015 20.8541 13.1015 17.7485Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99755 19.745C8.22113 19.745 7.33379 19.745 6.55737 19.745C6.55737 22.2961 6.55737 24.7362 6.55737 27.2873C7.33379 27.2873 8.22113 27.2873 8.99755 27.2873C8.99755 24.7362 8.99755 22.2961 8.99755 19.745Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.2"
      />
      <path d="M4.86126 21.7415H2.45343V27.1996H4.86126V21.7415Z" fill={fill} stroke={fill} strokeWidth="0.2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4202 5.43668L16.0962 8.09868L17.76 9.09694C13.3233 13.2009 7.99928 15.9738 1.67702 17.6375C0.45693 17.9703 1.01151 19.8559 2.34252 19.5231C9.10845 17.7485 14.7652 14.6428 19.5346 10.317L21.3093 11.4262L21.4202 5.43668Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.2"
      />
    </svg>
  );
};

MoneyEnvelopeIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

MoneyEnvelopeIcon.defaultProps = {
  width: "22",
  height: "28",
  fill: "var(--picton-blue)",
};

export default MoneyEnvelopeIcon;
