import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import {
  StyledInputTime,
  StyledToolBarDate,
  StyledInputTimeColon,
  StyledInputWrapper,
  StyledInputLabel,
} from "./styles";

type TToolBar = {
  selectedDateTime: string;
  hideTime: boolean;
  updateDateTime: (value: string) => void;
};

const ToolBarComponent = ({ selectedDateTime, updateDateTime, hideTime }: TToolBar) => {
  const [hours, setHours] = useState(dayjs(selectedDateTime).hour());
  const [minutes, setMinutes] = useState(dayjs(selectedDateTime).minute());
  const [changePeriod, setChangePeriod] = useState(false);
  const [period, setPeriod] = useState(dayjs(selectedDateTime).hour() >= 12 ? "pm" : "am");
  useEffect(() => {
    if (!hideTime) {
      let h = hours;
      const m = minutes;
      if (period === "pm" && changePeriod && h < 12) h = hours + 12;
      if (period === "am" && changePeriod && h >= 12) h = hours - 12;
      const newTimeStamp = dayjs(selectedDateTime).set("hour", h).set("minute", m).toString();
      updateDateTime(newTimeStamp);
      setChangePeriod(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes, hours, period, hideTime]);

  const handleValueUpdate = (value: number, type: string, max: number) => {
    let val = value;
    if (val > max) val = max;

    if (type === "hours") setHours(_.isNaN(val) ? 1 : val);
    else setMinutes(_.isNaN(val) ? 1 : val);
  };

  return (
    <Grid container maxWidth="395px">
      <Grid xs={hideTime ? 12 : 11} xsOffset={hideTime ? 0 : 1} paddingTop="35px" paddingBottom="28px">
        <StyledToolBarDate>
          {selectedDateTime ? dayjs(selectedDateTime).format("ddd, MMM DD").toString() : ""}
        </StyledToolBarDate>
      </Grid>
      {!hideTime && (
        <Grid xsOffset={1} sx={{ marginRight: "12px" }}>
          <StyledInputWrapper>
            <StyledInputTime
              type="number"
              min="1"
              max="12"
              defaultValue={parseInt(dayjs(selectedDateTime).format("hh"), 10)}
              onChange={(event) => {
                handleValueUpdate(parseInt(event.target.value, 10), "hours", parseInt(event.target.max, 10));
              }}
            />
            <StyledInputLabel>Hour</StyledInputLabel>
          </StyledInputWrapper>
          <StyledInputTimeColon>:</StyledInputTimeColon>
          <StyledInputWrapper>
            <StyledInputTime
              type="number"
              min="1"
              max="59"
              defaultValue={dayjs(selectedDateTime).format("mm")}
              onChange={(event) => {
                handleValueUpdate(parseInt(event.target.value, 10), "minutes", parseInt(event.target.max, 10));
              }}
            />
            <StyledInputLabel>Minute</StyledInputLabel>
          </StyledInputWrapper>
        </Grid>
      )}
      {!hideTime && (
        <Grid xs={1}>
          <ToggleButtonGroup
            value={period}
            onChange={(event, nextView) => {
              setPeriod(nextView);
            }}
            onClick={() => {
              setChangePeriod(true);
            }}
            sx={{
              "& .MuiToggleButton-root": {
                height: "30px",
                background: "var(--cotton-boll)",
                color: "var(--nepal-blue)",
              },
              "& .Mui-selected": {
                background: "linear-gradient(270.54deg, var(--picton-blue) 49.88%, var(--blue-ribbon) 135.73%)",
                color: "white",
              },
            }}
            orientation="vertical"
            exclusive
          >
            <ToggleButton value="am">AM</ToggleButton>
            <ToggleButton value="pm">PM</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
    </Grid>
  );
};

export default ToolBarComponent;
