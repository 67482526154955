import types from "rdx/modules/preferredPartners/types";
import createAction from "rdx/utils/createAction";

export default {
  setPreferredPartners: (payload) => createAction(types.SET_PREFERRED_PARTNERS, payload),
  setRecommendedPartners: (payload) => createAction(types.SET_RECOMMENDED_PARTNERS, payload),
  getPreferredPartners: (payload) => createAction(types.GET_PREFERRED_PARTNERS, payload),
  getPreferredPartnersAdmin: (payload) => createAction(types.GET_PREFERRED_PARTNERS_ADMIN, payload),
  setPreferredPartnersAdmin: (payload) => createAction(types.SET_PREFERRED_PARTNERS_ADMIN, payload),
  updatePreferredPartner: (payload) => createAction(types.UPDATE_PREFERRED_PARTNER, payload),
  deletePreferredPartner: (payload) => createAction(types.DELETE_PREFERRED_PARTNER, payload),
  createPreferredPartner: (payload) => createAction(types.CREATE_PREFERRED_PARTNER, payload),
  setPreferredPartnersAutocompleteSuggestions: (payload) =>
    createAction(types.SET_PREFERRED_PARTNERS_AUTOCOMPLETE_SUGGESTIONS, payload),
  // INSERTION_PT (for script, do not remove)
};
