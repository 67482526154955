import React from "react";
import PropTypes from "prop-types";
import { cvar } from "styles";

const EyeIcon = (props) => {
  const { height, width, disabled } = props;
  const fillColor = disabled ? cvar("botticelli-blue") : cvar("white");

  return (
    <svg width={width} height={height} viewBox="0 0 22 12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.886 5.55983C20.8203 4.16979 17.0906 0 11.0212 0C4.95169 0 1.24514 4.16987 0.156342 5.55983C-0.0521141 5.81466 -0.0521141 6.18535 0.156342 6.44017C1.22206 7.83021 4.95169 12 11.0212 12C17.0906 12 20.7972 7.83013 21.886 6.44017C22.0713 6.18534 22.0713 5.81465 21.886 5.55983ZM11.0212 10.471C8.54233 10.471 6.55014 8.47873 6.55014 5.99998C6.55014 3.52114 8.54241 1.52895 11.0212 1.52895C13.5 1.52895 15.4922 3.52122 15.4922 5.99998C15.4922 8.47882 13.5001 10.471 11.0212 10.471Z"
        fill={fillColor}
      />
      <path
        d="M11.0209 3.12698C10.7661 3.12698 10.5343 3.15009 10.3028 3.21958C10.6502 3.49752 10.8588 3.89131 10.8588 4.37787C10.8588 5.18876 10.2102 5.83737 9.39933 5.83737C8.93605 5.83737 8.51898 5.62891 8.24103 5.28134C8.17154 5.51307 8.14844 5.76791 8.14844 5.99945C8.14844 7.59794 9.44569 8.87207 11.0211 8.87207C12.5963 8.87207 13.8937 7.57481 13.8937 5.99945C13.8935 4.42441 12.5961 3.12695 11.0209 3.12695L11.0209 3.12698Z"
        fill={fillColor}
      />
    </svg>
  );
};

EyeIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool,
};

EyeIcon.defaultProps = {
  height: "16",
  width: "22",
};

export default EyeIcon;
