import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/projects/messages";
import { camelizeKeys } from "lib/helpers";

function* getLeadsCsv(action) {
  const { success, data, error } = yield* makeRequest.get("/leads/csv", action.payload);

  if (success && data) {
    yield put(actions.setLeadsCsv(camelizeKeys(data.body.csv)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROJECTS_CSV,
    });
  }

  return null;
}

export default getLeadsCsv;
