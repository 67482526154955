import { put } from "redux-saga/effects";
import { camelizeKeys, keysToSnakeCase } from "lib/helpers";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* archiveDashboardNotifications(action) {
  const params = keysToSnakeCase(action.payload);
  const { success, data, error } = yield* makeRequest.patch("/crm_notifications/archive", params);

  if (success && data) {
    const body = camelizeKeys(data.body);
    if (body.open) {
      yield put(actions.setDashboardNotifications(body));
    }
    if (body.paging) {
      yield put(actions.setAllNotifications(body));
    }
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_NOTIFICATIONS,
    });
  }
  return null;
}

export default archiveDashboardNotifications;
