import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";
import {
  UserSellerIntegrityT,
  SetIntegrityDataPayloadT,
} from "containers/Admin/users/components/UserDetailDrawer/types";

const initialState: UserSellerIntegrityT = {
  submitter: {
    id: 0,
    fullName: "",
  },
  userViolations: {
    userId: 0,
    violations: [],
    violationCount: 0,
  },
  allReasons: [],
};

export default {
  sellerIntegrity: createReducer(initialState, {
    [types.SET_INTEGRITY_VIOLATIONS](state: UserSellerIntegrityT, action: { payload: SetIntegrityDataPayloadT }) {
      return {
        ...state,
        userViolations: {
          userId: action.payload.userId || state.userViolations.userId,
          violations: action.payload.violations || state.userViolations.violations,
          violationCount:
            action?.payload?.violationCount === undefined
              ? state.userViolations.violationCount
              : action.payload.violationCount,
        },
        allReasons: action.payload.allReasons || state.allReasons,
        submitter: action.payload.submitter || state.submitter,
      };
    },
    [types.CLEAR_INTEGRITY_VIOLATIONS](state: UserSellerIntegrityT) {
      return {
        ...state,
        userViolations: {
          ...initialState.userViolations,
        },
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
