import React from "react";
import { cvar } from "styles/colorVariables";

interface CloseIconOutlinedSquareT {
  fill?: string;
  height?: string | number;
  width?: string | number;
}

const CloseIconOutlinedSquare = ({ fill = cvar("white"), height = "24", width = "24" }: CloseIconOutlinedSquareT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.59375" y="1" width="22" height="22" rx="2" stroke={fill} strokeWidth="2" />
      <line x1="7.62062" y1="16.8081" x2="17.0207" y2="7.13155" stroke={fill} strokeWidth="2" strokeLinecap="round" />
      <line x1="16.9585" y1="16.8321" x2="7.64839" y2="6.7462" stroke={fill} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default CloseIconOutlinedSquare;
