import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Input, Form } from "antd";

import styles from "./InputLabeled.module.less";

const { Item } = Form;

const InputLabeled = ({
  label,
  placeholder,
  labelClass,
  inputClass,
  inputPrefix,
  onInputChange = () => null,
  value,
  itemName,
  rules,
  validateTrigger = "onSubmit",
  disabled,
  maxLength,
  cleared,
  setClear = () => null,
  allowClear,
}) => {
  const [characterCount, setCharacterCount] = useState(0);

  const handleChange = (e) => {
    if (onInputChange) {
      onInputChange(e);
    }
    setCharacterCount(e.target.value.length);
  };

  useEffect(() => {
    setCharacterCount(value ? value.length : 0);
    setClear(false);
  }, [cleared, setClear, value]);

  return (
    <div className={styles.inputLabeledContainer}>
      {(label || maxLength) && (
        <div className={styles.labelRow}>
          {label ? <div className={labelClass || styles.label}>{label}</div> : <div className={styles.label} />}
          {maxLength && (
            <div className={labelClass || styles.label}>
              {characterCount}/{maxLength}
            </div>
          )}
        </div>
      )}
      <Item name={itemName} rules={rules} validateTrigger={validateTrigger}>
        <Input
          className={inputClass || styles.input}
          onChange={handleChange}
          onBlur={handleChange}
          allowClear={allowClear}
          placeholder={placeholder}
          maxLength={maxLength}
          prefix={inputPrefix ? <span className={styles.prefix}>{inputPrefix}</span> : null}
          value={value}
          disabled={disabled}
        />
      </Item>
    </div>
  );
};

InputLabeled.propTypes = {
  cleared: PropTypes.bool,
  setClear: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  onInputChange: PropTypes.func,
  inputPrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  itemName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  // form validation rules
  rules: PropTypes.arrayOf(PropTypes.shape({})),
  validateTrigger: PropTypes.string,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  maxLength: PropTypes.number,
  value: PropTypes.string,
};

export default InputLabeled;
