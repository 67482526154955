import { styled } from "@mui/material/styles";
import { theme } from "styles/themes";
import { mq } from "styles";

export const TestimonialContainer = styled("div")(() => ({
  background: theme.colors["hazy-gray"],
  width: "100%",
  display: "flex",
  justifyContent: "center",
  [mq.phoneOnly]: {
    padding: "45px",
  },
  [mq.tabletUp]: {
    padding: "70px",
  },
  [mq.desktopUp]: {
    padding: "120px",
  },
}));

export const MaxWidthContainer = styled("div")(() => ({
  maxWidth: "1440px",
}));

export const CarouselHeader = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  [mq.phoneOnly]: {
    marginBottom: "48px",
    flexDirection: "column",
    gap: "40px",
  },
  [mq.tabletUp]: {
    marginBottom: "27px",
    flexDirection: "row",
  },
  [mq.desktopUp]: {
    marginBottom: "48px",
  },
}));

export const HeaderText = styled("div")(() => ({
  fontFamily: "Sora",
  fontWeight: 600,
  [mq.phoneOnly]: {
    fontSize: "48px",
  },
  [mq.tabletUp]: {
    fontSize: "34px",
  },
  [mq.desktopUp]: {
    fontSize: "48px",
  },
  lineHeight: "108%",
}));

export const ButtonContainer = styled("div")(() => ({
  display: "flex",
  [mq.phoneOnly]: {
    gap: "20px",
  },
  [mq.tabletUp]: {
    gap: "12px",
  },
  [mq.desktopUp]: {
    gap: "20px",
  },
}));

export const RotatedContainer = styled("div")(() => ({
  transform: "rotate(180deg)",
  height: "22px",
}));

export const ArrowButton = styled("div")(() => ({
  [mq.phoneOnly]: {
    width: "64px",
    height: "64px",
  },
  [mq.tabletUp]: {
    width: "40px",
    height: "40px",
  },
  [mq.desktopUp]: {
    width: "64px",
    height: "64px",
  },
  background: theme.colors.white,
  cursor: "pointer",
  border: `1px solid ${theme.colors.silverback}`,
  boxShadow: "0px 4px 4px 0px #00000040",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    boxShadow: "0px 2px 2px 0px #00000040",
    background: theme.colors["porcelain-gray"],
  },
}));

export const TestimonialCard = styled("div")(() => ({
  background: theme.colors.white,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  rowGap: "18px",
  minHeight: "525px",
  [mq.phoneOnly]: {
    padding: "24px 24px 50px",
  },
  [mq.tabletUp]: {
    padding: "40px",
  },
  [mq.tabletLandUp]: {
    marginTop: "48px",
  },
  [mq.desktopUp]: {
    padding: "72px 56px",
  },
}));

export const TestimonyRow = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  flexGrow: 1,
  rowGap: "16px",
  transition: "transform 0.5s ease-in-out",
}));

export const FeaturedQuote = styled("div")(() => ({
  fontWeight: 500,
  fontSize: "32px",
  lineHeight: "38px",
}));

export const TestimonialText = styled("div")(() => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "22px",
}));

export const SellerInfoRow = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "30px",
  gap: "16px",
  [mq.phoneOnly]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  [mq.tabletUp]: {
    flexDirection: "row",
    alignItems: "normal",
  },
}));

export const SellerNameColumn = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const SmallDivider = styled("div")(() => ({
  width: "55px",
  borderTop: `2px solid ${theme.colors["tango-orange"]}`,
  marginBottom: "30px",
}));

export const SellerName = styled("div")(() => ({
  fontSize: "16px",
  fontWeight: 500,
}));

export const SellerLocation = styled("div")(() => ({
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: 400,
}));

export const ImageContainer = styled("img")(() => ({
  height: "150px",
  borderRadius: "12px",
}));

export const CarouselFooter = styled("div")(() => ({
  width: "100%",
  display: "flex",
  alignItems: "flex-end",
  fontSize: "24px",
  lineHeight: "24px",
  fontWeight: 500,
  color: theme.colors["vantablack-a40"],
  [mq.phoneOnly]: {
    paddingTop: "32px",
    justifyContent: "center",
  },
  [mq.tabletUp]: {
    justifyContent: "flex-end",
  },
  [mq.tabletLandUp]: {
    paddingTop: "56px",
  },
}));

export const CurrentTestimonialCount = styled("span")(() => ({
  fontSize: "32px",
  lineHeight: "32px",
  fontWeight: 500,
  color: theme.colors.vantablack,
}));
