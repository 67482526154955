import { put, all } from "redux-saga/effects";
import { keysToCamelCase } from "lib/helpers";

import makeRequest from "rdx/utils/makeRequest";
// import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
// import messages from "../messages";

function* getPageReferrer(action) {
  const { ref_id, slug, pageName, history } = action.payload;
  const params = {
    page: pageName,
  };
  const { success, data, error } = yield* makeRequest.get(`/pages/${ref_id || slug}`, {
    ...params,
  });

  const retry = () => {
    if (ref_id && slug) {
      history.push(`/${slug}`);
    } else {
      history.push("/");
    }
  };

  if (success && data) {
    yield put(actions.setPageReferrer({ ...keysToCamelCase(data.body.user) }));
  } else if (error) {
    yield all([put(actions.setPageReferrer(null)), retry()]);
  }

  return null;
}

export default getPageReferrer;
