import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

import { theme } from "styles/themes";

export const NoteContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  background: theme.colors["picton-blue-a10"],
  padding: "16px 16px 4px 16px",
  color: theme.colors["darkest-blue"],
  fontSize: "16px",
}));

export const BoldSpan = styled("span")(() => ({
  fontWeight: 600,
}));

export const NoteBoldFont = styled("div")(() => ({
  fontSize: "16px",
  lineHeight: "19px",
  fontWeight: 600,
  marginBottom: "6px",
}));

export const NoteSemiBoldFont = styled("div")(() => ({
  fontWeight: 500,
  fontSize: "16px",
  marginBottom: "22px",
}));

export const NoteItalicFont = styled("div")(() => ({
  fontStyle: "italic",
  fontWeight: 500,
  fontSize: "16px",
  marginBottom: "22px",
}));

export const NoteBoldLink = styled(Link)(() => ({
  fontWeight: 600,
  color: theme.colors["blue-ribbon"],
  cursor: "pointer",
  textDecoration: "underline",
}));

export const NoteList = styled("ul")(() => ({
  fontWeight: 500,
  fontSize: "16px",
  paddingLeft: "28px",
  marginTop: "6px",
  marginBottom: "24px",
}));
