import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/auth/types";
import login from "./login";
import passwordResetRequest from "./passwordResetRequest";
import createNewPasswordRequest from "./createNewPasswordRequest";
import logout from "./logout";
import oauth from "./oauth";
import acceptedLatestTermsOfService from "./acceptedLatestTermsOfService";
import getLatestTermsOfService from "./getLatestTermsOfService";
// IMPORT_PT (for script, do not remove)

function* watchAuthSagas() {
  yield trackRequests(takeEvery, types.LOGIN, login);
  yield trackRequests(takeEvery, types.LOGOUT, logout);
  yield trackRequests(takeEvery, types.OAUTH, oauth);
  yield trackRequests(takeEvery, types.PASSWORD_RESET_REQUEST, passwordResetRequest);
  yield trackRequests(takeEvery, types.CREATE_NEW_PASSWORD_REQUEST, createNewPasswordRequest);
  yield trackRequests(takeEvery, types.ACCEPTED_LATEST_TOS_REQUEST, acceptedLatestTermsOfService);
  yield trackRequests(takeEvery, types.GET_LATEST_TOS_REQUEST, getLatestTermsOfService);
  // INSERTION_PT (for script, do not remove)
}

export default watchAuthSagas;
