import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import SessionClient from "util/SessionClient";

import styles from "../MainNav.module.less";

const routeRow = (props) => {
  const { route, activeRoute, mainNavCollapsed, navigate, openMainNav } = props;

  const session = new SessionClient();
  const unpaid = session.backOfficeAccess === "needs_activation";

  const Icon = route.icon;
  const isActive = route.key === activeRoute;
  const gettingStarted = route.key === "getting-started";

  const sectionStyle = () => {
    if (mainNavCollapsed) {
      return styles.collapsedRow;
    }
    if (gettingStarted && !isActive && unpaid) {
      return styles.gettingStartedRow;
    }
    return styles.sectionRow;
  };

  const handleMouseOver = (e) => {
    if (mainNavCollapsed) {
      openMainNav(e);
    }
  };

  return (
    <Row
      className={[sectionStyle(), ...(isActive ? [styles.activeRow] : [])].join(" ")}
      onClick={(e) => {
        const destination = route.linkTarget === "/projects" ? "/projects/pre-sales?status=active" : route.linkTarget;
        navigate(e, { ...route, linkTarget: destination });
      }}
    >
      <Col
        data-test-id={`RouteRow-${route.key}`}
        className={styles.iconCol}
        onMouseOver={handleMouseOver}
        onFocus={handleMouseOver}
      >
        {route.icon && <Icon active={isActive} />}
      </Col>
      <Col className={styles.textCol}>
        <span className={styles.linkText}>{route.linkTitle}</span>
      </Col>
    </Row>
  );
};

routeRow.propTypes = {
  route: PropTypes.shape({
    icon: PropTypes.func,
    key: PropTypes.string,
    linkTitle: PropTypes.string,
  }),
  activeRoute: PropTypes.string,
  mainNavCollapsed: PropTypes.bool,
  navigate: PropTypes.func,
  openMainNav: PropTypes.func.isRequired,
};

export default routeRow;
