import { createTheme, PaletteColorOptions } from "@mui/material/styles";
import { colors, TColors } from "styles/colorVariables";
import { TTypography } from "./types";

const customColors = Object.keys(colors)
  .map((key) => ({ [key]: `var(--${key})` }))
  .reduce((res, current) => Object.assign(res, current), {}) as TColors;

declare module "@mui/material/styles" {
  interface SimplePaletteColorOptions {
    hover?: string;
    iconBg?: string;
    iconBgHover?: string;
    pending?: string;
    mine?: string;
    accepted?: string;
  }
  interface PaletteOptions {
    pending?: PaletteColorOptions;
    mine?: PaletteColorOptions;
    accepted?: PaletteColorOptions;
    awaiting_proposal: PaletteColorOptions;
    awaiting_signature: PaletteColorOptions;
    contract_signed: PaletteColorOptions;
    present_proposal: PaletteColorOptions;
    take_action: PaletteColorOptions;
    request_proposal: PaletteColorOptions;
    qualify: PaletteColorOptions;
    closed: PaletteColorOptions;
    canceled: PaletteColorOptions;
    view_offer: PaletteColorOptions;
    new: PaletteColorOptions;
  }

  interface Theme {
    formComponents?: {
      typography: TTypography;
    };
    colors: TColors;
    palette: PaletteOptions;
  }

  interface ThemeOptions {
    formComponents?: {
      typography: TTypography;
    };
    colors: TColors;
  }
}

const theme = createTheme({
  formComponents: { typography: { label: { color: customColors["--luxe-blue"], fontSize: "14px", fontWeight: 500 } } },
  colors: {
    ...colors,
  },
  typography: {
    allVariants: { fontFamily: "Barlow" },
  },
  palette: {
    pending: {
      main: colors["graham-grey"],
      hover: colors["institutional-grey-hover"],
    },
    mine: {
      main: colors["blue-ribbon"],
      hover: colors["mangus-blue-hover"],
    },
    accepted: {
      main: colors["blue-purple"],
      hover: colors["blue-purple-hover"],
    },
    awaiting_proposal: {
      main: colors["dark-periwinkle"],
      iconBg: colors["institutional-grey"],
    },
    awaiting_signature: {
      main: colors["move-it-forward-disabled"],
    },
    contract_signed: {
      main: colors["green-haze"],
      hover: colors["green-haze-highlighted"],
      iconBg: colors["green-haze-button"],
      iconBgHover: colors["green-haze-button-highlighted"],
    },
    present_proposal: {
      main: colors["blue-ribbon"],
      hover: colors["banner-blue-highlighted"],
      iconBg: colors["banner-blue-highlighted"],
      iconBgHover: colors["mangus-blue-hover"],
    },
    take_action: {
      main: colors["blue-ribbon"],
      hover: colors["banner-blue-highlighted"],
      iconBg: colors["banner-blue-highlighted"],
      iconBgHover: colors["mangus-blue-hover"],
    },
    request_proposal: {
      main: colors["blue-ribbon"],
      hover: colors["banner-blue-highlighted"],
      iconBg: colors["banner-blue-highlighted"],
      iconBgHover: colors["mangus-blue-hover"],
    },
    qualify: {
      main: colors["blue-ribbon"],
      hover: colors["banner-blue-highlighted"],
      iconBg: colors["banner-blue-highlighted"],
      iconBgHover: colors["mangus-blue-hover"],
    },
    new: {
      main: colors["blue-ribbon"],
      hover: colors["banner-blue-highlighted"],
      iconBg: colors["banner-blue-highlighted"],
      iconBgHover: colors["mangus-blue-hover"],
    },
    closed: {
      main: colors["green-haze"],
      hover: colors["green-haze-highlighted"],
      iconBg: colors["green-haze-button"],
      iconBgHover: colors["green-haze-button-highlighted"],
    },
    canceled: {
      main: colors["move-it-forward-disabled"],
    },
    view_offer: {
      main: colors["status-alert-fill"],
    },
  },
});

export { theme };
