import React from "react";
import { IconT } from "../../types/utils";

const ProjectBlockerIcon = ({ width = 46, height = 46, fill = "none", ...rest }: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} {...rest}>
      <path
        fill="#fff"
        fillOpacity={0.25}
        d="M0 23C0 10.297 10.297 0 23 0s23 10.297 23 23-10.297 23-23 23S0 35.703 0 23Z"
      />
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M23 35c6.616 0 12-5.383 12-12 0-3.208-1.248-6.222-3.513-8.487A11.923 11.923 0 0 0 23 11c-6.617 0-12 5.383-12 12 0 3.208 1.248 6.222 3.513 8.487A11.922 11.922 0 0 0 23 35Zm0-2a9.93 9.93 0 0 1-6.331-2.255L30.745 16.67A9.93 9.93 0 0 1 33 23c0 5.514-4.486 10-10 10Zm0-20c2.336 0 4.55.794 6.33 2.255L15.256 29.33A9.93 9.93 0 0 1 13 23c0-5.514 4.486-10 10-10Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M11 11h24v24H11z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProjectBlockerIcon;
