import { put, select, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import { keysToCamelCase } from "lib/helpers";
import selectors from "rdx/modules/workflows/selectors";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../../messages";

function* updateDataGroup(action) {
  const { id, label, description, milestoneId: milestone_id } = action.payload;
  const { success, data, error } = yield* makeRequest.patch(`/data_groups/${id}`, {
    label,
    description,
    milestone_id,
  });
  if (success && data) {
    const newData = keysToCamelCase(data.body.data_group);
    if (newData.metaFields) {
      newData.metaFields = newData.metaFields.map((f) => keysToCamelCase(f));
    }
    const groups = yield select(selectors.getDataGroups);
    const newGroups = groups.map((g) => (g.id === newData.id ? { ...g, ...newData } : g));
    yield all([
      put(actions.setDataGroups(newGroups)),
      put(
        actions.newSuccessEvent({
          message: messages.DATA_GROUP_UPDATE_SUCCESS,
          dataGroupId: id,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_DATA_GROUP,
    });
  }
  return null;
}

export default updateDataGroup;
