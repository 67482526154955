import React from "react";
import { ButtonProps, Tooltip } from "@mui/material";
import { TrashcanIcon } from "components/Icons";

import * as S from "./styles";

export type PrimaryButtonT = {
  children?: React.ReactNode;
  variant?: "outlined" | "contained" | "text";
  gradient?: boolean;
  reverseGradient?: boolean;
  gradientOutlined?: boolean;
  white?: boolean;
  buttonStyle?: "transparent" | "delete" | "completeProfile" | "text";
  width?: string;
  canceled?: boolean;
  height?: string;
  tooltipTitle?: string;
  component?: React.ElementType | string;
  startColor?: string;
  stopColor?: string;
} & ButtonProps;

const PrimaryButton = ({
  children,
  buttonStyle,
  variant = "contained",
  disabled,
  onClick,
  tooltipTitle,
  ...rest
}: PrimaryButtonT) => {
  const handleClick = (e) => {
    if (!disabled && onClick) onClick(e);
  };

  return (
    <Tooltip title={tooltipTitle} placement="bottom">
      <S.PrimaryButton variant={variant} onClick={handleClick} buttonStyle={buttonStyle} disabled={disabled} {...rest}>
        <S.ButtonContent className="buttonText" style={{ textTransform: "capitalize" }}>
          {buttonStyle === "delete" ? <TrashcanIcon height={16} width={14} /> : children}
        </S.ButtonContent>
      </S.PrimaryButton>
    </Tooltip>
  );
};

export default PrimaryButton;
