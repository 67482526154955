export default {
  GET_ENTERPRISE_APPLICATIONS_SUCCESS: "Sales Pro Enterprise Applications Retrieved Successfully",

  GET_ENTERPRISE_APPLICATION_DETAIL_SUCCESS: "Enterprise Application Details Retrieved Successfully",

  GET_LABOR_APPLICATION_STATUS_SUCCESS: "Enterprise LABOR Application Status Details Retrieved Successfully",

  APPROVE_ENTERPRISE_APPLICATION_SUCCESS: "Enterprise Application Approved",

  REJECT_ENTERPRISE_APPLICATION_SUCCESS: "Enterprise Application Rejected",

  MAKE_PENDING_ENTERPRISE_APPLICATION_SUCCESS: "Enterprise Application Made Pending",

  CREATE_ENTERPRISE_APPLICATIONS_SUCCESS: "Enterprise Application Created",

  UPDATE_ENTERPRISE_APPLICATIONS_SUCCESS: "Enterprise Application Updated",

  CREATE_TIER_ONE_ENTERPRISE_USER_SUCCESS: "Enterprise Tier One User Registration Success",

  ERROR_GETTING_ENTERPRISE_APPLICATIONS: "There was an error getting Enterprise Applications",

  ERROR_GETTING_ENTERPRISE_APPLICATION_DETAIL: "There was an error getting this Enterprise Application's Details",

  ERROR_APPROVING_ENTERPRISE_APPLICATION: "There was an error approving this Enterprise Application",

  ERROR_REJECTING_ENTERPRISE_APPLICATION: "There was an error rejecting this Enterprise Application",

  ERROR_MAKING_PENDING_ENTERPRISE_APPLICATION: "There was an error making this Enterprise Application pending",

  ERROR_CREATING_ENTERPRISE_APPLICATIONS: "There was an error creating this Enterprise Application",

  ERROR_UPDATING_ENTERPRISE_APPLICATIONS: "There was an error updating this Enterprise Application",

  ERROR_CREATING_TIER_ONE_USER_REGISTRATION: "There was an error creating tier one user registration",

  ERROR_GETTING_ENTERPRISE_APPLICATIONS_CSV: "There was an error generating this CSV",
};
