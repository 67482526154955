import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getEnterpriseProjects(action) {
  const params = {
    ...action.payload,
  };
  const { success, data, error } = yield* makeRequest.get("/enterprise/projects", params);
  if (success && data) {
    yield put(actions.setEnterpriseProjectsList(camelizeKeys(data.body)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ENTERPRISE_PROJECTS,
    });
  }
  return null;
}

export default getEnterpriseProjects;
