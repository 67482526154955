import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const SiteSurveyCompleteIcon = ({ height = 18, width = 18, fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2043_13545)">
        <path
          d="M7.41797 5.83594C7.41797 4.38212 6.23507 3.19922 4.78125 3.19922C3.32743 3.19922 2.14453 4.38212 2.14453 5.83594C2.14453 7.28975 3.32743 8.50781 4.78125 8.50781C6.23507 8.50781 7.41797 7.28975 7.41797 5.83594Z"
          fill={fill}
        />
        <path
          d="M9.52734 13.7812H9C8.70877 13.7812 8.47266 14.0173 8.47266 14.3086V15.3633C8.47266 15.6545 8.70877 15.8906 9 15.8906H9.52734C10.1098 15.8906 10.582 15.4184 10.582 14.8359C10.582 14.2534 10.1098 13.7812 9.52734 13.7812Z"
          fill={fill}
        />
        <path
          d="M2.99883 18L3.34691 16.9453H2.67188C2.10238 16.9453 1.56445 16.8188 1.08984 16.5762V17.4727C1.08984 17.768 1.32184 18 1.61719 18H2.99883Z"
          fill={fill}
        />
        <path
          d="M2.67188 15.8903H3.70543C3.66237 16.0207 4.36486 13.8924 4.40156 13.7813H2.67188C2.37653 13.7813 2.14453 13.5492 2.14453 13.2539V11.1445C2.14453 10.8492 2.37653 10.6172 2.67188 10.6172C2.96722 10.6172 3.19922 10.8492 3.19922 11.1445V12.7262H4.74957C4.71217 12.8371 5.30286 11.0867 5.34027 10.9757C5.40355 10.7648 5.60394 10.6172 5.83594 10.6172H9.47461C9.232 9.41484 8.1668 8.50781 6.89062 8.50781H2.67188C1.21201 8.50781 0 9.691 0 11.1445V13.2539C0 14.7622 1.29466 15.8903 2.67188 15.8903Z"
          fill={fill}
        />
        <path
          d="M9.70384 11.6719H6.21694L4.10742 18H7.94627C8.17314 18 8.37455 17.8548 8.44634 17.6396L8.68832 16.9137C7.96526 16.7681 7.41893 16.1286 7.41893 15.3633V14.3086C7.41893 13.4362 8.12859 12.7266 9.00096 12.7266H9.5283C9.71252 12.7266 9.8882 12.7579 10.0587 12.8024L10.2041 12.3659C10.318 12.0245 10.0638 11.6719 9.70384 11.6719Z"
          fill={fill}
        />
        <path
          d="M16.418 0H10.0547C9.18232 0 8.47266 0.709664 8.47266 1.58203V5.83594C8.47266 6.7083 9.18232 7.45312 10.0547 7.45312H10.582V9.03516C10.582 9.24834 10.7102 9.44096 10.9075 9.52232C11.102 9.60349 11.3302 9.55997 11.4822 9.40799L13.4371 7.45312H16.418C17.2903 7.45312 18 6.7083 18 5.83594V1.58203C18 0.709664 17.2903 0 16.418 0ZM14.6463 3.57205L13.0642 5.15408C12.9612 5.25709 12.8263 5.30859 12.6914 5.30859C12.5565 5.30859 12.4215 5.25709 12.3186 5.15408L11.5276 4.36307C11.3216 4.15709 11.3216 3.82335 11.5276 3.61737C11.7335 3.41139 12.0673 3.41139 12.2733 3.61737L12.6914 4.03555L13.9006 2.82635C14.1066 2.62037 14.4403 2.62037 14.6463 2.82635C14.8523 3.03233 14.8523 3.36607 14.6463 3.57205Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2043_13545">
          <rect width={width} height={height} fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SiteSurveyCompleteIcon;
