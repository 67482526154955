import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getSeatApprovals(action) {
  const params = {
    sort: "date",
    ...action.payload,
  };

  const { success, data, error } = yield* makeRequest.get("/enterprise_seat_recruits", params);

  if (success && data) {
    const out = camelizeKeys(data.body);

    yield put(actions.setSeatApprovals(out));
  } else {
    return getErrorActions({
      error,
      message: messages.GET_SEAT_APPROVALS_ERROR,
    });
  }
  return null;
}

export default getSeatApprovals;
