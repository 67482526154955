import React from "react";
import { IconT } from "types/utils";
import { theme } from "styles/themes";

const PlayButtonIcon = ({ height = "90", width = "90", fill = theme.colors["light-silver"] }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="45" cy="45" r="43.5" stroke={fill} strokeWidth="3" />
      <path d="M62 42.5L38.75 55.9234L38.75 29.0766L62 42.5Z" fill={fill} />
    </svg>
  );
};
export default PlayButtonIcon;
