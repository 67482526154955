import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* startUsersContestReport(action) {
  const params = {
    sort: "name",
    ...action.payload,
  };
  const { success, data, error } = yield* makeRequest.post("/reports", params);
  if (success && data) {
    yield put(actions.setUsersContestReport(data.body));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_STARTING_USERS_CONTEST_REPORT,
    });
  }
  return null;
}

export default startUsersContestReport;
