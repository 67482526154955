import ApiClient from "../util/ApiClient";

/* eslint-disable-next-line import/prefer-default-export */
export class Base {
  constructor(props, resKey) {
    this.props = props;
    this.api = new ApiClient();
    this.resKey = resKey;
  }

  resToProps = (res) => {
    if (this.resKey) {
      this.props = res.body[this.resKey];
    } else {
      this.props = res.body;
    }
    return res;
  };
}
