import { sellerIntegrityModules } from "lib/misc/dropdownOptions";
import { ViolationModulesT } from "containers/Admin/users/components/UserDetailDrawer/types";

const formatIntegrityModules = (moduleList: ViolationModulesT[]): ViolationModulesT[] => {
  return sellerIntegrityModules.map((module): ViolationModulesT => {
    const assignedModule = moduleList.find(({ name }) => name === module.key);

    return {
      name: module.key,
      displayName: module.displayName,
      complete: !!assignedModule?.complete,
      assigned: !!assignedModule,
    };
  });
};

export default formatIntegrityModules;
