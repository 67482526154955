import component from "containers/StaticPages/education/whysolar";
// import l from "@loadable/component";

// const component = l(() => import("containers/education/whysolar"));

export default {
  key: "education-why-solar",
  title: "Education: Why Solar?",
  path: "/:user?/education/why-solar",
  pageName: "why_solar",
  linkTarget: "/education/why-solar",
  component,
};
