import { useDispatch } from "react-redux";
import useRouteMatcher from "hooks/useRouteMatcher";
import { actions } from "rdx";

type ActionsT = typeof actions;

type useDispatchListT = (actionType: ActionsT[keyof ActionsT]) => (payload?: Record<string, unknown>) => void;

export const useDispatchList: useDispatchListT = (actionType) => {
  const { isAdmin } = useRouteMatcher();

  const dispatch = useDispatch();

  const getList = (payload?: Record<string, unknown>) => {
    dispatch(actionType({ ...payload, admin: isAdmin, requestFreshData: false }));
  };

  return getList;
};

export default useDispatchList;
