import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enterpriseApplications/messages";
import { camelizeKeys } from "lib/helpers";

function* getEnterpriseApplications(action) {
  const { success, data, error } = yield* makeRequest.get("/enterprise_applications", action.payload);

  if (success && data) {
    const list = camelizeKeys(data.body.enterprise_applications);
    const paging = camelizeKeys(data.body.paging);
    yield put(actions.setEnterpriseApplications({ list, paging }));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ENTERPRISE_APPLICATIONS,
    });
  }

  return null;
}

export default getEnterpriseApplications;
