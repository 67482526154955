import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  stateLicenses: [],
  states: [],
  updatedAt: [],
};

export default {
  stateLicenses: createReducer(initialState, {
    [types.SET_STATE_LICENSES](state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
