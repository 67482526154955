import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const ContractSignedIcon = ({ height = 17, width = 17, fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2031_13697)">
        <path
          d="M12.9335 2.90268C12.9335 2.50385 12.7778 2.12839 12.4957 1.84629L11.0872 0.43775C10.809 0.159541 10.4141 0 10.0211 0H9.93555V2.98828H12.9335L12.9335 2.90268Z"
          fill={fill}
        />
        <path
          d="M9.09557 10.2776C9.19089 9.9882 9.35724 9.71875 9.57416 9.50038L12.9463 6.14178V3.98438H9.44672C9.17143 3.98438 8.96484 3.76162 8.96484 3.48633V0H1.49414C0.670238 0 0 0.670238 0 1.49414V15.5059C0 16.3298 0.670238 17 1.49414 17H11.4389C12.2628 17 12.9463 16.3298 12.9463 15.5059V11.7887L12.399 12.3228C12.1772 12.5426 11.9175 12.7026 11.6247 12.8004L10.0411 13.3291L9.79984 13.3422C9.401 13.3422 9.02358 13.1482 8.78917 12.8233C8.55668 12.4998 8.49346 12.0806 8.6199 11.7017L9.09557 10.2776ZM2.49023 8.96484H7.45453C7.72982 8.96484 7.95258 9.1876 7.95258 9.46289C7.95258 9.73818 7.72982 9.96094 7.45453 9.96094H2.49023C2.21495 9.96094 1.99219 9.73818 1.99219 9.46289C1.99219 9.1876 2.21495 8.96484 2.49023 8.96484ZM1.99219 7.4707C1.99219 7.19542 2.21495 6.97266 2.49023 6.97266H7.45453C7.72982 6.97266 7.95258 7.19542 7.95258 7.4707C7.95258 7.74599 7.72982 7.96875 7.45453 7.96875H2.49023C2.21495 7.96875 1.99219 7.74599 1.99219 7.4707ZM1.99219 11.4551C1.99219 11.1798 2.21495 10.957 2.49023 10.957H7.45453C7.72982 10.957 7.95258 11.1798 7.95258 11.4551C7.95258 11.7304 7.72982 11.9531 7.45453 11.9531H2.49023C2.21495 11.9531 1.99219 11.7304 1.99219 11.4551ZM9.44672 13.9453C9.72201 13.9453 9.94477 14.1681 9.94477 14.4434C9.94477 14.7186 9.72204 14.9414 9.44672 14.9414H6.45844C6.18315 14.9414 5.96039 14.7186 5.96039 14.4434C5.96039 14.1681 6.18315 13.9453 6.45844 13.9453H9.44672ZM2.49023 5.97656C2.21495 5.97656 1.99219 5.7538 1.99219 5.47852C1.99219 5.20323 2.21495 4.98047 2.49023 4.98047H9.44672C9.72201 4.98047 9.94477 5.20323 9.94477 5.47852C9.94477 5.7538 9.72201 5.97656 9.44672 5.97656H2.49023Z"
          fill={fill}
        />
        <path
          d="M9.99731 10.7183L9.564 12.0182C9.49906 12.2129 9.68437 12.3982 9.8791 12.3331L11.1783 11.8992L9.99731 10.7183Z"
          fill={fill}
        />
        <path
          d="M13.866 6.61768L10.5859 9.89778L12.0006 11.3124C12.7419 10.5709 14.1844 9.1282 15.2805 8.03213L13.866 6.61768Z"
          fill={fill}
        />
        <path
          d="M16.7075 5.18935C16.5324 5.01327 16.2746 4.9126 16.0012 4.9126C15.7279 4.9126 15.4701 5.01327 15.294 5.18984L14.5703 5.91357L15.9847 7.32796C16.3809 6.93171 16.6548 6.65785 16.7085 6.60416C17.0975 6.21453 17.0975 5.57988 16.7075 5.18935Z"
          fill={fill}
        />
        <path
          d="M16.709 6.60488L16.7091 6.60479C16.7107 6.60315 16.7114 6.60241 16.7128 6.60107C16.7114 6.60246 16.7107 6.60319 16.709 6.60488Z"
          fill={fill}
        />
        <path
          d="M16.7129 6.60132C16.7144 6.59982 16.7161 6.59807 16.717 6.59717C16.7161 6.59811 16.7144 6.59982 16.7129 6.60132Z"
          fill={fill}
        />
        <path d="M16.7168 6.59738C16.721 6.59315 16.7209 6.59323 16.7168 6.59738Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_2031_13697">
          <rect width={width} height={height} fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContractSignedIcon;
