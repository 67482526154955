import React, { CSSProperties } from "react";
import { Button } from "antd";

import GradientComponent from "./CloseButtonGradient";

import styles from "./styles.module.less";

interface CloseButtonPropsI {
  action: () => void;
  className?: string;
  width?: number;
  height?: number;
  xSize?: number;
  buttonTypeStyle?: string;
  disabled?: boolean;
  style?: CSSProperties;
}

const CloseButton = ({
  action,
  className = styles.defaultCloseButton,
  width = 32,
  height = 32,
  xSize = 12,
  buttonTypeStyle,
  disabled = false,
  style = {},
}: CloseButtonPropsI) => {
  const getStyleType = (): JSX.Element | null => {
    switch (buttonTypeStyle) {
      case "gradient":
        return <GradientComponent height={xSize} width={xSize} disabled={false} />;
      default:
        return null;
    }
  };

  return (
    <Button
      disabled={disabled}
      style={{ width: `${width}px`, height: `${height}px`, ...style }}
      className={className}
      onClick={action}
    >
      {getStyleType()}
    </Button>
  );
};

export default CloseButton;
