import types from "rdx/modules/rbac/types";
import createAction from "rdx/utils/createAction";

export default {
  setCurrentUser: (payload) => createAction(types.SET_CURRENT_USER, payload),
  setUserPermissions: (payload) => createAction(types.SET_USER_PERMISSIONS, payload),
  setNoticeViewedByUser: (payload) => createAction(types.SET_NOTICE_VIEWED_BY_USER, payload),
  updateUnviewedNotices: (payload) => createAction(types.UPDATE_UNVIEWED_NOTICES, payload),
  updateInboxCount: (payload) => createAction(types.UPDATE_INBOX_COUNT, payload),
  updateInboxUnviewedMessagesCount: (payload) => createAction(types.UPDATE_INBOX_UNVIEWED_MESSAGES_COUNT, payload),
  updateInboxUnviewedArchivedCount: (payload) => createAction(types.UPDATE_INBOX_UNVIEWED_ARCHIVED_COUNT, payload),
  updateMyProjectsCount: (payload) => createAction(types.UPDATE_MY_PROJECTS_COUNT, payload),
  clearRbac: () => createAction(types.CLEAR_RBAC, {}),
  // INSERTION_PT (for script, do not remove)
};
