import React from "react";
import { useController } from "react-hook-form";
import Radio from "@mui/material/Radio";
import { TextFieldProps } from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { Tooltip } from "components/mui-styled-components/Tooltip";
import { useSmartFormContext } from "components/SmartForm";
import { UncheckedRadioButton, CheckedRadioButton } from "components/Icons";
import HelperText from "../HelperText";
import * as S from "./styles";

type SmartFormRadioTextFieldT = {
  name: string;
  selected: boolean;
  radioText: string;
  onRadioClick: (v: boolean) => void;
  tooltipText?: string;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  prefixBorder?: boolean;
  suffixBorder?: boolean;
  disabled?: boolean;
  textFieldWidth?: string;
  error?: boolean;
  helperText?: string;
} & TextFieldProps;

const SmartFormRadioTextField = ({
  name,
  selected,
  radioText,
  onRadioClick,
  tooltipText,
  prefix,
  suffix,
  prefixBorder = true,
  suffixBorder = true,
  disabled = false,
  textFieldWidth = "140px",
  error,
  helperText,
  ...rest
}: SmartFormRadioTextFieldT) => {
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });

  const handleRadioClick = (e) => {
    onRadioClick(e.target.checked);
  };

  return (
    <S.RadioTextContainer>
      <S.RadioRow>
        <Radio
          icon={<UncheckedRadioButton />}
          checkedIcon={<CheckedRadioButton />}
          checked={selected}
          disableFocusRipple
          onChange={handleRadioClick}
        />
        <S.RadioText>{radioText}</S.RadioText>
        {tooltipText && (
          <Tooltip placement="right" title={<S.TooltipText>{tooltipText}</S.TooltipText>}>
            <S.TooltipIcon>!</S.TooltipIcon>
          </Tooltip>
        )}
      </S.RadioRow>
      {selected && (
        <>
          <S.StyledTextField
            hasPrefix={!!prefix}
            hasSuffix={!!suffix}
            InputProps={{
              ...(prefix && {
                startAdornment: (
                  <InputAdornment position="start">
                    <S.PrefixWrapper border={prefixBorder}>{prefix}</S.PrefixWrapper>
                  </InputAdornment>
                ),
              }),
              ...(suffix && {
                endAdornment: (
                  <InputAdornment position="end">
                    <S.SuffixWrapper border={suffixBorder}>{suffix}</S.SuffixWrapper>
                  </InputAdornment>
                ),
              }),
            }}
            data-test-id={`${name}-SmartFormRadioTextField`}
            disabled={disabled}
            size="small"
            sx={{ backgroundColor: "white", borderRadius: "4px", width: textFieldWidth }}
            {...field}
            {...rest}
          />
          <HelperText sx={{ marginTop: "-22px", marginLeft: "42px" }} error={error}>
            {helperText}
          </HelperText>
        </>
      )}
    </S.RadioTextContainer>
  );
};

export default SmartFormRadioTextField;
