export default {
  getPendingProposalsList: (state) => state.adminProposals.pending.list,
  getPendingProposalsFilters: (state) => state.adminProposals.pending.filters,

  getInProgressProposalsList: (state) => state.adminProposals.inProgress.list,
  getInProgressProposalsFilters: (state) => state.adminProposals.inProgress.filters,

  getCompletedProposalsList: (state) => state.adminProposals.completed.list,
  getCompletedProposalsFilters: (state) => state.adminProposals.completed.filters,

  // This is very dangerous. Also, it's hard to find.
  // There is no type safety in this selector and it is easy to encounter a runtime error from destructuring undefined.
  getAllProposalsFilters: (state) => [
    state.adminProposals.pending.filters,
    state.adminProposals.inProgress.filters,
    state.adminProposals.completed.filters,
    {},
  ],

  getProposalsCounters: (state) => state.adminProposals.counts,

  getProposalsAutoComplete: (state) => state.adminProposals.proposalsAutoComplete,

  getAdminProposalDetails: (state) => state.adminProposals.proposalDetails,
};
