import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "rdx/modules/consultantDashboard/messages";

function* getSellerDashboard(action) {
  const { isOrgDashboard } = action.payload;
  const endpoint = isOrgDashboard ? "/enterprise_dashboard" : "/consultant_dashboard";

  const { success, data, error } = yield* makeRequest.get(endpoint, {});
  if (success && data) {
    yield all([
      put(
        actions.setSellerDashboard({
          ...camelizeKeys(data.body),
          dashboardInit: true,
        }),
      ),
    ]);
  } else if (error) {
    const errorMessage = messages.ERROR_GETTING_CONSULTANT_DASHBOARD;
    return getErrorActions({
      error,
      message: errorMessage,
    });
  }

  return null;
}

export default getSellerDashboard;
