import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/users/messages";
import selectors from "rdx/modules/users/selectors";

function* updatePermissionsEntity(action) {
  const { success, data, error } = yield* makeRequest.patch(`/entities/${action.payload.id}`, {
    name: action.payload.name,
    description: action.payload.description,
  });

  if (success && data) {
    const entities = yield select(selectors.getEntities);
    const newEntities = entities.map((o) => {
      if (o.id === action.payload.id) {
        return data.body;
      }
      return o;
    });
    yield all([
      put(actions.setRolesList({ entities: newEntities })),
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_PERMISSION_ENTITY_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_PERMISSION_ENTITY,
    });
  }

  return null;
}

export default updatePermissionsEntity;
