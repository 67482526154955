import types from "rdx/modules/settings/types";
import createAction from "rdx/utils/createAction";

export default {
  setAdminSettings: (payload) => createAction(types.SET_ADMIN_PLATFORM_SETTINGS, payload),
  getAdminSettings: (payload) => createAction(types.GET_ADMIN_PLATFORM_SETTINGS_REQUEST, payload),
  updateAdminSetting: (payload) => createAction(types.UPDATE_ADMIN_PLATFORM_SETTING_REQUEST, payload),
  getEnterpriseAdminSettings: (payload) => createAction(types.GET_ENTERPRISE_ADMIN_SETTINGS_REQUEST, payload),
  setEnterpriseAdminSettings: (payload) => createAction(types.SET_ENTERPRISE_ADMIN_SETTINGS, payload),
  getAdminServiceAreas: (payload) => createAction(types.GET_ADMIN_SERVICE_AREAS_REQUEST, payload),
  setAdminServiceAreas: (payload) => createAction(types.SET_ADMIN_SERVICE_AREAS, payload),
  updateAdminServiceArea: (payload) => createAction(types.UPDATE_ADMIN_SERVICE_AREA_REQUEST, payload),
  removeAdminServiceArea: (payload) => createAction(types.REMOVE_ADMIN_SERVICE_AREA_REQUEST, payload),
  createAdminServiceArea: (payload) => createAction(types.CREATE_ADMIN_SERVICE_AREA_REQUEST, payload),
  setPowurPairWeights: (payload) => createAction(types.SET_POWUR_PAIR_WEIGHTS, payload),
  getPowurPairWeights: (payload) => createAction(types.GET_POWUR_PAIR_WEIGHTS, payload),
  updatePowurPairWeights: (payload) => createAction(types.UPDATE_POWUR_PAIR_WEIGHTS, payload),
  getAdminVolumeTiersSettings: (payload) => createAction(types.GET_ADMIN_VOLUME_TIERS_SETTINGS, payload),
  setAdminVolumeTiersSettings: (payload) => createAction(types.SET_ADMIN_VOLUME_TIERS_SETTINGS, payload),
  updateAdminVolumeTiersSettings: (payload) => createAction(types.UPDATE_ADMIN_VOLUME_TIERS_SETTINGS, payload),
  getAdminAdvanceSettings: (payload) => createAction(types.GET_ADMIN_ADVANCE_SETTINGS, payload),
  setAdminAdvanceSettings: (payload) => createAction(types.SET_ADMIN_ADVANCE_SETTINGS, payload),
  createAdminAdvanceSetting: (payload) => createAction(types.CREATE_ADMIN_ADVANCE_SETTING, payload),
  updateAdminAdvanceSetting: (payload) => createAction(types.UPDATE_ADMIN_ADVANCE_SETTING, payload),
  removeAdminAdvanceSetting: (payload) => createAction(types.REMOVE_ADMIN_ADVANCE_SETTING, payload),
  // INSERTION_PT (for script, do not remove)
};
