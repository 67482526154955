import React from "react";
import PropTypes from "prop-types";

const CaretRightOutlined = (props) => {
  const { fill, height, width, containerStyles } = props;

  return (
    <div style={{ paddingLeft: "2px", ...containerStyles }}>
      <svg width={width} height={height} viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.4382 3.59456C5.71473 3.79413 5.71473 4.20587 5.4382 4.40544L0.792603 7.75812C0.461922 7.99677 3.82933e-07 7.76048 3.65107e-07 7.35267L7.2007e-08 0.647327C5.41813e-08 0.239523 0.461921 0.00323558 0.792603 0.241885L5.4382 3.59456Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

CaretRightOutlined.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

CaretRightOutlined.defaultProps = {
  fill: "var(--dark-blue)",
  width: "7",
  height: "8",
};

export default CaretRightOutlined;
