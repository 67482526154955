import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import types from "../types";
import styles from "../styles.module.less";
import "./layoutAnimations.css";

const Layout = ({
  dropzoneProps,
  // extra,
  files,
  input,
  previews,
  submitButton,
}) => {
  useEffect(() => {
    const previewGroup = document.getElementById("file-uploader-preview-group-6YXA0");
    if (files.length === 1) {
      previewGroup.style.borderTop = "1px solid #4E4F50";
    } else if (files.length === 0) {
      setTimeout(() => {
        previewGroup.style.borderTop = "none";
      }, 300);
    }
  }, [files.length]);

  return (
    <>
      <div {...dropzoneProps}>
        {input}
        <div className={styles.previewContainer}>
          <TransitionGroup id="file-uploader-preview-group-6YXA0" className={styles.previewGroup}>
            {previews.map((preview) => (
              <CSSTransition
                key={preview.props.meta.id}
                timeout={300}
                classNames="file-uploader-preview-animation-classes-4RV1B"
              >
                {preview}
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      </div>
      <div className={styles.submitButtonContainer}>{submitButton}</div>
    </>
  );
};

Layout.propTypes = {
  dropzoneProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  extra: types.extra,
  files: PropTypes.arrayOf(types.fileWithMeta),
  input: PropTypes.node,
  previews: PropTypes.arrayOf(PropTypes.node), // components/PreviewComponent
  submitButton: PropTypes.node, // components/SubmitButton
};

export default Layout;
