export default {
  // success messages
  SUCCESS_CREATING_SMS: "New sms created",
  SUCCESS_UPDATING_SMS_DETAIL: "Sms updated",
  SUCCESS_DELETING_SMS: "Sms deleted",

  // error messages
  ERROR_GETTING_SMS_LIST: "There was an error retrieving the sms list",
  ERROR_GETTING_SMS_DETAILS: "There was an error retrieving sms details",
  ERROR_CREATING_SMS: "There was an error creating a new sms.",
  ERROR_UPDATING_SMS_DETAIL: "There was an error updating this sms.",
  ERROR_DELETING_SMS: "There was an error deleting this sms.",
};
