import React from "react";
import { Paragraph } from "./AppText";

type AlertCopyProps = {
  children: React.ReactNode;
};

export const AlertCopy = ({ children }: AlertCopyProps) => {
  return (
    <Paragraph weight="400" size="18px">
      {children}
    </Paragraph>
  );
};
