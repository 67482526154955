import makeRequest from "rdx/utils/makeRequest";
import { all, put } from "redux-saga/effects";
import _ from "lodash";
import getErrorActions from "rdx/utils/getErrorActions";
import { camelizeKeys } from "lib/helpers";
import actions from "rdx/actions";
import messages from "../messages";

function* getAvailableManagers() {
  const { success, data, error } = yield* makeRequest.get("/sales_teams/available_managers");
  if (success && data) {
    const dataFormatted = camelizeKeys(_.clone(data.body));
    yield all([put(actions.setAvailableManagers(dataFormatted.users))]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_AVAILABLE_MANAGERS,
    });
  }

  return null;
}

export default getAvailableManagers;
