import styled from "@emotion/styled";
import { DialogActions, DialogTitle } from "@mui/material";
import GradientButton from "components/Buttons/GradientButton";

export const MaxWidth500 = styled.div`
  max-width: 500px;
`;

export const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background-color: #f7f9fc;
`;

export const StyledGradientButton = styled(GradientButton)`
  padding: 0 48px;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  font-family: "Poppins";
  font-weight: 700;
  line-height: "25px";
  font-size: "24px";
  color: #0171f3;
`;
