import { bindActionCreators } from "redux";
import actions from "rdx/actions";
import selectors from "rdx/selectors";

const getValidActions = (actionsRequested) => {
  const validActions = {};
  if (!actionsRequested) return validActions;
  for (const name of actionsRequested) {
    const action = actions[name];
    if (!action) {
      throw new Error(`No action found with name ${name}`);
    } else {
      validActions[name] = action;
    }
  }
  return validActions;
};

export const getRdxActionMapper = (actionsRequested) => {
  const validActions = getValidActions(actionsRequested);
  return (dispatch) => bindActionCreators(validActions, dispatch);
};

const getValidSelectors = (selectorsRequested) => {
  const validSelectors = {};
  if (!selectorsRequested) return validSelectors;
  for (const key of Object.keys(selectorsRequested)) {
    const info = selectorsRequested[key];
    const selector = selectors[info];
    if (!selector) {
      throw new Error(`No selector found with name ${info}`);
    } else {
      validSelectors[key] = selector;
    }
  }
  return validSelectors;
};

export const getRdxSelectionMapper = (selectorsRequested) => {
  const validSelectors = getValidSelectors(selectorsRequested);
  return (globalState) => {
    const componentState = {};
    for (const key of Object.keys(validSelectors)) {
      const selector = validSelectors[key];
      componentState[key] = selector(globalState);
    }
    return componentState;
  };
};
