import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";

function* getProjectPayTerms(action) {
  const { projectId } = action.payload;

  const params = { admin: action.payload.admin };
  const { success, data, error } = yield* makeRequest.get(`/projects/${projectId}/pay_terms`, params);

  if (success && data) {
    yield put(actions.setProjectPayTerms(camelizeKeys(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
    });
  }

  return null;
}

export default getProjectPayTerms;
