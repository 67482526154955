import React from "react";
import { useEffectOnce } from "react-use";
import { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import * as CustomIcons from "./customIcons";
import * as S from "./styles";

const icons = Quill.import("ui/icons");
icons.list.ordered = CustomIcons.orderedListIcon;
icons.list.bullet = CustomIcons.bulletListIcon;
icons.bold = CustomIcons.boldIcon;
icons.italic = CustomIcons.italicIcon;
icons.underline = CustomIcons.underlineIcon;
icons.strike = CustomIcons.strikethroughIcon;
icons.align[""] = CustomIcons.leftAlignIcon;
icons.align.right = CustomIcons.rightAlignIcon;
icons.align.center = CustomIcons.centerAlignIcon;
icons.color = CustomIcons.colorIcon;

export const formats = ["bold", "italic", "underline", "strike", "font", "header", "align", "color", "list"];

const alignOptions = ["", "center", "right"];

const CustomToolbar = () => {
  useEffectOnce(() => {
    const elem = document.getElementById("ql-picker-options-2");
    elem?.firstElementChild?.setAttribute("data-value", "#000");
  });

  return (
    <S.StyledToolbar id="toolbar_smart_form">
      <S.StyledFormatSection className="ql-formats">
        <S.StyledSuffixIcon>{CustomIcons.caretIcon}</S.StyledSuffixIcon>
        <select className="ql-header" defaultValue="3">
          <option value="3">Normal Text</option>
          <option value="2">Subheader</option>
          <option value="1">Header</option>
        </select>
      </S.StyledFormatSection>
      <S.StyledFormatSection className="ql-formats">
        <S.StyledSuffixIcon>{CustomIcons.caretIcon}</S.StyledSuffixIcon>
        <select className="ql-align">
          {alignOptions.map((value) => {
            return <option className="ql-align" value={value} key={value} />;
          })}
        </select>
      </S.StyledFormatSection>
      <S.StyledFormatSection className="ql-formats">
        <S.StyledSuffixIcon>{CustomIcons.caretIcon}</S.StyledSuffixIcon>
        <select className="ql-color" defaultValue="#000" />
      </S.StyledFormatSection>
      <S.StyledFormatSection className="ql-formats">
        <button className="ql-bold" type="button" />
        <button className="ql-italic" type="button" />
        <button className="ql-underline" type="button" />
        <button className="ql-strike" type="button" />
      </S.StyledFormatSection>
      <span className="ql-formats">
        <button className="ql-list" value="bullet" type="button" />
        <button className="ql-list" value="ordered" type="button" />
      </span>
    </S.StyledToolbar>
  );
};

export default CustomToolbar;
