import React from "react";
import type { GridColDef } from "@mui/x-data-grid-pro";
import { PrimaryButton } from "components/mui-styled-components";
import { T3PartnerTableDataT, T3PartnerT } from "components/Drawers/SelectT3PartnerDrawer/types";

type AssignColPropsT = {
  setSelectedT3Partner: (t3Partner: T3PartnerT) => void;
  setOpenFooter: (bool: boolean) => void;
};

export const AssignCol = ({
  setSelectedT3Partner,
  setOpenFooter,
}: AssignColPropsT): GridColDef<T3PartnerTableDataT> => ({
  field: "assign",
  width: 150,
  align: "center",
  renderCell: ({ row }) => {
    const handleAssign = () => {
      setSelectedT3Partner(row);
      setOpenFooter(true);
    };

    return (
      <PrimaryButton gradient onClick={handleAssign} style={{ minWidth: "98px" }}>
        Assign
      </PrimaryButton>
    );
  },
});
