import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { parse, stringify } from "query-string";
import { languages } from "i18n";
import { Select, Radio } from "antd";
import styles from "./ChangeLanguage.module.less";

const { Option } = Select;

const ChangeLanguage = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const currentLng = i18n.language;

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);

    // if user arrived with a language preference specified in query params,
    // then selects the default language, we want to remove that param from
    // the url to preserve their new language selection. E.g. if user arrives
    // with ?lng=es but actually wants English, they select English from
    // the dropdown, and we remove the lng query param as refreshing the
    // page will overwrite their selection and display spanish-language text
    const q = parse(history.location.search);
    if (q.lng && lng === i18n.options.fallbackLng[0]) {
      delete q.lng;
      history.replace({ search: stringify(q) });
    }
  };

  const selectLabel = (label) => (
    <div className={styles.selectLabel}>
      <div className={styles.icon} />
      <span className={styles.languageLabel}>{label}</span>
    </div>
  );

  const menuItems = Object.entries(languages).map(([lng, label]) => ({
    lng,
    label: selectLabel(label),
    onClick: () => handleChangeLanguage(lng),
    active: lng === currentLng,
  }));

  return (
    <Select
      className={styles.languageSelect}
      value={currentLng}
      onSelect={handleChangeLanguage}
      optionLabelProp="label"
      popupClassName={styles.languageDropdown}
    >
      {menuItems.map(({ lng, label, active }) => (
        <Option key={lng} value={lng} label={label} className={styles.option}>
          <div className={styles.optionContent}>
            <Radio className={styles.radioButton} checked={active} />
            <span className={styles.languageLabel}>{label}</span>
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default ChangeLanguage;
