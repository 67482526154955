import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form, Input, Select } from "antd";
import { CircleCloseIcon } from "@icons";
import Tag from "components/Tags/mention";
import { statesOperatedOptions, leadSourceOptions } from "./dropdownOptions";
import styles from "./EnterpriseProSignupModal.module.less";

const BusinessForm = (props) => {
  const { form } = props;
  const { Option } = Select;
  const { t } = useTranslation(["ent_join_form_labels"]);

  return (
    <>
      <Form form={form} className={styles.formContainer} requiredMark={false} colon={false}>
        <div className={styles.subHeaderContainer}>
          <div className={styles.subHeader}>{t("business_details")}</div>
          <div className={styles.underline} />
        </div>
        <span className={styles.inputLabel}>{t("years_in_business")}</span>
        <Form.Item
          name="yearsInBusiness"
          rules={[
            {
              required: true,
              message: t("number_of_years_required"),
            },
          ]}
        >
          <Input size="large" className={styles.input} name="years" />
        </Form.Item>
        <span className={styles.inputLabel}>{t("states_operated")}</span>
        <Form.Item
          name="statesOperatedIn"
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: t("states_operated_required"),
            },
          ]}
        >
          <Select
            size="large"
            mode="multiple"
            validateTrigger="onSubmit"
            className={styles.stateSelect}
            removeIcon={
              <div className={styles.closeIcon}>
                <CircleCloseIcon fill="var(--white)" color="var(--dark-blue)" />
              </div>
            }
          >
            {statesOperatedOptions.map((option) => (
              <Option
                key={option.value}
                value={option.value}
                label={<Tag title={option.label} style={{ padding: 0 }} />}
              >
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <span className={styles.inputLabel}>{t("sales_reps")}</span>
        <Form.Item
          name="numberOfReps"
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: t("sales_reps_required"),
            },
          ]}
        >
          <Input size="large" className={styles.input} name="sales_reps" />
        </Form.Item>
        <span className={styles.inputLabel}>{t("average_sales")}</span>
        <Form.Item
          name="monthlySales"
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: t("average_sales_required"),
            },
          ]}
        >
          <Input size="large" className={styles.input} name="average_sales" />
        </Form.Item>
        <span className={styles.inputLabel}>{t("average_revenue")}</span>
        <Form.Item
          name="monthlyRevenue"
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: t("average_revenue_required"),
            },
          ]}
        >
          <Input size="large" className={styles.input} name="average_revenue" />
        </Form.Item>
        <span className={styles.inputLabel}>{t("primary_lead_source")}</span>
        <Form.Item
          name="primaryLeadSource"
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: t("primary_lead_source_required"),
            },
          ]}
        >
          <Select size="large" className={styles.select} placeholder="Select One " name="primaryLeadSource">
            {leadSourceOptions.map((option) => (
              <Option key={option.key} value={option.value} title={option.label}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </>
  );
};

BusinessForm.propTypes = {
  // antd form instance
  form: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default BusinessForm;
