import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import { filterStyledProps } from "lib/helpers/filterStyledProps";
import { theme } from "styles/themes";

export const LabelGroupWrapper = styled("div")(() => ({
  display: "flex",
}));

export const StyledFormLabel = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "9px",
  marginBottom: "8px",
  color: theme.colors["nepal-blue"],
  textTransform: "uppercase",
}));

export const StyledAutocompleteOption = styled("li")(() => ({
  color: theme.colors["dark-blue"],
  fontSize: "14px",
}));

export const StyledTextField = styled(TextField, {
  shouldForwardProp: filterStyledProps(["redHighlight"]),
})(({ redHighlight }: { redHighlight: boolean }) => ({
  marginBottom: "12px",
  backgroundColor: "white",
  borderRadius: "4px",
  paddingTop: "0px",

  "& .MuiAutocomplete-input": {
    color: theme.colors["dark-blue"],
    fontSize: "14px",
    fontWeight: "500",
    padding: "7px 14px 8px",
    lineHeight: "17px",
    height: "17px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "6px",
    "&:not(:disabled)": {
      background: redHighlight ? theme.colors.bridesmaid : theme.colors.white,
    },

    "& > fieldset": { borderColor: redHighlight ? theme.colors.danger : theme.colors["botticelli-blue"] },
    "&.Mui-focused fieldset": {
      borderColor: redHighlight ? theme.colors.danger : theme.colors["botticelli-blue"],
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `${redHighlight ? theme.colors.danger : theme.colors["botticelli-blue"]} !important`,
  },
  "& .MuiOutlinedInput-root input": {
    borderRadius: "6px",
    "&:not(:disabled)": {
      background: redHighlight ? theme.colors.bridesmaid : theme.colors.white,
    },
  },
  "& .Mui-disabled": {
    background: theme.colors["cotton-boll"],
    borderRadius: "3px",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: theme.colors["queen-blue"],
  },
}));

export const StyledRequiredLabel = styled("p")(() => ({
  fontFamily: "Barlow",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "9px",
  color: theme.colors["commission-clawback"],
  width: "10px",
  margin: 0,
  textAlign: "center",
}));
