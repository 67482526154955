const questionsList = [
  {
    number: "01",
    title: "What is Net Metering?",
    description: "What is Net Metering and How Does it Benefit Me as a Solar Customer?",
    body: "Have you ever wondered how solar works with your current utility? In this video, the policy of Net Metering will be demystified. We will walk you through how this policy enabled solar to work with the utility’s grid to your advantage.",
    src: "https://player.vimeo.com/video/467513757?title=0&byline=0&portrait=0",
  },
  // {
  //   number: "02",
  //   title: "What is the Solar Investment Tax Credit?",
  //   description: "Get Your Solar Investment Investment Tax Credit (ITC) Before Time Runs Out!",
  //   body: "Did you know that the Federal Government will pay you to go solar? The Investment Tax Credit is a federal tax credit that in 2020 will pay you 26% of the value of your solar system. In this video, we will dive deeper into what the ITC is and how long it will last.",
  //   src: "https://player.vimeo.com/video/467514441?autoplay=1&title=0&byline=0&portrait=0",
  // },
  {
    number: "02",
    title: "How do Solar Warranties Work?",
    description: "How Do Solar Warranties Work? What Does a Powur Solar Warranty Cover?",
    body: "When it comes to protecting your solar investment, we’ve got you covered. In this video, we will outline how a solar warranty works and what a Powur Solar Warranty covers.",
    src: "https://player.vimeo.com/video/467515089?autoplay=1&title=0&byline=0&portrait=0",
  },
  {
    number: "03",
    title: "What is Powur's Mission?",
    description: "Powur’s Mission – Rooted in Making an Impact and Giving Back",
    body: "At Powur, our number one mission is to accelerate the adoption of clean sustainable energy worldwide because to us it is more than just about selling solar panels. Learn more about the causes you will help support by choosing Powur as your solar energy provider in this brief video.",
    src: "https://player.vimeo.com/video/467515727?autoplay=1&title=0&byline=0&portrait=0",
  },
  {
    number: "04",
    title: "Is Solar a Good Investment for My Family?",
    description: "Is Solar a Good Investment for My Family?",
    body: "Solar is one of the few investments that pay you back in energy! Watch this video to learn more about how solar can add anywhere from $10-50K to the value of your home.",
    src: "https://player.vimeo.com/video/467517302?autoplay=1&title=0&byline=0&portrait=0",
  },
  {
    number: "05",
    title: "How Do I Know if My Home is a Fit for Solar?",
    description: "How Do I Know if My Home is a Fit for Solar?",
    body: "So how exactly do you know if your home is right for solar? Powur will take care of everything with precision and deliver optimal recommendations for your home. In this video, learn how our Powur Energy Sellers determine the right system for you down to the kilowatt.",
    src: "https://player.vimeo.com/video/467518424?autoplay=1&title=0&byline=0&portrait=0",
  },
  {
    number: "06",
    title: "Think Solar is too Expensive?",
    description: "Think You Can’t Afford Solar? Think Again.",
    body: "Solar is the least expensive option when it comes to buying energy for your home. In this video, we outline the wide variety of loan terms Powur offers at the most competitive APRs possible.",
    src: "https://player.vimeo.com/video/467519389?autoplay=1&title=0&byline=0&portrait=0",
  },
  {
    number: "07",
    title: "What is Involved in Going Solar?",
    description: "What is Involved in Going Solar? Will it Mean a Lot of Work for Me?",
    body: "In this video, you will learn what will happen between signing the contract to the moment you start to generate your own power. Rest assured, you can count on us to be with you every step of the way!",
    src: "https://player.vimeo.com/video/467520670?autoplay=1&title=0&byline=0&portrait=0",
  },
  {
    number: "08",
    title: "Do I Need to Buy a Battery?",
    description: "Do I Need to Purchase a Battery as Part of My Solar Energy System?",
    body: "There is a lot of buzzes out there about batteries and the need for them as part of your system. In this video, we will talk about the reasons you might opt for a battery as part of your installed components.",
    src: "https://player.vimeo.com/video/467521719?autoplay=1&title=0&byline=0&portrait=0",
  },
  {
    number: "09",
    title: "Two Options for Purchasing Energy",
    description: "Two Options for Purchasing Energy – The Choice is Yours",
    body: "When it comes to purchasing energy, you essentially have two options; continue to rent dirty energy from the utility company and face ever-increasing rates or choose to take control of your power by installing solar in your home. In this final video, we will recap all the benefits of going solar and how incredibly easy it is to make the switch.",
    src: "https://player.vimeo.com/video/467522678?autoplay=1&title=0&byline=0&portrait=0",
  },
];

export default questionsList;
