import { put, all, select } from "redux-saga/effects";
import selectors from "rdx/modules/tickets/selectors";
import actions from "rdx/actions";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* createMessage(action) {
  const params = {
    ...action.payload,
  };
  const { success, data, error } = yield* makeRequest.post("/messages", params);

  if (success && data) {
    const notices = yield select(selectors.getInboxList);
    const newNotice = keysToCamelCase(data.body);
    const newList = [newNotice, ...notices];
    yield all([
      put(actions.setInboxList({ list: newList })),
      put(actions.setSelectedMessageDetails(newNotice)),
      put(
        actions.newSuccessEvent({
          message: messages.CREATE_PROJECT_MESSAGE_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_PROJECT_MESSAGE,
    });
  }
  return null;
}

export default createMessage;
