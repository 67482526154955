import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* getEvents(action) {
  const params = action.payload;
  const { success, data, error } = yield* makeRequest.get("/events", params);
  if (success && data) {
    yield put(actions.setEvents(data.body));
  } else {
    return getErrorActions({ error, message: messages.ERROR_FETCHING_EVENTS });
  }
  return null;
}

export default getEvents;
