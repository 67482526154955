import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";
import types from "rdx/modules/enterpriseProLaborForm/types";
import getEnterpriseProLaborForm from "./getEnterpriseProLaborForm";

// IMPORT_PT (for script, do not remove)

function* watchEnterpriseProLaborFormSagas() {
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_PRO_LABOR_MODAL_FORM_SUBMIT, getEnterpriseProLaborForm);

  // INSERTION_PT (for script, do not remove)
}

export default watchEnterpriseProLaborFormSagas;
