import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import _ from "lodash";
import { parse } from "query-string";
import PropTypes from "prop-types";
import FormElements from "components/FormElements";
import SessionClient from "util/SessionClient";
import styles from "./SidebarAutoComplete.module.less";

const { AutoCompleteInput } = FormElements;

const SidebarAutoComplete = (props) => {
  const {
    fieldKey,
    label,
    dataKey,
    requestUrl,
    formatData,
    successActions,
    errorMessage,
    debounceTimeout,
    minCharacters,
    suggestions,
    navigate,
    resetSuggestions,
    getUserName,
    usersDisplay,
    userCitiesDisplay,
    search,
    defaultUser,
  } = props;

  const [selected, setSelected] = useState(undefined);
  const [defaultUserName, setDefaultUserName] = useState(undefined);
  const session = new SessionClient();

  useEffect(() => {
    const searchKeys = Object.keys(parse(search));

    if (selected) {
      navigate({ [fieldKey]: selected[dataKey] });
    } else if (!(searchKeys && searchKeys.includes(fieldKey))) {
      setDefaultUserName(undefined);
      navigate({ [fieldKey]: null });
    } else if (defaultUserName) {
      setDefaultUserName(undefined);
      navigate({ [fieldKey]: null });
    }
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const defaultId = parse(search)[fieldKey];
    if (!userCitiesDisplay) {
      if (defaultId && !defaultUserName) {
        getUserName({ userId: defaultId });
      } else if (!defaultId) {
        setDefaultUserName("");
      }
    }
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const defaultId = parse(search)[fieldKey];
    if (!_.isEmpty(defaultUser) && defaultUser.id === parseInt(defaultId, 10)) {
      const name = defaultUser.id === session.user.props.id ? "You" : defaultUser.fullName;
      setDefaultUserName(name);
      setSelected(defaultUser);
    }
  }, [defaultUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const reset = () => {
    setSelected(undefined);
    resetSuggestions();
  };

  const requestUrlNoPaging = useMemo(() => {
    if (requestUrl.indexOf("paging_data") === -1) {
      return requestUrl.indexOf("?") > -1
        ? requestUrl.concat("&paging_data=false")
        : requestUrl.concat("?paging_data=false");
    }
    return requestUrl;
  }, [requestUrl]);

  return (
    <div className={styles.container}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.inputContainer}>
        <AutoCompleteInput
          classNames={{
            autoComplete: [styles.autoComplete, "sidebar-autocomplete-override"].join(" "),
            input: styles.input,
          }}
          requestUrl={requestUrlNoPaging}
          formatData={formatData}
          successActions={successActions}
          errorMessage={errorMessage}
          additionalReqParams={{ lookup: 1 }}
          additionalPayload={{ key: fieldKey }}
          debounceTimeout={debounceTimeout}
          minCharacters={minCharacters}
          suggestions={suggestions}
          setSelected={setSelected}
          defaultUserName={defaultUserName}
          handleReset={reset}
          defaultUserId={parse(search)[fieldKey]}
          usersDisplay={usersDisplay}
          userCitiesDisplay={userCitiesDisplay}
        />
      </div>
    </div>
  );
};

/*
  fieldKey [string] [required]: The query parameter we want to send to limit our search
    results; also serves as the key under which lookup results will be saved to redux store.
      EXAMPLE: I search "Joe" in my consultants autocomplete, find user Joe Friesen with id
        12345, select that user, and then make my search with provider leads having user 12345
        as consultant. The provider leads search url would look like
          "/json_web/provider_leads?consultant_id=12345"
        so I need to set the fieldKey prop to "consultant_id".

  dataKey [string] [required]: The results of an autocomplete lookup will likely be an array
    of objects. When user selects one of these objects, we want to get some key (most likely
    "id") out of this object for the second refined search. Set the dataKey prop to the
    key in this object that we want to extract.
      EXAMPLE: Our automcomplete users lookup returned an array of objects that look something
        like { id: 12345, fullName: "Joe Friesen", ... }. The id key is the key for the
        key-value we care about for building our second request
          "/json_web/provider_leads?consultant_id=12345",
        so we want to set the dataKey prop to "id" so we know which value to look for in
        the selected object.

  requestUrl [string] [required]: The endpoint to hit for the user lookup request. Will
    automatically send all reqs. with /json_web/ prefix, set this prop to url w/o json_web.
      EXAMPLE: We want to lookup all consultant users with "Joe" in their name. Our request
      would look like GET /json_web/users/project_consultants?q=Joe&lookup=1, so to make
      this request we want to set prop requestUrl to "users/project_consultants"
      - appended ".concat("?paging_data=false")" to requestUrl as there should never be an auto
      complete requiring paging, and it saves significant time on BE return by not including.

  label [string]: String to display above the autocomplete input.

  formatData(array) [func]: If we want to do anything with the resp. body from the lookup request
    before saving to redux store, give the function here that returns the data as you want
    it saved in store.

  successActions [array[func]]: An array of redux actions to dispatch after autocomplete
    lookup comes back with status 200. Put an action to dispatch to the reducer, dispatch
    a message event, etc. here.
      EXAMPLE: After getting an array of user objects on searching "Joe" for consultants,
        I want to set the returned list in redux store with the "setProjectsListAutoComplete"
        action. I would set successActions = [setProjectsListAutoComplete] which will
        dispatch a setProjectsListAutoComplete action with the returned list as part of
        the action's payload

  errorMessage [string]: If lookup request fails for any reason, set this as the message
    to display, see messages.js files in rdx modules

  debounceTimeout: Lookup requests are debounced, set the debounce timeout here

  minCharacters: Lookup requests won't fire until input string is at least minCharacters long

  suggestions [{
    displayValue: string,
    key: string | number,
    data: object
  }]: parse the list of users returned from backend into this format (data is the object
    returned from the backend) in a format such that the autocomplete input can display
    the list of suggestions in its dropdown

  navigate [func] [required]: the navigate function to update the query param after
    selecting a user

  resetSuggestions [func] [required]: A redux action to empty the store of autocomplete
    suggestions, to fire on select or on click of the X icon.
      EXAMPLE: After selecting user id 12345, we need to dispatch an action to clear
      out the autocomplete suggestions; we need to specify a key as well as what
      we want our suggestions to look like (null). The action here would look like
        () => setProjectsListAutoComplete({ key: "consultant_id", suggestions: null })

  usersDisplay [bool]: If the lookup is a list of users, set this to true to use the
    same username/avatar display in autocomplete dropdown. If not, set as false, which
    will simply render <div>{suggestion.displayValue}</div>
*/

SidebarAutoComplete.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  requestUrl: PropTypes.string.isRequired,
  formatData: PropTypes.func,
  successActions: PropTypes.arrayOf(PropTypes.func),
  errorMessage: PropTypes.string,
  debounceTimeout: PropTypes.number,
  minCharacters: PropTypes.number,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      displayValue: PropTypes.string,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    }),
  ),
  navigate: PropTypes.func.isRequired,
  resetSuggestions: PropTypes.func.isRequired,
  usersDisplay: PropTypes.bool,
  userCitiesDisplay: PropTypes.bool,
  search: PropTypes.string,
  getUserName: PropTypes.func,
  defaultUser: PropTypes.shape({
    fullName: PropTypes.string,
    id: PropTypes.number,
  }),
};

SidebarAutoComplete.defaultProps = {
  label: undefined,
  formatData: (data) => data,
  successActions: [],
  errorMessage: undefined,
  debounceTimeout: undefined,
  minCharacters: 2,
  usersDisplay: false,
  userCitiesDisplay: false,
};

export default connect(
  getRdxSelectionMapper({ defaultUser: "getUserName" }),
  getRdxActionMapper(["getUserName"]),
)(SidebarAutoComplete);
