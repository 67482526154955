import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getRdxSelectionMapper } from "@rdxprops";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form, Input, Select, Row, Col, Checkbox, Radio, Space } from "antd";
import { states } from "lib/misc/states";
import { MailOutlined } from "@ant-design/icons";
import PhoneIcon from "components/Icons/PhoneIcons/phoneIcon";
import EnterpriseApplication from "models/EnterpriseApplication/EnterpriseApplication";
import StreetAddressLookup from "./StreetAddressLookup";
import styles from "./EnterpriseProSignupModal.module.less";

const LaborPartnerForm = ({ form, applicationDetails }) => {
  const { Option } = Select;
  const { t, i18n } = useTranslation(["ent_join_form_labels"]);
  const [otherChecked, setOtherChecked] = useState(false);

  const createStateOptions = () => {
    const out = states.map((state) => (
      <Option key={state.short} value={state.short}>
        {state.long}
      </Option>
    ));
    return out;
  };

  useEffect(() => {
    form.setFieldsValue({
      firstName: applicationDetails.ownerFirstName,
      lastName: applicationDetails.ownerLastName,
      email: applicationDetails.ownerEmail,
      phone: applicationDetails.ownerPhone,
    });
  }, [applicationDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const onOtherChecked = (v) => {
    setOtherChecked(v.target.checked);
  };

  return (
    <Form
      form={form}
      className={styles.buildPartnerFormContainer}
      initialValues={{
        locale: i18n.language || "en",
        spokenLanguages: [i18n.language] || ["en"],
      }}
      requiredMark={false}
      colon={false}
      validateTrigger="onSubmit"
    >
      <div className={styles.subHeaderContainer}>
        <div className={styles.subHeader}>{t("contact_info")}</div>
        <div className={styles.underline} />
      </div>
      <span className={styles.inputLabel}>{t("first_name")}</span>
      <Form.Item
        name="firstName"
        validateTrigger={["onBlur", "onSubmit"]}
        rules={[
          {
            required: true,
            message: t("first_name_required"),
          },
        ]}
      >
        <Input size="large" className={styles.input} name="firstName" />
      </Form.Item>
      <span className={styles.inputLabel}>{t("last_name")}</span>
      <Form.Item
        name="lastName"
        validateTrigger={["onBlur", "onSubmit"]}
        rules={[
          {
            required: true,
            message: t("last_name_required"),
          },
        ]}
        message={null}
      >
        <Input size="large" className={styles.input} name="lastName" />
      </Form.Item>
      <span className={styles.inputLabel}>{t("email")}</span>
      <Form.Item
        name="email"
        validateTrigger={["onBlur", "onSubmit"]}
        rules={[
          {
            required: true,
            message: t("valid_email"),
          },
          {
            type: "email",
            message: t("valid_email"),
          },
        ]}
      >
        <Input
          size="large"
          className={styles.iconInput}
          name="email"
          addonBefore={
            <div className={styles.inputPrefix}>
              <MailOutlined
                style={{
                  color: "var(--botticelli-blue)",
                  fontSize: "17px",
                }}
              />
            </div>
          }
        />
      </Form.Item>
      <span className={styles.inputLabel}>{t("phone")}</span>
      <Form.Item
        name="phone"
        validateTrigger={["onBlur", "onSubmit"]}
        rules={[
          {
            pattern: /^[0-9.\-+]+$/,
            message: t("valid_phone"),
          },
          {
            required: true,
            message: t("valid_phone"),
          },
          {
            min: 7,
            message: t("valid_phone"),
          },
        ]}
      >
        <Input
          size="large"
          className={styles.iconInput}
          name="phone"
          addonBefore={
            <div className={styles.inputPrefix}>
              <PhoneIcon />
            </div>
          }
        />
      </Form.Item>

      <div className={styles.subHeaderContainer}>
        <div className={styles.subHeader}>{t("labor_partner_company")}</div>
        <div className={styles.underline} />
      </div>

      <span className={styles.inputLabel}>{t("company_name")}</span>
      <Form.Item
        name="businessName"
        validateTrigger={["onBlur", "onSubmit"]}
        rules={[
          {
            required: true,
            message: t("company_name_required"),
          },
        ]}
      >
        <Input size="large" className={styles.input} name="companyName" />
      </Form.Item>

      <span className={styles.inputLabel}>{t("company_address")}</span>
      <StreetAddressLookup form={form} />
      <Row>
        <Col className={styles.city} span={10}>
          <span className={styles.inputLabel}>{t("city")}</span>
          <Form.Item
            validateTrigger={["onBlur", "onSubmit"]}
            name="businessCity"
            rules={[
              {
                required: true,
                message: t("city_required"),
              },
            ]}
          >
            <Input size="large" className={styles.input} name="city" />
          </Form.Item>
        </Col>
        <Col className={styles.state} span={7}>
          <span className={styles.inputLabel}>{t("state")}</span>
          <Form.Item
            name="businessState"
            validateTrigger={["onBlur", "onSubmit"]}
            rules={[
              {
                required: true,
                message: t("state_required"),
              },
            ]}
          >
            <Select
              size="large"
              optionFilterProp="children"
              className={styles.select}
              dropdownStyle={{ minWidth: "150px" }}
            >
              {createStateOptions()}
            </Select>
          </Form.Item>
        </Col>
        <Col className={styles.zip} span={7}>
          <span className={styles.inputLabel}>{t("zipcode")}</span>
          <Form.Item
            name="businessZip"
            validateTrigger={["onBlur", "onSubmit"]}
            rules={[
              {
                required: true,
                message: t("zipcode_required"),
              },
              {
                pattern: /^(\d{5}(-\d{4})?|[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] ?\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/i,
                message: "Please enter a valid US or Canadian postal code.",
              },
            ]}
          >
            <Input className={styles.input} size="large" maxLength={10} />
          </Form.Item>
        </Col>
      </Row>

      <div className={styles.subHeaderContainer}>
        <div className={styles.subHeader}>{t("build_partner_app")}</div>
        <div className={styles.underline} />
      </div>

      <span className={styles.inputLabel}>{t("home_improvement")}</span>
      <Form.Item
        name="homeImprovementServices"
        className={styles.radioGroup}
        validateTrigger={["onBlur", "onSubmit"]}
        rules={[
          {
            required: true,
            message: t("field_required"),
          },
        ]}
      >
        <Radio.Group name="homeImprovementServices">
          <Space direction="vertical" size={2}>
            <Radio value>{t("yes")}</Radio>
            <Radio value={false}>{t("no")}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>

      <span className={styles.inputLabel}>{t("home_improvement_types")}</span>
      <Form.Item
        name="servicesLicensed"
        className={styles.checkboxGroup}
        validateTrigger={["onSubmit"]}
        rules={[
          {
            required: !otherChecked,
            message: t("improvement_services_required"),
          },
        ]}
      >
        <Checkbox.Group>
          <Row justify="space_between" gutter={[0, 20]}>
            <Col span={8}>
              <Checkbox value="Solar Electrical">{t("solar_electrical")}</Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Siding">{t("siding")}</Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Back Up Generator">{t("back_up_gen")}</Checkbox>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Checkbox value="Roofing">{t("roofing")}</Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Windows and Doors">{t("windows_doors")}</Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Electrical Upgrades">{t("elect_upgrades")}</Checkbox>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Checkbox value="HVAC">{t("hvac")}</Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Thermostat">{t("thermostat")}</Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Attic Ventilation">{t("attic_vent")}</Checkbox>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Checkbox value="Water Heating">{t("water_heat")}</Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Solar Thermal">{t("solar_thermal")}</Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Insulation and Radiant Barriers">{t("insulation_radiant_barriers")}</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <Row className={styles.checkboxBottomRow}>
        <Col span={8}>
          <Checkbox onChange={onOtherChecked}>{t("other")}</Checkbox>
        </Col>
      </Row>
      <Form.Item
        name="otherServices"
        validateTrigger={["onSubmit"]}
        rules={[
          {
            required: otherChecked,
            message: t("improvement_services_required"),
          },
        ]}
      >
        <Input className={styles.input} name="otherServices" />
      </Form.Item>

      <span className={styles.inputLabel}>{t("battery_storage")}</span>
      <Form.Item
        name="batteryExperience"
        className={styles.radioGroup}
        validateTrigger={["onSubmit"]}
        rules={[
          {
            required: true,
            message: t("field_required"),
          },
        ]}
      >
        <Radio.Group name="batteryExperience">
          <Space direction="vertical" size={2}>
            <Radio value>{t("yes")}</Radio>
            <Radio value={false}>{t("no")}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <span className={styles.inputLabel}>{t("home_upgrades")}</span>
      <Form.Item
        name="outsideWork"
        className={styles.radioGroup}
        validateTrigger={["onSubmit"]}
        rules={[
          {
            required: true,
            message: t("field_required"),
          },
        ]}
      >
        <Radio.Group name="outsideWork">
          <Space direction="vertical" size={2}>
            <Radio value>{t("yes")}</Radio>
            <Radio value={false}>{t("no")}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <span className={styles.inputLabel}>{t("references")}</span>
      <Form.Item
        name="referencesAvailable"
        className={styles.radioGroup}
        validateTrigger={["onSubmit"]}
        rules={[
          {
            required: true,
            message: t("field_required"),
          },
        ]}
      >
        <Radio.Group name="referencesAvailable">
          <Space direction="vertical" size={2}>
            <Radio value>{t("yes")}</Radio>
            <Radio value={false}>{t("no")}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <span className={styles.inputLabel}>{t("electricians")}</span>
      <Form.Item
        name="licensedElectricians"
        className={styles.radioGroup}
        validateTrigger={["onSubmit"]}
        rules={[
          {
            required: true,
            message: t("field_required"),
          },
        ]}
      >
        <Input size="large" className={styles.input} name="licensedElectricians" />
      </Form.Item>
      <span className={styles.inputLabel}>{t("support_staff")}</span>
      <Form.Item
        name="supportStaff"
        className={styles.radioGroup}
        validateTrigger={["onSubmit"]}
        rules={[
          {
            required: true,
            message: t("field_required"),
          },
        ]}
      >
        <Radio.Group name="supportStaff">
          <Space direction="vertical" size={2}>
            <Radio value>{t("yes")}</Radio>
            <Radio value={false}>{t("no")}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <span className={styles.inputLabel}>{t("warehouse")}</span>
      <Form.Item
        name="warehouseFacilities"
        className={styles.radioGroup}
        validateTrigger={["onSubmit"]}
        rules={[
          {
            required: true,
            message: t("field_required"),
          },
        ]}
      >
        <Radio.Group name="warehouseFacilities">
          <Space direction="vertical" size={2}>
            <Radio value>{t("yes")}</Radio>
            <Radio value={false}>{t("no")}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
};

LaborPartnerForm.propTypes = {
  // antd form instance
  form: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  applicationDetails: EnterpriseApplication.types(),
  // laborFormValues: PropTypes.object,
};

export default connect(
  getRdxSelectionMapper({
    applicationDetails: "selectEnterpriseApplicationDetail",
  }),
)(LaborPartnerForm);
