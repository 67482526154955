import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enterpriseProjects/messages";

function* cancelLeadOffer(action) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/offer_responses/${id}/cancel`, {});

  if (success && data) {
    yield all([
      yield put(
        actions.newSuccessEvent({
          message: messages.ENTERPRISE_CANCEL_OFFER_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_SUBMITTING_ENTERPRISE_RESCIND_OFFER,
      // payload: {},
    });
  }

  return null;
}

export default cancelLeadOffer;
