import type { PostSalesProject } from "rdx/modules/projects/types/projects/post-sales";
import { HomeownerInfoPropsT } from "components/Tabs/Project/Widgets/HomeownerHeader/types";
import { DisplayRoles } from "rdx/modules/projects/schemas/projects/base";

const formatProjectHeaderData = (projectData: PostSalesProject): HomeownerInfoPropsT => {
  const { lead } = projectData;

  const projectUsers =
    projectData?.projectUsers ||
    [
      projectData.agent ? { ...projectData.agent, role: DisplayRoles.SELLER } : null,
      projectData.mentor ? { ...projectData.mentor, role: DisplayRoles.CLOSER } : null,
      projectData.coordinator ? { ...projectData.coordinator, role: DisplayRoles.PROJECT_MANAGER } : null,
      projectData.ambassador ? { ...projectData.ambassador, role: DisplayRoles.AMBASSADOR } : null,
    ].filter(Boolean);

  return {
    firstName: lead.firstName,
    lastName: lead.lastName,
    email: lead.email,
    phone: lead.phone,
    leadId: lead.id,
    projectId: projectData.id,
    proposalUrl: projectData?.proposalUrl,
    visionProposal: projectData.visionProposal,
    address: {
      street: lead.address.street,
      city: lead.address.city,
      state: lead.address.state,
      zip: lead.address.zip,
    },
    transparencyInfo: {
      milestone: projectData.milestone,
      stage: projectData.stage,
      type: projectData.projectType,
      passedStages: projectData.projectProgress,
    },
    projectUsers,
    counts: {
      notes:
        (projectData.projectAtAGlance?.projectNotifications?.length || 0) +
        (projectData.powurOsData?.notes?.length || 0),
      tickets: projectData.ticketsCount,
    },
    proposalNonrequestableReason: projectData.proposalNonrequestableReason,
    roofingNonRequestableReason: projectData.roofingNonRequestableReason,
    crmActivities: projectData.crmActivities,
    upcomingStageNames: projectData.upcomingStageNames,
    projectProgress: projectData.projectProgress,
    closeAt: projectData.closeAt,
    closeReasonId: projectData.closeReasonId,
    isValidServiceArea: projectData.isValidServiceArea,
    setterId: projectData.agent.id,
    closerId: projectData.mentor?.id,
    hasProposals: projectData.hasProposals,
  };
};

export default formatProjectHeaderData;
