import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import _ from "lodash";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getAdminMentorRatings(action) {
  const { mentorId, menteeId, sort, p } = action.payload;
  const params = {
    mentor_id: mentorId,
    limit: 20,
  };
  if (menteeId) params.mentee_id = menteeId;
  if (sort) params.sort = sort;
  if (p) params.p = p;
  const { success, data, error } = yield* makeRequest.get(`/mentor_ratings/${mentorId}/admin_index`, {
    ...params,
  });
  if (success && data) {
    const { paging, mentorRatings } = camelizeKeys(_.clone(data.body));
    yield put(actions.setMentorRatings({ paging, list: mentorRatings }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_MENTOR_RATINGS_FOR_MENTOR,
    });
  }
  return null;
}

export default getAdminMentorRatings;
