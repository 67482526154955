import React from "react";
import PropTypes from "prop-types";

const RightGradArrow = (props) => {
  const { height, width, firstColor, secondColor } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.523438 3.5C0.247295 3.5 0.0234375 3.72386 0.0234375 4C0.0234375 4.27614 0.247295 4.5 0.523437 4.5L0.523438 3.5ZM9.90141 4.35355C10.0967 4.15829 10.0967 3.84171 9.90141 3.64645L6.71943 0.464467C6.52417 0.269205 6.20759 0.269205 6.01233 0.464467C5.81706 0.659729 5.81706 0.976311 6.01233 1.17157L8.84075 4L6.01233 6.82843C5.81706 7.02369 5.81706 7.34027 6.01233 7.53553C6.20759 7.7308 6.52417 7.7308 6.71943 7.53553L9.90141 4.35355ZM0.523437 4.5L9.54786 4.5L9.54786 3.5L0.523438 3.5L0.523437 4.5Z"
        fill="url(#paint0_linear_2291_9624)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2291_9624"
          x1="2.10667"
          y1="3.58333"
          x2="6.54615"
          y2="7.58522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={firstColor} />
          <stop offset="1" stopColor={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

RightGradArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  firstColor: PropTypes.string,
  secondColor: PropTypes.string,
};

RightGradArrow.defaultProps = {
  height: "11",
  width: "8",
  firstColor: "var(--picton-blue)",
  secondColor: "var(--primary-color)",
};

export default RightGradArrow;
