import { put } from "redux-saga/effects";
import Cookies from "universal-cookie";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* getStripeSignupUrl() {
  const { success, data, error } = yield* makeRequest.get("/stripe_connect/connect", {});

  if (success && data) {
    const cookies = new Cookies();
    cookies.set("stripe_jwt", data.body.jwt, {
      path: "/",
      domain: data.body.domain,
    });
    yield put(
      actions.newSuccessEvent({
        message: messages.GET_STRIPE_SIGNUP_URL_SUCCESS,
        payload: data.body,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_STRIPE_SIGNUP_URL,
    });
  }

  return null;
}

export default getStripeSignupUrl;
