import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getEnterpriseLeads(action) {
  const params = {
    sort: "newest",
    enterprise: true,
    ...action.payload,
  };
  const { success, data, error } = yield* makeRequest.get("/leads", params);
  if (success && data) {
    const leads = data.body.leads.map((lead) => keysToCamelCase(lead));
    yield put(actions.setEnterpriseLeads({ ...data.body, leads }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_ENTERPRISE_LEADS,
    });
  }
  return null;
}

export default getEnterpriseLeads;
