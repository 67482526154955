import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const CloseIconPlain = ({ fill = cvar("white"), width = 10, height = 10 }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="8.5"
        y="-0.388672"
        width="2.35714"
        height="12.5714"
        rx="1.17857"
        transform="rotate(45 8.5 -0.388672)"
        fill={fill}
      />
      <rect
        x="10.166"
        y="8.50098"
        width="2.35714"
        height="12.5714"
        rx="1.17857"
        transform="rotate(135 10.166 8.50098)"
        fill={fill}
      />
    </svg>
  );
};

export default CloseIconPlain;
