import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout, Row, Col } from "antd";
import { parse } from "query-string";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import SessionClient from "util/SessionClient";
import ApiClient from "util/ApiClient";
import LightbulbIcon from "components/Icons/LightbulbIcon";
import LoginHeader from "./components/Header";
import LoginForm from "./components/LoginForm";
import ForgotPassword from "./components/ForgotPassword";
import CreateNewPassword from "./components/NewPassword";
import EmailResetRedirect from "./components/EmailResetRedirect";
import styles from "./Login.module.less";

const { Content } = Layout;

const Login = ({ setAlertMessageVisible }) => {
  const { t } = useTranslation("login");
  const history = useHistory();
  const search = parse(history.location.search);

  const { tab } = useParams();

  const tabKeys = ["login", "reset-password", "new-password", "reset-email"];

  const panelBody = () => {
    switch (tab) {
      case tabKeys[1]:
        return <ForgotPassword />;
      case tabKeys[2]:
        return <CreateNewPassword />;
      case tabKeys[3]:
        return <EmailResetRedirect />;
      default:
        return <LoginForm tabKeys={tabKeys} />;
    }
  };

  useEffect(() => {
    const session = new SessionClient();
    const api = new ApiClient({ host: process.env.REACT_APP_POWUR_API_URL });
    if (session.validSession && search.SAMLRequest) {
      api.ssoLogin("zendesk", search.RelayState);
    } else if (session.validSession && session.universityOnly && !session.impersonating) {
      api.ssoLogin("zendesk");
    } else if (session.validSession && tab !== tabKeys[3]) {
      history.replace("/dashboard");
    } else if (search.sso || search.alr) {
      setAlertMessageVisible({ message: t("login_to_access_this_page"), severity: "info" });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tab && !tabKeys.includes(tab)) {
      history.replace("/login");
    }
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const chatWidget = document.getElementById("launcher");
    if (chatWidget) {
      chatWidget.style.display = "none";
    }
  }, []);

  return (
    <Layout>
      <LoginHeader />
      <Content className={styles.content}>
        <div className={styles.main}>
          <div className={styles.powur} />
          <div className={styles.loginContainer}>
            <div className={styles.tabs}>
              <div className={styles.tab}>{panelBody()}</div>
            </div>
          </div>
        </div>
        {!tab && (
          <div className={styles.proTip}>
            <div>
              <Row wrap={false}>
                <Col flex="48px">
                  <LightbulbIcon containerStyles={{ paddingLeft: "10px" }} />
                </Col>
                <Col flex="auto" className={styles.heading}>
                  {t("pro_tip")}
                </Col>
              </Row>
              <Row wrap={false}>
                <Col flex="48px" />
                <Col flex="auto" className={styles.body}>
                  {t("navigation_explain")}
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default connect(getRdxSelectionMapper({}), getRdxActionMapper(["setAlertMessageVisible"]))(Login);
