import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/auth/messages";

function* createNewPasswordRequest(action) {
  const { token, new_password, password_confirm } = action.payload;
  const { success, data, error } = yield* makeRequest.patch("/password", {
    token,
    new_password,
    password_confirm,
  });

  if (success && data) {
    yield put(
      actions.newSuccessEvent({
        message: messages.CREATE_NEW_PASSWORD_SUCCESS,
        payload: {
          event: messages.CREATE_NEW_PASSWORD_SUCCESS_EVENT,
        },
      }),
    );
  } else if (error) {
    yield put(actions.setNewPasswordErrors({ message: error.message }));
    return getErrorActions({
      error: {
        ...error,
        event: messages.CREATE_NEW_PASSWORD_ERROR_EVENT,
      },
    });
  }

  return null;
}

export default createNewPasswordRequest;
