import { ActivityCenter } from "@icons";
import tabs from "containers/Sales/ActivityCenter/tabs";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";
import breadCrumbs from "components/PlatformLayout/components/PlatformPageHeader/Breadcrumbs/ActivityCenterBreadCrumbs";

const content = l(() => import("containers/Sales/ActivityCenter"));
const { SALES_DASHBOARD_VIEW } = permissionTypes;

export default {
  key: "activity-center",
  title: breadCrumbs,
  path: "/activity-center/:tab?",
  linkTarget: "/activity-center/notifications",
  linkTitle: "Activity Center",
  linkCategory: "Sales",
  icon: ActivityCenter,
  basePath: "activity-center",
  requiredParams: ["tab"],
  optionalParams: [],
  tabs,
  content,
  sidebar: null,
  permission: [SALES_DASHBOARD_VIEW],
};
