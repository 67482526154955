import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* getUsersContestReport() {
  const { success, data, error } = yield* makeRequest.get("/reports/latest", {
    name: "Summer of Powur 2021 Championships",
  });
  if (success && data) {
    yield put(actions.setUsersContestReport(data.body));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_USERS_CONTEST_REPORT,
    });
  }
  return null;
}

export default getUsersContestReport;
