import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import _ from "lodash";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getUserName(action) {
  const { userId } = action.payload;

  const { success, data, error } = yield* makeRequest.get("/users/user_name", {
    user_id: userId,
  });

  if (success && data) {
    const user = keysToCamelCase(_.clone(data.body));
    yield put(actions.setUserName(user));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_USER_NAME,
    });
  }
  return null;
}

export default getUserName;
