import React from "react";
import PropTypes from "prop-types";

const TotalContractsIcon = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.2838 6.82723L26.8723 8.7716V2.90337H21.7245L21.7303 2.90922V4.91215L16.5004 1L1.41406 12.3031V12.3089H1.42578L4.52387 16.3675L6.13441 15.1728L6.14613 24.9941L26.8723 25L26.8665 24.9941H26.8723V15.1845L28.477 16.3792C29.5136 15.0205 30.5502 13.6676 31.5868 12.3089L26.8723 8.7716"
        stroke={fill}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};

TotalContractsIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

TotalContractsIcon.defaultProps = {
  fill: "var(--eggplant)",
  width: "33",
  height: "26",
};

export default TotalContractsIcon;
