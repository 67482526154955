import React from "react";
import { cvar } from "styles";
import { IconT } from "types/utils";

const QualifiedLeadsGradient = ({
  height = "29",
  width = "29",
  stopColor = cvar("blue-ribbon"),
  startColor = cvar("picton-blue"),
}: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.95332 17.1719H7.13913C6.15768 17.1719 5.35468 17.9749 5.35468 18.9563V26.451C5.35468 27.4424 6.15768 28.2355 7.13913 28.2355H8.95332V17.1719Z"
        fill="url(#paint0_linear_2055_14021)"
      />
      <path
        d="M22.3053 20.4233L20.6596 26.3516C20.3523 27.462 19.351 28.2352 18.1911 28.2352H10.4387V17.1716L13.7102 12.542C14.0571 12.0562 14.6222 11.7588 15.2269 11.7588C16.2778 11.7588 17.0808 12.6213 17.0808 13.6126C17.0808 13.7812 17.061 13.9497 17.0114 14.1182L16.1489 17.1716H19.8368C21.532 17.1716 22.7613 18.7875 22.3053 20.4233Z"
        fill="url(#paint1_linear_2055_14021)"
      />
      <path
        d="M4.04679 5.76011L4.7913 7.26797C4.86169 7.40974 4.99651 7.50887 5.15414 7.53168L6.81864 7.77357C7.2132 7.83107 7.37082 8.31584 7.08531 8.59442L5.88081 9.76819C5.76779 9.87922 5.71525 10.0378 5.74202 10.1945L6.02654 11.852C6.09395 12.2456 5.68154 12.545 5.32763 12.3596L3.8386 11.5774C3.69882 11.5041 3.53128 11.5041 3.3905 11.5774L1.90148 12.3596C1.54855 12.545 1.13516 12.2456 1.20257 11.852L1.48709 10.1945C1.51386 10.0378 1.4623 9.87922 1.3483 9.76819L0.145776 8.59442C-0.139736 8.31584 0.0178901 7.83107 0.412452 7.77357L2.07695 7.53168C2.23358 7.50887 2.3694 7.40974 2.43979 7.26797L3.1843 5.76011C3.36076 5.40223 3.87032 5.40223 4.04679 5.76011Z"
        fill="url(#paint2_linear_2055_14021)"
      />
      <path
        d="M14.3094 1.79478L15.0539 3.30264C15.1243 3.44441 15.2591 3.54354 15.4167 3.56634L17.0812 3.80824C17.4758 3.86573 17.6334 4.35051 17.3479 4.62908L16.1434 5.80286C16.0304 5.91389 15.9778 6.07251 16.0046 6.22914L16.2891 7.8867C16.3565 8.28027 15.9441 8.57966 15.5902 8.39428L14.1012 7.61209C13.9614 7.53873 13.7939 7.53873 13.6531 7.61209L12.1641 8.39428C11.8111 8.57966 11.3977 8.28027 11.4651 7.8867L11.7497 6.22914C11.7764 6.07251 11.7249 5.91389 11.6109 5.80286L10.4083 4.62908C10.1228 4.35051 10.2805 3.86573 10.675 3.80824L12.3395 3.56634C12.4962 3.54354 12.632 3.4454 12.7024 3.30264L13.4469 1.79478C13.6233 1.4369 14.1329 1.4369 14.3094 1.79478Z"
        fill="url(#paint3_linear_2055_14021)"
      />
      <path
        d="M24.5739 5.76011L25.3184 7.26797C25.3888 7.40974 25.5236 7.50887 25.6812 7.53168L27.3457 7.77357C27.7403 7.83107 27.8979 8.31584 27.6124 8.59442L26.4079 9.76819C26.2949 9.87922 26.2423 10.0378 26.2691 10.1945L26.5536 11.852C26.621 12.2456 26.2086 12.545 25.8547 12.3596L24.3657 11.5774C24.2259 11.5041 24.0583 11.5041 23.9176 11.5774L22.4285 12.3596C22.0756 12.545 21.6622 12.2456 21.7296 11.852L22.0142 10.1945C22.0409 10.0378 21.9894 9.87922 21.8754 9.76819L20.6709 8.59442C20.3853 8.31584 20.543 7.83107 20.9375 7.77357L22.602 7.53168C22.7587 7.50887 22.8945 7.41073 22.9649 7.26797L23.7094 5.76011C23.8878 5.40223 24.3974 5.40223 24.5739 5.76011Z"
        fill="url(#paint4_linear_2055_14021)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2055_14021"
          x1="5.98602"
          y1="12.562"
          x2="9.19147"
          y2="12.6662"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2055_14021"
          x1="12.5373"
          y1="4.89361"
          x2="23.1482"
          y2="5.66312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2055_14021"
          x1="1.26861"
          y1="2.60662"
          x2="7.64703"
          y2="3.27199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2055_14021"
          x1="11.5312"
          y1="-1.35871"
          x2="17.9096"
          y2="-0.693345"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2055_14021"
          x1="21.794"
          y1="2.60662"
          x2="28.1742"
          y2="3.27235"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default QualifiedLeadsGradient;
