import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* updateLicense(action) {
  const { id, identifier, expires } = action.payload;
  const { success, data, error } = yield* makeRequest.patch(`/profile/licenses/${id}`, {
    license: { id, identifier, expires },
  });
  if (success && data) {
    yield all([
      put(actions.setProfile(keysToCamelCase(data.body))),
      put(actions.newSuccessEvent({ message: messages.UPDATE_LICENSE_SUCCESS })),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_LICENSE,
    });
  }
  return null;
}

export default updateLicense;
