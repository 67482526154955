export const loggedOutConfig = {
  webWidget: {
    contactForm: {
      suppress: true,
    },
    helpCenter: {
      suppress: true,
    },
    talk: {
      suppress: true,
    },
    answerBot: {
      suppress: true,
    },
    chat: {
      menuOptions: {
        emailTranscript: false,
      },
      departments: {
        enabled: ["Customer Care", "Customer Care - Espanol", "Design Team"],
      },
      profileCard: {
        avatar: true,
        rating: false,
        title: true,
      },
    },
  },
};
export const loggedInUserConfig = {
  webWidget: {
    contactForm: {
      suppress: true,
    },
    helpCenter: {
      suppress: true,
    },
    talk: {
      suppress: true,
    },
    answerBot: {
      contactOnlyAfterQuery: true,
    },
    chat: {
      departments: {
        enabled: ["Sales Support", "Sales Support - Espanol", "Enterprise Support"],
      },
      menuOptions: {
        emailTranscript: false,
      },
      profileCard: {
        avatar: true,
        rating: false,
        title: true,
      },
    },
  },
};
