import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  progressOverTime: { timeSpan: "", progress: [] },
  leadOrigin: { timeSpan: "", counts: {} },
  earningsToDate: {},
  earningsBreakdown: {},
  projectActivity: [],
  paging: {
    pageSize: 20,
    itemCount: 0,
    pageNumber: 1,
  },
  filters: [],
  countPerStages: {},
  projectCounterWithNotifications: {},
  teamActivity: {},
  mentorRank: { timeSpan: "", place: null, leaderboard: [], showPodium: false },
  salesRank: {
    timeSpan: "",
    teamOnly: null,
    place: null,
    leaderboard: [],
    showPodium: false,
  },
  partneredProjectSuggestions: [],
  completedPartneredProjectsCount: 0,
  completedProposalProjects: [],
  referralsRank: {
    timeSpan: "",
    place: null,
    leaderboard: [],
    showPodium: false,
  },
  dashboardInit: false,
};

export default {
  consultantDashboard: createReducer(initialState, {
    [types.SET_CONSULTANT_DASHBOARD](state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    [types.SET_MORE_DASHBOARD_PROJECT_ACTIVITY](state, action) {
      return {
        ...state,
        projectActivity: [...state.projectActivity, ...action.payload.projectActivity],
      };
    },
    [types.CLEAR_CONSULTANT_DASHBOARD]() {
      return initialState;
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
