// import content from "containers/Sales/reports";
import tabs from "containers/Sales/reports/tabs";
import { ReportsIcon } from "@icons";
import l from "@loadable/component";
import { permissionTypes } from "hooks/usePermissions";

const content = l(() => import("containers/Sales/reports"));

const { REPORTS_VIEW, PREVIEW_USER } = permissionTypes;

export default {
  key: "reports",
  title: "Reports",
  path: "/reports/:tab?",
  linkTarget: "/reports",
  linkTitle: "Reports",
  linkCategory: "Sales",
  icon: ReportsIcon,
  basePath: "reports",
  requiredParams: ["tab"],
  optionalParams: [],
  tabs,
  content,
  sidebar: null,
  permission: [REPORTS_VIEW, PREVIEW_USER],
};
