import { OptionT } from "./types";

export const UrlStringToObject = (url: string) => {
  if (url.includes("Error(Saga)")) {
    console.warn("SAGA ERROR IN URL STRING");
    return {};
  }

  return url
    .substring(1)
    .split("&")
    .map(splitValuesObj)
    .reduce((newObj, item) => Object.assign(newObj, item), {});
};

const splitValuesObj = (keyVal: string) => {
  const queryKey = keyVal.split("=")[0];
  const queryValues = keyVal.split("=")[1]?.split("%2C");

  return queryValues ? { [queryKey]: queryValues } : {};
};

export const findFilterDateRangeItem = (from: string, to: string, options: OptionT[]) => {
  let match = options.find((option) => {
    const values = option.value as Array<Record<string, unknown>>;
    if (values?.length > 0) {
      return values[0].value === from && values[1].value === to;
    }

    return false;
  });

  if (!match && from && to)
    match = {
      id: "custom",
      label: "Custom",
      value: [
        { id: "from", value: from },
        { id: "to", value: to },
      ],
    };

  return match;
};

export const handleHeight = (filtersPresent = false, setsPresent = false) => {
  let h = 290;
  if (filtersPresent) h += 95;
  if (setsPresent) h += 100;

  return `${h}px`;
};
