import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const EnterpriseReportsIcon = (props) => {
  const { fill, height, width, active, hovered } = props;

  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      style={{
        paddingTop: "1px",
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.enterpriseIcon}
      >
        <rect
          x="0.834375"
          y="0.678125"
          width="19.3"
          height="19.3"
          rx="9.65"
          fill="var(--royal-peacock-blue)"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="0.7"
        />
        <path
          d="M16.6986 14.0938H12.2593V14.3157C12.2593 14.4923 12.1891 14.6617 12.0643 14.7866C11.9394 14.9115 11.77 14.9816 11.5934 14.9816H9.37375C9.19714 14.9816 9.02777 14.9115 8.90289 14.7866C8.77801 14.6617 8.70785 14.4923 8.70785 14.3157V14.0938H4.26855V14.7596C4.26855 14.9363 4.33871 15.1056 4.46359 15.2305C4.58847 15.3554 4.75784 15.4255 4.93445 15.4255H16.0327C16.2093 15.4255 16.3787 15.3554 16.5035 15.2305C16.6284 15.1056 16.6986 14.9363 16.6986 14.7596V14.0938Z"
          fill={fill}
        />
        <path
          d="M10.4884 9.06066L8.73267 10.5967L7.681 9.96583L6.86572 11.433H13.5915V8.77344L11.7982 10.1387L10.4884 9.06066Z"
          fill={fill}
        />
        <path
          d="M5.59985 12.3088H15.3663V6.09375H5.59985V12.3088ZM14.0345 11.8648H6.11037L7.51474 9.33666L8.68272 10.0376L10.4784 8.46611L11.808 9.5604L14.0345 7.86547V11.8648ZM7.26725 8.41151L8.60947 9.21702L10.4655 7.5929L11.8242 8.71116L14.096 6.98161H13.3686V6.53768H14.9626L14.6962 8.12806L14.2585 8.05481L14.3806 7.32233L11.8133 9.27739L10.4742 8.17511L8.65831 9.76416L7.43239 9.02835L6.41025 10.8678L6.02159 10.6522L7.26725 8.41151Z"
          fill={fill}
        />
        <path
          d="M9.37333 14.5377H11.593C11.6518 14.5377 11.7083 14.5143 11.7499 14.4727C11.7916 14.431 11.8149 14.3746 11.8149 14.3157V14.0938H9.15137V14.3157C9.15137 14.3746 9.17475 14.431 9.21638 14.4727C9.25801 14.5143 9.31446 14.5377 9.37333 14.5377Z"
          fill={fill}
        />
        <path
          d="M4.71167 13.6456H16.2538V5.87683C16.2538 5.70023 16.1837 5.53085 16.0588 5.40597C15.9339 5.28109 15.7646 5.21094 15.5879 5.21094H5.37756C5.20096 5.21094 5.03159 5.28109 4.90671 5.40597C4.78183 5.53085 4.71167 5.70023 4.71167 5.87683V13.6456ZM5.1556 5.65487H15.8099V12.7577H5.1556V5.65487Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

EnterpriseReportsIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

EnterpriseReportsIcon.defaultProps = {
  fill: "white",
  height: "19",
  width: "26",
};

export default EnterpriseReportsIcon;
