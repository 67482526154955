import PropTypes from "prop-types";
import { store } from "rdx/configureStore";
import actions from "rdx/actions";
import actionTypes from "rdx/modules/enterpriseApplications/types";
// import selectors from "rdx/modules/enterpriseApplications/selectors";
import { Base } from "../Base";

const {
  getEnterpriseApplications,
  getEnterpriseApplicationDetail,
  approveEnterpriseApplication,
  rejectEnterpriseApplication,
  makePendingEnterpriseApplication,
  createEnterpriseApplication,
  updateEnterpriseApplication,
  createTierOneUserRegistration,
  getEnterpriseApplicationByUser,
} = actions;

class EnterpriseApplication extends Base {
  static getAll = (filters) => {
    store.dispatch(getEnterpriseApplications(filters));
  };

  static getDetails = (id) => {
    store.dispatch(getEnterpriseApplicationDetail({ id }));
  };

  static getByUser = () => {
    store.dispatch(getEnterpriseApplicationByUser());
  };

  static create = (values) => {
    const params = {
      vanityName: values.vanityName,
      ownerFirstName: values.ownerFirstName,
      ownerLastName: values.ownerLastName,
      ownerPhone: values.ownerPhone,
      ownerEmail: values.ownerEmail,
      locale: values.locale,
      businessName: values.businessName,
      businessStreet: values.businessStreet,
      businessCity: values.businessCity,
      businessState: values.businessState,
      businessZip: values.businessZip,
      yearsInBusiness: values.yearsInBusiness,
      statesOperatedIn: values.statesOperatedIn,
      numberOfReps: values.numberOfReps,
      monthlySales: values.monthlySales,
      monthlyRevenue: values.monthlyRevenue,
      primaryLeadSource: values.primaryLeadSource,
      sellerValue: values.sellerValue,
      backgroundChecks: values.backgroundChecks,
      spokenLanguages: values.spokenLanguages,
    };
    store.dispatch(createEnterpriseApplication({ params }));
  };

  update = (values) => {
    store.dispatch(updateEnterpriseApplication({ id: this.props.id, values }));
  };

  approve = (appId, values) => {
    store.dispatch(approveEnterpriseApplication({ id: appId, values }));
  };

  reject = (appId) => {
    store.dispatch(rejectEnterpriseApplication({ id: appId }));
  };

  makePending = (appId) => {
    store.dispatch(makePendingEnterpriseApplication({ id: appId }));
  };

  static tierOneRegistration = (values) => {
    const params = {
      sponsor: values.sponsor,
      first_name: values.ownerFirstName,
      last_name: values.ownerLastName,
      email: values.ownerEmail,
      phone: values.ownerPhone,
      locale: values.locale,
      spoken_languages: values.spokenLanguages,
      kind: "enterprise",
    };
    store.dispatch(createTierOneUserRegistration({ params }));
  };

  static selectors = {
    list: "selectEnterpriseApplications",
    detail: "selectEnterpriseApplicationDetail",
  };

  static actionTypes = {
    getList: actionTypes.GET_ENTERPRISE_APPLICATIONS,
    getDetail: actionTypes.GET_ENTERPRISE_APPLICATION_DETAIL,
    approve: actionTypes.APPROVE_ENTERPRISE_APPLICATION,
    reject: actionTypes.REJECT_ENTERPRISE_APPLICATION,
    create: actionTypes.CREATE_ENTERPRISE_APPLICATION,
    tierOneRegistration: actionTypes.CREATE_ENTERPRISE_TIER_ONE_USER_REGISTRATION,
  };

  static types() {
    return PropTypes.shape({
      id: PropTypes.number,
      status: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      owner: PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        locale: PropTypes.string,
      }),
      org: PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.shape({
          street: PropTypes.string,
          city: PropTypes.string,
          state: PropTypes.string,
          zip: PropTypes.string,
        }),
        age: PropTypes.number,
        states: PropTypes.arrayOf(PropTypes.string),
        numReps: PropTypes.number,
        leadSource: PropTypes.oneOf([
          "Ambassador",
          "Friend Referral",
          "Online Marketing",
          "Word of Mouth",
          "Door Knocking",
          "Facebook",
          "YouTube",
          "Other",
        ]),
        monthlySales: PropTypes.number,
        monthlyRevenue: PropTypes.number,
        businessModel: PropTypes.string,
      }),
      documents: PropTypes.arrayOf(PropTypes.shape({ filename: PropTypes.string, url: PropTypes.string })),
      officers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          email: PropTypes.string,
          phone: PropTypes.string,
        }),
      ),
      adminNotes: PropTypes.string,
      external: PropTypes.bool,
      referrer: PropTypes.shape({
        fullName: PropTypes.string,
        id: PropTypes.number,
      }),
      response: PropTypes.shape({
        id: PropTypes.number,
        external: PropTypes.bool,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        decision: PropTypes.string,
      }),
    });
  }
}

export default EnterpriseApplication;
