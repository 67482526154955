import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/users/messages";
import selectors from "rdx/modules/users/selectors";

function* deletePermissionsEntity(action) {
  const { success, data, error } = yield* makeRequest.delete(`/entities/${action.payload.id}`);

  if (success && data) {
    const entities = yield select(selectors.getOperations);
    const idx = entities.indexOf(entities.find((p) => p.id === action.payload.id));
    if (idx > -1) {
      entities.splice(idx, 1);
    }

    yield all([
      put(actions.setRolesList({ entities })),
      put(
        actions.newSuccessEvent({
          message: messages.DELETE_PERMISSION_ENTITY_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_PERMISSION_ENTITY,
    });
  }

  return null;
}

export default deletePermissionsEntity;
