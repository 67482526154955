import styled from "@emotion/styled";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import { cvar } from "styles";

export const LabelGroupWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
}));

export const StyledHelperText = styled(FormHelperText)<{ error?: boolean }>(() => ({
  lineHeight: "10px",
  marginTop: "-10px",
  color: `${({ error }) => (error ? "red" : "black")}`,
}));

export const StyledFormLabel = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "9px",
  marginBottom: "8px",
  color: cvar("nepal-blue"),
  textTransform: "uppercase",
}));

export const CheckboxWrapper = styled(FormControlLabel)(() => ({
  margin: "0px 16px 8px -7px",
  height: "23px",
  "& .MuiCheckbox-root": {
    padding: "7px",
  },
}));

export const CheckboxLabel = styled("span")(() => ({
  fontFamily: "Barlow",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "9px",
  color: cvar("blue-bayoux"),
  margin: 0,
}));
