export default {
  // success
  CREATE_FUNDING_PROPOSAL_SUCCESS: "Funding Proposal created successfully.",
  GET_FUNDING_PROPOSALS_SUCCESS: "Funding Proposals retrieved successfully.",
  SUCCESS_UPDATING_FUNDING_PROPOSAL: "Funding Proposal updated successfully.",
  SUCCESS_COMPLETING_FUNDING_PROPOSAL: "Funding Proposal completed successfully.",
  SUCCESS_CANCELING_FUNDING_PROPOSAL: "Funding Proposal canceled successfully.",
  SUCCESS_ESCALATING_FUNDING_PROPOSAL: "Funding Proposal escalated successfully.",

  // errors
  ERROR_CREATING_FUNDING_PROPOSAL: "There was an error creating the Funding proposal.",
  ERROR_GETTING_FUNDING_PROPOSALS: "There was an error retrieving the Funding proposals.",
  ERROR_UPDATING_FUNDING_PROPOSAL: "There was an error updating the Funding proposal.",
  GET_FUNDING_USERS_AUTOCOMPLETE_ERROR: "There was an error retrieving Funding Proposal users.",
};
