import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/users/messages";

function* addNewPermission(action) {
  const { name, description } = action.payload;
  const { success, data, error } = yield* makeRequest.post("/permissions", {
    name,
    description,
  });

  if (success && data) {
    yield all([
      put(
        actions.setRolesList({
          permissions: data.body.permissions,
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.ADD_NEW_PERMISSION_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_ADDING_NEW_PERMISSION,
    });
  }

  return null;
}

export default addNewPermission;
