import component from "containers/StaticPages/installerjoin";

export default {
  key: "installer-join",
  title: "Installer Join",
  path: "/installer",
  pageName: "installer",
  linkTarget: "/installer",
  component,
};
