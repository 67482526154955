import React from "react";

interface TicketsButtonIconPropsI {
  height?: string | number;
  width?: string | number;
  fill?: string;
}

const TicketsButtonIcon = ({ height = "16", width = "17", fill = "white" }: TicketsButtonIconPropsI) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 15 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.977051 2.76855C0.977051 1.66398 1.87248 0.768555 2.97705 0.768555H13C14.1046 0.768555 15 1.66399 15 2.76855V8.84561C15 9.95018 14.1046 10.8456 13 10.8456H12.4659L11.8619 12.7566C11.7659 13.0603 11.3707 13.1355 11.1699 12.8882L9.5115 10.8456H2.97705C1.87248 10.8456 0.977051 9.95017 0.977051 8.8456V2.76855ZM3.55265 2.854C3.23476 2.854 2.97705 3.11171 2.97705 3.42961C2.97705 3.74751 3.23476 4.00521 3.55266 4.00521H12.4616C12.7795 4.00521 13.0372 3.74751 13.0372 3.42961C13.0372 3.11171 12.7795 2.854 12.4616 2.854H3.55265ZM2.97705 5.80705C2.97705 5.48915 3.23476 5.23145 3.55265 5.23145H12.4616C12.7795 5.23145 13.0372 5.48915 13.0372 5.80705C13.0372 6.12495 12.7795 6.38265 12.4616 6.38265H3.55266C3.23476 6.38265 2.97705 6.12495 2.97705 5.80705ZM3.55265 7.60889C3.23476 7.60889 2.97705 7.86659 2.97705 8.18449C2.97705 8.50239 3.23476 8.7601 3.55266 8.7601H12.4616C12.7795 8.7601 13.0372 8.50239 13.0372 8.18449C13.0372 7.86659 12.7795 7.60889 12.4616 7.60889H3.55265Z"
        fill={fill}
      />
    </svg>
  );
};
export default TicketsButtonIcon;
