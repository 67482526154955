import { styled, CSSObject } from "@mui/material/styles";
import { cvar } from "styles";
import OutlinedInput from "@mui/material/OutlinedInput";

const baseTimeStyles: CSSObject = {
  fontSize: "40px",
  fontWeight: 500,
  fontFamily: "Poppins",
  color: cvar("prussian-blue"),
};

export const StyledToolBarDate = styled("span")(() => ({
  ...baseTimeStyles,
}));

export const StyledInputWrapper = styled("span")(() => ({
  display: "inline-block",
  float: "left",
  width: "96px",
}));

export const StyledInputLabel = styled("span")(() => ({
  textTransform: "uppercase",
  fontWeight: "700",
  color: cvar("nepal-blue"),
  fontSize: "11px",
  letterSpacing: "0.4px",
}));

export const StyledInputTime = styled("input")(() => ({
  ...baseTimeStyles,
  height: "60px",
  width: "96px",
  background: cvar("cotton-boll"),
  fontWeight: "500",
  lineHeight: "52px",
  textAlign: "center",
  borderRadius: "8px",
  border: `1px solid ${cvar("botticelli-blue")}`,
  "::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
}));
export const StyledInputTimeColon = styled("span")(() => ({
  ...baseTimeStyles,
  height: "60px",
  width: "24px",
  fontWeight: "500",
  lineHeight: "52px",
  textAlign: "center",
  float: "left",
}));

export const sxStyle = {
  borderRadius: "6px",

  "& .MuiPickersArrowSwitcher-button, .MuiPickersCalendarHeader-switchViewButton": {
    svg: {
      path: {
        fill: cvar("prussian-blue"),
        height: 10,
      },
    },
  },
  "& .MuiPickersDay-root": {
    color: cvar("queen-blue"),
    fontWeight: "600",
    lineHeight: "16px",
    fontSize: "14px",
  },
  "& .css-3eghsz-PrivatePickersYear-button": {
    color: cvar("queen-blue"),
    fontWeight: "500",
    lineHeight: "16px",
    fontSize: "14px",
  },

  "& .css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected": {
    color: "white",
  },
  "& .MuiToggleButton-root": {
    fontFamily: "Poppins",
  },
  "& .MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
    background: `linear-gradient(270.54deg, ${cvar("picton-blue")} 49.88%, ${cvar("blue-ribbon")} 135.73%)`,

    color: "white",
  },

  "& .MuiDayPicker-weekDayLabel": {
    color: cvar("darkest-blue"),
    fontWeight: "600",
  },
  "& .Mui-selected": {
    background: `linear-gradient(270.54deg, ${cvar("picton-blue")} 49.88%, ${cvar("blue-ribbon")} 135.73%)`,
    color: cvar("white"),
  },
  "& .MuiPickersDay-today": {
    background: cvar("cotton-boll"),
    border: "none !important",
  },
  "& .MuiPickersCalendarHeader-label": {
    color: cvar("darkest-blue"),
    fontWeight: "600",
    lineHeight: "20px",
    align: "center",
    fontSize: "14px",
  },

  "& .MuiGrid2-root": {
    display: "flex",
    paddingBottom: "10px",
  },
  "& .MuiCalendarOrClockPicker-root": {
    alignItems: "center",
    maxHeight: "444px",
    width: "352px",
    paddingBottom: "0px",
    marginBottom: "-10px",
  },
  "& .MuiCalendarPicker-viewTransitionContainer": {
    height: "284px",
    paddingBottom: "0px",
  },
  "& 	.MuiCalendarPicker-root": {
    paddingBottom: "0px",
    marginBottom: "0px",
  },
};

export const StyledInput = styled(OutlinedInput)(() => ({
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { borderColor: cvar("botticelli-blue") },
    "&.Mui-focused fieldset": {
      borderColor: cvar("botticelli-blue"),
    },
  },
  "& > input": {
    "&::placeholder": {
      opacity: 1,
      fontWeight: 600,
      color: cvar("dark-blue"),
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `${cvar("botticelli-blue")} !important`,
  },
  "& .Mui-disabled": {
    background: cvar("cotton-boll"),
    borderRadius: "3px",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: cvar("queen-blue"),
  },
}));

export const LabelGroupWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const StyledFormLabel = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "9px",
  marginBottom: "8px",
  color: cvar("nepal-blue"),
  textTransform: "uppercase",
}));
