import { put, all } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getSmsDetails(action) {
  const { smsId } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/sms/${smsId}`, {});
  if (success && data) {
    const out = keysToCamelCase(_.clone(data.body));
    yield all([put(actions.setSmsDetails(out))]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_SMS_DETAILS,
    });
  }
  return null;
}

export default getSmsDetails;
