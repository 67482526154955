import { countryCodes } from "lib/misc/dropdownOptions";

const formatPhoneForPrefixInput = (phone: string) => {
  if (phone) {
    for (let i = 0; i < countryCodes.length; i++) {
      if (phone.includes(countryCodes[i].value)) {
        return {
          phonePrefix: countryCodes[i].value,
          phoneNumber: phone.replace(`${countryCodes[i].value}`, "").trim(),
        };
      }
    }
  }

  return { phonePrefix: countryCodes[0].value, phoneNumber: phone || "" };
};

export default formatPhoneForPrefixInput;
