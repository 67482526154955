import React from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import useModalControls from "hooks/useModalControls";
import MentorRating from "models/MentorRating";
import SessionClient from "util/SessionClient";
import QualifyingModal from "./qualifyingModal";
import RatingModal from "./ratingModal";

const MentorRatingModals = (props) => {
  const { rating } = props;

  const { visible: qualifyingVisible, close: closeQualifying } = useModalControls(true);

  const { visible: ratingVisible, open: openRating, close: closeRating } = useModalControls();

  const session = new SessionClient();

  const transitionModals = () => {
    closeQualifying();
    openRating();
  };

  if (!rating || !session.activeEnrollment) {
    return null;
  }

  return (
    <>
      <QualifyingModal
        ratingInfo={rating}
        visible={qualifyingVisible}
        close={closeQualifying}
        transition={transitionModals}
      />
      <RatingModal ratingInfo={rating} visible={ratingVisible} close={closeRating} />
    </>
  );
};

MentorRatingModals.propTypes = {
  rating: MentorRating.types(),
};

export default connect(
  getRdxSelectionMapper({ rating: "getMentorRating" }),
  getRdxActionMapper([]),
)(MentorRatingModals);
