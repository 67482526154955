import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/publicApiProxy/messages";
import { keysToSnakeCase } from "lib/helpers";

function* setUserEmailIntegrationType(action) {
  const params = keysToSnakeCase(action.payload);

  const { success, data, error } = yield* makeRequest.post("/gmail/email_integration_type", params);

  if (success && data) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.SET_USER_EMAIL_INTEGRATION_TYPE_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_SETTING_USER_EMAIL_INTEGRATION_TYPE,
    });
  }

  return null;
}

export default setUserEmailIntegrationType;
