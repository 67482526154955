// import content from "containers/Admin/admincommunications";
import tabs from "containers/Admin/admincommunications/tabs";
import { CommunicationsIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";

const content = l(() => import("containers/Admin/admincommunications"));

const { PLATFORM_COMMUNICATIONS } = permissionTypes;

export default {
  key: "admin-communications",
  title: "Communications",
  path: "/admin-communications/:tab?/:noticeId?",
  linkTarget: "/admin-communications",
  linkTitle: "Communications",
  basePath: "admin-communications",
  linkCategory: "Admin",
  permission: PLATFORM_COMMUNICATIONS,
  icon: CommunicationsIcon,
  tabs,
  content,
  sidebar: null,
};
