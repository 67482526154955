/*
 *  INPUT:
 *  zips (string): A string of comma- or newline-separated values that we wish to check
 *  OUTPUT:
 *  If split string contains all valid zipcodes, return true.
 *  Else, return an array of substrings that are not valid zipcodes
 */

export default function checkZipCodes(zips) {
  try {
    const regex = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);

    const list = zips
      .split(/[\n,]/)
      .map((z) => z.trim())
      .filter((z) => z !== "")
      .filter((z) => !regex.test(z));

    if (Array.isArray(list) && list.length === 0) {
      return true;
    }

    return list;
  } catch (err) {
    console.warn(err);
    return true;
  }
}
