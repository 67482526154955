import React, { ComponentType } from "react";
import { useController } from "react-hook-form";
import { useSmartFormContext } from "components/SmartForm";
import { HTMLFormControlType, HTMLInputType } from "@mui-styled-components/SmartFormElements/types";

/* aditional props to the new component */
type SmartFormElementT<I extends HTMLInputType | HTMLFormControlType> = {
  name: string;
  smartElement?: boolean;
  transform?: I extends
    | HTMLInputType.Text
    | HTMLInputType.Number
    | HTMLFormControlType.Select
    | HTMLFormControlType.TextArea
    ? {
        input: (value: unknown) => unknown;
        output: (
          e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>,
        ) => unknown;
      }
    : undefined;
};

/* hoc component will return new component with field props and with the component props: {...(rest as T)} */
/* code in the calback function is hoc hydration: we can add any editional logic to the final return component */

/* hoc material: https://www.robinwieruch.de/react-higher-order-components/ */

export const hocSmartFormElement =
  <I extends HTMLInputType, T extends object>(Component: ComponentType<T>) =>
  (props: T & SmartFormElementT<I>) => {
    const { name, smartElement, transform, ...rest } = props;
    if (smartElement) {
      const { controllerProps } = useSmartFormContext();
      const { field } = useController({ ...controllerProps, name });

      const { onChange, onBlur, value, name: fieldname } = field;
      const processedValue = transform ? transform.input(value) : value;

      const handleOnChange = transform
        ? (
            e:
              | React.ChangeEvent<HTMLInputElement>
              | React.ChangeEvent<HTMLSelectElement>
              | React.ChangeEvent<HTMLTextAreaElement>,
          ) => onChange(transform.output(e))
        : onChange;

      const fieldWithoutRef = { onChange: handleOnChange, onBlur, value: processedValue, name: fieldname };

      return <Component {...(rest as T)} {...fieldWithoutRef} />;
    }
    return <Component {...(rest as T)} />;
  };
