import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  seatApprovals: {
    paging: {
      pageSize: 0,
      pageNumber: 1,
      itemCount: 0,
    },
    list: [],
    filters: {},
  },
};

export default {
  seatApprovals: createReducer(initialState, {
    [types.SET_SEATS_APPROVALS](state, action) {
      return {
        ...state,
        seatApprovals: {
          ...state.seatApprovals,
          ...action.payload,
        },
      };
    },

    // INSERTION_PT (for script, do not remove)
  }),
};
