export const lookupEndpoint = {
  user: (data: string | null) => ({
    params: { search: data },
    endPoint: "/users/people_lookup",
    type: "user",
  }),
  leadOwner: (data: string | null) => ({
    params: { search: data, type: "lead_owner" },
    endPoint: "/users/lead_person_lookup",
    type: "leadOwner",
  }),
  leadSourceName: (data: string | null) => ({
    params: { search: data, type: "lead_referrer" },
    endPoint: "/users/lead_person_lookup",
    type: "leadSourceName",
  }),
  tier3: (data: string | null) => ({
    params: { search: data },
    endPoint: "/users/tier_3_partner_lookup",
    type: "tier3",
  }),
  location: (data: string | null) => ({ params: { q: data }, endPoint: "/projects/cities", type: "location" }),
  installer: (data: string | null) => ({
    params: { q: data },
    endPoint: "/installers/autocomplete",
    type: "installer",
  }),
  team: (data: string | null) => ({
    params: { q: data },
    endPoint: "/teams/autocomplete",
    type: "team",
  }),
  utility: (data: string | null) => ({
    params: { q: data },
    endPoint: "/utilities/autocomplete",
    type: "utility",
  }),
  teamUser: (data: string | null) => ({
    params: { search: data },
    endPoint: "/users/team_users_autocomplete",
    type: "teamUser",
  }),
  orgTeamUser: (data: string | null) => ({
    params: { search: data },
    endPoint: "/enterprise/org_team_users_autocomplete",
    type: "orgTeamUser",
  }),
};
