import React from "react";
import PropTypes from "prop-types";

const DeletePaymentIcon = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1944 3.89748L9.637 11.0939C9.59648 11.6174 9.35758 12 9.07141 12H2.84838C2.5621 12 2.32333 11.6173 2.28279 11.0939L1.72542 3.89748H10.1944ZM7.77868 1.07546V0.556914C7.77868 0.250665 7.5135 9.81526e-05 7.18931 9.81526e-05L4.81017 0C4.48596 0 4.22069 0.250487 4.22069 0.556816V1.07536L7.77868 1.07546ZM1.44472 1.68833H10.5545C11.0193 1.68833 11.3996 2.04758 11.3996 2.48662C11.3996 2.92567 11.0193 3.28491 10.5545 3.28491L1.44472 3.28481C0.979924 3.28481 0.599609 2.92557 0.599609 2.48652C0.599609 2.04738 0.979924 1.68834 1.44472 1.68834V1.68833ZM7.97872 5.03048L7.62887 10.2157C7.61764 10.3842 7.75335 10.5295 7.93156 10.5401C8.10997 10.5507 8.26375 10.4225 8.27498 10.2542L8.62505 5.0687C8.63627 4.90018 8.50056 4.75492 8.32235 4.74431C8.14373 4.73361 7.99005 4.8617 7.97872 5.03033V5.03048ZM5.63523 5.0454V10.2389C5.63523 10.408 5.78049 10.5452 5.95952 10.5452C6.13856 10.5452 6.28381 10.408 6.28381 10.2389V5.0454C6.28381 4.87629 6.13855 4.73908 5.95952 4.73908C5.78038 4.73908 5.63523 4.8763 5.63523 5.0454ZM4.29031 10.2158L3.94024 5.03033C3.92902 4.8618 3.77534 4.7338 3.59682 4.74441C3.41841 4.75501 3.28269 4.90017 3.29413 5.0688L3.6442 10.2543C3.65542 10.4228 3.8091 10.551 3.98762 10.5402C4.16582 10.5295 4.30143 10.3842 4.29031 10.2158Z"
        fill={fill}
      />
    </svg>
  );
};

DeletePaymentIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

DeletePaymentIcon.defaultProps = {
  fill: "white",
  width: "14",
  height: "12",
};

export default DeletePaymentIcon;
