import makeRequest from "rdx/utils/makeRequest";
import { all, put } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* unassignUser(action) {
  const { teamId, userId } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/sales_teams/${teamId}/unassign`, { user_id: userId });
  if (success && data) {
    yield all([put(actions.getTeams())]);
    yield all([put(actions.getSellers())]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_SALES_TEAM,
    });
  }

  return null;
}

export default unassignUser;
