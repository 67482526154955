import { permissionTypes } from "hooks/usePermissions";
import { createTab, mergeTabObjects } from "util/tabUtils";

export type ProjectDetailsTabKeyT =
  | "dashboard"
  | "summary"
  | "site-info"
  | "proposals"
  | "contracts"
  | "site-survey"
  | "assets"
  | "milestones"
  | "commission"
  | "tickets"
  | "activity-log";

const { VIEW_FINANCIALS } = permissionTypes;

const additionalPostSalesTabs = {
  dashboard: createTab("dashboard", "Dashboard", { conditionalRenderSelector: "getDashboardTabVisible" }),
} as const;

const commonTabs = {
  summary: createTab("summary", "Summary"),
  siteInfo: createTab("site-info", "Site Info"),
  proposals: createTab("proposals", "Proposals"),
  contracts: createTab("contracts", "Contracts", { conditionalRenderSelector: "getContractTabVisible" }),
  siteSurvey: createTab("site-survey", "Site Survey"),
  assets: createTab("assets", "Assets"),
  milestones: createTab("milestones", "Milestones"),
  commission: createTab("commission", "Commission", {
    conditionalRenderSelector: "getCommissionTabVisible",
    permission: [VIEW_FINANCIALS],
  }),
  tickets: createTab("tickets", "Tickets"),
  activityLog: createTab("activity-log", "Activity Log", {
    conditionalRenderSelector: "selectActivityLogTabVisible",
    breadcrumbLabel: "Project Activities",
  }),
} as const;

export { commonTabs, additionalPostSalesTabs };

export default mergeTabObjects([additionalPostSalesTabs, commonTabs]);
