import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/platform/messages";
import { camelizeKeys } from "lib/helpers";

function* getEnterpriseModules(action) {
  const { success, data, error } = yield* makeRequest.get("/enterprise_modules", action.payload);

  if (success && data) {
    yield all([put(actions.setPlatformModules(camelizeKeys(data.body)))]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.GET_ENTERPRISE_MODULES_ERROR,
    });
  }

  return null;
}

export default getEnterpriseModules;
