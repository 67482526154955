import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/projects/types";
import getProviderLeadDetails from "./getProviderLeadDetails";
import getMentors from "./getMentors";
import selectMentor from "./selectMentor";
import removeMentor from "./removeMentor";
import removeAmbassador from "./removeAmbassador";
import getProjectGeocode from "./getProjectGeocode";
import getProviderLeadsList from "./getProviderLeadsList";
import updateProviderLead from "./updateProviderLead";
import getNotes from "./getNotes";
import getAssets from "./getAssets";
import postNewNote from "./postNewNote";
import deleteNote from "./deleteNote";
import editNote from "./editNote";
import postNewAsset from "./postNewAsset";
import deleteAsset from "./deleteAsset";
import requestProposal from "./requestProposal";
import getProjectProposals from "./getProjectProposals";
import getVisionProposalCount from "./getVisionProposalCount";
import requestRevision from "./requestRevision";
import closeProject from "./closeProject";
import reopenProject from "./reopenProject";
import updateLeadInfo from "./updateLeadInfo";
import uploadCoordinators from "./uploadCoordinators";
import getProjectLeadsAutoComplete from "./getProjectLeadsAutoComplete";
import inviteToMyPowur from "./inviteToMyPowur";
import requestProposalUnlock from "./requestProposalUnlock";
import createSiteSurveySchedule from "./createSiteSurveySchedule";
import getSiteSurveySchedule from "./getSiteSurveySchedule";
import updateProposalSettings from "./updateProposalSettings";
import updatePreSalesMilestones from "./updatePreSalesMilestones";
import getProposalSettings from "./getProposalSettings";
import requestVisionProposal from "./requestVisionProposal";
import requestVisionDesignRevision from "./requestVisionDesignRevision";
import updateProviderLeadUsers from "./updateProviderLeadUsers";
import getProjectPayTerms from "./getProjectPayTerms";
import createRoofingProposal from "./createRoofingProposal";
import getRoofingProposals from "./getRoofingProposals";
import getRoofingProposalDetails from "./getRoofingProposalDetails";
import invalidateProposalCache from "./invalidateProposalCache";

import getProjectInterestRates from "./getProjectInterestRates";
import inviteToPowurSite from "./inviteToPowurSite";
import getProjectsCsv from "./getProjectsCsv";
import getProjectsCsvReport from "./getProjectsCsvReport";
import getProviderLeadsKanban from "./getProviderLeadsKanban";
import getContractDetails from "./getContractDetails";
import downloadAssets from "./downloadAssets";

import getKpiData from "./getKpiData";
import getProjectLookupOptions from "./getProjectLookupOptions";
import sendSalesDesignConfirmation from "./sendSalesDesignConfirmation";
import postSellerChangeOrder from "./postSellerChangeOrder";
import getProviderLeadsCsv from "./getProviderLeadsCsv";
import getProviderLeadsCsvReport from "./getProviderLeadsCsvReport";
import getFundingProposals from "./getFundingProposals";
import getFundingProposalDetails from "./getFundingProposalDetails";
import createTpoPricingWorksheet from "./createTpoPricingWorksheet";
import getTpoPricingWorksheets from "./getTpoPricingWorksheets";
// IMPORT_PT (for script, do not remove)

function* watchProjectsSagas() {
  yield trackRequests(takeEvery, types.GET_PROVIDER_LEAD_DETAILS_REQUEST, getProviderLeadDetails);
  yield trackRequests(takeEvery, types.GET_MENTORS_LIST_REQUEST, getMentors);
  yield trackRequests(takeEvery, types.SELECT_MENTOR_REQUEST, selectMentor);
  yield trackRequests(takeEvery, types.REMOVE_MENTOR_REQUEST, removeMentor);
  yield trackRequests(takeEvery, types.REMOVE_AMBASSADOR_REQUEST, removeAmbassador);
  yield trackRequests(takeEvery, types.GET_PROJECT_ADDRESS_GEOCODE, getProjectGeocode);
  yield trackRequests(takeEvery, types.GET_PROVIDER_LEADS_LIST_REQUEST, getProviderLeadsList);
  yield trackRequests(takeEvery, types.UPDATE_PROVIDER_LEAD, updateProviderLead);
  yield trackRequests(takeEvery, types.GET_NOTES_REQUEST, getNotes);
  yield trackRequests(takeEvery, types.GET_ASSETS_REQUEST, getAssets);
  yield trackRequests(takeEvery, types.POST_NEW_NOTE_REQUEST, postNewNote);
  yield trackRequests(takeEvery, types.DELETE_NOTE_REQUEST, deleteNote);
  yield trackRequests(takeEvery, types.EDIT_NOTE_REQUEST, editNote);
  yield trackRequests(takeEvery, types.POST_NEW_ASSET_REQUEST, postNewAsset);
  yield trackRequests(takeEvery, types.DELETE_ASSET_REQUEST, deleteAsset);
  yield trackRequests(takeEvery, types.REQUEST_PROPOSAL, requestProposal);
  yield trackRequests(takeEvery, types.REQUEST_VISION_PROPOSAL, requestVisionProposal);
  yield trackRequests(takeEvery, types.GET_PROJECT_PROPOSALS_REQUEST, getProjectProposals);
  yield trackRequests(takeEvery, types.REQUEST_PROPOSAL_REVISION, requestRevision);
  yield trackRequests(takeEvery, types.REQUEST_VISION_PROPOSAL_REVISION, requestVisionDesignRevision);
  yield trackRequests(takeEvery, types.CLOSE_PROJECT_REQUEST, closeProject);
  yield trackRequests(takeEvery, types.REOPEN_PROJECT_REQUEST, reopenProject);
  yield trackRequests(takeEvery, types.UPDATE_PROJECT_LEAD_INFORMATION_REQUEST, updateLeadInfo);
  yield trackRequests(takeEvery, types.UPLOAD_COORDINATOR_CSV_REQUEST, uploadCoordinators);
  yield trackRequests(takeEvery, types.GET_PROJECT_LEADS_AUTOCOMPLETE_REQUEST, getProjectLeadsAutoComplete);
  yield trackRequests(takeEvery, types.INVITE_TO_MY_POWUR, inviteToMyPowur);
  yield trackRequests(takeEvery, types.REQUEST_PROPOSAL_UNLOCK, requestProposalUnlock);
  yield trackRequests(takeEvery, types.CREATE_SITE_SURVEY_SCHEDULE, createSiteSurveySchedule);
  yield trackRequests(takeEvery, types.GET_SITE_SURVEY_SCHEDULE, getSiteSurveySchedule);
  yield trackRequests(takeEvery, types.UPDATE_PROPOSAL_SETTINGS, updateProposalSettings);
  yield trackRequests(takeEvery, types.GET_PROPOSAL_SETTINGS, getProposalSettings);
  yield trackRequests(takeEvery, types.UPDATE_PROVIDER_LEAD_USERS, updateProviderLeadUsers);
  yield trackRequests(takeEvery, types.UPDATE_PRE_SALES_MILESTONES, updatePreSalesMilestones);
  yield trackRequests(takeEvery, types.GET_PROJECT_PAY_TERMS, getProjectPayTerms);
  yield trackRequests(takeEvery, types.CREATE_ROOFING_PROPOSALS, createRoofingProposal);
  yield trackRequests(takeEvery, types.GET_ROOFING_PROPOSALS_REQUEST, getRoofingProposals);
  yield trackRequests(takeEvery, types.GET_VISION_PROPOSAL_COUNT, getVisionProposalCount);
  yield trackRequests(takeEvery, types.GET_ROOFING_PROPOSAL_DETAILS, getRoofingProposalDetails);
  yield trackRequests(takeEvery, types.GET_PROJECT_INTEREST_RATES, getProjectInterestRates);
  yield trackRequests(takeEvery, types.INVITE_TO_POWUR_SITE, inviteToPowurSite);
  yield trackRequests(takeEvery, types.GET_PROJECTS_CSV, getProjectsCsv);
  yield trackRequests(takeEvery, types.GET_PROJECTS_CSV_REPORT, getProjectsCsvReport);
  yield trackRequests(takeEvery, types.GET_PROVIDER_LEADS_CSV_REPORT, getProviderLeadsCsvReport);
  yield trackRequests(takeEvery, types.GET_PROVIDER_LEADS_KANBAN_REQUEST, getProviderLeadsKanban);
  yield trackRequests(takeEvery, types.GET_CONTRACT_DETAILS_REQUEST, getContractDetails);
  yield trackRequests(takeEvery, types.DOWNLOAD_ASSETS, downloadAssets);
  yield trackRequests(takeEvery, types.GET_KPI_DATA, getKpiData);
  yield trackRequests(takeEvery, types.SEND_SALES_DESIGN_CONFIRMATION, sendSalesDesignConfirmation);
  yield trackRequests(takeEvery, types.GET_PROJECT_LOOKUP_OPTIONS, getProjectLookupOptions);
  yield trackRequests(takeEvery, types.POST_SELLER_CHANGE_ORDER, postSellerChangeOrder);
  yield trackRequests(takeEvery, types.GET_PROVIDER_LEADS_CSV, getProviderLeadsCsv);
  yield trackRequests(takeEvery, types.GET_FUNDING_PROPOSALS_REQUEST, getFundingProposals);
  yield trackRequests(takeEvery, types.GET_FUNDING_PROPOSAL_DETAILS, getFundingProposalDetails);
  yield trackRequests(takeEvery, types.INVALIDATE_PROPOSAL_CACHE, invalidateProposalCache);
  yield trackRequests(takeEvery, types.CREATE_TPO_PRICING_WORKSHEET, createTpoPricingWorksheet);
  yield trackRequests(takeEvery, types.GET_TPO_PRICING_WORKSHEETS, getTpoPricingWorksheets);
  // INSERTION_PT (for script, do not remove)
}

export default watchProjectsSagas;
