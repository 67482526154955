import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { z } from "zod";
import { isNull } from "lodash";
import { useEffectOnce } from "react-use";
import { actions, selectors } from "rdx";
import projectMessages from "rdx/modules/projects/messages";
import adminMessages from "rdx/modules/adminProjects/messages";
import ProviderLead from "models/ProviderLead";
import SmartForm from "components/SmartForm";
import { T3PartnerT } from "components/Drawers/SelectT3PartnerDrawer/types";
import { useSmartForm } from "hooks/useSmartForm";
import usePermissions, { permissionTypes } from "hooks/usePermissions";
import useRouteMatcher from "hooks/useRouteMatcher";
import { canSendVision } from "util/canSendVision";
import { convertToBool } from "lib/helpers";
import { SmartFormDropdown, SmartFormTextarea } from "components/mui-styled-components/SmartFormElements";
import { L2pTabs } from "../../index";
import MissingSiteInfoBanner from "./MissingSiteInfoBanner";
import { DesignTeamNotes, SelfDesignNotes } from "./VisionDesignNotes";
import CannotRequestProposal from "./CannotRequestProposal";

import * as S from "./styles";

const schema = z
  .object({
    proposalType: z.string().min(1, { message: "A proposal type is required." }),
    visionSelfDesign: z.boolean(),
    requestedTurnaround: z.string(),
    proposalNotes: z.string(),
  })
  .refine(
    (data) => (convertToBool(data.visionSelfDesign) && data.proposalType === "vision") || !!data.requestedTurnaround,
    {
      message: "A requested turnaround time is required.",
      path: ["requestedTurnaround"],
    },
  );

const blankFormData = {
  proposalType: "",
  visionSelfDesign: false,
  requestedTurnaround: "",
  proposalNotes: "",
};

export type ProposalSchemaT = z.infer<typeof schema>;

type ProposalRequestFormPropsT = {
  reference: React.LegacyRef<HTMLButtonElement>;
  latestMessage: { message: string; type: string };
  rsmRequirementsPassed: boolean;
  assignedPartner?: T3PartnerT;
  setIsVisionSelfDesign: (bool: boolean) => void;
  setIsProposalFormValid: (bool: boolean) => void;
  setIsVisionRequestSubmitted: (bool: boolean) => void;
  checkForReqdSiteInfo: () => { isQualifyingSiteInfoPresent: boolean; isProposalSiteInfoPresent: boolean };
  isReqdSiteInfoMissing: boolean;
  setCurrentTab: (tabIdx: number) => void;
};

type VisionRequestTypeT = "self-request" | "overnight" | "real-time";

const ProposalRequestForm = ({
  latestMessage,
  reference,
  rsmRequirementsPassed,
  assignedPartner,
  setIsVisionSelfDesign,
  setIsProposalFormValid,
  setIsVisionRequestSubmitted,
  checkForReqdSiteInfo,
  isReqdSiteInfoMissing,
  setCurrentTab,
}: ProposalRequestFormPropsT) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectors.getCurrentUser);
  const projectId = useSelector(selectors.selectLeadToProposalDrawerProjectId);
  const { providerLead: projectDetails } = useSelector(selectors.getProjectDetails);
  const providerLead = new ProviderLead(projectDetails);
  const { projectRequirementMet } = currentUser;
  const {
    canRequestProposal,
    proposalNonrequestableReason,
    soloProposalNonrequestableReason,
    lightreachProposalNonrequestableReason,
  } = projectDetails;
  const [canSendVisionProposal, whyNot] = canSendVision(providerLead);
  const proposalType = useSelector(selectors.selectLeadToProposalDrawerProposalType);
  const isLeadToProposalDrawerOpen = useSelector(selectors.selectLeadToProposalDrawerVisible);
  const { isEnterprise, isAdmin } = useRouteMatcher();

  const currentUserIsAssignedPartner = currentUser.id === assignedPartner?.id;
  const userCannotRequestProposal = !projectRequirementMet && !!assignedPartner;
  const { CAN_REQUEST_VISION_SELF_DESIGN } = usePermissions({
    permissionRequests: [permissionTypes.CAN_REQUEST_VISION_SELF_DESIGN],
  });

  const { methods, getSmartFormProps } = useSmartForm<ProposalSchemaT>({
    schema,
    useFormProps: { defaultValues: blankFormData },
  });
  const { watch, handleSubmit, setValue, reset, trigger } = methods;
  const values = watch();
  const isVisionProposalSelected = values.proposalType === "vision";
  const isVisionSelfDesign = values.visionSelfDesign && isVisionProposalSelected;

  useEffectOnce(() => {
    checkForReqdSiteInfo();
  });

  useEffect(() => {
    setValue("proposalType", proposalType);
  }, [proposalType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLeadToProposalDrawerOpen) reset();
  }, [isLeadToProposalDrawerOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    trigger(["proposalType", "visionSelfDesign", "requestedTurnaround"]).then((validForm) => {
      setIsProposalFormValid(validForm);
    });
  }, [values.proposalType, values.requestedTurnaround, values.visionSelfDesign, trigger, setIsProposalFormValid]);

  useEffect(() => {
    setIsVisionSelfDesign(values.visionSelfDesign && isVisionProposalSelected);
  }, [values.visionSelfDesign, isVisionProposalSelected, setIsVisionSelfDesign]);

  useEffect(() => {
    const errorMessages = [projectMessages.ERROR_REQUESTING_PROPOSAL, adminMessages.ERROR_REQUESTING_ADMIN_PROPOSAL];

    if (errorMessages.includes(latestMessage.message)) {
      dispatch(actions.setAlertMessageVisible({ message: `Error: ${latestMessage.message}`, severity: "error" }));
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (latestMessage.message === projectMessages.REQUEST_VISION_PROPOSAL_SUCCESS) {
      setIsVisionRequestSubmitted(true);
    }
  }, [latestMessage, setIsVisionRequestSubmitted]);

  useEffect(() => {
    if (proposalNonrequestableReason === "already_requested" && whyNot === "already_requested") {
      dispatch(actions.setLeadToProposalDrawerProposalRequested());
    }
  }, [canSendVisionProposal, canRequestProposal, proposalNonrequestableReason, whyNot]); // eslint-disable-line react-hooks/exhaustive-deps

  const submittingAlertMessage = () => {
    dispatch(
      actions.setAlertMessageVisible({
        message: "Submitting your request. Please wait—this may take a few seconds. . .",
        severity: "info",
        duration: 60000,
      }),
    );
  };

  const handleSoloSubmit = () => {
    const soloTurnaround = { 1: "immediate", 24: "nextDay" };
    dispatch(
      actions.requestProposal({
        id: projectId,
        realTime: soloTurnaround[values.requestedTurnaround],
        proposalNotes: values.proposalNotes,
        enterprise: isEnterprise,
        admin: isAdmin,
        isKanban: false,
        proposalType: values.proposalType,
      }),
    );
  };

  const visionRequestType = (): VisionRequestTypeT => {
    if (isVisionSelfDesign) return "self-request";

    const visionTurnaround = { 1: "realtime", 24: "over-night" };
    return visionTurnaround[values.requestedTurnaround];
  };

  const handleVisionSubmit = () => {
    const requestData = {
      id: projectId,
      requestType: visionRequestType(),
      proposalNotes: isVisionSelfDesign ? "" : values.proposalNotes,
    };

    if (isAdmin) {
      dispatch(actions.requestAdminVisionProposal(requestData));
    } else {
      dispatch(actions.requestVisionProposal(requestData));
    }

    setIsVisionRequestSubmitted(true);
  };

  const onSubmit = (): void => {
    submittingAlertMessage();
    if (values.proposalType === "solo" || values.proposalType === "lightreach") {
      handleSoloSubmit();
    } else if (isVisionProposalSelected) {
      handleVisionSubmit();
    }
  };

  const canRequestLightReach = (): boolean => isNull(lightreachProposalNonrequestableReason);

  const canRequestSolo = (): boolean => {
    if (canRequestLightReach()) {
      return false;
    }

    return isNull(soloProposalNonrequestableReason);
  };

  const handleSiteInfoRedirect = () => {
    setCurrentTab(L2pTabs.SiteInfo);
  };

  if (userCannotRequestProposal && !currentUserIsAssignedPartner) {
    return <CannotRequestProposal assignedPartner={assignedPartner} rsmRequirementsPassed={rsmRequirementsPassed} />;
  }
  return (
    <SmartForm {...getSmartFormProps({})}>
      {isReqdSiteInfoMissing && <MissingSiteInfoBanner handleSiteInfoRedirect={handleSiteInfoRedirect} />}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <S.StyledSectionLabel>Proposal Type</S.StyledSectionLabel>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ marginBottom: "20px" }}>
          <SmartFormDropdown
            dropdownOptions={[
              { label: "Vision", value: "vision", disabled: !canSendVisionProposal },
              {
                label: "Solo",
                value: "solo",
                disabled: !canRequestSolo(),
                hidden: !canRequestSolo(),
              },
              {
                label: "LightReach",
                value: "lightreach",
                disabled: !canRequestLightReach(),
                hidden: !canRequestLightReach(),
              },
            ]}
            name="proposalType"
            mainLabel="Select Proposal Tool*"
            mainLabelStyle={{ marginTop: "10px" }}
            data-test-id="proposal-type"
            disabled={!rsmRequirementsPassed || isReqdSiteInfoMissing}
          />
        </Grid>
        {isVisionProposalSelected && CAN_REQUEST_VISION_SELF_DESIGN && (
          <>
            <Grid item xs={12}>
              <S.StyledSectionLabel>Design Method</S.StyledSectionLabel>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ marginBottom: "20px" }}>
              <SmartFormDropdown
                dropdownOptions={[
                  { label: "Powur Proposal Design Team", value: false },
                  { label: "Design Proposal Myself", value: true },
                ]}
                name="visionSelfDesign"
                mainLabel="Select who will be performing the design*"
                mainLabelStyle={{ marginTop: "10px" }}
                data-test-id="design-method"
                disabled={isReqdSiteInfoMissing}
              />
            </Grid>
          </>
        )}
        {!isVisionSelfDesign && (
          <>
            <Grid item xs={12}>
              <S.StyledSectionLabel>Requested Turnaround Time</S.StyledSectionLabel>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ marginBottom: "20px" }}>
              <SmartFormDropdown
                dropdownOptions={[
                  { label: "Expedited", value: "1" },
                  { label: "Next Day", value: "24" },
                ]}
                name="requestedTurnaround"
                placeholder="Select One"
                mainLabel="Time Needed*"
                mainLabelStyle={{ marginTop: "10px" }}
                data-test-id="turnaround"
                disabled={!rsmRequirementsPassed || isReqdSiteInfoMissing}
              />
            </Grid>
            <Grid item xs={12}>
              <S.StyledSectionLabel>Proposal Request Notes</S.StyledSectionLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <SmartFormTextarea
                name="proposalNotes"
                placeholder="Please share any design goals for this proposal including equipment or aesthetic preferences."
                height={145}
                disabled={!rsmRequirementsPassed || isReqdSiteInfoMissing}
              />
            </Grid>
          </>
        )}
        {isVisionProposalSelected && (
          <Grid item xs={12} sx={{ marginBottom: "20px" }}>
            {isVisionSelfDesign ? <SelfDesignNotes /> : <DesignTeamNotes />}
          </Grid>
        )}
      </Grid>
      <button ref={reference} style={{ display: "none" }} type="button" onClick={handleSubmit(onSubmit)} />
    </SmartForm>
  );
};

export default ProposalRequestForm;
