import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getEnterprisesList() {
  const { success, data, error } = yield* makeRequest.get("/enterprise/enterprises_list");
  if (success && data) {
    const { enterprises } = data.body;

    yield put(actions.setEnterprisesList(camelizeKeys(enterprises)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ENTERPRISES_LIST,
    });
  }
  return null;
}

export default getEnterprisesList;
