import { put, select, all } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import makeRequest from "rdx/utils/makeRequest";
import _ from "lodash";
import actions from "rdx/actions";
import selectors from "rdx/modules/adminProposals/selectors";
import messages from "../messages";

function* markInProgress(action) {
  const { id } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/proposal_flows/${id}/mark_in_progress`);
  if (success && data) {
    const { projects } = yield select(selectors.getPendingProposalsList);
    const nextProjects = _.clone(projects).filter((p) => p.project.id !== id);
    yield all([
      put(actions.setPendingAdminProposals({ projects: nextProjects })),
      put(
        actions.newSuccessEvent({
          message: messages.PROPOSAL_MARKED_AS_IN_PROGRESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_MARKING_IN_PROGRESS,
    });
  }
  return null;
}

export default markInProgress;
