import { styled } from "@mui/material/styles";
import { theme } from "styles/themes";
import { mq } from "styles";

export const SelectT3Container = styled("div")(() => ({
  height: "100%",
  [mq.tabletUp]: {
    marginTop: "24px",
  },
}));

export const LabelText = styled("div")(() => ({
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 400,
  color: theme.colors["blue-ribbon"],
  textTransform: "capitalize",
  marginBottom: "30px",
}));

export const BannerIconContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "39px",
  height: "39px",
  borderRadius: "50%",
  background: theme.colors.white,
}));
