import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const InstallScheduledIcon = ({ height = 18, width = 18, fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2043_13586)">
        <path
          d="M16.2 1.8H15.3V0.9C15.3 0.36 14.94 0 14.4 0C13.86 0 13.5 0.36 13.5 0.9V1.8H4.5V0.9C4.5 0.36 4.14 0 3.6 0C3.06 0 2.7 0.36 2.7 0.9V1.8H0.9C0.45 1.8 0 2.16 0 2.7V15.3C0 15.84 0.45 16.2 0.9 16.2H6.39C5.76 15.12 5.4 13.86 5.4 12.6C5.4 8.64 8.64 5.4 12.6 5.4C14.31 5.4 15.84 6.03 17.1 7.02V2.7C17.1 2.25 16.65 1.8 16.2 1.8Z"
          fill={fill}
        />
        <path
          d="M12.5992 7.2002C9.62922 7.2002 7.19922 9.63019 7.19922 12.6002C7.19922 15.5702 9.62922 18.0002 12.5992 18.0002C15.5692 18.0002 17.9992 15.5702 17.9992 12.6002C17.9992 9.63019 15.5692 7.2002 12.5992 7.2002ZM14.3992 13.5002H12.5992C12.0592 13.5002 11.6992 13.1402 11.6992 12.6002V9.9002C11.6992 9.3602 12.0592 9.0002 12.5992 9.0002C13.1392 9.0002 13.4992 9.3602 13.4992 9.9002V11.7002H14.3992C14.9392 11.7002 15.2992 12.0602 15.2992 12.6002C15.2992 13.1402 14.9392 13.5002 14.3992 13.5002Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2043_13586">
          <rect width={width} height={height} fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InstallScheduledIcon;
