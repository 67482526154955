import React from "react";
import PropTypes from "prop-types";

const ClosedThisMonth = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3705 2.48441H25.4331C26.5106 2.48441 27.5439 2.91243 28.3058 3.67431C29.0677 4.43619 29.4957 5.46952 29.4957 6.54699V22.7973C29.4957 23.8748 29.0677 24.9081 28.3058 25.67C27.5439 26.4319 26.5106 26.8599 25.4331 26.8599H4.3077C3.23023 26.8599 2.1969 26.4319 1.43502 25.67C0.673138 24.9081 0.245117 23.8748 0.245117 22.7973V6.54699C0.245117 5.46952 0.673138 4.43619 1.43502 3.67431C2.1969 2.91243 3.23023 2.48441 4.3077 2.48441H8.37028V1.67189C8.37028 1.4564 8.45588 1.24973 8.60826 1.09736C8.76063 0.944979 8.9673 0.859375 9.18279 0.859375C9.39829 0.859375 9.60495 0.944979 9.75733 1.09736C9.90971 1.24973 9.99531 1.4564 9.99531 1.67189V2.48441H19.7455V1.67189C19.7455 1.4564 19.8311 1.24973 19.9835 1.09736C20.1359 0.944979 20.3425 0.859375 20.558 0.859375C20.7735 0.859375 20.9802 0.944979 21.1326 1.09736C21.2849 1.24973 21.3705 1.4564 21.3705 1.67189V2.48441ZM27.1567 24.5209C27.6139 24.0638 27.8707 23.4438 27.8707 22.7973V10.6096H27.8689V8.98454H27.8707V6.54699C27.8707 5.90051 27.6139 5.28051 27.1567 4.82338C26.6996 4.36625 26.0796 4.10944 25.4331 4.10944H21.3705V4.92196C21.3705 5.13745 21.2849 5.34412 21.1326 5.49649C20.9802 5.64887 20.7735 5.73447 20.558 5.73447C20.3425 5.73447 20.1359 5.64887 19.9835 5.49649C19.8311 5.34412 19.7455 5.13745 19.7455 4.92196V4.10944H9.99531V4.92196C9.99531 5.13745 9.90971 5.34412 9.75733 5.49649C9.60495 5.64887 9.39829 5.73447 9.18279 5.73447C8.9673 5.73447 8.76063 5.64887 8.60826 5.49649C8.45588 5.34412 8.37028 5.13745 8.37028 4.92196V4.10944H4.3077C3.66122 4.10944 3.04122 4.36625 2.58409 4.82338C2.12696 5.28051 1.87015 5.90051 1.87015 6.54699V8.98454H1.8728V10.6096H1.87015V22.7973C1.87015 23.4438 2.12696 24.0638 2.58409 24.5209C3.04122 24.978 3.66122 25.2349 4.3077 25.2349H25.4331C26.0796 25.2349 26.6996 24.978 27.1567 24.5209Z"
        fill={fill}
      />
      <rect
        x="18.3584"
        y="10.8008"
        width="1.02045"
        height="10.8849"
        rx="0.510227"
        transform="rotate(45 18.3584 10.8008)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.4"
      />
      <rect
        x="19.0791"
        y="18.4961"
        width="1.02045"
        height="10.8849"
        rx="0.510227"
        transform="rotate(135 19.0791 18.4961)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.4"
      />
      <circle cx="14.8701" cy="15.0117" r="5" stroke={fill} strokeWidth="1.25" />
    </svg>
  );
};

ClosedThisMonth.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ClosedThisMonth.defaultProps = {
  fill: "var(--eggplant)",
  width: "30",
  height: "27",
};

export default ClosedThisMonth;
