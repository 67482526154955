import { styled } from "@mui/material/styles";
import { CardHeader, Modal } from "@mui/material";
import { mq } from "styles";

export const ModalHeader = styled(CardHeader)(({ theme }) => ({
  borderTopLeftRadius: "12px",
  borderTopRightRadius: "12px",
  background: `linear-gradient(270.54deg, ${theme.colors["picton-blue"]} 49.88%, ${theme.colors["blue-ribbon"]} 135.73%)`,
  color: theme.colors.white,
  fontSize: "24px",
  lineHeight: "25px",
  fontWeight: "600",
}));

export const ModalContainer = styled(Modal)(() => ({
  [mq.tabletUp]: {
    width: "504px",
  },
  position: "absolute",

  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  right: "auto",
  bottom: "auto",
  borderRadius: "20px",
  "& .MuiBackdrop-root": {
    background: "none",
  },
  width: "350px",
  height: "491px",
}));

export const ModalBody = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  boxShadow: " 0px 2px 48px rgba(0, 0, 0, 0.2)",
  background: theme.colors.white,
  borderRadius: "12px",
  textAlign: "center",
}));

export const ModalFooter = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: "14px",
  background: theme.colors["ma-white"],
  borderRadius: "0px 0px 12px 12px;",
}));
