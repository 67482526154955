import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import StorageClient from "util/StorageClient";
import SessionClient from "util/SessionClient";

import { Dropdown } from "antd";

import { DownCaretThicker, AccountIcon, ProfileIcon, LogoutIcon } from "@icons";
import LogoutModal from "components/Modals/Logout/LogoutModal";
import ReminderNotifications from "./ReminderNotifications";
import UserDisplay from "./UserDisplay";

import styles from "./PlatformPageHeader.module.less";

const UserLogout = (props) => {
  const { logout } = props;
  const session = new SessionClient();
  const storage = new StorageClient();
  const { user } = session;

  const history = useHistory();
  const { location } = history;

  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const openLogoutModal = () => {
    setLogoutModalOpen(true);
  };

  const closeLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  const handleSubmit = () => {
    logout();
    if (session.user) {
      session.user.clearStore();
    }
    session.clear();
    storage.setStorage({ tickets: {} }, "inboxFilters");
    storage.setStorage({ projects: {} }, "projectFilters");
    window.location.href = "/login";
  };

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  const isActiveRoute = (routeName) => location.pathname.includes(routeName);

  const menu = (
    <div className={styles.menu} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className={styles.inner}>
        <div
          onMouseEnter={() => setHoveredItem(0)}
          onMouseLeave={() => setHoveredItem(null)}
          className={isActiveRoute("profile") ? styles.itemRowActive : styles.itemRow}
          onClick={() => history.push("/profile")}
          onKeyDown={() => history.push("/profile")}
          role="button"
          tabIndex={0}
        >
          <div className={styles.iconContainer}>
            <ProfileIcon fill={hoveredItem === 0 || isActiveRoute("profile") ? "white" : "var(--primary-color)"} />
          </div>
          <span>Profile</span>
        </div>
        <div
          onMouseEnter={() => setHoveredItem(1)}
          onMouseLeave={() => setHoveredItem(null)}
          className={isActiveRoute("account") ? styles.itemRowActive : styles.itemRow}
          onClick={() => history.push("/account")}
          onKeyDown={() => history.push("/account")}
          role="button"
          tabIndex={0}
        >
          <div className={styles.iconContainer}>
            <AccountIcon fill={hoveredItem === 1 || isActiveRoute("account") ? "white" : "var(--primary-color)"} />
          </div>
          <span>Account</span>
        </div>
        <div
          onMouseEnter={() => setHoveredItem(2)}
          onMouseLeave={() => setHoveredItem(null)}
          className={styles.itemRow}
          onClick={() => setLogoutModalOpen(true)}
          onKeyDown={() => setLogoutModalOpen(true)}
          role="button"
          tabIndex={0}
        >
          <div className={styles.iconContainer}>
            <LogoutIcon fill={hoveredItem === 2 ? "white" : "var(--primary-color)"} />
          </div>
          <span>Logout</span>
        </div>
        <LogoutModal
          visible={logoutModalOpen}
          onSubmit={handleSubmit}
          closeModal={closeLogoutModal}
          openModal={openLogoutModal}
        />
      </div>
    </div>
  );

  return (
    <div className={styles.headerRight}>
      <ReminderNotifications />
      <Dropdown
        open={dropdownVisible}
        dropdownRender={() => menu}
        placement="bottom"
        trigger={["click", "hover"]}
        overlayClassName={styles.logoutDropdownOverlay}
      >
        <div
          className={[styles.display, ...(dropdownVisible ? [styles.hoverDisplay] : [])]}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {user && <UserDisplay user={user} />}
          <div className={styles.caretContainer}>
            <DownCaretThicker fill="white" height="8px" width="12px" />
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

// UserLogout.propTypes = {};

// UserLogout.defaultProps = {};

export default connect(getRdxSelectionMapper({}), getRdxActionMapper(["logout"]))(UserLogout);
