export default {
  // EXAMPLE_ACTION_TYPE: "[adminNotices] Example Action Type",
  GET_ADMIN_NOTICES_LIST_REQUEST: "[adminNotices] GET Admin Notices List Request",
  GET_ADMIN_NOTICE_PREVIEW_REQUEST: "[adminNotices] GET Admin Notice Preview Request",
  SET_ADMIN_NOTICE_PREVIEW: "[adminNotices] SET Admin Notice Preview",
  DELETE_ADMIN_NOTICE_REQUEST: "[adminNotices] Delete Admin Notice Request",
  DELETE_ADMIN_NOTICE: "[adminNotices] Delete Admin Notice",
  CREATE_NEW_ADMIN_NOTICE_REQUEST: "[adminNotices] Create New Admin Notice Request",
  SET_ADMIN_NOTICES_LIST_FILTERS: "[adminNotices] SET Admin Notices List Filters",
  SET_ADMIN_NOTICES_LIST: "[adminNotices] SET Admin Notices List",
  GET_ADMIN_NOTICE_DETAILS_REQUEST: "[adminNotices] GET Admin Notice Details Request",
  SET_ADMIN_NOTICE_DETAILS: "[adminNotices] SET Admin Notice Details",
  UPDATE_ADMIN_NOTICE_REQUEST: "[adminNotices] Update Admin Notice Request",
  UPDATE_ADMIN_NOTICE_DETAIL: "[adminNotices] Update Admin Notice Detail",
  SET_ADMIN_NOTICES_UI_STATE: "[adminNotices] SET Admin Notices Ui State",
  // INSERTION_PT (for script, do not remove)
};
