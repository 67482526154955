import React from "react";
import { IconT } from "types/utils";
import { theme } from "styles/themes";

const CheckCircleBrokenIcon = ({ height = 20, width = 20, fill = theme.colors["green-haze"] }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2278_1165)">
        <path
          d="M18.3334 9.23843V10.0051C18.3324 11.8021 17.7505 13.5507 16.6745 14.99C15.5986 16.4292 14.0862 17.4822 12.3629 17.9917C10.6396 18.5012 8.7978 18.44 7.11214 17.8172C5.42648 17.1945 3.98729 16.0435 3.00922 14.536C2.03114 13.0285 1.56657 11.2451 1.68481 9.45202C1.80305 7.6589 2.49775 5.95203 3.66531 4.58599C4.83288 3.21994 6.41074 2.26791 8.16357 1.87188C9.91641 1.47585 11.7503 1.65704 13.3918 2.38843M18.3334 3.33366L10.0001 11.6753L7.50008 9.17533"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2278_1165">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckCircleBrokenIcon;
