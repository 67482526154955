import component from "containers/StaticPages/education/submitRedirectPage/NoPage";

// import l from "@loadable/component";

// const component = l(() =>
//   import("containers/education/submitRedirectPage/NoPage"),
// );

export default {
  key: "education-qualify-no",
  title: "Education: Qualify No",
  path: "/:user?/education/qualify-no/:reason",
  pageName: "qualify_no",
  linkTarget: "/education/qualify-no",
  component,
};
