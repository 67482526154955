export default function formatPhoneNumber(phoneNumberString, options = {}) {
  if (!phoneNumberString || typeof phoneNumberString !== "string") return null;

  const { withDashes = false } = options;

  if (!phoneNumberString) return phoneNumberString;
  if (phoneNumberString.includes("+1")) {
    return `+1 ${formatPhoneNumber(phoneNumberString.slice(2), options)}`;
  }
  if (phoneNumberString.includes("+52")) {
    return `+52 ${formatPhoneNumber(phoneNumberString.slice(3), options)}`;
  }
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    if (withDashes) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumberString;
}
