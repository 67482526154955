import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "rdx/modules/enterpriseApplications/messages";

function* rejectEnterpriseApplication(action) {
  const { id } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/enterprise_applications/${id}/application_responses`, {
    enterprise_application_id: id,
    decision: 2,
    external: false,
  });

  if (success && data) {
    const applicationDetail = yield select(selectors.selectEnterpriseApplicationDetail);

    yield all([
      put(
        actions.setEnterpriseApplicationDetail({
          ...applicationDetail,
          response: { ...applicationDetail.response, decision: "rejected" },
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.REJECT_ENTERPRISE_APPLICATION_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_REJECTING_ENTERPRISE_APPLICATION,
    });
  }

  return null;
}

export default rejectEnterpriseApplication;
