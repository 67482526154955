import React, { CSSProperties } from "react";
import { FieldValues, FieldPath, useController } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import { TextFieldProps } from "@mui/material/TextField";

import { CheckboxChecked, CheckboxUnchecked } from "components/Icons";
import { cvar } from "styles";
import { useSmartFormContext } from "components/SmartForm";
import HelperText from "../HelperText";

import * as S from "./styles";

type DropdownOptionT = {
  label: string;
  value: string | number;
};

type SmartFormMultiSelectDropdownPropsT<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>,
> = {
  dropdownOptions: DropdownOptionT[];
  name: TFieldName;
  setSelectedValues: (name, value: DropdownOptionT[] | null) => void;
  mainLabel?: string;
  helperText?: string | undefined;
  error?: boolean;
  disabled?: boolean;
  mainLabelStyle?: CSSProperties;
  helperTextStyle?: CSSProperties;
  required?: boolean;
  fullWidth?: boolean;
} & TextFieldProps;

const SmartFormMultiSelectDropdown = <TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>({
  dropdownOptions,
  name,
  setSelectedValues,
  mainLabel,
  helperText,
  error,
  disabled = false,
  mainLabelStyle = {},
  helperTextStyle = {},
  required,
  fullWidth = true,
  ...rest
}: SmartFormMultiSelectDropdownPropsT<TFieldValues, TFieldName>) => {
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });
  const { value } = field;

  const handleOptionSelect = (_, v: DropdownOptionT[]) => {
    setSelectedValues(name, v);
  };

  return (
    <>
      <S.LabelGroupWrapper>
        <S.StyledFormLabel style={mainLabelStyle}>{mainLabel}</S.StyledFormLabel>
        {required && <S.StyledRequiredLabel>*</S.StyledRequiredLabel>}
      </S.LabelGroupWrapper>
      <Autocomplete
        id="smart-form-checkbox"
        multiple
        limitTags={2}
        options={dropdownOptions}
        disableCloseOnSelect
        getOptionLabel={(option) => option?.label}
        size="small"
        fullWidth={fullWidth}
        value={value}
        onChange={handleOptionSelect}
        isOptionEqualToValue={(opt, val) => opt.value === val.value}
        renderOption={(props, option, { selected }) => {
          return (
            <S.StyledListItem {...props}>
              <Checkbox
                icon={<CheckboxUnchecked />}
                checkedIcon={<CheckboxChecked />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </S.StyledListItem>
          );
        }}
        componentsProps={{
          popupIndicator: { sx: { transform: "none" } },
        }}
        sx={{
          "& .MuiAutocomplete-endAdornment": {
            top: "3px",
            "&  button:last-of-type": {
              borderRadius: "0px",
              height: "18px",
              borderLeft: `1px solid ${cvar("dark-blue")}`,
              paddingLeft: "5px",
              marginLeft: "10px",
            },
          },
          "& .MuiInputBase-root": {
            flexWrap: "nowrap",
          },
          "& .MuiChip-root": {
            width: "calc(50% - 28px)",
            marginTop: "-2px",
            marginBottom: "-2px",
          },
          "& .Mui-focused": {
            flexWrap: "wrap !important",
            "& .MuiChip-root": {
              width: "fit-content",
              maxWidth: "calc(100% - 4px)",
              margin: "2px !important",
            },
          },
        }}
        ChipProps={{
          sx: {
            border: `1px solid ${cvar("blue-ribbon")}`,
            background: cvar("white"),
            color: cvar("blue-ribbon"),
            fontWeight: 600,
            "& .MuiSvgIcon-root": {
              fill: cvar("blue-ribbon"),
            },
          },
        }}
        getLimitTagsText={(more: number) => (
          <S.AdditionalSelectedLabel>
            ...<S.AdditionalCount>{more}</S.AdditionalCount>
          </S.AdditionalSelectedLabel>
        )}
        popupIcon={<S.StyledDownCaretFilled />}
        clearIcon={<S.StyledCloseIcon fill={cvar("dark-blue")} />}
        renderInput={(params) => (
          <S.StyledTextField
            {...params}
            {...rest}
            placeholder={value.length ? undefined : rest.placeholder}
            disabled={disabled}
            size="small"
            sx={{
              "& input::placeholder": {
                opacity: 1,
                fontWeight: 600,
                color: cvar("dark-blue"),
              },
            }}
          />
        )}
      />
      <HelperText sx={{ marginTop: "-10px", ...helperTextStyle }} error={error}>
        {helperText}
      </HelperText>
    </>
  );
};

export type { DropdownOptionT };

export default SmartFormMultiSelectDropdown;
