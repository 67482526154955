import React from "react";

const EnvelopeIcon = () => {
  return (
    <svg
      style={{ display: "inline-block" }}
      width="22"
      height="15"
      viewBox="0 0 26 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.38596 6.54002L1.3263 1.0057C1.59951 0.869045 1.87272 0.80072 2.21423 0.80072H17.2407C17.5822 0.80072 17.992 0.93737 18.2653 1.07402L10.069 6.54002C9.86408 6.67667 9.59087 6.67667 9.38596 6.54002ZM19.1532 2.16722L10.8203 7.70155C10.1373 8.1115 9.31766 8.1115 8.63464 7.70155L0.370068 2.03057C0.233463 2.30387 0.165161 2.57717 0.165161 2.85047V12.416C0.165161 13.5775 1.05309 14.4657 2.21423 14.4657H17.2407C18.4019 14.4657 19.2898 13.5775 19.2898 12.416V2.85047C19.2898 2.6455 19.2215 2.3722 19.1532 2.16722Z"
        fill="white"
      />
      <circle cx="19.779" cy="12.72" r="6.19395" fill="var(--goldenrod)" />
      <path
        d="M19.7948 11.4377C19.6156 11.617 19.325 11.617 19.1457 11.4377C18.9665 11.2585 18.9665 10.9678 19.1457 10.7886L20.4441 9.49023C21.1611 8.77323 22.3235 8.77323 23.0406 9.49023C23.7576 10.2073 23.7576 11.3697 23.0406 12.0867L21.7423 13.385C21.5631 13.5642 21.2724 13.5642 21.0932 13.385C20.9139 13.2058 20.9139 12.9151 21.0932 12.7358L22.3915 11.4376C22.7499 11.0791 22.7499 10.4978 22.3915 10.1394C22.0329 9.78084 21.4517 9.78084 21.0932 10.1394L19.7948 11.4377ZM19.795 14.0341C19.9742 13.8549 20.2648 13.8549 20.4441 14.0341C20.6233 14.2134 20.6233 14.504 20.4441 14.6832L19.1457 15.9816C18.4287 16.6986 17.2663 16.6986 16.5492 15.9816C15.8322 15.2646 15.8322 14.1021 16.5492 13.3851L17.8475 12.0868C18.0268 11.9076 18.3174 11.9076 18.4967 12.0868C18.6759 12.2661 18.6759 12.5567 18.4967 12.736L17.1984 14.0342C16.8399 14.3927 16.8399 14.974 17.1984 15.3325C17.5569 15.691 18.1381 15.691 18.4967 15.3325L19.795 14.0341ZM21.0932 11.4376C21.2724 11.6168 21.2724 11.9075 21.0932 12.0867L19.1457 14.034C18.9665 14.2133 18.6759 14.2133 18.4966 14.034C18.3174 13.8548 18.3174 13.5641 18.4966 13.3849L20.444 11.4376C20.6233 11.2583 20.9138 11.2583 21.0931 11.4376H21.0932Z"
        fill="white"
      />
    </svg>
  );
};

export default EnvelopeIcon;
