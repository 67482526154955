import { put, select, all } from "redux-saga/effects";
import selectors from "rdx/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys, keysToSnakeCase } from "lib/helpers";
import messages from "../messages";

function* updateEnterpriseOrg(action) {
  const params = keysToSnakeCase(action.payload);
  const { success, data, error } = yield* makeRequest.post("/enterprise/settings", params);

  if (success && data) {
    const currentSettings = yield select(selectors.getEnterpriseOrg);
    const payload = camelizeKeys(action.payload);
    const orgName = payload.name;
    delete payload.name;

    yield all([
      put(
        actions.setEnterpriseOrg({
          ...currentSettings,
          name: orgName || currentSettings.name,
          ...(payload.enterpriseLogo?.length > 0 && { enterpriseLogo: payload.enterpriseLogo }),
          ...(payload.enterpriseLogo === null && { enterpriseLogo: "" }),
          settings: payload,
        }),
      ),
      put(actions.getEnterpriseCobranding()),
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_ENTERPRISE_ORG_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_CONSULTANT_TERMS,
    });
  }
  return null;
}

export default updateEnterpriseOrg;
