import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "antd";
import DefaultAvatar from "components/Icons/Avatars/DefaultAvatar";

const UserAvatar = ({ className = "", size = 28, avatarUrls, ...rest }) => {
  const avatarProps = {
    className,
    size,
    shape: "circle",
    height: size,
    width: size,
    ...rest,
  };
  if (avatarUrls && avatarUrls.thumb) {
    return <Avatar {...avatarProps} src={avatarUrls.thumb} />;
  }
  return <Avatar {...avatarProps} icon={<DefaultAvatar />} />;
};

UserAvatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  avatarUrls: PropTypes.shape({
    thumb: PropTypes.string,
    retina: PropTypes.string,
    large: PropTypes.string,
    preview: PropTypes.string,
  }),
};

export default UserAvatar;
