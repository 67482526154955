import { select, put, all } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import makeRequest from "rdx/utils/makeRequest";
import _ from "lodash";
import { camelizeKeys } from "lib/helpers";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "../messages";

function* updateProposal(action) {
  const { id, url, revisionComplete } = action.payload;

  const params = {
    url,
    revision_complete: revisionComplete,
  };

  const { success, data, error } = yield* makeRequest.patch(`/proposal_flows/${id}`, params);
  if (success && data) {
    const project = camelizeKeys(_.clone(data.body));
    const { projects } = action.payload.url
      ? yield select(selectors.getCompletedProposalsList)
      : yield select(selectors.getInProgressProposalsList);

    const newProjects = projects.map((p) => {
      if (p.proposal?.id === project.proposal.id) {
        return project;
      }
      return p;
    });
    yield all([
      action.payload.url
        ? put(actions.setCompletedAdminProposals({ projects: newProjects }))
        : put(actions.setInProgressAdminProposals({ projects: newProjects })),
      put(actions.newSuccessEvent({ message: messages.PROPOSAL_UPDATED })),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_PROPOSAL,
    });
  }
  return null;
}

export default updateProposal;
