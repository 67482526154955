import tabs from "containers/Sales/dashboard/EventsAndUpdates/tabs";
import breadcrumbs from "components/PlatformLayout/components/PlatformPageHeader/Breadcrumbs/SellerDashboardBreadCrumbs";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";

const content = l(() => import("containers/Sales/dashboard/EventsAndUpdates"));

const { SALES_DASHBOARD_VIEW, PREVIEW_USER } = permissionTypes;

export default {
  key: "dashboard-events-and-updates",
  title: breadcrumbs,
  path: "/dashboard/:tab?",
  basePath: "dashboard",
  requiredParams: ["tab"],
  linkTarget: null,
  linkTitle: null,
  linkCategory: null,
  permission: [SALES_DASHBOARD_VIEW, PREVIEW_USER],
  icon: null,
  tabs,
  content,
};
