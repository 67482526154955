export default {
  // general
  IMPERSONATE_TOKEN_RECEIVED_SUCCESS: "Successfully retrieved impersonated JWT for this user.",
  LOGBACK_TOKEN_RECEIVED_SUCCESS: "Successfully retrieved logback JWT for this user.",
  CHANGE_USER_SPONSOR_SUCCESS: "User's sponsor has been reassigned.",
  CHANGE_USER_MANAGER_SUCCESS: "Manager assigned",
  setUserAsCompedSuccess: (serviceName) => `User is now a comped ${serviceName} subscriber.`,
  ACTIVATE_USER_ENROLLMENT_SUCCESS: "User's Powur Energy enrollment is now active.",
  FETCH_ENTITIES_SUCCESS: "Retrieved permission entities success.",
  FETCH_OPERATIONS_SUCCESS: "Retrieved permission operations success.",
  ADD_NEW_ROLE_SUCCESS: "New role added.",
  ADD_NEW_PERMISSION_SUCCESS: "New permission added.",
  DELETE_PERMISSION_SUCCESS: "Permission removed.",
  ADD_NEW_PERMISSIONS_ENTITY_SUCCESS: "New permissions entity added.",
  READD_USER_TO_LMS_SUCCESS: "User has been readded to LMS.",
  CANCEL_SUBSCRIPTION_BY_ADMIN_SUCCESS: "User's subscription has been canceled.",
  BAN_USER_SUCCESS: "User has been banned from the platform.",
  UNBAN_USER_SUCCESS: "User has been unbanned from the platform.",
  RENEW_SUBSCRIPTION_BY_ADMIN_SUCCESS: "User's subscription has been renewed.",
  DESTROY_SUBSCRIPTION_BY_ADMIN_SUCCESS: "User's subscription has been destroyed.",
  UPDATE_USER_SUCCESS: "User details updated.",
  UPDATE_PERMISSION_SUCCESS: "Permission details updated.",
  DELETE_PERMISSION_ENTITY_SUCCESS: "Permission entity removed.",
  DELETE_PERMISSION_OPERATION_SUCCESS: "Permission operation removed.",
  UPDATE_PERMISSION_ENTITY_SUCCESS: "Permission entity updated.",
  UPDATE_PERMISSION_OPERATION_SUCCESS: "Permission operation updated.",
  MARK_TOOLTIP_VIEWED_SUCCESS: "Tooltip marked as viewed.",
  CREATE_TEAM_SUCCESS: "Team created successfully.",
  UPDATE_TEAM_SUCCESS: "Team updated successfully.",
  DELETE_TEAM_SUCCESS: "Team updated successfully.",
  UPDATE_MENTOR_RATINGS_SUCCESS: "Mentor rating changes saved.",
  UPGRADE_AMBASSADOR_SUCCESS: "Upgrade Ambassador to a Powur Sales role has completed successfully.",
  UPDATE_DEFAULT_ADVANCE_SUCCESS: "Update to default M1 saved successfully.",

  // errors
  ERROR_FETCHING_USERS_LIST: "There was an error retrieving users list",
  ERROR_FETCHING_USER_ROLES_LIST: "There was an error retrieving roles",
  ERROR_FETCHING_USER_DETAILS: "There was an error retrieving user information",
  ERROR_FETCHING_MENTOR_RATINGS_FOR_MENTOR: "There was an error retrieving mentor ratings",
  ERROR_FETCHING_USER_NAME: "There was an error retrieving user name information",
  ERROR_ADDING_PERMISSION_TO_ROLE: "There was an error adding permission",
  ERROR_REMOVING_PERMISSION_FROM_ROLE: "There was an error removing permission",
  ERROR_IMPERSONATING_USER: "There was an error requesting to impersonate this user",
  ERROR_LOGBACKING_USER: "There was an error requesting to log back to this user",
  ERROR_UPDATING_USER: "There was an error updating this user",
  ERROR_ADDING_ROLE_TO_USER: "There was an error adding role to this user",
  ERROR_REMOVING_ROLE_FROM_USER: "There was an error removing role from this user",
  ERROR_FETCHING_USERS_AUTOCOMPLETE: "There was an error searching users",
  ERROR_CHANGING_USER_SPONSOR: "There was an error updating the selected sponsor",
  ERROR_CHANGING_USER_MANAGER: "There was an error submitting user's new manager",
  ERROR_CHANGING_USER_CERTIFICATION_DATE: "There was an error submitting new certification date",
  ERROR_SETTING_USER_AS_COMPED: "There was an error setting user as comped",
  ERROR_ACTIVATING_USER_ENROLLMENT: "There was an error activating user's enrollment",
  ERROR_ADDING_NEW_ROLE: "There was an error adding new role",
  ERROR_UPDATING_ROLE: "There was an error updating role",
  ERROR_READDING_USER_TO_LMS: "There was an error readding user to LMS",
  ERROR_CANCELING_SUBSCRIPTION_BY_ADMIN: "There was an error cancelling user's subscription.",
  ERROR_BANNING_USER: "There was an error banning the user.",
  ERROR_UNBANNING_USER: "There was an error unbanning the user.",
  ERROR_RENEWING_SUBSCRIPTION_BY_ADMIN: "There was an error renewinging user's subscription.",
  ERROR_DESTROYING_SUBSCRIPTION_BY_ADMIN: "There was an error destroying user's subscription.",
  ERROR_DELETING_ROLE: "There was an error deleting this role",
  ERROR_ADDING_NEW_PERMISSION: "There was an error adding new permission",
  ERROR_DELETING_PERMISSION: "There was an error removing this permission",
  ERROR_GETTING_PERMISSIONS_ENTITIES: "There was an error retrieving permission entities list",
  ERROR_GETTING_PERMISSIONS_OPERATIONS: "There was an error retrieving permission operations list",
  ERROR_CREATING_NEW_PERMISSIONS_ENTITY: "There was an error creating new entity",
  ERROR_CREATING_NEW_PERMISSIONS_OPERATION: "There was an error creating new operation",
  ERROR_UPDATING_PERMISSION: "There was an error updating this permission",
  ERROR_DELETING_PERMISSION_ENTITY: "There was an error removing this permission entity",
  ERROR_DELETING_PERMISSION_OPERATION: "There was an error removing this permission operation",
  ERROR_UPDATING_PERMISSION_ENTITY: "There was an error updating permission entity",
  ERROR_UPDATING_PERMISSION_OPERATION: "There was an error updating permission operation",
  ERROR_MARKING_TOOLTIP_VIEWED: "There was an error marking this tooltip as viewed",
  ERROR_GETTING_TEAMS_LIST: "There was an error retrieving your team data",
  ERROR_CREATING_TEAM: "There was an error updating this team",
  ERROR_UPDATING_TEAM: "There was an error updating this team",
  ERROR_DELETING_TEAM: "There was an error deleting this team",
  ERROR_UPDATING_MENTOR_RATING: "There was an error saving changes to mentor rating",
  ERROR_GETTING_SELLER_APPLICATION_AGREEMENT: "There was an error getting application agreement",
  ERROR_UPGRADING_AMBASSADOR: "There was an error upgrading ambassador",
  ERROR_UPDATING_DEFAULT_ADVANCE: "There was an issue updating your default M1 commission advance",
};
