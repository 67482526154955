import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import _ from "lodash";
import { Form, Row, Col, Input, Button, Spin } from "antd";
import Checkbox from "antd/lib/checkbox";
import { actions } from "rdx";
import SessionClient from "util/SessionClient";
import { openLinkInNewTab } from "lib/props";
import msgs from "rdx/modules/ambassador/messages";
import MessageEvent from "models/MessageEvent";
import FormErrorMessage from "components/FormElements/FormErrorMessage";
import styles from "./CreateAccount.module.less";

const CreateAccountForm = (props) => {
  const { regInfo, applicationAgreement, getRegInfo, getAgreement, createUser, code, loading, latestMessage, push } =
    props;
  const [form] = Form.useForm();
  const session = new SessionClient();
  const dispatch = useDispatch();
  const [tosChecked, setTosChecked] = React.useState(false);

  useEffect(() => {
    getRegInfo({ code });
    getAgreement();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (regInfo && !_.isEmpty(regInfo)) {
      if (regInfo.confirmed) push("/ambassador/dashboard/home");
      form.setFieldsValue({
        email: regInfo.email,
        firstName: regInfo.firstName,
        lastName: regInfo.lastName,
      });
    }
  }, [regInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (latestMessage) {
      if (latestMessage.message === msgs.CREATE_NEW_AMBASSADOR_USER_SUCCESS) {
        const { payload } = latestMessage;
        dispatch(
          actions.setAlertMessageVisible({
            message: "Ambassador account created.",
            severity: "success",
          }),
        );
        session.setSession(payload);
        session.uiState = {};
        session.user.initStore(payload);
        push("/ambassador/dashboard/home");
      }

      if (latestMessage.message === msgs.ERROR_CREATING_NEW_AMBASSADOR_USER) {
        dispatch(
          actions.setAlertMessageVisible({
            message: latestMessage.msg,
            severity: "error",
          }),
        );
      }
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormSubmit = (values) => {
    if (tosChecked) {
      createUser({ ...values, slug: code });
    } else {
      dispatch(
        actions.setAlertMessageVisible({
          message: "Please affirm your agreement to Powur Seller terms and conditions.",
          severity: "error",
        }),
      );
    }
  };

  const tosChanged = (e) => {
    setTosChecked(e.target.checked);
  };

  return (
    <div className={styles.tab}>
      {loading && (
        <div className={styles.loading}>
          <Spin />
        </div>
      )}
      <div className={styles.banner}>Create Your Account</div>
      <Form
        form={form}
        name="create-account"
        className={styles.form}
        requiredMark={false}
        colon={false}
        layout="vertical"
        onFinish={handleFormSubmit}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name={["firstName"]}
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: <FormErrorMessage message="First name is required." />,
                },
              ]}
            >
              <Input className={styles.input} placeholder="FIRST NAME" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={["lastName"]}
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: <FormErrorMessage message="Last name is required." />,
                },
              ]}
            >
              <Input className={styles.input} placeholder="LAST NAME" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name={["email"]} className={styles.formItem}>
          <Input
            className={styles.input}
            placeholder="EMAIL"
            size="large"
            autoComplete="username"
            disabled
            style={{
              backgroundColor: "rgba(239, 240, 241, 0.65)",
              border: "1px solid var(--light-silver)",
            }}
          />
        </Form.Item>
        <Form.Item
          name={["password"]}
          className={styles.formItem}
          rules={[
            {
              required: true,
              message: <FormErrorMessage message="Password is required." />,
            },
            {
              min: 8,
              message: (
                <FormErrorMessage message="Please choose a password that is at least eight characters in length." />
              ),
            },
          ]}
        >
          <Input.Password className={styles.input} placeholder="PASSWORD" size="large" autoComplete="new-password" />
        </Form.Item>
        <Form.Item
          name={["passwordConfirm"]}
          className={styles.formItem}
          dependencies={["password"]}
          validateTrigger={["onSubmit"]}
          rules={[
            {
              required: true,
              message: <FormErrorMessage message="Please confirm your password." />,
            },
            {
              validator: (_rule, value) => {
                if (!value || form.getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords do not match"));
              },
              message: (
                <FormErrorMessage message="The two passwords you have entered do not match. Please check and try again." />
              ),
            },
          ]}
        >
          <Input.Password
            className={styles.input}
            placeholder="CONFIRM PASSWORD"
            size="large"
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item
          name={["tos"]}
          className={styles.formItem}
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: (
                <FormErrorMessage message="Please affirm your agreement to Powur Seller terms and conditions." />
              ),
            },
          ]}
        >
          <Checkbox className={styles.checkbox} checked={tosChecked} onChange={tosChanged}>
            Terms and Conditions: I agree to the{" "}
            <a {...openLinkInNewTab(applicationAgreement && applicationAgreement.documentPath)}>
              {" "}
              Powur Seller Policies and Procedures
            </a>
          </Checkbox>
        </Form.Item>
        <Form.Item className={styles.formItem}>
          <Button className={styles.submitButton} type="primary" block htmlType="submit" disabled={!tosChecked}>
            Create <span className={styles.bold}>Account</span>
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

CreateAccountForm.propTypes = {
  regInfo: PropTypes.shape({
    confirmed: PropTypes.bool,
    createdAt: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.number,
    inviteAt: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
  }),
  applicationAgreement: PropTypes.shape({
    documentPath: PropTypes.string,
    message: PropTypes.string,
    version: PropTypes.string,
  }),
  getRegInfo: PropTypes.func,
  getAgreement: PropTypes.func,
  createUser: PropTypes.func,
  code: PropTypes.string,
  loading: PropTypes.bool,
  latestMessage: MessageEvent.types(),
  push: PropTypes.func,
};

export default CreateAccountForm;
