import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const EnterpriseCompensationIcon = (props) => {
  const { fill, height, width, active, hovered } = props;

  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      style={{
        paddingTop: "1px",
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.enterpriseIcon}
      >
        <rect
          x="0.834375"
          y="1.03359"
          width="19.3"
          height="19.3"
          rx="9.65"
          fill="var(--royal-peacock-blue)"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="0.7"
        />
        <path
          d="M11.9541 15.1172C11.4655 15.3486 10.9126 15.4901 10.3211 15.4901C9.74242 15.4901 9.18949 15.3615 8.70085 15.13V15.5158C8.70085 15.6701 8.64942 15.8244 8.57227 15.9402C9.11234 16.1588 9.70384 16.2873 10.3339 16.2873C10.964 16.2873 11.5555 16.1588 12.0956 15.9402C12.0056 15.8244 11.967 15.6701 11.967 15.5158V15.1172H11.9541Z"
          fill={fill}
        />
        <path
          d="M12.7393 8.75396C13.305 9.22973 13.7294 9.85981 13.948 10.5799C14.1023 10.5285 14.2823 10.5028 14.4495 10.5028C14.5523 10.5028 14.6552 10.5156 14.7581 10.5285C14.4752 9.40976 13.768 8.44535 12.8164 7.82812C12.8164 7.84098 12.8164 7.85384 12.8164 7.8667V8.39391C12.8293 8.5225 12.8036 8.65109 12.7393 8.75396Z"
          fill={fill}
        />
        <path
          d="M6.68209 10.5799C6.90069 9.85981 7.32503 9.22973 7.89082 8.75396C7.83939 8.65109 7.80081 8.5225 7.80081 8.39391V7.8667C7.80081 7.85384 7.80081 7.84098 7.80081 7.82812C6.84926 8.44535 6.15488 9.40976 5.85913 10.5285C5.962 10.5156 6.06487 10.5028 6.16774 10.5028C6.36062 10.5028 6.52779 10.5285 6.68209 10.5799Z"
          fill={fill}
        />
        <path
          d="M10.3213 6.31393C10.9462 6.31393 11.4528 5.80731 11.4528 5.18236C11.4528 4.5574 10.9462 4.05078 10.3213 4.05078C9.69632 4.05078 9.1897 4.5574 9.1897 5.18236C9.1897 5.80731 9.69632 6.31393 10.3213 6.31393Z"
          fill={fill}
        />
        <path
          d="M8.56047 8.50906H12.0709C12.1352 8.50906 12.1867 8.45762 12.1867 8.39333V7.86612C12.1867 7.30033 11.8137 6.8117 11.3123 6.64453C11.0294 6.83741 10.6822 6.95314 10.3093 6.95314C9.93636 6.95314 9.58918 6.83741 9.30628 6.64453C8.79193 6.82455 8.43188 7.30033 8.43188 7.86612V8.39333C8.44474 8.45762 8.49618 8.50906 8.56047 8.50906Z"
          fill={fill}
        />
        <path
          d="M7.18408 13.7344C6.90119 13.9273 6.554 14.0558 6.18109 14.0558C5.80819 14.0558 5.461 13.9401 5.17811 13.7344C4.67662 13.9144 4.30371 14.403 4.30371 14.9688V15.496C4.30371 15.5603 4.35515 15.6118 4.41944 15.6118H7.92989C7.99418 15.6118 8.04562 15.5603 8.04562 15.496V14.9688C8.05848 14.3902 7.69843 13.9144 7.18408 13.7344Z"
          fill={fill}
        />
        <path
          d="M6.18065 13.4155C6.8056 13.4155 7.31222 12.9089 7.31222 12.2839C7.31222 11.659 6.8056 11.1523 6.18065 11.1523C5.5557 11.1523 5.04907 11.659 5.04907 12.2839C5.04907 12.9089 5.5557 13.4155 6.18065 13.4155Z"
          fill={fill}
        />
        <path
          d="M15.4649 13.7344C15.182 13.9273 14.8348 14.0558 14.4619 14.0558C14.089 14.0558 13.7418 13.9401 13.459 13.7344C12.9575 13.9144 12.5974 14.403 12.5974 14.9688V15.496C12.5974 15.5603 12.6488 15.6118 12.7131 15.6118H16.2236C16.2879 15.6118 16.3393 15.5603 16.3393 15.496V14.9688C16.3393 14.3902 15.9793 13.9144 15.4649 13.7344Z"
          fill={fill}
        />
        <path
          d="M14.4614 13.4155C15.0864 13.4155 15.593 12.9089 15.593 12.2839C15.593 11.659 15.0864 11.1523 14.4614 11.1523C13.8365 11.1523 13.3298 11.659 13.3298 12.2839C13.3298 12.9089 13.8365 13.4155 14.4614 13.4155Z"
          fill={fill}
        />
        <path
          d="M10.3221 14.2398C11.6337 14.2398 12.6881 13.1854 12.6881 11.8738C12.6881 10.5622 11.6337 9.50781 10.3221 9.50781C9.01048 9.50781 7.95605 10.5622 7.95605 11.8738C7.95605 13.1854 9.01048 14.2398 10.3221 14.2398ZM10.0778 10.6522V10.3951C10.0778 10.3436 10.1292 10.2922 10.1806 10.2922H10.4764C10.5278 10.2922 10.5792 10.3436 10.5792 10.3951V10.6522C10.875 10.6908 11.1193 10.9223 11.145 11.2309C11.145 11.2566 11.1322 11.2823 11.1193 11.308C11.0936 11.3338 11.0679 11.3466 11.0422 11.3466H10.7464C10.695 11.3466 10.6564 11.308 10.6435 11.2695C10.6307 11.1923 10.5664 11.1409 10.4892 11.1409H10.2321C10.1035 11.1409 9.98774 11.2437 9.97489 11.3595C9.96203 11.4238 9.98774 11.5009 10.0392 11.5524C10.0906 11.6038 10.1549 11.6295 10.2192 11.6295H10.4378C10.6435 11.6295 10.8493 11.7195 10.9907 11.8738C11.1322 12.0281 11.1965 12.2339 11.1836 12.4396C11.1579 12.7611 10.9136 13.0183 10.5792 13.0826V13.3397C10.5792 13.3912 10.5278 13.4426 10.4764 13.4426H10.1806C10.1292 13.4426 10.0778 13.3912 10.0778 13.3397V13.0826C9.782 13.044 9.53769 12.8125 9.51197 12.5039C9.51197 12.4782 9.52483 12.4525 9.53769 12.4268C9.5634 12.401 9.58912 12.3882 9.61484 12.3882H9.91059C9.96203 12.3882 10.0006 12.4268 10.0135 12.4653C10.0263 12.5425 10.0906 12.5939 10.1678 12.5939H10.4249C10.5535 12.5939 10.6693 12.4911 10.6821 12.3753C10.695 12.2982 10.6693 12.2339 10.6178 12.1824C10.5664 12.131 10.5021 12.1053 10.4378 12.1053H10.2449C9.8463 12.1053 9.51197 11.8095 9.47339 11.4366C9.43482 11.0766 9.69199 10.7294 10.0778 10.6522Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

EnterpriseCompensationIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

EnterpriseCompensationIcon.defaultProps = {
  fill: "white",
  height: "19",
  width: "26",
};

export default EnterpriseCompensationIcon;
