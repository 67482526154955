import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../../messages";

function* addNewRole(action) {
  const { success, data, error } = yield* makeRequest.post("/roles", {
    name: action.payload.name,
    description: action.payload.description,
    parent_id: action.payload.parent,
    permissions: [],
  });

  if (success && data) {
    const roles = data.body.roles.map((role) => keysToCamelCase(role));
    const permissions = data.body.permissions.map((permission) => keysToCamelCase(permission));
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.ADD_NEW_ROLE_SUCCESS,
        }),
      ),
      put(actions.setRolesList({ roles, permissions })),
      put(actions.getUsersList({ filters: 1 })),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_ADDING_NEW_ROLE,
    });
  }

  return null;
}

export default addNewRole;
