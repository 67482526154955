import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import { cvar, mq } from "styles";

export const StyledTabs = styled(Tabs)(() => ({
  [mq.phoneOnly]: {
    margin: "14px 28px 8px 28px",
    minHeight: "42px",
  },
  [mq.tabletUp]: {
    margin: "0px 40px",
    minHeight: "48px",
  },

  "& .MuiTab-root": {
    minWidth: "fit-content",
  },
  "& .MuiButtonBase-root": {
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "17px",
    textTransform: "capitalize",
    fontWeight: "600",
    padding: "12px 0px",
    marginRight: "24px",
  },
  "& .Mui-selected": {
    color: `${cvar("blue-ribbon")} !important`,
  },
  "& .MuiTabs-indicator": {
    backgroundColor: cvar("blue-ribbon"),
  },
}));
