import React from "react";
import { useParams, useLocation } from "react-router";
import useNavigation from "hooks/useNavigation";
import InboxSidebar from "./inboxSidebar";

const Sidebar = (props) => {
  const { route } = props;

  const { tab } = useParams();
  const { search } = useLocation();
  const { basePath, requiredParams, optionalParams } = route;
  const { navigate, override } = useNavigation(basePath, requiredParams, optionalParams);

  return (
    <>
      <InboxSidebar navigate={navigate} override={override} search={search} activeTabKey={tab} />
    </>
  );
};

export default Sidebar;
