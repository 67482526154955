import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";
import moment from "moment";
import { DatePicker } from "antd";
import CalendarIconDates from "components/Icons/Calendars/CalendarIconDates";
import styles from "./dateRange.module.less";

const { RangePicker } = DatePicker;

const DateRangeNavigate = (props) => {
  const {
    navigate,
    label,
    beginKey,
    endKey,
    noLabel,
    pickerStyle,
    pickerProps,
    onSelect,
    noBorder,
    largeText,
    additionalNavigation,
    defaultTo,
    defaultFrom,
    futureDateDisabled,
    iconAsPrefix,
    allowClear,
  } = props;

  const location = useLocation();
  const search = parse(location.search);
  const queryStart = search[beginKey];
  const queryEnd = search[endKey];
  const dateFormat = "MM/DD/YY";
  const dateUrlFormat = "YYYY-MM-DD";
  const calendarIcon = <CalendarIconDates />;
  const parseDate = (dateStr) => {
    // check if moment generated from dateStr is (a) a valid date (b) is a past date.
    // return null if either false, return moment if a & b.
    const date = moment(dateStr, dateUrlFormat);
    return date.isValid() && date.isBefore(moment()) ? date : undefined;
  };

  const [from, setFrom] = useState(defaultFrom || null);
  const [to, setTo] = useState(defaultTo || null);

  const handleDatePickerChange = (dates, dateStrings) => {
    if (dates) {
      navigate({
        [beginKey]: dates[0].format(dateUrlFormat),
        [endKey]: dates[1].format(dateUrlFormat),
        ...additionalNavigation,
      });
      if (onSelect) {
        onSelect("from", dateStrings[0].format(dateUrlFormat));
        onSelect("to", dateStrings[1].format(dateUrlFormat));
      }
    } else {
      navigate({ [beginKey]: null, [endKey]: null });
    }
  };

  useEffect(() => {
    setFrom(parseDate(queryStart));
    setTo(parseDate(queryEnd));
  }, [queryStart, queryEnd]);

  const disabledDateToday = (current) => {
    if (futureDateDisabled) {
      return current && current > moment().endOf("day");
    }
    return false;
  };

  return (
    <div className={styles.dateRange}>
      {!noLabel && <div className={styles.label}>{label}</div>}

      <RangePicker
        allowClear={allowClear}
        className={[
          styles.rangePicker,
          ...(iconAsPrefix ? [styles.iconAtTheBegining, styles.activeBar] : [styles.standardViewPadding]),
          ...(noBorder ? [styles.noBorder] : []),
          ...(largeText ? [styles.largeText] : []),
        ].join(" ")}
        popupClassName={styles.calendar}
        onChange={handleDatePickerChange}
        value={[from, to]}
        format={dateFormat}
        separator="-"
        style={pickerStyle}
        disabledDate={disabledDateToday}
        suffixIcon={calendarIcon}
        ranges={{
          Today: [moment(), moment()],
          Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
          "This Week": [moment().startOf("week"), moment()],
          "Last Week": [moment().subtract(1, "weeks").startOf("week"), moment().subtract(1, "weeks").endOf("week")],
          "This Month": [moment().startOf("month"), moment()],
          "Last Month": [
            moment().subtract(1, "months").startOf("month"),
            moment().subtract(1, "months").endOf("month"),
          ],
          "Year To Date": [moment().startOf("year"), moment()],
        }}
        {...pickerProps}
      />
    </div>
  );
};

DateRangeNavigate.propTypes = {
  navigate: PropTypes.func,
  onSelect: PropTypes.func,
  label: PropTypes.string,
  beginKey: PropTypes.string,
  endKey: PropTypes.string,
  defaultTo: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  defaultFrom: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  noLabel: PropTypes.bool,
  pickerStyle: PropTypes.objectOf(PropTypes.string),
  // pass thru props to antd RangePicker component
  pickerProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  noBorder: PropTypes.bool,
  largeText: PropTypes.bool,
  additionalNavigation: PropTypes.objectOf(PropTypes.string),
  futureDateDisabled: PropTypes.bool,
  iconAsPrefix: PropTypes.bool,
  allowClear: PropTypes.bool,
};

DateRangeNavigate.defaultProps = {
  label: "Date Range",
  beginKey: "from",
  endKey: "to",
  noLabel: false,
  noBorder: false,
  largeText: false,
  iconAsPrefix: false,
  futureDateDisabled: true,
  pickerStyle: {},
  pickerProps: {},
  allowClear: false,
};

export default DateRangeNavigate;
