import styled from "@emotion/styled";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";

import { formHeaderStyles } from "../FormRadioGroup/styles";
import type { FormTextFieldProps } from "./index";

export const StyledFormLabel = styled(FormLabel)`
  ${formHeaderStyles};
`;

export const StyledTextField = styled(TextField)<Pick<FormTextFieldProps, "ht">>`
  height: ${({ ht }) => `${ht}px` || "auto"};
`;
