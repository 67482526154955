import React from "react";

import SessionClient from "util/SessionClient";
import { AlertIcon } from "components/Icons";

import * as S from "./styles";

const IntegrityViolationBanner = ({ bannerProps }) => {
  const session = new SessionClient();
  const { user } = session;
  const { days_remaining: daysRemaining } = bannerProps;

  const messageText = () => {
    if (daysRemaining === 0) {
      return "Complete the assigned Seller Integrity Modules immediately to regain access to your account.";
    }
    return `Complete the assigned Seller Integrity Modules within ${
      daysRemaining ? `${daysRemaining} days` : "7 days of violation"
    } or your account will be restricted.`;
  };

  return (
    <S.Container>
      <S.BannerColumn>
        <AlertIcon width={20} height={20} />
        <S.Text>{messageText()}</S.Text>
      </S.BannerColumn>
      <S.StyledButton onClick={() => user?.getLitmosLoginLink()}>Complete Now</S.StyledButton>
    </S.Container>
  );
};

export default IntegrityViolationBanner;
