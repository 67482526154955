import { put, select, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { keysToCamelCase } from "lib/helpers";
import messages from "../../messages";

function* createPhase(action) {
  const { workflowId, title, rank } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/workflows/${workflowId}/phases`, {
    title,
    rank,
  });
  if (success && data) {
    const out = keysToCamelCase(data.body.phase);
    const phases = yield select(selectors.getPhases);
    const workflow = yield select(selectors.getWorkflowDetails);
    yield all([
      put(actions.setPhases([...phases, out])),
      put(actions.setWorkflowDetails({ ...workflow, phases: [...workflow.phases, out] })),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_PHASE,
    });
  }
  return null;
}

export default createPhase;
