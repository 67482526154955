export const newFiltersPaths = [
  "/admin-projects/leads",
  "/admin-projects/pre-sales",
  "/admin-projects/post-sales",
  "/admin-projects/closed",
  "/projects/leads",
  "/projects/pre-sales",
  "/projects/post-sales",
  "/projects/completed",
  "/org-projects/pre-sales",
  "/org-projects/post-sales",
  "/org-projects/completed",
  "/team/consultants",
  "/org-team/users",
];
