/* eslint-disable @typescript-eslint/no-explicit-any */
import z from "zod";

type SchemaKeys<T extends z.ZodObject<any, any>> = keyof z.infer<T>;
type ExcludeKeys<T extends z.ZodObject<any, any>> = SchemaKeys<T>[];

export default class ZodSchemaUtil {
  static makePropertiesNullable<T extends z.ZodObject<any, any>>(schema: T, exclude?: ExcludeKeys<T>) {
    const { shape } = schema;
    const newShape = {};
    for (const key in shape) {
      if (exclude?.includes(key as ExcludeKeys<T>[number])) {
        newShape[key] = shape[key];
      } else {
        newShape[key] = z.nullable(shape[key]);
      }
    }
    return z.object(newShape) as T;
  }

  static makeStringLiteralUnion<T extends [string, ...string[]]>(values: T) {
    const literals = values.map((value) => z.literal(value)) as [
      z.ZodLiteral<string>,
      z.ZodLiteral<string>,
      ...z.ZodLiteral<string>[]
    ];

    return z.union(literals);
  }
}
