import { put, all } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToCamelCase } from "lib/helpers";

function* fetchAutoCompleteSuggestions(action) {
  const { url, search, formatData, errorMessage, additionalPayload, additionalReqParams, leadOwnerId } = action.payload;
  let { successActions } = action.payload;

  if (!url) {
    throw new Error("Must provide URL for fetch request.");
  }

  if (!search) {
    throw new Error("Search parameter is required for fetch request.");
  }

  if (!successActions || !Array.isArray(successActions) || successActions.length === 0) {
    successActions = () => {
      console.warn(
        "No success callback actions specified for this request; as a result, the response data will not be saved in Redux store.",
      );
      return [];
    };
  }

  const { success, data, error } = yield* makeRequest.get(url, {
    q: search,
    lead_owner_id: leadOwnerId,
    ...additionalReqParams,
  });

  if (success && data) {
    const users = _.find(data.body, (item) => _.isArray(item));
    let suggestions;
    if (users) {
      suggestions = users.map((u) => keysToCamelCase(u));
    }

    if (formatData) {
      suggestions = formatData(suggestions);
    }

    yield all(successActions.map((a) => put(a({ suggestions, ...additionalPayload }))));
  } else {
    return getErrorActions({
      error,
      message: errorMessage || "An error occurred fetching autocomplete suggestions",
    });
  }
  return null;
}

export default fetchAutoCompleteSuggestions;
