// 01/01/2021
import SessionClient from "util/SessionClient";

export default function userTimezone() {
  const session = new SessionClient();

  if (session.user) {
    return session.user.props.timezone;
  }
  return "America/Los_Angeles";
}
