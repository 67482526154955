import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import SessionClient from "util/SessionClient";

const session = new SessionClient();

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_POWUR_API_URL}/json_web`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json; charset=utf-8");
      headers.set("Accept", "*/*");
      if (session.jwt) {
        headers.set("Authorization", `Bearer ${session.jwt}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
