import React from "react";
import { IconT } from "types/utils";
import { theme } from "styles/themes";

const DiagonalRightArrow = ({ height = 15, width = 16, fill = theme.colors["tango-orange"] }: IconT) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transition: ".2s all" }}
    >
      <path
        d="M1.10566 13.3337C0.812763 13.6266 0.812763 14.1015 1.10566 14.3943C1.39855 14.6872 1.87342 14.6872 2.16632 14.3943L1.10566 13.3337ZM15.1139 1.13609C15.1139 0.721878 14.7781 0.386092 14.3639 0.386091L7.61391 0.386092C7.19969 0.386092 6.86391 0.721878 6.86391 1.13609C6.86391 1.55031 7.19969 1.88609 7.61391 1.88609L13.6139 1.88609L13.6139 7.88609C13.6139 8.30031 13.9497 8.63609 14.3639 8.63609C14.7781 8.63609 15.1139 8.30031 15.1139 7.88609L15.1139 1.13609ZM2.16632 14.3943L14.8942 1.66642L13.8336 0.605762L1.10566 13.3337L2.16632 14.3943Z"
        fill={fill}
      />
    </svg>
  );
};

export default DiagonalRightArrow;
