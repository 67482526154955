export default {
  GET_LEADS_REQUEST: "[leads] GET Leads Request",

  SET_LEADS: "[leads] SET Leads",

  RESET_LEADS: "[leads] RESET Leads",

  GET_EVENTS_REQUEST: "[leads] GET Events Request",

  SET_EVENTS: "[leads] SET Events",

  GET_LEAD_DETAILS_REQUEST: "[leads] GET Lead Details Request",

  SET_LEAD_DETAILS: "[leads] SET Lead Details",

  CREATE_NEW_LEAD: "[leads] Create New Lead",

  SUBMIT_LEAD_TO_PROVIDER: "[leads] Submit Lead To Provider",

  UPDATE_LEAD: "[leads] Update Lead",

  UPDATE_LEAD_UTILITY_BILL: "[leads] Update Lead Utility Bill",

  GET_LEAD_ADDRESS_GEOCODE: "[leads] GET Lead Address Geocode",

  SET_LEAD_ADDRESS_GEOCODE: "[leads] SET Lead Address Geocode",

  DELETE_LEAD_REQUEST: "[leads] Delete Lead Request",

  SAVE_AND_SUBMIT_LEAD_REQUEST: "[leads] Save And Submit Lead Request",

  RESET_LEAD_DETAILS: "[leads] Reset Lead Details",

  CREATE_NEW_PUBLIC_LEAD_REQUEST: "[leads] Create New Public Lead Request",

  GET_LEADS_CSV: "[leads] GET Leads Csv",

  SET_LEADS_CSV: "[leads] SET Leads Csv",

  GET_LEADS_CSV_REPORT: "[leads] GET Leads Csv Report",

  DELETE_HOMEOWNER_AVAILABILITY: "[leads] Delete Homeowner Availability",

  SET_UTILITY_COMPANIES: "[leads] SET Utility Companies",
  GET_UTILITY_COMPANIES: "[leads] GET Utility Companies",

  GET_HOMEOWNER_NOTES: "[leads] GET Homeowner Notes",
  SET_HOMEOWNER_NOTES: "[leads] SET Homeowner Notes",
  RESET_HOMEOWNER_NOTES: "[leads] Reset Homeowner Notes",

  CLOSE_LEAD_REQUEST: "[leads] Close Lead Request",
  REOPEN_LEAD_REQUEST: "[leads] Reopen Lead Request",

  CONFIRM_SITE_LOCATION: "[leads] Confirm Site Location",
  SET_LOCATION_CONFIRMED: "[leads] Set site location confirmed",
  SET_LEAD_SUBMITTED: "[leads] Set lead submitted",
  UPDATE_LEAD_OWNER: "[leads] Update Lead Owner",
  CREATE_LEAD_ASSET: "[leads] Create Lead Asset",
  SET_LEAD_ASSETS: "[leads] Set Lead Assets",
  // INSERTION_PT (for script, do not remove)
};
