import React from "react";
import PropTypes from "prop-types";
import { CheckCircleTwoTone, FileImageTwoTone } from "@ant-design/icons";
import { Button, Progress, Spin, Typography } from "antd";
import ReactS3Uploader from "react-s3-uploader";
import FormErrorMessage from "components/FormElements/FormErrorMessage";
import ApiClient from "util/ApiClient";
import { bytesToSize, fileTypesToPlainText } from "lib/helpers";
import styles from "../FileUploader.module.less";

const { Text } = Typography;

class FileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploaderReady: false,
      uploaderStatus: "loading",
      uploaderConfigData: {},
      uploaderProgress: null,
    };
    this.api = new ApiClient({ host: process.env.REACT_APP_POWUR_API_URL });
  }

  setUploaderReady() {
    if (!this.props.containerProps?.disabled) {
      this.api.get("/uploader_config", { mode: this.props.apiMode }).then(
        (res) => {
          if (!this.props.containerProps?.disabled) {
            this.setState({
              uploaderReady: true,
              uploaderStatus: "ready",
              uploaderConfigData: res,
            });
          }
        },
        (res) => {
          console.warn(res.error);
        },
      );
    }
  }

  componentDidMount() {
    this.setUploaderReady();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.containerProps.disabled !== this.props.containerProps.disabled) {
      this.setUploaderReady();
    }
  }

  onUploadError = (_message) => {
    this.props.setLoading(false);
    this.setState({ uploaderStatus: "error" });
  };

  onUploadFinish = ({ willBeUrl }) => {
    this.props.setLoading(false);
    this.setState({ uploaderStatus: "finished" });
    this.props.onFinished(willBeUrl);
  };

  onUploadProgress = (percent, _message, _file) => {
    this.setState({ uploaderProgress: percent, uploaderStatus: "inProgress" });
  };

  onUploadStart = (file, next) => {
    this.props.setLoading(true);
    next(file);
  };

  getSignedUrl = () => {
    return (file, callback) => {
      this.api
        .get("/uploader_config", {
          mode: this.props.apiMode,
          filename: file.name,
        })
        .then(
          (res) => {
            callback({ signedUrl: res.signed_url, willBeUrl: res.will_be });
          },
          (res) => {
            console.warn(res.error);
          },
        );
    };
  };

  finishedText = () => {
    if (this.props.multiple) {
      return (
        <>
          <Text className={styles.direction}>Click to upload another file ({this.fileTypesToPlainText()})</Text>
          <Text className={styles.disclaimer}>Max file size: {bytesToSize(this.state.uploaderConfigData.max)}</Text>
        </>
      );
    }

    return <Text className={styles.direction}> File uploaded successfully </Text>;
  };

  render() {
    const uploaderContents = {
      ready: (
        <>
          <FileImageTwoTone className={styles.icon} />
          <div className={styles.textBox}>
            <Text className={styles.direction}>
              Click to select a file ({fileTypesToPlainText(this.state.uploaderConfigData.accept)})
            </Text>
            <Text className={styles.disclaimer}>Max file size: {bytesToSize(this.state.uploaderConfigData.max)}</Text>
          </div>
        </>
      ),
      loading: <Spin />,
      inProgress: (
        <Progress style={{ width: "80%" }} percent={this.state.uploaderProgress} size="small" status="active" />
      ),
      finished: (
        <>
          <CheckCircleTwoTone className={styles.icon} />
          <div className={styles.textBox}>{this.finishedText()}</div>
        </>
      ),
      error: <FormErrorMessage message="An error occurred uploading your file." />,
    };

    const renderUploaderIfReady = this.state.uploaderReady ? (
      <>
        <Button className={styles.uploadButton} {...this.props.containerProps}>
          <div className={styles.contents}>
            {this.props.customButtonContents[this.state.uploaderStatus]
              ? this.props.customButtonContents[this.state.uploaderStatus]
              : uploaderContents[this.state.uploaderStatus]}
          </div>
        </Button>
        <ReactS3Uploader
          className={styles.upload}
          getSignedUrl={this.getSignedUrl()}
          s3path={this.state.uploaderConfigData.s3_path}
          accept={this.state.uploaderConfigData.accept}
          uploadRequestHeaders={this.state.uploaderConfigData.headers}
          preprocess={this.onUploadStart}
          onProgress={this.onUploadProgress}
          onFinish={this.onUploadFinish}
          onError={this.onUploadError}
        />
      </>
    ) : (
      <>
        <Button className={styles.uploadButton} {...this.props.containerProps}>
          <div className={styles.contents}>
            {this.props.customButtonContents.loading
              ? this.props.customButtonContents.loading
              : uploaderContents.loading}
          </div>
        </Button>
      </>
    );

    return (
      <div style={{ height: this.props.height }} className={styles.uploadWrapper}>
        {renderUploaderIfReady}
      </div>
    );
  }
}

FileUploader.propTypes = {
  apiMode: PropTypes.string.isRequired,
  customButtonContents: PropTypes.shape({
    ready: PropTypes.node,
    loading: PropTypes.node,
    inProgress: PropTypes.node,
    finished: PropTypes.node,
    error: PropTypes.node,
  }),
  // pass thru any props
  containerProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  height: PropTypes.string,
  multiple: PropTypes.bool,
  setLoading: PropTypes.func,
  onFinished: PropTypes.func.isRequired,
};

FileUploader.defaultProps = {
  customButtonContents: {
    ready: false,
    loading: false,
    inProgress: false,
    finished: false,
    error: false,
  },
  containerProps: {
    disabled: false,
  },
  height: "150px",
  setLoading() {
    return null;
  },
};

export default FileUploader;
