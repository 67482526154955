import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/projects/messages";
import { camelizeKeys, keysToSnakeCase } from "lib/helpers";
import SchemaValidator from "util/schema-helpers/SchemaValidator";
import { CreateTpoPricingWorksheetResponse } from "../schemas/projects/post-sales";

function* createTpoPricingWorksheet(action) {
  const { projectId, cloneId, openAfterCreating } = action.payload;

  const params = { cloneId };

  const { data, error } = yield* makeRequest.post(
    `/provider_leads/${projectId}/tpo_pricing_worksheets`,
    keysToSnakeCase(params),
  );

  const result = SchemaValidator.validate<typeof CreateTpoPricingWorksheetResponse>({
    schema: CreateTpoPricingWorksheetResponse,
    schemaName: "CreateTpoPricingWorksheetResponse",
    dto: camelizeKeys(data),
  });

  if (result.success && result.data) {
    yield all([
      put(actions.setTpoPricingWorksheet(camelizeKeys(result.data.body))),
      put(actions.getTpoPricingWorksheets({ projectId })),
      put(
        actions.setAlertMessageVisible({
          message: messages.CREATE_TPO_WORKSHEET_SUCCESS,
          severity: "success",
        }),
      ),
      put(actions.newSuccessEvent({ message: messages.CREATE_TPO_WORKSHEET_SUCCESS })),
    ]);
    if (openAfterCreating) {
      window.open(result.data.body.worksheetUrl, "_blank");
    }
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_TPO_WORKSHEET,
    });
  }

  return null;
}

export default createTpoPricingWorksheet;
