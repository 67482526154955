import React from "react";
import { useTranslation } from "react-i18next";
import videosList from "lib/misc/homepageVideos";
import useModalControls from "hooks/useModalControls";
import VideoModal from "components/Modals/VideoModal";
import { buttonProps } from "lib/props";
import styles from "../../FAQ.module.less";

const VideoCards = () => {
  return (
    <div className={styles.videoCardsContainer}>
      <div className={styles.inner}>
        {videosList.map((v) => (
          <VideoCard
            key={v.number}
            // title={v.title}
            trTitleKey={v.trTitleKey}
            image={v.image}
            src={v.src}
          />
        ))}
      </div>
    </div>
  );
};

const VideoCard = ({ trTitleKey, image, src }) => {
  const { t } = useTranslation("faq");
  const { visible, open, close } = useModalControls();

  const handleClick = () => {
    open();
  };

  return (
    <>
      <div className={[styles.videoCard, styles.bgDarkenZoomOnHover].join(" ")} {...buttonProps(handleClick)}>
        <div className={[styles.image, styles.bg].join(" ")} style={{ background: `url('${image}')` }}>
          <div className={styles.overlay} />
        </div>
        <div className={styles.cta}>
          <div className={styles.logo} />
          <div className={styles.line} />
          <span className={styles.text}>{t(trTitleKey)}</span>
        </div>
      </div>
      <VideoModal visible={visible} open={open} close={close} videoSourceUrl={src} title={t(trTitleKey)} />
    </>
  );
};

export default VideoCards;
