import { z } from "zod";

export const languageOpts = [
  { label: "No Preference", value: "" },
  { label: "English", value: "en" },
  { label: "Spanish", value: "es" },
];

export const presentationOpts = [
  { label: "No Preference", value: "" },
  { label: "In Person", value: "in_person" },
  { label: "Virtual", value: "online" },
];

export const teamSearchOpts = [
  { label: "No Preference", value: "" },
  { label: "My Leadership Team", value: "upline" },
  { label: "My Selling Team", value: "downline" },
  { label: "My Whole Team", value: "both" },
];

export const availableSortOpts = [
  "rating",
  "name",
  "project_count",
  "qualify_count",
  "contract_count",
  "install_count",
  "mentor_project_count",
  "mentor_qualify_count",
  "mentor_contract_count",
  "mentor_install_count",
];

export const schema = z.object({
  q: z.string(),
  service_area: z.string(),
  spoken_languages: z.string(),
  appt_location: z.string(),
  team_search: z.string(),
  sort: z.string(),
  certification: z.string(),
});

export type SearchT3PartnerFormSchemaT = z.infer<typeof schema>;

export const blankFormData = {
  q: "",
  service_area: "",
  spoken_languages: "",
  appt_location: "",
  team_search: "",
  sort: "",
  certification: "",
};
