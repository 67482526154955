import { put, all, select } from "redux-saga/effects";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToSnakeCase, camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* createCustomField(action) {
  const { params } = keysToSnakeCase(action.payload);
  const { enterpriseId } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/enterprises/${enterpriseId}/lead_custom_fields`, params);

  if (success && data) {
    const customFields = yield select(selectors.selectCustomFields);

    const updatedCustomFields = [...customFields, data.body];

    yield all([
      yield put(actions.setCustomFields(camelizeKeys(updatedCustomFields))),
      put(
        actions.newSuccessEvent({
          message: messages.CREATE_CUSTOM_FIELD_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.CREATE_CUSTOM_FIELD_ERROR,
    });
  }
  return null;
}

export default createCustomField;
