import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import dayjs from "dayjs";
import actions from "rdx/actions";
import messages from "rdx/modules/earnings/messages";
import { camelizeKeys } from "lib/helpers";

function* getMonthlyBreakdown12Mos() {
  const params = {
    from: dayjs().subtract(11, "month").startOf("month").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  };

  const { success, data, error } = yield* makeRequest.get("/dashboard_earnings", params);

  if (success && data) {
    yield put(actions.setMonthlyBreakdown12Mos(camelizeKeys(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_MONTHLY_BREAKDOWN_12_MOS,
    });
  }

  return null;
}

export default getMonthlyBreakdown12Mos;
