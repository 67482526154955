import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

export default {
  mentorRating: createReducer(null, {
    [types.SET_MENTOR_RATING](state, action) {
      return action.payload;
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};
