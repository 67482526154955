import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../../messages";

function* getRolesList(action) {
  const params = action.payload;
  const { success, data, error } = yield* makeRequest.get("/roles", params);
  if (success && data) {
    const roles = data.body.roles.map((role) => keysToCamelCase(role));

    // TODO: This needs to go... should only live in BE... just update the names/descriptions!
    const regEx = /(?:^|\W)mentor(s\b|\b)(?:$|\W)/i;
    const updatedRoles = roles.map((role) => {
      if (role.description.includes("mentor") || role.description.includes("Mentors")) {
        const description = role.description.replace(regEx, " sales partner ");
        return {
          ...role,
          description,
        };
      }
      return role;
    });

    const permissions = data.body.permissions.map((permission) => keysToCamelCase(permission));
    yield put(actions.setRolesList({ roles: updatedRoles, permissions }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_ROLES_LIST,
    });
  }
  return null;
}

export default getRolesList;
