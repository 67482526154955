import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/buildPartnerApplications/messages";
import { camelizeKeys } from "lib/helpers";

function* getBuildPartnerApplicationDetail(action) {
  const { id } = action.payload;

  const { success, data, error } = yield* makeRequest.get(`/labor_partner_applications/${id}`);

  if (success && data) {
    yield all([
      put(
        actions.setBuildPartnerApplicationDetail({
          ...camelizeKeys(data.body),
          serviceAreas: data.body.service_areas,
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.GET_BUILD_PARTNER_APPLICATION_DETAIL_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_BUILD_PARTNER_APPLICATIONS,
    });
  }

  return null;
}

export default getBuildPartnerApplicationDetail;
