import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "antd";
import _ from "lodash";

import User from "models/User";

import Filters from "components/PlatformLayout/components/Sidebar/Filters";
import MultiSelect from "components/PlatformLayout/components/Sidebar/MultiSelect";
import SearchBar from "components/PlatformLayout/components/Sidebar/Search";
import ResetFiltersButton from "@buttons/ResetFilters";

const UsersSidebar = (props) => {
  const { navigate, override, filters, loading, collapsedView } = props;

  const roleItem = {
    key: "role",
    label: "User Role",
    options: [
      {
        key: "any-role",
        label: "All Roles",
        default: true,
        index: 0,
        parentKey: "role",
      },
    ],
  };

  const locationItem = {
    key: "location",
    label: "Location",
    options: [
      {
        key: "any-location",
        label: "Any Location",
        default: true,
        index: 1,
        parentKey: "location",
      },
    ],
  };

  const EnrollmentSelect = {
    key: "enrollment",
    label: "Enrollment Status",
    options: [],
  };

  const mentorRatingItem = {
    key: "mentor_rating",
    label: "Sales Partner Rating",
    options: [
      {
        key: "any-mentorRating",
        label: "All",
        default: true,
        index: 1,
        parentKey: "mentor_rating",
      },
    ],
  };

  const sortItem = {
    key: "sort",
    label: "Sort Type",
    options: [
      {
        key: "name",
        label: "Sort By Name",
        default: true,
        index: 2,
        parentKey: "sort",
      },
    ],
  };

  const payEligibilityItem = {
    key: "pay_eligibilities",
    label: "User Pay Eligibility",
    options: [
      {
        key: "any-pay-eligibility",
        label: "All",
        default: true,
        index: 3,
        parentKey: "pay_eligibilities",
      },
    ],
  };

  const menus = [roleItem, locationItem, mentorRatingItem, payEligibilityItem, ...(collapsedView ? [sortItem] : [])];

  if (filters.roles) {
    menus[0].options.push(
      ...filters.roles.map((role) => ({
        key: role.name,
        label: role.description,
        default: false,
        index: 0,
        parentKey: "role",
      })),
    );
  }

  if (filters.pay_eligibilities) {
    menus[3].options.push(
      ...filters.pay_eligibilities.map((eligibility) => ({
        key: eligibility.slug,
        label: eligibility.name,
        index: 3,
        default: false,
        parentKey: "pay_eligibilities",
      })),
    );
  }
  if (filters.states) {
    menus[1].options.push(
      ...filters.states.map((state) => ({
        key: Object.keys(state)[0],
        label: Object.values(state)[0],
        default: false,
        index: 1,
        parentKey: "location",
      })),
    );
  }

  if (filters.mentor_rating) {
    menus[2].options.push(
      ...filters.mentor_rating.map((rating) => ({
        key: rating,
        label: `${rating[0]}-Star Avg Rating`,
        default: false,
        index: 2,
        parentKey: "mentor_rating",
      })),
    );
  }

  if (filters.metrics && collapsedView) {
    const select = ["team_count", "recruit_count", "project_count", "install_count", "latest_install"];
    menus[3].options.push(
      ...filters.metrics
        .filter((metric) => select.includes(metric))
        .map((metric) => ({
          key: metric,
          label: `Performance: ${_.startCase(metric)}`,
          default: false,
          index: 2,
          parentKey: "sort",
        })),
    );
  }
  if (filters.enrollment) {
    EnrollmentSelect.options.push(
      ...filters.enrollment.map((type) => ({
        key: type,
        label: type.replaceAll("_", " "),
        default: false,
        index: 2,
        parentKey: "enrollment",
      })),
    );
  }

  return (
    <>
      <SearchBar navigate={navigate} />
      <Skeleton active paragraph={{ rows: 5, width: "100%" }} title={false} round loading={!!loading}>
        <Filters menus={menus} navigate={navigate} override={override} />
        <MultiSelect menu={EnrollmentSelect} navigate={navigate} override={override} showSelectedValues />
        <ResetFiltersButton />
      </Skeleton>
    </>
  );
};

UsersSidebar.propTypes = {
  navigate: PropTypes.func.isRequired,
  override: PropTypes.func.isRequired,
  filters: User.filterTypes(),
  loading: PropTypes.bool,
  collapsedView: PropTypes.bool,
};

export default UsersSidebar;
