import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/platform/messages";
import { camelizeKeys } from "lib/helpers";

function* getReminderNotificationsCount() {
  const { success, data, error } = yield* makeRequest.get("/reminder_notifications/count");

  if (success && data) {
    const out = camelizeKeys(data.body);

    yield all([put(actions.setReminderNotificationsCount(out))]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_REMINDER_NOTIFICATIONS_COUNT,
    });
  }

  return null;
}

export default getReminderNotificationsCount;
