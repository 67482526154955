import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Input, Divider, Radio } from "antd";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import { buttonProps } from "lib/props";
import Registration from "models/Registration";
import MessageEvent from "models/MessageEvent";
import useLoading from "@loading";
import types from "rdx/modules/registration/types";
import messages from "rdx/modules/registration/messages";
import GradientButton from "components/Buttons/GradientButton";
import SocialLoginButton from "components/Buttons/SocialButtons";

import styles from "./EmailVerification.module.less";

const EmailVerification = ({
  handleContinue,
  registerNewUser,
  regInfo,
  sponsor,
  latestMessage,
  clearLatestMessage,
  setPasswordCreated,
  updateRegistration,
  userWasInvited,
  setUsedOauthValidation,
  setAlertMessageVisible,
}) => {
  const { t, i18n } = useTranslation(["join", "form_labels"]);
  const history = useHistory();
  const location = useLocation();
  const [kind, setKind] = useState("consultant");
  const [manualKindSelection, setManualKindSelection] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [emailForm] = Form.useForm();
  const [locale, setLocale] = useState("en");
  const [socialProviderData, setSocialProviderData] = useState({});

  const loading = useLoading({
    watchRequests: [types.REGISTER_NEW_USER, types.UPDATE_REGISTRATION],
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("account-type")) {
      const kindId = queryParams.get("account-type");
      if (kindId === "9") setKind("enterprise");
      setManualKindSelection(true);
    }
    if (userWasInvited) {
      emailForm.setFieldsValue({ email: regInfo.email });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLocale(i18n.language);
  }, [i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (latestMessage.message === messages.REGISTER_NEW_USER_SUCCESS) {
      if (socialProviderData?.provider === "facebook") {
        if (regInfo?.slug) {
          updateRegistration({
            firstName: socialProviderData.firstName,
            lastName: socialProviderData.lastName,
            email: socialProviderData.email,
            slug: regInfo.slug,
            ...(userWasInvited ? { kind } : {}),
          });
        }
      }
      if (socialProviderData?.provider === "google") {
        return handleContinue();
      }
      setEmailSubmitted(true);
    }

    if (latestMessage.message === messages.ERROR_REGISTERING_NEW_USER) {
      setAlertMessageVisible({
        message: latestMessage.error.message,
        severity: "error",
      });
      setTimeout(() => {
        setAlertMessageVisible({
          message: "Redirecting...",
          severity: "info",
        });
      }, 2000);

      setTimeout(() => {
        history.push("/login");
      }, 3400);
    }

    if (latestMessage.message === messages.REGISTRATION_UPDATE_SUCCESS) {
      return handleContinue();
    }

    return () => clearLatestMessage();
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const facebookLogout = () => {
    window.FB.getLoginStatus(() => {
      window.FB.logout(() => {
        window.location.reload();
      });
    });
  };

  const handleSocialLogin = ({ data, provider }) => {
    if (!data.email && provider === "facebook") {
      setAlertMessageVisible({
        message: t("join_form.facebook_email_required"),
        severity: "warning",
      });
      setTimeout(() => {
        facebookLogout();
      }, 2000);
      return;
    }

    const googleProvider = provider === "google";
    const token = googleProvider ? data.access_token : data.accessToken;

    if (googleProvider) {
      setSocialProviderData({ provider });
    } else {
      setSocialProviderData({
        provider,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
      });
    }

    setPasswordCreated(true);
    setUsedOauthValidation(true);

    if (userWasInvited) {
      updateRegistration({
        authFormat: provider,
        token,
        slug: regInfo.slug,
        kind,
      });
    } else {
      registerNewUser({
        authFormat: provider,
        manualKindSelection,
        kind,
        token,
        locale,
        sponsor,
      });
    }
  };

  const onEmailSubmit = (v) => {
    if (userWasInvited) {
      updateRegistration({
        slug: regInfo.slug,
        kind,
      });
    } else {
      registerNewUser({
        authFormat: "password",
        sponsor,
        manualKindSelection,
        kind,
        locale,
        ...v,
      });
    }
  };

  const handleLogin = () => {
    history.push("/login");
  };

  const handleChangeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
    setLocale(e.target.value);
  };

  return (
    <div className={styles.loginCredContainer} data-test-id="login-credentials-form">
      {!emailSubmitted ? (
        <>
          <div className={styles.verificationLabel}>{t("join_form.email_verify")}</div>
          <div className={styles.emailForm}>
            <Form onFinish={onEmailSubmit} form={emailForm}>
              <Form.Item>
                <Form.Item
                  name="email"
                  noStyle
                  validateTrigger={["onSubmit"]}
                  rules={[
                    {
                      required: true,
                      message: t("form_labels:valid_email"),
                    },
                    {
                      type: "email",
                      message: t("form_labels:valid_email"),
                    },
                  ]}
                  initialValue={regInfo.email}
                >
                  <Input
                    placeholder={t("join_form.email_placeholder")}
                    disabled={userWasInvited}
                    data-test-id="signupEmailInput"
                  />
                </Form.Item>
                <div style={{ display: "inline-block" }}>
                  <GradientButton
                    type="primary"
                    htmlType="submit"
                    disabled={!!loading}
                    className={styles.emailSubmitButton}
                    data-test-id="emailSubmitButton"
                  >
                    GO
                  </GradientButton>
                </div>
              </Form.Item>
            </Form>
          </div>
          <div className={styles.otherOptions}>
            <div className={styles.emailVerifyDivider}>
              <Divider>OR</Divider>
            </div>
            <div className={styles.altVerifyButton}>
              <LoginSocialFacebook appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID} onResolve={handleSocialLogin}>
                <SocialLoginButton provider="facebook">Sign up with Facebook</SocialLoginButton>
              </LoginSocialFacebook>
            </div>
            <div className={styles.altVerifyButton}>
              <LoginSocialGoogle
                client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                onResolve={handleSocialLogin}
                scope="email https://www.googleapis.com/auth/userinfo.email"
              >
                <SocialLoginButton provider="google">Sign up with Google</SocialLoginButton>
              </LoginSocialGoogle>
            </div>
            <div className={styles.languagePreferenceContainer}>
              <div className={styles.languagePreferenceLabel}>{t("join_form.lang_preference")}</div>
              <Radio.Group onChange={handleChangeLanguage} value={locale}>
                <Radio value="en">English</Radio>
                <Radio value="es">Espa&ntilde;ol</Radio>
              </Radio.Group>
            </div>
            <div className={styles.existingAcct}>
              <span {...buttonProps(handleLogin)} className={styles.linkToLogin}>
                {t("join_form.existing_acct")}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.linkSentContainer}>
          <div className={styles.linkSent}>{t("join_form.email_sent")}</div>
          <div className={styles.emailVerifyDivider}>
            <Divider />
          </div>
          <div className={styles.linkInstructions}>{t("join_form.click_link")}</div>
        </div>
      )}
    </div>
  );
};

EmailVerification.propTypes = {
  handleContinue: PropTypes.func,
  registerNewUser: PropTypes.func,
  sponsor: PropTypes.string,
  getRegistration: PropTypes.func,
  setPasswordCreated: PropTypes.func,
  regInfo: Registration.types(),
  latestMessage: MessageEvent.types(),
  clearLatestMessage: PropTypes.func,
  updateRegistration: PropTypes.func,
  userWasInvited: PropTypes.bool,
  setUsedOauthValidation: PropTypes.func,
  setAlertMessageVisible: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    requests: "getActiveRequests",
    latestMessage: "getLatestMessageEvt",
  }),
  getRdxActionMapper(["registerNewUser", "clearLatestMessage", "updateRegistration", "setAlertMessageVisible"]),
)(EmailVerification);
