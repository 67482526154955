import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { marketingApi } from "./endpoints";
import { MarketingResponseBody } from "./marketingTypes";

const initialState: MarketingResponseBody = {
  facebookPixelId: null,
  pages: [],
};

export const marketingSlice = createSlice({
  name: "marketing",
  initialState,
  reducers: {
    setMarketing: (state, { payload }: PayloadAction<MarketingResponseBody>) => {
      return payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(marketingApi.endpoints?.getMarketing.matchFulfilled, (_, { payload }) => {
      return payload;
    });

    builder.addMatcher(marketingApi.endpoints?.updateFacebookPixel.matchFulfilled, (state, { payload }) => {
      state.facebookPixelId = payload.facebookPixelId;
    });
  },
  selectors: {
    selectMarketing: (state) => state,
  },
});

export const { setMarketing } = marketingSlice.actions;
export const { selectMarketing } = marketingSlice.selectors;

export default marketingSlice;
