import React from "react";
import { useTranslation } from "react-i18next";
import { CheckmarkHouseIcon, HandshakeDealIcon, BuildTeamIcon } from "components/Icons";

import * as S from "./styles";

const EarningInfographic = () => {
  const { t }: { t: (textRef: string) => string } = useTranslation(["setter_join"]);

  return (
    <S.InfographicContainer>
      <S.Row>
        <S.IconCol>
          <CheckmarkHouseIcon />
        </S.IconCol>
        <S.TextCol>{t("earn_infographic.set_appts")}</S.TextCol>
      </S.Row>
      <S.Divider />
      <S.Row>
        <S.IconCol>
          <HandshakeDealIcon />
        </S.IconCol>
        <S.TextCol>{t("earn_infographic.close_deals")}</S.TextCol>
      </S.Row>
      <S.Divider />
      <S.Row>
        <S.IconCol>
          <BuildTeamIcon />
        </S.IconCol>
        <S.TextCol>{t("earn_infographic.build_team")}</S.TextCol>
      </S.Row>
    </S.InfographicContainer>
  );
};

export default EarningInfographic;
