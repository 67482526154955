// import { put } from "redux-saga/effects";

// import makeRequest from "rdx/utils/makeRequest";
// import getErrorActions from "rdx/utils/getErrorActions";
// import actions from "rdx/actions";

function* deleteComplianceIssue() {
  // no endpoint yet 2/17
}

export default deleteComplianceIssue;
