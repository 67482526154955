import React, { useState } from "react";
import PropTypes from "prop-types";
import { Select, Input } from "antd";
import { AuditOutlined } from "@ant-design/icons";
import ProviderLead from "models/ProviderLead";
import Project from "models/Project";
import ModalContainer from "components/Modals/ModalContainer";
import styles from "./CloseProject.module.less";

const { Option } = Select;
const { TextArea } = Input;

const CloseProjectModal = (props) => {
  const { providerLead, visible, setVisible, closeReasons, updateClosedProjects } = props;
  const [selectedReason, setSelectedReason] = useState("");
  const [selectError, setSelectError] = useState(false);
  const [closeNote, setCloseNote] = useState("");

  const createCloseOptions = (arr) => {
    if (!arr) return [];
    return arr.map((o) => {
      const { id, description } = o.class;
      return (
        <Option key={id} value={id}>
          {description}
        </Option>
      );
    });
  };

  const handleCancel = () => {
    setSelectError(false);
    setSelectedReason("");
    setCloseNote("");
    setVisible(false);
  };

  const handleSelect = (option) => {
    setSelectedReason(option);
    if (option) {
      setSelectError(false);
    }
  };

  const handleSubmit = () => {
    if (!selectedReason) {
      setSelectError(true);
    } else {
      providerLead.close(selectedReason, closeNote);
      setVisible(false);
      updateClosedProjects(providerLead?.props.id);
    }
  };

  return (
    <>
      <ModalContainer
        visible={visible}
        headerIcon={<AuditOutlined className={styles.blue} />}
        title="Close Project"
        handleSubmit={handleSubmit}
        submitText="Confirm"
        handleCancel={handleCancel}
        zIndex={1002}
      >
        <div className={styles.closeProjectModal}>
          <div className={styles.itemContainer}>
            <div className={styles.label}>Please select a reason for closing this project</div>
            <div className={styles.selectContainer}>
              <Select className={styles.select} value={selectedReason} onSelect={handleSelect}>
                {createCloseOptions(closeReasons)}
              </Select>
            </div>
            {selectError && (
              <div className={styles.error}>Please select a reason from the dropdow above before clicking confirm</div>
            )}
          </div>

          <div className={styles.itemContainer}>
            <div className={styles.label}>
              Provide any additional details about why this project is being closed (optional)
            </div>
            <div className={styles.selectContainer}>
              <TextArea
                className={styles.textArea}
                value={closeNote}
                placeholder="(max 300 characters)"
                onChange={(e) => {
                  setCloseNote(e.target.value);
                }}
                autoSize={{ minRows: 3 }}
                maxLength={300}
              />
            </div>
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

CloseProjectModal.default = {
  updateClosedProjects: () => null,
};

CloseProjectModal.propTypes = {
  providerLead: PropTypes.oneOfType([PropTypes.instanceOf(Project), PropTypes.instanceOf(ProviderLead)]),
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  updateClosedProjects: PropTypes.func,
  closeReasons: PropTypes.arrayOf(
    PropTypes.shape({
      class: PropTypes.shape({
        id: PropTypes.number,
        description: PropTypes.string,
      }),
    }),
  ),
};

export default CloseProjectModal;
