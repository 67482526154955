import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { actions, selectors, types } from "rdx";
import messageTypes from "rdx/modules/leads/messages";

import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { Popconfirm, Input } from "antd";

import useLoading from "hooks/useLoading";
import useWindowResize from "hooks/useWindowResize";

import Lead from "models/Lead";

import { PrimaryButton } from "components/mui-styled-components/Buttons";

import styles from "./MapDisplay.module.less";

const MapDisplay = (props) => {
  const { lead, getGeocode, setGeocode, google, mapHeight = 275 } = props;
  const latestMessage = useSelector(selectors.getLatestMessageEvt);

  const dispatch = useDispatch();
  const { address, isAddressConfirmed, id: leadId } = lead;
  const { geocodeLat, geocodeLong, lat, long } = address || {};
  const location = useMemo(() => {
    if (address && lat && long) {
      return {
        lat: parseFloat(lat),
        lng: parseFloat(long),
      };
    }
    return null;
  }, [address, lat, long]);

  const [noGeocodeFound, setNoGeocodeFound] = useState(false);
  const [isMapTouched, setIsMapTouched] = useState(false);
  const [inputValues, setInputValues] = useState({ lat: "", lng: "" });
  const loading = useLoading({
    watchRequests: [types.GET_LEAD_DETAILS_REQUEST],
  });
  const { width, isMobileWidth } = useWindowResize();

  const confirmPinLocation = () => {
    setIsMapTouched(false);
    dispatch(
      actions.confirmSiteLocation({
        siteId: lead.siteId,
        latitude: location.lat,
        longitude: location.lng,
        leadId,
      }),
    );
  };

  const isLatOrLongChanged = () => {
    if (address) {
      const isLatChanged = !!geocodeLat && parseFloat(geocodeLat) !== parseFloat(lat);
      const isLongChanged = !!geocodeLong && parseFloat(geocodeLong) !== parseFloat(long);
      return isLatChanged || isLongChanged;
    }
    return false;
  };

  const onConfirmReset = () => {
    setIsMapTouched(true);
    dispatch(setGeocode({ lat: geocodeLat, lng: geocodeLong }));
  };

  const resetPinPosition = (originalLat, originalLng) => {
    dispatch(setGeocode({ lat: originalLat, lng: originalLng }));
    setIsMapTouched(false);
  };

  useEffect(() => {
    if (latestMessage.message === messageTypes.LEAD_FORM_RESET) {
      resetPinPosition(geocodeLat, geocodeLong);
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMarkerDrag = (_, marker) => {
    dispatch(setGeocode({ lat: marker.position.lat(), lng: marker.position.lng() }));
    setIsMapTouched(true);
  };

  const handleInputChange = (e, key) => {
    let {
      target: { value },
    } = e;
    value = value.replace(" ", "");
    if (Number.isNaN(Number(value))) {
      return null;
    }
    dispatch(setGeocode({ ...inputValues, [key]: value }));
    setIsMapTouched(true);
    return null;
  };

  useEffect(() => {
    // dispatch request to google api to lookup geocode from lead address if lat/long not found in lead details
    if (address && !!address?.status && address?.status !== "verified") {
      dispatch(getGeocode({ address }));
    }
  }, [address?.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // display error message instead of map if after geocode lookup still no geocode
    if (loading) {
      setNoGeocodeFound(false);
    } else {
      const geocodeFound = !!location;
      setNoGeocodeFound(!geocodeFound);
    }
  }, [loading, address, location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInputValues(location);
  }, [location]);

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
  };

  return (
    <div className={styles.updateLeadMapContainer} style={{ height: `${mapHeight + 100}px` }}>
      <div className={styles.mapCard} style={{ height: `${mapHeight}px` }}>
        {!!location && !loading ? (
          <>
            <Map
              containerStyle={containerStyle}
              className={styles.map}
              google={google}
              initialCenter={location}
              center={location}
              mapType="hybrid"
              zoom={18}
              scrollwheel={false}
              rotateControl={false}
              streetViewControl={false}
              scaleControl={false}
              mapTypeControl={false}
            >
              <Marker
                position={location}
                onDragend={handleMarkerDrag}
                name="default pin"
                draggable
                icon={{
                  url: !isAddressConfirmed ? "/img/orange-map-marker.png" : "/img/core-3.png",
                }}
                onClick={() => setIsMapTouched(true)}
                data-test-id="map-pin"
              />
            </Map>
          </>
        ) : null}
      </div>
      {noGeocodeFound && (
        <div className={styles.noMapData}>
          Cannot display map for this address. Please check that the address is accurate and valid, then resubmit.
        </div>
      )}
      {!isAddressConfirmed && !isMobileWidth && (
        <div className={styles.confirmLocationAlert}>
          <div className={styles.confirmLocationTitle}>Confirm Project Location</div>
          <div className={styles.confirmLocationInstruction}>Move pin to exact location</div>
        </div>
      )}
      {isLatOrLongChanged() && (
        <Popconfirm
          title={
            <div style={{ display: "flex", flexWrap: "wrap", maxWidth: "300px" }}>
              This will reset the lead location to the original latitude/longitude given by Google&apos;s geocoding
              service. Are you sure?
            </div>
          }
          okText="Reset"
          onConfirm={onConfirmReset}
        >
          <PrimaryButton className={styles.resetGeocodeButton}>Reset Pin Location</PrimaryButton>
        </Popconfirm>
      )}
      {(isMapTouched || !isAddressConfirmed) && !isMobileWidth && (
        <PrimaryButton
          onClick={confirmPinLocation}
          gradient
          className={styles.confirmPinLocation}
          style={{ minWidth: "100px" }}
        >
          Confirm
        </PrimaryButton>
      )}
      <div className={styles.latLongInputCard}>
        {address?.status === "unverified" && (
          <div className={styles.disclaimer}>This address has not been verified, map data may not be accurate.</div>
        )}
        <div className={width < 500 ? styles.latLongInputCol : styles.latLongInputRow}>
          {(isMapTouched || !isAddressConfirmed) && isMobileWidth && (
            <PrimaryButton onClick={confirmPinLocation} style={{ width: "100%", marginTop: "16px", fontSize: "12px" }}>
              Confirm Location
            </PrimaryButton>
          )}
          <div className={styles.latLongInputContainer}>
            <div className={!isAddressConfirmed ? styles.untouchedLabel : styles.inputLabel}>
              <span>Latitude</span>
            </div>
            <Input
              className={!isAddressConfirmed ? styles.untouchedInput : styles.input}
              value={inputValues?.lat}
              onChange={(e) => handleInputChange(e, "lat")}
            />
          </div>
          <div className={styles.latLongInputContainer}>
            <div className={!isAddressConfirmed ? styles.untouchedLabel : styles.inputLabel}>
              <span>Longitude</span>
            </div>
            <Input
              className={!isAddressConfirmed ? styles.untouchedInput : styles.input}
              value={inputValues?.lng}
              onChange={(e) => handleInputChange(e, "lng")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MapDisplay.propTypes = {
  lead: Lead.types(),
  getGeocode: PropTypes.func,
  setGeocode: PropTypes.func,
  // google object passed down from GoogleApiWrapper higher-order component
  google: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  mapHeight: PropTypes.number,
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(MapDisplay);
