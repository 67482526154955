import React from "react";

import { css, SerializedStyles } from "@emotion/react";

import NewDefaultAvatar from "components/Icons/NewDefaultAvatar";
import { LocationPin, UtilityIcon, InstallerIcon } from "components/Icons";
import { Avatar as MUIAvatar } from "@mui/material";
import { LookUpTypeT } from "./types";
import * as S from "./styles";

type AvatarT = {
  avatarContent?: string;
  defaultAvatarWidth?: string;
  avatarWidth?: string;
  borderRadius?: string | SerializedStyles;
  type: LookUpTypeT;
};

export const Avatar = ({
  avatarContent,
  defaultAvatarWidth = "20px",
  type,
  avatarWidth = "20px",
  borderRadius = "20px",
}: AvatarT) => {
  const addFallbackSrc = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = "/img/default-profile.png";
  };

  const userAvatar = avatarContent ? (
    <img
      loading="lazy"
      width={avatarWidth}
      src={avatarContent}
      onError={addFallbackSrc}
      alt="avatar"
      css={css`
        border-radius: ${borderRadius};
      `}
    />
  ) : (
    <NewDefaultAvatar width={defaultAvatarWidth} />
  );
  const avatarSection = {
    user: userAvatar,
    orgTeamUser: userAvatar,
    teamUser: userAvatar,
    team: <MUIAvatar style={{ width: "24px", height: "24px", fontSize: "10px" }}>{avatarContent}</MUIAvatar>,
    location: (
      <S.IconContainer>
        <LocationPin width="11px" height="11px" />
      </S.IconContainer>
    ),
    utility: (
      <S.IconContainer>
        <UtilityIcon width="11px" height="11px" />
      </S.IconContainer>
    ),
    installer: (
      <S.IconContainer>
        <InstallerIcon width="14px" height="11px" />
      </S.IconContainer>
    ),
  };

  return <S.AvatarContainer>{avatarSection[type]}</S.AvatarContainer>;
};
