// import l from "@loadable/component";

// const component = l(() =>
//   import("containers/Ambassador/ambassador/createaccount"),
// );

import component from "containers/Ambassador/createaccount";

export default {
  key: "ambassador-create-account",
  title: "Create Ambassador Account",
  path: "/ambassador/create-account/:code?",
  linkTarget: "/ambassador/create-account",
  component,
};
