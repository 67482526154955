import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import VideoModal from "components/Modals/VideoModal";
import useModalControls from "hooks/useModalControls";
import { buttonProps } from "lib/props";
import styles from "../../Home.module.less";

const JBMessage = () => {
  const { t } = useTranslation(["home", "form_labels"]);
  const { visible, open, close } = useModalControls();
  const urlPrefix = process.env.REACT_APP_S3_URL_PREFIX;

  return (
    <div className={styles.jbMessage}>
      <VideoModal
        visible={visible}
        close={close}
        videoSourceUrl={`${urlPrefix}/assets/videos/EDUCATIONAL_04_HOW_WE_GIVE_BACK.mp4`}
        title={t("jb.how_we_give_back")}
      />
      <div className={styles.innerContainer}>
        <div className={styles.message}>
          <div className={[styles.previewImg, styles.bgDarkenZoomOnHover].join(" ")} {...buttonProps(() => open())}>
            <div className={styles.bg} />
          </div>
          <div className={styles.card}>
            <h4 className={styles.headline}>{t("jb.giving_back")}</h4>
            <h3>{t("jb.ceo_message")}</h3>
            <Button className={styles.watchButton} type="primary" onClick={open}>
              {t("form_labels:watch_now")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JBMessage;
