import SessionClient, { StatusFlags } from "util/SessionClient";
import { UserSubscriptionStatusEnum } from "rdx/modules/users/enums";

type SessionUtils = {
  session: SessionClient;
  needsCertification: boolean;
  needsActivation: boolean;
  needsCloserCertification: boolean;
  paymentStatusFlags: StatusFlags | null;
  enrollmentStatus: UserSubscriptionStatusEnum;
};

const session = new SessionClient();

export function useSession(): SessionUtils {
  const needsCertification = session.enrollmentStatus === UserSubscriptionStatusEnum.NEEDS_CERTIFICATION;

  return {
    session,
    needsCertification,
    needsCloserCertification: !!session.user?.props.needsCloserCertification,
    needsActivation: Boolean(session.statusFlags?.isSetter) && needsCertification,
    paymentStatusFlags: session.statusFlags,
    enrollmentStatus: session.enrollmentStatus,
    // Add more properties as needed
  };
}
