import React from "react";
import { PrimaryButton } from "components/mui-styled-components";
import { CloseIcon } from "components/Icons";
import GradientGhostButton from "components/Buttons/GradientGhostButton";

import { LeadT } from "types/Lead";
import { cvar } from "styles/colorVariables";
import { T3PartnerT } from "../../types";

import * as S from "./styles";

type ConfirmT3PartnerFooterPropsT = {
  handleSelect: (mentorId: number) => void;
  selectedT3Partner: T3PartnerT;
  setSelectedT3Partner: (t3Partner: null) => void;
  lead: LeadT;
  setOpenFooter: (val: boolean) => void;
};

const ConfirmT3PartnerFooter = ({
  selectedT3Partner,
  setSelectedT3Partner,
  lead,
  handleSelect,
  setOpenFooter,
}: ConfirmT3PartnerFooterPropsT) => {
  const handleCancel = () => {
    setOpenFooter(false);
    setSelectedT3Partner(null);
  };

  return (
    <S.FooterContainer>
      <S.Column>
        {!!lead && (
          <S.Row>
            PROJECT <S.NameText>{lead.fullName}</S.NameText>
          </S.Row>
        )}
        <S.Row>
          PARTNER <S.NameText>{selectedT3Partner?.fullName}</S.NameText>
        </S.Row>
      </S.Column>
      <S.Row>
        <GradientGhostButton onClick={handleCancel} style={{ marginRight: "7px" }}>
          <CloseIcon startFill={cvar("picton-blue")} stopFill={cvar("blue-ribbon")} />
        </GradientGhostButton>
        <PrimaryButton gradient onClick={() => handleSelect(selectedT3Partner.id)}>
          Confirm Assignment
        </PrimaryButton>
      </S.Row>
    </S.FooterContainer>
  );
};

export default ConfirmT3PartnerFooter;
