import styled from "@emotion/styled";

type SpacerFunctionArgs = {
  axis: "horizontal" | "vertical";
  size: number;
};

function getHeight({ axis, size }: SpacerFunctionArgs) {
  return axis === "horizontal" ? 1 : size;
}
function getWidth({ axis, size }: SpacerFunctionArgs) {
  return axis === "vertical" ? 1 : size;
}
const Spacer = styled.span`
  display: block;
  width: ${getWidth}px;
  min-width: ${getWidth}px;
  height: ${getHeight}px;
  min-height: ${getHeight}px;
`;

export default Spacer;
