import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const WarningIconFilled = ({ height = "16", width = "16", fill = cvar("white") }: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M15.6676 11.9984L10.0155 1.60853C9.10743 0.0797482 6.89378 0.0777169 5.98447 1.60853L0.332715 11.9984C-0.595597 13.5606 0.528309 15.5387 2.34778 15.5387H13.652C15.47 15.5387 16.5959 13.5622 15.6676 11.9984ZM8 13.6637C7.48318 13.6637 7.0625 13.2431 7.0625 12.7262C7.0625 12.2094 7.48318 11.7887 8 11.7887C8.51681 11.7887 8.9375 12.2094 8.9375 12.7262C8.9375 13.2431 8.51681 13.6637 8 13.6637ZM8.9375 9.91375C8.9375 10.4306 8.51681 10.8512 8 10.8512C7.48318 10.8512 7.0625 10.4306 7.0625 9.91375V5.22625C7.0625 4.70944 7.48318 4.28875 8 4.28875C8.51681 4.28875 8.9375 4.70944 8.9375 5.22625V9.91375Z"
        fill={fill}
      />
    </svg>
  );
};
export default WarningIconFilled;
