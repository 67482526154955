import { put, select } from "redux-saga/effects";
import selectors from "rdx/modules/tickets/selectors";
import actions from "rdx/actions";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToCamelCase } from "lib/helpers";
import setMessageLists from "./util/updateLists";
import messages from "../messages";

function* updateMessage(action) {
  const { id } = action.payload;
  const params = {
    ...action.payload,
  };
  const { success, data, error } = yield* makeRequest.patch(`/messages/${id}`, params);

  if (success && data) {
    const replies = { ...(yield select(selectors.getProjectMessageReplies)) };
    const newNotice = keysToCamelCase(data.body);
    if (newNotice.parentId) {
      replies[newNotice.parentId] = replies[newNotice.parentId].map((note) =>
        note.id === newNotice.id ? newNotice : note,
      );
      yield put(actions.setProjectMessageReplies({ replies }));
    }
    if (!newNotice.parentId) yield setMessageLists(data.body);
    yield put(
      actions.newSuccessEvent({
        message: messages.UPDATE_PROJECT_MESSAGE_SUCCESS,
      }),
    );
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_PROJECT_MESSAGE,
    });
  }
  return null;
}

export default updateMessage;
