import { takeEvery, takeLatest } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/workflows/types";

import createDataGroup from "./dataGroups/create";
import updateDataGroup from "./dataGroups/update";
import getDataGroups from "./dataGroups/get";
import deleteDataGroup from "./dataGroups/delete";
import createMetaField from "./metaFields/create";
import updateMetaField from "./metaFields/update";
import deleteMetaField from "./metaFields/delete";
import createWorkflow from "./workflows/create";
import getWorkflowsList from "./workflows/getList";
import getActiveWorkflow from "./workflows/getActive";
import getWorkflow from "./workflows/get";
import updateWorkflow from "./workflows/update";
import copyWorkflow from "./workflows/copy";
import deleteWorkflow from "./workflows/delete";
import removeWorkflowPhase from "./workflows/removePhase";
import createPhase from "./phases/create";
import updatePhase from "./phases/update";
import getWorkflowPhases from "./phases/getWorkflowPhases";
import createMilestone from "./milestones/create";
import updateMilestone from "./milestones/update";
import getPhaseMilestones from "./milestones/getPhaseMilestones";
import removePhaseMilestone from "./phases/removeMilestone";
import reorderPhases from "./phases/reorderPhases";
import reorderMilestones from "./milestones/reorderMilestones";
import reorderMilestoneDataGroups from "./milestones/reorderDataGroups";
// IMPORT_PT (for script, do not remove)

function* watchWorkflowsSagas() {
  // data-groups
  yield trackRequests(takeEvery, types.CREATE_DATA_GROUP_REQUEST, createDataGroup);
  yield trackRequests(takeEvery, types.UPDATE_DATA_GROUP_REQUEST, updateDataGroup);
  yield trackRequests(takeEvery, types.GET_DATA_GROUPS_REQUEST, getDataGroups);
  yield trackRequests(takeEvery, types.DELETE_DATA_GROUP_REQUEST, deleteDataGroup);

  // meta-fields
  yield trackRequests(takeEvery, types.CREATE_META_FIELD_REQUEST, createMetaField);
  yield trackRequests(takeEvery, types.UPDATE_META_FIELD_REQUEST, updateMetaField);
  yield trackRequests(takeEvery, types.DELETE_META_FIELD_REQUEST, deleteMetaField);

  // workflows
  yield trackRequests(takeEvery, types.CREATE_WORKFLOW_REQUEST, createWorkflow);
  yield trackRequests(takeEvery, types.GET_WORKFLOWS_LIST_REQUEST, getWorkflowsList);
  yield trackRequests(takeEvery, types.GET_ACTIVE_WORKFLOW_REQUEST, getActiveWorkflow);
  yield trackRequests(takeEvery, types.GET_WORKFLOW_REQUEST, getWorkflow);
  yield trackRequests(takeEvery, types.UPDATE_WORKFLOW_REQUEST, updateWorkflow);
  yield trackRequests(takeEvery, types.COPY_WORKFLOW_REQUEST, copyWorkflow);
  yield trackRequests(takeEvery, types.DELETE_WORKFLOW_REQUEST, deleteWorkflow);
  yield trackRequests(takeEvery, types.REMOVE_WORKFLOW_PHASE_REQUEST, removeWorkflowPhase);

  // phases
  yield trackRequests(takeEvery, types.CREATE_PHASE_REQUEST, createPhase);
  yield trackRequests(takeEvery, types.UPDATE_PHASES_REQUEST, updatePhase);
  yield trackRequests(takeLatest, types.GET_WORKFLOW_PHASES_REQUEST, getWorkflowPhases);
  yield trackRequests(takeLatest, types.UPDATE_PHASE_RANKS_REQUEST, reorderPhases);

  // milestones
  yield trackRequests(takeEvery, types.CREATE_MILESTONE_REQUEST, createMilestone);
  yield trackRequests(takeEvery, types.UPDATE_MILESTONE_REQUEST, updateMilestone);
  yield trackRequests(takeLatest, types.UPDATE_MILESTONE_RANKS_REQUEST, reorderMilestones);
  yield trackRequests(takeLatest, types.REORDER_MILESTONE_DATA_GROUPS_REQUEST, reorderMilestoneDataGroups);
  yield trackRequests(takeEvery, types.GET_PHASE_MILESTONES_REQUEST, getPhaseMilestones);
  yield trackRequests(takeEvery, types.REMOVE_PHASE_MILESTONE_REQUEST, removePhaseMilestone);
  // INSERTION_PT (for script, do not remove)
}

export default watchWorkflowsSagas;
