// import content from "containers/Account/profile";
import tabs from "containers/Account/profile/tabs";
import l from "@loadable/component";

const content = l(() => import("containers/Account/profile"));

export default {
  key: "profile",
  title: "Profile",
  path: "/profile/:tab?",
  linkTarget: "/profile",
  linkTitle: "Profile",
  linkCategory: "Personal",
  icon: () => null,
  basePath: "profile",
  requiredParams: ["tab"],
  optionalParams: [],
  tabs,
  content,
  sidebar: null,
};
