import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const CoffeeMugIcon = ({ height = "32", width = "33", fill = cvar("graham-grey") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_805_1622)">
        <path
          d="M20.1202 6.16731C21.3503 5.17362 21.6288 4.11137 21.6457 3.39518C21.6871 1.65287 20.3113 0.377865 20.1542 0.238302C19.7652 -0.107699 19.1694 -0.0728238 18.8233 0.316239C18.4773 0.705303 18.5122 1.30112 18.9012 1.64718C18.91 1.65505 19.7817 2.47056 19.7608 3.3505C19.7497 3.81931 19.4797 4.26093 18.9354 4.70062C17.7052 5.69431 17.4267 6.75656 17.4098 7.47275C17.3684 9.21507 18.7442 10.4901 18.9012 10.6297C19.0813 10.7898 19.306 10.8687 19.5299 10.8687C19.7896 10.8687 20.0482 10.7624 20.234 10.5534C20.58 10.1643 20.547 9.57013 20.158 9.22413C19.9105 8.99913 19.2732 8.25269 19.2949 7.51038C19.3085 7.04419 19.5785 6.60481 20.1202 6.16731Z"
          fill={fill}
        />
        <path
          d="M14.1386 6.16731C15.3687 5.17362 15.6472 4.11137 15.6642 3.39518C15.7055 1.65287 14.3297 0.377865 14.1727 0.238302C13.7837 -0.107699 13.1878 -0.0728238 12.8417 0.316239C12.4957 0.705303 12.5306 1.30112 12.9197 1.64718C12.9285 1.65505 13.8001 2.47056 13.7792 3.3505C13.7681 3.81931 13.4981 4.26093 12.9538 4.70062C11.7237 5.69431 11.4452 6.75656 11.4282 7.47275C11.3868 9.21507 12.7627 10.4901 12.9197 10.6297C13.0997 10.7898 13.3245 10.8687 13.5483 10.8687C13.808 10.8687 14.0666 10.7624 14.2525 10.5534C14.5985 10.1643 14.5655 9.57013 14.1764 9.22413C13.929 8.99913 13.2916 8.25269 13.3133 7.51038C13.327 7.04419 13.5969 6.60481 14.1386 6.16731Z"
          fill={fill}
        />
        <path
          d="M27.6349 15.3841H26.5191V14.6928C26.5191 14.1721 26.0971 13.75 25.5764 13.75H1.51544C0.994819 13.75 0.572693 14.1721 0.572693 14.6928V19.6683C0.572693 24.0647 3.00613 27.9305 6.65952 30.1144H1.51601C0.995381 30.1144 0.573255 30.5365 0.573255 31.0572C0.573255 31.5778 0.995256 31.9999 1.51601 31.9999H25.5764C26.0971 31.9999 26.5192 31.5778 26.5192 31.0572C26.5192 30.5365 26.0971 30.1144 25.5764 30.1144H20.4324C22.5099 28.8725 24.1928 27.087 25.2581 24.9688H27.6349C30.2774 24.9688 32.4273 22.819 32.4273 20.1765C32.4273 17.5339 30.2774 15.3841 27.6349 15.3841ZM24.6337 19.6683C24.6336 25.4283 19.6597 30.1144 13.5459 30.1144C7.43214 30.1144 2.4582 25.4283 2.4582 19.6683V15.6355H24.6336V19.6683H24.6337ZM27.6349 23.0834H26.0126C26.3421 21.9985 26.5191 20.8526 26.5191 19.6683V17.2695H27.6349C29.2377 17.2695 30.5418 18.5736 30.5418 20.1765C30.5418 21.7793 29.2377 23.0834 27.6349 23.0834Z"
          fill={fill}
        />
        <path
          d="M8.157 6.16731C9.38713 5.17362 9.66563 4.11137 9.68257 3.39518C9.72394 1.65287 8.34813 0.377865 8.19106 0.238302C7.80206 -0.107699 7.20619 -0.0728238 6.86012 0.316239C6.51412 0.70524 6.549 1.30112 6.93806 1.64718C6.94687 1.65505 7.8185 2.47056 7.79763 3.3505C7.7865 3.81931 7.5165 4.26093 6.97219 4.70062C5.74206 5.69431 5.46356 6.75656 5.44662 7.47275C5.40531 9.21501 6.78112 10.49 6.93812 10.6296C7.11775 10.7894 7.34144 10.8679 7.56431 10.8679C7.82413 10.8679 8.08281 10.7611 8.26913 10.5517C8.61513 10.1626 8.58025 9.56682 8.19119 9.22076C8.18238 9.21288 7.31075 8.39738 7.33162 7.51744C7.34262 7.04863 7.61262 6.60694 8.157 6.16731Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_805_1622">
          <rect width="32" height="32" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CoffeeMugIcon;
