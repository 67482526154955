import types from "rdx/modules/tickets/types";
import createAction from "rdx/utils/createAction";

export default {
  getInboxList: (payload) => createAction(types.GET_INBOX_LIST_REQUEST, payload),

  setInboxList: (payload) => createAction(types.SET_INBOX_LIST, payload),

  getSelectedMessageDetails: (payload) => createAction(types.GET_SELECTED_MESSAGE_DETAILS_REQUEST, payload),

  setSelectedMessageDetails: (payload) => createAction(types.SET_SELECTED_MESSAGE_DETAILS, payload),

  createNewInboxMessage: (payload) => createAction(types.CREATE_NEW_INBOX_MESSAGE_REQUEST, payload),

  updateSelectedMessage: (payload) => createAction(types.UPDATE_SELECTED_MESSAGE_REQUEST, payload),

  setInboxFilters: (payload) => createAction(types.SET_INBOX_FILTERS, payload),

  setInboxAutoCompleteSuggestions: (payload) => createAction(types.SET_INBOX_AUTOCOMPLETE_SUGGESTIONS, payload),

  resolveIssue: (payload) => createAction(types.RESOLVE_INBOX_ISSUE_REQUEST, payload),

  reopenIssue: (payload) => createAction(types.REOPEN_INBOX_ISSUE_REQUEST, payload),

  starTicket: (payload) => createAction(types.STAR_TICKET_REQUEST, payload),

  unstarTicket: (payload) => createAction(types.UNSTAR_TICKET_REQUEST, payload),

  assignIssue: (payload) => createAction(types.ASSIGN_INBOX_ISSUE_REQUEST, payload),

  deleteInboxMessage: (payload) => createAction(types.DELETE_INBOX_MESSAGE_REQUEST, payload),

  resetSelectedMessageDetails: (payload) => createAction(types.RESET_SELECTED_MESSAGE_DETAILS, payload),

  resetSelectedProjectMessages: (payload) => createAction(types.RESET_SELECTED_PROJECT_MESSAGES, payload),

  getProjectMessageList: (payload) => createAction(types.GET_PROJECT_MESSAGE_LIST_REQUEST, payload),

  setProjectMessageList: (payload) => createAction(types.SET_PROJECT_MESSAGE_LIST, payload),

  getZendeskFormData: (payload) => createAction(types.GET_ZENDESK_FORM_DATA_REQUEST, payload),

  setZendeskFormData: (payload) => createAction(types.SET_ZENDESK_FORM_DATA, payload),

  setInboxTags: (payload) => createAction(types.SET_INBOX_TAGS, payload),

  createNewProjectMessage: (payload) => createAction(types.CREATE_NEW_PROJECT_MESSAGE_REQUEST, payload),

  deleteProjectMessage: (payload) => createAction(types.DELETE_PROJECT_MESSAGE_REQUEST, payload),

  getProjectRepliesPreview: (payload) => createAction(types.GET_PROJECT_MESSAGE_REPLIES_PREVIEW_REQUEST, payload),

  setProjectMessageReplies: (payload) => createAction(types.SET_PROJECT_MESSAGE_REPLIES, payload),

  getAllRepliesForIssue: (payload) => createAction(types.GET_ALL_REPLIES_FOR_ISSUE_REQUEST, payload),

  postProjectMessageReply: (payload) => createAction(types.POST_PROJECT_MESSAGE_REPLY_REQUEST, payload),

  archiveMessages: (payload) => createAction(types.ARCHIVE_MESSAGES_REQUEST, payload),

  unarchiveMessages: (payload) => createAction(types.UNARCHIVE_MESSAGES_REQUEST, payload),

  markMessagesAsViewed: (payload) => createAction(types.MARK_MESSAGES_AS_VIEWED_REQUEST, payload),

  markMessageAndRepliesAsViewed: (payload) => createAction(types.MARK_MESSAGE_AND_REPLIES_AS_VIEWED_REQUEST, payload),

  markMessagesAsUnviewed: (payload) => createAction(types.MARK_MESSAGES_AS_UNVIEWED_REQUEST, payload),

  getAvailableAssignees: (payload) => createAction(types.GET_AVAILABLE_ASSIGNEES_REQUEST, payload),

  setObservingAccount: (payload) => createAction(types.SET_OBSERVING_ACCOUNT, payload),
  // INSERTION_PT (for script, do not remove)
};
