import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  availablePeriods: [],
  kpi: {},
  paging: {
    itemCount: 0,
    pageNumber: 1,
    pageSize: 50,
  },
  payments: [],
  team: [],
  selectedPeriod: {},
  consultantOrLeadAutoComplete: [],
  searchedSeller: {},
  searchedProviderLead: {},
};

export default {
  enterpriseEarnings: createReducer(initialState, {
    [types.SET_ENTERPRISE_EARNINGS_LIST](state, action) {
      return {
        ...state,
        ...action.payload,
        searchedSeller: action.payload.searchedSeller || {},
        searchedProviderLead: action.payload.searchedProviderLead || {},
      };
    },

    [types.SET_ENTERPRISE_EARNINGS_FILTERS](state, action) {
      return {
        ...state,
        filters: action.payload,
      };
    },
    [types.SET_ENTERPRISE_COMP_PLAN](state, action) {
      return {
        ...state,
        enterpriseCompPlan: action.payload,
      };
    },

    // [types.SET_CONSULTANT_OR_LEAD_AUTO_COMPLETE](state, action) {
    //   return {
    //     ...state,
    //     consultantOrLeadAutoComplete: action.payload,
    //   };
    // },
    // INSERTION_PT (for script, do not remove)
  }),
};
