import { permissionTypes } from "hooks/usePermissions";

const { ENTERPRISE_ADMIN_PRO } = permissionTypes;

const commonTabs = [
  {
    key: "ledger",
    label: "Ledger",
  },
];

const adminTabs = [
  {
    key: "comp-plan",
    label: "Comp Plan",
    permission: ENTERPRISE_ADMIN_PRO,
  },
];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
