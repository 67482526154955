export default {
  // success messages
  PROJECT_UPDATE_SUCCESS: "Project details updated.",
  PROJECT_MILESTONES_UPDATE_SUCCESS: "Project milestones updated",

  REVISION_REQUEST_SUBMIT_SUCCESS: "Proposal request submitted.",
  REQUEST_ADMIN_PROPOSAL_SUCCESS: "Proposal request submitted.",

  // error messages
  ERROR_GETTING_ADMIN_PROJECTS_LIST: "There was an error retrieving projects list",
  ERROR_GETTING_ADMIN_PROJECTS_AUTOCOMPLETE: "There was an error retrieving users",
  ERROR_GETTING_ADMIN_PROJECT_DETAILS: "There was an error retrieving project details",
  ERROR_GETTING_PROJECT_MILESTONES: "There was an error retrieving project milestones",
  ERROR_UPDATING_PROJECT_MILESTONES: "There was an error updating this project",
  ERROR_UPDATING_PROJECT_MANAGEMENT_USERS: "There was an error updating this project",
  ERROR_GETTING_ADMIN_PROJECT_NOTES: "There was an error getting project notes",
  ERROR_UPDATING_ADMIN_PROJECT_LEAD: "There was an error updating this lead.",

  ERROR_GETTING_ADMIN_PROJECT_PROPOSALS: "There was an error retrieving proposals for this project.",

  ERROR_REQUESTING_ADMIN_PROPOSAL: "There was an error requesting a proposal for this project.",

  ERROR_REQUESTING_ADMIN_REVISION: "There was an error a revision for this project.",
  ERROR_REOPENING_ADMIN_PROJECT: "There was an error reopening this project.",
  ERROR_CLOSING_ADMIN_PROJECT: "There was an error closing this project.",
};
