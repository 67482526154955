import types from "rdx/modules/dashboardUpdates/types";
import createAction from "rdx/utils/createAction";

export default {
  getDashboardUpdates: (payload) => createAction(types.GET_DASHBOARD_UPDATES, payload),

  setDashboardUpdates: (payload) => createAction(types.SET_DASHBOARD_UPDATES, payload),

  createDashboardUpdate: (payload) => createAction(types.CREATE_DASHBOARD_UPDATE, payload),

  getDashboardUpdate: (payload) => createAction(types.GET_DASHBOARD_UPDATE, payload),

  setDashboardUpdateId: (payload) => createAction(types.SET_DASHBOARD_UPDATE_ID, payload),

  updateDashboardUpdateId: (payload) => createAction(types.UPDATE_DASHBOARD_UPDATE, payload),

  deleteDashboardUpdateId: (payload) => createAction(types.DELETE_DASHBOARD_UPDATE, payload),

  setDashboardUpdateModalId: (payload) => createAction(types.SET_DASHBOARD_UPDATE_MODAL_ID, payload),
  // INSERTION_PT (for script, do not remove)
};
