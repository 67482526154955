import React from "react";
import { isString } from "types/guards";
import useWindowResize from "hooks/useWindowResize";

import JoinPowurTodayButton from "../JoinPowurTodayButton";

import * as S from "./styles";

type ContentRowPropsT = {
  isRightAligned: boolean;
  backgroundImagePath: string;
  secondaryImagePath: string | null;
  headerText: string;
  subheaderText: string;
  detailBackgroundColor: string | null;
  detailHeaderText: string;
  highlightDetailHeader: boolean;
  detailImage: `/${string}.${"png"}` | JSX.Element | null;
  detailSubheaderText: string | null;
  onJoinClick: () => void;
};
const ContentRow = ({
  isRightAligned,
  headerText,
  subheaderText,
  backgroundImagePath,
  secondaryImagePath,
  detailBackgroundColor,
  detailHeaderText,
  highlightDetailHeader,
  detailImage,
  detailSubheaderText,
  onJoinClick,
}: ContentRowPropsT) => {
  const { isMobileWidth } = useWindowResize();

  const content = (
    <>
      <S.ContentContainer isRightAligned={isRightAligned}>
        <S.HeaderContainer isRightAligned={isRightAligned}>
          <S.WhiteHeader>{headerText}</S.WhiteHeader>
          <S.WhiteDetailText>{subheaderText}</S.WhiteDetailText>
          <JoinPowurTodayButton onClick={onJoinClick} />
        </S.HeaderContainer>
        <S.DetailsContainer backgroundColor={detailBackgroundColor}>
          <S.DetailsHeader isInlineSize={!!detailImage} highlightText={highlightDetailHeader}>
            {detailHeaderText}
          </S.DetailsHeader>
          {isString(detailImage) ? <S.DetailsImage src={detailImage} alt="Fast Pay" /> : detailImage}
          {detailSubheaderText && (
            <S.DetailsSubheader isInlineSize={!!detailImage}>{detailSubheaderText}</S.DetailsSubheader>
          )}
        </S.DetailsContainer>
      </S.ContentContainer>
      <S.SecondaryImageContainer>
        {secondaryImagePath && <img src={secondaryImagePath} alt="Inc 5000" />}
      </S.SecondaryImageContainer>
    </>
  );

  if (isMobileWidth) {
    return (
      <S.MobileContainer>
        <S.MobileImage src={backgroundImagePath} alt={headerText} />
        <S.MobileRowContainer>{content}</S.MobileRowContainer>
      </S.MobileContainer>
    );
  }
  return (
    <S.ContentRowContainer imagePath={backgroundImagePath}>
      <S.MaxWidthContainer isRightAligned={isRightAligned}>{content}</S.MaxWidthContainer>
    </S.ContentRowContainer>
  );
};

export default ContentRow;
