import React from "react";
import PropTypes from "prop-types";

const SwapIcon = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5904 11.5286C15.5425 11.4857 15.4732 11.4768 15.4146 11.504C15.3558 11.5316 15.3171 11.5898 15.3171 11.6553V14.3576H13.3526L10.8121 10.8203L9.11426 13.1836L11.5258 16.5401C11.7842 16.8993 12.2039 17.1144 12.646 17.1144H15.3171V19.8307C15.3171 19.8949 15.3558 19.9535 15.4146 19.9813C15.4732 20.0087 15.5425 19.9999 15.5904 19.9568L20.4218 15.8692C20.4595 15.8382 20.4798 15.7913 20.4798 15.7428C20.4798 15.694 20.4593 15.6471 20.4218 15.6155L15.5904 11.5286Z"
        fill={fill}
      />
      <path
        d="M15.3162 5.62592V8.34181C15.3162 8.40605 15.3549 8.46526 15.4137 8.49244C15.4723 8.51983 15.5416 8.51077 15.5895 8.46828C15.5895 8.46828 20.3833 4.41237 20.4209 4.38056C20.4586 4.34874 20.4789 4.30282 20.4789 4.25388C20.4789 4.20535 20.4584 4.15903 20.4209 4.12741C20.3835 4.0958 15.5897 0.0392894 15.5897 0.0392894C15.5418 -0.00259878 15.4725 -0.0116611 15.4139 0.0153245C15.3551 0.0423102 15.3164 0.101115 15.3164 0.166364V2.86936H12.576C12.1339 2.86936 11.714 3.08424 11.4559 3.44331L3.61633 14.3574H1.32074C1.0983 14.3574 0.917969 14.5377 0.917969 14.7602V16.7112C0.917969 16.9336 1.0983 17.114 1.32074 17.114H4.32299C4.76503 17.114 5.18351 16.8987 5.44209 16.5396L13.2822 5.62572L15.3162 5.62592Z"
        fill={fill}
      />
      <path
        d="M6.13067 9.02757L7.82815 6.66512L5.51262 3.44194C5.25424 3.08247 4.83536 2.86719 4.39291 2.86719H1.26117C1.03872 2.86719 0.858398 3.04751 0.858398 3.26996V5.22098C0.858398 5.44342 1.03873 5.62375 1.26117 5.62375H3.68565L6.13067 9.02757Z"
        fill={fill}
      />
    </svg>
  );
};

SwapIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

SwapIcon.defaultProps = {
  fill: "var(--eggplant)",
  width: "21",
  height: "20",
};

export default SwapIcon;
