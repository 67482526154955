import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/leads/types";
import getLeads from "./getLeads";
import getEvents from "./getEvents";
import getLeadDetails from "./getLeadDetails";
import createNewLead from "./createNewLead";
import submitLeadToProvider from "./submitLeadToProvider";
import updateLead from "./updateLead";
import updateLeadUtilityBill from "./updateLeadUtilityBill";
import getLeadGeocode from "./getLeadGeocode";
import deleteLead from "./deleteLead";
import saveAndSubmitLead from "./saveAndSubmitLead";
import createNewPublicLead from "./createNewPublicLead";
import getLeadsCsv from "./getLeadsCsv";
import getLeadsCsvReport from "./getLeadsCsvReport";
import deleteHomeownerAvailabilities from "./deleteHomeownerAvailabilities";
import getUtilityCompanies from "./getUtilityCompanies";
import getHomeownerNotes from "./getHomeownerNotes";
import confirmSiteLocation from "./confirmSiteLocation";
import closeLead from "./closeLead";
import reopenLead from "./reopenLead";
import updateLeadOwner from "./updateLeadOwner";
import createLeadAsset from "./createLeadAsset";
// IMPORT_PT (for script, do not remove)

function* watchLeadsSagas() {
  yield trackRequests(takeEvery, types.GET_LEADS_REQUEST, getLeads);
  yield trackRequests(takeEvery, types.GET_EVENTS_REQUEST, getEvents);
  yield trackRequests(takeEvery, types.GET_LEAD_DETAILS_REQUEST, getLeadDetails);
  yield trackRequests(takeEvery, types.CREATE_NEW_LEAD, createNewLead);
  yield trackRequests(takeEvery, types.SUBMIT_LEAD_TO_PROVIDER, submitLeadToProvider);
  yield trackRequests(takeEvery, types.UPDATE_LEAD, updateLead);
  yield trackRequests(takeEvery, types.UPDATE_LEAD_UTILITY_BILL, updateLeadUtilityBill);
  yield trackRequests(takeEvery, types.GET_LEAD_ADDRESS_GEOCODE, getLeadGeocode);
  yield trackRequests(takeEvery, types.DELETE_LEAD_REQUEST, deleteLead);
  yield trackRequests(takeEvery, types.SAVE_AND_SUBMIT_LEAD_REQUEST, saveAndSubmitLead);
  yield trackRequests(takeEvery, types.CREATE_NEW_PUBLIC_LEAD_REQUEST, createNewPublicLead);
  yield trackRequests(takeEvery, types.GET_LEADS_CSV, getLeadsCsv);
  yield trackRequests(takeEvery, types.GET_LEADS_CSV_REPORT, getLeadsCsvReport);
  yield trackRequests(takeEvery, types.DELETE_HOMEOWNER_AVAILABILITY, deleteHomeownerAvailabilities);
  yield trackRequests(takeEvery, types.GET_UTILITY_COMPANIES, getUtilityCompanies);
  yield trackRequests(takeEvery, types.GET_HOMEOWNER_NOTES, getHomeownerNotes);
  yield trackRequests(takeEvery, types.CONFIRM_SITE_LOCATION, confirmSiteLocation);
  yield trackRequests(takeEvery, types.CLOSE_LEAD_REQUEST, closeLead);
  yield trackRequests(takeEvery, types.REOPEN_LEAD_REQUEST, reopenLead);
  yield trackRequests(takeEvery, types.UPDATE_LEAD_OWNER, updateLeadOwner);
  yield trackRequests(takeEvery, types.CREATE_LEAD_ASSET, createLeadAsset);
  // INSERTION_PT (for script, do not remove)
}

export default watchLeadsSagas;
