import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import { useForm } from "hooks/useForm";
import { Input } from "antd";
import ModalContainer from "components/Modals/ModalContainer";
import styles from "containers/Admin/workflows/AdminWorkflows.module.less";

const NewWorkflowModal = (props) => {
  const { visible, setModalVisible, createWorkflow } = props;

  const close = () => {
    setModalVisible(false);
  };

  const handleSubmit = (values) => {
    createWorkflow({ title: values.title });
    close();
  };

  const validateTitle = () => {
    return (value) => (typeof value === "string" ? null : "invalid");
  };

  const [form, { title }] = useForm(
    {
      title: {
        initialValue: "",
        validate: validateTitle(),
      },
    },
    {
      onSubmit: handleSubmit,
    },
  );

  return (
    <ModalContainer
      visible={visible}
      title="Create New Workflow"
      handleSubmit={form.props.onSubmit}
      handleCancel={close}
      // submitDisabled={disableSubmit()}
    >
      <form {...form.props}>
        <div className={styles.label}>Workflow Title</div>
        <Input placeholder="Title" {...title.props} />
      </form>
    </ModalContainer>
  );
};

NewWorkflowModal.propTypes = {
  visible: PropTypes.bool,
  setModalVisible: PropTypes.func,
  createWorkflow: PropTypes.func,
};

export default connect(getRdxSelectionMapper([]), getRdxActionMapper(["createWorkflow"]))(NewWorkflowModal);
