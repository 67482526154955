import React, { useState, useContext } from "react";
import { useEffectOnce } from "react-use";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import { LayoutContext } from "components/PlatformLayout";

import styles from "./DetailPageContainer.module.less";

const Footer = (props) => {
  const { children } = props;
  const { CONTENT_WINDOW_ID } = useContext(LayoutContext);

  const [domReady, setDomReady] = useState(false);

  useEffectOnce(() => {
    setDomReady(true);
  });

  const contentWindow = document.getElementById(CONTENT_WINDOW_ID);

  const content = <div className={styles.detailPageFooter}>{children}</div>;

  return domReady ? ReactDOM.createPortal(content, contentWindow) : null;
};

Footer.propTypes = {
  children: PropTypes.node,
};
export default Footer;
