import React from "react";
import styles from "../../Solar.module.less";

const SolarChart = () => {
  const urlPrefix = process.env.REACT_APP_S3_URL_PREFIX;
  return (
    <div className={styles.solarChart}>
      <img
        src={`${urlPrefix}/assets/img/why-solar/whysolar_Chart.jpg`}
        alt="Why Solar Now? History indicates that homeowners who take no action to free themselves from the grid are going to pay more next year than they are today. See the graph to see how expensive energy could get."
      />
    </div>
  );
};

export default SolarChart;
