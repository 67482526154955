import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToCamelCase } from "lib/helpers";
import actions from "rdx/actions";
import messages from "../messages";

function* deleteNote(action) {
  const { noteId } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/inbox/${noteId}`, {});
  if (success && data) {
    yield all([
      put(
        actions.setNotes({
          notes: data.body.notes && data.body.notices.map((n) => keysToCamelCase(n)),
          availableTags: data.body.available_tags && data.body.available_tags.map((t) => keysToCamelCase(t)),
        }),
      ),
      put(actions.newSuccessEvent({ message: messages.DELETE_NOTE_SUCCESS })),
    ]);
  } else {
    return getErrorActions({ error, message: messages.ERROR_DELETING_NOTE });
  }
  return null;
}

export default deleteNote;
