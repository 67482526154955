import PropTypes from "prop-types";
import { store } from "rdx/configureStore";
import actions from "rdx/modules/leads/actions";
import { keysToCamelCase } from "lib/helpers";
import { Base } from "./Base";

const {
  createNewLead,
  createNewPublicLead,
  updateLead,
  deleteLead,
  submitLeadToProvider,
  saveAndSubmitLead,
  getLeadGeocode,
  setLeadGeocode,
  updateLeadUtilityBill,
} = actions;

export class Lead extends Base {
  constructor(props) {
    if (!props || Object.keys(props).length === 0) super(defaults);
    else super(keysToCamelCase(props));
  }

  createNewLead = ({ lead, address, enterprise }) => {
    store.dispatch(createNewLead({ lead, address, enterprise }));
  };

  updateLeadUtilityBill = ({ leadId, utilityBillUrl }) => {
    store.dispatch(updateLeadUtilityBill({ leadId, utilityBillUrl }));
  };

  static createNewPublicLead = ({ lead, address, userSlug, referrer_id, marketingLead }) => {
    store.dispatch(
      createNewPublicLead({
        lead,
        address,
        marketingLead,
        userSlug,
        referrer_id,
      }),
    );
  };

  updateLead = ({ lead, address, enterprise, isAdmin, projectId }) => {
    store.dispatch(
      updateLead({
        leadId: this.props.id,
        lead,
        address,
        enterprise,
        admin: isAdmin,
        projectId,
      }),
    );
  };

  deleteLead = ({ isEnterprise, isAdmin }) => {
    store.dispatch(
      deleteLead({
        leadId: this.props.id,
        enterprise: isEnterprise,
        admin: isAdmin,
      }),
    );
  };

  close = (closeReasonId, closeNote) => {
    const { closeLead } = actions;
    store.dispatch(closeLead({ id: this.props.id, closeReasonId, closeNote }));
  };

  reopen = () => {
    const { reopenLead } = actions;
    store.dispatch(reopenLead({ id: this.props.id }));
  };

  submitLead = ({ isEnterprise }) => {
    store.dispatch(submitLeadToProvider({ leadId: this.props.id, enterprise: isEnterprise }));
  };

  saveAndSubmit = ({ lead, address, isEnterprise }) => {
    store.dispatch(saveAndSubmitLead({ leadId: this.props.id, lead, address, isEnterprise }));
  };

  getAddressLatLong = () => {
    if (this.props.address && this.props.address.lat && this.props.address.long) {
      return {
        lat: parseFloat(this.props.address.lat),
        lng: parseFloat(this.props.address.long),
      };
    }
    return null;
  };

  getGeocode = () => {
    const { address } = this.props;
    store.dispatch(getLeadGeocode({ address }));
  };

  setGeocode = ({ lat, lng }) => {
    store.dispatch(setLeadGeocode({ lat, lng }));
  };

  static types() {
    return PropTypes.shape({
      address: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        geocodeLat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        geocodeLong: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        googleMapsUrl: PropTypes.string,
        lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        long: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        state: PropTypes.string,
        status: PropTypes.string,
        street: PropTypes.string,
        unit: PropTypes.string,
        zip: PropTypes.string,
      }),
      apiUri: PropTypes.string,
      averageBill: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      availableProviders: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          name: PropTypes.string,
          needs_transfer: PropTypes.bool,
          transfer_phone: PropTypes.string,
        }),
      ), // availableProviders to be deprecated, do not use
      category: PropTypes.string,
      complete: PropTypes.bool,
      createdAt: PropTypes.string,
      email: PropTypes.string,
      events: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          event_at: PropTypes.string,
          provider: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
      firstName: PropTypes.string,
      fullName: PropTypes.string,
      id: PropTypes.number,
      isAddressConfirmed: PropTypes.bool,
      lastName: PropTypes.string,
      owner: PropTypes.shape({
        id: PropTypes.number,
        fullName: PropTypes.string,
        email: PropTypes.string,
      }),
      notes: PropTypes.string,
      phone: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      providerLeads: PropTypes.arrayOf(PropTypes.object), // see ProviderLead.types()
      submittableStatus: PropTypes.shape({
        status: PropTypes.string,
        unsubmittableReason: PropTypes.string,
      }),
      submitted: PropTypes.bool,
      updatedAt: PropTypes.string,
      utilityBillUrl: PropTypes.string,
    });
  }

  static defaults() {
    return defaults;
  }
}

export const defaults = {
  address: {
    street: "",
    unit: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    status: null,
    lat: null,
    long: null,
  },
  apiUri: "",
  averageBill: "",
  availableProviders: [],
  category: "residential",
  createdAt: null,
  email: "",
  events: [],
  firstName: "",
  fullName: "",
  id: 0,
  isAddressConfirmed: false,
  lastName: "",
  owner: {
    id: 0,
    fullName: "",
    email: "",
  },
  phone: "",
  providerLeads: [],
  submittableStatus: {
    status: "",
    unsubmittableReason: "",
  },
  submitted: false,
  utilityBillUrl: "",
};

export default Lead;
