import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  modules: [],
  moduleDetail: {},
};

export default {
  platformModules: createReducer(initialState, {
    [types.SET_PLATFORM_MODULES](state, action) {
      return {
        ...state,
        modules: action.payload.modules,
      };
    },
    [types.SET_MODULE_DETAIL](state, action) {
      return {
        ...state,
        moduleDetail: action.payload.module,
      };
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};
