import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* updateSmsDetail(action) {
  const params = { ...action.payload };
  const { success, data, error } = yield* makeRequest.patch(`/sms/${params.id}`, params);
  if (success && data) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.SUCCESS_UPDATING_SMS_DETAIL,
          payload: data.body,
        }),
      ),
      put(actions.getSmsList()),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_SMS_DETAIL,
    });
  }
  return null;
}

export default updateSmsDetail;
