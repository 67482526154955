import React from "react";

const ActivityIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#0070F4" />
      <path
        d="M25.6244 19.3655C25.6244 16.2616 23.0915 13.7288 19.9876 13.7288C16.8837 13.7288 14.3508 16.2616 14.3508 19.3655C14.3508 19.8125 14.4005 20.2595 14.5247 20.7065C14.6736 21.4265 14.9468 21.8735 15.3193 22.4943C15.3938 22.6433 15.4931 22.7923 15.5925 22.9661C15.7166 23.1648 15.8159 23.3635 15.9401 23.5373C16.4367 24.3566 16.7347 24.8285 16.7347 25.7969V28.131C16.7347 28.7271 17.1568 29.1988 17.7279 29.2981C17.9763 30.5646 18.7212 31.3591 19.9876 31.3591C21.254 31.3591 22.0237 30.5646 22.2473 29.2981C22.8183 29.1988 23.2406 28.7022 23.2406 28.131V25.7969C23.2406 24.8285 23.5385 24.3318 24.0352 23.5373C24.1344 23.3635 24.2586 23.1648 24.3828 22.9661C24.4822 22.7923 24.5814 22.6433 24.656 22.4943C25.0284 21.8735 25.3015 21.4265 25.4506 20.7065C25.5747 20.2595 25.6244 19.8125 25.6244 19.3655ZM22.2721 26.3928H17.7528V25.8218C17.7528 25.6976 17.7528 25.5982 17.7279 25.4989H22.2721C22.2721 25.5982 22.2473 25.6976 22.2473 25.8218V26.3928H22.2721ZM22.0486 28.3546H17.9266C17.8025 28.3546 17.7279 28.2552 17.7279 28.1559V27.3861H22.2473V28.1559C22.2721 28.2552 22.1728 28.3546 22.0486 28.3546ZM19.9876 30.3908C19.7145 30.3908 19.0192 30.3908 18.746 29.3478H21.254C20.9808 30.3908 20.2608 30.3908 19.9876 30.3908ZM24.4822 20.5078C24.358 21.0541 24.1593 21.4265 23.8116 21.9977C23.7123 22.1466 23.6378 22.2957 23.5385 22.4695C23.4144 22.6681 23.315 22.8668 23.2157 23.0157C22.9177 23.5124 22.6445 23.9594 22.4707 24.4808H17.5541C17.3803 23.9594 17.132 23.5124 16.8092 23.0157C16.7098 22.8419 16.5857 22.6681 16.4863 22.4695C16.3871 22.2957 16.2877 22.1219 16.2131 21.9977C15.8655 21.4017 15.6669 21.0541 15.5427 20.4829C15.4434 20.1104 15.3938 19.738 15.3938 19.3655C15.3938 16.8079 17.4796 14.722 20.0373 14.722C22.5949 14.722 24.6807 16.8079 24.6807 19.3655C24.6311 19.738 24.5814 20.1104 24.4822 20.5078Z"
        fill="white"
      />
      <path
        d="M30.218 18.8672H28.157C27.8838 18.8672 27.6604 19.0906 27.6604 19.3638C27.6604 19.637 27.8838 19.8605 28.157 19.8605H30.218C30.4912 19.8605 30.7146 19.637 30.7146 19.3638C30.7146 19.0906 30.4912 18.8672 30.218 18.8672Z"
        fill="white"
      />
      <path
        d="M25.7484 14.1063C25.8726 14.1063 25.9968 14.0566 26.096 13.9573L27.5612 12.4922C27.7597 12.2936 27.7597 11.9956 27.5612 11.7969C27.3625 11.5983 27.0645 11.5983 26.8659 11.7969L25.4008 13.262C25.2022 13.4606 25.2022 13.7586 25.4008 13.9573C25.5001 14.0566 25.6243 14.1063 25.7484 14.1063Z"
        fill="white"
      />
      <path
        d="M19.9876 11.6951C20.2608 11.6951 20.4842 11.4717 20.4842 11.1985V9.13749C20.4842 8.86434 20.2608 8.64087 19.9876 8.64087C19.7145 8.64087 19.491 8.86434 19.491 9.13749V11.1985C19.491 11.4717 19.7145 11.6951 19.9876 11.6951Z"
        fill="white"
      />
      <path
        d="M13.8791 13.9573C13.9784 14.0566 14.1026 14.1063 14.2267 14.1063C14.3509 14.1063 14.475 14.0566 14.5744 13.9573C14.773 13.7586 14.773 13.4606 14.5744 13.262L13.1093 11.7969C12.9107 11.5983 12.6127 11.5983 12.414 11.7969C12.2154 11.9956 12.2154 12.2936 12.414 12.4922L13.8791 13.9573Z"
        fill="white"
      />
      <path
        d="M11.8427 18.8672H9.7817C9.50855 18.8672 9.28506 19.0906 9.28506 19.3638C9.28506 19.637 9.50855 19.8605 9.7817 19.8605H11.8427C12.1159 19.8605 12.3393 19.637 12.3393 19.3638C12.3393 19.0906 12.1159 18.8672 11.8427 18.8672Z"
        fill="white"
      />
      <path
        d="M13.8791 24.7774L12.414 26.2425C12.2154 26.4411 12.2154 26.7391 12.414 26.9378C12.5134 27.037 12.6375 27.0867 12.7617 27.0867C12.8858 27.0867 13.01 27.037 13.1093 26.9378L14.5744 25.4726C14.773 25.2741 14.773 24.976 14.5744 24.7774C14.3757 24.5787 14.0529 24.5787 13.8791 24.7774Z"
        fill="white"
      />
      <path
        d="M26.096 24.7774C25.8973 24.5787 25.5994 24.5787 25.4008 24.7774C25.2022 24.976 25.2022 25.2741 25.4008 25.4726L26.8659 26.9378C26.9651 27.037 27.0893 27.0867 27.2135 27.0867C27.3376 27.0867 27.4618 27.037 27.5612 26.9378C27.7597 26.7391 27.7597 26.4411 27.5612 26.2425L26.096 24.7774Z"
        fill="white"
      />
    </svg>
  );
};

export default ActivityIcon;
