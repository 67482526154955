import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* requestAdminVisionProposal(action) {
  const { id, requestType, listView, proposalNotes, cloneId } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/projects/${id}/request_vision_proposal`, {
    id,
    request_type: requestType || "over-night",
    proposal_notes: proposalNotes,
    clone_id: cloneId,
  });

  if (success && data && !data.body?.vision_proposal?.error) {
    const responseData = camelizeKeys(data.body);
    if (listView) {
      yield put(actions.setAdminProjectsList({ project: responseData }));
    } else {
      yield put(actions.setAdminProjectDetails(responseData));
    }

    yield put(actions.newSuccessEvent({ message: messages.REQUEST_ADMIN_PROPOSAL_SUCCESS }));
    yield put(actions.setLeadToProposalDrawerProposalRequested());
  } else {
    if (requestType === "self-request") put(actions.setCreateVisionReqSuccessful(false));
    return getErrorActions({
      error: error || data.body?.vision_proposal?.error,
      message: messages.ERROR_REQUESTING_ADMIN_PROPOSAL,
    });
  }
  return null;
}

export default requestAdminVisionProposal;
