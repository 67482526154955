import React from "react";
import { cvar } from "styles";
import { IconT } from "types/utils";

const UnqualifiedLeadsIcon = ({ height = "28", width = "21", fill = cvar("blue-ribbon") }: IconT) => {
  return (
    <div>
      <svg width={width} height={height} viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.44629" y="6.59766" width="18.0485" height="20.3605" rx="1" stroke={fill} strokeWidth="2" />
        <rect x="5.56836" y="1.18359" width="9.80342" height="5.41257" rx="1" stroke={fill} strokeWidth="2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.96676 14.6438H7.56652V17.0842C7.56652 17.2289 7.69061 17.353 7.83538 17.353H9.63467C9.65535 17.353 9.69671 17.353 9.7174 17.3324V15.0781H11.2065V17.353C11.2271 17.353 11.2478 17.353 11.2478 17.353H12.9644C13.1092 17.353 13.2332 17.2289 13.2332 17.0842V14.6438H13.9778C14.2053 14.6438 14.3294 14.3542 14.1639 14.1888L10.7308 10.7143C10.6274 10.6109 10.4619 10.6109 10.3585 10.7143L6.78062 14.1888C6.61517 14.3335 6.71858 14.6438 6.96676 14.6438Z"
          fill={fill}
        />
        <rect
          x="14.0366"
          y="20.125"
          width="1.05192"
          height="7.13229"
          rx="0.525958"
          transform="rotate(90 14.0366 20.125)"
          fill={fill}
        />
        <rect
          x="14.0366"
          y="22.25"
          width="1.05192"
          height="7.13229"
          rx="0.525958"
          transform="rotate(90 14.0366 22.25)"
          fill={fill}
        />
      </svg>
    </div>
  );
};

export default UnqualifiedLeadsIcon;
