// import content from "containers/Sales/tickets";
import tabs from "containers/Sales/tickets/ticketsTabs";
import sidebar from "containers/Sales/tickets/components/Sidebar";
import { permissionTypes } from "hooks/usePermissions";
import { SupportTicketsIcon } from "@icons";
import breadCrumbs from "components/PlatformLayout/components/PlatformPageHeader/Breadcrumbs/TicketsBreadCrumbs";
import l from "@loadable/component";

const content = l(() => import("containers/Sales/tickets"));
// const sidebar = l(() => import("containers/Sales/tickets/components/Sidebar"));

const { TICKETS_VIEW, PREVIEW_USER } = permissionTypes;

export default {
  key: "tickets",
  title: breadCrumbs,
  path: "/tickets/:tab?/:drawerTab?/:messageId?",
  basePath: "tickets",
  requiredParams: ["tab"],
  optionalParams: ["drawerTab", "messageId"],
  linkTarget: "/tickets",
  linkTitle: "Tickets Inbox",
  linkCategory: "Powur",
  icon: SupportTicketsIcon,
  tabs,
  content,
  sidebar,
  permission: [TICKETS_VIEW, PREVIEW_USER],
};
