import { takeEvery, takeLatest } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/users/actionTypes";
import getUsersList from "./getUsersList";
import getRolesList from "./roles/getRolesList";
import getUserDetails from "./getUserDetails";
import getMentorRatings from "./getMentorRatings";
import getAdminMentorRatings from "./getAdminMentorRatings";
import getUserName from "./getUserName";
import addPermissionToRole from "./roles/addPermissionToRole";
import removePermissionFromRoles from "./roles/removePermissionFromRole";
import impersonateUser from "./impersonateUser";
import logbackUser from "./logbackUser";
import addRoleToUser from "./addRoleToUser";
import updateUser from "./updateUser";
import removeRoleFromUser from "./removeRoleFromUser";
import getUsersAutoComplete from "./getUsersAutoComplete";
import getTeamUsersAutoComplete from "./getTeamUsersAutoComplete";
import changeUserSponsor from "./updateUserSponsor";
import changeUserManager from "./updateUserManager";
import changeCertificationDate from "./updateCertificationDate";
import setUserAsComped from "./setUserAsComped";
import activateUserEnrollment from "./activateUserEnrollment";
import addNewRole from "./roles/addNewRole";
import readdUserToLms from "./readdUserToLms";
import cancelSubscriptionByAdmin from "./cancelSubscriptionByAdmin";
import banUser from "./banUser";
import unbanUser from "./unbanUser";
import renewSubscriptionByAdmin from "./renewSubscriptionByAdmin";
import destroySubscriptionByAdmin from "./destroySubscriptionByAdmin";
import getLitmosLoginLink from "./getLitmosLoginLink";
import updateRole from "./roles/updateRole";
import deleteRole from "./roles/deleteRole";
import addNewPermission from "./permissions/addNewPermission";
import deletePermission from "./permissions/deletePermission";
import getPermissionsEntities from "./permissions/getPermissionsEntities";
import createPermissionsEntity from "./permissions/createPermissionsEntity";
import getPermissionsOperations from "./permissions/getPermissionsOperations";
import createPermissionsOperation from "./permissions/createPermissionsOperation";
import updatePermission from "./permissions/updatePermission";
import deletePermissionsEntity from "./permissions/deletePermissionsEntity";
import deletePermissionsOperation from "./permissions/deletePermissionsOperation";
import updatePermissionsEntity from "./permissions/updatePermissionsEntity";
import updatePermissionsOperation from "./permissions/updatePermissionsOperation";
import markTooltipViewed from "./markTooltipViewed";
import getTeamsList from "./getTeamsList";
import createTeam from "./createTeam";
import updateTeam from "./updateTeam";
import deleteTeam from "./deleteTeam";
import updateAdminUsersMentorRating from "./updateAdminUsersMentorRating";
import certifyUser from "./certifyUser";
import getSellerApplicationAgreement from "./getSellerApplicationAgreement";
import getMarketingToken from "./getMarketingToken";
import upgradeAmbassador from "./upgradeAmbassador";
import updateDefaultAdvance from "./updateDefaultAdvance";
// IMPORT_PT (for script, do not remove)

function* watchUsersSagas() {
  yield trackRequests(takeEvery, types.GET_USERS_LIST_REQUEST, getUsersList);
  yield trackRequests(takeEvery, types.GET_USER_ROLES_LIST_REQUEST, getRolesList);
  yield trackRequests(takeEvery, types.GET_USER_DETAILS_REQUEST, getUserDetails);
  yield trackRequests(takeEvery, types.GET_MENTOR_RATINGS_REQUEST, getMentorRatings);
  yield trackRequests(takeEvery, types.GET_ADMIN_MENTOR_RATINGS_REQUEST, getAdminMentorRatings);
  yield trackRequests(takeEvery, types.GET_USER_NAME_REQUEST, getUserName);
  yield trackRequests(takeEvery, types.ADD_PERMISSION_TO_ROLE_REQUEST, addPermissionToRole);
  yield trackRequests(takeEvery, types.REMOVE_PERMISSION_FROM_ROLE_REQUEST, removePermissionFromRoles);
  yield trackRequests(takeEvery, types.IMPERSONATE_USER_REQUEST, impersonateUser);
  yield trackRequests(takeEvery, types.LOGBACK_USER_REQUEST, logbackUser);
  yield trackRequests(takeLatest, types.UPDATE_USER_REQUEST, updateUser);
  yield trackRequests(takeEvery, types.ADD_ROLE_TO_USER_REQUEST, addRoleToUser);
  yield trackRequests(takeEvery, types.REMOVE_ROLE_FROM_USER_REQUEST, removeRoleFromUser);
  yield trackRequests(takeEvery, types.GET_USERS_AUTOCOMPLETE_REQUEST, getUsersAutoComplete);
  yield trackRequests(takeEvery, types.GET_TEAM_USERS_AUTOCOMPLETE_REQUEST, getTeamUsersAutoComplete);
  yield trackRequests(takeEvery, types.CHANGE_USER_SPONSOR_REQUEST, changeUserSponsor);
  yield trackRequests(takeEvery, types.CHANGE_USER_MANAGER_REQUEST, changeUserManager);
  yield trackRequests(takeEvery, types.CHANGE_USER_CERTIFICATION_DATE_REQUEST, changeCertificationDate);
  yield trackRequests(takeEvery, types.SET_USER_AS_COMPED_REQUEST, setUserAsComped);
  yield trackRequests(takeEvery, types.ACTIVATE_USER_ENROLLMENT_REQUEST, activateUserEnrollment);
  yield trackRequests(takeEvery, types.ADD_NEW_ROLE_REQUEST, addNewRole);
  yield trackRequests(takeEvery, types.READD_USER_TO_LMS, readdUserToLms);
  yield trackRequests(takeEvery, types.CANCEL_SUBSCRIPTION_BY_ADMIN, cancelSubscriptionByAdmin);
  yield trackRequests(takeEvery, types.BAN_USER_REQUEST, banUser);
  yield trackRequests(takeEvery, types.UNBAN_USER_REQUEST, unbanUser);
  yield trackRequests(takeEvery, types.RENEW_SUBSCRIPTION_BY_ADMIN, renewSubscriptionByAdmin);
  yield trackRequests(takeEvery, types.DESTROY_SUBSCRIPTION_BY_ADMIN, destroySubscriptionByAdmin);
  yield trackRequests(takeEvery, types.GET_LITMOS_LOGIN_LINK, getLitmosLoginLink);
  yield trackRequests(takeEvery, types.UPDATE_ROLE_REQUEST, updateRole);
  yield trackRequests(takeEvery, types.DELETE_ROLE_REQUEST, deleteRole);
  yield trackRequests(takeEvery, types.ADD_NEW_PERMISSION_REQUEST, addNewPermission);
  yield trackRequests(takeEvery, types.DELETE_PERMISSION_REQUEST, deletePermission);
  yield trackRequests(takeEvery, types.GET_PERMISSIONS_ENTITIES_REQUEST, getPermissionsEntities);
  yield trackRequests(takeEvery, types.CREATE_PERMISSIONS_ENTITY_REQUEST, createPermissionsEntity);
  yield trackRequests(takeEvery, types.GET_PERMISSIONS_OPERATIONS_REQUEST, getPermissionsOperations);
  yield trackRequests(takeEvery, types.CREATE_PERMISSIONS_OPERATION_REQUEST, createPermissionsOperation);
  yield trackRequests(takeEvery, types.UPDATE_PERMISSION_REQUEST, updatePermission);
  yield trackRequests(takeEvery, types.DELETE_PERMISSIONS_ENTITY_REQUEST, deletePermissionsEntity);
  yield trackRequests(takeEvery, types.DELETE_PERMISSIONS_OPERATION_REQUEST, deletePermissionsOperation);
  yield trackRequests(takeEvery, types.UPDATE_PERMISSIONS_ENTITY_REQUEST, updatePermissionsEntity);
  yield trackRequests(takeEvery, types.UPDATE_PERMISSIONS_OPERATION_REQUEST, updatePermissionsOperation);
  yield trackRequests(takeEvery, types.MARK_TOOLTIP_VIEWED_REQUEST, markTooltipViewed);
  yield trackRequests(takeEvery, types.GET_TEAMS_LIST_REQUEST, getTeamsList);
  yield trackRequests(takeEvery, types.CREATE_TEAM_REQUEST, createTeam);
  yield trackRequests(takeEvery, types.UPDATE_TEAM_REQUEST, updateTeam);
  yield trackRequests(takeEvery, types.DELETE_TEAM_REQUEST, deleteTeam);
  yield trackRequests(takeEvery, types.UPDATE_ADMIN_USERS_MENTOR_RATING, updateAdminUsersMentorRating);
  yield trackRequests(takeEvery, types.CERTIFY_USER, certifyUser);
  yield trackRequests(takeEvery, types.GET_SELLER_APPLICATION_AGREEMENT_REQUEST, getSellerApplicationAgreement);
  yield trackRequests(takeEvery, types.GET_MARKETING_TOKEN, getMarketingToken);
  yield trackRequests(takeEvery, types.UPGRADE_AMBASSADOR_REQUEST, upgradeAmbassador);
  yield trackRequests(takeEvery, types.UPDATE_DEFAULT_ADVANCE, updateDefaultAdvance);
  // INSERTION_PT (for script, do not remove)
}

export default watchUsersSagas;
