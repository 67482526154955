export default {
  getCurrentUser: (state) => state.rbac.user,
  getUserPermissions: (state) => state.rbac.permissions,
  getInboxCount: (state) => state.rbac.user.inboxCount,
  getMyProjectsCount: (state) => state.rbac.user.myProjectsCount,
  getInboxUnviewedMessagesCount: (state) => state.rbac.user.inboxUnviewedMessagesCount,
  getInboxUnviewedArchivedCount: (state) => state.rbac.user.inboxUnviewedArchivedCount,
  selectUserEnrollmentStartDay: (state) => state.rbac.user.enrollment?.startDay,
  getCurrentUserEnrollmentStatus: (state) => state.rbac.user?.enrollment?.paymentStatus !== "active",
};
