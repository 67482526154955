import { useContext } from "react";
import { ReactReduxContext } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";

export function useRdx(actionMap, stateMap) {
  const { store } = useContext(ReactReduxContext);
  const mapStateToProps = getRdxSelectionMapper(stateMap);
  const mapDispatchToProps = getRdxActionMapper(actionMap);
  const props = {
    ...mapStateToProps(store.getState()),
    ...mapDispatchToProps(store.dispatch),
  };
  return props;
}

export function useRdxActions(actionMap) {
  const { store } = useContext(ReactReduxContext);
  const mapDispatchToProps = getRdxActionMapper(actionMap);
  const props = {
    ...mapDispatchToProps(store.dispatch),
  };
  return props;
}

export function useRdxState(stateMap) {
  const { store } = useContext(ReactReduxContext);
  const mapStateToProps = getRdxSelectionMapper(stateMap);
  const props = {
    ...mapStateToProps(store.getState()),
  };
  return props;
}
