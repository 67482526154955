import z from "zod";
import { AvatarSchema, UserLiteSchema } from "../../../users/schemas/users";

export enum DisplayRoles {
  LEAD_OWNER = "lead owner",
  SELLER = "seller",
  CLOSER = "closer",
  PROJECT_MANAGER = "project manager",
  CONSULTANT = "consultant",
  AMBASSADOR = "ambassador",
  LEAD_GENERATOR = "lead_generator",
}

export const ProjectUserSchema = z
  .object({
    avatar: AvatarSchema,
    role: z.nativeEnum(DisplayRoles),
  })
  .merge(UserLiteSchema.pick({ id: true, firstName: true, lastName: true, email: true, phone: true }));

export const ProjectBaseSchema = z.object({
  projectUsers: z.array(ProjectUserSchema),
});
