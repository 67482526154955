import React from "react";
import { css } from "@emotion/react";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import GradientButton from "components/Buttons/GradientButton";
import CloseButton from "components/Buttons/CloseButton";

import { cvar } from "styles";

export type ModalContainerPropsT = {
  open: boolean;
  onClose?: () => void;
  handleCancel: () => void;
  handleSave: () => void;
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  titleStyles?: React.CSSProperties;
  submitButtonText?: string;
};

const ModalContainer = ({
  onClose,
  open,
  children,
  handleCancel,
  handleSave,
  title,
  titleStyles,
  submitButtonText,
}: ModalContainerPropsT) => {
  const onCancel = () => {
    handleCancel();
    if (onClose) onClose();
  };
  const onSave = () => {
    handleSave();
  };

  return (
    <Dialog
      onClose={onCancel}
      open={open}
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "12px",
        },
      }}
    >
      {title && (
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "700",
            lineHeight: "25px",
            textTransform: "capitalize",
            fontFamily: "Poppins",
            color: cvar("white"),
            fontSize: "24px",
            background: "linear-gradient(270.54deg, #20C2F1 49.88%, #0070F4 135.73%)",
            ...titleStyles,
          }}
        >
          <div
            css={css`
              margin: 0 111px;
            `}
          >
            {title}
          </div>
        </DialogTitle>
      )}
      <DialogContent sx={{ paddingBottom: "0px" }}>{children}</DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          padding: "14px",
          background: cvar("dark-blue-a07"),
        }}
      >
        <CloseButton action={onCancel} buttonTypeStyle="gradient" />
        <GradientButton onClick={onSave} style={{ width: "97px" }}>
          {submitButtonText || "Submit"}
        </GradientButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModalContainer;
