type StringOrBoolT = boolean | "true" | "false";

const convert = {
  true: true,
  false: false,
};

const convertToBool = (value: StringOrBoolT): boolean => {
  if (typeof value === "boolean") return value;
  return convert[value];
};

export default convertToBool;
