import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const InjectFacebookPixel = (props) => {
  const { facebookPixelId } = props;

  const [idPresent, setIdPresent] = useState(false);

  useEffect(() => {
    return parseInt(facebookPixelId, 10) > 999999999 ? setIdPresent(true) : setIdPresent(false);
  }, [facebookPixelId]);

  const pixelScript = `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${facebookPixelId}');
  fbq('track', 'PageView');
  `;

  const pixelNoScript = `<img height="1" width="1" style="display:none"
   src="https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1" />`;

  if (idPresent) {
    return (
      <Helmet>
        <script>{pixelScript}</script>
        <noscript>{pixelNoScript}</noscript>
      </Helmet>
    );
  }
  return null;
};

InjectFacebookPixel.propTypes = {
  facebookPixelId: PropTypes.string,
};

export default InjectFacebookPixel;
