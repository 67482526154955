import { styled } from "@mui/material/styles";
import { theme } from "styles/themes";
import { mq } from "styles";

export const InfographicContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  color: theme.colors.white,
  fontSize: "24px",
  lineHeight: "28px",
  fontWeight: 400,
  rowGap: "22px",
  paddingTop: "12px",
  [mq.phoneOnly]: {
    width: "100%",
  },
  [mq.tabletUp]: {
    width: "275px",
  },
}));

export const Divider = styled("div")(() => ({
  width: "100%",
  background: theme.colors["white-a20"],
  height: "1px",
}));

export const Row = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  [mq.phoneOnly]: {
    columnGap: "30px",
  },
  [mq.tabletUp]: {
    columnGap: "42px",
  },
}));

export const IconCol = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "63px",
}));

export const TextCol = styled("div")(() => ({
  width: "75px",
}));
