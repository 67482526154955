import React from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import { useParams } from "react-router";

// Hooks
import useNavigation from "hooks/useNavigation";

import adminFinancialsTabs from "../../tabs";

import AmbassadorSidebar from "./AmbassadorSidebar";
import M2sSidebar from "./M2sSidebar";
import styles from "../../AdminFinancials.module.less";

const AdminFinancialsSidebar = () => {
  const tabKeys = adminFinancialsTabs.map(({ key }) => key);

  const { tab: activeTabKey } = useParams();

  const { navigate } = useNavigation("admin-financials", ["tab"]);

  switch (activeTabKey) {
    case tabKeys[1]: // Ambassador program
      return (
        <div className={styles.sidebar}>
          <AmbassadorSidebar navigate={navigate} />
        </div>
      );
    case tabKeys[0]: // M2s
      return (
        <div className={styles.sidebar}>
          <M2sSidebar navigate={navigate} />
        </div>
      );
    default:
      return <></>;
  }
};

// AdminFinancialsSidebar.propTypes = {};

export default connect(getRdxSelectionMapper({}), getRdxActionMapper([]))(AdminFinancialsSidebar);
