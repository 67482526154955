import React, { useState, memo } from "react";
import { Modal, Button, Row, Tooltip, Col } from "antd";
import useRestrictAccessModal from "hooks/useRestrictAccessModal";
import copy from "copy-to-clipboard";
import styles from "../MainNav.module.less";

const PreSalesSupportModal = (props) => {
  const { modalVisible, setModalVisible } = props;

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [keepOpen, setKeepOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const { isUnpaidUser, restrictAccessModal } = useRestrictAccessModal();

  const handleChatClick = () => {
    if (isUnpaidUser) {
      restrictAccessModal();
    } else {
      window.open("https://help.powur.com/hc/en-us/signin", "_blank");
    }
  };

  const handleClick = (label, data) => {
    if (isUnpaidUser) {
      restrictAccessModal();
    } else {
      setTooltipText(`${label} copied to clipboard`);
      copy(data);
      setCopied(true);
      setTooltipVisible(true);
      setKeepOpen(true);
      setTimeout(() => {
        setKeepOpen(false);
        setTooltipVisible(false);
        setCopied(false);
      }, 1000);
    }
  };

  const handleInfoItemClick = (data) => {
    if (isUnpaidUser) {
      restrictAccessModal();
    } else {
      window.open(data);
    }
  };

  const handleHover = (label) => {
    setTooltipText(label);
    setTooltipVisible(true);
  };

  const handleMouseOff = () => {
    if (!keepOpen) {
      setTooltipVisible(false);
    }
  };

  const InfoItem = ({ label, data, additionalContent, link }) => {
    const innerContent = link ? (
      <>
        <Button
          className={[
            styles.salesSupportLink,
            ...(label === "Sales Support Hours" ? [styles.salesSupportHoursBtn] : [styles.salesSupportCalendarBtn]),
          ].join(" ")}
          shape="round"
          onClick={() => handleInfoItemClick(data)}
        >
          {additionalContent}
        </Button>
        <Tooltip
          title={tooltipText}
          placement="top"
          className={styles.modalTooltip}
          open={tooltipVisible && !link && (tooltipText === label || tooltipText === `${label} copied to clipboard`)}
          disabled
        />
      </>
    ) : (
      <>
        <Button
          className={[
            styles.salesSupportLink,
            ...(label === "Email" ? [styles.salesSupportEmailBtn] : [styles.salesSupportEmailBtnSp]),
          ].join(" ")}
          shape="round"
        >
          {data} {additionalContent}
        </Button>
        <Tooltip
          title={copied ? `${label} copied to clipboard` : label}
          placement="top"
          className={styles.modalTooltip}
          open={tooltipVisible && !link && (tooltipText === label || tooltipText === `${label} copied to clipboard`)}
          disabled
        />
      </>
    );
    return (
      <>
        <p className={styles.salesSupportTitle}>{label}</p>
        <div className={styles.spacerMedium} />

        <div
          onClick={() => handleClick(label, data)}
          onMouseEnter={() => handleHover(label)}
          onMouseLeave={handleMouseOff}
          onKeyDown={() => handleClick(label, data)}
          className={styles.btnData}
          tabIndex={0}
          role="button"
        >
          {innerContent}
        </div>
      </>
    );
  };

  return (
    <Modal
      title="Sales Support"
      open={modalVisible}
      centered
      maskStyle={{ backgroundColor: "var(--mask-blue-a75)" }}
      footer={null}
      onCancel={() => setModalVisible(false)}
      className={styles.preSalesSupportLinkModal}
    >
      <Row>
        <Col span={12}>
          <>
            <p className={styles.salesSupportTitle}>Chat</p>
            <div className={styles.spacerMedium} />

            <Button
              shape="round"
              onClick={handleChatClick}
              onKeyDown={handleChatClick}
              className={`${styles.salesSupportLink} ${styles.salesSupportChatBtn}`}
              tabIndex={0}
              role="button"
            >
              Launch Chat on help.powur.com
            </Button>
          </>
          <div className={styles.spacerMedium} />
          <div className={styles.spacer} />
          <Row>
            <InfoItem label="Email" data="support@powur.com" />
            <div className={styles.spacer} />
            <InfoItem label="Email (Espanol)" data="support@powur.com" />
          </Row>
          <div className={styles.spacerMedium} />
          <div className={styles.spacer} />
          <Row>
            <InfoItem
              label="Upcoming Powur Events"
              data="http://powurcalendar.com/month-at-a-glance"
              additionalContent="Powur Calendar"
              link
            />
          </Row>
          <div className={styles.spacerMedium} />
          <div className={styles.spacer} />
          <Row>
            <InfoItem
              label="Information On Contacting"
              data="https://help.powur.com/hc/en-us/articles/9505564018075"
              additionalContent="Seller Support Contacts"
              link
            />
          </Row>
        </Col>
        <Col span={12}>
          <Row className={styles.salesSupportInfoTitle}>Hours of Operation</Row>
          <div className={styles.spacer} />
          <div className={styles.supportModalHoursInfo}>
            Sales Support{" "}
            <p className={styles.supportModalHoursContent}>Mon – Fri, 8am — 5pm pst (Chat and Email only) </p>
          </div>
          <div className={styles.supportModalHoursInfo}>
            Change Orders
            <p className={styles.supportModalHoursContent}>Mon – Fri 8am — 5pm pst (Chat, Tickets, Email)</p>
          </div>
          <div className={styles.supportModalHoursInfo}>
            Homeowner Support
            <p className={styles.supportModalHoursContent}>Mon – Fri, 8am — 5pm pst (Chat, Email)</p>
          </div>
          <div className={styles.supportModalHoursInfo}>
            Project Acknowledgement (Welcome Call)
            <p className={styles.supportModalHoursContent}>Mon – Fri, 8am — 5pm pst (Chat, Tickets, Email)</p>
          </div>
          <div className={styles.supportModalHoursInfo}>
            Project Intake
            <p className={styles.supportModalHoursContent}>Mon – Fri, 8am — 5pm pst (Chat, Tickets, Email)</p>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default memo(PreSalesSupportModal);
