type openLinkNewTabT = {
  href: string;
  target: string;
  rel: string;
};

export default function openLinkNewTab(target: string): openLinkNewTabT {
  return {
    href: target,
    target: "_blank",
    rel: "noopener noreferrer",
  };
}
