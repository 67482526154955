import React, { useEffect } from "react";

import { CloseIconOutlinedSquare } from "@icons";
import { Drawer } from "@mui/material";

import useWindowResize from "hooks/useWindowResize";
import { cvar } from "styles/colorVariables";
import HorizontalTimeline from "../../HorizontalTimeline";
import Tabs from "../../Tabs";

import * as S from "./styles";

type DrawerTabsT = {
  tabs: { content: React.ReactNode; label: string; requiresAttention?: boolean }[];
  currentTab: number;
  setCurrentTab: (tab: number) => void;
};

type BasicDrawerT = {
  visible: boolean;
  setVisible: (bool: boolean) => void;
  width: number;
  onClose: () => void;
  headerContent?: React.ReactNode | string;
  tabInfo?: DrawerTabsT;
  showTimelineDisplay?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  footerContent?: React.ReactNode;
  drawerContentRef: React.LegacyRef<HTMLDivElement>;
};

const BasicDrawer = ({
  visible,
  setVisible,
  width,
  onClose,
  headerContent,
  children,
  footerContent,
  tabInfo,
  showTimelineDisplay = false,
  drawerContentRef,
}: BasicDrawerT) => {
  const { isMobileWidth } = useWindowResize();
  useEffect(() => {
    const closeOnKeydown = (e) => {
      if (e.keyCode === 27) {
        closeDrawer();
      }
    };

    if (visible) {
      window.addEventListener("keydown", closeOnKeydown);
    } else {
      window.removeEventListener("keydown", closeOnKeydown);
    }

    return () => window.removeEventListener("keydown", closeOnKeydown);
  }, [visible, setVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeDrawer = () => {
    setVisible(false);
    onClose();
  };

  return (
    <Drawer
      anchor={isMobileWidth ? "bottom" : "right"}
      open={visible}
      onClose={closeDrawer}
      PaperProps={{
        sx: {
          width: isMobileWidth ? width - 16 : width,
          ...(isMobileWidth
            ? {
                top: "48px",
                borderRadius: "10px 10px 0px 0px",
                backgroundColor: cvar("ma-white"),
                marginLeft: "8px",
                marginRight: "8px",
              }
            : {}),
        },
      }}
    >
      <S.DrawerHeader>
        <S.CloseDrawerIcon
          role="button"
          tabIndex={0}
          onClick={closeDrawer}
          onKeyDown={closeDrawer}
          data-test-id="close-drawer"
        >
          {!isMobileWidth && <CloseIconOutlinedSquare />}
        </S.CloseDrawerIcon>
        <S.HeaderContent>{headerContent}</S.HeaderContent>
      </S.DrawerHeader>
      {tabInfo && !showTimelineDisplay && (
        <Tabs tabs={tabInfo.tabs} currentTab={tabInfo.currentTab} setCurrentTab={tabInfo.setCurrentTab} />
      )}
      <S.DrawerContent ref={drawerContentRef}>
        {tabInfo && showTimelineDisplay && (
          <HorizontalTimeline
            timelineData={tabInfo.tabs}
            currentTab={tabInfo.currentTab}
            setCurrentTab={tabInfo.setCurrentTab}
          />
        )}
        {children}
      </S.DrawerContent>
      {!!footerContent && <S.Footer style={{ width: isMobileWidth ? width - 16 : width }}>{footerContent}</S.Footer>}
    </Drawer>
  );
};

export default BasicDrawer;
