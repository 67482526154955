import React, { useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import selectors from "rdx/selectors";

import { isEmpty } from "lodash";
import { Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";

import styles from "components/PlatformLayout/components/PlatformPageHeader/PlatformPageHeader.module.less";

const { Title } = Typography;

const EnterpriseApplicationBreadCrumbs = () => {
  const enterpriseDetails = useSelector(selectors.selectEnterpriseApplicationDetail);

  const buildPartnerDetails = useSelector(selectors.selectBuildPartnerApplicationDetail);

  const { params } = useRouteMatch();
  const isDetail = !!params.appId;

  const isBuildPartner = !!useRouteMatch("/admin-enterprise/build-partner");
  const isEntPro = !!useRouteMatch("/admin-enterprise/enterprise-applications");

  const breadcrumb = useCallback(() => {
    if (isBuildPartner) {
      return "Build Partner";
    }
    if (isEntPro) {
      return "Enterprise";
    }
    return null;
  }, [isBuildPartner, isEntPro]);

  const history = useHistory();

  const handleBaseNavigate = () => {
    history.push("/admin-enterprise");
  };

  const handleTabNavigate = () => {
    const tabPush = isBuildPartner ? "/admin-enterprise/build-partner" : "/admin-enterprise/enterprise-applications";
    history.push(tabPush);
  };

  const textStyle = {
    cursor: "pointer",
    textTransform: "capitalize",
    fontWeight: 600,
  };

  const buildPartnerName = !isEmpty(buildPartnerDetails)
    ? `${buildPartnerDetails.firstName} ${buildPartnerDetails.lastName}`
    : null;
  const enterpriseOwnerName = !isEmpty(enterpriseDetails)
    ? `${enterpriseDetails.ownerFirstName} ${enterpriseDetails.ownerLastName}`
    : null;

  return (
    <>
      <Title className={styles.title} style={textStyle} onClick={handleBaseNavigate}>
        Admin Enterprise
      </Title>
      {breadcrumb() && (
        <>
          <RightOutlined className={styles.title} style={{ margin: "0 10px" }} />
          <Title className={styles.title} style={textStyle} onClick={handleTabNavigate}>
            {breadcrumb()}
          </Title>
        </>
      )}
      {isDetail && (
        <>
          <RightOutlined className={styles.title} style={{ margin: "0 10px" }} />
          <Title className={styles.title} style={textStyle}>
            {buildPartnerName || enterpriseOwnerName}
          </Title>
        </>
      )}
    </>
  );
};

export default EnterpriseApplicationBreadCrumbs;
