import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { cvar } from "styles";
import { Button, Input } from "@mui/material";

// CSS
export const card = css`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  margin-bottom: 24px;
`;

export const Label = styled.div`
  text-transform: uppercase;
  color: ${cvar("nepal-blue")};
  font-size: 10px;
  font-weight: 600;
  line-height: 9px;
  padding-bottom: 8px;
`;

export const Heading = styled.div`
  color: ${cvar("banner-blue")};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 20px 0px 16px 0px;
`;

export const MessageText = styled.div`
  color: ${cvar("dark-blue")};
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  font-style: italic;
  margin-top: 16px;
`;

export const Text = styled.div`
  color: ${cvar("dark-blue")};
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  padding: 12px 0 4px 0;
`;

export const DateRangeInputsWrapper = styled.div`
  max-width: 393px;
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${cvar("botticelli-blue")};
  border-radius: 3px;
  background: ${cvar("white")};
  padding: 0px 8px;
`;

const DateRangeInputBase = styled(Input)`
  width: 40%;
  height: 100%;
  border: none;
  background: transparent;
  color: ${cvar("dark-blue")};
  font-weight: 600;
  font-size: 14px;
`;

export const DateRangeInputFrom = styled(DateRangeInputBase)`
  & .MuiInput-input {
    text-align: start;
  }
`;

export const DateRangeInputTo = styled(DateRangeInputBase)`
  & .MuiInput-input {
    text-align: center;
  }
`;

export const FileUploader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 3px;
  border: 1px solid ${cvar("botticelli-blue")};
`;

export const DisplayFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonText = styled(Button)`
  color: ${cvar("dark-blue")};
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  border: none;
  text-transform: capitalize;
`;

export const UploadingFileText = styled.div`
  color: ${cvar("dark-blue")};
  font-size: 16px;
  font-weight: 600;
  line-height: 17px;
  max-width: 240px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
`;

export const DisplayColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
  margin-top: 5px;
`;
