export default {
  GET_ADMIN_PROJECTS_LIST_REQUEST: "[adminProjects] GET Admin Projects List Request",

  SET_ADMIN_PROJECTS_LIST_FILTERS: "[adminProjects] SET Admin Projects List Filters",

  SET_ADMIN_PROJECTS_LIST: "[adminProjects] SET Admin Projects List",

  RESET_ADMIN_PROJECTS_LIST: "[adminProjects] RESET Admin Projects List",

  RESET_ADMIN_PROJECT_DETAILS: "[adminProjects] RESET Admin Projects Details",

  SET_ADMIN_PROJECTS_AUTOCOMPLETE_SUGGESTIONS: "[adminProjects] SET Admin Projects Autocomplete Suggestions",

  GET_ADMIN_PROJECT_DETAILS_REQUEST: "[adminProjects] GET Admin Project Details Request",
  SET_ADMIN_PROJECT_DETAILS: "[adminProjects] SET Admin Project Details",

  UPDATE_ADMIN_PROJECT_REQUEST: "[adminProjects] Update Admin Project Request",

  UPDATE_PROJECT_MANAGEMENT_USERS_REQUEST: "[adminProjects] Update Project Management Users Request",

  GET_PROJECT_MILESTONES_REQUEST: "[adminProjects] GET Project Milestones Request",

  SET_PROJECT_MILESTONES: "[adminProjects] SET Project Milestones",

  UPDATE_PROJECT_MILESTONES_REQUEST: "[adminProjects] Update Project Milestones Request",
  UPDATE_ADMIN_PROJECT_LEAD_REQUEST: "[adminProjects] Update Admin Project Lead Request",

  GET_ADMIN_PROJECT_NOTES_REQUEST: "[adminProjects] GET Admin Project Notes Request",
  SET_ADMIN_PROJECT_NOTES: "[adminProjects] SET Admin Project Notes",

  GET_PROJECT_DATES_REQUEST: "[adminProjects] GET Admin Project Notes Request",

  SET_PROJECT_DATES: "[adminProjects] SET Admin Project Notes",

  GET_ADMIN_PROJECT_PROPOSALS_REQUEST: "[adminProjects] GET Admin Project Proposals Request",
  SET_ADMIN_PROJECT_PROPOSALS: "[adminProjects] SET Admin Project Proposals",

  ADMIN_REVISION_REQUEST: "[adminProjects] Admin Revision Request",

  ADMIN_VISION_REVISION_REQUEST: "[adminProjects] Admin Vision Revision Request",

  CLOSE_ADMIN_PROJECT_REQUEST: "[adminProjects] Close Admin Project Request",

  REOPEN_ADMIN_PROJECT_REQUEST: "[adminProjects] Reopen Admin Project Request",

  REQUEST_ADMIN_PROPOSAL: "[adminProjects] Request Admin Proposal",

  REQUEST_ADMIN_VISION_PROPOSAL: "[adminProjects] Request Admin Vision Proposal",

  SET_CREATE_ADMIN_VISION_REQ_SUCCESS: "[adminProjects] Admin Create Vision Proposal Request Success",

  GET_ADMIN_PROJECT_ADDRESS_GEOCODE: "[adminProjects] GET Admin Project Address Geocode",

  SET_ADMIN_PROJECT_ADDRESS_GEOCODE: "[adminProjects] SET Admin Project Address Geocode",

  SET_ADMIN_PROPOSAL_SETTINGS: "[adminProjects] SET Admin Proposal Settings",

  GET_ADMIN_PROPOSAL_SETTINGS: "[adminProjects] GET Admin Proposal Settings",

  GET_AUTOCOMPLETE_LOOKUP: "[adminProjects] GET Autocomplete Lookup",

  SET_USERS_AUTOCOMPLETE_LOOKUP: "[adminProjects] SET Users Autocomplete Lookup",

  SET_LOCATIONS_AUTOCOMPLETE_LOOKUP: "[adminProjects] SET Locations Autocomplete Lookup",

  SET_TEAMS_AUTOCOMPLETE_LOOKUP: "[adminProjects] SET Teams Autocomplete Lookup",

  SET_UTILITIES_AUTOCOMPLETE_LOOKUP: "[adminProjects] SET Utilities Autocomplete Lookup",

  SET_INSTALLER_AUTOCOMPLETE_LOOKUP: "[adminProjects] SET Installer Autocomplete Lookup",

  GET_ADMIN_PROJECT_LOOKUP_OPTIONS: "[adminProjects] GET Admin Project Lookup Options",

  SET_ADMIN_PROJECT_LOOKUP_OPTIONS: "[adminProjects] SET Admin Project Lookup Options",

  SET_SELECTED_PROJECT_DETAIL: "[adminProjects] SET Selected Project Detail",

  GET_SELECTED_PROJECT_DETAIL: "[adminProjects] GET Selected Project Detail",

  SET_CHANGE_ORDER_BLOCKER_COMPLETE_FOR_ADMIN: "[adminProjects] SET Change Order Blocker Complete for Admin",
  REMOVE_CO_BLOCKER_FROM_MODAL_FOR_ADMIN: "[adminProjects] SET Remove Change Order Blocker from Modal for Admin",

  SET_AUTOCOMPLETE_SELECTED_OPTION: "[adminProjects] SET AutoComplete selected option",
  SET_AUTOCOMPLETE_SELECTED_OPTIONS: "[adminProjects] SET AutoComplete selected options",
  // INSERTION_PT (for script, do not remove)
};
