import React from "react";
import PropTypes from "prop-types";
import { DocumentIcon } from "components/Icons";
import { Row } from "antd";
import styles from "../styles.module.less";

const FileUploaded = ({ fileSize, fileUrl, setFileUrl, setFileSize }) => {
  const formatFileSize = (bytes) => {
    if (bytes) return `${(bytes / 1024).toFixed(1)}KB`;
    return null;
  };

  const formatFileName = (url) => {
    if (url) return url.split("/").pop();
    return null;
  };

  const removeFile = () => {
    setFileUrl(null);
    setFileSize(null);
  };

  return (
    <div className={styles.dropzoneSolidBorder}>
      <Row className={styles.previewRow}>
        <div className={styles.fileDetails}>
          <span className={styles.documentIcon}>
            <DocumentIcon />
          </span>

          <div className={styles.fileName}>
            <span className={styles.name}>{formatFileName(fileUrl)}</span>
            <span className={styles.size}>{formatFileSize(fileSize)}</span>
          </div>
        </div>

        <div className={styles.buttonSection}>
          <span role="button" tabIndex={0} onKeyDown={removeFile} onClick={removeFile} className={styles.deleteButton}>
            Remove
          </span>
        </div>
      </Row>
    </div>
  );
};

FileUploaded.propTypes = {
  fileSize: PropTypes.number,
  fileUrl: PropTypes.string,
  setFileUrl: PropTypes.func,
  setFileSize: PropTypes.func,
};

export default FileUploaded;
