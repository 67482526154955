import PropTypes from "prop-types";

export const powurOsDataSchema = PropTypes.shape({
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.shape({
        oid: PropTypes.string,
      }),
      createdAt: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  milestones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.shape({
        oid: PropTypes.string,
      }),
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  netRevenue: PropTypes.string,
});
