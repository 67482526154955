import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../careers.module.less";

const Divider = () => {
  const { t } = useTranslation("careers");
  return (
    <div className={styles.innerContainer}>
      <div>
        <h5 className={styles.heading}>{t("work_with_us")}</h5>
        <h2 className={styles.subheading}>{t("current_available_jobs")}</h2>
      </div>
    </div>
  );
};

export default Divider;
