import React from "react";
import { IconT } from "types/utils";

const ConfettiYellowIcon = ({ height = "44", width = "43" }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_238_1017)">
        <path
          d="M0.687605 42.3226C1.38409 43.0191 2.44139 43.2067 3.33493 42.7923L27.5854 32.3516C28.7723 31.8012 29.0497 30.2378 28.1246 29.3126L13.75 14.938C12.8248 14.0129 11.2614 14.2902 10.711 15.4773L0.21791 39.6753C-0.196445 40.5688 -0.00887755 41.6261 0.687605 42.3226Z"
          fill="#FFE266"
        />
        <path
          d="M20.8887 30.0643L3.06236 37.7391C2.43234 38.0313 1.69233 37.9154 1.18108 37.4538L0.217819 39.6752C-0.196537 40.5688 -0.0089691 41.626 0.687513 42.3225C1.38399 43.019 2.4413 43.2065 3.33483 42.7922L27.3527 32.4517C26.7994 32.2299 20.9787 30.0226 20.8887 30.0643Z"
          fill="#F1B620"
        />
        <path
          d="M28.2925 31.371C29.9496 29.714 27.5404 24.6181 22.9114 19.9892C18.2824 15.3602 13.1866 12.951 11.5295 14.608C9.87247 16.2651 12.2817 21.3609 16.9107 25.9899C21.5396 30.6189 26.6355 33.0281 28.2925 31.371Z"
          fill="#FFE266"
        />
        <path
          d="M24.093 29.2357C25.18 28.1487 23.4193 24.6257 20.1604 21.3668C16.9015 18.1078 13.3784 16.3472 12.2914 17.4342C11.2044 18.5212 12.9651 22.0442 16.224 25.3031C19.483 28.562 23.006 30.3227 24.093 29.2357Z"
          fill="#F1B620"
        />
        <path
          d="M26.8011 32.7579C24.1595 32.7579 20.0687 30.3636 16.3053 26.6002C12.8879 23.1827 10.5462 19.4191 10.1942 16.7781C10.1314 16.3062 10.4628 15.8727 10.9348 15.8099C11.4063 15.7472 11.8402 16.0784 11.903 16.5504C12.2029 18.8 14.4094 22.2663 17.5244 25.3811C21.5044 29.3612 25.1744 31.0965 26.8993 31.032C27.3744 31.0147 27.7758 31.3835 27.7948 31.8592C27.8137 32.3348 27.4434 32.7358 26.9676 32.7547C26.9129 32.7568 26.857 32.7579 26.8011 32.7579Z"
          fill="#EF9747"
        />
        <path
          d="M17.585 19.4508C17.3827 19.2485 17.2266 18.9943 17.1399 18.7006L16.3428 16.0026C16.0418 14.9837 16.3135 13.8921 17.0517 13.1538L18.5007 11.7048C19.2391 10.9664 20.3307 10.6948 21.3496 10.9959L24.2519 11.8151L23.273 8.32273C22.9956 7.38395 23.5319 6.3981 24.4707 6.12071C25.4094 5.84333 26.3953 6.37957 26.6728 7.31835L27.4698 10.0164C27.7708 11.0353 27.4992 12.1269 26.7609 12.8651L25.3119 14.3141C24.5737 15.0524 23.482 15.324 22.4632 15.023L19.5312 14.0669L20.5397 17.6962C20.817 18.635 20.2808 19.6208 19.342 19.8982C18.6968 20.0888 18.0294 19.8952 17.585 19.4508Z"
          fill="#F8B509"
        />
        <path
          d="M24.2519 11.815L21.3496 10.9959C20.4738 10.7371 19.5446 10.9017 18.8319 11.4218C19.0315 12.2197 19.2853 13.2812 19.5311 14.0668L22.4631 15.0229C23.3804 15.2939 24.3564 15.1005 25.0808 14.5206C24.8242 13.7176 24.496 12.6218 24.2519 11.815Z"
          fill="#F88A09"
        />
        <path
          d="M28.8102 18.189C29.0125 18.3913 29.2668 18.5474 29.5605 18.6342L32.2585 19.4312C33.2773 19.7322 34.369 19.4606 35.1073 18.7223L36.5562 17.2733C37.2946 16.535 37.5662 15.4434 37.2651 14.4245L36.446 11.5222L39.9383 12.501C40.8771 12.7784 41.863 12.2421 42.1404 11.3033C42.4178 10.3646 41.8816 9.37871 40.9428 9.10132L38.2448 8.30425C37.2258 8.00324 36.1342 8.27494 35.396 9.01314L33.947 10.4621C33.2087 11.2004 32.9371 12.292 33.2381 13.3109L34.1942 16.2428L30.5649 15.2344C29.6261 14.957 28.6402 15.4933 28.3628 16.4321C28.1722 17.0772 28.3659 17.7447 28.8102 18.189Z"
          fill="#20C2F1"
        />
        <path
          d="M37.2651 14.4246L36.4459 11.5223C35.6015 11.3403 34.4203 11.1075 33.5775 10.9185C33.1263 11.6128 32.9942 12.4857 33.2381 13.3111L34.1941 16.243C35.0682 16.4588 35.9932 16.6077 36.8324 16.9514C37.3578 16.2375 37.525 15.3041 37.2651 14.4246Z"
          fill="#0070F4"
        />
        <path
          d="M32.9368 3.61381C32.3748 4.17582 31.4635 4.17582 30.9015 3.61381C30.3394 3.05179 30.3394 2.1405 30.9015 1.57849C31.4635 1.01648 32.3748 1.01648 32.9368 1.57849C33.4988 2.14059 33.4988 3.05179 32.9368 3.61381Z"
          fill="#5BE3DB"
        />
        <path
          d="M35.1084 27.5234C35.9033 27.5234 36.5476 26.879 36.5476 26.0842C36.5476 25.2894 35.9033 24.645 35.1084 24.645C34.3136 24.645 33.6693 25.2894 33.6693 26.0842C33.6693 26.879 34.3136 27.5234 35.1084 27.5234Z"
          fill="#0070F4"
        />
        <path
          d="M27.2354 27.3631C26.6734 27.9251 25.7621 27.9251 25.2001 27.3631C24.638 26.8011 24.638 25.8898 25.2001 25.3278C25.7621 24.7657 26.6734 24.7657 27.2354 25.3278C27.7974 25.8899 27.7974 26.8011 27.2354 27.3631Z"
          fill="#0070F4"
        />
        <path
          d="M27.2354 27.3631C26.6734 27.9251 25.7621 27.9251 25.2001 27.3631C24.638 26.8011 24.638 25.8898 25.2001 25.3278C25.7621 24.7657 26.6734 24.7657 27.2354 25.3278C27.7974 25.8899 27.7974 26.8011 27.2354 27.3631Z"
          fill="#8BB6EF"
        />
        <path
          d="M7.83576 31.5825C7.72206 31.5825 7.60638 31.5598 7.49536 31.5119C7.05808 31.3237 6.8562 30.8167 7.04454 30.3794L8.13616 27.844C8.32441 27.4068 8.8316 27.2045 9.26872 27.3931C9.706 27.5814 9.90788 28.0884 9.71953 28.5257L8.62792 31.0611C8.4875 31.3872 8.16969 31.5825 7.83576 31.5825Z"
          fill="white"
        />
        <path
          d="M10.1491 26.2098C10.0354 26.2098 9.91978 26.1871 9.80858 26.1393C9.37139 25.951 9.16951 25.444 9.35785 25.0066L9.69058 24.2338C9.87883 23.7966 10.3857 23.5944 10.8232 23.783C11.2604 23.9713 11.4623 24.4783 11.274 24.9157L10.9413 25.6885C10.8008 26.0145 10.4829 26.2098 10.1491 26.2098Z"
          fill="white"
        />
        <path
          d="M16.9136 1.20076L17.6216 3.19177C17.6519 3.27702 17.7119 3.3446 17.7877 3.37873L19.5566 4.17572C19.8065 4.28829 19.8065 4.68644 19.5566 4.79911L17.7877 5.59609C17.7119 5.63023 17.6519 5.69781 17.6216 5.78297L16.9136 7.77398C16.8136 8.05524 16.4597 8.05524 16.3597 7.77398L15.6517 5.78297C15.6214 5.69772 15.5614 5.63014 15.4856 5.59609L13.7167 4.79911C13.4668 4.68653 13.4668 4.28838 13.7167 4.17572L15.4856 3.37873C15.5614 3.3446 15.6214 3.27702 15.6517 3.19177L16.3597 1.20076C16.4597 0.919408 16.8136 0.919408 16.9136 1.20076Z"
          fill="#20C2F1"
        />
        <path
          d="M40.9004 19.0996L41.4257 20.5764C41.4482 20.6397 41.4926 20.6898 41.5488 20.7151L42.8609 21.3062C43.0464 21.3898 43.0464 21.6851 42.8609 21.7686L41.5488 22.3598C41.4926 22.3851 41.4482 22.4352 41.4257 22.4984L40.9004 23.9753C40.8262 24.184 40.5638 24.184 40.4896 23.9753L39.9644 22.4984C39.9419 22.4352 39.8974 22.3851 39.8412 22.3598L38.5291 21.7686C38.3437 21.6851 38.3437 21.3898 38.5291 21.3062L39.8412 20.7151C39.8974 20.6898 39.9419 20.6397 39.9644 20.5764L40.4896 19.0996C40.5638 18.891 40.8262 18.891 40.9004 19.0996Z"
          fill="#F8B509"
        />
        <path
          d="M31.1347 7.97787C31.5031 7.97787 31.8017 7.67929 31.8017 7.31096C31.8017 6.94263 31.5031 6.64404 31.1347 6.64404C30.7664 6.64404 30.4678 6.94263 30.4678 7.31096C30.4678 7.67929 30.7664 7.97787 31.1347 7.97787Z"
          fill="#0070F4"
        />
        <path
          d="M41.2322 3.18341C41.3152 2.50826 40.8351 1.8937 40.1599 1.81074C39.4847 1.72779 38.8701 2.20785 38.7871 2.883C38.7042 3.55815 39.1843 4.17272 39.8595 4.25567C40.5347 4.33863 41.1493 3.85856 41.2322 3.18341Z"
          fill="#8BB6EF"
        />
      </g>
      <defs>
        <clipPath id="clip0_238_1017">
          <rect width="43" height="43" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ConfettiYellowIcon;
