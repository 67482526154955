import React from "react";
import ReactResizeDetector from "react-resize-detector";
import { Layout, Row, Col } from "antd";
import { Helmet } from "react-helmet";
import InjectFacebookPixel from "components/Injectors/InjectFacebookPixel";
import EmailInput from "../components/emailInput";
import PageFooter from "../components/pageFooter";
import AnimatedChevrons from "../components/animatedChevrons";
import styles from "../Education.module.less";

const { Content, Header } = Layout;

const EducationIntroduction = (props) => {
  const { user } = props;

  const colSpan12 = { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 };
  const colSpan18 = { xs: 24, sm: 24, md: 18, lg: 18, xl: 18 };
  const colSpan6 = { xs: 24, sm: 24, md: 6, lg: 6, xl: 6 };

  const description =
    "Welcome to the Powur Solar Insights Series. Sit back and enjoy this 6 minute series of videos and rest assured that going solar is the RIGHT choice for you.";

  return (
    <ReactResizeDetector
      handleWidth
      refreshMode="debounce"
      refreshRate={250}
      render={({ width }) => {
        const mobile = width < 992;
        const paragraphContent = (
          <>
            <Row className={[styles.innerRow, styles.leftBorder, styles.bodyTextContainer].join(" ")}>
              <h5>
                Are You Tired of Throwing Money Away Each Month on High Utility Bills? Thought of Going Solar but Don’t
                Know Where To Start? You Have Come to the Right Place.
              </h5>
            </Row>
            <Row className={[styles.innerRow, styles.leftBorder, styles.bodyTextContainer].join(" ")}>
              <h5>
                In just 6 minutes, we will show you how to put up to $26k in your pocket while adding up to $50K to the
                value of your home just by going solar! The only question you will have left is: where do I sign?
              </h5>
            </Row>
          </>
        );
        return (
          <Layout id={styles.introLayout} className={styles.introLayout}>
            <InjectFacebookPixel facebookPixelId={user?.facebook_pixel_id} />
            <Helmet>
              <meta name="description" content={description} />
              <meta name="og:description" content={description} />
              <title>Free Solar Education Video Series For Homeowners – POWUR | Solar Energy for your Home</title>
            </Helmet>
            <Content className={[styles.section, styles.section1]}>
              <Content className={styles.outerContainer}>
                <Header className={[styles.powurHeader, styles.centeredWhenSmall].join(" ")}>
                  <img src="/img/logo.svg" className={styles.logo} alt="Powur Logo" />
                </Header>
                <Row gutter={32} className={styles.innerContainer}>
                  <Col className={styles.leftCol} {...colSpan12}>
                    <Row className={[styles.innerRow, styles.centeredWhenSmall].join(" ")}>
                      <h5>Free Education Video Series For Homeowners:</h5>
                    </Row>
                    <Row className={[styles.innerRow, styles.centeredWhenSmall].join(" ")}>
                      <h1>Learn Why Now {window.innerWidth < 992 && <br />} Is The Best Time To Go Solar</h1>
                    </Row>
                    <Row className={[styles.innerRow, styles.centeredWhenSmall, styles.marginBottomMed].join(" ")}>
                      <h2 className={styles.blue}>The Powur Solar Insights Series</h2>
                    </Row>
                    {!mobile && paragraphContent}
                  </Col>
                  <Col className={styles.rightCol} {...colSpan12}>
                    <Row className={styles.videoRow}>
                      <div className={styles.videoContainer}>
                        <iframe
                          title="vimeo-player"
                          src="https://player.vimeo.com/video/461496902"
                          // width="640"
                          // height="360"
                          frameBorder="0"
                          allowFullScreen
                        />
                      </div>
                    </Row>
                    <Row className={styles.emailContainer}>
                      <EmailInput user={user} showHeader />
                    </Row>
                    {mobile && paragraphContent}
                  </Col>
                </Row>
              </Content>
            </Content>
            <Content className={[styles.section, styles.section2].join(" ")}>
              <Content className={styles.outerContainer}>
                <Content className={styles.innerContainer}>
                  <Content className={styles.insights}>
                    <Row className={styles.insightsTopCard}>
                      <Col className={styles.titleCol} {...colSpan18}>
                        <h2 className={styles.blue}>The Powur Solar Insights Series</h2>
                        <p>
                          We created these education videos to help you make a more informed decision about switching to
                          sustainable clean energy
                        </p>
                      </Col>
                      <Col className={styles.imgCol} {...colSpan6}>
                        <div className={styles.insightsIcon} />
                      </Col>
                    </Row>
                    <Row className={styles.cardsTitleRow}>
                      <p>
                        The Powur Solar Insight Series is made up of 3 short 2-min-videos where we answer the following
                        3 questions:
                      </p>
                    </Row>
                    <Row className={styles.cardsContainer}>
                      <Col className={styles.card}>
                        <div className={styles.title}>
                          <h3>Why Should I Consider Solar?</h3>
                        </div>
                        <p>
                          Solar power offers something for everyone, whether you want to save money on your energy
                          bills, increase your home’s value, protect against power outages, or just do right by the
                          environment.
                        </p>
                      </Col>
                      <Col className={styles.card}>
                        <div className={styles.title}>
                          <h3>Why Should I Go Solar With Powur?</h3>
                        </div>
                        <p>
                          Rest assured that we will work harder than any other company in the industry to ensure that
                          you have an exceptional customer experience.
                        </p>
                      </Col>
                      <Col className={styles.card}>
                        <div className={styles.title}>
                          <h3>Why Should I Go Solar Now?</h3>
                        </div>
                        <p>
                          You have access to a federal tax credit that will pay you 30% of the value of your solar
                          energy system, just for going solar!
                        </p>
                      </Col>
                    </Row>
                  </Content>
                </Content>
              </Content>
            </Content>
            <Content className={[styles.section, styles.readyBg, styles.section3]}>
              <Content className={styles.outerContainer}>
                <Content className={[styles.innerContainer, styles.flexColumn]}>
                  <Row className={styles.arrowRow}>
                    <AnimatedChevrons size="large" />
                  </Row>
                  <Row className={styles.titleRow}>
                    <h2 className={styles.title}>
                      Ready to put up to $26k in your pocket while adding up to $50K to the value of your home just by
                      going solar?
                    </h2>
                  </Row>
                  <Row className={styles.conclusionRow}>
                    <h4>We teach you how in our Solar Insights Series.</h4>
                    <h4>Just Enter Your Email below to Start Watching.</h4>
                  </Row>
                  <Row className={styles.emailRow}>
                    <EmailInput user={user} />
                  </Row>
                </Content>
              </Content>
            </Content>
            <PageFooter />
          </Layout>
        );
      }}
    />
  );
};

export default EducationIntroduction;
