import tabs from "containers/Enterprise/enterpriseEarnings/tabs";
import { EarningsIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";
import SessionClient from "util/SessionClient";

const content = l(() => import("containers/Enterprise/enterpriseEarnings"));

const { ENTERPRISE_EARNINGS_VIEW } = permissionTypes;

const session = new SessionClient();

export default {
  key: "org-earnings",
  title: `${session?.user?.props?.enterpriseName || ""} Earnings`,
  path: "/org-earnings/:tab?",
  basePath: "org-earnings",
  requiredParams: ["tab"],
  optionalParams: [],
  linkTarget: "/org-earnings",
  linkTitle: "Earnings",
  linkCategory: "Enterprise",
  icon: EarningsIcon,
  permission: [ENTERPRISE_EARNINGS_VIEW],
  content,
  sidebar: null,
  tabs,
};
