import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const LocationPinFilledIcon = ({ width = "13", height = "18", fill = cvar("graham-grey") }: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13 18">
      <path
        d="M12.2545 16.4772C12.2545 16.7157 11.828 17.113 10.6261 17.4524C9.46504 17.7802 7.91564 17.9607 6.26484 17.9607C4.61405 17.9607 3.06458 17.7802 1.90354 17.4524C0.701699 17.113 0.275153 16.715 0.275153 16.4772C0.275153 16.2737 0.558748 16.0283 1.05325 15.8037C1.48247 15.6084 2.27843 15.3381 3.63222 15.159L3.70029 15.15L3.75013 15.1972C4.75907 16.1468 5.62856 16.6487 6.26491 16.6487C6.90126 16.6487 7.7709 16.1468 8.77968 15.1972L8.82952 15.15L8.8976 15.159C10.2514 15.3381 11.0473 15.6084 11.4766 15.8037C11.9711 16.0284 12.2547 16.2731 12.2547 16.4772H12.2545ZM2.51309 12.9456C0.939042 10.876 0 8.71558 0 7.16741C0 5.50573 0.660061 3.91207 1.83505 2.73703C3.01016 1.56204 4.60378 0.901855 6.26543 0.901855C7.92712 0.901855 9.52077 1.56204 10.6958 2.73703C11.8708 3.91201 12.5309 5.50564 12.5309 7.16741C12.5309 8.71629 11.5911 10.8768 10.0178 12.9456C8.45598 14.9972 6.94796 16.0714 6.26453 16.0714C5.5811 16.0714 4.07321 14.9972 2.51285 12.9456H2.51309ZM2.521 7.16741C2.52112 8.16029 2.91571 9.11247 3.61793 9.81435C4.32004 10.5164 5.27234 10.9107 6.26521 10.9106C7.25809 10.9103 8.21027 10.5159 8.91224 9.81376C9.61422 9.11166 10.0086 8.15936 10.0085 7.16648C10.0085 6.1736 9.61401 5.22143 8.9119 4.51945C8.2098 3.81744 7.25762 3.42301 6.26475 3.42301C5.27221 3.42423 4.32066 3.81893 3.61881 4.52079C2.91692 5.22267 2.52212 6.17423 2.52103 7.16673L2.521 7.16741ZM6.26459 10.3322C7.10408 10.3322 7.90928 9.99876 8.50284 9.40508C9.09653 8.81152 9.42997 8.00644 9.42997 7.16682C9.42997 6.32733 9.09653 5.52226 8.50284 4.92857C7.90928 4.33501 7.10408 4.00145 6.26459 4.00145C5.4251 4.00145 4.6199 4.33501 4.02634 4.92857C3.43265 5.52226 3.09922 6.32733 3.09922 7.16682C3.10019 8.00607 3.43399 8.81064 4.02743 9.40411C4.62074 9.99742 5.42535 10.3312 6.26459 10.3322Z"
        fill={fill}
      />
    </svg>
  );
};

export default LocationPinFilledIcon;
