import { put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import { keysToCamelCase } from "lib/helpers";
import _ from "lodash";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/workflows/selectors";
import messages from "../../messages";

function* reorderPhases(action) {
  const { workflowId, phases } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/workflows/${workflowId}/phases/reorder`, {
    order: phases,
  });
  if (success && data) {
    const newPhases = data.body.phases.map((p) => keysToCamelCase(p));
    const workflow = yield select(selectors.getWorkflowDetails);
    const newWorkflow = _.clone(workflow);

    const phasesToSet = newPhases.map((p) => {
      const oldPhase = newWorkflow.phases.find((phase) => phase.id === p.id);
      return { ...p, milestones: oldPhase.milestones };
    });

    yield put(actions.setWorkflowDetails({ ...newWorkflow, phases: phasesToSet }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_PHASE_RANKS,
    });
  }
  return null;
}

export default reorderPhases;
