import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* updatePassword(action) {
  const { password, newPassword: new_password, passwordConfirm: password_confirm } = action.payload;

  const { success, error } = yield* makeRequest.patch("/password", {
    password,
    new_password,
    password_confirm,
  });
  if (success) {
    yield put(actions.newSuccessEvent({ message: messages.PASSWORD_UPDATE_SUCCESS }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_PASSWORD,
    });
  }
  return null;
}

export default updatePassword;
