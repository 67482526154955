// July 1st, 2021  11:11 AM
import userTimezone from "./userTimezone";

export default function formatDateTimeLonger(dateString, isShortMonth = false) {
  const timeZone = userTimezone();

  const date = new Date(dateString);
  const day = new Date(dateString).toLocaleDateString("en-US", {
    month: isShortMonth ? "short" : "long",
    year: "numeric",
    day: "numeric",
    timeZone,
  });
  const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const meridiem = date.getHours() >= 12 ? "PM" : "AM";
  return `${day}${"\xa0\xa0"}${hours}:${minutes} ${meridiem}`;
}
