import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  availablePeriods: [],
  kpi: {},
  paging: {
    itemCount: 0,
    pageNumber: 1,
    pageSize: 50,
  },
  payments: [],
  selectedPeriod: {},
  consultantOrLeadAutoComplete: [],
  searchedSeller: {},
  searchedProviderLead: {},
  dashboard: {
    thisMonthEarnings: {},
    last12Months: [],
    customDateRange: [],
    creditBalance: {},
  },
};

export default {
  earnings: createReducer(initialState, {
    [types.SET_EARNINGS_HISTORY](state, action) {
      return {
        ...state,
        ...action.payload,
        kpi: {
          ...state.kpi,
          ...action.payload.kpi,
        },
        searchedSeller: action.payload.searchedSeller || {},
        searchedProviderLead: action.payload.searchedProviderLead || {},
      };
    },
    [types.SET_CONSULTANT_OR_LEAD_AUTO_COMPLETE](state, action) {
      return {
        ...state,
        consultantOrLeadAutoComplete: action.payload,
      };
    },
    [types.SET_THIS_MONTH_EARNINGS](state, action) {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          thisMonthEarnings: action.payload,
        },
      };
    },
    [types.SET_MONTHLY_BREAKDOWN_12_MOS](state, action) {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          last12Months: action.payload.monthlyBreakdown,
          creditBalance: action.payload.creditBalance,
        },
      };
    },
    [types.SET_MONTHLY_BREAKDOWN](state, action) {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          customDateRange: action.payload,
        },
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
