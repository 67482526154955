/* eslint-disable import/prefer-default-export */
import { useEffect, useRef } from "react";

/**
 * Hook to add an event listener to the DOM
 * @function useEventListener
 * @param {string} eventName string name of the event to listen to ('resize', 'mouseenter', 'click', etc)
 * @param {function} handler event handler function to be called on event specified in eventName
 * @param {HTMLElement} element DOM node to attach listener to, defaults to window
 */

export function useEventListener(eventName, handler, element = window) {
  const handlerRef = useRef();

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (isSupported) {
      const listener = (event) => handlerRef.current(event);
      element.addEventListener(eventName, listener);

      return () => {
        element.removeEventListener(eventName, listener);
      };
    }
  }, [eventName, element]);

  return element;
}
