import { styled } from "@mui/material/styles";
import { theme } from "styles/themes";

export const KnowledgeBaseArticlesContainer = styled("div")(() => ({
  marginTop: "24px",
}));

export const ArticleDataContainer = styled("div")(() => ({
  width: "100%",
  backgroundColor: theme.colors.white,
  borderRadius: "0px 0px 12px 12px",
  padding: "22px",
}));

export const ArticleDataCard = styled("div")(() => ({}));

export const ArticleTitle = styled("div")(() => ({
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: 500,
  color: theme.colors["darkest-blue"],
  marginBottom: "6px",
}));

export const ArticleDetail = styled("div")(() => ({
  fontSize: "16px",
  fontWeight: 500,
  color: theme.colors["queen-blue"],
  lineHeight: "22px",
  marginBottom: "24px",
}));

export const ArticleLink = styled("div")(() => ({
  fontSize: "16px",
  fontWeight: 700,
  color: theme.colors["blue-ribbon"],
  textTransform: "uppercase",
  cursor: "pointer",
  display: "flex",
}));

export const IconContainer = styled("div")(() => ({
  marginLeft: "5px",
  paddingTop: "2px",
}));

export const Divider = styled("div")(() => ({
  background: theme.colors["botticelli-blue-50"],
  height: "1px",
  width: "100%",
  marginTop: "24px",
  marginBottom: "22px",
}));
