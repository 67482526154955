// import content from "containers/Sales/dashboard";
import { DashboardIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";

const content = l(() => import("containers/Sales/dashboard"));

const { SALES_DASHBOARD_VIEW, PREVIEW_USER } = permissionTypes;

export default {
  key: "dashboard",
  title: "Dashboard",
  path: "/dashboard",
  exact: true,
  linkTarget: "/dashboard",
  linkTitle: "Dashboard",
  linkCategory: "Sales",
  icon: DashboardIcon,
  basePath: "dashboard",
  requiredParams: [],
  optionalParams: [],
  tabs: null,
  content,
  sidebar: null,
  permission: [SALES_DASHBOARD_VIEW, PREVIEW_USER],
};
