import types from "rdx/modules/messages/types";
import createAction from "rdx/utils/createAction";

export default {
  newErrorEvent: (payload) => createAction(types.NEW_ERROR_EVENT, payload),
  newMessageEvent: (payload) => createAction(types.NEW_MESSAGE_EVENT, payload),
  newSuccessEvent: (payload) => createAction(types.NEW_SUCCESS_EVENT, payload),
  setFormErrors: (payload) => createAction(types.SET_FORM_ERRORS, payload),
  getFormErrors: (payload) => createAction(types.GET_FORM_ERRORS, payload),
  clearLatestMessage: (payload) => createAction(types.CLEAR_LATEST_MESSAGE, payload),
  // INSERTION_PT (for script, do not remove)
};
