export default {
  CREATE_DATA_GROUP_REQUEST: "[workflows] Create Data Group Request",
  UPDATE_DATA_GROUP_REQUEST: "[workflows] Update Data Group Request",
  GET_DATA_GROUPS_REQUEST: "[workflows] GET Data Groups Request",
  DELETE_DATA_GROUP_REQUEST: "[workflows] Delete Data Group Request",
  SET_DATA_GROUPS: "[workflows] SET Data Groups",
  SET_MILESTONE_OPTIONS: "[workflows] SET Milestone Options",

  CREATE_META_FIELD_REQUEST: "[workflows] Create Meta Field Request",
  UPDATE_META_FIELD_REQUEST: "[workflows] Update Meta Field Request",
  DELETE_META_FIELD_REQUEST: "[workflows] Delete Meta Field Request",

  CREATE_WORKFLOW_REQUEST: "[workflows] Create Workflow Request",
  GET_WORKFLOW_REQUEST: "[workflows] GET Workflow Request",
  GET_WORKFLOWS_LIST_REQUEST: "[workflows] GET Workflows List Request",
  GET_ACTIVE_WORKFLOW_REQUEST: "[workflows] GET Active Workflow Request",
  UPDATE_WORKFLOW_REQUEST: "[workflows] Update Workflow Request",
  DELETE_WORKFLOW_REQUEST: "[workflows] Delete Workflow Request",
  COPY_WORKFLOW_REQUEST: "[workflows] Copy Workflow Request",
  SET_WORKFLOWS: "[workflows] SET Workflows",
  SET_WORKFLOW_DETAILS: "[workflows] SET Workflow Details",
  REMOVE_WORKFLOW_PHASE_REQUEST: "[workflows] Remove Workflow Phase Request",

  CREATE_PHASE_REQUEST: "[workflows] Create Phase Request",
  UPDATE_PHASES_REQUEST: "[workflows] Update Phases Request",
  UPDATE_PHASE_RANKS_REQUEST: "[workflows] Update Phase Ranks Request",
  GET_WORKFLOW_PHASES_REQUEST: "[workflows] GET Workflow Phases Request",
  SET_PHASES: "[workflows] SET Phases",

  CREATE_MILESTONE_REQUEST: "[workflows] Create Milestone Request",
  UPDATE_MILESTONE_REQUEST: "[workflows] Update Milestone Request",
  UPDATE_MILESTONE_RANKS_REQUEST: "[workflows] Update Milestone Ranks Request",
  REORDER_MILESTONE_DATA_GROUPS_REQUEST: "[workflows] Reorder Milestone Data Groups Request",
  REMOVE_PHASE_MILESTONE_REQUEST: "[workflows] Remove Phase Milestone Request",
  GET_PHASE_MILESTONES_REQUEST: "[workflows] GET Phase Milestones Request",
  // INSERTION_PT (for script, do not remove)
};
