import React from "react";
import type { IconT } from "types/utils";
import { cvar } from "styles";

const FlagIcon = ({ fill = cvar("darkest-blue"), width = 26, height = 28 }: IconT) => (
  <svg width={width} height={height} viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4069 1.7488C9.79094 -0.317406 5.99059 -0.510672 2.33594 1.13892V0H0.695312V28H2.33594V17.788C5.70299 16.022 9.23892 16.0769 12.5931 17.9934C14.6046 19.143 16.673 19.7173 18.7422 19.7173C20.8106 19.7173 22.8798 19.1429 24.8913 17.9934L25.3047 17.7571V1.04705L24.0774 1.7488C20.5879 3.74347 16.8981 3.74347 13.4069 1.7488Z"
      fill={fill}
    />
  </svg>
);

export default FlagIcon;
