import styled from "@emotion/styled";
import { theme } from "styles/themes";
import Button from "@mui/material/Button";

export const ChatButton = styled(Button)(() => ({
  border: `2px solid ${theme.colors["picton-blue"]} !important`,
  background: theme.colors.white,
  borderRadius: "100px !important",
  height: "36px",
  width: "100%",
  justifyContent: "space-evenly !important",
}));
