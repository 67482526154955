import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { theme } from "styles/themes";
import { filterStyledProps } from "lib/helpers/filterStyledProps";
import { mq } from "styles";

const formLabelProps = {
  fontSize: "12px",
  fontWeight: "600",
  color: theme.colors["nepal-blue"],
  lineHeight: "10px",
};

export const DisclaimerText = styled(Typography)(() => ({
  fontSize: "16px",
  fontStyle: "italic",
  fontWeight: 500,
  lineHeight: "19.2px",
  textAlign: "left",
  color: theme.colors["congress-blue"],
}));

export const StyledSectionLabel = styled("div", { shouldForwardProp: filterStyledProps(["sub"]) })(
  ({ sub }: { sub?: boolean }) =>
    () => ({
      fontFamily: "Poppins",
      fontWeight: "400",
      fontSize: sub ? "18px" : "20px",
      lineHeight: "30px",
      color: theme.colors["blue-ribbon"],
    }),
);

export const FormFieldLabel = styled("div")(() => ({
  ...formLabelProps,
  textTransform: "uppercase",
  marginBottom: "8px",
}));

export const ExistingSystemLabel = styled("div")(() => ({
  ...formLabelProps,
  lineHeight: "16px",
  textTransform: "uppercase",
  marginTop: "4px",
}));

export const StyledDivider = styled(Divider)(() => ({
  background: theme.colors["botticelli-blue"],
  marginTop: "28px",
  marginBottom: "30px",
}));

export const NoteUrgent = styled("div")(() => ({
  marginTop: "5px",
  fontWeight: 600,
  lineHeight: "17px",
  color: theme.colors["persian-red"],
}));

export const Note = styled("div")(() => ({
  marginTop: "5px",
  marginBottom: "8px",
  fontWeight: 600,
  lineHeight: "17px",
  color: theme.colors["nepal-blue"],
}));

export const DarkBlueNote = styled("div")(() => ({
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: "17px",
  color: theme.colors["dark-blue"],
}));

export const ImageUploadNote = styled("div")(() => ({
  display: "flex",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "17px",
  color: theme.colors["nepal-blue"],
  marginLeft: "16px",
  marginBottom: "12px",
  flexWrap: "wrap",
  rowGap: "14px",
}));

export const ImageUploadClickable = styled("div")(() => ({
  display: "flex",
  marginLeft: "5px",
  color: theme.colors["picton-blue"],
  cursor: "pointer",
}));

export const ClickableCaret = styled("div")(() => ({
  [mq.tabletUp]: {
    marginLeft: "5px",
  },
  paddingTop: "2px",
}));

export const CustomFieldsContainer = styled("div")(() => ({
  [mq.tabletUp]: {
    marginTop: "30px",
  },
}));

export const Red = styled("span")(() => ({
  color: theme.colors.danger,
}));
