import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Grid from "@mui/material/Grid";

import { selectors, actions } from "rdx";
import { convertHtmlToPlainText } from "lib/helpers";
import { reminderValue } from "lib/misc/dropdownOptions";

import { CrmActivityFormDataT } from "types/Project/crmActivity";
import { ProjectTypeEnum } from "rdx/modules/projects/enums";

import { OpenInNewWindowIcon, LightbulbOutlineIcon, CheckCircleBrokenIcon } from "components/Icons";
import { useSmartForm } from "hooks/useSmartForm";
import SmartForm from "components/SmartForm";
import DatePickerModal from "components/mui-styled-components/Modals/DatePickerModal";
import CrmMeetingForm from "components/mui-styled-components/Drawers/CrmActivityDrawer/components/CrmMeetingForm";
import { schema, blankFormData } from "components/mui-styled-components/Drawers/CrmActivityDrawer/formData";
import SubHeaderFilled from "components/PlatformLayout/components/SubHeaderFilled";
import { PrimaryButton } from "components/mui-styled-components";
import { T3PartnerT } from "components/Drawers/SelectT3PartnerDrawer/types";
import { cvar } from "styles";

import * as S from "./styles";

dayjs.extend(utc);

type HomeownerApptFormPropsT = {
  setIsCreateApptDisabled: (disabled: boolean) => void;
  homeownerApptSubmitRef: React.RefObject<HTMLButtonElement>;
  closeDrawer: () => void;
  assignedPartner: T3PartnerT;
};

type CrmActivityFormT = z.infer<typeof schema>;

const HomeownerApptForm = ({
  assignedPartner,
  closeDrawer,
  homeownerApptSubmitRef,
  setIsCreateApptDisabled,
}: HomeownerApptFormPropsT) => {
  const dispatch = useDispatch();
  const leadId = useSelector(selectors.selectLeadToProposalDrawerLeadId);
  const leadDetails = useSelector(selectors.selectLeadToProposalLeadDetails);
  const { hasHomeownerApptScheduled, projectId, projectType } = leadDetails;
  const closerHasCalendlyLink = !!assignedPartner.homeownerCalendlyUrl;
  const isPostSales = projectType === ProjectTypeEnum.POST_SALES;
  const fastPayInfoUrl = "https://help.powur.com/hc/en-us/articles/32875994629275";
  const [customDueDate, setCustomDueDate] = useState<string>(dayjs().format("YYYY-MM-DD"));
  const [isCustomDateModalOpen, setIsCustomDateModalOpen] = useState(false);

  const { methods, getSmartFormProps } = useSmartForm<CrmActivityFormT>({
    schema,
    useFormProps: {
      defaultValues: {
        ...blankFormData,
        notes: convertHtmlToPlainText(leadDetails.homeownerNotes),
        kind: "meeting",
      },
    },
  });

  const {
    watch,
    setValue,
    getValues,
    handleSubmit,
    trigger,
    clearErrors,
    formState: { errors },
  } = methods;
  const values = watch();

  useEffect(() => {
    trigger(["title", "meetingType", "dueDate", "dueTime"]).then((validForm) => {
      setIsCreateApptDisabled(!validForm);
      clearErrors();
    });
  }, [values.title, values.meetingType, values.dueDate, values.dueTime, trigger, setIsCreateApptDisabled, clearErrors]);

  const handleUrl = (url) => {
    if (!/^(http|https):\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  const formatFormValues = () => {
    const { title, meetingType, meetingInfo, email, location, notes, kind } = values;

    const dueDate = values.dueDate === "custom" ? customDueDate : values.dueDate;
    const dueTime = dayjs.utc(values.dueTime).format("HH:mm:ss");
    const due = dayjs(`${dueDate} ${dueTime}`).utc().toISOString();

    const reminder = reminderValue[values.reminder](due);
    const reminderType: string[] = [];
    for (const [key, value] of Object.entries(values.reminderType)) {
      if (value) reminderType.push(key);
    }

    const formValues: CrmActivityFormDataT = {
      kind,
      leadId,
      title,
      email,
      meetingType,
      meetingInfo,
      location,
      due,
      reminder,
      reminderType,
      notes,
    };

    return formValues;
  };

  const onCreateAppointment = () => {
    dispatch(
      actions.setAlertMessageVisible({
        message: "Submitting. . .",
        severity: "info",
        duration: 6000,
      }),
    );
    const formValues = formatFormValues();
    dispatch(actions.createNewCrmActivity(formValues));
  };

  return (
    <>
      <SubHeaderFilled
        title="Homeowner Appointment"
        sideText={
          <S.TipsForSuccess>
            <S.TipIconContainer>
              <LightbulbOutlineIcon fill={cvar("blue-ribbon")} />
            </S.TipIconContainer>{" "}
            Tips for Success
          </S.TipsForSuccess>
        }
      />
      <S.AssignedPartnerCard container>
        <Grid item xs={12}>
          <S.ApptInstructionHeader>
            <S.Bold>Step 1</S.Bold>: Schedule on your Closer&apos;s Calendar
          </S.ApptInstructionHeader>
        </Grid>
        <Grid item xs={12} md={closerHasCalendlyLink ? 7 : 12}>
          <S.ApptInstructionText>
            {closerHasCalendlyLink
              ? "Use your Closer's calendar to schedule an appointment with the homeowner. Remember, the faster the project moves forward, the sooner you get paid!"
              : "Find a time when both the Closer and homeowner are available to meet and schedule their first appointment. Remember, the faster the project moves forward, the sooner you get paid!"}
          </S.ApptInstructionText>
        </Grid>
        {assignedPartner.homeownerCalendlyUrl && (
          <Grid item xs={12} md={5} sx={{ display: "flex", justifyContent: "center" }}>
            <PrimaryButton
              style={{ height: "44px", width: "235px" }}
              onClick={() =>
                window.open(handleUrl(assignedPartner.homeownerCalendlyUrl), "_blank", "noopener,noreferrer")
              }
            >
              <div>Schedule Appointment</div>
              <S.IconContainer>
                <OpenInNewWindowIcon fill={cvar("white")} />
              </S.IconContainer>
            </PrimaryButton>
          </Grid>
        )}
        <Grid item xs={12} mt={2}>
          <S.ApptInstructionHeader>
            <S.Bold>Step 2</S.Bold>: Record Appointment in Powur platform
          </S.ApptInstructionHeader>
        </Grid>
        <Grid item xs={12}>
          <S.ApptInstructionText>
            After scheduling the appointment, record it here to inform your Closer. Only the Closer can mark the
            appointment complete, but you can make changes up until the time of the appointment. If you&apos;ve opted
            into{" "}
            <S.Hyperlink to={{ pathname: fastPayInfoUrl }} target="_blank">
              Fast Pay
            </S.Hyperlink>{" "}
            this appointment will help you get paid sooner.
          </S.ApptInstructionText>
        </Grid>

        <S.AppointmentFormContainer>
          {hasHomeownerApptScheduled ? (
            <S.AppointmentScheduledText container spacing={2}>
              <Grid item xs="auto" sx={{ marginTop: "6px" }}>
                <CheckCircleBrokenIcon width={24} height={24} fill={cvar("congress-blue")} />
              </Grid>
              <Grid item xs>
                Success -- you scheduled a homeowner appointment! View or modify the appointment from the project{" "}
                <S.Hyperlink
                  onClick={closeDrawer}
                  to={`/projects/${projectId}/${isPostSales ? "dashboard" : "activity-log"}`}
                >
                  Activity Log
                </S.Hyperlink>
              </Grid>
            </S.AppointmentScheduledText>
          ) : (
            <SmartForm {...getSmartFormProps({})}>
              <CrmMeetingForm
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                customDueDate={customDueDate}
                setIsCustomDateModalOpen={setIsCustomDateModalOpen}
              />
              <button
                ref={homeownerApptSubmitRef}
                style={{ display: "none" }}
                type="button"
                onClick={handleSubmit(onCreateAppointment)}
              />
            </SmartForm>
          )}
        </S.AppointmentFormContainer>
      </S.AssignedPartnerCard>
      {isCustomDateModalOpen && (
        <DatePickerModal
          isDateModalOpen={isCustomDateModalOpen}
          setIsDateModalOpen={setIsCustomDateModalOpen}
          handleSave={(date) => setCustomDueDate(date)}
          hideTime
          dateFormat="YYYY-MM-DD"
        />
      )}
    </>
  );
};

export default HomeownerApptForm;
