import { css } from "@emotion/react";
// import { cvar } from "styles/colorVariables";
import { cvar, mq } from "styles";

const VerifyContactCss = {
  pageLayout: css({
    backgroundColor: cvar("gainsboro"),
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
  confirmWindow: css({
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 2px 48px rgba(0, 0, 0, 0.2)",
    [mq.phoneOnly]: {
      width: "282.78px",
      height: "314.32px",
      maxWidth: "282.78px",
      maxHeight: "314.32px",
    },
    [mq.tabletUp]: {
      height: "420.9px",
      maxHeight: "420.9px",
      width: "488.84px",
      maxWidth: "488.84px",
    },
    [mq.desktopUp]: {
      width: "658.26px",
      maxWidth: "658.26px",
    },
  }),
  topBar: css({
    background: `linear-gradient(90deg, ${cvar("picton-blue")} 0%, ${cvar("blue-ribbon")} 100%)`,
    width: "100%",
    height: "16.666%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
  logo: css({
    width: "115",
    height: "35",
    [mq.tabletUp]: {
      width: "146.29",
      height: "44.66",
    },
  }),
  verifyBody: css({
    backgroundColor: cvar("white"),
    width: "100%",
    height: "83.334%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "25px",
    [mq.tabletUp]: {
      padding: "25px 75px",
    },
  }),
  verifyTitle: css({
    fontFamily: "Poppins",
    color: cvar("darkest-blue"),
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    [mq.tabletUp]: {
      fontSize: "24px",
      lineHeight: "17px",
    },
  }),
  verifySub: css({
    fontFamily: "Barlow",
    color: cvar("table-grey"),
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    [mq.tabletUp]: {
      fontSize: "16px",
    },
  }),
};
export default VerifyContactCss;
