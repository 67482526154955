import { put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import _ from "lodash";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/workflows/selectors";
import messages from "../../messages";

function* removeWorkflowPhase(action) {
  const { workflowId, phaseId } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/workflows/${workflowId}/phases/${phaseId}`, {});
  if (success && data) {
    const workflow = yield select(selectors.getWorkflowDetails);
    const newWorkflowPhases = _.clone(workflow.phases);
    newWorkflowPhases.splice(workflow.phases.indexOf(workflow.phases.find((p) => p.id === phaseId)), 1);
    yield put(actions.setWorkflowDetails({ ...workflow, phases: newWorkflowPhases }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_WORKFLOW_PHASE,
    });
  }
  return null;
}

export default removeWorkflowPhase;
