import React from "react";
import PropTypes from "prop-types";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styles from "../styles.module.less";
import "./layoutAnimations.css";

const Layout = ({ dropzoneProps, input, previews }) => {
  return (
    <div {...dropzoneProps}>
      {previews.length ? (
        <div className={styles.previewContainer}>
          <TransitionGroup id="file-uploader-preview-group-6YXA0" className={styles.previewGroup}>
            {previews.map((preview) => (
              <CSSTransition
                key={preview.props.meta.id}
                timeout={300}
                classNames="file-uploader-preview-animation-classes-4RV1B"
              >
                {preview}
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      ) : (
        [input]
      )}
    </div>
  );
};

Layout.propTypes = {
  dropzoneProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  input: PropTypes.node,
  previews: PropTypes.arrayOf(PropTypes.node), // components/PreviewComponent
};

export default Layout;
