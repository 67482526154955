import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Alert, Snackbar } from "@mui/material";
import { actions, selectors } from "rdx";

import { Header, Footer, VideoTestimonials, GetQuoteModal, UtilityBillModal } from "components/HomepageComponents";
import SuccessModal from "components/HomepageComponents/SuccessModal";
import ScrollToTop from "containers/router/scrollToTop";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { parse } from "query-string";
import PowurCarousel from "./components/Carousel";
import Counters from "./components/Counters";
import InfoPanels from "./components/InfoPanels";
import Endorsements from "./components/Endorsements";
import JBMessage from "./components/JBMessage";
import styles from "./Home.module.less";

const { Content } = Layout;

const Home = (props) => {
  const { selectPageReferrer, getPageReferrer } = props;
  const history = useHistory();
  const alertMessage = useSelector(selectors.selectAlertMessage);

  // Effect calls "[app] GET page referrer" in root, if there's a user_slug or ref_id qParam and adds to redux.
  // This is very different from routers/PersonalRoute(s), but might be a simpler model for future use.
  const location = useLocation();
  const { ref_id } = parse(location.search);
  const { user: slug } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (ref_id || slug) {
      getPageReferrer({
        ref_id,
        slug,
        pageName: "home",
        history,
      });
    }
  }, [getPageReferrer, ref_id, slug, history]);
  // ENDS call with "[app] SET page referrer"

  const { t } = useTranslation("home");

  const urlPrefix = process.env.REACT_APP_S3_URL_PREFIX;

  const testimonialCards = [
    {
      title: t("mission.the_better_way"),
      text: t("mission.home_entrepreneurs"),
      imageSource: `${urlPrefix}/assets/img/home/homeowner-testimonial-1.png`,
      videoSource: t("mission.puja_amish_story.url"),
    },
    {
      title: t("mission.energy_and_independence"),
      text: t("mission.customer_independence"),
      imageSource: `${urlPrefix}/assets/img/home/homeowner-testimonial-2.png`,
      videoSource: t("mission.johns_story.url"),
    },
    {
      title: t("mission.services_you_trust"),
      text: t("mission.above_and_beyond"),
      imageSource: `${urlPrefix}/assets/img/home/homeowner-testimonial-3.png`,
      videoSource: t("mission.kristys_story.url"),
    },
  ];

  const testimonialIntro = t("mission.every_homeowner");

  const closeAlertMessage = () => {
    dispatch(actions.clearAlertMessage());
  };

  return (
    <Layout className={styles.pageLayout}>
      <Snackbar
        open={!!alertMessage.visible && !!alertMessage.message}
        autoHideDuration={alertMessage.duration}
        resumeHideDuration
        onClose={closeAlertMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={closeAlertMessage} severity={alertMessage.severity}>
          {alertMessage.message}
        </Alert>
      </Snackbar>
      <Helmet>
        <title>Powur: Request a customized solar + storage quote today</title>
        <meta name="title" content="Powur: Request a customized solar + storage quote today" />
        <meta
          name="description"
          content="Powur empowers homeowners with affordable, efficient and fully personalized solar energy solutions. Save money and contribute to a sustainable future."
        />
        <meta
          name="keywords"
          content="solar panels, solar energy company, solar panel company, solar energy, solar panels for home, residential solar panels, clean energy, solar power, solar lease, buy solar system"
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://powur.com/" />
        <meta property="og:title" content="Powur: Your Residential Solar Energy Company" />
        <meta
          property="og:description"
          content="Powur offers the most efficient and reliable home solar panels and energy solutions. Save more money and lower your carbon footprint. Get a free quote today."
        />
        <meta property="og:image" content="/img/powur_fb_01.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://powur.com/" />
        <meta property="twitter:title" content="Powur: Your Residential Solar Energy Company" />
        <meta
          property="twitter:description"
          content="Powur offers the most efficient and reliable home solar panels and energy solutions. Save more money and lower your carbon footprint. Get a free quote today."
        />
        <meta property="twitter:image" content="/img/powur_fb_01.jpg" />
      </Helmet>
      <ScrollToTop />
      <Header user={selectPageReferrer} />
      <Content className={styles.content}>
        <PowurCarousel />
        <Counters />
        <InfoPanels />
        <Endorsements />
        <VideoTestimonials testimonialIntro={testimonialIntro} cardInfo={testimonialCards} />
        <JBMessage />
      </Content>
      <Footer />
      <GetQuoteModal />
      <UtilityBillModal />
      <SuccessModal />
    </Layout>
  );
};

export default connect(
  getRdxSelectionMapper({
    selectPageReferrer: "selectPageReferrer",
  }),
  getRdxActionMapper(["getPageReferrer"]),
)(Home);
// export default Home;
