import makeRequest from "rdx/utils/makeRequest";
import { put } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToSnakeCase, camelizeKeys } from "lib/helpers";
import actions from "rdx/actions";
import messages from "../messages";

function* createRoofingProposal(action) {
  const { projectId, body, isAdmin } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/projects/${projectId}/roofing_proposals`, {
    ...keysToSnakeCase(body),
  });

  if (success && data) {
    const { roofingProposals } = camelizeKeys(data.body);
    const latestProposal = roofingProposals.find((proposal) => proposal.isLatestVersion);

    yield put(actions.setRoofingProposals(roofingProposals));

    if (isAdmin) {
      yield put(actions.getAdminProjectDetails({ projectId, filters: {} }));
    } else {
      yield put(actions.getProviderLeadDetails({ providerLeadId: projectId, filters: {} }));
    }

    if (latestProposal) {
      yield put(actions.setLatestRoofingProposal(latestProposal));
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_ROOFING_PROPOSAL,
    });
  }

  return null;
}

export default createRoofingProposal;
