import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "rdx";
import { StyledTabs, StyledTab } from "./styles";

type NewTabsPropsT = {
  params: Record<string, unknown>;
  tabs: Record<string, unknown>[];
  defaultValue: string;
  handleTabSelection: (event: React.SyntheticEvent<Element, Event>, value: string) => void;
};

const NewTabs: FC<NewTabsPropsT> = ({ params, tabs, defaultValue, handleTabSelection }) => {
  const [value, setValue] = useState(defaultValue);
  const dispatch = useDispatch();
  const isMiniHeaderActive = useSelector(selectors.selectProjectMiniHeaderActive);
  useEffect(() => {
    if (params.tab) {
      setValue(params.tab as string);
    }
  }, [params.tab]);

  return (
    <StyledTabs
      scrollButtons
      allowScrollButtonsMobile={!isMiniHeaderActive}
      variant="scrollable"
      value={value}
      onChange={(_, selectedVal) => {
        dispatch(actions.clearPreferredView());
        handleTabSelection(_, selectedVal);
      }}
      isMiniHeader={isMiniHeaderActive}
    >
      {tabs &&
        tabs.map((tab) => <StyledTab key={tab.key as string} label={tab.label as string} value={tab.key as string} />)}
    </StyledTabs>
  );
};

export default NewTabs;
