import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { parse } from "query-string";

import { actions, selectors } from "rdx";

import { formatNumberWithCommas } from "lib/helpers";
import { UseNavigation } from "hooks/useNavigation";
import { useSmartForm } from "hooks/useSmartForm";

import SmartForm from "components/SmartForm";

import UpDownIcon from "components/Icons/upDownIcon";
import { cvar } from "styles/colorVariables";
import { SearchT3PartnerFormSchemaT, blankFormData, schema } from "../T3PartnerFilters/t3PartnerFormData";
import { T3PartnerT, SearchT3FormItemNameT } from "../../types";
import NevadaProjectNote from "../NevadaProjectNote";
import T3PartnerFilters from "../T3PartnerFilters";
import T3PartnerTable from "../T3PartnerTable";

import * as S from "./styles";

type UrlParamsT = {
  leadId: string;
  tab: string;
  subDrawer: string;
};

type SearchT3PartnerContainerPropsT = {
  navigate: ReturnType<UseNavigation>["navigate"];
  override: ReturnType<UseNavigation>["override"];
  setSelectedT3Partner: (t3Partner: T3PartnerT) => void;
  setOpenFooter: (bool: boolean) => void;
  resetFields: () => void;
  rsmRequirementsPassed: boolean;
};
const SearchT3PartnerContainer = ({
  navigate,
  override,
  setSelectedT3Partner,
  setOpenFooter,
  resetFields,
  rsmRequirementsPassed,
}: SearchT3PartnerContainerPropsT) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { reverse } = parse(search);
  const params = useParams<UrlParamsT>();
  const { subDrawer } = params;
  const subDrawerKey = "select-mentor";
  const mentors = useSelector(selectors.getMentorsList);
  const leadToProposalDrawerVisible = useSelector(selectors.selectLeadToProposalDrawerVisible);
  const l2pProjectId = useSelector(selectors.selectLeadToProposalDrawerProjectId);
  const projectDetails = useSelector(selectors.getProjectDetails);
  const projectId = l2pProjectId || projectDetails.providerLead.id;

  const { methods, getSmartFormProps } = useSmartForm<SearchT3PartnerFormSchemaT>({
    schema,
    useFormProps: { defaultValues: blankFormData },
  });

  const { watch, handleSubmit, reset, setValue } = methods;
  const values = watch();

  const reverseSort = () => {
    const query = parse(search);
    navigate({
      reverse: query.reverse ? null : true,
    });
  };

  const handleSearchSubmit = (): void => {
    const formattedValues: Record<SearchT3FormItemNameT, string | null> = { ...values };
    Object.keys(formattedValues).forEach((key) => {
      if (formattedValues[key] === "" || formattedValues[key].length < 2) {
        formattedValues[key] = null;
      }
    });

    navigate({ ...formattedValues, project_id: projectId });
  };

  const resetFormFields = () => {
    resetFields();
    reset({ ...blankFormData, sort: "mentor_rating" });
  };

  useEffect(() => {
    // dispatch initial mentors request to populate filters values
    if (subDrawer === subDrawerKey || leadToProposalDrawerVisible) {
      dispatch(actions.getMentorsList({ filters: 1 }));
    }
  }, [subDrawer, leadToProposalDrawerVisible, dispatch]);

  useEffect(() => {
    // watch for changes to URL, make fetch requests as appropriate
    const query = parse(search);
    if (subDrawer === subDrawerKey || leadToProposalDrawerVisible) {
      dispatch(actions.getMentorsList({ ...query, projectId }));
    }
  }, [subDrawer, search, leadToProposalDrawerVisible, dispatch, projectId]);

  return (
    <S.SearchT3PartnerContainer>
      {!rsmRequirementsPassed && <NevadaProjectNote />}
      <SmartForm {...getSmartFormProps({})}>
        <T3PartnerFilters
          filters={mentors.filters}
          handleSearchSubmit={handleSubmit(handleSearchSubmit)}
          resetFields={resetFormFields}
          setValue={setValue}
        />
      </SmartForm>
      <S.ReverseSortContainer>
        <S.ReverseSortButton onClick={reverseSort}>
          <S.SortButtonContent>
            <UpDownIcon
              accentColor={reverse ? cvar("dark-blue") : cvar("botticelli-blue")}
              mainColor={reverse ? cvar("botticelli-blue") : cvar("dark-blue")}
            />
            <S.SortButtonText>Reverse Sort</S.SortButtonText>
          </S.SortButtonContent>
        </S.ReverseSortButton>
        <S.Results>{formatNumberWithCommas(mentors?.paging?.item_count || 0)} Results</S.Results>
      </S.ReverseSortContainer>
      <T3PartnerTable
        navigate={navigate}
        override={override}
        setSelectedT3Partner={setSelectedT3Partner}
        setOpenFooter={setOpenFooter}
      />
    </S.SearchT3PartnerContainer>
  );
};

export default SearchT3PartnerContainer;
