import { put, select } from "redux-saga/effects";
import rbacSelectors from "rdx/modules/rbac/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
// import messages from "rdx/modules/inbox/messages";

function* markMessagesAsUnviewed(action) {
  const { success, data, error } = yield* makeRequest.post("/messages/unviewed", {
    message_ids: action.payload.messageIds,
  });

  if (success && data) {
    const readLength = action.payload.messageIds?.length;
    if (action.payload.archived) {
      const currentInboxUnviewedArchivedCount = yield select(rbacSelectors.getInboxUnviewedArchivedCount);
      const newInboxUnviewedArchivedCount = Math.max(0, currentInboxUnviewedArchivedCount + readLength);
      yield put(actions.updateInboxUnviewedArchivedCount(newInboxUnviewedArchivedCount));
    } else {
      const currentInboxUnviewedMessagesCount = yield select(rbacSelectors.getInboxUnviewedMessagesCount);
      const newInboxUnviewedMessagesCount = Math.max(0, currentInboxUnviewedMessagesCount + readLength);
      yield put(actions.updateInboxUnviewedMessagesCount(newInboxUnviewedMessagesCount));
    }
  } else if (error) {
    // ditto.
    return getErrorActions({
      error,
      // message: "",
      // payload: {},
    });
  }

  return null;
}

export default markMessagesAsUnviewed;
