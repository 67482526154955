export default {
  // Success messages
  GET_ENTERPRISE_SETTINGS_SUCCESS: "Enterprise settings retrieved successfully",

  UPDATE_ENTERPRISE_ORG_SUCCESS: "Org settings updated",

  POST_ENTERPRISE_ASSETS_SUCCESS: "Enterprise assets uploaded successfully",

  SUCCESS_DELETE_ENTERPRISE_ASSET: "Enterprise asset deleted successfully",

  // Error messages
  ERROR_GETTING_ENTERPRISE_SETTINGS: "There was an error retrieving settings",

  UPDATE_ENTERPRISE_ORG_ERROR: "There was an error updating your org information",

  ERROR_GETTING_ENTERPRISES_LIST: "There was an error retrieving the list of enterprises",

  ERROR_GETTING_ENTERPRISE_COBRANDING: "There was an error retrieving the enterprise cobranding information",

  ERROR_POSTING_ENTERPRISE_ASSETS: "There was an error uploading the enterprise assets",

  ERROR_DELETE_ENTERPRISE_ASSET: "There was an error deleting the enterprise asset",

  ERROR_GETTING_ENTERPRISE_CUSTOM_FIELDS: "There was an error retrieving the enterprise custom fields",
};
