import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const DoubleDownCaret = ({ width = 10, height = 11, fill = cvar("dark-blue") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.788086 5.69385L4.64015 9.54591L8.49222 5.69385"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.788086 0.992188L4.64015 4.84425L8.49222 0.992188"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DoubleDownCaret;
