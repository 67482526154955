const tabs = [
  {
    key: "inbox",
    label: "Inbox",
  },
  {
    key: "archive",
    label: "Archive",
  },
];

export default tabs;
