const commonTabs = [
  {
    key: "general",
    label: "General",
  },
  {
    key: "organization",
    label: "Organization",
    conditionalRenderSelector: "getProfileOrganizationTabVisible",
  },
  {
    key: "reset-password",
    label: "Reset Password",
  },
  {
    key: "e-wallet",
    label: "E-wallet",
  },
  {
    key: "service-areas",
    label: "Service Areas & Licenses",
  },
  {
    key: "sales-partner",
    label: "Closer",
    conditionalRenderSelector: "getProfileMentorshipTabVisible",
  },
  {
    key: "achievements-and-certifications",
    label: "Achievements",
  },
];

const adminTabs = [];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
