import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";

import { theme } from "styles/themes";

export const CriteriaMenuHeader = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  padding: "5px",
  backgroundColor: theme.colors.white,
  boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.03)",
  width: "710px",
  height: "32px",
  borderRadius: "4px",
  paddingLeft: "12px",

  "&:hover": {
    cursor: "pointer",
  },
}));

export const CriteriaMenuLabel = styled("div")(() => ({
  paddingLeft: "12px",
  fontFamily: "Poppins",
  color: theme.colors["dark-blue"],
  fontWeight: 700,
  fontSize: "14px",
}));

export const StyledCriteriaMenu = styled(Menu)(() => ({
  "& .MuiMenu-paper": {
    padding: "15px 40px 35px",
    backgroundColor: theme.colors.white,
    borderRadius: "0px 0px 4px 4px",
    width: "710px",
    marginTop: "10px",
    boxShadow: `0 -11px 0 ${theme.colors.white}, 0px 4px 12px rgba(0, 0, 0, 0.15)`,
  },
}));

export const ButtonContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
}));
