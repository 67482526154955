import PropTypes from "prop-types";

export const visionUser = PropTypes.shape({
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
});

export const designRequestShape = PropTypes.shape({
  id: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  deletedAt: PropTypes.string,
  completedAt: PropTypes.string,
  pausedUntil: PropTypes.string,
  priority: PropTypes.number,
  timeAdded: PropTypes.number,
  designUserId: PropTypes.string,
  requstType: PropTypes.string,
});

export const visionDesign = PropTypes.shape({
  id: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  deletedAt: PropTypes.string,
  activatedAt: PropTypes.string,
  phase: PropTypes.string,
  completedAt: PropTypes.string,
  designRequestId: PropTypes.string,
  designRequest: designRequestShape,
});

export const visionSite = PropTypes.shape({
  id: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  deletedAt: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
});

export const visionProposalSchema = PropTypes.shape({
  id: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  deletedAt: PropTypes.string,

  homeowner: visionUser,
  homeownerId: PropTypes.string,
  seller: visionUser,
  sellerId: PropTypes.string,
  site: visionSite,
  siteId: PropTypes.string,
  designs: PropTypes.arrayOf(visionDesign),
  slug: PropTypes.string,
});

export const visionProposalDefaults = {};
