import types from "rdx/modules/enterprisePersonnel/types";
import createAction from "rdx/utils/createAction";

export default {
  getListOfAssignableServices: (payload) => createAction(types.GET_LIST_OF_ASSIGNABLE_SERVICES, payload),

  setListOfAssignableServices: (payload) => createAction(types.SET_LIST_OF_ASSIGNABLE_SERVICES, payload),

  sendEnterpriseTeamMemberInvite: (payload) => createAction(types.SEND_ENTERPRISE_TEAM_MEMBER_INVITE, payload),

  getEnterpriseUserDetails: (payload) => createAction(types.GET_ENTERPRISE_USER_DETAILS, payload),

  setEnterpriseUserDetails: (payload) => createAction(types.SET_ENTERPRISE_USER_DETAILS, payload),

  getPendingSeat: (payload) => createAction(types.GET_PENDING_SEAT, payload),

  setPendingSeat: (payload) => createAction(types.SET_PENDING_SEAT, payload),

  resetEnterpriseUserDetails: (payload) => createAction(types.RESET_ENTERPRISE_USER_DETAILS, payload),

  updateEnterpriseUserSettings: (payload) => createAction(types.UPDATE_ENTERPRISE_USER_SETTINGS, payload),

  updateEnterpriseUserServices: (payload) => createAction(types.UPDATE_ENTERPRISE_USER_SERVICES, payload),

  setEnterpriseServicesSeatChangePreview: (payload) =>
    createAction(types.SET_ENTERPRISE_SERVICES_SEAT_CHANGE_PREVIEW, payload),

  getEnterpriseSeatDetails: (payload) => createAction(types.GET_ENTERPRISE_SEAT_DETAILS, payload),

  setEnterpriseSeatDetails: (payload) => createAction(types.SET_ENTERPRISE_SEAT_DETAILS, payload),

  createSeatForExistingLevelOneUser: (payload) => createAction(types.CREATE_SEAT_FOR_EXISTING_LEVEL_ONE_USER, payload),

  rejectSeatInvite: (payload) => createAction(types.REJECT_SEAT_INVITE, payload),

  acceptSeatInvite: (payload) => createAction(types.ACCEPT_SEAT_INVITE, payload),

  updateEnterpriseUserRoles: (payload) => createAction(types.UPDATE_ENTERPRISE_USER_ROLES, payload),
  // INSERTION_PT (for script, do not remove)
};
