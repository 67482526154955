import React from "react";
import { UseFormSetValue, FieldErrors, UseFormGetValues } from "react-hook-form";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";

import useWindowResize from "hooks/useWindowResize";
import { meetingTypeOptions, dueDateOptions } from "lib/misc/dropdownOptions";
import {
  SmartFormDropdown,
  SmartFormTextField,
  SmartFormTextarea,
  SmartFormInputTimePicker,
} from "components/mui-styled-components/SmartFormElements";
import SmartFormSingleLineAddress from "components/mui-styled-components/SmartFormElements/SmartFormSingleLineAddress";
import { LocationPinFilledIcon, CalendarTimerIcon } from "components/Icons";
import { cvar } from "styles";
import { CrmActivityFormT } from "../..";
import * as S from "../../styles";

type CrmMeetingFormPropsT = {
  errors: FieldErrors<CrmActivityFormT>;
  setValue: UseFormSetValue<CrmActivityFormT>;
  getValues: UseFormGetValues<CrmActivityFormT>;
  initialAddressValue?: string;
  readOnly?: boolean;
  setIsCustomDateModalOpen: (isOpen: boolean) => void;
  customDueDate: string;
};

const CrmMeetingForm = ({
  errors,
  setValue,
  getValues,
  initialAddressValue = "",
  readOnly = false,
  setIsCustomDateModalOpen,
  customDueDate,
}: CrmMeetingFormPropsT) => {
  const { isMobileWidth } = useWindowResize();
  const showAddressField = getValues("meetingType") === "in_person";

  const clearAddressValue = () => {
    setValue("location", "");
  };

  const handleAutocompleteSelect = (_: React.SyntheticEvent<Element, Event>, value: string): void => {
    if (value) {
      setValue("location", value);
    }
  };

  return (
    <>
      <Grid container sx={{ marginBottom: "32px" }}>
        <Grid item xs={12}>
          <SmartFormTextField
            name="title"
            label="Title *"
            placeholder="Add Appointment Title..."
            error={!!errors.title}
            helperText={errors.title?.message}
            disabled={readOnly}
            onClick={(e) => e.stopPropagation()}
          />
        </Grid>
      </Grid>
      <Grid container rowSpacing={4} sx={{ ...(isMobileWidth ? {} : { width: "422px" }), marginBottom: "40px" }}>
        <Grid item xs={12}>
          <SmartFormDropdown
            key="meetingType"
            name="meetingType"
            mainLabel="Appointment Type *"
            placeholder="Add Appointment Type..."
            dropdownOptions={meetingTypeOptions}
            error={!!errors.meetingType}
            helperText={errors.meetingType?.message}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          {showAddressField ? (
            <SmartFormSingleLineAddress
              key="location"
              name="location"
              label="Location"
              placeholder="Add Location..."
              clearValues={clearAddressValue}
              prefixIcon={<LocationPinFilledIcon />}
              handleAutocompleteSelect={handleAutocompleteSelect}
              autoCompleteDefaultValue={initialAddressValue}
              {...(initialAddressValue ? { defaultSuggestions: [initialAddressValue] } : {})}
              disabled={readOnly}
            />
          ) : (
            <SmartFormTextField
              key="location"
              name="location"
              label="Location"
              placeholder="Add Location..."
              error={!!errors.location}
              helperText={errors.location?.message}
              disabled={readOnly}
              onClick={(e) => e.stopPropagation()}
            />
          )}
        </Grid>
      </Grid>
      <Grid container rowSpacing={5} columnSpacing={2}>
        <Grid item xs>
          <SmartFormDropdown
            name="dueDate"
            mainLabel="Date *"
            renderValue={(value) => {
              if (value === "custom") {
                return <>{`Custom Date (${dayjs(customDueDate).format("ddd M/D")})`}</>;
              }
              const item = dueDateOptions.find(({ value: v }) => v === value);
              return item?.label || "";
            }}
            prefixIcon={<CalendarTimerIcon fill={cvar("graham-grey")} height={17} width={18} />}
            prefixIconOnClick={() => {
              setValue("dueDate", "custom");
              setIsCustomDateModalOpen(true);
            }}
            dropdownOptions={[
              {
                value: "custom",
                label: "Custom Date",
                onClick: () => setIsCustomDateModalOpen(true),
              },
              ...dueDateOptions,
            ]}
            error={!!errors.dueDate}
            helperText={errors.dueDate?.message}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs="auto">
          <SmartFormInputTimePicker
            smartElement
            setTimeFieldValue={(_, value) => setValue("dueTime", value || "")}
            name="dueTime"
            label="Time *"
            error={!!errors.dueTime}
            helperText={errors.dueTime?.message}
            iconColor={cvar("graham-grey")}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <SmartFormTextarea
            name="notes"
            placeholder="Add hand off notes for your closer..."
            subLabel="Appointment Notes"
            subLabelStyle={{ ...S.FormLabel, textTransform: "uppercase", marginLeft: "2px" }}
            height={124}
            disabled={readOnly}
            onClick={(e) => e.stopPropagation()}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CrmMeetingForm;
