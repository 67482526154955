import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enterprisePersonnel/messages";
import { camelizeKeys } from "@helpers";

function* getEnterpriseSeatDetails(action) {
  const { success, data, error } = yield* makeRequest.get(`/seats/${action.payload.seatId}`, {});
  if (success && data) {
    yield put(actions.setEnterpriseSeatDetails(camelizeKeys(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ENTERPRISE_USER_DETAILS,
    });
  }

  return null;
}

export default getEnterpriseSeatDetails;
