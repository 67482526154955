import tabs from "containers/Admin/adminfinancials/tabs";
import { EarningsIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import sidebar from "containers/Admin/adminfinancials/components/Sidebar";
import l from "@loadable/component";

const content = l(() => import("containers/Admin/adminfinancials"));

const { FINANCE_MANAGEMENT, FINANCE_MANAGEMENT_READ_ONLY } = permissionTypes;

export default {
  key: "admin-financials",
  title: "Financials",
  path: "/admin-financials/:tab?",
  linkTarget: "/admin-financials",
  linkTitle: "Financials",
  basePath: "admin-financials",
  linkCategory: "Admin",
  permission: [FINANCE_MANAGEMENT, FINANCE_MANAGEMENT_READ_ONLY],
  icon: EarningsIcon,
  tabs,
  content,
  sidebar,
};
