import { styled } from "@mui/material/styles";
import { cvar } from "styles";

export const OuterContainer = styled("div")(() => ({
  padding: "24px 0",
}));

export const Container = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
  marginTop: "-20px",
}));

export const LeftSection = styled("div")(() => ({
  marginTop: "20px",
  marginBottom: "20px",
  padding: "18px 22px 8px 18px",
  width: "calc(50% - 12px)",
  flexGrow: "1",
  minWidth: "300px",
  background: cvar("dark-blue-a07"),
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.03)",
  borderRadius: "12px",
  display: "flex",
}));

export const LeftSider = styled("div")(() => ({
  minWidth: "40px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const LeftMain = styled("div")<{
  paddingBottom?: string;
}>(({ paddingBottom = 0 }) => ({
  paddingLeft: "13px",
  paddingBottom,
}));

export const LeftMainTitle = styled("div")(() => ({
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: " 700",
  lineHeight: "22px",
  color: cvar("blue-ribbon"),
  marginBottom: "15px",
}));

export const LeftMainSubtitle = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "14px",
  fontWeight: "700",
  lineHeight: "19px",
  color: cvar("queen-blue"),
  marginBottom: "15px",
}));

export const LeftMainBody = styled("span")(() => ({
  fontFamily: "Barlow",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "19px",
  color: cvar("queen-blue"),
}));

export const RightSection = styled("div")(() => ({
  marginTop: "20px",
  width: "calc(50% - 12px)",
  marginLeft: "24px",
  flexGrow: "1",
  minWidth: "300px",
  height: "100%",

  ".ant-radio-wrapper": {
    span: {
      color: cvar("queen-blue"),
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "17px",
    },
  },

  ".ant-row:last-of-type": {
    marginBottom: "0px",
    paddingBottom: "0px",
    height: "100px",
  },
}));

export const QuestionRowStyle = {
  fontFamily: "Barlow",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "19px",
  color: cvar("darkest-blue"),
};

type QuestionRowProps = {
  marginBottom?: string;
};

export const QuestionRow = styled("div")<QuestionRowProps>(({ marginBottom }) => ({
  ...QuestionRowStyle,
  marginBottom: `${marginBottom}`,
}));

export const Space = styled("div")(() => ({
  height: "30px",
}));

export const TextArea = styled("textarea")(() => ({
  width: "100%",
  height: "100px",
  border: `1px solid ${cvar("botticelli-blue")}`,
  borderRadius: "4px",
  padding: "5px 12px",
  resize: "none",
  color: cvar("queen-blue"),
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "19px",
  marginLeft: "-4px",
}));
