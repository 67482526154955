import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/buildPartnerApplications/messages";
import { camelizeKeys, keysToSnakeCase } from "lib/helpers";

function* updateBuildPartnerApplications(action) {
  const { id, values, enterpriseApplicationId } = action.payload;
  const params = {
    ...keysToSnakeCase(values),
    enterprise_application_id: enterpriseApplicationId,
  };

  const { success, data, error } = yield* makeRequest.patch(`/labor_partner_applications/${id}`, params);

  if (success && data) {
    yield all([
      put(
        actions.setBuildPartnerApplicationDetail({
          ...camelizeKeys(data.body),
          serviceAreas: data.body.service_areas,
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_BUILD_PARTNER_APPLICATIONS_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_BUILD_PARTNER_APPLICATIONS,
    });
  }

  return null;
}

export default updateBuildPartnerApplications;
