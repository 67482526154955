import { put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import { keysToCamelCase } from "lib/helpers";
import _ from "lodash";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "../../messages";

function* getPhaseMilestones(action) {
  const { phaseId } = action.payload;

  const { success, data, error } = yield* makeRequest.get(`/phases/${phaseId}/milestones`, {});
  if (success && data) {
    const ms = data.body.milestones.map((m) => keysToCamelCase(m));
    const workflow = yield select(selectors.getWorkflowDetails);
    const newWorkflow = _.clone(workflow);

    const newPhases = newWorkflow.phases?.map((p) => (p.id === phaseId ? { ...p, milestones: ms } : p));

    yield put(actions.setWorkflowDetails({ ...newWorkflow, phases: newPhases }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_WORKFLOW_DETAILS,
    });
  }
  return null;
}

export default getPhaseMilestones;
