/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { FC } from "react";

import type { RadioOptionsT } from "@mui-styled-components/FormRadioGroup";

import SmartFormTextarea from "components/mui-styled-components/SmartFormElements/SmartFormTextarea";
import ActivityIcon from "components/Icons/ActivityIcon";
import SmartFormRadioGroup from "components/mui-styled-components/SmartFormElements/SmartFormRadioGroup";

import * as copy from "./copyText";
import * as S from "./styles";

const optimizationOptions: RadioOptionsT = [
  { label: "Same", value: "1" },
  { label: "Optimized", value: "2" },
];

const streetFacingOptions: RadioOptionsT = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

const DesignTeamSurveyContent: FC = () => {
  return (
    <S.Container data-test-id="design-team-questions">
      <S.LeftSection>
        <S.LeftSider>
          <ActivityIcon />
        </S.LeftSider>
        <S.LeftMain paddingBottom="13px">
          <S.LeftMainTitle>{copy.leftMainTitle}</S.LeftMainTitle>
          <S.LeftMainSubtitle>{copy.leftMainSubtitle}</S.LeftMainSubtitle>
          <S.LeftMainBody>{copy.leftMainBody}</S.LeftMainBody>
        </S.LeftMain>
      </S.LeftSection>
      <S.RightSection>
        <SmartFormRadioGroup
          name="optimization"
          mainLabel={copy.questionOne}
          mainLabelStyle={S.QuestionRowStyle}
          radioOptions={optimizationOptions}
          error={false}
          row
        />
        <S.Space />
        <SmartFormRadioGroup
          name="streetFacing"
          mainLabel={copy.questionTwo}
          mainLabelStyle={S.QuestionRowStyle}
          radioOptions={streetFacingOptions}
          error={false}
          row
        />
        <S.Space />
        <SmartFormTextarea
          name="additional"
          mainLabel={copy.questionThree}
          mainLabelStyle={{ ...S.QuestionRowStyle, marginBottom: "12px" }}
        />
      </S.RightSection>
    </S.Container>
  );
};

export default DesignTeamSurveyContent;
