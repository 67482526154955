import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* deleteInvite(action) {
  const { inviteId } = action.payload;

  const { success, data, error } = yield* makeRequest.delete(`/registrations/${inviteId}`);

  if (success && data) {
    yield put(actions.newSuccessEvent({ message: messages.INVITE_DELETE_SUCCESS }));
    yield put(actions.getTeamLeadsList());
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_INVITE,
    });
  }

  return null;
}

export default deleteInvite;
