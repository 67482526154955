import React from "react";
import PropTypes from "prop-types";

const CancelIconFilled = (props) => {
  const { width, height, backfill, fill } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="7.03125" r="7" fill={backfill} />
      <rect
        x="9.29883"
        y="3.67285"
        width="1.5"
        height="8"
        rx="0.75"
        transform="rotate(45 9.29883 3.67285)"
        fill={fill}
      />
      <rect
        x="10.3594"
        y="9.33008"
        width="1.5"
        height="8"
        rx="0.75"
        transform="rotate(135 10.3594 9.33008)"
        fill={fill}
      />
    </svg>
  );
};

CancelIconFilled.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  backfill: PropTypes.string,
  fill: PropTypes.string,
};

CancelIconFilled.defaultProps = {
  width: "14",
  height: "15",
  backfill: "var(--botticelli-blue)",
  fill: "white",
};

export default CancelIconFilled;
