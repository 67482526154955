import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* updatePreSalesMilestones(action) {
  const { id, isKanban } = action.payload;

  const { success, data, error } = yield* makeRequest.patch(`/provider_leads/${id}/pre_sales_milestones`, {
    contract_out: action.payload.data.contractOut,
    proposal_presented: action.payload.data.proposalPresented,
    id,
  });
  if (success && data) {
    yield all([
      put(actions.setProjectDetails({ providerLead: camelizeKeys(data.body) })),
      put(actions.getProjectsPreSales()),
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_PRE_SALES_MILESTONES_SUCCESS,
        }),
      ),
      put(
        actions.setAlertMessageVisible({
          message: messages.UPDATE_PRE_SALES_MILESTONES_SUCCESS,
          severity: "success",
        }),
      ),
      ...(isKanban ? [put(actions.setProviderLeadStageInKanban({ providerLead: camelizeKeys(data.body) }))] : []),
    ]);
  } else {
    return yield put(actions.setFormErrors({ error }));
  }
  return null;
}

export default updatePreSalesMilestones;
