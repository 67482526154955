import { put, all } from "redux-saga/effects";
import actions from "rdx/actions";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* starTicket(action) {
  const { id } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/messages/${id}/star`, {});

  if (success && data) {
    const list = data.body.messages.map((entry) => keysToCamelCase(entry));
    yield all([
      put(actions.setProjectMessageList({ list })),
      put(
        actions.newSuccessEvent({
          message: messages.STAR_TICKET_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_STARRING_TICKET,
    });
  }
  return null;
}

export default starTicket;
