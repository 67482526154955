import React from "react";
import { Grid } from "@mui/material";

import { Avatar } from "components/mui-styled-components/AutoComplete/Avatar";
import { PrimaryButton } from "components/mui-styled-components";
import MentorStars from "components/UserCards/Mentor/MentorStars";
import { T3PartnerT } from "components/Drawers/SelectT3PartnerDrawer/types";
import * as S from "./styles";

type MobileColPropsT = {
  setSelectedT3Partner: (t3Partner: T3PartnerT) => void;
  setOpenFooter: (bool: boolean) => void;
  closer: T3PartnerT;
};

const MobileColContent = ({ setSelectedT3Partner, setOpenFooter, closer }: MobileColPropsT) => {
  const handleAssign = () => {
    setSelectedT3Partner(closer);
    setOpenFooter(true);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2} sx={{ flexWrap: "nowrap" }}>
          <Grid item xs="auto" sx={{ marginLeft: "10px" }}>
            <Avatar
              type="user"
              avatarContent={closer.avatar.thumb}
              avatarWidth="50px"
              defaultAvatarWidth="50px"
              borderRadius="25px"
            />
          </Grid>
          <Grid item xs sx={{ flex: "1 1 100%", minWidth: 0 }}>
            <S.UserNameRow>{closer.fullName}</S.UserNameRow>
            {closer.address?.city && closer.address?.state && (
              <S.SellerDetails>
                {closer.address?.city}, {closer.address?.state}
              </S.SellerDetails>
            )}
            <MentorStars
              count={closer?.mentorRatings?.totalCount}
              average={closer?.mentorRatings?.meanOfAvgs}
              numberDisplay
            />
          </Grid>
          <Grid item xs="auto" sx={{ alignSelf: "flex-start" }}>
            <PrimaryButton gradient onClick={handleAssign} style={{ minWidth: "75px", marginTop: "4px" }}>
              Assign
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default MobileColContent;
