// import content from "containers/Sales/earnings";
import { EarningsIcon } from "@icons";
import tabs from "containers/Sales/earnings/tabs";
import l from "@loadable/component";
import { permissionTypes } from "hooks/usePermissions";

const content = l(() => import("containers/Sales/earnings"));

const { VIEW_FINANCIALS, PREVIEW_USER } = permissionTypes;

export default {
  key: "earnings",
  title: "My Earnings",
  path: "/earnings/:tab?",
  linkTarget: null,
  linkTitle: "Earnings",
  linkCategory: "Sales",
  icon: EarningsIcon,
  basePath: "earnings",
  requiredParams: ["tab"],
  optionalParams: [],
  tabs,
  content,
  sidebar: null,
  permission: [VIEW_FINANCIALS, PREVIEW_USER],
};
