import React from "react";
import PropTypes from "prop-types";

const MagnifyingGlassBold = (props) => {
  const { width, height, fill } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 13 14" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.46525 1.96463C2.40781 1.02223 3.66578 0.5 4.99998 0.5C6.33395 0.5 7.59234 1.02059 8.53533 1.96525C9.47738 2.90897 9.99996 4.16379 9.99996 5.49998C9.99996 6.46448 9.7281 7.39031 9.21923 8.18563L12.8174 11.7838C12.8752 11.8416 12.9212 11.9104 12.9525 11.986C12.9839 12.0616 13 12.1426 13 12.2245C13 12.3063 12.9839 12.3873 12.9525 12.4629C12.9212 12.5386 12.8753 12.6073 12.8174 12.6651L12.1636 13.3174C12.1059 13.375 12.0368 13.4213 11.9614 13.4525C11.8859 13.4839 11.8048 13.5 11.723 13.5C11.6411 13.5 11.5601 13.4839 11.4845 13.4525C11.4089 13.4212 11.3403 13.3754 11.2825 13.3176L7.6839 9.72039C6.88916 10.228 5.96462 10.5 4.99998 10.5C3.66457 10.5 2.40784 9.97942 1.46494 9.03502C0.522348 8.09243 0 6.83433 0 5.49998C0 4.16447 0.520716 2.90755 1.46525 1.96463ZM4.99998 2.64C4.23598 2.64 3.51997 2.93655 2.97742 3.47767C2.43683 4.01832 2.14 4.73556 2.14 5.49998C2.14 6.2639 2.43649 6.97984 2.9775 7.52237C3.52004 8.06344 4.23602 8.35997 4.99998 8.35997C5.76402 8.35997 6.48007 8.06338 7.02263 7.5222C7.56316 6.98156 7.85997 6.26436 7.85997 5.49998C7.85997 4.73584 7.5633 4.01971 7.022 3.47713C6.48187 2.93572 5.76497 2.64 4.99998 2.64Z"
        fill={fill}
      />
    </svg>
  );
};

MagnifyingGlassBold.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

MagnifyingGlassBold.defaultProps = {
  width: "13",
  height: "14",
  fill: "var(--dark-blue)",
};

export default MagnifyingGlassBold;
