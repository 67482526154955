import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  widgetNotifications: {},
  allNotifications: {
    crmNotifications: [],
    paging: {
      pageSize: 0,
      itemCount: 0,
      pageNumber: 1,
    },
  },
  filters: {},
};

export default {
  notifications: createReducer(initialState, {
    [types.SET_DASHBOARD_NOTIFICATIONS](state, action) {
      return {
        ...state,
        widgetNotifications: {
          ...state.widgetNotifications,
          ...action.payload,
        },
      };
    },
    [types.SET_ALL_NOTIFICATIONS](state, action) {
      return {
        ...state,
        allNotifications: {
          crmNotifications: action.payload.crmNotifications,
          paging: action.payload.paging,
        },
      };
    },
    [types.SET_NOTIFICATION_FILTERS](state, action) {
      return {
        ...state,
        filters: action.payload.filters,
      };
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};
