import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";

import styles from "components/PlatformLayout/components/PlatformPageHeader/PlatformPageHeader.module.less";

const { Title } = Typography;

const GettingStartedBreadCrumbs = () => {
  const [tabTitle, setTabTitle] = useState(null);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    if (params.tab) setTabTitle(params.tab.replace(/-/g, " "));
    if (params.modal === "payment") setTabTitle("Payment");
  }, [params]);

  const handleBaseNavigate = () => {
    history.push("/getting-started");
  };

  const textStyle = {
    cursor: "pointer",
    textTransform: "capitalize",
    fontWeight: 600,
  };

  return (
    <>
      <Title className={styles.title} style={textStyle} onClick={handleBaseNavigate}>
        Getting Started
      </Title>
      {tabTitle && (
        <>
          <RightOutlined className={styles.title} style={{ margin: "0 10px" }} />
          <Title className={styles.title} style={textStyle}>
            {tabTitle}
          </Title>
        </>
      )}
    </>
  );
};

export default GettingStartedBreadCrumbs;
