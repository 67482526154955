import { put, select, all } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import makeRequest from "rdx/utils/makeRequest";
import _ from "lodash";
import actions from "rdx/actions";
import selectors from "rdx/modules/adminProposals/selectors";
import messages from "../messages";

function* createProposal(action) {
  const { id, url } = action.payload;

  const params = {
    url,
  };

  const { success, data, error } = yield* makeRequest.post(`/proposal_flows/${id}/set_proposal`, params);
  if (success && data) {
    const { projects } = yield select(selectors.getInProgressProposalsList);
    const next = _.clone(projects).filter((p) => p.project.id !== id);
    yield all([
      put(actions.setInProgressAdminProposals({ projects: next })),
      put(actions.newSuccessEvent({ message: messages.PROPOSAL_CREATED })),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_SETTING_PROPOSAL,
    });
  }
  return null;
}

export default createProposal;
