import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = false;

export default {
  bypassPaywall: createReducer(initialState, {
    [types.UPDATE_BYPASS_PAYWALL]() {
      return true;
    },
    [types.RESET_BYPASS_PAYWALL]() {
      return false;
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};
