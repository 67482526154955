// import content from "containers/Admin/platformAlerts";
import { PlatformAlerts } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";

const content = l(() => import("containers/Admin/platformAlerts"));

const { PLATFORM_COMMUNICATIONS } = permissionTypes;

export default {
  key: "platform-alerts",
  title: "Platform Alerts",
  path: "/platform-alerts/:view?/:tab?/:noticeId?",
  linkTarget: "/platform-alerts",
  linkTitle: "Platform Alerts",
  basePath: "platform-alerts",
  linkCategory: "Admin",
  permission: PLATFORM_COMMUNICATIONS,
  icon: PlatformAlerts,
  tabs: null,
  content,
  sidebar: null,
};
