import z from "zod";
import { OrgTeamFilterParamsSchema } from "rdx/modules/enterprisePersonnel/schemas";
import { CsvJobStateEnum, CsvJobNameEnum } from "./enums";

export const CsvJobStateSchema = z.nativeEnum(CsvJobStateEnum);
export const CsvJobNameSchema = z.nativeEnum(CsvJobNameEnum);

export const GetTeamUsersCsvParamsSchema = z.object({
  filterParams: OrgTeamFilterParamsSchema,
  searchedUserId: z.union([z.number(), z.null()]),
  isEnterprise: z.boolean(),
});

export const CsvJobSchema = z.object({
  results: z.boolean(),
  state: CsvJobStateSchema.nullable(),
  ts: z.string(),
});

export const CsvReportSchema = z.object({
  createdAt: z.string(),
  id: z.number(),
  jobName: CsvJobNameSchema.nullable(),
  name: z.string(),
  url: z.string().nullable(),
  jobStatus: z.string().nullable(),
});

export const TeamUsersCsvSchema = CsvReportSchema.extend({
  jobs: z.object({
    teamUsersCsvJob: CsvJobSchema,
  }),
  updatedAt: z.string(),
  userId: z.number(),
});

export const GetTeamUsersCsvResponseSchema = z.object({
  status: z.number(),
  body: z.object({
    csv: TeamUsersCsvSchema,
  }),
});

export const GetCsvReportByIdResponseSchema = z.object({
  status: z.number(),
  body: z.object({
    report: CsvReportSchema,
  }),
});
