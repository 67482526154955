import types from "rdx/modules/adminSms/types";
import createAction from "rdx/utils/createAction";

export default {
  // exampleAction: payload => createAction(types.EXAMPLE_ACTION_TYPE, payload),
  getSmsList: (payload) => createAction(types.GET_SMS_LIST_REQUEST, payload),
  deleteSmsRequest: (payload) => createAction(types.DELETE_SMS_REQUEST, payload),
  deleteSms: (payload) => createAction(types.DELETE_SMS, payload),
  createSms: (payload) => createAction(types.CREATE_SMS_REQUEST, payload),
  setSmsList: (payload) => createAction(types.SET_SMS_LIST, payload),
  setSmsLanguages: (payload) => createAction(types.SET_SMS_LANGUAGES, payload),
  setSmsRecipients: (payload) => createAction(types.SET_SMS_RECIPIENTS, payload),
  getSmsDetails: (payload) => createAction(types.GET_SMS_DETAILS_REQUEST, payload),
  setSmsDetails: (payload) => createAction(types.SET_SMS_DETAILS, payload),
  updateSmsDetail: (payload) => createAction(types.UPDATE_SMS_DETAIL, payload),
  // INSERTION_PT (for script, do not remove)
};
