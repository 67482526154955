export default {
  CREATE_PROJECT_MESSAGE_SUCCESS: "New ticket created.",
  UPDATE_PROJECT_MESSAGE_SUCCESS: "Ticket updated.",
  RESOLVE_PROJECT_MESSAGE_SUCCESS: "Ticket resolved and archived in your inbox.",
  REOPEN_PROJECT_MESSAGE_SUCCESS: "Ticket unresolved and unarchived in your inbox.",
  STAR_TICKET_SUCCESS: "Ticket starred.",
  UNSTAR_TICKET_SUCCESS: "Ticket unstarred.",
  ASSIGN_PROJECT_MESSAGE_SUCCESS: "Ticket Reassigned",
  DELETE_PROJECT_MESSAGE_SUCCESS: "Ticket deleted.",
  POST_PROJECT_MESSAGE_REPLY_SUCCESS: "Ticket reply posted.",
  GET_PROJECT_MESSAGE_LIST_SUCCESS: "Project tickets retrieved.",
  GET_PROJECT_REPLIES_PREVIEW_SUCCESS: "First 2 replies for each project ticket retrieved.",
  ARCHIVE_MESSAGES_SUCCESS: "Ticket(s) archived.",
  UNARCHIVE_MESSAGES_SUCCESS: "Ticket(s) retrieved from archive.",

  // error messages
  ERROR_GETTING_MESSAGE_LIST: "There was an error retrieving tickets list",
  ERROR_GETTING_FORM_DATA: "There was an error retrieving ticket form data",
  ERROR_GETTING_MESSAGE_DETAILS: "There was an error retrieving ticket details",
  ERRPR_REPLYING_TO_MESSAGE: "There was an error replying to this ticket.",
  ERROR_UPDATING_PROJECT_MESSAGE: "There was an error updating this ticket.",
  ERROR_CREATING_PROJECT_MESSAGE: "There was an error posting new ticket.",
  ERROR_RESOLVING_PROJECT_MESSAGE: "There was an error marking this ticket as resolved.",
  ERROR_STARRING_TICKET: "There was an error starring this ticket",
  ERROR_UNSTARRING_TICKET: "There was an error unstarring this ticket",
  ERROR_REOPENING_PROJECT_MESSAGE: "There was an error marking this ticket as open.",
  ERROR_ASSIGNING_PROJECT_MESSAGE: "There was an error assigning this ticket.",
  ERROR_DELETING_PROJECT_MESSAGE: "There was an error deleting this ticket.",
  ERROR_GETTING_PROJECT_REPLIES_PREVIEW: "There was an error getting project ticket replies",
  ERROR_ARCHIVING_MESSAGES: "There was an error archiving ticket(s)",
  ERROR_UNARCHIVING_MESSAGES: "There was an error retrieving ticket(s) from archive",
};
