import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
import RoundBadge from "./components/RoundBadge";
import Header from "./components/Header";

import styles from "./CreateAccount.module.less";

const MobileCard = ({ dataLoaded, tabData, handlePreviousTabClick, activeIndex }) => {
  if (!dataLoaded) {
    return (
      <div className={styles.joinPowurMobileContainer}>
        <div className={styles.joinPowurMobileCard}>
          <div className={styles.registrationLoading}>
            <Spin />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.joinPowurMobileContainer}>
      <div className={styles.joinPowurMobileCard}>
        <Header activeIndex={activeIndex} data={tabData} handlePreviousTabClick={handlePreviousTabClick} />
        {tabData[activeIndex].component}
      </div>
      {tabData[activeIndex].badgeImg && (
        <div className={styles.joinPowurMobileBadge}>
          <RoundBadge
            imgSource={tabData[activeIndex].badgeImg}
            imgAlt={tabData[activeIndex].badgeImgAlt}
            imgStyle={tabData[activeIndex].badgeImgStyle}
          />
        </div>
      )}
    </div>
  );
};

MobileCard.propTypes = {
  tabData: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.string,
      title: PropTypes.string,
      tabImg: PropTypes.string,
      tabImgStyle: PropTypes.shape({
        objectPosition: PropTypes.string,
      }),
      tabImgAlt: PropTypes.string,
      badgeImg: PropTypes.string,
      badgeImgStyle: PropTypes.shape({
        objectPosition: PropTypes.string,
      }),
      badgeImgAlt: PropTypes.string,
      component: PropTypes.node,
    }),
  ),
  handlePreviousTabClick: PropTypes.func,
  activeIndex: PropTypes.number,
  dataLoaded: PropTypes.bool,
};

export default MobileCard;
