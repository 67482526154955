import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* deleteLicense(action) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/profile/licenses/${id}`, {});
  if (success && data) {
    yield all([
      put(actions.setProfile(keysToCamelCase(data.body))),
      put(
        actions.newSuccessEvent({
          message: messages.DELETE_LICENSE_SUCCESS,
          payload: { licenseId: id },
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_LICENSE,
    });
  }
  return null;
}

export default deleteLicense;
