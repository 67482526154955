import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";

function* downloadAssets(action) {
  const { projectLeadId } = action.payload;
  const { success, data, error } = yield* makeRequest.get(
    `/provider_leads/${projectLeadId}/assets/verify_user_download`,
    {},
  );
  if (success && data) {
    const requestUrl = new URL(
      `${process.env.REACT_APP_POWUR_API_URL}/json_web/provider_leads/${projectLeadId}/assets/download_all_common`,
    );
    requestUrl.searchParams.append("token", data.token);
    window.open(requestUrl, "blank");
  } else {
    return getErrorActions({
      error,
      message: "download request failed",
    });
  }

  return null;
}

export default downloadAssets;
