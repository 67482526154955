import { styled, Tab, Tabs, MenuItem, Select } from "@mui/material";
import { CheckOutlined } from "@ant-design/icons";
import { filterStyledProps } from "lib/helpers/filterStyledProps";

export const StyledTabs = styled(Tabs, {
  shouldForwardProp: filterStyledProps(["isMiniHeader"]),
})<{ isMiniHeader: boolean }>(({ theme, isMiniHeader }) => ({
  maxWidth: "1152px",
  width: "100%",
  height: "48px",
  background: "white",
  transition: "all .5s ease",
  ...(!isMiniHeader && {
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.03)",
    borderRadius: "10px",
  }),
  ".MuiTabs-indicator": {
    background: theme.colors["blue-ribbon"],
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.colors["font-color-eastern-blue"],
  textTransform: "capitalize",
  ".Mui-selected": {
    color: theme.colors["blue-ribbon"],
  },
  fontFamily: "Poppins",
}));

export const TabDropDown = styled(Select)(({ theme }) => ({
  width: "155px",
  height: "30px",
  margin: "12px 0px 14px 12px",
  "& > .MuiSelect-select": {
    color: theme.colors["darkest-blue"],
  },
  "& > fieldset": {
    borderRadius: "4px",
    borderColor: theme.colors["botticelli-blue"],
  },
  "&.Mui-focused > fieldset": {
    borderRadius: "4px",
    borderColor: `${theme.colors["botticelli-blue"]}!important`,
  },
}));

export const TabDropDownItem = styled(MenuItem)(({ theme }) => ({
  "&.Mui-selected": {
    color: theme.colors["royal-blue"],
    background: "transparent",
  },
}));

export const CheckIcon = styled(CheckOutlined)(({ theme }) => ({
  "&>svg": {
    fill: theme.colors["royal-blue"],
  },
}));
