import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import _ from "lodash";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getUserDetails(action) {
  const { userId } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/users/${userId}`, {});
  if (success && data) {
    const user = camelizeKeys(_.clone(data.body));

    if (user.metrics) {
      user.metrics = {};
      data.body.metrics.forEach((metric) => {
        const key = Object.keys(metric)[0];
        const value = Object.values(metric)[0];
        user.metrics[key] = value;
      });
    }

    yield put(actions.setUserDetails(user));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_USER_DETAILS,
    });
  }
  return null;
}

export default getUserDetails;
