import React, { CSSProperties } from "react";
import { TextFieldProps } from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Tooltip } from "components/mui-styled-components/Tooltip";

import { useController } from "react-hook-form";

import { useSmartFormContext } from "components/SmartForm";
import HelperText from "../HelperText";
import * as S from "./styles";

export type SmartFormTextFieldT = {
  label?: string;
  name: string;
  helperText?: string | undefined;
  error?: boolean;
  disabled?: boolean;
  labelStyle?: CSSProperties;
  required?: boolean;
  prefixIcon?: React.ReactNode;
  type?: string;
  fieldPrefix?: React.ReactNode;
  prefixBorder?: boolean;
  fieldSuffix?: React.ReactNode;
  suffixBorder?: boolean;
  fullWidth?: boolean;
  tooltipText?: string;
  isLargeText?: boolean;
  noMarginBlockEnd?: boolean;
  hasRedHighlight?: boolean;
} & TextFieldProps;

const SmartFormTextField = ({
  label,
  name,
  helperText,
  error,
  disabled = false,
  labelStyle = {},
  required,
  type = "text",
  fullWidth = true,
  fieldPrefix,
  prefixBorder = true,
  fieldSuffix,
  suffixBorder = true,
  tooltipText,
  isLargeText = false,
  noMarginBlockEnd = false,
  hasRedHighlight = false,
  ...rest
}: SmartFormTextFieldT) => {
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });

  return (
    <>
      <S.LabelGroupWrapper>
        <S.StyledFormLabel style={labelStyle}>{label}</S.StyledFormLabel>
        {required && <S.StyledRequiredLabel>*</S.StyledRequiredLabel>}
        {tooltipText && (
          <Tooltip placement="top" title={<S.TooltipText>{tooltipText}</S.TooltipText>}>
            <S.TooltipIcon>!</S.TooltipIcon>
          </Tooltip>
        )}
      </S.LabelGroupWrapper>
      <S.StyledTextField
        fullWidth={fullWidth}
        hasPrefix={!!fieldPrefix}
        hasSuffix={!!fieldSuffix}
        isLargeText={isLargeText}
        noMarginBlockEnd={noMarginBlockEnd}
        redHighlight={hasRedHighlight}
        InputProps={{
          ...(fieldPrefix && {
            startAdornment: (
              <InputAdornment position="start">
                <S.PrefixWrapper border={prefixBorder}>{fieldPrefix}</S.PrefixWrapper>
              </InputAdornment>
            ),
          }),
          ...(fieldSuffix && {
            endAdornment: (
              <InputAdornment position="end">
                <S.SuffixWrapper border={suffixBorder}>{fieldSuffix}</S.SuffixWrapper>
              </InputAdornment>
            ),
          }),
        }}
        data-test-id={`${name}-SmartFormTextField`}
        disabled={disabled}
        type={type}
        size="small"
        defaultValue={rest.defaultValue}
        sx={{ backgroundColor: "white", borderRadius: "4px" }}
        {...field}
        value={field.value}
        {...rest}
      />
      <HelperText sx={{ marginTop: "0px" }} error={error}>
        {helperText}
      </HelperText>
    </>
  );
};

export default SmartFormTextField;
