import { put, select } from "redux-saga/effects";
import { camelizeKeys } from "lib/helpers";
import { clone } from "lodash";
import selectors from "rdx/modules/filterSets/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/filterSets/messages";

function* createFilterSet(action) {
  const params = action.payload;

  const { success, data, error } = yield* makeRequest.post("/filter_sets", params);

  if (success && data) {
    const response = camelizeKeys(data.body.filter_set);
    const current = yield select(selectors.getFilterSets);
    const filterSets = clone(current);
    filterSets.push(response);

    yield put(
      actions.newSuccessEvent({
        message: messages.FILTER_SET_CREATE_SUCCESS,
      }),
    );
    yield put(actions.setFilterSets({ filterSets }));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_SAVING_FILTER_SET,
    });
  }
  return null;
}

export default createFilterSet;
