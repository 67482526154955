import React, { useState, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DialogActions } from "@mui/material";
import { PrimaryButton } from "components/mui-styled-components/Buttons";
import GradientGhostButton from "components/Buttons/GradientGhostButton";
import { RightCaretFilled, LeftCaretFilled, DownCaretFilled, CloseIcon } from "components/Icons";
import { cvar } from "styles";
import DateTimeToolbar from "./DateTimeToolbar";
import * as S from "./styles";

export type StaticDateTimePickerT = {
  handleSave: (selectedTimeStamp: string) => void;
  onClose: () => void;
  hideTime?: boolean;
  dateFormat?: string;
};

const StaticDateTimePicker = ({
  handleSave,
  onClose,
  hideTime = false,
  dateFormat = "YYYY-MM-DD HH:mm:ss",
}: StaticDateTimePickerT) => {
  const [dateTimeValue, setDateTimeValue] = useState<string>(dayjs().toString());
  const datePickerRef = useRef(dateTimeValue);

  const handleDateChange = (v: unknown): void => {
    const newDate = v as Dayjs;
    newDate
      .set("hour", dayjs(datePickerRef.current).get("hour"))
      .set("minute", dayjs(datePickerRef.current).get("minute"));
    setDateTimeValue(newDate.toString());
  };

  const handleTimeChange = (hrs: number, mins: number) => {
    const newTimeStamp = dayjs(dateTimeValue).set("hour", hrs).set("minute", mins).toString();
    datePickerRef.current = newTimeStamp;
  };

  const handleDateTimeSelect = () => {
    const newDate = dayjs(dateTimeValue);
    newDate
      .set("hour", dayjs(datePickerRef.current).get("hour"))
      .set("minute", dayjs(datePickerRef.current).get("minute"))
      .set("second", 0);
    handleSave(newDate.format(dateFormat));
    onClose();
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <S.StyledDatePicker
          value={dateTimeValue}
          onChange={handleDateChange}
          disableHighlightToday
          renderInput={() => <></>}
          components={{
            RightArrowIcon: RightCaretFilled,
            LeftArrowIcon: LeftCaretFilled,
            SwitchViewIcon: DownCaretFilled,
          }}
          componentsProps={{
            actionBar: { actions: [] },
          }}
          showToolbar
          ToolbarComponent={() => (
            <DateTimeToolbar selectedDateTime={dateTimeValue} updateTime={handleTimeChange} hideTime={hideTime} />
          )}
        />
      </LocalizationProvider>
      <DialogActions
        sx={{
          justifyContent: "center",
          padding: "14px 22px",
          background: cvar("dark-blue-a07"),
        }}
      >
        <GradientGhostButton onClick={onClose} style={{ marginRight: "7px" }}>
          <CloseIcon startFill={cvar("picton-blue")} stopFill={cvar("blue-ribbon")} />
        </GradientGhostButton>
        <PrimaryButton
          onClick={handleDateTimeSelect}
          gradient
          style={{ minWidth: "164px", height: "32px", padding: "8px 24px" }}
        >
          Save
        </PrimaryButton>
      </DialogActions>
    </>
  );
};

export default StaticDateTimePicker;
