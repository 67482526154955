import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = [];

export default {
  careerListings: createReducer(initialState, {
    [types.SET_CAREER_LISTINGS](state, action) {
      return action.payload;
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
