import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import types from "rdx/modules/ambassador/types";
import useLoading from "hooks/useLoading";
import Router from "models/Router";
import MessageEvent from "models/MessageEvent";
import AmbassadorPageContainer from "../components/AmbassadorPageContainer";
import CreateAccountForm from "./createAccountForm";

const AmbassadorCreateAccount = (props) => {
  const {
    regInfo,
    applicationAgreement,
    latestMessage,
    getAmbassadorRegistrationInfo,
    getAmbassadorApplicationAgreement,
    createAmbassadorUser,
    match: { params },
    history: { push },
  } = props;
  const { code } = params;
  const loading = useLoading({
    watchRequests: [
      types.GET_AMBASSADOR_NEW_REGISTRATION_INFO_REQUEST,
      types.GET_AMBASSADOR_APPLICATION_AGREEMENT_REQUEST,
      types.CREATE_AMBASSADOR_NEW_USER_REQUEST,
    ],
  });

  return (
    <AmbassadorPageContainer
      tabs={[
        {
          key: "create-account",
          tab: (
            <CreateAccountForm
              key="create-account"
              regInfo={regInfo}
              applicationAgreement={applicationAgreement}
              getRegInfo={getAmbassadorRegistrationInfo}
              getAgreement={getAmbassadorApplicationAgreement}
              createUser={createAmbassadorUser}
              code={code}
              loading={!!loading}
              latestMessage={latestMessage}
              push={push}
            />
          ),
          forceRender: true,
        },
      ]}
      activeTabKey="create-account"
    />
  );
};

AmbassadorCreateAccount.propTypes = {
  regInfo: PropTypes.shape({
    confirmed: PropTypes.bool,
    createdAt: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.number,
    inviteAt: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
  }),
  applicationAgreement: PropTypes.shape({
    documentPath: PropTypes.string,
    message: PropTypes.string,
    version: PropTypes.string,
  }),
  latestMessage: MessageEvent.types(),
  getAmbassadorRegistrationInfo: PropTypes.func,
  getAmbassadorApplicationAgreement: PropTypes.func,
  createAmbassadorUser: PropTypes.func,
  match: Router.matchTypes(),
  history: Router.historyTypes(),
};

export default connect(
  getRdxSelectionMapper({
    regInfo: "getAmbassadorRegistrationInfo",
    applicationAgreement: "getAmbassadorApplicationAgreement",
    requests: "getActiveRequests",
    latestMessage: "getLatestMessageEvt",
  }),
  getRdxActionMapper(["getAmbassadorRegistrationInfo", "getAmbassadorApplicationAgreement", "createAmbassadorUser"]),
)(AmbassadorCreateAccount);
