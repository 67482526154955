export default {
  GET_PAYOUTS_LIST_REQUEST: "[financials] GET Payouts List Request",
  SET_PAYOUTS_LIST: "[financials] SET Payouts List",
  SET_PAYOUT_FILTERS: "[financials] SET Payout Filters",
  DELETE_PAYOUT_REQUEST: "[financials] Delete Payout Request",
  DISTRIBUTE_PAYOUTS_REQUEST: "[financials] Distribute Payouts Request",
  TOP_UP_PAYOUTS_REQUEST: "[financials] Top Up Payouts Request",
  CREATE_PAYOUT_REQUEST: "[financials] Create Payout Request",
  PAYOUT_TO_POWUR_REQUEST: "[financials] Payout To Powur Request",
  GET_PAYMENT_MILESTONES_REQUEST: "[financials] GET Payment Milestones Request",
  SET_PAYMENT_MILESTONES: "[financials] SET Payment Milestones",
  // INSERTION_PT (for script, do not remove)
};
