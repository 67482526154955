import createReducer from "rdx/utils/createReducer";
import types from "./types";

const initialState = {
  roofingProposals: {
    list: [],
    paging: {
      itemCount: 0,
      pageNumber: 0,
      pageSize: 50,
      hasMore: false,
    },
  },
  roofingS3Assets: [],
  roofingUsersAutocomplete: [],
  detail: {},
  roofingCsv: {},
  selectedRoofingAction: {
    selectedProjectId: null,
    selectedProposalId: null,
    selectedViewType: null,
    type: null,
  },
};

export default {
  roofingProposals: createReducer(initialState, {
    [types.SET_ROOFING_PROPOSAL_DATA](state, action) {
      if (action.payload.proposal) {
        const newState = state.roofingProposals.list.map((item) => {
          if (item.id !== action.payload.proposal.id) {
            return item;
          }

          return {
            ...item,
            ...action.payload.proposal,
          };
        });

        return {
          ...state,
          roofingProposals: {
            list: newState,
            paging: state.roofingProposals.paging,
          },
        };
      }
      // response to getAdminRoofingProposals
      return {
        ...state,
        roofingProposals: {
          list: action.payload.roofingProposals,
          paging: action.payload.paging,
        },
      };
    },
    [types.RESET_ROOFING_PROPOSAL_DATA](state) {
      return {
        ...state,
        roofingProposals: initialState.roofingProposals,
      };
    },
    [types.SET_ROOFING_USERS_AUTOCOMPLETE](state, action) {
      return {
        ...state,
        roofingUsersAutocomplete: action.payload,
      };
    },

    [types.SET_ADMIN_ROOFING_PROPOSAL_DETAILS](state, action) {
      return {
        ...state,
        detail: action.payload,
      };
    },

    [types.SET_ROOFING_CSV](state, action) {
      return {
        ...state,
        roofingCsv: action.payload,
      };
    },
    [types.SET_ROOFING_ACTION](state, action) {
      return {
        ...state,
        selectedRoofingAction: { ...action.payload },
      };
    },
    [types.SET_ROOFING_S3_ASSETS](state, action) {
      return {
        ...state,
        roofingS3Assets: action.payload.roofingAssets,
      };
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};
