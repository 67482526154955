import { put, select, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import { keysToCamelCase } from "lib/helpers";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/workflows/selectors";
import messages from "../../messages";

function* updatePhase(action) {
  const { id, title } = action.payload;
  const { success, data, error } = yield* makeRequest.patch(`/phases/${id}`, {
    title,
  });
  if (success && data) {
    const newPhase = keysToCamelCase(data.body.phase);
    const workflow = yield select(selectors.getWorkflowDetails);
    const newPhases = workflow.phases.map((p) => (p.id === newPhase.id ? newPhase : p));

    yield all([
      put(actions.setWorkflowDetails({ ...workflow, phases: newPhases })),
      put(
        actions.newSuccessEvent({
          message: messages.PHASE_UPDATE_SUCCESS,
          id,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_PHASE,
    });
  }
  return null;
}

export default updatePhase;
