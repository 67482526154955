import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/platform/messages";
import { camelizeKeys } from "lib/helpers";

function* getPlatformServices() {
  const { success, data, error } = yield* makeRequest.get("/services/platform");

  if (success && data) {
    yield all([put(actions.setPlatformServices(camelizeKeys(data.body.services)))]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PLATFORM_SERVICES,
    });
  }

  return null;
}

export default getPlatformServices;
