const commonTabs = [
  {
    key: "sms",
    label: "SMS",
  },
  {
    key: "events-and-promos",
    label: "Events & Promos",
  },
  {
    key: "powur-updates",
    label: "Powur Updates",
  },
];

const adminTabs = [];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
