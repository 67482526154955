import { permissionTypes } from "hooks/usePermissions";
import component from "containers/Ambassador/dashboard";
// import l from "@loadable/component";

// const component = l(() => import("containers/Ambassador/dashboard"));

const { POWUR_AMBASSADOR } = permissionTypes;

export default {
  key: "ambassador-dashboard",
  title: "Ambassador Dashboard",
  path: "/ambassador/dashboard/:tab?/:modal?",
  linkTarget: "/ambassador/dashboard",
  linkTitle: "Ambassador Portal",
  checkAuth: true,
  permission: POWUR_AMBASSADOR,
  component,
};
