import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import messages from "rdx/modules/auth/messages";
import types from "rdx/modules/auth/types";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { MailOutlined } from "@ant-design/icons";
import useLoading from "hooks/useLoading";
import MessageEvent from "models/MessageEvent";
import styles from "../../Login.module.less";

const { Item } = Form;

const ForgotPassword = (props) => {
  const {
    errors,
    latestMessage,
    passwordResetRequest,
    clearResetPasswordErrors,
    setAlertMessageVisible,
    clearAlertMessage,
  } = props;
  const { t } = useTranslation(["login", "form_labels"]);
  const [form] = Form.useForm();
  const history = useHistory();
  const inputRef = useRef();

  const loading = !!useLoading({
    watchRequests: [types.PASSWORD_RESET_REQUEST],
  });

  const handleSubmitButton = (e) => {
    e.preventDefault();
    form.submit();
  };

  const handleFinish = (values) => {
    passwordResetRequest(values);
  };

  const emailValidator = () => {
    if (errors && errors.message) {
      let msg;
      if (errors.message === "not found") {
        msg = t("no_account_found");
      }
      return Promise.reject(new Error(msg || _.startCase(errors.message)));
    }
    return Promise.resolve();
  };

  const handleValuesChange = () => {
    if (errors && errors.message) {
      clearResetPasswordErrors();
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (loading) {
      setAlertMessageVisible({
        message: `${t("sending_password_reset_request")}. . .`,
        severity: "info",
        duration: 6000,
      });
    }
  }, [loading, t]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (latestMessage.payload?.event === messages.RESET_PASSWORD_SUCCESS_EVENT) {
      setAlertMessageVisible({
        message: latestMessage.message,
        severity: "success",
      });
      setTimeout(() => {
        clearAlertMessage();
        history.push("/login");
      }, 2000);
    }
    if (latestMessage.error?.event === messages.RESET_PASSWORD_ERROR_EVENT) {
      clearAlertMessage();
      form.validateFields();
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form
      form={form}
      className={styles.form}
      layout="vertical"
      requiredMark={false}
      onFinish={handleFinish}
      onValuesChange={handleValuesChange}
      colon={false}
    >
      <div className={styles.instructions}>{t("reset_password_explain")}</div>
      <Item
        className={styles.formItem}
        style={{ marginBottom: "36px" }}
        name="email"
        label={<span className={styles.formLabel}>Email</span>}
        autoComplete="username email"
        rules={[
          {
            required: true,
            message: t("receive_reset_password_link"),
          },
          {
            type: "email",
            validateTrigger: ["onSubmit"],
            message: t("form_labels:valid_email"),
          },
          {
            validator: emailValidator,
          },
        ]}
      >
        <Input
          className={styles.input}
          addonBefore={<MailOutlined className={styles.icon} />}
          ref={(node) => {
            inputRef.current = node;
          }}
        />
      </Item>
      <div className={styles.loginRow}>
        <Button className={styles.submitButton} type="primary" htmlType="submit" onClick={handleSubmitButton}>
          {t("form_labels:reset_password")}
        </Button>
      </div>
      <div className={styles.returnRow}>
        <Button className={styles.linkButton} type="info" htmlType="button" onClick={() => history.push("/login")}>
          {t("form_labels:return_to_login")}
        </Button>
      </div>
    </Form>
  );
};

ForgotPassword.propTypes = {
  errors: PropTypes.shape({
    message: PropTypes.string,
  }),
  latestMessage: MessageEvent.types(),
  passwordResetRequest: PropTypes.func,
  clearResetPasswordErrors: PropTypes.func,
  setAlertMessageVisible: PropTypes.func,
  clearAlertMessage: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    errors: "getResetPasswordErrors",
    latestMessage: "getLatestMessageEvt",
    activeRequests: "getActiveRequests",
  }),
  getRdxActionMapper([
    "passwordResetRequest",
    "clearResetPasswordErrors",
    "setAlertMessageVisible",
    "clearAlertMessage",
  ]),
)(ForgotPassword);
