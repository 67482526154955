import { all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import messages from "rdx/modules/consultantDashboard/messages";

function* getHelpjuiceToken(action) {
  const { openInNewTab } = action.payload;
  const { success, data, error } = yield* makeRequest.get("/helpjuice");

  if (success && data) {
    yield all([]);
    if (openInNewTab) {
      window.open(`https://helpjuice.com/sso/powur?SAMLResponse=${data.body.saml}`, "_blank");
    } else {
      window.location.replace(`https://helpjuice.com/sso/powur?SAMLResponse=${data.body.saml}`);
    }
  } else if (error) {
    const errorMessage = messages.ERROR_GETTING_HELPJUICE_TOKEN;
    return getErrorActions({
      error,
      message: errorMessage,
    });
  }

  return null;
}

export default getHelpjuiceToken;
