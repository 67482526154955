// import content from "containers/Account/account";
import tabs from "containers/Account/Billing/tabs";
import l from "@loadable/component";

const content = l(() => import("containers/Account/Billing"));

export default {
  key: "account",
  title: "Account",
  path: "/account/:tab?/:modal?",
  linkTarget: "/account",
  linkTitle: "Account",
  linkCategory: "Personal",
  icon: () => null,
  basePath: "account",
  requiredParams: [],
  optionalParams: ["tab", "modal"],
  tabs,
  content,
  sidebar: null,
};
