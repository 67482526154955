import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import { GetCrmTaskListPayloadT } from "types/Project/crmActivity";
import messages from "../messages";

type GetCrmTaskListPropsT = {
  payload: GetCrmTaskListPayloadT;
};
function* getCrmTaskList(action: GetCrmTaskListPropsT) {
  const { success, data, error } = yield* makeRequest.get("/sales_enablement_activities/tasks_list", {
    ...action.payload.filters,
    ...(action.payload.projectType && { project_type: action.payload.projectType }),
  });

  if (success && data) {
    yield put(actions.setCrmTaskList(camelizeKeys(data.body)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_USERS_CRM_ACTIVITIES,
    });
  }
  return null;
}

export default getCrmTaskList;
