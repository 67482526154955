import types from "rdx/modules/adminNotices/types";
import createAction from "rdx/utils/createAction";

export default {
  // exampleAction: payload => createAction(types.EXAMPLE_ACTION_TYPE, payload),
  getAdminNoticesList: (payload) => createAction(types.GET_ADMIN_NOTICES_LIST_REQUEST, payload),
  getAdminNoticePreview: (payload) => createAction(types.GET_ADMIN_NOTICE_PREVIEW, payload),
  deleteAdminNoticeRequest: (payload) => createAction(types.DELETE_ADMIN_NOTICE_REQUEST, payload),
  deleteAdminNotice: (payload) => createAction(types.DELETE_ADMIN_NOTICE, payload),
  setAdminNoticePreview: (payload) => createAction(types.SET_ADMIN_NOTICE_PREVIEW, payload),
  createNewAdminNotice: (payload) => createAction(types.CREATE_NEW_ADMIN_NOTICE_REQUEST, payload),
  setAdminNoticesList: (payload) => createAction(types.SET_ADMIN_NOTICES_LIST, payload),
  setAdminNoticesListFilters: (payload) => createAction(types.SET_ADMIN_NOTICES_LIST_FILTERS, payload),
  getAdminNoticeDetails: (payload) => createAction(types.GET_ADMIN_NOTICE_DETAILS_REQUEST, payload),
  setAdminNoticeDetails: (payload) => createAction(types.SET_ADMIN_NOTICE_DETAILS, payload),
  updateAdminNoticeDetail: (payload) => createAction(types.UPDATE_ADMIN_NOTICE_DETAIL, payload),
  setAdminNoticesUIState: (payload) => createAction(types.SET_ADMIN_NOTICES_UI_STATE, payload),
  // INSERTION_PT (for script, do not remove)
};
