const statesOperatedOptions = [
  { value: "AK,USA", label: "Alaska, USA" },
  { value: "AL,USA", label: "Alabama, USA" },
  { value: "AR,USA", label: "Arkansas, USA" },
  { value: "AS,USA", label: "American Samoa, USA" },
  { value: "AZ,USA", label: "Arizona, USA" },
  { value: "CA,USA", label: "California, USA" },
  { value: "CO,USA", label: "Colorado, USA" },
  { value: "CT,USA", label: "Connecticut, USA" },
  { value: "DC,USA", label: "Washington D.C., USA" },
  { value: "DE,USA", label: "Delaware, USA" },
  { value: "FL,USA", label: "Florida, USA" },
  { value: "GA,USA", label: "Georgia, USA" },
  { value: "GU,USA", label: "Guam, USA" },
  { value: "HI,USA", label: "Hawaii, USA" },
  { value: "IA,USA", label: "Iowa, USA" },
  { value: "ID,USA", label: "Idaho, USA" },
  { value: "IL,USA", label: "Illinois, USA" },
  { value: "IN,USA", label: "Indiana, USA" },
  { value: "KS,USA", label: "Kansas, USA" },
  { value: "KY,USA", label: "Kentucky, USA" },
  { value: "LA,USA", label: "Louisiana, USA" },
  { value: "MA,USA", label: "Massachusetts, USA" },
  { value: "MD,USA", label: "Maryland, USA" },
  { value: "ME,USA", label: "Maine, USA" },
  { value: "MI,USA", label: "Michigan, USA" },
  { value: "MN,USA", label: "Minnesota, USA" },
  { value: "MO,USA", label: "Missouri, USA" },
  { value: "MS,USA", label: "Mississippi, USA" },
  { value: "MT,USA", label: "Montana, USA" },
  { value: "NC,USA", label: "North Carolina, USA" },
  { value: "ND,USA", label: "North Dakota, USA" },
  { value: "NE,USA", label: "Nebraska, USA" },
  { value: "NH,USA", label: "New Hampshire, USA" },
  { value: "NJ,USA", label: "New Jersey, USA" },
  { value: "NM,USA", label: "New Mexico, USA" },
  { value: "NV,USA", label: "Nevada, USA" },
  { value: "NY,USA", label: "New York, USA" },
  { value: "OH,USA", label: "Ohio, USA" },
  { value: "OK,USA", label: "Oklahoma, USA" },
  { value: "OR,USA", label: "Oregon, USA" },
  { value: "PA,USA", label: "Pennsylvania, USA" },
  { value: "PR,USA", label: "Puerto Rico, USA" },
  { value: "RI,USA", label: "Rhode Island, USA" },
  { value: "SC,USA", label: "South Carolina, USA" },
  { value: "SD,USA", label: "South Dakota, USA" },
  { value: "TN,USA", label: "Tennessee, USA" },
  { value: "TX,USA", label: "Texas, USA" },
  { value: "UT,USA", label: "Utah, USA" },
  { value: "VA,USA", label: "Virginia, USA" },
  { value: "VI,USA", label: "Virgin Islands, USA" },
  { value: "VT,USA", label: "Vermont, USA" },
  { value: "WA,USA", label: "Washington, USA" },
  { value: "WI,USA", label: "Wisconsin, USA" },
  { value: "WV,USA", label: "West Virginia, USA" },
  { value: "WY,USA", label: "Wyoming, USA" },
];

const leadSourceOptions = [
  { label: "Ambassador", value: "Ambassador", key: "1" },
  {
    label: "Friend Referral",
    value: "Friend Referral",
    key: "2",
  },
  {
    label: "Online Marketing",
    value: "Online Marketing",
    key: "3",
  },
  {
    label: "Word of Mouth",
    value: "Word of Mouth",
    key: "4",
  },
  {
    label: "Door Knocking",
    value: "Door Knocking",
    key: "5",
  },
  { label: "Facebook", value: "Facebook", key: "6" },
  { label: "YouTube", value: "YouTube", key: "7" },
  { label: "Other", value: "Other", key: "8" },
];

export { statesOperatedOptions, leadSourceOptions };
export default [...statesOperatedOptions, ...leadSourceOptions];
