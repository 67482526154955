import React from "react";

const OwnerIcon = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="71" cy="71" r="71" fill="var(--lightest-grey)" />
      <mask
        id="mask0_4228_3042"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="142"
      >
        <circle cx="71" cy="71" r="71" fill="var(--lightest-grey)" />
      </mask>
      <g mask="url(#mask0_4228_3042)">
        <path
          d="M98.8306 104.398L71 134.307L43.1694 104.398"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M46.6437 59.2848C45.2144 52.7873 42.7207 46.1444 43.3111 39.5922C44.1636 31.703 54.7577 24.995 61.6913 25C67.8971 25 74.103 25 80.3088 25C87.2429 24.9958 97.189 31.7027 98.0419 39.5922C98.6323 46.145 95.3556 59.2816 95.3556 59.2816C97.7567 57.6396 103.907 60.0123 100.693 67.2261C98.9561 71.1258 96.3697 72.68 94.7803 73.5559C90.6154 85.3766 80.7768 91.1962 71 91.1962C61.2232 91.1962 51.3846 85.3766 47.2198 73.5559C45.6304 72.68 43.0439 71.1258 41.3068 67.2261C38.47 60.8577 42.9305 58.2622 45.6669 58.8711C46.0302 58.9519 46.6437 59.2848 46.6437 59.2848Z"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M55.3478 85.543V85.543C55.3478 94.5233 49.4908 102.453 40.9077 105.094L16.2174 111.086L11 144.999H131L125.783 111.086L101.092 105.094C92.5092 102.453 86.6522 94.5233 86.6522 85.543V85.543"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M76.2173 111.088H65.7825L63.1738 103.262H78.826L76.2173 111.088Z"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M78.826 125.899L76.2173 111.086H65.7825L63.1738 125.899"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path d="M60.5652 77.1758H81.4348" stroke="var(--royal-peacock-blue)" strokeWidth="3" strokeMiterlimit="10" />
      </g>
    </svg>
  );
};

export default OwnerIcon;
