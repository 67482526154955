import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getSmsList(action) {
  const params = {
    ...action.payload,
  };
  const { success, data, error } = yield* makeRequest.get("/sms", params);
  if (success && data) {
    // populate sms list (for case when req. did not provide a filters param)
    if (data.body.sms) {
      const list = data.body.sms.map((entry) => keysToCamelCase(entry));
      yield all([
        put(actions.setSmsList({ list, paging: data.body.paging })),
        put(actions.setSmsLanguages(data.body.language_options)),
        put(actions.setSmsRecipients(data.body.recipient_options)),
      ]);
    }

    // populate sms filters (for case when req. gave param filters=1)
    // if (data.body.filters) {
    //   yield put(actions.setSmsListFilters(data.body));
    // }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_SMS_LIST,
    });
  }
  return null;
}

export default getSmsList;
