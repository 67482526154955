import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* readdUserToLms(action) {
  const { userId: user_id, serviceSlug } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/services/${serviceSlug}/enrollment/lmsonly`, { user_id });
  if (success && data) {
    yield put(
      actions.newSuccessEvent({
        message: messages.READD_USER_TO_LMS_SUCCESS,
      }),
    );
    yield put(
      actions.setDetailInUserDetails({
        enrollment: keysToCamelCase(data.body.enrollment),
      }),
    );
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_READDING_USER_TO_LMS,
    });
  }

  return null;
}

export default readdUserToLms;
