import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/projects/messages";

function* requestProposalUnlock(action) {
  const { id } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/provider_leads/${id}/request_unlock`, {});
  if (success && data) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.UNLOCK_REQUEST_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      // message: "",
      // payload: {},
    });
  }

  return null;
}

export default requestProposalUnlock;
