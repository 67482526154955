import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToCamelCase } from "lib/helpers";
import actions from "rdx/actions";
import messages from "../messages";
import { removeBlockerAsset } from "../../projectBlockers/slice";

function* deleteAsset(action) {
  const { leadId, assetId } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/provider_leads/${leadId}/assets/${assetId}`, {});
  if (success && data) {
    yield put(actions.setAssets(data.body.assets.map(keysToCamelCase)));
    yield put(removeBlockerAsset(assetId));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_ASSET,
    });
  }
  return null;
}

export default deleteAsset;
