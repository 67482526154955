import makeRequest from "rdx/utils/makeRequest";
import { put, all } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import messages from "rdx/modules/users/messages";
import actions from "rdx/actions";

function* updateDefaultAdvance(action) {
  const { defaultAdvance, userId } = action.payload;
  const { success, error } = yield* makeRequest.post(`/users/${userId}/set_advance_default`, {
    default_advance: defaultAdvance,
  });
  if (success) {
    yield all([put(actions.newSuccessEvent({ message: messages.UPDATE_DEFAULT_ADVANCE_SUCCESS }))]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_DEFAULT_ADVANCE,
    });
  }
  return null;
}

export default updateDefaultAdvance;
