import { styled } from "@mui/material/styles";
import { theme } from "styles/themes";

export const Header = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
}));

export const HomeownerNameColumn = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginLeft: "12px",
}));

export const HomeownerName = styled("div")(() => ({
  fontSize: "24px",
  fontWeight: 700,
  color: theme.colors["darkest-blue"],
  fontFamily: "Poppins",
  lineHeight: "16px",
  paddingTop: "5px",
}));

export const HomeownerLocation = styled("div")(() => ({
  fontSize: "14px",
  lineHeight: "19px",
  fontWeight: 500,
  color: theme.colors["dark-blue"],
  fontFamily: "Barlow",
  paddingTop: "4px",
}));

export const FormLabel = {
  fontFamily: "Barlow",
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "11px",
  marginBottom: "8px",
  color: theme.colors["nepal-blue"],
};

export const StyledFormLabel = styled("div")(() => ({
  ...FormLabel,
  textTransform: "uppercase",
}));

export const AvailabilityLabel = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "17px",
  color: theme.colors["darkest-blue"],
}));

export const CheckboxWrapper = styled("div")(() => ({
  marginLeft: "19px",
}));

export const MenuDivider = styled("div")(() => ({
  width: "calc(100% - 21px)",
  height: "2px",
  margin: "4px 13px 4px 8px",
  background: theme.colors["botticelli-blue-50"],
}));

export const EmailTooltipText = styled("span")(() => ({
  fontFamily: "Barlow",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "17px",
  color: theme.colors["queen-blue"],
}));

export const EmailTooltipHyperlink = styled("span")(() => ({
  fontFamily: "Barlow",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "17px",
  color: theme.colors["banner-blue"],
  textDecoration: "underline",
}));

export const GmailToggleWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "24px",
}));
