import { put, all } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/preferredPartners/messages";
import { keysToSnakeCase, keysToCamelCase } from "lib/helpers";

function* updatePreferredPartner(action) {
  const params = keysToSnakeCase(action.payload);
  const { success, data, error } = yield* makeRequest.patch(`/enterprise_labor_partners/${params.id}`, params);

  if (success && data) {
    const out = keysToCamelCase(_.clone(data.body));

    yield all([
      put(actions.setPreferredPartners({ partner: out })),
      put(
        actions.newSuccessEvent({
          message: messages.ASSIGN_PREFERRED_PARTNER_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_ASSIGNING_PREFERRED_PARTNERS,
    });
  }

  return null;
}

export default updatePreferredPartner;
