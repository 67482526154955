import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/auth/messages";

function* passwordResetRequest(action) {
  const { email } = action.payload;
  const { success, data, error } = yield* makeRequest.post("/password", {
    email,
  });

  if (success && data) {
    yield put(
      actions.newSuccessEvent({
        message: messages.RESET_PASSWORD_SUCCESS,
        payload: {
          event: messages.RESET_PASSWORD_SUCCESS_EVENT,
        },
      }),
    );
  } else if (error) {
    yield put(actions.setResetPasswordErrors({ message: error.message }));
    return getErrorActions({
      error: {
        ...error,
        event: messages.RESET_PASSWORD_ERROR_EVENT,
      },
    });
  }

  return null;
}

export default passwordResetRequest;
