import types from "rdx/modules/roofing/types";
import createAction from "rdx/utils/createAction";

export default {
  setRoofingProposalData: (payload) => createAction(types.SET_ROOFING_PROPOSAL_DATA, payload),
  getAdminRoofingProposals: (payload) => createAction(types.GET_ADMIN_ROOFING_PROPOSALS, payload),
  resetAdminRoofingProposals: (payload) => createAction(types.RESET_ROOFING_PROPOSAL_DATA, payload),

  getRoofingUsersAutocomplete: (payload) => createAction(types.GET_ROOFING_USERS_AUTOCOMPLETE, payload),

  setRoofingUsersAutocomplete: (payload) => createAction(types.SET_ROOFING_USERS_AUTOCOMPLETE, payload),

  setAdminRoofingProposalDetails: (payload) => createAction(types.SET_ADMIN_ROOFING_PROPOSAL_DETAILS, payload),

  setRoofingActionDetails: (payload) => createAction(types.SET_ROOFING_ACTION, payload),

  createRoofingProposal: (payload) => createAction(types.POST_ROOFING_PROPOSALS, payload),
  updateRoofingProposal: (payload) => createAction(types.UPDATE_ROOFING_PROPOSAL, payload),
  getAdminRoofingProposal: (payload) => createAction(types.GET_ROOFING_PROPOSAL, payload),
  getRoofingCsv: (payload) => createAction(types.GET_ROOFING_CSV, payload),
  setRoofingCsv: (payload) => createAction(types.SET_ROOFING_CSV, payload),
  getRoofingCsvReport: (payload) => createAction(types.GET_ROOFING_CSV_REPORT, payload),
  postRoofingS3Asset: (payload) => createAction(types.POST_ROOFING_S3_ASSET, payload),
  deleteRoofingS3Asset: (payload) => createAction(types.DELETE_ROOFING_S3_ASSET, payload),
  getRoofingS3Assets: (payload) => createAction(types.GET_ROOFING_S3_ASSETS, payload),
  setRoofingS3Assets: (payload) => createAction(types.SET_ROOFING_S3_ASSETS, payload),

  // INSERTION_PT (for script, do not remove)
};
