import { put, all } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/users/messages";
import { keysToCamelCase, destructureObjArray } from "lib/helpers";

function* changeUserManager(action) {
  const { userId, managerId } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/users/${userId}/manager`, {
    manager_id: managerId,
  });
  if (success && data) {
    const user = keysToCamelCase(_.clone(data.body));
    user.metrics = destructureObjArray(data.body.metrics);
    user.upline = user.upline.map((u) => keysToCamelCase(u));
    user.managerOptions = user.managerOptions.map((u) => keysToCamelCase(u));
    yield all([
      put(actions.setUserDetails(user)),
      put(
        actions.newSuccessEvent({
          message: messages.CHANGE_USER_MANAGER_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CHANGING_USER_MANAGER,
    });
  }
  return null;
}

export default changeUserManager;
