import React from "react";

import * as S from "./styles";

const NevadaProjectNotes = () => {
  return (
    <S.NevadaNoteContainer>
      <S.NoteHeader>Important Note for Nevada Projects</S.NoteHeader>
      <S.NoteDetails>
        Because your project is in Nevada, all proposals must be submitted and closed with the assistance of a Regional
        Sales Manager (RSM) to meet Nevada&apos;s SB293 requirements for solar sales which took effect on January 1,
        2024. Click here to learn more about selling solar in Nevada.
      </S.NoteDetails>
    </S.NevadaNoteContainer>
  );
};

export default NevadaProjectNotes;
