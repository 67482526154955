import React from "react";
import { IconT } from "types/utils";

const LeftCaretFilled = ({ width = 8, height = 9, color = "var(--prussian-blue)" }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.294399 6.78031C-0.0981323 6.50453 -0.0981327 5.93477 0.294398 5.659L6.87713 1.03427C7.34585 0.704962 8 1.03158 8 1.59492L8 10.8444C8 11.4077 7.34585 11.7343 6.87713 11.405L0.294399 6.78031Z"
        fill={color}
      />
    </svg>
  );
};

export default LeftCaretFilled;
