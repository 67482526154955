import React from "react";
import PropTypes from "prop-types";
import styles from "./Drawer.module.less";

const DetailsHeader = (props) => {
  const { titleText, detailText } = props.content;
  return (
    <div className={styles.detailsHeader}>
      <div className={styles.detailTopRow}>
        <span className={styles.detailTitle}>{titleText}</span>
      </div>
      <div className={styles.detailBottomRow}>
        <span className={styles.detail}>{detailText}</span>
      </div>
    </div>
  );
};

DetailsHeader.propTypes = {
  content: PropTypes.shape({
    titleText: PropTypes.string,
    detailText: PropTypes.string,
  }).isRequired,
};

export default DetailsHeader;
