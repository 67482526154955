import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../../messages";

function* updateRole(action) {
  const { name, description, permissions, parent, id } = action.payload;
  const { success, data, error } = yield* makeRequest.patch(`/roles/${id}`, {
    name,
    description,
    parent_id: parent,
    permissions,
  });

  if (success && data) {
    const newRoles = data.body.roles.map((r) => keysToCamelCase(r));
    const newPermissions = data.body.permissions.map((p) => keysToCamelCase(p));
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.ADD_NEW_ROLE_SUCCESS,
        }),
      ),
      put(actions.setRolesList({ roles: newRoles, permissions: newPermissions })),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_ADDING_NEW_ROLE,
    });
  }

  return null;
}

export default updateRole;
