import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { actions } from "rdx";
import CopyIcon from "components/Icons/copyIcon";
import copy from "copy-to-clipboard";

type TCopyToClipBoard = {
  valueToCopy: string | null;
  children?: ReactNode;
};

const CopyToClipBoard = ({ valueToCopy, children }: TCopyToClipBoard) => {
  const dispatch = useDispatch();

  const handleCopyAction = (value: string) => {
    copy(value);
    dispatch(
      actions.setAlertMessageVisible({
        message: "Copied to the clipboard!",
        severity: "success",
      }),
    );
  };

  return (
    <>
      {valueToCopy && (
        <span
          role="none"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleCopyAction(valueToCopy);
          }}
        >
          {children}
          <CopyIcon mainColor="var(--nepal-blue)" />
        </span>
      )}
    </>
  );
};

export default CopyToClipBoard;
