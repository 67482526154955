import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";
import types from "rdx/modules/publicApiProxy/types";

import createAppClientToken from "./createAppClientToken";
import getGoogleAccessStatus from "./getGoogleAccessStatus";
import getGoogleAuthorizeUrl from "./getGoogleAuthorizeUrl";
import exchangeGoogleCallback from "./exchangeGoogleCallback";
import getGoogleUser from "./getGoogleUser";
import setUserEmailIntegrationType from "./setUserEmailIntegrationType";
// IMPORT_PT (for script, do not remove)

function* watchPublicApiProxySagas() {
  yield trackRequests(takeEvery, types.CREATE_APPCLIENT_TOKEN_REQUEST, createAppClientToken);
  yield trackRequests(takeEvery, types.GET_GOOGLE_ACCESS_STATUS_REQUEST, getGoogleAccessStatus);
  yield trackRequests(takeEvery, types.GET_GOOGLE_AUTHORIZE_URL_REQUEST, getGoogleAuthorizeUrl);
  yield trackRequests(takeEvery, types.EXCHANGE_GOOGLE_CALLBACK_REQUEST, exchangeGoogleCallback);
  yield trackRequests(takeEvery, types.GET_GOOGLE_USER_REQUEST, getGoogleUser);
  yield trackRequests(takeEvery, types.SET_USER_EMAIL_INTEGRATION_TYPE_REQUEST, setUserEmailIntegrationType);
  // INSERTION_PT (for script, do not remove)
}

export default watchPublicApiProxySagas;
