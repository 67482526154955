import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  registration: {},
  seatedSellerRegistrationInfo: {
    enterpriseName: "",
    positions: [],
    firstName: "",
    lastName: "",
  },
};

export default {
  registration: createReducer(initialState, {
    [types.SET_REGISTRATION_INFO](state, action) {
      return {
        ...state,
        registration: action.payload,
      };
    },
    [types.SET_SEATED_SELLER_REGISTRATION_INFO](state, action) {
      return {
        ...state,
        seatedSellerRegistrationInfo: action.payload,
      };
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};
