import component from "containers/StaticPages/team";

// import l from "@loadable/component";

// const component = l(() => import("containers/StaticPages/team"));

export default {
  key: "powur-our-team",
  title: "Powur Team",
  path: "/:user?/about",
  pageName: "team",
  linkTarget: "/about",
  component,
};
