import AutoCompleteInput from "./autoCompleteInput";
import MultiSelectList from "./MultiSelectList";
import InputLabeled from "./InputLabeled";
import TextAreaInput from "./TextAreaInput";

export default {
  AutoCompleteInput,
  MultiSelectList,
  InputLabeled,
  TextAreaInput,
};
