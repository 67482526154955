import types from "rdx/modules/adminReports/types";
import createAction from "rdx/utils/createAction";

export default {
  getMessagingMetrics: (payload) => createAction(types.GET_MESSAGING_METRICS, payload),
  getTicketList: (payload) => createAction(types.GET_TICKET_LIST, payload),
  setTicketList: (payload) => createAction(types.SET_TICKET_LIST, payload),
  setMessagingMetricsList: (payload) => createAction(types.SET_MESSAGING_METRICS, payload),
  setMessagingMetricsRoles: (payload) => createAction(types.SET_MESSAGING_METRICS_ROLES, payload),
  setMetricsFilters: (payload) => createAction(types.SET_MESSAGING_METRICS_FILTERS, payload),
  getSellerReport: (payload) => createAction(types.GET_CONSULTANT_REPORT, payload),
  setSellerReport: (payload) => createAction(types.SET_CONSULTANT_REPORT, payload),
  setSellerReportUserLookups: (payload) => createAction(types.SET_CONSULTANT_REPORT_USER_LOOKUPS, payload),
  resetSellerReportUserLookups: (payload) => createAction(types.RESET_CONSULTANT_REPORT_USER_LOOKUPS, payload),
  getActivityLog: (payload) => createAction(types.GET_ACTIVITY_LOG, payload),
  setActivityLog: (payload) => createAction(types.SET_ACTIVITY_LOG, payload),
  getUsersProjectsAutoComplete: (payload) => createAction(types.GET_USERS_PROJECTS_AUTOCOMPLETE, payload),
  setUsersProjectsAutoComplete: (payload) => createAction(types.SET_USERS_PROJECTS_AUTOCOMPLETE, payload),
  getActivityLogCsv: (payload) => createAction(types.GET_ACTIVITY_LOG_CSV, payload),
  setActivityLogCsv: (payload) => createAction(types.SET_ACTIVITY_LOG_CSV, payload),
  getPreferredPartnerCsv: (payload) => createAction(types.GET_PREFERRED_PARTNER_CSV, payload),
  getPreferredPartnerCsvReport: (payload) => createAction(types.GET_PREFERRED_PARTNER_CSV_REPORT, payload),
  setPreferredPartnerCsv: (payload) => createAction(types.SET_PREFERRED_PARTNER_CSV, payload),
  // INSERTION_PT (for script, do not remove)
};
