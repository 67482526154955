import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* postNewAsset(action) {
  const { leadId, body, fileUrl, ownerId } = action.payload;

  const id = leadId || ownerId;

  const payload = {
    body,
    file_url: fileUrl,
  };

  const { success, data, error } = yield* makeRequest.post(`/provider_leads/${id}/assets`, payload);

  if (success && data) {
    const assets = keysToCamelCase(data.body.assets);
    yield put(actions.setAssets(assets));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_POSTING_NEW_ASSET,
    });
  }
  return null;
}

export default postNewAsset;
