import React from "react";
import PropTypes from "prop-types";

const ToolsIcon = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5894 18.1806C16.3164 19.3259 14.9297 18.7864 14.376 18.0481L9.84687 12.899L11.2619 11.1953L10.4337 10.3387L11.4844 9.25017L17.4711 14.8583C18.4697 15.7764 18.4366 17.4187 17.5894 18.1806ZM4.78297 18.0386C3.58561 19.3306 2.18476 18.7959 1.57898 18.1711C1.14358 17.7215 0.305909 16.3065 1.70676 14.8631L8.30403 9.01826L10.4432 11.2284L4.78297 18.0386ZM4.68358 7.0211C4.68358 7.0211 3.0461 7.51329 1.79668 6.22129C1.22404 5.62971 0.916417 4.83463 0.921149 4.01589L2.5539 5.70543L4.76877 4.94348L5.50706 2.65762L3.8743 0.972812C4.65992 0.958614 5.43607 1.28043 6.01345 1.87674C7.28652 3.18768 6.7896 4.8583 6.7896 4.8583L10.164 8.01968L9.17484 9.04193L8.34663 8.18533L6.86059 9.50099L4.68358 7.0211ZM16.217 2.6245L16.6382 3.0599L10.0125 9.90326L9.59131 9.46786L16.217 2.6245ZM16.8559 1.55492L18.0485 1.02487L18.1857 1.16211L17.6746 2.3926L17.1209 2.68129L16.5814 2.12284L16.8559 1.55492ZM17.8639 14.3993L11.9056 8.8195L17.2392 3.31546L18.1384 2.85166L18.8909 1.0296L18.181 0.300781L16.4205 1.08166L15.9709 2.00926L10.5804 7.57481L7.43323 4.62639C7.61307 3.45744 7.24866 2.27428 6.43465 1.43188C5.57331 0.546877 4.29551 0.0641523 2.62489 0.546879L4.83029 2.82327L4.30497 4.45602L2.72428 5.00027L0.518877 2.72388C0.518877 2.72388 -0.266738 4.96241 1.37548 6.65669C2.18949 7.49909 3.33952 7.87771 4.47061 7.6884L6.40625 9.8938L1.32815 14.3898C-0.238342 15.9184 0.367433 17.7736 1.16725 18.6018C2.58703 20.0689 4.57946 19.359 5.24676 18.4362L9.45879 13.3628L13.9264 18.4504C15.4929 20.1541 17.3149 19.3306 18.0106 18.6113C19.3263 17.253 18.8909 15.3126 17.8639 14.3993Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.5"
      />
    </svg>
  );
};

ToolsIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

ToolsIcon.defaultProps = {
  fill: "white",
  height: "20",
  width: "20",
};

export default ToolsIcon;
