import React, { useRef, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Carousel, Button } from "antd";
import { useTranslation } from "react-i18next";
import { parse, stringify } from "query-string";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { CSSTransition } from "react-transition-group";
import { ZipButton, ZipInput } from "components/HomepageComponents";
import useWindowResize from "hooks/useWindowResize";
import styles from "../../Home.module.less";

const PowurCarousel = () => {
  const { t } = useTranslation("home");
  const carouselRef = useRef();
  const inputRef = useRef();
  const history = useHistory();
  const { user } = useParams();
  const userTag = user ? `/${user}` : "";
  const { width } = useWindowResize();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [zip, setZip] = useState("");
  const urlPrefix = process.env.REACT_APP_S3_URL_PREFIX;

  const TRANSITION_TIMEOUT = 1000;

  const mobile = useMemo(() => {
    return width <= 768;
  }, [width]);

  const tinyView = useMemo(() => {
    return width <= 410;
  }, [width]);

  const handleInputFocus = () => {
    if (carouselRef.current) {
      carouselRef.current.innerSlider.pause("paused");
      const dot = document.querySelector("li.slick-active");
      if (dot) {
        dot.classList.remove("slick-active");
        // eslint-disable-next-line no-void
        void dot.offsetWidth;
        dot.classList.add("slick-active");
        dot.classList.add("slick-active-paused");
      }
    }
  };

  const handleInputBlur = () => {
    if (carouselRef.current) {
      carouselRef.current.autoPlay();
      const dot = document.querySelector("li.slick-active");
      if (dot) {
        dot.classList.remove("slick-active-paused");
      }
    }
  };

  const slides = (idx) => {
    const TITLE_CONTENT = [
      {
        title: <div className={styles.heading}>{t("carousel.take_your_powur_back")}</div>,
        buttonText: t("carousel.get_started"),
        text: (
          <>
            <div className={styles.subHeading}>{t("carousel.enter_your_zip")}</div>
          </>
        ),
        zip,
        handleButton: () => {
          const search = parse(history.location.search);
          search.zip = zip || 1;
          history.push({
            pathname: history.location.pathname,
            search: `?${stringify(search)}`,
          });
        },
      },
      {
        title: <div className={styles.headingSmaller}>{t("carousel.did_you_know")}</div>,
        buttonText: t("carousel.learn_how"),
        text: <div className={styles.subHeading}>{t("carousel.find_out_why")}</div>,
        handleButton: () => {
          history.push(`${userTag}/faq`);
        },
      },
      {
        title: <div className={styles.heading}>{t("carousel.powers_your_life")}</div>,
        buttonText: t("carousel.see_the_stories"),
        text: <div className={styles.subHeading}>{t("carousel.real_customers")}</div>,
        handleButton: () => {
          history.push(`${userTag}/solar`);
        },
      },
    ];

    return (
      <CSSTransition
        in={currentSlide === idx}
        classNames={{
          enter: styles.fadeEnter,
          enterActive: styles.fadeEnterActive,
          enterDone: styles.fadeEnterDone,
          exit: styles.fadeExit,
          exitActive: styles.fadeExitActive,
          exitDone: styles.fadeExitDone,
        }}
        // ensure timeout, speed prop in carousel below, and transition timeout
        // in stylesheet all agree
        timeout={TRANSITION_TIMEOUT}
        // unmountOnExit
      >
        <div className={styles.titleContainer}>
          <div className={styles.inner}>
            {TITLE_CONTENT[currentSlide].title}
            {TITLE_CONTENT[currentSlide].text}
            {currentSlide === 0 ? (
              <div className={styles.inputContainer}>
                <ZipInput
                  zip={zip}
                  setZip={setZip}
                  className={styles.zipInput}
                  forwardRef={inputRef}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                />
                <ZipButton zip={zip} className={styles.zipButton} type="primary">
                  {TITLE_CONTENT[currentSlide].buttonText}
                </ZipButton>
              </div>
            ) : (
              <Button className={styles.zipButton} onClick={TITLE_CONTENT[currentSlide].handleButton} type="primary">
                {TITLE_CONTENT[currentSlide].buttonText}
              </Button>
            )}
          </div>
        </div>
      </CSSTransition>
    );
  };

  const videoBg = (
    <div className={styles.videoContainer}>
      <video
        className={styles.video}
        poster={`${urlPrefix}/assets/img/home/carousel-video-screenshot.jpg`}
        playsInline
        autoPlay
        muted
        loop
      >
        <source src={`${urlPrefix}/assets/videos/Powur-Homepage_video_loop-UPDATE_100821.mp4`} type="video/mp4" />
      </video>
    </div>
  );

  const imgBg = tinyView ? (
    <div
      className={styles.imgContainer}
      style={{
        backgroundImage: `url(${urlPrefix}/assets/img/home/carousel-thumbnail-1.jpg)`,
      }}
    />
  ) : (
    <div
      className={styles.imgContainer}
      style={{
        backgroundImage: `url(${urlPrefix}/assets/img/home/carousel-thumbnail-2.jpg)`,
      }}
    />
  );

  return (
    <div className={styles.carouselContainer}>
      {mobile ? imgBg : videoBg}
      <div className={styles.carousel}>
        <Carousel
          ref={(ref) => {
            carouselRef.current = ref;
          }}
          autoplay
          autoplaySpeed={7000}
          infinite
          pauseOnHover={false}
          speed={TRANSITION_TIMEOUT}
          dots={{ className: styles.dots }}
          lazyLoad="ondemand"
          effect="fade"
          beforeChange={(_, current) => setCurrentSlide(current)}
        >
          <div className={styles.panel}>
            <div className={styles.overlay} />
            {slides(0)}
          </div>
          <div className={styles.panel}>
            <div className={styles.overlay} />
            {slides(1)}
          </div>
          <div className={styles.panel}>
            <div className={styles.overlay} />
            {slides(2)}
          </div>
        </Carousel>
      </div>
      <LeftOutlined
        className={styles.leftControl}
        onClick={() => {
          carouselRef.current.prev();
        }}
      />
      <RightOutlined
        className={styles.rightControl}
        onClick={() => {
          carouselRef.current.next();
        }}
      />
    </div>
  );
};

export default PowurCarousel;
