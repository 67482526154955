import React from "react";
import { Tooltip as AntdTooltip, TooltipProps } from "antd";

type TooltipT = {
  overlayClassName?: string;
  placement?: TooltipProps["placement"];
  defaultOpen?: boolean;
  overlay: string | React.ReactNode;
  tooltipProps?: Record<string, unknown>;
  fixedWidth?: boolean;
  children: React.ReactNode | string;
};

const Tooltip = (props: TooltipT) => {
  const { overlayClassName, placement = "top", defaultOpen = false, overlay, tooltipProps, fixedWidth = false } = props;

  return (
    <AntdTooltip
      overlayClassName={[...(fixedWidth ? ["customTooltipFixedWidth"] : ["customTooltip"]), overlayClassName].join(" ")}
      title={overlay}
      placement={placement}
      defaultOpen={defaultOpen}
      {...tooltipProps}
    >
      {props.children}
    </AntdTooltip>
  );
};

export default Tooltip;
