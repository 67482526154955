import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

export default {
  viewedAssets: createReducer(null, {
    [types.SET_VIEWED_ASSETS_TASKS_TO_STATE](state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    [types.SET_VIEWED_ASSETS_TASK_TO_COMPLETE](state, action) {
      if (action.payload?.viewableAssets) {
        return { ...state, ...action.payload };
      }
      return {
        ...state,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
