const noText = {
  header: "At this moment, your home doesn't qualify to save with solar.",
  subHeader: "Here is why:",
  content: [
    {
      text: {
        unsupported: "Unfortunately, Powur does not currently support this zip code.",
        unqualified:
          "In order to qualify for solar, you need to own your home and your utility bill needs to be over $60 per month.",
      },
      style: "Bold",
    },
    {
      text: "But, since we know things change and you may qualify for solar in the future, we will be in touch with you via email with our weekly 'Solar Insights Newsletter'.",
      style: "Normal",
    },
    {
      text: "Every week, we will email you very valuable information about sustainable energy. We aim to be your most reliable source of education and resources, about Solar. So keep an eye on your inbox.🙂",
      style: "Normal",
    },
    {
      text: "Thank you for your interest in solar. We hope to serve you in the near future.",
      style: "Normal",
    },
    {
      text: "- The Powur Team",
      style: "Normal",
    },
  ],
};

export default noText;
