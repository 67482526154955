import React from "react";
import { useHistory } from "react-router-dom";

import { Button } from "antd";
import SessionClient from "util/SessionClient";

import RightArrow2 from "components/Icons/Arrows/rightArrow2";
import GeneralBanner from "./GeneralBanner";

const PastDueBanner = () => {
  const history = useHistory();
  const session = new SessionClient();
  const { user } = session;

  const handleMessage = () => {
    const ent_roles_present = user?.props.displayRoles
      .flat(1)
      .some((role) => role === "Powur Enterprise" || role === "Org Manager");
    return ent_roles_present
      ? "Update your payment method before you lose any customers or seated sellers!"
      : "Update your payment method before you lose any customers!";
  };

  return (
    <GeneralBanner
      kind="warning"
      message="Update Your Payment Method"
      details={handleMessage()}
      action={
        <>
          <Button
            size="small"
            type="text"
            onClick={() => {
              history.location.pathname = "account/payment-info";
              history.push("/account/payment-info");
            }}
          >
            Update your credit card info
          </Button>
          <RightArrow2 fill="white" />
        </>
      }
    />
  );
};

export default PastDueBanner;
