import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import { ToggleButtonGroup } from "@mui/material";
import { cvar, mq } from "styles";

export const LabelGroupWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const TimePickerWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "5px",
}));

export const StyledFormLabel = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "9px",
  marginBottom: "8px",
  color: cvar("nepal-blue"),
  textTransform: "uppercase",
}));

export const StyledTextField = styled(TextField)(() => ({
  marginBottom: "12px",
  backgroundColor: "white",
  borderRadius: "4px",
  maxWidth: "145px",
  [mq.phoneOnly]: {
    width: "100%",
  },

  "& .MuiOutlinedInput-root": {
    paddingLeft: "9px",
    "& > fieldset": { borderColor: cvar("botticelli-blue") },
    "&.Mui-focused fieldset": {
      borderColor: cvar("botticelli-blue"),
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--botticelli-blue) !important",
  },
  "& input": {
    marginLeft: "8px",
    paddingLeft: "20px",
  },
  "& .Mui-disabled": {
    background: `${cvar("cotton-boll")} !important`,
    borderRadius: "4px",
  },

  "& .MuiInputBase-input": {
    padding: "7px 10px 8px 20px",
    borderLeft: `1px solid ${cvar("botticelli-blue")}`,
    width: "80px",
    color: cvar("dark-blue"),
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Barlow",
    lineHeight: "17px",
    height: "17px",

    "&::placeholder": {
      fontFamily: "Barlow",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "17px",
      color: cvar("graham-grey"),
    },
  },

  "& .MuiInputAdornment-root": {
    marginRight: "0px",
  },
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  "& .MuiToggleButton-root": {
    height: "32px",
    [mq.phoneOnly]: {
      width: "35px",
    },
    [mq.tabletUp]: {
      width: "52px",
    },
    background: cvar("white"),
    color: cvar("nepal-blue"),
    borderRadius: "4px",
    border: `1px solid ${cvar("botticelli-blue")} !important`,
    fontFamily: "Barlow",
    fontSize: "14px",
  },
  "& .Mui-disabled": {
    background: `${cvar("cotton-boll")} !important`,
    "&.Mui-selected": {
      color: `${cvar("queen-blue")} !important`,
    },
  },
  "& .Mui-selected:not(:disabled)": {
    background: `${cvar("blue-ribbon")} !important`,
    color: `${cvar("white")} !important`,
    pointerEvents: "none",
  },
  "& .Mui-selected:hover": {
    background: `${cvar("blue-ribbon")} !important`,
  },
}));
