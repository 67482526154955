export default {
  getMessagingMetricsList: (state) => state.adminReports.messagingMetrics.teams,
  getTicketList: (state) => state.adminReports.ticketList,
  getTicketMetricsDrawerUserInfo: (state) => state.adminReports.ticketList.observingAccount,
  getMessagingMetricsRoles: (state) => state.adminReports.messagingMetrics.hierarchicalRoles,
  getSellerReport: (state) => state.adminReports.consultantReport,
  getSellerReportUserLookups: (state) => state.adminReports.consultantReport.autocompleteSuggestions,
  selectActivityLog: (state) => state.adminReports.activityLog,
  selectUsersProjectsAutoComplete: (state) => state.adminReports.activityLog.autocompleteSuggestions,
  setUsersProjectsAutoComplete: (state) => state.adminReports.activityLog,
  selectActivityLogCsv: (state) => state.adminReports.activityLogCsvData,
  getPreferredPartnerCsv: (state) => state.adminReports.preferredPartnerCsv,
};
