export default {
  SET_CRM_ACTIVITY_LEAD_ID: "[CRM Activities] SET CRM Activity Drawer Lead Id",
  CLEAR_CRM_ACTIVITY_LEAD_ID: "[CRM Activities] Clear CRM Activity Drawer Lead Id",
  SET_CRM_ACTIVITY_DRAWER_VISIBLE: "[CRM Activities] SET CRM Activity Drawer Visible",
  SET_CRM_ACTIVITY_ID: "[CRM Activities] SET CRM Activity Id",
  CREATE_CRM_ACTIVITY: "[CRM Activities] Create CRM Activity Details",
  SET_CRM_ACTIVITY_DETAILS: "[CRM Activities] SET CRM Activity Details",
  UPDATE_CRM_ACTIVITY: "[CRM Activities] Update CRM Activity",
  CLEAR_CRM_ACTIVITY_DETAILS: "[CRM Activities] Clear CRM Activity Details",
  GET_CRM_ACTIVITY: "[CRM Activities] GET CRM Activity Details",
  DELETE_CRM_ACTIVITY: "[CRM Activities] Delete CRM Activity",
  GET_CRM_TASK_LIST: "[CRM Activities] GET Users CRM Task List",
  SET_CRM_TASK_LIST: "[CRM Activities] SET Users CRM Task List",

  // INSERTION_PT (for script, do not remove)
} as const;
