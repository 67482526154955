import { put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToCamelCase } from "lib/helpers";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "../messages";

function* resendEmailUpdate(action) {
  const { userId, newEmail } = action.payload;
  const { success, data, error } = yield* makeRequest.patch("/profile/email", { user_id: userId });

  if (success && data) {
    const newProps = {
      newEmail,
      expiresAt: dayjs().add(1, "day").toISOString(),
    };

    const newProfile = yield select(selectors.getProfile);
    if (!isEmpty(newProfile)) {
      newProfile.emailChangeRequest = newProps;
      yield put(actions.setProfile(keysToCamelCase(newProfile)));
    } else {
      const newUserDetail = yield select(selectors.getUserDetails);
      newUserDetail.emailChangeRequest = newProps;
      yield put(actions.setUserDetails(keysToCamelCase(newUserDetail)));
    }

    yield put(
      actions.newSuccessEvent({
        message: messages.RESEND_EMAIL_UPDATE_SUCCESS,
      }),
    );
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_RESENDING_EMAIL_UPDATE,
    });
  }
  return null;
}

export default resendEmailUpdate;
