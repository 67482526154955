import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const Container = styled("div")(() => ({ theme }) => ({
  display: "flex",
  alignItems: "center",
  background: theme.colors["commission-clawback"],
  justifyContent: "space-between",
  padding: "14px",
  height: "48px",
  color: theme.colors.white,
  fontSize: "14px",
}));

export const BannerColumn = styled("div")(() => () => ({
  display: "flex",
  alignItems: "center",
}));

export const Text = styled("div")(() => () => ({
  marginLeft: "12px",
}));

export const StyledButton = styled(Button)(() => ({ theme }) => ({
  width: "200px",
  height: "32px",
  background: "transparent",
  border: `1px solid ${theme.colors.white}`,
  borderRadius: "20px",
  color: theme.colors.white,
  textTransform: "capitalize",
}));
