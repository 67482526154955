import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DiagonalRightArrow } from "components/Icons";
import { theme } from "styles/themes";

import * as S from "./styles";

type JoinPowurTodayButtonPropsT = {
  onClick: () => void;
  buttonText?: string;
  maxWidth?: `${number}px`;
};

const JoinPowurTodayButton = ({ onClick, buttonText, maxWidth = "220px" }: JoinPowurTodayButtonPropsT) => {
  const { t }: { t: (textRef: string) => string } = useTranslation(["setter_join"]);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  return (
    <S.JoinTodayContainer
      onClick={onClick}
      onMouseOver={() => setIsButtonHovered(true)}
      onMouseOut={() => setIsButtonHovered(false)}
      maxWidth={maxWidth}
    >
      <S.ButtonText>{buttonText || t("join_today_button_text")}</S.ButtonText>
      <S.ArrowIconContainer background={isButtonHovered ? theme.colors["tango-orange"] : theme.colors.white}>
        <DiagonalRightArrow fill={isButtonHovered ? theme.colors.white : theme.colors["tango-orange"]} />
      </S.ArrowIconContainer>
    </S.JoinTodayContainer>
  );
};

export default JoinPowurTodayButton;
