import { select, put, all } from "redux-saga/effects";
import { cloneDeep } from "lodash";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { keysToSnakeCase, camelizeKeys, formatPreSalesLead } from "lib/helpers";
import formatLeadGenLead from "lib/helpers/formaters/formatLeadGenLead";
import messages from "../messages";

function* createNewLead(action) {
  const {
    lead,
    address,
    enterprise,
    homeownerAvailability,
    siteInfo,
    customSuccessMessage,
    isLeadGen = false,
  } = action.payload;
  const body = {
    lead: keysToSnakeCase(lead),
    address: keysToSnakeCase(address),
    enterprise,
    ...(homeownerAvailability && { homeowner_availability: keysToSnakeCase(homeownerAvailability) }),
    ...(siteInfo && { site_info: keysToSnakeCase(siteInfo) }),
  };
  const { success, data, error } = yield* makeRequest.post("/leads", body);
  if (success && data) {
    const entLeads = yield select(selectors.getEnterpriseLeads);
    const leads = yield select(selectors.getLeads);
    const out = cloneDeep(entLeads);
    out.leads.unshift(camelizeKeys(data.body));

    const { projects, paging } = yield select(selectors.getProjectPreSales);
    const presalesPaging = {
      ...paging,
      itemCount: paging.itemCount + 1,
    };
    const presalesOut = cloneDeep(projects);
    const preSalesLead = formatPreSalesLead(camelizeKeys({ ...data.body }));
    presalesOut.unshift(preSalesLead);

    if (isLeadGen && enterprise) {
      const leadGenPreSalesProjects = yield select(selectors.getLeadGenPreSalesProjects);
      const leadGenProjectsOut = cloneDeep(leadGenPreSalesProjects);
      const newLeadGenLead = formatLeadGenLead(camelizeKeys({ ...data.body }));

      leadGenProjectsOut.projects.unshift(newLeadGenLead);
      yield put(actions.setLeadGenPreSalesProjects(leadGenProjectsOut));
    }

    yield all([
      put(actions.setLeadToProposalDrawerLeadId({ id: data.body.id })),
      put(actions.setLeadToProposalDrawerLeadDetails(camelizeKeys(data.body))),
      put(actions.setEnterpriseLeads(out)),
      put(actions.setLeads({ ...leads, leads: [camelizeKeys(data.body), ...leads.leads] })),
      put(
        actions.setProjectsPreSales({
          projects: presalesOut,
          paging: presalesPaging,
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: customSuccessMessage || messages.LEAD_CREATE_SUCCESS,
          payload: { ...data.body },
        }),
      ),
    ]);
  } else if (error) {
    if (error.message.includes("Do Not Call")) {
      return getErrorActions({ error, message: messages.ERROR_UPDATING_LEAD_DNC_PHONE });
    }
    return getErrorActions({ error, message: messages.ERROR_CREATING_LEAD });
  }
  return null;
}

export default createNewLead;
