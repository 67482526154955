import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
// import _ from "lodash";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getTeamsList(action) {
  const params = {
    ...action.payload,
  };
  const options = {
    signal: params.signal,
  };

  const { success, data, error } = yield* makeRequest.get("/teams", {}, options);

  if (success && data) {
    const teamsList = data.body.teams.map((team) => {
      const newTeams = team.users.map((user) => keysToCamelCase(user));
      return keysToCamelCase({ ...team, users: newTeams });
    });

    yield put(actions.setTeamsList(teamsList));
  } else {
    if (params.signal?.aborted) return null;
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_TEAMS_LIST,
    });
  }
  return null;
}

export default getTeamsList;
