import React, { CSSProperties } from "react";
import { SwitchProps } from "@mui/material";
import { useController } from "react-hook-form";
import { useSmartFormContext } from "components/SmartForm";
import HelperText from "../HelperText";

import * as S from "./styles";

type SmartFormInviteSwitchProps = {
  headerLabel?: string;
  name: string;
  switchLabel?: string;
  description?: string;
  helperText?: string | undefined;
  error?: boolean;
  disabled?: boolean;
  headerLabelStyle?: CSSProperties;
  containerStyle?: CSSProperties;
} & SwitchProps;

const SmartFormInviteSwitch = ({
  switchLabel,
  headerLabel,
  name,
  helperText,
  error,
  disabled = false,
  headerLabelStyle = {},
  containerStyle = {},
  ...rest
}: SmartFormInviteSwitchProps) => {
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });
  const { value } = field;
  return (
    <div style={containerStyle}>
      <S.LabelGroupWrapper>
        <S.StyledSectionLabel style={headerLabelStyle}>{headerLabel}</S.StyledSectionLabel>
      </S.LabelGroupWrapper>

      <S.StyledFormControlLabel
        label={switchLabel}
        value={value}
        defaultValue={rest.defaultValue}
        disabled={disabled}
        control={<S.StyledSwitch {...field} checked={value} />}
      />

      <HelperText sx={{ marginTop: "-5px" }} error={error}>
        {helperText}
      </HelperText>
    </div>
  );
};

export default SmartFormInviteSwitch;
