import React from "react";
import PropTypes from "prop-types";
import Story from "./story";
import styles from "../../Solar.module.less";

const PowurStories = (props) => {
  const { stories, headline, subTitle, showTopBorder } = props;
  return (
    <div className={styles.powurStories}>
      <div className={[styles.titleContainer, showTopBorder ? "" : styles.headlineHideBorder].join(" ")}>
        <h5 className={styles.headline}>{headline}</h5>
        <h3 className={styles.subtitle}>{subTitle}</h3>
      </div>
      <div className={styles.cardsContainer}>
        {stories.map(({ imgClassName, title, videoSrc, content }, idx) => (
          <Story
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            imgClassName={imgClassName}
            title={title}
            videoSrc={videoSrc}
            content={content}
          />
        ))}
      </div>
    </div>
  );
};

PowurStories.propTypes = {
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      imgClassName: PropTypes.string,
      title: PropTypes.string,
      videoSrc: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ),
  headline: PropTypes.string,
  subTitle: PropTypes.string,
  showTopBorder: PropTypes.bool,
};

export default PowurStories;
