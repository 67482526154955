import React from "react";
import { Layout } from "antd";
import { Helmet } from "react-helmet";
import { Header, Footer, GetQuoteModal, UtilityBillModal, SuccessModal } from "components/HomepageComponents";
import ScrollToTop from "containers/router/scrollToTop";
import HeroImage from "./components/HeroImage";
import Divider from "./components/Divider";
import CareersLink from "./components/CareersLink";
import styles from "./careers.module.less";

const { Content } = Layout;

const Careers = (props) => {
  const { user } = props;
  return (
    <Layout className={styles.pageLayout}>
      <Helmet>
        <title>Build a Fulfilling and Prosperous Career with Powur</title>
        <meta name="title" content="Build a Fulfilling and Prosperous Career with Powur" />
        <meta
          name="description"
          content="Powur has assembled one of the most passionate and talented teams in the solar energy industry. Join us in accelerating the adoption of sustainable energy worldwide."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://powur.com/careers" />
        <meta property="og:title" content="Build a Fulfilling and Prosperous Career with Powur" />
        <meta
          property="og:description"
          content="Powur has assembled one of the most passionate and talented teams in the solar energy industry. Join us in accelerating the adoption of sustainable energy worldwide."
        />
        <meta property="og:image" content="/img/powur_fb_01.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://powur.com/careers" />
        <meta property="twitter:title" content="Build a Fulfilling and Prosperous Career with Powur" />
        <meta
          property="twitter:description"
          content="Powur has assembled one of the most passionate and talented teams in the solar energy industry. Join us in accelerating the adoption of sustainable energy worldwide."
        />
        <meta property="twitter:image" content="/img/powur_fb_01.jpg" />
      </Helmet>
      <ScrollToTop />
      <Header user={user} />
      <Content className={styles.content}>
        <HeroImage />
        <Divider />
        <CareersLink />
      </Content>
      <Footer />
      <GetQuoteModal />
      <UtilityBillModal />
      <SuccessModal />
    </Layout>
  );
};

export default Careers;
