export default function formatAndAbbreviateCurrency(fee, noDecimal = false, noMillions = false) {
  if (!fee) return null;
  let newFee = fee;
  let abbreviation = "";
  let newNoDecimal = noDecimal;

  if (newFee > 1000000 && !noMillions) {
    newFee /= 1000000;
    abbreviation = "m";
    if (newFee % 1 !== 0) {
      newNoDecimal = false;
    }
  } else if (newFee > 10000) {
    newFee /= 1000;
    abbreviation = "k";
  }

  let out = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(Number(newFee));

  if (fee > 10000 && !newNoDecimal) {
    out = out.substring(0, out.length - 1);
  }

  return newNoDecimal ? `${out.substring(0, out.length - 3)}${abbreviation}` : `${out}${abbreviation}`;
}
