import React from "react";
import { Spin } from "antd";
import styles from "../styles.module.less";

const Loading = () => (
  <div className={styles.spinContainer}>
    <Spin className={styles.spin} />
  </div>
);

export default Loading;
