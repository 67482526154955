import React from "react";
import PropTypes from "prop-types";

const RightCaretFilled = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.42273 3.59485C6.69871 3.79445 6.69871 4.20555 6.42273 4.40514L1.78876 7.75659C1.45813 7.99571 0.995747 7.75948 0.995746 7.35145L0.995746 0.648554C0.995746 0.240517 1.45813 0.00428737 1.78876 0.243409L6.42273 3.59485Z"
        fill={fill}
      />
    </svg>
  );
};

RightCaretFilled.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

RightCaretFilled.defaultProps = {
  fill: "var(--dark-blue)",
  width: "7",
  height: "8",
};

export default RightCaretFilled;
