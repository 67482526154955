import React from "react";
import PropTypes from "prop-types";
import styles from "../Education.module.less";

const AnimatedChevrons = (props) => {
  const { size } = props;

  return (
    <div className={styles.animatedChevronsContainer}>
      <div>
        <div className={[styles.chevron1, styles[size]].join(" ")} />
      </div>
      <div>
        <div className={[styles.chevron2, styles[size]].join(" ")} />
      </div>
    </div>
  );
};

// size: small or large
AnimatedChevrons.propTypes = {
  size: PropTypes.string,
};

export default AnimatedChevrons;
