import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";

function* markAssetAsViewed(action) {
  const params = {
    ...action.payload,
  };

  const { success, data, error } = yield* makeRequest.post("/viewable_assets/mark_viewed", params);

  if (success && data) {
    yield all([put(actions.setTaskToComplete(action.payload.slug))]);
  } else if (error) {
    return getErrorActions({
      error,
      message: "ERROR MARKING TASK AS COMPLETED",
    });
  }

  return null;
}

export default markAssetAsViewed;
