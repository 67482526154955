import React from "react";
import { Grid } from "@mui/material";
import CloseButton from "components/Buttons/CloseButton";
import GradientButton from "components/Buttons/GradientButton";
import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";
import { cvar } from "styles";

const ActionBarComponent = (props: PickersActionBarProps) => {
  const { onCancel, onAccept } = props;
  return (
    <>
      <Grid
        style={{ background: cvar("athens-gray"), borderRadius: "12px" }}
        container
        justifyContent="center"
        alignItems="center"
        height="60px"
        width="352px"
      >
        <Grid item xs={1} paddingRight={6}>
          <CloseButton buttonTypeStyle="gradient" action={onCancel} />
        </Grid>
        <Grid item xs={6} paddingRight={1}>
          <GradientButton style={{ width: "164px" }} onClick={onAccept}>
            Select
          </GradientButton>
        </Grid>
      </Grid>
    </>
  );
};

export default ActionBarComponent;
