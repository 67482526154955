import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/ambassador/types";

import getRegistrationInfo from "./getRegistrationInfo";
import getApplicationAgreement from "./getApplicationAgreement";
import createNewAmbassadorUser from "./createNewAmbassadorUser";
import createAmbassadorLead from "./createLead";
import ambassadorZipLookup from "./zipLookup";
import getAmbassadorLeads from "./getLeads";
import getStripeSignupUrl from "./getStripeSignupUrl";
import getStripeLoginUrl from "./getStripeLoginUrl";
import getStripeInfo from "./getStripeInfo";
// IMPORT_PT (for script, do not remove)

function* watchAmbassadorSagas() {
  yield trackRequests(takeEvery, types.GET_AMBASSADOR_NEW_REGISTRATION_INFO_REQUEST, getRegistrationInfo);
  yield trackRequests(takeEvery, types.GET_AMBASSADOR_APPLICATION_AGREEMENT_REQUEST, getApplicationAgreement);
  yield trackRequests(takeEvery, types.CREATE_AMBASSADOR_NEW_USER_REQUEST, createNewAmbassadorUser);
  yield trackRequests(takeEvery, types.CREATE_AMBASSADOR_LEAD_REQUEST, createAmbassadorLead);
  yield trackRequests(takeEvery, types.GET_AMBASSADOR_LEAD_ZIP_LOOKUP_REQUEST, ambassadorZipLookup);
  yield trackRequests(takeEvery, types.GET_AMBASSADOR_LEADS_REQUEST, getAmbassadorLeads);
  yield trackRequests(takeEvery, types.GET_STRIPE_SIGNUP_URL_REQUEST, getStripeSignupUrl);
  yield trackRequests(takeEvery, types.GET_STRIPE_LOGIN_URL_REQUEST, getStripeLoginUrl);
  yield trackRequests(takeEvery, types.GET_STRIPE_ACCOUNT_INFO_REQUEST, getStripeInfo);
  // INSERTION_PT (for script, do not remove)
}

export default watchAmbassadorSagas;
