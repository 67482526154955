import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "../types";
import verifyHomeownerContact from "./verifyHomeownerContact";
// IMPORT_PT (for script, do not remove)

function* watchHomeownerSagas() {
  yield trackRequests(takeEvery, types.VERIFY_HOMEOWNER_CONTACT, verifyHomeownerContact);
  // INSERTION_PT (for script, do not remove)
}

export default watchHomeownerSagas;
