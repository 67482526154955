import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const CloseIconThin = ({ width = "20", height = "20", fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 5L5 15M5 5L15 15" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CloseIconThin;
