import { isNull } from "types/guards";

function stringToBool(str: string | null): boolean {
  if (isNull(str)) return false;
  return str.toLowerCase() === "true";
}

function boolToString(bool: boolean): "true" | "false" {
  return bool ? "true" : "false";
}

export { boolToString, stringToBool };
