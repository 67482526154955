import React from "react";
import { cvar } from "styles";
import { IconT } from "types/utils";

const ContractsOutGradient = ({
  height = "28",
  width = "30",
  stopColor = cvar("blue-ribbon"),
  startColor = cvar("picton-blue"),
}: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2167_14259)">
        <path
          d="M30.3014 4.78089C30.3014 4.12398 30.0451 3.50558 29.5804 3.04095L27.2605 0.721C26.8023 0.262773 26.1519 0 25.5046 0H25.3636V4.92188H30.3015L30.3014 4.78089Z"
          fill="url(#paint0_linear_2167_14259)"
        />
        <path
          d="M30.3234 18.0469V14.7656V10.1159V6.5625H24.5593C24.1059 6.5625 23.7656 6.1956 23.7656 5.74219V0H11.4609C10.1039 0 9 1.10392 9 2.46094V25.5391C9 26.8961 10.1039 28 11.4609 28H27.8406C29.1976 28 30.3234 26.8961 30.3234 25.5391V19.4167L29.4219 20.2963C29.0566 20.6584 30.3234 19.4167 30.3234 19.4167V18.0469ZM13.1016 14.7656H21.2781C21.7315 14.7656 22.0984 15.1325 22.0984 15.5859C22.0984 16.0394 21.7315 16.4062 21.2781 16.4062H13.1016C12.6481 16.4062 12.2813 16.0394 12.2813 15.5859C12.2813 15.1325 12.6481 14.7656 13.1016 14.7656ZM12.2813 12.3047C12.2813 11.8513 12.6481 11.4844 13.1016 11.4844H21.2781C21.7315 11.4844 22.0984 11.8513 22.0984 12.3047C22.0984 12.7581 21.7315 13.125 21.2781 13.125H13.1016C12.6481 13.125 12.2813 12.7581 12.2813 12.3047ZM12.2813 18.8672C12.2813 18.4138 12.6481 18.0469 13.1016 18.0469H21.2781C21.7315 18.0469 22.0984 18.4138 22.0984 18.8672C22.0984 19.3206 21.7315 19.6875 21.2781 19.6875H13.1016C12.6481 19.6875 12.2813 19.3206 12.2813 18.8672ZM24.5593 22.9688C25.0127 22.9688 25.3796 23.3356 25.3796 23.7891C25.3796 24.2425 25.0128 24.6094 24.5593 24.6094H19.6374C19.184 24.6094 18.8171 24.2425 18.8171 23.7891C18.8171 23.3356 19.184 22.9688 19.6374 22.9688H24.5593ZM13.1016 9.84375C12.6481 9.84375 12.2813 9.47685 12.2813 9.02344C12.2813 8.57002 12.6481 8.20312 13.1016 8.20312H24.5593C25.0127 8.20312 25.3796 8.57002 25.3796 9.02344C25.3796 9.47685 25.0127 9.84375 24.5593 9.84375H13.1016Z"
          fill="url(#paint1_linear_2167_14259)"
        />
        <path
          d="M29.5196 10.8783L29.5197 10.8782C29.5224 10.8755 29.5237 10.8743 29.5259 10.8721C29.5237 10.8743 29.5224 10.8756 29.5196 10.8783Z"
          fill="url(#paint2_linear_2167_14259)"
        />
        <path
          d="M29.525 10.8721C29.5275 10.8696 29.5304 10.8667 29.5319 10.8652C29.5303 10.8668 29.5275 10.8696 29.525 10.8721Z"
          fill="url(#paint3_linear_2167_14259)"
        />
        <path
          d="M29.5317 10.866C29.5385 10.8592 29.5387 10.859 29.5317 10.866V10.866Z"
          fill="url(#paint4_linear_2167_14259)"
        />
        <path d="M2 21H7M3.875 17H7" stroke={startColor} strokeWidth="1.5" strokeLinecap="round" />
        <path d="M2 13H7" stroke={startColor} strokeWidth="1.5" strokeLinecap="round" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2167_14259"
          x1="26.2299"
          y1="-2.05078"
          x2="30.5891"
          y2="-1.61394"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2167_14259"
          x1="12.7409"
          y1="-11.6667"
          x2="31.6452"
          y2="-10.2286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2167_14259"
          x1="29.5207"
          y1="10.8695"
          x2="29.5262"
          y2="10.87"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2167_14259"
          x1="29.5262"
          y1="10.8624"
          x2="29.5323"
          y2="10.863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2167_14259"
          x1="29.5326"
          y1="10.8587"
          x2="29.5372"
          y2="10.8591"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <clipPath id="clip0_2167_14259">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContractsOutGradient;
