export default {
  // EXAMPLE_ACTION_TYPE: "[earnings] Example Action Type",
  GET_EARNINGS_HISTORY_REQUEST: "[earnings] GET Earnings History Request",
  SET_EARNINGS_HISTORY: "[earnings] SET Earnings History",
  GET_CONSULTANT_OR_LEAD_AUTO_COMPLETE: "[earnings] GET Seller Or Lead Auto Complete",
  SET_CONSULTANT_OR_LEAD_AUTO_COMPLETE: "[earnings] SET Seller Or Lead Auto Complete",
  GET_THIS_MONTH_EARNINGS: "[earnings] GET This Month Earnings Request",
  SET_THIS_MONTH_EARNINGS: "[earnings] SET This Month Earnings",
  GET_MONTHLY_BREAKDOWN_12_MOS: "[earnings] GET Monthly Earnings Breakdown Last 12 Mos Request",
  SET_MONTHLY_BREAKDOWN_12_MOS: "[earnings] SET Monthly Earnings Breakdown Last 12 Mos",
  GET_MONTHLY_BREAKDOWN: "[earnings] GET Monthly Earnings Breakdown Custom Date Range Request",
  SET_MONTHLY_BREAKDOWN: "[earnings] SET Monthly Earnings Breakdown Custom Date Range",
  // INSERTION_PT (for script, do not remove)
};
