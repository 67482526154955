import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import { theme } from "styles/themes";

type TextFieldPropsT = {
  hasPrefix: boolean;
  hasSuffix: boolean;
};

type AdornmentBorderT = {
  border?: boolean;
};

export const RadioTextContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const RadioRow = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "-7px",
}));

export const RadioText = styled("div")(() => ({
  marginLeft: "7px",
  fontSize: "16px",
  fontWeight: 500,
  color: theme.colors["queen-blue"],
  marginRight: "6px",
}));

export const TooltipText = styled("div")(() => ({
  fontSize: "16px",
  padding: "3px",
}));

export const TooltipIcon = styled("div")(() => ({
  transform: "matrix(1, 0, 0, -1, 0, 0)",
  fontSize: "10px",
  lineHeight: "10px",
  fontWeight: 500,
  color: theme.colors["graham-grey"],
  border: `1px solid ${theme.colors["graham-grey"]}`,
  width: "14px",
  height: "14px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const AdornmentWrapper = {
  color: theme.colors["dark-blue"],
  fontFamily: "Barlow",
  fontWeight: 500,
  height: "32px",
  display: "flex",
  alignItems: "center",
};

export const PrefixWrapper = styled("span", {
  shouldForwardProp: (prop) => prop !== "border",
})(({ border }: AdornmentBorderT) => ({
  ...AdornmentWrapper,
  ...(border && {
    borderRight: `1px solid ${theme.colors["botticelli-blue"]}`,
    paddingRight: "10px",
  }),
  marginLeft: "-2px",
}));

export const SuffixWrapper = styled("span")(({ border }: AdornmentBorderT) => ({
  ...AdornmentWrapper,
  ...(border && {
    borderLeft: `1px solid ${theme.colors["botticelli-blue"]}`,
    paddingLeft: "10px",
  }),
  marginRight: "-2px",
}));

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => !["hasPrefix", "hasSuffix"].includes(prop),
})(({ hasPrefix, hasSuffix }: TextFieldPropsT) => ({
  marginBottom: "24px",
  marginLeft: "42px",

  "& .MuiOutlinedInput-root": {
    "& input": {
      color: theme.colors["dark-blue"],
      fontSize: "16px",
      fontWeight: 500,
      padding: "7px 14px 8px",
      ...(hasPrefix && {
        paddingLeft: "0px",
      }),
      ...(hasSuffix && {
        paddingRight: "0px",
      }),
      lineHeight: "17px",
      height: "17px",
    },
    "& > fieldset": { borderColor: theme.colors["botticelli-blue"] },
    "&.Mui-focused fieldset": {
      borderColor: theme.colors["botticelli-blue"],
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `${theme.colors["botticelli-blue"]} !important`,
  },
  "& .Mui-disabled": {
    background: theme.colors["cotton-boll"],
    borderRadius: "3px",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: theme.colors["queen-blue"],
  },
}));
