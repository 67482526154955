import React from "react";
import PropTypes from "prop-types";
import { Rate } from "antd";
import styles from "./MentorRatingModals.module.less";

const MentorRatingDisplay = (props) => {
  const { rating, count } = props;

  return (
    <div className={styles.ratingShow}>
      <Rate disabled value={rating} style={{ color: "var(--dark-blue)", fontSize: "12px" }} />
      {count !== undefined && <div className={styles.count}>({count})</div>}
    </div>
  );
};

MentorRatingDisplay.propTypes = {
  rating: PropTypes.number,
  count: PropTypes.number,
};

MentorRatingDisplay.defaultProps = {
  count: undefined,
};

export default MentorRatingDisplay;
