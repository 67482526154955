import { put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import { keysToCamelCase } from "lib/helpers";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "../../messages";

function* getWorkflowPhases(action) {
  const { workflowId } = action.payload;

  const { success, data, error } = yield* makeRequest.get(`/workflows/${workflowId}/phases`, {});
  if (success && data) {
    const newPhases = data.body.phases.map((g) => {
      const out = keysToCamelCase(g);
      out.milestones = out.milestones.map((m) => keysToCamelCase(m));
      return out;
    });
    const workflows = yield select(selectors.getWorkflows);
    const workflow = workflows.find((flow) => flow.id === parseInt(workflowId, 10));

    yield put(actions.setWorkflowDetails({ ...workflow, phases: newPhases }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_WORKFLOW_DETAILS,
    });
  }
  return null;
}

export default getWorkflowPhases;
