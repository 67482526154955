import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import _ from "lodash";
import { keysToCamelCase, destructureObjArray } from "lib/helpers";
import messages from "../messages";

function* addRoleToUser(action) {
  const { userId, roleId } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/users/${userId}/role`, { role_id: roleId });
  if (success && data) {
    const user = keysToCamelCase(_.clone(data.body));
    user.metrics = destructureObjArray(data.body.metrics);
    user.managerOptions = user.managerOptions.map((u) => keysToCamelCase(u));
    yield put(actions.setUserDetails(user));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_ADDING_ROLE_TO_USER,
    });
  }
  return null;
}

export default addRoleToUser;
