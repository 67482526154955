import { put, call, take, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import types from "rdx/types";
import messages from "../messages";
import pollVisionProposal from "./pollVisionProposal";

function* requestVisionProposal(action) {
  const { id, requestType, proposalNotes, cloneId } = action.payload;

  yield put(actions.getVisionProposalCount({ id }));
  const { payload: initialCount } = yield take(types.SET_VISION_PROPOSAL_COUNT) || 0;

  const { success, error } = yield* makeRequest.post(`/provider_leads/${id}/request_vision_proposal`, {
    request_type: requestType || "over-night",
    proposal_notes: proposalNotes,
    clone_id: cloneId,
  });

  if (!success) {
    if (requestType === "self-request") {
      yield put(actions.setCreateVisionReqSuccessful(false));
    }
    return getErrorActions({
      error,
      message: messages.ERROR_REQUESTING_PROPOSAL,
    });
  }

  const pollSuccess = yield call(pollVisionProposal, { projectId: id, initialCount });
  if (pollSuccess) {
    yield all([
      ...(!cloneId
        ? [
            put(
              actions.newSuccessEvent({
                message: messages.REQUEST_VISION_PROPOSAL_SUCCESS,
              }),
            ),
          ]
        : []),
      put(actions.getProviderLeadDetails({ providerLeadId: id })),
      put(actions.getProjectProposals({ id })),
    ]);
  }

  return null;
}

export default requestVisionProposal;
