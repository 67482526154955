import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone-uploader";
import ApiClient from "util/ApiClient";
import { Spin } from "antd";
import LayoutComponent from "./Components/Layout";
import Input from "./Components/Input";
import FileUploaded from "./Components/FileUploaded";
import FileUploading from "./Components/FileUploading";
import styles from "./styles.module.less";
import "react-dropzone-uploader/dist/styles.css";

const api = new ApiClient({ host: process.env.REACT_APP_POWUR_API_URL });
const getConfig = async (cb, apiMode, filename) => {
  const params = { mode: apiMode };
  if (filename) params.filename = filename;
  await api.get("/uploader_config", params).then(
    (res) => {
      cb(res);
    },
    (res) => {
      console.warn(res.error);
    },
  );
};

const SingleFileDragAndDrop = ({ apiMode, fileUrl, setFileUrl, fileSize, setFileSize, widthShort }) => {
  const [config, setConfig] = useState({});
  const [ready, setReady] = useState(false);

  let dropzoneClassName = styles.singleFileDropzone;

  if (fileUrl) {
    dropzoneClassName = styles.dropzoneSolidBorder;
  } else if (widthShort) {
    dropzoneClassName = styles.widthShort;
  }

  const classNames = {
    dropzone: dropzoneClassName,
    input: styles.singleFileUploadInput,
    inputLabel: styles.singleFileInputLabel,
    inputLabelWithFiles: styles.inputLabelWithFiles,
  };

  const getUploadParams = async ({ file, meta }) => {
    let params;
    await getConfig(
      (res) => {
        params = {
          url: res.signed_url,
          headers: res.headers,
          method: "PUT",
          body: file,
          meta: {
            signedUrl: res.signed_url,
            url: res.signed_url.split("?")[0],
          },
        };
      },
      apiMode,
      meta.name,
    );
    return params;
  };

  const handleChangeState = async (file, status) => {
    if (status === "done") {
      setFileUrl(file.meta.url);
      setFileSize(file.meta.size);
    }
  };

  useEffect(() => {
    getConfig((res) => {
      setConfig(res);
      setReady(true);
    }, apiMode);
  }, [apiMode]);

  if (fileUrl) {
    return <FileUploaded fileUrl={fileUrl} setFileUrl={setFileUrl} fileSize={fileSize} setFileSize={setFileSize} />;
  }

  if (ready) {
    const input = <Input key="file-uploader-input-contents" max={config.max} accept={config.accept} />;

    return (
      <Dropzone
        // config
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeState}
        accept={config.accept}
        maxSizeBytes={config.max}
        multiple={false}
        // custom UI components
        inputContent={input}
        inputWithFilesContent={input}
        PreviewComponent={FileUploading}
        LayoutComponent={LayoutComponent}
        classNames={classNames}
      />
    );
  }

  return (
    <div className={styles.spinContainer}>
      <Spin className={styles.spin} />
    </div>
  );
};

SingleFileDragAndDrop.propTypes = {
  fileUrl: PropTypes.string,
  setFileUrl: PropTypes.func.isRequired,
  apiMode: PropTypes.string.isRequired,
  fileSize: PropTypes.number,
  setFileSize: PropTypes.func,
  widthShort: PropTypes.bool,
};

export default SingleFileDragAndDrop;
