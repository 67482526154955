import React, { CSSProperties } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

import InputAdornment from "@mui/material/InputAdornment";
import { useController } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";

import { cvar } from "styles";
import { useSmartFormContext } from "components/SmartForm";
import { ClockIcon } from "components/Icons";
import * as S from "./styles";
import HelperText from "../HelperText";

dayjs.extend(utc);

type SmartFormTimePickerT = {
  label?: string;
  name: string;
  setTimeFieldValue: (timeField: string, timeValue: string | null) => void;
  helperText?: string | undefined;
  error?: boolean;
  disabled?: boolean;
  labelStyle?: CSSProperties;
  iconColor?: string;
};

const SmartFormTimePicker = ({
  label,
  name,
  helperText,
  error,
  setTimeFieldValue,
  disabled = false,
  labelStyle = {},
  iconColor = cvar("dark-blue"),
}: SmartFormTimePickerT) => {
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });
  const { value } = field;

  const handleTimeChange = (inputValue: Dayjs | null) => {
    const timeValue = inputValue?.format("YYYY-MM-DD HH:mm:ss") || null;
    setTimeFieldValue(name, timeValue);
  };

  return (
    <>
      <S.LabelGroupWrapper>
        <S.StyledFormLabel style={labelStyle}>{label}</S.StyledFormLabel>
      </S.LabelGroupWrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{ hours12h: "h" }}>
        <MobileTimePicker
          value={dayjs.utc(value) || null}
          minutesStep={15}
          disabled={disabled}
          onChange={handleTimeChange}
          inputFormat="h:mm A"
          DialogProps={{
            sx: { "& .MuiPickersToolbar-penIconButton": { display: "none" } },
          }}
          renderInput={(params) => (
            <S.StyledTextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "- - : - -",
              }}
              disabled={disabled}
              size="small"
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ClockIcon fill={iconColor} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </LocalizationProvider>
      <HelperText sx={{ marginTop: "-10px" }} error={error}>
        {helperText}
      </HelperText>
    </>
  );
};

export default SmartFormTimePicker;
