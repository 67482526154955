import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Menu, Typography } from "antd";
import { parse } from "query-string";
import Router from "models/Router";
import { RightOutlined } from "@ant-design/icons";
import StorageClient from "util/StorageClient";
import styles from "./SidebarFilters.module.less";
import "./sidebarFilters.css";

const { SubMenu, Item } = Menu;
const { Paragraph } = Typography;

const Filters = (props) => {
  const {
    menus,
    navigate,
    match: {
      params: { tab },
    },
    location: { search },
    onSelect,
  } = props;
  const storage = new StorageClient();
  const updatedMenus = menus?.map((menuItem) => {
    if (menuItem.key === "mentored_projects") {
      return {
        ...menuItem,
        key: "partnered_projects",
        label: "Partnered Projects",
      };
    }
    return menuItem;
  });

  const menuKeys = updatedMenus?.map((menu) => {
    return menu.key;
  });

  const menuLabels = updatedMenus?.map((menu) => {
    return menu.label;
  });

  const storedValues = Object.values(storage?.projectFilters.projects || []);

  // stateful array of submenu keys, updates on user select or change in query string (e.g. browser back/forward)
  const [selectedKeys, setSelectedKeys] = useState([]);

  // shorthand functions to get specific item objects from items arrays
  const getSelectedItem = (idx) => {
    for (const option of updatedMenus[idx].options) {
      if (option.key === selectedKeys[idx]) return option;
    }
    return getDefaultItem(idx);
  };
  const getDefaultItem = (idx) => {
    for (const option of updatedMenus[idx].options) {
      if (option.default) return option;
    }
    return null;
  };

  // set default keys from items arrays, needs getDefaultItem function above, returns array of keys associated with items in the items array that have default === true
  const defaultSelectedKeys = updatedMenus?.map((_, idx) => getDefaultItem(idx)?.key);

  // function to call on submenu selection; navigates to new URL with updated query string
  const handleSelect = ({ item, key }) => {
    const {
      props: { option },
    } = item;
    const newValue = option.default ? null : key;
    onSelect(option.parentKey, newValue);
    navigate({ [option.parentKey]: newValue });
  };

  // watch for changes to URL query string and menu options, update selectedKeys accordingly
  useEffect(() => {
    // update selected keys
    setSelectedKeys(
      menuKeys?.map((menuKey, idx) => {
        let selectedKey = storedValues[idx] || defaultSelectedKeys[idx];
        const mentoredKey = menuKey === "partnered_projects" ? "mentored_projects" : menuKey;
        for (const option of updatedMenus[idx].options) {
          if (parse(search)[mentoredKey] === option.key) {
            selectedKey = option.key;
          }
        }
        return selectedKey;
      }),
    );
  }, [tab, search, menus]); // eslint-disable-line react-hooks/exhaustive-deps

  const subMenuOptions = (label) => {
    if (label === "Mentored") return "Partnered";
    if (label === "Unmentored") return "Unpartnered";
    return label;
  };

  return (
    <div className={styles.menuContainer}>
      <Menu
        className={styles.menu}
        defaultSelectedKeys={defaultSelectedKeys}
        selectedKeys={selectedKeys}
        onSelect={handleSelect}
        expandIcon={
          <RightOutlined
            style={{
              fontSize: "10px",
              color: "var(--dark-blue)",
              marginRight: "0px",
              minWidth: "auto",
            }}
          />
        }
      >
        {menuKeys?.map((menuKey, idx) => {
          let itemLabel = getSelectedItem(idx) ? getSelectedItem(idx).label : "";
          if (itemLabel === "Unmentored") itemLabel = "Unpartnered";
          if (itemLabel === "Mentored") itemLabel = "Partnered";
          return (
            <SubMenu
              className={[styles.subMenu, "remove-ant-design-submenu-title-padding"].join(" ")}
              key={menuKey}
              title={
                <div className={styles.titleContainer}>
                  <div className={styles.label}>{menuLabels[idx]}</div>
                  <Paragraph ellipsis className={styles.activeItem}>
                    {itemLabel}
                  </Paragraph>
                </div>
              }
            >
              {updatedMenus[idx].options.map((o) => {
                return (
                  <Item key={o.key} option={o}>
                    <span className={styles.item} style={{ textTransform: "capitalize" }}>
                      {subMenuOptions(o.label)}
                    </span>
                  </Item>
                );
              })}
            </SubMenu>
          );
        })}
      </Menu>
    </div>
  );
};

Filters.defaultProps = {
  onSelect: () => null,
};

Filters.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      // IMPORTANT: must have *unique* keys for each submenu as well as each option within the menu. if two submenus have the same key, this will break. if multiple options have the same key, the menu will show all options with that key as selected.
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          label: PropTypes.string,
          default: PropTypes.bool,
          index: PropTypes.number,
          parentKey: PropTypes.string,
        }).isRequired,
      ),
    }),
  ).isRequired,
  navigate: PropTypes.func.isRequired,
  location: Router.locationTypes(),
  match: Router.matchTypes(),
  onSelect: PropTypes.func,
};

export default withRouter(Filters);
