import { z } from "zod";

export const schema = z.object({
  utilityBillUrl: z.string().optional(),
  utilityBillSize: z.number().optional(),
  customSiteImage: z.string().optional(),
  customSiteImageSize: z.number().optional(),
  mounting: z.string().optional(),
  utilityCompany: z.string().optional(),
  kwhConsumption: z.string().regex(/^\d*$/, { message: "Please enter KWH Consumption in numbers only" }),
  hasExistingSystem: z.string().nullable(),
});

export type SiteInfoFormT = z.infer<typeof schema>;

export const blankFormData = {
  utilityBillUrl: "",
  utilityBillSize: 0,
  customSiteImage: "",
  customSiteImageSize: 0,
  mounting: "roof",
  utilityCompany: "",
  kwhConsumption: "",
  hasExistingSystem: null,
};
