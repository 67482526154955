export default {
  GET_DASHBOARD_UPDATES: "[dashboardUpdates] GET dashboardUpdates",

  CREATE_DASHBOARD_UPDATE: "[dashboardUpdates] POST new event dashboard update",

  SET_DASHBOARD_UPDATES: "[dashboardUpdates] SET dashboardUpdates",

  GET_DASHBOARD_UPDATE: "[dashboardUpdates] GET selected dashboard update",

  SET_DASHBOARD_UPDATE_ID: "[dashboardUpdates] SET selected dashboard update ID",

  UPDATE_DASHBOARD_UPDATE: "[dashboardUpdates] UPDATE selected dashboard update",

  DELETE_DASHBOARD_UPDATE: "[dashboardUpdates] DELETE selected dashboard promotion",

  SET_DASHBOARD_UPDATE_MODAL_ID: "[dashboardUpdates] SET Dashboard Update Modal Id",
  // INSERTION_PT (for script, do not remove)
};
