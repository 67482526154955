import { put, all, select } from "redux-saga/effects";
import selectors from "rdx/modules/tickets/selectors";
import actions from "rdx/actions";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToCamelCase, camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getInboxList(action) {
  const previousPaging = yield select(selectors.getInboxListPaging);

  // Only request paging if first page or no existing paging in rdx
  const pQuery = action.payload?.p;
  const params = {
    ...action.payload,
    ...(pQuery && { paging_data: pQuery && previousPaging.page_size === 0 }),
  };

  const { success, data, error } = yield* makeRequest.get("/messages", params);

  if (success && data) {
    if (data.body.messages) {
      if (data.body.taggable_roles || data.body.taggable_users) {
        yield put(
          actions.setInboxTags({
            roleTags: data.body.taggable_roles?.map((r) => ({
              ...keysToCamelCase(r),
              type: "role",
              text: r.description,
            })),
            userTags: data.body.taggable_users?.map((r) => ({
              ...keysToCamelCase(r),
              type: "user",
              text: r.full_name,
            })),
          }),
        );
      }

      // Set paging from rdx if not sent by BE
      const newPage = parseInt(params.p, 10);
      const pagingOut = data.body.paging
        ? data.body.paging
        : { ...previousPaging, ...(newPage && { page_number: newPage }) };

      const filterToTeamOptions = data.body.filter_to_team_options
        ? camelizeKeys(data.body.filter_to_team_options)
        : [];

      yield all([
        put(
          actions.setInboxList({
            paging: camelizeKeys(pagingOut),
            list: data.body.messages.map((entry) => keysToCamelCase(entry)),
            filterToTeamOptions,
          }),
        ),
      ]);
    }

    if (data.body.filters) {
      yield all([
        put(actions.setInboxFilters(camelizeKeys(data.body))),
        put(actions.updateInboxCount(data.body.inbox_count)),
      ]);
    }
    const observingAccount = data.body.observing_account ? camelizeKeys(data.body.observing_account) : null;
    yield all([put(actions.setObservingAccount(observingAccount))]);

    if (data.body.counters) {
      yield all([put(actions.updateInboxUnviewedMessagesCount(camelizeKeys(data.body.counters)))]);
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_MESSAGE_LIST,
    });
  }
  return null;
}

export default getInboxList;
