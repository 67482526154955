import types from "rdx/modules/sellerIntegrity/types";
import createAction from "rdx/utils/createAction";
import {
  SellerIntegrityViolationDataT,
  SetIntegrityDataPayloadT,
  UpdateIntegrityViolationPayloadT,
  UpdateViolationModulesPayloadT,
  DeleteViolationPayloadPropsT,
} from "containers/Admin/users/components/UserDetailDrawer/types";

export default {
  createIntegrityViolation: (payload: SellerIntegrityViolationDataT) =>
    createAction(types.CREATE_INTEGRITY_VIOLATION_REQUEST, payload),
  setIntegrityViolations: (payload: SetIntegrityDataPayloadT) => createAction(types.SET_INTEGRITY_VIOLATIONS, payload),
  getIntegrityViolations: (payload: { userId: number }) => createAction(types.GET_INTEGRITY_VIOLATIONS, payload),
  clearIntegrityData: () => createAction(types.CLEAR_INTEGRITY_VIOLATIONS),
  updateIntegrityViolation: (payload: UpdateIntegrityViolationPayloadT) =>
    createAction(types.UPDATE_INTEGRITY_VIOLATION, payload),
  addModuleToViolation: (payload: UpdateViolationModulesPayloadT) =>
    createAction(types.ADD_MODULE_TO_VIOLATION, payload),
  removeModuleFromViolation: (payload: UpdateViolationModulesPayloadT) =>
    createAction(types.REMOVE_MODULE_FROM_VIOLATION, payload),
  deleteIntegrityViolation: (payload: DeleteViolationPayloadPropsT) =>
    createAction(types.DELETE_INTEGRITY_VIOLATION, payload),
  // INSERTION_PT (for script, do not remove)
};
