import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form, Input, Row, Col } from "antd";
import { isEmpty } from "lodash";
import Registration from "models/Registration";
import SelectInput from "components/FormElements/SelectInput";
import GradientButton from "components/Buttons/GradientButton";
import styles from "./ContactInfo.module.less";

const NameAndNumberForm = ({ continueToNextStep, regInfo }) => {
  const { t } = useTranslation(["join", "form_labels"]);
  const [form] = Form.useForm();

  const countryCodes = [{ value: "+1" }, { value: "+52" }];

  const formatPhone = (phone) => {
    if (phone) {
      for (let i = 0; i < countryCodes.length; i++) {
        if (phone.includes(countryCodes[i].value)) {
          return {
            phonePrefix: countryCodes[i].value,
            phone: phone.replace(`${countryCodes[i].value} `, ""),
          };
        }
      }
    }
    return { phone };
  };

  useEffect(() => {
    if (regInfo && !isEmpty(regInfo)) {
      form.setFieldsValue({
        firstName: regInfo.firstName || "",
        lastName: regInfo.lastName || "",
        ...formatPhone(regInfo.phone),
      });
    }
  }, [form, regInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.contactInfoForm} data-test-id="name-and-number-form">
      <div className={styles.label}>{t("join_form.name")}</div>
      <Form onFinish={continueToNextStep} form={form} initialValues={regInfo}>
        <Form.Item
          name="firstName"
          validateTrigger={["onSubmit"]}
          rules={[
            {
              required: true,
              message: t("form_labels:first_name_required"),
            },
          ]}
        >
          <Input placeholder={t("form_labels:first_name")} />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
              message: t("form_labels:last_name_required"),
            },
          ]}
        >
          <Input placeholder={t("form_labels:last_name")} />
        </Form.Item>
        <div className={styles.label}>{t("join_form.phone_number")}</div>
        <Row gutter={8} align="middle">
          <Col span={7}>
            <SelectInput
              initialValue="+1"
              itemName="phonePrefix"
              choices={countryCodes}
              rules={[
                {
                  required: true,
                  message: t("form_labels:valid_phone_number"),
                },
              ]}
            />
          </Col>
          <Col span={17}>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: t("form_labels:valid_phone_number"),
                },
                {
                  pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                  message: t("form_labels:valid_phone_number"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsValue }) => {
            const { firstName, lastName, phone } = getFieldsValue();
            const formIsComplete = !!firstName && !!lastName && !!phone;
            return (
              <GradientButton
                type="primary"
                htmlType="submit"
                className={styles.contactInfoSubmit}
                disabled={!formIsComplete}
              >
                {t("join_form.continue")}
              </GradientButton>
            );
          }}
        </Form.Item>
      </Form>
    </div>
  );
};

NameAndNumberForm.propTypes = {
  continueToNextStep: PropTypes.func,
  regInfo: Registration.types(),
};

export default NameAndNumberForm;
