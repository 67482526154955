import React from "react";
import PropTypes from "prop-types";

const CancelIcon = (props) => {
  const { accentColor, mainColor, size, containerStyles, border } = props;

  return (
    <div
      style={{
        height: `${size}px`,
        // paddingTop: "3px",
        ...containerStyles,
      }}
    >
      <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* <g filter="url(#filter0_d)"> */}
        <rect x="2" y="1" width={size} height={size} rx={size / 2} fill="white" />
        <rect
          x="2"
          y="1"
          width={size - 0.5}
          height={size - 0.5}
          rx={(size - 0.5) / 2}
          fill={accentColor}
          stroke={border ? mainColor : accentColor}
          strokeWidth="0.5"
        />
        {/* </g> */}
        <path
          d="M16.7471 15L20.5047 10.5209C20.5677 10.4464 20.5148 10.3333 20.4174 10.3333H19.2751C19.2078 10.3333 19.1434 10.3634 19.099 10.4149L15.9998 14.1096L12.9007 10.4149C12.8577 10.3634 12.7933 10.3333 12.7246 10.3333H11.5823C11.4849 10.3333 11.4319 10.4464 11.4949 10.5209L15.2526 15L11.4949 19.4791C11.4808 19.4957 11.4718 19.516 11.4689 19.5376C11.4659 19.5592 11.4693 19.5812 11.4785 19.6009C11.4877 19.6207 11.5023 19.6373 11.5207 19.649C11.5391 19.6607 11.5605 19.6668 11.5823 19.6667H12.7246C12.7919 19.6667 12.8563 19.6366 12.9007 19.5851L15.9998 15.8904L19.099 19.5851C19.142 19.6366 19.2064 19.6667 19.2751 19.6667H20.4174C20.5148 19.6667 20.5677 19.5536 20.5047 19.4791L16.7471 15Z"
          fill={mainColor}
        />
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="32"
            height="32"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

CancelIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mainColor: PropTypes.string,
  accentColor: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  border: PropTypes.bool,
};

CancelIcon.defaultProps = {
  accentColor: "var(--white)",
  mainColor: "var(--royal-peacock-blue)",
  size: "28",
  border: true,
};

export default CancelIcon;
