import React, { useState } from "react";
import { Layout, Row, Col, Button, Avatar } from "antd";
import { Helmet } from "react-helmet";
import ScrollToTopOnMount from "containers/router/scrollToTop";
import InjectFacebookPixel from "components/Injectors/InjectFacebookPixel";
import styles from "../Education.module.less";
import videoList from "./videoList";
import PageFooter from "../components/pageFooter";
import ResizableButton from "../components/resizableButton";
import AnimatedChevrons from "../components/animatedChevrons";
import SolarMarketingForm from "../components/MarketingForm/index";

const WhySolar = (props) => {
  const { Content } = Layout;
  const { user } = props;
  const [currentVideo, setCurrentVideo] = useState(0);
  const [currentVideoHover, setCurrentVideoHover] = useState(null);

  const renderPreviewImg = (thisVideo) => {
    if (currentVideo > thisVideo) {
      return videoList[thisVideo].previewWatched;
    }
    if (currentVideo === thisVideo) {
      return videoList[thisVideo].previewNowWatching;
    }
    return videoList[thisVideo].previewPrimary;
  };

  const renderHoverImg = (thisVideo) => {
    if (currentVideo > thisVideo) {
      return videoList[thisVideo].previewHoverReplay;
    }
    return videoList[thisVideo].previewHoverWatch;
  };

  const tenQuestionsLink = user
    ? `/${user?.vanity_name}/education/top-10-questions${window.location.search}`
    : `/education/top-10-questions${window.location.search}`;

  return (
    <Layout className={styles.whySolarContainer}>
      <ScrollToTopOnMount />
      <InjectFacebookPixel facebookPixelId={user?.facebook_pixel_id} />
      <Helmet>
        <title>Why Should I Go Solar? – POWUR | Solar Energy for your Home</title>
      </Helmet>
      <Content>
        <Row className={styles.headerContainer}>
          <Col className={[styles.headerItem, styles.logo].join(" ")} />
          <Col className={styles.headerItem}>
            <Button
              className={styles.emptyButton}
              onClick={() => {
                const element = document.getElementById("consultation-form");
                element.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              <ResizableButton content="Get Your Free Solar Consultation" size="large" animation="pulse" />
            </Button>
          </Col>
        </Row>
        <Row className={styles.contentContainer}>
          <Col>
            <Row className={styles.videoDescriptionContainer}>
              <Col>
                <div className={styles.h4}>You Are Watching VIDEO {videoList[currentVideo].number}:</div>
                <p id="videoNumber" className={styles.h1}>
                  {videoList[currentVideo].title}
                </p>
                <p className={styles.h2}>{videoList[currentVideo].description}</p>
              </Col>
            </Row>
            <Row className={styles.videoContainerMargin}>
              <div className={styles.videoContainer}>
                <iframe
                  title="vimeo-player"
                  className={styles.iframe}
                  src={videoList[currentVideo].src}
                  // width="640"
                  // height="360"
                  frameBorder="0"
                  allowFullScreen
                  allow="autoplay, fullscreen"
                />
              </div>
            </Row>
            <Row className={styles.previewContainer}>
              {videoList.map((video, index) => {
                return (
                  <Col key={`preview${video.number}`} className={styles.previewCol}>
                    <Button
                      className={styles.previewButton}
                      onClick={() => {
                        const element = document.getElementById("videoNumber");
                        element.scrollIntoView({
                          behavior: "smooth",
                        });
                        setCurrentVideo(index);
                      }}
                      onMouseEnter={() => setCurrentVideoHover(index)}
                      onMouseLeave={() => setCurrentVideoHover(null)}
                    >
                      {currentVideoHover === index ? (
                        <img src={renderHoverImg(index)} alt="video1Preview" />
                      ) : (
                        <img src={renderPreviewImg(index)} alt="video1Preview" />
                      )}
                    </Button>
                  </Col>
                );
              })}
            </Row>
            {currentVideo !== 2 ? (
              <>
                <Row className={styles.consultationDescriptionContainer}>
                  <Col className={styles.formContainerItem}>
                    <div className={styles.centerChevrons}>
                      <AnimatedChevrons size="large" />
                    </div>
                    <p id="consultation-form" className={styles.h1}>
                      Get Your Free, No-obligation
                      <br /> Solar Consultation Now
                    </p>
                    <p className={styles.h2}>
                      If you want to put the sun to work for you, connect with us by filling out the form below. An
                      experienced Powur Solar Seller will listen to your needs, help you choose the right materials and
                      loan products, and make sure to include every tax incentive and rebate available.
                    </p>

                    <p className={styles.h2}>
                      You’ll receive a free, no-obligation proposal for $0-down solar so you can go solar with total
                      confidence.
                    </p>
                  </Col>
                </Row>
                <br />
                <Row className={styles.formContainer}>
                  <Col className={styles.formContainerItem}>
                    <div className={styles.boxShadow}>
                      <SolarMarketingForm />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className={styles.consultationDescriptionContainer}>
                  <Col className={styles.videoDescriptionCopy}>
                    <span className={styles.centerChevrons}>
                      <AnimatedChevrons size="large" />
                    </span>
                    <p id="consultation-form" className={styles.h1}>
                      Choose Your Next Step
                    </p>
                    <p className={styles.h2}>
                      Congratulations. You completed the Powur Solar Insights Series. Now you know why you should switch
                      to Solar, why you should go Solar with Powur, and why you should go Solar now.
                    </p>

                    <p className={styles.h2}>
                      We are here to help you make an informed decision regarding solar for you and your family. Here
                      are two ways we can further help:
                    </p>
                  </Col>
                </Row>
                <Row className={styles.formContainer}>
                  <Col className={[styles.formContainerItem, styles.boxShadow].join(" ")}>
                    <Avatar
                      size={70}
                      shape="circle"
                      className={styles.numberAvatar}
                      style={{
                        backgroundColor: "var(--picton-blue)",
                        color: "white",
                        marginBottom: "20px",
                      }}
                      icon="1"
                    />
                    <p className={styles.p}>If you are ready to make the sun work for you, then:</p>
                    <p className={styles.h1}>Connect with Us and receive a Free Solar Consultation</p>
                    <p className={styles.p}>
                      An experienced consultant will listen to your needs, help you choose the right materials and loan
                      products, and make sure to include every tax incentive and rebate available.
                    </p>
                    <p className={styles.p}>
                      You’ll receive a free, no-obligation proposal for $0-down solar so you can go solar with total
                      confidence.
                    </p>
                    <p className={styles.p}>Just fill the form below to get started.</p>
                    <SolarMarketingForm />
                  </Col>
                  <Col className={styles.formContainerItem}>
                    <Avatar
                      size={70}
                      shape="circle"
                      className={styles.numberAvatar}
                      style={{
                        backgroundColor: "var(--picton-blue)",
                        color: "white",
                        marginBottom: "20px",
                      }}
                      icon="2"
                    />
                    <p className={styles.p}>If you would prefer to learn more about Solar, then:</p>
                    <p className={styles.h1}>We invite you to Watch our answers to the Top 10 questions about Solar.</p>
                    <p className={styles.p}>
                      We want to help you to get all your Solar questions answered. This is why we created a Video
                      Series where we answer the most frequent and important questions about Solar such as:
                    </p>
                    <div className={styles.topTenList}>
                      <ul>
                        <li className={styles.p}>What is Net Metering?</li>
                        {/* <li className={styles.p}>What is the Solar Investment Tax Credit?</li> */}{" "}
                        {/* removing temporaily until updated content available 10/22/22 */}
                        <li className={styles.p}>How do Solar Warranties Work?</li>
                        <li className={styles.p}>What is Powur&apos;s Mission?</li>
                        <li className={styles.p}>Is Solar a Good Investment for me?</li>
                        <li className={styles.p}>How Do I Know if My Home is a Fit for Solar?</li>
                        <li className={styles.p}>Think Solar is too Expensive?</li>
                        <li className={styles.p}>What is Involved in Going Solar?</li>
                        <li className={styles.p}>Do I Need to Buy a Battery?</li>
                        <li className={styles.p}>Two Options for Purchasing Energy</li>
                      </ul>
                      <a href={tenQuestionsLink}>
                        <ResizableButton
                          content="Watch Now the Top 10 Questions Series"
                          size="large"
                          animation="pulse"
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <PageFooter />
        </Row>
      </Content>
    </Layout>
  );
};

export default WhySolar;
