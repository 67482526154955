import { permissionTypes } from "hooks/usePermissions";

const { ENTERPRISES_SUPER_ADMIN } = permissionTypes;

const commonTabs = [];

const adminTabs = [
  {
    key: "enterprise-applications",
    label: "Enterprise Applications",
    permission: ENTERPRISES_SUPER_ADMIN,
  },
  {
    key: "build-partner",
    label: "Build Partner",
    permission: ENTERPRISES_SUPER_ADMIN,
  },
  {
    key: "preferred-partner",
    label: "Preferred Partner",
    permission: ENTERPRISES_SUPER_ADMIN,
  },
];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
