// January 1, 2021
import userTimezone from "./userTimezone";

export default function formatDate(dateString) {
  const timeZone = userTimezone();

  return new Date(dateString).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone,
  });
}
