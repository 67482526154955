/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tooltip, Typography } from "antd";
import { MailOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard";
import CopyIcon from "components/Icons/copyIcon";
import styles from "./CopyEmail.module.less";

const { Paragraph } = Typography;

const CopyEmail = (props) => {
  const {
    label,
    email,
    tooltipTimeout,
    showIcon,
    showText,
    containerProps,
    tooltipProps,
    emailProps,
    iconProps,
    withHover,
    showCopyIcon,
  } = props;
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState(email);

  const handleClick = (e) => {
    setTooltipText(`${email} copied to clipboard`);
    e.stopPropagation();
    copy(email);
    setTooltipVisible(true);
    setTimeout(() => setTooltipVisible(false), tooltipTimeout);
  };

  const handleHover = (e) => {
    setTooltipText(email);
    e.stopPropagation();
    setTooltipVisible(true);
    // setTimeout(() => setTooltipVisible(false), tooltipTimeout);
  };

  return (
    <span className={styles.emailRow} {...containerProps}>
      <Tooltip
        title={tooltipText}
        placement="top"
        trigger="click"
        open={tooltipVisible}
        color="rgba(41, 92, 160, 0.9)"
        {...tooltipProps}
      >
        {showIcon && (
          <>
            <MailOutlined
              onClick={handleClick}
              onMouseEnter={handleHover}
              onMouseLeave={() => setTooltipVisible(false)}
              className={styles.icon}
              {...iconProps}
            />
          </>
        )}
        {showText && (
          <Paragraph
            className={[styles.email, ...(withHover ? [styles.withHover] : [])]}
            ellipsis={{
              expandable: false,
              rows: 1,
            }}
            onClick={handleClick}
            {...emailProps}
          >
            {label || email} {showCopyIcon && <CopyIcon />}
          </Paragraph>
        )}
      </Tooltip>
    </span>
  );
};

CopyEmail.propTypes = {
  email: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipTimeout: PropTypes.number,
  /* pass through props to ant design components */
  containerProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  tooltipProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  emailProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  iconProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  showIcon: PropTypes.bool,
  showText: PropTypes.bool,
  withHover: PropTypes.bool,
  showCopyIcon: PropTypes.bool,
};

CopyEmail.defaultProps = {
  tooltipTimeout: 4000,
  containerProps: {},
  tooltipProps: {},
  emailProps: {},
  iconProps: {},
  showIcon: true,
  showText: true,
  showCopyIcon: false,
};

export default CopyEmail;
