import React from "react";
import { IconT } from "types/utils";

const IncompleteCheck = (props: IconT) => (
  <svg width={25} height={25} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      x={0.736}
      y={0.48}
      width={23.25}
      height={23.25}
      rx={11.625}
      fill="#D9D9D9"
      stroke="#D9D9D9"
      strokeWidth={0.75}
    />
    <rect x={16.498} y={6.342} width={2.3} height={14} rx={1.15} transform="rotate(45 16.498 6.342)" fill="#fff" />
    <rect x={6.598} y={7.968} width={2.3} height={14} rx={1.15} transform="rotate(-45 6.598 7.968)" fill="#fff" />
  </svg>
);

export default IncompleteCheck;
