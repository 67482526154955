import React from "react";
import PropTypes from "prop-types";

const CheckIcon = (props) => {
  // Looks like antd's CheckCircleOutlined but a little different
  const { backgroundFill, fill, height, width } = props;

  return (
    <div>
      <svg width={width} height={height} viewBox="0 0 25 24" fill={backgroundFill} xmlns="http://www.w3.org/2000/svg">
        <circle cx="12.7363" cy="12" r="10.925" stroke={fill} strokeWidth="2.15" />
        <rect
          x="8.9043"
          y="10.8359"
          width="6"
          height="2.4"
          rx="0.5"
          transform="rotate(42.6057 8.9043 10.8359)"
          fill={fill}
        />
        <rect
          x="18.1943"
          y="9.59375"
          width="9.6"
          height="2.4"
          rx="0.5"
          transform="rotate(132.606 18.1943 9.59375)"
          fill={fill}
        />
      </svg>
    </div>
  );
};

CheckIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundFill: PropTypes.string,
  fill: PropTypes.string,
};

CheckIcon.defaultProps = {
  backgroundFill: "none",
  fill: "var(--eggplant)",
  width: "25",
  height: "24",
};

export default CheckIcon;
