import React, { useState, useEffect } from "react";
import SettingsClient from "util/SettingsClient";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import dayjs from "dayjs";
import selectors from "rdx/selectors";

import { Layout } from "antd";
import { Typography } from "@mui/material";
import { buttonProps, openLinkInNewTab } from "lib/props";
import ChangeLanguage from "components/HomepageComponents/ChangeLanguage";
import ChatIcon from "components/Icons/ChatIcon";
import { theme } from "styles/themes";
import SessionClient from "../../../util/SessionClient";
import ZipButton from "../ZipButton";
import ZipInput from "../ZipInput";
import styles from "./Footer.module.less";
import * as S from "./styles";

const Footer = () => {
  const pageReferrer = useSelector(selectors.selectPageReferrer);
  const { t } = useTranslation(["footer", "translation", "form_labels"]);
  const { user } = useParams();
  const { user: sessionUser } = new SessionClient();
  const [zip, setZip] = useState("");
  const [buttonTouch, setButtonTouch] = useState(false);
  const [currentConsultantAgreementPath, setCurrentConsultantAgreementPath] = useState(
    "https://powur-public2.s3.us-west-1.amazonaws.com/purchase_terms_and_conditions/Independent-Powur-Seller-Application-v1.0.pdf",
  );
  const history = useHistory();
  const location = useLocation();
  const currentYear = dayjs().year();
  const settings = new SettingsClient();

  const powurSupportLink = "https://chat.powur.com";

  const handleChatClick = () => {
    window.open(powurSupportLink, "_blank");
  };

  useEffect(() => {
    if (settings?.data?.current_consultant_agreement_path) {
      setCurrentConsultantAgreementPath(settings?.data?.current_consultant_agreement_path);
    }
  }, [settings?.data]);

  const handleLogin = () => {
    history.push("/login");
  };

  const getBlogLink = () => {
    let baseLink = "https://blog.powur.com/";
    if (sessionUser) {
      baseLink += `?sname=${sessionUser.props.vanityName}`;
    } else if (pageReferrer?.vanityName && !sessionUser) {
      baseLink += `?sname=${pageReferrer.vanityName}`;
    }
    return baseLink;
  };

  const { search } = location;

  const userTag = user ? `/${user}` : "";

  return (
    <Layout.Footer className={styles.footer}>
      <div className={styles.innerContainer}>
        <div className={styles.infoRow}>
          <div className={styles.links}>
            <div className={styles.linkCol}>
              <div className={styles.header}>Powur</div>
              <ul className={styles.linksList}>
                <li>
                  <Link to={{ pathname: `${userTag}/solar`, search }}>{t("translation:why_solar")}</Link>
                </li>
                <li>
                  <Link to={{ pathname: `${userTag}/faq`, search }}>FAQ</Link>
                </li>
                <li>
                  <Link to={{ pathname: `${userTag}/join`, search }}>{t("translation:join_powur")}</Link>
                </li>
                <li>
                  <Link to={{ pathname: "https://partner.powur.com" }} target="_blank">
                    Partner with Powur
                  </Link>
                </li>
                <li>
                  <Link to={{ pathname: `${userTag}/enterprise`, search }}>Enterprise</Link>
                </li>
                <li>
                  <Link to={{ pathname: `${userTag}/ambassador`, search }}>{t("translation:ambassador")}</Link>
                </li>
                <li>
                  <div {...buttonProps(handleLogin)}>Powur {t("form_labels:login")}</div>
                </li>
                <li>
                  <Link to={{ pathname: "https://powur.com/privacy-policy.html" }} target="_blank">
                    {t("form_labels:privacy_policy")}
                  </Link>
                </li>
                {currentConsultantAgreementPath && (
                  <li>
                    <Link
                      to={{
                        pathname: currentConsultantAgreementPath,
                      }}
                      target="_blank"
                    >
                      {t("form_labels:terms_and_conditions")}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            <div className={styles.linkCol}>
              <div className={styles.header}>{t("company")}</div>
              <ul className={styles.linksList}>
                <li>
                  <Link to={{ pathname: `${userTag}/about`, search }}>{t("our_team")}</Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `${userTag}/about`,
                      hash: "#mission",
                      search,
                    }}
                  >
                    {t("our_mission")}
                  </Link>
                </li>
                <li>
                  <Link to={{ pathname: `${userTag}/licenses`, search }}>{t("licenses")}</Link>
                </li>
                <li>
                  <a {...openLinkInNewTab(process.env.REACT_APP_INCOME_DISCLOSURE_LINK)}>{t("income_disclosure")}</a>
                </li>
                <li>
                  <Link to={{ pathname: `${userTag}/careers`, search }}>{t("careers")}</Link>
                </li>
                <li>
                  <a {...openLinkInNewTab("https://outlet.powur.com/")}>{t("store")}</a>
                </li>
                <li>
                  <a {...openLinkInNewTab(getBlogLink())}>{t("blog")}</a>
                </li>
              </ul>
            </div>
            <div className={styles.linkCol} style={{ display: "none" }}>
              <div className={styles.header}>Get MyPowur App</div>
              <ul className={styles.linksList} />
            </div>
            <div className={styles.linkCol}>
              <div className={styles.header}>{t("translation:contact")}</div>
              <ul className={styles.linksList}>
                <li>
                  <S.ChatButton onClick={handleChatClick}>
                    <ChatIcon height={18} width={18} />
                    <Typography fontSize={14} fontWeight={700} color={theme.colors.black}>
                      Live Chat
                    </Typography>
                  </S.ChatButton>
                </li>
                <br />
                <li>
                  {t("translation:need_help")} <br />
                  <a className={styles.emailLink} href="mailto:support@powur.com">
                    support@powur.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.solarCol}>
            <div className={styles.linksCol}>
              <div className={styles.header}>{t("form_labels:get_quote")}</div>
              <div className={styles.quoteContainer}>
                <ZipInput
                  isEmpty={buttonTouch && (zip === "" || zip.length < 5)}
                  zip={zip}
                  setZip={setZip}
                  className={styles.input}
                />
                <ZipButton zip={zip} className={styles.zipButton} buttonTouch={setButtonTouch} type="primary">
                  {t("start_now")}
                </ZipButton>
              </div>
              <div className={styles.lang}>
                <div className={styles.header}>{t("translation:language")}</div>
                <ChangeLanguage />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.disclaimerRow}>
          <div className={styles.disclaimer}>{t("disclaimer")}</div>
          <div className={styles.copyright}>
            &#169; Powur PBC 2012-{currentYear}, {t("all_rights_reserved")}
          </div>
        </div>
      </div>
    </Layout.Footer>
  );
};

export default Footer;
