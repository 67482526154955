import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";
import types from "../types";
import getAvailableManagers from "./getAvailableManagers";
import getTeams from "./getTeams";
import createTeamAssignment from "./createTeamAssignment";
import createSalesTeam from "./createSalesTeam";
import getSellers from "./getSellers";
import updateSalesTeam from "./updateSalesTeam";
import deleteSalesTeam from "./deleteSalesTeam";
import unassignUser from "./unassignUser";
import getAvailableSupport from "./getAvailableSupport";

function* watchSalesTeamsSaga() {
  yield trackRequests(takeEvery, types.GET_AVAILABLE_MANAGERS, getAvailableManagers);
  yield trackRequests(takeEvery, types.GET_AVAILABLE_SUPPORT, getAvailableSupport);
  yield trackRequests(takeEvery, types.GET_TEAMS, getTeams);
  yield trackRequests(takeEvery, types.POST_TEAM_ASSIGNMENT, createTeamAssignment);
  yield trackRequests(takeEvery, types.POST_SALES_TEAMS, createSalesTeam);
  yield trackRequests(takeEvery, types.GET_SELLERS, getSellers);
  yield trackRequests(takeEvery, types.UPDATE_SALES_TEAM, updateSalesTeam);
  yield trackRequests(takeEvery, types.DELETE_SALES_TEAM, deleteSalesTeam);
  yield trackRequests(takeEvery, types.UNASSIGN_USER, unassignUser);
  // INSERTION_PT (for script, do not remove)
}

export default watchSalesTeamsSaga;
