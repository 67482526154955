import React from "react";
import PropTypes from "prop-types";

const PowurLiveAvatar = (props) => {
  const { height, width, square } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      {square && (
        <rect xmlns="http://www.w3.org/2000/svg" x="1" width={width} height={height} rx="6" fill="var(--darker-blue)" />
      )}
      <path
        d="M36.998 18.0002C36.998 27.9413 28.9392 36.0002 18.998 36.0002C9.05692 36.0002 0.998047 27.9413 0.998047 18.0002C0.998047 8.05906 9.05692 0.000183105 18.998 0.000183105C28.9392 0.000183105 36.998 8.05906 36.998 18.0002Z"
        fill="var(--darker-blue)"
      />
      <path d="M11.0977 14.6913V10.0131H15.7247L11.0977 14.6913Z" fill="var(--white)" />
      <path d="M11.0982 21.3086L7.82617 18.0004L11.0982 14.6922V21.3086Z" fill="var(--white)" />
      <path d="M15.7246 10.0119L18.9967 6.70367L22.2687 10.0119H15.7246Z" fill="var(--white)" />
      <path d="M22.2687 25.9885L18.9967 29.2966L15.7246 25.9885H22.2687Z" fill="var(--white)" />
      <path d="M18.9961 22.6778V17.9971H23.6257L18.9961 22.6778Z" fill="var(--goldenrod)" />
      <path d="M18.9972 13.3203V17.9985H14.3701L18.9972 13.3203Z" fill="var(--goldenrod)" />
      <path d="M15.7247 25.9896H11.0977V21.3088L15.7247 25.9896Z" fill="var(--white)" />
      <path d="M26.8992 21.3088V25.9896H22.2695L26.8992 21.3088Z" fill="var(--white)" />
      <path d="M26.8994 14.6922L30.1715 18.0004L26.8994 21.3086V14.6922Z" fill="var(--white)" />
      <path d="M22.2695 10.0131H26.8992V14.6913L22.2695 10.0131Z" fill="var(--white)" />
    </svg>
  );
};

PowurLiveAvatar.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  square: PropTypes.bool,
};

// Notice the default height and width set to 100%. This is so the svg fills the antd avatar component.
PowurLiveAvatar.defaultProps = {
  width: "100%",
  height: "100%",
  square: false,
};

export default PowurLiveAvatar;
