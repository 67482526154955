import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  user: {},
  permissions: [],
};

export default {
  rbac: createReducer(initialState, {
    [types.SET_CURRENT_USER](state, action) {
      return {
        ...state,
        user: action.payload,
      };
    },
    [types.SET_USER_PERMISSIONS](state, action) {
      return {
        ...state,
        permissions: action.payload.permissions,
      };
    },
    [types.SET_NOTICE_VIEWED_BY_USER](state) {
      return {
        ...state,
      };
    },
    [types.UPDATE_UNVIEWED_NOTICES](state, action) {
      return {
        ...state,
        user: { ...state.user, notices: action.payload },
      };
    },
    [types.UPDATE_INBOX_COUNT](state, action) {
      const updateInboxCount = action.payload;
      return {
        ...state,
        user: { ...state.user, inboxCount: updateInboxCount },
      };
    },
    [types.UPDATE_INBOX_UNVIEWED_MESSAGES_COUNT](state, action) {
      const updateInboxUnviewedMessagesCount = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          inboxUnviewedMessagesCount: updateInboxUnviewedMessagesCount,
        },
      };
    },
    [types.UPDATE_INBOX_UNVIEWED_ARCHIVED_COUNT](state, action) {
      const updateInboxUnviewedArchivedCount = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          inboxUnviewedArchivedCount: updateInboxUnviewedArchivedCount,
        },
      };
    },
    [types.UPDATE_MY_PROJECTS_COUNT](state, action) {
      const updateMyProjectsCount = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          myProjectsCount: updateMyProjectsCount,
        },
      };
    },
    [types.CLEAR_RBAC]() {
      return initialState;
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
