export default {
  GET_ENTERPRISE_APPLICATIONS: "[enterpriseAppReview] GET Enterprise Applications",

  SET_ENTERPRISE_APPLICATIONS: "[enterpriseAppReview] SET Enterprise Applications",

  GET_ENTERPRISE_APPLICATION_DETAIL: "[enterpriseAppReview] GET Enterprise Application Detail",

  SET_ENTERPRISE_APPLICATION_DETAIL: "[enterpriseAppReview] SET Enterprise Application Detail",

  APPROVE_ENTERPRISE_APPLICATION: "[enterpriseAppReview] PATCH Approve Enterprise Application",

  REJECT_ENTERPRISE_APPLICATION: "[enterpriseAppReview] PATCH Reject Enterprise Application",

  MAKE_PENDING_ENTERPRISE_APPLICATION: "[enterpriseAppReview] PATCH Make Pending Enterprise Application",

  CREATE_ENTERPRISE_APPLICATION: "[enterpriseAppReview] POST Create Enterprise Application",

  UPDATE_ENTERPRISE_APPLICATION: "[enterpriseAppReview] PATCH Update Enterprise Application",

  CREATE_ENTERPRISE_TIER_ONE_USER_REGISTRATION: "[enterpriseApplications] Create Tier One User Registration",

  GET_LABOR_APPLICATION_STATUS: "[enterpriseApplications] GET Labor Application Status",

  SET_LABOR_APPLICATION_STATUS: "[enterpriseApplications] SET Labor Application Status",

  GET_ENTERPRISE_APPLICATION_BY_USER: "[enterpriseAppReview] GET Enterprise Application by User",

  GET_ENTERPRISE_APPLICATIONS_CSV: "[enterpriseApplications] GET Enterprise Applications Csv",

  SET_ENTERPRISE_APPLICATIONS_CSV: "[enterpriseApplications] SET Enterprise Applications Csv",

  GET_ENT_APPLICATIONS_CSV_REPORT: "[enterpriseApplications] GET Ent Applications Csv Report",
  // INSERTION_PT (for script, do not remove)
};
