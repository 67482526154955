import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/settings/messages";
import { keysToCamelCase } from "lib/helpers";

function* getAdminServiceAreas(action = { payload: {} }) {
  const { success, data, error } = yield* makeRequest.get("/service_areas", action.payload);

  if (success && data) {
    let out = [];
    if (data.body.service_areas) {
      out = data.body.service_areas.map((s) => keysToCamelCase(s));
    }
    yield put(actions.setAdminServiceAreas(out));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_SERVICE_AREAS_LIST,
    });
  }

  return null;
}

export default getAdminServiceAreas;
