import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* updateProviderLeadUsers(action) {
  const { projectId, newUserId, userParamKey } = action.payload;

  const KEYS = {
    Ambassador: "referrer_id",
    LeadGenerator: "referrer_id",
  };

  if (!KEYS[userParamKey]) {
    throw new Error(`Specified parameter ${userParamKey} is not a valid project parameter that can be updated.`);
  }

  const { success, data, error } = yield* makeRequest.patch(`/provider_leads/${projectId}/update_users`, {
    [KEYS[userParamKey]]: newUserId,
    ...(userParamKey === "Ambassador" ? { kind: "ambassador" } : {}),
    ...(userParamKey === "LeadGenerator" ? { kind: "lead_generator" } : {}),
  });

  if (success && data) {
    yield all([
      put(actions.setProjectDetails({ providerLead: keysToCamelCase(data.body) })),
      put(
        actions.newSuccessEvent({
          message: messages.ADD_AMBASSADOR_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_SELECTING_AMBASSADOR,
    });
  }
  return null;
}

export default updateProviderLeadUsers;
