import types from "rdx/modules/financials/types";
import createAction from "rdx/utils/createAction";

export default {
  getPayoutsList: (payload) => createAction(types.GET_PAYOUTS_LIST_REQUEST, payload),
  setPayoutsList: (payload) => createAction(types.SET_PAYOUTS_LIST, payload),
  setPayoutFilters: (payload) => createAction(types.SET_PAYOUT_FILTERS, payload),
  deletePayout: (payload) => createAction(types.DELETE_PAYOUT_REQUEST, payload),
  distributePayouts: (payload) => createAction(types.DISTRIBUTE_PAYOUTS_REQUEST, payload),
  topUpPayouts: (payload) => createAction(types.TOP_UP_PAYOUTS_REQUEST, payload),
  createPayout: (payload) => createAction(types.CREATE_PAYOUT_REQUEST, payload),
  payoutToPowur: (payload) => createAction(types.PAYOUT_TO_POWUR_REQUEST, payload),
  getPaymentMilestones: (payload) => createAction(types.GET_PAYMENT_MILESTONES_REQUEST, payload),
  setPaymentMilestones: (payload) => createAction(types.SET_PAYMENT_MILESTONES, payload),
  // INSERTION_PT (for script, do not remove)
};
