import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step, { stepClasses } from "@mui/material/Step";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
import { mq } from "styles";

import { theme } from "styles/themes";

type StyledStepIconPropsT = {
  isActive?: boolean;
  isCompleted?: boolean;
};

const labelProps = {
  fontFamily: "Poppins",
  color: theme.colors["darkest-blue"],
  fontWeight: 500,
};

export const StyledStepper = styled(Stepper)(() => ({
  [mq.tabletUp]: {
    margin: "-16px 40px 20px 30px",
  },
}));

export const StyledStep = styled(Step)(() => ({
  [`&.${stepClasses.horizontal}`]: {
    display: "flex",
    padding: "0px",
    zIndex: "100",
  },
}));

export const StyledStepConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.root}`]: {
    position: "relative",
    top: "-12px",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.colors["blue-ribbon"],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.colors["blue-ribbon"],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.colors["botticelli-blue"],
    borderTopWidth: "3px",
  },
}));

export const StyledStepLabel = styled(StepLabel)(() => ({
  display: "flex",
  flexDirection: "column",
  whiteSpace: "nowrap",
  width: "15px",
  [`& .${stepLabelClasses.iconContainer}`]: {
    paddingRight: "0px",
  },
  [`& .${stepLabelClasses.labelContainer}`]: {
    width: "fit-content",
    height: "24px",
    display: "flex",
    alignItems: "flex-end",
  },
}));

export const Label = styled("div")(() => ({
  ...labelProps,
  fontSize: "12px",
  textTransform: "capitalize",
  textAlign: "center",
}));

export const MobileLabel = styled("div")(() => ({
  ...labelProps,
  fontSize: "14px",
  textTransform: "capitalize",
}));

export const IconContainer = styled("div")(() => ({
  width: "30px",
  height: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledStepIcon = styled("div", {
  shouldForwardProp: (prop) => !["isActive", "isCompleted"].includes(prop as string),
})(({ isActive = false, isCompleted = false }: StyledStepIconPropsT) => ({
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.colors["botticelli-blue"],
  height: "15px",
  width: "15px",
  ...(isCompleted && {
    cursor: "pointer",
    backgroundColor: theme.colors["blue-ribbon"],
    paddingTop: "5px",
    [mq.phoneOnly]: {
      height: "20px",
      width: "20px",
    },
    [mq.tabletUp]: {
      height: "28px",
      width: "28px",
    },
  }),
  ...(isActive && {
    backgroundColor: theme.colors["blue-ribbon"],
    [mq.phoneOnly]: {
      height: "20px",
      width: "20px",
    },
    [mq.tabletUp]: {
      height: "28px",
      width: "28px",
    },
  }),
}));

export const CircleWithShadow = styled("div")(() => ({
  backgroundColor: theme.colors.white,
  borderRadius: "50%",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  [mq.phoneOnly]: {
    width: "8px",
    height: "8px",
  },
  [mq.tabletUp]: {
    width: "10px",
    height: "10px",
  },
}));

export const NeedsAttentionIconContainer = styled("div")(() => ({
  fontWeight: 600,
  color: theme.colors.white,
  fontSize: "18px",
  marginBottom: "5px",
  [mq.phoneOnly]: {
    fontSize: "14px",
    marginBottom: "6px",
  },
}));
