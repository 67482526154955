import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import _ from "lodash";
import { keysToCamelCase, camelizeKeys, objArrayKeysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getAmbassadorList(action) {
  const params = {
    sort: "newest",
    role: "ambassador",
    ...action.payload,
  };

  if (params.rank) {
    params.rank = params.rank.slice(1);
  }
  if (params.level) {
    params.level = params.level.slice(1);
  }
  if (!params.status && params.role !== "ambassador") {
    params.status = "active";
  }

  const { success, data, error } = yield* makeRequest.get("/users/team_ambassador", params);
  if (success && data) {
    const out = _.clone(data.body);
    if (out.users) {
      out.users = data.body.users.map((user) => {
        const newUser = _.clone(user);
        newUser.metrics = {};
        objArrayKeysToCamelCase(user.metrics).forEach((obj) => {
          const newKey = Object.keys(obj)[0];
          const newValue = Object.values(obj)[0];
          newUser.metrics[newKey] = newValue;
        });
        return keysToCamelCase(newUser);
      });
    }
    yield put(actions.setAmbassadorList(camelizeKeys(out)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_AMBASSADOR_LIST,
    });
  }
  return null;
}

export default getAmbassadorList;
