import React from "react";
import PropTypes from "prop-types";

const PersonSilhouetteIcon = (props) => {
  const { height, width, firstColor, secondColor } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 27 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1756 15.9183C17.5716 15.9183 21.1348 12.3548 21.1348 7.95915C21.1348 3.56353 17.5716 0 13.1756 0C8.77999 0 5.21646 3.56353 5.21646 7.95915C5.21646 12.3548 8.77999 15.9183 13.1756 15.9183ZM19.9609 15.6635L21.0834 15.7144C24.2465 15.9183 26.5937 18.6224 26.4919 21.7346L25.1654 36.9899C25.1142 38.3163 24.043 39.4388 22.6653 39.5409C19.3489 39.847 16.747 40 13.2265 40C9.75716 40 7.15482 39.847 3.83866 39.5409C2.51193 39.4388 1.44069 38.3675 1.33855 36.9899L0.0120972 21.7346C-0.191804 18.6227 2.20617 15.9183 5.36937 15.7144L6.49183 15.6635C7.00176 15.6126 7.56303 15.7147 7.97125 16.0208C9.50196 17.0411 11.2874 17.6024 13.2264 17.6024C15.1654 17.6024 16.9508 17.0411 18.4815 16.0208C18.9406 15.7656 19.4506 15.6126 19.9609 15.6635Z"
        fill="url(#gradient)"
      />
      <defs>
        <linearGradient
          id="gradient"
          x1="4.64826"
          y1="-16.6667"
          x2="28.1704"
          y2="-15.1104"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={firstColor} />
          <stop offset="1" stopColor={secondColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

PersonSilhouetteIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  firstColor: PropTypes.string,
  secondColor: PropTypes.string,
};

PersonSilhouetteIcon.defaultProps = {
  height: "40",
  width: "27",
  firstColor: "var(--picton-blue)",
  secondColor: "var(--blue-ribbon)",
};

export default PersonSilhouetteIcon;
