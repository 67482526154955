import styled from "@emotion/styled";
import { theme } from "styles/themes";
import Button from "@mui/material/Button";
import { IconButtonT } from "./index";

export const StyledButton = styled(Button)<IconButtonT>(({ width, height }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid ${theme.colors["botticelli-blue"]} !important`,
  height: "32px",
  width: "32px !important",
  minWidth: "32px !important",
  background: theme.colors.white,
  padding: "4px !important",
  color: theme.colors["dark-blue"],
  boxShadow: "0px 0.5px 1px rgba(0, 0, 0, 0.12)",
  borderRadius: 3,
  "&:hover": {
    background: `${theme.colors["botticelli-blue"]} !important`,
    boxShadow: "0px 0.5px 1px rgba(0, 0, 0, 0.12)",
  },
  ...(width && {
    width: `${width} !important`,
    minWidth: `${width} !important`,
  }),
  ...(height && {
    height: `${height} !important`,
  }),
}));
