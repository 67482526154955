import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import { Layout } from "antd";
import { Helmet } from "react-helmet";
import { Header, Footer, GetQuoteModal, UtilityBillModal } from "components/HomepageComponents";
import ScrollToTop from "containers/router/scrollToTop";
import { useLocation, useParams, useHistory } from "react-router-dom";
import useModalControls from "hooks/useModalControls";
import { parse } from "query-string";
// Enterprise Join Page Components:
import EnterpriseProSignupModal from "components/HomepageComponents/EnterpriseProSignupModal";
import VideoBanner from "./components/VideoBanner";
import InfoPanels from "./components/InfoPanels";
import Features from "./components/Features";
import BuildYourTeam from "./components/BuildYourTeam";
import GetSignedUpEnterprise from "./components/GetSignedUpEnterprise";
import PricingAndPlansModal from "./components/PricingAndPlans/pricingAndPlansModal";
import styles from "./EnterpriseJoin.module.less";

const { Content } = Layout;

const EnterpriseJoin = (props) => {
  const { selectPageReferrer, getPageReferrer } = props;

  const history = useHistory();
  const { visible, open, close } = useModalControls();

  // Effect calls "[app] GET page referrer" in root, if there's a user_slug or ref_id qParam and adds to redux.
  // This is very different from routers/PersonalRoute(s), but might be a simpler model for future use.
  const location = useLocation();
  const { ref_id } = parse(location.search);
  const { user: slug } = useParams();

  useEffect(() => {
    if (ref_id || slug) {
      getPageReferrer({
        ref_id,
        slug,
        pageName: "home",
        history,
      });
    }
  }, [getPageReferrer, ref_id, slug, history]);
  // ENDS call with "[app] SET page referrer"

  const panels = [
    [
      {
        section: "business_flexibility",
        imgUrl: "url(/img/enterprise/img_bizflexibility.png)",
      },
      {
        section: "powur_project_management",
        imgUrl: "url(/img/enterprise/img_projectmgmt.png)",
      },
      {
        section: "close_your_deal",
        imgUrl: "url(/img/enterprise/img_offering-leads.png",
      },
    ],
    [
      {
        section: "accounting_simplified",
        imgUrl: "url(/img/enterprise/img_earnings_ipad.png)",
      },
      {
        section: "lead_generators",
        imgUrl: "url(/img/enterprise/img_leadgenerator.png)",
      },
      {
        section: "train_your_team",
        imgUrl: "url(/img/enterprise/img_trainyourteam.png)",
      },
    ],
  ];

  return (
    <Layout className={styles.pageLayout}>
      <Helmet>
        <title>Powur: The best solar organizations grow faster with Powur</title>
        <meta name="title" content="Powur: The Best Solar Organizations grow faster with Powur" />
        <meta
          name="description"
          content="Powur’s revolutionary software gives you control over how you and your team earns money, bonuses and incentives."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://powur.com/join" />
        <meta property="og:title" content="Powur: The Best Solar Organizations grow faster with Powur" />
        <meta
          property="og:description"
          content="Powur’s revolutionary software gives you control over how you and your team earns money, bonuses and incentives."
        />
        <meta property="og:image" content="/img/powur_fb_01.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://powur.com/join" />
        <meta property="twitter:title" content="Powur: The Best Solar Organizations grow faster with Powur" />
        <meta
          property="twitter:description"
          content="Powur’s revolutionary software gives you control over how you and your team earns money, bonuses and incentives."
        />
        <meta property="twitter:image" content="/img/powur_fb_01.jpg" />
      </Helmet>
      <ScrollToTop />
      <Header user={selectPageReferrer} />
      <Content className={styles.content}>
        <VideoBanner openModal={open} />
        <InfoPanels panels={panels[0]} />
        <Features />
        <InfoPanels panels={panels[1]} />
        <BuildYourTeam />
        <GetSignedUpEnterprise onJoinClick={open} />
        <Footer />
      </Content>
      <GetQuoteModal />
      <UtilityBillModal />
      <EnterpriseProSignupModal />
      <PricingAndPlansModal visible={visible} close={close} />
    </Layout>
  );
};

export default connect(
  getRdxSelectionMapper({
    selectPageReferrer: "selectPageReferrer",
  }),
  getRdxActionMapper(["getPageReferrer"]),
)(EnterpriseJoin);
