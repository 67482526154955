import React from "react";
import PropTypes from "prop-types";

const OrgSellerStar = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.03906" cy="9.71094" r="9.03906" fill={fill} />
      <path
        d="M8.81153 5.69213C8.88312 5.47182 9.1948 5.47182 9.26638 5.69213L10.0441 8.08565C10.0761 8.18418 10.1679 8.25089 10.2715 8.25089L12.7882 8.25089C13.0199 8.25089 13.1162 8.54732 12.9288 8.68348L10.8927 10.1628C10.8089 10.2236 10.7738 10.3316 10.8058 10.4301L11.5835 12.8236C11.6551 13.0439 11.403 13.2271 11.2156 13.091L9.17952 11.6117C9.0957 11.5508 8.98221 11.5508 8.8984 11.6117L6.86235 13.091C6.67494 13.2271 6.42279 13.0439 6.49437 12.8236L7.27207 10.4301C7.30408 10.3316 7.26901 10.2236 7.1852 10.1628L5.14915 8.68348C4.96175 8.54732 5.05806 8.25089 5.28971 8.25089L7.8064 8.25089C7.91 8.25089 8.00182 8.18418 8.03383 8.08565L8.81153 5.69213Z"
        fill="white"
      />
    </svg>
  );
};

OrgSellerStar.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

OrgSellerStar.defaultProps = {
  fill: "var(--eggplant)",
  height: "19",
  width: "19",
};

export default OrgSellerStar;
