import React from "react";
import { theme } from "styles/themes";

type ListIconPropsT = {
  width?: string | number;
  height?: string | number;
  fill?: string;
  backgroundFill?: string;
};

const ListIcon = ({
  width = "48",
  height = "24",
  fill = theme.colors["graham-grey"],
  backgroundFill = "transparent",
}: ListIconPropsT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} rx="2" fill={backgroundFill} />
      <rect x="14.5" y="4.79492" width="19" height="2" rx="1" fill={fill} />
      <rect x="14.5" y="8.93164" width="19" height="2" rx="1" fill={fill} />
      <rect x="14.5" y="13.0684" width="19" height="2" rx="1" fill={fill} />
      <rect x="14.5" y="17.2051" width="19" height="2" rx="1" fill={fill} />
    </svg>
  );
};

export default ListIcon;
