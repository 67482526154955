import types from "rdx/modules/viewedAssets/types";
import createAction from "rdx/utils/createAction";

export default {
  getViewedAssetsTaskCompletion: (payload) => createAction(types.GET_VIEWED_ASSETS_TASKS_COMPLETION, payload),
  setViewedAssetsTaskCompletion: (payload) => createAction(types.SET_VIEWED_ASSETS_TASKS_TO_STATE, payload),
  markTaskComplete: (payload) => createAction(types.MARK_TASK_AS_COMPLETE, payload),
  setTaskToComplete: (payload) => createAction(types.SET_VIEWED_ASSETS_TASK_TO_COMPLETE, payload),

  // INSERTION_PT (for script, do not remove)
};
