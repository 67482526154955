import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ZipButton, ZipInput } from "components/HomepageComponents";
import styles from "../../Home.module.less";

const InfoPanels = () => {
  const { t, i18n } = useTranslation(["home", "form_labels"]);
  const [zip, setZip] = useState("");
  const urlPrefix = process.env.REACT_APP_S3_URL_PREFIX;

  return (
    <div className={styles.infoPanels}>
      <div className={styles.infoPanel}>
        <div className={[styles.infoImage, styles.bgDarkenZoomOnHover].join(" ")}>
          <div
            className={styles.bg}
            style={{
              backgroundImage: `url(${urlPrefix}/assets/img/home/2021-Footage-Reel.00_01_39_14.Still002.jpg`,
            }}
          />
        </div>
        <div className={styles.panel}>
          <div className={styles.innerContainer}>
            <h4 className={styles.title}>{t("info_panels.powur_difference")}</h4>
            <h3 className={styles.subheading}>{t("info_panels.solution")}</h3>
            <ul className={styles.infoBullets}>
              {t("info_panels.info_bullets", { returnObjects: true }).map((str, idx) => (
                <li key={idx}>{str}</li> // eslint-disable-line
              ))}
            </ul>
            <div className={styles.logos}>
              <div className={styles.bbbLogo} />
              <div className={styles.bCorpLogo} />
              <div className={styles.incLogo} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.infoPanel}>
        <div className={styles.panel}>
          <div className={styles.innerContainer}>
            <h4 className={styles.title}>{t("info_panels.powur_process")}</h4>
            <h3 className={styles.subheading}>{t("info_panels.simple_process")}</h3>
            <ul className={styles.stepBullets}>
              {t("info_panels.step_bullets", { returnObjects: true }).map((str, idx) => (
                // eslint-disable-next-line
                <li className={styles[i18n.language]} key={idx}>
                  {str}
                </li>
              ))}
            </ul>
            <div className={styles.zipInput}>
              <ZipInput zip={zip} setZip={setZip} className={styles.input} />
              <ZipButton className={styles.zipButton} type="primary" zip={zip}>
                {t("form_labels:check_savings")}
              </ZipButton>
            </div>
          </div>
        </div>
        <div className={[styles.infoImage, styles.bgDarkenZoomOnHover].join(" ")}>
          <div
            className={styles.bg}
            style={{
              backgroundImage: `url(${urlPrefix}/assets/img/home/home_ConsultantWithHomeowner.jpg)`,
            }}
          />
        </div>
      </div>
      <div className={styles.infoPanel}>
        <div className={[styles.infoImage, styles.bgDarkenZoomOnHover].join(" ")}>
          <div
            className={styles.bg}
            style={{
              backgroundImage: `url(${urlPrefix}/assets/img/home/Brunasso_selects.00_01_41_13.Still005.jpg`,
            }}
          />
        </div>
        <div className={styles.panel}>
          <div className={styles.innerContainer}>
            <h4 className={styles.title}>{t("info_panels.the_powur_promise")}&trade;</h4>
            <h3 className={styles.subheading}>{t("info_panels.this_is_our_promise")}</h3>
            <ul className={styles.infoBullets}>
              {t("info_panels.promise_bullets", { returnObjects: true }).map((str, idx) => (
                <li key={idx}>{str}</li> // eslint-disable-line
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPanels;
