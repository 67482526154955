const learnMoreData = {
  header: "Discover the Powur Platform benefits",
  desc: "With Powur, you are in the driver’s seat. Learn more about a flexible way to earn, grow, and build a thriving business - while helping homeowners make the switch to clean, affordable solar energy.",
  tasks: [
    {
      title: "Welcome from our CEO",
      slug: "welcome_video",
      video: {
        en: "https://vimeo.com/1063656018/2a3ff277f2",
        es: "https://vimeo.com/1065562943/2f6991a6cc",
      },
      hasVideo: true,
      complete: false,
      header: "Welcome from our CEO",
      powerTip: {
        span: "Our CEO, Jonathan Budd",
        p: "founded Powur after a visit to the Amazon. His vision was to create the largest virtual platform for clean energy in the world. We are just getting started.",
      },
    },
    {
      title: "Earn the most competitive commissions in the industry",
      slug: "competitive_commissions",
      header: "Earn the most competitive commissions in the industry",
      hasVideo: false,
      complete: false,
      items: [
        {
          isVideo: false,
          image: "Slide01Row01",
          header: "COGS+ Model",
          desc: "Powur's game-changing COGS+ model combines the predictability of a traditional redline model with transparency and competitive pricing to bring you total control of your leads and projects while earning the biggest commissions in the solar industry.",
        },
        {
          isVideo: false,
          image: "Slide01Row02",
          header: "Fast Pay",
          desc: "Fast Pay puts money in your pocket sooner with advanced pay for every qualified and confirmed proposal presentation appointment you set - so you can start earning right away and focus on creating volume and maximizing your impact.",
        },
        {
          isVideo: false,
          image: "Slide01Row03",
          header: "Commissions on Personal and Partnered Sales",
          desc: "If you choose to become a Sales Pro, you can close your own deals and earn up to 80% of the project margin on your sales. Become a Closer to access and close leads from Setters and split commissions with them 50/50.",
        },
        {
          isVideo: false,
          image: "Slide01Row04",
          header: "Team Revenue Share",
          desc: "Referring other sellers to the platform and building a team of solar professionals is a core feature of the Powur platform model. When you invite others, you’ll earn passive income in the form of revenue share on each deal sold by someone on your team. It’s a great reason to share the message of Powur and how it can change people’s lives - just like it will change yours!",
        },
      ],
    },
    {
      title: "Leverage Powur’s customizable solutions to attract homeowners and seal the deal",
      slug: "customizable_solutions",
      header: "Leverage Powur’s customizable solutions to attract homeowners and seal the deal",
      hasVideo: false,
      complete: false,
      items: [
        {
          isVideo: false,
          image: "Slide02Row01",
          header: "Flexible Product Options",
          desc: "You’re not tied to one suite of products or equipment with Powur. Our platform offers industry-leading options for panels, inverters, storage, and racking to build the right system to meet any customer’s unique needs. You’ll benefit from our buying power and growing base of sellers to get the best value and competitive pricing for your customers on products and equipment.",
        },
        {
          isVideo: false,
          image: "Slide02Row02",
          header: "Multiple Financing Options",
          desc: "With Powur’s top-tier financing partners and diverse payment options, you can help homeowners find the perfect fit for their homes and budget. From flexible loans to TPO solutions, we’ve got the right tools to make going solar a no-brainer.",
        },
        {
          isVideo: false,
          image: "Slide02Row03",
          header: "Certified Local Labor Partners",
          desc: "We only work with the best, most experienced local installers and ensure they are trained and certified. At Powur, we hold our installers to the excellence standards of the Powur platform, so you can rest assured the local labor we contract will provide your customers with high-quality installations.",
        },
        {
          isVideo: false,
          image: "Slide02Row04",
          header: "Powerful Proposal Design Tool",
          desc: "With Powur’s proprietary Vision design tool, you can create fully customized solar proposals tailored to each homeowner’s unique energy needs. Just pinpoint their home on Google Maps, input their rate, usage, and details, and generate a professional, personalized presentation in minutes.",
        },
      ],
    },
    {
      title: "Level up as a solar professional",
      slug: "solar_professional",
      header: "Level up as a solar professional",
      hasVideo: false,
      complete: false,
      items: [
        {
          isVideo: false,
          image: "Slide03Row01",
          header: "Training & Certification",
          desc: "We provide top-tier solar training to help you sell with confidence and expertise. Become a trusted solar expert, ready to guide homeowners toward the smartest choice - going solar with Powur.",
        },
        {
          isVideo: false,
          image: "Slide03Row02",
          header: "Knowledge Base",
          desc: "Tap into our information-packed knowledge base for answers to almost any question about solar, the Powur platform, and sales - everything you need to know, all in one place.",
        },
        {
          isVideo: false,
          image: "Slide03Row03",
          header: "Community & Events",
          desc: "Join thousands of Powur sellers around the country who are achieving financial freedom on the Powur platform by bringing renewable energy and lower utility bills to homeowners. You’ll have access to our supportive community, join our collaborative culture, and have access to Powur events to keep you motivated and supported.",
        },
        {
          isVideo: false,
          image: "Slide03Row04",
          header: "Powur Play Video Platform",
          desc: "Powur Play is your go-to video training hub, packed with insights from top performers and industry leaders. Search for expert tips, event recaps, and game-changing strategies to level up your success.",
        },
      ],
    },
    {
      title: "Utilize powerful platform tools to nurture your solar leads",
      slug: "platform_tools",
      header: "Utilize powerful platform tools to nurture your solar leads",
      hasVideo: false,
      complete: false,
      items: [
        {
          isVideo: false,
          image: "Slide04Row01",
          header: "Built-in CRM ",
          desc: "Track your leads, schedule follow-up reminders, send emails using preset templates, and make the most of platform features designed to enhance communication, boost sales, and drive growth.",
        },
        {
          isVideo: false,
          image: "Slide04Row02",
          header: "Project Progress Tracking",
          desc: "Stay in the loop as your leads go solar. The project page lets you track every step, from contract signing to installation, so you always know where your deals stand.",
        },
        {
          isVideo: false,
          image: "Slide04Row03",
          header: "Marketing Center",
          desc: "Choose from a dynamic and expanding library of marketing tools tailored for social media, digital campaigns, canvassing, trade shows, direct mail, and face-to-face interactions. With the Powur platform, simply plug and play with professionally crafted, on-brand marketing messages that make an impact.",
        },
        {
          isVideo: false,
          image: "Slide04Row04",
          header: "Personalized Links",
          desc: "Share impactful marketing links with homeowners that seamlessly connect to your back office. The leads you generate from your personal pages will automatically flow directly to your account.",
        },
      ],
    },
  ],
  colOneButton: "Get Started",
  buttonLabel: "Learn more about our Account Types",
};

export default learnMoreData;
