import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const ProjectAlertTriangle = ({ height = "14px", width = "15px", fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.97986 9.74732C7.96616 9.70959 7.94801 9.67367 7.92602 9.64002C7.90223 9.60662 7.87604 9.57489 7.84768 9.5451C7.70157 9.3997 7.49274 9.33626 7.29039 9.37579C7.24942 9.38445 7.20941 9.39682 7.17072 9.41292C7.13431 9.42674 7.0997 9.44477 7.0675 9.46675C7.03326 9.48958 7.00153 9.51578 6.97258 9.5451C6.91442 9.60289 6.86816 9.67162 6.83656 9.74732C6.8231 9.78505 6.81205 9.82374 6.80351 9.86291C6.7951 9.90232 6.7909 9.94246 6.79102 9.98271C6.7921 10.1899 6.89555 10.3831 7.0675 10.4987C7.09971 10.5204 7.13443 10.5384 7.17072 10.5522C7.20941 10.5682 7.24942 10.5807 7.2904 10.5893C7.36934 10.606 7.45093 10.606 7.52987 10.5893C7.56964 10.5813 7.60846 10.5688 7.64558 10.5522C7.68319 10.5384 7.71912 10.5204 7.75277 10.4987C7.78617 10.4747 7.81789 10.4485 7.84769 10.4202C7.96388 10.3042 8.02925 10.1468 8.02925 9.98268C8.02937 9.90158 8.01254 9.82143 7.97986 9.7473L7.97986 9.74732Z"
        fill={fill}
      />
      <path
        d="M7.30763 4.52794C7.08928 4.55443 6.89045 4.6666 6.7547 4.83965C6.61903 5.01282 6.55768 5.23271 6.58416 5.45109L6.96189 8.56781C6.97962 8.71426 7.06818 8.84273 7.19881 8.91127C7.32944 8.97982 7.48548 8.97982 7.61612 8.91127C7.74674 8.84272 7.83531 8.71425 7.85304 8.56781L8.23077 5.45109C8.23844 5.38481 8.23844 5.31782 8.23077 5.25155C8.20429 5.0332 8.09223 4.83425 7.91907 4.69862C7.74602 4.56296 7.52609 4.5016 7.30762 4.52796L7.30763 4.52794Z"
        fill={fill}
      />
      <path
        d="M14.2478 10.8922L8.66257 1.03586C8.49432 0.738776 8.22601 0.511092 7.90541 0.393498C7.58496 0.275815 7.23297 0.275695 6.91253 0.393378C6.59196 0.510942 6.32364 0.738644 6.15524 1.03562L0.570047 10.8923C0.317301 11.3384 0.320657 11.8851 0.578795 12.328C0.836935 12.771 1.31102 13.0435 1.82371 13.0435H12.9944C13.5071 13.0435 13.9812 12.7709 14.2393 12.328C14.4974 11.8851 14.5008 11.3384 14.248 10.8923L14.2478 10.8922ZM13.1721 11.7061C13.1361 11.7704 13.0678 11.8097 12.9941 11.8084H1.82343C1.75021 11.8084 1.6825 11.7695 1.64558 11.7063C1.60867 11.643 1.60819 11.565 1.64427 11.5013L7.22976 1.64458C7.26631 1.57999 7.33475 1.5402 7.40881 1.5402C7.48299 1.54032 7.55142 1.58023 7.58785 1.6447L13.173 11.5014H13.1732C13.2106 11.5647 13.2101 11.6434 13.172 11.7062L13.1721 11.7061Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProjectAlertTriangle;
