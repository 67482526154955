import React from "react";
import PropTypes from "prop-types";

const WarningIcon = (props) => {
  const { width, height, startFill, stopFill } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1549 15.3619C12.1338 15.3034 12.1058 15.2478 12.0718 15.1956C12.0351 15.1439 11.9947 15.0947 11.9509 15.0486C11.7254 14.8233 11.4031 14.725 11.0908 14.7862C11.0276 14.7996 10.9658 14.8188 10.9061 14.8438C10.8499 14.8652 10.7965 14.8931 10.7468 14.9272C10.694 14.9625 10.645 15.0031 10.6003 15.0486C10.5106 15.1381 10.4392 15.2446 10.3904 15.3619C10.3696 15.4204 10.3526 15.4803 10.3394 15.541C10.3264 15.6021 10.3199 15.6643 10.3201 15.7266C10.3218 16.0476 10.4815 16.347 10.7468 16.5261C10.7965 16.5598 10.8501 16.5877 10.9061 16.6091C10.9659 16.6339 11.0276 16.6532 11.0908 16.6665C11.2127 16.6923 11.3386 16.6923 11.4604 16.6665C11.5218 16.6542 11.5817 16.6348 11.639 16.6091C11.6971 16.5877 11.7525 16.5598 11.8044 16.5261C11.856 16.489 11.9049 16.4484 11.9509 16.4045C12.1302 16.2248 12.2311 15.9809 12.2311 15.7266C12.2313 15.6009 12.2054 15.4767 12.1549 15.3619L12.1549 15.3619Z"
        fill="url(#paint0_linear_804_3)"
      />
      <path
        d="M11.1163 7.27806C10.7793 7.31907 10.4724 7.49276 10.2629 7.76071C10.0536 8.02885 9.95886 8.36934 9.99973 8.70748L10.5827 13.5335C10.6101 13.7602 10.7467 13.9592 10.9483 14.0653C11.1499 14.1714 11.3908 14.1714 11.5924 14.0653C11.794 13.9592 11.9307 13.7602 11.958 13.5335L12.541 8.70748C12.5528 8.60486 12.5528 8.50113 12.541 8.39851C12.5001 8.06041 12.3272 7.75235 12.0599 7.54233C11.7929 7.33227 11.4534 7.23726 11.1163 7.27809L11.1163 7.27806Z"
        fill="url(#paint1_linear_804_3)"
      />
      <path
        d="M21.3982 16.3933L12.7784 1.13141C12.5188 0.671397 12.1047 0.318846 11.6099 0.13676C11.1153 -0.0454631 10.5721 -0.0456484 10.0775 0.136575C9.58278 0.318613 9.16867 0.671193 8.90878 1.13104L0.288965 16.3934C-0.101107 17.084 -0.0959268 17.9306 0.302467 18.6164C0.700863 19.3023 1.43254 19.7242 2.22378 19.7242H19.4639C20.2551 19.7242 20.9868 19.3023 21.3852 18.6164C21.7836 17.9306 21.7888 17.084 21.3987 16.3934L21.3982 16.3933ZM19.7381 17.6534C19.6826 17.7531 19.5772 17.8139 19.4634 17.8119H2.22335C2.11035 17.8119 2.00584 17.7516 1.94888 17.6538C1.89191 17.5558 1.89117 17.435 1.94684 17.3363L10.5671 2.07397C10.6235 1.97394 10.7291 1.91234 10.8434 1.91234C10.9579 1.91253 11.0635 1.97432 11.1198 2.07415L19.7396 17.3365H19.7398C19.7975 17.4344 19.7967 17.5564 19.7379 17.6536L19.7381 17.6534Z"
        fill="url(#paint2_linear_804_3)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_804_3"
          x1="10.6554"
          y1="13.9694"
          x2="12.3427"
          y2="14.1374"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startFill} />
          <stop offset="1" stopColor={stopFill} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_804_3"
          x1="10.4394"
          y1="4.40377"
          x2="12.7185"
          y2="4.48851"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startFill} />
          <stop offset="1" stopColor={stopFill} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_804_3"
          x1="3.80485"
          y1="-8.21844"
          x2="22.9128"
          y2="-6.11979"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startFill} />
          <stop offset="1" stopColor={stopFill} />
        </linearGradient>
      </defs>
    </svg>
  );
};

WarningIcon.propTypes = {
  startFill: PropTypes.string,
  stopFill: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

WarningIcon.defaultProps = {
  startFill: "var(--picton-blue)",
  stopFill: "var(--blue-ribbon)",
  width: "22",
  height: "20",
};

export default WarningIcon;
