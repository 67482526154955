import DOMPurify from "dompurify";

const convertHtmlToPlainText = (htmlOrString: string): string => {
  const tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = DOMPurify.sanitize(htmlOrString);
  const elementArray = Array.from(tempDivElement.children);
  if (!elementArray.length) {
    return htmlOrString;
  }

  const elementsWithContent = elementArray.filter((element) => !!element?.textContent);
  const textContentArray = elementsWithContent.map((element) => element.textContent);

  return textContentArray.join(" ");
};

export default convertHtmlToPlainText;
