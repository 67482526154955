import types from "rdx/modules/fundingProposals/types";
import createAction from "rdx/utils/createAction";
import {
  GetAdminListPayloadT,
  FundingAdminListT,
  FundingProposalDataT,
  FundingProposalUpdatePayloadT,
} from "types/Project/fundingProposal";

export default {
  createFundingProposal: (payload: FundingProposalDataT) =>
    createAction(types.CREATE_FUNDING_PROPOSAL_REQUEST, payload),
  getAdminFundingProposals: (payload: GetAdminListPayloadT) => createAction(types.GET_ADMIN_FUNDING_PROPOSALS, payload),
  setAdminFundingProposals: (payload: FundingAdminListT) => createAction(types.SET_ADMIN_FUNDING_PROPOSALS, payload),
  updateFundingProposal: (payload: FundingProposalUpdatePayloadT) =>
    createAction(types.UPDATE_FUNDING_PROPOSAL_REQUEST, payload),
  getFundingUsersAutocomplete: (payload: string) => createAction(types.GET_FUNDING_USERS_AUTOCOMPLETE, payload),
  setFundingUsersAutocomplete: (payload) => createAction(types.SET_FUNDING_USERS_AUTOCOMPLETE, payload),
  // INSERTION_PT (for script, do not remove)
};
