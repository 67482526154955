import React from "react";
import SubHeaderFilled from "components/PlatformLayout/components/SubHeaderFilled";
import { RightCaretIcon } from "components/Icons";

import * as S from "./styles";

export type ArticleDataT = {
  key: string;
  title: string;
  detail: string;
  url: string;
};

type KnowledgeBaseArticlesPropsT = {
  articleData: ArticleDataT[];
};

const KnowledgeBaseArticles = ({ articleData }: KnowledgeBaseArticlesPropsT) => {
  const handleOpenLink = (url: string): void => {
    window.open(url, "_blank");
  };

  return (
    <S.KnowledgeBaseArticlesContainer>
      <SubHeaderFilled title="Powur Knowledge Base Articles" />
      <S.ArticleDataContainer>
        {articleData.map((data, idx) => (
          <S.ArticleDataCard key={data.key}>
            <S.ArticleTitle>{data.title}</S.ArticleTitle>
            <S.ArticleDetail>{data.detail}</S.ArticleDetail>
            <S.ArticleLink onClick={() => handleOpenLink(data.url)}>
              Learn More
              <S.IconContainer>
                <RightCaretIcon />
              </S.IconContainer>
            </S.ArticleLink>
            {idx < articleData.length - 1 && <S.Divider />}
          </S.ArticleDataCard>
        ))}
      </S.ArticleDataContainer>
    </S.KnowledgeBaseArticlesContainer>
  );
};

export default KnowledgeBaseArticles;
