import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/projects/messages";

function* postSellerChangeOrder(action) {
  const { projectId } = action.payload;

  const { success, error } = yield* makeRequest.post(`/change_orders/${projectId}/seller_change_order`);

  if (success) {
    yield put(actions.newSuccessEvent({ message: messages.POST_SELLER_CHANGE_ORDER_SUCCESS }));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_POSTING_SELLER_CHANGE_ORDER,
    });
  }

  return null;
}

export default postSellerChangeOrder;
