import React from "react";
import { IconT } from "types/utils";

const PdfIcon = ({
  width = 23,
  height = 28,
  startColor = "var(--picton-blue)",
  stopColor = "var(--blue-ribbon)",
}: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.1502 27.3227H19.4764C21.2161 27.3227 22.6266 25.9122 22.6266 24.1725V3.1502C22.6266 1.41053 21.2161 0 19.4764 0H3.1502C1.41053 0 0 1.41053 0 3.1502V24.1725C0 25.9122 1.41053 27.3227 3.1502 27.3227ZM1.70766 3.15049C1.70766 2.35516 2.35492 1.70796 3.1502 1.70796H19.4764C20.2718 1.70796 20.919 2.35521 20.919 3.15049V24.1728C20.919 24.9681 20.2717 25.6153 19.4764 25.6153H3.1502C2.35487 25.6153 1.70766 24.9681 1.70766 24.1728V3.15049ZM13.0678 11.0989C13.5629 10.6796 13.9212 10.0017 13.9212 9.01811C13.9212 8.11129 13.5862 7.47911 13.0525 7.08289C12.5574 6.70934 11.925 6.5338 10.9496 6.5338C10.3626 6.5338 9.80587 6.57173 9.37989 6.64053V11.6855C9.66882 11.7234 10.0957 11.7619 10.6978 11.7619C11.703 11.7619 12.5265 11.5484 13.0678 11.099L13.0678 11.0989ZM10.5452 7.47126C10.6437 7.44858 10.8116 7.42552 11.0707 7.42552C12.0692 7.42552 12.6937 7.98949 12.6865 9.05638C12.6865 10.2834 12 10.8626 10.9489 10.8549C10.8037 10.8549 10.6436 10.8549 10.545 10.8319L10.5452 7.47126ZM6.57281 9.8954C6.42013 9.8954 6.28323 9.88779 6.17656 9.87239L6.17675 11.7096H5.02617V6.64171C5.3839 6.58073 5.88669 6.53499 6.59586 6.53499C7.31171 6.53499 7.82285 6.6724 8.16515 6.94646C8.49354 7.20603 8.71423 7.63236 8.71423 8.13534C8.71423 8.63807 8.54652 9.06501 8.2412 9.35451C7.84536 9.72806 7.25836 9.8954 6.57281 9.8954ZM6.1762 7.45644C6.25987 7.43338 6.41996 7.4107 6.65648 7.4107C7.23528 7.4107 7.5633 7.69296 7.5633 8.16504C7.5633 8.69105 7.18251 9.00346 6.56481 9.00346C6.39747 9.00346 6.27587 8.99621 6.17639 8.97315L6.1762 7.45644ZM17.6205 9.64347H15.7762V11.7085H14.6103V6.57231H17.7493V7.5247H15.7762V8.69835H17.6205V9.64347ZM18.5708 16.5215H4.05571V18.2292H18.5708V16.5215ZM4.05571 19.0415H18.5708V20.7492H4.05571V19.0415ZM18.5708 21.5615H4.05571V23.2692H18.5708V21.5615Z"
        fill="url(#paint0_linear_51_10852)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_51_10852"
          x1="11.1365"
          y1="40.0931"
          x2="-8.46903"
          y2="39.9415"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PdfIcon;
