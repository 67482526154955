import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/publicApiProxy/messages";
import { camelizeKeys } from "lib/helpers";

function* createAppClientToken(action) {
  // CreateAppClientTokenActionParams
  const { userId } = action.payload;

  const params = {
    user_id: userId,
  };

  const { success, data, error } = yield* makeRequest.post("/public_api_proxy", params);

  if (success && data) {
    yield all([
      put(actions.setAppClientToken(camelizeKeys(data.body.token))),
      put(
        actions.newSuccessEvent({
          message: messages.CREATING_APPCLIENT_TOKEN_SUCCESS,
        }),
      ),
    ]);
  } else {
    const errorMessage = messages.ERROR_CREATING_APPCLIENT_TOKEN;
    return getErrorActions({
      error,
      message: errorMessage,
    });
  }

  return null;
}

export default createAppClientToken;
