import React from "react";
import type { IconT } from "types/utils";
import { cvar } from "styles";

const HandshakeIcon = ({ fill = cvar("white"), width = 23, height = 23, ...rest }: IconT) => (
  <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M12.118 16.4219L10.3101 17.939C9.73993 18.4175 9.66554 19.2675 10.144 19.8376C10.6224 20.4078 11.4724 20.4822 12.0426 20.0037L13.8506 18.4867"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8506 18.4867L13.075 19.1375C12.5048 19.6159 12.4304 20.466 12.9088 21.0362C13.3873 21.6063 14.2373 21.6807 14.8075 21.2022L19.1855 17.5286H22.3262V10.3411H16.5872"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3856 14.3573L8.57758 15.8743C8.00743 16.3528 7.15742 16.2784 6.67896 15.7083C6.20054 15.1381 6.27493 14.2881 6.84508 13.8096L10.9786 10.3412"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3855 14.3572L8.57758 15.8743C8.00743 16.3528 7.93308 17.2028 8.41146 17.773C8.88992 18.3431 9.73993 18.4175 10.3101 17.939L12.118 16.422"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.6262 17.9778H5.92972L4.13284 16.1809H0.673859V8.99341H6.10941L6.53603 8.56679C7.12576 7.97705 7.92555 7.64575 8.75953 7.64575H12.9247C13.5204 7.64575 14.0917 7.88245 14.5129 8.30363L16.7507 10.5046C17.277 11.0308 17.277 11.8842 16.7507 12.4104C16.2244 12.9367 15.3711 12.9367 14.8449 12.4104L12.7755 10.3411H10.9786"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 1.48229V3.70776"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6445 4.85156L16.4414 3.05469"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.35547 4.85156L6.55859 3.05469"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HandshakeIcon;
