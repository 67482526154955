import { select, put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import selectors from "rdx/selectors";

import { cloneDeep } from "lodash";

import { camelizeKeys } from "lib/helpers";
import actions from "../actions";
import messages from "../messages";

function* getAdminRoofingProposals(action) {
  const { requestFreshData, isAdmin } = action.payload;

  const url = isAdmin ? "/admin_roofing_proposals" : "/admin_roofing_proposals/work_queue";
  const paging = yield select(selectors.selectRoofingProposalsPaging);
  const existingList = yield select(selectors.selectRoofingProposals);

  const params = {
    limit: 25,
    ...action.payload,
  };

  if (!requestFreshData) {
    params.p = paging.pageNumber + 1;
  }

  const { success, data, error } = yield* makeRequest.get(url, params);

  if (success && data) {
    const list = cloneDeep(existingList).concat(data.body.roofing_proposals);

    const out = requestFreshData ? data.body.roofing_proposals : list;

    yield put(actions.setRoofingProposalData(camelizeKeys({ ...data.body, roofingProposals: out })));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ROOFING_PROPOSALS,
    });
  }
  return null;
}

export default getAdminRoofingProposals;
