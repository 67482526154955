export default {
  // general
  META_FIELD_UPDATE_SUCCESS: "Data field updated successfully",
  META_FIELD_DELETE_SUCCESS: "Data field deleted",
  DATA_GROUP_CREATE_SUCCESS: "Data group created successfully",
  DATA_GROUP_UPDATE_SUCCESS: "Data group updated successfully",
  WORKFLOW_UPDATE_SUCCESS: "Workflow updated successfully",
  PHASE_UPDATE_SUCCESS: "Phase updated successfully",
  MILESTONE_UPDATE_SUCCESS: "Milestone updated successfully",
  MILESTONE_CREATE_SUCCESS: "Milestone created successfully",
  WARNING_REASSIGN_DATA_GROUP_TO_NEW_MILESTONE:
    "Attempted to assign a data group previously associated with another milestone",
  WARNING_REASSIGN_DATA_GROUP_TO_NEW_MILESTONE_ON_CREATE:
    "Attempted to assign a data group previously associated with another milestone",

  // errors
  ERROR_GETTING_DATA_GROUPS: "There was an error fetching data groups",
  ERROR_UPDATING_DATA_GROUPS: "There was an error updating data group",
  ERROR_CREATING_DATA_GROUP: "There was an error creating new data group",
  ERROR_DELETING_DATA_GROUP: "There was an error deleting data group",

  ERROR_CREATING_META_FIELD: "There was an error creating new data group field",
  ERROR_UPDATING_META_FIELD: "There was an error updating a data group field",
  ERROR_DELETING_META_FIELD: "There was an error deleting data group field",

  ERROR_CREATING_WORKFLOW: "There was an error creating new workflow",
  ERROR_GETTING_WORKFLOWS: "There was an error getting workflows",
  ERROR_GETTING_WORKFLOW_DETAILS: "There was an error getting workflow",
  ERROR_UPDATING_WORKFLOW: "There was an error updating workflow",
  ERROR_COPYING_WORKFLOW: "There was an error duplicating workflow",
  ERROR_DELETING_WORKFLOW: "There was an error deleting workflow",

  ERROR_CREATING_PHASE: "There was an error creating new phase",
  ERROR_UPDATING_PHASE: "There was an error updating phase",
  ERROR_UPDATING_PHASE_RANKS: "There was an error updating phase ranks",
  ERROR_DELETING_WORKFLOW_PHASE: "There was an error removing phase from workflow",

  ERROR_CREATING_MILESTONE: "There was an error creating milestone",
  ERROR_UPDATING_MILESTONE: "There was an error updating milestone",
  ERROR_UPDATING_MILESTONE_RANKS: "There was an error updating milestone ranks",
  ERROR_REORDERING_MILESTONE_DATA_GROUPS: "There was an error updating milestone's data groups",
};
