import React, { useContext, useMemo } from "react";
// import PropTypes from "prop-types";
import { Button, Badge } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import LogoutModal from "components/Modals/Logout/LogoutModal";
import useModalControls from "hooks/useModalControls";
import SessionClient from "util/SessionClient";
import { AmbassadorDashboardContext as context } from "containers/Ambassador/dashboard";

import "../../override.less";

const Header = () => {
  const {
    history: { push },
    navigate,
    tabKeys,
    styles,
    user,
    leads,
  } = useContext(context);
  const session = new SessionClient();
  const { visible, open, close } = useModalControls();

  const handlePayoutsClick = () => {
    navigate({ tab: tabKeys[2] });
  };

  const handleLogoutButtonClick = () => {
    open();
  };

  const handleLogout = () => {
    close();
    setTimeout(() => {
      if (session.user) session.user.clearStore();
      session.clear();
      push("/login");
    }, 200);
  };

  const installs = useMemo(() => leads.filter((lead) => lead.progress[4].past).length, [leads]);

  return (
    <div className={styles.headerButtons}>
      {user.props?.stripeOauthId ? (
        <Button className={styles.headerButton} onClick={handlePayoutsClick}>
          Payouts
        </Button>
      ) : (
        <Badge count={installs} offset={[-12, 3]}>
          <Button className={styles.headerButton} onClick={handlePayoutsClick}>
            Payouts
          </Button>
        </Badge>
      )}
      <div className={styles.divider}> | </div>
      <Button className={styles.headerButton} onClick={handleLogoutButtonClick}>
        Logout <LogoutOutlined />
      </Button>
      <LogoutModal visible={visible} closeModal={close} onSubmit={handleLogout} />
    </div>
  );
};

export default Header;
