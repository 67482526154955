import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase, keysToSnakeCase } from "lib/helpers";
import messages from "../messages";

type CreateLeadAssetPropsT = {
  payload: {
    leadId: number;
    assetType: string;
    fileUrl: string;
  };
};

function* createLeadAsset(action: CreateLeadAssetPropsT) {
  const { leadId, assetType, fileUrl } = action.payload;
  const params = keysToSnakeCase({
    assetType,
    fileUrl,
  });
  const { success, data, error } = yield* makeRequest.post(`/leads/${leadId}/assets`, params);
  if (success && data) {
    yield put(actions.setLeadAssets(data.body.assets.map(keysToCamelCase)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_LEAD_ASSET,
    });
  }
  return null;
}

export default createLeadAsset;
