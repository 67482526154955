import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/mentorRatings/types";
import updateMentorRating from "./updateMentorRating";
// IMPORT_PT (for script, do not remove)

function* watchMentorRatingsSagas() {
  yield trackRequests(takeEvery, types.UPDATE_MENTOR_RATING, updateMentorRating);
  // INSERTION_PT (for script, do not remove)
}

export default watchMentorRatingsSagas;
