import React from "react";
import PropTypes from "prop-types";

const LifetimeTotalInstalls = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4701 10.4724L13.7852 14.031H18.4555L17.5158 10.4724H13.4701ZM13.4701 10.4724H8.95447L8.6393 14.031H13.7795L14.1921 18.7357H8.23244L7.79693 23.6754H14.6276L14.1978 18.7357L13.4701 10.4724ZM19.4068 10.3807L20.3237 9.716L17.51 7.65878L18.5931 4.31793H15.1032L14.0259 1L11.2065 3.05723L8.38716 1L7.31557 4.31793H3.82573L4.90878 7.65304L2.08941 9.70454L3.00628 10.375M14.7823 25.4002L14.9485 27.2913L18.8166 28.0649L18.9999 29.1135H3.41887L3.60224 28.0649L7.47602 27.2913L7.63647 25.4002H14.7823ZM3.97472 14.031L2.73122 18.7357L3.97472 14.031ZM8.22671 18.7357L8.6393 14.031L8.22671 18.7357ZM8.23244 18.7357H2.72549L1.41895 23.6754H7.79693L8.23244 18.7357ZM8.95447 10.4724H4.90878L3.96899 14.031H8.6393L8.95447 10.4724ZM19.699 18.7357L18.4555 14.031L19.699 18.7357ZM14.6276 23.6754H20.9999L19.699 18.7357H14.1921L14.6276 23.6754ZM7.48748 8.74183C7.60782 8.28913 7.81412 7.85934 8.1121 7.45248C8.73099 6.59292 9.56763 6.07717 10.6106 5.91099C11.6535 5.74481 12.6048 5.97402 13.4643 6.59864C14.2207 7.14876 14.7078 7.86507 14.9256 8.74183H7.48748Z"
        stroke={fill}
        strokeWidth="1.35"
        strokeMiterlimit="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

LifetimeTotalInstalls.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

LifetimeTotalInstalls.defaultProps = {
  fill: "var(--eggplant)",
  width: "22",
  height: "30",
};

export default LifetimeTotalInstalls;
