import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/compensation/messages";
import { camelizeKeys } from "lib/helpers";

function* getRoofingCsv(action) {
  const params = {
    ...action.payload,
    csv: true,
  };
  const { success, data, error } = yield* makeRequest.get("/admin_roofing_proposals/csv", params);

  if (success && data) {
    yield put(actions.setRoofingCsv(camelizeKeys(data.body.csv)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ROOFING_CSV,
    });
  }

  return null;
}

export default getRoofingCsv;
