import React from "react";
import { IconT } from "types/utils";

const UploadIcon = ({ width = 34, height = 28, color = "#29AAE3" }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.6481 17.6445C21.7901 17.6445 21.9321 17.5895 22.0403 17.4812C22.2569 17.2647 22.2569 16.9132 22.0403 16.6967L17.0437 11.7C16.8271 11.4835 16.4757 11.4835 16.2591 11.7L11.2642 16.6967C11.0477 16.9132 11.0477 17.2647 11.2642 17.4812C11.4808 17.6978 11.8322 17.6978 12.0488 17.4812L16.0976 13.4324V26.5249C16.0976 26.832 16.3461 27.0805 16.6532 27.0805C16.9602 27.0805 17.2088 26.832 17.2088 26.5249V13.4324L21.2576 17.4812C21.3641 17.5913 21.5061 17.6445 21.6481 17.6445Z"
        fill={color}
      />
      <path
        d="M27.2749 7.32726C26.9678 3.24828 23.5154 0 19.3814 0C16.0799 0 13.112 2.08919 11.9654 5.14577C11.4524 4.99134 10.9199 4.91147 10.3767 4.91147C7.47814 4.91147 5.09962 7.17816 4.92212 10.0342C2.04304 10.7708 0 13.3712 0 16.3816C0 19.9973 2.9412 22.9385 6.55691 22.9385H13.3215C13.6286 22.9385 13.8771 22.69 13.8771 22.3829C13.8771 22.0759 13.6286 21.8274 13.3215 21.8274H6.55868C3.55536 21.8274 1.11116 19.3832 1.11116 16.3798C1.11116 13.751 2.98735 11.5003 5.57177 11.0264L6.0386 10.9412L6.02085 10.3785C6.02085 7.97692 7.97514 6.02262 10.3767 6.02262C10.9714 6.02262 11.5482 6.14155 12.0932 6.37408L12.6701 6.62081L12.8458 6.01908C13.6854 3.12935 16.3727 1.11116 19.3814 1.11116C23.0628 1.11116 26.1158 4.10738 26.185 7.79054L26.1939 8.24317L26.6394 8.32659C29.8593 8.92655 32.1952 11.7417 32.1952 15.0166C32.1952 18.4921 29.5842 21.4298 26.1318 21.8114L25.9259 21.8291H19.9831C19.676 21.8291 19.4275 22.0776 19.4275 22.3847C19.4275 22.6918 19.676 22.9403 19.9831 22.9403H26.2028L26.6448 22.8924C30.5303 22.2374 33.2496 18.8933 33.2496 15.0184C33.2496 11.353 30.7947 8.18814 27.2749 7.32726Z"
        fill={color}
      />
    </svg>
  );
};

export default UploadIcon;
