// React
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

// AntD
import { Button, Input, Select, Form } from "antd";
import { MailOutlined } from "@ant-design/icons";

// Util
import { useTranslation } from "react-i18next";
import SessionClient from "util/SessionClient";
import ApiClient from "util/ApiClient";
import * as _ from "lodash";
import actions from "rdx/actions";

// Components
import PhoneIcon from "components/Icons/PhoneIcons/phoneIcon";
import DownCaret from "components/Icons/Chevrons/DownCaretSimple";

import styles from "../../Installerjoin.module.less";

const JoinModal = (props) => {
  const { setSuccessVisible } = props;

  const { t, i18n } = useTranslation(["join", "form_labels", "translation"]);
  const dispatch = useDispatch();

  const { Option } = Select;
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const api = new ApiClient();
  const session = new SessionClient();

  const [form] = Form.useForm();

  const handleEmailAlreadyExists = (email) => {
    dispatch(
      actions.setAlertMessageVisible({
        message: t("translation:email_already_registered"),
        severity: "warning",
      }),
    );
    setTimeout(() => {
      history.push({ pathname: "/login", search: `?email=${email}` });
    }, 1000);
  };

  const submitRegistrationForm = async () => {
    if (!submitting) {
      try {
        setSubmitting(true);
        const values = await form.validateFields();
        session.clear();
        const params = _.mapKeys(values, (v, k) => _.snakeCase(k));
        params.sponsor = "powur.pbc";
        params.auth_format = "password";
        params.kind = "installer";
        api
          .post("/registrations", params)
          .then(() => {
            setSuccessVisible(true);
          })
          .catch((res) => {
            if (
              res.error.message === "A user already exists associated with this email address" &&
              res.error.field === "email"
            ) {
              handleEmailAlreadyExists();
            } else {
              dispatch(
                actions.setAlertMessageVisible({
                  message: `${res.error.message}`,
                  severity: "error",
                }),
              );
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      } catch (errorInfo) {
        setSubmitting(false);
      }
    }
  };

  const paragraph = t("modal.confirm_information_below");

  const headerText = t("installer.join_as_installer");

  return (
    <>
      <div className={styles.join}>
        <div className={styles.headerContainer}>
          <span className={styles.title}>{headerText}</span>
          <p className={styles.paragraph}>{paragraph}</p>
        </div>
        <div className={styles.subHeaderContainer}>
          <div className={styles.subHeader}>{t("join:installer.installer_information")}</div>
          <div className={styles.underline} />
        </div>
        <Form
          className={styles.formContainer}
          initialValues={{ locale: i18n.language || "en" }}
          form={form}
          validateTrigger="onSubmit"
        >
          <span className={styles.inputLabel}>{t("form_labels:first_name")}</span>
          <Form.Item
            name="first_name"
            rules={[
              {
                required: true,
                message: t("form_labels:first_name_required"),
              },
            ]}
          >
            <Input size="large" className={styles.input} name="first_name" />
          </Form.Item>

          <span className={styles.inputLabel}>{t("form_labels:last_name")}</span>
          <Form.Item
            name="last_name"
            rules={[
              {
                required: true,
                message: t("form_labels:last_name_required"),
              },
            ]}
            message={null}
          >
            <Input size="large" className={styles.input} name="last_name" />
          </Form.Item>

          <span className={styles.inputLabel}>{t("form_labels:email")}</span>
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: t("form_labels:valid_email"),
              },
              {
                required: true,
                message: t("form_labels:valid_email"),
              },
            ]}
          >
            <Input
              size="large"
              className={styles.iconInput}
              name="email"
              addonBefore={
                <div className={styles.inputPrefix}>
                  <MailOutlined
                    style={{
                      color: "var(--botticelli-blue)",
                      fontSize: "17px",
                    }}
                  />
                </div>
              }
            />
          </Form.Item>

          <span className={styles.inputLabel}>{t("form_labels:phone")}</span>
          <Form.Item
            name="phone"
            rules={[
              {
                pattern: /^[0-9.\-+]+$/,
                message: t("form_labels:valid_phone_number"),
              },
              {
                required: true,
                message: t("form_labels:valid_phone_number"),
              },
              {
                min: 7,
                message: t("form_labels:valid_phone_number"),
              },
            ]}
          >
            <Input
              size="large"
              className={styles.iconInput}
              name="phone"
              addonBefore={
                <div className={styles.inputPrefix}>
                  <PhoneIcon />
                </div>
              }
            />
          </Form.Item>

          <span className={styles.inputLabel}>{t("translation:language_preference")}</span>
          <Form.Item name="locale" rules={[{ required: true }]}>
            <Select className={styles.select} size="large" suffixIcon={<DownCaret />}>
              <Option value="en">{t("translation:english")}</Option>
              <Option value="es">{t("translation:spanish")}</Option>
            </Select>
          </Form.Item>
        </Form>

        <div className={styles.buttonContainer}>
          <Button onClick={submitRegistrationForm} className={styles.joinButton} disabled={submitting}>
            {t("form_labels:join")}
          </Button>
        </div>
      </div>
    </>
  );
};

JoinModal.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      user: PropTypes.string,
    }),
  }).isRequired,
  setSuccessVisible: PropTypes.func.isRequired,
};

export default JoinModal;
