import types from "rdx/modules/app/types";
import createAction from "rdx/utils/createAction";

export default {
  batchActions: (actions) => createAction(types.BATCH_ACTIONS, actions),
  fetchAutoCompleteSuggestions: (payload) => createAction(types.FETCH_AUTOCOMPLETE_SUGGESTIONS, payload),
  toggleProjectPeopleDrawerOpen: () => createAction(types.TOGGLE_PROJECT_PEOPLE_DRAWER),
  getZipLookupAddress: (payload) => createAction(types.GET_ZIP_LOOKUP_ADDRESS, payload),
  setZipLookupAddress: (payload) => createAction(types.SET_ZIP_LOOKUP_ADDRESS, payload),
  getPageReferrer: (payload) => createAction(types.GET_PAGE_REFERRER, payload),
  setPageReferrer: (payload) => createAction(types.SET_PAGE_REFERRER, payload),
  setDisplayZendeskWidget: (payload) => createAction(types.SET_DISPLAY_ZENDESK_WIDGET, payload),

  getRandomAddress: (payload) => createAction(types.GET_RANDOM_ADDRESS, payload),
  setRandomAddress: (payload) => createAction(types.SET_RANDOM_ADDRESS, payload),
  setShowDefaultM1OptionModal: (payload) => createAction(types.SET_SHOW_DEFAULT_M1_OPTION_MODAL, payload),
  // INSERTION_PT (for script, do not remove)
};
