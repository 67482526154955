import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToSnakeCase } from "lib/helpers";
import messages from "../messages";

function* updatePendingInvite(action) {
  const { inviteId } = action.payload;

  if (!inviteId) {
    throw new Error(`Invalid inviteId = ${inviteId} specified. Please check this parameter.`);
  }

  const params = keysToSnakeCase(action.payload);
  const { success, data, error } = yield* makeRequest.patch(`/registrations/${inviteId}/update_pending_invite`, params);

  if (success && data) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.SUCCESS_UPDATE_PENDING_INVITE,
          payload: data.body,
        }),
      ),
      put(actions.getTeamLeadsList()),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_PENDING_INVITE,
    });
  }
  return null;
}

export default updatePendingInvite;
