import { put, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import selectors from "rdx/selectors";
import { cloneDeep } from "lodash";
import messages from "../messages";

function* getLeads(action) {
  const { requestFreshData = true } = action.payload || {};
  const existingList = yield select(selectors.getLeads);

  const params = {
    sort: "newest",
    ...action.payload,
  };

  if (!requestFreshData) {
    params.p = existingList.paging.pageNumber + 1;
  }

  const { success, data, error } = yield* makeRequest.get("/leads", params);

  if (success && data) {
    const list = cloneDeep(existingList.leads).concat(data.body.leads.map((lead) => keysToCamelCase(lead)));
    const paging = keysToCamelCase(data.body.paging);
    const leads = requestFreshData ? data.body.leads.map((lead) => keysToCamelCase(lead)) : list;

    yield put(actions.setLeads({ ...data.body, paging, leads }));
  } else {
    return getErrorActions({ error, message: messages.ERROR_FETCHING_LEADS });
  }
  return null;
}

export default getLeads;
