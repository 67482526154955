import React from "react";
import styled from "@emotion/styled";
import SessionClient from "util/SessionClient";
import { AlertCopy } from "components/Typography";
import GradientButton from "components/Buttons/GradientButton";
import { RoofingCertificationIcon, TierTwoCertificationIcon } from "@icons";
import RightArrow2 from "components/Icons/Arrows/rightArrow2";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .gradientButton {
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    width: 287px;

    .courseTitle {
      font-weight: 700;
      font-size: 10px;
      text-transform: uppercase;
      text-align: left;
    }
    .viewLink {
      font-weight: 700;
      font-size: 14px;
      text-align: left;
    }
  }
`;

const session = new SessionClient();
const certifications = session.user?.props.certifications;
const showSalesProLink = !certifications?.tierTwo;

const courseSlug = showSalesProLink ? "tier_2" : "roofing";

const universityLinkContainer = (
  <Wrapper>
    <GradientButton onClick={() => session.user?.getLitmosLoginLink(courseSlug)} className="gradientButton">
      {showSalesProLink ? (
        <TierTwoCertificationIcon height={31} width={31} />
      ) : (
        <RoofingCertificationIcon height={31} width={31} />
      )}
      <div>
        <div className="courseTitle">{`${showSalesProLink ? "Sales Pro" : "Roofing"} Certification`}</div>
        <div className="viewLink">View Powur University Course</div>
      </div>
      <RightArrow2 fill="white" />
    </GradientButton>
  </Wrapper>
);

const reasonText = (
  <AlertCopy>
    You must complete the <b>{showSalesProLink ? "Sales Pro and Roofing Certification" : "Roofing Certification"}</b>{" "}
    before you can request a roof proposal.
  </AlertCopy>
);

export const modalState = (reason: "nil" | "certification_needed" | "out_of_service_area" | "both") => {
  switch (reason) {
    case "nil":
      return {
        title: "",
        content: "",
      };
    case "certification_needed":
      return {
        nonReqReason: "certification_needed",
        title: "Certification Needed",
        content: (
          <div>
            {reasonText}
            {universityLinkContainer}
          </div>
        ),
      };
    case "out_of_service_area":
      return {
        nonReqReason: "out_of_service_area",
        title: "Out Of Area",
        content: <AlertCopy>Your project must be in an area where we support roofing work.</AlertCopy>,
      };
    case "both":
      return {
        nonReqReason: "both",
        title: "Cert Needed & Out of Area",
        content: (
          <div>
            {reasonText}
            <AlertCopy>&</AlertCopy>
            <AlertCopy>Your project must be in an area where we support roofing work.</AlertCopy>
            {universityLinkContainer}
          </div>
        ),
      };
    default:
      return {
        title: "",
        content: (
          <AlertCopy>
            <></>
          </AlertCopy>
        ),
      };
  }
};
