// import { get } from 'lodash';

export default {
  getLeads: (state) => state.leads.leadsList,
  getEvents: (state) => state.leads.events,
  getLeadDetails: (state) => state.leads.leadDetails,
  selectLeadsCsv: (state) => state.leads.leadsCsv,
  selectUtilityCompanies: (state) => state.leads.utilityCompanies,
  selectHomeownerNotes: (state) => state.leads.homeownerNotes,
  selectLeadAssets: (state) => state.leads.leadDetails.assets,
};
