import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/fundingProposals/messages";
import { keysToSnakeCase, camelizeKeys } from "lib/helpers";
import { FundingProposalDataT } from "types/Project/fundingProposal";

type CreateFundingProposalPropsT = {
  payload: FundingProposalDataT;
};

function* createFundingProposal(action: CreateFundingProposalPropsT) {
  const params = keysToSnakeCase(action.payload);
  const { success, data, error } = yield* makeRequest.post("/funding_proposals", params);

  if (success && data) {
    const { fundingProposals } = camelizeKeys(data.body);

    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.CREATE_FUNDING_PROPOSAL_SUCCESS,
        }),
      ),
      put(actions.setFundingProposals(fundingProposals)),
      put(
        actions.setFundingProposalRequestable({
          canRequestFundingProposal: false,
          fundingNonRequestableReason: "funding_in_progress",
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_FUNDING_PROPOSAL,
    });
  }

  return null;
}

export default createFundingProposal;
