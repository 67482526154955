import React from "react";
import { FormControl } from "@mui/material";
import { useController } from "react-hook-form";
import type { Control, UseControllerProps } from "react-hook-form";
import * as S from "./styles";

type TOption = {
  label: string;
  value: string;
  textColor?: string;
  disabled?: boolean;
};

type TDropDownSelect = {
  handleChange?: (val: string) => void;
  label: string;
  options: TOption[];
  width?: string;
  placeholder?: string;
  control: Control;
  disabled?: boolean;
  labelStyles?: React.CSSProperties;
} & UseControllerProps;

const DropDownSelect = ({
  options,
  label,
  width = "200px",
  placeholder = "Choose...",
  handleChange,
  disabled = false,
  labelStyles,
  ...rest
}: TDropDownSelect) => {
  const {
    field: { name, ref, onChange, value },
  } = useController({ name: rest.name, control: rest.control, defaultValue: rest.defaultValue });

  return (
    <FormControl disabled={disabled} style={{ width }}>
      <S.StyledSelectLabel style={labelStyles}>{label}</S.StyledSelectLabel>
      <S.StyledSelect
        name={name}
        ref={ref}
        value={value || value === 0 ? value : ""}
        onChange={(e) => {
          onChange(e);
          if (handleChange) handleChange(e.target.value as string);
        }}
        renderValue={(val) => {
          const currentValue = options.find((el) => el.value === val);
          return val || val === 0 || val === "" ? (
            <div style={{ color: `${currentValue?.textColor}` }}>{currentValue?.label}</div>
          ) : (
            <S.StyledSelectedDefaultValue style={{ width: "100%" }}>{placeholder}</S.StyledSelectedDefaultValue>
          );
        }}
        displayEmpty
      >
        {options &&
          options.map((el) => (
            <S.StyledMenuItem disabled={el.disabled} key={el.value} value={el.value}>
              <span style={{ color: `${el.textColor}` }}>{el.label}</span>
            </S.StyledMenuItem>
          ))}
      </S.StyledSelect>
    </FormControl>
  );
};

export default DropDownSelect;
