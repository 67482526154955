import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/projects/messages";
import { camelizeKeys } from "lib/helpers";
import SchemaValidator from "util/schema-helpers/SchemaValidator";
import { GetTpoPricingWorksheetsResponse } from "../schemas/projects/post-sales";

function* getTpoPricingWorksheets(action) {
  const { projectId } = action.payload;

  const { data, error } = yield* makeRequest.get(`/provider_leads/${projectId}/tpo_pricing_worksheets`, {});

  const result = SchemaValidator.validate<typeof GetTpoPricingWorksheetsResponse>({
    schema: GetTpoPricingWorksheetsResponse,
    schemaName: "GetTpoPricingWorksheetsResponse",
    dto: camelizeKeys(data),
  });

  if (result.success && result.data) {
    yield all([put(actions.setTpoPricingWorksheets(camelizeKeys(result.data.body)))]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_TPO_WORKSHEETS,
    });
  }

  return null;
}

export default getTpoPricingWorksheets;
