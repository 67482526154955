import React from "react";

const CaretBundle = () => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8359 11.4369C14.0354 11.7139 14.4477 11.7139 14.6472 11.4369L17.9936 6.79228C18.2319 6.46155 17.9955 6 17.5879 6H10.8952C10.4876 6 10.2513 6.46155 10.4896 6.79228L13.8359 11.4369Z"
        fill="var(--dark-blue)"
      />
      <rect width="1" height="18" rx="0.5" fill="var(--dark-blue)" />
    </svg>
  );
};

export default CaretBundle;
