import React from "react";
import PropTypes from "prop-types";
import { Avatar, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import User from "models/User";
import styles from "./PlatformPageHeader.module.less";

const UserDisplay = (props) => {
  const userProps = props.user.props;
  const avatarSrc = userProps.avatar ? userProps.avatar.thumb : null;
  return (
    <div className={styles.userDisplay}>
      <Avatar
        className={styles.avatar}
        icon={avatarSrc ? null : <UserOutlined />}
        shape="circle"
        src={avatarSrc}
        style={{ width: "28px", height: "28px" }}
      />
      <Typography.Text className={styles.name}>{userProps.fullName}</Typography.Text>
    </div>
  );
};

UserDisplay.propTypes = {
  user: PropTypes.instanceOf(User),
};

export default UserDisplay;
