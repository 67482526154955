import React from "react";
import PropTypes from "prop-types";

const MarketingIcon = (props) => {
  const { height, width, containerStyles } = props;

  return (
    <div className={containerStyles}>
      <svg width={width} height={height} viewBox="0 0 94 109" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.3043 21.0917C49.3369 21.0731 49.3694 21.0544 49.4065 21.0397C51.2174 20.3212 53.1964 21.1238 53.8233 22.831L71.2195 69.3044C71.857 71.0075 70.913 72.9698 69.1127 73.6842C69.0756 73.6989 69.0393 73.7075 69.003 73.7162C68.9667 73.7248 68.9304 73.7335 68.8934 73.7482L68.9009 73.7682C68.8903 73.7724 68.876 73.7666 68.8616 73.7608C68.7414 73.797 68.4179 73.8103 67.9412 73.8038L48.4599 21.7604C48.8092 21.4263 49.0576 21.2126 49.1704 21.1564C49.181 21.1522 49.1878 21.138 49.1947 21.1237L49.2022 21.1438C49.2392 21.1291 49.2718 21.1104 49.3043 21.0917ZM56.0638 71.8422L56.0642 71.8423L56.0644 71.8423C59.9333 72.2052 63.3885 72.5293 65.7747 72.7084L47.5025 23.3343C45.7828 25.1971 43.3158 27.9787 40.564 31.0814C35.8683 36.3759 30.3437 42.6051 26.2896 46.7975L34.9214 70.1218C40.7475 70.4055 49.0797 71.1871 56.0638 71.8422ZM74.2124 21.2703L71.583 19.8128L66.7436 28.1626L69.373 29.6201L74.2124 21.2703ZM84.8206 38.7948L83.7712 36.0336L74.8185 39.5708L75.8679 42.332L84.8206 38.7948ZM87.2888 57.0564L79.1177 52.4487L77.4899 55.3053L85.6609 59.9131L87.2888 57.0564ZM32.5294 69.4541L33.0946 69.2275L32.8945 68.6869L32.8842 68.6911L25.1671 47.844L10.0902 53.8871L10.2903 54.4277L18.0074 75.2748L32.5294 69.4541ZM22.0287 76.5921L32.1488 72.5372L39.4086 82.6886C41.2963 85.3354 40.0244 89.158 36.8221 90.4411C34.5743 91.3417 32.0864 90.6709 30.7654 88.8225L22.0287 76.5921ZM9.4378 57.413L5.63499 58.9332L11.2933 74.171L15.0962 72.6508L9.4378 57.413ZM67.2982 53.5372L61.3508 37.476C65.4673 36.8276 69.4937 38.9575 70.9356 42.8514C72.3812 46.7552 70.769 51.1449 67.2982 53.5372Z"
          fill="url(#paint0_linear_689_11142)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_689_11142"
            x1="5.22611"
            y1="13.5158"
            x2="75.0708"
            y2="-0.149448"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="var(--picton-blue)" />
            <stop offset="1" stopColor="var(--blue-ribbon)" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

MarketingIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  containerStyles: PropTypes.string,
};

MarketingIcon.defaultProps = {
  height: "108",
  width: "94",
};

export default MarketingIcon;
