import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const ArchiveIcon = (props) => {
  const { height, width, containerStyles, active, hovered } = props;

  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      {...containerStyles}
    >
      <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.856445" y="0.946289" width="4.5458" height="4.5458" rx="1" fill="white" />
        <rect x="0.856445" y="6.40137" width="4.5458" height="4.5458" rx="1" fill="white" />
        <rect x="0.856445" y="11.8564" width="4.5458" height="4.5458" rx="1" fill="white" />
        <rect x="6.31152" y="0.946289" width="4.5458" height="4.5458" rx="1" fill="white" />
        <rect x="6.31152" y="6.40137" width="4.5458" height="4.5458" rx="1" fill="white" />
        <rect x="6.31152" y="11.8564" width="4.5458" height="4.5458" rx="1" fill="white" />
        <rect x="11.7666" y="0.946289" width="4.5458" height="4.5458" rx="1" fill="white" />
        <rect x="11.7666" y="6.40137" width="4.5458" height="4.5458" rx="1" fill="white" />
        <rect x="11.7666" y="11.8564" width="4.5458" height="4.5458" rx="1" fill="white" />
      </svg>
    </div>
  );
};

ArchiveIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

ArchiveIcon.defaultProps = {
  height: "21",
  width: "21",
};

export default ArchiveIcon;
