import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxActionMapper } from "rdx/utils/propsMapping";
import { Button } from "antd";

const ZipButton = (props) => {
  const { zip, getZipLookupAddress, buttonTouch, children, ...rest } = props;

  const handleZipButton = () => {
    buttonTouch(true);
    if (zip && zip.length === 5) {
      buttonTouch(false);
      getZipLookupAddress({ zip });
    }
  };

  return (
    <Button onClick={handleZipButton} {...rest}>
      {children}
    </Button>
  );
};

ZipButton.propTypes = {
  zip: PropTypes.string.isRequired,
  getZipLookupAddress: PropTypes.func,
  buttonTouch: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string])),
  ]),
};

ZipButton.defaultProps = {
  buttonTouch: () => null,
};

export default connect(undefined, getRdxActionMapper(["getZipLookupAddress"]))(ZipButton);
