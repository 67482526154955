import ProviderLead, { providerLeadDefaults } from "models/ProviderLead";
import Project, { projectDefaults } from "models/Project";

export const REQUIRED_FIELDS_AND_VALUES: Record<
  string,
  (props: typeof providerLeadDefaults | typeof projectDefaults) => boolean
> = {
  // disabling reasons because they shouldn't disable ability to open proposal drawer
  // not removing yet because it's unclear to me how this will effect MIF button

  // qualify_incomplete: (p) => !!p.kwhConsumption && !!p.utilityCompany,
  // no_mentor: (p) => !!p.mentorRequirementMet,
  // only_mentor: (p) => !!p.userCanRequestProposal,

  proposal_request_in_progress: (p) => p.visionProposalNonrequestableReason !== "proposal_request_in_progress",
  outside_vision_regions: (p) => p.visionProposalNonrequestableReason !== "outside_vision_regions",
};

export const canSendVision = (details: ProviderLead | Project): [true, undefined] | [false, string] => {
  let canSend = true;
  const reasons: string[] = [];
  Object.entries(REQUIRED_FIELDS_AND_VALUES).forEach(([message, checker]) => {
    const pass = checker(details.props);
    if (!pass) {
      canSend = false;
      reasons.push(message);
    }
  });
  if (canSend) {
    return [true, undefined];
  }

  return [false, reasons[0]];
};
