// [eyecue-codemap:4P2dZihfnX8]
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  no-console */

import { GraphQLClient } from "graphql-request";
import React from "react";
import SessionClient from "util/SessionClient";
import {
  ClaimDesignDocument,
  ClaimDesignMutation,
  ClaimNextDesignDocument,
  ClaimNextDesignMutation,
  DesignRequestFindManyWhereInput,
  GetQueueDocument,
  GetQueueQuery,
  QueueCountDocument,
  QueueCountQuery,
  QueueInput,
  RequestRevisionDocument,
  RequestRevisionMutation,
  RequestRevisionMutationVariables,
  UpdatePriorityDocument,
  UpdatePriorityMutation,
  UpdatePriorityMutationVariables,
} from "./client/graphql";

export class VisionClient {
  public constructor(noisy = false) {
    this.noisy = noisy;
    this.getGraphQLClient = this.getGraphQLClient.bind(this);
    this.getQueue = this.getQueue.bind(this);
    this.queueCount = this.queueCount.bind(this);
    this.claimDesign = this.claimDesign.bind(this);
    this.claimNextDesign = this.claimNextDesign.bind(this);
    this.updatePriority = this.updatePriority.bind(this);
    this.requestRevision = this.requestRevision.bind(this);
    this.log = this.log.bind(this);
  }

  private client: GraphQLClient | undefined;
  private noisy: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  private log(...args: any[]) {
    if (this.noisy) {
      /* eslint-disable-next-line no-console */
      console.debug(...args);
    }
  }

  public getGraphQLClient(): GraphQLClient {
    const { jwt } = new SessionClient();
    this.log({ jwt });
    if (this.client) {
      this.log("client exists, setting header");
      this.client.setHeader("Authorization", `Bearer ${jwt}`);
      return this.client;
    }
    this.log("client does not exist, creating new client");
    this.client = new GraphQLClient(`${process.env.REACT_APP_VISION_API_URL}/graphql`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    return this.client;
  }

  public async getQueue(queueInput: QueueInput): Promise<GetQueueQuery> {
    this.log("getQueue", { queueInput });
    const client = this.getGraphQLClient();
    return client.request(GetQueueDocument, { queueInput });
  }

  public async queueCount(): Promise<QueueCountQuery> {
    this.log("queueCount");
    const client = this.getGraphQLClient();
    return client.request(QueueCountDocument);
  }

  public async claimDesign(id: string): Promise<ClaimDesignMutation> {
    this.log("claimDesign", { id });
    const client = this.getGraphQLClient();
    return client.request(ClaimDesignDocument, { id });
  }

  public async claimNextDesign(): Promise<ClaimNextDesignMutation> {
    this.log("claimNextDesign");
    const client = this.getGraphQLClient();
    return client.request(ClaimNextDesignDocument);
  }

  public async requestRevision(input: RequestRevisionMutationVariables["input"]): Promise<RequestRevisionMutation> {
    this.log("requestRevision", { input });
    const client = this.getGraphQLClient();
    return client.request(RequestRevisionDocument, { input });
  }

  public async updatePriority({ id, priority }: UpdatePriorityMutationVariables): Promise<UpdatePriorityMutation> {
    this.log("updatePriority", { id, priority });
    const client = this.getGraphQLClient();
    return client.request(UpdatePriorityDocument, { id, priority });
  }
}

export const useVisionClient = () => {
  const client = React.useMemo(() => new VisionClient(), []);

  const getQueue = React.useCallback(
    async (take = 20, skip?: number, filter?: DesignRequestFindManyWhereInput) => {
      return client.getQueue({ take, skip, filter });
    },
    [client],
  );

  const queueCount = React.useCallback(async () => {
    return client.queueCount();
  }, [client]);

  const claimDesign = React.useCallback(
    async (id: string) => {
      return client.claimDesign(id);
    },
    [client],
  );

  const claimNextDesign = React.useCallback(async () => {
    return client.claimNextDesign();
  }, [client]);

  const updatePriority = React.useCallback(
    async (id: string, priority: number) => {
      return client.updatePriority({ id, priority });
    },
    [client],
  );
  const requestRevision = React.useCallback(
    async (input: RequestRevisionMutationVariables["input"]) => {
      return client.requestRevision(input);
    },
    [client],
  );

  return {
    getQueue,
    queueCount,
    claimDesign,
    claimNextDesign,
    updatePriority,
    requestRevision,
  };
};
