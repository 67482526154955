import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/compensation/messages";
import { camelizeKeys } from "lib/helpers";

function* getDistributionsRequest(action) {
  const params = {
    ...action.payload,
    limit: 15,
  };
  const { success, data, error } = yield* makeRequest.get("/compensation/distributions", params);

  if (success && data) {
    yield put(actions.setDistributions(camelizeKeys(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_DISTRIBUTIONS,
    });
  }

  return null;
}

export default getDistributionsRequest;
