export default {
  // success
  CREATE_NEW_USER_SUCCESS: "New user created successfully",
  RECRUIT_REJECT_SUCCESS: "Offer declined",
  REGISTER_NEW_USER_SUCCESS: "New user registered successfully",
  REGISTRATION_UPDATE_SUCCESS: "Registration updated successfully",
  GET_REGISTRATION_SUCCESS: "Registration retrieved successfully",

  // errors
  ERROR_GETTING_REGISTRATION: "There was an error getting registration information",
  ERROR_GETTING_SEATED_SELLER_REGISTRATION_INFO: "There was an error getting seated seller registration information",
  ERROR_CREATING_NEW_USER: "There was an error creating new user",
  RECRUIT_REJECT_ERROR: "There was an error rejecting this offer",
  ERROR_REGISTERING_NEW_USER: "There was an error registering new user",
  ERROR_UPDATING_REGISTRATION: "There was an error updating this registration",
};
