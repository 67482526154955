import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/crmActivities/types";
import createNewCrmActivity from "./createNewCrmActivity";
import getCrmActivity from "./getCrmActivity";
import deleteCrmActivity from "./deleteCrmActivity";
import updateCrmActivity from "./updateCrmActivity";
import getCrmTaskList from "./getCrmTaskList";

// IMPORT_PT (for script, do not remove)

function* watchCrmActivitySagas() {
  yield trackRequests(takeEvery, types.CREATE_CRM_ACTIVITY, createNewCrmActivity);
  yield trackRequests(takeEvery, types.GET_CRM_ACTIVITY, getCrmActivity);
  yield trackRequests(takeEvery, types.DELETE_CRM_ACTIVITY, deleteCrmActivity);
  yield trackRequests(takeEvery, types.UPDATE_CRM_ACTIVITY, updateCrmActivity);
  yield trackRequests(takeEvery, types.GET_CRM_TASK_LIST, getCrmTaskList);

  // INSERTION_PT (for script, do not remove)
}

export default watchCrmActivitySagas;
