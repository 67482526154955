import PropTypes from "prop-types";

export const providerSchema = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

export const providerDefaults = {
  id: 0,
  name: "",
};
