import React, { useState, useMemo } from "react";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import { useEventListener } from "hooks/useEventListener";
import { Layout, Button, Typography } from "antd";
import { Typography as MuiTypography } from "@mui/material";
import ChatIcon from "components/Icons/ChatIcon";
import { theme } from "styles/themes";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import useModalControls from "hooks/useModalControls";
import useWindowResize from "hooks/useWindowResize";
import { buttonProps } from "lib/props";
import ReferrerInfoBox from "components/HomepageComponents/ReferrerInfoBox";
import HeaderLogo from "components/Icons/Logos/HeaderLogo";
import DownCaret from "components/Icons/Chevrons/DownCaretSimple";
import InjectFacebookPixel from "components/Injectors/InjectFacebookPixel";
import InjectZendeskWidget from "components/Injectors/InjectZendeskWidget";
import ZipButton from "../ZipButton";
import ZipInput from "../ZipInput";
import Menu from "../MobileMenu";
import * as S from "../Footer/styles";
import styles from "./StickyHeader.module.less";

const { Header } = Layout;

const StickyHeader = (props) => {
  const { user } = props;
  const { t } = useTranslation(["form_labels", "translation", "ambassador"]);
  const { Paragraph } = Typography;
  const history = useHistory();
  const location = useLocation();
  const { user: userId } = useParams();
  const userTag = userId ? `/${userId}` : "";
  const { search } = location;
  const slugRole = location.search.includes("ref_id")
    ? t("ambassador:your_solar_ambassador")
    : t("ambassador:your_solar_consultant");

  const [zip, setZip] = useState("");
  const [agentCardVisible, setAgentCardVisible] = useState(false);
  const [buttonTouch, setButtonTouch] = useState(false);

  const links = [
    {
      key: "solar",
      target: { pathname: `${userTag}/solar`, search },
      title: t("translation:why_solar"),
    },
    {
      key: "faq",
      target: { pathname: `${userTag}/faq`, search },
      title: "FAQ",
    },
    {
      key: "ambassador",
      target: { pathname: `${userTag}/ambassador`, search },
      title: t("translation:make_a_referral"),
    },
  ];

  const { visible: mobileMenuVisible, toggle: mobileMenuToggle, close: mobileMenuClose } = useModalControls();

  const { width } = useWindowResize();

  const mobile = useMemo(() => {
    return width <= 865;
  }, [width]);

  const showHamburger = useMemo(() => {
    return width <= 1120;
  }, [width]);

  // navigate to login page on escape keydown event
  const handleNavigate = (e) => {
    if (e.keyCode === 27) {
      history.push("/login");
    }
  };

  const powurSupportLink = "https://chat.powur.com";

  const handleChatClick = () => {
    window.open(powurSupportLink, "_blank");
  };

  const getCardStyle = () => {
    if (agentCardVisible && user?.org?.name) return styles.agentCardWithOrg;
    if (agentCardVisible) return styles.agentCard;
    return styles.invisibleCard;
  };

  useEventListener("keydown", handleNavigate);

  const rootURL = `${userTag}/`;

  return (
    <Header className={styles.header}>
      <InjectZendeskWidget displayWidget />
      <InjectFacebookPixel facebookPixelId={user?.facebook_pixel_id} />
      <div className={styles.innerContainer}>
        <div className={styles.linksCol}>
          <div
            {...buttonProps(() => {
              mobileMenuClose();
              setTimeout(() => {
                history.push({ pathname: rootURL, search });
              }, 300);
            })}
          >
            <HeaderLogo />
          </div>
          {!mobile && (
            <>
              {links.map(({ key, target, title }) => (
                <Link
                  key={key}
                  to={target}
                  className={[styles.link, location.pathname.includes(key) ? styles.activeLink : ""].join(" ")}
                >
                  {title}
                </Link>
              ))}
              <S.ChatButton onClick={handleChatClick}>
                <ChatIcon height={18} width={18} />
                <MuiTypography fontSize={14} fontWeight={700} color={theme.colors.white}>
                  Live Chat
                </MuiTypography>
              </S.ChatButton>
            </>
          )}
        </div>
        {mobile && !isEmpty(user) && (
          <div
            className={styles.agentTitleContainer}
            onClick={() => setAgentCardVisible(!agentCardVisible)}
            onKeyDown={() => setAgentCardVisible(!agentCardVisible)}
            role="button"
            tabIndex={0}
          >
            <div className={styles.agentTitle}>
              <span className={styles.label}>{slugRole}</span>
            </div>
            <div className={styles.caretContainer}>
              <Paragraph ellipsis={{ rows: 2, width: 14 }} className={styles.agentName}>
                {user.fullName || user.full_name}
              </Paragraph>
              <DownCaret width="18px" height="12px" fill="var(--white)" />
            </div>
          </div>
        )}
        <div className={styles.zipCol}>
          {!mobile && (
            <>
              {history.location.pathname.includes("/join") ? (
                <Button className={styles.loginButton} type="primary" onClick={() => history.push("/login")}>
                  Powur {t("login")}
                </Button>
              ) : (
                <>
                  <ZipInput
                    isEmpty={buttonTouch && (zip === "" || zip.length < 5)}
                    zip={zip}
                    setZip={setZip}
                    className={styles.input}
                  />
                  <ZipButton zip={zip} className={styles.zipButton} type="primary" buttonTouch={setButtonTouch}>
                    {t("get_quote")}
                  </ZipButton>
                </>
              )}
            </>
          )}
          {showHamburger && !mobile && <div className={styles.divider} />}
          {showHamburger && (
            <Button
              className={styles.mobileMenuButton}
              type="info"
              htmlType="button"
              icon={
                mobileMenuVisible ? (
                  <div className={styles.mobileMenuClose} />
                ) : (
                  <div className={styles.mobileMenuOpen} />
                )
              }
              onClick={() => mobileMenuToggle()}
            />
          )}
        </div>
      </div>
      {/* Absolute positioned Items */}
      {showHamburger && <Menu visible={mobileMenuVisible} toggle={mobileMenuToggle} zIndex={100} />}
      {!mobile && <ReferrerInfoBox user={user} />}
      {mobile && !isEmpty(user) && (
        <div className={getCardStyle()}>
          {user?.org?.name && <span className={styles.orgName}>{user.org.name}</span>}
          <span className={styles.label}>{t("translation:contact")}</span>
          <a className={styles.contact} href={`tel:${user.phone}`}>
            {user.phone}
          </a>
          <Paragraph ellipsis={{ rows: 1, width: 14 }} className={styles.contact}>
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </Paragraph>
        </div>
      )}
    </Header>
  );
};

export default StickyHeader;
