import React from "react";
import PropTypes from "prop-types";

const StatusIconCurrent = (props) => {
  const { accentColor, mainColor, size, containerStyles } = props;

  return (
    <div
      style={{
        // paddingTop: "3px",
        lineHeight: "10px",
        ...containerStyles,
      }}
    >
      <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="24" fill={accentColor} />
        <circle cx="24" cy="24" r="18" fill={mainColor} />
      </svg>
    </div>
  );
};

StatusIconCurrent.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mainColor: PropTypes.string,
  accentColor: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

StatusIconCurrent.defaultProps = {
  accentColor: "var(--diamond-blue)",
  mainColor: "var(--white)",
  size: "48",
};

export default StatusIconCurrent;
