import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/settings/messages";
import { camelizeKeys } from "lib/helpers";

function* getAdminAdvanceSettings(action = { payload: {} }) {
  const { success, data, error } = yield* makeRequest.get("/pay_advance_levels", action.payload);

  if (success && data) {
    const advanceSettings = camelizeKeys(data.body.pay_advance_levels);
    yield put(actions.setAdminAdvanceSettings(advanceSettings));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ADMIN_ADVANCE_SETTINGS,
    });
  }

  return null;
}

export default getAdminAdvanceSettings;
