import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import _ from "lodash";
import messages from "rdx/modules/auth/messages";
import types from "rdx/modules/auth/types";
import { useParams, useHistory } from "react-router-dom";
import { Form, Input, Col, Row } from "antd";
import GradientButton from "components/Buttons/GradientButton";
import CheckMarkBullet from "components/Icons/CheckMarkBullet";
import CloseIconPlain from "components/Icons/CloseIconPlain";
import useLoading from "hooks/useLoading";
import MessageEvent from "models/MessageEvent";
import styles from "../../Login.module.less";

const { Item } = Form;

const CreateNewPassword = (props) => {
  const {
    errors,
    latestMessage,
    createNewPasswordRequest,
    clearNewPasswordErrors,
    setAlertMessageVisible,
    clearAlertMessage,
  } = props;
  const { t } = useTranslation("form_labels");
  const [form] = Form.useForm();
  const history = useHistory();
  const { token } = useParams();
  const inputRef = useRef();
  const [passwordRules, setPasswordRules] = useState([]);

  const loading = !!useLoading({
    watchRequests: [types.CREATE_NEW_PASSWORD_REQUEST],
  });

  const handleFinish = (values) => {
    createNewPasswordRequest({ ...values, token });
  };

  const handleValuesChange = () => {
    if (errors && errors.message) {
      clearNewPasswordErrors();
    }
  };

  const passwordsMatch = (rule, value) => {
    const confirm = form.getFieldValue("password_confirm");
    if (value !== confirm) {
      return Promise.reject(new Error("Passwords do not match"));
    }
    return Promise.resolve();
  };

  const passwordsError = () => {
    if (errors && errors.message) {
      return Promise.reject(new Error(errors.message));
    }
    return Promise.resolve();
  };

  const passwordValidation = () => {
    setPasswordRules([
      {
        id: 1,
        rulePassed: regexValidator(/^.{8,}$/),
        label: "At least 8 Characters",
      },
      {
        id: 2,
        rulePassed: regexValidator(/(?=.*?[A-Z])/),
        label: "At least one capital letter",
      },
      {
        id: 3,
        rulePassed: regexValidator(/(?=.*?[0-9])/),
        label: "At least one number",
      },
    ]);
  };

  useEffectOnce(() => {
    passwordValidation();
    inputRef.current.focus();
    if (!token) {
      history.replace("/login");
    }
  });

  useEffect(() => {
    if (loading) {
      setAlertMessageVisible({
        message: `${t("submitting_new_password")}. . .`,
        severity: "info",
        duration: 6000,
      });
    }
  }, [loading, t]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (latestMessage.payload?.event === messages.CREATE_NEW_PASSWORD_SUCCESS_EVENT) {
      setAlertMessageVisible({
        message: latestMessage.message,
        severity: "success",
      });
      history.push("/login");
    }
    if (latestMessage.error?.event === messages.CREATE_NEW_PASSWORD_ERROR_EVENT) {
      clearAlertMessage();
      form.validateFields();
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const regexValidator = (regex) => {
    return regex.test(form.getFieldValue("new_password"));
  };

  return (
    <Form
      form={form}
      className={styles.resetPasswordForm}
      layout="vertical"
      requiredMark={false}
      onFinish={handleFinish}
      onValuesChange={handleValuesChange}
      colon={false}
    >
      <Item
        className={styles.formItem}
        initialValue=""
        name="new_password"
        label={<span className={styles.formLabel}>{t("create_new_password")}</span>}
        rules={[
          {
            required: true,
            message: t("enter_your_password"),
          },
          {
            validator: passwordsMatch,
            message: t("passwords_do_not_match"),
            validateTrigger: "onSubmit",
          },
          {
            validator: passwordsError,
            message: _.startCase(errors.message || ""),
          },
        ]}
      >
        <Input.Password
          className={styles.input}
          type="password"
          onChange={passwordValidation}
          ref={(node) => {
            inputRef.current = node;
          }}
        />
      </Item>
      <Item
        className={styles.formItem}
        name="password_confirm"
        label={<span className={styles.formLabel}>{t("confirm_new_password")}</span>}
        rules={[
          {
            required: true,
            message: t("password_confirmation"),
          },
        ]}
      >
        <Input.Password className={styles.input} type="password" />
      </Item>
      <div className={styles.validationRules}>
        {passwordRules.map((rule) => (
          <Row key={rule.id}>
            {rule.rulePassed ? (
              <Col span={2}>
                <CheckMarkBullet width={12} height={10} fill="var(--harlequin-green)" />
              </Col>
            ) : (
              <Col span={2}>
                <CloseIconPlain fill="var(--nepal-blue)" />
              </Col>
            )}
            <Col span={22} className={styles.ruleText}>
              {rule.label}
            </Col>
          </Row>
        ))}
      </div>
      <div className={styles.loginRow}>
        <GradientButton className={styles.submitButton} type="primary" htmlType="submit">
          {t("create_new_password")}
        </GradientButton>
      </div>
    </Form>
  );
};

CreateNewPassword.propTypes = {
  errors: PropTypes.shape({
    message: PropTypes.string,
  }),
  latestMessage: MessageEvent.types(),
  createNewPasswordRequest: PropTypes.func,
  clearNewPasswordErrors: PropTypes.func,
  setAlertMessageVisible: PropTypes.func,
  clearAlertMessage: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    errors: "getCreatePasswordErrors",
    latestMessage: "getLatestMessageEvt",
    activeRequests: "getActiveRequests",
  }),
  getRdxActionMapper([
    "createNewPasswordRequest",
    "clearNewPasswordErrors",
    "setAlertMessageVisible",
    "clearAlertMessage",
  ]),
)(CreateNewPassword);
