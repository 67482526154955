import { GettingStartedIcon } from "@icons";
import l from "@loadable/component";
import breadCrumbs from "components/PlatformLayout/components/PlatformPageHeader/Breadcrumbs/GettingStartedBreadCrumbs";

const content = l(() => import("containers/Sales/gettingStarted"));

export default {
  key: "getting-started",
  title: breadCrumbs,
  path: "/getting-started/:tab?/:modal?",
  linkTarget: "/getting-started",
  linkTitle: "Getting Started",
  linkCategory: "Learn",
  icon: GettingStartedIcon,
  basePath: "getting-started",
  requiredParams: [],
  optionalParams: ["tab", "modal"],
  content,
  sidebar: null,
};
