import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone-uploader";
import ApiClient from "util/ApiClient";
import Loading from "./Components/Loading";
import LayoutComponent from "./Components/Layout";
import Input from "./Components/Input";
import PreviewComponent from "./Components/Preview";
import SubmitButton from "./Components/SubmitButton";
import styles from "./styles.module.less";
import "react-dropzone-uploader/dist/styles.css";

const api = new ApiClient({ host: process.env.REACT_APP_POWUR_API_URL });
const getConfig = async (cb, apiMode, filename) => {
  const params = { mode: apiMode };
  if (filename) params.filename = filename;
  await api.get("/uploader_config", params).then(
    (res) => {
      cb(res);
    },
    (res) => {
      console.warn(res.error);
    },
  );
};

const FileUploaderDragAndDrop = ({ apiMode, onUploadSubmit }) => {
  const [config, setConfig] = useState({});
  const [ready, setReady] = useState(false);

  const getUploadParams = async ({ file, meta }) => {
    let params;
    await getConfig(
      (res) => {
        params = {
          url: res.signed_url,
          headers: res.headers,
          method: "PUT",
          body: file,
          meta: {
            signedUrl: res.signed_url,
            url: res.signed_url.split("?")[0],
          },
        };
      },
      apiMode,
      meta.name,
    );
    return params;
  };

  const handleChangeState = async (file, status) => {
    if (status === "started") {
      const input = document.getElementById(`${file.meta.id}-filename-input`);
      if (input.value) file.meta.name = input.value; // eslint-disable-line no-param-reassign
    }

    if (status === "done") {
      await onUploadSubmit(file.meta);
      file.remove();
    }
  };

  const handleSubmit = async (files, allFiles) => {
    for (const file of allFiles) {
      file.restart();
    }
  };

  useEffect(() => {
    getConfig((res) => {
      setConfig(res);
      setReady(true);
    }, apiMode);
  }, [apiMode]);

  if (ready) {
    const input = <Input key="file-uploader-input-contents" max={config.max} accept={config.accept} />;

    return (
      <Dropzone
        // config
        autoUpload={false}
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeState}
        onSubmit={handleSubmit}
        accept={config.accept}
        maxSizeBytes={config.max}
        maxFiles={3}
        // custom UI components
        inputContent={input}
        inputWithFilesContent={input}
        PreviewComponent={PreviewComponent}
        LayoutComponent={LayoutComponent}
        SubmitButtonComponent={SubmitButton}
        classNames={{
          dropzone: styles.dropzone,
          // dropzoneActive: styles.dropzoneActive,
          // dropzoneReject: styles.dropzoneReject,
          // dropzoneDisabled: styles.dropzoneDisabled,
          input: styles.input,
          inputLabel: styles.inputLabel,
          inputLabelWithFiles: styles.inputLabelWithFiles,
          // preview: styles.preview,
          // previewImage: styles.previewImage,
          // submitButtonContainer: styles.submitButtonContainer,
          // submitButton: styles.submitButton,
        }}
      />
    );
  }

  return <Loading />;
};

FileUploaderDragAndDrop.propTypes = {
  apiMode: PropTypes.string.isRequired,
  onUploadSubmit: PropTypes.func.isRequired,
};

export default FileUploaderDragAndDrop;
