import { put, all } from "redux-saga/effects";
import actions from "rdx/actions";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getZendeskFormData(action) {
  const params = {
    message_topic_id: action.payload.messageTopicId,
  };

  const { success, data, error } = yield* makeRequest.get(`/projects/${action.payload.projectId}/messages/topic`, {
    ...params,
  });

  if (success && data) {
    const formData = camelizeKeys(data.body);
    yield all([put(actions.setZendeskFormData({ formData }))]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_FORM_DATA,
    });
  }
  return null;
}

export default getZendeskFormData;
