import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enterprisePersonnel/messages";
import SessionClient from "util/SessionClient";

function* acceptSeatInvite(action) {
  const { pendingSeatId, pendingSponsorId } = action.payload;
  const session = new SessionClient();

  const params = { sponsor_id: pendingSponsorId };
  const { success, data, error } = yield* makeRequest.patch(`/pending_seats/${pendingSeatId}/accept`, params);

  if (success && data) {
    // Will need to refresh token or permissions?

    yield all([
      // put(actions.setEnterpriseServicesSeatChangePreview({})),
      put(
        actions.newSuccessEvent({
          message: messages.ACCEPT_SEAT_INVITE_SUCCESS,
        }),
      ),
    ]);

    session.user.reload();
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_ACCEPTING_SEAT_INVITE,
    });
  }

  return null;
}

export default acceptSeatInvite;
