import types from "rdx/modules/leads/types";
import createAction from "rdx/utils/createAction";

export default {
  getLeads: (payload) => createAction(types.GET_LEADS_REQUEST, payload),

  setLeads: (payload) => createAction(types.SET_LEADS, payload),

  resetLeads: (payload) => createAction(types.RESET_LEADS, payload),

  getEvents: (payload) => createAction(types.GET_EVENTS_REQUEST, payload),

  setEvents: (payload) => createAction(types.SET_EVENTS, payload),

  getLeadDetails: (payload) => createAction(types.GET_LEAD_DETAILS_REQUEST, payload),

  setLeadDetails: (payload) => createAction(types.SET_LEAD_DETAILS, payload),

  createNewLead: (payload) => createAction(types.CREATE_NEW_LEAD, payload),

  submitLeadToProvider: (payload) => createAction(types.SUBMIT_LEAD_TO_PROVIDER, payload),

  updateLead: (payload) => createAction(types.UPDATE_LEAD, payload),

  updateLeadUtilityBill: (payload) => createAction(types.UPDATE_LEAD_UTILITY_BILL, payload),

  getLeadGeocode: (payload) => createAction(types.GET_LEAD_ADDRESS_GEOCODE, payload),

  setLeadGeocode: (payload) => createAction(types.SET_LEAD_ADDRESS_GEOCODE, payload),

  deleteLead: (payload) => createAction(types.DELETE_LEAD_REQUEST, payload),

  saveAndSubmitLead: (payload) => createAction(types.SAVE_AND_SUBMIT_LEAD_REQUEST, payload),

  resetLeadDetails: (payload) => createAction(types.RESET_LEAD_DETAILS, payload),

  createNewPublicLead: (payload) => createAction(types.CREATE_NEW_PUBLIC_LEAD_REQUEST, payload),

  getLeadsCsv: (payload) => createAction(types.GET_LEADS_CSV, payload),

  setLeadsCsv: (payload) => createAction(types.SET_LEADS_CSV, payload),

  getLeadsCsvReport: (payload) => createAction(types.GET_LEADS_CSV_REPORT, payload),

  deleteHomeownerAvailabilities: (payload) => createAction(types.DELETE_HOMEOWNER_AVAILABILITY, payload),

  setUtilityCompanies: (payload) => createAction(types.SET_UTILITY_COMPANIES, payload),
  getUtilityCompanies: (payload) => createAction(types.GET_UTILITY_COMPANIES, payload),

  getHomeownerNotes: (payload) => createAction(types.GET_HOMEOWNER_NOTES, payload),
  setHomeownerNotes: (payload) => createAction(types.SET_HOMEOWNER_NOTES, payload),
  resetHomeownerNotes: (payload) => createAction(types.RESET_HOMEOWNER_NOTES, payload),

  closeLead: (payload) => createAction(types.CLOSE_LEAD_REQUEST, payload),
  reopenLead: (payload) => createAction(types.REOPEN_LEAD_REQUEST, payload),

  confirmSiteLocation: (payload) => createAction(types.CONFIRM_SITE_LOCATION, payload),
  setAddressConfirmed: () => createAction(types.SET_LOCATION_CONFIRMED),
  setLeadSubmitted: () => createAction(types.SET_LEAD_SUBMITTED),
  updateLeadOwner: (payload) => createAction(types.UPDATE_LEAD_OWNER, payload),
  createLeadAsset: (payload) => createAction(types.CREATE_LEAD_ASSET, payload),
  setLeadAssets: (payload) => createAction(types.SET_LEAD_ASSETS, payload),
  // INSERTION_PT (for script, do not remove)
};
