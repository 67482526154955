import React from "react";
import { cvar } from "styles";

type CheckboxCheckedT = {
  width?: string | number;
  height?: string | number;
  fill?: string;
  checkFill?: string;
};

const CheckboxChecked = ({
  width = 17,
  height = 17,
  fill = cvar("blue-ribbon"),
  checkFill = cvar("white"),
}: CheckboxCheckedT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.318848 3.74463C0.318848 2.08777 1.66199 0.744629 3.31885 0.744629H13.3188C14.9757 0.744629 16.3188 2.08777 16.3188 3.74463V13.7446C16.3188 15.4015 14.9757 16.7446 13.3188 16.7446H3.31885C1.66199 16.7446 0.318848 15.4015 0.318848 13.7446V3.74463Z"
        fill={fill}
      />
      <path
        d="M13.0768 3.92052C12.569 3.50256 11.8299 3.58888 11.4257 4.11285L6.83997 11.2407L4.83833 9.17383C4.42649 8.74728 3.77996 8.72579 3.39412 9.12369C3.00898 9.52123 3.03012 10.1892 3.44231 10.6154L6.46072 13.7345C6.81363 14.0984 7.32808 14.1474 7.71426 13.9107C7.88066 13.834 8.03319 13.7219 8.15487 13.5647L13.2623 5.62531C13.6672 5.10169 13.583 4.33884 13.0768 3.92052Z"
        fill={checkFill}
      />
    </svg>
  );
};

export default CheckboxChecked;
