import { useCallback, useMemo, useState, createRef } from "react";
import type { RefObject, Dispatch, SetStateAction } from "react";

type SectionOption = {
  key: string;
  label: string;
  ref: RefObject<HTMLDivElement>;
};

type SectionKeyMap = {
  [key: string]: SectionOption;
};

type UseSectionOptionsArgs = {
  options: readonly [...string[]];
};

type UseSectionOptionsReturn<T extends readonly [...string[]]> = {
  getSectionAttrs: (key: T[number]) => { ref: RefObject<HTMLDivElement>; "data-key": string };
  getActiveKeyProps: () => {
    activeSectionKey: string;
    setActiveSectionKey: Dispatch<SetStateAction<string>>;
    sectionOptions: { key: string; label: string; ref: RefObject<HTMLDivElement> }[];
  };
  getSectionContainerProps: (key: T[number]) => { sectionRef: RefObject<HTMLDivElement>; title: string };
  getSectionOptions: (key: T[number]) => SectionOption | undefined;
};

function capitalizeWords(str: string) {
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
}

function keyToLabel(key: string): string {
  // Convert camel case to space-separated words
  const label = key.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/&/g, " & ");
  return capitalizeWords(label);
}

const useSectionOptions = <T extends readonly [...string[]]>({
  options,
}: UseSectionOptionsArgs): UseSectionOptionsReturn<T> => {
  const sectionOptions = useMemo(() => {
    return options.map((key) => {
      return {
        key,
        label: keyToLabel(key),
        ref: createRef<HTMLDivElement>(),
      };
    });
  }, [options]);

  const sectionKeyMap = useMemo(() => {
    const keyMap: SectionKeyMap = {} as SectionKeyMap;
    sectionOptions.forEach((option) => {
      keyMap[option.key] = option;
    });
    return keyMap;
  }, [sectionOptions]);

  const [activeSectionKey, setActiveSectionKey] = useState<string>(sectionOptions[0].key);

  const getActiveKeyProps = () => {
    return {
      activeSectionKey,
      setActiveSectionKey,
      sectionOptions,
    };
  };

  const getSectionAttrs = useCallback(
    (key: T[number]) => {
      return {
        ref: sectionKeyMap[key].ref,
        "data-key": key,
      };
    },
    [sectionKeyMap],
  );

  const getSectionOptions = useCallback(
    (key: T[number]) => {
      return sectionOptions.find((option) => option.key === key);
    },
    [sectionOptions],
  );

  const getSectionContainerProps = useCallback(
    (key: T[number]) => {
      return {
        sectionRef: sectionKeyMap[key].ref,
        title: keyToLabel(key),
      };
    },
    [sectionKeyMap],
  );

  return {
    getSectionAttrs,
    getActiveKeyProps,
    getSectionContainerProps,
    getSectionOptions,
  };
};

export { useSectionOptions };
