export default {
  // general
  FILTER_SET_CREATE_SUCCESS: "Filter set saved",
  FILTER_SET_DELETE_SUCCESS: "Filter set deleted",
  PREFERRED_VIEW_CREATE_SUCCESS: "Preferred view was saved",
  GET_FILTER_SET_BY_VIEW_SUCCESS: "Your Preferred view was retrieved successfully",
  // errors
  ERROR_SAVING_FILTER_SET: "There was an error saving your filter set",
  ERROR_GETTING_FILTER_SET: "There was an error getting your filter set",
  ERROR_DELETING_FILTER_SET: "There was an error deleting your filter set",
  ERROR_GET_LOOKUP_FILTERS: "There was an error getting the right filters",
};
