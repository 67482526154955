import makeRequest from "rdx/utils/makeRequest";
import { put } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import { camelizeKeys } from "lib/helpers";
import actions from "rdx/actions";
import messages from "../messages";

function* getRoofingS3Assets(action) {
  const { proposalId } = action.payload;
  const { success, data, error } = yield* makeRequest.get("/roofing_assets", { proposal_id: proposalId });
  if (success && data) {
    const dataFormated = camelizeKeys(data.body);
    yield put(actions.setRoofingS3Assets(dataFormated));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ROOFING_ASSETS,
    });
  }

  return null;
}

export default getRoofingS3Assets;
