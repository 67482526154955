import { put, all } from "redux-saga/effects";
import SettingsClient from "util/SettingsClient";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/mentorRatings/messages";

function* updateMentorRating(action) {
  const { success, data, error } = yield* makeRequest.patch(`/mentor_ratings/${action.payload.id}`, action.payload);

  // Send bump_type of next_rating_delay, inital_delay, or user_delay
  const delay = new SettingsClient().data[action.payload.bump];

  if (success && data) {
    const successMessage = action.payload.bump
      ? messages.deferMentorRatingSuccess(delay)
      : messages.UPDATE_MENTOR_RATING_SUCCESS;

    yield all([put(actions.setMentorRating(null)), put(actions.newSuccessEvent({ message: successMessage }))]);
  } else if (error) {
    const errorMessage = action.payload.bump
      ? messages.ERROR_DEFERRING_MENTOR_RATING
      : messages.ERROR_UPDATING_MENTOR_RATING;
    return getErrorActions({
      error,
      message: errorMessage,
    });
  }

  return null;
}

export default updateMentorRating;
