import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../licenses.module.less";

const Divider = () => {
  const { t } = useTranslation("licenses");
  return (
    <div className={styles.innerContainer}>
      <div>
        <h5 className={styles.heading}>{t("our_states")}</h5>
        <h2 className={styles.subheading}>{t("current_states")}</h2>
      </div>
    </div>
  );
};

export default Divider;
