import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getVolumeTiersForEnterprise(action) {
  const { enterpriseId } = action.payload;

  const { success, data, error } = yield* makeRequest.get("/enterprise/volume_tiers_for_enterprise", {
    enterprise_id: enterpriseId,
  });
  if (success && data) {
    const { enterprise_volume_tiers } = data.body;

    yield put(actions.setSelectedVolumeTiers(camelizeKeys(enterprise_volume_tiers)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ENTERPRISES_LIST,
    });
  }
  return null;
}

export default getVolumeTiersForEnterprise;
