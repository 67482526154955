import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";

import { camelizeKeys } from "lib/helpers";
import actions from "../actions";
import messages from "../messages";

function* getFundingUsersAutocomplete(action) {
  const { params } = action.payload;

  const { success, data, error } = yield* makeRequest.get("/users/funding_designers", { ...params });

  if (success && data) {
    yield put(actions.setFundingUsersAutocomplete(camelizeKeys(data.body.users)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.GET_FUNDING_USERS_AUTOCOMPLETE_ERROR,
    });
  }
  return null;
}

export default getFundingUsersAutocomplete;
