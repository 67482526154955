export default {
  CREATE_INTEGRITY_VIOLATION_REQUEST: "[sellerIntegrity] CREATE Integrity Violation",
  GET_INTEGRITY_VIOLATIONS: "[sellerIntegrity] GET Integrity Violations",
  SET_INTEGRITY_VIOLATIONS: "[sellerIntegrity] SET Integrity Violations",
  CLEAR_INTEGRITY_VIOLATIONS: "[sellerIntegrity] CLEAR Integrity Violations",
  UPDATE_INTEGRITY_VIOLATION: "[sellerIntegrity] UPDATE Seller Integrity Violation",
  ADD_MODULE_TO_VIOLATION: "[sellerIntegrity] ADD Module to Integrity Violation",
  REMOVE_MODULE_FROM_VIOLATION: "[sellerIntegrity] REMOVE Module from Integrity Violation",
  DELETE_INTEGRITY_VIOLATION: "[sellerIntegrity] DELETE Integrity Violation",
  // INSERTION_PT (for script, do not remove)
};
