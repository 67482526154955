const commonTabs = [
  {
    key: "m2s",
    label: "M2s",
  },
  {
    key: "ambassador_commissions",
    label: "Ambassador Commissions",
  },
];

const adminTabs = [];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
