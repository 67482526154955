import React, { CSSProperties } from "react";
import Grid from "@mui/material/Grid";
import { useController } from "react-hook-form";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { useSmartFormContext } from "components/SmartForm";
import { TrashcanIcon, ClockIcon } from "components/Icons";
import SmartFormToggleButtonGroup from "components/mui-styled-components/SmartFormElements/SmartFormToggleButtonGroup";
import SmartFormTimePicker from "components/mui-styled-components/SmartFormElements/SmartFormTimePicker";

import { cvar } from "styles";
import * as S from "./styles";

dayjs.extend(utc);

const availabilityDays = [
  { label: "Mon", value: "mon" },
  { label: "Tue", value: "tue" },
  { label: "Wed", value: "wed" },
  { label: "Thu", value: "thu" },
  { label: "Fri", value: "fri" },
  { label: "Sat", value: "sat" },
  { label: "Sun", value: "sun" },
];

const blankAvailabilityData = {
  id: null,
  days: [],
  startTime: "",
  endTime: "",
};

type AvailabilityT = { id?: number | null | undefined; days: string[]; startTime: string; endTime: string };

type SmartFormAvailabilityPickerT = {
  mainLabel?: string;
  availabilityIdx: number;
  availabilityId: number | null | undefined;
  helperText?: string | undefined;
  name?: "homeownerAvailability";
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  mainLabelStyle?: CSSProperties;
  setAvailabilityValue: (availabilityName, availabilityValue: string | string[] | null) => void;
  handleDeleteSection?: (idx: number, id: number | null | undefined) => void;
  setIsFormTouched?: (bool: boolean) => void;
};

const SmartFormAvailabilityPicker = ({
  helperText,
  error,
  setAvailabilityValue,
  handleDeleteSection,
  availabilityIdx,
  availabilityId,
  name = "homeownerAvailability",
  readOnly = false,
  disabled = false,
  mainLabel = "Availability",
  mainLabelStyle = {},
  setIsFormTouched = () => null,
}: SmartFormAvailabilityPickerT) => {
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });
  const { value } = field;

  const setAvailabilityValues = (buttonValues: string[]): void => {
    setAvailabilityValue(`homeownerAvailability[${[availabilityIdx]}].days`, buttonValues);
  };

  const setTimeFieldValue = (timeField: string, timeValue: string | null): void => {
    setAvailabilityValue(timeField, timeValue);
    setIsFormTouched(true);
  };

  return (
    <>
      <S.StyledGridContainer container>
        <Grid item xs={10}>
          <S.StyledSectionLabel style={mainLabelStyle}>{mainLabel}</S.StyledSectionLabel>
        </Grid>
        {handleDeleteSection && (
          <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <S.StyledDeleteSectionButton
              role="button"
              tabIndex={0}
              onClick={() => handleDeleteSection(availabilityIdx, availabilityId)}
              onKeyDown={() => handleDeleteSection(availabilityIdx, availabilityId)}
            >
              <TrashcanIcon fill={cvar("darkest-blue")} height={16} width={16} />
            </S.StyledDeleteSectionButton>
          </Grid>
        )}
        <Grid item xs={12}>
          <SmartFormToggleButtonGroup
            name={`homeownerAvailability[${availabilityIdx}].days`}
            disabled={disabled || readOnly}
            buttonOptions={availabilityDays}
            setButtonGroupValues={setAvailabilityValues}
          />
        </Grid>
      </S.StyledGridContainer>
      {readOnly ? (
        <Grid container sx={{ marginBottom: "24px" }}>
          <Grid item xs={6} sm={3}>
            <S.ReadOnlyLabel>From:</S.ReadOnlyLabel>
            <S.ReadOnlyTimeDisplay>
              <ClockIcon />
              <S.ReadOnlyTime>
                {dayjs.utc(value[availabilityIdx]?.startTime || dayjs()).format("h:mm A")}
                {dayjs.utc(value[availabilityIdx]?.startTime).format("h:mm A") || ""}
              </S.ReadOnlyTime>
            </S.ReadOnlyTimeDisplay>
          </Grid>
          <Grid item xs={6} sm={3}>
            <S.ReadOnlyLabel>To:</S.ReadOnlyLabel>
            <S.ReadOnlyTimeDisplay>
              <ClockIcon />
              <S.ReadOnlyTime>{dayjs.utc(value[availabilityIdx]?.endTime || dayjs()).format("h:mm A")}</S.ReadOnlyTime>
            </S.ReadOnlyTimeDisplay>
          </Grid>
        </Grid>
      ) : (
        <S.StyledGridContainer container spacing={2}>
          <Grid item xs={6} sm={4}>
            <SmartFormTimePicker
              setTimeFieldValue={setTimeFieldValue}
              name={`homeownerAvailability[${availabilityIdx}].startTime`}
              label="Start Time"
              disabled={disabled}
              error={error}
              helperText={helperText}
            />
          </Grid>
          <Grid item xs={6} sm={4} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
            <SmartFormTimePicker
              setTimeFieldValue={setTimeFieldValue}
              name={`homeownerAvailability[${availabilityIdx}].endTime`}
              label="End Time"
              disabled={disabled}
            />
          </Grid>
        </S.StyledGridContainer>
      )}
    </>
  );
};

export default SmartFormAvailabilityPicker;
export { blankAvailabilityData, AvailabilityT };
