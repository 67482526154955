// import { get } from 'lodash';

export default {
  getCurrentState: (state) => state,
  getProjectPeopleDrawerOpen: (state) => state.app.projectPeopleDrawerOpen,
  getZipLookupAddress: (state) => state.app.zipLookupAddress,
  selectPageReferrer: (state) => state.app.pageReferrer,
  selectDisplayZendeskWidget: (state) => state.app.displayZendeskWidget,
  selectRandomAddress: (state) => state.app.randomAddress,
  getShowDefaultM1OptionModal: (state) => state.app.showDefaultM1OptionModal,
};
