import PropTypes from "prop-types";
import { store } from "rdx/configureStore";
import actions from "rdx/actions";
import { Base } from "./Base";

export default class DataGroup extends Base {
  update = ({ label, description, milestoneId }) => {
    const { updateDataGroup } = actions;
    store.dispatch(updateDataGroup({ id: this.props.id, label, description, milestoneId }));
  };

  delete = () => {
    const { deleteDataGroup } = actions;
    store.dispatch(deleteDataGroup({ id: this.props.id }));
  };

  createMetaField = ({ label, description, dataType, required, lookupChoices, oldField }) => {
    const { createMetaField } = actions;
    store.dispatch(
      createMetaField({
        dataGroupId: this.props.id,
        label,
        description,
        dataType,
        required,
        lookupChoices,
        oldField,
      }),
    );
  };

  static types() {
    return PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      description: PropTypes.string,
      metaFields: PropTypes.array, // eslint-disable-line react/forbid-prop-types
      milestoneId: PropTypes.number,
    });
  }
}
