export default {
  GET_MESSAGING_METRICS: "[adminReports] GET Messaging Metrics",
  GET_TICKET_LIST: "[adminReports] GET Ticket List",
  SET_TICKET_LIST: "[adminReports] SET Ticket List",
  SET_MESSAGING_METRICS: "[adminReports] SET Messaging Metrics",
  SET_MESSAGING_METRICS_ROLES: "[adminReports] SET Messaging Metrics Roles",
  SET_MESSAGING_METRICS_FILTERS: "[adminReports] SET Messaging Metrics Filters",
  GET_CONSULTANT_REPORT: "[adminReports] GET Seller Report",
  SET_CONSULTANT_REPORT: "[adminReports] SET Seller Report",
  SET_CONSULTANT_REPORT_USER_LOOKUPS: "[adminReports] SET Seller Report User Lookups",
  RESET_CONSULTANT_REPORT_USER_LOOKUPS: "[adminReports] Reset Seller Report User Lookups",
  GET_ACTIVITY_LOG: "[adminReports] Get Activity Log",
  SET_ACTIVITY_LOG: "[adminReports] Set Activity Log",
  GET_USERS_PROJECTS_AUTOCOMPLETE: "[adminReports] Get Users/Projects AutoComplete",
  SET_USERS_PROJECTS_AUTOCOMPLETE: "[adminReports] Set Users/Projects AutoComplete",
  GET_ACTIVITY_LOG_CSV: "[adminReports] Get Activity Log CSV",
  SET_ACTIVITY_LOG_CSV: "[adminReports] Set Activity Log CSV",
  GET_PREFERRED_PARTNER_CSV: "[adminReports] Get Preferred Partner CSV",
  GET_PREFERRED_PARTNER_CSV_REPORT: "[adminReports] Get Preferred Partner CSV Report",
  SET_PREFERRED_PARTNER_CSV: "[adminReports] Set Preferred Partner CSV",
  // INSERTION_PT (for script, do not remove)
};
