import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "antd";
import styles from "./StepsRow.module.less";

const ContentRow = () => {
  const { t } = useTranslation("ambassador");
  return (
    <Row className={styles.stepsRow}>
      <div className={styles.stepsInfo}>
        <h5 className={styles.stepsHeading}>{t("steps.three_easy_steps")}</h5>
        <div className={styles.stepsHyphen} />
        <h2 className={styles.stepsSub}>{t("steps.how_it_works")}</h2>
        <div className={styles.bulletRow}>
          <div className={styles.bullet}>1</div>
          <h4 className={styles.bulletText}>{t("steps.sign_up_free")}</h4>
        </div>
        <div className={styles.bulletRow}>
          <div className={styles.bullet}>2</div>
          <h4 className={styles.bulletText}>{t("steps.have_a_conversation")}</h4>
        </div>
        <div className={styles.bulletRow}>
          <div className={styles.bullet}>3</div>
          <h4 className={styles.bulletText}>{t("steps.share_the_electric_bill")}</h4>
        </div>
      </div>
      <div className={styles.tabletCol}>
        <div className={styles.tablet} />
      </div>
    </Row>
  );
};

export default ContentRow;
