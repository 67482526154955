import React from "react";
import { useTranslation } from "react-i18next";
import JoinPowurTodayButton from "containers/StaticPages/join/components/JoinPowurTodayButton";

import * as S from "./styles";

type GetSignedUpEnterprisePropsT = {
  onJoinClick: () => void;
};

const GetSignedUpEnterprise = ({ onJoinClick }: GetSignedUpEnterprisePropsT) => {
  const { t }: { t: (textRef: string) => string } = useTranslation(["setter_join"]);

  return (
    <S.GetSignedUpContainer>
      <S.MaxWidthContainer container spacing={3} justifyContent="center">
        <S.HeaderText item xs={12} lg={6}>
          {t("easy_signup")}
        </S.HeaderText>
        <S.ButtonColumn item xs={12} lg={6}>
          <JoinPowurTodayButton onClick={onJoinClick} buttonText="Join Powur Enterprise Today!" maxWidth="475px" />
        </S.ButtonColumn>
      </S.MaxWidthContainer>
    </S.GetSignedUpContainer>
  );
};

export default GetSignedUpEnterprise;
