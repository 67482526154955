import { createSlice } from "@reduxjs/toolkit";
import { CsvJobNameEnum } from "./enums";
import { csvReportsApi } from "./endpoints";
import { TeamUsersCsvT } from "./types";

export type CsvReportsReduxStateT = {
  teamUsersCsv: TeamUsersCsvT;
};

const CsvReportInitialState = {
  createdAt: "",
  id: 0,
  jobName: null,
  jobs: {
    teamUsersCsvJob: {
      results: false,
      state: null,
      ts: "",
    },
  },
  name: "",
  url: null,
  updatedAt: "",
  userId: 0,
};

const initialState: CsvReportsReduxStateT = {
  teamUsersCsv: CsvReportInitialState,
};

export const csvReportsSlice = createSlice({
  name: "csvReports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(csvReportsApi.endpoints?.getTeamUsersCsv.matchFulfilled, (state, { payload }) => {
      state.teamUsersCsv = payload.csv;
    });
    builder.addMatcher(csvReportsApi.endpoints?.getCsvReportById.matchFulfilled, (state, { payload }) => {
      if (payload.report.jobName === CsvJobNameEnum.TEAM_USERS) {
        state.teamUsersCsv = { ...state.teamUsersCsv, ...payload.report };
      }
    });
  },
  selectors: {
    selectTeamUsersCsv: (state) => state.teamUsersCsv,
  },
});

export const { selectTeamUsersCsv } = csvReportsSlice.selectors;

export default csvReportsSlice;
