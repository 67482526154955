import React from "react";
import { IconT } from "types/utils";

export const MaximizeIcon = ({ width = 34, height = 35, fill = "none", ...rest }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 35" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect y="0.5" width="34" height="34" rx="17" fill="#0070F4" fillOpacity="0.11" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3333 17.4999C10.3333 14.3572 10.3333 12.7859 11.3096 11.8096C12.2859 10.8333 13.8573 10.8333 17 10.8333C20.1427 10.8333 21.7141 10.8333 22.6903 11.8096C23.6667 12.7859 23.6667 14.3572 23.6667 17.4999C23.6667 20.6426 23.6667 22.214 22.6903 23.1903C21.7141 24.1666 20.1427 24.1666 17 24.1666C13.8573 24.1666 12.2859 24.1666 11.3096 23.1903C10.3333 22.214 10.3333 20.6426 10.3333 17.4999ZM17.3333 14.1666C17.3333 13.8904 17.5572 13.6666 17.8333 13.6666H20.3333C20.6095 13.6666 20.8333 13.8904 20.8333 14.1666V16.6666C20.8333 16.9427 20.6095 17.1666 20.3333 17.1666C20.0572 17.1666 19.8333 16.9427 19.8333 16.6666V15.3737L14.8738 20.3333H16.1667C16.4428 20.3333 16.6667 20.5571 16.6667 20.8333C16.6667 21.1094 16.4428 21.3333 16.1667 21.3333H13.6667C13.3905 21.3333 13.1667 21.1094 13.1667 20.8333V18.3333C13.1667 18.0571 13.3905 17.8333 13.6667 17.8333C13.9428 17.8333 14.1667 18.0571 14.1667 18.3333V19.6261L19.1262 14.6666H17.8333C17.5572 14.6666 17.3333 14.4427 17.3333 14.1666Z"
        fill="url(#paint0_linear_731_2366)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_731_2366"
          x1="10.7682"
          y1="10.8332"
          x2="23.8736"
          y2="11.0417"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#07469A" />
          <stop offset="1" stopColor="#0070F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MaximizeIcon;
