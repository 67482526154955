import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import actions from "rdx/actions";

function* logout() {
  const { error } = yield* makeRequest.delete("/jwt");

  if (error) {
    yield put(
      actions.setLogoutErrors({
        [error.field]: {
          message: error.message,
        },
      }),
    );
  }

  return null;
}

export default logout;
