import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";

function* getViewedAssets(action) {
  const { category } = action.payload;
  const { success, data, error } = yield* makeRequest.get("/viewable_assets", { category });

  if (success && data) {
    yield all([
      put(
        actions.setViewedAssetsTaskCompletion({
          ...camelizeKeys(data.body),
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: "messages.ERROR_GETTING_ENTERPRISE_APPLICATIONS",
    });
  }

  return null;
}

export default getViewedAssets;
