import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/users/messages";

function* createPermissionsEntity(action) {
  const { success, data, error } = yield* makeRequest.post("/entities", {
    name: action.payload.name,
    description: action.payload.description,
  });

  if (success && data) {
    const newEntity = data.body.entities.reduce((a, b) => {
      return a.id > b.id ? a : b;
    });
    yield all([
      put(actions.setRolesList({ entities: data.body.entities })),
      put(
        actions.newSuccessEvent({
          message: messages.ADD_NEW_PERMISSIONS_ENTITY_SUCCESS,
          payload: { newEntity, permissionId: action.payload.permissionId },
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_NEW_PERMISSIONS_ENTITY,
    });
  }

  return null;
}

export default createPermissionsEntity;
