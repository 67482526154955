import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { VideoModal } from "components/HomepageComponents";
import useModalControls from "hooks/useModalControls";
import styles from "../../Solar.module.less";

const Story = ({ imgClassName, title, videoSrc, content }) => {
  const { t } = useTranslation("form_labels");
  const { visible, open, close } = useModalControls();

  return (
    <div className={styles.story}>
      <div className={styles.storyContent}>
        <div className={[styles.img, styles.bgZoomOnHover].join(" ")}>
          <div className={[styles.bg, imgClassName].join(" ")} />
        </div>
        <div className={styles.card}>
          <div className={styles.titleBox}>
            <h3 className={styles.title}>{content}</h3>
          </div>
          <Button type="primary" className={styles.videoButton} onClick={open}>
            {t("watch_now")} <CaretRightOutlined className={styles.icon} />
          </Button>
        </div>
      </div>
      <VideoModal visible={visible} close={close} title={title} videoSourceUrl={videoSrc} />
    </div>
  );
};

Story.propTypes = {
  imgClassName: PropTypes.string,
  title: PropTypes.string,
  videoSrc: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

Story.defaultProps = {
  imgClassName: "",
};

export default Story;
