export default {
  // EXAMPLE_ACTION_TYPE: "[settings] Example Action Type",

  SET_ADMIN_PLATFORM_SETTINGS: "[settings] SET Admin Platform Settings",
  GET_ADMIN_PLATFORM_SETTINGS_REQUEST: "[settings] GET Admin Platform SETtings Request",
  UPDATE_ADMIN_PLATFORM_SETTING_REQUEST: "[settings] Update Admin Platform SETting Request",
  GET_ADMIN_SERVICE_AREAS_REQUEST: "[settings] GET Admin Service Areas Request",
  SET_ADMIN_SERVICE_AREAS: "[settings] SET Admin Service Areas",
  UPDATE_ADMIN_SERVICE_AREA_REQUEST: "[settings] Update Admin Service Area Request",
  REMOVE_ADMIN_SERVICE_AREA_REQUEST: "[settings] Remove Admin Service Area Request",
  CREATE_ADMIN_SERVICE_AREA_REQUEST: "[settings] Create Admin Service Area Request",
  SET_POWUR_PAIR_WEIGHTS: "[settings] SET Powur Pair Weights",
  GET_POWUR_PAIR_WEIGHTS: "[settings] GET Powur Pair Weights Request",
  UPDATE_POWUR_PAIR_WEIGHTS: "[settings] Update Powur Pair Weights Request",
  GET_ADMIN_VOLUME_TIERS_SETTINGS: "[settings] GET Admin Volume Tier Settings",
  SET_ADMIN_VOLUME_TIERS_SETTINGS: "[settings] SET Admin Volume Tier Settings",
  UPDATE_ADMIN_VOLUME_TIERS_SETTINGS: "[settings] Update Admin Volume Tier Settings",
  GET_ADMIN_ADVANCE_SETTINGS: "[settings] GET Admin Pay Advance Settings Request",
  SET_ADMIN_ADVANCE_SETTINGS: "[settings] SET Admin Pay Advance Settings",
  CREATE_ADMIN_ADVANCE_SETTING: "[settings] Create Admin Pay Advance Setting",
  UPDATE_ADMIN_ADVANCE_SETTING: "[settings] Update Admin Pay Advance Setting",
  REMOVE_ADMIN_ADVANCE_SETTING: "[settings] Remove Admin Pay Advance Setting",
  // INSERTION_PT (for script, do not remove)
};
