import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  notices: {
    list: [],
    paging: {
      pageSize: 0,
      pageNumber: 1,
      itemCount: 0,
    },
    filterToTeamOptions: [],
  },
  inboxTags: {
    roleTags: [],
    userTags: [],
    teamTags: [],
    defaultAssignee: undefined,
  },
  inboxFilters: {
    filters: {},
    sorts: [],
  },
  inboxAutoComplete: {},
  selectedMessage: {},
  projectMessageList: {
    list: [],
    paging: {
      page_size: 0,
      page_number: 1,
      item_count: 0,
    },
    assignableEntities: [],
    topics: [],
  },
  zendeskFormData: {},
  projectMessageReplies: {},
  observingAccount: null,
};

export default {
  inbox: createReducer(initialState, {
    [types.SET_INBOX_LIST](state, action) {
      return {
        ...state,
        notices: {
          ...state.notices,
          ...action.payload,
        },
      };
    },
    [types.SET_INBOX_TAGS](state, action) {
      return {
        ...state,
        inboxTags: {
          ...state.inboxTags,
          ...action.payload,
        },
      };
    },
    [types.SET_SELECTED_MESSAGE_DETAILS](state, action) {
      return {
        ...state,
        selectedMessage: action.payload,
      };
    },
    [types.RESET_SELECTED_MESSAGE_DETAILS](state) {
      return {
        ...state,
        selectedMessage: {},
      };
    },
    [types.RESET_SELECTED_PROJECT_MESSAGES](state) {
      return {
        ...state,
        projectMessageList: {
          list: [],
          paging: {},
          assignableEntities: [],
          topics: [],
        },
        projectMessageReplies: {},
      };
    },
    [types.SET_INBOX_FILTERS](state, action) {
      return {
        ...state,
        inboxFilters: {
          ...state.inboxFilters,
          ...action.payload,
        },
      };
    },
    [types.SET_INBOX_AUTOCOMPLETE_SUGGESTIONS](state, action) {
      const { key, suggestions } = action.payload;
      if (key) {
        return {
          ...state,
          inboxAutoComplete: {
            ...state.inboxAutoComplete,
            [key]: suggestions || [],
          },
        };
      }
      return state;
    },
    [types.SET_PROJECT_MESSAGE_LIST](state, action) {
      return {
        ...state,
        projectMessageList: {
          ...state.projectMessageList,
          ...action.payload,
        },
      };
    },
    [types.SET_ZENDESK_FORM_DATA](state, action) {
      return {
        ...state,
        zendeskFormData: {
          ...state.zendeskFormData,
          ...action.payload,
        },
      };
    },
    [types.SET_PROJECT_MESSAGE_REPLIES](state, action) {
      return {
        ...state,
        projectMessageReplies: action.payload.replies,
      };
    },
    [types.MARK_MESSAGE_AND_REPLIES_AS_VIEWED_REQUEST](state, action) {
      const { messageId } = action.payload;
      const readMessages = state.notices.list.map((message) =>
        messageId === message.id ? { ...message, viewed: true } : message,
      );
      return {
        ...state,
        notices: { ...state.notices, list: readMessages },
      };
    },
    [types.MARK_MESSAGES_AS_VIEWED_REQUEST](state, action) {
      const { messageIds } = action.payload;
      const readMessages = state.notices.list.map((message) => {
        if (messageIds) {
          return messageIds.includes(message.id) ? { ...message, viewed: true } : message;
        }
        return { ...message, viewed: true };
      });
      return {
        ...state,
        notices: { ...state.notices, list: readMessages },
      };
    },
    [types.MARK_MESSAGES_AS_UNVIEWED_REQUEST](state, action) {
      const { messageIds } = action.payload;
      const readMessages = state.notices.list.map((message) => {
        if (messageIds) {
          return messageIds.includes(message.id) ? { ...message, viewed: false } : message;
        }
        return { ...message, viewed: false };
      });
      return {
        ...state,
        notices: { ...state.notices, list: readMessages },
      };
    },
    [types.SET_OBSERVING_ACCOUNT](state, action) {
      return {
        ...state,
        observingAccount: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
