import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Row, Button } from "antd";
import styles from "./PageHeader.module.less";

const PageHeader = (props) => {
  const { setRegisterModalVisible } = props;
  const { t } = useTranslation(["ambassador", "form_labels"]);

  return (
    <Row className={styles.heroImage}>
      <div className={styles.background} />
      <div className={styles.heroOverlay}>
        <span className={styles.heroText}>{t("banner.become_a_solar_ambassador")}</span>
        <span className={styles.heroSubText}>{t("banner.share_the_benefits_of_solar_energy")}</span>
        <Button className={styles.joinButton} type="primary" block onClick={() => setRegisterModalVisible(true)}>
          {t("form_labels:free_sign_up")}
        </Button>
      </div>
    </Row>
  );
};

PageHeader.propTypes = {
  setRegisterModalVisible: PropTypes.func,
};

export default PageHeader;
