import z from "zod";
import { UserItemSchema } from "./users";

export const PastDueHomeownerAppointmentSchema = z.object({
  id: z.number(),
  due: z.string(),
  fastPay: z.boolean(),
  homeowner: z.string(),
  setter: z.string(),
  location: z.string(),
  projectId: z.number(),
  leadId: z.number(),
});

const UserFlowSchema = z.object({
  userCreatedAt: z.string(),
  userId: z.number(),
  name: z.string(),
  email: z.string(),
  sponsorId: z.number(),
  state: z.null(),
  roles: z.object({
    activeSubscribers: z.string(),
    enterpriseOwner: z.string(),
    anyEnterpriseUser: z.string(),
    consultants: z.string(),
  }),
  enrollmentDate: z.string(),
  numberOfLeads: z.number(),
  numberOfInstalls: z.number(),
  numberOfContracts: z.number(),
  numberOfRecruits: z.number(),
  userflowSignature: z.string(),
});

export const JwtResponseBodySchema = z.object({
  jwt: z.string(),
  user: UserItemSchema,
  rbac: z.object({ permissions: z.array(z.object({ name: z.string() })) }),
  banners: z.array(
    z.object({
      label: z.string(),
      props: z.object({ daysRemaining: z.number() }),
      show: z.boolean(),
    }),
  ),
  notices: z.array(z.unknown()),
  pendingEnterpriseApproval: z.boolean(),
  userFlow: UserFlowSchema,
});

export const JwtResponseSchema = z.object({
  status: z.number(),
  body: JwtResponseBodySchema,
});
