import { css, styled } from "@mui/material/styles";
import { cvar } from "styles";

type TProjectDetailText = {
  color?: string;
  isAction?: boolean;
  textSize?: number;
};

export const ProjectBasicInfoWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
}));

export const LeadTextWrapper = styled("span")(() => ({
  display: "block",
  color: cvar("darkest-blue"),
  fontWeight: "700",
  fontSize: "24px",
  lineHeight: "24px",
  marginBottom: "5px",
}));

export const ProjectDetailText = styled("span")(
  ({ color = cvar("dark-blue"), isAction, textSize = 14 }: TProjectDetailText) => ({
    display: "block",
    color,
    fontWeight: "500",
    fontSize: `${textSize}px`,
    cursor: isAction ? "pointer" : "default",
  }),
);

export const linkStyles = css({
  color: cvar("nepal-blue"),
  cursor: "pointer",
});
