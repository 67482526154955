import PropTypes from "prop-types";

export const preferredInstallersSchema = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    kind: PropTypes.string,
    osPartnerId: PropTypes.number,
    workTypes: PropTypes.arrayOf(PropTypes.string),
  }),
);
