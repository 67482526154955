import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/enterpriseEarnings/types";

import getEnterpriseEarnings from "./getEnterpriseEarnings";
import getEnterpriseCompPlan from "./getEnterpriseCompPlan";

// IMPORT_PT (for script, do not remove)

function* watchEnterpriseEarningsSagas() {
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_EARNINGS_LIST, getEnterpriseEarnings);
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_COMP_PLAN, getEnterpriseCompPlan);
  // INSERTION_PT (for script, do not remove)
}

export default watchEnterpriseEarningsSagas;
