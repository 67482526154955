import { put } from "redux-saga/effects";
import SessionClient from "util/SessionClient";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* markTooltipViewed(action) {
  const { userId, type } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/users/${userId}/mark_tooltip_viewed`, { type });

  if (success && data) {
    const session = new SessionClient();

    session.setUserProps({
      ...session.user.props,
      tooltipViewedOn: {
        [type]: new Date().toString(),
      },
    });

    yield put(
      actions.newSuccessEvent({
        message: messages.MARK_TOOLTIP_VIEWED_SUCCESS,
      }),
    );
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_MARKING_TOOLTIP_VIEWED,
    });
  }

  return null;
}

export default markTooltipViewed;
