import types from "rdx/modules/notifications/types";
import createAction from "rdx/utils/createAction";

export default {
  getDashboardNotificationsWidget: (payload) => createAction(types.GET_DASHBOARD_NOTIFICATIONS_WIDGET, payload),
  setDashboardNotifications: (payload) => createAction(types.SET_DASHBOARD_NOTIFICATIONS, payload),
  archiveDashboardNotifications: (payload) => createAction(types.ARCHIVE_DASHBOARD_NOTIFICATIONS_WIDGET, payload),
  unarchiveDashboardNotifications: (payload) => createAction(types.UNARCHIVE_DASHBOARD_NOTIFICATIONS_WIDGET, payload),
  getAllNotifications: (payload) => createAction(types.GET_ALL_NOTIFICATIONS, payload),
  setAllNotifications: (payload) => createAction(types.SET_ALL_NOTIFICATIONS, payload),
  setNotificationFilters: (payload) => createAction(types.SET_NOTIFICATION_FILTERS, payload),
  // INSERTION_PT (for script, do not remove)
};
