import React, { useContext, useState, useEffect } from "react";
// import PropTypes from "prop-types";
import { Button, Spin, Form, Row, Col, Input, Avatar, Select } from "antd";
import { buttonProps } from "lib/props";
import SettingsClient from "util/SettingsClient";
import { formatPhoneNumber } from "lib/helpers";
import FileUploader from "components/FileUploader/FileUploader";
import FormErrorMessage from "components/FormElements/FormErrorMessage";
import { AmbassadorDashboardContext as context } from "containers/Ambassador/dashboard";
import ChangeEmail from "./ChangeEmailSection";
import ChangePassword from "./changePassword";
import UpgradeToSeller from "./UpgradeToSeller";

const { Option } = Select;

const Profile = () => {
  const {
    styles,
    user,
    navigate,
    tabKeys,
    fetching,
    updatingPassword,
    profile,
    getProfile,
    updateProfile,
    updatePassword,
    latestMessage,
  } = useContext(context);
  const settings = new SettingsClient();

  const [avatarUrl, setAvatarUrl] = useState(false);

  const [form] = Form.useForm();
  const uploaderContents = <div className={styles.uploadButton}>Select</div>;

  useEffect(() => {
    getProfile();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (profile) {
      const formattedPhone = formatPhoneNumber(profile.phone, { withDashes: true });
      form.setFieldsValue({
        firstName: profile.firstName,
        lastName: profile.lastName,
        phone: formattedPhone,
        email: profile.email,
        locale: profile.locale,
      });
      setPhoneValue(formattedPhone);
      setAvatarUrl(profile.avatar?.thumb || null);
    }
  }, [profile, form]);

  const handleFormSubmit = (values) => {
    if (profile.id === user.props?.id) {
      const params = {
        user: { ...values },
      };
      if (values.avatarUrl) params.user.profileImageUrl = values.avatarUrl;
      updateProfile(params);
    }
  };

  const getInitials = (name) => {
    if (!name) return null;
    return name.split(/-| /)[0].substring(0, 1) + name.split(/-| /)[name.split(/-| /).length - 1].substring(0, 1);
  };

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);

  const generateOptions = (opts, transform) =>
    Object.keys(opts).map((key) => {
      const value = opts[key];
      const text = transform ? transform(key) : key;
      return (
        <Option key={value} value={value} label={text}>
          <span className={styles.selectLabel}>{text}</span>
        </Option>
      );
    });

  const getAvatarUrl = (newUrl) => {
    form.setFieldsValue({ avatarUrl: newUrl });
    setAvatarUrl(newUrl);
  };

  const isPhoneFieldValid = form.getFieldValue("phone")?.length !== 12 || false;
  const [isPhoneFieldRequired, setIsPhoneFieldRequired] = useState(isPhoneFieldValid);

  const [phoneValue, setPhoneValue] = React.useState("");

  const phoneFieldValueValidator = (field, e) => {
    const max_chars = 12;
    let newVal = e.target.value;
    const reg = new RegExp("^[0-9]+$");
    newVal = formatPhoneNumber(e.target.value);
    newVal = newVal.replace("(", "").replace(") ", "-");
    if (newVal.length > max_chars) newVal = newVal.slice(0, -1);
    if (!reg.test(newVal[newVal.length - 1])) newVal = newVal.slice(0, -1);
    form.setFieldsValue({ [field]: newVal });
  };

  const handleValuesChange = (e) => {
    const key = Object.keys(e);
    if (key.includes("phone")) {
      phoneFieldValueValidator("phone", e.phone);
      setIsPhoneFieldRequired(isPhoneFieldValid);
    }
  };

  return (
    <div className={styles.profileTab}>
      <div className={styles.banner}>
        <div className={styles.title}>Ambassador Profile</div>
      </div>
      <Form
        form={form}
        name="ambassador-profile"
        className={styles.form}
        requiredMark={false}
        colon={false}
        layout="vertical"
        onFinish={handleFormSubmit}
        onChange={handleValuesChange}
      >
        {fetching && (
          <div className={styles.fetching}>
            <Spin className={styles.spin} />
          </div>
        )}
        <Row gutter={8}>
          <Col span={8}>
            <div className={styles.profileUploadContainer}>
              <div className={styles.avatarContainer}>
                <span className={styles.label}>Profile Image</span>
                <Avatar
                  className={styles.avatar}
                  shape="square"
                  size={100}
                  src={avatarUrl}
                  icon={getInitials(user.props?.fullName)}
                />
              </div>
              <FileUploader
                onFinished={getAvatarUrl}
                apiMode="user_image"
                height="32px"
                customButtonContents={{
                  ready: uploaderContents,
                  loading: uploaderContents,
                  inProgress: uploaderContents,
                  finished: uploaderContents,
                  error: uploaderContents,
                }}
              />
              <Form.Item name={["avatarUrl"]} style={{ height: "0px" }}>
                <Input type="hidden" />
              </Form.Item>
            </div>
          </Col>
          <Col span={16}>
            <Form.Item
              name={["firstName"]}
              className={styles.formItem}
              label={<span className={styles.label}>First Name</span>}
              validateTrigger={["onSubmit"]}
              rules={[
                {
                  required: true,
                  message: <FormErrorMessage message="First name is required." />,
                },
              ]}
            >
              <Input className={styles.input} placeholder="FIRST NAME" />
            </Form.Item>
            <Form.Item
              name={["lastName"]}
              className={styles.formItem}
              validateTrigger={["onSubmit"]}
              label={<span className={styles.label}>Last Name</span>}
              rules={[
                {
                  required: true,
                  message: <FormErrorMessage message="Last name is required." />,
                },
              ]}
            >
              <Input className={styles.input} placeholder="LAST NAME" />
            </Form.Item>
            <Form.Item
              name={["phone"]}
              validateTrigger={["onChange"]}
              className={styles.formItem}
              value={phoneValue}
              onChange={(e) => phoneFieldValueValidator("phone", e)}
              label={<span className={styles.label}>Phone</span>}
              rules={[
                {
                  pattern: /^[0-9.\-+\s]+$/,
                  message: <span style={{ fontSize: "10px" }}>* Phone number may only contain digits or dashes.</span>,
                },
                {
                  min: 12,
                  required: isPhoneFieldRequired,
                  message: <span style={{ fontSize: "10px" }}>* Phone number must contain 10 digits.</span>,
                },
              ]}
            >
              <Input className={styles.input} placeholder="PHONE" />
            </Form.Item>
            <Form.Item
              name={["locale"]}
              className={styles.formItem}
              label={<span className={styles.label}>Preferred Language</span>}
            >
              <Select
                className={styles.select}
                placeholder="LANGUAGE"
                // size="large"
              >
                {settings.data.locales && generateOptions(settings.data.locales, capitalize)}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item className={styles.formItem}>
              <Button className={styles.submitButton} type="primary" block htmlType="submit">
                Update Account
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <ChangeEmail profile={profile} />
      <ChangePassword loading={updatingPassword} updatePassword={updatePassword} latestMessage={latestMessage} />
      <UpgradeToSeller loading={updatingPassword} latestMessage={latestMessage} userId={user.props?.id} />
      <div className={styles.back} {...buttonProps(() => navigate({ tab: tabKeys[0] }))}>
        Back to Dashboard
      </div>
    </div>
  );
};

export default Profile;
