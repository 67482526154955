import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enterpriseApplications/messages";
import { camelizeKeys } from "lib/helpers";

function* getLaborApplicationStatus(action) {
  const { payload } = action;
  const { success, data, error } = yield* makeRequest.get(`/enterprise_applications/${payload}/slug`);

  if (success && data) {
    yield all([
      put(
        actions.setLaborApplicationStatus({
          ...camelizeKeys(data.body),
          user: data.body,
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.GET_LABOR_APPLICATION_STATUS_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: "error",
    });
  }

  return null;
}

export default getLaborApplicationStatus;
