import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys, keysToSnakeCase } from "lib/helpers";
import messages from "../messages";

function* getProviderLeadsKanban(action) {
  const params = keysToSnakeCase({ ...action.payload, isKanban: true });

  const { success, data, error } = yield* makeRequest.get("/provider_leads", params);
  if (success && data) {
    const body = camelizeKeys(data.body);
    const { kpi, providerLeads } = body;
    if (body) {
      yield put(actions.setProviderLeadsKanban({ kpi, providerLeads }));
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROVIDER_LEADS_KANBAN,
    });
  }
  return null;
}
export default getProviderLeadsKanban;
