import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";

// NOTE: check
dayjs.extend(localeData);

const formatMonth = (number: number): string | number => (number < 10 ? `0${number}` : number);

export const getMonths = (labelFormat = "MMMM") =>
  dayjs()
    .localeData()
    .months()
    .map((month, idx) => ({
      key: month,
      label: dayjs().month(idx).format(labelFormat),
      value: formatMonth(idx + 1),
    }));
