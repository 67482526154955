import PropTypes from "prop-types";

export const mentorSchema = PropTypes.shape({
  email: PropTypes.string,
  fullName: PropTypes.string,
  id: PropTypes.number,
  phone: PropTypes.string,
});

export const mentorDefaults = {
  email: "",
  fullName: "",
  id: 0,
  phone: "",
};
