import { put, select, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import { keysToCamelCase } from "lib/helpers";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/workflows/selectors";
import messages from "../../messages";

function* updateWorkflow(action) {
  const { id, title, active } = action.payload;
  const { success, data, error } = yield* makeRequest.patch(`/workflows/${id}`, {
    title,
    active,
  });
  if (success && data) {
    const newWorkflow = keysToCamelCase(data.body.workflow);
    const workflows = yield select(selectors.getWorkflows);
    const newWorkflows = workflows.map((flow) => (flow.id === newWorkflow.id ? newWorkflow : flow));
    yield all([
      put(actions.setWorkflowDetails(newWorkflow)),
      put(actions.setWorkflows(newWorkflows)),
      put(
        actions.newSuccessEvent({
          message: messages.WORKFLOW_UPDATE_SUCCESS,
          id,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_WORKFLOW,
    });
  }
  return null;
}

export default updateWorkflow;
