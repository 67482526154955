import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "rdx/modules/buildPartnerApplications/messages";

function* makePendingBuildPartnerApplication(action) {
  const { id } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/labor_partner_applications/${id}/respond`, {
    decision: "pending",
  });

  if (success && data) {
    const applicationDetail = yield select(selectors.selectBuildPartnerApplicationDetail);

    yield all([
      put(
        actions.setBuildPartnerApplicationDetail({
          ...applicationDetail,
          decision: "pending",
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.MAKE_PENDING_BUILD_PARTNER_APPLICATION_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_MAKING_PENDING_BUILD_PARTNER_APPLICATION,
    });
  }

  return null;
}

export default makePendingBuildPartnerApplication;
