import React from "react";
import PropTypes from "prop-types";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import User from "models/User";
import ConferenceBadgeAvatar from "components/Avatars/BadgeAvatar/ConferenceBadgeAvatar";
import MentorStars from "./MentorStars";
import styles from "../UserCards.module.less";

const MentorInfoAndAvatar = (props) => {
  const { user, reverse, avatarSize } = props;

  const avatar = () => {
    return user.avatar && user.avatar.thumb ? (
      <ConferenceBadgeAvatar iconSize={{ width: "25px", height: "25px" }} size={avatarSize || 80} user={user} />
    ) : (
      <Avatar className={styles.avatar} size={avatarSize || 80} shape="circle" icon={<UserOutlined />} />
    );
  };

  const containerStyle = () => (reverse ? styles.mentorContainerR : styles.mentorContainer);

  const body = (
    <div className={reverse ? styles.nameContainerR : styles.nameContainer}>
      <div className={styles.mentorNameBold}>{user.fullName}</div>
      {user.address && (
        <div className={styles.medium}>
          {user.address.city}, {user.address.state}
        </div>
      )}
      <MentorStars count={user?.mentorRatings?.totalCount} average={user?.mentorRatings?.meanOfAvgs} numberDisplay />
    </div>
  );

  return (
    <div className={containerStyle()}>
      <div className={styles.avatarContainer}>{avatar()}</div>
      {body}
    </div>
  );
};

MentorInfoAndAvatar.propTypes = {
  user: User.types(),
  reverse: PropTypes.bool,
  avatarSize: PropTypes.number,
};

export default MentorInfoAndAvatar;
