import {
  ViolationT,
  ViolationReasonT,
  UserSellerIntegrityT,
} from "containers/Admin/users/components/UserDetailDrawer/types";

export default {
  selectSellerIntegrityData: (state): UserSellerIntegrityT => state.sellerIntegrity,
  selectIntegrityViolations: (state): ViolationT[] => state.sellerIntegrity.userViolations.violations,
  selectIntegrityViolationCount: (state): number => state.sellerIntegrity.userViolations.violationCount,
  selectIntegrityViolationsReasons: (state): ViolationReasonT[] => state.sellerIntegrity.allReasons,
};
