export default {
  CREATE_COMPLIANCE_ISSUE_SUCCESS: "Compliance incident recorded.",
  UPDATE_COMPLIANCE_ISSUE_SUCCESS: "Compliance incident updated.",
  DELETE_COMPLIANCE_ISSUE_SUCCESS: "Compliance incident deleted.",

  // errors
  ERROR_CREATING_COMPLIANCE_ISSUE: "There was an error creating this compliance issue",
  ERROR_UPDATING_COMPLIANCE_ISSUE: "There was an error updating this compliance issue",
  ERROR_DELETING_COMPLIANCE_ISSUE: "There was an error deleting this compliance issue",
  ERROR_GETTING_COMPLIANCE_ISSUES: "There was an error getting compliance issues",
};
