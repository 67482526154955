import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";
import types from "../types";
import getAdminRoofingProposals from "./getAdminRoofingProposals";
import updateRoofingProposal from "./updateRoofingProposal";
import getRoofingCsv from "./getRoofingCsv";
import getRoofingCsvReport from "./getRoofingCsvReport";
import getAdminRoofingProposal from "./getAdminRoofingProposal";
import getRoofingUsersAutocomplete from "./getRoofingUsersAutocomplete";
import postRoofingS3Asset from "./postRoofingS3Asset";
import deleteRoofingS3Asset from "./deleteRoofingS3Asset";
import getRoofingS3Assets from "./getRoofingS3Assets";

function* watchRoofingProposalsSaga() {
  yield trackRequests(takeEvery, types.GET_ADMIN_ROOFING_PROPOSALS, getAdminRoofingProposals);
  yield trackRequests(takeEvery, types.UPDATE_ROOFING_PROPOSAL, updateRoofingProposal);
  yield trackRequests(takeEvery, types.GET_ROOFING_PROPOSAL, getAdminRoofingProposal);
  yield trackRequests(takeEvery, types.GET_ROOFING_CSV, getRoofingCsv);
  yield trackRequests(takeEvery, types.GET_ROOFING_CSV_REPORT, getRoofingCsvReport);
  yield trackRequests(takeEvery, types.GET_ROOFING_USERS_AUTOCOMPLETE, getRoofingUsersAutocomplete);
  yield trackRequests(takeEvery, types.POST_ROOFING_S3_ASSET, postRoofingS3Asset);
  yield trackRequests(takeEvery, types.DELETE_ROOFING_S3_ASSET, deleteRoofingS3Asset);
  yield trackRequests(takeEvery, types.GET_ROOFING_S3_ASSETS, getRoofingS3Assets);
  // INSERTION_PT (for script, do not remove)
}

export default watchRoofingProposalsSaga;
