import { put } from "redux-saga/effects";
import { camelizeKeys } from "lib/helpers";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* getAllNotifications(action) {
  const params = {
    ...action.payload,
  };
  const { success, data, error } = yield* makeRequest.get("/crm_notifications", params);

  if (success && data) {
    const { body } = data;
    if (body.crm_notifications) {
      yield put(actions.setAllNotifications(camelizeKeys(body)));
    }
    if (body.filters) {
      yield put(actions.setNotificationFilters(body));
    }
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_NOTIFICATIONS,
    });
  }
  return null;
}

export default getAllNotifications;
