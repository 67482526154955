import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import User from "models/User";
// Components
import { Header, Footer, GetQuoteModal, UtilityBillModal, SuccessModal } from "components/HomepageComponents";
import SectionHeader from "./components/SectionHeader";
import HeroImage from "./components/HeroImage";
import AnchorNavBar from "./components/SubNav";
import Leadership from "./components/Leadership";
import Mission from "./components/Mission/Index";

import styles from "./Team.module.less";

const { Content } = Layout;

const Team = (props) => {
  const { match, user } = props;
  const { t } = useTranslation("team");
  const location = useLocation();
  const description =
    "Powur can help save you thousands on your electric bill. Ready to see if solar power is the right choice for your pocket book and our planet? Let us prepare a custom quote so you can see the difference. Request A Quote Today!";

  useEffect(() => {
    if (location.hash?.includes("#mission")) {
      document.getElementById("mission").scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout className={styles.pageLayout}>
      <Helmet>
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
        <title>Meet the Team behind Powur and what drives us every day</title>
        <meta name="title" content="Meet the Team behind Powur and what drives us every day" />
        <meta
          name="description"
          content="Our Leadership and Executive Team is committed to leaving a better world than we found for future generations. Learn more about our vision, mission, and values."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://powur.com/about" />
        <meta property="og:title" content="Meet the Team behind Powur and what drives us every day" />
        <meta
          property="og:description"
          content="Our Leadership and Executive Team is committed to leaving a better world than we found for future generations. Learn more about our vision, mission, and values."
        />
        <meta property="og:image" content="/img/powur_fb_01.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://powur.com/about" />
        <meta property="twitter:title" content="Meet the Team behind Powur and what drives us every day" />
        <meta
          property="twitter:description"
          content="Our Leadership and Executive Team is committed to leaving a better world than we found for future generations. Learn more about our vision, mission, and values."
        />
        <meta property="twitter:image" content="/img/powur_fb_01.jpg" />
      </Helmet>
      <Header user={user} />
      <Content className={styles.content}>
        <HeroImage />
        <AnchorNavBar />
        <SectionHeader text={t("body.meet_the_powur_team")} sectionID="team" />
        <Leadership />
        <SectionHeader text={t("vision_mission_values")} sectionID="mission" />
        <Mission />
        <SectionHeader
          text={t("join_body.help_shape_our_culture")}
          subText={t("join_body.making_things_possible_together")}
          sectionID="join"
        />
      </Content>
      <Footer user={match?.params?.user} />
      <GetQuoteModal />
      <UtilityBillModal />
      <SuccessModal />
    </Layout>
  );
};

Team.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      user: PropTypes.string,
    }),
  }),
  user: User.types(),
};

export default Team;
