import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Popconfirm } from "antd";
import { BorderedCloseIcon } from "@icons";
import CloseButton from "components/Buttons/CloseButton";
import GradientButton from "components/Buttons/GradientButton";
import styles from "./Modal.module.less";

const ModalContainer = (props) => {
  const {
    // adding className to allow composition with @emotion */
    className,
    visible,
    headerIcon,
    title,
    headerClassName,
    headingClassName,
    modalClassName,
    popconfirmClassName,
    handleSubmit,
    submitText,
    submitDisabled,
    handleCancel,
    cancelText,
    cancelDisabled,
    popconfirmCancelDisabled,
    handleClear,
    clearText,
    clearDisabled,
    withClearButton,
    withoutCancel,
    withPopconfirm,
    confirmText,
    confirmContent,
    popconfirmOkDisabled,
    overridePopconfirmCancel,
    popconfirmCancelText,
    popconfirmVisible,
    setPopconfirmVisible,
    centerButtons,
    maskClosable,
    maskBackground,
    zIndex,
    forceRender,
    getContainer,
    destroyOnClose,
    noFooter,
    noSubmit,
    customHeader,
    headerCentered,
    customFooter,
    xClosable,
    width,
    centered,
    footerText,
    footerSubText,
    cancelButtonStyles,
    onClose,
    closeIcon,
    gradientButtons,
    children, // eslint-disable-line react/prop-types
    ...rest // any other Antd modal props passed directly
  } = props;

  const getHeaderClass = () => {
    if (headerClassName) return headerClassName;
    return headerCentered ? styles.headerCentered : styles.header;
  };

  const header = (
    <div className={getHeaderClass()}>
      <span className={styles.headerIcon}>{headerIcon}</span>
      <span className={headingClassName || styles.heading}>{title}</span>
    </div>
  );

  const getCancelButton = () => {
    if (cancelText) {
      return (
        <Button
          className={styles.customCancel}
          type="primary"
          disabled={cancelDisabled}
          ghost
          onClick={handleCancel}
          style={cancelButtonStyles}
        >
          <span className={styles.text}>{cancelText}</span>
        </Button>
      );
    }
    if (gradientButtons) {
      return <CloseButton buttonTypeStyle="gradient" height={32} width={32} xSize={14} action={handleCancel} />;
    }
    return (
      <Button
        className={styles.defaultCancel}
        type="primary"
        disabled={cancelDisabled}
        ghost
        onClick={handleCancel}
        style={cancelButtonStyles}
      >
        <BorderedCloseIcon fill="var(--dark-blue)" height="32px" width="32px" />
      </Button>
    );
  };

  const confirmButton = gradientButtons ? (
    <GradientButton
      className={styles.gradientSubmit}
      type="primary"
      disabled={submitDisabled}
      onClick={withPopconfirm ? () => setPopconfirmVisible(true) : handleSubmit}
    >
      <span className={styles.text}>{submitText}</span>
    </GradientButton>
  ) : (
    <Button
      className={styles.submit}
      type="primary"
      disabled={submitDisabled}
      onClick={withPopconfirm ? () => setPopconfirmVisible(true) : handleSubmit}
    >
      <span className={styles.text}>{submitText}</span>
    </Button>
  );

  const footer = (
    <div className={styles.footerContainer}>
      {footerText && <div className={styles.footerText}>{footerText}</div>}
      {footerSubText && <div className={styles.footerSubText}>{footerSubText}</div>}
      <div className={centerButtons ? styles.centeredFooter : styles.footer}>
        {!withoutCancel && getCancelButton()}
        {withClearButton && (
          <Button
            className={clearText ? styles.customCancel : styles.defaultCancel}
            type="primary"
            disabled={clearDisabled}
            ghost
            onClick={handleClear}
          >
            <span className={styles.text}>{clearText}</span>
          </Button>
        )}

        {withPopconfirm ? (
          <Popconfirm
            okText={confirmText}
            cancelText={overridePopconfirmCancel ? popconfirmCancelText : "Cancel"}
            open={popconfirmVisible}
            okButtonProps={{ type: "primary", disabled: popconfirmOkDisabled }}
            cancelButtonProps={{ disabled: popconfirmCancelDisabled }}
            onConfirm={handleSubmit}
            onCancel={() => setPopconfirmVisible(false)}
            title={confirmContent}
            icon={<></>}
            placement="topRight"
            overlayClassName={popconfirmClassName}
          >
            {confirmButton}
          </Popconfirm>
        ) : (
          <>{!noSubmit && confirmButton}</>
        )}
      </div>
    </div>
  );

  const footerType = () => {
    if (customFooter) {
      return customFooter;
    }
    if (noFooter) {
      return null;
    }
    return footer;
  };

  return (
    <Modal
      className={
        // adding className to allow composition with @emotion */
        modalClassName ? [modalClassName, styles.modal, className].join(" ") : [styles.modal, className].join(" ")
      }
      open={visible}
      onCancel={onClose || handleCancel}
      onOk={handleSubmit}
      maskClosable={maskClosable}
      maskStyle={{ backgroundColor: maskBackground }}
      zIndex={zIndex}
      title={customHeader || header}
      footer={footerType()}
      forceRender={forceRender}
      getContainer={getContainer}
      destroyOnClose={destroyOnClose}
      closable={xClosable}
      width={width}
      centered={centered}
      closeIcon={closeIcon}
      {...rest}
    >
      {children}
    </Modal>
  );
};

ModalContainer.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  headerIcon: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  headerClassName: PropTypes.string,
  headingClassName: PropTypes.string,
  modalClassName: PropTypes.string,
  popconfirmClassName: PropTypes.string,
  handleSubmit: PropTypes.func,
  submitDisabled: PropTypes.bool,
  submitText: PropTypes.string,
  handleCancel: PropTypes.func,
  onClose: PropTypes.func,
  cancelText: PropTypes.string,
  cancelDisabled: PropTypes.bool,
  handleClear: PropTypes.func,
  clearText: PropTypes.string,
  clearDisabled: PropTypes.bool,
  withClearButton: PropTypes.bool,
  withoutCancel: PropTypes.bool,
  withPopconfirm: PropTypes.bool,
  confirmText: PropTypes.string,
  confirmContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  popconfirmVisible: PropTypes.bool,
  overridePopconfirmCancel: PropTypes.bool,
  popconfirmCancelDisabled: PropTypes.bool,
  popconfirmOkDisabled: PropTypes.bool,
  popconfirmCancelText: PropTypes.string,
  setPopconfirmVisible: PropTypes.func,
  zIndex: PropTypes.number,
  forceRender: PropTypes.bool,
  maskClosable: PropTypes.bool,
  centerButtons: PropTypes.bool,
  noFooter: PropTypes.bool,
  getContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.bool]),
  destroyOnClose: PropTypes.bool,
  noSubmit: PropTypes.bool,
  customHeader: PropTypes.node,
  customFooter: PropTypes.node,
  xClosable: PropTypes.bool,
  centered: PropTypes.bool,
  headerCentered: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  footerText: PropTypes.string,
  footerSubText: PropTypes.string,
  cancelButtonStyles: PropTypes.objectOf(PropTypes.string),
  closeIcon: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  maskBackground: PropTypes.string,
  gradientButtons: PropTypes.bool,
};

ModalContainer.defaultProps = {
  visible: false,
  headerIcon: <></>,
  title: "Title",
  handleSubmit: () => null,
  submitText: "Submit",
  submitDisabled: false,
  handleCancel: () => null,
  cancelDisabled: false,
  handleClear: () => null,
  clearText: "Clear",
  clearDisabled: false,
  withClearButton: false,
  withoutCancel: false,
  withPopconfirm: false,
  confirmText: "Confirm",
  zIndex: 9999,
  forceRender: false,
  centerButtons: false,
  maskClosable: true,
  maskBackground: "var(--mask-blue-a75)",
  getContainer: undefined,
  destroyOnClose: true,
  customHeader: false,
  customFooter: false,
  xClosable: true,
  centered: true,
  footerText: "",
  footerSubText: "",
  cancelButtonStyles: {},
  closeIcon: <BorderedCloseIcon fill="var(--dark-blue)" height="22px" width="22px" />,
  gradientButtons: false,
};

export default ModalContainer;
