export default {
  // EXAMPLE_ACTION_TYPE: "[filterSETs] Example Action Type",
  CREATE_FILTER_SET_REQUEST: "[filterSETs] Create Filter Set Request",
  CREATE_PREFERRED_VIEW_REQUEST: "[filterSETs] Create preferred view Request",
  DELETE_FILTER_SET_REQUEST: "[filterSETs] Delete Filter Set Request",
  SET_FILTER_SETS: "[filterSETs] Set Filter Sets",
  UPDATE_PREFERRED_VIEW: "[filterSETs] UPDATE preferred view Request",
  UPDATE_PREFERRED_VIEW_BULK: "[filterSETs] UPDATE preferred view bulk Request",
  SET_CLEAR_PREFERRED_VIEW: "[filterSETs] SET Clear Preferred view Request",
  SET_PREFERRED_VIEW: "[filterSETs] SET Preferred view Request",
  DELETE_PREFERRED_VIEW_FILTER: "[filterSETs] Delete  Preferred View Filter",
  GET_FILTER_SETS_BY_VIEW: "[filterSETs] GET Filter Sets By View",
  GET_PREFERRED_VIEW_REQUEST: "[filterSETs] GET preferred view Request",
  GET_SELECTED_LOOKUP_FILTERS: "[filterSETs] GET Selected Lookup Filters",
  SET_PREFERRED_VIEW_ALLOW_UPDATE: "[filterSETs] SET Preferred View Allow Update",
  SET_PREFERRED_VIEW_IS_TOUCHED: "[filterSETs] SET Preferred View isTouched",
  // INSERTION_PT (for script, do not remove)
};
