import React from "react";
import type { GridColDef } from "@mui/x-data-grid-pro";
import MentorInfoAndAvatar from "components/UserCards/Mentor";
import { T3PartnerTableDataT } from "components/Drawers/SelectT3PartnerDrawer/types";

export const UserDetailCol: GridColDef<T3PartnerTableDataT> = {
  field: "t3Partner",
  minWidth: 275,
  maxWidth: 348,
  flex: 2,
  renderCell: ({ row }) => {
    return <MentorInfoAndAvatar user={row} avatarSize={65} />;
  },
};
