import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* recruitRejectPendingSeat(action) {
  const { registrationSlug } = action.payload;

  const { success, data, error } = yield* makeRequest.patch("/pending_seats/recruit_reject", {
    registration_slug: registrationSlug,
  });

  if (success && data) {
    yield put(
      actions.newSuccessEvent({
        message: messages.RECRUIT_REJECT_SUCCESS,
        payload: data,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.RECRUIT_REJECT_ERROR,
    });
  }

  return null;
}

export default recruitRejectPendingSeat;
