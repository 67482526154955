export default {
  LOGIN: "[auth] Login",
  OAUTH: "[auth] OAuth",
  SET_AUTH_URI: "[auth] SET OAuth URI",
  LOGOUT: "[auth] Logout",
  SET_LOGIN_ERRORS: "[auth] SET Login Errors",
  SET_LOGOUT_ERRORS: "[auth] SET Logout Errors",
  CLEAR_LOGIN_ERRORS: "[auth] Clear Login Errors",
  PASSWORD_RESET_REQUEST: "[auth] Password Reset Request",
  SET_RESET_PASSWORD_ERRORS: "[auth] SET Reset Password Errors",
  CLEAR_RESET_PASSWORD_ERRORS: "[auth] Clear Reset Password Errors",
  CREATE_NEW_PASSWORD_REQUEST: "[auth] Create New Password Request",
  SET_NEW_PASSWORD_ERRORS: "[auth] SET New Password Errors",
  CLEAR_NEW_PASSWORD_ERRORS: "[auth] Clear New Password Errors",
  ACCEPTED_LATEST_TOS_REQUEST: "[auth] Accepted Latest Terms of Service Request",
  GET_LATEST_TOS_REQUEST: "[auth] Get Latest Terms of Service Request",
  SET_LATEST_TOS: "[auth] SET Latest Terms of Service",
  SET_TERMS_OF_SERVICE_MODAL_VISIBLE: "[auth] SET Terms of Service Modal Visible",
  // INSERTION_PT (for script, do not remove)
};
