import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/settings/selectors";
import messages from "rdx/modules/settings/messages";

function* removeAdminServiceArea(action) {
  const { success, data, error } = yield* makeRequest.delete(`/service_areas/${action.payload.id}`, action.payload);

  if (success && data) {
    const serviceAreas = yield select(selectors.getAdminServiceAreas);
    const out = serviceAreas.slice().filter((s) => s.id !== action.payload.id);
    yield all([
      put(actions.setAdminServiceAreas(out)),
      put(
        actions.newSuccessEvent({
          message: messages.SERVICE_AREA_REMOVE_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_REMOVING_SERVICE_AREA,
    });
  }

  return null;
}

export default removeAdminServiceArea;
