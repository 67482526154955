import { PermissionT } from "../types";

export const usersRead = (permissions: PermissionT[]): boolean => {
  const p = permissions.find(
    (permission) => permission.name === "user_management_read_only" || permission.name === "user_management",
  );
  return !!p && !!p?.actions?.includes("read");
};

export default usersRead;
