export default {
  PASSWORD_UPDATE_SUCCESS: "User password updated successfully.",
  GET_PROFILE_SUCCESS: "User profile retrieved successfully.",
  PROFILE_UPDATE_SUCCESS: "User profile updated successfully.",
  CREATE_LICENSE_SUCCESS: "Licenses information submitted.",
  UPDATE_LICENSE_SUCCESS: "License information updated.",
  DELETE_LICENSE_SUCCESS: "License information removed.",
  CREATE_MENTOR_STATUS_CHANGE_SUCCESS: "Sales Partner break submitted.",
  DELETE_MENTOR_STATUS_CHANGE_SUCCESS: "Sales Partner break deleted.",
  CREATE_EMAIL_UPDATE_SUCCESS: "Email update requested. Please check the new email to confirm.",
  DELETE_EMAIL_UPDATE_SUCCESS: "Email update request cancelled.",
  VERIFY_EMAIL_UPDATE_SUCCESS: "Email update request verified",
  RESEND_EMAIL_UPDATE_SUCCESS: "Verification email resent to new email.",
  UPDATE_MENTOR_PREFERENCES_SUCCESS: "Sales Partner preferences updated.",

  ERROR_GETTING_PROFILE: "There was an error getting profile details",
  ERROR_UPDATING_PROFILE: "There was an error updating profile details",
  ERROR_UPDATING_PASSWORD: "There was an error attempting to update password",
  ERROR_CREATING_LICENSE: "There was an error submitting license information",
  ERROR_UPDATING_LICENSE: "There was an error updating license information",
  ERROR_DELETING_LICENSE: "There was an error removing license",
  ERROR_CREATING_MENTOR_STATUS_CHANGE: "There was an error submitting this sales partner break",
  ERROR_DELETING_MENTOR_STATUS_CHANGE: "There was an error deleting this sales partner break",
  ERROR_CREATING_EMAIL_UPDATE: "There was an error requesting an email update",
  ERROR_DELETING_EMAIL_UPDATE: "There was an error deleting your email update request",
  ERROR_VERIFYING_EMAIL_UPDATE: "There was an error verifying your email update request",
  ERROR_RESENDING_EMAIL_UPDATE: "There was an error resending your email update request",
  ERROR_UPDATING_MENTOR_PREFERENCES: "There was an error updating sales partner perferences",
};
