import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToCamelCase } from "lib/helpers";
import actions from "rdx/actions";
import messages from "../messages";

function* getNotes(action) {
  const { leadId } = action.payload;
  const { success, data, error } = yield* makeRequest.get("/inbox", {
    project_id: leadId,
  });
  if (success && data) {
    yield put(
      actions.setNotes({
        notes: data.body.notices && data.body.notices.map((n) => keysToCamelCase(n)),
        availableTags: data.body.available_tags && data.body.available_tags.map((t) => keysToCamelCase(t)),
      }),
    );
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROJECT_NOTES,
    });
  }
  return null;
}

export default getNotes;
