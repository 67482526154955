import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "rdx";
import SimpleModal from "components/mui-styled-components/Modals/SimpleModal";
import SessionClient from "util/SessionClient";

import TermsAndConditions, { ApplicationAgreementT } from "./TermsAndConditions";
import * as S from "./styles";

const TermsOfServiceModal = ({ modalVisible }: { modalVisible: boolean }) => {
  const dispatch = useDispatch();
  const latestTerms = useSelector(selectors.selectLatestTermsOfService);
  const session = new SessionClient();

  const [isTosChecked, setIsTosChecked] = useState(false);
  const [applicationAgreement, setApplicationAgreement] = useState({} as ApplicationAgreementT);

  const handleCloseModal = () => {
    // don't allow close if TOS is not checked
  };

  const getLatestTerms = () => {
    dispatch(actions.getLatestTermsOfServiceRequest());
  };

  const handleTos = (isChecked: boolean) => {
    setIsTosChecked(isChecked);
  };

  const handleConfirm = () => {
    if (isTosChecked) {
      dispatch(actions.acceptedLatestTermsOfServiceRequest());
      if (session?.user?.props.acceptedLatestTos === false) {
        session.setUserProps({ acceptedLatestTos: true });
      }
      dispatch(actions.setTermsOfServiceModalVisible(false));
    }
  };

  useEffect(() => {
    if (modalVisible) {
      getLatestTerms();
    }
  }, [modalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (latestTerms) {
      setApplicationAgreement(latestTerms);
    }
  }, [latestTerms]);

  return (
    <SimpleModal
      open={modalVisible}
      title=""
      handleConfirm={handleConfirm}
      justifyFooterContent="center"
      cancelButton={false}
      confirmButtonText="I agree"
      maxWidth={900}
      width={900}
      handleCancel={handleCloseModal}
    >
      <S.ModalContent>
        <TermsAndConditions handleChecked={handleTos} applicationAgreement={applicationAgreement} />
      </S.ModalContent>
    </SimpleModal>
  );
};

export default TermsOfServiceModal;
