import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const PhoneIconFilledGradient = ({
  width = "14",
  height = "14",
  startColor = cvar("picton-blue"),
  stopColor = cvar("blue-ribbon"),
}: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14">
      <path
        d="M11.3826 11.6059C11.2429 11.7457 11.1093 11.8932 10.9726 12.0408C10.5501 12.4957 10.1074 12.9493 9.41459 13.1714C8.94242 13.3235 8.25115 13.4198 7.53203 13.3329C6.73055 13.235 5.89335 12.7986 5.55629 12.5424L1.32355 8.30901C1.06731 7.97203 0.629257 7.13652 0.532924 6.33374C0.44592 5.61467 0.542243 4.9252 0.694473 4.45161C0.918106 3.75897 1.37165 3.31486 1.82533 2.89402C1.9729 2.75731 2.12048 2.62386 2.25873 2.48401C2.81478 1.92809 3.40193 1.93275 3.79804 2.32868L4.93819 3.46856C5.3094 3.83968 5.41189 4.50902 5.06713 4.90505C4.90243 5.09451 4.64468 5.26686 4.37119 5.44081C3.76541 5.82591 3.54795 5.86632 3.65196 6.48594C3.74829 7.05431 4.61659 8.05903 5.24254 8.72834C5.91204 9.35412 6.91697 10.2222 7.48547 10.3185C8.10371 10.4226 8.04 10.0996 8.42519 9.49399C8.5992 9.22069 8.77157 8.96137 8.96108 8.7967C9.3572 8.45189 10.0266 8.55449 10.3979 8.9256L11.5381 10.0655C11.9342 10.4615 11.9388 11.0469 11.3827 11.6044L11.3826 11.6059ZM8.09747 3.0863C7.84291 3.04872 7.6331 3.26092 7.6331 3.51824C7.6331 3.77555 7.844 3.9791 8.09576 4.03229C8.95729 4.21432 9.63795 4.89408 9.82018 5.75611C9.87341 6.00792 10.077 6.21887 10.3344 6.21887C10.5917 6.21887 10.804 6.00901 10.7664 5.7544C10.5632 4.37968 9.47249 3.28931 8.09747 3.0863ZM7.98867 0.383746C7.73212 0.363885 7.5228 0.574374 7.5228 0.831688C7.5228 1.089 7.73207 1.29534 7.98831 1.31888C10.3996 1.54041 12.325 3.46536 12.5467 5.87597C12.5702 6.13226 12.7766 6.34158 13.034 6.34158C13.2914 6.34158 13.5019 6.13221 13.482 5.8756C13.2554 2.951 10.9141 0.610218 7.98867 0.383746Z"
        fill="url(#paint0_linear_681_94537)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_681_94537"
          x1="2.77777"
          y1="-5.02728"
          x2="14.2404"
          y2="-3.8823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PhoneIconFilledGradient;
