import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = [];

export default {
  workSchedules: createReducer(initialState, {
    [types.SET_SCHEDULES_BY_TEAM](_, action) {
      return action.payload;
    },
    [types.RESET_WORK_SCHEDULES]() {
      return [];
    },
    [types.SET_WORK_SCHEDULES](_, action) {
      return action.payload;
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};
