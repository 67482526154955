import React from "react";
import PropTypes from "prop-types";

const NewDefaultAvatar = (props) => {
  const { height, width, square } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 78 76">
      {square && (
        <rect xmlns="http://www.w3.org/2000/svg" x="1" width={width} height={height} rx="6" fill="var(--dark-blue)" />
      )}
      <circle cx="39" cy="38.893" r="33.024" fill="var(--bright-turquoise)" />
      <path
        fill="var(--darkest-blue)"
        d="M11.649 11.265C18.648 4.428 28.319.207 39 .207c21.362 0 38.685 16.901 38.685 37.742 0 10.42-4.326 19.856-11.334 26.684C59.344 71.461 49.68 75.691 39 75.691c-10.681 0-20.352-4.221-27.351-11.058C4.649 57.797.314 48.37.314 37.95c0-10.42 4.327-19.856 11.335-26.684zm27.36.563c-7.398-.009-13.388 5.843-13.388 13.06.009 7.093 6.091 13.036 13.362 13.036 7.398.008 13.387-5.843 13.387-13.06 0-7.085-6.091-13.028-13.361-13.036zM16.772 59.634a31.432 31.432 0 009.332 6.29A31.969 31.969 0 0039 68.614c4.598 0 8.959-.96 12.895-2.69a31.63 31.63 0 009.332-6.29 31.487 31.487 0 003.673-4.296C62.95 48.99 56.892 44.33 49.765 44.33h-21.53c-7.127 0-13.184 4.66-15.136 11.008a32.04 32.04 0 003.674 4.296z"
      />
    </svg>
  );
};

NewDefaultAvatar.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  square: PropTypes.bool,
};

NewDefaultAvatar.defaultProps = {
  width: "100%",
  height: "100%",
  square: false,
};

export default NewDefaultAvatar;
