import React from "react";
import PropTypes from "prop-types";

const PowurLogoMobile = (props) => {
  const { height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.759766"
        y="18.7979"
        width="24.7479"
        height="2.74977"
        rx="0.25"
        transform="rotate(-45 0.759766 18.7979)"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
      <rect
        x="2.70508"
        y="1.29883"
        width="24.7479"
        height="2.74977"
        rx="0.25"
        transform="rotate(45 2.70508 1.29883)"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

PowurLogoMobile.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

PowurLogoMobile.defaultProps = {
  width: "21",
  height: "22",
};

export default PowurLogoMobile;
