import React from "react";
import { Layout } from "antd";
import { Helmet } from "react-helmet";
import { Header, Footer, GetQuoteModal, UtilityBillModal, SuccessModal } from "components/HomepageComponents";
import ScrollToTop from "containers/router/scrollToTop";
import HeroImage from "./components/HeroImage";
import Divider from "./components/Divider";
import CareerListings from "./components/StateLicenses";
import styles from "./licenses.module.less";

const { Content } = Layout;

const Licenses = (props) => {
  const { user } = props;
  return (
    <Layout className={styles.pageLayout}>
      <Helmet>
        <title>Powur State Licenses</title>
        <meta name="title" content="Powur State Licenses" />
        <meta name="description" content="Powur State Licenses." />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://powur.com/careers" />
        <meta property="og:title" content="Powur Licenses" />
        <meta property="og:description" content="Powur Licenses" />
        <meta property="og:image" content="/img/powur_fb_01.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://powur.com/careers" />
        <meta property="twitter:title" content="Powur Licenses" />
        <meta property="twitter:description" content="Powur Licenses" />
        <meta property="twitter:image" content="/img/powur_fb_01.jpg" />
      </Helmet>
      <ScrollToTop />
      <Header user={user} />
      <Content className={styles.content}>
        <HeroImage />
        <Divider />
        <CareerListings />
      </Content>
      <Footer />
      <GetQuoteModal />
      <UtilityBillModal />
      <SuccessModal />
    </Layout>
  );
};

export default Licenses;
