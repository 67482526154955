import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/earnings/messages";
import { camelizeKeys } from "lib/helpers";

function* getMonthlyBreakdown(action) {
  const params = action.payload;

  const { success, data, error } = yield* makeRequest.get("/dashboard_earnings", params);

  if (success && data) {
    yield put(actions.setMonthlyBreakdown(camelizeKeys(data.body.monthly_breakdown)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_MONTHLY_BREAKDOWN,
    });
  }

  return null;
}

export default getMonthlyBreakdown;
