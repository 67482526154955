import React from "react";

type PublicNoteAvatarT = {
  width?: string;
  height?: string;
};

const PublicNoteAvatar = ({ height = "30px", width = "30px" }: PublicNoteAvatarT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#4826D2" />
      <path
        d="M20.6905 15.5413H19.4531C19.5793 15.8866 19.6482 16.2592 19.6482 16.6476V21.3242C19.6482 21.4861 19.62 21.6416 19.5687 21.7861H21.6143C22.3785 21.7861 23.0002 21.1645 23.0002 20.4003V17.8509C23.0002 16.5774 21.9641 15.5413 20.6905 15.5413Z"
        fill="white"
      />
      <path
        d="M10.352 16.6477C10.352 16.2593 10.4209 15.8866 10.5471 15.5413H9.30969C8.03613 15.5413 7 16.5774 7 17.851V20.4004C7 21.1646 7.62166 21.7862 8.38582 21.7862H10.4315C10.3802 21.6416 10.352 21.4862 10.352 21.3243V16.6477Z"
        fill="white"
      />
      <path
        d="M16.4144 14.338H13.5856C12.312 14.338 11.2759 15.3741 11.2759 16.6477V21.3243C11.2759 21.5794 11.4827 21.7862 11.7378 21.7862H18.2621C18.5173 21.7862 18.7241 21.5794 18.7241 21.3243V16.6477C18.7241 15.3741 17.688 14.338 16.4144 14.338Z"
        fill="white"
      />
      <path
        d="M14.9999 8.21375C13.4682 8.21375 12.2222 9.45981 12.2222 10.9915C12.2222 12.0304 12.7956 12.9377 13.6424 13.4141C14.044 13.64 14.5071 13.7692 14.9999 13.7692C15.4926 13.7692 15.9557 13.64 16.3573 13.4141C17.2042 12.9377 17.7776 12.0303 17.7776 10.9915C17.7776 9.45984 16.5315 8.21375 14.9999 8.21375Z"
        fill="white"
      />
      <path
        d="M10.1223 10.8028C8.9768 10.8028 8.04492 11.7347 8.04492 12.8801C8.04492 14.0256 8.9768 14.9575 10.1223 14.9575C10.4128 14.9575 10.6895 14.8973 10.9409 14.7891C11.3754 14.602 11.7337 14.2708 11.9555 13.856C12.1111 13.5648 12.1996 13.2327 12.1996 12.8801C12.1996 11.7347 11.2677 10.8028 10.1223 10.8028Z"
        fill="white"
      />
      <path
        d="M19.8776 10.8028C18.7322 10.8028 17.8003 11.7347 17.8003 12.8801C17.8003 13.2327 17.8888 13.5649 18.0445 13.856C18.2662 14.2709 18.6245 14.6021 19.059 14.7891C19.3104 14.8973 19.5871 14.9575 19.8776 14.9575C21.0231 14.9575 21.955 14.0256 21.955 12.8801C21.955 11.7347 21.0231 10.8028 19.8776 10.8028Z"
        fill="white"
      />
    </svg>
  );
};

export default PublicNoteAvatar;
