import React, { ReactNode } from "react";
import { Alert } from "antd";

import { NeedsAttentionIcon } from "components/Icons";

import styles from "./HeaderAlertMessage.module.less";

type GeneralBannerT = {
  message: string;
  kind: "warning" | "info" | "success";
  details?: string;
  action?: ReactNode;
};

const GeneralBanner = ({ message, kind, details = "", action }: GeneralBannerT) => {
  return (
    <span className={styles.HeaderAlertMessageContainer}>
      <Alert
        className={styles[kind]}
        message={
          <>
            <NeedsAttentionIcon circleColor="transparent" height="35" width="35" />
            {message}
          </>
        }
        description={details}
        action={action}
      />
    </span>
  );
};

export default GeneralBanner;
