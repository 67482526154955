import Home from "containers/StaticPages/home";

import ambassadorRoutes from "containers/router/routers/Ambassador/routes";
import generalRoutes from "containers/router/routers/General/routes";
import personalRoutes from "containers/router/routers/Personal/routes";
import platformRoutes from "containers/router/routers/Platform/routes";

import {
  KnowledgeBaseIcon,
  PowurLiveIcon,
  PowurUniversityIcon,
  PreSalesSupportIcon,
  BillingSupportIcon,
  CalendarDotsIcon,
  PowurPlayIcon,
} from "components/Icons";

export default {
  generalRoutes,
  ambassadorRoutes,
  personalRoutes,
  platformRoutes,
  externalRoutes: [
    // LEARN
    {
      key: "powur-university",
      title: "Powur University",
      linkTarget: null,
      linkTitle: "Powur University",
      linkCategory: "Learn",
      icon: PowurUniversityIcon,
    },
    {
      key: "powur-live",
      title: "Powur Live",
      linkTarget: null,
      linkTitle: "Powur Live",
      linkCategory: "Learn",
      icon: PowurLiveIcon,
    },

    // SUPPORT
    {
      key: "knowledge-base",
      title: "Knowledge Base",
      linkTarget: null,
      linkTitle: "Knowledge Base",
      linkCategory: "Learn",
      icon: KnowledgeBaseIcon,
      hideIf: "isExternal",
    },
    {
      key: "powur-play",
      title: "Powur Play",
      linkTarget: null,
      linkTitle: "Powur Play",
      linkCategory: "Learn",
      icon: PowurPlayIcon,
      hideIf: "isExternal",
    },
    {
      key: "sales-support",
      title: "Sales Support",
      linkTarget: null,
      linkTitle: "Sales Support",
      linkCategory: "Support",
      icon: PreSalesSupportIcon,
    },
    {
      key: "billing-support",
      title: "Billing Support",
      linkTarget: "https://support.powur.com",
      linkTitle: "Billing Support",
      linkCategory: "Support",
      icon: BillingSupportIcon,
    },
    {
      key: "powur-calendar",
      title: "Powur Calendar",
      linkTarget: "https://www.powurcalendar.com/events/",
      linkTitle: "Powur Calendar",
      linkCategory: "Learn",
      icon: CalendarDotsIcon,
    },
  ],
  // Path below handles referrer via redux, not via async PersonalRoute.
  home: {
    key: "home",
    title: "Home",
    path: "/:user?/",
    pageName: "home",
    linkTarget: "/",
    component: Home,
  },
};
