import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import { cvar } from "styles";

type SideBoxPropsT = {
  disabled?: boolean;
};

export const Container = styled("div")({
  display: "flex",
  width: "100%",
});

export const AvatarContainer = styled(Container)({
  justifyContent: "center",
  alignItems: "center",
});

export const SideBox = styled("div")(({ disabled }: SideBoxPropsT) => ({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "36px",
  height: "32px",
  borderRadius: "3px 0 0 3px",
  border: `1px solid ${theme.colors["botticelli-blue"]}`,
  background: disabled ? theme.colors["botticelli-blue-50"] : "transparent",
  borderRight: "none",
  cursor: disabled ? "default" : "pointer",
}));

export const autocompleteStyles = {
  width: "100%",
  height: 32,

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--botticelli-blue) !important",
  },

  "& .MuiInputBase-root": {
    borderRadius: "0px 3px 3px 0px",
    height: "32px !important",
    padding: "4px 4px 2.5px 6px !important",
  },

  "& .MuiOutlinedInput-input": {
    background: "transparent !important",
  },

  "& .MuiAutocomplete-input": {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "17px",
    marginBottom: "3px",
  },

  "& .Mui-disabled": {
    background: cvar("botticelli-blue-50"),
  },

  "& .MuiFormLabel-root": {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: " 17px",
    color: "var(--dark-periwinkle)",
  },

  "& .MuiInputLabel-animated": {
    color: "var(--dark-periwinkle)",
  },

  "& .MuiOutlinedInput-root": {
    paddingRight: "35px !important",
  },
};

export const StyledPopper = styled(Popper)(({ theme }) => ({
  width: "fit-content",
  [`& .${autocompleteClasses.listbox}`]: {
    "& .MuiAutocomplete-option": {
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "30px",
      color: theme.colors["font-color-eastern-blue"],
    },
  },
  "& .MuiPaper-root": {
    width: "fit-content",
    minWidth: "100%",
    "& .MuiAutocomplete-listbox": {
      width: "fit-content",
      "& .MuiAutocomplete-option": {
        width: "fit-content",
      },
    },
  },
}));

export const OutsideSelectedOption = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  alignItems: "center",
  border: `1px solid ${theme.colors["botticelli-blue"]}`,
  height: "32px",
  maxWidth: "320px",
  borderRadius: "3px",

  "& div": {
    width: "33.5px",
    height: "100%",
    padding: "3px",
    boxSizing: "border-box",
    borderRight: `1px solid ${theme.colors["botticelli-blue"]}`,
    "& img, svg": {
      borderRadius: "100%",
    },
  },
  "&>span:last-child": {
    position: "absolute",
    right: "4px",
    display: "flex",
    height: "100%",
    alignItems: "center",
    float: "right",
  },
}));

export const OutsideSelectedOptionText = styled("span")(({ theme }) => ({
  color: theme.colors["dark-blue"],
  paddingLeft: "8px",
  boxSizing: "border-box",
  width: "75%",
}));

export const IconContainer = styled("span")(({ theme }) => ({
  background: theme.colors["blue-ribbon"],
  height: "24px",
  width: "24px",
  borderRadius: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

/* LOOKUP ROW */

export const LookUpRowContainer = styled("div")({
  marginRight: "7px",
  display: "flex",
  alignItems: "center",
});

export const LookUpRowWrapper = styled(LookUpRowContainer)({
  width: "100%",
});
