import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const AlertIconFilled = ({ fill = cvar("white"), stroke = cvar("danger"), height = 24, width = 24 }: IconT) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
    <path
      d="M10 18.3334C14.6024 18.3334 18.3334 14.6025 18.3334 10.0001C18.3334 5.39771 14.6024 1.66675 10 1.66675C5.39765 1.66675 1.66669 5.39771 1.66669 10.0001C1.66669 14.6025 5.39765 18.3334 10 18.3334Z"
      fill={fill}
    />
    <path d="M10 5.41675L10 11.2501" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 14.1667H10.0083" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default AlertIconFilled;
