import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const SandtimerIcon = ({ width = "10", height = "14", fill = cvar("white") }: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10 14">
      <path
        d="M8.62513 12.0103C8.82436 11.0535 8.78147 9.09915 6.14232 6.94526C9.04189 4.57878 8.80772 2.45342 8.56154 1.62705C9.04877 1.49587 9.3333 1.33798 9.3333 1.16804C9.3333 0.712815 7.2927 0.34375 4.77557 0.34375C2.25823 0.343654 0.217848 0.712716 0.217848 1.16804C0.217848 1.33797 0.502375 1.49585 0.989707 1.62712C0.743246 2.45334 0.508742 4.57826 3.40882 6.94509C0.769335 9.09875 0.726647 11.0532 0.926021 12.0101C0.478219 12.1378 0.217773 12.2889 0.217773 12.4512V12.8335C0.217773 13.2887 2.25837 13.6578 4.7755 13.6578C7.29263 13.6578 9.33323 13.2887 9.33323 12.8335V12.4512C9.33323 12.2889 9.07288 12.1377 8.62518 12.0101L8.62513 12.0103ZM4.22952 7.28948C4.33708 7.20813 4.40108 7.07916 4.4008 6.94413C4.40051 6.80928 4.33593 6.6806 4.2298 6.60127C1.28311 4.3704 1.5459 2.45463 1.73738 1.83479H2.09536C2.84766 1.93387 3.77337 1.99248 4.77532 1.99248C5.77728 1.99248 6.70286 1.93387 7.45528 1.83479H7.81181C7.99812 2.45379 8.25423 4.37977 5.32086 6.60102C5.21368 6.68198 5.14977 6.81056 5.14977 6.94511C5.14977 7.07957 5.21378 7.20835 5.32076 7.28903C7.98838 9.30901 8.0254 11.0705 7.86673 11.8458C7.66885 11.8127 7.45439 11.7828 7.22644 11.7565C7.02297 11.505 6.49004 11.1263 5.24348 10.393C5.10517 10.3215 4.4427 10.3254 4.30178 10.3989C3.06137 11.1297 2.52913 11.5068 2.32519 11.7564C2.09704 11.7827 1.88249 11.8126 1.6845 11.8457C1.52498 11.0687 1.56305 9.3081 4.22949 7.28944L4.22952 7.28948Z"
        fill={fill}
      />
    </svg>
  );
};

export default SandtimerIcon;
