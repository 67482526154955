import { styled } from "@mui/material/styles";
import { theme } from "styles/themes";
import { mq } from "styles";

export const DrawerHeader = styled("div")(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  [mq.phoneOnly]: {
    padding: "18px 24px",
    height: "58px",
    borderRadius: "10px 10px 0px 0px",
    background: theme.colors["ma-white"],
    flexGrow: 4,
    borderBottom: `2px solid ${theme.colors.white}`,
  },
  [mq.tabletUp]: {
    padding: "16px 24px",
    height: "56px",
    borderRadius: "0px",
    background: `linear-gradient(270.54deg, ${theme.colors["picton-blue"]} 49.88%, ${theme.colors["blue-ribbon"]} 135.73%)`,
  },
}));

export const CloseDrawerIcon = styled("div")(() => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  [mq.tabletUp]: {
    marginRight: "18px",
  },
}));

export const HeaderContent = styled("div")(() => ({
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "18px",
  width: "100%",
  display: "flex",
  [mq.phoneOnly]: {
    color: theme.colors["blue-ribbon"],
    justifyContent: "center",
  },
  [mq.tabletUp]: {
    color: theme.colors.white,
    justifyContent: "flex-start",
  },
}));

export const DrawerContent = styled("div")(() => ({
  height: "100%",
  background: theme.colors["ma-white"],
  [mq.phoneOnly]: {
    padding: "16px 20px 88px",
  },
  [mq.tabletUp]: {
    padding: "40px 24px 100px 24px",
  },
  overflowY: "scroll",
  scrollbarColor: `${theme.colors["picton-blue"]} transparent`,
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.colors["picton-blue"],
    borderLeft: "7px solid transparent",
    borderBottom: "60px solid transparent",
    backgroundClip: "padding-box",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: theme.colors["picton-blue"],
    borderLeft: "none",
    borderBottom: `60px solid ${theme.colors["ma-white"]}`,
  },
}));

export const Footer = styled("div")(() => ({
  minHeight: "60px",
  padding: "14px 48px",
  background: theme.colors["ma-white-a22"],
  backdropFilter: "blur(10px)",
  position: "fixed",
  bottom: "0",
  display: "flex",
  zIndex: 2000,
  [mq.phoneOnly]: {
    justifyContent: "center",
    padding: "14px 24px",
  },
  [mq.tabletUp]: {
    justifyContent: "flex-start",
  },
}));
