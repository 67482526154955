import React from "react";

import Grid from "@mui/material/Grid";
import { UseFormReset } from "react-hook-form";
import useWindowResize from "hooks/useWindowResize";
import Spinner from "components/LoadingElements/Spinner";
import SubHeader from "components/PlatformLayout/components/SubHeader";
import GradientButton from "components/Buttons/GradientButton";
import UndoArrow from "components/Icons/Arrows/undoArrow";
import CloserBanner from "../CloserBanner";
import PowurPairCard from "../PowurPairCard";
import PowurPairCriteriaMenu from "../PowurPairCriteriaMenu";
import { PowurPairT, T3PartnerT, PowurPairListT } from "../../types";
import { PowurPairFormSchemaT } from "../PowurPairForm/powurPairFormData";
import * as S from "./styles";

type PowurPairTopPicksPropsT = {
  isLoading: boolean;
  backToForm: () => void;
  selectedT3Partner: T3PartnerT | null;
  setSelectedT3Partner: (t3Partner: PowurPairT | null) => void;
  powurPairCriteria: PowurPairFormSchemaT;
  onSubmit: (e?: React.BaseSyntheticEvent<object> | undefined) => Promise<void>;
  setOpenFooter: (bool: boolean) => void;
  powurPairResults: PowurPairListT | null;
  resetCriteria: UseFormReset<PowurPairFormSchemaT>;
};

const PowurPairTopPicks = ({
  isLoading,
  backToForm,
  selectedT3Partner,
  setSelectedT3Partner,
  powurPairCriteria,
  onSubmit,
  setOpenFooter,
  powurPairResults,
  resetCriteria,
}: PowurPairTopPicksPropsT) => {
  const handleBackToForm = () => {
    backToForm();
  };
  const { isMobileWidth } = useWindowResize();

  if (!powurPairResults) return null;
  return (
    <S.PowurPairTopPicksContainer>
      <CloserBanner bannerText="Find Your Closer" />
      <SubHeader
        title="Powur's Top Picks for You"
        titleStyles={{
          fontSize: "20px",
          fontFamily: "'Poppins', sans-serif",
        }}
      />
      <S.TopPicksDescription>
        These Closers have been selected just for you based on your homeowner&#39;s location, language preference,
        rating, seller statistics, and a little secret sauce.
      </S.TopPicksDescription>
      {!isMobileWidth && (
        <PowurPairCriteriaMenu
          powurPairCriteria={powurPairCriteria}
          onSubmit={onSubmit}
          resetCriteria={resetCriteria}
        />
      )}
      <S.ScrollableList>
        {!isLoading && !!powurPairResults.users.length && (
          <S.MatchContainer container spacing={1}>
            {powurPairResults.users.map((user) => (
              <Grid item key={user.id} xs={12} sm={4}>
                <PowurPairCard
                  key={user.id}
                  mentor={user}
                  selectedT3Partner={selectedT3Partner}
                  setSelectedT3Partner={setSelectedT3Partner}
                  setOpenFooter={setOpenFooter}
                />
              </Grid>
            ))}
          </S.MatchContainer>
        )}
        {!isLoading && !powurPairResults.users.length && (
          <S.NoMatchesContainer>
            <S.GrayBoldText>We&#39;re Sorry</S.GrayBoldText>
            <S.BlackBoldText>We were unable to find a match with your current preferences.</S.BlackBoldText>
            <S.Text>Would you like to try again?</S.Text>
            <GradientButton onClick={handleBackToForm} icon={<UndoArrow height="14" fill="white" />}>
              Start Over
            </GradientButton>
          </S.NoMatchesContainer>
        )}
        <Spinner loading={!!isLoading} />
      </S.ScrollableList>
    </S.PowurPairTopPicksContainer>
  );
};

export default PowurPairTopPicks;
