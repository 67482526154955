import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const CalendarXIcon = ({ height = 14, width = 15, fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.63909 7.16089C8.44312 6.96482 8.13972 6.9696 7.94843 7.16089L7.33707 7.77225L6.72571 7.16089C6.53442 6.9696 6.21711 6.9696 6.02571 7.16089C5.83298 7.36327 5.84564 7.67181 6.02571 7.86089L6.63707 8.47225L6.03038 9.07894C5.9324 9.17693 5.88574 9.29815 5.88574 9.42416C5.88574 9.54561 5.9324 9.67618 6.03038 9.77416C6.22179 9.96557 6.52974 9.96557 6.72109 9.77416L7.3324 9.16291L7.94376 9.77416C8.13505 9.96557 8.44312 9.96557 8.63442 9.77416C8.83038 9.58287 8.83038 9.27024 8.63909 9.07894L8.02773 8.46758L8.63909 7.85622C8.83038 7.66482 8.83038 7.35219 8.63909 7.16089Z"
        fill={fill}
      />
      <path
        d="M11.8096 1.99236H11.6229C11.6229 2.06234 11.6229 3.21557 11.6229 3.16837C11.6229 3.93838 10.9976 4.56837 10.2229 4.56837C9.45287 4.56837 8.82294 3.93838 8.82294 3.16837C8.82294 2.96024 8.82294 1.94444 8.82294 1.99236H5.84555V2.9257V3.16837C5.84555 3.93838 5.21556 4.56837 4.44555 4.56837C3.67092 4.56837 3.04555 3.93838 3.04555 3.16837C3.04555 2.96024 3.04555 1.94444 3.04555 1.99236H2.85893C1.98153 1.99236 1.26758 2.70638 1.26758 3.58371V5.03971V11.1251C1.26758 12.0023 1.98153 12.7164 2.85893 12.7164H11.8096C12.6869 12.7164 13.4009 12.0023 13.4009 11.1251V5.03971V3.58371C13.4009 2.70638 12.6869 1.99236 11.8096 1.99236ZM11.8096 11.783H2.85893C2.49491 11.783 2.20091 11.4891 2.20091 11.1251V5.50638H12.4676V11.1251C12.4676 11.4891 12.1736 11.783 11.8096 11.783Z"
        fill={fill}
      />
      <path
        d="M4.44421 3.63389C4.70192 3.63389 4.91087 3.42516 4.91087 3.16722V1.75036C4.91087 1.49242 4.70192 1.28369 4.44421 1.28369C4.18649 1.28369 3.97754 1.49242 3.97754 1.75036V3.16722C3.97754 3.42516 4.18649 3.63389 4.44421 3.63389Z"
        fill={fill}
      />
      <path
        d="M10.2235 3.63389C10.4812 3.63389 10.6902 3.42516 10.6902 3.16722V1.75036C10.6902 1.49242 10.4812 1.28369 10.2235 1.28369C9.96579 1.28369 9.75684 1.49242 9.75684 1.75036V3.16722C9.75684 3.42516 9.96579 3.63389 10.2235 3.63389Z"
        fill={fill}
      />
    </svg>
  );
};

export default CalendarXIcon;
