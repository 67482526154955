import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../../messages";
import updateDataGroupsFromRes from "./util";

function* createMetaField(action) {
  const { dataGroupId, label, description, dataType, required, lookupChoices, oldField } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/data_groups/${dataGroupId}/meta_fields`, {
    label,
    description,
    data_type: dataType,
    required,
    lookup_choices: lookupChoices,
    old_field: oldField,
  });
  if (success && data) {
    const groups = yield updateDataGroupsFromRes(data, dataGroupId);
    yield put(actions.setDataGroups(groups));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_META_FIELD,
    });
  }
  return null;
}

export default createMetaField;
