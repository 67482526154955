import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/financials/messages";
import formatList from "./util/formatPayoutsList";

function* createPayoutRequest(action) {
  const { success, data, error } = yield* makeRequest.post("/payouts", {
    start_day: action.payload.startDay,
    end_day: action.payload.endDay,
  });

  if (success && data) {
    yield all([
      put(actions.setPayoutsList(formatList(data))),
      put(actions.newSuccessEvent({ message: messages.SUCCESS_CREATING_PAYOUT })),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_PAYOUT,
    });
  }

  return null;
}

export default createPayoutRequest;
