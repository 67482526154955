import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/projects/messages";
import { camelizeKeys } from "lib/helpers";
import { FundingProposalDataT } from "types/Project/fundingProposal";

type FundingProposalsPropsT = {
  payload: {
    proposalId: number;
  };
};

function* getFundingProposalDetails(action: FundingProposalsPropsT) {
  const { proposalId } = action.payload;

  const { success, data, error } = yield* makeRequest.get(`/funding_proposals/${proposalId}`, {});

  if (success && data) {
    const fundingProposal: FundingProposalDataT = camelizeKeys(data.body);
    yield put(actions.setFundingProposalDetails(fundingProposal));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROPOSAL,
    });
  }

  return null;
}

export default getFundingProposalDetails;
