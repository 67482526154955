import React from "react";

const Installation = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16.9316" cy="16" r="16" fill="var(--dark-blue)" />
      <path
        d="M18.2179 13.3909L18.3973 15.4162H21.0553L20.5204 13.3909H18.2179ZM18.2179 13.3909H15.648L15.4686 15.4162H18.394L18.6288 18.0938H15.2371L14.9892 20.905H18.8767L18.6321 18.0938L18.2179 13.3909ZM21.5966 13.3388L22.1184 12.9605L20.5171 11.7896L21.1335 9.8883H19.1474L18.5343 8L16.9297 9.17082L15.3251 8L14.7153 9.8883H12.7291L13.3455 11.7864L11.7409 12.9539L12.2628 13.3355M18.9648 21.8867L19.0593 22.9629L21.2607 23.4032L21.3651 24H12.4976L12.6019 23.4032L14.8066 22.9629L14.8979 21.8867H18.9648ZM12.8139 15.4162L12.1062 18.0938L12.8139 15.4162ZM15.2338 18.0938L15.4686 15.4162L15.2338 18.0938ZM15.2371 18.0938H12.103L11.3594 20.905H14.9892L15.2371 18.0938ZM15.648 13.3909H13.3455L12.8107 15.4162H15.4686L15.648 13.3909ZM21.763 18.0938L21.0553 15.4162L21.763 18.0938ZM18.8767 20.905H22.5033L21.763 18.0938H18.6288L18.8767 20.905ZM14.8131 12.406C14.8816 12.1484 14.999 11.9038 15.1686 11.6722C15.5208 11.183 15.997 10.8895 16.5905 10.7949C17.1841 10.7004 17.7255 10.8308 18.2147 11.1863C18.6451 11.4994 18.9224 11.9071 19.0463 12.406H14.8131Z"
        stroke="white"
        strokeWidth="0.4"
        strokeMiterlimit="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Installation;
