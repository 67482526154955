import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getAmbassadorRegistrationInfo(action) {
  const { code } = action.payload;

  const { success, data, error } = yield* makeRequest.get(`/registrations/${code}`, {});

  if (success && data) {
    yield put(actions.setAmbassadorRegistrationInfo(keysToCamelCase(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_AMBASSADOR_REGISTRATION_INFO,
    });
  }

  return null;
}

export default getAmbassadorRegistrationInfo;
