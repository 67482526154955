import { put, select } from "redux-saga/effects";
import { cloneDeep } from "lodash";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "rdx/modules/fundingProposals/messages";
import { camelizeKeys } from "lib/helpers";

function* getAdminFundingProposals(action) {
  const { requestFreshData } = action.payload;

  const existingPaging = yield select(selectors.selectFundingProposalsPaging);
  const existingList = yield select(selectors.selectFundingProposalsList);

  const params = {
    limit: 20,
    ...action.payload,
  };

  if (!requestFreshData) {
    params.p = existingPaging.pageNumber + 1;
  }

  const { success, data, error } = yield* makeRequest.get("/funding_proposals", params);

  if (success && data) {
    const { fundingProposals, paging } = camelizeKeys(data.body);
    const combinedList = cloneDeep(existingList).concat(fundingProposals);

    const out = requestFreshData ? fundingProposals : combinedList;

    yield put(actions.setAdminFundingProposals({ list: out, paging }));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_FUNDING_PROPOSALS,
    });
  }

  return null;
}

export default getAdminFundingProposals;
