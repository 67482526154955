import React from "react";
import { Layout } from "antd";

import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import ChangeLanguage from "components/HomepageComponents/ChangeLanguage";
import common from "components/HomepageComponents/Footer/Footer.module.less";
import styles from "../../Installerjoin.module.less";

const Footer = () => {
  const { t } = useTranslation(["footer", "translation", "form_labels"]);

  const currentYear = dayjs().year();

  return (
    <Layout.Footer className={[common.footer, styles.footer].join(" ")}>
      <div className={common.innerContainer}>
        <div className={common.infoRow}>
          <div className={common.links}>
            <div className={common.linkCol}>
              <div className={common.header}>{t("translation:contact")}</div>
              <ul className={common.linksList}>
                <li>
                  <a href="tel:1-866-GO-POWUR">1-866-GO-POWUR</a>
                </li>
                <br />
              </ul>
            </div>
            <div className={common.linkCol}>
              <ul className={common.linksList}>
                <li>
                  {t("translation:need_help")} <br />
                  <a className={common.emailLink} href="mailto:customercare@powur.com">
                    customercare@powur.com
                  </a>
                </li>
              </ul>
            </div>
            <div className={common.linkCol} />
            <div className={common.linkCol} />
          </div>
          <div className={common.solarCol}>
            <div className={common.linksCol}>
              <div className={common.lang}>
                <div className={common.header}>{t("translation:language")}</div>
                <ChangeLanguage />
              </div>
            </div>
          </div>
        </div>
        <div className={common.divider} />
        <div className={common.disclaimerRow}>
          <div className={common.disclaimer}>{t("disclaimer")}</div>
          <div className={common.copyright}>
            &#169; Powur PBC 2012-{currentYear}, {t("all_rights_reserved")}
          </div>
        </div>
      </div>
    </Layout.Footer>
  );
};

export default Footer;
