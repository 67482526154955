import { RoofingProposalsIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import tabs from "containers/Admin/adminRoofingProposals/tabs";
import l from "@loadable/component";

const content = l(() => import("containers/Admin/adminRoofingProposals"));

const { ROOF_DESIGN_TEAM, ROOF_DESIGN_ADMIN } = permissionTypes;

export default {
  key: "admin-roofing-proposals",
  title: "Admin Roofing Proposals",
  path: "/admin-roofing-proposals/:tab?/:proposalId?",
  requiredParams: ["tab"],
  optionalParams: [],
  linkTarget: "/admin-roofing-proposals",
  linkTitle: "Roofing Proposals",
  basePath: "admin-roofing-proposals",
  linkCategory: "Admin",
  icon: RoofingProposalsIcon,
  permission: [ROOF_DESIGN_TEAM, ROOF_DESIGN_ADMIN],
  content,
  tabs,
};
