import React from "react";

interface TrophyNoIcon {
  width?: string | number;
  height?: string | number;
}

const TrophyNoIcon = ({ width = 28, height = 28 }: TrophyNoIcon) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.7271 15.1729H20.1931C19.1966 16.1146 18.0052 16.8259 16.7035 17.2567V20.9255H19.2203C19.7198 20.9244 20.2051 21.0904 20.5991 21.3976C20.9928 21.7047 21.2725 22.1346 21.3934 22.6193L22.0326 25.1761V25.1759C22.2002 25.8446 22.0498 26.5535 21.6252 27.0966C21.2004 27.6395 20.549 27.9565 19.8595 27.955H7.79525C7.10575 27.9561 6.45433 27.6389 6.02976 27.0955C5.60498 26.5524 5.45476 25.8435 5.6221 25.1746L6.26131 22.6179V22.6181C6.38221 22.1333 6.66191 21.7034 7.05566 21.3963C7.44963 21.0892 7.93498 20.9231 8.43444 20.9242H10.9512V17.2568C9.64903 16.8262 8.45733 16.1148 7.46029 15.173H4.87952C3.60861 15.1713 2.39029 14.6658 1.49175 13.767C0.593197 12.8684 0.0877163 11.65 0.0860181 10.3792V5.26594C0.0866601 4.67271 0.322481 4.10409 0.741932 3.68487C1.16136 3.26542 1.72994 3.02936 2.32291 3.0287H4.05115L4.03959 2.90094C3.98588 2.27649 4.19666 1.65817 4.6204 1.19638C5.04411 0.734768 5.64221 0.471949 6.26904 0.472168H21.3858C22.0126 0.471954 22.6107 0.734757 23.0345 1.19638C23.4582 1.65821 23.669 2.27649 23.6153 2.90094L23.6037 3.0287H25.332C25.9249 3.02934 26.4935 3.26539 26.9129 3.68487C27.3324 4.10411 27.5682 4.67271 27.5688 5.26594V9.331C27.5671 10.8798 26.9513 12.3648 25.856 13.46C24.7608 14.5553 23.2761 15.1712 21.7272 15.173L21.7271 15.1729ZM2.32296 4.94604C2.14727 4.94818 2.00539 5.09007 2.00347 5.26577V10.379C2.00433 11.1416 2.30777 11.8726 2.84685 12.4117C3.38612 12.951 4.11716 13.2543 4.87957 13.2553H5.90226H5.90204C5.14686 12.0288 4.69063 10.6421 4.57016 9.20674L4.21471 4.94604L2.32296 4.94604ZM25.6512 5.26577C25.6512 5.08921 25.5082 4.94604 25.3317 4.94604H23.4436L23.0894 9.20674H23.0896C22.9674 10.6416 22.5095 12.0278 21.7526 13.2528C22.7881 13.2453 23.7791 12.8291 24.5093 12.0946C25.2394 11.3599 25.6499 10.3667 25.6512 9.33089L25.6512 5.26577Z"
        fill="white"
      />
    </svg>
  );
};

export default TrophyNoIcon;
