import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  paging: {
    pageSize: 0,
    pageNumber: 1,
    itemCount: 0,
  },
  updates: [],
  filters: {},
  customFields: [],
};

export default {
  customFields: createReducer(initialState, {
    [types.SET_CUSTOM_FIELDS](state, action) {
      return {
        ...state,
        customFields: action?.payload,
      };
    },

    //   INSERTION_PT (for script, do not remove)
  }),
};
