/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tooltip, Typography } from "antd";
import copy from "copy-to-clipboard";
import PhoneIcon from "../../Icons/PhoneIcons/rotaryPhoneIcon";
import styles from "./CopyCallPhone.module.less";

const { Paragraph } = Typography;

const CopyCallPhone = (props) => {
  const { phone, tooltipTimeout, showIcon, showText, containerProps, tooltipProps, phoneProps, iconProps, withHover } =
    props;

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState(phone);

  const handleClick = (e) => {
    e.stopPropagation();
    setTooltipText(`${phone} copied to clipboard`);
    copy(phone);
    setTooltipVisible(true);
    setTimeout(() => setTooltipVisible(false), tooltipTimeout);
  };

  const handleHover = (e) => {
    e.stopPropagation();
    setTooltipText(phone);
    setTooltipVisible(true);
    // setTimeout(() => setTooltipVisible(false), tooltipTimeout);
  };

  return (
    <span className={styles.phoneRow} {...containerProps}>
      <Tooltip
        title={tooltipText}
        placement="top"
        trigger="click"
        open={tooltipVisible}
        color="rgba(41, 92, 160, 0.9)"
        {...tooltipProps}
      >
        {showIcon && (
          <span
            onClick={handleClick}
            onMouseEnter={handleHover}
            onMouseLeave={() => setTooltipVisible(false)}
            className={styles.icon}
            onKeyDown={handleClick}
            role="button"
            tabIndex={0}
          >
            <PhoneIcon {...iconProps} />
          </span>
        )}
        {showText && (
          <Paragraph
            className={[styles.phone, ...(withHover ? [styles.withHover] : [])]}
            style={{
              marginLeft: showIcon ? "6px" : "0px",
            }}
            ellipsis={{
              expandable: false,
              rows: 1,
            }}
            onClick={handleClick}
            {...phoneProps}
          >
            {phone}
          </Paragraph>
        )}
      </Tooltip>
    </span>
  );
};

CopyCallPhone.propTypes = {
  phone: PropTypes.string.isRequired,
  tooltipTimeout: PropTypes.number,
  /* pass through props to ant design components */
  containerProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  tooltipProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  phoneProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  iconProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  showIcon: PropTypes.bool,
  showText: PropTypes.bool,
  withHover: PropTypes.bool,
};

CopyCallPhone.defaultProps = {
  tooltipTimeout: 4000,
  containerProps: {},
  tooltipProps: {},
  phoneProps: {},
  iconProps: {},
  showIcon: true,
  showText: true,
};

export default CopyCallPhone;
