import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "rdx/modules/sellerIntegrity/messages";
import { DeleteViolationPayloadPropsT, ViolationT } from "containers/Admin/users/components/UserDetailDrawer/types";

type DeleteIntegrityViolationPropsT = {
  payload: DeleteViolationPayloadPropsT;
};

function* deleteIntegrityViolation(action: DeleteIntegrityViolationPropsT) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/seller_integrity_violations/${id}`, { id });

  if (success && data) {
    const violations = yield select(selectors.selectIntegrityViolations);
    const violationCount: number = yield select(selectors.selectIntegrityViolationCount);
    const clone = violations.filter((violation: ViolationT) => violation.id !== id);

    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.DELETE_INTEGRITY_VIOLATION_SUCCESS,
        }),
      ),
      put(
        actions.setIntegrityViolations({
          violations: clone,
          violationCount: violationCount - 1,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_INTEGRITY_VIOLATION,
    });
  }
  return null;
}

export default deleteIntegrityViolation;
