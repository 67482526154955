import { put } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getProjectMilestones(action) {
  const { projectId } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/projects/${projectId}/milestones`, {});
  if (success && data) {
    const out = keysToCamelCase(_.clone(data.body));
    if (out.data && out.data.length) out.data = out.data.map((d) => keysToCamelCase(d));
    yield put(actions.setProjectMilestones(out));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROJECT_MILESTONES,
    });
  }
  return null;
}

export default getProjectMilestones;
