import { useRdxState } from "hooks/useRdx";
import { isEmpty } from "lodash";
import c from "./constants";
import decisions from "./decisions";
import { PermissionT, DecisionMethodNamesT } from "./types";

type UserPermissionStateT = {
  permissions: PermissionT[];
};

type PermissionRequestsPropsT = {
  permissionRequests: string[];
};

type PermissionListT = DecisionMethodNamesT | DecisionMethodNamesT[] | true;

type PermissionListPropsT = {
  permissionsList: Array<PermissionListT>;
};

const noCheckError = (req) => {
  throw new Error(`Invalid permission request, no check found for requested permission ${req}`);
};

const usePermissions = ({ permissionRequests }: PermissionRequestsPropsT): Record<DecisionMethodNamesT, boolean> => {
  const { permissions } = useRdxState({ permissions: "getUserPermissions" }) as UserPermissionStateT;
  const out = {} as Record<DecisionMethodNamesT, boolean>;

  for (const req of permissionRequests) {
    if (!c[req]) {
      noCheckError(req);
    } else {
      out[req] = decisions[req](permissions);
    }
  }

  return out;
};

const useCheckPermissionsList = ({ permissionRequests }: PermissionRequestsPropsT): boolean => {
  const { permissions } = useRdxState({ permissions: "getUserPermissions" }) as UserPermissionStateT;

  const out: PermissionT[] = [];

  for (const req of permissionRequests) {
    if (!c[req]) {
      noCheckError(req);
    }
    out.push(decisions[req](permissions));
  }

  return isEmpty(out) || out.some((r) => r);
};

const useListOfPermissionChecks = ({ permissionsList }: PermissionListPropsT): boolean[] => {
  /* use this if we have an array of single permission we want to check, and
   * return the array of results for each permission.
   * input:
   *  permissionsList: (String|Boolean)[], a list of single permission string literals,
   *   drawn from our constants list of string literals, e.g.
   *   [ c.PLATFORM_CONFIGURATION, c.PROJECT_MANAGEMENT, c.PLATFORM_CONFIGURATION ]
   *   If an entry in permissionsList === true, then there is no permission required
   *   for this entry (may reflect a nav item or page view that does not require
   *   elevated permission to render), simply return true and move to next elememnt
   * output: Boolean[], values reflecting that user does or does not have that
   *  specific permission
   */
  const { permissions } = useRdxState({ permissions: "getUserPermissions" }) as UserPermissionStateT;
  return permissionsList.map((req) => {
    if (req === true) {
      // no permission check needed, return true
      return true;
    }

    const out: boolean[] = [];

    if (typeof req === "object") {
      for (const r of req) {
        if (!c[r]) {
          noCheckError(r);
        }
        out.push(decisions[r](permissions));
      }
    } else {
      out.push(decisions[req](permissions));
      if (!c[req]) {
        noCheckError(req);
      }
    }

    return isEmpty(out) || out.some((r) => r);
  });
};

export default usePermissions;

export { useCheckPermissionsList, useListOfPermissionChecks, c as permissionTypes };
