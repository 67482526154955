import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../Home.module.less";

const Endorsements = () => {
  const { t } = useTranslation("home");
  return (
    <div className={styles.endorsements}>
      <div className={styles.innerContainer}>
        <div className={styles.item}>
          <div className={styles.logo} />
          <div className={styles.quote}>&quot;{t("quotes.tech_crunch")}&quot;</div>
        </div>
        <div className={styles.item}>
          <div className={styles.logo} />
          <div className={styles.quote}>&quot;{t("quotes.inc_5000")}&quot;</div>
        </div>
        <div className={styles.item}>
          <div className={styles.logo} />
          <div className={styles.quote}>&quot;{t("quotes.ein_presswire")}&quot;</div>
        </div>
      </div>
    </div>
  );
};

export default Endorsements;
