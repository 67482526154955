import styled from "@emotion/styled";
import { cvar } from "styles";

export const LabelGroupWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const StyledCheckboxLabel = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "9px",
  marginBottom: "4px",
  color: cvar("nepal-blue"),
  textTransform: "uppercase",
}));

export const CheckboxLabel = styled.label(() => ({
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "17px",
  color: cvar("dark-blue"),
}));
