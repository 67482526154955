import component from "containers/Ambassador/ambassadorjoin";
// import l from "@loadable/component";

// const component = l(() => import("containers/Ambassador/ambassadorjoin"));

export default {
  key: "ambassador-join",
  title: "Create Ambassador Account",
  path: "/:user?/ambassador",
  pageName: "ambassador",
  linkTarget: "/ambassador",
  exact: true,
  component,
};
