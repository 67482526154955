import { styled } from "@mui/material/styles";
import { theme } from "styles/themes";

export const StyledSectionLabel = styled("div")(() => ({
  fontFamily: "Poppins",
  fontSize: "20px",
  lineHeight: "30px",
  color: theme.colors["blue-ribbon"],
  fontWeight: "400",
}));
