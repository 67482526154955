import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/platformModules/types";

import getPlatformModules from "./getPlatformModules";
import getEnterpriseModules from "./getEnterpriseModules";

import toggleModuleActive from "./toggleModuleActive";
import getModuleDetail from "./getModuleDetail";
// IMPORT_PT (for script, do not remove)

function* watchPlatformModulesSagas() {
  yield trackRequests(takeEvery, types.GET_PLATFORM_MODULES, getPlatformModules);
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_MODULES, getEnterpriseModules);

  yield trackRequests(takeEvery, types.TOGGLE_MODULE_ACTIVE, toggleModuleActive);
  yield trackRequests(takeEvery, types.GET_MODULE_DETAIL, getModuleDetail);
  // INSERTION_PT (for script, do not remove)
}

export default watchPlatformModulesSagas;
