import { put, all, select } from "redux-saga/effects";
import * as _ from "lodash";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { actions, selectors } from "rdx";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getProviderLeadsList(action) {
  const { requestFreshData = true } = action.payload || {};
  const existingList = yield select(selectors.getProviderLeadsList);

  if (requestFreshData) {
    yield put(actions.resetProjectsList());
  }

  const params = {
    sort: "activity_asc",
    ...action.payload,
    limit: 20,
  };

  if (!requestFreshData) {
    params.p = existingList.paging.pageNumber + 1 || existingList.paging.page_size + 1;
  }
  const { success, data, error } = yield* makeRequest.get("/provider_leads", params);
  if (success && data) {
    if (data.body.provider_leads) {
      const list = _.cloneDeep(existingList.providerLeads).concat(
        data.body.provider_leads.map((entry) => camelizeKeys(entry)),
      );
      const paging = camelizeKeys(data.body.paging);
      const providerLeads = requestFreshData ? data.body.provider_leads.map((entry) => camelizeKeys(entry)) : list;
      yield put(actions.setProviderLeadsList({ ...data.body, paging, providerLeads }));
    }
    if (data.body.filters) {
      const filters = { filters: data.body.filters, sorts: data.body.sorts };

      const eventCounts = Object.entries(data.body.event_counts).map((e) => ({
        code: _.camelCase(e[0]),
        eventCount: e[1],
      }));

      yield all([put(actions.setProviderLeadsFilters(filters)), put(actions.setLeadsEventCounts(eventCounts))]);
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROVIDER_LEADS_LIST,
    });
  }
  return null;
}

export default getProviderLeadsList;
