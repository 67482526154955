import { all, put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/tickets/messages";
import { camelizeKeys } from "lib/helpers";

function* removeAdminAdvanceSetting(action) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/pay_advance_levels/${id}`, {});

  if (success && data) {
    const updatedSettings = camelizeKeys(data.body.pay_advance_levels);
    yield all([
      put(actions.setAdminAdvanceSettings(updatedSettings)),
      put(
        actions.newSuccessEvent({
          message: messages.ADMIN_ADVANCE_SETTINGS_UPDATE_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_REMOVING_ADMIN_ADVANCE_SETTNG,
    });
  }
  return null;
}

export default removeAdminAdvanceSetting;
