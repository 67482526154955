const adminProjectsTabs = [
  {
    key: "pre-sales",
    label: "Pre-Sales",
  },
  {
    key: "post-sales",
    label: "Post-Sales",
  },
  {
    key: "closed",
    label: "Closed",
  },
];

export default adminProjectsTabs;
