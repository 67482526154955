import React, { CSSProperties } from "react";
import { cvar } from "styles/colorVariables";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Controller } from "react-hook-form";

import { useSmartFormContext } from "components/SmartForm";
import HelperText from "../HelperText";

import * as S from "./styles";

type CheckboxOptionsT = {
  label: string;
  value: string;
  disabled?: boolean;
};

export type FormCheckboxProps = {
  mainLabel?: string;
  checkboxOptions: CheckboxOptionsT[];
  name: string;
  disabled?: boolean;
  mainLabelStyle?: CSSProperties;
  helperText?: string;
  error?: boolean;
  checkboxLabelStyles?: CSSProperties;
  groupContainerStyles?: CSSProperties;
  checkboxStyles?: CSSProperties;
  checkedIcon?: React.ReactNode;
  uncheckedIcon?: React.ReactNode;
} & CheckboxProps;

const SmartFormCheckbox = ({
  mainLabel,
  checkboxOptions,
  name,
  disabled = false,
  mainLabelStyle = {},
  helperText,
  error,
  checkboxLabelStyles,
  groupContainerStyles,
  checkboxStyles,
  checkedIcon,
  uncheckedIcon,
  ...rest
}: FormCheckboxProps) => {
  const { controllerProps } = useSmartFormContext();
  return (
    <>
      <S.LabelGroupWrapper>
        <S.StyledCheckboxLabel style={mainLabelStyle}>{mainLabel}</S.StyledCheckboxLabel>
      </S.LabelGroupWrapper>
      <div style={groupContainerStyles}>
        {checkboxOptions.map(({ label: optionLabel, value }: CheckboxOptionsT) => {
          return (
            <FormControlLabel
              key={optionLabel}
              control={
                <Controller
                  {...controllerProps}
                  name={`${name}.${value}`}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        size="small"
                        color="default"
                        sx={{
                          color: cvar("botticelli-blue"),
                          "&.Mui-checked": {
                            color: cvar("dark-blue"),
                          },
                          "&.Mui-disabled": {
                            color: cvar("cotton-boll"),
                          },
                          ...checkboxStyles,
                        }}
                        disabled={disabled}
                        checked={field.value}
                        onChange={field.onChange}
                        {...(checkedIcon && {
                          checkedIcon,
                        })}
                        {...(uncheckedIcon && {
                          icon: uncheckedIcon,
                        })}
                        {...rest}
                      />
                    );
                  }}
                />
              }
              label={<S.CheckboxLabel style={{ ...checkboxLabelStyles }}>{optionLabel}</S.CheckboxLabel>}
              disabled={disabled}
            />
          );
        })}
      </div>
      <HelperText error={error}>{helperText}</HelperText>
    </>
  );
};

export type { CheckboxOptionsT };

export default SmartFormCheckbox;
