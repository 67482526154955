import component from "containers/StaticPages/careers";

// import l from "@loadable/component";

// const component = l(() => import("containers/StaticPages/careers"));

export default {
  key: "powur-careers",
  title: "Careers",
  path: "/:user?/careers",
  pageName: "Careers",
  linkTarget: "/careers",
  component,
};
