import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* verifyEmailUpdate(action) {
  const { success, data, error } = yield* makeRequest.post("/profile/email/verify", { token: action.payload.token });

  if (success && data) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.VERIFY_EMAIL_UPDATE_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_VERIFYING_EMAIL_UPDATE,
    });
  }
  return null;
}

export default verifyEmailUpdate;
