import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { theme } from "styles/themes";
import { mq } from "styles";

export const PowurPairFormContainer = styled("div")(() => ({
  height: "180px",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  fontFamily: "Poppins",
  fontWeight: 800,
  fontSize: "36px",
  justifyContent: "center",
  alignItems: "center",
  color: theme.colors.white,
  backgroundImage: `url("/img/powur_pair_lines.png"), linear-gradient(106.59deg, ${theme.colors["congress-blue"]} 2.67%, ${theme.colors["primary-color"]} 46.33%, rgba(81, 242, 203, 0.83) 107.46%)`,
  backgroundRepeat: "no-repeat",
  marginBottom: "24px",
}));

export const Text = styled(Grid)(() => ({
  fontSize: "14px",
  lineHeight: "17px",
  color: theme.colors["table-grey"],
  marginLeft: "16px",
  marginBottom: "20px",
}));

export const FormCardContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
}));

export const FormCard = styled(Grid)(() => ({
  backgroundColor: theme.colors.white,
  borderRadius: "16px",
  boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.03)",
  padding: "22px",
  [mq.phoneOnly]: {
    padding: "12px",
  },
}));

export const FormQuestion = styled("div")(() => ({
  fontFamily: "Poppins",
  color: theme.colors["blue-ribbon"],
  fontSize: "18px",
  lineHeight: "24px",
  fontWeight: 700,
  padding: "12px",
}));

export const RadioButtonLabel = styled("div")(() => ({
  fontSize: "18px",
  lineHeight: "22px",
  fontWeight: 700,
}));

export const RadioButtonSubLabel = styled("div")(() => ({
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: 400,
}));

export const FormButtons = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "30px",
}));
