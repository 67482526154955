import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/filterSets/types";
import getFilterSetsByView from "./getFilterSetsByView";
import createFilterSet from "./createFilterSet";
import deleteFilterSet from "./deleteFilterSet";
import getSelectedLookupFilters from "./getSelectedLookupFilters";
// IMPORT_PT (for script, do not remove)

function* watchEarningsSagas() {
  yield trackRequests(takeEvery, types.GET_FILTER_SETS_BY_VIEW, getFilterSetsByView);
  yield trackRequests(takeEvery, types.CREATE_FILTER_SET_REQUEST, createFilterSet, createFilterSet);
  yield trackRequests(takeEvery, types.DELETE_FILTER_SET_REQUEST, deleteFilterSet);
  yield trackRequests(takeEvery, types.GET_SELECTED_LOOKUP_FILTERS, getSelectedLookupFilters);
  // INSERTION_PT (for script, do not remove)
}

export default watchEarningsSagas;
