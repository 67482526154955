import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "../types";
import getAdminNoticesList from "./getAdminNoticesList";
import createNewAdminNotice from "./createNewAdminNotice";
import getAdminNoticeDetails from "./getAdminNoticeDetails";
import deleteAdminNotice from "./deleteAdminNotice";
import updateAdminNoticeDetail from "./updateAdminNoticeDetail";

function* watchAdminNoticesSagas() {
  yield trackRequests(takeEvery, types.GET_ADMIN_NOTICES_LIST_REQUEST, getAdminNoticesList);
  yield trackRequests(takeEvery, types.CREATE_NEW_ADMIN_NOTICE_REQUEST, createNewAdminNotice);
  yield trackRequests(takeEvery, types.DELETE_ADMIN_NOTICE, deleteAdminNotice);
  yield trackRequests(takeEvery, types.GET_ADMIN_NOTICE_DETAILS_REQUEST, getAdminNoticeDetails);
  yield trackRequests(takeEvery, types.UPDATE_ADMIN_NOTICE_DETAIL, updateAdminNoticeDetail);

  // INSERTION_PT (for script, do not remove)
}

export default watchAdminNoticesSagas;
