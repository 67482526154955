import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  partnersListAutoComplete: {},
  partnersList: {
    paging: {
      pageSize: 50,
      pageNumber: 1,
      itemCount: 0,
    },
    list: [],
    labor: [],
    moduleOn: false,
  },
  recommendedPartners: [],
};

export default {
  preferredPartners: createReducer(initialState, {
    [types.SET_PREFERRED_PARTNERS](state, action) {
      if (action.payload.partner) {
        const newState = state.partnersList.list.map((item) => {
          if (item.id !== action.payload.partner.id) {
            return { ...item };
          }
          return {
            ...item,
            ...action.payload.partner,
          };
        });
        return {
          ...state,
          partnersList: {
            ...state.partnersList,
            list: newState,
          },
        };
      }
      // response to getPreferredPartners
      return {
        ...state,
        partnersList: {
          ...state.partnersList,
          ...action.payload,
        },
      };
    },

    [types.SET_PREFERRED_PARTNERS_ADMIN](state, action) {
      return {
        ...state,
        enterprisesWithPartners: {
          ...state.enterprisesWithPartners,
          ...action.payload,
        },
      };
    },

    [types.SET_PREFERRED_PARTNERS_AUTOCOMPLETE_SUGGESTIONS](state, action) {
      const { key, suggestions } = action.payload;
      if (key) {
        return {
          ...state,
          partnersListAutoComplete: {
            ...state.partnersListAutoComplete,
            [key]: suggestions || [],
          },
        };
      }
      return state;
    },

    [types.SET_RECOMMENDED_PARTNERS](state, action) {
      return {
        ...state,
        recommendedPartners: [...state.recommendedPartners, ...action.payload.recommendedPartners],
      };
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};
