import PropTypes from "prop-types";

export const availableInstallersSchema = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
);

export const availableInstallersDefaults = [];
