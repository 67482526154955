/* eslint-disable import/prefer-default-export */
// should probably deprecate
import { useState, useEffect, useRef } from "react";

let GLOBAL_COUNTER = 0;

/**
 * Generates a globally unique id with an optional prefix (e.g. for DOM ids, etc)
 * @param {string} prefix Id prefix
 * @returns {string} Generated prefix
 */
export const useUniqueId = (prefix = "uid") => {
  const [initialized, setInitialized] = useState(false);
  if (!initialized) {
    GLOBAL_COUNTER++;
    setInitialized(true);
  }
  const instance = useRef(GLOBAL_COUNTER);
  const [id, setId] = useState(`${prefix}-${instance.current}`);
  useEffect(() => {
    setId(`${prefix}-${instance.current}`);
  }, [prefix]);
  return id;
};
