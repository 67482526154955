import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Stack from "@mui/material/Stack";

import { actions, selectors, types } from "rdx";
import leadMessages from "rdx/modules/leads/messages";
import projectMessages from "rdx/modules/projects/messages";
import crmMessages from "rdx/modules/crmActivities/messages";
import { User } from "rdx/modules/users/types/index";
import useLoading from "hooks/useLoading";
import { UseNavigation } from "hooks/useNavigation";
import usePermissions, { permissionTypes } from "hooks/usePermissions";
import useRouteMatcher from "hooks/useRouteMatcher";
import useWindowResize from "hooks/useWindowResize";
import Lead from "models/Lead";
import { isUndefined } from "types/guards";

import Spinner from "components/LoadingElements/Spinner";
import BasicDrawer from "components/mui-styled-components/Drawers/BasicDrawer";
import { PrimaryButton } from "components/mui-styled-components/Buttons";
import { Tooltip } from "components/mui-styled-components/Tooltip";
import CloseLeadModal from "components/Modals/CloseLeadModal";
import { T3PartnerT } from "components/Drawers/SelectT3PartnerDrawer/types";
import ConfirmT3PartnerFooter from "components/Drawers/SelectT3PartnerDrawer/components/ConfirmT3PartnerFooter";
import useRsmRequirementsMet from "hooks/useRsmRequirementsMet";
import { RootState } from "rdx/types";
import CannotQualifyBanner from "./components/CannotQualifyBanner";
import CloseReasonBanner from "./components/CloseReasonBanner";
import LeadSummaryForm from "./components/LeadSummaryForm";
import SiteInfoForm from "./components/SiteInfoForm";
import SelectT3Partner from "./components/SelectT3Partner";
import ProposalRequestForm from "./components/ProposalRequestForm";
import RequestComplete from "./components/RequestComplete";
import * as S from "./styles";

type LeadToProposalDrawerPropsT = {
  navigate: ReturnType<UseNavigation>["navigate"];
  override: ReturnType<UseNavigation>["override"];
};

export enum L2pTabs {
  Summary = 0,
  SiteInfo = 1,
  Tier3Partner = 2,
  ProposalForm = 3,
}

const LeadToProposalDrawer = ({ navigate, override }: LeadToProposalDrawerPropsT) => {
  const dispatch = useDispatch();
  const leadFormRef = useRef<HTMLButtonElement>(null);
  const siteFormSubmitRef = useRef<HTMLButtonElement>(null);
  const siteFormSaveRef = useRef<HTMLButtonElement>(null);
  const t3PartnerFormRef = useRef<HTMLButtonElement>(null);
  const homeownerApptFormRef = useRef<HTMLButtonElement>(null);
  const proposalFormRef = useRef<HTMLButtonElement>(null);
  const drawerRef = useRef<HTMLDivElement>(null);
  const latestMessage = useSelector(selectors.getLatestMessageEvt);
  const isDrawerOpen = useSelector(selectors.selectLeadToProposalDrawerVisible);
  const leadId = useSelector(selectors.selectLeadToProposalDrawerLeadId);
  const projectId = useSelector(selectors.selectLeadToProposalDrawerProjectId);
  const initialTab = useSelector(selectors.selectLeadToProposalInitialTab);
  const leadDetails = useSelector(selectors.selectLeadToProposalLeadDetails);
  const projectDetails = useSelector(selectors.selectLeadToProposalProjectDetails);
  const isRequestSubmitted = useSelector(selectors.selectLeadToProposalRequestSubmitted);

  const currentUser = useSelector<RootState, User>(selectors.getCurrentUser);
  const lead = new Lead(leadDetails);
  const { ENTERPRISE_LEAD_GENERATOR } = usePermissions({
    permissionRequests: [permissionTypes.ENTERPRISE_LEAD_GENERATOR],
  });
  const { isMobileWidth } = useWindowResize();

  const { isEnterprise, isAdmin } = useRouteMatcher();
  const isNonServiceable = !!leadId && !leadDetails?.submitted && leadDetails?.submittableStatus?.status !== "ok";
  const isQualified = !!projectDetails?.qualifyAt;
  const isClosed = !!leadDetails?.closeAt;
  const projectHasMentor = !!projectDetails?.mentor;
  const currentUserIsCloser = currentUser.id === projectDetails?.mentor?.id;
  const userCanCreateHomeownerAppt =
    projectHasMentor && !leadDetails?.hasHomeownerApptScheduled && !currentUserIsCloser;
  const userCannotRequestProposal = !currentUser.projectRequirementMet;
  const [currentTab, setCurrentTab] = useState<number | null>(initialTab);
  const [closeAfterSave, setCloseAfterSave] = useState<boolean>(false);
  const [missingQualifyingSiteInfo, setMissingQualifyingSiteInfo] = useState<boolean>(true);
  const [missingProposalSiteInfo, setMissingProposalSiteInfo] = useState<boolean>(true);
  const [isAddressPresent, setIsAddressPresent] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isCurrentFormTouched, setIsCurrentFormTouched] = useState<boolean>(false);
  const noTabSet = currentTab === null;

  const rsmRequirementsPassed = useRsmRequirementsMet(projectDetails?.requiresRsm);

  const nonRequestableProject = isRequestSubmitted || userCannotRequestProposal || !rsmRequirementsPassed;

  // T3 Partner State Management
  const [selectedT3Partner, setSelectedT3Partner] = useState<T3PartnerT | null>(null);
  const [powurPairAlgorithmUsed, setPowurPairAlgorithmUsed] = useState<boolean>(false);
  const [openT3Footer, setOpenT3Footer] = useState(false);
  const [isPowurPairView, setIsPowurPairView] = useState<boolean>(false);
  const [isMentorSearchView, setIsMentorSearchView] = useState(false);
  const [isPowurPairFormView, setIsPowurPairFormView] = useState(true);
  const [closeProjectVisible, setCloseProjectVisible] = useState(false);
  const [isCreateApptDisabled, setIsCreateApptDisabled] = useState(false);
  const isT3SelectSubmitButtonDisabled =
    (!projectDetails?.mentorRequirementMet && !isPowurPairFormView) ||
    (!isPowurPairFormView && !rsmRequirementsPassed) ||
    isSubmitDisabled ||
    isFormSubmitting ||
    isClosed;

  // Proposal Request Form State Management
  const [isVisionSelfDesign, setIsVisionSelfDesign] = useState(false);
  const [isProposalFormValid, setIsProposalFormValid] = useState(false);
  const [isVisionRequestSubmitted, setIsVisionRequestSubmitted] = useState(false);

  const loading = useLoading({
    watchRequests: [
      types.GET_LEAD_DETAILS_REQUEST,
      types.GET_PROVIDER_LEAD_DETAILS_REQUEST,
      types.REQUEST_VISION_PROPOSAL,
    ],
  });

  const canProgressToNextTab = {
    0: leadId && !isNonServiceable && isAddressPresent,
    1: isQualified && !ENTERPRISE_LEAD_GENERATOR && !missingQualifyingSiteInfo,
    2: projectHasMentor || (projectDetails?.mentorRequirementMet && rsmRequirementsPassed),
  };

  const visionProposalURL = (slug: string): string => {
    return `${process.env.REACT_APP_VISION_CLIENT_URL}/proposal/${slug}`;
  };

  useEffect(() => {
    if (!isVisionRequestSubmitted || !projectDetails.visionProposal?.slug) {
      return;
    }

    window.open(visionProposalURL(projectDetails.visionProposal.slug), "_blank", "noopener,noreferrer");
  }, [isVisionRequestSubmitted, projectDetails.visionProposal?.slug]);

  useEffect(() => {
    if (noTabSet) {
      if (!leadId) {
        setCurrentTab(L2pTabs.Summary);
        return;
      }
      if (leadDetails?.id && (!projectId || projectDetails?.id)) {
        checkForFullAddress();
        checkForRequiredSiteInfo();
        setCurrentTab(selectInitialTab());
      }
    }
    if (initialTab) {
      checkForFullAddress();
      checkForRequiredSiteInfo();
    }
  }, [leadId, leadDetails?.id, projectDetails?.id, initialTab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (leadDetails?.address?.state) {
      dispatch(actions.getUtilityCompanies({ state: leadDetails.address.state, isLeadDrawer: true }));
    }
  }, [leadDetails?.address?.state]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDrawerOpen) {
      if (leadId) {
        dispatch(actions.getLeadDetails({ leadId, enterprise: isEnterprise, admin: isAdmin, isLeadDrawer: true }));
      }
      if (projectId) {
        dispatch(actions.getProviderLeadDetails({ providerLeadId: projectId, enterprise: isEnterprise }));
      }
    }
  }, [leadId, projectId, isDrawerOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const successMessages = [
      leadMessages.LEAD_CREATE_SUCCESS,
      leadMessages.LEAD_UPDATE_SUCCESS,
      leadMessages.LEAD_SUBMIT_SUCCESS,
      projectMessages.UPDATE_PROVIDER_LEAD_SUCCESS,
      crmMessages.CRM_MEETING_CREATE_SUCCESS,
      projectMessages.REQUEST_VISION_PROPOSAL_SUCCESS,
    ];
    const errorMessages = [
      leadMessages.ERROR_CREATING_LEAD,
      leadMessages.ERROR_UPDATING_LEAD,
      leadMessages.ERROR_SUBMITTING_LEAD,
      leadMessages.ERROR_UPDATING_LEAD_DNC_PHONE,
      projectMessages.ERROR_REQUESTING_PROPOSAL,
      projectMessages.VISION_PROPOSAL_TIMEOUT,
      projectMessages.ERROR_GETTING_VISION_PROPOSAL,
    ];
    if (isDrawerOpen) {
      if (successMessages.includes(latestMessage.message)) {
        setIsSubmitDisabled(false);
        setIsFormSubmitting(false);
        setIsCurrentFormTouched(false);
        dispatch(
          actions.setAlertMessageVisible({
            message: latestMessage.message,
            severity: "success",
            duration: latestMessage.duration,
          }),
        );
        if (closeAfterSave) {
          handleCloseDrawer();
        } else if (!noTabSet && canProgressToNextTab[currentTab]) {
          handleChangeTab(currentTab + 1);
        }
      }
      if (latestMessage.message === crmMessages.CRM_MEETING_CREATE_SUCCESS) {
        dispatch(actions.setLeadToProposalDrawerLeadDetails({ ...leadDetails, hasHomeownerApptScheduled: true }));
      }
      if (latestMessage.error?.message || errorMessages.includes(latestMessage.message)) {
        let msg = latestMessage.error?.message || latestMessage.message;
        let duration = 2000;
        if (leadMessages.ERROR_UPDATING_LEAD_DNC_PHONE === latestMessage.message) {
          msg = latestMessage.error.message;
          duration = 30000;
        }
        dispatch(
          actions.setAlertMessageVisible({
            message: msg,
            severity: "error",
            duration,
          }),
        );
      }
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeTab = (tabIdx: number): void => {
    setCurrentTab(tabIdx);
    if (drawerRef?.current) {
      drawerRef.current.scrollTo(0, 0);
    }
  };

  const checkForRequiredSiteInfo = () => {
    const isQualifyingSiteInfoPresent =
      leadDetails?.siteInfo?.utilityCompany &&
      leadDetails?.isAddressConfirmed &&
      !isUndefined(leadDetails.existingSystem);
    setMissingQualifyingSiteInfo(!isQualifyingSiteInfoPresent);
    const isProposalSiteInfoPresent = isQualifyingSiteInfoPresent && !!leadDetails?.siteInfo?.kwhConsumption;
    setMissingProposalSiteInfo(!isProposalSiteInfoPresent);

    return { isQualifyingSiteInfoPresent, isProposalSiteInfoPresent };
  };

  const checkForFullAddress = () => {
    const isFullAddressPresent =
      !!leadDetails?.address?.street &&
      !!leadDetails?.address?.city &&
      !!leadDetails?.address?.state &&
      !!leadDetails?.address?.zip;
    setIsAddressPresent(isFullAddressPresent);

    return isFullAddressPresent;
  };

  const handleSelectingT3Partner = (mentor: T3PartnerT | null, isPowurPair: boolean): void => {
    setSelectedT3Partner(mentor);
    setPowurPairAlgorithmUsed(isPowurPair);
  };

  const handleT3PartnerConfirm = (mentorId: number): void => {
    dispatch(
      actions.setAlertMessageVisible({
        message: "Submitting. . .",
        severity: "info",
        duration: 10000,
      }),
    );
    dispatch(
      actions.selectMentor({
        id: projectId,
        mentorId,
        mentorAlgorithmUsed: powurPairAlgorithmUsed,
      }),
    );
  };

  const handleSubmitButton = () => {
    if (!noTabSet && !tabInfo.tabs[currentTab].submitButtonDisabled && !isSubmitDisabled) {
      tabInfo.tabs[currentTab].submitAction();
    }
  };

  const handleSaveForLater = () => {
    if (!isCurrentFormTouched) {
      handleCloseDrawer();
    } else if (!noTabSet) {
      setCloseAfterSave(true);
      const { saveForLaterAction, submitAction } = tabInfo.tabs[currentTab];
      if (saveForLaterAction) {
        saveForLaterAction();
      } else {
        submitAction();
      }
    }
  };

  const resetT3UrlParams = useCallback(
    (additionalParams = {}) => {
      navigate({
        p: null,
        q: null,
        service_area: null,
        spoken_languages: null,
        appt_location: null,
        team_search: null,
        sort: null,
        certification: null,
        project_id: null,
        reverse: null,
        ...additionalParams,
      });
    },
    [navigate],
  );

  const resetT3LandingPage = () => {
    setIsPowurPairView(false);
    setIsMentorSearchView(false);
    setIsPowurPairFormView(true);
    setSelectedT3Partner(null);
    setPowurPairAlgorithmUsed(false);
    resetT3UrlParams();
  };

  const handleT3PartnerBack = (): void => {
    resetT3UrlParams();
    if (!isPowurPairView && !isMentorSearchView && currentTab) {
      handleChangeTab(currentTab - 1);
    } else if (isMentorSearchView || (isPowurPairView && isPowurPairFormView)) {
      resetT3LandingPage();
    } else if (isPowurPairView) {
      setIsPowurPairFormView(true);
    } else if (projectHasMentor && currentTab) {
      handleChangeTab(currentTab - 1);
    }
  };

  const handleT3PartnerNext = (): void => {
    if (userCanCreateHomeownerAppt) {
      homeownerApptFormRef?.current?.click();
    } else if (isPowurPairView && isPowurPairFormView) {
      handleSubmitButton();
    } else if (projectDetails?.mentorRequirementMet && currentTab) {
      handleChangeTab(currentTab + 1);
      resetT3UrlParams();
    }
  };

  const handleCloseDrawer = () => {
    dispatch(actions.clearLeadToProposalDrawer());
    dispatch(actions.clearLatestMessage());
    dispatch(actions.setLeadToProposalDrawerVisible(false));
    resetT3UrlParams();
  };

  const setDrawerVisibility = (isVisible: boolean) => {
    if (!isVisible) {
      handleCloseDrawer();
    }
  };

  const shouldRequireMentor = (): boolean => {
    return (
      !isEmpty(projectDetails) && !projectHasMentor && (!projectDetails?.mentorRequirementMet || !rsmRequirementsPassed)
    );
  };

  const shouldStartFromSummaryTab = (): boolean => {
    const hasFullAddress = checkForFullAddress();

    return !leadId || isNonServiceable || !hasFullAddress;
  };

  const shouldStartFromSiteInfoTab = (): boolean => {
    if (shouldStartFromSummaryTab()) {
      return false;
    }

    const { isQualifyingSiteInfoPresent, isProposalSiteInfoPresent } = checkForRequiredSiteInfo();

    return !isQualified || !isQualifyingSiteInfoPresent || !isProposalSiteInfoPresent;
  };

  const shouldStartFromTier3Tab = (): boolean => {
    if (shouldStartFromSummaryTab() || shouldStartFromSiteInfoTab()) {
      return false;
    }

    return shouldRequireMentor();
  };

  const selectInitialTab = (): L2pTabs => {
    const tabSelectionCriteria = [
      { condition: shouldStartFromSummaryTab, tab: L2pTabs.Summary },
      { condition: shouldStartFromSiteInfoTab, tab: L2pTabs.SiteInfo },
      { condition: shouldStartFromTier3Tab, tab: L2pTabs.Tier3Partner },
    ];

    const matchingCriteria = tabSelectionCriteria.find((criteria) => criteria.condition());

    return matchingCriteria ? matchingCriteria.tab : L2pTabs.ProposalForm;
  };

  const shouldDisableSiteInfoSubmit = (): boolean => {
    if (missingQualifyingSiteInfo) return true;

    if (!isQualified) {
      return !isLeadQualifiable();
    }

    return false;
  };

  const shouldDisableProposalRequestSubmit = (): boolean => {
    if (proposalRequestTabButtonText() === "Close") {
      return false;
    }

    return missingProposalSiteInfo || !isProposalFormValid || isClosed || loading;
  };

  const isLeadQualifiable = (): boolean => {
    return !(isNonServiceable || isSubmitDisabled || isFormSubmitting || isClosed || missingQualifyingSiteInfo);
  };

  const qualifyTabSubmitButtonText = () => {
    if (isQualified) return "Next";
    if (isMobileWidth) return "Qualify";

    return "Qualify Lead";
  };

  const closerTabSubmitButtonText = () => {
    if (!userCanCreateHomeownerAppt) return "Next";
    if (isMobileWidth) return "Create";

    return "Create Appointment";
  };

  const proposalRequestTabButtonText = () => {
    if (nonRequestableProject) return "Close";
    if (isVisionSelfDesign) return "Create Proposal";
    return "Send Proposal Request";
  };

  const customFields = lead.props.customFields || [];
  const powurFields = lead.props.powurCustomData || [];
  const enterpriseName = lead.props.enterprise?.name || "";

  const tabInfo = {
    tabs: [
      {
        label: "Create Lead",
        content: (
          <>
            {isNonServiceable && <CannotQualifyBanner />}
            <LeadSummaryForm
              reference={leadFormRef}
              currentTab={currentTab || 0}
              setCurrentTab={handleChangeTab}
              setIsSubmitDisabled={setIsSubmitDisabled}
              setIsFormSubmitting={setIsFormSubmitting}
              setIsFormTouched={setIsCurrentFormTouched}
              customFields={customFields}
              powurFields={powurFields}
              enterpriseName={enterpriseName}
              setIsAddressPresent={setIsAddressPresent}
            />
          </>
        ),
        submitButtonText: leadId ? "Next" : "Create Lead",
        submitButtonDisabledReason:
          !isAddressPresent && !!leadId ? "Physical address required to progress the lead" : null,
        submitButtonDisabled:
          isNonServiceable || isSubmitDisabled || isFormSubmitting || isClosed || (!isAddressPresent && !!leadId),
        submitAction: () => leadFormRef?.current?.click(),
      },
      {
        label: "Qualify Lead",
        content: (
          <>
            <SiteInfoForm
              submitRef={siteFormSubmitRef}
              saveRef={siteFormSaveRef}
              currentTab={currentTab || 1}
              setCurrentTab={handleChangeTab}
              latestMessage={latestMessage}
              setMissingQualifyingSiteInfo={setMissingQualifyingSiteInfo}
              setMissingProposalSiteInfo={setMissingProposalSiteInfo}
              setIsFormSubmitting={setIsFormSubmitting}
              setIsFormTouched={setIsCurrentFormTouched}
              closeDrawer={handleCloseDrawer}
              customFields={customFields}
              powurFields={powurFields}
              enterpriseName={enterpriseName}
            />
          </>
        ),
        requiresAttention: missingProposalSiteInfo,
        submitButtonText: qualifyTabSubmitButtonText(),
        submitButtonDisabled: shouldDisableSiteInfoSubmit(),
        submitAction: () => siteFormSubmitRef?.current?.click(),
        saveForLaterAction: () => siteFormSaveRef?.current?.click(),
      },
      {
        label: "Select Closer",
        content: (
          <SelectT3Partner
            powurPairSubmitRef={t3PartnerFormRef}
            homeownerApptSubmitRef={homeownerApptFormRef}
            navigate={navigate}
            override={override}
            selectedT3Partner={selectedT3Partner}
            setSelectedT3Partner={handleSelectingT3Partner}
            setOpenT3Footer={setOpenT3Footer}
            isPowurPairView={isPowurPairView}
            setIsPowurPairView={setIsPowurPairView}
            isMentorSearchView={isMentorSearchView}
            setIsMentorSearchView={setIsMentorSearchView}
            isPowurPairFormView={isPowurPairFormView}
            setIsPowurPairFormView={setIsPowurPairFormView}
            resetLandingPage={resetT3LandingPage}
            assignedPartner={projectDetails?.mentor}
            resetT3UrlParams={resetT3UrlParams}
            rsmRequirementsPassed={rsmRequirementsPassed}
            currentTab={currentTab || 0}
            setCurrentTab={handleChangeTab}
            setIsCreateApptDisabled={setIsCreateApptDisabled}
            closeDrawer={handleCloseDrawer}
            drawerContentRef={drawerRef}
          />
        ),
        submitButtonText: closerTabSubmitButtonText(),
        submitButtonDisabled: userCanCreateHomeownerAppt ? isCreateApptDisabled : isT3SelectSubmitButtonDisabled,
        submitAction: () => t3PartnerFormRef?.current?.click(),
      },
      {
        label: "Request Proposal",
        content: isRequestSubmitted ? (
          <RequestComplete />
        ) : (
          <ProposalRequestForm
            latestMessage={latestMessage}
            reference={proposalFormRef}
            assignedPartner={projectDetails?.mentor}
            rsmRequirementsPassed={rsmRequirementsPassed}
            setIsVisionSelfDesign={setIsVisionSelfDesign}
            setIsProposalFormValid={setIsProposalFormValid}
            setIsVisionRequestSubmitted={setIsVisionRequestSubmitted}
            isReqdSiteInfoMissing={missingProposalSiteInfo}
            setCurrentTab={handleChangeTab}
            checkForReqdSiteInfo={checkForRequiredSiteInfo}
          />
        ),
        submitButtonText: proposalRequestTabButtonText(),
        submitButtonDisabled: shouldDisableProposalRequestSubmit(),
        submitAction: nonRequestableProject ? handleCloseDrawer : () => proposalFormRef?.current?.click(),
      },
    ],
    currentTab: currentTab || 0,
    setCurrentTab: handleChangeTab,
  };

  const headerContent: React.ReactNode = (
    <S.Header>
      <S.HeaderContent>
        {leadDetails?.fullName}
        {leadDetails?.id && <S.HeaderSubText>{projectId || leadId}</S.HeaderSubText>}
      </S.HeaderContent>
    </S.Header>
  );

  const showNextButtonInT3Footer =
    isPowurPairView || projectHasMentor || (projectDetails?.mentorRequirementMet && !isMentorSearchView);

  const t3PartnerFooter: React.ReactNode =
    selectedT3Partner && openT3Footer && !projectHasMentor && leadDetails ? (
      <ConfirmT3PartnerFooter
        selectedT3Partner={selectedT3Partner}
        setSelectedT3Partner={setSelectedT3Partner}
        lead={leadDetails}
        handleSelect={handleT3PartnerConfirm}
        setOpenFooter={setOpenT3Footer}
      />
    ) : (
      <S.T3Footer>
        <PrimaryButton
          variant="outlined"
          style={{
            minWidth: "70px",
            fontSize: "12px",
            marginRight: "8px",
            ...(isMobileWidth ? { flexGrow: 1, maxWidth: "30%" } : {}),
          }}
          onClick={handleT3PartnerBack}
          disableRipple={isFormSubmitting}
          disabled={isFormSubmitting}
        >
          Back
        </PrimaryButton>
        <Stack direction="row" sx={{ ...(isMobileWidth ? { flexGrow: 3 } : {}) }}>
          {userCanCreateHomeownerAppt && (
            <PrimaryButton
              variant="outlined"
              style={{ fontSize: "12px", marginRight: "8px", ...(isMobileWidth ? { flexGrow: 1 } : {}) }}
              onClick={() => handleChangeTab(L2pTabs.ProposalForm)}
              disableRipple={isFormSubmitting}
              disabled={isFormSubmitting}
            >
              Skip
            </PrimaryButton>
          )}
          {showNextButtonInT3Footer && (
            <PrimaryButton
              gradient
              onClick={handleT3PartnerNext}
              disableRipple={tabInfo.tabs[currentTab || 2]?.submitButtonDisabled}
              disabled={tabInfo.tabs[currentTab || 2]?.submitButtonDisabled}
              style={{ fontSize: "12px", ...(isMobileWidth ? { flexGrow: 1 } : {}) }}
            >
              {tabInfo.tabs[currentTab || 0]?.submitButtonText}
            </PrimaryButton>
          )}
        </Stack>
      </S.T3Footer>
    );

  const footerContent: React.ReactNode =
    currentTab === 2 ? (
      t3PartnerFooter
    ) : (
      <S.Footer>
        <Stack direction="row" sx={{ ...(isMobileWidth ? { flexGrow: 2, maxWidth: "70%" } : {}) }}>
          {leadId && !noTabSet && currentTab < 2 && (
            <PrimaryButton
              variant="outlined"
              style={{
                fontSize: "12px",
                marginRight: "8px",
                ...(isMobileWidth ? { flexGrow: 1 } : {}),
              }}
              onClick={handleSaveForLater}
              disabled={isFormSubmitting}
              disableRipple={isFormSubmitting}
              data-test-id="save-for-later"
            >
              {isMobileWidth ? "Save" : "Save For Later"}
            </PrimaryButton>
          )}
          <div style={{ ...(isMobileWidth ? { flexGrow: 4 } : {}) }}>
            <Tooltip title={tabInfo.tabs[currentTab || 0]?.submitButtonDisabledReason} placement="top">
              <>
                <PrimaryButton
                  gradient
                  onClick={handleSubmitButton}
                  disabled={tabInfo.tabs[currentTab || 0]?.submitButtonDisabled}
                  style={{ fontSize: "12px", width: "100%" }}
                  disableRipple={tabInfo.tabs[currentTab || 0]?.submitButtonDisabled}
                >
                  {tabInfo.tabs[currentTab || 0]?.submitButtonText}
                </PrimaryButton>
              </>
            </Tooltip>
          </div>
        </Stack>
        {!!currentTab && (!nonRequestableProject || currentTab !== 3) && (
          <PrimaryButton
            variant="outlined"
            style={{
              minWidth: "70px",
              fontSize: "12px",
              ...(isMobileWidth ? { flexGrow: 1, marginRight: "8px", maxWidth: "30%" } : {}),
            }}
            onClick={() => handleChangeTab(currentTab - 1)}
            disabled={isFormSubmitting}
            disableRipple={isFormSubmitting}
            data-test-id="back"
          >
            Back
          </PrimaryButton>
        )}
      </S.Footer>
    );

  const drawerTabContent: React.ReactNode =
    loading || noTabSet ? <Spinner loading={!!loading} /> : <>{tabInfo.tabs[currentTab].content}</>;

  return (
    <BasicDrawer
      visible={isDrawerOpen}
      setVisible={setDrawerVisibility}
      onClose={handleCloseDrawer}
      width={Math.min(window.innerWidth, 800)}
      headerContent={leadId ? headerContent : "Create New Lead"}
      footerContent={footerContent}
      tabInfo={!isClosed && !isRequestSubmitted ? tabInfo : undefined}
      showTimelineDisplay={!isClosed && !isRequestSubmitted}
      drawerContentRef={drawerRef}
    >
      <div>
        {isClosed && <CloseReasonBanner leadDetails={leadDetails} />}
        {drawerTabContent}
        <CloseLeadModal
          leadDetails={leadDetails}
          visible={closeProjectVisible}
          setVisible={setCloseProjectVisible}
          closeReasons={leadDetails?.leadCloseReasons}
        />
      </div>
    </BasicDrawer>
  );
};

export default LeadToProposalDrawer;
