import z, { ZodIssue } from "zod";
import { Notifier } from "@airbrake/browser";

type Config<T extends z.ZodTypeAny> = {
  dto: unknown;
  schema: T;
  schemaName: string;
};

type ValidateResult<T> =
  | {
      success: false;
    }
  | { success: true; data: T };

export default class SchemaValidator {
  private static airbrake: Notifier;

  private static isDevelopmentEnvironment() {
    return process.env.REACT_APP_ENV === "development";
  }

  private static initializeAirbrake() {
    this.airbrake = new Notifier({
      projectId: Number(process.env.REACT_APP_AIRBRAKE_PROJECT_ID),
      environment: process.env.REACT_APP_ENV,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY,
    });
  }

  private static logError(message: string, extra: { dto: unknown; issues: ZodIssue[] }) {
    if (process.env.REACT_APP_ENV === "development") {
      console.error(message, extra);
    } else if (this.airbrake) {
      try {
        this.airbrake.notify({
          error: new Error(message),
          context: { severity: "warning", issues: extra.issues },
        });
      } catch (err) {
        console.error("Failed to notify Airbrake:", err);
      }
    } else {
      console.error(message, extra);
    }
  }

  static validate<T extends z.ZodTypeAny>(config: Config<T>): ValidateResult<z.infer<T>> {
    if (!this.airbrake && process.env.REACT_APP_ENV !== "development") {
      this.initializeAirbrake();
    }

    const result = config.schema.safeParse(config.dto);
    if (!result.success) {
      this.logError(`Schema Validation Error: ${config.schemaName}`, {
        dto: config.dto,
        issues: result.error.issues,
      });
      return {
        success: false,
      };
    }
    return {
      success: result.success,
      data: result.data,
    };
  }
}
