export default {
  GET_USERS_LIST_REQUEST: "[users] GET Users List Request",
  SET_USERS_LIST: "[users] SET Users List",
  SET_DETAIL_IN_USERS_LIST: "[users] SET Detail In Users List",
  GET_USER_ROLES_LIST_REQUEST: "[users] GET User Roles List Request",
  SET_USER_ROLES_LIST: "[users] SET User Roles List",
  GET_USER_DETAILS_REQUEST: "[users] GET User Details Request",
  SET_USER_DETAILS: "[users] SET User Details",
  GET_MENTOR_RATINGS_REQUEST: "[users] GET Mentor Ratings Request",
  GET_ADMIN_MENTOR_RATINGS_REQUEST: "[users] GET Admin Mentor Ratings Request",
  SET_MENTOR_RATINGS: "[users] SET Mentor Ratings",
  GET_USER_NAME_REQUEST: "[users] GET User Name Request",
  SET_USER_NAME: "[users] SET User Name",
  SET_DETAIL_IN_USER_DETAILS: "[users] SET Detail In User Details",
  ADD_PERMISSION_TO_ROLE_REQUEST: "[users] Add Permission To Role Request",
  REMOVE_PERMISSION_FROM_ROLE_REQUEST: "[users] Remove Permission From Role Request",
  IMPERSONATE_USER_REQUEST: "[users] Impersonate User Request",
  LOGBACK_USER_REQUEST: "[users] Logback User Request",
  UPDATE_USER_REQUEST: "[users] Update User Request",
  ADD_ROLE_TO_USER_REQUEST: "[users] Add Role To User Request",
  REMOVE_ROLE_FROM_USER_REQUEST: "[users] Remove Role From User Request",
  GET_TEAM_USERS_AUTOCOMPLETE_REQUEST: "[users] GET Team Users Autocomplete Request",
  GET_USERS_AUTOCOMPLETE_REQUEST: "[users] GET Users Autocomplete Request",
  SET_USERS_AUTOCOMPLETE: "[users] SET Users Autocomplete",
  CHANGE_USER_SPONSOR_REQUEST: "[users] Change User Sponsor Request",
  CHANGE_USER_MANAGER_REQUEST: "[users] Change User Manager Request",
  CHANGE_USER_CERTIFICATION_DATE_REQUEST: "[users] Change User Certification Date Request",
  SET_USER_AS_COMPED_REQUEST: "[users] SET User As Comped Request",
  ACTIVATE_USER_ENROLLMENT_REQUEST: "[users] Activate User Enrollment Request",
  ADD_NEW_ROLE_REQUEST: "[users] Add New Role Request",
  UPDATE_ROLE_REQUEST: "[users] Update Role",
  DELETE_ROLE_REQUEST: "[users] Delete Role",
  READD_USER_TO_LMS: "[users] Readd User To Lms",
  CANCEL_SUBSCRIPTION_BY_ADMIN: "[users] Cancel Subscription By Admin",
  BAN_USER_REQUEST: "[users] Ban User Request",
  UNBAN_USER_REQUEST: "[users] Unban User Request",
  RENEW_SUBSCRIPTION_BY_ADMIN: "[users] Renew Subscription By Admin",
  DESTROY_SUBSCRIPTION_BY_ADMIN: "[users] Destroy Subscription By Admin",
  ADD_NEW_PERMISSION_REQUEST: "[users] Add New Permission Request",
  DELETE_PERMISSION_REQUEST: "[users] Delete Permission Request",
  SET_USER_PERMISSIONS_LIST: "[users] SET User Permissions List",
  GET_PERMISSIONS_ENTITIES_REQUEST: "[users] GET Permissions Entities Request",
  GET_PERMISSIONS_OPERATIONS_REQUEST: "[users] GET Permissions Operations Request",
  CREATE_PERMISSIONS_ENTITY_REQUEST: "[users] Create Permissions Entity Request",
  CREATE_PERMISSIONS_OPERATION_REQUEST: "[users] Create Permissions Operation Request",
  UPDATE_PERMISSION_REQUEST: "[users] Update Permission Request",
  DELETE_PERMISSIONS_ENTITY_REQUEST: "[users] Delete Permissions Entity Request",
  DELETE_PERMISSIONS_OPERATION_REQUEST: "[users] Delete Permissions Operation Request",
  UPDATE_PERMISSIONS_ENTITY_REQUEST: "[users] Update Permissions Entity Request",
  UPDATE_PERMISSIONS_OPERATION_REQUEST: "[users] Update Permissions Operation Request",
  MARK_TOOLTIP_VIEWED_REQUEST: "[users] Mark Tooltip Viewed Request",
  GET_TEAMS_LIST_REQUEST: "[users] GET Teams List Request",
  SET_TEAMS_LIST: "[users] SET Teams List",
  CREATE_TEAM_REQUEST: "[users] Create Team Request",
  UPDATE_TEAM_REQUEST: "[users] Update Team Request",
  DELETE_TEAM_REQUEST: "[users] Delete Team Request",
  GET_LITMOS_LOGIN_LINK: "[users] GET Litmos Login Link",
  SET_LITMOS_LOGIN_LINK: "[users] SET Litmos Login Link",
  UPDATE_ADMIN_USERS_MENTOR_RATING: "[users] Update Admin Users Mentor Rating",
  RESET_MENTOR_USER_LOOKUPS: "[users] Reset Mentor User Lookups",
  SET_MENTOR_USER_LOOKUPS: "[users] SET Mentor User Lookups",
  SET_UNSAVED_MENTOR_RATINGS: "[users] SET Unsaved Mentor Ratings",
  CERTIFY_USER: "[users] Certify User",
  GET_SELLER_APPLICATION_AGREEMENT_REQUEST: "[ambassador] GET Seller Application Agreement",
  SET_SELLER_APPLICATION_AGREEMENT: "[ambassador] SET Seller Application Agreement",
  GET_MARKETING_TOKEN: "[users] GET Marketing Token",
  SET_MARKETING_TOKEN: "[users] SET Marketing Token",
  UPGRADE_AMBASSADOR_REQUEST: "[users] Upgrade Ambassador Request",
  UPDATE_DEFAULT_ADVANCE: "[users] Update default advance",
  SET_USER_PAST_DUE_HOMEOWNER_APPOINTMENT: "[users] SET User Past Due Homeowner Appointment",
  // INSERTION_PT (for script, do not remove)
};
