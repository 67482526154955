import { permissionTypes } from "hooks/usePermissions";

const { LOGGED_ACTIONS, PROJECT_MANAGEMENT } = permissionTypes;

const commonTabs = [];

const adminTabs = [
  {
    key: "activity-log",
    label: "Activity Log",
    permission: LOGGED_ACTIONS,
  },
  {
    key: "ticket-metrics",
    label: "Ticket Metrics",
    permission: PROJECT_MANAGEMENT,
  },
];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
