import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Button, Form, Row, Col, Input, Spin } from "antd";
import { actions } from "rdx";
import FormErrorMessage from "components/FormElements/FormErrorMessage";
import MessageEvent from "models/MessageEvent";
import msgs from "rdx/modules/profile/messages";
import styles from "../../AmbassadorDashboard.module.less";

const ChangePassword = (props) => {
  const { loading, updatePassword, latestMessage } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    const handleInvalidPassword = () => {
      form.validateFields(["password"], { force: true });
    };

    if (latestMessage) {
      const { message: msg, error } = latestMessage;
      if (msg === msgs.PASSWORD_UPDATE_SUCCESS) {
        dispatch(
          actions.setAlertMessageVisible({
            message: "Password Reset",
            severity: "success",
          }),
        );
        form.resetFields();
      }
      if (error && error.message === "Invalid password") {
        dispatch(
          actions.setAlertMessageVisible({
            message: "Invalid Password",
            severity: "error",
          }),
        );
        handleInvalidPassword();
      }
      if (msg === msgs.ERROR_UPDATING_PASSWORD) {
        handleInvalidPassword();
      }
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePasswordSubmit = (values) => {
    updatePassword(values);
  };

  return (
    <Form
      form={form}
      name="ambassador-password"
      className={styles.form}
      requiredMark={false}
      colon={false}
      layout="vertical"
      onFinish={handlePasswordSubmit}
    >
      {loading && (
        <div className={styles.fetching}>
          <Spin className={styles.spin} />
        </div>
      )}
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name={["password"]}
            dependencies={["newPassword"]}
            className={styles.formItem}
            label={<span className={styles.label}>Current Password</span>}
            validateTrigger={["onSubmit"]}
            rules={[
              {
                required: true,
                message: <FormErrorMessage message="Current Password is required." />,
              },
            ]}
          >
            <Input.Password
              className={styles.passwordInput}
              placeholder="CURRENT PASSWORD"
              autoComplete="current-password"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["newPassword"]}
            className={styles.formItem}
            dependencies={["password"]}
            label={<span className={styles.label}>New Password</span>}
            validateTrigger={["onSubmit"]}
            rules={[
              {
                required: true,
                message: <FormErrorMessage message="New Password is required." />,
              },
              {
                min: 6,
                message: <FormErrorMessage message="New password must be at least 6 characters.." />,
              },
              {
                validator: (_rule, value) => {
                  if (!value || form.getFieldValue("password") !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("New password must not equal current password"));
                },
                message: <FormErrorMessage message="New password must not equal current password." />,
              },
            ]}
          >
            <Input.Password className={styles.passwordInput} placeholder="PASSWORD" autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            name={["passwordConfirm"]}
            className={styles.formItem}
            validateTrigger={["onSubmit"]}
            rules={[
              {
                required: true,
                message: <FormErrorMessage message="Please confirm your new password." />,
              },
              {
                validator: (_rule, value) => {
                  if (!value || form.getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match"));
                },
                message: <FormErrorMessage message="Passwords do not match." />,
              },
            ]}
          >
            <Input.Password
              className={styles.passwordInput}
              placeholder="CONFIRM PASSWORD"
              autoComplete="new-password"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item className={styles.formItem}>
            <Button className={styles.submitButton} type="primary" block htmlType="submit">
              Update Password
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

ChangePassword.propTypes = {
  loading: PropTypes.bool,
  updatePassword: PropTypes.func,
  latestMessage: MessageEvent.types(),
};

export default ChangePassword;
