import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/publicApiProxy/messages";
import { camelizeKeys } from "lib/helpers";

function* getGoogleAccessStatus() {
  const { success, data, error } = yield* makeRequest.get("/gmail_oauth/access_status");

  if (success && data) {
    const result = camelizeKeys(data);

    yield all([
      put(actions.setGoogleAccessStatus(result)),
      put(
        actions.newSuccessEvent({
          message: messages.GET_GOOGLE_ACCESS_STATUS_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_GOOGLE_ACCESS_STATUS,
    });
  }

  return null;
}

export default getGoogleAccessStatus;
