import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button } from "antd";
import types from "../types";
import styles from "../styles.module.less";

const SubmitButton = (props) => {
  const loading = () => _.some(props.files, ["meta.status", "uploading"]);
  const disabled = () => props.files.length === 0 || loading();

  return (
    <Button
      className={[styles.submitButton, styles.disabledButton].join(" ")}
      type="primary"
      onClick={() => props.onSubmit(props.files)}
      disabled={disabled()}
      loading={loading()}
      data-test-id="complete-upload-button"
    >
      Complete Upload
    </Button>
  );
};

SubmitButton.propTypes = {
  // props passed down by Dropzone component, can be used or disregarded
  // buttonClassName: PropTypes.string,
  // className: PropTypes.string, // for component container
  // buttonStyle: PropTypes.object,
  // content: PropTypes.string,
  // disabled: PropTypes.bool,
  // extra: types.extra,
  // style: PropTypes.object,
  files: PropTypes.arrayOf(types.fileWithMeta),
  onSubmit: PropTypes.func,
};

export default SubmitButton;
