import React from "react";
import PropTypes from "prop-types";

import styles from "../CreateAccount.module.less";

const RoundBadge = ({ imgSource, imgAlt, imgStyle }) => {
  return (
    <div className={styles.joinPowurBadgeContainer}>
      <svg width="172" height="172" viewBox="0 0 172 172" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M168.42 85.7099C168.42 40.0305 131.389 3 85.7099 3C40.0305 3 3 40.0305 3 85.7099C3 131.389 40.0305 168.42 85.7099 168.42"
          stroke="url(#paint0_linear_23_533)"
          strokeWidth="6"
        />
        <defs>
          <linearGradient
            id="paint0_linear_23_533"
            x1="12.2822"
            y1="1.86116"
            x2="82.4478"
            y2="130.489"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="var(--congress-blue)" />
            <stop offset="0.416667" stopColor="var(--blue-ribbon)" />
            <stop offset="1" stopColor="var(--sweet-mint)" stopOpacity="0.83" />
          </linearGradient>
        </defs>
      </svg>
      <div className={styles.joinPowurBadgePhotoContainer}>
        <div className={styles.joinPowurBadgeContent} style={imgStyle}>
          <img className={styles.logo} src={imgSource} alt={imgAlt} />
        </div>
      </div>
    </div>
  );
};

RoundBadge.propTypes = {
  imgSource: PropTypes.string,
  imgAlt: PropTypes.string,
  imgStyle: PropTypes.objectOf(PropTypes.string),
};

export default RoundBadge;
