import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ZipButton, ZipInput } from "components/HomepageComponents";
import useWindowResize from "hooks/useWindowResize";
import styles from "../../Solar.module.less";

const SolarIntro = () => {
  const { t } = useTranslation(["whysolar", "form_labels"]);
  const { width } = useWindowResize();
  const mobile = useMemo(() => {
    return width <= 768;
  }, [width]);
  const urlPrefix = process.env.REACT_APP_S3_URL_PREFIX;
  const [zip, setZip] = useState("");

  return (
    <div className={styles.solarIntro}>
      {mobile ? (
        <div className={styles.backgroundImage} />
      ) : (
        <div className={styles.videoContainer}>
          <video
            className={styles.video}
            playsInline
            autoPlay
            muted
            loop
            poster={`${urlPrefix}/assets/img/why-solar/solar-carousel_video_screenshot.jpg`}
          >
            <source src={`${urlPrefix}/assets/videos/PowurHomeowner-Carousel_loop_v2.mp4`} type="video/mp4" />
          </video>
        </div>
      )}
      <div className={styles.imageOverlay} />
      <div className={styles.innerContainer}>
        <h2 className={styles.headline}>{t("banner.how_much_you_can_save")}</h2>
        <p className={styles.subHeading}>{t("banner.powur_provides_cleaner_greener_energy")}</p>
        <div className={styles.zipInputContainer}>
          <ZipInput zip={zip} setZip={setZip} className={styles.input} />
          <ZipButton className={styles.zipButton} type="primary" zip={zip}>
            {t("form_labels:start_the_quote_process")}
          </ZipButton>
        </div>
      </div>
    </div>
  );
};

export default SolarIntro;
