import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { parse, stringify } from "query-string";
import useModalControls from "hooks/useModalControls";
import styles from "./SuccessModal.module.less";
import common from "../HomepageComponents.module.less";

const SuccessModal = () => {
  const { t } = useTranslation(["home", "translation"]);
  const { location } = useHistory();
  const history = useHistory();
  const search = parse(location.search);
  const { ref_id } = search;

  const mobileView = window.innerWidth <= 720;
  const { visible, open, close } = useModalControls();

  useEffect(() => {
    const { success } = search;
    if (success) open();
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeModal = () => {
    history.push({
      pathname: history.location.pathname,
      search: `?${stringify({ ref_id })}`,
    });
    close();
  };

  return (
    <Modal
      bodyStyle={{
        minWidth: mobileView ? "300px" : "720px",
        padding: 0,
      }}
      className={styles.billModal}
      open={visible}
      footer={null}
      mask
      maskClosable
      centered
      keyboard
      destroyOnClose
      onCancel={closeModal}
      onClose={closeModal}
      closeIcon={<CloseOutlined style={{ color: "white" }} />}
    >
      <div className={styles.container}>
        <div className={styles.headerBg}>
          <div className={[common.header, styles.header].join(" ")}>
            <div className={common.headerText}>{t("translation:congratulations")}</div>
            <div className={common.headerSubtext}>{t("quote_modal.first_step_towards_electrical_freedom")}</div>
          </div>
        </div>
        <div className={styles.inner}>
          <div className={styles.paragraph}>{t("quote_modal.information_successfully_submitted")}</div>
          <div className={styles.largeText}>{t("translation:thank_you")}</div>
          <Button className={styles.button} onClick={closeModal} type="primary" ghost>
            {t("translation:close")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

SuccessModal.propTypes = {};

export default SuccessModal;
