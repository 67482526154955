import { styled } from "@mui/material/styles";
import ReactPlayer from "react-player";

import { Grid } from "@mui/material";
import { theme } from "styles/themes";
import { mq } from "styles";

export const HeroImageContainer = styled("div")(() => ({
  background: theme.colors.white,
  width: "100%",
  backgroundImage: "url(/img/setterJoin/join_PowurLogoOrangeBackground.png)",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom 10px top 40px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}));

export const MaxWidthContainer = styled("div")(() => ({
  width: "100%",
  maxWidth: "1440px",
  display: "flex",
  justifyContent: "center",
}));

export const TopRow = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  [mq.phoneOnly]: {
    padding: "0px",
  },
  [mq.tabletUp]: {
    padding: "34px 44px 58px",
  },
  [mq.desktopUp]: {
    padding: "120px 50px 92px 82px",
  },
}));

export const TextWrapper = styled(Grid)(() => ({
  [mq.phoneOnly]: {
    padding: "8px 30px 40px",
  },
  [mq.tabletUp]: {
    padding: "0px",
  },
}));

export const HeaderText = styled(Grid)(() => ({
  fontFamily: "Sora",
  fontWeight: 600,
  textTransform: "capitalize",
  [mq.phoneOnly]: {
    fontSize: "40px",
  },
  [mq.tabletUp]: {
    fontSize: "55px",
  },
  lineHeight: "108%",
}));

export const SubheaderText = styled(Grid)(() => ({
  fontSize: "24px",
  lineHeight: "30px",
  fontWeight: 400,
}));

export const VideoContainer = styled("div")(() => ({
  position: "relative",
  paddingTop: "56.25%",
}));

export const PlayOverlay = styled("div")(() => ({
  position: "absolute",
  cursor: "pointer",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

export const Video = styled(ReactPlayer)(() => ({
  position: "absolute",
  top: "0",
  left: "0",
  "& > div": {
    [mq.tabletUp]: {
      borderRadius: "12px",
    },
  },
}));

export const GradientDivider = styled("div")(() => ({
  width: "100%",
  height: "2px",
  background: `radial-gradient(circle at center, ${theme.colors["tango-orange"]} 0, ${theme.colors.white} 90%)`,
}));

export const StatsContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  padding: "72px 80px 32px",
  [mq.phoneOnly]: {
    padding: "72px 32px 32x",
  },
  [mq.tabletUp]: {
    padding: "52px 0px 12px",
  },
  [mq.desktopUp]: {
    padding: "72px 80px 32px",
  },
}));

export const Stat = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  rowGap: "16px",
  padding: "40px 0px",
}));

export const StatLabel = styled("div")(() => ({
  fontFamily: "Sora",
  color: theme.colors["tango-orange"],
  fontWeight: 600,
  [mq.phoneOnly]: {
    fontSize: "40px",
  },
  [mq.tabletUp]: {
    fontSize: "28px",
  },
  [mq.desktopUp]: {
    fontSize: "40px",
  },
  lineHeight: "108%",
}));

export const StatText = styled("div")(() => ({
  fontWeight: 400,
  textTransform: "capitalize",
  [mq.phoneOnly]: {
    fontSize: "16px",
    lineHeight: "22px",
  },
  [mq.tabletUp]: {
    fontSize: "12px",
    lineHeight: "16px",
  },
  [mq.desktopUp]: {
    fontSize: "16px",
    lineHeight: "22px",
  },
}));
