import { put, all, select } from "redux-saga/effects";
import selectors from "rdx/modules/tickets/selectors";
import rbacSelectors from "rdx/modules/rbac/selectors";
import SessionClient from "util/SessionClient";
import actions from "rdx/actions";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";
import { projectBlockersApi } from "../../projectBlockers/endpoints";
import { PROJECT_BLOCKER_TAGS } from "../../projectBlockers/tags";

function* resolveIssue(action) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/messages/${id}/resolve`);

  if (success && data) {
    const message = keysToCamelCase(data.body);

    // Update Project Notice List and Inbox List on resolve
    const currentPNList = yield select(selectors.getProjectMessageList);
    const currentIBList = yield select(selectors.getInboxList);
    const newPNList = currentPNList.map((n) => (n.id === id ? { ...message, counters: n.counters } : n));
    const newIBList = currentIBList.map((n) => (n.id === id ? { ...message, counters: n.counters } : n));

    // Update issue count in rbac rdx module if user is assignee
    const session = new SessionClient();
    const currentInboxCount = yield select(rbacSelectors.getInboxCount);
    const newInboxCount =
      message.assignee && message.assignee?.id === session.user.props.id
        ? Math.max(0, currentInboxCount - 1)
        : currentInboxCount;

    yield all([
      put(actions.setProjectMessageList({ list: newPNList })),
      put(projectBlockersApi.util.invalidateTags([PROJECT_BLOCKER_TAGS.PROJECT_BLOCKERS])),
      put(actions.setInboxList({ list: newIBList })),
      put(actions.setSelectedMessageDetails(message)),
      put(actions.updateInboxCount(newInboxCount)),
      put(
        actions.newSuccessEvent({
          message: messages.RESOLVE_PROJECT_MESSAGE_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_RESOLVING_PROJECT_MESSAGE,
    });
  }
  return null;
}

export default resolveIssue;
