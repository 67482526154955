import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  smsList: {
    list: [],
    paging: {
      page_size: 50,
      item_count: 0,
      page_number: 1,
    },
  },
  // smsListFilters: {
  //   filters: {},
  //   sorts: [],
  // },
  smsDetails: {},
  languageOptions: {},
  recipientOptions: [],
};

export default {
  sms: createReducer(initialState, {
    [types.SET_SMS_LIST](state, action) {
      return {
        ...state,
        smsList: {
          ...state.smsList,
          ...action.payload,
        },
      };
    },
    [types.SET_SMS_LANGUAGES](state, action) {
      return {
        ...state,
        languageOptions: action.payload,
      };
    },
    [types.SET_SMS_RECIPIENTS](state, action) {
      return {
        ...state,
        recipientOptions: [...action.payload],
      };
    },
    // [types.SET_SMS_LIST_FILTERS](state, action) {
    //   return {
    //     ...state,
    //     smsListFilters: {
    //       ...state.smsListFilters,
    //       ...action.payload,
    //     },
    //   };
    // },

    [types.SET_SMS_DETAILS](state, action) {
      return {
        ...state,
        smsDetails: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
