/* GUARDS */

/* When we need to know specifically what type we have, we can use guards.
The best way to do it is to check the presence of a member. in isUser we are checking if object has fullName 
and if it does then predicate <data is UserLookupT> equal true 
URL : https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates */

import { GenericLookupT, LocationLookUpT, TeamLookupT, UserLookupT } from "./types";

export function isUser(data: Record<string, unknown>): data is UserLookupT {
  return data?.fullName !== undefined;
}

export function isLocation(data: Record<string, unknown>): data is LocationLookUpT {
  return data?.city !== undefined;
}

export function isTeam(data: Record<string, unknown>): data is TeamLookupT {
  return data?.shortName !== undefined;
}

export function isGeneric(data: Record<string, unknown>): data is GenericLookupT {
  return data?.name !== undefined;
}
