import makeRequest from "rdx/utils/makeRequest";
import { all, put } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* deleteRoofingS3Asset(action) {
  const { assetId, proposalId } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/roofing_assets/${assetId}`);

  if (success && data) {
    yield put(actions.getRoofingS3Assets({ proposalId }));
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.SUCCESS_DELETE_ROOFING_ASSET,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_ROOFING_PROPOSAL,
    });
  }

  return null;
}

export default deleteRoofingS3Asset;
