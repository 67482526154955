import React, { useState } from "react";

import { Grid } from "@mui/material";

import CloseButton from "components/Buttons/CloseButton";
import GradientButton from "components/Buttons/GradientButton";
import { AlertModal } from "components/Modals/AlertModal/AlertModal";

import { StyledFilterSetModalHeaderText, StyledNameField } from "./styles";

type CreateFilterSetModalPropsT = {
  isOpen: boolean;
  toggleModal: () => void;
  handleCreate: (name: string) => void;
  handleDelete: (id: number) => void;
  type: "create" | "delete";
  id: number | null;
};

const FilterSetModal = ({
  isOpen,
  toggleModal,
  handleCreate,
  handleDelete,
  id,
  type = "create",
}: CreateFilterSetModalPropsT) => {
  const [name, setName] = useState("");

  return (
    <AlertModal
      visible={isOpen}
      hideHeader
      footer={
        <>
          <Grid container justifyContent="center">
            <div style={{ marginRight: "10px" }}>
              <CloseButton buttonTypeStyle="gradient" action={toggleModal} />
            </div>

            {type === "create" && (
              <GradientButton
                style={{ width: "177px" }}
                onClick={() => {
                  if (handleCreate) {
                    handleCreate(name);
                    toggleModal();
                    setName("");
                  }
                }}
              >
                Submit
              </GradientButton>
            )}
            {type === "delete" && (
              <GradientButton
                onClick={() => {
                  if (id) handleDelete(id);
                }}
              >
                Yes
              </GradientButton>
            )}
          </Grid>
        </>
      }
    >
      <Grid container p={2}>
        <Grid item xs={12} textAlign="left" pb={2}>
          <StyledFilterSetModalHeaderText>
            {type === "create" && <> Save Filter Set</>}
            {type === "delete" && <>Delete Filter Set?</>}
          </StyledFilterSetModalHeaderText>
        </Grid>
        <Grid xs={12} item>
          {type === "create" && (
            <StyledNameField
              fullWidth
              name="name"
              style={{ height: "32px" }}
              placeholder="e.g: This Week’s Leads"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          )}
          {type === "delete" && <>Are you sure you want to delete this filter set?</>}
        </Grid>
      </Grid>
    </AlertModal>
  );
};

export default FilterSetModal;
