import { put, select } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import selectors from "rdx/modules/users/selectors";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../../messages";

function* deleteRole(action) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/roles/${id}`, {});
  if (success && data) {
    const roles = yield select(selectors.getRoles);
    const newRoles = _.clone(roles);

    const rolesList = newRoles.filter((role) => role.id !== id);

    const permissionsList = data.body.permissions.map((permission) => keysToCamelCase(permission));

    yield put(actions.setRolesList({ roles: rolesList, permissions: permissionsList }));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_ROLE,
    });
  }

  return null;
}

export default deleteRole;
