import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* getUserProjectsReport(action) {
  const { reportId } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/reports/${reportId}`);
  if (success && data) {
    yield put(actions.setUserProjectsReport(data.body));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_USER_PROJECTS_REPORT,
    });
  }
  return null;
}

export default getUserProjectsReport;
