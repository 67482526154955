export default {
  ERROR_GETTING_ROOFING_PROPOSALS: "There was an error retrieving Roofing Proposals",
  OPEN_ROOFING_REVISION: "New Roofing Revision",
  OPEN_ROOFING_REQUEST: "New Roofing Proposal",
  CLOSE_ROOFING_DRAWER: "Close Roofing Drawer",
  GET_ROOFING_USERS_AUTOCOMPLETE_ERROR: "There was an error retrieving Roofing Proposals",
  UPDATE_ROOFING_PROPOSAL: "A roofing proposal was updated successfully",
  ERROR_CREATING_ROOFING_PROPOSAL: "There was an error creating a roofing proposal",
  ERROR_UPDATING_ROOFING_PROPOSAL: "There was an error updating a roofing proposal",
  SUCCESS_UPDATING_ROOFING_PROPOSAL: "Roofing proposal updated",
  SUCCESS_UPLOAD_ROOFING_ASSET: "Roofing asset uploaded",
  SUCCESS_DELETE_ROOFING_ASSET: "Roofing asset deleted",
  ERROR_GETTING_ROOFING_PROPOSAL: "There was an error getting a roofing proposal",
  ERROR_GETTING_ROOFING_CSV: "There was an error getting proposals CSV",
  ERROR_GETTING_ROOFING_ASSETS: "There was an error getting roofing assets",
};
