import styled from "@emotion/styled";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import { cvar } from "styles";

export const LabelGroupWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const StyledHelperText = styled(FormHelperText)<{ error?: boolean }>(() => ({
  lineHeight: "10px",
  marginTop: "-10px",
  color: `${({ error }) => (error ? "red" : "black")}`,
}));

export const StyledFormLabel = styled("div")(({ marginBottom = "8px" }: { marginBottom?: string }) => ({
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "9px",
  marginBottom,
  color: cvar("nepal-blue"),
  textTransform: "uppercase",
}));

export const StyledSwitchWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

export const StyledSwitch = styled(Switch)(() => ({
  "& .MuiSwitch-switchBase": {
    padding: "0",
    margin: "2px",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(7px)",
      color: cvar("white"),
      "& + .MuiSwitch-track": {
        backgroundColor: cvar("blue-ribbon"),
        opacity: "1",
        border: "0",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: "0.5",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: `6px solid ${cvar("white")}`,
      boxShadow: "none",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: cvar("botticelli-blue"),
      boxShadow: "none",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: cvar("graham-grey"),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: "11px",
    height: "11px",
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: "13px",
    backgroundColor: cvar("graham-grey"),
    opacity: 1,
  },
}));

export const StyledSwitchText = styled("div")(() => ({
  fontFamily: "Barlow",
  fontWeight: "500",
  lineHeight: "14px",
  color: cvar("arrow-blue"),
  marginLeft: "10px",
}));
