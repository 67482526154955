import React, { CSSProperties } from "react";

import { TextareaAutosizeProps } from "@mui/base/TextareaAutosize";

import * as S from "./styles";

type HelperTextProps = {
  children?: string | undefined;
  error?: boolean;
  sx?: CSSProperties;
} & TextareaAutosizeProps;

const HelperText = ({ children, error, sx }: HelperTextProps) => {
  return (
    <S.HelperText sx={sx} error={error}>
      {children}
    </S.HelperText>
  );
};

export default HelperText;
