import React from "react";
import { GoogleIcon, FacebookIcon } from "@icons";
import { Button } from "antd";

import styles from "./styles.module.less";

type LoginWithIconButtonT = {
  provider: "facebook" | "google" | "";
};

const LoginWithIconButton = ({ provider }: LoginWithIconButtonT) => {
  return (
    <Button className={styles.loginButton}>
      <div className={styles.icon}>{provider === "google" ? <GoogleIcon /> : <FacebookIcon />}</div>
      <span className={styles.buttonText}>Login with {provider}</span>
    </Button>
  );
};

export default LoginWithIconButton;
