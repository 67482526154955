import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/compensation/messages";
import { camelizeKeys } from "lib/helpers";

function* getPreferredPartnerCsv(action) {
  const params = {
    ...action.payload,
  };
  const { success, data, error } = yield* makeRequest.get("/enterprise_labor_partners/csv", params);
  if (success && data) {
    yield put(actions.setPreferredPartnerCsv(camelizeKeys(data.body.csv)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PREFERRED_PARTNER_CSV,
    });
  }

  return null;
}

export default getPreferredPartnerCsv;
