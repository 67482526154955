import types from "rdx/modules/salesTeams/types";
import createAction from "rdx/utils/createAction";

export default {
  getTeams: (payload) => createAction(types.GET_TEAMS, payload),
  setTeams: (payload) => createAction(types.SET_TEAMS, payload),
  getSellers: (payload) => createAction(types.GET_SELLERS, payload),
  setSellers: (payload) => createAction(types.SET_SELLERS, payload),
  getAvailableManagers: (payload) => createAction(types.GET_AVAILABLE_MANAGERS, payload),
  setAvailableManagers: (payload) => createAction(types.SET_AVAILABLE_MANAGERS, payload),
  getAvailableSupport: (payload) => createAction(types.GET_AVAILABLE_SUPPORT, payload),
  setAvailableSupport: (payload) => createAction(types.SET_AVAILABLE_SUPPORT, payload),
  createSalesTeam: (payload) => createAction(types.POST_SALES_TEAMS, payload),
  createTeamAssignment: (payload) => createAction(types.POST_TEAM_ASSIGNMENT, payload),
  updateSalesTeam: (payload) => createAction(types.UPDATE_SALES_TEAM, payload),
  deleteSalesTeam: (payload) => createAction(types.DELETE_SALES_TEAM, payload),
  unassignUser: (payload) => createAction(types.UNASSIGN_USER, payload),

  // INSERTION_PT (for script, do not remove)
};
