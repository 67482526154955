import z from "zod";
import { UserLiteSchema } from "../../../../users/schemas/users";

export const TeamSchema = z.object({
  id: z.number(),
  label: z.string(),
  shortName: z.string(),
  phone: z.string().nullable(),
  email: z.string(),
  color: z.string(),
  sourceUid: z.number().nullable(),
  assignable: z.boolean(),
  customerCare: z.boolean(),
  leadUser: UserLiteSchema,
  users: z.array(UserLiteSchema),
});
