import { put, select, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import _ from "lodash";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { keysToCamelCase } from "lib/helpers";
import messages from "../../messages";

function* createMilestone(action) {
  const { phaseId, title, rank, required, prerequisiteId, dataGroups, editableBy, oldField, confirm } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/phases/${phaseId}/milestones`, {
    title,
    confirm,
    rank,
    required,
    prerequisite_id: prerequisiteId,
    editable_by: editableBy,
    data_groups: dataGroups,
    old_field: oldField,
  });
  if (success && data) {
    if (data.warning) {
      yield put(
        actions.newMessageEvent({
          message: messages.WARNING_REASSIGN_DATA_GROUP_TO_NEW_MILESTONE_ON_CREATE,
          warning: data.warning,
          payload: action.payload,
        }),
      );
    } else {
      const ms = data.body.milestones.map((m) => keysToCamelCase(m));
      const workflow = yield select(selectors.getWorkflowDetails);
      const newWorkflow = _.clone(workflow);

      const newPhases = newWorkflow.phases.map((p) => (p.id === phaseId ? { ...p, milestones: ms } : p));

      yield all([
        yield put(
          actions.newSuccessEvent({
            message: messages.MILESTONE_CREATE_SUCCESS,
          }),
        ),
        // also add to all milestones?
        put(actions.setWorkflowDetails({ ...newWorkflow, phases: newPhases })),
      ]);
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_MILESTONE,
    });
  }
  return null;
}

export default createMilestone;
