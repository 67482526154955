import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const StarOutlineIcon = ({ height = "24", width = "24", fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1382_715"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="23"
        height="24"
      >
        <path d="M0.84613 0H24.8461V24H0.84613V0Z" fill={fill} />
      </mask>
      <g mask="url(#mask0_1382_715)">
        <path
          d="M10.8158 3.75886C11.0238 3.33754 11.4529 3.07081 11.923 3.07081C12.3932 3.07081 12.8222 3.33754 13.0302 3.75886C13.7013 5.11858 14.5064 6.74998 14.9265 7.60131C15.1064 7.96583 15.454 8.21836 15.8562 8.277C16.7959 8.41343 18.5964 8.67488 20.0966 8.89312C20.5617 8.96072 20.9482 9.28637 21.0933 9.73335C21.2387 10.1803 21.1174 10.6711 20.7809 10.9989C19.6953 12.0574 18.3923 13.3273 17.7126 13.99C17.4215 14.2737 17.2888 14.6824 17.3573 15.0828C17.5179 16.0187 17.8256 17.8118 18.0818 19.3061C18.1611 19.7694 17.971 20.2374 17.5907 20.5136C17.2104 20.7899 16.7063 20.8264 16.2902 20.6078C14.9484 19.9022 13.338 19.0557 12.4975 18.6137C12.1379 18.4248 11.7082 18.4248 11.3486 18.6137C10.5081 19.0557 8.89765 19.9022 7.55586 20.6078C7.13975 20.8264 6.63566 20.7899 6.25539 20.5136C5.87509 20.2374 5.68496 19.7694 5.76425 19.3061C6.02049 17.8118 6.32821 16.0187 6.48873 15.0828C6.55725 14.6824 6.42454 14.2737 6.13344 13.99C5.45373 13.3273 4.15082 12.0574 3.06519 10.9989C2.7287 10.6711 2.6074 10.1803 2.7528 9.73335C2.89788 9.28637 3.28435 8.96072 3.74953 8.89312C5.2497 8.67488 7.05024 8.41343 7.98986 8.277C8.39207 8.21836 8.73962 7.96583 8.9196 7.60131C9.3397 6.74998 10.1447 5.11858 10.8158 3.75886Z"
          stroke={fill}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
export default StarOutlineIcon;
