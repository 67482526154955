import React from "react";
import PropTypes from "prop-types";

const CCIcon = (props) => {
  const { accentColor, mainColor, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.3359 11C22.3359 17.0751 17.4111 22 11.3359 22C5.26081 22 0.335938 17.0751 0.335938 11C0.335938 4.92487 5.26081 0 11.3359 0C17.4111 0 22.3359 4.92487 22.3359 11Z"
        fill={mainColor}
      />
      <path
        d="M7.39642 8.38384C6.77794 8.38384 6.29907 8.61709 5.95978 9.0836C5.6205 9.54658 5.45086 10.1933 5.45086 11.0239C5.45086 12.7521 6.09938 13.6162 7.39642 13.6162C7.94069 13.6162 8.59981 13.4801 9.37379 13.208V14.5863C8.73764 14.8514 8.02727 14.9839 7.24269 14.9839C6.11529 14.9839 5.25295 14.6428 4.65568 13.9608C4.0584 13.2751 3.75977 12.2926 3.75977 11.0133C3.75977 10.2075 3.90643 9.5024 4.19977 8.89806C4.49311 8.29018 4.91367 7.82544 5.46147 7.50383C6.0128 7.17868 6.65778 7.01611 7.39642 7.01611C8.1492 7.01611 8.90551 7.19812 9.66536 7.56214L9.13523 8.89806C8.84543 8.76022 8.55386 8.64006 8.26053 8.53757C7.96719 8.43508 7.67916 8.38384 7.39642 8.38384Z"
        fill={accentColor}
      />
      <path
        d="M12.3974 8.38384C11.7789 8.38384 11.3 8.61709 10.9608 9.0836C10.6215 9.54658 10.4518 10.1933 10.4518 11.0239C10.4518 12.7521 11.1004 13.6162 12.3974 13.6162C12.9417 13.6162 13.6008 13.4801 14.3748 13.208V14.5863C13.7386 14.8514 13.0282 14.9839 12.2437 14.9839C11.1163 14.9839 10.2539 14.6428 9.65665 13.9608C9.05938 13.2751 8.76074 12.2926 8.76074 11.0133C8.76074 10.2075 8.90741 9.5024 9.20075 8.89806C9.49408 8.29018 9.91465 7.82544 10.4624 7.50383C11.0138 7.17868 11.6588 7.01611 12.3974 7.01611C13.1502 7.01611 13.9065 7.19812 14.6663 7.56214L14.1362 8.89806C13.8464 8.76022 13.5548 8.64006 13.2615 8.53757C12.9682 8.43508 12.6801 8.38384 12.3974 8.38384Z"
        fill={accentColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5657 9.52692C11.3544 9.81529 11.2018 10.2843 11.2018 11.0239C11.2018 11.8158 11.3542 12.2729 11.5381 12.5179C11.6916 12.7224 11.9317 12.8662 12.3974 12.8662C12.8255 12.8662 13.3956 12.7573 14.126 12.5004L15.1248 12.1493V15.0863L14.6632 15.2786C13.9239 15.5867 13.1136 15.7339 12.2437 15.7339C10.957 15.7339 9.86573 15.338 9.0924 14.4548L9.09114 14.4534C8.33433 13.5846 8.01074 12.4005 8.01074 11.0133C8.01074 10.1167 8.17412 9.29593 8.52565 8.57134C8.87923 7.83897 9.39846 7.25895 10.0821 6.85743C10.7655 6.45457 11.546 6.26611 12.3974 6.26611C13.2751 6.26611 14.1415 6.4791 14.9904 6.88575L15.6216 7.18817L14.5378 9.91954L13.8141 9.57536C13.5478 9.44872 13.2812 9.33891 13.0141 9.2456C12.7925 9.16815 12.588 9.13384 12.3974 9.13384C11.9986 9.13384 11.754 9.2681 11.5673 9.52473L11.5657 9.52692ZM9.20075 8.89806C8.90741 9.5024 8.76074 10.2075 8.76074 11.0133C8.76074 12.2926 9.05938 13.2751 9.65665 13.9608C10.2539 14.6428 11.1163 14.9839 12.2437 14.9839C13.0282 14.9839 13.7386 14.8514 14.3748 14.5863V13.208C14.1115 13.3005 13.8615 13.3774 13.6248 13.4385C13.1656 13.5569 12.7565 13.6162 12.3974 13.6162C11.1004 13.6162 10.4518 12.7521 10.4518 11.0239C10.4518 10.1933 10.6215 9.54658 10.9608 9.0836C11.3 8.61709 11.7789 8.38384 12.3974 8.38384C12.6801 8.38384 12.9682 8.43508 13.2615 8.53757C13.323 8.55907 13.3845 8.58134 13.4458 8.6044C13.6771 8.69125 13.9072 8.78913 14.1362 8.89806L14.6663 7.56214C13.9065 7.19812 13.1502 7.01611 12.3974 7.01611C11.6588 7.01611 11.0138 7.17869 10.4624 7.50383C9.91465 7.82544 9.49408 8.29018 9.20075 8.89806Z"
        fill={mainColor}
      />
      <path
        d="M14.7066 9.12807L14.666 9.06427C14.7086 8.88831 14.7782 8.66789 14.8748 8.403C14.9734 8.13617 15.074 7.89351 15.1765 7.67503H15.8116C15.684 8.19128 15.5864 8.67563 15.5187 9.12807H14.7066Z"
        fill={accentColor}
      />
      <path
        d="M17.1044 11.834C16.7235 11.834 16.4238 11.6861 16.2054 11.3903C15.9888 11.0945 15.8805 10.6846 15.8805 10.1606C15.8805 9.62886 15.9907 9.21508 16.2112 8.91925C16.4335 8.62149 16.739 8.47261 17.1276 8.47261C17.5356 8.47261 17.8469 8.63115 18.0615 8.94825H18.0905C18.0461 8.70656 18.0238 8.49097 18.0238 8.30149V7.26318H18.9113V11.776H18.2327L18.0615 11.3555H18.0238C17.8228 11.6745 17.5163 11.834 17.1044 11.834ZM17.4148 11.1293C17.641 11.1293 17.8063 11.0635 17.9107 10.9321C18.0171 10.8006 18.0751 10.5773 18.0847 10.2621V10.1664C18.0847 9.81834 18.0306 9.56892 17.9223 9.4181C17.816 9.26729 17.642 9.19188 17.4003 9.19188C17.2031 9.19188 17.0493 9.27599 16.9391 9.4442C16.8309 9.61049 16.7767 9.85315 16.7767 10.1722C16.7767 10.4912 16.8318 10.731 16.942 10.8914C17.0522 11.05 17.2098 11.1293 17.4148 11.1293Z"
        fill={accentColor}
      />
    </svg>
  );
};

CCIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  mainColor: PropTypes.string,
  accentColor: PropTypes.string,
};

CCIcon.defaultProps = {
  accentColor: "white",
  mainColor: "var(--botticelli-blue)",
  height: "22",
  width: "23",
};

export default CCIcon;
