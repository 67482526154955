import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/enterpriseProjects/selectors";
import projectSelectors from "rdx/modules/projects/selectors";
import messages from "rdx/modules/enterpriseProjects/messages";

function* rescindLeadOffer(action) {
  const { id } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/project_offers/${id}/rescind`, {});

  if (success && data) {
    const enterpriseProjects = yield select(selectors.getEnterpriseProjectsList);
    const enterpriseProjectDetail = yield select(projectSelectors.getProjectDetails);

    const projects = enterpriseProjects.projects.map((ep) => {
      const proj = ep;
      if (proj.offer?.id === id) {
        proj.offer = {};
      }
      return proj;
    });

    yield all([
      yield put(
        actions.newSuccessEvent({
          message: messages.ENTERPRISE_RESCIND_OFFER_SUCCESS,
        }),
      ),
      yield put(
        actions.setProjectDetails({
          ...enterpriseProjectDetail,
          providerLead: {
            ...enterpriseProjectDetail.providerLead,
            offer: null,
          },
        }),
      ),
      yield put(actions.setEnterpriseProjectsList({ ...enterpriseProjects, projects })),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_SUBMITTING_ENTERPRISE_RESCIND_OFFER,
    });
  }

  return null;
}

export default rescindLeadOffer;
