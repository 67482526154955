// import content from "components/Icons/IconLibrary";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";

const content = l(() => import("components/Icons/IconLibrary"));

const { PLATFORM_CONFIGURATION } = permissionTypes;

export default {
  key: "icon-library",
  title: "Icon Library",
  path: "/icon-library",
  basePath: "icon-library",
  requiredParams: [],
  optionalParams: [],
  linkTarget: "/icon-library",
  linkTitle: null,
  linkCategory: null,
  icon: null,
  permission: PLATFORM_CONFIGURATION,
  tabs: null,
  content,
  sidebar: null,
};
