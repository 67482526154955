import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/buildPartnerApplications/messages";
import { camelizeKeys, keysToSnakeCase } from "lib/helpers";

function* createBuildPartnerApplications(action) {
  const { params } = action.payload;
  const out = {
    ...keysToSnakeCase(params),
    background_checks: params.backgroundChecks?.map((b) => keysToSnakeCase(b)),
    states_operated_in: params.statesOperatedIn,
  };
  const { success, data, error } = yield* makeRequest.post("/labor_partner_applications", out);

  if (success && data) {
    yield all([
      put(
        actions.setBuildPartnerApplicationDetail({
          ...camelizeKeys(data.body),
          serviceAreas: data.body.service_areas,
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.CREATE_BUILD_PARTNER_APPLICATIONS_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_BUILD_PARTNER_APPLICATIONS,
    });
  }

  return null;
}

export default createBuildPartnerApplications;
