import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const EnvelopeCrmGradientIcon = ({
  width = "15",
  height = "13",
  startColor = cvar("picton-blue"),
  stopColor = cvar("blue-ribbon"),
}: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 15 13">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.84131 6.48044C8.8441 6.47866 8.84677 6.47676 8.85045 6.47498L14.3812 3.17322V2.68985C14.3812 1.71851 13.5947 0.932861 12.6242 0.932861H1.75699C0.78744 0.932861 0 1.71939 0 2.68985V3.17335L7.18999 7.46738L8.84131 6.48044ZM0.000435829 5.29482V9.8948L3.43352 7.34495L0.000435829 5.29482ZM5.12622 8.3546L6.72305 9.30955C6.86694 9.39515 7.02799 9.43795 7.19003 9.43795C7.35208 9.43795 7.51225 9.39515 7.65702 9.30955L9.25297 8.35639L14.1635 12.0044C13.8675 12.5542 13.2931 12.9329 12.624 12.9329H1.75579C1.08761 12.9329 0.51229 12.5542 0.215477 12.0034L5.12622 8.3546ZM14.38 9.89675V5.29482L10.946 7.34498L14.38 9.89675Z"
        fill="url(#paint0_linear_681_94532)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_681_94532"
          x1="2.52302"
          y1="-4.06717"
          x2="15.1653"
          y2="-2.55378"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EnvelopeCrmGradientIcon;
