import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const ArchiveIcon = (props) => {
  const { fill, height, width, containerStyles, active, hovered } = props;

  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      style={{ paddingRight: "1px" }}
      {...containerStyles}
    >
      <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.79786 4.68028C6.03349 4.68028 7.03516 3.67366 7.03516 2.43194C7.03516 1.19021 6.03349 0.183594 4.79786 0.183594C3.56222 0.183594 2.56055 1.19021 2.56055 2.43194C2.56055 3.67366 3.56222 4.68028 4.79786 4.68028Z"
          fill={fill}
        />
        <path
          d="M10.3078 4.68071C11.5436 4.68071 12.5454 3.67399 12.5454 2.43215C12.5454 1.19031 11.5436 0.183594 10.3078 0.183594C9.07209 0.183594 8.07031 1.19031 8.07031 2.43215C8.07031 3.67399 9.07209 4.68071 10.3078 4.68071Z"
          fill={fill}
        />
        <path
          d="M15.8039 4.68071C17.0397 4.68071 18.0415 3.67399 18.0415 2.43215C18.0415 1.19031 17.0397 0.183594 15.8039 0.183594C14.5682 0.183594 13.5664 1.19031 13.5664 2.43215C13.5664 3.67399 14.5682 4.68071 15.8039 4.68071Z"
          fill={fill}
        />
        <path
          d="M13.516 5.52344H7.13561C6.59037 5.52344 6.14844 5.96755 6.14844 6.51504V12.1838C6.14844 12.732 6.59037 13.1754 7.13561 13.1754H7.4393V19.186C7.4393 19.7383 7.88702 20.186 8.4393 20.186H12.0959C12.6482 20.186 13.0959 19.7383 13.0959 19.186V13.1754H13.516C14.0612 13.1754 14.5027 12.7317 14.5027 12.1838V6.51504C14.5025 5.96776 14.0612 5.52344 13.516 5.52344Z"
          fill={fill}
        />
        <path
          d="M4.75672 12.1408V6.47207C4.75672 6.11756 4.8388 5.78372 4.97704 5.48047H1.68205C1.13724 5.48047 0.695312 5.92458 0.695312 6.47207V12.1408C0.695312 12.689 1.13724 13.1325 1.68205 13.1325H1.98596V19.143C1.98596 19.6953 2.43368 20.143 2.98596 20.143H5.04715C5.59944 20.143 6.04715 19.6953 6.04715 19.143V14.2666C5.28138 13.8695 4.75672 13.0655 4.75672 12.1408Z"
          fill={fill}
        />
        <path
          d="M19.047 5.48047H15.6652C15.8035 5.78372 15.8858 6.11778 15.8858 6.47207V12.1408C15.8858 13.1118 15.3071 13.9483 14.4785 14.3226V19.143C14.4785 19.6953 14.9262 20.143 15.4785 20.143H17.6274C18.1797 20.143 18.6274 19.6953 18.6274 19.143V13.1325H19.0468C19.5918 13.1325 20.034 12.6888 20.034 12.1408V6.47207C20.0342 5.9248 19.5918 5.48047 19.047 5.48047Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

ArchiveIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

ArchiveIcon.defaultProps = {
  fill: "var(--royal-peacock-blue)",
  height: "21",
  width: "21",
};

export default ArchiveIcon;
