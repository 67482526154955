import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Rate, Input } from "antd";
import UserAvatar from "components/UserCards/UserAvatar";
import MentorRatingDisplay from "components/Modals/MentorRatingModal/ratingDisplay";
import MentorRating from "models/MentorRating";
import styles from "./MentorRatingModals.module.less";

const { TextArea } = Input;

const RatingModal = (props) => {
  const { ratingInfo, visible, close } = props;

  const [ratingValue, setRatingValue] = useState(0);
  const [notes, setNotes] = useState("");

  const contents = useMemo(() => {
    if (!ratingInfo) {
      return {};
    }

    const TEMPLATES = {
      proposal: {
        title: "Proposal",
        message: "Proposal Process",
      },
      contract: {
        title: "Sale",
        message: "Sale Process",
      },
      install: {
        title: "Install",
        message: "Installation Process",
      },
    };

    return TEMPLATES[ratingInfo.eventType];
  }, [ratingInfo]);

  if (!ratingInfo) {
    console.warn(
      "Mentor rating modal passed invalid or undefined rating info, ensure that modal is not rendered or check rating info.",
    );
    return null;
  }

  const handleSubmit = () => {
    const rating = new MentorRating(ratingInfo);
    const payload = { rating: ratingValue };
    if (notes && notes.length >= 2) {
      payload.notes = notes;
    }
    rating.updateRating(payload);
    close();
  };

  const handleDefer = () => {
    new MentorRating(ratingInfo).deferRating("next_rating_delay");
    close();
  };

  const { mentor, project } = ratingInfo;
  const { lead } = project;

  return (
    <Modal
      data-test-id="ratingModal-mentor"
      className={styles.ratingModal}
      open={visible}
      centered
      destroyOnClose
      footer={null}
      closable={false}
      maskClosable={false}
      width={Math.min(window.innerWidth, 500)}
      zIndex={10000}
      keyboard={false}
      bodyStyle={{
        borderRadius: "12px",
        backgroundColor: "transparent",
        padding: 0,
      }}
      maskStyle={{ backgroundColor: "var(--mask-blue-a75)" }}
    >
      <div className={styles.content}>
        <div className={styles.header}>Mentor Rating - {contents?.title}</div>
        <div className={styles.innerContent}>
          <div className={styles.ratingMessage}>
            Please rate your mentor on how they performed during the {contents?.message}. Any additional notes would be
            helpful in understanding your rating. Thank you.
          </div>
          <div className={styles.mentorDisplay}>
            <div className={styles.userContainer}>
              <UserAvatar user={mentor} size={48} />
              <div className={styles.user}>
                <div className={styles.title}>Your Mentor</div>
                <div className={styles.userName}>{mentor.fullName}</div>
                <div className={styles.rating}>
                  <MentorRatingDisplay
                    rating={mentor?.mentorRatings?.meanOfAvgs}
                    count={mentor?.mentorRatings?.totalCount}
                  />
                </div>
              </div>
            </div>
            <div className={styles.user}>
              <div className={styles.title}>On Project</div>
              <div className={styles.userName}>{lead.fullName}</div>
              <div className={styles.city}>
                {project.city}, {project.state}
              </div>
            </div>
          </div>
          <div className={styles.ratingSelection}>
            <div className={styles.heading}>Select Your Rating</div>
            <div className={styles.ratingContainer}>
              <Rate
                className={styles.rateSelect}
                value={ratingValue}
                allowClear={false}
                onChange={(value) => setRatingValue(value)}
              />
            </div>
          </div>
          <div className={styles.additionalInfo}>
            <div className={styles.label}>
              <span style={{ fontWeight: 600 }}>Notes</span> (Optional) 240 Max Characters
            </div>
            <TextArea
              className={styles.input}
              maxLength={240}
              value={notes}
              placeholder="Write your note here..."
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
          <div className={styles.ratingsButtonsContainer}>
            <div className={styles.buttonsRow}>
              <Button className={styles.cancelButton} type="info" onClick={handleDefer}>
                Remind Me Later
              </Button>
              <Button
                className={styles.submitButton}
                type="primary"
                disabled={ratingValue === 0}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
          <div className={styles.disclaimer}>
            During the mentorship process we will occasionally ask you to rate how your mentor is performing on
            different stages of a project. We appreciate your feedback.
          </div>
        </div>
      </div>
    </Modal>
  );
};

RatingModal.propTypes = {
  ratingInfo: MentorRating.types().isRequired,
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default RatingModal;
