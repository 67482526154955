import React from "react";
import PropTypes from "prop-types";

const DownloadIcon = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.389 7.83301C10.227 7.83325 10.0717 7.89775 9.95716 8.01225C9.84266 8.12683 9.77816 8.2821 9.77792 8.44414V9.91082C9.77767 10.008 9.73896 10.1011 9.67021 10.1698C9.60154 10.2385 9.5084 10.2772 9.41126 10.2775H1.58886C1.49171 10.2772 1.39857 10.2385 1.32991 10.1698C1.26116 10.1011 1.22245 10.008 1.2222 9.91082V8.44414C1.2222 8.22578 1.10573 8.02403 0.916671 7.91484C0.727608 7.80574 0.494667 7.80574 0.305529 7.91484C0.116466 8.02402 0 8.22578 0 8.44414V9.91082C0.000491084 10.332 0.16811 10.7359 0.465941 11.0337C0.763781 11.3316 1.16768 11.4992 1.58887 11.4997H9.41127C9.83245 11.4992 10.2364 11.3316 10.5342 11.0337C10.832 10.7359 10.9997 10.332 11.0001 9.91082V8.44414C10.9999 8.28209 10.9354 8.12682 10.8209 8.01225C10.7063 7.89775 10.551 7.83325 10.389 7.83301H10.389Z"
        fill={fill}
      />
      <path
        d="M5.49913 0.500002C5.33716 0.500248 5.18181 0.564741 5.06732 0.679244C4.95274 0.793829 4.88824 0.949091 4.888 1.11114V6.65076L2.95693 5.04176C2.83237 4.93823 2.67179 4.8883 2.51046 4.90295C2.34922 4.9176 2.20026 4.99568 2.09648 5.12C1.99286 5.24457 1.94294 5.40506 1.95759 5.56639C1.97232 5.72771 2.05032 5.87659 2.17463 5.98045L5.10798 8.42498C5.13884 8.44683 5.17158 8.46582 5.20579 8.4817C5.22813 8.49667 5.25146 8.51002 5.27568 8.52172C5.41809 8.58171 5.57868 8.58171 5.72109 8.52172C5.74466 8.51002 5.76758 8.49676 5.78951 8.48219C5.82372 8.46623 5.85646 8.44724 5.88732 8.42547L8.82067 5.98094C8.94998 5.87332 9.02929 5.71715 9.03977 5.54928C9.05033 5.38133 8.99107 5.21649 8.87616 5.09365C8.76125 4.9708 8.60074 4.90074 8.43247 4.9C8.28957 4.90025 8.15124 4.95042 8.04133 5.04176L6.11026 6.65076V1.11114C6.11002 0.949081 6.04552 0.79381 5.93094 0.679242C5.81644 0.564741 5.66109 0.500245 5.49913 0.5L5.49913 0.500002Z"
        fill={fill}
      />
    </svg>
  );
};

DownloadIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

DownloadIcon.defaultProps = {
  fill: "var(--dark-blue)",
  height: "12",
  width: "11",
};

export default DownloadIcon;
