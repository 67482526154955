// React
import React from "react";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import PropTypes from "prop-types";

import { CaretRightOutlined } from "components/Icons";

import FilterMenuItem from "./FilterMenuItem";

import styles from "./FilterMenu.module.less";

const FilterMenuTabMobile = (props) => {
  const { item, selectedItem, updateFilters, queryParams, setSelectedItem, override } = props;

  const selected = item.type === selectedItem?.type;
  const disabled = item?.disabled;

  const dateSearchItems = ["pay_period", "created_at", "created_at_from"];

  const sortCount = () => {
    if (typeof item.type === "string") {
      if (dateSearchItems.includes(item.type)) {
        return queryParams[item.type] ? 1 : 0;
      }

      return queryParams[item.type]?.split(",").length;
    }

    if (Array.isArray(item.type)) {
      const includesDateItems = dateSearchItems.some((dsi) => item.type.includes(dsi));
      return includesDateItems ? 1 : 0;
    }

    return 0;
  };

  return (
    <div key={item.label}>
      <div
        className={[selected ? styles.selectedTab : styles.tab, disabled ? styles.disabledTab : ""].join(" ")}
        onClick={disabled ? null : () => setSelectedItem(item)}
        onKeyDown={disabled ? null : () => setSelectedItem(item)}
        tabIndex={0}
        role="button"
      >
        <div className={disabled ? styles.disabledText : styles.tabText}>{item.label}</div>
        <div className={styles.countContainer}>
          <span className={disabled ? styles.disabledText : styles.countText}>
            <span className={styles.slashText}>/</span> {sortCount() || 0}
          </span>
          <span className={styles.caretRightContainer}>
            <CaretRightOutlined fill={disabled ? "var(--old-grey)" : "var(--dark-blue)"} />
          </span>
        </div>
      </div>

      {selected && (
        <div className={styles.content}>
          <FilterMenuItem
            item={item}
            override={override}
            selectedItem={selectedItem}
            queryParams={queryParams}
            updateFilters={updateFilters}
          />
        </div>
      )}
    </div>
  );
};

FilterMenuTabMobile.propTypes = {
  selectedItem: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    type: PropTypes.string,
    component: PropTypes.node,
    options: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  }),
  item: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    type: PropTypes.string,
    component: PropTypes.node,
    options: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    disabled: PropTypes.bool,
  }),
  queryParams: PropTypes.objectOf(PropTypes.string),
  setSelectedItem: PropTypes.func,
  updateFilters: PropTypes.func,
  override: PropTypes.func,
};

export default connect(getRdxSelectionMapper({}), getRdxActionMapper(["deleteFilterSet"]))(FilterMenuTabMobile);
