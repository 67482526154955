import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import { isEmpty } from "lodash";
import FormErrorMessage from "components/FormElements/FormErrorMessage";
import { Form, Row, Col, Input, Button, Popconfirm } from "antd";
import User from "models/User";
import styles from "./ChangeEmailSection.module.less";

const ChangeEmailSection = (props) => {
  const { profile, createEmailUpdate, deleteEmailUpdate, resendEmailUpdate } = props;

  const [editing, setEditing] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const requestInProgress = !isEmpty(profile?.emailChangeRequest);
  const [form] = Form.useForm();

  const handleClick = () => {
    if (editing && newEmail) {
      createEmailUpdate({ newEmail });
    } else {
      setEditing(true);
    }
  };

  const handleResend = () => {
    resendEmailUpdate({ userId: profile.id, newEmail });
  };

  const handleCancelRequest = () => {
    deleteEmailUpdate({ userId: profile.id });
    setEditing(false);
  };

  const buttonRow = () => {
    if (requestInProgress) {
      form.setFieldsValue({
        newEmail: profile.emailChangeRequest?.newEmail || null,
      });
      return (
        <>
          <span className={styles.verificationText}>Verification sent to new email</span>
          <div className={styles.inProgressButtons}>
            <Button className={styles.disabledUpdateEmailBtn} onClick={handleResend}>
              Resend Verification
            </Button>
            <Popconfirm
              title="Are you sure you want cancel this request?"
              okText="Confirm"
              placement="topRight"
              onConfirm={handleCancelRequest}
            >
              <Button className={styles.cancelEmailBtn}>Cancel Request</Button>
            </Popconfirm>
          </div>
        </>
      );
    }
    return (
      <Button className={styles.updateEmailBtn} type="primary" block htmlType="submit">
        {editing ? "Send Verification" : "Change Email"}
      </Button>
    );
  };

  return (
    <Row gutter={12} className={styles.changeEmailSectionContainer}>
      <Col span={24}>
        <Row gutter={12}>
          <Col span={24}>
            <div className={styles.emailLabel}>Email</div>
            <Input className={styles.emailInputDisabled} readOnly value={profile?.email} />
          </Col>
        </Row>
        <Form form={form} name="new-email-form" requiredMark={false} colon={false} onFinish={handleClick}>
          {(editing || requestInProgress) && (
            <Row gutter={12}>
              <Col span={24}>
                <span className={styles.emailLabel}>New Email</span>
                <Form.Item
                  name={["newEmail"]}
                  className={styles.formItem}
                  validateTrigger={["onSubmit"]}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: <FormErrorMessage message="Input must be a valid email address." />,
                    },
                  ]}
                >
                  <Input
                    className={requestInProgress ? styles.emailInputProcessing : styles.input}
                    readOnly={requestInProgress}
                    onChange={(e) => setNewEmail(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row className={styles.buttonRow}>{buttonRow()}</Row>
        </Form>
      </Col>
    </Row>
  );
};

ChangeEmailSection.propTypes = {
  profile: User.types(),
  createEmailUpdate: PropTypes.func,
  deleteEmailUpdate: PropTypes.func,
  resendEmailUpdate: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({}),
  getRdxActionMapper(["createEmailUpdate", "deleteEmailUpdate", "resendEmailUpdate"]),
)(ChangeEmailSection);
