import { select, put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { keysToSnakeCase, camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* createDashboardUpdate(action) {
  const { success, data, error } = yield* makeRequest.post("/dashboard_updates", keysToSnakeCase(action.payload));

  if (success && data) {
    const list = yield select(selectors.selectDashboardUpdates);

    const out = {
      ...list,
      updates: [camelizeKeys(data.body), ...list.updates],
    };

    yield all([
      put(actions.setDashboardUpdates(out)),
      put(
        actions.newSuccessEvent({
          message: messages.CREATE_DASHBOARD_UPDATE_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.CREATE_DASHBOARD_UPDATE_ERROR,
    });
  }
  return null;
}

export default createDashboardUpdate;
