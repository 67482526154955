import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "rdx/modules/team/messages";

function* getInviteDetails(action) {
  const { inviteId } = action.payload;
  const params = action.payload;

  if (!inviteId) {
    throw new Error(
      `Specified inviteId ${inviteId} is invalid, check the payload provided with the getInviteDetails action.`,
    );
  }
  const { success, data, error } = yield* makeRequest.get(`/registrations/${inviteId}/invite_details`, params);

  if (success && data) {
    yield put(actions.setInviteDetails(camelizeKeys(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_INVITE_DETAILS,
    });
  }

  return null;
}

export default getInviteDetails;
