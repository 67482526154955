import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styles from "../../Home.module.less";

const Counter = ({ id, initialValue, rate, start, title, bgImgSrc, prefix, suffix }) => {
  const idCount = useRef(1);
  const prevValue = useRef([]);
  const [currentValue, setCurrentValue] = useState(initialValue);

  const displayValue = useMemo(() => {
    const next = Array.from(Math.round(currentValue).toLocaleString()).map((char, idx) => {
      if (prevValue.current[idx] && prevValue.current[idx].value === char) {
        return prevValue.current[idx];
      }
      const elementId = `${id}-${idCount.current}`;
      idCount.current++;
      return { value: char, elementId };
    });
    prevValue.current = next;
    return next;
  }, [id, currentValue]);

  useInterval(() => {
    const elapsed = Math.round((new Date().getTime() - new Date(start).getTime()) / 1000);
    const change = elapsed * rate;
    setCurrentValue(initialValue + change);
  }, 1000);

  return (
    <div className={styles.counterCard} style={{ backgroundImage: `url(${bgImgSrc})` }}>
      <div className={styles.overlay} />
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.topContainer}>
        <div className={styles.counterContainer}>
          {prefix}
          {displayValue.map(({ value, elementId }) => {
            if (value === ",") {
              return (
                <div key={elementId} className={styles.separator}>
                  {value}
                </div>
              );
            }
            return (
              <TransitionGroup
                // TODO: transition classnames are not being applied when
                // TransitionGroup is given a key and the counter no longer
                // animates, may need to rework this.
                // key={elementId}
                className={styles.countBox}
                key={elementId}
              >
                <CSSTransition
                  classNames={{
                    enter: styles.animateEnter,
                    enterActive: styles.animateEnterActive,
                    exit: styles.animateExit,
                    exitActive: styles.animateExitActive,
                  }}
                  key={elementId}
                  id={elementId}
                  timeout={300}
                >
                  <div className={styles.digit}>{value}</div>
                </CSSTransition>
              </TransitionGroup>
            );
          })}
        </div>
        {suffix}
      </div>
    </div>
  );
};

const useInterval = (cb, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = cb;
  }, [cb]);

  useEffect(() => {
    const id = setInterval(() => {
      savedCallback.current();
    }, delay);

    return () => clearInterval(id);
  }, [delay]);
};

Counter.propTypes = {
  id: PropTypes.string,
  initialValue: PropTypes.number.isRequired,
  rate: PropTypes.number.isRequired,
  start: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bgImgSrc: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
};

Counter.defaultProps = {
  id: "counter",
  bgImgSrc: "",
  prefix: <></>,
  suffix: <></>,
};

export default Counter;
