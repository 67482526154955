import { put, call } from "redux-saga/effects";
import actions from "rdx/actions";

function* getRandomAddress() {
  try {
    const response = yield call(fetchData);
    const responseBody = yield response.json();

    if (responseBody) {
      yield put(actions.setRandomAddress(responseBody));
    }
  } catch (error) {
    console.error("Failed to fetch:", error);
    return null;
  }

  return null;
}

function fetchData() {
  return fetch(process.env.REACT_APP_RANDOM_ADDRESS_ENDPOINT);
}

export default getRandomAddress;
