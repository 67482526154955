import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/stateLicenses/types";
import getStateLicenses from "./getStateLicenses";
import deleteStateLicense from "./deleteStateLicense";
import createStateLicense from "./createStateLicense";
// IMPORT_PT (for script, do not remove)

function* watchStateLicensesSagas() {
  yield trackRequests(takeEvery, types.GET_STATE_LICENSES_REQUEST, getStateLicenses);
  yield trackRequests(takeEvery, types.DELETE_STATE_LICENSE_REQUEST, deleteStateLicense);
  yield trackRequests(takeEvery, types.CREATE_STATE_LICENSE_REQUEST, createStateLicense);
  // INSERTION_PT (for script, do not remove)
}

export default watchStateLicensesSagas;
