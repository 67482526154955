import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/settings/messages";
import { camelizeKeys, keysToSnakeCase } from "lib/helpers";

function* updateAdminVolumeTiersSettings(action) {
  const { volumeTiers } = action.payload;

  const params = { revenue_tiers: keysToSnakeCase(volumeTiers) };
  const { success, data, error } = yield* makeRequest.post("/revenue_tiers", params);

  if (success && data) {
    const defaultVolumeTiers = camelizeKeys(data.body.revenueTiers);
    yield all([
      put(actions.setAdminVolumeTiersSettings(defaultVolumeTiers)),
      put(
        actions.newSuccessEvent({
          message: messages.ENTERPRISE_ADMIN_SETTINGS_UPDATE_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_ADMIN_VOLUME_TIER_SETTINGS,
    });
  }

  return null;
}

export default updateAdminVolumeTiersSettings;
