import React from "react";

import { T3PartnerT } from "components/Drawers/SelectT3PartnerDrawer/types";
import KnowledgeBaseArticles, { ArticleDataT } from "../../KnowledgeBaseArticles";
import PowurPlayVideos, { PowurPlayVideoT } from "../../PowurPlayVideos";
import AssignedT3Partner from "../../AssignedT3Partner";

import * as S from "./styles";

type CannotRequestProposalPropsT = {
  assignedPartner: T3PartnerT;
  rsmRequirementsPassed: boolean;
};

const t3PartnerKbArticles: ArticleDataT[] = [
  {
    key: "pre-sale-prep",
    title: "Pre-Sale Preparation",
    detail: "Preparing to present your solar proposal to your homeowner can improve your chances of closing the sale.",
    url: "https://help.powur.com/hc/en-us/articles/15494548693019-Pre-Sale-Preparation",
  },
  {
    key: "powur-proposal",
    title: "The Powur Proposal",
    detail:
      "Powur's solar proposal is designed to help you flow through the presentation to customers in an effective, straightforward process.",
    url: "https://help.powur.com/hc/en-us/articles/9480067979163-The-Powur-Proposal",
  },
  {
    key: "homeowner-presentation",
    title: "Homeowner Presentation",
    detail: "Powur's sales presentation for delivering high-quality and consistent sales presentations to homeowners. ",
    url: "https://help.powur.com/hc/en-us/articles/9479616799003-Homeowner-Presentation",
  },
  {
    key: "powur-fourmula",
    title: "The Powur FOURmula: Quick-Start Guide",
    detail: "This formula, if applied consistently, will help you achieve your goals in this business.",
    url: "https://help.powur.com/hc/en-us/articles/15494548693019-Pre-Sale-Preparation",
  },
];

const powurPlayVideoData: PowurPlayVideoT[] = [
  {
    key: "presenting-proposal-tips",
    label: "Tips for Presenting Proposal",
    image: "/img/presenting-proposal-tips.png",
    url: "https://play.powur.com/movie/smith-tips-for-presenting-the-proposal",
  },
  {
    key: "first-month-tips",
    label: "Stories from Successful Sellers: Luiz Perez",
    image: "/img/first-month-tips.png",
    url: "https://play.powur.com/movie/perez-stories-from-successful-sellers-first-month-tips",
  },
  {
    key: "seller-success-stories",
    label: "Stories from Successful Sellers: Anthony DiPierro",
    image: "/img/seller-success-stories.png",
    url: "https://play.powur.com/movie/dipierro-helpful-tips-for-your-first-month",
  },
];

const CannotRequestProposal = ({ rsmRequirementsPassed, assignedPartner }: CannotRequestProposalPropsT) => {
  return (
    <S.CannotRequestContainer>
      <S.NoteHeader>
        {rsmRequirementsPassed
          ? "Your Closer has been added to the project!"
          : "Reach out to your Regional Sales Manager for Assistance"}
      </S.NoteHeader>
      <S.NoteDetails>
        {rsmRequirementsPassed
          ? "What happens next? Your Closer will submit the proposal request and assist you in communicating with the homeowner and closing the deal."
          : "Because your project is in Nevada, all proposals must be submitted and closed with the assistance of a Regional Sales Manager (RSM) to meet Nevada's SB293 requirements for solar sales which took effect on January 1, 2024."}
      </S.NoteDetails>
      <AssignedT3Partner
        assignedPartner={assignedPartner}
        rsmRequirementsPassed={rsmRequirementsPassed}
        hideAppointmentForm
      />
      {rsmRequirementsPassed && (
        <>
          <KnowledgeBaseArticles articleData={t3PartnerKbArticles} />
          <PowurPlayVideos videoData={powurPlayVideoData} />
        </>
      )}
    </S.CannotRequestContainer>
  );
};

export default CannotRequestProposal;
