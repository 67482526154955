import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getRegistration(action) {
  const { code } = action.payload;

  const { success, data, error } = yield* makeRequest.get(`/registrations/${code}`, {});

  if (success && data) {
    yield all([
      put(actions.setRegistrationInfo(keysToCamelCase(data.body))),
      put(
        actions.newSuccessEvent({
          message: messages.GET_REGISTRATION_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_REGISTRATION,
    });
  }

  return null;
}

export default getRegistration;
