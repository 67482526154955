import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import types from "rdx/types";
import messageTypes from "rdx/modules/stateLicenses/messages";

import { formatDate } from "lib/helpers";
import useLoading from "hooks/useLoading";

import { useTranslation } from "react-i18next";

import usePermissions, { permissionTypes } from "hooks/usePermissions";
// import listings from "lib/misc/StateLicenses";
import { Button, Popconfirm, Form, Input, Select, Skeleton } from "antd";

import styles from "../../licenses.module.less";

const StateLicenses = (props) => {
  const {
    getStateLicenses,
    createStateLicense,
    deleteStateLicense,
    listings,
    states,
    latestMessage,
    updatedAt,
    setAlertMessageVisible,
  } = props;

  const { t } = useTranslation("licenses");
  const { Option } = Select;

  const { STATE_LICENSES_UPDATES } = usePermissions({
    permissionRequests: [permissionTypes.STATE_LICENSES_UPDATES],
  });

  const loading = useLoading({
    watchRequests: [types.GET_CAREER_LISTINGS_REQUEST],
  });

  useEffect(() => {
    getStateLicenses();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [form] = Form.useForm();

  const handleSaveNew = (values) => {
    createStateLicense(values);
    form.resetFields();
  };

  const handleDelete = (id) => {
    deleteStateLicense(id);
  };

  useEffect(() => {
    if (latestMessage.type === "SUCCESS") {
      if (
        latestMessage.message !== messageTypes.STATE_LICENSE_DELETED_SUCCESS ||
        latestMessage.message !== messageTypes.STATE_LICENSE_CREATED_SUCCESS
      ) {
        setAlertMessageVisible({ message: latestMessage.message, severity: "success" });
      }
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const openRoles = useMemo(
    () =>
      listings.map((listing) => {
        const { id, license, state } = listing;
        if (loading) {
          return <Skeleton paragraph={{ rows: 4 }} key={id} />;
        }
        return STATE_LICENSES_UPDATES ? (
          <div className={styles.listing} key={id}>
            <h2 className={styles.subheading}>{state}</h2>
            <div className={styles.subtextContainer}>
              <div className={styles.subtext}>{license}</div>
            </div>
            <Popconfirm
              title="Are you sure you want to delete this state license?"
              onConfirm={() => handleDelete(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button className={styles.deleteButton}>Delete</Button>
            </Popconfirm>
          </div>
        ) : (
          <div className={styles.listing} key={id}>
            <h2 className={styles.subheading}>{state}</h2>
            <div className={styles.subtext}>{license}</div>
          </div>
        );
      }),
    [listings, loading], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div className={styles.careersSection}>
      <div className={styles.careerImage} />
      <div className={styles.listings}>
        <h5 className={styles.heading}>
          {t("updated")} {formatDate(updatedAt)}
        </h5>
        {openRoles}
        {STATE_LICENSES_UPDATES && (
          <div className={styles.subheadingForm}>
            <span>Add New State</span>
            <Form className={styles.newPostingForm} form={form} onFinish={handleSaveNew}>
              <span className={styles.formLabel}>State</span>
              <Form.Item name="state" rules={[{ required: true, message: "State is required" }]}>
                <Select defaultValue="Select">
                  {Object.values(states).map((state) => {
                    return (
                      <Option key={state} value={state}>
                        {state}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <span className={styles.formLabel}>License</span>
              <Form.Item name="license" rules={[{ required: true, message: "License is required" }]}>
                <Input />
              </Form.Item>
              <Button type="primary" htmlType="submit" className={styles.submitButton}>
                Submit
              </Button>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  getRdxSelectionMapper({
    listings: "getStateLicenses",
    states: "getStateLicensesStates",
    updatedAt: "getStateLicensesUpdatedAt",
    requests: "getActiveRequests",
    latestMessage: "getLatestMessageEvt",
  }),
  getRdxActionMapper([
    "getStateLicenses",
    "createStateLicense",
    "updateStateLicense",
    "deleteStateLicense",
    "setAlertMessageVisible",
  ]),
)(StateLicenses);
