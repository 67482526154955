import { styled } from "@mui/material/styles";
import { cvar } from "styles";

export const ReasonBanner = styled("div")(() => ({
  background: cvar("warning-orange"),
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.03)",
  borderRadius: "4px",
  padding: "16px",
  marginBottom: "30px",
  marginTop: "-20px",
  display: "flex",
  alignItems: "center",
}));

export const ReasonIcon = styled("div")(() => ({
  marginRight: "16px",
}));

export const ReasonBody = styled("div")(() => ({
  fontFamily: "Barlow",
  fontWeight: "500",
  fontSize: "14px",
  color: cvar("pumpkin"),
}));

export const ReasonSpan = styled("span")(() => ({
  marginRight: "16px",
  fontWeight: "700",
}));
