import React from "react";
import PropTypes from "prop-types";

const StarIcon = (props) => {
  const { stroke, strokeOpacity, fill, height, width, containerStyles } = props;

  return (
    <span style={{ paddingTop: "2px", ...containerStyles }}>
      <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.42937 1.75623C8.60898 1.20345 9.39102 1.20344 9.57063 1.75623L10.8859 5.80426C10.9662 6.05147 11.1966 6.21885 11.4566 6.21885H15.7129C16.2941 6.21885 16.5358 6.96262 16.0656 7.30426L12.6221 9.80608C12.4118 9.95886 12.3238 10.2297 12.4042 10.4769L13.7194 14.5249C13.899 15.0777 13.2664 15.5374 12.7961 15.1957L9.35267 12.6939C9.14238 12.5411 8.85762 12.5411 8.64733 12.6939L5.20387 15.1957C4.73364 15.5374 4.10096 15.0777 4.28057 14.5249L5.59585 10.4769C5.67617 10.2297 5.58818 9.95886 5.37789 9.80608L1.93443 7.30426C1.4642 6.96262 1.70587 6.21885 2.2871 6.21885H6.54345C6.80338 6.21885 7.03376 6.05147 7.11408 5.80426L8.42937 1.75623Z"
          fill={fill}
        />
        <path
          d="M10.0462 1.60172C9.71687 0.588278 8.28312 0.588283 7.95384 1.60172L6.63855 5.64975C6.62517 5.69095 6.58677 5.71885 6.54345 5.71885H2.2871C1.22151 5.71885 0.778454 7.08243 1.64054 7.70877L5.08399 10.2106C5.11904 10.236 5.13371 10.2812 5.12032 10.3224L3.80504 14.3704C3.47575 15.3839 4.63568 16.2266 5.49776 15.6003L8.94122 13.0984C8.97627 13.073 9.02373 13.073 9.05878 13.0984L12.5022 15.6003C13.3643 16.2266 14.5242 15.3839 14.195 14.3704L12.8797 10.3224C12.8663 10.2812 12.881 10.236 12.916 10.2106L16.3595 7.70877C17.2216 7.08242 16.7785 5.71885 15.7129 5.71885H11.4566C11.4132 5.71885 11.3748 5.69095 11.3614 5.64975L10.0462 1.60172Z"
          stroke={stroke}
          strokeOpacity={strokeOpacity}
        />
      </svg>
    </span>
  );
};

StarIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  stroke: PropTypes.string,
  strokeOpacity: PropTypes.string,
};

StarIcon.defaultProps = {
  stroke: "black",
  strokeOpacity: ".2",
  fill: "var(--canary-yellow)",
  height: "12",
  width: "12",
};

export default StarIcon;
