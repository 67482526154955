import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/adminLeads/types";

import getAdminLeadsList from "./getAdminLeadsList";
// IMPORT_PT (for script, do not remove)

function* watchAdminLeadsSagas() {
  yield trackRequests(takeEvery, types.GET_ADMIN_LEADS_LIST, getAdminLeadsList);
  // INSERTION_PT (for script, do not remove)
}

export default watchAdminLeadsSagas;
