import types from "rdx/modules/compensation/types";
import createAction from "rdx/utils/createAction";

export default {
  getPeriods: (payload) => createAction(types.GET_PERIODS_REQUEST, payload),
  setPeriods: (payload) => createAction(types.SET_PERIODS, payload),
  getPayments: (payload) => createAction(types.GET_PAYMENTS_REQUEST, payload),
  setPayments: (payload) => createAction(types.SET_PAYMENTS, payload),
  getLedger: (payload) => createAction(types.GET_LEDGER_REQUEST, payload),
  setLedger: (payload) => createAction(types.SET_LEDGER, payload),
  getLedgerCsv: (payload) => createAction(types.GET_LEDGER_CSV_REQUEST, payload),
  getEnterpriseLedgerCsv: (payload) => createAction(types.GET_ENTERPRISE_LEDGER_CSV_REQUEST, payload),
  getEarningsCsv: (payload) => createAction(types.GET_EARNINGS_CSV_REQUEST, payload),
  getLedgerCsvReport: (payload) => createAction(types.GET_LEDGER_CSV_REPORT_REQUEST, payload),
  setLedgerCsv: (payload) => createAction(types.SET_LEDGER_CSV, payload),
  addPayment: (payload) => createAction(types.ADD_PAYMENT_REQUEST, payload),
  clawbackPayment: (payload) => createAction(types.CLAWBACK_PAYMENT_REQUEST, payload),
  deletePayment: (payload) => createAction(types.DELETE_PAYMENT_REQUEST, payload),
  editPayment: (payload) => createAction(types.EDIT_PAYMENT_AMOUNT, payload),
  recalculatePayment: (payload) => createAction(types.RECALCULATE_PAYMENT_REQUEST, payload),
  distributePayment: (payload) => createAction(types.DISTRIBUTE_PAYMENT_REQUEST, payload),
  getUserOrLeadAutoComplete: (payload) => createAction(types.GET_USER_OR_LEAD_AUTO_COMPLETE, payload),
  setUserOrLeadAutoComplete: (payload) => createAction(types.SET_USER_OR_LEAD_AUTO_COMPLETE, payload),
  getDistributions: (payload) => createAction(types.GET_DISTRIBUTIONS_REQUEST, payload),
  setDistributions: (payload) => createAction(types.SET_DISTRIBUTIONS, payload),
  getProjectsCompensationList: (payload) => createAction(types.GET_PROJECTS_COMPENSATION_LIST, payload),
  setProjectsCompensationList: (payload) => createAction(types.SET_PROJECTS_COMPENSATION_LIST, payload),

  getProjectRevenueSplit: (payload) => createAction(types.GET_PROJECT_REVENUE_SPLIT, payload),

  setProjectRevenueSplit: (payload) => createAction(types.SET_PROJECT_REVENUE_SPLIT, payload),
  // INSERTION_PT (for script, do not remove)
};
