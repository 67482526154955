export default {
  GET_TEAM_LEADS_LIST_REQUEST: "[team] GET Team Leads List Request",
  SET_TEAM_LEADS_LIST: "[team] SET Team Leads List",
  GET_TEAM_UPLINE_LIST_REQUEST: "[team] GET Team Upline List Request",
  SET_TEAM_UPLINE_LIST: "[team] SET Team Upline List",
  SET_AMBASSADOR_LIST: "[team] SET Ambassdor List",
  GET_AMBASSADOR_LIST_REQUEST: "[team] GET Ambassdor List Request",
  GET_TEAM_USER_DETAILS_REQUEST: "[team] GET Team User Details Request",
  GET_TEAM_USER_RECRUITS_REQUEST: "[team] GET Team User Recruits Request",
  SET_TEAM_USER_DETAILS: "[team] SET Team User Details",
  RESET_TEAM_USER_DETAILS: "[team] Reset Team User Details",
  INVITE_NEW_TEAM_LEAD_REQUEST: "[team] Invite New Team Lead Request",
  SET_CONSULTANTS_AUTOCOMPLETE_SUGGESTIONS: "[team] SET Sellers Autocomplete Suggestions",
  ARCHIVE_TEAM_LEAD_REQUEST: "[team] Archive Team Lead Request",
  UNARCHIVE_TEAM_LEAD_REQUEST: "[team] Unarchive Team Lead Request",
  SET_EDIT_INVITE_ID: "[team] SET Edit Invite Id",
  GET_INVITE_DETAILS: "[team] GET Invite Details",
  SET_INVITE_DETAILS: "[team] SET Invite Details",
  UPDATE_PENDING_INVITE: "[team] Update Pending Invite",
  DELETE_INVITE: "[team] Delete Invite",
  // INSERTION_PT (for script, do not remove)
};
