import React from "react";
import { useSelector } from "react-redux";
import selectors from "rdx/selectors";
import { HeaderInfoSectionContainer } from "./styles";

const HeaderInfoSection = () => {
  const isProjectMiniHeaderActive = useSelector(selectors.selectProjectMiniHeaderActive);
  return <HeaderInfoSectionContainer isMiniHeaderActive={isProjectMiniHeaderActive} id="HEADER-SECTION-CONTAINER" />;
};

export default HeaderInfoSection;
