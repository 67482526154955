import React from "react";
import PropTypes from "prop-types";

const RescindIcon = (props) => {
  const { fill, height, width, containerStyles } = props;

  return (
    <div
      style={{
        borderRadius: "50%",
        width: `${width * 2}px`,
        height: `${height * 2}px`,
        ...containerStyles,
      }}
    >
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect
            x="9.78809"
            y="1.77344"
            width="1.02045"
            height="10.8849"
            rx="0.510227"
            transform="rotate(45 9.78809 1.77344)"
            fill={fill}
            stroke={fill}
            strokeWidth="0.4"
          />
          <circle cx="6.2998" cy="5.98438" r="5" stroke={fill} strokeWidth="1.25" />
        </svg>
      </div>
    </div>
  );
};

RescindIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

RescindIcon.defaultProps = {
  fill: "white",
  height: "12",
  width: "12",
  containerStyles: {},
};

export default RescindIcon;
