import { styled } from "@mui/material";
import { theme } from "styles/themes";

export const FooterContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "20%",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

export const Column = styled("div")(() => ({
  display: "flex",
  flexFlow: "column",
  gap: "2px",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "5px",
  },
}));

export const Row = styled("div")(() => ({
  display: "flex",
  flexFlow: "row wrap",
  weight: 700,
  fontSize: "12px",
  color: theme.colors["nepal-blue"],
}));

export const NameText = styled("div")(() => ({
  fontSize: "14px",
  fontWeight: 600,
  color: theme.colors["dark-blue"],
  marginLeft: "5px",
}));
