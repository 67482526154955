import { styled } from "@mui/material/styles";
import { cvar } from "styles";

export const RequirementBanner = styled("div")(() => ({
  background: `linear-gradient(95.7deg, ${cvar("lemon-chiffon")} 12.17%, ${cvar("canary-yellow")} 93.24%)`,
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.03)",
  borderRadius: "12px",
  padding: "14px 24px",
  marginBottom: "24px",
  display: "flex",
  alignItems: "center",
}));

export const RequirementIcon = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "32px",
  maxWidth: "32px",
  minHeight: "32px",
  maxHeight: "32px",
  borderRadius: "50%",
  paddingBottom: "2px",
  marginRight: "22px",
  backgroundColor: cvar("safety-orange"),
}));

export const RequirementHeader = styled("div")(() => ({
  fontFamily: "Poppins",
  fontWeight: "700",
  fontSize: "16px",
  color: cvar("blue-ribbon"),
}));

export const RequirementBody = styled("div")(() => ({
  fontFamily: "Poppins",
  fontWeight: "700",
  fontSize: "14px",
  color: cvar("blue-ribbon"),
}));

export const RequirementText = styled("div")(() => ({
  fontWeight: "500",
  color: cvar("dark-blue"),
  lineHeight: "17px",
}));
