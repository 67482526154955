module.exports = {
  states: [
    {
      short: "AB",
      long: "Alberta",
    },
    {
      short: "AL",
      long: "Alabama",
    },
    {
      short: "AK",
      long: "Alaska",
    },
    {
      short: "AZ",
      long: "Arizona",
    },
    {
      short: "AR",
      long: "Arkansas",
    },
    {
      short: "BC",
      long: "British Columbia",
    },
    {
      short: "CA",
      long: "California",
    },
    {
      short: "CO",
      long: "Colorado",
    },
    {
      short: "CT",
      long: "Connecticut",
    },
    {
      short: "DE",
      long: "Delaware",
    },
    {
      short: "FL",
      long: "Florida",
    },
    {
      short: "GA",
      long: "Georgia",
    },
    {
      short: "HI",
      long: "Hawaii",
    },
    {
      short: "ID",
      long: "Idaho",
    },
    {
      short: "IL",
      long: "Illinois",
    },
    {
      short: "IN",
      long: "Indiana",
    },
    {
      short: "IA",
      long: "Iowa",
    },
    {
      short: "KS",
      long: "Kansas",
    },
    {
      short: "KY",
      long: "Kentucky",
    },
    {
      short: "LA",
      long: "Louisiana",
    },
    {
      short: "ME",
      long: "Maine",
    },
    {
      short: "MD",
      long: "Maryland",
    },
    {
      short: "MA",
      long: "Massachusetts",
    },
    {
      short: "MB",
      long: "Manitoba",
    },
    {
      short: "MI",
      long: "Michigan",
    },
    {
      short: "MN",
      long: "Minnesota",
    },
    {
      short: "MS",
      long: "Mississippi",
    },
    {
      short: "MO",
      long: "Missouri",
    },
    {
      short: "MT",
      long: "Montana",
    },
    {
      short: "NB",
      long: "New Brunswick",
    },
    {
      short: "NE",
      long: "Nebraska",
    },
    {
      short: "NL",
      long: "Newfoundland and Labrador",
    },
    {
      short: "NS",
      long: "Nova Scotia",
    },
    {
      short: "NT",
      long: "Northwest Territories",
    },
    {
      short: "NU",
      long: "Nunavut",
    },
    {
      short: "NV",
      long: "Nevada",
    },
    {
      short: "NH",
      long: "New Hampshire",
    },
    {
      short: "NJ",
      long: "New Jersey",
    },
    {
      short: "NM",
      long: "New Mexico",
    },
    {
      short: "NY",
      long: "New York",
    },
    {
      short: "NC",
      long: "North Carolina",
    },
    {
      short: "ND",
      long: "North Dakota",
    },
    {
      short: "OH",
      long: "Ohio",
    },
    {
      short: "OK",
      long: "Oklahoma",
    },
    {
      short: "ON",
      long: "Ontario",
    },
    {
      short: "OR",
      long: "Oregon",
    },
    {
      short: "PA",
      long: "Pennsylvania",
    },
    {
      short: "PE",
      long: "Prince Edward Island",
    },
    {
      short: "PR",
      long: "Puerto Rico",
    },
    {
      short: "QC",
      long: "Quebec",
    },
    {
      short: "RI",
      long: "Rhode Island",
    },
    {
      short: "SC",
      long: "South Carolina",
    },
    {
      short: "SD",
      long: "South Dakota",
    },
    {
      short: "SK",
      long: "Saskatchewan",
    },
    {
      short: "TN",
      long: "Tennessee",
    },
    {
      short: "TX",
      long: "Texas",
    },
    {
      short: "UT",
      long: "Utah",
    },
    {
      short: "VT",
      long: "Vermont",
    },
    {
      short: "VA",
      long: "Virginia",
    },
    {
      short: "WA",
      long: "Washington",
    },
    {
      short: "WV",
      long: "West Virginia",
    },
    {
      short: "WI",
      long: "Wisconsin",
    },
    {
      short: "WY",
      long: "Wyoming",
    },
    {
      short: "YT",
      long: "Yukon",
    },
  ],
  us: [
    {
      short: "AL,USA",
      long: "Alabama",
    },
    {
      short: "AK,USA",
      long: "Alaska",
    },
    {
      short: "AZ,USA",
      long: "Arizona",
    },
    {
      short: "AR,USA",
      long: "Arkansas",
    },
    {
      short: "CA,USA",
      long: "California",
    },
    {
      short: "CO,USA",
      long: "Colorado",
    },
    {
      short: "CT,USA",
      long: "Connecticut",
    },
    {
      short: "DC,USA",
      long: "Washington, D.C.",
    },
    {
      short: "DE,USA",
      long: "Delaware",
    },
    {
      short: "FL,USA",
      long: "Florida",
    },
    {
      short: "GA,USA",
      long: "Georgia",
    },
    {
      short: "GU,USA",
      long: "Guam",
    },
    {
      short: "HI,USA",
      long: "Hawaii",
    },
    {
      short: "ID,USA",
      long: "Idaho",
    },
    {
      short: "IL,USA",
      long: "Illinois",
    },
    {
      short: "IN,USA",
      long: "Indiana",
    },
    {
      short: "IA,USA",
      long: "Iowa",
    },
    {
      short: "KS,USA",
      long: "Kansas",
    },
    {
      short: "KY,USA",
      long: "Kentucky",
    },
    {
      short: "LA,USA",
      long: "Louisana",
    },
    {
      short: "ME,USA",
      long: "Maine",
    },
    {
      short: "MD,USA",
      long: "Maryland",
    },
    {
      short: "MA,USA",
      long: "Massachusetts",
    },
    {
      short: "MI,USA",
      long: "Michigan",
    },
    {
      short: "MN,USA",
      long: "Minnesota",
    },
    {
      short: "MS,USA",
      long: "Mississippi",
    },
    {
      short: "MO,USA",
      long: "Missouri",
    },
    {
      short: "MT,USA",
      long: "Montana",
    },
    {
      short: "NE,USA",
      long: "Nebraska",
    },
    {
      short: "NV,USA",
      long: "Nevada",
    },
    {
      short: "NH,USA",
      long: "New Hampshire",
    },
    {
      short: "NJ,USA",
      long: "New Jersey",
    },
    {
      short: "NM,USA",
      long: "New Mexico",
    },
    {
      short: "NY,USA",
      long: "New York",
    },
    {
      short: "NC,USA",
      long: "North Carolina",
    },
    {
      short: "ND,USA",
      long: "North Dakota",
    },
    {
      short: "OH,USA",
      long: "Ohio",
    },
    {
      short: "OK,USA",
      long: "Oklahoma",
    },
    {
      short: "OR,USA",
      long: "Oregon",
    },
    {
      short: "PA,USA",
      long: "Pennsylvania",
    },
    {
      short: "PR,USA",
      long: "Puerto Rico",
    },
    {
      short: "RI,USA",
      long: "Rhode Island",
    },
    {
      short: "SC,USA",
      long: "South Carolina",
    },
    {
      short: "SD,USA",
      long: "South Dakota",
    },
    {
      short: "TN,USA",
      long: "Tennessee",
    },
    {
      short: "TX,USA",
      long: "Texas",
    },
    {
      short: "UT,USA",
      long: "Utah",
    },
    {
      short: "VT,USA",
      long: "Vermont",
    },
    {
      short: "VA,USA",
      long: "Virginia",
    },
    {
      short: "VI,USA",
      long: "Virgin Islands",
    },
    {
      short: "WA,USA",
      long: "Washington",
    },
    {
      short: "WV,USA",
      long: "West Virginia",
    },
    {
      short: "WI,USA",
      long: "Wisconsin",
    },
    {
      short: "WY,USA",
      long: "Wyoming",
    },
  ],
  // Should we remove?
  australia: [
    { long: "New South Wales", short: "NSW,AUS" },
    { long: "Queensland", short: "QLD,AUS" },
    { long: "South Australia", short: "SA,AUS" },
    { long: "Tasmania", short: "TAS,AUS" },
    { long: "Victoria", short: "VIC,AUS" },
    { long: "Western Australia", short: "WA,AUS" },
  ],
  canada: [
    {
      short: "AB,CAN",
      long: "Alberta",
    },
    {
      short: "BC,CAN",
      long: "British Columbia",
    },
    {
      short: "MB,CAN",
      long: "Manitoba",
    },
    {
      short: "NB,CAN",
      long: "New Brunswick",
    },
    {
      short: "NL,CAN",
      long: "Newfoundland and Labrador",
    },
    {
      short: "NS,CAN",
      long: "Nova Scotia",
    },
    {
      short: "NT,CAN",
      long: "Northwest Territories",
    },
    {
      short: "NU,CAN",
      long: "Nunavut",
    },
    {
      short: "ON,CAN",
      long: "Ontario",
    },
    {
      short: "PE,CAN",
      long: "Prince Edward Island",
    },
    {
      short: "QC,CAN",
      long: "Quebec",
    },
    {
      short: "SK,CAN",
      long: "Saskatchewan",
    },
    {
      short: "YT,CAN",
      long: "Yukon",
    },
  ],
};
