import React from "react";
import { cvar } from "styles";
import { IconT } from "types/utils";

const DocumentIcon = ({ height = "33px", width = "35px", fill = cvar("nepal-blue") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.4059 6.8493L22.9815 0.969762C22.2982 0.344373 21.4038 0 20.4632 0H8.23194C6.21134 0 4.56744 1.59025 4.56744 3.54492V29.4551C4.56744 31.4097 6.21134 33 8.23194 33H26.8876C28.9082 33 30.5521 31.4097 30.5521 29.4551V9.42447C30.5521 8.45451 30.1343 7.51588 29.4059 6.8493ZM27.4646 7.73438H22.4902C22.3065 7.73438 22.157 7.58981 22.157 7.41211V2.87693L27.4646 7.73438ZM26.8876 31.0664H8.23194C7.31348 31.0664 6.56626 30.3436 6.56626 29.4551V3.54492C6.56626 2.65644 7.31348 1.93359 8.23194 1.93359H20.1582V7.41211C20.1582 8.65599 21.2043 9.66797 22.4902 9.66797H28.5532V29.4551C28.5532 30.3436 27.806 31.0664 26.8876 31.0664Z"
        fill={fill}
      />
      <path
        d="M24.6888 12.8906H10.0308C9.4789 12.8906 9.03143 13.3235 9.03143 13.8574C9.03143 14.3914 9.4789 14.8242 10.0308 14.8242H24.6888C25.2408 14.8242 25.6882 14.3914 25.6882 13.8574C25.6882 13.3235 25.2408 12.8906 24.6888 12.8906Z"
        fill={fill}
      />
      <path
        d="M24.6888 18.0469H10.0308C9.4789 18.0469 9.03143 18.4797 9.03143 19.0137C9.03143 19.5476 9.4789 19.9805 10.0308 19.9805H24.6888C25.2408 19.9805 25.6882 19.5476 25.6882 19.0137C25.6882 18.4797 25.2408 18.0469 24.6888 18.0469Z"
        fill={fill}
      />
      <path
        d="M14.876 23.2031H10.0308C9.4789 23.2031 9.03143 23.636 9.03143 24.1699C9.03143 24.7039 9.4789 25.1367 10.0308 25.1367H14.876C15.4279 25.1367 15.8754 24.7039 15.8754 24.1699C15.8754 23.636 15.4279 23.2031 14.876 23.2031Z"
        fill={fill}
      />
    </svg>
  );
};

export default DocumentIcon;
