import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getSeatedSellerRegistrationInfo(action) {
  const { slug } = action.payload;

  const { success, data, error } = yield* makeRequest.get("/registrations/seated_seller_info", { slug });

  if (success && data) {
    yield put(actions.setSeatedSellerRegistrationInfo(camelizeKeys(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_SEATED_SELLER_REGISTRATION_INFO,
    });
  }

  return null;
}

export default getSeatedSellerRegistrationInfo;
