import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../careers.module.less";

const HeroImage = () => {
  const { t } = useTranslation("careers");
  return (
    <div className={styles.heroImageContainer}>
      <div className={styles.heroImage} />
      <div className={styles.titleOverlay}>
        <div className={styles.textContainer}>
          <span className={styles.mainText}>{t("careers")}</span>
          <span className={styles.subText}>{t("join_the_solar_energy_team")}</span>
        </div>
      </div>
    </div>
  );
};

export default HeroImage;
