import types from "rdx/modules/app/types";
import { $TSFixMe } from "types";

type THandler<T> = {
  [x: string]: (state: T, action: { payload: $TSFixMe }) => T;
};

export default function createReducer<TState>(initialState: TState, handlers: THandler<TState>) {
  // eslint-disable-next-line no-prototype-builtins
  if (handlers.hasOwnProperty(typeof undefined)) {
    console.error("Reducer created with undefined handler, check your type constants");
  }

  return function reducer(state: TState = initialState, action: { type: string; payload: TState }) {
    if (action.type === types.BATCH_ACTIONS) {
      const batchedActions = action.payload;
      if (!Array.isArray(batchedActions)) return state;
      for (let i = 0; i < batchedActions.length; i++) {
        const thisAction = batchedActions[i];
        if (handlers[thisAction.type]) {
          return handlers[thisAction.type](state, thisAction);
        }
      }
    } else if (handlers[action.type]) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}
