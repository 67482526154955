import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import { cvar } from "styles";

export const StyledGridContainer = styled(Grid)(() => ({
  marginBottom: "15px",
}));

export const StyledSectionLabel = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "17px",
  color: cvar("darkest-blue"),
}));

export const ReadOnlyTimeDisplay = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "17px",
  color: cvar("dark-blue"),
  display: "flex",
  alignItems: "center",
}));

export const ReadOnlyTime = styled("div")(() => ({
  marginLeft: "13px",
}));

export const ReadOnlyLabel = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "9px",
  marginBottom: "16px",
  color: cvar("nepal-blue"),
  textTransform: "uppercase",
}));

export const StyledDeleteSectionButton = styled("div")(() => ({
  cursor: "pointer",
}));
