import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* putGrayscaleCopy(action) {
  const { success, data, error } = yield* makeRequest.put("/upload_gray_scale_copy", action.payload);

  if (success && data) {
    yield put(
      actions.newSuccessEvent({
        message: messages.PUT_GRAYSCALE_SUCCESS,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.PUT_GRAYSCALE_FAIL,
    });
  }

  return null;
}

export default putGrayscaleCopy;
