import React from "react";
import { DocumentIcon } from "components/Icons";
import { Row, Progress } from "antd";
import { CSSTransition, Transition } from "react-transition-group";
import types from "../types";
import styles from "../styles.module.less";

const FileUploading = ({ meta }) => {
  const { id, percent, status } = meta;

  const setStatus = () => {
    const errors = [
      "rejected_file_type",
      "rejected_max_files",
      "error_file_size",
      "error_validation",
      "error_upload_params",
      "exception_upload",
      "aborted",
      "removed",
      "error_upload",
    ];
    if (errors.includes(status)) return "exception";
    if (status === "done") return "success";
    return "active";
  };

  return (
    <Row className={styles.previewRow}>
      <div className={styles.fileDetails}>
        <span className={styles.documentIcon}>
          <DocumentIcon />
        </span>
        <Transition timeout={300}>
          {() => (
            <CSSTransition
              in={status !== "done"}
              key={`${id}-preview-progress-bar`}
              timeout={300}
              classNames="file-uploader-filename-input-progress-switch-animations-U7VV2"
              unmountOnExit
            >
              <Progress
                className={styles.progress}
                percent={percent}
                type="line"
                showInfo={false}
                strokeColor={{
                  "0%": "rgba(207, 231, 250, 1)",
                  "100%": "rgba(38, 165, 210, 1)",
                }}
                status={setStatus()}
              />
            </CSSTransition>
          )}
        </Transition>
      </div>
    </Row>
  );
};

FileUploading.propTypes = {
  meta: types.meta,
};

export default FileUploading;
