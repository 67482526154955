import makeRequest from "rdx/utils/makeRequest";
import { all, put, select } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToSnakeCase, camelizeKeys, formatFundingProposal } from "lib/helpers";
import {
  FundingProposalUpdatePayloadT,
  FundingProposalListItemT,
  FundingProposalT,
} from "types/Project/fundingProposal";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "../messages";

type UpdateFundingProposalPropsT = {
  payload: FundingProposalUpdatePayloadT;
};

type KpiPagingT = {
  paging: {
    pageNumber: number;
    totalPageCount: number;
    pageSize: number;
    totalKpiCount: number;
  };
};

function* updateFundingProposal(action: UpdateFundingProposalPropsT) {
  const { proposalId } = action.payload;
  const existingFundingPaging = yield select(selectors.selectFundingProposalsPaging);
  const { paging: kpiPaging }: KpiPagingT = yield select(selectors.selectKpiData);

  const successMessage = () => {
    if (action.payload.markComplete) {
      return messages.SUCCESS_COMPLETING_FUNDING_PROPOSAL;
    }
    if (action.payload.cancelProposal) {
      return messages.SUCCESS_CANCELING_FUNDING_PROPOSAL;
    }
    if (action.payload.escalatorId) {
      return messages.SUCCESS_ESCALATING_FUNDING_PROPOSAL;
    }
    return messages.SUCCESS_UPDATING_FUNDING_PROPOSAL;
  };

  const params = keysToSnakeCase(action.payload);

  const { success, data, error } = yield* makeRequest.patch(`/funding_proposals/${proposalId}`, params);

  if (success && data) {
    const currentList = yield select(selectors.selectFundingProposalsList);
    const clone: FundingProposalListItemT[] = [...currentList];
    const idx = clone.findIndex((proposal) => {
      return proposal.id === proposalId;
    });
    const updatedProposal: FundingProposalT = camelizeKeys(data.body);
    clone[idx] = formatFundingProposal(updatedProposal);

    yield all([
      put(actions.setFundingProposalDetails(updatedProposal)),
      put(actions.setAdminFundingProposals({ list: clone, paging: existingFundingPaging })),
      put(actions.getKpiData({ type: "funding_proposals", page: kpiPaging.pageNumber })),
      put(
        actions.newSuccessEvent({
          message: successMessage(),
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_FUNDING_PROPOSAL,
    });
  }

  return null;
}

export default updateFundingProposal;
