import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles/colorVariables";

const EnterpriseTileIcon = ({ fill = cvar("picton-blue"), width = 34, height = 35 }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="34" height="34" rx="17" fill={fill} fillOpacity="0.08" />
      <path
        d="M12.75 23V11.3636H21.1364V13.9091H15.9091V15.9091H20.7045V18.4545H15.9091V20.4545H21.1136V23H12.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default EnterpriseTileIcon;
