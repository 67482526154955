const ProjectsTabs = [
  {
    key: "pre-sales?status=active",
    label: "Pre-Sales",
  },
  {
    key: "post-sales?status=active",
    label: "Post-Sales",
  },
  {
    key: "completed",
    label: "Completed",
  },
];

export default ProjectsTabs;
