import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/projects/messages";
import { camelizeKeys } from "lib/helpers";

function* getSiteSurveySchedule(action) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/provider_leads/${id}/site_survey_schedules`);

  if (success && data) {
    if (data.body) {
      yield put(actions.setSiteSurveySchedule(camelizeKeys(data.body?.schedule)));
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_SITE_SURVEY_SCHEDULE,
    });
  }

  return null;
}

export default getSiteSurveySchedule;
