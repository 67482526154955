export default {
  SET_ROOFING_PROPOSAL_DATA: "[roofing] SET Roofing Proposal Data",
  RESET_ROOFING_PROPOSAL_DATA: "[roofing] RESET Roofing Proposal Data",
  GET_ADMIN_ROOFING_PROPOSALS: "[roofing] GET Admin Roofing Proposals",

  GET_ROOFING_USERS_AUTOCOMPLETE: "[roofing] GET Roofing Users Autocomplete",
  SET_ROOFING_USERS_AUTOCOMPLETE: "[roofing] SET Roofing Users Autocomplete",
  POST_ROOFING_PROPOSALS: "[roofing] POST Roofing Proposals",
  UPDATE_ROOFING_PROPOSAL: "[roofing] PATCH Roofing Proposal",
  GET_ROOFING_PROPOSAL: "[roofing] GET Roofing Proposal",
  SET_ADMIN_ROOFING_PROPOSAL_DETAILS: "[roofing] SET Admin Roofing Proposal Detail",
  SET_ROOFING_ACTION: "[roofing] SET Roofing Action Details",

  GET_ROOFING_CSV: "[roofing] GET Roofing Csv",
  SET_ROOFING_CSV: "[roofing] SET Roofing Csv",
  GET_ROOFING_CSV_REPORT: "[roofing] GET Roofing Csv Report",

  GET_ROOFING_S3_ASSETS: "[roofing] GET Roofing S3 Assets",
  SET_ROOFING_S3_ASSETS: "[roofing] SET Roofing S3 Assets",
  POST_ROOFING_S3_ASSET: "[roofing] POST Roofing S3 Asset",
  DELETE_ROOFING_S3_ASSET: "[roofing] DELETE Roofing S3 Asset",

  // INSERTION_PT (for script, do not remove)
};
