import React from "react";
import PropTypes from "prop-types";
import { bytesToSize, fileTypesToPlainText } from "lib/helpers";
import { ReactComponent as UploadIcon } from "./UploadIcon.svg";
import styles from "../styles.module.less";

const Input = (props) => (
  <div className={styles.inputLabelContents} data-test-id="drag-and-drop-file">
    <div className={styles.iconRow}>
      <UploadIcon className={styles.icon} />
      {/* <div className={styles.icon} /> */}
    </div>
    <div className={styles.row}>
      <div className={styles.instruction}>Drag & Drop Files Here</div>
    </div>
    <div className={styles.row}>
      <div className={styles.or}>or</div>
    </div>
    <div className={styles.row}>
      <div className={styles.click}>Browse Files</div>
    </div>
    <div className={styles.details}>
      Max file size: {bytesToSize(props.max)}, File types:
      {fileTypesToPlainText(props.accept)}
    </div>
  </div>
);

Input.propTypes = {
  max: PropTypes.number,
  accept: PropTypes.string,
};

export default Input;
