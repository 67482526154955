import React from "react";
import { Button } from "antd";
import styles from "./RemoveButton.module.less";

type RemoveButtonProps = {
  handleClick: () => void;
  title?: string;
  disabled?: boolean;
  inverse?: boolean;
};

const RemoveButton = ({ handleClick, title = "Remove", disabled = false, inverse = false }: RemoveButtonProps) => {
  return (
    <Button
      className={[styles.removeButton, ...(inverse ? [styles.removeButtonInverse] : [])].join(" ")}
      onClick={handleClick}
      type="primary"
      disabled={disabled}
    >
      <div className={[styles.icon, ...(inverse ? [styles.iconInverse] : [])].join(" ")}>
        <div className={[styles.line, ...(inverse ? [styles.lineInverse] : [])].join(" ")} />
      </div>
      <div>{title}</div>
    </Button>
  );
};

export default RemoveButton;
