import React, { useState } from "react";
import Helmet from "react-helmet";
import { Layout, Row, Col } from "antd";
import ScrollToTopOnMount from "containers/router/scrollToTop";
import { topTenQuestionsCopy } from "lib/misc/social";
import InjectFacebookPixel from "components/Injectors/InjectFacebookPixel";
import questionsList from "lib/misc/solarVideoQuestions";
import AnimatedChevrons from "../components/animatedChevrons";
import ResizableButton from "../components/resizableButton";
import MarketingForm from "../components/MarketingForm/index";
import PageFooter from "../components/pageFooter";
import SocialButtons from "./socialButtons";
import styles from "../Education.module.less";

const { Content } = Layout;

const EducationTop10Questions = (props) => {
  const { user } = props;
  const mobile = window.innerWidth < 992;
  const [currentQuestion, setCurrentQuestion] = useState(questionsList[0]);

  const videoTextSection = (
    <div className={styles.videoTextSection}>
      <h3 className={styles.videoTextTitle}>{currentQuestion?.description}</h3>
      <p className={styles.videoTextBody}>{currentQuestion?.body}</p>
    </div>
  );
  const additionalStyle = (q) => (currentQuestion?.title === q?.title ? styles.selected : "");

  const description =
    "Welcome to the Powur Solar Insights Series. Sit back and enjoy this 6 minute series of videos and rest assured that going solar is the RIGHT choice for you.";

  const image = `${window.location.origin}/img/solar_fb_01.jpeg`;

  return (
    <Layout className={styles.topTenQuestionsContainer}>
      <InjectFacebookPixel facebookPixelId={user?.facebook_pixel_id} />
      <Helmet>
        <title>The Top 10 Solar Questions Answered – POWUR | Solar Energy for your Home</title>
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
        <meta name="og:image" content={image} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <ScrollToTopOnMount />
      <Content className={styles.contentContainer}>
        <Row className={styles.headerContainer}>
          <div className={[styles.headerItem, styles.logo].join(" ")} />
          <div className={styles.headerItem}>
            <button
              type="button"
              className={styles.emptyButton}
              onClick={() => {
                const element = document.getElementById("consultation-form");
                element.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              <ResizableButton content="Get Your Free Solar Consultation" size="large" animation="pulse" />
            </button>
          </div>
        </Row>
        <Row className={styles.mainContent}>
          <Col span={24}>
            <Row className={styles.videoDescriptionContainer}>
              <Row className={styles.titleRow}>
                <div className={styles.currentVideoText}>You Are Watching VIDEO:</div>
                <p className={styles.currentVideoTitle}>{currentQuestion?.title}</p>
              </Row>
              {mobile && (
                <Row className={styles.titleRow}>
                  <div className={styles.listTitle}>Browse The Solar Questions:</div>
                </Row>
              )}
              <Row className={styles.videoRow}>
                <div className={styles.iframeContainer}>
                  <iframe
                    className={styles.iframe}
                    title={currentQuestion?.title || "iframe"}
                    // width="560"
                    // height="315"
                    src={currentQuestion?.src}
                    frameBorder="0"
                    // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allow="autoplay, fullscreen"
                    allowFullScreen
                  />
                  {!mobile && videoTextSection}
                </div>
                <div className={styles.questionContainer}>
                  {!mobile && <div className={styles.listTitle}>Browse The Solar Questions:</div>}
                  <div className={styles.questionSelect}>
                    {questionsList.map((q) => (
                      <button
                        type="button"
                        onClick={() => setCurrentQuestion(q)}
                        key={q.number}
                        className={[styles.button, additionalStyle(q)].join(" ")}
                      >
                        <span className={styles.text}>
                          {q.number} {q.title}
                        </span>
                      </button>
                    ))}
                  </div>
                  {!mobile && <SocialButtons copy={topTenQuestionsCopy} />}
                </div>
              </Row>
              {mobile && <SocialButtons copy={topTenQuestionsCopy} />}
              {mobile && videoTextSection}
            </Row>
          </Col>
          <Row id="consultation-form" className={styles.marketingFormContainer}>
            <div className={styles.chevronContainer}>
              <AnimatedChevrons size="large" />
            </div>
            <Col className={styles.formContainerItem}>
              <p className={styles.h1}>Get Your Free, No-obligation Solar Consultation Now</p>
              <p className={styles.h2}>
                If you want to put the sun to work for you, connect with us by filling out the form below. An
                experienced Powur Solar Seller will listen to your needs, help you choose the right materials and loan
                products, and make sure to include every tax incentive and rebate available.
              </p>

              <p className={styles.h2}>
                You’ll receive a free, no-obligation proposal for $0-down solar so you can go solar with total
                confidence.
              </p>
            </Col>
          </Row>
          <br />
          <Row className={styles.formContainer}>
            <Col className={styles.formContainerItem}>
              <MarketingForm backgroundColor="var(--lightest-grey)" innerPadding="0" />
            </Col>
          </Row>
        </Row>
        <Row>
          <PageFooter user={user} />
        </Row>
      </Content>
    </Layout>
  );
};

export default EducationTop10Questions;
