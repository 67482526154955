import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* createMentorStatusChange(action) {
  const { pause_from, pause_to } = action.payload;
  const { success, data, error } = yield* makeRequest.post("/profile/create_mentor_break", {
    pause_from,
    pause_to,
  });
  if (success && data) {
    yield all([
      put(actions.setProfile(keysToCamelCase(data.body))),
      put(
        actions.newSuccessEvent({
          message: messages.CREATE_MENTOR_STATUS_CHANGE_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_MENTOR_STATUS_CHANGE,
    });
  }
  return null;
}

export default createMentorStatusChange;
