import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const ReportsIcon = (props) => {
  const { fill, height, width, containerStyles, active, hovered } = props;

  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      style={{ paddingLeft: "1px" }}
      {...containerStyles}
    >
      <svg width={width} height={height} viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.4783 14.8983H12.5217V15.2461C12.5217 15.5229 12.4118 15.7883 12.2161 15.984C12.0204 16.1797 11.755 16.2896 11.4783 16.2896H8C7.72325 16.2896 7.45784 16.1797 7.26215 15.984C7.06646 15.7883 6.95652 15.5229 6.95652 15.2461V14.8983H0V15.9418C0 16.2185 0.109938 16.4839 0.305628 16.6796C0.501318 16.8753 0.766731 16.9852 1.04348 16.9852H18.4348C18.7115 16.9852 18.9769 16.8753 19.1726 16.6796C19.3683 16.4839 19.4783 16.2185 19.4783 15.9418V14.8983Z"
          fill={fill}
        />
        <path
          d="M9.7462 7.00668L6.9949 9.41364L5.3469 8.42512L4.06934 10.7242H14.6088V6.55659L11.7987 8.69607L9.7462 7.00668Z"
          fill={fill}
        />
        <path
          d="M2.08691 12.1157H17.3913V2.37656H2.08691V12.1157ZM15.3043 11.42H2.88691L5.08761 7.4583L6.91787 8.55674L9.73178 6.09413L11.8153 7.80891L15.3043 5.15291V11.42ZM4.69978 6.00856L6.80309 7.27082L9.71161 4.72578L11.8407 6.47813L15.4007 3.76787H14.2608V3.07222H16.7586L16.3412 5.56439L15.6553 5.44961L15.8466 4.30178L11.8236 7.36543L9.72517 5.63813L6.87961 8.12822L4.95857 6.97517L3.35683 9.85761L2.74778 9.51987L4.69978 6.00856Z"
          fill={fill}
        />
        <path
          d="M8.00005 15.5939H11.4783C11.5706 15.5939 11.659 15.5573 11.7243 15.4921C11.7895 15.4268 11.8261 15.3384 11.8261 15.2461V14.8983H7.65222V15.2461C7.65222 15.3384 7.68887 15.4268 7.7541 15.4921C7.81933 15.5573 7.9078 15.5939 8.00005 15.5939Z"
          fill={fill}
        />
        <path
          d="M0.695679 14.2026H18.7826V2.02871C18.7826 1.75196 18.6727 1.48655 18.477 1.29086C18.2813 1.09517 18.0159 0.985229 17.7392 0.985229H1.73916C1.46241 0.985229 1.197 1.09517 1.00131 1.29086C0.805616 1.48655 0.695679 1.75196 0.695679 2.02871V14.2026ZM1.39133 1.68088H18.087V12.8113H1.39133V1.68088Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

ReportsIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

ReportsIcon.defaultProps = {
  fill: "var(--royal-peacock-blue)",
  height: "18",
  width: "21",
};

export default ReportsIcon;
