import React from "react";
import { cvar } from "styles";
import { IconT } from "types/utils";

const AmbassadorSubmittedIcon = ({ height = "24", width = "20", fill = cvar("blue-ribbon") }: IconT) => {
  return (
    <div>
      <svg width={width} height={height} viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.87543 1.12109L1.84766 4.5397V13.6211C1.84766 18.3155 5.65324 22.1211 10.3477 22.1211C15.0421 22.1211 18.8477 18.3155 18.8477 13.6211V4.5397L9.87543 1.12109Z"
          stroke={fill}
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.16585 11.894H6.88177V14.8008C6.88177 14.9733 7.02989 15.1211 7.2027 15.1211H9.35048C9.37517 15.1211 9.42454 15.1211 9.44923 15.0965V12.4113H11.2267V15.1211C11.2514 15.1211 11.2761 15.1211 11.2761 15.1211H13.3251C13.4979 15.1211 13.646 14.9733 13.646 14.8008V11.894H14.5348C14.8063 11.894 14.9544 11.5491 14.757 11.352L10.6589 7.21347C10.5355 7.0903 10.338 7.0903 10.2145 7.21347L5.94366 11.352C5.74616 11.5245 5.8696 11.894 6.16585 11.894Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

export default AmbassadorSubmittedIcon;
