import { useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectors } from "rdx/modules/activeRequests";

export default ({ watchRequests }) => {
  const activeRequests = useSelector(selectors.getActiveRequests);

  const fetching = useRef(false);

  fetching.current = useMemo(() => {
    const activeWatched = watchRequests.filter(
      (watchedType) => !!activeRequests.find((activeReq) => watchedType === activeReq.type),
    );
    return activeWatched.length ? "loading" : undefined;
  }, [watchRequests, activeRequests]);

  return fetching.current;
};
