import { put, all, select } from "redux-saga/effects";
import selectors from "rdx/modules/tickets/selectors";
import actions from "rdx/actions";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToCamelCase, camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getProjectMessageList(action) {
  const pQuery = action.payload?.p || 1;
  const params = {
    p: pQuery,
  };
  const { success, data, error } = yield* makeRequest.get(`/projects/${action.payload.project_id}/messages`, {
    ...params,
  });

  if (success && data) {
    const currentMessages = yield select(selectors.getProjectMessageList);
    const list = data.body.messages.map((entry) => keysToCamelCase(entry));
    const paging = keysToCamelCase(data.body.paging);
    const topics = camelizeKeys(data.body.topics);

    const assignableEntities = camelizeKeys(data.body.assignable_entities).map((entity) => {
      if (entity.label === "Mentor") {
        return {
          ...entity,
          label: "Closer",
        };
      }
      return {
        ...entity,
      };
    });

    const parentIds = list.map((entry) => entry.id);

    yield all([
      put(
        actions.setProjectMessageList({
          list: paging.pageNumber > 1 ? [...currentMessages, ...list] : list,
          paging,
          assignableEntities,
          topics,
        }),
      ),
      put(
        actions.setInboxTags({
          roleTags: data.body.taggable_roles.map((r) => ({
            ...keysToCamelCase(r),
            type: "role",
            text: r.description,
          })),
          userTags: data.body.taggable_users.map((r) => ({
            ...keysToCamelCase(r),
            type: "user",
            text: r.full_name,
          })),
          teamTags: data.body.team?.team_members
            ? data.body.team.team_members.map((r) => ({
                ...keysToCamelCase(r),
                type: "teamMember",
                text: r.full_name,
              }))
            : [],
          defaultAssignee: data.body.default_assignee,
        }),
      ),
      put(
        actions.getProjectRepliesPreview({
          parentIds,
          project_id: action.payload.project_id,
        }),
      ),
      // put(
      //   actions.markMessagesAsViewed({
      //     messageIds: ids,
      //     projectId: action.payload.project_id,
      //     parentIds,
      //   }),
      // ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_MESSAGE_LIST,
    });
  }
  return null;
}

export default getProjectMessageList;
