import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import msgs from "rdx/modules/leads/messages";
import types from "rdx/modules/leads/types";
import { useHistory, useParams } from "react-router-dom";
import { Form, Input, Row, Col, Select } from "antd";
import { parse } from "query-string";
import useLoading from "hooks/useLoading";
import Lead from "models/Lead";
import MessageEvent from "models/MessageEvent";
import ResizableButton from "../resizableButton";
import styles from "./form.module.less";

const { Option } = Select;

const SolarMarketingForm = (props) => {
  const {
    backgroundColor,
    innerPadding,
    latestMessage,
    clearLatestMessage,
    setAlertMessageVisible,
    clearAlertMessage,
  } = props;

  const { location, push } = useHistory();
  const search = parse(location.search);
  const { user: userSlug } = useParams();

  const options = ["$0-$60", "$60-$100", "$100-$200", "$200-$350", "$350+"];
  const mobileView = window.innerWidth <= 600;

  const [form] = Form.useForm();

  const submitting = useLoading({
    watchRequests: [types.CREATE_NEW_PUBLIC_LEAD_REQUEST],
  });

  const submitSolarForm = (values) => {
    if (values.own === "rent" || values.averageBill === options[0]) {
      navigateToUnqualified("unqualified");
      return null;
    }
    const { ref_id } = search;

    const p = {
      lead: {
        first_name: values.firstName,
        last_name: values.lastName,
        phone: values.phoneNumber,
        email: values.email,
        average_bill: values.averageBill,
        own: values.own,
        category: "residential",
      },
      address: {
        zip: values.zip,
      },
    };
    if (ref_id) p.referrer_id = ref_id;

    Lead.createNewPublicLead({
      userSlug: userSlug || "powur.pbc",
      marketingLead: true,
      ...p,
    });

    return null;
  };

  const initialFormValues = () => {
    const { email } = search;
    return {
      email,
    };
  };

  const navigateToQualified = () => {
    navigate("/education/qualify-yes");
  };

  const navigateToUnqualified = (reason) => {
    navigate(`/education/qualify-no/${reason}`);
  };

  const navigate = (path) => {
    push(userSlug ? `/${userSlug}${path}` : path);
  };

  useEffect(() => {
    if (latestMessage && latestMessage.message === msgs.LEAD_CREATE_SUCCESS) {
      if (latestMessage.payload.submittable_status.status === "unavailable") {
        navigateToUnqualified("unsupported");
      } else {
        navigateToQualified();
      }
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => clearLatestMessage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (submitting) {
      setAlertMessageVisible({
        message: "Submitting your information. . .",
        severity: "info",
        duration: 6000,
      });
    } else {
      clearAlertMessage();
    }
  }, [submitting]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.solarFormContainer} style={{ backgroundColor }}>
      <div className={styles.inner} style={{ padding: innerPadding }}>
        <Form
          name="solar"
          onFinish={submitSolarForm}
          className={styles.solarForm}
          form={form}
          initialValues={initialFormValues()}
          validateTrigger={["onSubmit"]}
        >
          <Row className={styles.labelRow}>
            <Col span={12}>
              <div className={styles.label}>
                First Name <span className={styles.ast}> *</span>
              </div>{" "}
            </Col>
            {!mobileView && (
              <Col span={12}>
                <div className={styles.rightLabel}>
                  Last Name <span className={styles.ast}> *</span>
                </div>
              </Col>
            )}
          </Row>
          <Row className={styles.inputRow}>
            <Col span={mobileView ? 24 : 12}>
              <Form.Item
                name="firstName"
                className={styles.formItemLeft}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input className={styles.input} name="firstName" size="large" />
              </Form.Item>
            </Col>
            {!mobileView && (
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  className={styles.formItemRight}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input className={styles.input} name="lastName" size="large" />
                </Form.Item>
              </Col>
            )}
          </Row>
          {mobileView && (
            <>
              <Row className={styles.labelRow}>
                <Col span={24}>
                  <div className={styles.label}>
                    Last Name <span className={styles.ast}> *</span>
                  </div>
                </Col>
              </Row>
              <Row className={styles.inputRow}>
                <Col span={24}>
                  <Form.Item
                    name="lastName"
                    className={styles.formItemLeft}
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input className={styles.input} name="lastName" size="large" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Row className={styles.labelRow}>
            <Col span={mobileView ? 24 : 12}>
              <div className={styles.label}>
                Your Cell Number <span className={styles.ast}> *</span>
              </div>
            </Col>
            {!mobileView && (
              <Col span={12}>
                <div className={styles.rightLabel}>
                  Your Postal Code <span className={styles.ast}> *</span>
                </div>
              </Col>
            )}
          </Row>
          <Row className={styles.inputRow}>
            <Col span={mobileView ? 24 : 12}>
              <Form.Item
                name="phoneNumber"
                className={styles.formItemLeft}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <Input
                  className={styles.input}
                  placeholder="# must be cellular to qualify"
                  name="phoneNumber"
                  size="large"
                />
              </Form.Item>
            </Col>
            {!mobileView && (
              <Col span={12}>
                <Form.Item
                  name="zip"
                  className={styles.formItemRight}
                  rules={[
                    {
                      required: true,
                      message: "This field is required.",
                    },
                  ]}
                >
                  <Input className={styles.input} name="zip" size="large" />
                </Form.Item>
              </Col>
            )}
          </Row>
          {mobileView && (
            <>
              <Row className={styles.labelRow}>
                <Col span={24}>
                  <div className={styles.label}>
                    Your Postal Code <span className={styles.ast}> *</span>
                  </div>
                </Col>
              </Row>
              <Row className={styles.inputRow}>
                <Col span={24}>
                  <Form.Item
                    name="zip"
                    className={styles.formItemLeft}
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <Input className={styles.input} name="zip" size="large" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <div className={styles.label}>
            Your Email <span className={styles.ast}> *</span>
          </div>
          <Row className={styles.inputRow}>
            <Col span={24}>
              <Form.Item
                name="email"
                className={styles.formItem}
                rules={[
                  {
                    type: "email",
                    message: "The input is not a valid E-mail",
                  },
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <Input className={styles.input} name="email" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.centeredLabel}>
            Do you own or rent your home? <span className={styles.ast}> *</span>
          </div>
          <Row className={styles.centeredRow}>
            <Col className={styles.radioCol}>
              <Form.Item
                name="own"
                className={styles.radioFormItem}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <div className={styles.radioContainer}>
                  <label htmlFor="own" className={styles.container}>
                    <input type="radio" id="own" name="own" value="own" />
                    <span className={styles.checkmark} />
                    <span className={styles.radioLabel}>own</span>
                  </label>
                  <label htmlFor="rent" className={styles.container}>
                    <input type="radio" id="rent" name="own" value="rent" />
                    <span className={styles.radioLabel}>rent</span>
                    <span className={styles.checkmark} />
                  </label>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.label}>
            What is your monthly utility bill? <span className={styles.ast}> *</span>
          </div>
          <Row className={styles.inputRow}>
            <Col span={24}>
              <Form.Item
                name="averageBill"
                className={styles.formItem}
                rules={[
                  {
                    required: true,
                    message: "This field is required.",
                  },
                ]}
              >
                <Select
                  showSearch
                  className={styles.select}
                  size="large"
                  placeholder="Select one of the options"
                  name="averageBill"
                  allowClear
                  filterOption={(input, option) => {
                    const newOps = option.key.split("-");
                    const range = newOps.map((o) => parseInt(o.replace(/^\D+/g, ""), 10));
                    if (input >= range[0]) {
                      if (!range[1] || input <= range[1]) return option;
                    }
                    return null;
                  }}
                >
                  {options.map((item) => (
                    <Option key={item}>{item}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.row}>
            <div className={styles.buttonContainer}>
              <ResizableButton content="See if you qualify" size="small" onClick={() => form.submit()} />
            </div>
          </Row>
        </Form>
        <div className={styles.bottomText}>
          By submitting this request, you authorize PowurTM to call you or text you on the phone number you provided and
          prerecorded calls or messages even if your number is on any federal, state, or local do not call list. Your
          consent to this agreement is not required to purchase products or services. We respect your privacy.
        </div>
      </div>
    </div>
  );
};

SolarMarketingForm.propTypes = {
  backgroundColor: PropTypes.string,
  innerPadding: PropTypes.string,
  latestMessage: MessageEvent.types(),
  clearLatestMessage: PropTypes.func,
  setAlertMessageVisible: PropTypes.func,
  clearAlertMessage: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    latestMessage: "getLatestMessageEvt",
    requests: "getActiveRequests",
  }),
  getRdxActionMapper(["clearLatestMessage", "setAlertMessageVisible", "clearAlertMessage"]),
)(SolarMarketingForm);
