import z from "zod";
import { ProjectBlockerSlugSchema } from "rdx/modules/projectBlockers/schemas";
import { CrmActivityTypeSchema } from "./activityLog";

export const CrmActivitySchema = z.object({
  id: z.number(),
  leadId: z.number(),
  title: z.string(),
  kind: CrmActivityTypeSchema,
  meetingType: z.string(),
  meetingInfo: z.string(),
  location: z.string(),
  email: z.string(),
  due: z.string(),
  reminder: z.string(),
  reminderType: z.array(z.string()),
  notes: z.string(),
  completeType: z.string().nullable(),
  completedAt: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string().nullable(),
  archivedAt: z.string().nullable(),
  leadInfo: z.object({
    id: z.number(),
    address: z.object({ city: z.string(), state: z.string() }),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
  }),
  canDelete: z.boolean().optional(),
  slug: ProjectBlockerSlugSchema.optional(),
});
