import React from "react";
import { ButtonProps } from "@mui/material";
import { StyledButton } from "./styles";

export type IconButtonT = {
  children?: React.ReactNode;
  disabled?: boolean;
  width?: string;
  height?: string;
} & ButtonProps;

const IconButton = ({ children, onClick, ...rest }: IconButtonT) => {
  return (
    <StyledButton onClick={onClick} {...rest}>
      {children}
    </StyledButton>
  );
};

export default IconButton;
