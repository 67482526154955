import React from "react";

type MortarCapProps = {
  height?: string | number;
  width?: string | number;
  fill?: string | null;
};

const MortarCapIcon = ({ height = 100, width = 91, fill = null }: MortarCapProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 139 91" fill="none">
      <path
        d="M34.026 51.9585C30.4062 57.3286 30.4062 63.7145 30.4062 63.7145V68.9394C46.9125 68.9394 61.3917 77.7927 69.3552 91C77.3188 77.7927 91.7979 68.9394 108.449 68.9394V63.7145C108.449 58.4896 107.001 54.7161 104.974 51.9585L69.3552 67.7783L34.026 51.9585ZM139 31.059L69.5 0L0 31.059L69.5 62.118L122.639 38.4609V50.9426C119.019 51.9585 116.268 55.1515 116.268 59.2153C116.268 62.8437 118.44 65.8916 121.625 67.1978L118.729 87.807H131.036L128.141 67.1978C131.326 65.8916 133.498 62.8437 133.498 59.2153C133.498 55.2967 130.747 51.9585 127.127 50.9426V36.2839L139 31.059Z"
        fill={fill || "url(#paint0_linear_689_13837)"}
      />
      <defs>
        {!fill && (
          <linearGradient
            id="paint0_linear_689_13837"
            x1="24.386"
            y1="-37.9167"
            x2="145.51"
            y2="-19.4361"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="var(--picton-blue)" />
            <stop offset="1" stopColor="var(--blue-ribbon)" />
          </linearGradient>
        )}
      </defs>
    </svg>
  );
};

export default MortarCapIcon;
