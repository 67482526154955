import { put, all, select } from "redux-saga/effects";
import { cloneDeep } from "lodash";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { keysToCamelCase, keysToSnakeCase, camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* selectMentor(action) {
  const { id, mentorId, mentorAlgorithmUsed } = action.payload;

  const params = keysToSnakeCase({ mentorId, mentorAlgorithmUsed });

  const { success, data, error } = yield* makeRequest.patch(`/provider_leads/${id}`, params);
  if (success && data) {
    const l2pProjectId = yield select(selectors.selectLeadToProposalDrawerProjectId);
    const projectDetails = yield select(selectors.getProjectDetails);
    const projectDetailsId = projectDetails?.providerLead?.id;
    const { projects: presalesProjects, paging: presalesPaging } = yield select(selectors.getProjectPreSales);
    const idxOfUpdatedLead = presalesProjects.findIndex((proj) => proj.projectId === id);
    const updatedPreSales = cloneDeep(presalesProjects);
    updatedPreSales[idxOfUpdatedLead] = {
      ...updatedPreSales[idxOfUpdatedLead],
      projectMentorId: mentorId,
    };

    yield all([
      ...(l2pProjectId === id ? [put(actions.setLeadToProposalDrawerProjectDetails(camelizeKeys(data.body)))] : []),
      ...(!projectDetailsId || projectDetailsId === id
        ? [put(actions.setProjectDetails({ providerLead: keysToCamelCase(data.body) }))]
        : []),
      put(actions.newSuccessEvent({ message: messages.SELECT_MENTOR_SUCCESS })),
      put(
        actions.setProjectsPreSales({
          projects: updatedPreSales,
          paging: presalesPaging,
        }),
      ),
      put(actions.setAlertMessageVisible({ message: messages.SELECT_MENTOR_SUCCESS, severity: "success" })),
    ]);
  } else {
    return getErrorActions({ error, message: messages.ERROR_SELECTING_MENTOR });
  }
  return null;
}

export default selectMentor;
