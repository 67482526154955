import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import FormHelperText from "@mui/material/FormHelperText";
import { theme } from "styles/themes";
import { mq } from "styles";

export const FormLabel = {
  fontFamily: "Barlow",
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "9px",
  color: theme.colors["nepal-blue"],
  marginLeft: "0px",
};

export const StyledSectionLabel = styled("div")(() => ({
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "30px",
  color: theme.colors["blue-ribbon"],
}));

export const StyledDivider = styled(Divider)(() => ({
  background: theme.colors["botticelli-blue"],
  marginTop: "15px",
  marginBottom: "30px",
  [mq.phoneOnly]: {
    marginBottom: "18px",
  },
}));

export const StyledNote = styled("div")(() => ({
  marginBottom: "16px",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "14px",
  color: theme.colors["nepal-blue"],
}));

export const NoteUrgent = styled("div")(() => ({
  marginTop: "5px",
  fontWeight: 600,
  lineHeight: "17px",
  color: theme.colors["persian-red"],
}));

export const StyledHomeownerNote = styled("div")(() => ({
  marginBottom: "6px",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "19px",
  color: theme.colors["dark-blue"],
}));

export const Blue = styled("span")(() => ({
  color: theme.colors["blue-ribbon"],
}));

export const PuertoRicoNote = styled("div")(() => ({
  marginTop: "16px",
  marginBottom: "25px",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "14px",
  color: theme.colors["nepal-blue"],
  [mq.phoneOnly]: {
    marginBottom: "10px",
  },
}));

export const StyledCopilotNote = styled("div")(() => ({
  marginBottom: "-10px",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "14px",
  color: theme.colors["nepal-blue"],
}));

export const StyledErrorText = styled(FormHelperText)<{ error?: boolean }>(() => ({
  lineHeight: "10px",
  marginTop: "-10px",
  fontWeight: "500",
  color: theme.colors["persian-red"],
}));
