import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import User from "models/User";
import styles from "./styles.module.less";

const UserAvatar = ({ user, size = 32 }) => {
  return (
    <div className={styles.container}>
      <Avatar
        size={size}
        className={styles.avatar}
        src={user?.avatar?.thumb}
        icon={!user?.avatar?.thumb && <UserOutlined />}
      />
    </div>
  );
};

UserAvatar.propTypes = {
  user: User.types().isRequired,
  size: PropTypes.number,
};

export default UserAvatar;
