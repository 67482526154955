import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getInProgressAdminProposals(action) {
  const params = {
    ...action.payload,
  };
  const { success, data, error } = yield* makeRequest.get("/proposal_flows/in_progress", params);
  if (success && data) {
    // populate proposals list (for case when req. did not provide a filters param)
    if (data.body.projects) {
      const projects = camelizeKeys(data.body.projects);
      yield all([
        put(
          actions.setInProgressAdminProposals({
            projects,
            paging: data.body.paging,
          }),
        ),
      ]);
    }

    // populate proposals filters (for case when req. gave param filters=1)
    if (data.body.filters) {
      const inProgressFilters = {
        filters: {
          work_queue: data.body.filters.work_queue,
          version: data.body.filters.upcoming_version,
          generatedType: data.body.filters.generated_type,
          consultants: data.body.filters.consultants,
        },
      };
      yield put(actions.setInProgressAdminProposalsFilters(inProgressFilters));
    }

    // set propsal counters
    if (data.body.counts) {
      const counts = camelizeKeys(data.body.counts);
      yield put(actions.setAdminPropopsalsCounts(counts));
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ADMIN_PROPOSALS_LIST,
    });
  }
  return null;
}

export default getInProgressAdminProposals;
