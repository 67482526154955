import types from "rdx/modules/ambassador/types";
import createAction from "rdx/utils/createAction";

export default {
  getAmbassadorRegistrationInfo: (payload) => createAction(types.GET_AMBASSADOR_NEW_REGISTRATION_INFO_REQUEST, payload),
  getAmbassadorApplicationAgreement: (payload) =>
    createAction(types.GET_AMBASSADOR_APPLICATION_AGREEMENT_REQUEST, payload),
  setAmbassadorRegistrationInfo: (payload) => createAction(types.SET_AMBASSADOR_NEW_REGISTRATION_INFO, payload),
  setAmbassadorApplicationAgreement: (payload) => createAction(types.SET_AMBASSADOR_APPLICATION_AGREEMENT, payload),
  createAmbassadorUser: (payload) => createAction(types.CREATE_AMBASSADOR_NEW_USER_REQUEST, payload),
  createAmbassadorLead: (payload) => createAction(types.CREATE_AMBASSADOR_LEAD_REQUEST, payload),
  ambassadorZipLookup: (payload) => createAction(types.GET_AMBASSADOR_LEAD_ZIP_LOOKUP_REQUEST, payload),
  setAmbassadorZipLookup: (payload) => createAction(types.SET_AMBASSADOR_LEAD_ZIP_LOOKUP, payload),
  resetAmbassadorZipLookup: (payload) => createAction(types.RESET_AMBASSADOR_LEAD_ZIP_LOOKUP, payload),
  getAmbassadorLeads: (payload) => createAction(types.GET_AMBASSADOR_LEADS_REQUEST, payload),
  setAmbassadorLeads: (payload) => createAction(types.SET_AMBASSADOR_LEADS, payload),
  getStripeSignupUrl: (payload) => createAction(types.GET_STRIPE_SIGNUP_URL_REQUEST, payload),
  getStripeLoginUrl: (payload) => createAction(types.GET_STRIPE_LOGIN_URL_REQUEST, payload),
  getStripeAccountInfo: (payload) => createAction(types.GET_STRIPE_ACCOUNT_INFO_REQUEST, payload),
  setStripeAccountInfo: (payload) => createAction(types.SET_STRIPE_ACCOUNT_INFO, payload),
  // INSERTION_PT (for script, do not remove)
};
