import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const InspectionScheduledIcon = ({ height = 19, width = 19, fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2043_13608)">
        <path
          d="M11.7637 3.33984C9.61504 3.33984 7.86719 5.0877 7.86719 7.23633C7.86719 9.38496 9.61504 11.1328 11.7637 11.1328C13.9123 11.1328 15.6602 9.38496 15.6602 7.23633C15.6602 5.0877 13.9123 3.33984 11.7637 3.33984ZM14.384 6.51678L11.6008 9.29998C11.3846 9.51614 11.0315 9.51826 10.8133 9.29998L9.14338 7.63006C8.92629 7.4126 8.92629 7.06006 9.14338 6.8426C9.36084 6.62551 9.71338 6.62551 9.93084 6.8426L11.207 8.11916L13.5965 5.72932C13.814 5.51223 14.1665 5.51223 14.384 5.72932C14.6011 5.94678 14.6011 6.29932 14.384 6.51678Z"
          fill={fill}
        />
        <path
          d="M11.7636 0C7.77356 0 4.52724 3.24632 4.52724 7.23631C4.52724 9.03129 5.18445 10.676 6.27064 11.9418C6.27067 11.9418 6.27071 11.9418 6.27075 11.9419L5.35396 12.8587L4.96031 12.465C4.74322 12.2476 4.39031 12.2479 4.17322 12.465L0.162817 16.4754C-0.0542724 16.6929 -0.0542724 17.045 0.162817 17.2625L1.73736 18.8371C1.95483 19.0545 2.30736 19.0541 2.52445 18.8371L6.53486 14.8267C6.75232 14.6092 6.75232 14.257 6.53486 14.0396L6.14116 13.6459L7.05795 12.7291C7.05799 12.7291 7.05802 12.7292 7.0581 12.7292C8.3239 13.8154 9.96858 14.4726 11.7636 14.4726C15.7535 14.4726 18.9999 11.2263 18.9999 7.23628C18.9999 3.24628 15.7535 0 11.7636 0ZM11.7636 12.2461C9.00114 12.2461 6.7538 9.99873 6.7538 7.23631C6.7538 4.4739 9.00114 2.22656 11.7636 2.22656C14.526 2.22656 16.7733 4.4739 16.7733 7.23631C16.7733 9.99873 14.526 12.2461 11.7636 12.2461Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2043_13608">
          <rect width={width} height={height} fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InspectionScheduledIcon;
