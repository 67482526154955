import React from "react";
import PropTypes from "prop-types";

const OfferPendingIcon = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.748 13.6983C16.7261 13.6787 16.6945 13.6747 16.6677 13.6871C16.6409 13.6997 16.6233 13.7262 16.6233 13.7561V14.9896H15.7266L14.567 13.375L13.792 14.4537L14.8927 15.9857C15.0107 16.1497 15.2022 16.2479 15.404 16.2479H16.6233V17.4877C16.6233 17.5171 16.6409 17.5438 16.6677 17.5565C16.6945 17.569 16.7261 17.5649 16.748 17.5453L18.9533 15.6796C18.9705 15.6654 18.9797 15.644 18.9797 15.6218C18.9797 15.5996 18.9704 15.5782 18.9533 15.5637L16.748 13.6983Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.2"
      />
      <path
        d="M16.6208 11.0015V12.2412C16.6208 12.2705 16.6385 12.2975 16.6653 12.3099C16.6921 12.3224 16.7237 12.3183 16.7456 12.2989C16.7456 12.2989 18.9336 10.4476 18.9508 10.4331C18.968 10.4185 18.9773 10.3976 18.9773 10.3753C18.9773 10.3531 18.9679 10.332 18.9508 10.3175C18.9337 10.3031 16.7456 8.45153 16.7456 8.45153C16.7238 8.43241 16.6921 8.42827 16.6654 8.44059C16.6386 8.45291 16.6209 8.47975 16.6209 8.50953V9.74329H15.37C15.1683 9.74329 14.9766 9.84137 14.8588 10.0053L11.2805 14.9869H10.2327C10.1311 14.9869 10.0488 15.0692 10.0488 15.1708V16.0613C10.0488 16.1628 10.1311 16.2451 10.2327 16.2451H11.603C11.8048 16.2451 11.9958 16.1469 12.1138 15.983L15.6924 11.0014L16.6208 11.0015Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.2"
      />
      <path
        d="M12.428 12.5462L13.2028 11.4679L12.1459 9.99672C12.0279 9.83264 11.8367 9.73438 11.6348 9.73438H10.2053C10.1038 9.73438 10.0215 9.81668 10.0215 9.91822V10.8087C10.0215 10.9103 10.1038 10.9926 10.2053 10.9926H11.312L12.428 12.5462Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.2"
      />
      <path
        d="M26.1423 15.2346L24.5907 14.9184C24.5574 14.9116 24.5225 14.9184 24.4937 14.9369C24.4649 14.9557 24.4449 14.9857 24.4381 15.0189C24.2859 15.7652 24.0478 16.4982 23.7298 17.1975C23.6912 17.2812 23.6516 17.3662 23.611 17.4493C22.4221 19.8874 20.3549 21.7165 17.7904 22.5992C16.7141 22.9701 15.5986 23.1577 14.4761 23.1577C12.933 23.1577 11.4384 22.8096 10.0329 22.1242C7.75643 21.0141 6.00493 19.1374 5.07143 16.8174L7.19621 17.8535C7.22707 17.8685 7.26264 17.8708 7.29497 17.8594C7.3273 17.8485 7.35405 17.825 7.36904 17.7938L8.06329 16.3706C8.09444 16.3063 8.06799 16.229 8.00362 16.1972L3.31611 13.9114C3.25204 13.8805 3.17474 13.9067 3.14329 13.9705L2.79469 14.6812L1.32948 17.6645C1.32595 17.6713 1.32331 17.6789 1.32125 17.6862L0.843328 18.6621C0.811878 18.7264 0.838331 18.8038 0.902407 18.8355L2.32441 19.5324C2.35498 19.548 2.39084 19.5497 2.42317 19.5386C2.4555 19.5274 2.48225 19.5042 2.49753 19.473L3.41076 17.6101C3.79903 18.543 4.299 19.4189 4.89978 20.2175C6.0455 21.7412 7.50071 22.9399 9.22488 23.7808C10.6801 24.4903 12.2314 24.8939 13.8357 24.98C14.0508 24.9914 14.2692 24.9979 14.4838 24.9979C15.8108 24.9979 17.1247 24.7769 18.3897 24.3413C19.8626 23.8343 21.2023 23.0607 22.3718 22.041C23.5831 20.9858 24.5569 19.7126 25.267 18.2573C25.3146 18.1592 25.3616 18.0592 25.4066 17.9599C25.7819 17.1348 26.0638 16.2692 26.2437 15.3869C26.2578 15.3169 26.2125 15.2484 26.1423 15.2346Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.4"
      />
      <path
        d="M28.1639 7.22561C28.153 7.19328 28.1295 7.16653 28.0984 7.15125L26.6781 6.45112C26.6473 6.43584 26.612 6.43349 26.5794 6.44466C26.5467 6.45582 26.5203 6.47934 26.5047 6.50991L25.5683 8.41042C25.0695 7.2021 24.3811 6.09136 23.5205 5.10524C22.4662 3.89721 21.195 2.9252 19.7424 2.21685C18.2872 1.50702 16.7358 1.10346 15.1313 1.01734C14.9162 1.00588 14.6981 1 14.4835 1C13.1564 1 11.8423 1.22074 10.5772 1.65633C9.10439 2.16365 7.76468 2.93755 6.59516 3.95659C5.38419 5.01207 4.41012 6.28505 3.70029 7.74057C3.25794 8.64762 2.93109 9.60434 2.72917 10.5843C2.72241 10.6178 2.72888 10.6528 2.74769 10.6816C2.7665 10.7104 2.79589 10.7304 2.82969 10.7371L4.38073 11.0569C4.45068 11.0713 4.51917 11.0263 4.53357 10.9564C4.70463 10.1272 4.98122 9.31688 5.35626 8.54827C6.54519 6.11046 8.61265 4.28137 11.1771 3.39842C12.2535 3.02778 13.3686 2.83997 14.4917 2.83997C16.0342 2.83997 17.5291 3.18768 18.9344 3.87311C21.1817 4.96915 22.9235 6.82646 23.8697 9.12613L21.8013 8.12914C21.7367 8.09827 21.6597 8.12502 21.6285 8.18968L20.9407 9.61639C20.9099 9.68076 20.9369 9.75807 21.0013 9.78893L25.6864 12.0471C25.7049 12.0557 25.7238 12.0598 25.7426 12.0598C25.7902 12.0598 25.836 12.0333 25.8587 11.9875L28.1566 7.32496C28.1727 7.2938 28.1748 7.25824 28.1639 7.22561Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.4"
      />
    </svg>
  );
};

OfferPendingIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

OfferPendingIcon.defaultProps = {
  fill: "var(--eggplant)",
  width: "29",
  height: "26",
};

export default OfferPendingIcon;
