// DEPRECATED, FAVOR SMART FORM

/* eslint-disable import/prefer-default-export */
import { useState, useReducer, useEffect } from "react";

/**
 * @typedef {Object} FieldOptions
 * @property {any} initialValue Initial value
 * @property {any} schema Yup validation schema
 * @property {function} validate Validation handler returning an error string, array of error strings, or null
 * @property {{change: boolean, blur: boolean}} validateOn Define when to run validation. Overrides the form's setting. Default { change: true, blur: true }.
 * @property {function} onChange onChange event handler. Defining onChange means you take all responsibility for updating the field's value.
 * @property {function} onBlur onBlur event handler
 */

/**
 * @typedef {Object} FormOptions
 * @property {function} validate Validation handler returning an error string, array of error strings, or null
 * @property {{change: boolean, blur: boolean, init: boolean}} validateOn Define when to run validation function for all fields. Default { change: true, blur: true, init: true }.
 * @property {function} onSubmit onSubmit event handler
 * @property {function} onReset onReset event handler
 * @property {boolean} scrollToErrors on longer forms scrolls up to first invalid field
 */

/**
 * @typedef {Object<string, FieldBagItem>} FieldBag
 */

/**
 * @typedef {Object} FieldBagItem
 * @property {{name, value, onChange, onBlur}} props Props to attach to the target DOM element
 * @property {string[]} errors Array of error messages
 * @property {any} value Current value
 * @property {function} setValue Imperatively set the value of a field
 * @property {boolean} isValid Whether the field has passed validation
 * @property {function} validate Asynchronously run the field validation
 * @property {function} setTouched imperatively set field as touched,
 * @property {boolean} touched Whether the field has been modified or visited by the user
 * @property {boolean} visited Whether the field has been visited by the user. (A visit means focusing the field, and then unfocusing it).
 * @property {function} reset Reset the field to its default value
 */

/**
 * @typedef {Object} FormBag
 * @property {{onSubmit, onReset}} props Props to attach to the target DOM element
 * @property {string[]} errors Array of error messages
 * @property {Object<string, any>} values Dictionary of field values keyed by name
 * @property {boolean} isValid Whether the form and all fields have passed validation
 * @property {function} validate Asynchronously validate the form and all fields
 * @property {function} resetForm reset all fields to initial values
 * @property {boolean} touched Whether any form fields have been touched by the user
 * @property {boolean} visited Whether any form fields been visited by the user
 * @property {boolean} submitted Whether the form has previously been submitted
 * @property {boolean} isSubmitting Whether the form is currently submitting data
 * @property {function} setSubmitting Imperatively set the form's submitting status
 */

/**
 * @typedef {Array} UseFormReturnTuple
 * @param {FormBag} 0 - form object
 * @param {FieldBag} 1 - fields keyed by name
 */

/**
 * Hook to create a new form with fields
 * @function useForm
 * @param {Object<string, FieldOptions>} fields Dictionary of fields keyed by name
 * @param {FormOptions} options Form options
 * @returns {UseFormReturnTuple} Tuple containing form and fields keyed by name
 */

export function useForm(fields, options) {
  // Aliases
  const fieldOptionsList = fields;
  const formOptions = options;

  // Field states
  const [fieldStates, fieldDispatch] = useReducer(fieldReducer, fieldOptionsList, initializeFields);

  // Form state
  const [errors, setErrors] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  // Computed props
  const values = getFieldValues(fieldStates);
  const fieldErrors = getFieldErrors(fieldStates);
  const touched = getAnyFieldsTouched(fieldStates);
  const visited = getAnyFieldsVisited(fieldStates);
  const isValid = errors.length === 0 && fieldErrors.length === 0;

  // Build callbacks
  const validate = async () => {
    const fieldsValid = await Promise.all(Object.keys(fieldOptionsList).map(async (name) => fieldBag[name].validate()));
    let formValid = true;
    if (formOptions.validate) {
      const data = { fields: fieldBag, form: formBag };
      const err = formOptions.validate(values, data);
      if (err) {
        formValid = false;
        if (Array.isArray(err)) {
          setErrors(err);
        } else {
          setErrors([err]);
        }
      }
      setErrors([]);
    }
    return formValid && fieldsValid.every((fieldValid) => fieldValid === true);
  };

  const resetForm = () => {
    Object.keys(fieldOptionsList).map((name) => fieldBag[name].reset());
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    Object.keys(fieldOptionsList).forEach(async (name) => {
      fieldDispatch({
        type: FIELD.SET_TOUCHED,
        payload: { field: name, touched: true },
      });
      fieldDispatch({
        type: FIELD.SET_VISITED,
        payload: { field: name, visited: true },
      });
    });
    const validationPassed = await validate();
    if (formOptions.scrollToErrors && !validationPassed) {
      Object.entries(fieldBag)
        .reverse()
        .forEach((f) => {
          const field = f[1];
          if (field.errors?.length) {
            const element = document.getElementById(`${field.props.name}-error`);
            element.scrollIntoView({ behavior: "smooth" });
          }
        });
    }
    if (validationPassed && formOptions.onSubmit) {
      setSubmitted(true);
      const data = { fields: fieldBag, form: formBag };
      formOptions.onSubmit(values, data);
    }
  };

  const onReset = async (e) => {
    e.preventDefault();
    fieldDispatch({ type: FIELD.INIT_ALL, payload: { fieldOptionsList } });
    setErrors([]);
    if (formOptions.onReset) {
      const data = { fields: fieldBag, form: formBag };
      formOptions.onReset(data);
    }
  };

  useEffect(() => {
    if (formOptions.validateOn?.init !== false) {
      validate();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // These props go on the DOM element
  const props = { onSubmit, onReset };
  const formBag = {
    props,
    errors,
    values,
    isValid,
    validate,
    resetForm,
    touched,
    visited,
    submitted,
    isSubmitting,
    setSubmitting,
  };
  const fieldBag = buildFieldBag({
    fieldOptionsList,
    formOptions,
    fieldStates,
    fieldDispatch,
    formBag,
  });
  return [formBag, fieldBag];
}

function buildFieldBag({ fieldOptionsList, formOptions, fieldStates, fieldDispatch, formBag }) {
  const fieldBag = {};
  Object.entries(fieldOptionsList).forEach(([name, fieldOptions]) => {
    // Field state
    const { value, errors, touched, visited } = fieldStates[name];

    // Computed props
    const isValid = errors.length === 0;
    const validateOn = {
      ...(formOptions.validateOn || {}),
      ...(fieldOptions.validateOn || {}),
    };

    // Build callbacks
    const validate = async (currentValue = fieldStates[name].value) => {
      let newErrors = [];
      if (fieldOptions.validate) {
        const data = { fields: fieldBag, form: formBag };
        const err = fieldOptions.validate(currentValue, data);
        if (err) {
          if (Array.isArray(err)) {
            newErrors = err;
          } else {
            newErrors = [err];
          }
        }
      }
      if (fieldOptions.schema?.validate) {
        try {
          await fieldOptions.schema.validate(currentValue, {
            abortEarly: false,
          });
        } catch (e) {
          e.errors.forEach((error) => {
            newErrors.push(error);
          });
        }
      }
      fieldDispatch({
        type: FIELD.SET_ERRORS,
        payload: { field: name, errors: newErrors },
      });
      return !newErrors.length;
    };

    const onChange = (e) => {
      fieldDispatch({
        type: FIELD.SET_TOUCHED,
        payload: { field: name, touched: true },
      });
      if (e === undefined) {
        setValue(fieldOptions.initialValue);
      } else if (fieldOptions.onChange) {
        const data = { fields: fieldBag, form: formBag };
        fieldOptions.onChange(e, data);
      } else {
        let newValue;
        // Use target.value if e is an Event (or similar) object, otherwise assume e itself is the new value
        if (Object.prototype.hasOwnProperty.call(e, "target")) {
          newValue = e.target.value;
        } else {
          newValue = e;
        }
        setValue(newValue);
      }
    };

    const onBlur = (e) => {
      fieldDispatch({
        type: FIELD.SET_TOUCHED,
        payload: { field: name, touched: true },
      });
      fieldDispatch({
        type: FIELD.SET_VISITED,
        payload: { field: name, visited: true },
      });
      if (fieldOptions.onBlur) {
        const data = { fields: fieldBag, form: formBag };
        fieldOptions.onBlur(e, data);
      }
      if (validateOn.blur !== false) {
        validate();
      }
    };

    const setValue = (newValue) => {
      fieldDispatch({
        type: FIELD.SET_VALUE,
        payload: { field: name, value: newValue },
      });
      if (validateOn.change !== false) {
        validate(newValue);
      }
    };

    const reset = () => {
      fieldDispatch({
        type: FIELD.RESET,
        payload: { field: name, initialValue: fieldOptions.initialValue },
      });
    };

    const setTouched = (boolean) => {
      fieldDispatch({
        type: FIELD.SET_TOUCHED,
        payload: { field: name, touched: boolean },
      });
      fieldDispatch({
        type: FIELD.SET_VISITED,
        payload: { field: name, visited: boolean },
      });
    };

    // These props go on the DOM element
    const props = { name, value, onChange, onBlur };
    fieldBag[name] = {
      props,
      errors,
      value,
      isValid,
      validate,
      touched,
      visited,
      setValue,
      setTouched,
      reset,
    };
  });
  return fieldBag;
}

/* ====== Reducer Helpers ====== */
function initializeFields(fieldOptionsList) {
  const state = {};
  Object.entries(fieldOptionsList).forEach(([field, { initialValue }]) => {
    state[field] = fieldDefaultState(initialValue);
  });
  return state;
}

function fieldDefaultState(initialValue) {
  return {
    value: initialValue,
    errors: [],
    touched: false,
    visited: false,
  };
}

const FIELD = {
  INIT_ALL: "init-all",
  RESET: "reset",
  SET_VALUE: "set-value",
  SET_ERRORS: "set-errors",
  SET_TOUCHED: "set-touched",
  SET_VISITED: "set-visited",
};
function fieldReducer(state, { type, payload }) {
  const field = payload?.field;
  if (type === FIELD.INIT_ALL) {
    return initializeFields(payload.fieldOptionsList);
  }
  if (!field) return state;
  switch (type) {
    case FIELD.RESET:
      return {
        ...state,
        [field]: fieldDefaultState(payload.initialValue),
      };
    case FIELD.SET_VALUE:
      return {
        ...state,
        [field]: {
          ...(state[field] || {}),
          value: payload.value,
        },
      };
    case FIELD.SET_ERRORS:
      return {
        ...state,
        [field]: {
          ...(state[field] || {}),
          errors: payload.errors,
        },
      };
    case FIELD.SET_TOUCHED:
      return {
        ...state,
        [field]: {
          ...(state[field] || {}),
          touched: payload.touched,
        },
      };
    case FIELD.SET_VISITED:
      return {
        ...state,
        [field]: {
          ...(state[field] || {}),
          visited: payload.visited,
        },
      };
    default:
      return state;
  }
}

function getFieldValues(fieldStates) {
  const values = {};
  Object.entries(fieldStates).forEach(([field, { value }]) => {
    values[field] = value;
  });
  return values;
}

function getFieldErrors(fieldStates) {
  const errors = [];
  Object.values(fieldStates).forEach((field) => {
    field.errors.forEach((error) => {
      errors.push(error);
    });
  });
  return errors;
}

function getAnyFieldsTouched(fieldStates) {
  return !!Object.values(fieldStates).find((field) => field.touched);
}

function getAnyFieldsVisited(fieldStates) {
  return !!Object.values(fieldStates).find((field) => field.visited);
}
