import React, { useEffect, useState, FC } from "react";

import { Row, Col } from "antd";
import SessionClient from "util/SessionClient";
import useNavigation from "hooks/useNavigation";

import { CloseIcon, WarningIconGradient } from "components/Icons";
import dayjs from "dayjs";
import ModalContainer from "../ModalContainer";

import styles from "./PaymentModal.module.less";

type Service = {
  name: string;
};

type Enrollment = {
  payment_status: string;
  current_period_start: string;
  service: Service;
};

const PaymentModal: FC = () => {
  const [show, setShow] = useState(false);
  const [touched, setTouched] = useState(false);
  const [subscription, setSubscription] = useState<string | null>(null);
  const [daysLeft, setDaysLeft] = useState(30);
  const { navigate } = useNavigation("account", ["tab"]);

  const session = new SessionClient();
  const { enrollments } = session;

  useEffect(() => {
    const pastDueEnrollment = enrollments?.filter(
      (enrollment: Enrollment) => enrollment?.payment_status === "past_due",
    )[0];
    if (pastDueEnrollment?.current_period_start) {
      setDaysLeft(30 - dayjs().diff(dayjs(pastDueEnrollment.current_period_start), "day"));
      setShow(!!pastDueEnrollment);
      setSubscription(pastDueEnrollment.service.name);
    }
  }, [enrollments]);

  const handleCancel = () => {
    setShow(false);
    setTouched(true);
  };

  const handleChooseClick = () => {
    setShow(false);
    setTouched(true);
    navigate({ tab: "subscriptions" });
  };

  return (
    <ModalContainer
      modalClassName={styles.paymentModal}
      title="Update Your Payment Method"
      visible={show && !touched}
      width={680}
      withoutCancel
      maskClosable={false}
      maskBackground="rgba(11, 47, 95, 0.75)"
      headerClassName={styles.header}
      centerButtons
      submitText="Update Payment"
      headerIcon={<CloseIcon startFill="white" stopFill="white" height={16} width={16} />}
      onClose={handleCancel}
      handleSubmit={handleChooseClick}
    >
      <Row className={styles.contentRow} align="middle" justify="center">
        <Col>
          <WarningIconGradient height={70} width={70} />
        </Col>
        <Col>
          <span>Hello {session.user?.props.firstName}</span>
        </Col>
        <Col>
          <span>
            We weren’t able to process the payment for your {subscription} Subscription. To Powur back on, please update
            your method by clicking on the button below! You have {daysLeft} days left to resolve this payment issue.
          </span>
        </Col>
      </Row>
    </ModalContainer>
  );
};

export default PaymentModal;
