import React from "react";
import StaticDateTimePicker from "components/mui-styled-components/StaticDateTimePicker";

import * as S from "./styles";

type DatePickerModalT = {
  isDateModalOpen: boolean;
  setIsDateModalOpen: (bool: boolean) => void;
  handleSave: (selectedTimeStamp: string) => void;
  hideTime?: boolean;
  dateFormat?: string;
};

const DatePickerModal = ({
  isDateModalOpen,
  setIsDateModalOpen,
  handleSave,
  hideTime = false,
  dateFormat = "YYYY-MM-DD HH:mm:ss",
}: DatePickerModalT) => {
  const handleClose = () => {
    setIsDateModalOpen(false);
  };

  return (
    <S.StyledModal open={isDateModalOpen}>
      <StaticDateTimePicker handleSave={handleSave} onClose={handleClose} hideTime={hideTime} dateFormat={dateFormat} />
    </S.StyledModal>
  );
};

export default DatePickerModal;
