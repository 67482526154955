import React from "react";
import { Grid, Box } from "@mui/material";

import { FiltersWrapper, FilterWrapper, FilterOption, FilterOptions, FilterTitle, EllipsisText } from "./styles";
import { FiltersT } from "./types";

type FiltersListPropsT = {
  mobile: boolean;
  horizontalScrollContainer: React.RefObject<HTMLDivElement>;
  handleScroll: () => void;
  handleSelection: (key: string, value: Record<string, unknown>, multiSelect?: boolean) => void;
  handleDelete: (key: string, id: string | number) => void;
  checkIfSelected: (key: string, id: string | number, multislected?: boolean) => boolean;
  preferredView: { filters: unknown[] };
  sortItems: FiltersT;
};

const FiltersList = ({
  mobile,
  horizontalScrollContainer,
  handleScroll,
  handleDelete,
  handleSelection,
  checkIfSelected,
  preferredView,
  sortItems,
}: FiltersListPropsT) => {
  return (
    <FiltersWrapper
      isMobile={mobile}
      ref={horizontalScrollContainer}
      onScroll={handleScroll}
      sx={{ marginBottom: "252px" }}
    >
      {sortItems.filters.length > 0 &&
        sortItems.filters.map((filter) => (
          <FilterWrapper isMobile={mobile} key={`${filter.paramKey}`}>
            <Grid container p={0}>
              <Grid item xs={12} pt={mobile ? 2 : 0} pb={mobile ? 2 : 0}>
                <FilterTitle>
                  {filter.label} /
                  {preferredView?.filters[filter.paramKey] ? preferredView.filters[filter.paramKey]?.length || 1 : 0}
                </FilterTitle>
              </Grid>
              <Grid item xs={12}>
                <FilterOptions isMobile={mobile}>
                  {filter.options &&
                    filter.options.length > 0 &&
                    filter.options.map((option, indx) => {
                      const isSelected = checkIfSelected(option.customKey || filter.paramKey, option.id);
                      const isDisabled = option.disableOtherOptions && isSelected && option.customKey;

                      if (option.component) {
                        let defaultValue = [];
                        if (Array.isArray(preferredView?.filters[option.customKey || filter.paramKey])) {
                          defaultValue = preferredView?.filters[option.customKey || filter.paramKey].map(
                            (el) => el.value,
                          );
                        }
                        return (
                          <Box key={option.id} style={{ marginTop: (!mobile && indx === 0 && "10px") || 0 }}>
                            {option.component({
                              paramKey: option.customKey || filter.paramKey,
                              handleSelection,
                              handleDelete,
                              isSelected,
                              isMobile: mobile,
                              defaultValue,
                            })}
                          </Box>
                        );
                      }

                      return (
                        <FilterOption
                          style={{ marginTop: (!mobile && indx === 0 && "10px") || 0 }}
                          isMobile={mobile}
                          isSelected={isSelected}
                          key={option.id}
                          onClick={() => {
                            if (!isDisabled) {
                              if (!isSelected) handleSelection(filter.paramKey, option, filter.multiselect);
                              else handleDelete(filter.paramKey, option.id);
                            }
                          }}
                        >
                          <EllipsisText>{option.label}</EllipsisText>
                        </FilterOption>
                      );
                    })}
                </FilterOptions>
              </Grid>
            </Grid>
          </FilterWrapper>
        ))}
    </FiltersWrapper>
  );
};

export default FiltersList;
