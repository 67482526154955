import { ActivityLogDataT, ProjectActivityBlockerDataT } from "rdx/modules/projects/types/projects/pre-sales";
import { CrmActivityT } from "types/Project/crmActivity";
import removeCoBlockerFromModalList from "./removeCoBlockerFromModalList";

type FormattedDataReturnT = {
  updatedHistoricalActivityLog: ActivityLogDataT[];
  updatedCrmActivities: CrmActivityT[];
};

const formatCompletedBlocker = (
  crmActivities: CrmActivityT[],
  historicalActivityLog: ActivityLogDataT[],
): FormattedDataReturnT => {
  const { updatedCrmActivities, completedActivity } = removeCoBlockerFromModalList(crmActivities);
  const updatedHistoricalActivityLog = [...historicalActivityLog];

  const coBlockerDescriptions = {
    change_order_required: "Change Order Approved",
    buildable_with_changes: "Project Change Approved",
  };

  if (completedActivity.slug) {
    // @ts-expect-error TSFixMe: missing status, coBlockerActivity
    const newActivityLogItem: ProjectActivityBlockerDataT = {
      id: completedActivity.id,
      title: completedActivity.title,
      description: coBlockerDescriptions[completedActivity.slug],
      activityDate: new Date().toISOString(),
      type: "blocker",
    };

    updatedHistoricalActivityLog.unshift(newActivityLogItem);
  }
  return {
    updatedHistoricalActivityLog,
    updatedCrmActivities,
  };
};

export default formatCompletedBlocker;
