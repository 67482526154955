import { z } from "zod";
import dayjs from "dayjs";

const activityEnum = z.enum(["call", "email", "meeting", "reminder"]);

export const schema = z
  .object({
    kind: activityEnum,
    title: z.string().min(1, { message: "Title is required." }),
    meetingType: z.string().nullish(),
    meetingInfo: z.string(),
    location: z.string(),
    email: z.string(),
    dueDate: z.string(),
    dueTime: z.string().refine((time) => dayjs(time, "YYYY-MM-DD HH:mm:ss").isValid(), {
      message: "Invalid time",
    }),
    reminder: z.string(),
    reminderType: z.object({
      email: z.boolean(),
      sms: z.boolean(),
    }),
    notes: z.string(),
    homeownerAvailability: z
      .object({
        id: z.number().nullish(),
        days: z.string().array().nullish(),
        startTime: z.string().nullish(),
        endTime: z.string().nullish(),
      })
      .array()
      .nullish(),
  })
  .refine((data) => data.kind !== "email" || data.notes.length, {
    message: "Email body is required.",
    path: ["notes"],
  })
  .refine((data) => data.kind !== "meeting" || data.meetingType, {
    message: "Meeting type is required.",
    path: ["meetingType"],
  });

export const blankReminderTypeData = { email: true, sms: true };

export const blankFormData = {
  kind: activityEnum.enum.call,
  title: "",
  meetingType: "",
  meetingInfo: "",
  email: "",
  // template: "",
  location: "",
  dueDate: dayjs().format("YYYY-MM-DD"),
  dueTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
  reminder: "atDueTime",
  reminderType: blankReminderTypeData,
  notes: "",
  homeownerAvailability: [],
};
