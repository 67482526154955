import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import styles from "../../EnterpriseJoin.module.less";

const VideoBanner = (props) => {
  const { openModal, org } = props;
  const { t } = useTranslation(["enterprise_seller_join"]);
  return (
    <div className={styles.solarIntro}>
      <div
        className={styles.backgroundImage}
        style={{
          backgroundImage: "url(/img/enterprise/img_ent_header.png)",
        }}
      />
      <div className={styles.innerContainer}>
        <p className={styles.bannerHeader}>
          {org}&nbsp;{t("banner.title")}
        </p>
        <p className={styles.subHeading}>
          {t("banner.sub_title")}&nbsp;{org}.
        </p>
        <Button
          data-test-id="VideoBanner-e-seller-join-btn"
          onClick={openModal}
          className={styles.bannerButton}
          type="primary"
        >
          {t("banner.join_powur_enterprise")}
          &nbsp;{org}
        </Button>
      </div>
    </div>
  );
};

VideoBanner.propTypes = {
  openModal: PropTypes.func,
  org: PropTypes.string,
};

export default VideoBanner;
