import { put, all, select } from "redux-saga/effects";
import selectors from "rdx/modules/workSchedules/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "rdx/modules/workSchedules/messages";

function* updateWorkSchedules(action) {
  const payload = {
    ...(action.payload.hours ? { hours: action.payload.hours } : {}),
    ooo_start: action.payload.oooStart,
    ooo_end: action.payload.oooEnd,
  };

  const { success, data, error } = yield* makeRequest.patch(
    `/work_schedules/${action.payload.workScheduleId}`,
    payload,
  );

  if (success && data) {
    const workSchedules = yield select(selectors.getSchedulesByTeam);
    const schedule = camelizeKeys(data.body);
    const newSchedules = workSchedules.map((oldSchedule) => {
      if (oldSchedule.id === schedule.id) return schedule;
      return oldSchedule;
    });
    yield all([put(actions.setWorkSchedules(newSchedules))]);
  } else if (error) {
    const errorMessage = messages.ERROR_UPDATING_WORK_SCHEDULES;
    return getErrorActions({
      error,
      message: errorMessage,
    });
  }

  return null;
}

export default updateWorkSchedules;
