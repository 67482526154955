import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/fundingProposals/types";
import getAdminFundingProposals from "./getAdminFundingProposals";
import createFundingProposal from "./createFundingProposal";
import getFundingUsersAutocomplete from "./getFundingUsersAutocomplete";
import updateFundingProposal from "./updateFundingProposal";
// IMPORT_PT (for script, do not remove)

function* watchFundingProposalsSagas() {
  yield trackRequests(takeEvery, types.GET_ADMIN_FUNDING_PROPOSALS, getAdminFundingProposals);
  yield trackRequests(takeEvery, types.CREATE_FUNDING_PROPOSAL_REQUEST, createFundingProposal);
  yield trackRequests(takeEvery, types.GET_FUNDING_USERS_AUTOCOMPLETE, getFundingUsersAutocomplete);
  yield trackRequests(takeEvery, types.UPDATE_FUNDING_PROPOSAL_REQUEST, updateFundingProposal);
  // INSERTION_PT (for script, do not remove)
}

export default watchFundingProposalsSagas;
