import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import SidebarFilters from "components/PlatformLayout/components/Sidebar/Filters";
import SidebarSearch from "components/PlatformLayout/components/Sidebar/Search";
import SidebarAutoComplete from "components/PlatformLayout/components/Sidebar/AutoComplete";
import Project from "models/Project";
import ProviderLead from "models/ProviderLead";
import StorageClient from "util/StorageClient";

const ProjectsSidebar = (props) => {
  const {
    navigate,
    filters: { filters, sorts },
    suggestions,
    search,
    setSuggestions,
    resetSuggestions,
    customSearchPopover,
  } = props;

  const storage = new StorageClient();
  const selects = useMemo(() => {
    const out = _.filter(filters, (f) => _.isArray(f.values));
    if (sorts && !_.isEmpty(sorts)) {
      out.push({
        name: "sort",
        values: sorts,
        default: sorts[0], // DEFAULT SORT will always be the FIRST item returned from the backend.
      });
    }
    return out;
  }, [filters, sorts]);

  const lookups = useMemo(() => {
    const fields = _.filter(filters, (f) => _.isString(f.values) && f.values.includes("/json_web"));
    return fields.map((f) => {
      return (
        <ProjectsAutoComplete
          key={f.name}
          navigate={navigate}
          search={search}
          lookupField={f}
          suggestions={suggestions[f.name]}
          setSuggestions={setSuggestions}
          resetSuggestions={resetSuggestions}
          usersDisplay={f.name !== "city_name"}
          userCitiesDisplay={f.name === "city_name"}
        />
      );
    });
  }, [filters, suggestions, search]); // eslint-disable-line react-hooks/exhaustive-deps

  const filterKeys = useMemo(() => selects.map((s) => s.name), [selects]);
  const filterMenus = useMemo(() => {
    const label = (s, i) => {
      if (s.label) return s.label;
      return filterKeys[i].includes("_id") ? _.startCase(filterKeys[i].replace("_id", "")) : _.startCase(filterKeys[i]);
    };

    return selects.map((s, i) => {
      let customLabel = null;
      if (s.name === "milestone_name") customLabel = "Milestone";

      const out = {
        key: filterKeys[i],
        label: customLabel === null ? label(s, i) : customLabel,
        options: s.values.map((v) => ({
          key: _.isPlainObject(v) ? _.keys(v)[0] : v,
          label: _.isPlainObject(v) ? _.values(v)[0] : _.startCase(v),
          parentKey: filterKeys[i],
          default: s.default === v,
          index: i,
        })),
      };

      if (!s.default) {
        out.options.unshift({
          key: `${filterKeys[i]}-any`,
          label: "All",
          parentKey: filterKeys[i],
          default: true,
          index: i,
        });
      }

      return out;
    });
  }, [selects, filterKeys]);

  const onSelect = (key, value) => {
    let storedFilters = { ...storage?.projectFilters.projects };
    if (value) {
      storedFilters = { ...storedFilters, [key]: value };
    } else {
      delete storedFilters[key];
    }
    storage.setStorage({ projects: storedFilters }, "projectFilters");
  };

  // this is temp solution for pre and post sales
  const projectTypeFilter = {
    key: "project_type",
    label: "Project Type",
    options: [
      {
        default: true,
        index: 0,
        key: "all",
        label: "All",
        parentKey: "project_type",
      },
      {
        default: false,
        index: 0,
        key: "pre_sales",
        label: "Pre Sales",
        parentKey: "project_type",
      },
      {
        default: false,
        index: 0,
        key: "post_sales",
        label: "Post Sales",
        parentKey: "project_type",
      },
    ],
  };

  return (
    <>
      <SidebarSearch navigate={navigate} customSearchPopover={customSearchPopover} />
      {filterMenus.length > 0 && (
        <SidebarFilters menus={[...filterMenus, projectTypeFilter]} navigate={navigate} onSelect={onSelect} />
      )}
      {lookups}
    </>
  );
};

const ProjectsAutoComplete = ({
  navigate,
  lookupField,
  suggestions,
  resetSuggestions,
  setSuggestions,
  search,
  usersDisplay,
  userCitiesDisplay,
}) => {
  const { name, values, label } = lookupField;

  const users = useMemo(() => {
    if (suggestions && usersDisplay) {
      return _.uniqBy(
        suggestions.map((u) => ({
          displayValue: u.fullName,
          key: u.id,
          data: u,
        })),
        "key",
      );
    }
    if (suggestions && userCitiesDisplay) {
      return _.uniqBy(
        suggestions.map((u) => ({
          displayValue: (
            <span>
              {u.city}, {u.state}
            </span>
          ),
          key: u.id,
          data: u,
        })),
        "key",
      );
    }

    return [];
  }, [suggestions, userCitiesDisplay, usersDisplay]);

  const generateLabel = () => {
    if (label) return label;

    // TODO: This needs to go... should only live in BE... just update the names/descriptions!
    const updatedLabel = name.includes("_id") ? _.startCase(name.replace("_id", "")) : _.startCase(name);
    return updatedLabel.includes("Mentor")
      ? _.startCase(updatedLabel.replace("Mentor", "Sales Partner"))
      : _.startCase(name);
  };

  return (
    <>
      <SidebarAutoComplete
        key={name}
        fieldKey={name}
        dataKey="id"
        label={generateLabel()}
        requestUrl={values.replace("/json_web", "")}
        suggestions={users}
        successActions={[setSuggestions]}
        resetSuggestions={() => resetSuggestions({ key: name, suggestions: null })}
        navigate={navigate}
        usersDisplay={usersDisplay}
        userCitiesDisplay={userCitiesDisplay}
        search={search}
      />
    </>
  );
};

ProjectsSidebar.propTypes = {
  navigate: PropTypes.func.isRequired,
  filters: PropTypes.oneOfType([Project.filterTypes(), ProviderLead.filterTypes()]).isRequired,
  suggestions: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        fullName: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        vanityName: PropTypes.string,
        subscriptionStatus: PropTypes.string,
        locale: PropTypes.string,
        avatar: PropTypes.shape({
          large: PropTypes.string,
          thumb: PropTypes.string,
          retina: PropTypes.string,
          preview: PropTypes.string,
        }),
      }),
    ),
  ),

  search: PropTypes.string,
  setSuggestions: PropTypes.func.isRequired,
  resetSuggestions: PropTypes.func.isRequired,
  customSearchPopover: PropTypes.node,
};

export default ProjectsSidebar;
