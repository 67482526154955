// React
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Ant Design
import { Layout } from "antd";

// Util
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

// Components
import { Header, Footer, GetQuoteModal, UtilityBillModal, SuccessModal } from "components/HomepageComponents";
import { theme } from "styles/themes";
import ScrollToTop from "containers/router/scrollToTop";
import SetterJoinHeroImage from "./components/SetterJoinHeroImage";
import ContentRow from "./components/ContentRow";
import EarningInfographic from "./components/EarningInfographic";
import GetSignedUp from "./components/GetSignedUp";
import SellerTestimonials from "./components/SellerTestimonials";

// CSS Modules
import styles from "./Join.module.less";

const { Content } = Layout;

type JoinPropsT = {
  user?: {
    avatar: {
      thumb: string;
      preview: string;
      large: string;
      retina: string;
    };
    email: string;
    facebook_pixel_id: string | null;
    first_name: string;
    full_name: string;
    phone: string;
    vanity_name: string;
  };
  params: {
    user: string | undefined;
    slug: string | undefined;
  };
};

type ContentDataT = {
  isRightAligned: boolean;
  backgroundImagePath: string;
  secondaryImagePath: string | null;
  headerText: string;
  subheaderText: string;
  detailHeaderText: string;
  highlightDetailHeader: boolean;
  detailImage: `/${string}.png` | JSX.Element | null;
  detailSubheaderText: string | null;
  detailBackgroundColor: string | null;
  followUpComponent: JSX.Element | null;
};

const Join = ({ user, params }: JoinPropsT) => {
  const { slug } = params;
  const { t } = useTranslation(["setter_join"]);

  const history = useHistory();
  const [sponsor, setSponsor] = useState<string | null>(null);

  useEffect(() => {
    if (user) setSponsor(user.vanity_name);
  }, [user]);

  const handleCreateAccount = () => {
    history.push(`${sponsor ? `/${sponsor}` : ""}/signup${slug ? `/${slug}` : ""}`);
  };

  const contentData: ContentDataT[] = [
    {
      isRightAligned: false,
      backgroundImagePath: "/img/setterJoin/join_RevolutionaryCompensation.png",
      secondaryImagePath: null,
      headerText: t("content.compensation_header"),
      subheaderText: t("content.compensation_subheader"),
      detailHeaderText: t("content.compensation_detail_header"),
      highlightDetailHeader: false,
      detailImage: "/img/setterJoin/join_FastPay.png",
      detailSubheaderText: t("content.compensation_detail_subheader"),
      detailBackgroundColor: theme.colors["blue-ribbon"],
      followUpComponent: null,
    },
    {
      isRightAligned: true,
      backgroundImagePath: "/img/setterJoin/join_UndeniableTrackRecord.png",
      secondaryImagePath: "/img/setterJoin/join_Inc5000.png",
      headerText: t("content.track_record_header"),
      subheaderText: t("content.track_record_subheader"),
      detailHeaderText: t("content.track_record_detail_header"),
      highlightDetailHeader: true,
      detailImage: null,
      detailSubheaderText: t("content.track_record_detail_subheader"),
      detailBackgroundColor: null,
      followUpComponent: null,
    },
    {
      isRightAligned: false,
      backgroundImagePath: "/img/setterJoin/join_InnovativeTechnology.png",
      secondaryImagePath: null,
      headerText: t("content.innovative_header"),
      subheaderText: t("content.innovative_subheader"),
      detailHeaderText: t("content.innovative_detail_header"),
      highlightDetailHeader: false,
      detailImage: null,
      detailSubheaderText: t("content.innovative_detail_subheader"),
      detailBackgroundColor: null,
      followUpComponent: <GetSignedUp onJoinClick={handleCreateAccount} />,
    },
    {
      isRightAligned: true,
      backgroundImagePath: "/img/setterJoin/join_FinancialFreedom.png",
      secondaryImagePath: null,
      headerText: t("content.freedom_header"),
      subheaderText: t("content.freedom_subheader"),
      detailHeaderText: t("content.freedom_detail_header"),
      highlightDetailHeader: false,
      detailImage: null,
      detailSubheaderText: t("content.freedom_detail_subheader"),
      detailBackgroundColor: null,
      followUpComponent: null,
    },
    {
      isRightAligned: false,
      backgroundImagePath: "/img/setterJoin/join_EquityOwnership.png",
      secondaryImagePath: null,
      headerText: t("content.equity_header"),
      subheaderText: t("content.equity_subheader"),
      detailHeaderText: t("content.equity_detail_header"),
      highlightDetailHeader: false,
      detailImage: null,
      detailSubheaderText: t("content.equity_detail_subheader"),
      detailBackgroundColor: null,
      followUpComponent: null,
    },
    {
      isRightAligned: true,
      backgroundImagePath: "/img/setterJoin/join_WorkAnywhere.png",
      secondaryImagePath: null,
      headerText: t("content.remote_header"),
      subheaderText: t("content.remote_subheader"),
      detailHeaderText: t("content.remote_detail_header"),
      highlightDetailHeader: false,
      detailImage: <EarningInfographic />,
      detailSubheaderText: null,
      detailBackgroundColor: null,
      followUpComponent: <GetSignedUp detailedVersion onJoinClick={handleCreateAccount} />,
    },
  ];

  const description =
    "Powur gives the power back. See why the best solar professionals are choosing to build their future with us.";

  return (
    <>
      <Layout className={styles.join}>
        <Helmet>
          <meta name="description" content={description} />
          <meta name="og:description" content={description} />
          <title>Join Powur</title>
          <meta name="title" content="Join Powur" />
          <meta name="description" content="Access training and start your solar sales journey in just minutes." />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://powur.com/join" />
          <meta property="og:title" content="The Best Solar Professionals are building their future with Powur" />
          <meta
            property="og:description"
            content="Our cloud-based solar sales and fulfillment platform provides you with the training, tools, and mentorship that you need, to reach your professional goals."
          />
          <meta property="og:image" content="/img/powur_fb_01.jpg" />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://powur.com/join" />
          <meta property="twitter:title" content="The Best Solar Professionals are building their future with Powur" />
          <meta
            property="twitter:description"
            content="Our cloud-based solar sales and fulfillment platform provides you with the training, tools, and mentorship that you need, to reach your professional goals."
          />
          <meta property="twitter:image" content="/img/powur_fb_01.jpg" />
        </Helmet>
        <Header user={user} />
        <ScrollToTop />
        <Content className={styles.contentContainer}>
          <SetterJoinHeroImage handleCreateAccount={handleCreateAccount} />
          {contentData.map((d) => {
            return (
              <>
                <ContentRow
                  key={d.headerText}
                  isRightAligned={d.isRightAligned}
                  backgroundImagePath={d.backgroundImagePath}
                  secondaryImagePath={d.secondaryImagePath}
                  headerText={d.headerText}
                  subheaderText={d.subheaderText}
                  detailBackgroundColor={d.detailBackgroundColor}
                  detailHeaderText={d.detailHeaderText}
                  highlightDetailHeader={d.highlightDetailHeader}
                  detailImage={d.detailImage}
                  detailSubheaderText={d.detailSubheaderText}
                  onJoinClick={handleCreateAccount}
                />
                {d.followUpComponent}
              </>
            );
          })}
          <SellerTestimonials />
        </Content>
        <Footer />
        <GetQuoteModal />
        <UtilityBillModal />
        <SuccessModal />
      </Layout>
    </>
  );
};

export default Join;
