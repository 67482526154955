// import content from "containers/Admin/workflows";
import tabs from "containers/Admin/workflows/tabs";
import sidebar from "containers/Admin/workflows/components/Sidebar";
import { WorkflowsIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";

const content = l(() => import("containers/Admin/workflows"));
// const sidebar = l(() =>
//   import("containers/Admin/workflows/components/Sidebar"),
// );

const { PLATFORM_CONFIGURATION } = permissionTypes;

export default {
  key: "admin-workflows",
  title: "Admin Workflows",
  path: "/admin-workflows/:tab?",
  linkTarget: "/admin-workflows",
  linkTitle: "Workflows",
  linkCategory: "Admin",
  permission: PLATFORM_CONFIGURATION,
  icon: WorkflowsIcon,
  basePath: "admin-workflows",
  tabs,
  content,
  sidebar,
};
