import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import _ from "lodash";
import { keysToCamelCase, objArrayKeysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getTeamUserRecruits(action) {
  // const params = action.payload;
  const { id, p } = action.payload;
  const params = {};
  if (p) params.p = p;

  const { success, data, error } = yield* makeRequest.get(`/users/${id}/recruits`, params);

  if (success && data) {
    const users = data.body.users.map((user) => {
      const newUser = _.clone(user);
      newUser.metrics = {};
      objArrayKeysToCamelCase(user.metrics).forEach((obj) => {
        const newKey = Object.keys(obj)[0];
        const newValue = Object.values(obj)[0];
        newUser.metrics[newKey] = newValue;
      });
      return keysToCamelCase(newUser);
    });
    yield put(
      actions.setTeamUserDetails({
        recruits: { users, paging: data.body.paging },
      }),
    );
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_USER_RECRUITS,
    });
  }
  return null;
}

export default getTeamUserRecruits;
