import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors, types } from "rdx";
import { useLocation } from "react-router";

import useDispatchList from "hooks/useDispatchList";
import useWindowResize from "hooks/useWindowResize";

import useLoading from "hooks/useLoading";
import BasicTable from "components/mui-styled-components/Tables/BasicTable";
import { T3PartnerTableDataT } from "../../types";
import { UserDetailCol, MetricsCol, AssignCol, MobileCol } from "./columns";

const T3PartnerTable = ({ navigate, override, setSelectedT3Partner, setOpenFooter }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMobileWidth } = useWindowResize();

  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    dispatch(actions.setLatestIndexRoute(`/summary/select-mentor${location.search}`));
  }, [dispatch, location.search]);

  const isLoading = useLoading({
    watchRequests: [types.GET_MENTORS_LIST_REQUEST],
  });
  const t3PartnerData = useSelector(selectors.getMentorsList);
  const isLeadToProposalDrawer = useSelector(selectors.selectLeadToProposalDrawerVisible);
  const projectDetails = useSelector(selectors.getProjectDetails)?.providerLead;
  const getData = useDispatchList(actions.getMentorsList);

  const getRowCount = useCallback(() => {
    const rowCount = t3PartnerData?.users.length;
    if (rowCount === 0 || !t3PartnerData.users) return 0;

    if (rowCount >= 6) {
      setTableHeight(552);
    } else {
      const height = rowCount * 90 + 55;
      setTableHeight(height);
    }
    return rowCount;
  }, [t3PartnerData]);

  useEffect(() => {
    getRowCount();
  }, [t3PartnerData, getRowCount]);

  const columns = [
    UserDetailCol,
    MetricsCol({ width: isLeadToProposalDrawer ? 272 : 300 }),
    AssignCol({ setSelectedT3Partner, setOpenFooter }),
  ];

  return (
    <BasicTable<T3PartnerTableDataT>
      getData={() => getData({ projectId: projectDetails?.id })}
      isLoading={!!isLoading}
      totalResultCount={t3PartnerData?.paging?.item_count}
      navigate={navigate}
      override={override}
      rows={t3PartnerData?.users}
      columns={isMobileWidth ? [MobileCol({ setSelectedT3Partner, setOpenFooter })] : columns}
      containerStyles={{
        paddingLeft: "0px",
        paddingTop: "0px",
        maxHeight: "700px",
        height: `${tableHeight}px`,
        minHeight: "110px",
        overflow: "hidden",
      }}
      isMobile={isMobileWidth}
      hideFilterBar
      hideColumnHeaders
      hideBottomPadding
    />
  );
};

export default T3PartnerTable;
