import React from "react";
import PropTypes from "prop-types";

const RightArrow2 = (props) => {
  const { height = "12", width = "16", fill = "var(--blue-ribbon)", className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585787 6.75 1 6.75L1 5.25ZM15.5303 6.53033C15.8232 6.23743 15.8232 5.76256 15.5303 5.46967L10.7574 0.696697C10.4645 0.403804 9.98959 0.403804 9.6967 0.696697C9.4038 0.989591 9.4038 1.46446 9.6967 1.75736L13.9393 6L9.6967 10.2426C9.40381 10.5355 9.40381 11.0104 9.6967 11.3033C9.98959 11.5962 10.4645 11.5962 10.7574 11.3033L15.5303 6.53033ZM1 6.75L15 6.75L15 5.25L1 5.25L1 6.75Z"
        fill={fill}
      />
    </svg>
  );
};

RightArrow2.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default RightArrow2;
