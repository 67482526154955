import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { DateRange } from "@mui/x-date-pickers-pro";
import { useMediaQuery } from "@mui/material";
import { parse } from "query-string";
import dayjs, { Dayjs } from "dayjs";

import { actions } from "rdx";
import { isNull, isString } from "types/guards";
import CalendarIcon from "components/Icons/Calendars/CalendarIcon";
import DateRangeSelector from "components/mui-styled-components/DateRangeSelector";
import { theme } from "styles/themes";
import * as S from "./styles";

type FilterByDateButtonPropsT = {
  buttonText?: string;
  filterKey?: string;
  fromKey?: string;
  toKey?: string;
  filterValueId?: string;
};

const FilterByDateButton = ({
  buttonText = "Activity by Date",
  filterKey = "performance_by_date",
  fromKey = "performanceFrom",
  toKey = "performanceTo",
  filterValueId = "custom_date_range",
}: FilterByDateButtonPropsT) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { search } = useLocation();
  const [rangeSelectorValue, setRangeSelectorValue] = useState<DateRange<Dayjs>>([null, null]);
  const { [fromKey]: from, [toKey]: to } = parse(search);

  const isRangeSelected = useMemo<boolean>(() => {
    return !!from && !!to;
  }, [from, to]);

  useEffect(() => {
    if (!from && !to) {
      setRangeSelectorValue([null, null]);
    } else {
      const fromDate = isString(from) ? dayjs(from) : null;
      const toDate = isString(to) ? dayjs(to) : null;
      setRangeSelectorValue([fromDate, toDate]);
    }
  }, [from, to]);

  const formatDisplayDate = (date: string): string => {
    return dayjs(date).format("MMM D, YYYY").toString();
  };

  const displayText = useMemo(() => {
    if (isString(from) && isString(to)) {
      return `${formatDisplayDate(from)} - ${formatDisplayDate(to)}`;
    }
    return buttonText;
  }, [from, to, buttonText]);

  const createFilterPayload = (fromValue: Dayjs, toValue: Dayjs) => {
    return {
      key: filterKey,
      value: {
        id: filterValueId,
        label: buttonText,
        chipLabel: buttonText,
        value: [
          { id: fromKey, value: fromValue.format("YYYY-MM-DD").toString() },
          { id: toKey, value: toValue.format("YYYY-MM-DD").toString() },
        ],
      },
      multiSelect: false,
      view: history.location.pathname,
      allowUpdate: true,
    };
  };

  const handleSelection = (dateValues: DateRange<Dayjs>): void => {
    const dateValuesNull = dateValues.every((date) => isNull(date));
    if (dateValuesNull) {
      dispatch(actions.deletePreferredViewFilter({ key: filterKey, id: filterValueId }));
    } else {
      const fromValue = dateValues[0] || dayjs("2015-01-01");
      const toValue = dateValues[1] || dayjs();
      const appliedFilterPayload = createFilterPayload(fromValue, toValue);
      dispatch(actions.updatePreferredView(appliedFilterPayload));
    }
  };

  return (
    <DateRangeSelector
      handleAccept={handleSelection}
      disableFuture
      isClearable
      parentControlledValue={rangeSelectorValue}
      setParentControlledValue={setRangeSelectorValue}
      renderInput={
        <S.FilterByDateButton disableRipple isActive={isRangeSelected} isMobile={isMobile}>
          <S.IconContainer isMobile={isMobile}>
            <CalendarIcon fill={isRangeSelected ? theme.colors.white : theme.colors["blue-ribbon"]} />
          </S.IconContainer>
          {!isMobile && displayText}
        </S.FilterByDateButton>
      }
    />
  );
};

export default FilterByDateButton;
