import { styled } from "@mui/material/styles";
import { theme } from "styles/themes";
import { mq } from "styles";

export const LandingPageCard = styled("div")(() => ({
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  transition: "background-color 0.5s ease",
  background: theme.colors.white,
  position: "relative",
  zIndex: 1000,
  "&:hover": {
    [mq.phoneOnly]: {
      backgroundImage: `linear-gradient(107deg, ${theme.colors["congress-blue"]} 2.67%, ${theme.colors["blue-ribbon"]} 46.33%, rgba(81, 242, 203, 0.83) 107.46%)`,
    },
    [mq.tabletUp]: {
      backgroundImage: `url("/img/powur_pair_lines.png"), linear-gradient(107deg, ${theme.colors["congress-blue"]} 2.67%, ${theme.colors["blue-ribbon"]} 46.33%, rgba(81, 242, 203, 0.83) 107.46%)`,
    },
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom left",
    "& .iconContainer": {
      background: theme.colors.white,
    },
    "& .headerText": {
      color: theme.colors.white,
    },
    "& .detailText": {
      color: theme.colors.white,
    },
    "& .stroke": {
      "& path": {
        stroke: theme.colors["blue-ribbon"],
      },
    },
    "& .fill": {
      "& path": {
        fill: theme.colors["blue-ribbon"],
      },
    },
  },
  [mq.phoneOnly]: {
    padding: "24px 18px",
  },
  [mq.tabletUp]: {
    padding: "32px 80px 42px",
  },
}));

export const IconContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "39px",
  height: "39px",
  borderRadius: "50%",
  background: `linear-gradient(95.7deg, ${theme.colors["picton-blue"]} 12.17%, ${theme.colors["blue-ribbon"]} 93.24%)`,
}));

export const CardDetail = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [mq.phoneOnly]: {
    marginTop: "16px",
  },
  [mq.tabletUp]: {
    marginTop: "22px",
  },
}));

export const CardHeaderText = styled("div")(() => ({
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "24px",
  color: theme.colors["darkest-blue"],
  marginBottom: "8px",
  textAlign: "center",
}));

export const CardDetailText = styled("div")(() => ({
  fontSize: "14px",
  lineHeight: "19px",
  color: theme.colors.black,
  textAlign: "center",
  [mq.tabletUp]: {
    width: "60%",
  },
}));
