import makeRequest from "rdx/utils/makeRequest";
import { all, put } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* postRoofingS3Asset(action) {
  const { ownerId, urls } = action.payload;
  const { success, data, error } = yield* makeRequest.post("/roofing_assets/", {
    proposal_id: ownerId,
    signed_urls: urls,
  });
  if (success && data) {
    yield put(actions.getRoofingS3Assets({ proposalId: ownerId }));
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.SUCCESS_UPLOAD_ROOFING_ASSET,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_ROOFING_PROPOSAL,
    });
  }

  return null;
}

export default postRoofingS3Asset;
