export default {
  GET_PREFERRED_PARTNERS_SUCCESS: "Preferred Partners Retrieved Successfully",

  ASSIGN_PREFERRED_PARTNER_SUCCESS: "Preferred Partner was Assigned",

  REMOVE_PREFERRED_PARTNER_SUCCESS: "Preferred Partner was Removed",

  NOT_FOUND_PREFERRED_PARTNER_SUCCESS: "Preferred Partner was not found",

  ADD_PREFERRED_PARTNERS_SUCCESS: "Preferred Partner was Added Successfully",

  CREATE_ORG_PARTNER_REQUEST_SUCCESS: "Preferred Partner was Requested Successfully",

  ERROR_GETTING_PREFERRED_PARTNERS: "There was an error getting Preferred Partners",

  ERROR_CREATING_PREFERRED_PARTNERS: "There was an error creating Preferred Partner",

  ERROR_ASSIGNING_PREFERRED_PARTNERS: "There was an error assigning Preferred Partner",

  ERROR_REMOVING_PREFERRED_PARTNER: "There was an error removing Preferred Partners",

  ERROR_CREATING_ORG_PARTNER_REQUEST: "There was an error creating the Preferred Partner Request",
};
