import types from "rdx/modules/earnings/types";
import createAction from "rdx/utils/createAction";

export default {
  getEarningsHistory: (payload) => createAction(types.GET_EARNINGS_HISTORY_REQUEST, payload),

  setEarningsHistory: (payload) => createAction(types.SET_EARNINGS_HISTORY, payload),

  getSellerOrLeadAutoComplete: (payload) => createAction(types.GET_CONSULTANT_OR_LEAD_AUTO_COMPLETE, payload),

  setSellerOrLeadAutoComplete: (payload) => createAction(types.SET_CONSULTANT_OR_LEAD_AUTO_COMPLETE, payload),

  getThisMonthEarnings: (payload) => createAction(types.GET_THIS_MONTH_EARNINGS, payload),

  setThisMonthEarnings: (payload) => createAction(types.SET_THIS_MONTH_EARNINGS, payload),

  getMonthlyBreakdown12Mos: (payload) => createAction(types.GET_MONTHLY_BREAKDOWN_12_MOS, payload),

  setMonthlyBreakdown12Mos: (payload) => createAction(types.SET_MONTHLY_BREAKDOWN_12_MOS, payload),

  getMonthlyBreakdown: (payload) => createAction(types.GET_MONTHLY_BREAKDOWN, payload),

  setMonthlyBreakdown: (payload) => createAction(types.SET_MONTHLY_BREAKDOWN, payload),
  // INSERTION_PT (for script, do not remove)
};
