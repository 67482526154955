import component from "containers/StaticPages/licenses";

export default {
  key: "powur-licenses",
  title: "Licenses",
  path: "/:user?/licenses",
  pageName: "Licenses",
  linkTarget: "/licenses",
  component,
};
