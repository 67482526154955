import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enterprisePersonnel/messages";
import { camelizeKeys } from "@helpers";

function* createSeatForExistingLevelOneUser(action) {
  const { userId, services, preview, proration_key, enterprise_id, consultantPercentage, alreadyPendingSeller } =
    action.payload;

  const { success, data, error } = yield* makeRequest.post("/seats", {
    user_id: userId,
    services,
    preview,
    proration_key,
    consultant_percentage: consultantPercentage,
    already_pending_seller: alreadyPendingSeller,
    enterprise_id,
  });

  if (success && data) {
    if (preview) {
      yield all([
        put(
          actions.setEnterpriseServicesSeatChangePreview({
            ...camelizeKeys(data),
            request: action.payload,
          }),
        ),
        put(
          actions.newSuccessEvent({
            message: messages.UPDATE_ENTERPRISE_SEAT_PREVIEW_SUCCESS,
            noMessageDisplay: true,
          }),
        ),
      ]);
    } else {
      yield all([
        put(actions.setEnterpriseServicesSeatChangePreview({})),
        put(
          actions.newSuccessEvent({
            message: messages.ENTERPRISE_SEAT_CHANGE_SUCCESS,
          }),
        ),
      ]);
    }
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_ENTERPRISE_USER_SERVICES,
    });
  }

  return null;
}

export default createSeatForExistingLevelOneUser;
