import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Row, Button } from "antd";
import User from "models/User";
import styles from "./JoinRow.module.less";

const JoinRow = (props) => {
  const { referrer, setRegisterModalVisible } = props;
  const { t } = useTranslation(["ambassador", "form_labels"]);
  const urlPrefix = process.env.REACT_APP_S3_URL_PREFIX;
  const fullName = referrer?.props?.fullName || "Powur PBC";
  const firstName = referrer?.props?.firstName || "Powur";

  const header = t("support.here_for_you");

  const firstParagraph = t("support.help_clean_up_our_environment");
  const secondParagraph = t("support.here_to_support_you", {
    agent_name: firstName,
  });

  const defaultAvatar = `${urlPrefix}/users/snmcl5c8zpcrssirway2/avatars/original`;

  return (
    <Row className={styles.joinRow}>
      <div className={styles.consultantCol}>
        <div
          className={styles.avatar}
          style={{
            backgroundImage: `url(${referrer?.props?.avatar?.original || defaultAvatar})`,
          }}
        />
        <span className={styles.title}>{t("your_solar_consultant")}</span>
        <span className={styles.name}>{fullName}</span>
      </div>
      <div className={styles.infoCol}>
        <h3>{header}</h3>
        <div className={styles.underline} />
        <h2>{t("support.join_us_now")}</h2>
        <p>{firstParagraph}</p>
        <p>
          <span>{fullName}</span> {secondParagraph}
        </p>
        <Button className={styles.button} type="primary" block onClick={() => setRegisterModalVisible(true)}>
          {t("form_labels:free_sign_up")}
        </Button>
        <div className={styles.angledAccent} />
      </div>
    </Row>
  );
};

JoinRow.propTypes = {
  referrer: User.types(),
  setRegisterModalVisible: PropTypes.func,
};

export default JoinRow;
