const convertDecimalToWholeNumber = (percentage: string | number) => {
  if (!percentage) return null;

  const stringifiedNumber = percentage.toString();
  const trimmedPercentage = parseFloat(stringifiedNumber).toFixed(2);

  return parseFloat(trimmedPercentage) * 100;
};

export default convertDecimalToWholeNumber;
