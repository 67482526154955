import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import ModalContainer from "@modals/ModalContainer";
// import { v4 as uuid } from "uuid";
import PlansHeader from "./plansHeader";
import styles from "./PricingAndPlans.module.less";

const PricingAndPlansModal = (props) => {
  const { close, visible } = props;
  const { t } = useTranslation(["enterprise_join"]);
  const { user } = useParams();
  const history = useHistory();

  const handleProClick = () => {
    close();
    history.push(`${user ? `/${user}` : ""}/enterprise/signup-pro`);
  };

  return (
    <ModalContainer
      visible={visible}
      modalClassName={styles.modal}
      noFooter
      width="50%"
      title=""
      handleCancel={close}
      closeIcon={<CloseOutlined />}
      centered
    >
      <div className={styles.content}>
        <div className={styles.modalTitle}>{t("pricing_and_plans.modal_title")}</div>
        <div className={styles.cardsContainer}>
          {t("pricing_and_plans.tiers", { returnObjects: true }).map((plan) => (
            <PlansHeader key={plan.type} plan={plan} onClick={handleProClick} />
          ))}
        </div>
      </div>
    </ModalContainer>
  );
};

PricingAndPlansModal.propTypes = {
  visible: PropTypes.bool,
  close: PropTypes.func,
};

export default PricingAndPlansModal;
