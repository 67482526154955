import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enterprisePersonnel/messages";
import { camelizeKeys } from "@helpers";

function* getListOfAssignableServices() {
  const { success, data, error } = yield* makeRequest.get("/services/assignable", {});
  if (success && data) {
    const services = camelizeKeys(data.body.services);
    const out = services.reduce((a, s) => {
      // group services by enterpriseCategory; each will show in separate dropdown
      const next = { ...a };
      const categoryKey = s.groupData.enterpriseCategory;
      if (next[categoryKey] === undefined) {
        next[categoryKey] = {};
      }
      next[categoryKey][s.slug] = s;
      return next;
    }, {});
    yield put(actions.setListOfAssignableServices(camelizeKeys(out)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ASSIGNABLE_SERVICES,
    });
  }

  return null;
}

export default getListOfAssignableServices;
