import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  leadsList: {
    leads: [],
    paging: {
      pageSize: 0,
      pageNumber: 1,
      itemCount: 0,
    },
    event_counts: [],
    kpi: [],
  },
  events: {
    events: [],
    paging: {
      page_size: 0,
      page_number: 1,
      item_count: 0,
    },
  },
  leadDetails: {
    assets: [],
  },
  leadsCsv: {},
  utilityCompanies: [],
  homeownerNotes: {},
};

export default {
  leads: createReducer(initialState, {
    [types.SET_LEADS](state, action) {
      return {
        ...state,
        leadsList: {
          ...state.leads,
          ...action.payload,
        },
      };
    },
    [types.RESET_LEADS](state) {
      return {
        ...state,
        leadsList: initialState.leadsList,
      };
    },
    [types.SET_EVENTS](state, action) {
      return { ...state, events: action.payload };
    },
    [types.SET_LEAD_DETAILS](state, action) {
      return { ...state, leadDetails: action.payload };
    },
    [types.RESET_LEAD_DETAILS](state) {
      return {
        ...state,
        leadDetails: initialState.leadDetails,
        homeownerNotes: initialState.homeownerNotes,
      };
    },
    [types.SET_LEAD_ADDRESS_GEOCODE](state, action) {
      return {
        ...state,
        leadDetails: {
          ...state.leadDetails,
          address: {
            ...state.leadDetails.address,
            lat: action.payload.lat,
            long: action.payload.lng,
          },
        },
      };
    },
    [types.SET_LOCATION_CONFIRMED](state) {
      return {
        ...state,
        leadDetails: {
          ...state.leadDetails,
          isAddressConfirmed: true,
        },
      };
    },

    [types.SET_LEADS_CSV](state, action) {
      return {
        ...state,
        leadsCsv: action.payload,
      };
    },
    [types.SET_UTILITY_COMPANIES](state, action) {
      return { ...state, utilityCompanies: action.payload };
    },
    [types.SET_HOMEOWNER_NOTES](state, action) {
      return { ...state, homeownerNotes: action.payload };
    },
    [types.SET_LEAD_SUBMITTED](state) {
      return {
        ...state,
        leadDetails: {
          ...state.leadDetails,
          submitted: true,
          isQualified: true,
        },
      };
    },
    [types.SET_LEAD_ASSETS](state, action) {
      return {
        ...state,
        leadDetails: {
          ...state.leadDetails,
          assets: action.payload,
        },
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
