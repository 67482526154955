import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";

function* updateCareerListing() {
  const { success, data, error } = yield* makeRequest.patch("/career_listings/id", {});

  if (success && data) {
    yield put(actions.setCareerListings(keysToCamelCase(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: "There was an error getting career listings",
    });
  }

  return null;
}

export default updateCareerListing;
