export default {
  // general
  ADD_PAYMENT_SUCCESS: "The payment was added successfully.",
  CLAWBACK_PAYMENT_SUCCESS: "The payment was clawed back successfully.",
  DELETE_PAYMENT_SUCCESS: "The payment was deleted successfully.",
  UPDATE_PAYMENT_SUCCESS: "The payment was updated successfully.",
  RECALCULATE_PAYMENT_SUCCESS: "The payment was recalculated successfully.",
  DISTRIBUTE_PAYMENT_SUCCESS: "The payment was distributed successfully.",

  // errors
  ERROR_GETTING_PERIODS: "There was an error retrieving periods",
  ERROR_GETTING_PAYMENTS: "There was an error retrieving payments",
  ERROR_GETTING_PROJECTS_COMPENSATION: "There was an error retrieving project payments",
  ERROR_GETTING_LEDGER: "There was an error retrieving ledger",
  ERROR_GETTING_LEDGER_CSV: "There was an error retrieving ledger csv",
  ERROR_ADDING_PAYMENT: "There was an error adding the payment",
  ERROR_CLAWING_BACK_PAYMENT: "There was an error clawing back the payment",
  ERROR_DELETING_PAYMENT: "There was an error deleting the payment",
  ERROR_UPDATING_PAYMENT: "There was an error updating the payment amount",
  ERROR_DISTRIBUTING_PAYMENT: "There was an error distributing the payment",
  ERROR_RECALCULATING_PAYMENT: "There was an error recalculating the payment",
  ERROR_GETTING_USER_OR_LEAD_AUTO_COMPLETE: "There was an error getting users/leads",
  ERROR_GETTING_PROJECT_REVENUE_SPLIT: "There was an error gettig project revenue split details",
};
