import React from "react";
import { cvar } from "styles";
import { IconT } from "types/utils";

const ProposalsGeneratedGradient = ({
  height = "30",
  width = "26",
  stopColor = cvar("blue-ribbon"),
  startColor = cvar("picton-blue"),
}: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2055_14043)">
        <rect x="8.22888" y="5.90137" width="16.7711" height="23.7369" rx="2" fill="#E6F1FE" />
        <rect x="5.69495" y="3.37012" width="16.7711" height="23.7369" rx="2" fill="#B3D5FC" />
        <rect x="3" y="1" width="16.7711" height="23.7369" rx="2" fill="url(#paint0_linear_2055_14043)" />
        <path d="M15.0006 5.19873H17.2486" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M5.52246 5.19873H7.77048" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <circle cx="11.2385" cy="5.19928" r="2.12603" stroke="white" strokeWidth="0.75" />
        <path d="M6.55676 9.22119H16.0907" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M6.55676 11.2754H16.0907" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M6.55957 13.3291H16.0935" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M6.55957 15.3848H16.0935" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M6.55957 17.4395H16.0935" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M6.55957 19.4917H16.0935" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
        <path d="M6.55957 21.5474H16.0935" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2055_14043"
          x1="5.94231"
          y1="-8.89036"
          x2="20.8227"
          y2="-7.84018"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <clipPath id="clip0_2055_14043">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProposalsGeneratedGradient;
