import React, { useState } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Button, Row, Col, Spin } from "antd";
import usePermissions, { permissionTypes } from "hooks/usePermissions";
import Router from "models/Router";
import styles from "../../AmbassadorDashboard.module.less";
import UpgradeModal from "./UpgradeModal";

const UpgradeToSeller = (props) => {
  const { loading, history } = props;

  const { PORTAL_ACCESS } = usePermissions({
    permissionRequests: [permissionTypes.PORTAL_ACCESS],
  });

  const [visibleUpgradeModal, setVisibleUpgradeModal] = useState(false);

  const handleUpgradeSubmit = () => {
    setVisibleUpgradeModal(false);
    history.push("/signup");
  };

  return (
    <>
      {loading && (
        <div className={styles.fetching}>
          <Spin className={styles.spin} />
        </div>
      )}

      <Row className={styles.form}>
        <Col span={24} className={styles.formItemCentered}>
          {PORTAL_ACCESS ? (
            <Button
              className={styles.upgradeButton}
              type="primary"
              block
              htmlType="button"
              onClick={() => history.push("/projects")}
            >
              Seller Portal
            </Button>
          ) : (
            <Button
              className={styles.upgradeButton}
              type="primary"
              block
              htmlType="button"
              onClick={() => setVisibleUpgradeModal(true)}
            >
              Upgrade to a Powur Sales Account
            </Button>
          )}
        </Col>
      </Row>

      <UpgradeModal
        visible={visibleUpgradeModal}
        onSubmit={() => handleUpgradeSubmit()}
        closeModal={() => setVisibleUpgradeModal(false)}
        openModal={() => setVisibleUpgradeModal(true)}
      />
    </>
  );
};

UpgradeToSeller.propTypes = {
  loading: PropTypes.bool,
  history: Router.historyTypes(),
};

export default withRouter(UpgradeToSeller);
