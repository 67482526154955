import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import msgs from "rdx/modules/leads/messages";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Button, Input, InputNumber, Row, Col, Modal } from "antd";
import { MailOutlined, CloseOutlined } from "@ant-design/icons";
import { parse, stringify } from "query-string";
import Lead from "models/Lead";
import PhoneIcon from "components/Icons/PhoneIcons/phoneIcon";
import useModalControls from "hooks/useModalControls";
import MessageEvent from "models/MessageEvent";
import CircledTextFilled from "./CircledTextFilled";
import styles from "./GetQuoteModal.module.less";
import common from "../HomepageComponents.module.less";

// const { Option } = Select;

const GetQuoteModal = (props) => {
  const {
    backgroundColor,
    innerPadding,
    latestMessage,
    clearLatestMessage,
    zipLookupAddress,
    setZipLookupAddress,
    setAlertMessageVisible,
  } = props;

  const { t } = useTranslation(["home", "form_labels", "translation"]);
  const { location } = useHistory();
  const history = useHistory();
  const { user: userSlug } = useParams();

  const search = parse(location.search);
  const [bill, setBill] = useState(null);

  const { visible, open, close } = useModalControls();

  useEffect(() => {
    if (zipLookupAddress.zip) {
      const { state, country, city, zip, available, invalid } = zipLookupAddress;
      const newSearch = {
        ...search,
        zip,
        state,
        city,
        country,
        available,
        invalid,
      };
      setZipLookupAddress({});
      history.push({
        pathname: history.location.pathname,
        search: `?${stringify(newSearch)}`,
      });
    }
  }, [zipLookupAddress]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { zip } = search;
    if (zip) open();
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  const mobileView = window.innerWidth <= 600;
  const { ref_id } = search;
  const [form] = Form.useForm();

  const submitSolarForm = (values) => {
    const { firstName, lastName, email, phone, street, unit, city, state, zip } = values;
    const lead = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      average_bill: bill,
      category: "residential",
    };
    const address = { street, unit, city, state, zip };

    setAlertMessageVisible({
      message: `${t("translation:submitting")}...`,
      duration: 10000,
      severity: "info",
    });
    const params = { lead, address };
    if (ref_id) params.referrer_id = ref_id;

    Lead.createNewPublicLead({
      userSlug: userSlug || "powur.pbc",
      ...params,
    });
  };

  const closeModal = () => {
    form.resetFields();
    history.push({
      pathname: history.location.pathname,
      search: `?${stringify({ ref_id })}`,
    });
    close();
  };

  const initialFormValues = () => {
    const { zip, country, state, city } = search;
    return {
      zip,
      country,
      state,
      city,
    };
  };

  useEffect(() => {
    return () => clearLatestMessage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (latestMessage.message && latestMessage.message === msgs.LEAD_CREATE_SUCCESS) {
      clearLatestMessage();
      setAlertMessageVisible({
        message: t("quote_modal.steps_one_and_two_complete"),
        severity: "success",
      });
      const newSearch = {
        ref_id,
        id: latestMessage.payload.id,
      };
      history.push({
        pathname: history.location.pathname,
        search: `?${stringify(newSearch)}`,
      });
      form.resetFields();
      close();
    }
    if (latestMessage.message && latestMessage.message === msgs.ERROR_CREATING_LEAD) {
      const msg = `${latestMessage.error.message || t("translation:bad_request_error_msg")}`;
      setAlertMessageVisible({
        message: msg,
        duration: 6000,
        severity: "error",
      });
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const formatbillValue = (value) => {
    if (value === 0 || !value) return value;
    return value === "350" ? "$350+" : `$${value}`;
  };

  return (
    <Modal
      bodyStyle={{
        minWidth: mobileView ? "300px" : "720px",
        padding: 0,
      }}
      className={styles.getQuoteModal}
      open={visible}
      footer={null}
      mask
      maskClosable
      centered
      keyboard
      destroyOnClose
      onCancel={closeModal}
      onClose={closeModal}
      closeIcon={<CloseOutlined style={{ color: "white" }} />}
    >
      {search.available === "true" ? (
        <div className={styles.formContainer} style={{ backgroundColor }}>
          <div className={common.header}>
            <div className={common.headerText}>{t("quote_modal.prime_for_solar_savings")}</div>
            <div className={common.headerSubtext}>{t("quote_modal.complete_information")}</div>
          </div>
          <div className={styles.inner} style={{ padding: innerPadding }}>
            <Form
              name="quote"
              onFinish={submitSolarForm}
              className={styles.quoteForm}
              form={form}
              initialValues={initialFormValues()}
              validateTrigger={["onSubmit"]}
            >
              <Row className={styles.sectionNumber}>
                <CircledTextFilled text="1" />
              </Row>
              <Row className={styles.sectionLabel}>
                <span className={styles.label}>{t("quote_modal.typical_electricity_bill")}</span>
              </Row>
              <Row className={styles.sectionLabel}>
                <div className={styles.underline} />
              </Row>
              <Row className={styles.billSection}>
                <Col span={24} className={styles.centeredCol}>
                  <Form.Item
                    name="bill"
                    className={styles.billFormItem}
                    rules={[
                      {
                        required: true,
                        message: t("form_labels:required_field"),
                      },
                    ]}
                  >
                    <InputNumber
                      name="bill"
                      className={styles.billInput}
                      autoFocus
                      min={0}
                      size="large"
                      bordered={false}
                      formatter={formatbillValue}
                      placeholder="$0"
                      value={bill}
                      onChange={(value) => {
                        setBill(value);
                        return form.setFieldsValue({ bill: value });
                      }}
                    />
                    <div className={styles.billLabel}>{t("form_labels:per_month")}</div>
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.sectionNumber}>
                <CircledTextFilled text="2" />
              </Row>
              <Row className={styles.sectionLabel}>
                <span className={styles.label}>{t("quote_modal.tell_us_more_about_you")}</span>
              </Row>
              <Row className={styles.sectionLabel}>
                <div className={styles.underline} />
              </Row>
              <Row className={styles.inputRow}>
                <Col span={mobileView ? 24 : 12}>
                  <div className={styles.formItem}>
                    <div className={styles.inputLabel}>{t("form_labels:first_name")}</div>
                    <Form.Item
                      name="firstName"
                      className={styles.formItemLeft}
                      rules={[
                        {
                          required: true,
                          message: t("form_labels:required_field"),
                        },
                      ]}
                    >
                      <Input className={styles.input} name="firstName" size="large" />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={mobileView ? 24 : 12}>
                  <div className={styles.formItem}>
                    <div className={styles.inputLabel}>{t("form_labels:last_name")}</div>
                    <Form.Item
                      name="lastName"
                      className={styles.formItemRight}
                      rules={[
                        {
                          required: true,
                          message: t("form_labels:required_field"),
                        },
                      ]}
                    >
                      <Input className={styles.input} name="lastName" size="large" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row className={styles.inputRow}>
                <Col span={18}>
                  <div className={styles.formItem}>
                    <div className={styles.inputLabel}>{t("form_labels:street_address")}</div>
                    <Form.Item
                      name="street"
                      className={styles.formItemLeft}
                      rules={[
                        {
                          required: true,
                          message: t("form_labels:required_field"),
                        },
                      ]}
                    >
                      <Input className={styles.input} name="street" size="large" />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={6}>
                  <div className={styles.formItem}>
                    <div className={styles.inputLabel}>{t("form_labels:unit")}</div>
                    <Form.Item name="unit" className={styles.formItemRight}>
                      <Input className={styles.input} name="unit" size="large" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row className={styles.inputRow}>
                <Col span={mobileView ? 24 : 12}>
                  <div className={styles.formItem}>
                    <div className={styles.inputLabel}>{t("form_labels:city")}</div>
                    <Form.Item
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: t("form_labels:required_field"),
                        },
                      ]}
                    >
                      <Input className={styles.input} name="city" size="large" />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={mobileView ? 12 : 6}>
                  <div className={styles.formItem}>
                    <div className={styles.inputLabel}>{t("form_labels:state")}</div>
                    <Form.Item
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: t("form_labels:required_field"),
                        },
                      ]}
                    >
                      <Input
                        className={styles.input}
                        name="state"
                        size="large"
                        // options={stateOptions}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={mobileView ? 12 : 6}>
                  <div className={styles.formItem}>
                    <div className={styles.inputLabel}>{t("form_labels:zipcode")}</div>
                    <Form.Item
                      name="zip"
                      className={styles.formItemRight}
                      rules={[
                        {
                          required: true,
                          message: t("form_labels:required_field"),
                        },
                      ]}
                    >
                      <Input className={styles.input} name="zip" size="large" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row className={styles.inputRow}>
                <Col span={mobileView ? 24 : 12}>
                  <div className={styles.formItem}>
                    <div className={styles.inputLabel}>{t("form_labels:phone")}</div>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          pattern: /^[0-9.\-+]+$/,
                          message: t("form_labels:valid_phone_number"),
                        },
                        {
                          required: true,
                          message: t("form_labels:required_field"),
                        },
                      ]}
                    >
                      <Input
                        className={styles.addonInput}
                        name="phone"
                        size="large"
                        addonBefore={
                          <div className={styles.inputPrefix}>
                            <PhoneIcon />
                          </div>
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={mobileView ? 24 : 12}>
                  <div className={styles.formItem}>
                    <div className={styles.inputLabel}>{t("form_labels:email")}</div>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: t("form_labels:valid_email"),
                        },
                        {
                          required: true,
                          message: t("form_labels:required_field"),
                        },
                      ]}
                    >
                      <Input
                        className={styles.addonInput}
                        name="email"
                        size="large"
                        addonBefore={
                          <div className={styles.inputPrefix}>
                            <MailOutlined
                              style={{
                                color: "var(--botticelli-blue)",
                                fontSize: "17px",
                              }}
                              className={styles.emailIcon}
                            />
                          </div>
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row className={styles.row}>
                <div className={styles.buttonContainer}>
                  <Button
                    content={t("form_labels:see_if_you_qualify")}
                    size="small"
                    onClick={() => form.submit()}
                    className={common.submitButton}
                    type="primary"
                  >
                    {t("form_labels:next_step")}
                  </Button>
                </div>
              </Row>
            </Form>
            <div className={styles.footerText}>{t("quote_modal.authorization_disclaimer")}</div>
          </div>
        </div>
      ) : (
        <div className={styles.unavailableContainer}>
          <div className={styles.unavailableText}>
            {t("quote_modal.unsupported", {
              city_name: search.invalid ? search.zip : `${search.city}, ${search.state}`,
            })}
          </div>
          <div className={styles.buttonContainer}>
            <Button content="Close" size="small" onClick={closeModal} className={common.submitButton} type="primary">
              {t("translation:close")}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

GetQuoteModal.propTypes = {
  backgroundColor: PropTypes.string,
  innerPadding: PropTypes.string,
  latestMessage: MessageEvent.types(),
  clearLatestMessage: PropTypes.func,
  setZipLookupAddress: PropTypes.func,
  zipLookupAddress: PropTypes.shape({
    zip: PropTypes.string,
    state: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    available: PropTypes.bool,
    invalid: PropTypes.bool,
  }),
  setAlertMessageVisible: PropTypes.func,
};

GetQuoteModal.defaultProps = {
  zipLookupAddress: {
    zip: "",
    state: "",
    city: "",
    country: "",
    available: true,
    invalid: false,
  },
};

export default connect(
  getRdxSelectionMapper({
    latestMessage: "getLatestMessageEvt",
    requests: "getActiveRequests",
    zipLookupAddress: "getZipLookupAddress",
  }),
  getRdxActionMapper(["clearLatestMessage", "setZipLookupAddress", "setAlertMessageVisible"]),
)(GetQuoteModal);
