import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "@rdxmodules/enterpriseEarnings/messages";
import { camelizeKeys } from "@helpers";

function* getEnterpriseCompPlan(action) {
  const params = {
    ...action.payload,
  };
  const { success, data, error } = yield* makeRequest.get("/enterprise/enterprise_comp_plan_info", params);

  if (success && data) {
    yield put(actions.setEnterpriseCompPlan(camelizeKeys(data.body)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ENTERPRISE_COMP_PLAN,
    });
  }
  return null;
}

export default getEnterpriseCompPlan;
