import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import SettingsClient from "util/SettingsClient";
import { v4 as uuid } from "uuid";
import styles from "./Counter.module.less";
import "./Counter.css";

class Counter extends React.Component {
  constructor(props) {
    super(props);
    const count = 0;
    const display = count
      .toString()
      .split("")
      .map((char) => {
        const id = uuid();
        return { id, label: char };
      });
    this.state = {
      count,
      display,
      rateOfChange: 10,
      startDate: new Date(),
      error: null,
    };
    this.settings = new SettingsClient();
  }

  componentDidMount = () => {
    if (this.settings.data?.impact_metrics?.carbon_sequestered) {
      const { current, per_second, updated_at } = this.settings.data.impact_metrics.carbon_sequestered;
      this.setState({
        rateOfChange: per_second,
        startDate: new Date(updated_at),
      });
      this.initCounter(current);
      const interval = setInterval(this.incrementCount, 1000);
      this.setState({ interval });
    } else {
      this.setState({ error: "Error occurred, cannot display counter." });
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.state.interval);
  };

  incrementCount = () => {
    this.setState((prevState) => {
      const newCount = prevState.count + prevState.rateOfChange;
      const newDisplay = this.updateDisplayArray(newCount);
      return { count: newCount, display: newDisplay };
    });
  };

  initCounter = (current) => {
    const changePerSecond = this.state.rateOfChange;
    const now = new Date();
    const secondsElapsed = (now.getTime() - this.state.startDate.getTime()) / 1000;
    const count = current + changePerSecond * secondsElapsed;
    const display = Math.floor(count)
      .toString()
      .split("")
      .map((char) => {
        const id = uuid();
        return { id, label: char };
      });
    this.setState({ count, display });
  };

  updateDisplayArray = (count) => {
    const split = Math.floor(count).toString().split("");
    const oldDisplay = this.state.display;
    const newDisplay = oldDisplay.map(({ label }, idx) => {
      if (label !== split[idx]) {
        const newId = uuid();
        return { id: newId, label: split[idx] };
      }
      return oldDisplay[idx];
    });
    return newDisplay;
  };

  render() {
    if (this.state.error) {
      return (
        <div className={styles.counterContainer}>
          <div className={styles.errorMessage}>{this.state.error}</div>
        </div>
      );
    }

    const displayWithPunctuation = [];
    const len = this.state.display.length;
    for (let i = 0; i < len; i++) {
      const j = len - i - 1;
      displayWithPunctuation.unshift(this.state.display[j]);
      if (i % 3 === 2 && i !== len - 1) {
        displayWithPunctuation.unshift({ label: ",", id: "punc" });
      }
    }

    return (
      <div className={styles.counterContainer}>
        {displayWithPunctuation.map(({ label, id }, index) => {
          const divId = `counter-box-${index}`;
          if (id === "punc") {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <span key={index} className={styles.comma}>
                {label}
              </span>
            );
          }
          return (
            // eslint-disable-next-line react/no-array-index-key
            // <TransitionGroup className={styles.box} key={index} id={divId}>
            //   <CSSTransition key={id} timeout={500} classNames="animate">
            //     <div id={id} className={styles.num}>
            //       {label}
            //     </div>
            //   </CSSTransition>
            // </TransitionGroup>

            <TransitionGroup className={styles.box} id={divId}>
              <CSSTransition
                classNames={{
                  enter: styles.animateEnter,
                  enterActive: styles.animateEnterActive,
                  exit: styles.animateExit,
                  exitActive: styles.animateExitActive,
                }}
                id={id}
                key={id}
                timeout={300}
              >
                <div className={styles.num}>{label}</div>
              </CSSTransition>
            </TransitionGroup>
          );
        })}
      </div>
    );
  }
}

export default Counter;
