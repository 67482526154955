import dayjs from "dayjs";

const formatDateDifferenceInDays = (time: string) => {
  const startDay = dayjs(time).startOf("day");
  const daysDifference = startDay.diff(dayjs().startOf("day"), "days");

  if (daysDifference > 1) return `${daysDifference} Days`;
  if (daysDifference === 1) return "Tomorrow";
  if (daysDifference < -1) return `${Math.abs(daysDifference)} Days Ago`;
  if (daysDifference === -1) return "Yesterday";
  return "Today";
};

export default formatDateDifferenceInDays;
