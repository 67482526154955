import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";
import { connect } from "react-redux";
import { Layout } from "antd";
import ContactConfirmation from "components/Icons/ContactConfirmation";
import HeaderLogoLarge from "components/Icons/Logos/HeaderLogoLarge";
import { getRdxActionMapper } from "rdx/utils/propsMapping";
import useWindowResize from "hooks/useWindowResize";

import styles from "./styles";

const ContactVerification = (props) => {
  const { verifyHomeownerContact } = props;
  const { search: urlSearch } = useLocation();
  const [contactMethod, setContactMethod] = useState("Email address");
  const [actionItem, setActionItem] = useState("to login to your MyPowur profile");
  const params = parse(urlSearch);
  const { width } = useWindowResize();
  const tabletUp = width >= 600;

  useEffect(() => {
    verifyHomeownerContact({
      auth_token: params.auth_token,
      mobile: !!params.mobile,
    });
  }, [params, verifyHomeownerContact]);

  useEffect(() => {
    if (params.mobile) {
      setContactMethod("Phone number");
      setActionItem("in your communications on MyPowur");
    } else {
      setContactMethod("Email address");
      setActionItem("to login to your MyPowur profile");
    }
  }, [params, setContactMethod]);

  return (
    <Layout css={styles.pageLayout}>
      <div css={styles.confirmWindow}>
        <div css={styles.topBar}>
          <HeaderLogoLarge css={styles.logo} width={tabletUp ? "146px" : "115px"} height={tabletUp ? "46px" : "35px"} />
        </div>
        <div css={styles.verifyBody}>
          <ContactConfirmation />
          <div css={styles.verifyTitle}>{contactMethod} confirmed</div>
          <div css={styles.verifySub}>
            You have successfully confirmed your {contactMethod.toLowerCase()}. Please use this{" "}
            {contactMethod.toLowerCase()} {actionItem}.
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default connect(undefined, getRdxActionMapper(["verifyHomeownerContact"]))(ContactVerification);
