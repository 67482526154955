import { store } from "rdx/configureStore";
import leadsTypes from "rdx/modules/leads/types";
import activeRequestsTypes from "rdx/modules/activeRequests/types";
import appTypes from "rdx/modules/app/types";
import messagesTypes from "rdx/modules/messages/types";
import projectsTypes from "rdx/modules/projects/types";
import inboxTypes from "rdx/modules/tickets/types";
import usersTypes from "rdx/modules/users/actionTypes";
import teamTypes from "rdx/modules/team/types";
import rbacTypes from "rdx/modules/rbac/types";
import adminProjectsTypes from "rdx/modules/adminProjects/types";
import workflowsTypes from "rdx/modules/workflows/types";
import ambassadorTypes from "rdx/modules/ambassador/types";
import profileTypes from "rdx/modules/profile/types";
import reportsTypes from "rdx/modules/reports/types";
import admindashboardTypes from "rdx/modules/adminDashboard/types";
import consultantDashboardTypes from "rdx/modules/consultantDashboard/types";
import helpjuiceTypes from "rdx/modules/helpjuice/types";
import settingsTypes from "rdx/modules/settings/types";
import adminNoticesTypes from "rdx/modules/adminNotices/types";
import smsTypes from "rdx/modules/adminSms/types";
import earningsTypes from "rdx/modules/earnings/types";
import registrationTypes from "rdx/modules/registration/types";
import financialsTypes from "rdx/modules/financials/types";
import messagingmetricsTypes from "rdx/modules/adminReports/types";
import adminProposalsTypes from "rdx/modules/adminProposals/types";
import mentorratingsTypes from "rdx/modules/mentorRatings/types";
import workSchedulesTypes from "rdx/modules/workSchedules/types";
import bypassPaywallTypes from "rdx/modules/bypassPaywall/types";
import careerListingsTypes from "rdx/modules/careerListings/types";
import stateLicensesTypes from "rdx/modules/stateLicenses/types";
import authTypes from "rdx/modules/auth/types";
import filterSetsTypes from "rdx/modules/filterSets/types";
import compensationTypes from "rdx/modules/compensation/types";
import platformTypes from "rdx/modules/platform/types";
import enterpriseProjectsTypes from "rdx/modules/enterpriseProjects/actionTypes";
import enterpriseOrgTypes from "rdx/modules/enterpriseOrg/actionTypes";
import enterpriseLeadsTypes from "rdx/modules/enterpriseLeads/types";
import enterprisePersonnelTypes from "rdx/modules/enterprisePersonnel/types";
import enterpriseEarningsTypes from "rdx/modules/enterpriseEarnings/types";
import enrollmentTypes from "rdx/modules/enrollment/actionTypes";
import enterpriseApplicationsTypes from "rdx/modules/enterpriseApplications/types";
import buildPartnerApplicationsTypes from "rdx/modules/buildPartnerApplications/types";
import complianceIssuesTypes from "rdx/modules/complianceIssues/types";
import adminLeadsTypes from "rdx/modules/adminLeads/types";
import dashboardPromotionsTypes from "rdx/modules/dashboardPromotions/types";
import dashboardUpdatesTypes from "rdx/modules/dashboardUpdates/types";
import seatapprovalsTypes from "rdx/modules/seatApprovals/types";
import enterpriseProLaborFormTypes from "rdx/modules/enterpriseProLaborForm/types";
import notificationsTypes from "rdx/modules/notifications/types";
import viewedAssetsTypes from "rdx/modules/viewedAssets/types";
import activateAcctBtnClicked from "rdx/modules/activateAcctBtnClicked/types";
import preferredpartnersTypes from "rdx/modules/preferredPartners/types";
import platformModulesTypes from "rdx/modules/platformModules/types";
import roofingTypes from "rdx/modules/roofing/types";
import homeownerTypes from "rdx/modules/homeowner/types";
import crmActivitiesTypes from "rdx/modules/crmActivities/types";
import salesTeamsTypes from "rdx/modules/salesTeams/types";
import sellerIntegrityTypes from "rdx/modules/sellerIntegrity/types";
import publicApiProxyTypes from "rdx/modules/publicApiProxy/types";
import projectpresalesTypes from "rdx/modules/projectPreSales/types";
import fundingProposalsTypes from "rdx/modules/fundingProposals/types";
import fileuploadTypes from "rdx/modules/fileUpload/types";
import customFieldsTypes from "rdx/modules/customFields/actionTypes";
// IMPORT_PT (for script, do not remove)

const types = [
  leadsTypes,
  activeRequestsTypes,
  appTypes,
  messagesTypes,
  projectsTypes,
  inboxTypes,
  usersTypes,
  teamTypes,
  rbacTypes,
  adminProjectsTypes,
  workflowsTypes,
  ambassadorTypes,
  profileTypes,
  admindashboardTypes,
  consultantDashboardTypes,
  helpjuiceTypes,
  reportsTypes,
  settingsTypes,
  adminNoticesTypes,
  smsTypes,
  earningsTypes,
  registrationTypes,
  financialsTypes,
  messagingmetricsTypes,
  adminProposalsTypes,
  mentorratingsTypes,
  workSchedulesTypes,
  bypassPaywallTypes,
  careerListingsTypes,
  stateLicensesTypes,
  authTypes,
  filterSetsTypes,
  compensationTypes,
  platformTypes,
  enterpriseProjectsTypes,
  enterpriseOrgTypes,
  enterpriseLeadsTypes,
  enterprisePersonnelTypes,
  enterpriseEarningsTypes,
  enrollmentTypes,
  enterpriseApplicationsTypes,
  buildPartnerApplicationsTypes,
  complianceIssuesTypes,
  adminLeadsTypes,
  dashboardPromotionsTypes,
  dashboardUpdatesTypes,
  seatapprovalsTypes,
  enterpriseProLaborFormTypes,
  notificationsTypes,
  viewedAssetsTypes,
  activateAcctBtnClicked,
  preferredpartnersTypes,
  platformModulesTypes,
  roofingTypes,
  homeownerTypes,
  crmActivitiesTypes,
  salesTeamsTypes,
  sellerIntegrityTypes,
  publicApiProxyTypes,
  projectpresalesTypes,
  fundingProposalsTypes,
  fileuploadTypes,
  customFieldsTypes,
  // INSERTION_PT (for script, do not remove)
];

type U2I<U> = (U extends U ? (u: U) => void : never) extends (i: infer I) => void ? I : never;

const checkForDuplicates = (data: typeof types) => {
  const duplicates = data
    .map((el) => Object.keys(el))
    .flat()
    .filter((key, indx, ar) => ar.indexOf(key) !== indx);

  if (duplicates.length > 0) throw new Error(`Duplicate saga types were found: [ ${duplicates} ]`);

  const res: U2I<typeof data[number]> = Object.assign({}, ...data);
  return res;
};

const distinctTypes = checkForDuplicates(types);

export type SagaTypesT = keyof typeof distinctTypes;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default distinctTypes;
