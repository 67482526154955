import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import { Row } from "antd";

import ModalContainer from "components/Modals/ModalContainer";
import styles from "./EvolutionModal.module.less";

const ConferenceModal = (props) => {
  const { title, modalOpen, setModalOpen, onClose } = props;

  const handleClose = () => {
    onClose();
    setModalOpen(false);
  };

  const handleSubmit = () => {
    window.open("https://powurconvention.com", "blank");
  };

  const subTitle = "Solar, but better. Let's evolve in life and business.";
  const paragraphText =
    "Join us for our most (r)evolutionary Powur event to date, as we help you evolve into the solar professional of the future.";
  const dateText = "January 14th & 15th, 2022";
  const locationText = "Held in our state-of-the-art virtual media center";

  return (
    <ModalContainer
      visible={modalOpen}
      title={title || "Powur Evolution Convention"}
      submitText="Learn More"
      zIndex={1003}
      handleSubmit={handleSubmit}
      withoutCancel
      handleCancel={handleClose}
      headerClassName={styles.header}
      headingClassName={styles.heading}
      modalClassName={styles.modal}
      centerButtons
      maskClosable
    >
      <div className={styles.conferenceModal}>
        <div className={styles.overlay} />
        <iframe
          src="https://player.vimeo.com/video/646907100?h=11a7ca1691&muted=1&autoplay=1&loop=1&transparent=0&background=1&app_id=122963"
          allowFullScreen=""
          allow="autoplay; fullscreen; picture-in-picture"
          title="Video Background for homepage powur"
          height="100%"
          width="100%"
          className={styles.backgroundVideo}
          frameBorder="0"
        />
        <div className={styles.content}>
          <Row className={styles.logo}>
            <img src="/img/logo_powur-evolution.png" alt="Powered By Stripe" />
          </Row>
          <Row className={styles.subText}>
            <span>{subTitle}</span>
          </Row>
          <Row className={styles.paragraphText}>
            <span>{paragraphText}</span>
          </Row>
          <Row className={styles.dateText}>
            <span>{dateText}</span>
          </Row>
          <Row className={styles.locationText}>
            <span>{locationText}</span>
          </Row>
        </div>
      </div>
    </ModalContainer>
  );
};

ConferenceModal.propTypes = {
  title: PropTypes.string,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  onClose: PropTypes.func,
};

ConferenceModal.defaultProps = {
  onClose: () => null,
};

export default connect(getRdxSelectionMapper({}), getRdxActionMapper([]))(ConferenceModal);
