import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToSnakeCase } from "lib/helpers";
import messages from "../messages";

function* createNewPublicLead(action) {
  const body = {
    lead: keysToSnakeCase(action.payload.lead),
    address: keysToSnakeCase(action.payload.address),
    marketingLead: !!action.payload.marketingLead,
    referrer_id: action.payload.referrer_id,
  };
  const { success, data, error } = yield* makeRequest.post(`/pages/${action.payload.userSlug}/leads`, body);
  if (success && data) {
    yield put(
      actions.newSuccessEvent({
        message: messages.LEAD_CREATE_SUCCESS,
        payload: { ...data.body },
      }),
    );
  } else if (error) {
    return getErrorActions({ error, message: messages.ERROR_CREATING_LEAD });
  }
  return null;
}

export default createNewPublicLead;
