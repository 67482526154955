import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { buttonProps } from "lib/props";
import styles from "../Education.module.less";

const ResizableButton = (props) => {
  const { content, size, animation, containerClassName, onClick } = props;

  const className = useMemo(() => {
    let animClass = null;
    if (animation) {
      animClass = animation === "pulse" ? styles.pulseAnim : styles.radarAnim;
    }
    return [styles.resizableButton, animClass, styles[size]].join(" ");
  }, [animation, size]);

  return (
    <div className={[styles.resizableButtonContainer, containerClassName].join(" ")} {...buttonProps(onClick)}>
      <div className={className}>{content}</div>
      {animation === "radar" && <div className={[styles.resizableButtonUnderlay, styles[size]].join(" ")} />}
    </div>
  );
};

// content: text to display in the button
// size: small or large
// animation: radar or pulse
ResizableButton.propTypes = {
  content: PropTypes.string,
  size: PropTypes.string,
  animation: PropTypes.string,
  containerClassName: PropTypes.string,
  onClick: PropTypes.func,
};

ResizableButton.defaultProps = {
  containerClassName: "",
};

export default ResizableButton;
