import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/seatApprovals/types";

// IMPORT_PT (for script, do not remove)
import getSeatApprovals from "./getSeatApprovals";
import approveSeatApproval from "./approveSeatApproval";
import declineSeatApproval from "./declineSeatApproval";

function* watchSeatApprovalsSagas() {
  yield trackRequests(takeEvery, types.GET_SEATS_APPROVALS, getSeatApprovals);
  yield trackRequests(takeEvery, types.APPROVE_SEATS_APPROVAL, approveSeatApproval);
  yield trackRequests(takeEvery, types.DECLINE_SEATS_APPROVAL, declineSeatApproval);
}

export default watchSeatApprovalsSagas;
