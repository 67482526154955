import makeRequest from "rdx/utils/makeRequest";
import { all, put } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* createSalesTeam(action) {
  const { l1ManagerId, name } = action.payload;
  const { success, data, error } = yield* makeRequest.post("/sales_teams", {
    l1_manager_id: l1ManagerId,
    name,
  });

  if (success && data) {
    yield all([put(actions.getAvailableManagers())]);
    yield all([put(actions.getTeams())]);
    yield all([put(actions.getSellers())]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_SALES_TEAM,
    });
  }

  return null;
}

export default createSalesTeam;
