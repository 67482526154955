import { keysToCamelCase } from "lib/helpers";

export default function formatPayoutsList(data) {
  return {
    paging: keysToCamelCase(data.body.paging),
    balance: data.body.balance,
    distributedNeeded: data.body.distributed_needed,
    payoutNeeded: data.body["payout_needed?"],
    topupNeeded: data.body.topup_needed,
    list: data.body.payouts.map((p) => keysToCamelCase(p)),
  };
}
