// 1/1/21
import userTimezone from "./userTimezone";

export default function formatDateShort(dateString, placeholder = "-") {
  if (!dateString) {
    return placeholder;
  }

  const timeZone = userTimezone();

  return new Date(dateString).toLocaleDateString("en-US", {
    timeZone,
    dateStyle: "short",
  });
}
