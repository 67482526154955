import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {};

export default {
  adminDashboard: createReducer(initialState, {
    [types.SET_ADMIN_DASHBOARD_DETAILS](state, action) {
      return action.payload;
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
