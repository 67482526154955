import React, { useMemo, useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";

import Grid from "@mui/material/Grid";
import _ from "lodash";
import { UserFilterTypes } from "types/User";
import { SmartFormTextField, SmartFormDropdown } from "components/mui-styled-components/SmartFormElements";
import { PrimaryButton } from "components/mui-styled-components";
import { CloseIcon } from "components/Icons";
import GradientGhostButton from "components/Buttons/GradientGhostButton";
import SubHeaderFilled from "components/PlatformLayout/components/SubHeaderFilled";
import { cvar } from "styles/colorVariables";
import { DropdownOptionsT } from "../../types";
import {
  languageOpts,
  presentationOpts,
  teamSearchOpts,
  availableSortOpts,
  SearchT3PartnerFormSchemaT,
} from "./t3PartnerFormData";
import * as S from "./styles";

type T3PartnerFiltersPropsT = {
  filters: UserFilterTypes;
  handleSearchSubmit: (e?: React.BaseSyntheticEvent<object> | undefined) => Promise<void>;
  resetFields: () => void;
  setValue: UseFormSetValue<SearchT3PartnerFormSchemaT>;
};

const T3PartnerFilters = ({ filters, handleSearchSubmit, setValue, resetFields }: T3PartnerFiltersPropsT) => {
  const serviceAreaOptions = useMemo((): DropdownOptionsT => {
    if (!filters.serviceAreas) return [];
    const alphabetized = filters.serviceAreas.sort((a, b) => Object.values(a)[0].localeCompare(Object.values(b)[0]));
    const formattedOptions = alphabetized.map((area) => {
      return { label: Object.values(area)[0], value: Object.keys(area)[0] };
    });
    return [{ label: "Any Service Area", value: "" }, ...formattedOptions];
  }, [filters]);

  const sortOptions = useMemo((): DropdownOptionsT => {
    if (!filters.metrics) return [];
    const filteredMetrics = filters.metrics.filter((metric) => {
      return availableSortOpts.includes(metric);
    });
    return filteredMetrics.map((metric) => {
      return { label: _.startCase(metric), value: metric === "rating" ? "mentor_rating" : metric };
    });
  }, [filters]);

  const certificationOptions = useMemo((): DropdownOptionsT => {
    if (!filters.certifications) return [];
    const certOptions = filters.certifications.map((cert) => {
      return { label: _.startCase(cert), value: `${cert}__certified` };
    });
    return [{ label: "No Preference", value: "" }, ...certOptions];
  }, [filters]);

  useEffect(() => {
    if (sortOptions.length) {
      setValue("sort", "mentor_rating");
    }
  }, [sortOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SubHeaderFilled title="Refine Results" />
      <S.T3PartnerFiltersContainer>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <SmartFormTextField name="q" label="Search By Name" />
          </Grid>
          <Grid item xs={6} sm={4}>
            <SmartFormDropdown
              name="service_area"
              mainLabel="Service Area"
              dropdownOptions={serviceAreaOptions}
              disabled={!serviceAreaOptions.length}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <SmartFormDropdown name="spoken_languages" mainLabel="Language" dropdownOptions={languageOpts} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <SmartFormDropdown name="appt_location" mainLabel="Presentation" dropdownOptions={presentationOpts} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <SmartFormDropdown name="team_search" mainLabel="Leadership" dropdownOptions={teamSearchOpts} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <SmartFormDropdown
              name="sort"
              mainLabel="Sort By"
              dropdownOptions={sortOptions}
              disabled={!sortOptions.length}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <SmartFormDropdown
              name="certification"
              mainLabel="Certification"
              dropdownOptions={certificationOptions}
              disabled={!certificationOptions.length}
            />
          </Grid>
          <S.ButtonContainer item xs={12} sm={4}>
            <GradientGhostButton onClick={resetFields} style={{ marginRight: "7px" }}>
              <CloseIcon startFill={cvar("picton-blue")} stopFill={cvar("blue-ribbon")} />
            </GradientGhostButton>
            <PrimaryButton gradient onClick={handleSearchSubmit} style={{ flexGrow: 1 }}>
              Search
            </PrimaryButton>
          </S.ButtonContainer>
        </Grid>
      </S.T3PartnerFiltersContainer>
    </>
  );
};

export default T3PartnerFilters;
