import { put, all } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* deleteAdminNotice(action) {
  const noticeId = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/notices/${noticeId}`, {});
  if (success && data) {
    const out = keysToCamelCase(_.clone(data.body));
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.SUCCESS_DELETING_NEW_ADMIN_NOTICE,
          payload: data.body,
        }),
      ),
      put(actions.setAdminNoticeDetails(out)),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_NEW_ADMIN_NOTICE,
    });
  }
  return null;
}

export default deleteAdminNotice;
