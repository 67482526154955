import { css } from "@emotion/react";

export { cvar } from "./colorVariables";
export { mq } from "./mediaQueries";

export const test = css({
  border: "1px solid red",
});

export const useMuiClass = (className: string) => `& .${className}`;
