import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* updateMentorPreferences(action) {
  const params = {
    online: action.payload.online,
    in_person: action.payload.inPerson,
    farthest_distance: action.payload.distance,
    online_availability: action.payload.onlineAvailability,
    in_person_availability: action.payload.inPersonAvailability,
  };

  const { success, data, error } = yield* makeRequest.post("/profile/mentor_preferences", params);
  if (success && data) {
    yield all([
      put(actions.setProfile(keysToCamelCase(data.body))),
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_MENTOR_PREFERENCES_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_MENTOR_PREFERENCES,
    });
  }
  return null;
}

export default updateMentorPreferences;
