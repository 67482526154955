import React from "react";
import PropTypes from "prop-types";

const TotalActiveProjects = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.8697 6.82723L26.4583 8.7716V2.90337H21.3104L21.3163 2.90922V4.91215L16.0864 1L1 12.3031V12.3089H1.01171L4.10981 16.3675L5.72035 15.1728L5.73206 24.9941L26.4583 25L26.4524 24.9941H26.4583V15.1845L28.063 16.3792C29.0996 15.0205 30.1362 13.6676 31.1728 12.3089L26.4583 8.7716"
        stroke={fill}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};

TotalActiveProjects.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

TotalActiveProjects.defaultProps = {
  fill: "var(--eggplant)",
  width: "33",
  height: "26",
};

export default TotalActiveProjects;
