import { z } from "zod";
import { blankAvailabilityData } from "components/mui-styled-components/SmartFormElements/SmartFormAvailabilityPicker";
import { countryCodes } from "lib/misc/dropdownOptions";

export const schema = z
  .object({
    firstName: z.string().min(1, { message: "First Name is required." }),
    lastName: z.string().min(1, { message: "Last Name is required." }),
    email: z.string().email({ message: "Email is required." }),
    phone: z.object({
      phonePrefix: z.string().min(1, { message: "Please select a country code" }),
      phoneNumber: z
        .string()
        .regex(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, { message: "Phone Number is required." }),
    }),
    locale: z.string().min(1, { message: "Language Preference is required." }),
    street: z.string(),
    city: z.string(),
    state: z.string(),
    zip: z.string().min(1, { message: "Zipcode is required." }),
    category: z.string().min(1, { message: "Category is required." }),
    homeownerNotes: z.string().nullish(),
    homeownerAvailability: z
      .object({
        id: z.number().nullish(),
        days: z.string().array(),
        startTime: z.string(),
        endTime: z.string(),
      })
      .array()
      .nullish(),
    leadSource: z.string(),
    altSource: z.string(),
    copilot: z.boolean(),
  })
  .refine((data) => (data.leadSource === "Other" && data.altSource) || data.leadSource !== "Other", {
    message: "Lead Source is Required",
    path: ["altSource"],
  });

export type AddUpdateLeadFormT = z.infer<typeof schema>;

export const puertoRicoNote = "Note: If this is a Puerto Rico project please select Puerto Rico in the State dropdown";

export const availabilityNote =
  "Please work with the prospective homeowner on their preferred times to be contacted for their Project.";

export const copilotNote =
  "This program is provided by Powur at no additional cost to me as the seller and will include this prospect in nurture campaigns to help guide them along during the pre-sales process. I understand that Powur will send my prospect periodic messages about the benefits of solar, the benefits of Powur and how they can take advantage of Powur’s offerings. As long as I am an active seller with Powur, each of these messages will include my name and contact information for the homeowner to easily get in touch.";

export const copilotText = "Yes! I’d like this prospect to be included in the Powur Copilot Program.";

export const blankFormData = {
  firstName: "",
  lastName: "",
  email: "",
  phone: { phonePrefix: countryCodes[0].value, phoneNumber: "" },
  locale: "en",
  street: "",
  city: "",
  state: "",
  zip: "",
  category: "residential",
  homeownerNotes: "",
  homeownerAvailability: [blankAvailabilityData],
  leadSource: "",
  altSource: "",
  copilot: true,
};

export const fastFillData: AddUpdateLeadFormT = {
  firstName: "",
  lastName: "",
  email: "",
  phone: { phonePrefix: "+1", phoneNumber: "971-590-2385" },
  locale: "en",
  street: "",
  city: "",
  state: "",
  zip: "",
  category: "residential",
  homeownerAvailability: [blankAvailabilityData],
  leadSource: "facebook",
  altSource: "",
  copilot: true,
};
