import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/tickets/types";
import getInboxList from "./getInboxList";
import getSelectedMessage from "./getSelectedMessage";
import updateMessage from "./updateMessage";
import createMessage from "./createMessage";
import deleteMessage from "./deleteMessage";
import resolveIssue from "./resolveIssue";
import reopenIssue from "./reopenIssue";
import starTicket from "./starTicket";
import unstarTicket from "./unstarTicket";
import assignProjectIssue from "./assignIssue";
import getProjectMessageList from "./getProjectMessageList";
import getZendeskFormData from "./getZendeskFormData";
import createNewProjectMessage from "./createNewProjectMessage";
import deleteProjectMessage from "./deleteProjectMessage";
import getProjectRepliesPreview from "./getProjectRepliesPreview";
import getAllRepliesForIssue from "./getAllRepliesForIssue";
import postProjectMessageReply from "./postProjectMessageReply";
import archiveMessages from "./archiveMessages";
import unarchiveMessages from "./unarchiveMessages";
import markMessagesAsViewed from "./markMessagesAsViewed";
import markMessageAndRepliesAsViewed from "./markMessageAndRepliesAsViewed";
import markMessagesAsUnviewed from "./markMessagesAsUnviewed";
// IMPORT_PT (for script, do not remove)

function* watchProjectNoticesSagas() {
  yield trackRequests(takeEvery, types.GET_INBOX_LIST_REQUEST, getInboxList);
  yield trackRequests(takeEvery, types.GET_SELECTED_MESSAGE_DETAILS_REQUEST, getSelectedMessage);
  yield trackRequests(takeEvery, types.UPDATE_SELECTED_MESSAGE_REQUEST, updateMessage);
  yield trackRequests(takeEvery, types.CREATE_NEW_INBOX_MESSAGE_REQUEST, createMessage);
  yield trackRequests(takeEvery, types.RESOLVE_INBOX_ISSUE_REQUEST, resolveIssue);
  yield trackRequests(takeEvery, types.REOPEN_INBOX_ISSUE_REQUEST, reopenIssue);
  yield trackRequests(takeEvery, types.STAR_TICKET_REQUEST, starTicket);
  yield trackRequests(takeEvery, types.UNSTAR_TICKET_REQUEST, unstarTicket);
  yield trackRequests(takeEvery, types.ASSIGN_INBOX_ISSUE_REQUEST, assignProjectIssue);
  yield trackRequests(takeEvery, types.DELETE_INBOX_MESSAGE_REQUEST, deleteMessage);
  yield trackRequests(takeEvery, types.GET_PROJECT_MESSAGE_LIST_REQUEST, getProjectMessageList);
  yield trackRequests(takeEvery, types.GET_ZENDESK_FORM_DATA_REQUEST, getZendeskFormData);
  yield trackRequests(takeEvery, types.CREATE_NEW_PROJECT_MESSAGE_REQUEST, createNewProjectMessage);
  yield trackRequests(takeEvery, types.DELETE_PROJECT_MESSAGE_REQUEST, deleteProjectMessage);
  yield trackRequests(takeEvery, types.GET_PROJECT_MESSAGE_REPLIES_PREVIEW_REQUEST, getProjectRepliesPreview);
  yield trackRequests(takeEvery, types.GET_ALL_REPLIES_FOR_ISSUE_REQUEST, getAllRepliesForIssue);
  yield trackRequests(takeEvery, types.POST_PROJECT_MESSAGE_REPLY_REQUEST, postProjectMessageReply);
  yield trackRequests(takeEvery, types.ARCHIVE_MESSAGES_REQUEST, archiveMessages);
  yield trackRequests(takeEvery, types.UNARCHIVE_MESSAGES_REQUEST, unarchiveMessages);
  yield trackRequests(takeEvery, types.MARK_MESSAGES_AS_VIEWED_REQUEST, markMessagesAsViewed);
  yield trackRequests(takeEvery, types.MARK_MESSAGE_AND_REPLIES_AS_VIEWED_REQUEST, markMessageAndRepliesAsViewed);
  yield trackRequests(takeEvery, types.MARK_MESSAGES_AS_UNVIEWED_REQUEST, markMessagesAsUnviewed);
  // INSERTION_PT (for script, do not remove)
}

export default watchProjectNoticesSagas;
