import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Spin, Tooltip, Typography, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import SessionClient from "util/SessionClient";
import copy from "copy-to-clipboard";
import { buttonProps } from "lib/props";
import usePermissions, { permissionTypes } from "hooks/usePermissions";
import { AmbassadorDashboardContext as context } from "containers/Ambassador/dashboard";
import ReferralStatus from "./status";
import SocialButtons from "../Social";

const Referrals = () => {
  const { fetching, navigate, tabKeys, user, leads, styles, canCreateLead } = useContext(context);
  const session = new SessionClient();
  const { push } = useHistory();
  const { PORTAL_ACCESS } = usePermissions({
    permissionRequests: [permissionTypes.PORTAL_ACCESS],
  });
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const tooltipTimeout = "1000";
  const currencyString = (x) => {
    return `$${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const payout = () => {
    const num = session.user.props?.ambassadorEarnings;
    return currencyString(num ? parseInt(num, 10) : 0);
  };

  const handleAddReferralButton = () => {
    navigate({ tab: tabKeys[1] });
  };

  const handleClick = (e, shareUrl) => {
    setTooltipText("Link copied to clipboard");
    e.stopPropagation();
    copy(shareUrl);
    setTooltipVisible(true);
    setTimeout(() => setTooltipVisible(false), tooltipTimeout);
  };

  const shareUrl = new URL(`${window.location.origin.toString()}/whysolar`);
  if (user) {
    shareUrl.searchParams.append("ref_id", user.props.id);
  }

  const educationUrl = new URL(`${window.location.origin.toString()}/education`);
  if (user) {
    educationUrl.searchParams.append("ref_id", user.props.id);
  }

  const noReferrals = () => {
    if (canCreateLead) {
      return (
        <div className={styles.noReferrals}>
          Looks like you are ready to start! Click on the <span className={styles.blue}>plus button</span>{" "}
          <Button
            className={styles.exampleButton}
            type="primary"
            shape="circle"
            size="small"
            onClick={handleAddReferralButton}
            icon={<PlusOutlined className={styles.icon} />}
          />{" "}
          to add your first referral.
          <div className={styles.shareAffiliateLink}>
            Get a jump start by sharing your unique referral link on your social networks:
            <div className={styles.socialContainer}>
              <SocialButtons shareUrl={shareUrl} size="big" />
            </div>
            <Tooltip
              title={tooltipText}
              placement="bottom"
              trigger="click"
              open={tooltipVisible}
              color="rgba(41, 92, 160, 0.9)"
            >
              <Col className={styles.linkCol}>
                <div className={styles.linkDisplay}>
                  <Typography.Paragraph
                    ellipsis={{
                      expandable: false,
                      rows: 1,
                    }}
                    onClick={(e) => handleClick(e, shareUrl)}
                  >
                    <div className={styles.referralLinkContainer}>
                      <div className={styles.label}>Referral Link</div>
                      <span className={styles.linkButton}>{shareUrl.href}</span>
                    </div>
                  </Typography.Paragraph>
                </div>
                <div className={styles.linkDisplay}>
                  <Typography.Paragraph
                    ellipsis={{
                      expandable: false,
                      rows: 1,
                    }}
                    onClick={(e) => handleClick(e, educationUrl)}
                  >
                    <div className={styles.referralLinkContainer}>
                      <div className={styles.label}>Education Link</div>
                      <span className={styles.linkButton}>{educationUrl.href}</span>
                    </div>
                  </Typography.Paragraph>
                </div>
              </Col>
            </Tooltip>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.noReferrals}>
        You have upgraded to a Powur Sales Account and can no longer enter ambassador leads.
        <div className={styles.redirectSection}>
          <span
            role="button"
            tabIndex={0}
            onClick={() => push("/projects/leads")}
            onKeyDown={() => push("/projects/leads")}
            className={styles.linkToSellerLeads}
          >
            Click here
          </span>{" "}
          to start adding leads now!
        </div>
      </div>
    );
  };

  return (
    <div className={styles.referralTab}>
      <div className={styles.profileLinksContainer}>
        {PORTAL_ACCESS && (
          <div className={styles.consultantLink} {...buttonProps(() => push("/projects/list"))}>
            <div className={styles.icon} />
            <div className={styles.label}>Seller</div>
          </div>
        )}
        <div className={styles.profileLink} {...buttonProps(() => navigate({ tab: tabKeys[3] }))}>
          <div className={styles.icon} />
          {PORTAL_ACCESS && <div className={styles.label}>Ambassador</div>}
        </div>
      </div>
      <div className={styles.header}>
        <div className={styles.title}>Referral Dashboard</div>
        <div className={styles.scoreboard}>
          <div className={styles.score}>
            <div className={styles.total}>{payout()}</div>
            <div className={styles.payout}>Current Payout</div>
          </div>
          <div className={styles.score}>
            <div className={styles.total}>{currencyString(leads.length * 1000)}</div>
            <div className={styles.payout}>Possible Payout</div>
          </div>
        </div>
        <Button
          className={styles.addReferralButton}
          shape="circle"
          type="primary"
          onClick={handleAddReferralButton}
          icon={<PlusOutlined className={styles.icon} />}
          disabled={!canCreateLead}
        />
      </div>
      <div className={styles.referrals}>
        {fetching && (
          <div className={styles.fetching}>
            <Spin className={styles.spin} />
          </div>
        )}
        {leads.length > 0 ? leads.map((lead) => <ReferralStatus key={lead.id} lead={lead} />) : noReferrals()}
      </div>
    </div>
  );
};

export default Referrals;
