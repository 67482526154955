import React from "react";
import PropTypes from "prop-types";

const LinkIcon = (props) => {
  const { height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.83088 0.878269C6.26868 0.315915 5.50615 0 4.71097 0C3.91591 0 3.15342 0.315904 2.59118 0.878269L0.878116 2.59133V2.59118C0.315915 3.15338 0 3.91602 0 4.71109C0 5.50615 0.315904 6.26879 0.878116 6.831L4.0279 9.97004C4.46973 10.412 5.06898 10.6604 5.69389 10.6604C6.31869 10.6604 6.91794 10.412 7.35974 9.97004L7.38109 9.94868C7.54808 9.78916 7.64368 9.56885 7.64592 9.33792C7.64831 9.107 7.5572 8.88488 7.39349 8.72211C7.22979 8.5593 7.00722 8.46938 6.7763 8.47297C6.54539 8.47655 6.32568 8.57319 6.16703 8.74093L6.14777 8.76019C6.02723 8.88058 5.86397 8.9481 5.69385 8.9481C5.52358 8.9481 5.36032 8.88058 5.23979 8.76019L2.09432 5.61473C1.8531 5.37366 1.71747 5.04672 1.71747 4.70572C1.71747 4.36472 1.8531 4.03775 2.09432 3.79682L3.80738 2.08376C4.04816 1.84313 4.37482 1.70796 4.71521 1.70796C5.05561 1.70796 5.38226 1.84313 5.62304 2.08376L8.7643 5.23565C8.88468 5.35618 8.9522 5.51944 8.9522 5.68971C8.9522 5.85984 8.88469 6.02309 8.7643 6.14363L8.74294 6.16499C8.52651 6.38156 8.44182 6.69702 8.52113 6.99274C8.60044 7.28848 8.83136 7.51954 9.12711 7.59873C9.42286 7.67804 9.73845 7.5935 9.95487 7.37692L9.97623 7.35556H9.97638C10.4184 6.91373 10.6666 6.31448 10.6666 5.68972C10.6666 5.06477 10.4184 4.46552 9.97638 4.02373L6.83088 0.878269Z"
        fill="var(--nepal-blue)"
      />
      <path
        d="M8.03416 13.9765L11.1775 17.122C11.7397 17.6843 12.5023 18.0003 13.2974 18.0003C14.0925 18.0003 14.855 17.6843 15.4172 17.122L17.1303 15.4089V15.4091C17.6925 14.8469 18.0084 14.0842 18.0084 13.2892C18.0084 12.4941 17.6925 11.7315 17.1303 11.1693L13.9805 8.03022C13.5387 7.58823 12.9394 7.33984 12.3145 7.33984C11.6897 7.33984 11.0905 7.58824 10.6487 8.03022L10.6273 8.05157C10.4107 8.26815 10.3262 8.58361 10.4055 8.87933C10.4847 9.17507 10.7157 9.40613 11.0115 9.48532C11.3072 9.56463 11.6227 9.48009 11.8392 9.26351L11.8606 9.24215C11.9811 9.12191 12.1444 9.05425 12.3145 9.05425C12.4848 9.05425 12.648 9.12191 12.7686 9.24215L15.914 12.3855C16.1547 12.6264 16.2898 12.953 16.2898 13.2935C16.2898 13.6339 16.1547 13.9604 15.914 14.2013L14.201 15.9144C13.9602 16.155 13.6335 16.2902 13.2932 16.2902C12.9528 16.2902 12.6261 16.155 12.3853 15.9144L9.24407 12.7646C9.12308 12.6441 9.05512 12.4804 9.05512 12.3095C9.05512 12.1388 9.12308 11.9751 9.24407 11.8546L9.26543 11.8332C9.472 11.6145 9.54892 11.3035 9.46782 11.0139C9.38671 10.7242 9.15953 10.4983 8.86948 10.4187C8.57942 10.3391 8.26889 10.4177 8.05128 10.6254L8.03202 10.6447C7.59003 11.0865 7.3418 11.6858 7.3418 12.3105C7.3418 12.9355 7.59004 13.5347 8.03202 13.9765L8.03416 13.9765Z"
        fill="var(--nepal-blue)"
      />
      <path
        d="M12.2563 13.109C12.4832 13.108 12.7004 13.0172 12.8602 12.8563C13.0202 12.6954 13.1096 12.4778 13.1093 12.2509C13.1089 12.0242 13.0185 11.8067 12.8581 11.6464L6.35702 5.14535C6.13641 4.95655 5.83527 4.89232 5.55702 4.97463C5.27861 5.05692 5.06082 5.2747 4.97853 5.55312C4.89623 5.83138 4.96046 6.13249 5.14925 6.35311L11.6503 12.8542C11.8105 13.0163 12.0285 13.108 12.2563 13.109L12.2563 13.109Z"
        fill="var(--nepal-blue)"
      />
    </svg>
  );
};

LinkIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

LinkIcon.defaultProps = {
  width: "18",
  height: "18",
};

export default LinkIcon;
