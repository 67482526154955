import type { EnterpriseApplicationState } from "./reducers";

interface RootState {
  enterpriseApplications: EnterpriseApplicationState;
}

export default {
  selectEnterpriseApplications: (state: RootState) => state.enterpriseApplications.applicationsList,
  selectEnterpriseApplicationDetail: (state: RootState) => state.enterpriseApplications.detail,
  selectEnterpriseApplicationDetailId: (state: RootState) => state.enterpriseApplications.detail.id,
  selectUserLaborApplicationStatus: (state: RootState) => state.enterpriseApplications.user,
  selectEnterpriseApplicationsCSV: (state: RootState) => state.enterpriseApplications.enterpriseApplicationCsvData,
};
