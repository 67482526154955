import React from "react";
import PropTypes from "prop-types";

const CircleCheckGradient = (props) => {
  const { height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_3495_1090)">
        <rect x="1.11133" y="0.663086" width="20" height="20" rx="10" fill="url(#paint0_linear_3495_1090)" />
      </g>
      <path
        d="M15.6334 5.73971C15.1267 5.32276 14.3894 5.40886 13.9862 5.93157L9.41157 13.0422L7.41475 10.9804C7.0039 10.5548 6.35893 10.5334 5.97402 10.9303C5.58981 11.3269 5.6109 11.9933 6.0221 12.4184L9.03323 15.53C9.38528 15.893 9.89849 15.942 10.2837 15.7058C10.4497 15.6293 10.6019 15.5175 10.7233 15.3607L15.8184 7.44038C16.2223 6.91803 16.1383 6.15702 15.6334 5.73971Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_3495_1090"
          x="0.111328"
          y="0.163086"
          width="22"
          height="22"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3495_1090" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3495_1090" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_3495_1090"
          x1="-3.27464"
          y1="3.11207"
          x2="24.8047"
          y2="12.1121"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--congress-blue)" />
          <stop offset="0.416667" stopColor="var(--blue-ribbon)" />
          <stop offset="1" stopColor="var(--sweet-mint)" stopOpacity="0.83" />
        </linearGradient>
      </defs>
    </svg>
  );
};

CircleCheckGradient.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

CircleCheckGradient.defaultProps = {
  height: "10",
  width: "14",
};

export default CircleCheckGradient;
