import _ from "lodash";

/**
 * Given an array of tree data with unknown number and depth of descendants,
 * returns array with all child elements on same level with children array
 * removed.
 * @param {Object[]} children - An array of trees of unknown depth we wish to
 * flatten to asingle array of members with hierarchy removed.
 * @param {string} childKey - The key in which child nodes are stored.
 * Specify this argument if child nodes array's key is not "children".
 * @returns {Object[]} - Flat array of all member nodes with hierarchy removed.
 *
 * Example:
 * INPUT: [
 * {
 *   id: 1,
 *   children: [
 *     {
 *       id: 4,
 *     },
 *     {
 *       id: 5,
 *       children: [
 *         {
 *           id: 7,
 *         },
 *         {
 *           id: 8,
 *         },
 *       ],
 *     },
 *     {
 *       id: 6,
 *     },
 *   ],
 * },
 * {
 *   id: 2,
 * },
 * {
 *   id: 3,
 * },
 * ]
 * OUTPUT
 * [{ id: 1 }, { id: 4 }, { id: 5 }, { id: 7 },
 * { id: 8 }, { id: 6 }, { id: 2 }, { id: 3 }]
 *
 */
export default function flattenTree(children, childKey = "children") {
  function recurse(array) {
    return array.reduce((acc, x) => {
      /* eslint-disable no-param-reassign */
      acc = acc.concat(x);
      if (x[childKey]) {
        acc = acc.concat(recurse(x[childKey]));
        delete x[childKey];
      }
      return acc;
      /* eslint-enable no-param-reassign */
    }, []);
  }

  return recurse(_.cloneDeep(children));
}
