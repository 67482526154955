import React from "react";
import { useTranslation } from "react-i18next";
import useWindowResize from "hooks/useWindowResize";
import JoinPowurTodayButton from "../JoinPowurTodayButton";

import * as S from "./styles";

type GetSignedUpPropsT = {
  onJoinClick: () => void;
  detailedVersion?: boolean;
};

const GetSignedUp = ({ detailedVersion = false, onJoinClick }: GetSignedUpPropsT) => {
  const { t }: { t: (textRef: string) => string } = useTranslation(["setter_join"]);
  const { isMobileWidth } = useWindowResize();
  const buttonText = isMobileWidth ? t("join_today_button_text") : t("join_today_button_text_long");
  return (
    <S.GetSignedUpContainer>
      <S.MaxWidthContainer container spacing={3} justifyContent="center">
        <S.HeaderText item xs={12} lg={detailedVersion ? 12 : 6} centerContent={detailedVersion}>
          {t("easy_signup")}
        </S.HeaderText>
        <S.ButtonColumn item xs={12} lg={detailedVersion ? 9 : 6} centerContent={detailedVersion}>
          <S.SubheadingText
            fontFamily={detailedVersion ? "Sora" : "Barlow"}
            fontSize={detailedVersion ? "28px" : "18px"}
            lineHeight={detailedVersion ? "32px" : "22px"}
          >
            {t("cost_free")}
          </S.SubheadingText>
          {detailedVersion && !isMobileWidth && <S.DetailText>{t("onboard_and_certify")}</S.DetailText>}
          <JoinPowurTodayButton onClick={onJoinClick} buttonText={buttonText} maxWidth="475px" />
        </S.ButtonColumn>
      </S.MaxWidthContainer>
    </S.GetSignedUpContainer>
  );
};

export default GetSignedUp;
