import z from "zod";

export const PageSchema = z.object({
  name: z.string(),
  title: z.string(),
  url: z.string(),
  views: z.string().or(z.number()),
  omit: z.boolean().nullish(),
});

export const MarketingResponseBodySchema = z.object({
  pages: z.array(PageSchema),
  facebookPixelId: z.string().nullish(),
});

export const MarketingResponseSchema = z.object({
  status: z.number(),
  body: MarketingResponseBodySchema,
});
