import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/financials/messages";
import formatList from "./util/formatPayoutsList";

function* topUpPayoutsRequest() {
  const { success, data, error } = yield* makeRequest.post("/payouts/topup", {});

  if (success && data) {
    yield all([
      put(actions.setPayoutsList(formatList(data))),
      put(actions.newSuccessEvent({ message: messages.TOPUP_SUCCESS })),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.TOPUP_ERROR,
    });
  }

  return null;
}

export default topUpPayoutsRequest;
