// React
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import { useParams } from "react-router-dom";

// Hooks
import useNavigation from "hooks/useNavigation";
import useRestrictAccessModal from "hooks/useRestrictAccessModal";

import SessionClient from "util/SessionClient";

// Components
import ResetFiltersButton from "@buttons/ResetFilters";
import OpenDrawerButton from "components/PlatformLayout/components/Sidebar/OpenDrawerButton";
import SidebarSearch from "components/PlatformLayout/components/Sidebar/Search";
import teamTabs from "../../tabs";
import LeadsFilters from "./leadsfilters";
import AmbassadorFilters from "./ambassadorfilters";

import styles from "../../MyTeam.module.less";

const Sidebar = (props) => {
  const { setInviteDrawerVisible, inviteDrawerVisible } = props;

  const { tab } = useParams();
  const tabKeys = teamTabs.map(({ key }) => key);
  const { navigate, override } = useNavigation("team", ["tab"], ["userId", "drawerTab"]);
  const { backOfficeAccessTrue, restrictAccessModal } = useRestrictAccessModal();

  const session = new SessionClient();
  const { isSeatedSeller, isEnterpriseStaffOnly } = session;

  const sessionUserRecruitBarred = isSeatedSeller && !session.user.props.enterpriseSellerInvitesAllowed;

  const handleOpenDrawer = () => setInviteDrawerVisible(!inviteDrawerVisible);

  if (tab === tabKeys[0]) return null;
  return (
    <div className={styles.sidebar}>
      {tab !== tabKeys[0] && (!sessionUserRecruitBarred || isEnterpriseStaffOnly) && (
        <OpenDrawerButton
          handleClick={() => (backOfficeAccessTrue ? handleOpenDrawer() : restrictAccessModal())}
          drawerOpen={inviteDrawerVisible}
          title="Add New"
        />
      )}
      {(tab === tabKeys[3] || tab === tabKeys[1]) && <SidebarSearch navigate={navigate} />}
      {tab === tabKeys[3] && <LeadsFilters navigate={navigate} override={override} />}
      {tab === tabKeys[1] && (
        <>
          <AmbassadorFilters navigate={navigate} override={override} />
          <ResetFiltersButton />
        </>
      )}
    </div>
  );
};

Sidebar.propTypes = {
  inviteDrawerVisible: PropTypes.bool,
  setInviteDrawerVisible: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    requests: "getActiveRequests",
    latestMessage: "getLatestMessageEvt",
    inviteDrawerVisible: "getInviteDrawerVisible",
  }),
  getRdxActionMapper(["setInviteDrawerVisible"]),
)(Sidebar);
