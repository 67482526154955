import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/registration/types";
import getRegistration from "./getRegistration";
import getSeatedSellerRegistrationInfo from "./getSeatedSellerRegistrationInfo";
import createNewUser from "./createNewUser";
import registerNewUser from "./registerNewUser";
import recruitRejectPendingSeat from "./recruitRejectPendingSeat";
import updateRegistration from "./updateRegistration";
// IMPORT_PT (for script, do not remove)

function* watchRegistrationSagas() {
  yield trackRequests(takeEvery, types.GET_REGISTRATION_INFO, getRegistration);
  yield trackRequests(takeEvery, types.GET_SEATED_SELLER_REGISTRATION_INFO, getSeatedSellerRegistrationInfo);
  yield trackRequests(takeEvery, types.CREATE_NEW_USER, createNewUser);
  yield trackRequests(takeEvery, types.REGISTER_NEW_USER, registerNewUser);
  yield trackRequests(takeEvery, types.RECRUIT_REJECT_PENDING_SEAT, recruitRejectPendingSeat);
  yield trackRequests(takeEvery, types.UPDATE_REGISTRATION, updateRegistration);
  // INSERTION_PT (for script, do not remove)
}

export default watchRegistrationSagas;
