import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const RequestedIcon = ({
  width = "28",
  height = "26",
  startColor = cvar("picton-blue"),
  stopColor = cvar("blue-ribbon"),
}: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_167_81)">
        <path
          d="M24.5438 0H14C12.0911 0 10.5438 1.43417 10.5438 3.20934V17.8952C10.5438 18.5183 11.3133 18.8827 11.8563 18.5046L15.1521 16.2093H24.5438C26.4528 16.2093 28 14.7752 28 13V3.20934C28 1.43675 26.4555 0 24.5438 0ZM18.4515 12.13H14.8787C14.4256 12.13 14.0583 11.789 14.0583 11.3683C14.0583 10.9475 14.4256 10.6065 14.8787 10.6065H18.4515C18.9046 10.6065 19.2718 10.9475 19.2718 11.3683C19.2718 11.789 18.9046 12.13 18.4515 12.13ZM23.6652 8.86649H14.8787C14.4256 8.86649 14.0583 8.5253 14.0583 8.10477C14.0583 7.68404 14.4256 7.34305 14.8787 7.34305H23.6652C24.1181 7.34305 24.4855 7.68404 24.4855 8.10477C24.4855 8.5253 24.1183 8.86649 23.6652 8.86649ZM23.6652 5.6028H14.8787C14.4256 5.6028 14.0583 5.26181 14.0583 4.84108C14.0583 4.42055 14.4256 4.07936 14.8787 4.07936H23.6652C24.1181 4.07936 24.4855 4.42055 24.4855 4.84108C24.4855 5.26181 24.1183 5.6028 23.6652 5.6028Z"
          fill="url(#paint0_linear_167_81)"
        />
        <path
          d="M9.09119 15.8386C9.09119 17.8272 7.35507 19.4393 5.2135 19.4393C3.07193 19.4393 1.33582 17.8272 1.33582 15.8386C1.33582 13.8498 3.07193 12.2377 5.2135 12.2377C7.35507 12.2377 9.09119 13.8498 9.09119 15.8386Z"
          fill="url(#paint1_linear_167_81)"
        />
        <path
          d="M5.21347 19.4393C2.33426 19.4393 0 21.6069 0 24.2804V25.2383C0 25.659 0.367218 26 0.820312 26H9.60684C10.0597 26 10.4272 25.659 10.4272 25.2383V24.2804C10.4272 21.6069 8.0929 19.4393 5.21347 19.4393Z"
          fill="url(#paint2_linear_167_81)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_167_81"
          x1="13.6063"
          y1="-7.77425"
          x2="29.0369"
          y2="-6.33221"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_167_81"
          x1="2.69641"
          y1="9.237"
          x2="9.53262"
          y2="9.97236"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_167_81"
          x1="1.82933"
          y1="16.7057"
          x2="10.8984"
          y2="18.1455"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <clipPath id="clip0_167_81">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RequestedIcon;
