import types from "rdx/modules/adminLeads/types";
import createAction from "rdx/utils/createAction";

export default {
  // exampleAction: payload => createAction(types.EXAMPLE_ACTION_TYPE, payload),

  getAdminLeadsList: (payload) => createAction(types.GET_ADMIN_LEADS_LIST, payload),

  setAdminLeadsList: (payload) => createAction(types.SET_ADMIN_LEADS_LIST, payload),

  setAdminLeadsListFilters: (payload) => createAction(types.SET_ADMIN_LEADS_LIST_FILTERS, payload),
  // INSERTION_PT (for script, do not remove)
};
