import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "rdx/modules/sellerIntegrity/messages";
import { camelizeKeys, keysToSnakeCase, formatIntegrityModules } from "lib/helpers";
import { UpdateViolationModulesPayloadT } from "containers/Admin/users/components/UserDetailDrawer/types";

type RemoveModuleFromViolationPropsT = {
  payload: UpdateViolationModulesPayloadT;
};

function* removeModuleFromViolation(action: RemoveModuleFromViolationPropsT) {
  const { id: violationId } = action.payload;
  const params = keysToSnakeCase(action.payload);

  const { success, data, error } = yield* makeRequest.patch(
    `/seller_integrity_violations/${violationId}/remove_module`,
    params,
  );

  if (success && data) {
    const violations = yield select(selectors.selectIntegrityViolations);
    const clone = [...violations];
    const idx = clone.findIndex((violation) => {
      return violation.id === violationId;
    });
    const updatedViolation = camelizeKeys(data.body);
    updatedViolation.violationModules = formatIntegrityModules(updatedViolation.violationModules);
    clone[idx] = camelizeKeys(updatedViolation);

    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_REQUIRED_MODULES_SUCCESS,
        }),
      ),
      put(actions.setIntegrityViolations({ violations: clone })),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_REQUIRED_MODULES,
    });
  }

  return null;
}

export default removeModuleFromViolation;
