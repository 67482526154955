import { select, put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "../messages";

function* approveSeatApproval(action) {
  const { approvalId, seller_percentage, seller_invites_allowed } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/enterprise_seat_recruits/${approvalId}/approve`, {
    seller_percentage,
    seller_invites_allowed,
  });

  if (success && data) {
    const approvals = yield select(selectors.selectSeatApprovals);
    const out = approvals.list.filter((a) => a.id !== approvalId);

    yield all([
      yield put(actions.setSeatApprovals({ ...approvals, list: out })),
      put(
        actions.newSuccessEvent({
          message: messages.APPROVE_SEAT_APPROVALS_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.APPROVE_SEAT_APPROVALS_ERROR,
    });
  }
  return null;
}

export default approveSeatApproval;
