import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { isArray } from "lodash";

import { Drawer, Grid, Popover } from "@mui/material";
import { FilterIcon, DownCaretFilled, DownCaret, CloseIcon } from "components/Icons";

import { actions, selectors } from "rdx";
import { Spin } from "antd";

import { theme } from "styles/themes";
import useWindowResize from "hooks/useWindowResize";
import SCREEN_SIZES from "util/screenSizes";
import FiltersList from "./filtersList";
import {
  DefaultFilterButton,
  PopOverPaperStyles,
  FiltersHeaderTitle,
  FiltersHeaderStatistics,
  FiltersButton,
  NavigateButton,
  FilterTitle,
  MobileFooter,
  LoadingOverlay,
} from "./styles";

import { FiltersT } from "./types";
import AppliedFilters from "./appliedFilters";
import { findFilterDateRangeItem, UrlStringToObject, handleHeight } from "./logic";
import FilterSetModal from "./modals";
import FilterSets from "./filterSets";

type FiltersPropsT = {
  renderItem?: React.ReactNode;
  sortItems?: FiltersT;
  isLoading?: boolean;
};

const Filters = ({ renderItem = null, sortItems = { filters: [] }, isLoading }: FiltersPropsT) => {
  const [horizontalScrollPosition, setHorizontalScrollPosition] = useState(0);
  const horizontalScrollContainer = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState<"create" | "delete">("create");
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const dispatch = useDispatch();
  const preferredView = useSelector(selectors.getPreferredView);
  const filterSets = useSelector(selectors.getFilterSets);
  const {
    paging: { itemCount: projectsCount, totalCount: projectsTotal },
  } = useSelector(selectors.getAdminProjectsList);

  const {
    paging: { totalCount: totalLeads, itemCount: countLeads },
  } = useSelector(selectors.getLeads);

  const history = useHistory();
  const { width } = useWindowResize();

  const columnWidth = 169;

  const mobile = width <= SCREEN_SIZES.sm;
  const appliedFiltersCount = Object.entries(preferredView.filters).filter(([, val]) => val !== null).length;

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleModal = (id?: number) => {
    if (id) {
      setDeleteId(id);
      setModalType("delete");
    }
    setModalShow((prev) => !prev);
  };

  const toggleMobileDrawer = () => {
    setOpenDrawer((prev) => !prev);
  };

  const handleScroll = () => {
    if (horizontalScrollContainer.current) {
      setHorizontalScrollPosition(horizontalScrollContainer.current.scrollLeft);
    }
  };

  const handleScrollLeft = () => {
    if (horizontalScrollContainer.current) {
      horizontalScrollContainer.current.scrollLeft -= columnWidth * 4; // move left by 4 elements
      setHorizontalScrollPosition(horizontalScrollContainer.current.scrollLeft);
    }
  };
  const handleScrollRight = () => {
    if (horizontalScrollContainer.current) {
      horizontalScrollContainer.current.scrollLeft += columnWidth * 4; // move right by 4 elements
      setHorizontalScrollPosition(horizontalScrollContainer.current.scrollLeft);
    }
  };

  const handleSelection = (
    key: string,
    value: Record<string, unknown>,
    multiSelect = false,
    allowUpdateAction = true,
  ) => {
    const payload = {
      key,
      value,
      multiSelect,
      view: history.location.pathname,
      allowUpdate: allowUpdateAction,
    };
    dispatch(actions.updatePreferredView(payload));
  };

  const handleDelete = (key: string, id: string | number) => {
    const payload = { key, id };
    dispatch(actions.deletePreferredViewFilter(payload));
  };

  const clearFilters = () => {
    dispatch(actions.clearPreferredView());
    history.push({ search: "" });
  };

  const checkIfSelected = (key: string, id: string | number): boolean => {
    if (!preferredView.filters[key]) return false;
    if (isArray(preferredView.filters[key])) {
      return !!preferredView.filters[key].find((select) => select.id === id);
    }
    return preferredView.filters[key].id === id;
  };

  const handleSaveFilterSet = (name: string) => {
    dispatch(actions.setPreferredViewIsTouched(true));
    dispatch(
      actions.createFilterSet({
        name,
        query_url: searchUrl,
        view: history.location.pathname,
      }),
    );
  };

  const handleDeleteFilterSet = (id: number) => {
    dispatch(actions.deleteFilterSet({ id }));
    setDeleteId(null);
    toggleModal();
  };

  const getQueryParam = useCallback(
    (name: string) => {
      const query = new URLSearchParams(history.location.search);
      return query.get(name);
    },
    [history.location.search],
  );

  const formatUrlParam = useCallback(
    (filterKey: string): string => {
      if (Array.isArray(preferredView.filters[filterKey])) {
        return `${filterKey}=${preferredView.filters[filterKey]
          .map((el: Record<string, unknown>) => el.id)
          .join(filterKey === "city_name" ? "-" : "%2C")}`;
      }
      if (preferredView.filters[filterKey].value) {
        return `${preferredView.filters[filterKey].value
          ?.map((el: Record<string, unknown>) => `${el.id}=${el.value}`)
          .join("&")}`;
      }
      return `${filterKey}=${preferredView.filters[filterKey].id}`;
    },
    [preferredView.filters],
  );

  const searchUrl = useMemo(() => {
    const sortValue = getQueryParam("sort");
    const filterParamKeyArray = Object.keys(preferredView.filters).filter((key) => preferredView.filters[key] !== null);
    const filterParams = filterParamKeyArray.map((key: string) => formatUrlParam(key)).join("&");

    return sortValue ? `${filterParams}&sort=${sortValue}` : filterParams;
  }, [preferredView.filters, getQueryParam, formatUrlParam]);

  const UrlObject = useMemo(
    () => (history.location.search.length > 0 ? UrlStringToObject(history.location.search) : []),
    [history.location.search],
  ) as Record<string, unknown[]>;

  const filterIsActive = useMemo(() => appliedFiltersCount > 0, [appliedFiltersCount]);
  const filtersPresent = useMemo(
    () => Object.keys(preferredView.filters).filter((key) => preferredView.filters[key] !== null).length > 0,
    [preferredView.filters],
  );
  const setsPresent = useMemo(() => filterSets.length > 0, [filterSets]);

  useEffect(() => {
    dispatch(actions.getSelectedLookupFilters(UrlObject));
    sortItems?.filters?.forEach((filter) => {
      if (!["created_at", "updated_at", "city_name", "enrolledAt"].includes(filter.paramKey)) {
        if (UrlObject[filter.paramKey]) {
          const selectedOptions = filter.options?.filter((option) => UrlObject[filter.paramKey].includes(option.id));
          if (selectedOptions && selectedOptions?.length > 0)
            dispatch(actions.updatePreferredViewBulk({ [filter.paramKey]: selectedOptions }));
        } else {
          dispatch(actions.updatePreferredViewBulk({ [filter.paramKey]: null }));
        }
      } else {
        const fromUrlParam = UrlObject?.from || UrlObject?.enrolledFrom;
        const toUrlParam = UrlObject?.to || UrlObject?.enrolledTo;
        const dateFilterOption = findFilterDateRangeItem(
          fromUrlParam && (fromUrlParam[0] as string),
          toUrlParam && (toUrlParam[0] as string),
          filter.options || [],
        );

        if (dateFilterOption) handleSelection(filter.paramKey, dateFilterOption, false, false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UrlObject]);

  useEffect(() => {
    if (preferredView.allowUpdate) {
      history.push({ search: searchUrl });
      dispatch(actions.setPreferredViewAllowUpdate(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchUrl]);

  const handleApplyFilter = () => {
    history.push({ search: searchUrl });
    toggleMobileDrawer();
  };

  const FilterItem = renderItem || (
    <DefaultFilterButton
      isActive={filterIsActive}
      isMobile={mobile}
      variant="contained"
      onClick={mobile ? toggleMobileDrawer : handleOpen}
    >
      {!mobile && (
        <>
          <span>
            <FilterIcon
              stroke={filterIsActive ? "white" : theme.colors["blue-ribbon"]}
              fill={filterIsActive ? "white" : theme.colors["blue-ribbon"]}
            />
            &nbsp;&nbsp;Filters &nbsp;
            {filterIsActive && `/${appliedFiltersCount}`}
          </span>
          <span>
            <DownCaretFilled fill={filterIsActive ? "white" : theme.colors["blue-ribbon"]} />
          </span>
        </>
      )}

      {mobile && (
        <span>
          <FilterIcon
            stroke={filterIsActive ? "white" : theme.colors["blue-ribbon"]}
            fill={filterIsActive ? "white" : theme.colors["blue-ribbon"]}
          />
        </span>
      )}
    </DefaultFilterButton>
  );

  return (
    <>
      {FilterItem}
      {!mobile && (
        <Popover
          PaperProps={{
            sx: { ...PopOverPaperStyles, height: handleHeight(filtersPresent, setsPresent) },
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {isLoading ? (
            <LoadingOverlay>
              <Spin />
            </LoadingOverlay>
          ) : (
            <>
              {sortItems.filters && (
                <>
                  <NavigateButton hidden={horizontalScrollPosition === 0} way="left" onClick={handleScrollLeft}>
                    <DownCaret fill={theme.colors["darkest-blue"]} containerStyles={{ transform: "rotateZ(90deg)" }} />
                  </NavigateButton>
                  <NavigateButton way="right" onClick={handleScrollRight} hidden={sortItems.filters.length < 5}>
                    <DownCaret fill={theme.colors["darkest-blue"]} containerStyles={{ transform: "rotateZ(-90deg)" }} />
                  </NavigateButton>
                </>
              )}
              <Grid container height={1}>
                <Grid item xs={12} height="53px">
                  <Grid container p={0} height={1} padding="0px 26px">
                    <Grid item xs={8} display="flex" alignItems="center">
                      <FiltersHeaderTitle>Filters</FiltersHeaderTitle>
                      {(totalLeads > 0 || projectsTotal > 0) && (
                        <FiltersHeaderStatistics>
                          Showing {countLeads || projectsCount || 0} of {totalLeads || projectsTotal} projects under
                          selected filters
                        </FiltersHeaderStatistics>
                      )}
                    </Grid>
                    <Grid item xs={4} display="flex" alignItems="center" justifyContent="flex-end">
                      <FiltersButton style={{ marginRight: "36px" }} onClick={clearFilters}>
                        Clear All
                      </FiltersButton>
                      <FiltersButton
                        onClick={() => {
                          setModalType("create");
                          toggleModal();
                        }}
                        isContained
                        disabled={!preferredView.isTouched}
                      >
                        Create Filter Set
                      </FiltersButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} height="230px">
                  <FiltersList
                    mobile={mobile}
                    handleScroll={handleScroll}
                    sortItems={sortItems}
                    preferredView={preferredView}
                    handleDelete={handleDelete}
                    checkIfSelected={checkIfSelected}
                    handleSelection={handleSelection}
                    horizontalScrollContainer={horizontalScrollContainer}
                  />
                </Grid>
                <Grid
                  display={filtersPresent || setsPresent ? "flex" : "none"}
                  alignItems="center"
                  item
                  xs={12}
                  borderTop={`1px solid ${theme.colors["botticelli-blue"]}`}
                  paddingLeft="27px"
                  paddingTop="19px"
                  {...(setsPresent && { paddingBottom: "20px" })}
                >
                  <Grid container>
                    {filtersPresent && (
                      <>
                        <Grid item xs={12} pb={0}>
                          <FilterTitle>Applied Filters</FilterTitle>
                        </Grid>
                        <Grid item xs={12} pb={2} whiteSpace="nowrap" overflow={["auto", "none"]}>
                          <AppliedFilters handleDelete={handleDelete} />
                        </Grid>
                      </>
                    )}
                    {setsPresent && (
                      <>
                        <Grid item xs={12} pb={2}>
                          <FilterTitle>Filter Sets</FilterTitle>
                        </Grid>
                        <Grid item xs={12} whiteSpace="nowrap" overflow={["auto", "none"]}>
                          <FilterSets
                            filterSets={filterSets}
                            handleClick={(path) => {
                              history.push({ search: path });
                              dispatch(actions.setPreferredViewIsTouched(false));
                            }}
                            handleDelete={toggleModal}
                            view={history.location.pathname}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Popover>
      )}
      {mobile && (
        <Drawer
          open={openDrawer}
          onClose={toggleMobileDrawer}
          anchor="bottom"
          PaperProps={{ style: { height: "calc(100% - 24px)", borderRadius: "10px 10px 0px 0px" } }}
        >
          <Grid container height="58px" sx={{ background: theme.colors["ma-white"] }}>
            <Grid item xs={2} display="flex" alignItems="center" justifyContent="flex-start">
              <FiltersButton style={{ background: "transparent" }} onClick={toggleMobileDrawer}>
                <CloseIcon stopFill={theme.colors["darkest-blue"]} startFill={theme.colors["darkest-blue"]} />
              </FiltersButton>
            </Grid>
            <Grid item xs={8} display="flex" alignItems="center" justifyContent="center">
              <FiltersHeaderTitle>Filters</FiltersHeaderTitle>
            </Grid>
            <Grid item xs={2} display="flex" alignItems="center" justifyContent="flex-start">
              <FiltersButton style={{ background: "transparent" }} onClick={clearFilters}>
                Clear All
              </FiltersButton>
            </Grid>
          </Grid>
          <FiltersList
            mobile={mobile}
            handleScroll={handleScroll}
            sortItems={sortItems}
            preferredView={preferredView}
            handleDelete={handleDelete}
            checkIfSelected={checkIfSelected}
            handleSelection={handleSelection}
            horizontalScrollContainer={horizontalScrollContainer}
          />
          <MobileFooter>
            <Grid container height={1} spacing={1} mt={0} pl={2} pr={2}>
              <Grid item xs={12} pt={0}>
                <FilterTitle>Applied Filters</FilterTitle>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  paddingTop: 0,
                  whiteSpace: "nowrap",
                  overflowX: "auto",
                  "&::-webkit-scrollbar": { display: "none" },
                }}
              >
                <AppliedFilters handleDelete={handleDelete} />
              </Grid>
              <Grid item xs={12} pb={0}>
                <FilterTitle>Filter Sets</FilterTitle>
              </Grid>
              <Grid item xs={12} pb={0} whiteSpace="nowrap" overflow={["auto", "none"]}>
                <FilterSets
                  filterSets={filterSets}
                  handleClick={(path) => {
                    history.push({ search: path });
                    dispatch(actions.setPreferredViewIsTouched(false));
                  }}
                  handleDelete={toggleModal}
                  view={history.location.pathname}
                />
              </Grid>
              <Grid item xs={6} display="flex" alignItems="center">
                <FiltersButton
                  fullWidth
                  onClick={() => {
                    setModalType("create");
                    toggleModal();
                  }}
                  isMobile
                  disabled={!preferredView.isTouched}
                >
                  Create Filter Set
                </FiltersButton>
              </Grid>
              <Grid item xs={6} display="flex" alignItems="center">
                <FiltersButton fullWidth isContained onClick={handleApplyFilter} disabled={!preferredView.isTouched}>
                  Apply Filter
                </FiltersButton>
              </Grid>
            </Grid>
          </MobileFooter>
        </Drawer>
      )}
      <FilterSetModal
        type={modalType}
        isOpen={modalShow}
        toggleModal={toggleModal}
        handleCreate={handleSaveFilterSet}
        handleDelete={handleDeleteFilterSet}
        id={deleteId}
      />
    </>
  );
};

export default Filters;
