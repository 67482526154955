import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enterpriseProjects/messages";

function* submitSellerOfferLeadDecision(action) {
  const { projectOfferId, decision, message, userId, type, page } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/project_offers/${projectOfferId}/offer_responses`, {
    decision,
    message,
  });

  if (success && data) {
    if (page && type) {
      const kpiType = type.replace("-", "_");
      yield put(actions.getKpiData({ userId, type: kpiType, page }));
    }
    yield put(actions.setAlertMessageVisible({ message: `Successfully ${decision} lead offer.`, severity: "success" }));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_SUBMITTING_ENTERPRISE_LEAD_OFFER_DECISION,
    });
  }

  return null;
}

export default submitSellerOfferLeadDecision;
