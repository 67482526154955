// import content from "containers/Admin/users";
import tabs from "containers/Admin/users/tabs";
import sidebar from "containers/Admin/users/components/Sidebar";
import { TeamIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";

const content = l(() => import("containers/Admin/users"));
// const sidebar = l(() => import("containers/Admin/users/components/Sidebar"));

const { USERS_READ } = permissionTypes;

export default {
  key: "admin-users",
  title: "Users",
  path: "/users/:tab?/:userId?/:drawerTab?",
  basePath: "users",
  requiredParams: ["tab"],
  optionalParams: ["userId", "drawerTab"],
  linkTarget: "/users",
  linkTitle: "Users",
  linkCategory: "Admin",
  icon: TeamIcon,
  permission: USERS_READ,
  tabs,
  content,
  sidebar,
};
