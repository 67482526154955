import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import msgs from "rdx/modules/leads/messages";
import { useHistory } from "react-router-dom";
import { parse, stringify } from "query-string";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import useModalControls from "hooks/useModalControls";
import MessageEvent from "models/MessageEvent";
import FileUploader from "components/FileUploader/FileUploader";
import styles from "./UtilityBillModal.module.less";
import common from "../HomepageComponents.module.less";

const UtilityBillModal = (props) => {
  const { latestMessage, clearLatestMessage, updateLeadUtilityBill, setAlertMessageVisible } = props;
  const { t } = useTranslation(["home", "translation", "form_labels"]);
  const { location } = useHistory();
  const history = useHistory();
  // const { user: userSlug } = useParams();

  const search = parse(location.search);

  const { visible, open, close } = useModalControls();

  const mobileView = window.innerWidth <= 720;
  const { ref_id } = search;

  const closeModal = () => {
    setAlertMessageVisible({
      message: "Success, your inquiry has been sent.",
      severity: "success",
    });
    history.push({
      pathname: history.location.pathname,
      search: `?${stringify({ ref_id })}`,
    });
    close();
  };

  const skipForNow = () => {
    setAlertMessageVisible({
      message: "Success, your inquiry has been sent.",
      severity: "success",
    });
    history.push({
      pathname: history.location.pathname,
      search: `?${stringify({ ref_id, success: true })}`,
    });
    close();
  };

  const onUtilityBillUploadFinish = (url) => {
    const { id } = search;
    updateLeadUtilityBill({ leadId: id, utility_bill_url: url });
    closeModal();
  };

  const onUtilityBillUploadError = () => {
    setAlertMessageVisible({
      message: t("translation:bad_request_error_msg"),
      severity: "error",
    });
  };

  const uploaderContents = (text) => (
    <div
      style={{
        color: "white",
        textTransform: "uppercase",
        fontSize: "16px",
      }}
    >
      {text}
    </div>
  );

  useEffect(() => {
    return () => clearLatestMessage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (latestMessage.message && latestMessage.message === msgs.LEAD_UPDATE_SUCCESS) {
      clearLatestMessage();
      setAlertMessageVisible({
        message: "Success, your inquiry has been sent.",
        severity: "success",
      });
      history.push({
        pathname: history.location.pathname,
        search: `?${stringify({ ref_id, success: true })}`,
      });
      close();
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { id } = search;
    if (id) open();
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      bodyStyle={{
        minWidth: mobileView ? "300px" : "720px",
        padding: 0,
      }}
      className={styles.billModal}
      open={visible}
      footer={null}
      mask
      maskClosable
      centered
      keyboard
      destroyOnClose
      onCancel={closeModal}
      onClose={closeModal}
      closeIcon={<CloseOutlined style={{ color: "white" }} />}
    >
      <div className={styles.container}>
        <div className={styles.headerBg}>
          <div className={[common.header, styles.header].join(" ")}>
            <div className={[common.headerText, styles.headerText].join(" ")}>{t("quote_modal.one_last_step")}</div>
            <div className={[common.headerSubtext, styles.headerSubtext].join(" ")}>
              {t("quote_modal.upload_utility_bill_prompt")}
            </div>
          </div>
        </div>
        <div className={styles.inner}>
          <div className={styles.image} />

          <div className={styles.paragraph}>{t("quote_modal.utility_bill_explain")}</div>
          <div>
            <FileUploader
              onFinished={onUtilityBillUploadFinish}
              onError={onUtilityBillUploadError}
              apiMode="lead_utility_bill"
              height="54px"
              containerProps={{
                style: {
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "70px",
                  height: "100%",
                  width: "320px",
                  textTransform: "uppercase",
                  border: "none",
                  backgroundColor: "var(--primary-lite)",
                  borderRadius: "96px",
                  fontSize: "16px",
                  fontWeight: "500",
                },
              }}
              customButtonContents={{
                ready: uploaderContents(t("form_labels:find_and_upload_bill")),
                loading: uploaderContents(t("form_labels:find_and_upload_bill")),
                inProgress: uploaderContents(`${t("translation:uploading")}...`),
                finished: uploaderContents(`${t("translation:submitting")}...`),
              }}
            />
          </div>
          <div className={styles.text}>{t("quote_modal.utility_bill_optional")}</div>
          <Button className={styles.button} onClick={skipForNow} type="primary">
            {t("quote_modal.skip_this_step")}
          </Button>
        </div>
        <div className={common.footer}>
          <div className={common.footerText}>
            {t("quote_modal.help_obtaining_utility_bill")}
            <div className={common.linkContainer}>
              <a href="mailto: customercare@powur.com">customercare@powur.com</a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

UtilityBillModal.propTypes = {
  latestMessage: MessageEvent.types(),
  clearLatestMessage: PropTypes.func,
  updateLeadUtilityBill: PropTypes.func,

  setAlertMessageVisible: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    latestMessage: "getLatestMessageEvt",
    requests: "getActiveRequests",
  }),
  getRdxActionMapper(["clearLatestMessage", "updateLeadUtilityBill", "setAlertMessageVisible"]),
)(UtilityBillModal);
