export default {
  GET_ENTERPRISE_ORG_REQUEST: "[enterpriseSettings] GET Enterprise Org Request",

  GET_ENTERPRISE_ORG_SEAT_REQUEST: "[enterpriseSettings] GET Enterprise Org Seat Request",

  SET_ENTERPRISE_ORG: "[enterpriseSettings] SET Enterprise Org",

  SET_ENTERPRISE_ORG_SEAT: "[enterpriseSettings] SET Enterprise Org Seat",

  RESET_ENTERPRISE_ORG_SEAT: "[enterpriseSettings] RESET Enterprise Org Seat",

  UPDATE_ENTERPRISE_ORG_REQUEST: "[enterpriseSettings] Update Enterprise Org Request",

  GET_ENTERPRISE_ORG_SEATS: "[enterpriseOrg] GET Enterprise Org Seats",

  SET_ENTERPRISE_ORG_SEATS: "[enterpriseOrg] SET Enterprise Org Seats",

  GET_ENTERPRISES_LIST: "[enterpriseOrg] GET Enterprises List",

  SET_ENTERPRISES_LIST: "[enterpriseOrg] SET Enterprises List",

  GET_VOLUME_TIERS_FOR_ENTERPRISE: "[enterpriseOrg] GET Volume Tiers For Enterprise",

  SET_SELECTED_VOLUME_TIERS: "[enterpriseOrg] SET Selected Volume Tiers",

  GET_ENTERPRISE_COBRANDING: "[enterpriseOrg] GET Enterprise Cobranding",

  SET_ENTERPRISE_COBRANDING: "[enterpriseOrg] SET Enterprise Cobranding",

  POST_ENTERPRISE_ASSETS: "[enterpriseOrg] POST Enterprise Assets",

  SET_ENTERPRISE_ASSETS: "[enterpriseOrg] SET Enterprise Assets",

  DELETE_ENTERPRISE_ASSET: "[enterpriseOrg] DELETE Enterprise Asset",

  GET_ENTERPRISE_CUSTOM_FIELDS: "[enterpriseOrg] GET Enterprise Custom Fields",

  SET_ENTERPRISE_CUSTOM_FIELDS: "[enterpriseOrg] SET Enterprise Custom Fields",
  // INSERTION_PT (for script, do not remove)
};
