import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import messages from "rdx/modules/complianceIssues/messages";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";

import { omit } from "lodash";

function* getComplianceIssues(action) {
  const { userId } = action.payload;

  const { success, data, error } = yield* makeRequest.get("/compliance_incidents", {
    limit: 3,
    ...omit(action.payload, ["userId"]),
    ...(userId ? { user_id: userId } : {}),
  });

  if (success && data) {
    yield put(actions.setComplianceIssues(camelizeKeys(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_COMPLIANCE_ISSUES,
    });
  }

  return null;
}

export default getComplianceIssues;
