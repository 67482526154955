/**
 * Extracts the project ID from a potentially concatenated format.
 *
 * This method ensures the correct format of the project ID as required.
 * When the given projectId is concatenated (as in "12345_67890"), this method separates and returns
 * only the latter part as the project ID, which is needed for the request to succeed.
 *
 * @param {string | number} projectId - A string representation of the project ID which may be in the concatenated form "12345_67890" or a number in form of 12345.
 * @returns {number} The extracted project ID in its proper form.
 */
const extractProjectId = (projectId?: number | string): number => {
  if (typeof projectId === "string" && /^\d+_\d+$/.test(projectId)) {
    return Number(projectId.split("_")[1]);
  }

  return Number(projectId);
};

export default extractProjectId;
