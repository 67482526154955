import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/sellerIntegrity/types";
import getIntegrityViolations from "./getIntegrityViolations";
import createIntegrityViolation from "./createIntegrityViolation";
import updateIntegrityViolation from "./updateIntegrityViolation";
import addModuleToViolation from "./addModuleToViolation";
import removeModuleFromViolation from "./removeModuleFromViolation";
import deleteIntegrityViolation from "./deleteIntegrityViolation";
// IMPORT_PT (for script, do not remove)

function* watchSellerIntegritySagas() {
  yield trackRequests(takeEvery, types.GET_INTEGRITY_VIOLATIONS, getIntegrityViolations);
  yield trackRequests(takeEvery, types.CREATE_INTEGRITY_VIOLATION_REQUEST, createIntegrityViolation);
  yield trackRequests(takeEvery, types.UPDATE_INTEGRITY_VIOLATION, updateIntegrityViolation);
  yield trackRequests(takeEvery, types.ADD_MODULE_TO_VIOLATION, addModuleToViolation);
  yield trackRequests(takeEvery, types.REMOVE_MODULE_FROM_VIOLATION, removeModuleFromViolation);
  yield trackRequests(takeEvery, types.DELETE_INTEGRITY_VIOLATION, deleteIntegrityViolation);
  // INSERTION_PT (for script, do not remove)
}

export default watchSellerIntegritySagas;
