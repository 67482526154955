import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

type TUserDetailText = {
  allCaps?: boolean;
  textSize?: number;
  isAction?: boolean;
  isBold?: boolean;
  lineHeight?: number;
  dark?: boolean;
  noBottomMargin?: boolean;
  noMarginTop?: boolean;
};

export const UserBasicInfoWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
}));

export const UserNameText = styled("div")(({ theme }) => ({
  color: theme.colors["darkest-blue"],
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "22px",
  fontFamily: "Poppins",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  marginTop: "5px",
  marginBottom: "2px",
}));

export const UserDetailText = styled("span")(
  ({
      allCaps = false,
      textSize = 14,
      isAction = false,
      isBold = false,
      lineHeight = 12,
      dark,
      noBottomMargin = false,
      noMarginTop = false,
    }: TUserDetailText) =>
    ({ theme }) => ({
      display: "block",
      color: dark ? theme.colors["nepal-blue"] : theme.colors["dark-blue"],
      fontWeight: isBold ? "600" : "500",
      fontSize: `${textSize}px`,
      lineHeight: `${lineHeight}px`,
      textTransform: allCaps ? "uppercase" : "none",
      cursor: isAction ? "pointer" : "default",
      marginBottom: noBottomMargin ? 0 : "-2px",
      marginTop: noMarginTop ? 0 : "2px",
      "&:hover": {
        color: dark ? theme.colors["nepal-blue"] : theme.colors["dark-blue-highlighted"],
      },
    }),
);

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  ".MuiTooltip-tooltip": {
    backgroundColor: theme.colors.white,
    color: "rgba(0, 0, 0, 0.87)",
    filter: "drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15))",
    fontSize: 11,
    padding: "4px",
    borderRadius: "6px",
    maxWidth: "80vw",
    "& .MuiTooltip-arrow": {
      color: theme.colors.white,
      "&::before": {
        boxShadow: theme.shadows[1],
      },
    },
  },
}));
