import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { CopyOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Select, Typography } from "antd";
import Workflow from "models/Workflow";
import styles from "../../AdminWorkflows.module.less";
import NewWorkflowModal from "../Workflows/components/newWorkflowModal";

const { Title, Paragraph } = Typography;
const { Option } = Select;

const WorkflowSelect = (props) => {
  const { workflows, workflowDetails, handleSelect } = props;

  const [modalVisible, setModalVisible] = useState(false);

  const workflow = useMemo(() => {
    return workflowDetails.props;
  }, [workflowDetails]);

  const handleCopy = () => {
    workflowDetails.copy({ id: workflow.id });
  };

  return (
    <div>
      <Title level={4} className={styles.sidebarTitleText}>
        Workflow
      </Title>
      <Paragraph className={styles.sidebarText}>View, edit, or duplicate</Paragraph>
      <Select
        className={styles.select}
        onChange={(value) => handleSelect(value)}
        placeholder="Select a Workflow"
        defaultValue={workflow?.id || null}
        value={workflow?.id || null}
      >
        {workflows.map((flow) => (
          <Option key={flow.id} value={flow.id}>
            {flow.title}
          </Option>
        ))}
      </Select>
      {workflow.id && (
        <div className={styles.buttonContainer}>
          <Button
            className={styles.addWorkflowButton}
            block
            htmlType="button"
            type="primary"
            onClick={handleCopy}
            icon={<CopyOutlined className={styles.icon} />}
          >
            Copy Selected
          </Button>
        </div>
      )}

      <div style={{ marginTop: "50px" }}>
        <Title level={3} className={styles.sidebarTitleText}>
          Start a new workflow
        </Title>

        <div className={styles.buttonContainer}>
          <Button
            className={styles.addWorkflowButton}
            htmlType="button"
            type="submit"
            onClick={() => setModalVisible(true)}
          >
            <PlusOutlined className={styles.icon} /> New Workflow
          </Button>
        </div>
      </div>

      <NewWorkflowModal visible={modalVisible} setModalVisible={setModalVisible} />
    </div>
  );
};

WorkflowSelect.propTypes = {
  workflows: PropTypes.arrayOf(Workflow.types()),
  workflowDetails: Workflow.types(),
  handleSelect: PropTypes.func,
};

export default WorkflowSelect;
