import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  applicationsList: {
    paging: {
      page_size: 50,
      page_number: 1,
      item_count: 0,
    },
    list: [],
  },
  detail: {},
};

export default {
  buildPartnerApplications: createReducer(initialState, {
    [types.SET_BUILD_PARTNER_APPLICATIONS](state, action) {
      return {
        ...state,
        applicationsList: action.payload,
      };
    },

    [types.SET_BUILD_PARTNER_APPLICATION_DETAIL](state, action) {
      return {
        ...state,
        detail: {
          ...action.payload,
          applicantName: action.payload.applicant.fullName,
        },
      };
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};
