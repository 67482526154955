export default {
  GET_UPGRADE_PREVIEW: "[enrollments] Get Upgrade Preview",
  SET_UPGRADE_PREVIEW: "[enrollments] Set Upgrade Preview",
  UPGRADE_ENROLLMENT: "[enrollments] Upgrade Enrollment",
  DOWNGRADE_ENROLLMENT: "[enrollments] Downgrade Enrollment",
  SET_ENROLLMENTS: "[enrollments] Set Enrollments",
  GET_ENROLLMENTS: "[enrollment] GET Enrollments",
  SET_SERVICES: "[enrollments] Set Services",
  SET_MONTHLY_TOTAL: "[enrollments] Set Monthly Total",
  SET_CREDIT: "[enrollments] Set Credit",
  SET_CARD_INFO: "[enrollments] Set Card Info",
  SET_SELECTED_ENROLLMENT: "[enrollment] SET Selected Enrollment",
  SET_DOWNGRADE_TO: "[enrollment] SET Downgrade To",

  GET_CHARGES: "[enrollment] GET Charges",

  SET_CHARGES: "[enrollment] SET Charges",

  OPEN_EDIT_ENROLLMENT_MODAL: "[enrollment] Open Edit Enrollment Modal",

  CLOSE_EDIT_ENROLLMENT_MODAL: "[enrollment] Close Edit Enrollment Modal",

  RENEW_ENROLLMENT: "[enrollment] Renew Enrollment",

  CANCEL_ENROLLMENT: "[enrollment] Cancel Enrollment",

  SUBSCRIBE_ENROLLMENT: "[enrollment] Subscribe Enrollment",
  // INSERTION_PT (for script, do not remove)
};
