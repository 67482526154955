import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import styles from "../../Team.module.less";

const SectionHeader = (props) => {
  const { text, subText, sectionID } = props;
  const { t } = useTranslation("team");
  const history = useHistory();

  const headerStyle = subText ? styles.joinHeader : styles.header;

  return (
    <div className={headerStyle} id={sectionID}>
      <span className={styles.text}>{text}</span>
      {subText && (
        <>
          <span className={styles.subText}>{subText}</span>
          <Button className={styles.joinButton} onClick={() => history.push("/join")}>
            {t("join_powur")}
          </Button>
        </>
      )}
    </div>
  );
};

SectionHeader.propTypes = {
  text: PropTypes.string,
  subText: PropTypes.string,
  sectionID: PropTypes.string,
};

export default SectionHeader;
