import React, { CSSProperties } from "react";
import { SwitchProps } from "@mui/material/Switch";

import { useController } from "react-hook-form";
import { useSmartFormContext } from "components/SmartForm";
import HelperText from "../HelperText";
import * as S from "./styles";

type SmartFormSwitchT = {
  name: string;
  label?: string;
  switchText?: string;
  helperText?: string | undefined;
  error?: boolean;
  disabled?: boolean;
  labelStyle?: CSSProperties;
  switchTextStyle?: CSSProperties;
} & SwitchProps;

const SmartFormSwitch = ({
  label,
  name,
  switchText,
  helperText,
  error,
  disabled = false,
  labelStyle = {},
  switchTextStyle = {},
  ...rest
}: SmartFormSwitchT) => {
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });

  return (
    <>
      <S.LabelGroupWrapper>
        <S.StyledFormLabel style={labelStyle}>{label}</S.StyledFormLabel>
      </S.LabelGroupWrapper>
      <S.StyledSwitchWrapper>
        <S.StyledSwitch
          disabled={disabled}
          {...field}
          {...rest}
          sx={{ width: "22px", height: "15px", padding: "0px", marginRight: "9px" }}
        />
        <S.StyledSwitchText style={switchTextStyle}>{switchText}</S.StyledSwitchText>
      </S.StyledSwitchWrapper>
      <HelperText error={error}>{helperText}</HelperText>
    </>
  );
};

export default SmartFormSwitch;
