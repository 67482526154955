import React from "react";
import PropTypes from "prop-types";
import GradientButton from "components/Buttons/GradientButton";
import styles from "./PricingAndPlans.module.less";

const PlansHeader = (props) => {
  const { plan, onClick } = props;

  return (
    <div className={styles.cardHeader}>
      <div className={styles.planType}>{plan.header}</div>
      <div className={styles.planTitle}>
        <span>Powur </span>
        <span className={styles.titleGradient}>{plan.type}</span>
      </div>
      <div className={styles.proPrice}>
        <span className={styles.symbol}>$</span>
        <span className={styles.price}>350</span>
        <span className={styles.frequency}>/mo</span>
      </div>
      <GradientButton className={styles.button} type="primary" onClick={onClick}>
        <span>{plan.button}</span>
      </GradientButton>
      <div className={styles.disclaimer}>{plan.disclaimer}</div>
    </div>
  );
};

PlansHeader.propTypes = {
  plan: PropTypes.shape({
    type: PropTypes.string,
    header: PropTypes.string,
    button: PropTypes.string,
    disclaimer: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

PlansHeader.defaultProps = {
  onClick: () => null,
};

export default PlansHeader;
