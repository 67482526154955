import React from "react";
import type { IconT } from "types/utils";

const EyeIconOff = ({ height = "18", width = "18" }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.05721 3.81924C8.36202 3.77417 8.67645 3.75 9.00031 3.75C12.8291 3.75 15.3415 7.12863 16.1855 8.46512C16.2877 8.62688 16.3388 8.70776 16.3673 8.8325C16.3888 8.92619 16.3888 9.074 16.3673 9.16768C16.3387 9.29243 16.2873 9.37384 16.1844 9.53668C15.9595 9.89261 15.6166 10.3928 15.1624 10.9353M5.04324 5.03628C3.42169 6.13628 2.32084 7.66453 1.81583 8.46396C1.71321 8.6264 1.66191 8.70762 1.6333 8.83236C1.61182 8.92605 1.61181 9.07383 1.63328 9.16752C1.66187 9.29226 1.71295 9.37313 1.8151 9.53488C2.65916 10.8714 5.17156 14.25 9.00031 14.25C10.5441 14.25 11.8739 13.7007 12.9666 12.9575M2.25031 2.25L15.7503 15.75M7.40932 7.40901C7.00215 7.81618 6.75031 8.37868 6.75031 9C6.75031 10.2426 7.75767 11.25 9.00031 11.25C9.62163 11.25 10.1841 10.9982 10.5913 10.591"
        stroke="#8499B7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeIconOff;
