import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enterpriseApplications/messages";
import { camelizeKeys } from "lib/helpers";

function* getEnterpriseApplicationByUser() {
  const { success, data, error } = yield* makeRequest.get("/enterprise_applications/for_session_user");

  if (success && data) {
    yield all([
      put(
        actions.setEnterpriseApplicationDetail({
          ...camelizeKeys(data.body),
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.GET_ENTERPRISE_APPLICATION_DETAIL_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ENTERPRISE_APPLICATIONS,
    });
  }

  return null;
}

export default getEnterpriseApplicationByUser;
