import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import User from "models/User";
import { useLocation } from "react-router-dom";
import InjectFacebookPixel from "components/Injectors/InjectFacebookPixel";
import styles from "./ReferrerInfoBox.module.less";

const ReferrerInfoBox = (props) => {
  const { user, containerStyles } = props;
  const { Paragraph } = Typography;
  const [referrer, setReferrer] = useState(null);
  const location = useLocation();
  const slugRole = location.search.includes("ref_id") ? "Ambassador" : "Professional";

  useEffect(() => {
    if (user) setReferrer(new User(user));
  }, [user]);

  return (
    <>
      {referrer && (
        <div className={styles.outer}>
          <InjectFacebookPixel facebookPixelId={user?.facebook_pixel_id} />
          <Row className={styles.referrerInfoContainer} style={{ ...containerStyles }}>
            <Col
              span={8}
              className={styles.image}
              style={{
                backgroundImage: `url(${
                  referrer.props.avatar?.original ? referrer.props.avatar?.original : referrer.props.avatar?.thumb
                })`,
              }}
            />
            <Col span={16} className={styles.textContainer}>
              <h5>{`Your Solar ${slugRole}`}</h5>
              <Paragraph
                ellipsis={{ rows: 1, width: 10 }}
                className={referrer.props?.org?.name ? styles.h2WithOrg : styles.h2}
              >
                {referrer.props.fullName}
              </Paragraph>
              {referrer.props?.org?.name && (
                <Paragraph ellipsis={{ rows: 1, width: 10 }} className={styles.orgName}>
                  {referrer.props.org.name}
                </Paragraph>
              )}
              {referrer.props?.org?.name ? (
                <Paragraph ellipsis={{ rows: 1, width: 10 }} className={styles.h5WithOrg}>
                  Contact
                </Paragraph>
              ) : (
                <h5>Contact</h5>
              )}

              <Paragraph ellipsis={{ rows: 1, width: 10 }} className={styles.h4}>
                <a href={`tel:${referrer.props.phone}`}>{referrer.props.phone}</a>
              </Paragraph>
              <Paragraph ellipsis={{ rows: 1, width: 10 }} className={styles.h4}>
                <a href={`mailto:${referrer.props.email}`}>{referrer.props.email}</a>
              </Paragraph>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

ReferrerInfoBox.propTypes = {
  user: User.types(),
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

export default ReferrerInfoBox;
