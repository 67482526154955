import { put, select, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { keysToCamelCase } from "lib/helpers";
import messages from "../../messages";

function* copyWorkflow(action) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/workflows/${id}/copy`, {});
  if (success && data) {
    const workflowCopy = keysToCamelCase(data.body.workflow);
    const workflows = yield select(selectors.getWorkflows);
    yield all([
      yield put(actions.setWorkflows([...workflows, workflowCopy])),
      yield put(actions.setWorkflowDetails(workflowCopy)),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_COPYING_WORKFLOW,
    });
  }
  return null;
}

export default copyWorkflow;
