import React from "react";
import PropTypes from "prop-types";

const OfferLeadIcon = (props) => {
  const { height, width, fill } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.01673 6.90798C8.98833 6.88256 8.94728 6.87731 8.91255 6.89342C8.8777 6.90977 8.85479 6.94425 8.85479 6.98304V8.58437H7.69069L6.1853 6.48828L5.1792 7.88866L6.60822 9.87759C6.76132 10.0905 7.01001 10.2179 7.27195 10.2179H8.85479V11.8275C8.85479 11.8656 8.8777 11.9003 8.91255 11.9168C8.94728 11.933 8.98833 11.9277 9.01673 11.9022L11.8797 9.48009C11.902 9.46172 11.914 9.43391 11.914 9.40515C11.914 9.37627 11.9019 9.34847 11.8797 9.32973L9.01673 6.90798Z"
        fill={fill}
      />
      <path
        d="M8.85466 3.40793V5.01726C8.85466 5.05533 8.87757 5.09042 8.91241 5.10653C8.94714 5.12276 8.98819 5.11739 9.01659 5.09221C9.01659 5.09221 11.8572 2.68883 11.8795 2.66997C11.9018 2.65112 11.9139 2.62391 11.9139 2.59491C11.9139 2.56615 11.9017 2.53871 11.8795 2.51997C11.8573 2.50124 9.01671 0.0975002 9.01671 0.0975002C8.98831 0.0726788 8.94726 0.0673088 8.91253 0.0832995C8.87769 0.0992902 8.85478 0.134136 8.85478 0.1728V1.77449H7.23088C6.96895 1.77449 6.72014 1.90182 6.56715 2.1146L1.92173 8.58188H0.561452C0.42964 8.58188 0.322784 8.68873 0.322784 8.82054V9.97665C0.322784 10.1085 0.429639 10.2153 0.561452 10.2153H2.34048C2.60241 10.2153 2.85039 10.0877 3.00361 9.87498L7.64939 3.40781L8.85466 3.40793Z"
        fill={fill}
      />
      <path
        d="M3.41129 5.42385L4.41716 4.02395L3.04506 2.11402C2.89195 1.90101 2.64374 1.77344 2.38156 1.77344H0.525807C0.393994 1.77344 0.28714 1.88029 0.28714 2.0121V3.16821C0.28714 3.30002 0.393995 3.40688 0.525807 3.40688H1.96246L3.41129 5.42385Z"
        fill={fill}
      />
    </svg>
  );
};

OfferLeadIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

OfferLeadIcon.defaultProps = {
  height: "11.85",
  width: "11.63",
  fill: "white",
};

export default OfferLeadIcon;
