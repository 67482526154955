import React from "react";

import KnowledgeBaseArticles, { ArticleDataT } from "../KnowledgeBaseArticles";
import PowurPlayVideos, { PowurPlayVideoT } from "../PowurPlayVideos";

import * as S from "./styles";

const proposalRequestArticles: ArticleDataT[] = [
  {
    key: "homeowner-presentation",
    title: "Homeowner Presentation",
    detail: "Powur's sales presentation for delivering high quality and consistent sales presentation to homeowners.",
    url: "https://help.powur.com/hc/en-us/articles/13138631221531",
  },
  {
    key: "overcoming-objections",
    title: "Overcoming Objections in the Sales Process",
    detail: "Techniques and examples for handling objections at each step in the sales process.",
    url: "https://help.powur.com/hc/en-us/articles/9479694938523-Overcoming-Objections-in-the-Sales-Process",
  },
  {
    key: "homeowner-objections",
    title: "Handling Homeowner Objections",
    detail:
      "With proper planning, you can handle any objection that may come up; knowing common objections and being prepared with a response will set you up for success.",
    url: "https://help.powur.com/hc/en-us/articles/15536332747931-Handling-Homeowner-Objections",
  },
];

const powurPlayVideoData: PowurPlayVideoT[] = [
  {
    key: "presenting-virtually",
    label: "Presenting Virtually with Andy Smith",
    image: "/img/presenting-virtually.png",
    url: "https://play.powur.com/movie/smith-presenting-virtually",
  },
  {
    key: "communicate-in-person",
    label: "Communicating With Homeowners in Person",
    image: "/img/communicate-in-person.png",
    url: "https://play.powur.com/movie/lonson-communicating-with-homeowners-in-person",
  },
  {
    key: "follow-up-strategies",
    label: "Follow-Up Strategies with Mike Rosenfeld",
    image: "/img/follow-up-strategies.png",
    url: "https://play.powur.com/movie/rosenfeld-follow-up-strategies",
  },
];

const RequestComplete = () => {
  return (
    <S.RequestCompleteContainer>
      <S.NoteHeader>Congratulations, we&apos;ve received your proposal request!</S.NoteHeader>
      <S.NoteDetails>
        What happens next? Our Proposal Team will return your design within 24 hours for standard requests, 1 hour for
        expedited requests, and between 1-4 hours for Sunnova or EverBright requests. (Requests submitted at the end or
        outside of operating hours will be attended to first thing the following day.)
        <br />
        <br />
        Make sure you are staying in touch regularly with your homeowner as you guide them through to a closed contract
        and successful project. Below you can find some tips and best practices to help you prepare for the next phases
        of your project.
      </S.NoteDetails>
      <KnowledgeBaseArticles articleData={proposalRequestArticles} />
      <PowurPlayVideos videoData={powurPlayVideoData} />
    </S.RequestCompleteContainer>
  );
};

export default RequestComplete;
