import React from "react";
import { BannerContainer, IconContainer } from "../styles";
import * as S from "./styles";
import { ReasonBannerProps } from "../types";

const ReasonBanner = (props: ReasonBannerProps) => {
  return (
    <BannerContainer style={props.containerStyles}>
      <IconContainer>{props.icon}</IconContainer>
      <S.TextContainer>
        <S.Text style={props.textStyles}>This lead is {props.status}. Reason:</S.Text>
        <S.BoldText style={props.textStyles}> {props.reason}</S.BoldText>
      </S.TextContainer>
    </BannerContainer>
  );
};

export default ReasonBanner;
