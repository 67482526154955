import { put, all } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import makeRequest from "rdx/utils/makeRequest";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* reopenProject(action) {
  const { id } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/provider_leads/${id}/reopen`, {});
  if (success && data) {
    yield all([
      put(
        actions.setProjectDetails({
          providerLead: keysToCamelCase(data.body),
          lead: keysToCamelCase(data.body.lead),
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.REOPEN_PROJECT_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_REOPENING_PROJECT,
    });
  }
  return null;
}

export default reopenProject;
