import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const PhoneIconFilled = ({ height = "14", width = "14", fill = cvar("white") }: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14">
      <path
        d="M11.0496 11.7402C10.9099 11.8799 10.7763 12.0274 10.6395 12.175C10.2171 12.6299 9.77439 13.0835 9.08159 13.3056C8.60941 13.4578 7.91814 13.5541 7.19902 13.4671C6.39754 13.3693 5.56035 12.9328 5.22328 12.6766L0.990545 8.44323C0.734298 8.10625 0.296249 7.27074 0.199917 6.46796C0.112913 5.74889 0.209235 5.05942 0.361465 4.58583C0.585098 3.89319 1.03865 3.44908 1.49232 3.02824C1.63989 2.89153 1.78747 2.75808 1.92572 2.61823C2.48177 2.06231 3.06892 2.06697 3.46504 2.4629L4.60518 3.60278C4.97639 3.9739 5.07888 4.64324 4.73412 5.03927C4.56943 5.22873 4.31168 5.40107 4.03819 5.57503C3.4324 5.96013 3.21494 6.00054 3.31895 6.62016C3.41528 7.18853 4.28358 8.19325 4.90953 8.86256C5.57903 9.48834 6.58396 10.3565 7.15246 10.4528C7.7707 10.5569 7.70699 10.2338 8.09218 9.62821C8.26619 9.35491 8.43857 9.09559 8.62807 8.93092C9.02419 8.58611 9.69357 8.68871 10.0649 9.05982L11.205 10.1997C11.6012 10.5957 11.6058 11.1811 11.0497 11.7387L11.0496 11.7402ZM7.76446 3.22052C7.5099 3.18294 7.30009 3.39514 7.30009 3.65246C7.30009 3.90977 7.51099 4.11332 7.76275 4.16651C8.62429 4.34854 9.30494 5.0283 9.48717 5.89033C9.5404 6.14214 9.74399 6.35309 10.0014 6.35309C10.2587 6.35309 10.471 6.14323 10.4334 5.88862C10.2302 4.5139 9.13948 3.42353 7.76446 3.22052ZM7.65566 0.517965C7.39912 0.498104 7.18979 0.708594 7.18979 0.965908C7.18979 1.22322 7.39906 1.42956 7.6553 1.4531C10.0666 1.67463 11.992 3.59958 12.2136 6.01019C12.2372 6.26648 12.4436 6.4758 12.701 6.4758C12.9583 6.4758 13.1689 6.26642 13.149 6.00982C12.9224 3.08522 10.5811 0.744438 7.65566 0.517965Z"
        fill={fill}
      />
    </svg>
  );
};

export default PhoneIconFilled;
