export default {
  GET_BUILD_PARTNER_APPLICATIONS: "[buildPartnerAppReview] GET Build Partner Applications",

  SET_BUILD_PARTNER_APPLICATIONS: "[buildPartnerAppReview] SET Build Partner Applications",

  GET_BUILD_PARTNER_APPLICATION_DETAIL: "[buildPartnerAppReview] GET Build Partner Application Detail",

  SET_BUILD_PARTNER_APPLICATION_DETAIL: "[buildPartnerAppReview] SET Build Partner Application Detail",

  APPROVE_BUILD_PARTNER_APPLICATION: "[buildPartnerAppReview] PATCH Approve Build Partner Application",

  REJECT_BUILD_PARTNER_APPLICATION: "[buildPartnerAppReview] PATCH Reject Build Partner Application",

  MAKE_PENDING_BUILD_PARTNER_APPLICATION: "[buildPartnerAppReview] PATCH Make Pending Build Partner Application",

  CREATE_BUILD_PARTNER_APPLICATION: "[buildPartnerAppReview] POST Create Build Partner Application",

  UPDATE_BUILD_PARTNER_APPLICATION: "[buildPartnerAppReview] PATCH Update Build Partner Application",

  CREATE_TIER_ONE_USER_REGISTRATION: "[buildPartnerApplications] Create Tier One User Registration",
  // INSERTION_PT (for script, do not remove)
};
