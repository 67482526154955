import { permissionTypes } from "hooks/usePermissions";

const { ROOF_DESIGN_ADMIN } = permissionTypes;

const commonTabs = [
  {
    key: "your-work-queue",
    label: "Your Work Queue",
  },
];

const adminTabs = [
  {
    key: "all-requests",
    label: "All Requests",
    permission: ROOF_DESIGN_ADMIN,
  },
];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
