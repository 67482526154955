import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToSnakeCase } from "lib/helpers";
import messages from "../messages";

function* inviteNewTeamLead(action) {
  const params = keysToSnakeCase(action.payload);

  const { success, data, error } = yield* makeRequest.post("/registrations/invite", params);
  if (success && data) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.SUCCESS_INVITE_NEW_TEAM_LEAD,
          payload: data.body,
        }),
      ),
      put(actions.getTeamLeadsList()),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_SENDING_INVITE_TO_NEW_TEAM_LEAD,
    });
  }
  return null;
}

export default inviteNewTeamLead;
