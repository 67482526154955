import React from "react";

export default function fileTypesToPlainText(string) {
  if (string) {
    const splitTypes = string.split(", ");
    const len = splitTypes.length;
    return splitTypes.map((type, i) => {
      let str = type.split("/")[1].toUpperCase();
      if (i < len - 1) {
        str += ", ";
      }
      return <span key={i}>{str}</span>; // eslint-disable-line react/no-array-index-key
    });
  }
  return <></>;
}
