import { all, put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/adminReports/messages";
import { camelizeKeys } from "lib/helpers";

function* getSellerReport(action) {
  const { from, to, consultant, initialDate } = action.payload;
  const params = {
    ...(from && { start_day: from || null }),
    ...(to && { end_day: to || null }),
    ...(consultant && { consultant: consultant || null }),
    ...(initialDate && { initial_date: initialDate || null }),
  };

  const { success, data, error } = yield* makeRequest.get("/consultant_report", params);

  if (success && data) {
    const out = {};
    const { consultants_reports, top_level_consultant_report, initial_date } = data.body;
    if (consultants_reports) {
      out.consultants = camelizeKeys(consultants_reports);
    }
    if (top_level_consultant_report) {
      out.topLevelReport = camelizeKeys(top_level_consultant_report);
    }
    if (initial_date) {
      out.initialDate = initial_date;
    }
    yield all([put(actions.setSellerReport(out))]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_CONSULTANT_REPORT,
    });
  }
  return null;
}

export default getSellerReport;
