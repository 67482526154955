import component from "containers/StaticPages/contactVerification";

export default {
  key: "verify",
  title: "verify",
  path: "/verify",
  pageName: "verify",
  linkTarget: "/verify",
  component,
};
