import React from "react";
import PropTypes from "prop-types";

const LeftCarouselArrow = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.23005 11.1897C0.129775 10.3912 0.129779 8.75114 1.23005 7.95255L10.8252 0.988274C12.1474 0.0286438 14 0.973176 14 2.60687L14 16.5354C14 18.1691 12.1474 19.1137 10.8252 18.154L1.23005 11.1897Z"
        fill={fill}
      />
    </svg>
  );
};

LeftCarouselArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

LeftCarouselArrow.defaultProps = {
  fill: "var(--silver)",
  height: "18",
  width: "18",
};

export default LeftCarouselArrow;
