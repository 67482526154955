import React from "react";
import { TooltipProps } from "@mui/material";

import { AdminTooltip } from "./styles";

type TooltipT = {
  children: JSX.Element;
  placement: TooltipProps["placement"];
} & TooltipProps;

export const Tooltip = ({ title, placement, children, ...rest }: TooltipT) => {
  return (
    <div>
      <AdminTooltip {...rest} placement={placement} arrow title={title}>
        {children}
      </AdminTooltip>
    </div>
  );
};
