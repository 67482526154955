import React from "react";
import styles from "../FormElements.module.less";

export const UserCitiesDisplay = ({ city: { displayValue, _data } }) => (
  <div className={styles.autoCompleteDropdownUserDisplay}>
    <div className={styles.nameContainer}>
      <span className={styles.name}>{displayValue}</span>
    </div>
  </div>
);
