import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { CrmActivityUpdatePayloadT, CrmActivityT, CrmActivityTypeEnum } from "types/Project/crmActivity";
import { keysToSnakeCase, camelizeKeys, formatCrmActivityForLog, formatCrmActivityLog } from "lib/helpers";

import messages from "../messages";

type CrmActivityUpdateParamsT = {
  payload: CrmActivityUpdatePayloadT;
};

function* updateCrmActivity(action: CrmActivityUpdateParamsT) {
  const { activityId, leadId } = action.payload;
  const params = keysToSnakeCase(action.payload);

  const { success, data, error } = yield* makeRequest.patch(`/sales_enablement_activities/${activityId}`, params);
  if (success && data) {
    const updatedActivity = camelizeKeys(data.body);

    const filterOrUpdateActivity = (activityList: CrmActivityT[] = []): CrmActivityT[] => {
      if (updatedActivity.kind === CrmActivityTypeEnum.EMAIL) {
        return activityList;
      }

      if (updatedActivity.completedAt) {
        return activityList.filter((activity) => activity.id !== updatedActivity.id);
      }

      const updatedList = activityList.map((activity) =>
        activity.id === activityId ? { ...updatedActivity } : activity,
      );
      return updatedList;
    };

    const projectDetail = yield select(selectors.getProjectDetails);
    const activityLog = yield select(selectors.selectCrmActivityLogFromDetail);
    const pastDueAppointment = yield select(selectors.getPastDueHomeownerAppointment);

    const projectCrmActivities = yield select(selectors.selectProjectCrmActivities);
    const updatedCrmActivities = projectCrmActivities.filter((activity) => activity.id !== activityId);

    const kanbanData = yield select(selectors.selectProviderLeadsKanban);
    const idxOfUpdatedProject = kanbanData.providerLeads.findIndex((project) => project.homeowner.id === leadId);
    const idxOfActivity = kanbanData.providerLeads[idxOfUpdatedProject]?.activities?.findIndex(
      (activity: CrmActivityT) => activity.id === activityId,
    );

    const updatedProjectDetailCrmActivities = filterOrUpdateActivity(projectDetail?.providerLead?.crmActivities);

    const formattedActivity = formatCrmActivityForLog(updatedActivity);

    const updatedActivityLog = formatCrmActivityLog({ activityId, activityLog, updatedActivity: formattedActivity });

    const preSalesList = yield select(selectors.getProjectPreSales);
    const idxOfPreSalesProject = preSalesList.projects.findIndex((project) => project.leadId === action.payload.leadId);
    const updatedPreSales = [...preSalesList.projects];

    if (updatedPreSales[idxOfPreSalesProject]) {
      updatedPreSales[idxOfPreSalesProject].activities = filterOrUpdateActivity(
        updatedPreSales[idxOfPreSalesProject].activities,
      );
    }

    const providerLeadsList = yield select(selectors.getProviderLeadsList);
    const idxOfProviderLeadsList = providerLeadsList.providerLeads.findIndex(
      (project) => project.homeowner.id === updatedActivity.leadInfo.id,
    );
    const updatedProviderLeads = [...providerLeadsList.providerLeads];

    if (updatedProviderLeads[idxOfProviderLeadsList]) {
      updatedProviderLeads[idxOfProviderLeadsList].activities = filterOrUpdateActivity(
        updatedProviderLeads[idxOfProviderLeadsList].activities,
      );
    }

    const selectCrmTaskList = yield select(selectors.selectCrmTaskList);
    const updatedCrmTaskList = {
      ...selectCrmTaskList,
      activities: (selectCrmTaskList?.activities || [])
        .map((activity) => {
          if (activity.id !== activityId) {
            return activity;
          }
          if (updatedActivity.completedAt) {
            return null;
          }
          return { ...activity, ...updatedActivity };
        })
        .filter(Boolean),
    };

    yield all([
      ...(kanbanData.providerLeads.length
        ? [
            put(
              actions.updateCrmActivityInList({
                projectIdx: idxOfUpdatedProject,
                activityIdx: idxOfActivity,
                updatedActivity,
              }),
            ),
          ]
        : []),
      ...(idxOfPreSalesProject >= 0
        ? [
            put(
              actions.setProjectsPreSales({
                projects: updatedPreSales,
                paging: preSalesList.paging,
              }),
            ),
          ]
        : []),
      put(actions.updateProjectCrmActivities(updatedCrmActivities)),
      put(actions.setCrmActivityDetails(updatedActivity)),
      put(actions.updateProjectCrmActivity(updatedActivity)),
      put(actions.setCrmTaskList(updatedCrmTaskList)),
      put(
        actions.setProjectDetails({
          ...projectDetail,
          providerLead: { ...projectDetail.providerLead, crmActivities: updatedProjectDetailCrmActivities },
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.CRM_ACTIVITY_UPDATE_SUCCESS,
        }),
      ),
      ...(action.payload.leadId === projectDetail?.lead?.id
        ? [put(actions.setCrmActivityLog(updatedActivityLog))]
        : []),

      ...(pastDueAppointment?.id === activityId ? [put(actions.setUserPastDueHomeownerAppointment(null))] : []),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_CRM_ACTIVITY,
    });
  }
  return null;
}

export default updateCrmActivity;
