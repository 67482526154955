import PropTypes from "prop-types";
import { store } from "rdx/configureStore";
import actions from "rdx/actions";
import { Base } from "./Base";
import Phase from "./Phase";

export default class Workflow extends Base {
  update = ({ title, active }) => {
    const { updateWorkflow } = actions;
    store.dispatch(
      updateWorkflow({
        id: this.props.id,
        title,
        active,
      }),
    );
  };

  copy = () => {
    const { copyWorkflow } = actions;
    store.dispatch(copyWorkflow({ id: this.props.id }));
  };

  delete = () => {
    const { deleteWorkflow } = actions;
    store.dispatch(deleteWorkflow({ id: this.props.id }));
  };

  removePhase = (phaseId) => {
    const { removeWorkflowPhase } = actions;
    store.dispatch(
      removeWorkflowPhase({
        workflowId: this.props.id,
        phaseId,
      }),
    );
  };

  updatePhaseRanks = (phases) => {
    const { updatePhaseRanks } = actions;
    store.dispatch(
      updatePhaseRanks({
        workflowId: this.props.id,
        phases,
      }),
    );
  };

  static types() {
    return PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      active: PropTypes.bool,
      phases: PropTypes.arrayOf(Phase.types()),
    });
  }
}
