import { styled } from "@mui/material/styles";
import { filterStyledProps } from "lib/helpers/filterStyledProps";
import { theme } from "styles/themes";

type JoinTodayContainerPropsT = {
  maxWidth?: `${number}px`;
};

export const JoinTodayContainer = styled("div", { shouldForwardProp: filterStyledProps(["maxWidth"]) })(
  ({ maxWidth = "220px" }: JoinTodayContainerPropsT) => ({
    display: "flex",
    alignItems: "center",
    background: theme.colors["tango-orange"],
    borderRadius: "40px",
    color: theme.colors.white,
    maxWidth,
    minWidth: "fit-content",
    height: "51px",
    gap: "8px",
    cursor: "pointer",
    fontWeight: 500,
    transition: ".2s all",
    "&:hover": {
      background: theme.colors["hot-cinnamon"],
    },
  }),
);

export const ButtonText = styled("div")(() => ({
  flexGrow: 1,
  color: theme.colors.white,
  fontSize: "16px",
  textAlign: "right",
  paddingLeft: "32px",
  whiteSpace: "nowrap",
}));

export const ArrowIconContainer = styled("div", { shouldForwardProp: filterStyledProps(["background"]) })(
  ({ background }: { background: string }) => ({
    transition: "0.2s all",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background,
    borderRadius: "50%",
    padding: "8px",
    margin: "6px",
    width: "40px",
    height: "40px",
  }),
);
