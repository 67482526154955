import React, { CSSProperties } from "react";

// External
import { useDispatch, useSelector } from "react-redux";
import { GridRowParams } from "@mui/x-data-grid-pro";
import type { GridColumns, GridRowModel, GridSortItem, DataGridProProps } from "@mui/x-data-grid-pro";

// Hooks
import { UseNavigation } from "hooks/useNavigation";
import useRestrictAccessModal from "hooks/useRestrictAccessModal";
import { actions, selectors } from "rdx";

// Components
import { FiltersT } from "components/Pagination/SearchFilterPagination/NewFilters/types";
import BasicTable from "../BasicTable";

// Link to learn more about the generic types: {https://www.typescriptlang.org/docs/handbook/2/generics.html}
type ProjectsTablePropsT<TData extends GridRowModel> = {
  rows: TData[];
  columns: GridColumns;
  sortItems?: FiltersT;
  getCsv?: (search: Record<string, unknown>) => void;
  getCsvReport?: ({ reportId }: { reportId: number }) => void;
  selectedCsv?: Record<string, number | string>;
  navigate?: ReturnType<UseNavigation>["navigate"];
  override?: ReturnType<UseNavigation>["override"];
  totalResultCount?: number;
  addLead?: boolean;
  isLoading?: boolean;
  getData?: (payload?: Record<string, unknown>) => void;
  resetData?: () => void;
  onRowClick?: (params: GridRowParams) => void;
  defaultSort?: GridSortItem;
  rightSideComponent?: React.ReactElement;
  isMobile?: boolean; // after all tables have the same design could be removed
  hideFilterBar?: boolean;
  hideColumnHeaders?: boolean;
  hideBottomPadding?: boolean;
  noXScroll?: boolean;
  containerStyles?: CSSProperties;
  hideCSV?: boolean;
} & DataGridProProps;

const ProjectsTable = <T extends Record<string, unknown>>({
  rows,
  columns,
  getCsv,
  getCsvReport,
  selectedCsv,
  sortItems,
  navigate,
  override,
  totalResultCount,
  addLead,
  isLoading,
  getData,
  resetData,
  onRowClick,
  defaultSort,
  rightSideComponent,
  isMobile,
  hideFilterBar = false,
  hideColumnHeaders = false,
  hideBottomPadding = false,
  containerStyles = {},
  noXScroll = false,
  hideCSV = false,
}: ProjectsTablePropsT<T>) => {
  const dispatch = useDispatch();
  const { restrictAccessModal, isUnpaidUser } = useRestrictAccessModal();

  const addLeadDrawerVisible = useSelector(selectors.selectLeadToProposalDrawerVisible);

  const handleAddLead = () => {
    if (isUnpaidUser) {
      restrictAccessModal();
    } else {
      dispatch(actions.setLeadToProposalDrawerVisible(!addLeadDrawerVisible));
    }
  };

  return (
    <BasicTable
      rows={rows}
      columns={columns}
      getCsv={getCsv}
      getCsvReport={getCsvReport}
      selectedCsv={selectedCsv}
      sortItems={sortItems}
      navigate={navigate}
      override={override}
      totalResultCount={totalResultCount}
      isLoading={isLoading}
      getData={getData}
      resetData={resetData}
      onRowClick={onRowClick}
      defaultSort={defaultSort}
      rightSideComponent={rightSideComponent}
      isMobile={isMobile}
      showFilterBarButton={addLead}
      handleFilterBarButtonClick={handleAddLead}
      filterBarButtonText="Add Lead"
      hideFilterBar={hideFilterBar}
      hideColumnHeaders={hideColumnHeaders}
      hideBottomPadding={hideBottomPadding}
      containerStyles={containerStyles}
      noXScroll={noXScroll}
      hideCSV={hideCSV}
    />
  );
};

export default ProjectsTable;
