import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  payouts: {
    list: [],
    paging: {
      itemCount: 0,
      pageNumber: 1,
      pageSize: 50,
    },
    balance: 0,
  },
  payoutFilters: {},
  paymentMilestones: {
    list: [],
    paging: {
      itemCount: 0,
      pageNumber: 1,
      pageSize: 50,
    },
  },
};

export default {
  financials: createReducer(initialState, {
    [types.SET_PAYOUTS_LIST](state, action) {
      return {
        ...state,
        payouts: action.payload,
      };
    },
    [types.SET_PAYOUT_FILTERS](state, action) {
      return {
        ...state,
        payoutFilters: action.payload,
      };
    },

    [types.SET_PAYMENT_MILESTONES](state, action) {
      return {
        ...state,
        paymentMilestones: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
