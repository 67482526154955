import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

import { parse } from "query-string";

import { Menu } from "antd";
import Checkbox from "antd/lib/checkbox";
import { RightOutlined } from "@ant-design/icons";

import styles from "../Filters/SidebarFilters.module.less";
import "../Filters/sidebarFilters.css";

const { SubMenu } = Menu;

const MultiSelect = (props) => {
  const { menu, navigate, onSelect, showSelectedValues } = props;
  const menuRef = React.createRef();

  const { search } = useLocation();
  const parsedSearch = parse(search);
  const checked = parsedSearch[menu.key]?.split("-");

  // stateful array of submenu keys, updates on user select or change in query string (e.g. browser back/forward)
  const [checkedList, setCheckedList] = useState(checked);

  const options = useMemo(() => {
    return menu.options.map((o) => ({ label: o.label, value: o.key }));
  }, [menu]);

  const handleCheckboxClick = (list) => {
    onSelect(list);
    setCheckedList(list);
    const newValue = list.length ? list.join("-") : null;
    navigate({ [menu.key]: newValue });
  };

  const getValueNames = () => {
    if (checkedList?.length > 0) return checkedList.join(", ").replaceAll("_", " ");
    return "All";
  };

  useEffect(() => {
    if (isEmpty(parsedSearch)) {
      setCheckedList(checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedSearch]);

  return (
    <div className={styles.multiSelectContainer}>
      <Menu
        className={styles.menu}
        expandIcon={
          <RightOutlined
            style={{
              fontSize: "10px",
              color: "var(--dark-blue)",
              marginRight: "0px",
              minWidth: "auto",
            }}
          />
        }
      >
        <SubMenu
          title={<div className={styles.label}>{menu.key}</div>}
          popupClassName={styles.multiselectPopup}
          key="multiselect-submenu"
        >
          <Menu.Item
            key="multiselect-submenu-checkbox-container"
            tabIndex="0"
            role="button"
            className={styles.menuShow}
            ref={menuRef}
          >
            <Checkbox.Group onChange={handleCheckboxClick} options={options} value={checkedList} />
          </Menu.Item>
        </SubMenu>
        {showSelectedValues && <div className={styles.activeItem}>{getValueNames()}</div>}
      </Menu>
    </div>
  );
};

MultiSelect.defaultProps = {
  onSelect: () => null,
};

MultiSelect.propTypes = {
  menu: PropTypes.shape({
    key: PropTypes.string.isRequired,
    // IMPORTANT: must have *unique* keys for each submenu as well as each option within the menu. if two submenus have the same key, this will break. if multiple options have the same key, the menu will show all options with that key as selected.
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        default: PropTypes.bool,
        index: PropTypes.number,
        parentKey: PropTypes.string,
      }).isRequired,
    ),
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  showSelectedValues: PropTypes.bool,
};

export default withRouter(MultiSelect);
