import React, { CSSProperties } from "react";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { CheckboxUnchecked, CheckboxChecked } from "components/Icons";
import { useController } from "react-hook-form";

import { useSmartFormContext } from "components/SmartForm";

import * as S from "./styles";

export type SmartFormSingleCheckboxT = {
  name: string;
  checkboxLabel: string;
  label?: string;
  helperText?: string | undefined;
  error?: boolean;
  disabled?: boolean;
  labelStyle?: CSSProperties;
} & CheckboxProps;

const SmartFormSingleCheckbox = ({
  name,
  checkboxLabel,
  label,
  helperText,
  error,
  disabled = false,
  labelStyle = {},
  ...rest
}: SmartFormSingleCheckboxT) => {
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });

  return (
    <>
      <S.LabelGroupWrapper>
        <S.StyledFormLabel style={labelStyle}>{label}</S.StyledFormLabel>
      </S.LabelGroupWrapper>
      <S.CheckboxWrapper
        checked={field.value}
        disabled={disabled}
        {...field}
        control={<Checkbox size="small" icon={<CheckboxUnchecked />} checkedIcon={<CheckboxChecked />} {...rest} />}
        label={<S.CheckboxLabel>{checkboxLabel}</S.CheckboxLabel>}
        labelPlacement="end"
      />
      <S.StyledHelperText error={error}>{helperText}</S.StyledHelperText>
    </>
  );
};

export default SmartFormSingleCheckbox;
