import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import { keysToCamelCase } from "lib/helpers";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../../messages";

function* getWorkflowsList() {
  const { success, data, error } = yield* makeRequest.get("/workflows", {});
  if (success && data) {
    const workflows = data.body.workflows.map((g) => keysToCamelCase(g));
    yield put(actions.setWorkflows(workflows));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_WORKFLOWS,
    });
  }
  return null;
}

export default getWorkflowsList;
