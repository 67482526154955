import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";
import types from "rdx/modules/buildPartnerApplications/types";
import getBuildPartnerApplications from "./getBuildPartnerApplications";
import getBuildPartnerApplicationDetail from "./getBuildPartnerApplicationDetail";
import approveBuildPartnerApplication from "./approveBuildPartnerApplication";
import rejectBuildPartnerApplication from "./rejectBuildPartnerApplication";
import makePendingBuildPartnerApplication from "./makePendingBuildPartnerApplication";
import createBuildPartnerApplication from "./createBuildPartnerApplication";
import updateBuildPartnerApplication from "./updateBuildPartnerApplication";

import createTierOneUserRegistration from "./createTierOneUserRegistration";
// IMPORT_PT (for script, do not remove)

function* watchBuildPartnerApplicationsSagas() {
  yield trackRequests(takeEvery, types.GET_BUILD_PARTNER_APPLICATIONS, getBuildPartnerApplications);

  yield trackRequests(takeEvery, types.GET_BUILD_PARTNER_APPLICATION_DETAIL, getBuildPartnerApplicationDetail);

  yield trackRequests(takeEvery, types.APPROVE_BUILD_PARTNER_APPLICATION, approveBuildPartnerApplication);

  yield trackRequests(takeEvery, types.REJECT_BUILD_PARTNER_APPLICATION, rejectBuildPartnerApplication);

  yield trackRequests(takeEvery, types.MAKE_PENDING_BUILD_PARTNER_APPLICATION, makePendingBuildPartnerApplication);

  yield trackRequests(takeEvery, types.CREATE_BUILD_PARTNER_APPLICATION, createBuildPartnerApplication);

  yield trackRequests(takeEvery, types.UPDATE_BUILD_PARTNER_APPLICATION, updateBuildPartnerApplication);
  yield trackRequests(takeEvery, types.CREATE_TIER_ONE_USER_REGISTRATION, createTierOneUserRegistration);
  // INSERTION_PT (for script, do not remove)
}

export default watchBuildPartnerApplicationsSagas;
