import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { ToggleButton } from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import * as S from "./styles";

type DateTimeToolbarT = {
  selectedDateTime: string;
  updateTime: (hrs: number, mins: number) => void;
  hideTime?: boolean;
};
const DateTimeToolbar = ({ selectedDateTime, updateTime, hideTime = false }: DateTimeToolbarT) => {
  const [hours, setHours] = useState(dayjs(selectedDateTime).hour());
  const [minutes, setMinutes] = useState(dayjs(selectedDateTime).minute());
  const [period, setPeriod] = useState(dayjs(selectedDateTime).format("a"));

  useEffect(() => {
    let hrs = hours;
    if (period === "pm" && hrs < 12) hrs = hours + 12;
    if (period === "am" && hrs >= 12) hrs = hours - 12;
    updateTime(hrs, minutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes, hours, period]);

  const handleValueUpdate = (value: number, type: string, max: number) => {
    let val = value;
    if (val > max) val = max;

    if (type === "hours") {
      setHours(_.isNaN(val) ? 1 : val);
    } else {
      setMinutes(_.isNaN(val) ? 0 : val);
    }
  };

  return (
    <S.ToolbarContainer container>
      <S.StyledToolbar>{dayjs(selectedDateTime).format("ddd, MMM D")}</S.StyledToolbar>
      {!hideTime && (
        <>
          <Grid item sx={{ marginRight: "12px" }}>
            <S.StyledInputWrapper>
              <S.StyledInputTime
                type="number"
                min="1"
                max="12"
                defaultValue={parseInt(dayjs(selectedDateTime).format("hh"), 10)}
                onChange={(event) => {
                  handleValueUpdate(parseInt(event.target.value, 10), "hours", parseInt(event.target.max, 10));
                }}
              />
              <S.StyledInputLabel>Hour</S.StyledInputLabel>
            </S.StyledInputWrapper>
            <S.StyledInputTimeColon>:</S.StyledInputTimeColon>
            <S.StyledInputWrapper>
              <S.StyledInputTime
                type="number"
                min="0"
                max="59"
                defaultValue={dayjs(selectedDateTime).format("mm")}
                onChange={(event) => {
                  handleValueUpdate(parseInt(event.target.value, 10), "minutes", parseInt(event.target.max, 10));
                }}
              />
              <S.StyledInputLabel>Minute</S.StyledInputLabel>
            </S.StyledInputWrapper>
          </Grid>
          <Grid item sx={{ width: "52px" }}>
            <S.StyledToggleButtonGroup
              value={period}
              onChange={(event) => {
                const target = event.target as HTMLButtonElement;
                setPeriod(target.value);
              }}
              orientation="vertical"
              exclusive
            >
              <ToggleButton value="am">AM</ToggleButton>
              <ToggleButton value="pm">PM</ToggleButton>
            </S.StyledToggleButtonGroup>
          </Grid>
        </>
      )}
    </S.ToolbarContainer>
  );
};

export default DateTimeToolbar;
