import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";
import { messageTypes } from "rdx/modules/messages/constants";

import { v1 as uuidv1 } from "uuid";

export default {
  latestMessageEvent: createReducer(
    {},
    {
      [types.SET_FORM_ERRORS](state, action) {
        return { ...state, formErrors: action.payload.error };
      },
      [types.NEW_MESSAGE_EVENT](state, action) {
        return {
          id: uuidv1(),
          type: messageTypes.GENERAL,
          ...action.payload,
        };
      },
      [types.NEW_SUCCESS_EVENT](state, action) {
        return {
          id: uuidv1(),
          type: messageTypes.SUCCESS,
          ...action.payload,
        };
      },
      [types.NEW_ERROR_EVENT](state, action) {
        return {
          id: uuidv1(),
          type: messageTypes.ERROR,
          ...action.payload,
        };
      },
      [types.CLEAR_LATEST_MESSAGE]() {
        return {};
      },
      // INSERTION_PT (for script, do not remove)
    },
  ),
};
