import React from "react";
import ReasonBanner from "components/Banners/ReasonBanner";
import InfoBanner from "components/Banners/InfoBanner";
import { BannersProps } from "./types";

const Banners = ({ bannerDetails }: BannersProps) => {
  const bannerComponents = {
    info: InfoBanner,
    reason: ReasonBanner,
  };

  return (
    <div>
      {bannerDetails.map((banner) => {
        const Banner = bannerComponents[banner.bannerType];
        return <Banner {...banner} />;
      })}
    </div>
  );
};

export default Banners;
