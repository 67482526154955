import { put, select } from "redux-saga/effects";
import selectors from "rdx/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* createStateLicense(action) {
  const { success, data, error } = yield* makeRequest.post("/state_licenses", action.payload);

  if (success && data) {
    const rawListings = yield select(selectors.getStateLicenses);
    const stateLicenses = [...rawListings, data.body.state_license];

    yield put(actions.setStateLicenses({ stateLicenses }));
    yield put(
      actions.newSuccessEvent({
        message: messages.STATE_LICENSE_CREATED_SUCCESS,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: "There was an error getting career listings",
    });
  }

  return null;
}

export default createStateLicense;
