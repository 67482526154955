import types from "rdx/modules/projectPreSales/types";
import createAction from "rdx/utils/createAction";
import { PreSalesProjectT } from "./stateTypes";

export default {
  getProjectsPreSales: (payload) => createAction(types.GET_PROJECTS_PRE_SALES, payload),
  getSelectedPreSalesProject: (payload) => createAction(types.GET_SELECTED_PRE_SALES_PROJECT, payload),
  setProjectsPreSales: (payload) => createAction(types.SET_PROJECTS_PRE_SALES, payload),
  setSelectedPreSalesProject: (payload) => createAction(types.SET_SELECTED_PRE_SALES_PROJECT, payload),
  updatePreSalesProject: (
    payload: Pick<PreSalesProjectT, "projectId"> & Partial<Omit<PreSalesProjectT, "projectId">>,
  ) => createAction(types.UPDATE_PRE_SALES_PROJECT, payload),
  resetProjectsPreSales: () => createAction(types.RESET_PROJECTS_PRE_SALES),
  updateCrmActivityInPreSalesList: (payload) => createAction(types.UPDATE_CRM_ACTIVITY_IN_PRE_SALES_LIST, payload),
  // INSERTION_PT (for script, do not remove)
};
