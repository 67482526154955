import component from "components/GoogleOAuth/GoogleCallback";

export default {
  key: "googleOAuth",
  title: "Google OAuth Callback",
  path: "/googleOAuth/callback",
  linkTarget: null,
  exact: true,
  component,
};
