import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/publicApiProxy/messages";
import { camelizeKeys, keysToSnakeCase } from "lib/helpers";

function* getGoogleUser(action) {
  // GoogleUserActionParams
  const params = keysToSnakeCase(action.payload);

  const { success, data, error } = yield* makeRequest.get("/gmail/user", params);

  if (success && data) {
    const result = camelizeKeys(data.body);

    yield all([
      put(actions.setGoogleUser({ user: result.user })),
      put(
        actions.newSuccessEvent({
          message: messages.GET_GOOGLE_USER_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_GOOGLE_USER,
    });
  }

  return null;
}

export default getGoogleUser;
