import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UnpackNestedValue, SetValueConfig, FieldPath } from "react-hook-form";

import type { FieldValues, UseFormProps, UseFormReturn, UseFormHandleSubmit } from "react-hook-form";

import type { SmartFormPropsT } from "components/SmartForm";

type SetValueEntry<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
  value: UnpackNestedValue<TFieldValues[FieldPath<TFieldValues>]>;
  options?: SetValueConfig;
};

type SetValueEntryArray<TFieldValues extends FieldValues> = Array<SetValueEntry<TFieldValues>>;

type GetSmartFormPropsT<T extends FieldValues> = (props?: Omit<SmartFormPropsT, "children" | "methods">) => {
  onSubmit?: ReturnType<UseFormHandleSubmit<T>>;
  methods: Omit<UseFormReturn<T>, "handleSubmit">;
};

type UseSmartFormPropsT<T extends FieldValues> = {
  schema: z.ZodTypeAny;
  useFormProps?: Omit<UseFormProps<T>, "resolver">;
};

type Helpers<TFieldValues extends FieldValues> = {
  setValues: (setValueArray: SetValueEntryArray<TFieldValues>) => void;
};

type UseSmartFormReturnT<TFieldValues extends FieldValues> = {
  methods: UseFormReturn<TFieldValues>;
  getSmartFormProps: GetSmartFormPropsT<TFieldValues>;
  helpers: Helpers<TFieldValues>;
};

function useSmartForm<TFieldValues extends FieldValues>({
  schema,
  useFormProps,
}: UseSmartFormPropsT<TFieldValues>): UseSmartFormReturnT<TFieldValues> {
  const fullMethods = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    ...useFormProps,
  });

  const { handleSubmit: _handleSubmit, ...methods } = fullMethods;

  function getSmartFormProps(props: Omit<SmartFormPropsT, "children" | "methods"> = {}) {
    return {
      methods,
      ...props,
    };
  }

  function setValues(setValueArray: SetValueEntryArray<TFieldValues>) {
    setValueArray.forEach(({ name, value, options }) => {
      methods.setValue(name, value, options);
    });
  }

  const helpers = {
    setValues,
  };

  return { methods: fullMethods, getSmartFormProps, helpers };
}

export type { GetSmartFormPropsT };
export { useSmartForm };
