import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import _ from "lodash";
import { camelizeKeys } from "lib/helpers";

function* getOrgSellers(action) {
  const params = _.pickBy(action.payload);

  const { success, data, error } = yield* makeRequest.get("/users/enterprise_consultants", {
    ...params,
    limit: 20,
    sort: params.sort || "name",
  });
  if (success && data) {
    const out = camelizeKeys(data.body);

    yield put(actions.setOrgSellers(out));
  } else {
    return getErrorActions({ error });
  }
  return null;
}

export default getOrgSellers;
