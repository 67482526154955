import styled from "@emotion/styled";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { cvar, mq } from "styles";

export const LabelGroupWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const StyledSectionLabel = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "17px",
  marginBottom: "8px",
  color: cvar("darkest-blue"),
}));

export const StyledFormLabel = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "9px",
  marginBottom: "8px",
  color: cvar("nepal-blue"),
  textTransform: "uppercase",
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  marginBottom: "15px",
  flexWrap: "wrap",
}));

export const StyledToggleButton = styled(ToggleButton)(() => ({
  margin: "4px",
  border: `1px solid ${cvar("botticelli-blue")} !important`,
  borderRadius: "6px !important",
  background: cvar("white"),
  height: "32px",
  color: cvar("dark-blue"),
  fontFamily: "Barlow",
  fontSize: "14px",
  marginLeft: "-1px",
  [mq.phoneOnly]: {
    width: "72px",
  },
  [mq.tabletUp]: {
    width: "68px",
  },
  "&:first-of-type": {
    marginLeft: "0px",
  },

  "&.MuiSelected": {
    border: `1px solid ${cvar("blue-ribbon")} !important`,
    background: cvar("cotton-boll"),
  },

  "&.Mui-disabled:not(.Mui-selected)": {
    background: cvar("botticelli-blue"),
    color: cvar("institutional-grey-hover"),
  },
}));
