export default {
  // general
  UPDATE_DASHBOARD_UPDATE_SUCCESS: "You have successfully updated this dashboard update.",

  DELETE_DASHBOARD_UPDATE_SUCCESS: "You have successfully deleted this dashboard update.",

  CREATE_DASHBOARD_UPDATE_SUCCESS: "You have successfully created this dashboard update.",

  // errors
  GET_DASHBOARD_UPDATES_ERROR: "There was an error getting dashboard updates.",

  CREATE_DASHBOARD_UPDATE_ERROR: "There was an error getting dashboard updates.",

  GET_DASHBOARD_UPDATE_ERROR: "There was an error getting this dashboard update.",

  UPDATE_DASHBOARD_UPDATE_ERROR: "There was an error updating this dashboard update.",

  DELETE_DASHBOARD_UPDATE_ERROR: "There was an error deleting this dashboard update.",
};
