export default {
  // success
  CREATE_INTEGRITY_VIOLATION_SUCCESS: "Integrity Violation created successfully.",
  GET_INTEGRITY_VIOLATION_SUCCESS: "Integrity Violation retrieved successfully.",
  UPDATE_INTEGRITY_VIOLATION_SUCCESS: "Integrity Violation updated successfully.",
  UPDATE_REQUIRED_MODULES_SUCCESS: "Required Modules updated successfully.",
  DELETE_INTEGRITY_VIOLATION_SUCCESS: "Integrity Violation deleted successfully.",

  // errors
  ERROR_CREATING_INTEGRITY_VIOLATION: "There was an error creating the integrity violation.",
  ERROR_UPDATING_INTEGRITY_VIOLATION: "There was an error updating the integrity violation.",
  ERROR_UPDATING_REQUIRED_MODULES: "There was an erorr updating the required modules.",
  ERROR_DELETING_INTEGRITY_VIOLATION: "There was an error deleting the integrity violation.",
};
