import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";

import { theme } from "styles/themes";
import { mq } from "styles";

export const AssignedPartnerCard = styled(Grid)(() => ({
  width: "100%",
  background: theme.colors.white,
  borderRadius: "0px 0px 12px 12px",
  padding: "22px",
  marginBottom: "16px",
}));

export const AppointmentFormContainer = styled("div")(() => ({
  width: "100%",
  background: theme.colors["picton-blue-a10"],
  padding: "36px",
  color: theme.colors["darkest-blue"],
  fontSize: "16px",
  borderRadius: "3px",
  [mq.phoneOnly]: {
    padding: "16px",
  },
}));

export const AppointmentScheduledText = styled(Grid)(() => ({
  display: "flex",
  fontSize: "16px",
  fontStyle: "italic",
  color: theme.colors["congress-blue"],
  fontWeight: 500,
  [mq.phoneOnly]: {
    alignItems: "center",
  },
}));

export const ApptInstructionHeader = styled("div")(() => ({
  fontFamily: "Poppins",
  fontSize: "20px",
  lineHeight: "30px",
  fontWeight: 400,
  marginBottom: "20px",
  color: theme.colors["blue-ribbon"],
}));

export const Bold = styled("span")(() => ({
  fontWeight: 700,
}));

export const Hyperlink = styled(Link)(() => ({
  color: theme.colors["blue-ribbon"],
  cursor: "pointer",
}));

export const ApptInstructionText = styled("div")(() => ({
  fontWeight: 500,
  fontSize: "16px",
  marginBottom: "22px",
  lineHeight: "22px",
  color: theme.colors["nevada-gray"],
}));

export const IconContainer = styled("div")(() => ({
  paddingTop: "3px",
  marginLeft: "8px",
}));

export const TipsForSuccess = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  lineHeight: "19px",
  fontWeight: 600,
  color: theme.colors["blue-ribbon"],
}));

export const TipIconContainer = styled("div")(() => ({
  marginRight: "6px",
}));
