import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/fileUpload/types";
import putGrayscaleCopy from "./putGrayscaleCopy";

// IMPORT_PT (for script, do not remove)

function* watchFileUploadSagas() {
  yield trackRequests(takeEvery, types.PUT_GRAYSCALE_COPY, putGrayscaleCopy);
  // INSERTION_PT (for script, do not remove)
}

export default watchFileUploadSagas;
