import React from "react";
import PropTypes from "prop-types";

const DownCaretFilled = (props) => {
  const { fill, height, width, className, preserveAspectRatio } = props;

  return (
    <svg
      preserveAspectRatio={preserveAspectRatio}
      width={width}
      height={height}
      className={className}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.59457 5.4382C3.79414 5.71473 4.20589 5.71473 4.40546 5.4382L7.75813 0.792603C7.99678 0.46192 7.76049 -4.76837e-07 7.35269 -4.76837e-07H0.647341C0.239537 -4.76837e-07 0.00324935 0.46192 0.241899 0.792603L3.59457 5.4382Z"
        fill={fill}
      />
    </svg>
  );
};

DownCaretFilled.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
  preserveAspectRatio: PropTypes.string,
  className: PropTypes.string,
};

DownCaretFilled.defaultProps = {
  fill: "var(--dark-blue)",
  preserveAspectRatio: null,
  width: "8",
  height: "6",
};

export default DownCaretFilled;
