export default {
  GET_PLATFORM_MODULES: "[platformModules] GET platform modules",
  SET_PLATFORM_MODULES: "[platformModules] SET platform modules",

  GET_ENTERPRISE_MODULES: "[platformModules] GET enterprise modules",
  SET_ENTERPRISE_MODULES: "[platformModules] SET enterprise modules",

  TOGGLE_MODULE_ACTIVE: "[platformModules] Toggle Module Active",

  GET_MODULE_DETAIL: "[platformModules] GET Module Details",
  SET_MODULE_DETAIL: "[platformModules] SET Module Details",
  // INSERTION_PT (for script, do not remove)
};
