import React from "react";
import PropTypes from "prop-types";

const TrainingUpdateIcon = (props) => {
  const { iconColor, circleColor, height, width, containerStyles } = props;

  return (
    <div style={{ paddingTop: "3px", ...containerStyles }}>
      <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill={circleColor} />
        <path
          d="M14.7494 12.5255C15.1016 13.6888 14.444 14.9176 13.2807 15.2699C12.1173 15.6221 10.8887 14.9645 10.5364 13.8012C10.1842 12.6378 10.8417 11.4092 12.005 11.0569C13.1683 10.7047 14.3971 11.3622 14.7494 12.5255Z"
          fill={iconColor}
        />
        <path
          d="M19.9842 16.4622C19.9814 15.8558 19.4664 15.377 18.8603 15.377H14.4724C13.9769 15.7452 13.3429 16.0275 12.6481 16.0275C11.9643 16.0275 11.3303 15.7838 10.8375 15.3825C9.7464 15.5154 8.89648 16.4346 8.89648 17.5612V21.7415C8.89648 22.3479 9.39471 22.8405 10.0011 22.8405C10.2475 22.8405 10.4827 22.7574 10.6683 22.6217V27.9509C10.6683 28.5573 11.1582 29.0499 11.7673 29.0499C12.3763 29.0499 12.8662 28.5571 12.8662 27.9509C12.8662 28.5573 13.3591 29.0499 13.9652 29.0499C14.5716 29.0499 15.0615 28.5571 15.0615 27.9509L15.0617 17.5559H18.86C19.4664 17.5559 19.9813 17.0686 19.9842 16.4622Z"
          fill={iconColor}
        />
        <path
          d="M29.615 11.465H24.3688C24.3688 11.1688 24.139 10.9473 23.8567 10.9473L21.4675 10.9475C21.1852 10.9475 20.9554 11.169 20.9554 11.4652L16.393 11.465C16.1106 11.465 15.8809 11.7004 15.8809 11.9828C15.8809 12.2651 16.1107 12.5005 16.393 12.5005H29.6123C29.6372 12.5005 29.6511 12.4949 29.6511 12.5171V22.3228C29.6511 22.3478 29.6372 22.3893 29.6123 22.3893H16.393C16.1106 22.3893 15.8809 22.6052 15.8809 22.8875C15.8809 23.1698 16.1107 23.3857 16.393 23.3857H29.6123C30.2021 23.3857 30.6837 22.9124 30.6837 22.3226L30.6839 12.5144C30.6866 11.9247 30.205 11.4651 29.6152 11.4651L29.615 11.465Z"
          fill={iconColor}
        />
        <path
          d="M22.9056 24.1211C22.7727 23.9883 22.5568 23.9883 22.4239 24.1211L18.0801 28.4648C17.9473 28.5976 17.9473 28.8135 18.0801 28.9464C18.213 29.0793 18.4289 29.0793 18.5617 28.9464L22.6646 24.8436L26.7674 28.9464C26.9003 29.0793 27.1162 29.0793 27.249 28.9464C27.3819 28.8135 27.3819 28.5976 27.249 28.4648L22.9056 24.1211Z"
          fill={iconColor}
        />
        <path
          d="M20.5634 20.0761C20.497 20.0871 20.4333 20.1093 20.3724 20.1425L19.4229 19.4781C19.4339 19.4116 19.4339 19.3425 19.4202 19.2759C19.3677 18.9796 19.1101 18.7638 18.8084 18.7638C18.7725 18.7638 18.7364 18.7665 18.7005 18.7721C18.3627 18.8302 18.1385 19.154 18.1967 19.4918C18.2492 19.788 18.5068 20.0039 18.8084 20.0039C18.8444 20.0039 18.8805 20.0012 18.9164 19.9956C18.9606 19.9873 19.005 19.9763 19.0466 19.9568L20.0488 20.6599C20.0461 20.7042 20.0488 20.7513 20.0571 20.7955C20.1096 21.0917 20.3672 21.3076 20.6689 21.3076C20.7048 21.3076 20.7409 21.3049 20.7768 21.2993C21.1146 21.2412 21.3388 20.9173 21.2806 20.5796C21.275 20.5491 21.2667 20.5158 21.2557 20.4855L22.8004 18.279C22.8778 18.2956 22.9582 18.2956 23.0358 18.2817C23.0966 18.2707 23.1521 18.2512 23.2075 18.2263L24.3509 19.0264C24.3509 19.0623 24.3536 19.1011 24.3592 19.137C24.4117 19.4333 24.6693 19.6492 24.971 19.6492C25.0069 19.6492 25.043 19.6464 25.0789 19.6408C25.4167 19.5827 25.6409 19.2589 25.5827 18.9211C25.5744 18.874 25.5605 18.8298 25.5439 18.7882L27.6923 15.7209C27.7504 15.7265 27.8085 15.7265 27.8667 15.7153C28.2044 15.6571 28.4286 15.3333 28.3705 14.9955C28.3179 14.6993 28.0603 14.4834 27.7587 14.4834C27.7228 14.4834 27.6867 14.4861 27.6507 14.4917C27.313 14.5498 27.0888 14.8737 27.1469 15.2114C27.1552 15.2669 27.1745 15.3194 27.1968 15.3692L25.0623 18.4173C24.9958 18.4063 24.9294 18.409 24.863 18.42C24.7883 18.4339 24.7162 18.4588 24.6525 18.5003L23.5479 17.7251C23.5535 17.6726 23.5506 17.6172 23.5396 17.5674C23.4871 17.2711 23.2295 17.0553 22.9278 17.0553C22.8919 17.0553 22.8558 17.058 22.8199 17.0636C22.4821 17.1217 22.2579 17.4455 22.3161 17.7833C22.3217 17.8165 22.33 17.847 22.341 17.8803L20.7963 20.0868C20.7241 20.0624 20.6437 20.0624 20.5634 20.0761L20.5634 20.0761Z"
          fill={iconColor}
        />
      </svg>
    </div>
  );
};

TrainingUpdateIcon.propTypes = {
  iconColor: PropTypes.string,
  circleColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

TrainingUpdateIcon.defaultProps = {
  iconColor: "white",
  circleColor: "var(--blue-ribbon)",
  height: 40,
  width: 40,
  containerStyles: {},
};

export default TrainingUpdateIcon;
