import SessionClient from "util/SessionClient";
import { useDispatch } from "react-redux";
import actions from "rdx/actions";

const useRestrictAccessModal = () => {
  const session = new SessionClient();
  const { backOfficeAccessTrue } = session;
  const isUncertifiedUser = session.backOfficeAccess === "needs_certification" || !session.backOfficeAccess;
  const isUnpaidUser = session.backOfficeAccess === "needs_activation" || !session.backOfficeAccess;
  const integrityTrainingRequired = session.backOfficeAccess === "integrity_restricted";
  const isRestrictedUser = isUncertifiedUser || isUnpaidUser || integrityTrainingRequired;

  const dispatch = useDispatch();

  const restrictAccessModal = () => {
    if (integrityTrainingRequired) {
      return dispatch(actions.setIntegrityTrainingModalVisible(true));
    }
    return dispatch(actions.setActivateAccountModalVisible(true));
  };

  return {
    isUnpaidUser,
    isUncertifiedUser,
    backOfficeAccessTrue,
    integrityTrainingRequired,
    isRestrictedUser,
    restrictAccessModal,
  };
};

export default useRestrictAccessModal;
