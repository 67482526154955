// import { get } from "lodash";

export default {
  getSellerDashboard: (state) => state.consultantDashboard,
  getDashboardLeadProgress: (state) => state.consultantDashboard.progressOverTime,
  getDashboardTeamActivity: (state) => state.consultantDashboard.teamActivity,
  getDashboardLeadOrigin: (state) => state.consultantDashboard.leadOrigin,
  getDashboardEarningsToDate: (state) => state.consultantDashboard.earningsToDate,
  getDashboardEarningsBreakdown: (state) => state.consultantDashboard.earningsBreakdown,
  getDashboardProjectActivity: (state) => state.consultantDashboard.projectActivity,
  getDashboardProjectActivitySummary: (state) => state.consultantDashboard.countPerStages,
  getProjectCounterWithNotifications: (state) => state.consultantDashboard.projectCounterWithNotifications,
  getDashboardSalesLeaderboard: (state) => state.consultantDashboard.salesRank,
  getDashboardReferralsLeaderboard: (state) => state.consultantDashboard.referralsRank,
  getDashboardMentorsLeaderboard: (state) => state.consultantDashboard.mentorRank,
  getDashboardInitState: (state) => state.consultantDashboard.dashboardInit,
  getDashboardPorjectActivityCount: (state) => state.consultantDashboard.paging.itemCount,
  getDashboardFilters: (state) => state.consultantDashboard.filters,
  getDashboardPartneredProjectSuggestions: (state) => state.consultantDashboard.partneredProjectSuggestions,
  getCompletedPartneredProjectsCount: (state) => state.consultantDashboard.completedPartneredProjectsCount,
  getCompletedProposalProjects: (state) => state.consultantDashboard.completedProposalProjects,
};
