// React
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

// AntD
import { Button, Modal, Input, Select, Form, Checkbox } from "antd";
import { MailOutlined, CloseOutlined } from "@ant-design/icons";

// Util
import { actions } from "rdx";
import { useTranslation } from "react-i18next";
import SessionClient from "util/SessionClient";
import ApiClient from "util/ApiClient";
import * as _ from "lodash";

// Components
import PhoneIcon from "components/Icons/PhoneIcons/phoneIcon";
import DownCaret from "components/Icons/Chevrons/DownCaretSimple";
import SuccessModal from "./SuccessModal";

import styles from "./JoinModal.module.less";

const JoinModal = (props) => {
  const { visible, setVisible, org } = props;
  const { t, i18n } = useTranslation(["join", "enterprise_seller_join", "ambassador", "form_labels", "translation"]);

  const spokenLanguagesOptions = [
    { label: t("translation:english"), value: "en" },
    { label: t("translation:spanish"), value: "es" },
  ];

  const history = useHistory();
  const dispatch = useDispatch();
  const { user: userId } = useParams();
  const [submitting, setSubmitting] = useState(false);

  const api = new ApiClient();
  const session = new SessionClient();

  const [form] = Form.useForm();
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  const location = useLocation();

  const viewingAmbassadorJoin = location.pathname.toLowerCase().includes("/ambassador");

  const viewingEntSellerJoin = location.pathname.toLowerCase().includes("/enterprise-seller-join");

  const handleEmailAlreadyExists = (email) => {
    setVisible(false);
    dispatch(
      actions.setAlertMessageVisible({
        message: t("translation:email_already_registered"),
        severity: "info",
      }),
    );
    setTimeout(() => {
      history.push({ pathname: "/login", search: `?email=${email}` });
    }, 1000);
  };

  const submitRegistrationForm = async ({ authFormat }) => {
    if (!submitting) {
      try {
        setSubmitting(true);
        const values = await form.validateFields();
        session.clear();
        const params = _.mapKeys(values, (v, k) => _.snakeCase(k));
        params.sponsor = userId || "powur.pbc";
        params.auth_format = authFormat;

        if (viewingAmbassadorJoin) {
          params.kind = "ambassador";
        }

        if (viewingEntSellerJoin) {
          params.enterprise_seller_application = true;
          params.kind = "consultant";
        }

        api
          .post("/registrations", params)
          .then(() => {
            setVisible(false);
            setTimeout(setSuccessModalVisible(true), 1000);
          })
          .catch((res) => {
            if (
              res.error.message === "A user already exists associated with this email address" &&
              res.error.field === "email"
            ) {
              handleEmailAlreadyExists();
            } else {
              dispatch(
                actions.setAlertMessageVisible({
                  message: res.error.message,
                  severity: "error",
                }),
              );
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      } catch {
        setSubmitting(false);
      }
    }
  };

  let paragraph = t("modal.confirm_information_below");
  let headerText = t("modal.join_the_energy_revolution");

  if (viewingAmbassadorJoin) {
    headerText = t("ambassador:become_a_powur_ambassador");
    paragraph = t("ambassador:modal.confirm_information_below");
  }
  if (viewingEntSellerJoin) {
    headerText = `${t("enterprise_seller_join:modal.join_the_energy_revolution")} ${org} Sales`;
    paragraph = t("enterprise_seller_join:modal.confirm_information_below");
  }

  return (
    <>
      <Modal
        className={styles.joinModal}
        title={
          <div className={styles.headerContainer}>
            <span className={styles.title}>{headerText}</span>
            <p className={styles.paragraph}>{paragraph}</p>
          </div>
        }
        footer={null}
        open={visible}
        onCancel={() => setVisible(false)}
        closeIcon={<CloseOutlined style={{ color: "white" }} />}
      >
        <div className={styles.subHeaderContainer}>
          <div className={styles.subHeader}>{t("form_labels:your_information")}</div>
          <div className={styles.underline} />
        </div>
        <Form
          className={styles.formContainer}
          initialValues={{ locale: i18n.language || "en" }}
          form={form}
          validateTrigger="onSubmit"
        >
          <span className={styles.inputLabel}>{t("form_labels:first_name")}</span>
          <Form.Item
            name="first_name"
            rules={[
              {
                required: true,
                message: t("form_labels:first_name_required"),
              },
            ]}
          >
            <Input size="large" className={styles.input} name="first_name" />
          </Form.Item>

          <span className={styles.inputLabel}>{t("form_labels:last_name")}</span>
          <Form.Item
            name="last_name"
            rules={[
              {
                required: true,
                message: t("form_labels:last_name_required"),
              },
            ]}
            message={null}
          >
            <Input size="large" className={styles.input} name="last_name" />
          </Form.Item>

          <span className={styles.inputLabel}>{t("form_labels:email")}</span>
          <Form.Item
            name="email"
            normalize={(v) => v.trim()}
            rules={[
              {
                required: true,
                message: t("form_labels:valid_email"),
              },
              {
                type: "email",
                message: t("form_labels:valid_email"),
              },
            ]}
          >
            <Input
              size="large"
              className={styles.iconInput}
              name="email"
              addonBefore={
                <div className={styles.inputPrefix}>
                  <MailOutlined
                    style={{
                      color: "var(--botticelli-blue)",
                      fontSize: "17px",
                    }}
                  />
                </div>
              }
            />
          </Form.Item>

          <span className={styles.inputLabel}>{t("form_labels:phone")}</span>
          <Form.Item
            name="phone"
            rules={[
              {
                pattern: /^[0-9.\-+]+$/,
                message: t("form_labels:valid_phone_number"),
              },
              {
                required: true,
                message: t("form_labels:valid_phone_number"),
              },
              {
                min: 7,
                message: t("form_labels:valid_phone_number"),
              },
            ]}
          >
            <Input
              size="large"
              className={styles.iconInput}
              name="phone"
              addonBefore={
                <div className={styles.inputPrefix}>
                  <PhoneIcon />
                </div>
              }
            />
          </Form.Item>

          <span className={styles.inputLabel}>{t("translation:language_preference")}</span>
          <Form.Item name="locale" rules={[{ required: true }]}>
            <Select
              className={styles.select}
              size="large"
              suffixIcon={<DownCaret />}
              options={spokenLanguagesOptions}
            />
          </Form.Item>
          <span className={styles.inputLabel}>{t("translation:languages_spoken")}</span>
          <Form.Item className={styles.formItem} name="spokenLanguages">
            <Checkbox.Group options={spokenLanguagesOptions} />
          </Form.Item>
        </Form>

        <div className={styles.buttonContainer}>
          <Button
            onClick={() => submitRegistrationForm({ authFormat: "password" })}
            className={styles.joinButton}
            loading={submitting}
          >
            {t("form_labels:join")}
          </Button>
          <Button onClick={() => setVisible(false)} className={styles.cancelButton}>
            {t("form_labels:cancel")}
          </Button>
        </div>
      </Modal>
      <SuccessModal
        visible={successModalVisible}
        setVisible={setSuccessModalVisible}
        viewingAmbassadorJoin={viewingAmbassadorJoin}
        viewingEntSellerJoin={viewingEntSellerJoin}
      />
    </>
  );
};

JoinModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  org: PropTypes.string,
};

export default JoinModal;
