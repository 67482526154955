import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/notifications/types";
import getDashboardNotificationsWidget from "./getDashboardNotificationsWidget";
import archiveDashboardNotifications from "./archiveDashboardNotifications";
import unarchiveDashboardNotifications from "./unarchiveDashboardNotifications";
import getAllNotifications from "./getAllNotifications";

// IMPORT_PT (for script, do not remove)

function* watchDashboardNotificationsWidgetSagas() {
  yield trackRequests(takeEvery, types.GET_DASHBOARD_NOTIFICATIONS_WIDGET, getDashboardNotificationsWidget);
  yield trackRequests(takeEvery, types.ARCHIVE_DASHBOARD_NOTIFICATIONS_WIDGET, archiveDashboardNotifications);
  yield trackRequests(takeEvery, types.UNARCHIVE_DASHBOARD_NOTIFICATIONS_WIDGET, unarchiveDashboardNotifications);
  yield trackRequests(takeEvery, types.GET_ALL_NOTIFICATIONS, getAllNotifications);

  // INSERTION_PT (for script, do not remove)
}

export default watchDashboardNotificationsWidgetSagas;
