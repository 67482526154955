import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { CSSTransition } from "react-transition-group";
import { useErrorContext } from "components/ErrorBoundary";
import PlatformPageHeader from "components/PlatformLayout/components/PlatformPageHeader";
import styles from "../../styles.module.less";

const { Content } = Layout;

const HomeFallback = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const { hasError, error, errorUID } = useErrorContext();
  const { message, fileName, stack } = error || {};

  useEffect(() => {
    if (hasError) {
      setTimeout(() => setShowPrompt(true), 300);
    }
  }, [hasError]);

  return (
    <Layout className={styles.homeFallback}>
      <PlatformPageHeader altLogout={<></>} mainNavCollapsed toggleMenu={() => null} />
      <Content className={styles.content}>
        <CSSTransition
          in={showPrompt}
          classNames={{
            appear: styles.promptAppear,
            appearActive: styles.promptAppearActive,
            enter: styles.promptEnter,
            enterActive: styles.promptEnterActive,
            exit: styles.promptExit,
            exitActive: styles.promptExitActive,
          }}
          timeout={300}
        >
          <div
            className={styles.body}
            style={{
              display: showPrompt ? "block" : "none",
            }}
          >
            <div className={styles.oops}>Oops!</div>
            <div className={styles.message}>
              <p>
                We&apos;re sorry, something unexpected happened causing the Powur Energy app to crash. An automated
                error report has been sent that will help us address this issue. Refresh the page to return to Powur
                Energy.
              </p>
              <p>
                If this issue persists or you would like to send us feedback directly, please send us an email:{" "}
                <a href="mailto:support@powur.com">support@powur.com</a>.
              </p>
              {errorUID && (
                <p>
                  Error ID: <strong>{errorUID}</strong>
                </p>
              )}
              <div className={styles.devDetails}>
                <div />
                <details>
                  <summary>
                    <code>Error:</code>
                  </summary>
                  <ul>
                    <li>
                      <mark>{message}</mark>
                    </li>
                    <li>
                      <mark>{stack.split(/<|(:.{2,9}:)/)[0]}</mark>
                    </li>
                    <li>
                      <mark>{fileName}</mark>
                    </li>
                  </ul>
                </details>
              </div>
            </div>
          </div>
        </CSSTransition>
      </Content>
    </Layout>
  );
};

export default HomeFallback;
