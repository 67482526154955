export default {
  getInboxList: (state) => state.inbox.notices.list,
  getInboxListPaging: (state) => state.inbox.notices.paging,
  getInboxTags: (state) => state.inbox.inboxTags,
  getSelectedMessage: (state) => state.inbox.selectedMessage,
  getInboxFilters: (state) => state.inbox.inboxFilters,
  getInboxAutoComplete: (state) => state.inbox.inboxAutoComplete,
  getProjectMessageList: (state) => state.inbox.projectMessageList.list,
  getZendeskFormData: (state) => state.inbox.zendeskFormData,
  getProjectMessagePaging: (state) => state.inbox.projectMessageList.paging,
  getProjectMessageReplies: (state) => state.inbox.projectMessageReplies,
  getObservingAccount: (state) => state.inbox.observingAccount,
  selectAssignableEntities: (state) => state.inbox.selectedMessage.assignableEntities,
  selectMessageTopics: (state) => state.inbox.projectMessageList.topics,
  selectZendeskFormData: (state) => state.inbox.zendeskFormData,
  selectProjectMessageAssignableEntities: (state) => state.inbox.projectMessageList.assignableEntities,
  selectFilterToTeamOptions: (state) => state.inbox.notices.filterToTeamOptions,
};
