import { permissionTypes } from "hooks/usePermissions";

const { PLATFORM_CONFIGURATION, SERVICE_AREA_UPDATES } = permissionTypes;

const commonTabs = [];

const adminTabs = [
  {
    key: "settings",
    label: "Settings",
    permission: PLATFORM_CONFIGURATION,
  },
  {
    key: "service-areas",
    label: "Service Areas",
    permission: SERVICE_AREA_UPDATES,
  },
  {
    key: "enterprise-admin",
    label: "Enterprise",
    permission: PLATFORM_CONFIGURATION,
  },
  {
    key: "powur-pair",
    label: "Powur Pair",
    permission: PLATFORM_CONFIGURATION,
  },
  {
    key: "compensation",
    label: "Compensation",
    permission: PLATFORM_CONFIGURATION,
  },
];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
