import React from "react";

const PermissionToOperate = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16.9316" cy="16" r="16" fill="var(--dark-blue)" />
      <path
        d="M21.8738 8.5868C21.1693 9.14385 20.3257 9.50248 19.4346 9.62399L19.2129 9.65424V12.2977C19.2129 13.7753 20.1475 15.1022 21.5393 15.5992L22.0327 15.7753L22.5259 15.599C23.9177 15.1019 24.8526 13.7753 24.8526 12.2977V9.65424L24.6308 9.62399C23.7398 9.50248 22.8961 9.14359 22.1917 8.5868L22.0327 8.46094L21.8738 8.5868ZM24.3399 10.0972V12.2975C24.3399 13.5589 23.5416 14.6917 22.3534 15.116L22.0327 15.2309L21.7115 15.1163C20.5236 14.6917 19.7256 13.5592 19.7256 12.2977V10.0975C20.56 9.94904 21.3501 9.61117 22.0327 9.11027C22.7154 9.61092 23.5054 9.94879 24.3399 10.0972Z"
        fill="white"
      />
      <path
        d="M25.6216 8.64448C24.3676 8.64448 23.1886 8.15613 22.3022 7.26968L22.0328 7L21.7636 7.26942C20.8769 8.15613 19.6979 8.64448 18.4439 8.64448H18.1875V10.1826H11.2661C10.5594 10.1826 9.98438 10.7576 9.98438 11.4643V13.7714H12.0352V19.4111H9.98438V21.7183C9.98438 22.425 10.5594 23 11.2661 23H19.4693C20.176 23 20.751 22.425 20.751 21.7183V16.3752C20.8948 16.4447 21.0417 16.5098 21.1945 16.5644L22.0328 16.8635L22.871 16.5644C24.6696 15.9217 25.878 14.207 25.878 12.2972V8.64448H25.6216ZM19.2129 15.1634V17.3603H13.5733V13.7714H18.438C18.6136 14.279 18.8758 14.7504 19.2129 15.1634ZM10.4971 13.2588V11.4643C10.4971 11.0403 10.8421 10.6953 11.2661 10.6953C11.6901 10.6953 12.0352 11.0403 12.0352 11.4643V13.2588H10.4971ZM10.4971 21.7183V19.9238H12.0352V21.7183C12.0352 22.1423 11.6901 22.4873 11.2661 22.4873C10.8421 22.4873 10.4971 22.1423 10.4971 21.7183ZM20.2383 21.7183C20.2383 22.1423 19.8933 22.4873 19.4693 22.4873H12.2848C12.4476 22.2725 12.5479 22.0079 12.5479 21.7183V11.4643C12.5479 11.1746 12.4476 10.9101 12.2848 10.6953H18.1875V12.2972C18.1875 12.6253 18.2257 12.9468 18.2939 13.2588H13.0606V17.873H19.7256V15.6956C19.8864 15.8376 20.0573 15.9691 20.2383 16.0878V21.7183ZM25.3653 12.2972C25.3653 13.9909 24.2935 15.5118 22.6988 16.0814L22.0328 16.319L21.3668 16.0814C19.772 15.5118 18.7002 13.9909 18.7002 12.2972V9.15102C19.9517 9.09078 21.1222 8.59013 22.0328 7.72316C22.9431 8.59013 24.1138 9.09078 25.3653 9.15102V12.2972Z"
        fill="white"
      />
      <path
        d="M20.9328 12.5654L20.5703 12.9279L21.5206 13.8782L24.009 11.3898L23.6465 11.0273L21.5206 13.1532L20.9328 12.5654Z"
        fill="white"
      />
      <path d="M13.5732 11.207H13.0605V11.7197H13.5732V11.207Z" fill="white" />
      <path d="M17.6748 11.207H14.0859V11.7197H17.6748V11.207Z" fill="white" />
      <path d="M17.6748 12.2344H13.0605V12.7471H17.6748V12.2344Z" fill="white" />
      <path
        d="M16.6494 19.9248H19.7256V18.3867H16.6494V19.9248ZM17.1621 18.8994H19.2129V19.4121H17.1621V18.8994Z"
        fill="white"
      />
      <path
        d="M16.6494 21.9756H19.7256V20.4375H16.6494V21.9756ZM17.1621 20.9502H19.2129V21.4629H17.1621V20.9502Z"
        fill="white"
      />
      <path
        d="M14.855 19.4121C14.9965 19.4121 15.1113 19.5272 15.1113 19.6685H15.624C15.624 19.2445 15.279 18.8994 14.855 18.8994V18.3867H14.3423V18.8994H13.5732V19.6685C13.5732 20.0925 13.9183 20.4375 14.3423 20.4375H14.855C14.9965 20.4375 15.1113 20.5526 15.1113 20.6939V20.9502H14.3423C14.2008 20.9502 14.0859 20.8351 14.0859 20.6939H13.5732C13.5732 21.1179 13.9183 21.4629 14.3423 21.4629V21.9756H14.855V21.4629H15.624V20.6939C15.624 20.2699 15.279 19.9248 14.855 19.9248H14.3423C14.2008 19.9248 14.0859 19.8097 14.0859 19.6685V19.4121H14.855Z"
        fill="white"
      />
      <path d="M14.5986 14.2852H14.0859V14.7979H14.5986V14.2852Z" fill="white" />
      <path d="M15.624 14.2852H15.1113V14.7979H15.624V14.2852Z" fill="white" />
      <path d="M16.6494 14.2852H16.1367V14.7979H16.6494V14.2852Z" fill="white" />
    </svg>
  );
};

export default PermissionToOperate;
