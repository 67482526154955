/* eslint-disable no-param-reassign */

import { camelizeKeys, keysToSnakeCase } from "lib/helpers";
import { api } from "rdx/services/api";
import { actions } from "rdx";
import SchemaValidator from "util/schema-helpers/SchemaValidator";
import messages from "./messages";
import { GetTeamUsersCsvParamsT, GetTeamUsersCsvResponseT, GetCsvReportByIdResponseT } from "./types";
import { GetTeamUsersCsvResponseSchema, GetCsvReportByIdResponseSchema } from "./schemas";
import { CSV_REPORTS_TAGS } from "./tags";

const defaultTeamSellersQueryParams = {
  role: ["consultants", "seller_pro", "enterprise_owner"],
  sort: "newest",
};

export const csvReportsApi = api.enhanceEndpoints({ addTagTypes: [CSV_REPORTS_TAGS.TEAM_USERS_CSV] }).injectEndpoints({
  endpoints: (builder) => ({
    getTeamUsersCsv: builder.query<GetTeamUsersCsvResponseT["body"], GetTeamUsersCsvParamsT>({
      query: ({ filterParams, searchedUserId, isEnterprise }) => ({
        url: "users/team_csv",
        params: keysToSnakeCase({
          ...(isEnterprise ? {} : defaultTeamSellersQueryParams),
          ...filterParams,
          searchedUserId,
          isEnterprise,
        }),
      }),
      transformResponse: (res: GetTeamUsersCsvResponseT) => {
        SchemaValidator.validate<typeof GetTeamUsersCsvResponseSchema>({
          schema: GetTeamUsersCsvResponseSchema,
          schemaName: "GetTeamUsersCsvResponseSchema",
          dto: camelizeKeys(res),
        });
        return camelizeKeys(res.body);
      },
      onQueryStarted: (_queryParams, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(({ data }) => {
            if (data.csv.url) {
              dispatch(
                actions.setAlertMessageVisible({
                  message: messages.GET_CSV_SUCCESS,
                  severity: "success",
                }),
              );
            }
          })
          .catch(() => {
            dispatch(
              actions.setAlertMessageVisible({
                message: messages.ERROR_GETTING_CSV,
                severity: "error",
              }),
            );
          });
      },
      providesTags: [CSV_REPORTS_TAGS.TEAM_USERS_CSV],
    }),
    getCsvReportById: builder.query<GetCsvReportByIdResponseT["body"], { reportId: number }>({
      query: ({ reportId }) => ({
        url: `reports/${reportId}`,
        params: {},
      }),
      transformResponse: (res: GetCsvReportByIdResponseT) => {
        SchemaValidator.validate<typeof GetCsvReportByIdResponseSchema>({
          schema: GetCsvReportByIdResponseSchema,
          schemaName: "GetCsvReportByIdResponseSchema",
          dto: camelizeKeys(res),
        });
        return camelizeKeys(res.body);
      },
      onQueryStarted: (_queryParams, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(({ data }) => {
            if (data.report.jobStatus === "error") {
              dispatch(
                actions.setAlertMessageVisible({
                  message: messages.ERROR_GETTING_CSV,
                  severity: "error",
                  duration: 6000,
                }),
              );
            }
            if (data.report.url) {
              dispatch(
                actions.setAlertMessageVisible({
                  message: messages.GET_CSV_SUCCESS,
                  severity: "success",
                }),
              );
            }
          })
          .catch(() => {
            dispatch(
              actions.setAlertMessageVisible({
                message: messages.ERROR_GETTING_CSV,
                severity: "error",
              }),
            );
          });
      },
    }),
  }),
});
