import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { isEmpty } from "lodash";

import { actions, selectors } from "rdx";
import * as opts from "lib/misc/dropdownOptions";
import { formatPhoneForPrefixInput, convertHtmlToPlainText } from "lib/helpers";
import FEATURE_FLAGS from "@flags";
import { useSmartForm } from "hooks/useSmartForm";
import usePermissions, { permissionTypes } from "hooks/usePermissions";
import useRouteMatcher from "hooks/useRouteMatcher";
import { CustomFieldT } from "types/Project/interfaces";

import EnterpriseCustomFields from "containers/Sales/projects/components/EnterpriseCustomFields";
import Banners from "components/Banners";
import { createBannerDetails } from "components/Banners/helpers";

import FastFill from "components/mui-styled-components/SmartFormElements/FastFill";
import SmartForm from "components/SmartForm";
import { PrimaryButton } from "components/mui-styled-components/Buttons";
import {
  SmartFormTextField,
  SmartFormDropdown,
  SmartFormPhone,
  SmartFormSwitch,
  SmartFormTextarea,
} from "components/mui-styled-components/SmartFormElements";
import SmartFormAddressAutocomplete, {
  AddressSuggestionT,
} from "components/mui-styled-components/SmartFormElements/SmartFormAddressAutocomplete";
import SmartFormAvailabilityPicker, {
  blankAvailabilityData,
  AvailabilityT,
} from "components/mui-styled-components/SmartFormElements/SmartFormAvailabilityPicker";
import DefaultM1OptionModal from "containers/Sales/projects/components/DefaultM1OptionModal";
import { fastFillData, AddUpdateLeadFormT, schema, blankFormData } from "./LeadFormData";

import * as St from "../SiteInfoForm/styles";

import * as staticData from "./LeadFormData";
import * as S from "./styles";

type LeadSummaryFormT = {
  reference: React.LegacyRef<HTMLButtonElement>;
  currentTab: number;
  customFields: CustomFieldT[];
  powurFields: CustomFieldT[];
  enterpriseName: string;
  setCurrentTab: (tabIdx: number) => void;
  setIsSubmitDisabled: (bool: boolean) => void;
  setIsFormSubmitting: (bool: boolean) => void;
  setIsFormTouched: (bool: boolean) => void;
  setIsAddressPresent: (bool: boolean) => void;
};

const LeadSummaryForm = ({
  reference,
  currentTab,
  setCurrentTab,
  setIsSubmitDisabled,
  setIsFormSubmitting,
  setIsFormTouched,
  setIsAddressPresent,
  customFields,
  powurFields,
  enterpriseName,
}: LeadSummaryFormT) => {
  const dispatch = useDispatch();
  const leadId: number = useSelector(selectors.selectLeadToProposalDrawerLeadId);
  const leadDetails = useSelector(selectors.selectLeadToProposalLeadDetails);
  const isNonServiceable = !!leadId && !leadDetails?.submitted && leadDetails?.submittableStatus?.status !== "ok";
  const [isAvailabilityTouched, setIsAvailabilityTouched] = useState<boolean>(false);
  const [isOtherLeadSource, setIsOtherLeadSource] = useState<boolean>(false);
  const [availabilityData, setAvailabilityData] = useState<AvailabilityT[]>([blankAvailabilityData]);
  const [availabilitiesToDelete, setAvailabilitiesToDelete] = useState<number[]>([]);
  const { isEnterprise, isAdmin } = useRouteMatcher();
  const addressFieldsRequired = !!leadId;

  const { methods, getSmartFormProps } = useSmartForm<AddUpdateLeadFormT>({
    schema,
    useFormProps: { defaultValues: blankFormData },
  });
  const { ENTERPRISE_LEAD_GENERATOR } = usePermissions({
    permissionRequests: [permissionTypes.ENTERPRISE_LEAD_GENERATOR],
  });

  const {
    watch,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = methods;

  const values = watch();
  const addressFieldsIncomplete =
    addressFieldsRequired && (!values.street || !values.city || !values.state || !values.zip);

  useEffect(() => {
    if (!!leadId && !isEmpty(leadDetails)) {
      const formattedAvailability = formatAvailability(leadDetails.homeownerAvailability);
      reset({
        firstName: leadDetails.firstName || "",
        lastName: leadDetails.lastName || "",
        email: leadDetails.email || "",
        phone: formatPhoneForPrefixInput(leadDetails.phone),
        locale: leadDetails.locale || "",
        street: leadDetails?.address?.street || "",
        city: leadDetails?.address?.city || "",
        state: leadDetails?.address?.state || "",
        zip: leadDetails?.address?.zip || "",
        category: leadDetails.category || "",
        ...(FEATURE_FLAGS.includes("salesEnablement") ? { homeownerAvailability: formattedAvailability } : {}),
        leadSource: determineLeadSource(leadDetails.leadSource),
        altSource: leadDetails.leadSource || "",
        copilot: leadDetails.copilot,
        homeownerNotes: convertHtmlToPlainText(leadDetails?.homeownerNotes),
      });
      setAvailabilityData(formattedAvailability);
    }
  }, [leadDetails, !!leadId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsSubmitDisabled(!isDirty && !isAvailabilityTouched && isNonServiceable);
    setIsFormTouched(isDirty || isAvailabilityTouched);
  }, [isDirty, isAvailabilityTouched, isNonServiceable]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsOtherLeadSource(values.leadSource === "Other");
  }, [values.leadSource]);

  useEffect(() => {
    const isAddressPresent = !!values.street && !!values.city && !!values.state && !!values.zip;
    setIsAddressPresent(isAddressPresent);
    const reqdCreateFieldsPresent =
      !!values.firstName && !!values.lastName && !!values.email && !!values.phone.phoneNumber && !!values.zip;
    setIsSubmitDisabled(!reqdCreateFieldsPresent);
  }, [
    values.firstName,
    values.lastName,
    values.email,
    values.phone.phoneNumber,
    values.street,
    values.city,
    values.state,
    values.zip,
    setIsAddressPresent,
    setIsSubmitDisabled,
  ]);

  const determineLeadSource = (source: string): string => {
    if (!source) return "";
    if (opts.leadSourceValues.includes(source)) {
      return source;
    }
    return "Other";
  };

  const formatAvailability = (availability) => {
    if (!availability || !availability.length) {
      return [blankAvailabilityData];
    }
    return availability;
  };

  const formatFormValues = () => {
    const hasAddressChanged =
      !!leadId && (leadDetails.address.street !== values.street || leadDetails.address.zip !== values.zip);
    const shouldSaveLatLong = !!leadId && leadDetails.isAddressConfirmed;
    return {
      lead: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: `${values.phone.phonePrefix}${values.phone.phoneNumber}`,
        locale: values.locale,
        leadSource: isOtherLeadSource ? values.altSource : values.leadSource,
        category: values.category,
        copilot: values.copilot,
        homeownerNotes: values.homeownerNotes,
      },
      address: {
        street: values.street,
        city: values.city,
        state: values.state,
        zip: values.zip,
        ...(shouldSaveLatLong && {
          // invalidate lat/long if address changes
          // persist if specific lat/long has been confirmed by user
          latitude: hasAddressChanged ? null : leadDetails.address.lat,
          longitude: hasAddressChanged ? null : leadDetails.address.long,
        }),
      },
      ...(hasAddressChanged && {
        siteInfo: {
          isAddressConfirmed: false,
        },
      }),
    };
  };

  const updateExistingLead = () => {
    const { lead, address, siteInfo } = formatFormValues();
    if (availabilitiesToDelete.length) {
      dispatch(actions.deleteHomeownerAvailabilities({ availabilityIds: availabilitiesToDelete }));
    }
    dispatch(
      actions.updateLead({
        leadId,
        lead,
        address,
        homeownerAvailability: values.homeownerAvailability,
        enterprise: isEnterprise,
        admin: isAdmin,
        siteInfo,
      }),
    );
  };

  const createNewLead = () => {
    const { lead, address } = formatFormValues();
    dispatch(
      actions.createNewLead({
        lead,
        address,
        homeownerAvailability: values.homeownerAvailability,
        enterprise: isEnterprise,
        isLeadGen: ENTERPRISE_LEAD_GENERATOR,
      }),
    );
  };

  const onSubmit = (): void => {
    if (isDirty || isAvailabilityTouched) {
      dispatch(
        actions.setAlertMessageVisible({
          message: "Submitting. . .",
          severity: "info",
          duration: 6000,
        }),
      );
      setIsSubmitDisabled(true);
      setIsFormSubmitting(true);
      if (leadId) {
        updateExistingLead();
      } else {
        createNewLead();
      }
    } else if (!isNonServiceable) {
      setCurrentTab(currentTab + 1);
    }
  };

  const onError = (e) => {
    dispatch(
      actions.setAlertMessageVisible({
        message: `Invalid Fields: ${Object.keys(e).join(", ")}`,
        severity: "warning",
      }),
    );
  };

  const updateAvailability = (availability: AvailabilityT[]): void => {
    setValue("homeownerAvailability", availability);
    setAvailabilityData(availability);
    setIsAvailabilityTouched(true);
  };

  const handleAutocompleteSelect = (_: React.SyntheticEvent<Element, Event>, value: AddressSuggestionT): void => {
    if (value) {
      setValue("street", value.street);
      setValue("city", value.city);
      setValue("state", value.state);
      setValue("zip", value.zip);
    }
  };

  const clearAddressValues = (): void => {
    setValue("street", "");
    setValue("city", "");
    setValue("state", "");
    setValue("zip", "");
  };

  const handleLeadSourceChange = (value): void => {
    setValue("leadSource", value);
    setIsOtherLeadSource(value === "Other");
  };

  const handleDeleteAvailabilitySection = (sectionIndex: number, sectionId: number | null | undefined): void => {
    if (sectionId) setAvailabilitiesToDelete([...availabilitiesToDelete, sectionId]);
    const newAvailability = getValues("homeownerAvailability");
    if (newAvailability) {
      newAvailability.splice(sectionIndex, 1);
      updateAvailability(newAvailability as AvailabilityT[]);
    }
  };

  const handleAddAvailabilitySection = (): void => {
    const newAvailability = getValues("homeownerAvailability");
    if (newAvailability) {
      newAvailability.push(blankAvailabilityData);
      updateAvailability(newAvailability as AvailabilityT[]);
    }
  };

  return (
    <SmartForm {...getSmartFormProps({})}>
      <FastFill setValue={setValue} fastFillData={fastFillData} />
      <Banners bannerDetails={createBannerDetails(leadDetails.showThdBanner)} />
      {powurFields.length > 0 && (
        <St.CustomFieldsContainer>
          <St.StyledSectionLabel>Custom Fields</St.StyledSectionLabel>
          <EnterpriseCustomFields customFields={powurFields} />
          <S.StyledDivider />
        </St.CustomFieldsContainer>
      )}
      {customFields.length > 0 && (
        <St.CustomFieldsContainer>
          <St.StyledSectionLabel>{enterpriseName} Fields</St.StyledSectionLabel>
          <EnterpriseCustomFields customFields={customFields} />
          <S.StyledDivider />
        </St.CustomFieldsContainer>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <S.StyledSectionLabel>Homeowner Info</S.StyledSectionLabel>
        </Grid>
        <Grid item xs={6}>
          <SmartFormTextField
            name="firstName"
            label="First Name"
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <SmartFormTextField
            name="lastName"
            label="Last Name"
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <SmartFormTextField
            name="email"
            label="Email"
            error={!!errors.email}
            helperText={errors.email?.message}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SmartFormPhone
            name="phone"
            label="Phone"
            error={!!errors.phone?.phoneNumber || !!errors.phone?.phonePrefix}
            helperText={errors.phone?.phoneNumber?.message || errors.phone?.phonePrefix?.message}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SmartFormDropdown
            name="locale"
            mainLabel="Language Preference"
            dropdownOptions={opts.languageOptions}
            error={!!errors.locale}
            helperText={errors.locale?.message}
          />
        </Grid>
      </Grid>
      <S.StyledDivider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <S.StyledSectionLabel>Physical Address</S.StyledSectionLabel>
          {addressFieldsIncomplete && <S.NoteUrgent>Required to progress to lead qualification</S.NoteUrgent>}
        </Grid>
        <Grid item xs={12}>
          <SmartFormAddressAutocomplete
            name="street"
            label="street address"
            getValues={getValues}
            handleAutocompleteSelect={handleAutocompleteSelect}
            clearValues={clearAddressValues}
            required={addressFieldsRequired}
            hasRedHighlight={addressFieldsRequired && !values.street}
          />
        </Grid>
        <Grid item xs={8} sm={5}>
          <SmartFormTextField
            label="city"
            name="city"
            required={addressFieldsRequired}
            hasRedHighlight={addressFieldsRequired && !values.city}
          />
        </Grid>
        <Grid item xs={4} sm={3}>
          <SmartFormDropdown
            dropdownOptions={opts.stateOptions()}
            renderValue={(selected) => <>{selected}</>}
            mainLabel="state"
            name="state"
            required={addressFieldsRequired}
            hasRedHighlight={addressFieldsRequired && !values.state}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <SmartFormTextField
            label="zipcode"
            name="zip"
            required
            error={!!errors.zip}
            helperText={errors.zip?.message}
          />
        </Grid>
        <Grid item xs={6}>
          <SmartFormDropdown
            dropdownOptions={opts.categoryOptions}
            mainLabel="Category"
            name="category"
            error={!!errors.category}
            helperText={errors.category?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <S.PuertoRicoNote>{staticData.puertoRicoNote}</S.PuertoRicoNote>
        </Grid>
      </Grid>
      {FEATURE_FLAGS.includes("salesEnablement") && (
        <>
          <S.StyledDivider />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <S.StyledSectionLabel>Homeowner Availability</S.StyledSectionLabel>
            </Grid>
            <Grid item xs={12}>
              <S.StyledNote>{staticData.availabilityNote}</S.StyledNote>
            </Grid>
            <Grid item xs={12}>
              {availabilityData.map((availability, idx: number) => (
                <SmartFormAvailabilityPicker
                  key={`${availability.id}-${idx + 1}`}
                  mainLabel={`Availability ${idx + 1}`}
                  availabilityIdx={idx}
                  availabilityId={availability.id}
                  setAvailabilityValue={(availabilityName, availabilityValue) =>
                    setValue(availabilityName, availabilityValue)
                  }
                  handleDeleteSection={handleDeleteAvailabilitySection}
                  setIsFormTouched={setIsAvailabilityTouched}
                />
              ))}
              <Grid item xs={12} sm={4}>
                <PrimaryButton onClick={handleAddAvailabilitySection} sx={{ marginBottom: "15px", width: "100%" }}>
                  + Add Availability
                </PrimaryButton>
              </Grid>
              {errors.homeownerAvailability && (
                <S.StyledErrorText>
                  Available Days, Start Time, and End Time required for all Availabilities
                </S.StyledErrorText>
              )}
            </Grid>
          </Grid>
        </>
      )}
      <S.StyledDivider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <S.StyledSectionLabel>Homeowner Notes</S.StyledSectionLabel>
        </Grid>
        <Grid item xs={12}>
          <S.StyledHomeownerNote>
            Add detailed notes for yourself or your closer. <S.Blue>Learn more</S.Blue>
          </S.StyledHomeownerNote>
        </Grid>
        <Grid item xs={12}>
          <SmartFormTextarea
            name="homeownerNotes"
            mainLabel="Notepad"
            mainLabelStyle={{ ...S.FormLabel, textTransform: "uppercase" }}
            height={132}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <SmartFormDropdown
            dropdownOptions={opts.leadSourceOptions}
            mainLabel="Lead Source"
            name="leadSource"
            onChange={(e) => handleLeadSourceChange(e.target.value)}
            error={!!errors.leadSource}
            helperText={errors.leadSource?.message}
          />
        </Grid>
        {isOtherLeadSource && (
          <Grid item xs={12}>
            <SmartFormTextField
              name="altSource"
              label="Source"
              error={!!errors.altSource}
              helperText={errors.altSource?.message}
            />
          </Grid>
        )}
      </Grid>
      {!!leadId && (
        <>
          <S.StyledDivider sx={{ marginTop: "40px" }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <S.StyledSectionLabel>Copilot Program</S.StyledSectionLabel>
            </Grid>
            <Grid item xs={12}>
              <S.StyledCopilotNote>{staticData.copilotNote}</S.StyledCopilotNote>
            </Grid>
            <Grid item xs={12}>
              <SmartFormSwitch
                name="copilot"
                switchText={staticData.copilotText}
                checked={getValues("copilot") || false}
                error={!!errors.copilot}
                helperText={errors.copilot?.message}
              />
            </Grid>
          </Grid>
        </>
      )}
      <button ref={reference} style={{ display: "none" }} type="button" onClick={handleSubmit(onSubmit, onError)} />
      {ENTERPRISE_LEAD_GENERATOR && FEATURE_FLAGS.includes("leadGenDefaultM1") && (
        <DefaultM1OptionModal onSave={createNewLead} />
      )}
    </SmartForm>
  );
};

export default LeadSummaryForm;
