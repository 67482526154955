import PropTypes from "prop-types";
import { store } from "rdx/configureStore";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import Lead, { defaults as leadDefaults } from "models/Lead";
import { Base } from "./Base";
import { agentDefaults, agentSchema, closeReasonsSchema } from "./shared";
import { visionProposalSchema } from "./shared/visionProposal";

const {
  updateProjectManagementUsers,
  getProjectMessageList,
  setProjectMessageList,
  getAssets,
  postNewAsset,
  deleteAsset,
  downloadAssets,
  postNewNote,
  deleteNote,
  editNote,
  setAdminProjectsList,
  updateProject,
  updateAdminProjectLead,
  getAdminProjectProposals,
  reopenAdminProject,
  closeAdminProject,
  requestAdminProposal,
  inviteToMyPowur,
} = actions;

export default class Project extends Base {
  constructor(props = null) {
    if (!props || Object.keys(props).length === 0) super(projectDefaults);
    else super(keysToCamelCase(props));
  }

  updateProjectManagement(id, key) {
    store.dispatch(
      updateProjectManagementUsers({
        newUserId: id,
        userParamKey: key,
        projectId: this.props.id,
      }),
    );
  }

  getAdminProjectProposals = () => {
    if (this.props.id) {
      store.dispatch(getAdminProjectProposals({ id: this.props.id }));
    }
  };

  getMessages = (paging) => {
    store.dispatch(getProjectMessageList({ project_id: this.props.id, ...paging }));
  };

  resetMessages = () => {
    store.dispatch(setProjectMessageList({ list: [] }));
  };

  getAssets = () => {
    store.dispatch(getAssets({ leadId: this.props.id }));
  };

  downloadAssets = () => {
    store.dispatch(downloadAssets({ projectLeadId: this.props.id }));
  };

  postNewAsset = (fileUrl) => {
    store.dispatch(postNewAsset({ leadId: this.props.id, fileUrl }));
  };

  deleteAsset = (assetId) => {
    store.dispatch(deleteAsset({ leadId: this.props.id, assetId }));
  };

  postNewNote = (body, tags) => {
    store.dispatch(postNewNote({ leadId: this.props.id, body, tags }));
  };

  deleteNote = (noteId) => {
    store.dispatch(deleteNote({ leadId: this.props.id, noteId }));
  };

  editNote = (noteId, body, tags) => {
    store.dispatch(editNote({ leadId: this.props.id, noteId, body, tags }));
  };

  previewMilestoneUpdate = (payload) => {
    const newState = {
      ...this.props,
      netRevenue: payload.net_revenue,
      m1At: payload.m1_at,
      m2At: payload.m2_at,
      validating: "validating",
    };
    store.dispatch(
      setAdminProjectsList({
        project: newState,
      }),
    );
  };

  updatePaymentMilestones = (payload) => {
    const { net_revenue, m1_at, m2_at, m3_at } = payload;
    store.dispatch(updateProject({ projectId: this.props.id, net_revenue, m1_at, m2_at, m3_at }));
  };

  updateEvent = (payload) => {
    store.dispatch(updateProject({ projectId: this.props.id, ...payload }));
  };

  updateM1 = (m1_at) => {
    store.dispatch(updateProject({ projectId: this.props.id, m1_at }));
  };

  updateM2 = (m2_at) => {
    store.dispatch(updateProject({ projectId: this.props.id, m2_at }));
  };

  updateAdminProjectLead = ({ lead, address }) => {
    store.dispatch(
      updateAdminProjectLead({
        projectId: this.props.id,
        leadId: this.props.lead.id,
        lead,
        address,
      }),
    );
  };

  close = (closeReasonId, closeNote) => {
    store.dispatch(closeAdminProject({ id: this.props.id, closeReasonId, closeNote }));
  };

  reopen = () => {
    store.dispatch(reopenAdminProject({ id: this.props.id }));
  };

  updateProposalUrl = (proposalUrl) => {
    store.dispatch(updateProject({ projectId: this.props.id, proposalUrl }));
  };

  requestAdminProposal = (realTime) => {
    store.dispatch(requestAdminProposal({ realTime, id: this.props.id }));
  };

  unlockProposals = ({ proposalLockedAt }) => {
    store.dispatch(updateProject({ projectId: this.props.id, proposalLockedAt }));
  };

  updateTeam = (teamId) => {
    store.dispatch(updateProject({ projectId: this.props.id, teamId }));
  };

  sendHomeownerInvite = () => {
    store.dispatch(inviteToMyPowur({ id: this.props.id, inviteKind: "homeowner" }));
  };

  updateSiteInfo = ({ projectUpdate, utilityBillUrl, notes, siteInfo, existingSystem }) => {
    store.dispatch(
      updateProject({
        projectId: this.props.id,
        projectUpdate,
        utilityBillUrl,
        notes,
        siteInfo,
        existingSystem,
      }),
    );
  };

  updateLeadInfo = ({ lead, address, enterprise, siteInfo }) => {
    const { updateProjectLeadInfo } = actions;
    store.dispatch(
      updateProjectLeadInfo({
        leadId: this.props.lead.id,
        lead,
        address,
        enterprise,
        siteInfo,
        admin: true,
      }),
    );
  };

  static types() {
    return PropTypes.shape({
      agent: agentSchema,
      apiUri: PropTypes.string,
      canRequestProposal: PropTypes.bool,
      canRequestRoofingProposal: PropTypes.bool,
      closeAt: PropTypes.string,
      closeNote: PropTypes.string,
      closeReasons: closeReasonsSchema,
      createdAt: PropTypes.string,
      dataFields: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          dataGroupId: PropTypes.number,
          label: PropTypes.string,
          description: PropTypes.string,
          exitedAt: PropTypes.string,
          dataType: PropTypes.string,
          required: PropTypes.bool,
          value: PropTypes.string,
        }),
      ),

      dataGroups: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
      financing: PropTypes.string,
      hoaName: PropTypes.string,
      hoaPhone: PropTypes.string,
      id: PropTypes.number,
      installationNotes: PropTypes.string,
      installerId: PropTypes.number,
      kwhConsumption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      utilityCompany: PropTypes.string,
      lastEvent: PropTypes.shape({
        eventAt: PropTypes.string,
        title: PropTypes.string,
      }),
      lastMessageAt: PropTypes.string,
      lastNote: PropTypes.string,
      latestProposal: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      // see Lead.types()
      lead: Lead.types(), // eslint-disable-line react/forbid-prop-types
      milestones: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line react/forbid-prop-types
      mounting: PropTypes.string,
      netRevenue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      paymentMilestones: PropTypes.shape({
        m1Editable: PropTypes.bool,
        m2Editable: PropTypes.bool,
        netRevenueEditable: PropTypes.bool,
      }),
      preSubmitAt: PropTypes.string,
      phases: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          title: PropTypes.string,
          rank: PropTypes.number,
        }),
      ),
      qualifyComplete: PropTypes.bool,
      userCanRequestProposal: PropTypes.bool,
      proposalNonrequestableReason: PropTypes.string,
      mentorRequirementMet: PropTypes.bool,
      stages: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          rank: PropTypes.number,
          milestones: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              at: PropTypes.string,
              data: PropTypes.arrayOf(
                PropTypes.shape({
                  id: PropTypes.string,
                  name: PropTypes.string,
                  type: PropTypes.string,
                  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                }),
              ),
            }),
          ),
        }),
      ),
      submitAt: PropTypes.string,
      updatedAt: PropTypes.string,
      visionProposal: visionProposalSchema,
    });
  }

  static defaults() {
    return projectDefaults;
  }

  static filterTypes() {
    return PropTypes.shape({
      filters: PropTypes.shape({
        stage: PropTypes.shape({
          name: PropTypes.string,
          default: PropTypes.string,
          values: PropTypes.arrayOf(PropTypes.string),
        }),
        installers: PropTypes.shape({
          name: PropTypes.string,
          values: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line react/forbid-prop-types
          // array of objects containing key-value pairs,
          // key = value to send to server
          // value = display string
        }),
        consultants: PropTypes.shape({
          name: PropTypes.string,
          values: PropTypes.string,
          // values is a req url for the purposes of an autocomplete/user lookup
        }),
        mentors: PropTypes.shape({
          name: PropTypes.string,
          values: PropTypes.string,
          // same as above
        }),
      }),
      sorts: PropTypes.arrayOf(PropTypes.string),
    });
  }
}

export const projectDefaults = {
  agent: agentDefaults,
  apiUri: "",
  canRequestProposal: false,
  canRequestRoofingProposal: false,
  closeAt: null,
  closeNote: null,
  createdAt: "",
  dataFields: [],
  dataGroups: [],
  financing: "",
  id: 0,
  installerId: 0,
  kwhConsumption: 0,
  utilityCompany: "",
  lastNote: "",
  lead: leadDefaults,
  qualifyComplete: false,
  userCanRequestProposal: false,
  proposalNonrequestableReason: "",
  visionProposalNonrequestableReason: "",
  soloProposalNonrequestableReason: "",
  lightreachNonrequestableReason: "",
  mentorRequirementMet: false,
  stages: [],
  submitAt: "",
  updatedAt: "",
  visionProposal: {},
  homeowner: {
    address: {
      state: "",
    },
  },
};
