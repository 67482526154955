export default {
  CREATING_APPCLIENT_TOKEN_SUCCESS: "AppClient token created successfully.",
  GET_GOOGLE_AUTHORIZE_URL_SUCCESS: "Google authorize url retrieved successfully.",
  EXCHANGE_GOOGLE_CALLBACK_SUCCESS: "Google callback exchanged successfully.",
  GET_GOOGLE_ACCESS_STATUS_SUCCESS: "Google access status retrieved successfully.",
  GET_GOOGLE_USER_SUCCESS: "Google user retrieved successfully.",
  GET_USER_EMAIL_INTEGRATION_TYPE_SUCCESS: "User email integration type retrieved successfully.",
  SET_USER_EMAIL_INTEGRATION_TYPE_SUCCESS: "User email integration type set successfully.",

  // error messages
  ERROR_CREATING_APPCLIENT_TOKEN: "There was an error creating the AppClient token.",
  ERROR_GETTING_GOOGLE_AUTHORIZE_URL: "There was an error retrieving the Google authorize url.",
  ERROR_EXCHANGING_GOOGLE_CALLBACK: "There was an error exchanging the Google callback.",
  ERROR_GETTING_GOOGLE_ACCESS_STATUS: "There was an error retrieving the Google access status.",
  ERROR_GETTING_GOOGLE_USER: "There was an error retrieving the Google user.",
  ERROR_GETTING_USER_EMAIL_INTEGRATION_TYPE: "There was an error retrieving the user email integration type.",
  ERROR_SETTING_USER_EMAIL_INTEGRATION_TYPE: "There was an error setting the user email integration type.",
};
