import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getProjectLeadsAutoComplete(action) {
  const params = action.payload;
  const { success, data, error } = yield* makeRequest.get("/projects/search_with_milestones", params);
  if (success && data) {
    if (data.body.projects) {
      yield put(actions.setProjectLeadsAutoComplete(camelizeKeys(data.body.projects)));
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_PROJECTS_AUTOCOMPLETE,
    });
  }
  return null;
}

export default getProjectLeadsAutoComplete;
