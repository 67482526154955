import React from "react";

const ManagerIcon = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="71" cy="71" r="71" fill="var(--lightest-grey)" />
      <mask id="mask0_4228_3053" maskUnits="userSpaceOnUse" x="0" y="0" width="142" height="142">
        <circle cx="71" cy="71" r="71" fill="var(--lightest-grey)" />
      </mask>
      <g mask="url(#mask0_4228_3053)">
        <path
          d="M81.6768 89.5391L73.6086 144.001H68.3912L60.4429 89.6692"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M81.6769 89.5391L94.4782 107.479L88.559 113.252L96.3851 120.093L73.6086 144.001"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M60.4431 89.668L47.5218 107.478L53.5185 113.25L45.6924 120.091L68.4689 144"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M45.9805 63.8154C45.9805 63.8154 37.0881 67.2869 48.6409 78.7838C48.6409 78.7838 54.0503 91.641 71.0011 91.641C87.952 91.641 92.3003 78.7838 92.3003 78.7838C103.592 70.6086 96.5131 64.7275 96.5131 64.7275C96.5131 64.7275 103.445 53.0521 99.7968 39.0051C99.7968 39.0051 97.0604 30.2486 87.939 34.6269C87.939 34.6269 79.626 19.1373 58.3683 25.5594C58.3683 25.5594 30.1092 35.7214 45.9805 63.8154Z"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M98.7094 59.747C98.7094 59.747 96.8936 54.3954 83.8501 47.4797L81.4348 53.1715C81.4348 53.1715 76.2174 44.2632 57.0806 43.4023L57.9566 51.8677C57.9566 51.8677 50.8228 48.6229 41.3567 51.005"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M87.0325 97.043L104.918 101.288C120.206 104.916 131 118.571 131 134.284V143.999H11V134.302C11 118.581 21.8045 104.921 37.103 101.3L55.0921 97.043"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
};

export default ManagerIcon;
