import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  noticesList: {
    notices: [],
    paging: {
      page_size: 50,
      item_count: 0,
      page_number: 1,
    },
  },
  noticesListFilters: {
    filters: {},
    sorts: [],
  },
  noticesListAutoComplete: {},
  noticeDetails: {},
  noticeNotes: [],
  uiState: {
    list: {},
    detail: {},
  },
  noticePreview: {},
};

export default {
  adminNotices: createReducer(initialState, {
    [types.SET_ADMIN_NOTICES_LIST](state, action) {
      return {
        ...state,
        noticesList: {
          ...state.noticesList,
          ...action.payload,
        },
      };
    },
    [types.SET_ADMIN_NOTICE_PREVIEW](state, action) {
      return {
        ...state,
        noticePreview: {
          ...action.payload,
        },
      };
    },

    [types.SET_ADMIN_NOTICES_LIST_FILTERS](state, action) {
      return {
        ...state,
        noticesListFilters: {
          ...state.noticesListFilters,
          ...action.payload,
        },
      };
    },

    // [types.SET_ADMIN_NOTICES_AUTOCOMPLETE_SUGGESTIONS](state, action) {
    //   const { key, suggestions } = action.payload;
    //   if (key) {
    //     return {
    //       ...state,
    //       noticesListAutoComplete: {
    //         ...state.noticesListAutoComplete,
    //         [key]: suggestions || [],
    //       },
    //     };
    //   }
    //   return state;
    // },

    [types.SET_ADMIN_NOTICE_DETAILS](state, action) {
      return {
        ...state,
        noticeDetails: action.payload,
      };
    },

    [types.SET_ADMIN_NOTICES_UI_STATE](state, action) {
      return {
        ...state,
        uiState: {
          ...state.uiState,
          ...action.payload,
        },
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
