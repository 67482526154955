import { put } from "redux-saga/effects";
import { camelizeKeys } from "lib/helpers";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/filterSets/messages";

function* getFilterSetsByView(action) {
  const params = action.payload;

  const { success, data, error } = yield* makeRequest.get("/filter_sets", params);

  if (success && data) {
    yield put(actions.setFilterSets(camelizeKeys(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_FILTER_SET,
    });
  }
  return null;
}

export default getFilterSetsByView;
