/*
Per ticket #8478374060 "Temporarily Hide Sunnova Lead Banner": hiding all instances of thd_sunnova
 banner, but prepared to reenable everywhere with uncommenting code in this single helper.
*/
// import React from "react";
// import { AlertIcon } from "components/Icons";
// import { cvar } from "styles/colorVariables";
// import { InfoBannerProps } from "./types";

export const createBannerDetails = (showThdBanner) => {
  const bannerDetails: InfoBannerProps[] = [];
  if (showThdBanner) {
    // if (false) {
    // const detail = {
    //   title: "Home Depot Lead",
    //   text: "Must be sold with Sunnova Financing",
    //   icon: <AlertIcon fill={cvar("blue-ribbon")} />,
    //   bannerType: "info",
    // };
    // bannerDetails.push(detail);
  }
  return bannerDetails;
};
