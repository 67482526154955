import React from "react";
import PropTypes from "prop-types";
import TermsAndConditions from "./TermsAndConditions";
import CreatePassword from "./CreatePassword";

import styles from "./FinalizeAccount.module.less";

const FinalizeAccount = ({ handleSubmit, applicationAgreement, loading, passwordCreated, savePassword }) => {
  return (
    <div className={styles.finalizeAcctContainer}>
      {!passwordCreated ? (
        <CreatePassword savePassword={savePassword} />
      ) : (
        <TermsAndConditions
          handleCreateAccount={handleSubmit}
          applicationAgreement={applicationAgreement}
          loading={loading}
        />
      )}
    </div>
  );
};

FinalizeAccount.propTypes = {
  handleSubmit: PropTypes.func,
  applicationAgreement: PropTypes.shape({
    documentPath: PropTypes.string,
    message: PropTypes.string,
    version: PropTypes.string,
  }),
  loading: PropTypes.bool,
  passwordCreated: PropTypes.bool,
  savePassword: PropTypes.func,
};

export default FinalizeAccount;
