import PropTypes from "prop-types";

const types = {
  extra: PropTypes.shape({
    accept: PropTypes.string,
    active: PropTypes.bool,
    dragged: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    maxFiles: PropTypes.number,
    maxSizeBytes: PropTypes.number,
    minSizeBytes: PropTypes.number,
    multiple: PropTypes.bool,
    reject: PropTypes.bool,
  }),

  fileWithMeta: PropTypes.shape({
    cancel: PropTypes.func,
    file: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    remove: PropTypes.func,
    restart: PropTypes.func,
    xhr: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }),

  meta: PropTypes.shape({
    id: PropTypes.string,
    lastModifiedDate: PropTypes.string,
    name: PropTypes.string,
    percent: PropTypes.number,
    signedUrl: PropTypes.string,
    size: PropTypes.number,
    status: PropTypes.string,
    type: PropTypes.string,
    uploadedDate: PropTypes.string,

    // if selected file is an image:
    previewUrl: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
};

export default types;
