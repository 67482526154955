import { put, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import enterpriseSelectors from "rdx/modules/enterpriseLeads/selectors";
import adminSelectors from "rdx/modules/leads/selectors";
import preSalesSelectors from "rdx/modules/projectPreSales/selectors";

import messages from "../messages";

function* deleteLead(action) {
  const { leadId, enterprise, admin } = action.payload;
  if (!leadId) {
    throw new Error(`Invalid lead ID = ${leadId} specified. Please check this parameter.`);
  }

  const params = { enterprise, admin };

  const { success, data, error } = yield* makeRequest.delete(`/leads/${leadId}`, params);
  const enterpriseLeads = yield select(enterpriseSelectors.getEnterpriseLeads);
  const adminLeads = yield select(adminSelectors.getLeads);
  const { projects: presales, paging: presalesPaging } = yield select(preSalesSelectors.getProjectPreSales);

  const entProjectLeads = enterpriseLeads.leads.filter((el) => el.id !== leadId);
  const adminProjectLeads = adminLeads.leads.filter((el) => el.id !== leadId);
  const presalesProjects = presales.filter((el) => el.leadId !== leadId);

  if (success && data) {
    yield put(actions.newSuccessEvent({ message: messages.LEAD_DELETE_SUCCESS }));
    yield put(actions.setLeads({ ...adminLeads, leads: adminProjectLeads }));
    yield put(actions.setEnterpriseLeads({ ...enterpriseLeads, leads: entProjectLeads }));
    yield put(
      actions.setProjectsPreSales({
        projects: presalesProjects,
        paging: { ...presalesPaging, itemCount: presalesPaging.itemCount - 1 },
      }),
    );
  } else {
    return getErrorActions({ error, message: messages.ERROR_DELETING_LEAD });
  }
  return null;
}

export default deleteLead;
