import { put } from "redux-saga/effects";
import { camelizeKeys } from "lib/helpers";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "../actions";
import messages from "../messages";

function* getDashboardCarouselPromotions() {
  const { success, data, error } = yield* makeRequest.get("/dashboard_promotions/carousel");

  if (success && data) {
    const out = {};
    const { promotions } = data.body;

    if (promotions) {
      out.promotions = camelizeKeys(promotions);
      out.promotions.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
    }

    yield put(actions.setDashboardCarouselPromotions(out));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.GET_EVENTS_AND_PROMOS_ERROR,
    });
  }
  return null;
}

export default getDashboardCarouselPromotions;
