import types from "rdx/modules/enrollment/actionTypes";
import createAction from "rdx/utils/createAction";

export default {
  renewEnrollment: (payload) => createAction(types.RENEW_ENROLLMENT, payload),

  cancelEnrollment: (payload) => createAction(types.CANCEL_ENROLLMENT, payload),

  subscribeEnrollment: (payload) => createAction(types.SUBSCRIBE_ENROLLMENT, payload),
  // INSERTION_PT (for script, do not remove)
};
