import loadable from "@loadable/component";

const content = loadable(() => import("containers/Account/AccountTypes"));

const AccountTypesRoute = {
  key: "account-types",
  title: "Account Types",
  path: "/account-types/:type",
  exact: true,
  linkTarget: null,
  linkTitle: null,
  linkCategory: null,
  icon: () => null,
  basePath: "account-types",
  requiredParams: ["type"],
  optionalParams: [],
  tabs: null,
  content,
  sidebar: null,
};

export default AccountTypesRoute;
