/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";

import type { UseControllerProps, UseFormReturn, FieldValues, UseFormHandleSubmit } from "react-hook-form";

type SmartFormChildPropsT = {
  controllerProps: Omit<UseControllerProps, "name">;
};

type SmartFormPropsT = {
  children: React.ReactNode;
  onSubmit?: ReturnType<UseFormHandleSubmit<FieldValues>>;
  methods: Omit<UseFormReturn<any>, "handleSubmit">;
  formProps?: Record<string, unknown>;
};

type SmartFormContextT = SmartFormChildPropsT;

const SmartFormContext = React.createContext<SmartFormContextT | undefined>(undefined);
SmartFormContext.displayName = "SmartFormContext";

export default function SmartForm({ children, onSubmit, methods, formProps }: SmartFormPropsT) {
  const { control } = methods;

  const value: SmartFormContextT = { controllerProps: { control } };

  return (
    <SmartFormContext.Provider value={value}>
      {onSubmit ? (
        <form {...formProps} onSubmit={onSubmit}>
          {children}
        </form>
      ) : (
        <form {...formProps}>{children}</form>
      )}
    </SmartFormContext.Provider>
  );
}

function useSmartFormContext() {
  const context = React.useContext(SmartFormContext);
  if (context === undefined) {
    throw new Error("useSmartForm must be used within a SmartForm");
  }
  return context;
}

export type { SmartFormPropsT, SmartFormChildPropsT };
export { useSmartFormContext };
