import { VisionClient } from "graphql/visionClient";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { all, put, call, select } from "redux-saga/effects";
import messages from "../messages";

function* requestVisionRevision(action) {
  const { reasons, note, parentId, proposalId, projectId } = action.payload;
  const visionClient = new VisionClient();

  try {
    const { requestDesignRevision } = yield call(visionClient.requestRevision, {
      notes: note,
      reasons,
      parentId,
    });
    const { visionProposals, goSoloProposals } = yield select(selectors.getProjectProposals);

    const visionProposalsClone = [...visionProposals];
    const idx = visionProposalsClone.findIndex((proposal) => {
      return proposal.meta.id === proposalId;
    });
    if (!visionProposalsClone[idx].meta.designs) {
      visionProposalsClone[idx].meta.designs = [];
    }
    visionProposalsClone[idx].meta.designs.push(requestDesignRevision);

    yield all([
      put(actions.invalidateProposalCache({ projectId })),
      put(
        actions.setProjectProposals({
          goSoloProposals,
          visionProposals: visionProposalsClone,
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.REVISION_REQUEST_SUBMIT_SUCCESS,
        }),
      ),
    ]);
    return null;
  } catch (error) {
    return getErrorActions({ error, message: messages.ERROR_REQUESTING_REVISION });
  }
}

export default requestVisionRevision;
