import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "antd";
import MentorRating from "models/MentorRating";
import styles from "./MentorRatingModals.module.less";

const QualifyingModal = (props) => {
  const { ratingInfo, visible, close, transition } = props;

  const contents = useMemo(() => {
    if (!ratingInfo) {
      return {};
    }

    const { project } = ratingInfo;
    const homeowner = `${project.lead.fullName} (${project.city}, ${project.state})`;

    const TEMPLATES = {
      proposal: {
        title: "Proposal",
        message: (
          <>
            Has the proposal for the
            <br />
            <span className={styles.homeowner}>{homeowner}</span>
            <br />
            project been presented to the homeowner?
          </>
        ),
        cancel: false,
      },
      contract: {
        title: "Sale",
        message: (
          <>
            Is there a signed contract for
            <br />
            <span className={styles.homeowner}>{homeowner}</span>
          </>
        ),
        cancel: true,
      },
      install: {
        title: "Install",
        message: (
          <>
            Has the project for
            <br />
            <span className={styles.homeowner}>{homeowner}</span>
            <br />
            been installed?
          </>
        ),
        cancel: false,
      },
    };

    return TEMPLATES[ratingInfo.eventType];
  }, [ratingInfo]);

  const handleSubmit = () => {
    transition();
  };

  const handleDefer = () => {
    new MentorRating(ratingInfo).deferRating("user_delay");
    close();
  };

  const handleClose = () => {
    new MentorRating(ratingInfo).closeRating();
    close();
  };

  return (
    <Modal
      data-test-id="qualifyingModal-mentor"
      className={styles.ratingModal}
      open={visible}
      centered
      destroyOnClose
      footer={null}
      closable={false}
      maskClosable={false}
      width={400}
      zIndex={10000}
      keyboard={false}
      bodyStyle={{
        borderRadius: "12px",
        backgroundColor: "transparent",
        padding: 0,
      }}
      maskStyle={{ backgroundColor: "var(--mask-blue-a75)" }}
    >
      <div className={styles.content}>
        <div className={styles.header}>Mentor Rating - {contents?.title}</div>
        <div className={styles.innerContent}>
          <div className={styles.message}>{contents?.message}</div>
          <div className={styles.buttonsContainer} style={{ width: contents?.cancel ? "100%" : "90%" }}>
            <div className={styles.buttonsRow}>
              <Button className={styles.cancelButton} type="info" onClick={handleDefer}>
                Not Yet
              </Button>
              <Button className={styles.submitButton} type="primary" onClick={handleSubmit}>
                Yes
              </Button>
            </div>
            {contents?.cancel && (
              <div className={styles.buttonsRowSingle}>
                <Button className={styles.cancelButtonBig} type="info" onClick={handleClose}>
                  The project has been put on hold or canceled
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

QualifyingModal.propTypes = {
  ratingInfo: MentorRating.types().isRequired,
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  transition: PropTypes.func.isRequired,
};

export default QualifyingModal;
