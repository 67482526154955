import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, useLocation } from "react-router";
import { connect, useSelector } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import selectors from "rdx/selectors";
import { Badge } from "antd";
import dayjs from "dayjs";
import SessionClient from "util/SessionClient";
import usePermissions, { permissionTypes } from "hooks/usePermissions";
import useWindowResize from "hooks/useWindowResize";
import useRestrictAccessModal from "hooks/useRestrictAccessModal";

import { clone, isEmpty, camelCase, isArray } from "lodash";

import Router from "models/Router";

import routes from "containers/router/routes";
import RouteRow from "./components/RouteRow";
import PreSalesSupportModal from "./components/preSalesSupportLink";

import styles from "./MainNav.module.less";

const MainNav = (props) => {
  const {
    openMainNav,
    closeMainNav,
    clearLatestMessage,
    mainNavCollapsed,
    history,
    currentRoute,
    litmosLoginLink,
    setLitmosLoginLink,
    getInboxNotifications,
    inboxNotificationsCount,
    containerRef,
  } = props;

  const permissions = usePermissions({
    permissionRequests: Object.values(permissionTypes),
  });

  const { width } = useWindowResize();
  const mobile = width <= 500;
  const session = new SessionClient();
  const { isUnpaidUser, restrictAccessModal } = useRestrictAccessModal();

  const [preSalesModalVisible, setPreSalesModalVisible] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const activeRoute = currentRoute.key;

  const bannersHeight = useSelector(selectors.selectBannersHeight);

  const sessionIsSeatedEmployee = session.hasSeat && !session.isOrgOwner;

  routes.platformRoutes = routes.platformRoutes
    .map((route) => {
      const newRoute = route;
      if (route.key === "getting-started") {
        newRoute.linkCategory = session.pendingEnrollment ? "Sales" : "Learn";
        if (sessionIsSeatedEmployee) {
          return null;
        }
      }
      return newRoute;
    })
    .filter((n) => n);

  const externalRoutes = routes.externalRoutes.concat(routes.platformRoutes);

  const learnRoutes = externalRoutes.filter(
    (r) => r.linkCategory === "Learn" && !session.callByDotNotation(r.hideIf) && !r.hideRoute,
    [routes],
  );

  learnRoutes.forEach((route) => {
    if (route.key === "powur-calendar") {
      learnRoutes.push(learnRoutes.splice(learnRoutes.indexOf(route), 1)[0]);
    }
  });

  const supportRoutes = routes.externalRoutes.filter(
    (r) => r.linkCategory === "Support" && !session.callByDotNotation(r.hideIf),
  );

  const [debounceRequestTime, setDebounceRequestTime] = useState(dayjs().startOf("day"));
  useEffect(() => {
    const triggerAgain = dayjs(dayjs().subtract(15, "second")).isAfter(debounceRequestTime);

    if (triggerAgain) {
      setDebounceRequestTime(dayjs());
      getInboxNotifications();
    }

    const timeout = setTimeout(() => {
      setDebounceRequestTime(dayjs());
      getInboxNotifications();
    }, 1000 * 60 * 60);
    return () => clearTimeout(timeout);
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const platformRoutes = useMemo(() => {
    const out = routes.platformRoutes.reduce((allRoutes, route) => {
      const newRoutes = clone(allRoutes);
      const routePermission = isArray(route.permission)
        ? route.permission.some((p) => permissions[p] !== false)
        : permissions[route.permission] !== false;

      if (routePermission && route.linkCategory !== "Personal" && !route.hideRoute) {
        newRoutes[route.linkCategory] = newRoutes[route.linkCategory]
          ? newRoutes[route.linkCategory].concat(route)
          : [route];
      }
      return newRoutes;
    }, {});

    return {
      ...(!isEmpty(out.Powur) ? { Powur: out.Powur } : {}),
      ...(!isEmpty(out.Sales) ? { Sales: out.Sales } : {}),
      ...(!isEmpty(out.Enterprise) ? { Enterprise: out.Enterprise } : {}),
      ...(!isEmpty(out.Admin) ? { Admin: out.Admin } : {}),
      Learn: learnRoutes,
    };
  }, [learnRoutes, permissions]);

  const handleLearnClick = (onClick) => {
    if (isUnpaidUser) {
      restrictAccessModal();
    } else {
      onClick();
    }
  };

  const handlePowurUniversityClick = () => {
    const onClick = () => session.user.getLitmosLoginLink();
    handleLearnClick(onClick);
  };

  const handleKnowledgeBaseClick = () => {
    const onClick = () => window.open("https://help.powur.com/hc/en-us/signin", "_blank");
    handleLearnClick(onClick);
  };

  const specialRouteMethods = {
    salesSupport: () => setPreSalesModalVisible(true),
    powurUniversity: handlePowurUniversityClick,
    powurLive: () => {
      if (isUnpaidUser) {
        restrictAccessModal();
      } else if (session.activePowurLiveService) {
        window.open("https://powur.com/login?sso=live", "_blank");
      } else {
        window.open("https://powur.live", "_blank");
      }
    },
    powurPlay: () => {
      if (isUnpaidUser) {
        restrictAccessModal();
      } else {
        window.open("https://powur.com/login?sso=play", "_blank");
      }
    },
    knowledgeBase: handleKnowledgeBaseClick,
    earnings: () => {
      if (isUnpaidUser) {
        restrictAccessModal();
      } else {
        setTimeout(() => {
          history.push("/earnings");
        }, 300);
      }
    },
  };

  useEffect(() => {
    if (litmosLoginLink) {
      if (mobile) {
        window.location.href = litmosLoginLink;
      } else {
        window.open(litmosLoginLink, "_blank");
      }
    }
    return () => setLitmosLoginLink(null);
  }, [litmosLoginLink]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigate = (e, route) => {
    const { linkTarget, key } = route;
    e.stopPropagation();

    if (!linkTarget) {
      specialRouteMethods[camelCase(key)]();
    } else if (linkTarget.includes("http")) {
      setTimeout(() => {
        window.open(linkTarget, "_blank");
      }, 300);
    } else {
      setTimeout(() => {
        history.push(linkTarget);
      }, 300);
    }

    closeMainNav();
    clearLatestMessage();
  };

  const enterpriseLabel = useMemo(() => {
    const full = permissions.ENTERPRISE_ADMIN_PRO ? "ENTERPRISE" : "ORGANIZATION";
    const short = permissions.ENTERPRISE_ADMIN_PRO ? "ENT" : "ORG";
    const out = mainNavCollapsed ? short : full;

    return <span className={mainNavCollapsed ? "" : styles.entTitle}>{out}</span>;
  }, [mainNavCollapsed, permissions.ENTERPRISE_ADMIN_PRO]);

  const basePadding = 18;

  return (
    <div
      className={mainNavCollapsed ? styles.container : styles.containerExpanded}
      onMouseLeave={closeMainNav}
      onBlur={closeMainNav}
      ref={containerRef}
      style={{
        paddingTop: `${bannersHeight + basePadding}px`,
      }}
    >
      <div className={styles.mainSections} onClick={closeMainNav} onKeyDown={closeMainNav} tabIndex={0} role="button">
        {Object.keys(platformRoutes) &&
          Object.keys(platformRoutes).map((sectionLabel) => {
            return (
              <div key={sectionLabel} className={mainNavCollapsed ? styles.collapsedSection : styles.section}>
                <div className={styles.sectionTitle}>
                  {sectionLabel === "Enterprise" ? enterpriseLabel : <span>{sectionLabel}</span>}
                </div>
                {sectionLabel === "Powur" ? (
                  <div className={styles.inboxMainNav}>
                    <Badge
                      count={inboxNotificationsCount}
                      overflowCount={99}
                      size="default"
                      className={[mainNavCollapsed ? styles.bubbleNotification : styles.collapsedBubbleNotification]}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {platformRoutes[sectionLabel].map((route) => {
                  if (route.key === "reports") return null;
                  return (
                    <RouteRow
                      key={route.key}
                      route={route}
                      activeRoute={activeRoute}
                      mainNavCollapsed={mainNavCollapsed}
                      navigate={navigate}
                      openMainNav={openMainNav}
                    />
                  );
                })}
              </div>
            );
          })}
      </div>
      <div className={mainNavCollapsed ? styles.collapsedSectionBottom : styles.sectionBottom}>
        <div className={mainNavCollapsed ? styles.sectionTitle : styles.supportSectionTitle}>
          <span>Support</span>
        </div>
        {supportRoutes.map((sr) => {
          return (
            <RouteRow
              key={sr.key}
              route={sr}
              activeRoute={activeRoute}
              mainNavCollapsed={mainNavCollapsed}
              navigate={navigate}
              openMainNav={openMainNav}
              getInboxNotifications={getInboxNotifications}
            />
          );
        })}
      </div>
      <PreSalesSupportModal modalVisible={preSalesModalVisible} setModalVisible={setPreSalesModalVisible} />
    </div>
  );
};

MainNav.propTypes = {
  openMainNav: PropTypes.func.isRequired,
  closeMainNav: PropTypes.func.isRequired,
  mainNavCollapsed: PropTypes.bool,
  clearLatestMessage: PropTypes.func,
  getInboxNotifications: PropTypes.func,
  history: Router.historyTypes(),
  currentRoute: PropTypes.shape({
    key: PropTypes.string,
  }),
  litmosLoginLink: PropTypes.string,
  setLitmosLoginLink: PropTypes.func,
  inboxNotificationsCount: PropTypes.number,
  containerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
};

export default withRouter(
  connect(
    getRdxSelectionMapper({
      permissions: "getUserPermissions",
      litmosLoginLink: "getLitmosLoginLink",
      inboxNotificationsCount: "selectInboxNotificationsCount",
    }),
    getRdxActionMapper(["clearLatestMessage", "setLitmosLoginLink", "getInboxNotifications"]),
  )(MainNav),
);
