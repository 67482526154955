const commonTabs = [
  {
    key: "consultants",
    label: "Sellers",
  },
  {
    key: "ambassadors",
    label: "Ambassadors",
  },
  {
    key: "leadership",
    label: "Leadership",
  },
  {
    key: "leads",
    label: "Invites",
  },
];

const adminTabs = [];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
