import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* setNoticeViewedByUser(action) {
  const body = {
    notice_id: action.payload.key,
    user_id: action.payload.id,
  };
  const { success, data, error } = yield* makeRequest.post("/notices_user", body);
  if (success && data) {
    yield put(actions.updateUnviewedNotices(data.body.notices));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_SET_NOTICE_VIEWED_BY_USER,
      // payload: {},
    });
  }

  return null;
}

export default setNoticeViewedByUser;
