import { put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import _ from "lodash";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/workflows/selectors";
import messages from "../../messages";

function* removePhaseMilestone(action) {
  const { phaseId, milestoneId } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/phases/${phaseId}/milestones/${milestoneId}`, {});
  if (success && data) {
    const workflow = yield select(selectors.getWorkflowDetails);
    const newWorkflow = _.clone(workflow);

    const newPhases = newWorkflow.phases.map((p) =>
      p.id === phaseId && p.milestones ? { ...p, milestones: p.milestones.filter((m) => m.id !== milestoneId) } : p,
    );

    yield put(actions.setWorkflowDetails({ ...newWorkflow, phases: newPhases }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_WORKFLOW_PHASE,
    });
  }
  return null;
}

export default removePhaseMilestone;
