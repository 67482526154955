import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";

import { UserOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";

import ModalContainer from "components/Modals/ModalContainer";
import styles from "../SearchFilterCSV.module.less";

const SaveFilterSetModal = (props) => {
  const { createFilterSet, query_url, pathname, filterSetModalVisible, filterSetModalClose } = props;

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const { name } = await form.validateFields();

    // Remove sort and search before creation?
    createFilterSet({
      name,
      query_url,
      view: pathname,
    });
    filterSetModalClose();
    form.resetFields();
  };

  return (
    <ModalContainer
      visible={filterSetModalVisible}
      title="Save Filter Set"
      headerIcon={<UserOutlined className={styles.blue} />}
      handleSubmit={handleSubmit}
      handleCancel={filterSetModalClose}
      zIndex={2006}
    >
      <Form form={form}>
        <Form.Item name="name" rules={[{ required: true, message: "Please name this filter set." }]}>
          <Input />
        </Form.Item>
      </Form>
    </ModalContainer>
  );
};

SaveFilterSetModal.propTypes = {
  createFilterSet: PropTypes.func,
  query_url: PropTypes.string,
  pathname: PropTypes.string,
  filterSetModalVisible: PropTypes.bool,
  filterSetModalClose: PropTypes.func,
};

export default connect(getRdxSelectionMapper({}), getRdxActionMapper(["createFilterSet"]))(SaveFilterSetModal);
