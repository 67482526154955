import React, { useState } from "react";

import { Box, Grid } from "@mui/material";
import { DateRange, DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";
import { RightCaretFilled, LeftCaretFilled } from "components/Icons";
import { CalendarPlainIcon } from "components/Icons/Calendars/CalendarPlainIcon";

import dayjs, { Dayjs } from "dayjs";
import { cvar } from "styles";
import { getMonths } from "lib/helpers/getMonths";
import ActionBarComponent from "../DateTimePicker/ActionBar";
import { DateRangeInputsWrapper, DateRangeInput, customStyles, MonthTab } from "./styles";

type DateRangeSelectorProps = {
  defaultRange?: DateRange<Dayjs>;
  renderInput?: React.ReactNode;
  alowSelectStartYearMonth?: boolean;
  numberOfCalendars?: 1 | 2 | 3;
  handleChange?: (newValue: DateRange<Dayjs>) => void | null;
  handleAccept?: (newValue: DateRange<Dayjs>) => void | null;
  popperProps?: Record<string, unknown>;
  disableFuture?: boolean;
  isClearable?: boolean;
  parentControlledValue?: DateRange<Dayjs> | null;
  setParentControlledValue?: ((val: DateRange<Dayjs>) => void) | null;
};

const DateRangeSelector = ({
  defaultRange = [null, null],
  renderInput,
  alowSelectStartYearMonth = false,
  numberOfCalendars = 1,
  handleChange = () => null,
  handleAccept = () => null,
  popperProps = {},
  disableFuture = false,
  isClearable = false,
  parentControlledValue = null,
  setParentControlledValue = null,
}: DateRangeSelectorProps) => {
  const [value, setValue] = useState<DateRange<Dayjs>>(defaultRange);
  const [defaultMonth, setDefaultMonth] = useState(dayjs());
  const [showMonth, setShowMonth] = useState(false);
  const actionBarActions: PickersActionBarProps["actions"] = isClearable
    ? ["cancel", "accept", "clear"]
    : ["cancel", "accept"];
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        value={parentControlledValue || value}
        {...(alowSelectStartYearMonth ? { defaultCalendarMonth: defaultMonth } : {})}
        closeOnSelect={false}
        disableFuture={disableFuture}
        onChange={(newValue) => {
          if (setParentControlledValue) {
            setParentControlledValue(newValue);
          } else {
            setValue(newValue);
          }
          handleChange(newValue);
        }}
        onAccept={handleAccept}
        toolbarFormat="ddd, MMM DD"
        toolbarTitle={<span />}
        showToolbar
        PopperProps={{
          sx: (theme) => customStyles(theme),
          ...popperProps,
        }}
        DialogProps={{
          sx: (theme) => customStyles(theme),
        }}
        calendars={numberOfCalendars}
        componentsProps={{
          actionBar: { actions: actionBarActions },
        }}
        components={{
          ActionBar: ActionBarComponent,
          RightArrowIcon: RightCaretFilled,
          LeftArrowIcon: LeftCaretFilled,
          SwitchViewButton: () => null,
          PaperContent: ({ children }) => {
            const SelectMonth = (month: number) => {
              setDefaultMonth(dayjs().month(month));
              setShowMonth(false);
            };

            const clickHandle = (event: React.MouseEvent<HTMLElement>) => {
              if (
                (event?.target as HTMLInputElement)?.className?.includes("MuiTypography-root") &&
                (event?.target as HTMLInputElement)?.nodeName.toLowerCase() === "span" &&
                alowSelectStartYearMonth
              )
                setShowMonth(true);
            };
            if (showMonth && alowSelectStartYearMonth && !value[0])
              return (
                <Box style={{ width: "260px", height: "300px" }}>
                  <Grid height={1} container p={1}>
                    {getMonths("MMM")
                      .map((el) => el.label)
                      .map((month, indx) => (
                        <Grid height={1 / 4} textAlign="center" alignItems="center" item xs={4}>
                          <MonthTab
                            onClick={() => {
                              SelectMonth(indx);
                            }}
                            isCurrent={dayjs().format("MMM").toString() === month}
                          >
                            {month}
                          </MonthTab>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              );

            return <Box onClick={clickHandle}>{children}</Box>;
          },
        }}
        renderInput={(startProps, endProps) => {
          const { inputProps: startInputProps } = startProps;
          const { inputProps: endInputProps } = endProps;

          return (
            <Box
              style={{ width: "100%" }}
              onClick={(event) => {
                if (startInputProps?.onClick)
                  startInputProps?.onClick(event as unknown as React.MouseEvent<HTMLInputElement, MouseEvent>);
              }}
            >
              {renderInput || (
                <DateRangeInputsWrapper>
                  <DateRangeInput
                    inputProps={{
                      ...startInputProps,
                      value: defaultRange[0] === null ? "Start Date" : dayjs(defaultRange[0]).format("MM-DD-YYYY"),
                    }}
                    type="button"
                    disableUnderline
                  />
                  <Box sx={{ color: cvar("dark-blue"), fontWeight: 600, paddingLeft: "13px" }}>—</Box>
                  <DateRangeInput
                    inputProps={{
                      ...endInputProps,
                      value: defaultRange[1] === null ? "End Date" : dayjs(defaultRange[1]).format("MM-DD-YYYY"),
                    }}
                    type="button"
                    disableUnderline
                  />
                  <CalendarPlainIcon fill={cvar("dark-blue")} />
                </DateRangeInputsWrapper>
              )}
            </Box>
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default DateRangeSelector;
