import PropTypes from "prop-types";

export const agentSchema = PropTypes.shape({
  id: PropTypes.number,
  fullName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
});

export const agentDefaults = {
  id: 0,
  fullName: "",
  email: "",
  phone: "",
};
