import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/careerListings/types";
import getCareerListings from "./getCareerListings";
import updateCareerListing from "./updateCareerListing";
import deleteCareerListing from "./deleteCareerListing";
import createCareerListing from "./createCareerListing";
// IMPORT_PT (for script, do not remove)

function* watchAdminDashboardSagas() {
  yield trackRequests(takeEvery, types.GET_CAREER_LISTINGS_REQUEST, getCareerListings);
  yield trackRequests(takeEvery, types.UPDATE_CAREER_LISTING_REQUEST, updateCareerListing);
  yield trackRequests(takeEvery, types.DELETE_CAREER_LISTING_REQUEST, deleteCareerListing);
  yield trackRequests(takeEvery, types.CREATE_CAREER_LISTING_REQUEST, createCareerListing);
  // INSERTION_PT (for script, do not remove)
}

export default watchAdminDashboardSagas;
