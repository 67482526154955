import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/users/messages";

function* deletePermission(action) {
  const { success, data, error } = yield* makeRequest.delete(`/permissions/${action.payload.id}`, {});

  if (success && data) {
    yield all([
      put(
        actions.setRolesList({
          permissions: data.body.permissions,
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.DELETE_PERMISSION_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_PERMISSION,
    });
  }

  return null;
}

export default deletePermission;
