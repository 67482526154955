import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/settings/selectors";
import messages from "rdx/modules/settings/messages";
import { keysToCamelCase } from "lib/helpers";

function* createAdminServiceArea(action) {
  const { success, data, error } = yield* makeRequest.post("/service_areas", action.payload);

  if (success && data) {
    const serviceAreas = yield select(selectors.getAdminServiceAreas);
    const newArea = keysToCamelCase(data.body);
    yield all([
      put(actions.setAdminServiceAreas([...serviceAreas, newArea])),
      put(
        actions.newSuccessEvent({
          message: messages.SERVICE_AREA_CREATE_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_SERIVCE_AREA,
    });
  }

  return null;
}

export default createAdminServiceArea;
