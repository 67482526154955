import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/projects/messages";
import { camelizeKeys } from "lib/helpers";

function* createSiteSurveySchedule(action) {
  const { id, firstChoiceStart, secondChoiceStart, siteSurveyorFirstChoice, siteSurveyorSecondChoice } = action.payload;

  const params = {
    provider_lead_id: id,
    first_choice_start: firstChoiceStart,
    first_choice_window: 2,
    second_choice_start: secondChoiceStart,
    second_choice_window: 2,
    first_choice_surveyor_id: siteSurveyorFirstChoice,
    second_choice_surveyor_id: siteSurveyorSecondChoice,
  };

  const { success, data, error } = yield* makeRequest.post(`/provider_leads/${id}/site_survey_schedules`, params);

  if (success && data) {
    yield all([
      put(actions.setSiteSurveySchedule(camelizeKeys(data.body.schedule))),
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_SITE_SURVEY_SCHEDULE_SUCCESS,
        }),
      ),
    ]);
  } else {
    const errorMessage = messages.ERROR_UPDATING_SITE_SURVEY_SCHEDULE;
    return getErrorActions({
      error,
      message: errorMessage,
    });
  }

  return null;
}

export default createSiteSurveySchedule;
