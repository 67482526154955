import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const SiteSurveyScheduledIcon = ({ height = 19, width = 19, fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.64 4.39969H6.26402C5.76527 4.39969 5.42089 3.895 5.60496 3.43188L5.74152 3.09344L6.08589 2.22656C6.19277 1.95938 6.45402 1.78125 6.74496 1.78125H10.159C10.45 1.78125 10.7112 1.95938 10.8181 2.22656L11.1625 3.09344L11.299 3.43188C11.4831 3.895 11.1387 4.39969 10.64 4.39969Z"
        fill={fill}
      />
      <path
        d="M12.9429 3.09326H12.1223L12.1241 3.09801C12.3212 3.59439 12.2601 4.14954 11.9626 4.58832C11.6645 5.0277 11.17 5.29014 10.6391 5.29014H6.2632C5.73298 5.29014 5.2378 5.0277 4.93973 4.58832C4.64227 4.14954 4.58111 3.59439 4.77645 3.10276L4.78002 3.09326H3.95352C2.87289 3.09326 1.99414 3.97201 1.99414 5.05264V15.2592C1.99414 16.3398 2.87289 17.2186 3.95352 17.2186H11.9792C11.9222 17.1687 11.8646 17.12 11.8106 17.066C10.4218 15.6772 10.4218 13.4168 11.8106 12.028C12.4839 11.3553 13.3787 10.9848 14.3299 10.9848C14.524 10.9848 14.714 11.0044 14.9023 11.0341V5.05264C14.9023 3.97201 14.0235 3.09326 12.9429 3.09326ZM5.6558 12.9673H5.11489C4.8198 12.9673 4.58052 12.728 4.58052 12.4329C4.58052 12.1379 4.8198 11.8986 5.11489 11.8986H5.65639C5.95148 11.8986 6.19077 12.1379 6.19077 12.4329C6.19077 12.728 5.95089 12.9673 5.6558 12.9673ZM5.6558 9.72545H5.11489C4.8198 9.72545 4.58052 9.48617 4.58052 9.19107C4.58052 8.89598 4.8198 8.6567 5.11489 8.6567H5.65639C5.95148 8.6567 6.19077 8.89598 6.19077 9.19107C6.19077 9.48617 5.95089 9.72545 5.6558 9.72545ZM9.75683 12.9673H7.79745C7.50236 12.9673 7.26308 12.728 7.26308 12.4329C7.26308 12.1379 7.50236 11.8986 7.79745 11.8986H9.75683C10.0519 11.8986 10.2912 12.1379 10.2912 12.4329C10.2912 12.728 10.0519 12.9673 9.75683 12.9673ZM11.788 9.72545H7.79745C7.50236 9.72545 7.26308 9.48617 7.26308 9.19107C7.26308 8.89598 7.50236 8.6567 7.79745 8.6567H11.788C12.0831 8.6567 12.3224 8.89598 12.3224 9.19107C12.3224 9.48617 12.0837 9.72545 11.788 9.72545Z"
        fill={fill}
      />
      <path
        d="M16.2192 12.6574C15.176 11.6142 13.4838 11.6142 12.4406 12.6574C11.3974 13.7006 11.3974 15.3928 12.4406 16.436C13.4838 17.4793 15.176 17.4793 16.2192 16.436C17.2631 15.3928 17.2631 13.7006 16.2192 12.6574ZM15.518 14.5515L14.2937 15.5377C14.1981 15.6149 14.08 15.6559 13.9588 15.6559C13.9386 15.6559 13.9185 15.6547 13.8977 15.6523C13.7558 15.6363 13.6257 15.5638 13.5379 15.451L13.0569 14.8371C12.8746 14.6049 12.9156 14.2689 13.1478 14.0866C13.3805 13.9049 13.716 13.9453 13.8983 14.1774L14.0449 14.365L14.8477 13.7184C15.0775 13.5332 15.4141 13.57 15.5988 13.7992C15.784 14.0302 15.7478 14.3668 15.518 14.5515Z"
        fill={fill}
      />
    </svg>
  );
};

export default SiteSurveyScheduledIcon;
