import React from "react";
import { WarningIcon } from "components/Icons";

import { cvar } from "styles";
import * as S from "./styles";

const bannerText = {
  nonServiceableHeader: "Zip Code Requirement",
  nonServiceableBody:
    "This Lead's Zip Code is not in a Powur-serviceable area. Powur will communicate to the field when we expand to more areas!",
};

const CannotQualifyBanner = () => {
  return (
    <S.RequirementBanner>
      <S.RequirementIcon>
        <WarningIcon width={18} height={18} startFill={cvar("white")} stopFill={cvar("white")} />
      </S.RequirementIcon>
      <S.RequirementBody>
        <S.RequirementHeader>{bannerText.nonServiceableHeader}</S.RequirementHeader>
        <S.RequirementText>{bannerText.nonServiceableBody}</S.RequirementText>
      </S.RequirementBody>
    </S.RequirementBanner>
  );
};

export default CannotQualifyBanner;
