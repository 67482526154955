import React, { CSSProperties, ReactNode, useState } from "react";
import { TextFieldProps } from "@mui/material/TextField";

import Autocomplete from "@mui/material/Autocomplete";
import { useController } from "react-hook-form";
import { useSmartFormContext } from "components/SmartForm";
import { addressLookup } from "lib/helpers";
import HelperText from "../HelperText";
import * as S from "./styles";

type AddressSuggestionT = {
  street: string;
  city: string;
  state: string;
  zip: string;
  label?: string;
  unit?: string | null;
  country?: string;
  geocodeLat?: number;
  geocodeLong?: number;
  geocodePrecision?: string;
  googleMapsUrl?: string;
  lat?: number;
  long?: number;
  status?: string;
};

type SmartFormAddressAutocompleteT = {
  name: string;
  clearValues: () => void;
  getValues: (name: string) => ReactNode;
  handleAutocompleteSelect: (event, value) => void;
  helperText?: string | undefined;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  labelStyle?: CSSProperties;
  hasRedHighlight?: boolean;
} & TextFieldProps;

const SmartFormAddressAutocomplete = ({
  label,
  name,
  helperText,
  error,
  clearValues,
  getValues,
  handleAutocompleteSelect,
  disabled = false,
  labelStyle = {},
  required = false,
  hasRedHighlight = false,
  ...rest
}: SmartFormAddressAutocompleteT) => {
  const [suggestions, setSuggestions] = useState<AddressSuggestionT[] | []>([]);
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });

  const getAutocompleteValue = () => {
    const value = getValues(name);
    if (value) return value.toString();
    return "";
  };

  const handleSelectedOption = (event, value) => {
    handleAutocompleteSelect(event, value);
  };

  const fetchAutocompleteSuggestions = async (value: string) => {
    const addresses = await addressLookup(value);

    setSuggestions(
      addresses.map((address): AddressSuggestionT => {
        return {
          label: `${address.street_line},${address.unit ? `${address.unit}, ` : " "}${address.city}, ${
            address.state
          }, ${address.zipcode}`,
          street: address.street_line,
          unit: address.secondary,
          city: address.city,
          state: address.state,
          zip: address.zipcode,
        };
      }),
    );
  };

  return (
    <>
      <S.LabelGroupWrapper>
        <S.StyledFormLabel style={labelStyle}>{label}</S.StyledFormLabel>
        {required && <S.StyledRequiredLabel>*</S.StyledRequiredLabel>}
      </S.LabelGroupWrapper>
      <Autocomplete
        options={suggestions}
        disabled={disabled}
        filterOptions={(x) => x}
        freeSolo
        onChange={handleSelectedOption}
        onInputChange={(_, newInputValue, reason) => {
          if (reason === "clear") {
            clearValues();
          } else {
            fetchAutocompleteSuggestions(newInputValue);
          }
        }}
        inputValue={getAutocompleteValue()}
        getOptionLabel={getAutocompleteValue}
        isOptionEqualToValue={(option, value) => option.street === value.street}
        renderOption={(props, option) => (
          <S.StyledAutocompleteOption {...props} key={`${option.label}${option.unit}`}>
            {option.label}
          </S.StyledAutocompleteOption>
        )}
        renderInput={(params) => (
          <S.StyledTextField
            {...params}
            {...field}
            {...rest}
            disabled={disabled}
            size="small"
            redHighlight={hasRedHighlight}
          />
        )}
      />
      <HelperText sx={{ marginTop: "-10px" }} error={error}>
        {helperText}
      </HelperText>
    </>
  );
};

export type { AddressSuggestionT };
export default SmartFormAddressAutocomplete;
