import React from "react";
import PropTypes from "prop-types";

const ArchiveIcon = (props) => {
  const {
    // accentColor,
    mainColor,
    height,
    width,
    containerStyles,
  } = props;

  return (
    <div style={{ paddingTop: "3px", ...containerStyles }}>
      <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 0.849857L9.2 3.36986V3.47701L5.76965 5.87825L5.51601 5.70071C5.20612 5.48379 4.79366 5.48379 4.48378 5.70071L4.23025 5.87818L0.8 3.47701V3.36986L5 0.849857ZM0.8 4.45353V8.27935L3.53273 6.36644L0.8 4.45353ZM8.98758 9.1169H1.01227L1.21325 8.9666L4.94255 6.35609C4.97698 6.33199 5.02281 6.33199 5.05724 6.35609L8.78653 8.9666L8.98758 9.1169ZM9.2 8.2795V4.45353L6.46716 6.36652L9.2 8.2795ZM0 3.87733V3.2C0 3.02437 0.0921494 2.86161 0.242752 2.77125L4.74275 0.0712537C4.90109 -0.0237511 5.09891 -0.0237513 5.25725 0.0712534L9.75725 2.77125C9.90785 2.86161 10 3.02437 10 3.2V3.87733V9.4169V9.41701C10 9.69315 9.77614 9.91701 9.5 9.91701H0.5C0.223858 9.91701 0 9.69315 0 9.41701V9.4169V3.87733Z"
          fill={mainColor}
        />
      </svg>
    </div>
  );
};

ArchiveIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  mainColor: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  // accentColor: PropTypes.string,
};

ArchiveIcon.defaultProps = {
  // accentColor: "white",
  mainColor: "white",
  height: "14",
  width: "14",
};

export default ArchiveIcon;
