import { styled } from "@mui/material";
import { theme } from "styles/themes";

export const UserNameRow = styled("div")(() => ({
  color: theme.colors["darkest-blue"],
  fontWeight: 700,
  fontFamily: "Poppins",
  whiteSpace: "break-spaces",
}));

export const SellerDetails = styled("div")(() => ({
  color: theme.colors["nevada-gray"],
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "16px",
}));
