import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import SessionClient from "util/SessionClient";
import { useHistory } from "react-router-dom";
import messages from "rdx/modules/users/messages";
import { Spin } from "antd";
import { parse } from "query-string";
import MessageEvent from "models/MessageEvent";
import styles from "./EmailResetRedirect.module.less";

const EmailResetRedirect = (props) => {
  const { latestMessage, verifyEmailUpdate } = props;

  const history = useHistory();

  useEffect(() => {
    const { token } = parse(history.location.search);
    verifyEmailUpdate({ token });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const session = new SessionClient();
    if (latestMessage.payload?.event === messages.VERIFY_EMAIL_UPDATE_SUCCESS) {
      setTimeout(() => {
        if (session.validSession) {
          history.push("/profile");
        } else {
          history.push("/login?alr=%2Fprofile");
        }
      }, 2000);
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.waitContainer}>
      <Spin size="large" />
      <span className={styles.text}>Please wait while we update your information</span>
    </div>
  );
};

EmailResetRedirect.propTypes = {
  latestMessage: MessageEvent.types(),
  verifyEmailUpdate: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    latestMessage: "getLatestMessageEvt",
  }),
  getRdxActionMapper(["verifyEmailUpdate"]),
)(EmailResetRedirect);
