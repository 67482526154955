import SessionClient from "util/SessionClient";
import enterpriseAdminPro from "./enterpriseAdminPro";
import earningsView from "./earningsView";
import { PermissionT } from "../types";

export const viewFinancials = (permissions: PermissionT[]): boolean => {
  const session = new SessionClient();

  return session.isExternal ? enterpriseAdminPro(permissions) : earningsView(permissions);
};

export default viewFinancials;
