import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const EnvelopeCrmIcon = ({ width = "14", height = "12", fill = cvar("white") }: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.26914 5.58992C8.27113 5.58862 8.27319 5.58728 8.27582 5.58601L13.2754 2.60136V2.16441C13.2754 1.28637 12.5644 0.576172 11.6872 0.576172H1.86363C0.987202 0.576172 0.275391 1.28716 0.275391 2.16441V2.60148L6.77483 6.4831L8.26755 5.59095L8.26914 5.58992ZM0.275801 4.51951V8.67769L3.37916 6.37273L0.275801 4.51951ZM4.90927 7.28523L6.35273 8.14846C6.48281 8.22584 6.62839 8.26453 6.77487 8.26453C6.92136 8.26453 7.06614 8.22584 7.19701 8.14846L8.63968 7.28684L13.0785 10.5845C12.811 11.0815 12.2918 11.4238 11.687 11.4238H1.86255C1.25854 11.4238 0.738477 11.0815 0.47017 10.5836L4.90927 7.28523ZM13.2743 8.67946V4.51951L10.1701 6.37276L13.2743 8.67946Z"
        fill={fill}
      />
    </svg>
  );
};

export default EnvelopeCrmIcon;
