import React from "react";
import { IconT } from "types/utils";

const HomeOwnerGradientIcon = ({
  width = 48,
  height = 48,
  startColor = "var(--picton-blue)",
  stopColor = "var(--blue-ribbon)",
}: IconT) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} rx="24" fill="url(#paint0_linear_67_17338)" />
      <path
        d="M34.352 22.5136L25.146 13.8537L24.5289 13.273C24.3883 13.1415 24.1982 13.0677 24 13.0677C23.8018 13.0677 23.6117 13.1415 23.4711 13.273L13.648 22.5136C13.5039 22.6487 13.39 22.8095 13.3131 22.9867C13.2362 23.1639 13.1977 23.3537 13.2 23.5451C13.2096 24.3344 13.9077 24.9645 14.7463 24.9645H15.7589V32.2677H32.2411V24.9645H33.2751C33.6825 24.9645 34.0661 24.8142 34.3544 24.5429C34.4964 24.4098 34.6088 24.2514 34.6853 24.077C34.7618 23.9027 34.8007 23.7158 34.7999 23.5272C34.7999 23.146 34.6403 22.7849 34.352 22.5136ZM25.3342 30.6533H22.6658V26.0789H25.3342V30.6533ZM30.5257 23.35V30.6533H26.859V25.5408C26.859 25.0452 26.4325 24.6438 25.906 24.6438H22.094C21.5674 24.6438 21.141 25.0452 21.141 25.5408V30.6533H17.4743V23.35H15.1871L24.0024 15.0601L24.5527 15.5781L32.8153 23.35H30.5257Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_67_17338"
          x1="8.42105"
          y1="-20"
          x2="50.7989"
          y2="-15.767"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HomeOwnerGradientIcon;
