import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";

import messages from "rdx/modules/projects/messages";

function* getRoofingProposalDetails(action) {
  const { projectId, proposalId, isAdmin = false } = action.payload;
  const urlPrefix = isAdmin ? "/projects" : "/provider_leads";

  const { success, data, error } = yield* makeRequest.get(
    `${urlPrefix}/${projectId}/roofing_proposals/${proposalId}`,
    {},
  );

  if (success && data) {
    yield put(actions.setRoofingProposalDetails(keysToCamelCase(data.body)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROPOSAL_DETAILS,
    });
  }
  return null;
}

export default getRoofingProposalDetails;
