import _ from "lodash";

/**
 * Alias for camelizeKeys
 *
 * Due to several issues in the initial implementation of this method causing incorrect conversion to camel case,
 * the `keysToCamelCase` method has been repurposed. Given its wide usage, rather than removing it entirely,
 * we've decided to retain the method and modify its implementation to serve as an alias for `camelizeKeys`.
@returns {Object} - A new object with all keys converted to camel case
@param {Object} obj - The object to convert
*/
export function keysToCamelCase(obj) {
  return camelizeKeys(obj);
}

export function camelizeKeys(obj) {
  // src: https://stackoverflow.com/a/50620653
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  }

  if (obj !== null && obj?.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [_.camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {},
    );
  }

  return obj;
}

export function objArrayKeysToCamelCase(arr) {
  return arr.map((obj) => {
    const newKey = _.camelCase(Object.keys(obj)[0]);
    const newValue = Object.values(obj)[0];
    return { [newKey]: newValue };
  });
}

export function destructureObjArray(arr) {
  const out = {};
  objArrayKeysToCamelCase(arr).forEach((obj) => {
    const newKey = Object.keys(obj)[0];
    const newValue = Object.values(obj)[0];
    out[newKey] = newValue;
  });
  return out;
}

export function keysToSnakeCase(obj) {
  const output = Array.isArray(obj) ? [] : {};
  const keys = Object.keys(obj);
  const values = Object.values(obj);
  for (let i = 0; i < keys.length; i++) {
    if (typeof values[i] === "object" && values[i] !== null) {
      output[_.snakeCase(keys[i])] = keysToSnakeCase(values[i]);
    } else {
      output[_.snakeCase(keys[i])] = values[i];
    }
  }
  return output;
}
