import component from "containers/login";
// import l from "@loadable/component";

// const component = l(() => import("containers/login"));

export default {
  key: "login",
  title: "Login",
  path: "/login/:tab?/:token?",
  linkTarget: "/login",
  component,
};
