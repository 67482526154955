import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/financials/messages";
import formatPayoutsList from "./util/formatPayoutsList";

function* getPayoutsListRequest(action) {
  const { success, data, error } = yield* makeRequest.get("/payouts", action.payload);

  if (success && data) {
    if (data.body.filters || data.body.sorts) {
      yield put(actions.setPayoutFilters(data.body));
    } else {
      yield put(actions.setPayoutsList(formatPayoutsList(data)));
    }
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_PAYOUTS_LIST,
    });
  }

  return null;
}

export default getPayoutsListRequest;
