import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToSnakeCase } from "lib/helpers";
import SessionClient from "util/SessionClient";
import messages from "../messages";

function* upgradeAmbassador(action) {
  const { userId } = action.payload;

  const params = keysToSnakeCase(action.payload);

  const { success, data, error } = yield* makeRequest.patch(`/users/${userId}/roles/upgrade_ambassador`, params);
  if (success && data) {
    const session = new SessionClient();
    session.setSession(data);
    session.user.initStore(data);

    yield put(
      actions.newSuccessEvent({
        message: messages.UPGRADE_AMBASSADOR_SUCCESS,
      }),
    );
    yield put(actions.clearRbac());
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPGRADING_AMBASSADOR,
    });
  }

  return null;
}

export default upgradeAmbassador;
