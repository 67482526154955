import React from "react";
import { useTranslation } from "react-i18next";
import { cvar } from "styles/colorVariables";
import Button from "@mui/material/Button";

const CareersLink = () => {
  const { t } = useTranslation("careers");

  return (
    <div css={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "80px" }}>
      <Button
        sx={{
          display: "flex",
          textTransform: "uppercase",
          backgroundColor: "var(--primary-lite)",
          height: "40px",
          minWidth: "144px",
          borderRadius: "96px",
          border: "none",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "28px",
          letterSpacing: "0em",
          color: "white",
          "&:hover": {
            backgroundColor: cvar("primary-lite-highlighted"),
          },
        }}
        onClick={() =>
          window.open(
            "https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=643fec1c-5e5e-42f0-8edb-03a82d42a589&ccId=19000101_000001&lang=en_US",
            "_blank",
          )
        }
      >
        {t<string>("view_openings")}
      </Button>
    </div>
  );
};

export default CareersLink;
