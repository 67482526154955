import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
// import _ from "lodash";
import { keysToCamelCase } from "lib/helpers";
import messages from "../../messages";

function* removePermissionFromRole(action) {
  const { roleId, permissionId } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/roles/${roleId}/permissions/${permissionId}`, {});
  if (success && data) {
    const roles = data.body.roles.map((role) => keysToCamelCase(role));
    const permissions = data.body.permissions.map((permission) => keysToCamelCase(permission));
    yield put(actions.setRolesList({ roles, permissions }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_REMOVING_PERMISSION_FROM_ROLE,
    });
  }
  return null;
}

export default removePermissionFromRole;
