import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

import { theme } from "styles/themes";
import { mq } from "styles";

type MetricRowPropsT = {
  evenRow: boolean;
};

type MetricDataPropsT = {
  negativeStyle: boolean;
};

export const PowurPairCard = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "10px 16px 24px",
  backgroundColor: theme.colors.white,
  borderRadius: "4px",
  boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.10)",
  width: "100%",
  [mq.phoneOnly]: {
    paddingBottom: "10px",
  },
}));

export const PowurPairCardHeader = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const TooltipIconContainer = styled("div")(() => ({
  fontSize: "18px",
  lineHeight: "18px",
  color: theme.colors["picton-blue"],
  transform: "rotate(180deg)",
  cursor: "pointer",
}));

export const MatchRelationship = styled(Grid)(() => ({
  fontWeight: 700,
  textTransform: "uppercase",
  fontSize: "12px",
  color: theme.colors["nepal-blue"],
  fontFamily: "Poppins",
  height: "20px",
  position: "relative",
  bottom: "8px",
  textAlign: "center",
}));

export const MatchImage = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  [mq.tabletUp]: {
    margin: "10px",
    marginBottom: "0px",
  },
}));

export const MatchDetailsContainer = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  [mq.phoneOnly]: {
    paddingLeft: "10px",
  },
  [mq.tabletUp]: {
    alignItems: "center",
  },
}));

export const MatchName = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "16px",
  fontFamily: "Poppins",
  color: theme.colors["darker-blue"],
  height: "30px",
  [mq.tabletUp]: {
    marginTop: "5px",
    textAlign: "center",
  },
}));

export const MatchLocation = styled("div")(() => ({
  color: theme.colors["table-grey"],
  [mq.tabletUp]: {
    margin: "5px 0px",
  },
}));

export const MentorStarsContainer = styled("div")(() => ({
  [mq.tabletUp]: {
    margin: "5px",
  },
}));

export const LanguageLabel = styled("div")(() => ({
  fontWeight: 700,
  textTransform: "uppercase",
  fontFamily: "Poppins",
  color: theme.colors["nepal-blue"],
  fontSize: "10px",
  [mq.tabletUp]: {
    marginTop: "10px",
  },
}));

export const MatchLang = styled("div")(() => ({
  fontWeight: 400,
  marginBottom: "25",
  color: theme.colors["darker-blue"],
}));

export const TooltipTitle = styled("div")(() => ({
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 700,
  color: theme.colors["darker-blue"],
  padding: "10px",
}));

export const ButtonContainer = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  margin: "24px 0px",
  [mq.phoneOnly]: {
    margin: "16px 0px 12px",
  },
}));

export const IconContainer = styled("div")(() => ({
  paddingTop: "8px",
  marginRight: "6px",
}));

export const MetricTooltipRow = styled(Grid)(({ evenRow }: MetricRowPropsT) => ({
  display: "flex",
  padding: "2px 10px",
  ...(evenRow && { backgroundColor: theme.colors["lightest-grey"] }),
}));

export const MetricTooltipData = styled(Grid)(({ negativeStyle }: MetricDataPropsT) => ({
  fontSize: "14px",
  fontWeight: 600,
  color: theme.colors["darker-blue"],
  ...(negativeStyle && { color: theme.colors.scarlet }),
}));

export const MetricTooltipDetail = styled(Grid)(() => ({
  fontSize: "14px",
  fontWeight: 400,
  color: theme.colors["darker-blue"],
}));
