import { UseNavigation } from "hooks/useNavigation";
import dayjs from "dayjs";

export const applyValuesToFilter = (
  filterValue: string[],
  setValue: React.Dispatch<React.SetStateAction<string[] | null[] | null>>,
  override: ReturnType<UseNavigation>["override"],
) => {
  setValue(filterValue);
  const [from, to] = filterValue;
  if (override)
    override({
      from: dayjs(from).format(),
      to: dayjs(to).format(),
    });
};
