import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../../messages";

function* createWorkflow(action) {
  const { title } = action.payload;
  const { success, data, error } = yield* makeRequest.post("/workflows", {
    title,
  });
  if (success && data) {
    const workflows = data.body.workflows.map((g) => keysToCamelCase(g));
    const workflow = workflows.find((w) => w.title === title);
    yield all([put(actions.setWorkflows(workflows)), put(actions.setWorkflowDetails(workflow))]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_WORKFLOW,
    });
  }
  return null;
}

export default createWorkflow;
