import React from "react";
import { cvar } from "styles";

type ProposalGeneratedIconPropsT = {
  height?: number;
  width?: number;
  fill?: string;
  startColor?: string;
  stopColor?: string;
  shadowColor?: string;
};

const ProposalGeneratedIcon = ({
  height = 21,
  width = 16,
  fill = cvar("white"),
  startColor = cvar("picton-blue"),
  stopColor = cvar("blue-ribbon"),
  shadowColor = "#BEE1FF",
}: ProposalGeneratedIconPropsT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.07617" y="4.20605" width="11.4014" height="16.1369" rx="1" fill={fill} fillOpacity="0.59" />
      <rect x="2.35352" y="2.48535" width="11.4014" height="16.1369" rx="1" fill={shadowColor} />
      <rect x="0.521484" y="0.874023" width="11.4014" height="16.1369" rx="1" fill={fill} />
      <path
        d="M8.67969 3.72852H10.2079"
        stroke="url(#paint0_linear_227_12004)"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M2.23633 3.72852H3.76458"
        stroke="url(#paint1_linear_227_12004)"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <circle cx="6.12214" cy="3.72858" r="1.32526" stroke="url(#paint2_linear_227_12004)" strokeWidth="0.75" />
      <path
        d="M2.93945 6.46289H9.42084"
        stroke="url(#paint3_linear_227_12004)"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M2.93945 7.85938H9.42084"
        stroke="url(#paint4_linear_227_12004)"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M2.94141 9.25586H9.42279"
        stroke="url(#paint5_linear_227_12004)"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M2.94141 10.6533H9.42279"
        stroke="url(#paint6_linear_227_12004)"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M2.94141 12.0498H9.42279"
        stroke="url(#paint7_linear_227_12004)"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M2.94141 13.4453H9.42279"
        stroke="url(#paint8_linear_227_12004)"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M2.94141 14.8428H9.42279"
        stroke="url(#paint9_linear_227_12004)"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_227_12004"
          x1="7.42295"
          y1="3.3008"
          x2="9.19116"
          y2="2.82033"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_227_12004"
          x1="2.50444"
          y1="3.31185"
          x2="4.23702"
          y2="1.3101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_227_12004"
          x1="4.75184"
          y1="2.72526"
          x2="8.86808"
          y2="2.12419"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_227_12004"
          x1="4.07654"
          y1="6.04623"
          x2="13.1658"
          y2="8.1571"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_227_12004"
          x1="4.07654"
          y1="7.44271"
          x2="8.14895"
          y2="10.0792"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint5_linear_227_12004"
          x1="4.07849"
          y1="8.83919"
          x2="8.1509"
          y2="11.4757"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint6_linear_227_12004"
          x1="4.07849"
          y1="10.2367"
          x2="8.1509"
          y2="12.8732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint7_linear_227_12004"
          x1="4.07849"
          y1="11.6331"
          x2="8.1509"
          y2="14.2697"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint8_linear_227_12004"
          x1="4.07849"
          y1="13.0286"
          x2="8.1509"
          y2="15.6652"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint9_linear_227_12004"
          x1="4.07849"
          y1="14.4261"
          x2="8.1509"
          y2="17.0626"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProposalGeneratedIcon;
