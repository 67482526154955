import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/users/messages";
import { keysToSnakeCase, keysToCamelCase } from "lib/helpers";

function* changeCertificationDate(action) {
  const { serviceSlug } = action.payload;
  const { success, data, error } = yield* makeRequest.patch(
    `/services/${serviceSlug}/enrollment/revision`,
    keysToSnakeCase(action.payload),
  );
  if (success && data) {
    const enrollment = keysToCamelCase(data.body.enrollment);
    yield put(actions.setDetailInUserDetails({ enrollment }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CHANGING_USER_CERTIFICATION_DATE,
    });
  }
  return null;
}

export default changeCertificationDate;
