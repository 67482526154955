import { put, all, select } from "redux-saga/effects";
import selectors from "rdx/modules/users/selectors";
import makeRequest from "rdx/utils/makeRequest";
import { keysToCamelCase } from "lib/helpers";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* updateTeam(action) {
  const teamId = action.payload.id;
  const params = {
    label: action.payload.label,
    color: action.payload.color,
    phone: action.payload.phone,
    email: action.payload.email,
    lead_user_id: action.payload.leadUserId,
    short_name: action.payload.shortName,
    source_uid: action.payload.sourceUid,
    assignable: action.payload.assignable,
    customer_care: action.payload.customerCare,
    new_users: action.payload.newUsers,
    users: action.payload.users.map((user) => user.id),
  };

  const { success, data, error } = yield* makeRequest.patch(`/teams/${teamId}`, { ...params });
  if (success && data) {
    const teams = yield select(selectors.getTeamsList);
    const newUsers = data.body.users ? data.body.users.map((user) => keysToCamelCase(user)) : [];
    const newTeam = { ...keysToCamelCase(data.body), users: newUsers };
    const out = teams.map((team) => (team.id === newTeam.id ? newTeam : team));

    yield all([
      put(actions.setTeamsList(out)),
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_TEAM_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({ error, message: messages.ERROR_UPDATING_TEAM });
  }
  return null;
}
export default updateTeam;
