export default {
  // success messages
  PROPOSAL_MARKED_AS_IN_PROGRESS: "Proposal moved to In-Progress",
  PROPOSAL_REJECTED: "Proposal marked as rejected",
  PROPOSAL_UPDATED: "Proposal updated",
  PROPOSAL_CREATED: "Proposal added",

  // error messages
  ERROR_GETTING_ADMIN_PROPOSALS_LIST: "There was an error retrieving proposals list",
  ERROR_GETTING_ADMIN_PROPOSAL_DETAILS: "There was an error retrieving proposal details",
  ERROR_MARKING_IN_PROGRESS: "There was an error marking proposal as in-progress",
  ERROR_SETTING_PROPOSAL: "There was an error creating proposal",
  ERROR_UPDATING_PROPOSAL: "There was an error updating proposal",
  ERROR_MARKING_REJECTED: "There was an error marking proposal as rejected",
};
