import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = false;

export default {
  activateAcctBtnClicked: createReducer(initialState, {
    [types.UPDATE_ACTIVATE_ACCT_BTN_CLICKED]() {
      return true;
    },
    [types.RESET_ACTIVATE_ACCT_BTN_CLICKED]() {
      return false;
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};
