import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const PowurLive = (props) => {
  const { fill, height, width, containerStyles, active, hovered } = props;

  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      {...containerStyles}
    >
      <svg width={width} height={height} viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.5971 1.25H11.0248C11.0562 1.31289 11.0562 1.40723 11.0562 1.50157V2.66509H17.8487V12.3192H2.84869V2.66509H3.1946V1.50157C3.1946 1.40723 3.1946 1.31289 3.22605 1.25H3.10026C2.18831 1.25 1.43359 2.00472 1.43359 2.91667V13.7343H19.2638V2.91667C19.2638 1.97327 18.5091 1.25 17.5971 1.25Z"
          fill={fill}
        />
        <path
          d="M20.1137 14.2031H0.616807C0.459574 14.2031 0.365234 14.2975 0.365234 14.4233C0.365234 15.9012 1.5602 17.0648 3.00674 17.0648H17.6923C18.4155 17.0648 19.0759 16.7817 19.5791 16.2786C20.0822 15.7755 20.3652 15.1151 20.3652 14.3918C20.3338 14.2975 20.2394 14.2031 20.1137 14.2031ZM10.3338 16.0585C10.1137 16.0585 9.89354 15.8698 9.89354 15.6182C9.89354 15.3981 10.0822 15.178 10.3338 15.178C10.5539 15.178 10.774 15.3666 10.774 15.6182C10.774 15.8698 10.5854 16.0585 10.3338 16.0585Z"
          fill={fill}
        />
        <path
          d="M15.5848 10.1153V11.8134H10.7734V10.1153C10.7734 9.23479 11.4653 8.54297 12.3458 8.54297H14.0124C14.8615 8.54297 15.5848 9.26624 15.5848 10.1153Z"
          fill={fill}
        />
        <path
          d="M14.6308 6.78511C14.6308 7.59888 13.9797 8.2499 13.166 8.2499C12.3522 8.2499 11.7012 7.59888 11.7012 6.78511C11.7012 5.97133 12.3522 5.32031 13.166 5.32031C13.9797 5.32031 14.6308 5.97133 14.6308 6.78511Z"
          fill={fill}
        />
        <path
          d="M9.42073 0.304688H4.86098C4.20061 0.304688 3.66602 0.839279 3.66602 1.49966V5.08456C3.66602 5.74494 4.20061 6.27953 4.86098 6.27953H7.72262L8.94903 7.78896C8.98048 7.85186 9.07482 7.8833 9.13771 7.8833C9.16916 7.8833 9.20061 7.8833 9.23205 7.8833C9.32639 7.85186 9.38929 7.75752 9.38929 7.66318V6.31098H9.42073C10.0811 6.31098 10.6157 5.77639 10.6157 5.11601V1.49966C10.5843 0.839279 10.0497 0.304688 9.42073 0.304688ZM9.13771 4.7701H5.08111V4.2984H9.13771V4.7701ZM9.13771 3.51223H5.08111V3.04054H9.13771V3.51223ZM9.13771 2.25437H5.08111V1.78267H9.13771V2.25437Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

PowurLive.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

PowurLive.defaultProps = {
  fill: "var(--royal-peacock-blue)",
  width: "21",
  height: "18",
};

export default PowurLive;
