import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* activateUserEnrollment(action) {
  const { userId: user_id, serviceSlug } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/services/${serviceSlug}/enrollment/activate`, {
    user_id,
  });
  if (success && data) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.ACTIVATE_USER_ENROLLMENT_SUCCESS,
        }),
      ),
      put(
        actions.setDetailInUserDetails({
          enrollment: keysToCamelCase(data.body.enrollment),
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_ACTIVATING_USER_ENROLLMENT,
    });
  }

  return null;
}

export default activateUserEnrollment;
