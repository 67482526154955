import { select } from "redux-saga/effects";
import { keysToCamelCase } from "lib/helpers";
import _ from "lodash";
import selectors from "../../../selectors";

function* updateDataGroupsFromRes(res, dataGroupId) {
  const groups = yield select(selectors.getDataGroups);
  const newGroups = _.clone(groups);
  const groupToUpdate = newGroups.find((g) => g.id === dataGroupId);
  groupToUpdate.metaFields = res.body.meta_fields.map((f) => keysToCamelCase(f));
  return newGroups;
}

export default updateDataGroupsFromRes;
