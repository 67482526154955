import React from "react";
import PropTypes from "prop-types";
import SessionClient from "util/SessionClient";
import styles from "./MainNav.module.less";

const GettingStarted = (props) => {
  const { height, width, active, hovered, fill } = props;
  const session = new SessionClient();
  const unpaid = session.backOfficeAccess === "needs_activation";

  const activeStyles = () => {
    if (active) {
      return styles.navIconContainerActive;
    }
    if (unpaid) {
      return styles.gettingStartedNavIconContainer;
    }
    return styles.navIconContainer;
  };

  const hoveredStyles = () => {
    if (unpaid && hovered && !active) {
      return styles.gettingStartedHovered;
    }
    if (hovered) {
      return styles.hovered;
    }
    return [];
  };

  return (
    <div className={[activeStyles(), hoveredStyles()].join(" ")}>
      <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.92871 7.06906V2.92773H7.07004L2.92871 7.06906Z" fill={fill} />
        <path d="M2.92856 12.9265L0 9.99789L2.92856 7.06934V12.9265Z" fill={fill} />
        <path d="M7.07031 2.92856L9.99887 0L12.9274 2.92856H7.07031Z" fill={fill} />
        <path d="M12.9274 17.0723L9.99887 20.0008L7.07031 17.0723H12.9274Z" fill={fill} />
        <path d="M7.07004 17.0714H2.92871V12.9277L7.07004 17.0714Z" fill={fill} />
        <path d="M17.0714 12.9277V17.0714H12.9277L17.0714 12.9277Z" fill={fill} />
        <path d="M17.0713 7.06934L19.9998 9.99789L17.0713 12.9265V7.06934Z" fill={fill} />
        <path d="M12.9277 2.92773H17.0714V7.06906L12.9277 2.92773Z" fill={fill} />
        <mask id="mask0_612_6215" maskUnits="userSpaceOnUse" x="5" y="4" width="10" height="14">
          <rect x="5" y="4.28711" width="9.99962" height="12.8567" fill="var(--light-silver)" />
        </mask>
        <g mask="url(#mask0_612_6215)">
          <path
            d="M9.82123 9.04602C8.90164 9.05049 8.15612 8.305 8.15612 7.38092C8.15612 6.46133 8.90164 5.71582 9.82123 5.71582C10.7408 5.71582 11.4863 6.46133 11.4863 7.38092C11.4863 8.30051 10.7408 9.04602 9.82123 9.04602Z"
            fill={fill}
          />
          <path
            d="M8.15192 9.41992H11.491C12.7499 9.41992 13.5713 10.527 13.5713 11.5806V16.6517C13.5713 17.6338 12.1874 17.6338 12.1874 16.6517V11.9646H11.9196V24.7142C11.9196 26.0713 10.0447 26.0266 10.0001 24.7142V17.3664H9.64293V24.7187C9.56704 26.1115 7.72339 25.9776 7.72339 24.7142V11.9646H7.50019V16.6518C7.50019 17.6338 6.07168 17.6338 6.07168 16.6518V11.5806C6.07168 10.5315 6.89752 9.41995 8.15195 9.41995L8.15192 9.41992Z"
            fill={fill}
          />
        </g>
      </svg>
    </div>
  );
};

GettingStarted.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

GettingStarted.defaultProps = {
  width: "22",
  height: "22",
  fill: "var(--royal-peacock-blue)",
};

export default GettingStarted;
