import React from "react";
import PropTypes from "prop-types";
import sharedClasses from "components/HomepageComponents/JoinModal/JoinModal.module.less";

const Header = ({ title, subtitle }) => {
  return (
    <div className={sharedClasses.headerContainer} style={subtitle ? { minHeight: "112px" } : {}}>
      {title && (
        <>
          <span className={sharedClasses.title}>{title}</span>
          {subtitle && <p className={sharedClasses.paragraph}>{subtitle}</p>}
        </>
      )}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Header;
