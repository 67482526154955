import styled from "@emotion/styled";
import DownCaretFilled from "components/Icons/Chevrons/DownCaretFilled";
import CloseIconPlain from "components/Icons/CloseIconPlain";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { cvar } from "styles";

export const LabelGroupWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
}));

export const IconContainer = styled("div")(() => ({
  position: "absolute",
  right: "10px",
  paddingLeft: "10px",
  borderLeft: `1px solid ${cvar("dark-blue")}`,
  pointerEvents: "none",
}));

export const StyledDownCaretFilled = styled(DownCaretFilled)(() => ({
  position: "relative",
  marginRight: "4px",
  marginLeft: "4px",
}));

export const StyledCloseIcon = styled(CloseIconPlain)(() => ({
  marginRight: "10px",
  marginLeft: "4px",
}));

export const StyledChip = styled(Chip)(() => ({
  border: `1px solid ${cvar("blue-ribbon")}`,
  background: cvar("white"),
  color: cvar("blue-ribbon"),
  fontWeight: 600,
  width: "calc(50% - 28px)",
  marginTop: "-2px !important",
  marginBottom: "-2px !important",
  "& .MuiSvgIcon-root": {
    fill: cvar("blue-ribbon"),
  },
}));

export const StyledFormLabel = styled("div")(() => ({
  fontSize: "10px",
  fontWeight: "600",
  lineHeight: "9px",
  marginBottom: "8px",
  color: cvar("nepal-blue"),
  textTransform: "uppercase",
}));

export const StyledListItem = styled("li")(() => ({
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "17px",
  color: cvar("darkest-blue"),
  padding: "10px",
  "& span": {
    padding: "3px",
  },
}));

export const StyledRequiredLabel = styled("p")(() => ({
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "9px",
  color: cvar("commission-clawback"),
  width: "10px",
  margin: 0,
  textAlign: "center",
}));

export const AdditionalSelectedLabel = styled("div")(() => ({
  display: "flex",
  color: cvar("blue-bayoux"),
  fontWeight: 600,
  marginLeft: "5px",
}));

export const AdditionalCount = styled("span")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: cvar("blue-ribbon"),
  height: "21px",
  minWidth: "21px",
  borderRadius: "5px",
  fontWeight: 600,
  padding: "0px 3px",
  color: cvar("white"),
  lineHeight: "17px",
  marginLeft: "8px",
}));

export const StyledTextField = styled(TextField)(() => ({
  marginBottom: "12px",
  backgroundColor: "white",
  borderRadius: "4px",
  paddingTop: "0px",

  "& .MuiAutocomplete-input": {
    color: cvar("dark-blue"),
    fontSize: "14px",
    fontWeight: "500",
    padding: "7px 14px 8px",
    lineHeight: "17px",
    height: "17px",
  },
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { borderColor: cvar("botticelli-blue") },
    "&.Mui-focused fieldset": {
      borderColor: cvar("botticelli-blue"),
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--botticelli-blue) !important",
  },
  "& .Mui-disabled": {
    background: cvar("cotton-boll"),
    borderRadius: "3px",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: cvar("queen-blue"),
  },
}));
