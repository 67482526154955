import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export default function formatDateTimeSinceNow(time) {
  const startTime = dayjs(time);
  const now = dayjs();
  const diff = dayjs.duration(now.diff(startTime));

  const pluralize = (e) => (parseInt(e, 10) === 1 ? "" : "s");
  const difference = () => {
    if (diff.asDays() > 9) {
      return startTime.format("LL");
    }
    if (diff.asDays() >= 1) {
      return `${diff.asDays().toFixed()} day${pluralize(diff.asDays())} ago`;
    }
    if (diff.asHours() >= 1) {
      return `${diff.asHours().toFixed()} hour${pluralize(diff.asHours())} ago`;
    }
    if (diff.asMinutes() >= 1) {
      return `${diff.asMinutes().toFixed()} min ago`;
    }
    return "now";
  };
  return difference();
}
