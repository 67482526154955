import styled from "@emotion/styled";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { cvar } from "styles";

export const LabelGroupWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const StyledHelperText = styled(FormHelperText)<{ error?: boolean }>(() => ({
  lineHeight: "10px",
  marginTop: "-10px",
  color: `${({ error }) => (error ? "red" : "black")}`,
}));

export const StyledFormLabel = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "9px",
  marginBottom: "8px",
  color: cvar("nepal-blue"),
  textTransform: "uppercase",
}));

export const StyledAutocompleteOption = styled("li")(() => ({
  color: cvar("dark-blue"),
  fontSize: "14px",
}));

export const StyledTextField = styled(TextField)(() => ({
  marginBottom: "12px",
  backgroundColor: "white",
  borderRadius: "4px",
  paddingTop: "0px",

  "& .MuiAutocomplete-input": {
    color: cvar("dark-blue"),
    fontSize: "14px",
    fontWeight: "500",
    padding: "7px 14px 8px",
    lineHeight: "17px",
    height: "17px",
  },
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { borderColor: cvar("botticelli-blue") },
    "&.Mui-focused fieldset": {
      borderColor: cvar("botticelli-blue"),
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--botticelli-blue) !important",
  },
}));

export const PrefixContainer = styled("div")(() => ({
  borderRight: `1px solid ${cvar("botticelli-blue")}`,
  paddingRight: "7px",
  paddingLeft: "3px",
  height: "32px",
  width: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
