import tabs from "containers/Admin/SolarProposals/tabs";
import { SolarProposalsIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import sidebar from "containers/Admin/SolarProposals/components/Sidebar";
import l from "@loadable/component";

const content = l(() => import("containers/Admin/SolarProposals"));

const { PROPOSAL_MANAGEMENT } = permissionTypes;

export default {
  key: "admin-solar-proposals",
  title: "Solar Proposals",
  path: "/admin-solar-proposals/:tab?/:projectId?",
  linkTarget: "/admin-solar-proposals",
  linkTitle: "Solar Proposals",
  basePath: "admin-solar-proposals",
  linkCategory: "Admin",
  permission: PROPOSAL_MANAGEMENT,
  icon: SolarProposalsIcon,
  tabs,
  content,
  sidebar,
};
