import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/complianceIssues/types";
import createComplianceIssue from "./createComplianceIssue";
import updateComplianceIssue from "./updateComplianceIssue";
import deleteComplianceIssue from "./deleteComplianceIssue";
import getComplianceIssues from "./getComplianceIssues";

// IMPORT_PT (for script, do not remove)

function* watchEarningsSagas() {
  yield trackRequests(takeEvery, types.CREATE_COMPLIANCE_ISSUE_REQUEST, createComplianceIssue);
  yield trackRequests(takeEvery, types.UPDATE_COMPLIANCE_ISSUE_REQUEST, updateComplianceIssue);
  yield trackRequests(takeEvery, types.DELETE_COMPLIANCE_ISSUE_REQUEST, deleteComplianceIssue);
  yield trackRequests(takeEvery, types.GET_COMPLIANCE_ISSUES_REQUEST, getComplianceIssues);
  // INSERTION_PT (for script, do not remove)
}

export default watchEarningsSagas;
