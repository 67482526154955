export default {
  GET_PROVIDER_LEAD_DETAILS_REQUEST: "[projects] GET Provider Lead Details Request",
  SET_PROJECT_DETAILS: "[projects] SET Project Details",
  SET_LEAD_PROJECT_DETAILS: "[projects] SET Project Lead Details",
  GET_PROVIDER_LEADS_LIST_REQUEST: "[projects] GET Provider Leads Request",
  SET_PROVIDER_LEADS_LIST: "[projects] SET Provider Leads",
  SET_PROVIDER_LEADS_LIST_FILTERS: "[projects] SET Provider Leads List Filters",
  GET_MENTORS_LIST_REQUEST: "[projects] GET Mentors List Request",
  SET_MENTORS_LIST: "[projects] SET Mentors",
  SET_MENTOR_FILTERS: "[projects] SET Mentor Filters",
  SET_POWUR_PAIR_LIST: "[projects] SET Powur Pair List",
  RESET_MENTORS_LIST: "[projects] Reset Mentors",
  RESET_POWUR_PAIR_LIST: "[projects] Reset Powur Pair List",
  SELECT_MENTOR_REQUEST: "[projects] Select Mentor Request",
  REMOVE_MENTOR_REQUEST: "[projects] Remove Mentor Request",
  REMOVE_AMBASSADOR_REQUEST: "[projects] Remove Ambassador Request",
  GET_PROJECT_ADDRESS_GEOCODE: "[projects] GET Project Address Geocode",
  SET_PROJECT_ADDRESS_GEOCODE: "[projects] SET Project Address Geocode",
  UPDATE_PROVIDER_LEAD: "[projects] Update Provider Lead",
  UPDATE_PROJECT_LEAD_INFORMATION_REQUEST: "[projects] Update Project Lead Information Request",
  GET_NOTES_REQUEST: "[projects] GET Notes Request",
  GET_ASSETS_REQUEST: "[projects] GET Assets Request",
  SET_NOTES: "[projects] SET Notes",
  SET_ASSETS: "[projects] SET Assets",
  SET_OPS_DROP_ASSETS_URL: "[projects] SET OpsDrop Assets Url",
  POST_NEW_NOTE_REQUEST: "[projects] Post New Note Request",
  DELETE_NOTE_REQUEST: "[projects] Delete Note Request",
  EDIT_NOTE_REQUEST: "[projects] Edit Note Request",
  POST_NEW_ASSET_REQUEST: "[projects] Post New Asset Request",
  DELETE_ASSET_REQUEST: "[projects] Delete Asset Request",
  REQUEST_PROPOSAL: "[projects] Request Proposal",
  REQUEST_VISION_PROPOSAL: "[projects] Request Vision Proposal",
  SET_CREATE_VISION_REQ_SUCCESS: "[projects] Create Vision Request Successful",
  SET_VISION_DESIGN_REVISION: "[projects] Set Vision Revision",
  GET_PROJECT_PROPOSALS_REQUEST: "[projects] GET Project Proposals Request",
  SET_PROJECT_PROPOSALS: "[projects] SET Project Proposals",
  GET_VISION_PROPOSAL_COUNT: "[projects] GET Vision Proposal Count",
  SET_VISION_PROPOSAL_COUNT: "[projects] SET Vision Proposal Count",
  REQUEST_PROPOSAL_REVISION: "[projects] Request Proposal Revision",
  REQUEST_VISION_PROPOSAL_REVISION: "[projects] Request Vision Proposal Revision",
  RESET_PROJECT_DETAILS: "[projects] Reset Project Details",
  RESET_PROJECTS_LIST: "[projects] Reset Project List",
  CLOSE_PROJECT_REQUEST: "[projects] Close Project Request",
  REOPEN_PROJECT_REQUEST: "[projects] Reopen Project Request",
  SET_PROJECTS_AUTOCOMPLETE_SUGGESTIONS: "[projects] SET Projects Autocomplete Suggestions",
  SET_LEADS_EVENT_COUNTS: "[projects] SET Projects Event Counts",
  UPLOAD_COORDINATOR_CSV_REQUEST: "[projects] Upload Coordinator Csv Request",
  GET_PROJECT_LEADS_AUTOCOMPLETE_REQUEST: "[projects] GET Project Leads Autocomplete Request",
  SET_PROJECT_LEADS_AUTOCOMPLETE: "[projects] SET Project Leads Autocomplete",
  UPDATE_PROJECT_BADGE_COUNT: "[projects] Update Project Badge Count",
  INVITE_TO_MY_POWUR: "[projects] Invite To MyPowur",
  REQUEST_PROPOSAL_UNLOCK: "[projects] Request Proposal Unlock",

  CREATE_SITE_SURVEY_SCHEDULE: "[projects] Create Site Survey Schedule",
  SET_SITE_SURVEY_SCHEDULE: "[projects] SET Site Survey Schedule",
  GET_SITE_SURVEY_SCHEDULE: "[projects] GET Site Survey Schedule",
  SET_PROPOSAL_SETTINGS: "[projects] SET Proposal Settings",
  UPDATE_PROPOSAL_SETTINGS: "[projects] UPDATE Proposal Settings",
  UPDATE_PRE_SALES_MILESTONES: "[projects] UPDATE Pre Sales MileStones",
  GET_PROPOSAL_SETTINGS: "[projects] GET Proposal Settings",
  UPDATE_PROVIDER_LEAD_USERS: "[projects] Update Ambassador",
  GET_PROJECT_PAY_TERMS: "[projects] GET Project Pay Terms",
  SET_PROJECT_PAY_TERMS: "[projects] SET Project Pay Terms",
  SET_INTEREST_RATES_DRAWER_OPEN: "[projects] SET Project Interest Rates Drawer Open",
  SET_INTEREST_RATES_DRAWER_CLOSED: "[projects] SET Project Interest Rates Drawer Closed",
  INVALIDATE_PROPOSAL_CACHE: "[projects] POST Invalidate proposal cache",

  GET_ROOFING_PROPOSALS_REQUEST: "[projects] GET Roofing Proposals Request",

  CREATE_ROOFING_PROPOSALS: "[roofing] POST Roofing Proposals",

  SET_ROOFING_PROPOSALS: "[projects] SET Roofing Proposals",

  GET_ROOFING_PROPOSAL_DETAILS: "[projects] GET Roofing Proposal Details",

  SET_PROJECT_ROOFING_PROPOSAL_DETAILS: "[projects] SET Roofing Proposal Details",

  SET_LATEST_ROOFING_PROPOSAL: "[projects] SET Latest Roofing Proposal",

  GET_PROJECT_INTEREST_RATES: "[projects] GET Project Interest Rates",

  SET_PROJECT_INTEREST_RATES: "[projects] SET Project Interest Rates",

  INVITE_TO_POWUR_SITE: "[projects] Invite To Powur Site",

  GET_PROJECTS_CSV: "[projects] GET Projects Csv",
  SET_PROJECTS_CSV: "[projects] SET Projects Csv",

  GET_PROJECTS_CSV_REPORT: "[projects] GET Projects Csv Report",

  GET_PROVIDER_LEADS_CSV: "[projects] GET Provider Leads Csv",
  SET_PROVIDER_LEADS_CSV: "[projects] SET Provider Leads Csv",

  GET_PROVIDER_LEADS_CSV_REPORT: "[projects] GET Provider Leads Csv Report",

  SET_REQUEST_MODAL_VISIBLE: "[projects] SET Solo Modal Visible",
  GET_REQUEST_MODAL_VISIBLE: "[projects] GET Solo Modal Visible",

  SET_ROOFING_MODAL_VISIBLE: "[projects] SET Roofing Modal Visible",
  GET_ROOFING_MODAL_VISIBLE: "[projects] GET Roofing Modal Visible",

  SET_ROOFING_MODAL_TITLE: "[projects] SET Roofing Modal Title",
  GET_ROOFING_MODAL_TITLE: "[projects] SET Roofing Modal Title",

  GET_ROOFING_NON_REQ_REASON: "[projects] GET Roofing Non Req Reason",
  SET_ROOFING_NON_REQ_REASON: "[projects] SET Roofing Non Req Reason",
  SET_SELECTED_ROOFING: "[projects] SET Selected Roofing",
  GET_SELECTED_ROOFING: "[projects] GET Selected Roofing",

  SET_HOVERED_PROJECT: "[projects] SET Hovered Project",
  GET_HOVERED_PROJECT: "[projects] GET Hovered Project",

  INVITE_HOMEOWNER: "[projects] SET Invite Homeowner",

  GET_PROVIDER_LEADS_KANBAN_REQUEST: "[projects] GET Provider Leads Kanban Request",
  SET_PROVIDER_LEADS_KANBAN: "[projects] SET Provider Leads Kanban",
  SET_PROVIDER_LEAD_STAGE_IN_KANBAN: "[projects] SET Provider Lead in Kanban",
  SET_CRM_ACTIVITY_IN_LIST: "[projects] SET Crm Activity In List",
  ADD_CRM_ACTIVITY_TO_KANBAN: "[projects] Add Crm Activity To Kanban",
  ADD_CRM_ACTIVITY_TO_LIST: "[projects] Add Crm Activity To List",
  REMOVE_COMPLETE_CRM_ACTIVITIES: "[CRM Activities] Remove Complete CRM Activity",
  SET_CRM_ACTIVITY_IN_DETAIL: "[projects] ADD Crm Activity To Project Detail",
  UPDATE_CRM_ACTIVITY_IN_DETAIL: "[projects] UPDATE Crm Activity in Project Detail",
  SET_CRM_ACTIVITY_LOG: "[projects] SET Crm Activity Log",
  ADD_PROJECT_CRM_ACTIVITY: "[projects] ADD Project Crm Activity",
  UPDATE_PROJECT_CRM_ACTIVITY: "[projects] UPDATE Project Crm Activity",
  UPDATE_PROJECT_CRM_ACTIVITIES: "[projects] UPDATE Project CRM Activities",
  // SET_PROVIDER_LEADS_KANBAN_FILTERS: "[projects] SET Provider Leads Kanban Filters",

  DOWNLOAD_ASSETS: "[projects] Downloadassets",
  SET_CONTRACT_TAB_COMPONENT: "[projects] SET Contract Tab Component",

  SET_CONTRACT_DETAILS: "[projects] SET Contract Details",

  GET_CONTRACT_DETAILS_REQUEST: "[projects] GET Contract Details Request",
  SET_CONTRACT_TAB_VISIBLE: "[projects] SET Contract Tab Visible",

  SET_DASHBOARD_TAB_VISIBLE: "[projects] SET Dashboard Tab Visible",

  SET_TABS_AS_LEAD_GENERATOR: "[projects] SET Tabs as Lead Generator",

  GET_KPI_DATA: "[projects] GET Kpi Data",
  SET_KPI_DATA: "[projects] SET Kpi Data",

  SEND_SALES_DESIGN_CONFIRMATION: "[projects] Send Sales Design Confirmation",
  SET_PROJECT_CHANGE_REQUEST_VISIBLE: "[projects] SET Project Change Request",
  SET_CONTRACT_AS_SOLD_DETAILS: "[projects] SET Contract As Sold Details",

  SET_COMMISSION_TAB_VISIBLE: "[projects] SET Commission Tab Visible",

  GET_PROJECT_LOOKUP_OPTIONS: "[projects] GET Project Lookup Options",

  SET_PROJECT_LOOKUP_OPTIONS: "[projects] SET Project Lookup Options",
  POST_SELLER_CHANGE_ORDER: "[projects] POST Seller Change Order",

  GET_FUNDING_PROPOSALS_REQUEST: "[projects] GET Funding Proposals Request",
  GET_FUNDING_PROPOSAL_DETAILS: "[projects] GET Funding Proposal Details",
  SET_FUNDING_PROPOSALS: "[projects] SET Funding Proposals",
  SET_PROJECT_FUNDING_PROPOSAL_DETAILS: "[projects] SET Funding Proposal Details",
  SET_FUNDING_PROPOSAL_REQUESTABLE: "[projects] SET Funding Proposal Requestable",
  CLEAR_PROJECT_FUNDING_PROPOSAL_DETAILS: "[projects] CLEAR Funding Proposal Details",

  SET_LATEST_INDEX_ROUTE: "[projects] SET Latest Index Route",

  SET_COMPARE_CONTRACT_ID: "[projects] SET Compare Contract Id",

  SET_CONTRACT_VERSION: "[projects] SET Contract Version",

  SET_HIDE_PROJECT_CHANGE_BANNER: "[projects] SET Hide Project Change Banner",

  CREATE_TPO_PRICING_WORKSHEET: "[projects] CREATE Pricing Worksheet",
  SET_TPO_PRICING_WORKSHEET: "[projects] SET Pricing Worksheet",
  GET_TPO_PRICING_WORKSHEETS: "[projects] GET Pricing Worksheets",
  SET_TPO_PRICING_WORKSHEETS: "[projects] SET Pricing Worksheets",
  SET_IS_TPO_PRICING_WORKSHEET_REQUESTED: "[projects] SET Pricing Worksheet Requested",
  SET_CHANGE_ORDER_BLOCKER_COMPLETE: "[projects] SET Change Order Blocker Complete",
  REMOVE_CO_BLOCKER_FROM_MODAL: "[projects] SET Remove Change Order Blocker from Modal",
  // INSERTION_PT (for script, do not remove)
};
