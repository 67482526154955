import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { UseFormReset } from "react-hook-form";

import { DownCaretFilled, RightCaretFilled, CloseIcon } from "components/Icons";
import { SmartFormRadioGroup } from "components/mui-styled-components/SmartFormElements";
import { PrimaryButton } from "components/mui-styled-components";
import GradientGhostButton from "components/Buttons/GradientGhostButton";
import { cvar } from "styles/colorVariables";
import { radioButtonContent, PowurPairFormSchemaT } from "../PowurPairForm/powurPairFormData";

import * as S from "./styles";

type PowurPairCriteriaMenuPropsT = {
  powurPairCriteria: PowurPairFormSchemaT;
  resetCriteria: UseFormReset<PowurPairFormSchemaT>;
  onSubmit: (e?: React.BaseSyntheticEvent<object> | undefined) => Promise<void>;
};

const PowurPairCriteriaMenu = ({ powurPairCriteria, onSubmit, resetCriteria }: PowurPairCriteriaMenuPropsT) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    handleResetCriteria();
  }, [powurPairCriteria]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResetCriteria = (): void => {
    resetCriteria(powurPairCriteria);
  };

  const handleCloseDropdown = (): void => {
    handleResetCriteria();
    setMenuAnchorEl(null);
  };

  const handleToggleMenu = (target: HTMLElement): void => {
    if (menuAnchorEl) {
      handleCloseDropdown();
    } else {
      setMenuAnchorEl(target);
    }
  };

  const handleSubmit = (): void => {
    handleCloseDropdown();
    onSubmit();
  };

  return (
    <>
      <S.CriteriaMenuHeader
        tabIndex={0}
        role="button"
        onClick={(e) => handleToggleMenu(e.currentTarget)}
        onKeyDown={(e) => handleToggleMenu(e.currentTarget)}
      >
        {menuAnchorEl ? (
          <DownCaretFilled fill={cvar("dark-blue")} height="15" width="15" />
        ) : (
          <RightCaretFilled fill={cvar("dark-blue")} height="15" width="15" />
        )}
        <S.CriteriaMenuLabel>Criteria Settings</S.CriteriaMenuLabel>
      </S.CriteriaMenuHeader>
      <S.StyledCriteriaMenu anchorEl={menuAnchorEl} open={!!menuAnchorEl} onClose={() => setMenuAnchorEl(null)}>
        <Grid container>
          {radioButtonContent.map((content) => (
            <Grid item xs={12} sm={4} key={content.name}>
              <SmartFormRadioGroup
                name={content.name}
                mainLabel={content.title}
                mainLabelStyle={{
                  fontWeight: 700,
                  fontSize: "10px",
                  lineHeight: "10px",
                  color: cvar("darkest-blue"),
                  textTransform: "uppercase",
                  fontFamily: "Barlow",
                  paddingLeft: "3px",
                }}
                radioOptions={content.answersShort}
                radioLabelStyle={{
                  fontFamily: "Barlow",
                  color: cvar("dark-blue"),
                  fontSize: "14px",
                }}
              />
            </Grid>
          ))}
        </Grid>
        <S.ButtonContainer>
          <GradientGhostButton onClick={handleResetCriteria} style={{ marginRight: "7px" }}>
            <CloseIcon startFill={cvar("picton-blue")} stopFill={cvar("blue-ribbon")} />
          </GradientGhostButton>
          <PrimaryButton gradient onClick={handleSubmit} style={{ width: "180px" }}>
            Run Pair
          </PrimaryButton>
        </S.ButtonContainer>
      </S.StyledCriteriaMenu>
    </>
  );
};

export default PowurPairCriteriaMenu;
