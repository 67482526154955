import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getSellerApplicationAgreement(action) {
  const { enterpriseId } = action.payload;

  const params = {
    kind: enterpriseId ? "seated_user" : "consultant",
  };

  if (enterpriseId) {
    params.enterprise_id = enterpriseId;
  }

  const { success, data, error } = yield* makeRequest.get("/application_agreement", params);

  if (success && data) {
    yield put(actions.setSellerApplicationAgreement(keysToCamelCase(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_SELLER_APPLICATION_AGREEMENT,
    });
  }

  return null;
}

export default getSellerApplicationAgreement;
