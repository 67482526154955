import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const CleanDealIcon = ({ width = "24", height = "24", fill = cvar("white") }: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M16.5215 10.3125C16.5215 9.79472 16.1018 9.375 15.584 9.375H5.9278C5.41001 9.375 4.9903 9.79472 4.9903 10.3125C4.9903 10.8303 5.41001 11.25 5.9278 11.25H15.584C16.1018 11.25 16.5215 10.8303 16.5215 10.3125Z"
        fill={fill}
      />
      <path
        d="M5.9278 13.125C5.41001 13.125 4.9903 13.5447 4.9903 14.0625C4.9903 14.5803 5.41001 15 5.9278 15H11.7924C12.3102 15 12.7299 14.5803 12.7299 14.0625C12.7299 13.5447 12.3102 13.125 11.7924 13.125H5.9278Z"
        fill={fill}
      />
      <path
        d="M8.13091 22.125H4.99548C3.96161 22.125 3.12048 21.2839 3.12048 20.25V3.75C3.12048 2.71613 3.96161 1.875 4.99548 1.875H16.5215C17.5554 1.875 18.3965 2.71613 18.3965 3.75V9.51562C18.3965 10.0334 18.8162 10.4531 19.334 10.4531C19.8518 10.4531 20.2715 10.0334 20.2715 9.51562V3.75C20.2715 1.68225 18.5893 0 16.5215 0H4.99548C2.92773 0 1.24548 1.68225 1.24548 3.75V20.25C1.24548 22.3177 2.92773 24 4.99548 24H8.13091C8.64869 24 9.06841 23.5803 9.06841 23.0625C9.06841 22.5447 8.64869 22.125 8.13091 22.125Z"
        fill={fill}
      />
      <path
        d="M15.584 5.625H5.9278C5.41001 5.625 4.9903 6.04472 4.9903 6.5625C4.9903 7.08028 5.41001 7.5 5.9278 7.5H15.584C16.1018 7.5 16.5215 7.08028 16.5215 6.5625C16.5215 6.04472 16.1018 5.625 15.584 5.625Z"
        fill={fill}
      />
      <g clipPath="url(#clip0_1348_1555)">
        <mask
          id="mask0_1348_1555"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="12"
          y="11"
          width="13"
          height="13"
        >
          <path d="M12 11H25V24H12V11Z" fill={fill} />
        </mask>
        <g mask="url(#mask0_1348_1555)">
          <path
            d="M17.4003 13.036C17.5129 12.8078 17.7454 12.6633 18 12.6633C18.2547 12.6633 18.4871 12.8078 18.5997 13.036C18.9632 13.7725 19.3993 14.6562 19.6269 15.1173C19.7243 15.3148 19.9126 15.4515 20.1305 15.4833C20.6395 15.5572 21.6147 15.6988 22.4273 15.817C22.6793 15.8537 22.8886 16.0301 22.9672 16.2722C23.046 16.5143 22.9803 16.7801 22.798 16.9577C22.21 17.531 21.5042 18.2189 21.136 18.5779C20.9784 18.7315 20.9065 18.9529 20.9436 19.1698C21.0305 19.6767 21.1972 20.648 21.336 21.4574C21.379 21.7084 21.276 21.9619 21.07 22.1115C20.864 22.2612 20.5909 22.2809 20.3656 22.1625C19.6387 21.7803 18.7665 21.3218 18.3112 21.0824C18.1164 20.98 17.8836 20.98 17.6888 21.0824C17.2336 21.3218 16.3613 21.7803 15.6344 22.1625C15.4091 22.2809 15.136 22.2612 14.93 22.1115C14.724 21.9619 14.621 21.7084 14.664 21.4574C14.8028 20.648 14.9695 19.6767 15.0564 19.1698C15.0935 18.9529 15.0216 18.7315 14.864 18.5779C14.4958 18.2189 13.7901 17.531 13.202 16.9577C13.0197 16.7801 12.954 16.5143 13.0328 16.2722C13.1114 16.0301 13.3207 15.8537 13.5727 15.817C14.3853 15.6988 15.3606 15.5572 15.8695 15.4833C16.0874 15.4515 16.2757 15.3148 16.3731 15.1173C16.6007 14.6562 17.0368 13.7725 17.4003 13.036Z"
            stroke={fill}
            fill="transparent"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1348_1555">
          <rect width="13" height="13" fill={fill} transform="translate(11 11)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CleanDealIcon;
