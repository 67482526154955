import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
// import selectors from "rdx/selectors";

import { camelizeKeys } from "lib/helpers";
import actions from "../actions";
import messages from "../messages";

function* getRoofingUsersAutocomplete(action) {
  const { params } = action.payload;

  const { success, data, error } = yield* makeRequest.get("/users/roofing_designers", { ...params });

  if (success && data) {
    yield put(actions.setRoofingUsersAutocomplete(camelizeKeys(data.body.users)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.GET_ROOFING_USERS_AUTOCOMPLETE_ERROR,
    });
  }
  return null;
}

export default getRoofingUsersAutocomplete;
