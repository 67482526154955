import React from "react";
import { Layout } from "antd";
import { Header, Footer, GetQuoteModal, UtilityBillModal, SuccessModal } from "components/HomepageComponents";
import { Helmet } from "react-helmet";
import EstimateSavings from "containers/StaticPages/solar/components/EstimateSavings";
import ScrollToTop from "containers/router/scrollToTop";
import VideoBanner from "./components/VideoBanner";
import VideoCards from "./components/VideoCards";
import FAQSection from "./components/FAQ";
import styles from "./FAQ.module.less";

const { Content } = Layout;

const FAQ = (props) => {
  const { user } = props;
  return (
    <Layout className={styles.pageLayout}>
      <Helmet>
        <title>The Most Frequently Asked Questions About Solar | Powur Energy</title>
        <meta name="title" content="The Most Frequently Asked Questions About Solar | Powur Energy" />
        <meta
          name="description"
          content="We want to help you go solar with clarity and confidence.  We created a series of videos where Powur CEO Jonathan Budd answers your questions. Click to watch."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://powur.com/whysolar" />
        <meta property="og:title" content="The Most Frequently Asked Questions About Solar | Powur Energy" />
        <meta
          property="og:description"
          content="We want to help you go solar with clarity and confidence.  We created a series of videos where Powur CEO Jonathan Budd answers your questions. Click to watch."
        />
        <meta property="og:image" content="/img/powur_fb_01.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://powur.com/whysolar" />
        <meta property="twitter:title" content="The Most Frequently Asked Questions About Solar | Powur Energy" />
        <meta
          property="twitter:description"
          content="We want to help you go solar with clarity and confidence.  We created a series of videos where Powur CEO Jonathan Budd answers your questions. Click to watch."
        />
        <meta property="twitter:image" content="/img/powur_fb_01.jpg" />
      </Helmet>
      <ScrollToTop />
      <Header user={user} />
      <Content className={styles.content}>
        <VideoBanner />
        <VideoCards />
        <EstimateSavings />
        <FAQSection />
      </Content>
      <Footer />
      <GetQuoteModal />
      <UtilityBillModal />
      <SuccessModal />
    </Layout>
  );
};

export default FAQ;
