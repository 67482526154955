import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import _ from "lodash";
import { keysToCamelCase, objArrayKeysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getTeamUserDetails(action) {
  const { id, timespan } = action.payload;
  const params = {};
  if (timespan) params.kpi_span = timespan;

  const { success, data, error } = yield* makeRequest.get(`/users/${id}`, params);

  if (success && data) {
    const user = keysToCamelCase(_.clone(data.body));
    user.metrics = {};
    objArrayKeysToCamelCase(data.body.metrics).forEach((obj) => {
      const newKey = Object.keys(obj)[0];
      const newValue = Object.values(obj)[0];
      user.metrics[newKey] = newValue;
    });
    user.upline = user.upline.map((uplineUser) => keysToCamelCase(uplineUser));
    yield put(actions.setTeamUserDetails({ user }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_USER_DETAILS,
    });
  }
  return null;
}

export default getTeamUserDetails;
