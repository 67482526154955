import React from "react";

const IconBullet = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: 30, minHeight: 30 }}
    >
      <path d="M4.60498 9.57642V4.18359H9.98877L4.60498 9.57642Z" fill="var(--picton-blue)" />
      <path d="M4.60502 17.2052L0.797852 13.3917L4.60502 9.57812V17.2052Z" fill="var(--picton-blue)" />
      <path d="M9.98877 4.18465L13.7959 0.371094L17.6031 4.18465H9.98877Z" fill="var(--picton-blue)" />
      <path d="M17.1801 22.5977L13.5844 26.1994L9.98877 22.5977H17.1801Z" fill="var(--picton-blue)" />
      <path d="M13.7959 18.7864V13.3906H19.1827L13.7959 18.7864Z" fill="var(--picton-blue)" />
      <path d="M13.7959 8V13.3928H8.41211L13.7959 8Z" fill="var(--picton-blue)" />
      <path d="M9.98877 22.5989H4.60498V17.2031L9.98877 22.5989Z" fill="var(--picton-blue)" />
      <path d="M22.9898 17.2031V22.5989H17.603L22.9898 17.2031Z" fill="var(--picton-blue)" />
      <path d="M22.99 9.57812L26.7972 13.3917L22.99 17.2052V9.57812Z" fill="var(--picton-blue)" />
      <path d="M17.603 4.18359H22.9898V9.57642L17.603 4.18359Z" fill="var(--picton-blue)" />
    </svg>
  );
};

export default IconBullet;
