import React from "react";
import PropTypes from "prop-types";
import ModalContainer from "@modals/ModalContainer";
import styles from "../../AmbassadorDashboard.module.less";

const UpgradeModal = (props) => {
  const { visible, onSubmit, closeModal } = props;

  return (
    <ModalContainer
      visible={visible}
      title={<div className={styles.heading}>Upgrade to a Powur Sales Account</div>}
      submitText="Get Started"
      handleCancel={closeModal}
      handleSubmit={onSubmit}
      headingClassName={styles.ambassadorUpgradeModalHeading}
      headerCentered
      centerButtons
      gradientButtons
    >
      <div className={styles.ambassadorUpgradeModalBody}>
        Continue on to create a Powur Preview Account and choose what type of Powur Sales Account works for you.
      </div>
    </ModalContainer>
  );
};

UpgradeModal.propTypes = {
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
  visible: PropTypes.bool,
};

export default UpgradeModal;
