export const leftMainTitle = "A note on design to share with your homeowner";

export const leftMainSubtitle = "HELP AVOID CHANGE ORDERS!";

export const leftMainBody =
  "It is important to explain to the homeowner that during the design process, our Powur expert design specialists will review the proposed module layout and system configuration. There is always the possibility that the design team will look to optimize the system to best suit the customer's aesthetic and energy production needs, while complying with local regulations and best system cost and efficiency. In the case there are proposed design changes, obtaining the seller’s answers to the following questions will enable the homeowner and design team to be on the same page from the get-go and hopefully avoid any unnecessary change orders!";

export const questionOne =
  "If the Powur Design Team identifies any areas of optimization for the solar module layout, are you open to updating the design?";

export const questionTwo =
  "If the Powur Solar Design Team determines that street-facing roof surfaces could be utilized to improve the system, would you be open to it?";

export const questionThree = "Any additional detail that may be helpful for the design team to consider?";
