import { put } from "redux-saga/effects";
import { camelizeKeys } from "lib/helpers";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "../actions";
import messages from "../messages";

function* getSelectedDashboardPromotion(action) {
  const { id } = action.payload;

  const { success, data, error } = yield* makeRequest.get(`/dashboard_promotions/${id}`, {});

  if (success && data) {
    yield put(actions.setSelectedDashboardPromotion(camelizeKeys(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.GET_EVENTS_AND_PROMOS_ERROR,
    });
  }
  return null;
}

export default getSelectedDashboardPromotion;
