import { put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "rdx/modules/compensation/messages";
import { camelizeKeys } from "lib/helpers";

function* recalculatePayment(action) {
  const { payload } = action;

  const params = {
    keep_manual_items: payload.keep_manual_items,
  };

  const { success, data, error } = yield* makeRequest.post(`/compensation/pay_periods/${payload.id}/calculate`, params);

  if (success && data) {
    const payments = yield select(selectors.getPayPeriods);
    const updatedPeriods = payments.payPeriods.map((p) => (p.id === data.body.id ? data.body : p));
    const out = { ...payments, payPeriods: camelizeKeys(updatedPeriods) };
    yield put(actions.setPeriods(out));
    yield put(
      actions.newSuccessEvent({
        message: messages.RECALCULATE_PAYMENT_SUCCESS,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_RECALCULATING_PAYMENT,
    });
  }

  return null;
}

export default recalculatePayment;
