import React from "react";

const Inspection = () => {
  return (
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.74947 0.166143C9.85738 0.085295 10.0057 0.0852388 10.1136 0.166005L12.6424 2.05766V1.55161C12.6344 1.49811 12.6406 1.44233 12.6622 1.39016C12.7092 1.27664 12.82 1.20262 12.9429 1.20262H15.9103C16.0781 1.20262 16.2141 1.33865 16.2141 1.50644V4.73716L18.8102 6.68503C18.875 6.73364 18.9176 6.80611 18.9288 6.88635C18.9399 6.96659 18.9185 7.04794 18.8694 7.11234C18.5706 7.50391 18.2719 7.89462 17.9733 8.28518L17.9729 8.2857C17.6741 8.67647 17.3754 9.0671 17.0768 9.45855C16.9759 9.59085 16.7873 9.61734 16.6538 9.51796L16.2141 9.19058V14.2401C16.2141 14.2405 16.2141 14.2408 16.2141 14.2411C16.2144 14.2811 16.2068 14.3214 16.1909 14.3598C16.1439 14.4734 16.0331 14.5474 15.9102 14.5473L3.96306 14.544C3.79544 14.5439 3.65952 14.4081 3.65932 14.2405L3.65329 9.18198L3.20906 9.51153C3.0756 9.61053 2.88736 9.58395 2.78654 9.45186L1.03273 7.15435C0.970682 7.09872 0.931641 7.01795 0.931641 6.92805V6.92467C0.931641 6.82899 0.976716 6.7389 1.05329 6.68152L9.74947 0.166143ZM1.66564 6.98202L3.08771 8.84495L3.77538 8.33482C3.86752 8.26647 3.99031 8.25585 4.09282 8.30736C4.19534 8.35888 4.26009 8.46375 4.26022 8.57847L4.26661 13.9364L15.6064 13.9396V8.58559C15.6064 8.4707 15.6712 8.36564 15.7739 8.31408C15.8766 8.26252 15.9995 8.27328 16.0917 8.34189L16.7759 8.85127C17.0139 8.53956 17.2519 8.2283 17.4898 7.91713L17.4902 7.91662C17.727 7.60689 17.9637 7.29725 18.2004 6.98722L14.2357 4.01119L14.6006 3.52533L15.6064 4.28084V1.81027H13.2501V2.66436C13.2501 2.77937 13.1852 2.88452 13.0823 2.93602C12.9795 2.98752 12.8564 2.97654 12.7643 2.90765L9.93179 0.788828L1.66564 6.98202Z"
        fill="red"
      />
    </svg>
  );
};

export default Inspection;
