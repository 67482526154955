import React from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";

import { selectors } from "rdx";
import { SettingsTuneIcon, HandshakeIcon, PowurPairIcon } from "components/Icons";

import * as S from "./styles";

type LandingPagePropsT = {
  findMentor: () => void;
  powurPair: () => void;
  closeMyOwn: () => void;
  cardSize?: number;
  spacing?: number;
  rsmRequirementsPassed?: boolean;
};

const LandingPage = ({
  findMentor,
  powurPair,
  closeMyOwn,
  cardSize = 6,
  spacing = 1,
  rsmRequirementsPassed,
}: LandingPagePropsT) => {
  const currentUser = useSelector(selectors.getCurrentUser);
  const { projectRequirementMet } = currentUser;

  const landingPageCardContent = [
    ...(projectRequirementMet && rsmRequirementsPassed
      ? [
          {
            icon: <HandshakeIcon width="23" height="23" className="stroke" />,
            titleText: "I'm Closing My Own Deal",
            detailText:
              "I will complete this sale and support my customer through installation on my own without a Closer.",
            buttonAction: closeMyOwn,
          },
        ]
      : []),
    {
      icon: <PowurPairIcon fill="white" width="23" height="23" className="fill" />,
      titleText: "Use Powur Pair to Find the Best Closer",
      detailText:
        "Show me who the algorithm selects as the best Closer to set me up for success on this specific project.",
      buttonAction: powurPair,
    },
    {
      icon: <SettingsTuneIcon width="23" height="23" className="fill" />,
      titleText: "Show Me All Available Closers",
      detailText:
        "I would like to search and filter through the entire list of highly skilled Closer to find the right one for my project.",
      buttonAction: findMentor,
    },
  ];

  return (
    <Grid container spacing={spacing}>
      {landingPageCardContent.map((card) => (
        <Grid item xs={cardSize} key={card.titleText}>
          <S.LandingPageCard onClick={card.buttonAction}>
            <S.IconContainer className="iconContainer">{card.icon}</S.IconContainer>
            <S.CardDetail>
              <S.CardHeaderText className="headerText">{card.titleText}</S.CardHeaderText>
              <S.CardDetailText className="detailText">{card.detailText}</S.CardDetailText>
            </S.CardDetail>
          </S.LandingPageCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default LandingPage;
