import React from "react";
import PropTypes from "prop-types";

const Ellipses = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="19" cy="3" r="3" fill={fill} />
      <circle cx="11" cy="3" r="3" fill={fill} />
      <circle cx="3" cy="3" r="3" fill={fill} />
    </svg>
  );
};

Ellipses.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

Ellipses.defaultProps = {
  fill: "var(--silver-grey)",
  height: "4",
  width: "14",
};

export default Ellipses;
