import React, { useEffect, useContext } from "react";
import { Table } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { buttonProps } from "lib/props";
import { AmbassadorDashboardContext as context } from "containers/Ambassador/dashboard";

const BankingTable = () => {
  const {
    stripeInfo: { email, bankAccount },
    getStripeInfo,
    getStripeLoginUrl,
    user,
    fetching,
    styles,
  } = useContext(context);

  useEffect(() => {
    getStripeInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      width: "35%",
      dataIndex: "label",
      className: styles.labelCol,
      render: (text) => text,
    },
    {
      width: "65%",
      dataIndex: "render",
      className: styles.valueCol,
      render: (_, record) => <div>{record.render()}</div>,
    },
  ];
  const dataSource = [
    {
      key: "name",
      label: "Name",
      render: () => <div className={styles.valueCell}>{user.props?.fullName}</div>,
    },
    {
      key: "email",
      label: "Email",
      render: () => <div className={styles.valueCell}>{email || "-"}</div>,
    },
    {
      key: "last4",
      label: "Account Number (Last 4 Digits)",
      render: () => <div className={styles.valueCell}>{bankAccount ? bankAccount.last4 : "-"}</div>,
    },
    {
      key: "bank",
      label: "Bank",
      render: () => <div className={styles.valueCell}>{bankAccount ? bankAccount.bankName : "-"}</div>,
    },
    {
      key: "edit-stripe",
      label: "Edit Banking Info",
      render: () => (
        <div className={styles.stripeCell} {...buttonProps(handleStripeClick)}>
          <img src="/ambassador/stripe.png" alt="Powered By Stripe" />
          <ExportOutlined className={styles.icon} />
        </div>
      ),
    },
  ];

  const handleStripeClick = () => {
    getStripeLoginUrl();
  };

  return (
    <Table className={styles.table} columns={columns} dataSource={dataSource} pagination={false} loading={fetching} />
  );
};

export default BankingTable;
