import PropTypes from "prop-types";
import { store } from "rdx/configureStore";
import actions from "rdx/modules/tickets/actions";
import { Base } from "../Base";

const {
  deleteInboxMessage,
  reopenIssue,
  resolveIssue,
  archiveMessages,
  createNewProjectMessage,
  updateSelectedMessage,
  getAllRepliesForIssue,
  postProjectMessageReply,
  deleteProjectMessage,
  assignIssue,
  getAvailableAssignees,
  starTicket,
  unstarTicket,
} = actions;

class Ticket extends Base {
  postNewProjectNote = ({
    body,
    subjectLine: subject_line,
    projectId: project_id,
    parentId: parent_id,
    assignedUserId: assigned_user_id,
    assignedTeamId: assigned_team_id,
    assignedToCustomerCare: assigned_to_customer_care,
    messageTopicId: message_topic_id,
    remoteFormId: remote_form_id,
    submitFieldsArray: remote_fields,
  }) => {
    store.dispatch(
      createNewProjectMessage({
        body,
        subject_line,
        project_id,
        parent_id,
        assigned_user_id,
        assigned_team_id,
        assigned_to_customer_care,
        message_topic_id,
        remote_form_id,
        remote_fields,
      }),
    );
  };

  edit = ({ body }) => {
    store.dispatch(
      updateSelectedMessage({
        id: this.props.id,
        body,
      }),
    );
  };

  delete = () => {
    store.dispatch(deleteInboxMessage({ id: this.props.id, parent_id: this.props.parentId }));
  };

  deleteProjectNote = () => {
    store.dispatch(deleteProjectMessage({ id: this.props.id }));
  };

  reopen = () => {
    store.dispatch(reopenIssue({ id: this.props.id }));
  };

  resolve = () => {
    store.dispatch(resolveIssue({ id: this.props.id }));
  };

  star = () => {
    store.dispatch(starTicket({ id: this.props.id }));
  };

  unstar = () => {
    store.dispatch(unstarTicket({ id: this.props.id }));
  };

  reassign = ({ assignedUserId, assignedTeamId, assignedToCustomerCare }) => {
    store.dispatch(
      assignIssue({
        id: this.props.id,
        assigned_user_id: assignedUserId,
        assigned_team_id: assignedTeamId,
        assigned_to_customer_care: assignedToCustomerCare,
      }),
    );
  };

  archive = (resolve = false) => {
    store.dispatch(
      archiveMessages({
        message_ids: [this.props.id],
        archived: this.props.achived,
        resolve,
      }),
    );
  };

  postReply = ({ body }) => {
    store.dispatch(
      postProjectMessageReply({
        body,
        parent_id: this.props.id,
      }),
    );
  };

  fetchReplies = () => {
    store.dispatch(getAllRepliesForIssue({ id: this.props.id }));
  };

  static getAvailableAssignees = () => {
    store.dispatch(getAvailableAssignees());
  };

  // user info

  isSeller(userId) {
    return !!userId && userId === this.props.project?.agent?.id;
  }

  isMentor(userId) {
    return !!userId && userId === this.props.project?.mentor?.id;
  }

  isProjectManager(userId) {
    return !!userId && userId === this.props.project?.coordinator?.id;
  }

  isAuthor(userId) {
    return !!userId && userId === this.props.author?.id;
  }

  isAssigned(userId) {
    if (userId) {
      return userId === this.props.assignedUser?.id || userId === this.props.assignedTeam?.id;
    }
    return false;
  }

  // Typechecking

  static types() {
    const user = PropTypes.shape({
      avatar: PropTypes.shape({
        large: PropTypes.string,
        preview: PropTypes.string,
        retina: PropTypes.string,
        thumb: PropTypes.string,
      }),
      id: PropTypes.number,
      email: PropTypes.string,
      firstName: PropTypes.string,
      fullName: PropTypes.string,
      lastName: PropTypes.string,
      locale: PropTypes.string,
      phone: PropTypes.string,
      subscriptionStatus: PropTypes.string,
      vanityName: PropTypes.string,
    });

    return PropTypes.shape({
      archived: PropTypes.bool,
      assignee: user,
      author: user,
      body: PropTypes.string,
      canDelete: PropTypes.bool,
      canArchive: PropTypes.bool,
      canEdit: PropTypes.bool,
      canReopen: PropTypes.bool,
      canReply: PropTypes.bool,
      childCount: PropTypes.number,
      createdAt: PropTypes.string,
      id: PropTypes.number,
      parentId: PropTypes.number,
      project: PropTypes.shape({
        id: PropTypes.number,
        lead: PropTypes.shape({
          email: PropTypes.string,
          fullName: PropTypes.string,
          id: PropTypes.number,
          phone: PropTypes.string,
        }),
      }),
      remainingTimeToResolve: PropTypes.number,
      resolved: PropTypes.bool,
      resolvedAt: PropTypes.string,
      updatedAt: PropTypes.string,
      viewed: PropTypes.bool,
    });
  }
}

export default Ticket;
