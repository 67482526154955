import { ProjectBlockerSlug } from "rdx/modules/projectBlockers/enums";
import { CrmActivityT } from "types/Project/crmActivity";

type FormattedDataReturnT = {
  updatedCrmActivities: CrmActivityT[];
  completedActivity: CrmActivityT;
};

const removeCoBlockerFromModalList = (crmActivities: CrmActivityT[]): FormattedDataReturnT => {
  const changeOrderBlockerSlugs = [ProjectBlockerSlug.BUILDABLE_WITH_CHANGES, ProjectBlockerSlug.CHANGE_ORDER_REQUIRED];

  const isChangeOrderBlocker = (activity: CrmActivityT): boolean => {
    return !!activity?.slug && activity.kind === "blocker" && changeOrderBlockerSlugs.includes(activity.slug);
  };

  const crmActivityIdx = crmActivities.findIndex((activity) => isChangeOrderBlocker(activity));
  const completedActivity = { ...crmActivities[crmActivityIdx] };
  const updatedCrmActivities = [...crmActivities];
  updatedCrmActivities.splice(crmActivityIdx, 1);

  return {
    updatedCrmActivities,
    completedActivity,
  };
};

export default removeCoBlockerFromModalList;
