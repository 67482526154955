import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enterpriseApplications/messages";
import { camelizeKeys, keysToSnakeCase } from "lib/helpers";

function* updateEnterpriseApplications(action) {
  const { id, values } = action.payload;
  const params = {
    ...keysToSnakeCase(values),
    background_checks: values.backgroundChecks.map((b) => keysToSnakeCase(b)),
    states_operated_in: values.statesOperatedIn,
  };

  const { success, data, error } = yield* makeRequest.patch(`/enterprise_applications/${id}`, params);

  if (success && data) {
    yield all([
      put(
        actions.setEnterpriseApplicationDetail({
          ...camelizeKeys(data.body),
          serviceAreas: data.body.service_areas,
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_ENTERPRISE_APPLICATIONS_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_ENTERPRISE_APPLICATIONS,
    });
  }

  return null;
}

export default updateEnterpriseApplications;
