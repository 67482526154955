import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import { cvar } from "styles";

export const InputContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
}));

export const LabelGroupWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  height: "20px",
}));

export const StyledFormLabel = styled("div")(() => ({
  fontSize: "10px",
  fontWeight: "600",
  lineHeight: "9px",
  marginBottom: "8px",
  color: cvar("nepal-blue"),
  textTransform: "uppercase",
}));

export const StyledTextField = styled(TextField)(() => ({
  marginBottom: "12px",
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { borderColor: cvar("botticelli-blue") },
    "&.Mui-focused fieldset": {
      borderColor: cvar("botticelli-blue"),
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `${cvar("botticelli-blue")} !important`,
  },
}));
