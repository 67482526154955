// Libraries
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import ReactQuill from "react-quill";

// Helpers
import { formatDateTimeSinceNow } from "lib/helpers";

// Icons
import EquipmentIcon from "@icons/PowurUpdatesIcons/EquipmentUpdateIcon";
import FinancingIcon from "@icons/PowurUpdatesIcons/FinancingUpdateIcon";
import GeneralIcon from "@icons/PowurUpdatesIcons/GeneralUpdateIcon";
import PlatformIcon from "@icons/PowurUpdatesIcons/PlatformUpdateIcon";
import PricingIcon from "@icons/PowurUpdatesIcons/PricingUpdateIcon";
import TrainingIcon from "@icons/PowurUpdatesIcons/TrainingUpdateIcon";

// Components
import { Button } from "antd";
import ModalContainer from "components/Modals/ModalContainer";
import GradientGhostButton from "components/Buttons/GradientGhostButton";
import { FireEmojiIcon } from "@icons";

// Styles
import "react-quill/dist/quill.snow.css";
import styles from "./DashboardUpdateModal.module.less";

const DashboardUpdateModal = (props) => {
  const { dashboardUpdates, selectedUpdate, setDashboardUpdateModalId } = props;
  const [selectedUpdateCreatedAtDiff, setSelectedUpdateCreatedAtDiff] = useState(null);
  const [selectedUpdateData, setSelectedUpdateData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [fadeDashboardModal, setFadeDashboardModal] = useState(styles.fadeDashboardModalUp);

  const indexAtSelectedUpdate = dashboardUpdates?.map((e) => e.id).indexOf(selectedUpdate);

  useEffect(() => {
    if (selectedUpdate === null) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [selectedUpdate]);

  useEffect(() => {
    if (dashboardUpdates?.length > 0 && selectedUpdate) {
      setSelectedUpdateData(dashboardUpdates.find((x) => x.id === selectedUpdate));
    } else {
      setSelectedUpdateData(null);
    }
  }, [dashboardUpdates, selectedUpdate]);

  // DateTime diff handling:
  useEffect(() => {
    return setSelectedUpdateCreatedAtDiff(formatDateTimeSinceNow(selectedUpdateData?.dateWithTime));
  }, [selectedUpdateData]);

  // Record's icon handling:
  const iconWidth = 40;
  const iconHeight = 40;
  const categoryIcon = () => {
    switch (selectedUpdateData?.categories[0]) {
      case "equipment":
        return <EquipmentIcon width={iconWidth} height={iconHeight} />;
      case "platform":
        return <PlatformIcon width={iconWidth} height={iconHeight} />;
      case "pricing":
        return <PricingIcon width={iconWidth} height={iconHeight} />;
      case "training":
        return <TrainingIcon width={iconWidth} height={iconHeight} />;
      case "financing":
        return <FinancingIcon width={iconWidth} height={iconHeight} />;
      default:
        return <GeneralIcon width={iconWidth} height={iconHeight} />;
    }
  };

  // Navigation and modal data/animation transitions (precise timing):
  const animationPause = 150;
  useEffect(() => {
    if (selectedUpdate != null) {
      setTimeout(() => setFadeDashboardModal(styles.fadeDashboardModalMiddle), animationPause);
    }
  }, [selectedUpdate]);

  const increment = () => {
    setFadeDashboardModal(styles.fadeDashboardModalUp);
    const next = dashboardUpdates[indexAtSelectedUpdate - 1]?.id;
    setTimeout(() => setDashboardUpdateModalId(next), animationPause);
    setTimeout(() => setFadeDashboardModal(styles.fadeDashboardModalDown), animationPause);
  };
  const decrement = () => {
    setFadeDashboardModal(styles.fadeDashboardModalDown);
    const previous = dashboardUpdates[indexAtSelectedUpdate + 1]?.id;
    setTimeout(() => setDashboardUpdateModalId(previous), animationPause);
    setTimeout(() => setFadeDashboardModal(styles.fadeDashboardModalUp), animationPause);
  };

  const close = () => {
    setFadeDashboardModal(styles.fadeDashboardModalUp);

    setTimeout(() => {
      setDashboardUpdateModalId(null);
      setFadeDashboardModal(styles.fadeDashboardModalDown);
    }, animationPause);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 38 && showIncrementButton()) {
      increment();
    }
    if (e.keyCode === 40 && showDecrementButton()) {
      decrement();
    }
  };

  useEffect(() => {
    // inject listener if selectedUpdate (therefore modal active)
    if (selectedUpdate) {
      window.addEventListener("keydown", handleKeyDown);
    }
    // else remove listener and cleanup component
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const showIncrementButton = () => {
    return indexAtSelectedUpdate !== 0;
  };
  const showDecrementButton = () => {
    return indexAtSelectedUpdate !== dashboardUpdates?.length - 1;
  };

  // Custom header for ModalContainer:
  const customHeader = (
    <div className={styles.header}>
      <div className={styles.headerIcon}>{categoryIcon()}</div>
      <div>
        <div>
          <span className={styles.headerCategory}>{selectedUpdateData?.categories[0]}</span>
          <span className={styles.headerCreatedSince}>{selectedUpdateCreatedAtDiff}</span>
        </div>
        <div>
          {selectedUpdateData?.hot === true && <FireEmojiIcon height="18px" width="24px" />}
          <span className={styles.headerTitle}>{selectedUpdateData?.title}</span>
        </div>
      </div>
    </div>
  );

  // Custom footer for ModalContainer:
  const customFooter = (
    <div className={styles.footer}>
      <div />
      <div className={styles.close}>
        <GradientGhostButton onClick={() => close()}>x</GradientGhostButton>
      </div>
      <div className={styles.navigate}>
        <span className={styles.spacer}>
          {showIncrementButton() && (
            <Button
              className={styles.button}
              type="primary"
              ghost
              onClick={() => increment()}
              style={styles.cancelButtonStyles}
            >
              <span className={styles.increment}>↑</span>
            </Button>
          )}
        </span>
        <span className={styles.spacer}>
          {showDecrementButton() && (
            <Button
              className={styles.button}
              type="primary"
              ghost
              onClick={() => decrement()}
              style={styles.cancelButtonStyles}
            >
              <span className={styles.decrement}>↓</span>
            </Button>
          )}
        </span>
      </div>
    </div>
  );

  return (
    <ModalContainer
      modalClassName={[styles.dashboardUpdatesModal, fadeDashboardModal].join(" ")}
      visible={visible}
      zIndex={1202}
      xClosable={false}
      onClose={() => close()}
      customHeader={customHeader}
      customFooter={customFooter}
      width={640}
      maskStyle={{ backgroundColor: "var(--mask-blue-a75)" }}
      transitionName=""
      // maskTransitionName=""
    >
      <>
        {selectedUpdateData?.copy && (
          <ReactQuill readOnly className="previewQuill" theme="bubble" value={selectedUpdateData?.copy} />
        )}
      </>
    </ModalContainer>
  );
};

DashboardUpdateModal.propTypes = {
  dashboardUpdates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      copy: PropTypes.string,
      date: PropTypes.string,
      status: PropTypes.string,
      userRoles: PropTypes.arrayOf(PropTypes.string),
      locations: PropTypes.arrayOf(PropTypes.string),
      categories: PropTypes.arrayOf(PropTypes.string),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      dateWithTime: PropTypes.string,
      alwaysHot: PropTypes.bool,
      hot: PropTypes.bool,
    }),
  ),
  selectedUpdate: PropTypes.number,
  setDashboardUpdateModalId: PropTypes.func,
};

DashboardUpdateModal.defaultProps = {
  dashboardUpdates: [],
};

export default connect(
  getRdxSelectionMapper({
    dashboardUpdates: "selectDashboardUpdatesArray",
    selectedUpdate: "selectDashboardUpdateModalId",
  }),
  getRdxActionMapper(["setDashboardUpdateModalId"]),
)(DashboardUpdateModal);
