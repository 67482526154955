import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
// import _ from "lodash";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getTeamLeadsList(action) {
  const params = {
    sort: "newest",
    ...action.payload,
  };

  const { success, data, error } = yield* makeRequest.get("/team/leads", params);

  if (success && data) {
    yield put(actions.setTeamLeadsList(camelizeKeys(data.body)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_TEAM_LEADS_LIST,
    });
  }
  return null;
}

export default getTeamLeadsList;
