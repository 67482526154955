import React from "react";
import PropTypes from "prop-types";

const InfoIcon = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9702 0C17.0515 0 22 4.94851 22 10.9702C22 17.0515 17.0515 22 10.9702 22C4.94851 22 0 17.0515 0 10.9702C0 4.94851 4.94851 0 10.9702 0ZM11.4472 4.23306C12.4607 4.23306 13.2358 5.06775 13.2358 6.02168C13.1762 7.03523 12.4011 7.8103 11.4472 7.8103C10.4336 7.8103 9.65854 6.97561 9.65854 6.02168C9.65854 5.00813 10.4932 4.23306 11.4472 4.23306ZM14.0108 16.2764C13.4743 18.2439 8.22764 18.4228 8.58537 15.7398C8.82385 13.9512 9.18157 12.2222 9.47968 10.4932C9.65854 9.36043 8.94309 9.71816 8.28726 9.47968C7.86992 9.30081 7.86992 8.76423 8.4065 8.64499C9.06233 8.46612 11.3875 8.58537 12.1626 8.58537C12.5799 8.58537 12.8184 8.82385 12.878 9.24119C12.878 9.59892 12.8184 9.89702 12.7588 10.2547C12.4607 11.9241 12.0434 13.7127 11.8049 15.3821C11.8049 15.7398 11.8645 16.3957 12.4011 16.336C12.9377 16.336 13.1762 16.0976 13.5935 15.9187C13.832 15.7995 14.0705 15.9783 14.0108 16.2764Z"
        fill={fill}
      />
    </svg>
  );
};

InfoIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

InfoIcon.defaultProps = {
  fill: "var(--dark-blue)",
  height: "22",
  width: "22",
};

export default InfoIcon;
