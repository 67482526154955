import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";

import useNavigation from "hooks/useNavigation";
import useLoading from "hooks/useLoading";
import usePermissions, { permissionTypes } from "hooks/usePermissions";

import msgs from "rdx/modules/profile/messages";
import types from "rdx/types";

import Router from "models/Router";
import User from "models/User";
import MessageEvent from "models/MessageEvent";

import SessionClient from "util/SessionClient";

import ImpersonationBanner from "components/PlatformLayout/components/Banners/ImpersonationBanner";
import AmbassadorPageContainer from "../components/AmbassadorPageContainer";
import DashboardHome from "./components/Referrals";
import AddReferral from "./components/AddReferral";
import Payouts from "./components/Payouts";
import Profile from "./components/Profile";
import Header from "./components/Header";
import Footer from "./components/Footer";
import styles from "./AmbassadorDashboard.module.less";

const AmbassadorDashboardContext = React.createContext({});

const AmbassadorDashboard = (props) => {
  const {
    match: { params },
    history,
    leads,
    zipInfo,
    stripeInfo,
    profile,
    requests,
    latestMessage,
    createAmbassadorLead,
    getAmbassadorLeads,
    ambassadorZipLookup: zipLookup,
    resetAmbassadorZipLookup: resetZipInfo,
    getStripeSignupUrl,
    getStripeLoginUrl,
    getStripeAccountInfo: getStripeInfo,
    getProfile,
    updateProfile,
    updatePassword,
    clearAlertMessage,
    setAlertMessageVisible,
  } = props;
  const session = new SessionClient();
  const { AMBASSADOR_CREATE } = usePermissions({
    permissionRequests: [permissionTypes.AMBASSADOR_CREATE],
  });

  const tabKeys = ["home", "new", "payouts", "profile"];
  const modalKeys = ["help"];
  const { navigate, override } = useNavigation("ambassador/dashboard", ["tab"], ["modal"]);
  const [activeTabKey, setActiveTabKey] = useState(() => {
    const match = tabKeys.find((key) => key === params.tab);
    if (match) return match;
    override({ tab: tabKeys[0] });
    return tabKeys[0];
  });
  const [activeModalKey, setActiveModalKey] = useState(() => {
    const match = modalKeys.find((key) => key === params.modal);
    if (match) return match;
    override({ modal: "none" });
    return undefined;
  });

  useEffect(() => {
    if (tabKeys.includes(params.tab)) {
      setActiveTabKey(params.tab);
    } else {
      // why do invalid tab keys persist? i don't know but this sorta fixes it.
      override({ tab: tabKeys[0] });
      setActiveTabKey(tabKeys[0]);
    }
  }, [params.tab, tabKeys]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (params.tab !== tabKeys[0]) {
      getAmbassadorLeads();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (params.tab === tabKeys[0]) {
      getAmbassadorLeads();
    }
  }, [params.tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // Show success/error messages
  const prevProps = useRef({
    activeRequests: requests,
    latestMessage,
  });

  useEffect(() => {
    const { latestMessage: msg } = props;
    const { latestMessage: prevMsg } = prevProps.current;
    const success = [
      msgs.CREATE_EMAIL_UPDATE_SUCCESS,
      msgs.DELETE_EMAIL_UPDATE_SUCCESS,
      msgs.RESEND_EMAIL_UPDATE_SUCCESS,
    ];

    if (msg && msg.id !== prevMsg.id && success.includes(msg.message)) {
      clearAlertMessage();
      if (msg.message !== msgs.GET_PROFILE_SUCCESS) {
        setAlertMessageVisible({
          message: msg.message,
          severity: "success",
        });
      }
    }

    if (msg && msg.id !== prevMsg.id && msg.type === "ERROR") {
      setAlertMessageVisible({
        message: `${msg.message}: ${msg.error.message}`,
        severity: "error",
      });
    }

    prevProps.current = {
      activeRequests: requests,
      latestMessage,
    };
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabs = [
    {
      key: tabKeys[0],
      tab: <DashboardHome />,
      forceRender: true,
    },
    {
      key: tabKeys[1],
      tab: <AddReferral />,
      forceRender: true,
    },
    {
      key: tabKeys[2],
      tab: <Payouts />,
    },
    {
      key: tabKeys[3],
      tab: <Profile />,
    },
  ];

  const submitting = useLoading({
    watchRequests: [
      types.CREATE_AMBASSADOR_LEAD_REQUEST,
      types.CREATE_EMAIL_UPDATE_REQUEST,
      types.DELETE_EMAIL_UPDATE_REQUEST,
      types.RESEND_EMAIL_UPDATE_REQUEST,
    ],
  });
  const fetching = useLoading({
    watchRequests: [
      types.GET_AMBASSADOR_LEADS_REQUEST,
      types.GET_STRIPE_ACCOUNT_INFO_REQUEST,
      types.GET_PROFILE_REQUEST,
      types.UPDATE_PROFILE_REQUEST,
    ],
  });
  const updatingPassword = useLoading({
    watchRequests: [types.UPDATE_USER_PASSWORD_REQUEST],
  });
  const redirecting = useLoading({
    watchRequests: [types.GET_STRIPE_LOGIN_URL_REQUEST, types.GET_STRIPE_SIGNUP_URL_REQUEST],
  });

  if (!session.user) {
    // temp. redundant redirect, /router/routes/AmbassadorRoute checks that session.user is
    // not null and redirects to splash page, need to investigate how user can end up
    // getting through the router's null check to render the dashboard component with
    // a null session.user.
    // see: https://eyecuelab.airbrake.io/projects/107005/groups/2746127667850893236
    return <Redirect to="/?alr=/ambassador/dashboard" />;
  }

  return (
    <>
      {session.impersonating && <ImpersonationBanner hideBypass />}
      <AmbassadorDashboardContext.Provider
        value={{
          navigate,
          override,
          tabKeys,
          activeTabKey,
          setActiveTabKey,
          modalKeys,
          activeModalKey,
          setActiveModalKey,
          history,
          params,
          styles,
          user: session.user,
          leads,
          createAmbassadorLead,
          zipLookup,
          zipInfo,
          stripeInfo,
          profile,
          resetZipInfo,
          getStripeSignupUrl,
          getStripeLoginUrl,
          getStripeInfo,
          getProfile,
          updateProfile,
          updatePassword,
          submitting: !!submitting,
          fetching: !!fetching,
          redirecting: !!redirecting,
          updatingPassword: !!updatingPassword,
          latestMessage,
          canCreateLead: AMBASSADOR_CREATE,
        }}
      >
        <AmbassadorPageContainer
          headerContent={<Header />}
          footerContent={<Footer />}
          activeTabKey={activeTabKey}
          tabs={tabs}
          tabsClassName={styles.tabsContainer}
        />
      </AmbassadorDashboardContext.Provider>
    </>
  );
};

AmbassadorDashboard.propTypes = {
  match: Router.matchTypes(),
  history: Router.historyTypes(),
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      progress: PropTypes.arrayOf(
        PropTypes.shape({
          timestamp: PropTypes.string,
          value: PropTypes.string,
          past: PropTypes.bool,
        }),
      ),
    }),
  ),
  zipInfo: PropTypes.shape({
    state: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
  stripeInfo: PropTypes.shape({
    email: PropTypes.string,
    bankAccount: PropTypes.shape({
      id: PropTypes.string,
      last4: PropTypes.string,
      object: PropTypes.string,
      status: PropTypes.string,
      account: PropTypes.string,
      country: PropTypes.string,
      metadata: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      bankName: PropTypes.string,
      fingerprint: PropTypes.string,
      routingNumber: PropTypes.string,
      accountHolderName: PropTypes.string,
      accountHolderType: PropTypes.string,
      defaultForCurrency: PropTypes.bool,
    }),
  }),
  profile: User.types(),
  latestMessage: MessageEvent.types(),
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      payload: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      type: PropTypes.string,
    }),
  ),
  createAmbassadorLead: PropTypes.func,
  getAmbassadorLeads: PropTypes.func,
  ambassadorZipLookup: PropTypes.func,
  resetAmbassadorZipLookup: PropTypes.func,
  getStripeSignupUrl: PropTypes.func,
  getStripeLoginUrl: PropTypes.func,
  getStripeAccountInfo: PropTypes.func,
  getProfile: PropTypes.func,
  updateProfile: PropTypes.func,
  updatePassword: PropTypes.func,
  clearAlertMessage: PropTypes.func,
  setAlertMessageVisible: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    leads: "getAmbassadorLeads",
    zipInfo: "getAmbassdorLeadZipLookup",
    stripeInfo: "getStripeInfo",
    profile: "getProfile",
    requests: "getActiveRequests",
    latestMessage: "getLatestMessageEvt",
  }),
  getRdxActionMapper([
    "createAmbassadorLead",
    "getAmbassadorLeads",
    "ambassadorZipLookup",
    "resetAmbassadorZipLookup",
    "getStripeSignupUrl",
    "getStripeLoginUrl",
    "getStripeAccountInfo",
    "getProfile",
    "updateProfile",
    "updatePassword",
    "clearAlertMessage",
    "setAlertMessageVisible",
  ]),
)(AmbassadorDashboard);
export { AmbassadorDashboardContext };
