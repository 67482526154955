import { put, all, select } from "redux-saga/effects";
import selectors from "rdx/modules/team/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "rdx/modules/enterprisePersonnel/messages";
import { cloneDeep } from "lodash";

function* sendEnterpriseTeamMemberInvite(action) {
  const { success, data, error } = yield* makeRequest.post("/registrations/invite", {
    ...action.payload,
    enterprise: true,
  });

  if (success && data) {
    const currentInvites = yield select(selectors.getTeamLeads);
    currentInvites.leads.unshift(camelizeKeys(data.body));

    yield all([
      yield put(
        actions.newSuccessEvent({
          message: messages.generateTeamMemberInviteMsg(action.payload.email),
          event: messages.NEW_TEAM_MEMBER_INVITE_SUCCESS,
        }),
      ),
      yield put(actions.setTeamLeadsList(cloneDeep(currentInvites))),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_SENDING_NEW_TEAM_MEMBER_INVITE,
    });
  }

  return null;
}

export default sendEnterpriseTeamMemberInvite;
