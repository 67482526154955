import { put, all } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import msgs from "rdx/modules/tickets/messages";
import { keysToCamelCase } from "lib/helpers";

function* getProjectRepliesPreview(action) {
  const { parentIds, project_id } = action.payload;
  if (!parentIds || _.isEmpty(parentIds)) {
    return null;
  }

  let url = "/messages/replies?limit=2";
  for (const id of parentIds) {
    url += `&parent_ids[]=${id}`;
  }
  if (project_id) {
    url += `&project_id=${project_id}`;
  }

  const { success, data, error } = yield* makeRequest.get(url, {});

  if (success && data) {
    const replies = _.groupBy(
      data.body.messages.map((e) => keysToCamelCase(e)),
      "parentId",
    );
    // const ids = data.body.messages.map((m) => m.id);
    yield all([
      put(actions.setProjectMessageReplies({ replies })),
      put(
        actions.setInboxTags({
          roleTags: data.body.taggable_roles.map((r) => ({
            ...keysToCamelCase(r),
            type: "role",
            text: r.description,
          })),
          userTags: data.body.taggable_users.map((r) => ({
            ...keysToCamelCase(r),
            type: "user",
            text: r.full_name,
          })),
        }),
      ),
      // put(
      //   actions.markMessagesAsViewed({
      //     messageIds: ids,
      //     projectId: action.payload.project_id,
      //     parentIds,
      //   }),
      // ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: msgs.ERROR_GETTING_PROJECT_REPLIES_PREVIEW,
    });
  }

  return null;
}

export default getProjectRepliesPreview;
