import React, { CSSProperties, useState, useEffect, useCallback } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import InputAdornment from "@mui/material/InputAdornment";
import { useController } from "react-hook-form";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";

import { cvar } from "styles";
import { useSmartFormContext } from "components/SmartForm";
import { ClockIcon } from "components/Icons";
import ToggleButton from "@mui/material/ToggleButton";
import * as S from "./styles";
import HelperText from "../HelperText";
import { hocSmartFormElement } from "../hoc";

dayjs.extend(utc);

type SmartFormInputTimePickerT = {
  label?: string;
  name: string;
  setTimeFieldValue: (timeField: string, timeValue: string | null) => void;
  helperText?: string | undefined;
  error?: boolean;
  disabled?: boolean;
  labelStyle?: CSSProperties;
  iconColor?: string;
};

type AmPmT = "am" | "pm";

const SmartFormInputTimePicker = ({
  label,
  name,
  helperText,
  error,
  setTimeFieldValue,
  disabled = false,
  labelStyle = {},
  iconColor = cvar("dark-blue"),
}: SmartFormInputTimePickerT) => {
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });
  const { value } = field;

  const [selectedTime, setSelectedTime] = useState<dayjs.Dayjs | null>(value ? dayjs(value) : dayjs());
  const [ampm, setAmPm] = useState<string>((value ? dayjs(value) : dayjs()).format("a"));
  const [ampmChanged, setAmpmChanged] = useState(false);

  const adjustHoursTo24Hr = useCallback(
    (selectedHour: number): number => {
      if (ampm === "am") {
        return selectedHour % 12;
      }
      if (selectedHour < 12) {
        return selectedHour + 12;
      }
      return selectedHour;
    },
    [ampm],
  );

  const handleTimeChange = useCallback(
    (inputValue: dayjs.Dayjs | null) => {
      setSelectedTime(inputValue);
      const selectedHours = inputValue?.hour() || 0;
      const adjustedHours = adjustHoursTo24Hr(selectedHours);
      const adjustedTime = inputValue?.set("hour", adjustedHours);
      const timeValue = adjustedTime?.format("YYYY-MM-DD HH:mm:ss") || null;
      setTimeFieldValue(name, timeValue);
    },
    [name, setTimeFieldValue, adjustHoursTo24Hr],
  );

  const handleAmPmToggle = (event: React.MouseEvent<HTMLElement>, newAmPm: AmPmT) => {
    setAmPm(newAmPm);
    setAmpmChanged(true);
    if (selectedTime) {
      const selectedHours = selectedTime.hour();
      const adjustedHours = newAmPm === "pm" ? selectedHours + 12 : selectedHours % 12;
      const adjustedTime = selectedTime.hour(adjustedHours);
      setSelectedTime(adjustedTime);
    }
  };

  useEffect(() => {
    if (ampmChanged && selectedTime) {
      const selectedHours = selectedTime.hour();
      const adjustedHours = ampm === "pm" ? selectedHours + 12 : selectedHours % 12;
      const adjustedTime = selectedTime.hour(adjustedHours);
      setSelectedTime(adjustedTime);
      handleTimeChange(adjustedTime);
      setAmpmChanged(false);
    }
  }, [ampm, ampmChanged, handleTimeChange, selectedTime]);

  return (
    <>
      <S.LabelGroupWrapper>
        <S.StyledFormLabel style={labelStyle}>{label}</S.StyledFormLabel>
      </S.LabelGroupWrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{ hours12h: "h" }}>
        <S.TimePickerWrapper>
          <DesktopTimePicker
            value={selectedTime}
            minutesStep={15}
            disabled={disabled}
            onChange={(newTime) => {
              handleTimeChange(newTime);
            }}
            inputFormat="hh:mm"
            renderInput={(params) => (
              <S.StyledTextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "HH : MM",
                }}
                disabled={disabled}
                size="small"
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ClockIcon fill={iconColor} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <S.StyledToggleButtonGroup
            value={ampm}
            exclusive
            onChange={handleAmPmToggle}
            aria-label="ampm"
            size="small"
            disabled={disabled}
          >
            <ToggleButton value="am" aria-label="AM">
              AM
            </ToggleButton>
            <ToggleButton value="pm" aria-label="PM">
              PM
            </ToggleButton>
          </S.StyledToggleButtonGroup>
        </S.TimePickerWrapper>
      </LocalizationProvider>
      <HelperText sx={{ marginTop: "-10px" }} error={error}>
        {helperText}
      </HelperText>
    </>
  );
};

export default hocSmartFormElement(SmartFormInputTimePicker);
