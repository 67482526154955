import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/preferredPartners/messages";
import { camelizeKeys } from "lib/helpers";

function* deletePreferredPartner(action) {
  const { id, deleteFromAdminView = false, search } = action.payload;

  const { success, data, error } = yield* makeRequest.delete(`/enterprise_labor_partners/${id}`);

  if (success && data) {
    const list = camelizeKeys(data.body.preferred_partners);
    const paging = camelizeKeys(data.body.paging);

    if (deleteFromAdminView) {
      yield put(actions.getPreferredPartnersAdmin(search));
    } else {
      yield put(actions.setPreferredPartners({ list, paging }));
    }
    yield put(actions.newSuccessEvent({ message: messages.REMOVE_PREFERRED_PARTNER_SUCCESS }));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_REMOVING_PREFERRED_PARTNER,
    });
  }

  return null;
}

export default deletePreferredPartner;
