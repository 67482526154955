import { put, select, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/users/messages";
import selectors from "rdx/modules/users/selectors";

function* updatePermission(action) {
  const { success, data, error } = yield* makeRequest.patch(`/permissions/${action.payload.id}`, {
    name: action.payload.name,
    description: action.payload.description,
    entity_id: action.payload.entityId,
    operation_id: action.payload.operationId,
  });

  if (success && data) {
    const permissions = yield select(selectors.getPermissions);
    const idx = permissions.indexOf(permissions.find((p) => p.id === action.payload.id));
    permissions[idx] = data.body;
    yield all([
      put(actions.setRolesList({ permissions })),
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_PERMISSION_SUCCESS,
          payload: { permissionId: action.payload.id },
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_PERMISSION,
    });
  }

  return null;
}

export default updatePermission;
