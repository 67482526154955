import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

type PermitApprovedIconPropsT = {
  checkmarkStartColor?: string;
  checkmarkStopColor?: string;
} & IconT;

const PermitApprovedIcon = ({
  height = 19,
  width = 19,
  fill = cvar("white"),
  checkmarkStartColor = cvar("picton-blue"),
  checkmarkStopColor = cvar("blue-ribbon"),
}: PermitApprovedIconPropsT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2043_13562)">
        <path
          d="M7.54883 6.42084C7.54883 6.49196 7.60655 6.54968 7.67767 6.54968H11.9965C12.0676 6.54968 12.1254 6.49196 12.1254 6.42084C12.1254 6.34971 12.0676 6.29199 11.9965 6.29199H11.6512V2.83175C11.6512 2.30197 11.2203 1.87109 10.6905 1.87109H8.98364C8.45386 1.87109 8.02298 2.30197 8.02298 2.83175V6.29199H7.67767C7.60655 6.29199 7.54883 6.34971 7.54883 6.42084ZM9.83709 2.7823C10.2494 2.7823 10.5844 3.11733 10.5844 3.52854C10.5844 3.94083 10.2494 4.27586 9.83709 4.27586C9.4248 4.27586 9.08978 3.94083 9.08978 3.52854C9.08978 3.11729 9.4248 2.7823 9.83709 2.7823Z"
          fill={fill}
        />
        <path
          d="M17.5533 7.67139H2.11884C1.84569 7.67139 1.62305 7.89403 1.62305 8.16718V16.5513C1.62305 16.8244 1.84569 17.0471 2.11884 17.0471H17.5533C17.8264 17.0471 18.0491 16.8244 18.0491 16.5513V8.16718C18.0491 7.89407 17.8264 7.67139 17.5533 7.67139ZM10.2886 9.52367H14.8743C14.9455 9.52367 15.0032 9.58138 15.0032 9.65251C15.0032 9.72364 14.9455 9.78136 14.8743 9.78136H10.2886C10.2174 9.78136 10.1597 9.72364 10.1597 9.65251C10.1597 9.58138 10.2174 9.52367 10.2886 9.52367ZM5.82645 8.66195C6.66961 8.66195 7.35506 9.34842 7.35506 10.1906C7.35506 10.977 6.71293 12.1428 5.82645 12.1428C4.94001 12.1428 4.29887 10.977 4.29887 10.1906C4.29887 9.34846 4.98431 8.66195 5.82645 8.66195ZM8.86511 15.3969C8.86511 15.7607 8.57031 16.0555 8.20649 16.0555H3.44645C3.08365 16.0555 2.78783 15.7607 2.78783 15.3969V14.963C2.78783 13.6354 3.86807 12.5551 5.19568 12.5551H6.4573C7.78491 12.5551 8.86515 13.6354 8.86515 14.963L8.86511 15.3969ZM16.7555 15.1949H10.2886C10.2174 15.1949 10.1597 15.1372 10.1597 15.066C10.1597 14.9949 10.2174 14.9372 10.2886 14.9372H16.7555C16.8266 14.9372 16.8844 14.9949 16.8844 15.066C16.8843 15.1371 16.8266 15.1949 16.7555 15.1949ZM10.1597 13.2612C10.1597 13.1901 10.2174 13.1323 10.2886 13.1323H14.8743C14.9455 13.1323 15.0032 13.1901 15.0032 13.2612C15.0032 13.3323 14.9455 13.39 14.8743 13.39H10.2886C10.2174 13.39 10.1597 13.3323 10.1597 13.2612ZM16.7555 11.5862H10.2886C10.2174 11.5862 10.1597 11.5284 10.1597 11.4573C10.1597 11.3862 10.2174 11.3285 10.2886 11.3285H16.7555C16.8266 11.3285 16.8844 11.3862 16.8844 11.4573C16.8844 11.5284 16.8266 11.5862 16.7555 11.5862Z"
          fill={fill}
        />
        <path
          d="M17.7611 5.16943H11.9075V6.03424H11.9951C12.2085 6.03424 12.3817 6.20743 12.3817 6.42077C12.3817 6.63411 12.2085 6.8073 11.9951 6.8073H7.67629C7.46294 6.8073 7.28975 6.63411 7.28975 6.42077C7.28975 6.20743 7.46294 6.03424 7.67629 6.03424H7.76391V5.16943H1.9103C1.1506 5.16943 0.533203 5.7879 0.533203 6.54653V16.7592C0.533203 17.5189 1.1506 18.1363 1.9103 18.1363H17.7612C18.5209 18.1363 19.1383 17.5189 19.1383 16.7592V6.54653C19.1382 5.7879 18.5208 5.16943 17.7611 5.16943ZM18.3064 16.551C18.3064 16.9664 17.9683 17.3045 17.5529 17.3045H2.1185C1.70309 17.3045 1.36501 16.9664 1.36501 16.551V8.16683C1.36501 7.75142 1.70309 7.41335 2.1185 7.41335H17.5529C17.9683 7.41335 18.3064 7.75142 18.3064 8.16683V16.551Z"
          fill={fill}
        />
        <rect x="10.1172" y="8.54004" width="6.85902" height="6.85902" fill={fill} />
        <path
          d="M15.8319 10.8262L13.4741 13.1125L12.4023 12.0733"
          stroke="url(#paint0_linear_2043_13562)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2043_13562"
          x1="13.004"
          y1="9.87353"
          x2="15.9949"
          y2="10.3217"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={checkmarkStartColor} />
          <stop offset="1" stopColor={checkmarkStopColor} />
        </linearGradient>
        <clipPath id="clip0_2043_13562">
          <rect width={width} height={height} fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PermitApprovedIcon;
