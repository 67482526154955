import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form, Input } from "antd";
import { CloseIcon } from "components/Icons";
import CircleCheck from "components/Icons/CircleCheck";
import GradientButton from "components/Buttons/GradientButton";

import styles from "./CreatePassword.module.less";

const CreatePassword = ({ savePassword }) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation(["join", "form_labels"]);

  const [passwordForm] = Form.useForm();
  const [passwordRules, setPasswordRules] = useState([]);

  useEffect(() => {
    passwordValidation();
  }, [i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  const regexValidator = (regex) => {
    return regex.test(passwordForm.getFieldValue("password"));
  };

  const passwordValidation = () => {
    setPasswordRules([
      {
        id: 1,
        rulePassed: regexValidator(/^.{8,}$/),
        label: t("join_form.char_count"),
      },
      {
        id: 2,
        rulePassed: regexValidator(/(?=.*?[A-Z])/),
        label: t("join_form.capital_letter"),
      },
      {
        id: 3,
        rulePassed: regexValidator(/(?=.*?[0-9])/),
        label: t("join_form.one_number"),
      },
    ]);
  };

  const onPasswordSubmit = (v) => {
    const formValues = { ...v };
    savePassword({
      ...formValues,
    });
  };

  return (
    <div className={styles.verificationContainer}>
      <div className={styles.verificationLabel}>{t("join_form.create_password")}</div>
      <Form onFinish={onPasswordSubmit} form={passwordForm} data-test-id="password-form">
        <Form.Item
          className={styles.passwordInput}
          initialValue=""
          validateTrigger="onSubmit"
          validateFirst
          name="password"
          rules={[
            {
              required: true,
              message: t("form_labels:enter_your_password"),
            },
            {
              min: 8,
              message: t("join_form.char_min"),
            },
            {
              pattern: /(?=.*?[A-Z])/,
              message: t("join_form.require_capital"),
            },
            {
              pattern: /(?=.*?[0-9])/,
              message: t("join_form.require_number"),
            },
          ]}
        >
          <Input.Password size="large" onChange={passwordValidation} placeholder={t("join_form.new_password")} />
        </Form.Item>
        <div className={styles.passwordRuleList}>
          {passwordRules.map((rule) => (
            <div key={rule.label} className={styles.passwordRule}>
              {rule.rulePassed ? (
                <div className={styles.validationIcon}>
                  <CircleCheck height="15" width="15" fill="var(--picton-blue)" />
                </div>
              ) : (
                <div className={styles.validationIcon}>
                  <CloseIcon startFill="var(--spanish-gray)" stopFill="var(--spanish-gray)" height="15" width="15" />
                </div>
              )}
              <div className={styles.validationLabel}>{rule.label}</div>
            </div>
          ))}
        </div>

        <Form.Item
          className={styles.passwordRepeat}
          name="passwordConfirm"
          dependencies={["password"]}
          validateTrigger={["onSubmit"]}
          rules={[
            {
              required: true,
              message: t("form_labels:password_confirmation"),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("form_labels:passwords_do_not_match")));
              },
            }),
          ]}
        >
          <Input.Password placeholder={t("join_form.repeat_password")} visibilityToggle={false} size="large" />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsValue }) => {
            const { password, passwordConfirm } = getFieldsValue();
            const match = password === passwordConfirm;
            const formIsComplete = !!passwordConfirm && !!password && match;
            return (
              <GradientButton
                type="primary"
                htmlType="submit"
                className={styles.passwordSubmit}
                disabled={!formIsComplete}
              >
                {t("join_form.continue")}
              </GradientButton>
            );
          }}
        </Form.Item>
      </Form>
    </div>
  );
};

CreatePassword.propTypes = {
  savePassword: PropTypes.func,
};

export default CreatePassword;
