import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "antd";
import { ContactsOutlined, PlusCircleOutlined } from "@ant-design/icons";
import ModalContainer from "components/Modals/ModalContainer";
import FormErrorMessage from "components/FormElements/FormErrorMessage";
import useModalControls from "hooks/useModalControls";
import { toSnakeCase } from "lib/helpers";
import styles from "../../Users.module.less";

const AddPermissionModal = (props) => {
  const { addNewPermission } = props;
  const { visible, toggle, close } = useModalControls();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        addNewPermission(values);
      })
      .finally(() => close());
  };

  return (
    <>
      <Button
        className={styles.addRoleButton}
        style={{ marginTop: 0 }}
        type="info"
        htmlType="button"
        icon={<PlusCircleOutlined className={styles.blue} />}
        onClick={toggle}
      >
        Add New Permission
      </Button>
      <ModalContainer
        visible={visible}
        title="Add New Permission"
        headerText={<ContactsOutlined className={styles.blue} />}
        handleCancel={close}
        handleSubmit={handleSubmit}
      >
        <Form form={form} layout="vertical" requiredMark={false} className={styles.addRoleForm}>
          <Form.Item
            name="name"
            className={styles.formItem}
            label={<span className={styles.label}>Name</span>}
            normalize={toSnakeCase}
            rules={[
              {
                required: true,
                message: <FormErrorMessage message="Name is required." />,
              },
            ]}
          >
            <Input className={styles.input} />
          </Form.Item>
          <Form.Item
            name="description"
            className={styles.formItem}
            label={<span className={styles.label}>Description</span>}
          >
            <Input className={styles.input} />
          </Form.Item>
        </Form>
      </ModalContainer>
    </>
  );
};

AddPermissionModal.propTypes = {
  addNewPermission: PropTypes.func,
};

export default AddPermissionModal;
