import React from "react";
import { buttonProps } from "lib/props";

type TagT = {
  title: string | number | React.ReactNode;
  style: Record<string, unknown>;
  onClick: () => void;
};

function Tag(props: TagT) {
  const { title, style, onClick } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "var(--seagull-blue)",
        color: "white",
        borderColor: "var(--seagull-blue)",
        fontSize: "14px",
        padding: "0px 8px",
        ...style,
      }}
      {...buttonProps(onClick)}
    >
      <span>{title}</span>
    </div>
  );
}

export default Tag;
