import React from "react";
import { useHistory } from "react-router-dom";
import { Layout } from "antd";
// import { Layout, Button } from "antd";
import { buttonProps } from "lib/props";
import HeaderLogo from "components/Icons/Logos/HeaderLogo";
import styles from "../../Installerjoin.module.less";

const { Header } = Layout;

const LoginHeader = () => {
  const history = useHistory();
  const backToHomepage = () => {
    history.push("/");
  };

  return (
    <Header className={styles.header}>
      <div className={styles.innerContainer}>
        <div className={styles.logo} {...buttonProps(backToHomepage)}>
          <HeaderLogo hoverFill="white" />
        </div>
      </div>
    </Header>
  );
};

export default LoginHeader;
