import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/adminReports/types";
import getMessagingMetrics from "rdx/modules/adminReports/sagas/getMessagingMetrics";
import getSellerReport from "rdx/modules/adminReports/sagas/getSellerReport";
import getActivityLog from "rdx/modules/adminReports/sagas/getActivityLog";
import getUsersProjectsAutoComplete from "./getUsersProjectsAutoComplete";
import getActivityLogCsv from "./getActivityLogCsv";
import getPreferredPartnerCsv from "./getPreferredPartnerCsv";
import getPreferredPartnerCsvReport from "./getPreferredPartnerCsvReport";
import getTicketList from "./getTicketList";
// IMPORT_PT (for script, do not remove)

function* watchMessagingMetricsSagas() {
  yield trackRequests(takeEvery, types.GET_MESSAGING_METRICS, getMessagingMetrics);
  yield trackRequests(takeEvery, types.GET_TICKET_LIST, getTicketList);
  yield trackRequests(takeEvery, types.GET_CONSULTANT_REPORT, getSellerReport);
  yield trackRequests(takeEvery, types.GET_ACTIVITY_LOG, getActivityLog);
  yield trackRequests(takeEvery, types.GET_USERS_PROJECTS_AUTOCOMPLETE, getUsersProjectsAutoComplete);
  yield trackRequests(takeEvery, types.GET_ACTIVITY_LOG_CSV, getActivityLogCsv);
  yield trackRequests(takeEvery, types.GET_PREFERRED_PARTNER_CSV, getPreferredPartnerCsv);
  yield trackRequests(takeEvery, types.GET_PREFERRED_PARTNER_CSV_REPORT, getPreferredPartnerCsvReport);
  // INSERTION_PT (for script, do not remove)
}

export default watchMessagingMetricsSagas;
