import { cloneDeep } from "lodash";
import { ProjectActivityCrmDataT } from "rdx/modules/projects/types/projects/pre-sales";

type ActivityLogT = {
  historicalData: ProjectActivityCrmDataT[];
  priorityData: ProjectActivityCrmDataT[];
};

type FormatCrmActivityLogPropsT = {
  activityId: number;
  activityLog: ActivityLogT;
  updatedActivity?: ProjectActivityCrmDataT;
};

const formatCrmActivityLog = ({ activityId, activityLog, updatedActivity }: FormatCrmActivityLogPropsT) => {
  const { priorityData, historicalData } = activityLog;

  if (!priorityData.length && !historicalData.length) {
    return { historicalData: [], priorityData: [] };
  }

  // get existing activity lists
  const priorityListData: ProjectActivityCrmDataT[] = cloneDeep(priorityData);
  const historicalListData: ProjectActivityCrmDataT[] = cloneDeep(historicalData);

  // make sure activity is removed from each list
  const updatedPriorityData: ProjectActivityCrmDataT[] = priorityListData.filter(
    (activity) => activity.id !== activityId,
  );
  const updatedHistoricalData: ProjectActivityCrmDataT[] = historicalListData.filter(
    (activity) => activity.id !== activityId,
  );

  // if activity is deleted, update lists and return
  if (!updatedActivity) {
    return { historicalData: updatedHistoricalData, priorityData: updatedPriorityData };
  }

  if (updatedActivity.completeType) {
    // if activity is complete add to historical list
    updatedHistoricalData.unshift(updatedActivity);
  } else {
    // if activity is not complete add to priority list
    updatedPriorityData.unshift(updatedActivity);
  }

  const sortListByDate = (activityList: ProjectActivityCrmDataT[]) => {
    return activityList.sort((a, b) => Date.parse(a.completedAt || a.dueDate) - Date.parse(b.completedAt || b.dueDate));
  };

  return {
    historicalData: sortListByDate(updatedHistoricalData),
    priorityData: sortListByDate(updatedPriorityData),
  };
};

export default formatCrmActivityLog;
