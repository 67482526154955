import leadsActions from "rdx/modules/leads/actions";
import activeRequestsActions from "rdx/modules/activeRequests/actions";
import appActions from "rdx/modules/app/actions";
import messagesActions from "rdx/modules/messages/actions";
import projectsActions from "rdx/modules/projects/actions";
import inboxActions from "rdx/modules/tickets/actions";
import usersActions from "rdx/modules/users/actions";
import teamActions from "rdx/modules/team/actions";
import rbacActions from "rdx/modules/rbac/actions";
import adminProjectsActions from "rdx/modules/adminProjects/actions";
import workflowsActions from "rdx/modules/workflows/actions";
import ambassadorActions from "rdx/modules/ambassador/actions";
import profileActions from "rdx/modules/profile/actions";
import reportsActions from "rdx/modules/reports/actions";
import admindashboardActions from "rdx/modules/adminDashboard/actions";
import consultantDashboardActions from "rdx/modules/consultantDashboard/actions";
import helpjuiceActions from "rdx/modules/helpjuice/actions";
import settingsActions from "rdx/modules/settings/actions";
import adminNoticesActions from "rdx/modules/adminNotices/actions";
import adminSmsActions from "rdx/modules/adminSms/actions";
import earningsActions from "rdx/modules/earnings/actions";
import registrationActions from "rdx/modules/registration/actions";
import financialsActions from "rdx/modules/financials/actions";
import messagingmetricsActions from "rdx/modules/adminReports/actions";
import adminProposalsActions from "rdx/modules/adminProposals/actions";
import mentorratingsActions from "rdx/modules/mentorRatings/actions";
import workSchedulesActions from "rdx/modules/workSchedules/actions";
import bypassPaywallActions from "rdx/modules/bypassPaywall/actions";
import careerListingsActions from "rdx/modules/careerListings/actions";
import stateLicensesActions from "rdx/modules/stateLicenses/actions";
import authActions from "rdx/modules/auth/actions";
import filterSetsActions from "rdx/modules/filterSets/actions";
import compensationActions from "rdx/modules/compensation/actions";
import platformActions from "rdx/modules/platform/actions";
import enterpriseProjectsActions from "rdx/modules/enterpriseProjects/actions";
import enterpriseOrgActions from "rdx/modules/enterpriseOrg/actions";
import enterpriseLeadsActions from "rdx/modules/enterpriseLeads/actions";
import enterprisePersonnelActions from "rdx/modules/enterprisePersonnel/actions";
import enterpriseEarningsActions from "rdx/modules/enterpriseEarnings/actions";
import enrollmentActions from "rdx/modules/enrollment/actions";
import enterpriseApplicationsActions from "rdx/modules/enterpriseApplications/actions";
import buildPartnerApplicationsActions from "rdx/modules/buildPartnerApplications/actions";
import complianceIssuesActions from "rdx/modules/complianceIssues/actions";
import adminLeadsActions from "rdx/modules/adminLeads/actions";
import dashboardPromotionsActions from "rdx/modules/dashboardPromotions/actions";
import dashboardUpdatesActions from "rdx/modules/dashboardUpdates/actions";
import seatapprovalsActions from "rdx/modules/seatApprovals/actions";
import enterpriseProLaborFormActions from "rdx/modules/enterpriseProLaborForm/actions";
import notificationsActions from "rdx/modules/notifications/actions";
import activateAcctBtnClicked from "rdx/modules/activateAcctBtnClicked/actions";
import viewedAssetsActions from "rdx/modules/viewedAssets/actions";
import preferredpartnersActions from "rdx/modules/preferredPartners/actions";
import platformModulesActions from "rdx/modules/platformModules/actions";
import roofingProposalsActions from "rdx/modules/roofing/actions";
import homeownerActions from "rdx/modules/homeowner/actions";
import crmActivityActions from "rdx/modules/crmActivities/actions";
import salesTeamsActions from "rdx/modules/salesTeams/actions";
import sellerIntegrity from "rdx/modules/sellerIntegrity/actions";
import publicApiProxy from "rdx/modules/publicApiProxy/actions";
import projectpresalesActions from "rdx/modules/projectPreSales/actions";
import fundingProposals from "rdx/modules/fundingProposals/actions";
import fileuploadActions from "rdx/modules/fileUpload/actions";
import customFieldsActions from "rdx/modules/customFields/actions";
// IMPORT_PT (for script, do not remove)

const actions = {
  ...leadsActions,
  ...activeRequestsActions,
  ...appActions,
  ...messagesActions,
  ...projectsActions,
  ...inboxActions,
  ...usersActions,
  ...teamActions,
  ...rbacActions,
  ...adminProjectsActions,
  ...workflowsActions,
  ...ambassadorActions,
  ...profileActions,
  ...admindashboardActions,
  ...consultantDashboardActions,
  ...helpjuiceActions,
  ...reportsActions,
  ...settingsActions,
  ...adminNoticesActions,
  ...adminSmsActions,
  ...earningsActions,
  ...registrationActions,
  ...financialsActions,
  ...messagingmetricsActions,
  ...adminProposalsActions,
  ...mentorratingsActions,
  ...workSchedulesActions,
  ...bypassPaywallActions,
  ...careerListingsActions,
  ...stateLicensesActions,
  ...authActions,
  ...filterSetsActions,
  ...compensationActions,
  ...platformActions,
  ...enterpriseProjectsActions,
  ...enterpriseOrgActions,
  ...enterpriseLeadsActions,
  ...enterprisePersonnelActions,
  ...enterpriseEarningsActions,
  ...enrollmentActions,
  ...enterpriseApplicationsActions,
  ...buildPartnerApplicationsActions,
  ...complianceIssuesActions,
  ...adminLeadsActions,
  ...dashboardPromotionsActions,
  ...dashboardUpdatesActions,
  ...seatapprovalsActions,
  ...enterpriseProLaborFormActions,
  ...notificationsActions,
  ...viewedAssetsActions,
  ...activateAcctBtnClicked,
  ...preferredpartnersActions,
  ...platformModulesActions,
  ...roofingProposalsActions,
  ...homeownerActions,
  ...crmActivityActions,
  ...salesTeamsActions,
  ...sellerIntegrity,
  ...publicApiProxy,
  ...projectpresalesActions,
  ...fundingProposals,
  ...fileuploadActions,
  ...customFieldsActions,
  // INSERTION_PT (for script, do not remove)
};

export default actions;
