import { put, all, select } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import adminProjectsSelectors from "rdx/modules/adminProjects/selectors";

import actions from "rdx/actions";
import { keysToSnakeCase, camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* updateProject(action) {
  const { projectId } = action.payload;

  const params = {
    contract_at: action.payload?.contract_at,
    permit_submit_at: action.payload?.permit_submit_at,
    install_at: action.payload?.install_at,
    net_revenue: action.payload?.net_revenue,
    m1_at: action.payload?.m1_at,
    m2_at: action.payload?.m2_at,
    m3_at: action.payload?.m3_at,
    proposal_url: action.payload?.proposalUrl,
    team_id: action.payload?.teamId,
    ...(action.payload?.projectUpdate && {
      project_update: keysToSnakeCase(action.payload?.projectUpdate),
    }),
    utility_bill_url: action.payload?.utilityBillUrl,
    notes: action.payload?.notes,
    duplicate_podio_uids: action.payload?.duplicatePodioUids,
    proposal_locked_at: action.payload?.proposalLockedAt,
    proposal_presented: action.payload?.proposalPresented,
    contract_out: action.payload?.contractOut,
    site_info: action.payload?.siteInfo && keysToSnakeCase(action.payload?.siteInfo),
    agent_id: action.payload?.agentId,
    mentor_id: action.payload?.mentorId,
    existing_system: action.payload?.existingSystem && keysToSnakeCase(action.payload?.existingSystem),
  };

  const { success, data, error } = yield* makeRequest.patch(`/projects/${projectId}`, params);
  if (success && data) {
    const out = camelizeKeys(_.clone(data.body));
    const adminListProjects = yield select(adminProjectsSelectors.getAdminProjectsList);
    const { projects: adminProjects } = adminListProjects;

    const newAdminProjects = adminProjects.map((project) => {
      if (project.id === projectId) {
        return { ...project, ...out };
      }
      return project;
    });

    yield all([
      put(actions.setAdminProjectDetails(out)),
      put(actions.setAdminProjectsList({ ...adminProjects, projects: newAdminProjects })),
      put(actions.newSuccessEvent({ message: messages.PROJECT_UPDATE_SUCCESS })),
    ]);
  } else {
    yield all([put(actions.newErrorEvent({ error })), put(actions.setFormErrors({ error }))]);
  }
  return null;
}

export default updateProject;
