import React from "react";
import PropTypes from "prop-types";

const PlatformUpdateIcon = (props) => {
  const { iconColor, circleColor, height, width, containerStyles } = props;

  return (
    <div style={{ paddingTop: "3px", ...containerStyles }}>
      <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill={circleColor} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0428 11.6201H27.9568C28.5778 11.6201 29.0857 12.1284 29.0857 12.7495V24.8815H10.9139V12.7495C10.9139 12.1284 11.4217 11.6201 12.0428 11.6201ZM8.82617 25.8228H31.1738V26.4991C31.1738 27.5342 30.327 28.3809 29.292 28.3809H10.7078C9.67313 28.3809 8.82637 27.5342 8.82637 26.4991L8.82617 25.8228ZM17.0304 17.1659V15.4086H18.7877L17.0304 17.1659ZM15.7896 18.4078L17.0323 19.6505V17.1652L15.7896 18.4078ZM18.7881 15.4083L20.0307 14.1657L21.2733 15.4083H18.7881ZM20.0307 22.6514L21.2733 21.4087H18.7881L20.0307 22.6514ZM20.0331 20.1664V18.4081H21.7914L20.0331 20.1664ZM20.0328 18.4067V16.6494L18.2755 18.4067H20.0328ZM18.7877 21.4093H17.0304V19.6511L18.7877 21.4093ZM23.0322 21.4093V19.6511L21.2739 21.4093H23.0322ZM23.0322 17.1658L24.2742 18.4078L23.0316 19.6505V17.1653L21.2739 15.4086H23.0322V17.1658Z"
          fill={iconColor}
        />
      </svg>
    </div>
  );
};

PlatformUpdateIcon.propTypes = {
  iconColor: PropTypes.string,
  circleColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

PlatformUpdateIcon.defaultProps = {
  iconColor: "white",
  circleColor: "var(--blue-ribbon)",
  height: 40,
  width: 40,
  containerStyles: {},
};

export default PlatformUpdateIcon;
