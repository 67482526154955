import component from "containers/StaticPages/createAccount";
// import l from "@loadable/component";

// const component = l(() => import("containers/StaticPages/join"));

export default {
  key: "user-join",
  title: "Create Account",
  path: "/:user?/signup/:slug?",
  pageName: "signup",
  linkTarget: "/signup",
  component,
};
