import React from "react";
import { cvar } from "styles";

type ProposalRequestedIconPropsT = {
  height?: number;
  width?: number;
  fill?: string;
  startColor?: string;
  stopColor?: string;
};

const ProposalRequestedIcon = ({
  height = 20,
  width = 20,
  fill = cvar("white"),
  startColor = cvar("picton-blue"),
  stopColor = cvar("blue-ribbon"),
}: ProposalRequestedIconPropsT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6.04883" y="1.48242" width="8.88441" height="12.5745" rx="1" fill={fill} />
      <path
        d="M12.4062 3.70703H13.5971"
        stroke="url(#paint0_linear_227_12091)"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M7.38477 3.70703H8.57564"
        stroke="url(#paint1_linear_227_12091)"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <circle cx="10.4148" cy="3.70674" r="1.07491" stroke="url(#paint2_linear_227_12091)" strokeWidth="0.5" />
      <path
        d="M7.93359 5.83789H12.9841"
        stroke="url(#paint3_linear_227_12091)"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M7.93359 6.92578H12.9841"
        stroke="url(#paint4_linear_227_12091)"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M7.93359 8.01367H12.9841"
        stroke="url(#paint5_linear_227_12091)"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M7.93359 9.10254H12.9841"
        stroke="url(#paint6_linear_227_12091)"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M7.93359 10.1914H12.9841"
        stroke="url(#paint7_linear_227_12091)"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M7.93359 11.2783H12.9841"
        stroke="url(#paint8_linear_227_12091)"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M7.93359 12.3672H12.9841"
        stroke="url(#paint9_linear_227_12091)"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M18.1023 5.59961V16.434H3.02415L4.33709 15.1107L3.50487 14.2578L0.71875 17.0646L3.50487 19.8766L4.33709 19.0185L2.96729 17.6384H19.2808V5.59961H18.1023Z"
        fill={fill}
      />
      <path d="M19.2801 0.787109H18.1016V1.9915H19.2801V0.787109Z" fill={fill} />
      <path d="M19.2801 3.19531H18.1016V4.3997H19.2801V3.19531Z" fill={fill} />
      <defs>
        <linearGradient
          id="paint0_linear_227_12091"
          x1="11.427"
          y1="3.27932"
          x2="12.8431"
          y2="2.97947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_227_12091"
          x1="7.59369"
          y1="3.29036"
          x2="9.33476"
          y2="1.72288"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_227_12091"
          x1="9.34697"
          y1="2.92492"
          x2="12.5545"
          y2="2.45654"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_227_12091"
          x1="8.81965"
          y1="5.42123"
          x2="16.0476"
          y2="6.72926"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_227_12091"
          x1="8.81965"
          y1="6.50911"
          x2="12.4095"
          y2="8.32014"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint5_linear_227_12091"
          x1="8.81965"
          y1="7.59701"
          x2="12.4095"
          y2="9.40803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint6_linear_227_12091"
          x1="8.81965"
          y1="8.68587"
          x2="12.4095"
          y2="10.4969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint7_linear_227_12091"
          x1="8.81965"
          y1="9.77474"
          x2="12.4095"
          y2="11.5858"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint8_linear_227_12091"
          x1="8.81965"
          y1="10.8617"
          x2="12.4095"
          y2="12.6727"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint9_linear_227_12091"
          x1="8.81965"
          y1="11.9505"
          x2="12.4095"
          y2="13.7615"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProposalRequestedIcon;
