import { put, all } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* updateProjectMilestones(action) {
  const { projectId, milestones } = action.payload;

  const params = {
    milestones,
  };
  const { success, data, error } = yield* makeRequest.patch(`/projects/${projectId}/milestones`, params);
  if (success && data) {
    const out = keysToCamelCase(_.clone(data.body));
    out.data = out.data.map((d) => keysToCamelCase(d));
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.PROJECT_MILESTONES_UPDATE_SUCCESS,
        }),
      ),
      put(actions.setProjectMilestones(out)),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_PROJECT_MILESTONES,
    });
  }
  return null;
}

export default updateProjectMilestones;
