export default {
  CREATE_FUNDING_PROPOSAL_REQUEST: "[fundingProposals] CREATE Funding Proposal",
  GET_ADMIN_FUNDING_PROPOSALS: "[fundingProposals] GET Admin Funding Proposal List",
  SET_ADMIN_FUNDING_PROPOSALS: "[fundingProposals] SET Admin Funding Proposal List & Paging",
  RESET_FUNDING_PROPOSAL_DATA: "[fundingProposals] RESET Admin Funding Proposal List",
  UPDATE_FUNDING_PROPOSAL_REQUEST: "[fundingProposals] UPDATE Funding Proposal",
  GET_FUNDING_USERS_AUTOCOMPLETE: "[fundingProposals] GET Funding Users Autocomplete",
  SET_FUNDING_USERS_AUTOCOMPLETE: "[fundingProposals] SET Funding Users Autocomplete",
  // INSERTION_PT (for script, do not remove)
};
