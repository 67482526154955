import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../../messages";
import { formatDataGroups, formatMilestoneOptions } from "./util";

function* getDataGroups() {
  const { success, data, error } = yield* makeRequest.get("/data_groups", {});
  if (success && data) {
    yield all([
      put(actions.setDataGroups(formatDataGroups(data))),
      put(actions.setMilestoneOptions(formatMilestoneOptions(data))),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_DATA_GROUPS,
    });
  }
  return null;
}

export default getDataGroups;
