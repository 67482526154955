import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const CheckWithShadowIcon = ({ height = "15", width = "14", fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_30_2999)">
        <path
          d="M11.6666 1.5L5.24998 7.91667L2.33331 5"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_30_2999"
          x="-2.66669"
          y="0.5"
          width="19.3333"
          height="16.4167"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_2999" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_2999" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
export default CheckWithShadowIcon;
