import { put, select, all } from "redux-saga/effects";
import rbacSelectors from "rdx/modules/rbac/selectors";
import selectors from "rdx/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
// import messages from "rdx/modules/inbox/messages";

function* markMessageAndRepliesAsViewed(action) {
  const { messageId } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/messages/${messageId}/viewed`);
  if (success && data) {
    if (data) {
      const selectedMessage = yield select(selectors.getSelectedMessage);
      const inboxList = yield select(selectors.getInboxList);
      const newInboxList = inboxList.map((message) =>
        action.payload.parentIds && message.id === action.payload.parentIds[0]
          ? {
              ...message,
              counters: {
                ...message.counters,
                viewedCount: selectedMessage.counters.childCount,
              },
            }
          : message,
      );
      const currentInboxUnviewedMessagesCount = yield select(rbacSelectors.getInboxUnviewedMessagesCount);
      const newInboxUnviewedMessagesCount = Math.max(0, currentInboxUnviewedMessagesCount - 1);
      yield all([
        put(actions.updateInboxUnviewedMessagesCount(newInboxUnviewedMessagesCount)),
        put(actions.setInboxList({ list: newInboxList })),
      ]);
    } else if (action.payload.archived) {
      const currentInboxUnviewedArchivedCount = yield select(rbacSelectors.getInboxUnviewedArchivedCount);
      const newInboxUnviewedArchivedCount = Math.max(0, currentInboxUnviewedArchivedCount - 1);
      yield put(actions.updateInboxUnviewedArchivedCount(newInboxUnviewedArchivedCount));
    }
  } else if (error) {
    // ditto.
    return getErrorActions({
      error,
      // message: "",
      // payload: {},
    });
  }

  return null;
}

export default markMessageAndRepliesAsViewed;
