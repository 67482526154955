import { styled } from "@mui/material/styles";
import { theme } from "styles/themes";
import { mq } from "styles";

export const RequestCompleteContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const NoteHeader = styled("div")(() => ({
  color: theme.colors["blue-ribbon"],
  fontSize: "24px",
  fontWeight: 500,
  fontFamily: "Poppins",
  marginBottom: "15px",
  [mq.tabletUp]: {
    paddingRight: "70px",
  },
}));

export const NoteDetails = styled("div")(() => ({
  color: theme.colors["dark-blue"],
  fontSize: "16px",
  lineHeight: "19px",
  fontWeight: 500,
  marginBottom: "18px",
  [mq.tabletUp]: {
    paddingRight: "70px",
  },
}));
