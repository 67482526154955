import { select, put, all } from "redux-saga/effects";
import { keysToSnakeCase, camelizeKeys } from "lib/helpers";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import selectors from "rdx/selectors";
import actions from "rdx/actions";
import messages from "../messages";

function* updateCustomField(action) {
  const { params } = keysToSnakeCase(action.payload);
  const { enterpriseId, id } = action.payload;

  const { success, data, error } = yield* makeRequest.patch(
    `/enterprises/${enterpriseId}/lead_custom_fields/${id}`,
    params,
  );

  if (success && data) {
    const customFields = yield select(selectors.selectCustomFields);

    const updatedCustomFields = customFields.map((item) => {
      if (item.id === id) {
        return data.body;
      }
      return item;
    });

    yield all([
      yield put(actions.setCustomFields(camelizeKeys(updatedCustomFields))),
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_CUSTOM_FIELD_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.UPDATE_CUSTOM_FIELD_ERROR,
    });
  }
  return null;
}

export default updateCustomField;
