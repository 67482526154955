import { put, all, select } from "redux-saga/effects";
import selectors from "rdx/modules/tickets/selectors";
import actions from "rdx/actions";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* createNewProjectMessage(action) {
  const params = {
    ...action.payload,
  };

  const projectId = params.project_id;
  delete params.project_id;

  const { success, data, error } = yield* makeRequest.post(`/projects/${projectId}/messages/`, params);

  if (success && data) {
    const notices = yield select(selectors.getProjectMessageList);
    const replies = { ...(yield select(selectors.getProjectMessageReplies)) };
    const newNotice = keysToCamelCase(data.body);
    const newList = [newNotice, ...notices];
    if (newNotice.parentId) {
      replies[newNotice.parentId] = replies[newNotice.parentId]
        ? [newNotice, ...replies[newNotice.parentId]]
        : [newNotice];
      yield put(actions.setProjectMessageReplies({ replies }));
    }
    yield all([
      put(actions.setProjectMessageList({ list: newList })),
      put(
        actions.newSuccessEvent({
          message: messages.CREATE_PROJECT_MESSAGE_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_PROJECT_MESSAGE,
    });
  }
  return null;
}

export default createNewProjectMessage;
