import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "rdx/modules/enterpriseApplications/messages";

function* approveEnterpriseApplication(action) {
  const { id, values } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/enterprise_applications/${id}/application_responses`, {
    enterprise_application_id: id,
    decision: 1,
    ...values,
  });

  if (success && data) {
    const applicationDetail = yield select(selectors.selectEnterpriseApplicationDetail);

    yield all([
      put(
        actions.setEnterpriseApplicationDetail({
          ...applicationDetail,
          response: { ...applicationDetail.response, decision: "approved" },
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.APPROVE_ENTERPRISE_APPLICATION_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_APPROVING_ENTERPRISE_APPLICATION,
    });
  }

  return null;
}

export default approveEnterpriseApplication;
