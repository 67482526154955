// import { get } from "lodash";

export default {
  getPayPeriods: (state) => {
    return state.compensation.periodsData;
  },
  getPayments: (state) => state.compensation.paymentsData,
  getLedger: (state) => state.compensation.ledgerData,
  getDistributions: (state) => state.compensation.distributionsData,
  getLedgerCsv: (state) => state.compensation.ledgerCsvData,
  getUserOrLeadAutoComplete: (state) => state.compensation.userOrLeadAutoComplete,
  selectProjectPaymentsList: (state) => state.compensation.projectsCompensation,
  selectProjectRevenueSplit: (state) => state.compensation.projectRevenueSplit,
};
