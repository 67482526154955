import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import copy from "copy-to-clipboard";

import { selectors } from "rdx";
import { formatPhoneNumber } from "lib/helpers";
import UserAvatar from "components/UserCards/UserAvatar";
import MentorStars from "components/UserCards/Mentor/MentorStars";
import SubHeaderFilled from "components/PlatformLayout/components/SubHeaderFilled";
import { Tooltip } from "components/mui-styled-components/Tooltip";
import { T3PartnerT } from "components/Drawers/SelectT3PartnerDrawer/types";
import HomeownerApptForm from "./HomeownerApptForm";

import * as S from "./styles";

type AssignedT3PartnerApptFormPropsT =
  | {
      setIsCreateApptDisabled: (disabled: boolean) => void;
      homeownerApptSubmitRef: React.RefObject<HTMLButtonElement>;
      closeDrawer: () => void;
      hideAppointmentForm?: false;
    }
  | {
      setIsCreateApptDisabled?: never;
      closeDrawer?: never;
      homeownerApptSubmitRef?: never;
      hideAppointmentForm: true;
    };

type AssignedT3PartnerPropsT = {
  assignedPartner: T3PartnerT;
  rsmRequirementsPassed?: boolean;
} & AssignedT3PartnerApptFormPropsT;

const AssignedT3Partner = ({
  assignedPartner,
  closeDrawer,
  rsmRequirementsPassed = true,
  homeownerApptSubmitRef,
  setIsCreateApptDisabled,
  hideAppointmentForm = false,
}: AssignedT3PartnerPropsT) => {
  const currentUser = useSelector(selectors.getCurrentUser);
  const currentUserIsAssignedPartner = currentUser.id === assignedPartner.id;
  const showAppointmentForm =
    !currentUserIsAssignedPartner && !hideAppointmentForm && !!closeDrawer && !!homeownerApptSubmitRef;
  const [t3SpokenLanguages, setT3SpokenLanguages] = useState<string[]>([]);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [tooltipText, setTooltipText] = useState<string>("");

  useEffect(() => {
    if (assignedPartner?.spokenLanguages?.length) {
      const languages = assignedPartner?.spokenLanguages?.map((lang) => {
        return lang === "en" ? "English" : "Spanish";
      });
      setT3SpokenLanguages(languages);
    }
  }, [assignedPartner?.spokenLanguages]);

  const handleCopyEmail = () => {
    setTooltipText(`${assignedPartner.email} copied to clipboard`);
    copy(assignedPartner.email);
    setTooltipVisible(true);
    setTimeout(() => setTooltipVisible(false), 3000);
  };

  const subHeaderText = () => {
    if (currentUserIsAssignedPartner) {
      return `You Have Been Selected as the ${
        rsmRequirementsPassed ? "Closer" : "Regional Sales Manager"
      } on this Project`;
    }
    if (!rsmRequirementsPassed) {
      return "Your Regional Sales Manager";
    }
    return "Your Closer";
  };

  return (
    <S.AssignedPartnerContainer>
      <SubHeaderFilled title={subHeaderText()} />
      <S.AssignedPartnerCard container>
        <S.UserAvatarContainer item xs={4} md={2}>
          <UserAvatar user={assignedPartner} size={108} />
        </S.UserAvatarContainer>
        <Grid item xs={6} sm={4}>
          <S.PartnerName>{assignedPartner.fullName}</S.PartnerName>
          <S.PartnerLocation>
            {assignedPartner.address?.city}, {assignedPartner.address?.state}
          </S.PartnerLocation>
          <MentorStars
            average={assignedPartner.mentorRatings?.meanOfAvgs}
            count={assignedPartner.mentorRatings?.totalCount}
          />
          <S.PartnerLanguages>{t3SpokenLanguages.join(", ")}</S.PartnerLanguages>
        </Grid>
        <Grid item xs={5}>
          <S.PartnerContactLabel>Email</S.PartnerContactLabel>
          <Tooltip open={tooltipVisible} title={tooltipText} placement="top">
            <S.PartnerEmail onClick={handleCopyEmail}>{assignedPartner.email}</S.PartnerEmail>
          </Tooltip>

          <S.PartnerContactLabel>Phone Number</S.PartnerContactLabel>
          <S.PartnerContact>{formatPhoneNumber(assignedPartner.phone)}</S.PartnerContact>
        </Grid>
      </S.AssignedPartnerCard>
      {showAppointmentForm && (
        <HomeownerApptForm
          assignedPartner={assignedPartner}
          closeDrawer={closeDrawer}
          homeownerApptSubmitRef={homeownerApptSubmitRef}
          setIsCreateApptDisabled={setIsCreateApptDisabled}
        />
      )}
    </S.AssignedPartnerContainer>
  );
};

export default AssignedT3Partner;
