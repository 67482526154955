import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "rdx/modules/consultantDashboard/messages";

function* getProgressOverTime(action) {
  const { userId, timeSpan, isOrgDashboard } = action.payload;
  const endpoint = isOrgDashboard ? "/enterprise_dashboard" : "/consultant_dashboard";
  const payload = {
    user_id: userId,
    time_span: timeSpan,
  };
  const { success, data, error } = yield* makeRequest.get(`${endpoint}/progress_over_time`, payload);

  if (success && data) {
    yield all([put(actions.setSellerDashboard(camelizeKeys(data.body)))]);
  } else if (error) {
    const errorMessage = messages.ERROR_GETTING_DASHBOARD_SLICE;
    return getErrorActions({
      error,
      message: errorMessage,
    });
  }

  return null;
}

export default getProgressOverTime;
