const commonTabs = [
  {
    key: "periods",
    label: "Periods",
  },
  {
    key: "projects",
    label: "Projects",
  },
  {
    key: "ledger",
    label: "Ledger",
  },
  {
    key: "distributions",
    label: "Distributions",
  },
];

const adminTabs = [];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
