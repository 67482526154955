import { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import r from "containers/router/routes";

const { platformRoutes } = r;

type useGetCurrentRouteInfoT = {
  currentRoute: Record<string, unknown>;
  platformRoutes: Record<string, unknown>[];
  allRoutes: object;
};

const useGetCurrentRouteInfo = (): useGetCurrentRouteInfoT => {
  const [currentRoute, setCurrentRoute] = useState({});
  const location = useLocation();
  useEffect(() => {
    for (const route of platformRoutes) {
      const match = matchPath(location.pathname, route);
      if (match) {
        setCurrentRoute(route);
        break;
      }
    }
  }, [location]);

  return { currentRoute, platformRoutes, allRoutes: r };
};

export default useGetCurrentRouteInfo;
