import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/complianceIssues/messages";
import selectors from "rdx/modules/complianceIssues/selectors";
import { camelizeKeys } from "lib/helpers";

function* updateComplianceIssue(action) {
  const { occurredAt, description, complianceIssueId } = action.payload;

  const { success, data, error } = yield* makeRequest.patch(`/compliance_incidents/${action.payload.id}`, {
    occurred_at: occurredAt,
    description,
    compliance_issue_id: complianceIssueId,
  });

  if (success && data) {
    const list = yield select(selectors.selectComplianceIncidents);
    const clone = [...list];
    const idx = clone.findIndex((incident) => {
      return incident.id === action.payload.id;
    });
    clone[idx] = camelizeKeys(data.body);
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_COMPLIANCE_ISSUE_SUCCESS,
        }),
      ),
      put(actions.setComplianceIssues({ incidents: clone })),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_COMPLIANCE_ISSUE,
    });
  }

  return null;
}

export default updateComplianceIssue;
