import React, { useRef, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { useController } from "react-hook-form";
import { Dayjs } from "dayjs";
import { useSmartFormContext } from "components/SmartForm";
import { RightCaretFilled, LeftCaretFilled } from "components/Icons";
import { CSSInterpolation } from "@emotion/css";
import { SerializedStyles } from "@emotion/react";
import ActionBarComponent from "./ActionBar";
import ToolBarComponent from "./toolBar";
import HelperText from "../HelperText";
import { sxStyle } from "./styles";

export type TSmartFormDatePicker = {
  renderInput: (timeStamp?: string | Dayjs) => JSX.Element | React.ReactNode;
  onSubmit: (selectedTimeStamp: string) => void;
  name: string;
  displayValue: string;
  hideTime?: boolean;
  disabled?: boolean;
  styleOverrides?: CSSInterpolation | SerializedStyles;
  error?: boolean;
  helperText?: string | undefined;
  onSelectDate?: (val: string) => void;
  shouldDisableDate?: (val?: Dayjs) => boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  minDate?: Dayjs;
  popperPlacement?:
    | "auto-end"
    | "auto-start"
    | "auto"
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
};

const SmartFormDatePicker = ({
  name,
  onSubmit,
  renderInput,
  disabled = false,
  hideTime = false,
  error,
  helperText,
  onSelectDate,
  shouldDisableDate = () => false,
  popperPlacement = "auto",
  disableFuture = false,
  disablePast = false,
  minDate,
}: TSmartFormDatePicker) => {
  const [open, setOpen] = useState(false);
  const renderInputRef = useRef(null);
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });

  const handleClick = () => {
    if (!disabled) {
      setOpen(true);
    }
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          {...field}
          open={open}
          showToolbar
          closeOnSelect={false}
          onClose={() => {
            setOpen(false);
          }}
          onAccept={() => {
            onSubmit(field?.value?.toString());
            setOpen(false);
          }}
          orientation="portrait"
          PaperProps={{
            sx: sxStyle,
          }}
          DialogProps={{
            sx: sxStyle,
          }}
          PopperProps={{
            placement: popperPlacement,
            anchorEl: renderInputRef.current,
          }}
          components={{
            ActionBar: ActionBarComponent,
            RightArrowIcon: RightCaretFilled,
            LeftArrowIcon: LeftCaretFilled,
          }}
          componentsProps={{
            actionBar: { actions: ["cancel", "accept"] },
          }}
          ToolbarComponent={() => {
            return (
              <ToolBarComponent
                hideTime={hideTime}
                selectedDateTime={field?.value?.toString()}
                updateDateTime={(val) => {
                  field?.onChange(val);
                }}
              />
            );
          }}
          renderInput={() => (
            <span ref={renderInputRef} role="none" onClick={handleClick}>
              {renderInput(field?.value?.toString())}
            </span>
          )}
          onChange={(value) => {
            onSubmit(value?.toString());
            field.onChange(value);
            if (typeof onSelectDate === "function") onSelectDate(value.toString());
          }}
          shouldDisableDate={(dataParam) => shouldDisableDate(dataParam)}
          disableFuture={disableFuture}
          disablePast={disablePast}
          minDate={minDate}
        />
      </LocalizationProvider>
      <HelperText sx={{ display: "inline-block" }} error={error}>
        {helperText}
      </HelperText>
    </>
  );
};

export default SmartFormDatePicker;
