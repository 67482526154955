import styled from "@emotion/styled";
import { css } from "@emotion/react";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import { theme } from "styles/themes";

type StyledBoxPropsT = {
  isBoxStyle?: boolean;
  isSelected?: boolean;
};
export const formHeaderStyles = css`
  color: var(--blue-ribbon);
  font-family: "Poppins";
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  margin-left: -4px;
  margin-bottom: 4px;
`;

export const LabelGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledFormLabel = styled(FormLabel)`
  ${formHeaderStyles};
`;

export const SubLabel = styled.span`
  font-size: 14px;
  line-height: 23px;
  color: var(--dark-periwinkle);
  margin-left: -3px;
  margin-bottom: 4px;
`;

export const RadioLabel = styled.label`
  color: var(--queen-blue);
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  span {
    font-weight: 200;
  }
`;

export const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => !["isBoxStyle", "isSelected"].includes(prop),
})(({ isBoxStyle, isSelected }: StyledBoxPropsT) => ({
  display: "flex",
  alignItems: "center",
  ...(isBoxStyle && {
    padding: "11px 20px",
    border: `1px solid ${theme.colors["cotton-boll"]}`,
    marginRight: "16px",
    borderRadius: "12px",
    width: "280px",
    ...(isSelected && {
      backgroundColor: theme.colors["blue-ribbon-a05"],
      border: `1px solid ${theme.colors["blue-ribbon"]}`,
    }),
  }),
}));

export const TooltipText = styled("div")(() => ({
  fontSize: "16px",
  padding: "3px",
}));

export const TooltipIcon = styled("div")(() => ({
  background: theme.colors.white,
  transform: "matrix(1, 0, 0, -1, 0, 0)",
  fontSize: "10px",
  lineHeight: "10px",
  fontWeight: 500,
  color: theme.colors["graham-grey"],
  border: `1px solid ${theme.colors["graham-grey"]}`,
  width: "14px",
  height: "14px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
