import React, { useState } from "react";
import { Layout } from "antd";
import InstallerJoinForm from "./components/InstallerJoin";
import Header from "./components/Header";
import Success from "./components/Success";
import Footer from "./components/Footer";
import styles from "./Installerjoin.module.less";

const { Content } = Layout;

const InstallerJoin = () => {
  const [successVisible, setSuccessVisible] = useState(false);

  return (
    <Layout>
      <Header />
      <Content className={styles.content}>
        <div className={styles.main}>
          {successVisible ? <Success /> : <InstallerJoinForm setSuccessVisible={setSuccessVisible} />}
        </div>
      </Content>
      <Footer />
    </Layout>
  );
};

export default InstallerJoin;
