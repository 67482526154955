import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import EnterpriseJoinBanner from "components/Icons/EnterpriseJoinBanner";
import styles from "../../EnterpriseJoin.module.less";

const VideoBanner = (props) => {
  const { openModal } = props;
  const { t } = useTranslation(["enterprise_join"]);
  return (
    <div className={styles.solarIntro}>
      <div
        className={styles.backgroundImage}
        style={{
          backgroundImage: "url(/img/enterprise/img_ent_header.png)",
        }}
      />
      <div className={styles.innerContainer}>
        <EnterpriseJoinBanner />
        <p className={styles.subHeading}>{t("banner.sub_title")}</p>
        <Button
          data-test-id="VideoBanner-e-join-btn"
          onClick={openModal}
          className={styles.bannerButton}
          type="primary"
        >
          {t("banner.join_powur_enterprise")}
        </Button>
      </div>
    </div>
  );
};

VideoBanner.propTypes = {
  openModal: PropTypes.func,
};

export default VideoBanner;
