import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  projectPeopleDrawerOpen: false,
  zipLookupAddress: {},
  // displayZendeskWidget: null,
  randomAddress: {},
  showDefaultM1OptionModal: false,
};

export default {
  app: createReducer(initialState, {
    [types.TOGGLE_PROJECT_PEOPLE_DRAWER](state) {
      return {
        ...state,
        projectPeopleDrawerOpen: !state.projectPeopleDrawerOpen,
      };
    },
    [types.SET_ZIP_LOOKUP_ADDRESS](state, action) {
      return {
        ...state,
        zipLookupAddress: action.payload,
      };
    },
    [types.SET_PAGE_REFERRER](state, action) {
      return {
        ...state,
        pageReferrer: action.payload,
      };
    },
    [types.SET_DISPLAY_ZENDESK_WIDGET](state, action) {
      return {
        ...state,
        displayZendeskWidget: action.payload,
      };
    },

    [types.SET_RANDOM_ADDRESS](state, action) {
      return {
        ...state,
        randomAddress: action.payload,
      };
    },
    [types.SET_SHOW_DEFAULT_M1_OPTION_MODAL](state, action) {
      return {
        ...state,
        showDefaultM1OptionModal: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
