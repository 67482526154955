import { put } from "redux-saga/effects";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import setMessageLists from "./util/updateLists";
import messages from "../messages";

function* assignIssue(action) {
  const { id, assigned_user_id, assigned_team_id, assigned_to_customer_care } = action.payload;

  const params = {
    ...(assigned_user_id && { assigned_user_id }),
    ...(assigned_team_id && { assigned_team_id }),
    ...(typeof assigned_to_customer_care === "boolean" && { assigned_to_customer_care }),
  };

  const { success, data, error } = yield* makeRequest.post(`/messages/${id}/assign`, params);

  if (success && data) {
    yield setMessageLists(camelizeKeys(data.body));
    yield put(
      actions.newSuccessEvent({
        message: messages.ASSIGN_PROJECT_MESSAGE_SUCCESS,
      }),
    );
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_ASSIGNING_PROJECT_MESSAGE,
    });
  }
  return null;
}

export default assignIssue;
