import React from "react";
import { useLocation } from "react-router-dom";
import { Row, Col } from "antd";
import styles from "../styles.module.less";

type MetricT = {
  label: string;
  value: string | number | undefined;
};

type ColumnT = {
  label: string;
  metrics: Array<MetricT>;
};

type MetricsTableViewT = {
  columns: Array<ColumnT>;
  classNames?: {
    container?: string;
    headerRow?: string;
    body?: string;
  };
};

function MetricsTableView({ columns = [], classNames = {} }: MetricsTableViewT) {
  const location = useLocation();
  const adminUserDetail = location.pathname.includes("/users/list");
  if (columns.length === 0) return <></>;

  const colSpan = 24 / columns.length;

  const navToAdmin = () => {
    window.location.href = "/admin-projects/leads";
  };

  const metric = (label, value, columnLabel) => {
    const clickable = columnLabel !== "Performance" && adminUserDetail;
    return (
      <Row key={`metrics-display-table-col-${columnLabel}-row-${label}`} className={styles.metricRow}>
        <Col span={18} className={styles.metric}>
          <span
            className={clickable ? styles.activeMetric : ""}
            role="button"
            tabIndex={0}
            onClick={() => (clickable ? navToAdmin() : {})}
            onKeyDown={() => null}
          >
            {label}
          </span>
        </Col>
        <Col span={6} className={styles.value}>
          {value || "0"}
        </Col>
      </Row>
    );
  };

  const headerRow = (cols) => {
    return (
      <Row className={[styles.categoryRow, classNames.headerRow].join(" ")}>
        {cols.map((col) => (
          <Col key={`metrics-display-table-col-${col.label}-header-row`} span={colSpan} className={styles.categoryCol}>
            <div className={styles.category}>{col.label}</div>
          </Col>
        ))}
      </Row>
    );
  };

  const metricRows = (col) => (
    <Col span={colSpan} key={`metrics-display-table-col-${col.label}-metrics-column`} className={styles.metricsCol}>
      {col.metrics.map((row) => metric(row.label, row.value, col.label))}
    </Col>
  );

  return (
    <Row
      gutter={24}
      style={{ marginLeft: 0, marginRight: 0 }}
      className={[styles.metricsTableViewContainer, classNames.container].join(" ")}
    >
      {headerRow(columns)}
      <Row gutter={24} className={[styles.metricsRow, classNames.body].join(" ")}>
        {columns.map((col) => metricRows(col))}
      </Row>
    </Row>
  );
}

export default MetricsTableView;
