import React, { useEffect } from "react";
import { useLocalStorage, useEffectOnce } from "react-use";
import { useDispatch } from "react-redux";
import actions from "rdx/actions";
import { Helmet } from "react-helmet";
import SessionClient from "util/SessionClient";

import { loggedInUserConfig, loggedOutConfig } from "./configs";

const InjectZendeskWidget = (props) => {
  const { displayWidget } = props || null;
  const dispatch = useDispatch();
  const { jwt } = new SessionClient();
  const isProductionEnv = process.env.NODE_ENV === "production";
  const [localLauncherLabelRemoved, setLocalLauncherLabelRemoved] = useLocalStorage("ZD-launcherLabelRemoved", true);
  useEffect(() => {
    setLocalLauncherLabelRemoved(true);
  }, [localLauncherLabelRemoved, setLocalLauncherLabelRemoved, displayWidget]);

  const [localDisplayZendeskWidget, setLocalDisplayZendeskWidget] = useLocalStorage("displayZendeskWidget", false);

  useEffectOnce(() => {
    setLocalLauncherLabelRemoved(true);
    dispatch(actions.setDisplayZendeskWidget(!!localDisplayZendeskWidget));
    setLocalDisplayZendeskWidget(!!localDisplayZendeskWidget);
  });

  useEffect(() => {
    setLocalLauncherLabelRemoved(true);

    if (displayWidget === true) {
      setLocalDisplayZendeskWidget(!!displayWidget);
    } else {
      setLocalDisplayZendeskWidget(false);
    }
  }, [displayWidget, setLocalDisplayZendeskWidget, setLocalLauncherLabelRemoved]);

  const devUrl = "https://static.zdassets.com/ekr/snippet.js?key=71654836-cba8-450e-a3a2-5f9149e507d8";
  const prodUrl = "https://static.zdassets.com/ekr/snippet.js?key=d04a5ffe-39e7-4877-8043-3f1e24deae48";

  let widgetConfig = null;
  if (displayWidget && !jwt) {
    widgetConfig = { ...loggedOutConfig };
  } else if (displayWidget && jwt) {
    widgetConfig = { webWidget: { ...loggedInUserConfig } };
  }
  useEffect(() => {
    if (widgetConfig) {
      window.zESettings = widgetConfig;
    }
  }, [widgetConfig]);

  return widgetConfig ? (
    <Helmet>
      <script id="ze-snippet" src={isProductionEnv ? prodUrl : devUrl} />
      <script type="text/javascript" />
    </Helmet>
  ) : null;
};

export default InjectZendeskWidget;
