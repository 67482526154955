import React from "react";
import { SmartFormRadioGroup } from "components/mui-styled-components/SmartFormElements";
import { RadioOptionsT } from "components/mui-styled-components/SmartFormElements/SmartFormRadioGroup";
import { useSmartForm } from "hooks/useSmartForm";
import { selectors } from "rdx";
import { z } from "zod";
import SmartForm from "components/SmartForm";
import { Dialog, DialogContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import actions from "rdx/actions";
import * as S from "./styles";

type DefaultM1OptionModalPropsT = {
  onSave?: () => void;
};

const DefaultM1OptionModal = ({ onSave }: DefaultM1OptionModalPropsT) => {
  const user = useSelector(selectors.getCurrentUser);
  const showDefaultM1OptionModal = useSelector(selectors.getShowDefaultM1OptionModal);

  const dispatch = useDispatch();
  const schema = z.object({
    defaultM1Option: z.string(),
  });
  type SiteInfoFormT = z.infer<typeof schema>;
  const { methods, getSmartFormProps } = useSmartForm<SiteInfoFormT>({
    schema,
    useFormProps: {
      defaultValues: {
        defaultM1Option: "",
      },
    },
  });

  const defaultM1Options: RadioOptionsT = [
    { label: "M1 OFF", value: "false" },
    { label: "M1 ON", value: "true" },
  ];

  const { defaultM1Option } = methods.watch();

  const closeModal = () => {
    dispatch(actions.setShowDefaultM1OptionModal(false));
  };

  const onSubmit = () => {
    dispatch(
      actions.updateDefaultAdvance({
        defaultAdvance: defaultM1Option === "true",
        userId: user?.id,
      }),
    );
    dispatch(actions.setCurrentUser({ ...user, defaultAdvanceSet: true }));
    if (onSave) onSave();
    closeModal();
  };

  const onError = () => {
    dispatch(
      actions.setAlertMessageVisible({
        message: "There was an error updating your M1 preferences.",
        severity: "error",
      }),
    );
    closeModal();
  };

  return (
    <Dialog
      onClose={closeModal}
      open={showDefaultM1OptionModal}
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "12px",
        },
      }}
    >
      <S.StyledDialogTitle>M1 Commission Advance</S.StyledDialogTitle>
      <DialogContent sx={{ paddingBottom: "0px" }}>
        <S.MaxWidth500>
          For every project, you have the opportunity to select whether you would like to receive an M1 commission
          advance for the project. Project M1&apos;s that are paid out before 6/10/2024 will be charged a 15% fee, while
          those paid out after 6/10/2024 will be charged a 10% fee. You can select your default choice below, which will
          determine your M1 choice for each new project. You can always change your mind and set your M1 choice for each
          individual project prior to the date when M1 is paid (i.e., after the site survey).
        </S.MaxWidth500>
        <SmartForm
          formProps={{
            css: { width: "100%" },
          }}
          {...getSmartFormProps({})}
        >
          <SmartFormRadioGroup name="defaultM1Option" radioOptions={defaultM1Options} />
        </SmartForm>
      </DialogContent>
      <S.StyledDialogActions>
        <S.StyledGradientButton onClick={methods.handleSubmit(onSubmit, onError)} disabled={!defaultM1Option}>
          Save Settings
        </S.StyledGradientButton>
      </S.StyledDialogActions>
    </Dialog>
  );
};

export default DefaultM1OptionModal;
