import z from "zod";
import { UserSubscriptionStatusEnum } from "rdx/modules/users/enums";
import { TeamUserTypeEnum } from "./enums";

export const TeamUserTypeSchema = z.nativeEnum(TeamUserTypeEnum);
export const UserSubscriptionStatusSchema = z.nativeEnum(UserSubscriptionStatusEnum);

export const ParsedQueryParamSchema = z.union([z.string(), z.string().nullable().array(), z.undefined()]).nullable();

export const TeamFilterParamsSchema = z.object({
  activity: ParsedQueryParamSchema,
  certificationTier: ParsedQueryParamSchema,
  enrolledFrom: ParsedQueryParamSchema,
  enrolledTo: ParsedQueryParamSchema,
  enrollment: ParsedQueryParamSchema,
  teamActivity: ParsedQueryParamSchema,
  level: ParsedQueryParamSchema,
  performanceFrom: ParsedQueryParamSchema,
  performanceTo: ParsedQueryParamSchema,
});

export const TeamSellerSchema = z.object({
  activeProjects: z.object({
    preSalesCount: z.number(),
    postSalesCount: z.number(),
  }),
  address: z.object({ city: z.string().nullable(), state: z.string().nullable() }).optional(),
  avatar: z.object({
    thumb: z.string(),
    preview: z.string(),
    large: z.string(),
    retina: z.string(),
  }),
  certificationTier: z.number().nullable(),
  hierarchy: z.array(z.number()),
  email: z.string(),
  enrolledAt: z.string().nullable(),

  firstName: z.string(),
  fullName: z.string(),
  hasViewableDownline: z.boolean(),
  id: z.number(),
  lastName: z.string(),
  performanceMetrics: z.object({
    buildableProjectsCount: z.number(),
    contractCount: z.number(),
    installCount: z.number(),
    recruitCount: z.number(),
    teamCount: z.number(),
  }),
  phone: z.string(),
  relatedLevel: z.number(),
  seatId: z.number().nullable(),
  sponsor: z.object({
    id: z.number(),
    fullName: z.string(),
  }),
  subscriptionStatus: UserSubscriptionStatusSchema,
  userActivity: z.object({
    lastLeadAt: z.string().nullable(),
    lastContractAt: z.string().nullable(),
    lastRecruitAt: z.string().nullable(),
  }),
  userTypes: z.array(TeamUserTypeSchema),
});

export const GetTeamSellersParamsSchema = z.object({
  filterParams: TeamFilterParamsSchema,
  withDownlineExpansion: z.boolean(),
  searchedUserId: z.union([z.number(), z.null()]),
});

export const GetTeamDownlineParamsSchema = z.object({
  filterParams: TeamFilterParamsSchema,
  userId: z.number(),
  searchedUserId: z.union([z.number(), z.null()]),
});

export const GetMoreTeamSellersParamsSchema = z.object({
  filterParams: TeamFilterParamsSchema,
  withDownlineExpansion: z.boolean(),
  p: z.number(),
});

export const GetTeamSellersResponseSchema = z.object({
  status: z.number(),
  body: z.object({
    paging: z.object({
      itemCount: z.number(),
      pageNumber: z.number(),
      pageSize: z.number(),
      hasMore: z.boolean(),
    }),
    users: z.array(TeamSellerSchema),
  }),
});

export const GetTeamDownlineResponseSchema = z.object({
  status: z.number(),
  body: z.object({
    users: z.array(TeamSellerSchema),
  }),
});
