import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  dashboardUpdates: {
    paging: {
      pageSize: 0,
      pageNumber: 1,
      itemCount: 0,
    },
    updates: [],
    filters: {},
  },
  selectedDashboardUpdateId: {},
  dashboardUpdateModalId: null,
};

export default {
  dashboardUpdates: createReducer(initialState, {
    [types.SET_DASHBOARD_UPDATES](state, action) {
      return {
        ...state,
        dashboardUpdates: {
          ...state.dashboardUpdates,
          ...action.payload,
        },
      };
    },

    [types.SET_DASHBOARD_UPDATE_ID](state, action) {
      return { ...state, selectedDashboardUpdateId: action.payload };
    },

    [types.SET_DASHBOARD_UPDATE_MODAL_ID](state, action) {
      return {
        ...state,
        dashboardUpdateModalId: action.payload,
      };
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};
