export default {
  GET_PREFERRED_PARTNERS: "[preferredPartners] GET Preferred Partners",

  GET_PREFERRED_PARTNERS_ADMIN: "[preferredPartners] GET Preferred Partners Admin",

  SET_PREFERRED_PARTNERS: "[preferredPartners] SET Preferred Partners",

  SET_RECOMMENDED_PARTNERS: "[preferredPartners] Set Recommended Partners",

  SET_PREFERRED_PARTNERS_ADMIN: "[preferredPartners] SET Preferred Partners Admin",

  DELETE_PREFERRED_PARTNER: "[preferredPartners] DELETE Preferred Partner",

  CREATE_PREFERRED_PARTNER: "[preferredPartners] POST Create Preferred Partner",

  UPDATE_PREFERRED_PARTNER: "[preferredPartners] PATCH Update Preferred Partner",

  CREATE_ORG_PARTNER_REQUEST: "[preferredPartners] Create Org Partner Request",

  SET_PREFERRED_PARTNERS_AUTOCOMPLETE_SUGGESTIONS:
    "[preferredPartners] SET Preferred Partners Autocomplete Suggestions",
  // INSERTION_PT (for script, do not remove)
};
