export default {
  // errors
  APPROVE_SEAT_APPROVALS_SUCCESS: "This seat request has been approved.",
  DECLINE_SEAT_APPROVALS_SUCCESS: "This seat request has been declined.",

  // errors
  GET_SEAT_APPROVALS_ERROR: "There was an error getting seat approvals.",
  APPROVE_SEAT_APPROVALS_ERROR: "There was an error approving this seat.",
  DECLINE_SEAT_APPROVALS_ERROR: "There was an error declining this seat.",
};
