import React from "react";
import ConferenceIcon from "@icons/ConferenceIcon";
import styles from "./evolutionTooltipContents.module.less";

const EvolutionTooltipContent = () => {
  const handleClick = (e, skipLink) => {
    e.stopPropagation();
    if (!skipLink) {
      window.open("https://powurconvention.com");
    }
  };

  return (
    <div
      className={styles.evolutionTooltipContent}
      tabIndex={0}
      role="button"
      onClick={(e) => handleClick(e, true)}
      onKeyDown={(e) => handleClick(e, true)}
    >
      <div className={styles.top}>
        <div className={styles.left}>
          <ConferenceIcon shade="light" height="45px" width="45px" />
        </div>
        <div className={styles.right}>
          <div className={styles.title}>Powur World Conference</div>
          <div className={styles.date}>September 6th - 9th, 2023</div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.left} />
        <div tabIndex={0} role="button" onClick={(e) => handleClick(e)} onKeyDown={(e) => handleClick(e)}>
          <div className={styles.right}>
            <div className={styles.learn}>
              Learn More{" "}
              <svg width="5" height="7" viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.59024 3.28706C4.86677 3.48663 4.86677 3.89838 4.59024 4.09795L1.44464 6.36809C1.11396 6.60674 0.652039 6.37045 0.652039 5.96265L0.652039 1.42236C0.652039 1.01456 1.11396 0.778272 1.44464 1.01692L4.59024 3.28706Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EvolutionTooltipContent.propTypes = {};

export default EvolutionTooltipContent;
