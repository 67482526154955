import React, { CSSProperties } from "react";

import { ToggleButtonGroupProps } from "@mui/material/ToggleButtonGroup";
import { useController } from "react-hook-form";
import { cvar } from "styles";

import { useSmartFormContext } from "components/SmartForm";
import HelperText from "../HelperText";

import * as S from "./styles";

type ButtonOptionsT = {
  label: string;
  value: string | number;
};

type SmartFormToggleButtonGroupProps = {
  mainLabel?: string;
  subLabel?: string;
  buttonOptions: ButtonOptionsT[];
  name: string;
  helperText?: string | undefined;
  error?: boolean;
  disabled?: boolean;
  subLabelStyle?: CSSProperties;
  mainLabelStyle?: CSSProperties;
  setButtonGroupValues: (buttonValues: string[]) => void;
} & ToggleButtonGroupProps;

const SmartFormToggleButtonGroup = ({
  mainLabel,
  subLabel,
  buttonOptions,
  name,
  helperText,
  error,
  setButtonGroupValues,
  disabled = false,
  mainLabelStyle = {},
  subLabelStyle = {},
  ...rest
}: SmartFormToggleButtonGroupProps) => {
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });
  const { value } = field;

  const handleChange = (_: React.MouseEvent<HTMLElement>, buttonValues: string[]) => {
    setButtonGroupValues(buttonValues);
  };

  return (
    <>
      <S.LabelGroupWrapper>
        <S.StyledSectionLabel style={mainLabelStyle}>{mainLabel}</S.StyledSectionLabel>
        <S.StyledFormLabel style={subLabelStyle}>{subLabel}</S.StyledFormLabel>
      </S.LabelGroupWrapper>

      <S.StyledToggleButtonGroup value={value} onChange={handleChange} exclusive={false} disabled={disabled} {...rest}>
        {buttonOptions.map((opt) => (
          <S.StyledToggleButton
            sx={{
              "&.Mui-selected": {
                border: `1px solid ${cvar("blue-ribbon")} !important`,
                backgroundColor: cvar("cotton-boll"),
                color: cvar("blue-ribbon"),
              },
            }}
            value={opt.value}
            key={opt.value}
          >
            {opt.label}
          </S.StyledToggleButton>
        ))}
      </S.StyledToggleButtonGroup>
      <HelperText sx={{ marginTop: "-10px" }} error={error}>
        {helperText}
      </HelperText>
    </>
  );
};

export default SmartFormToggleButtonGroup;
