import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/projects/messages";
import { camelizeKeys, keysToSnakeCase } from "lib/helpers";

type FundingProposalsPropsT = {
  payload: {
    projectId: number;
  };
};

function* getFundingProposals(action: FundingProposalsPropsT) {
  const params = keysToSnakeCase(action.payload);

  const { success, data, error } = yield* makeRequest.get("/funding_proposals/for_project", params);

  if (success && data) {
    const { fundingProposals } = camelizeKeys(data.body);

    yield put(actions.setFundingProposals(fundingProposals));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROPOSALS,
    });
  }

  return null;
}

export default getFundingProposals;
