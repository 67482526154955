import { put, all } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/users/messages";
import { keysToCamelCase, destructureObjArray } from "lib/helpers";

function* changeUserSponsor(action) {
  const { userId, sponsorId } = action.payload;
  const { success, data, error } = yield* makeRequest.patch(`/users/${userId}/update_sponsor`, {
    sponsor_id: sponsorId,
  });
  if (success && data) {
    const user = keysToCamelCase(_.clone(data.body));
    user.metrics = destructureObjArray(data.body.metrics);
    user.upline = user.upline.map((u) => keysToCamelCase(u));
    user.managerOptions = user.managerOptions.map((u) => keysToCamelCase(u));
    yield all([
      put(actions.setUserDetails(user)),
      put(
        actions.newSuccessEvent({
          message: messages.CHANGE_USER_SPONSOR_SUCCESS,
          payload: data,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CHANGING_USER_SPONSOR,
    });
  }
  return null;
}

export default changeUserSponsor;
