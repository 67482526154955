import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getTeamUsersAutoComplete(action) {
  let params = {
    lookup: "item",
  };
  if (action.payload.search) {
    params = { ...params, q: action.payload.search };
  }
  if (action.payload.roles) {
    params = { ...params, roles: action.payload.roles };
  }
  const { success, data, error } = yield* makeRequest.get("/users/team_eligible_users", {
    ...params,
  });
  if (success && data) {
    const users = data.body.users.map((u) => keysToCamelCase(u));
    yield put(actions.setUsersAutoComplete({ users }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_USERS_AUTOCOMPLETE,
    });
  }
  return null;
}

export default getTeamUsersAutoComplete;
