import React from "react";
import PropTypes from "prop-types";
import { LogoutOutlined } from "@ant-design/icons";
import ModalContainer from "components/Modals/ModalContainer";
import { Typography } from "antd";
import styles from "./Logout.module.less";

const { Text } = Typography;

const LogoutModal = (props) => {
  const { visible, closeModal, onSubmit } = props;
  return (
    <ModalContainer
      visible={visible}
      headerIcon={<LogoutOutlined style={{ color: "var(--dark-blue)" }} />}
      title="Log Out"
      handleCancel={closeModal}
      handleSubmit={onSubmit}
      submitText="Log Out"
      zIndex={5000}
    >
      <Text className={styles.title}>Would you like to log out and return to the Powur homepage?</Text>
    </ModalContainer>
  );
};

LogoutModal.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default LogoutModal;
