import React from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "antd";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import { actions, selectors } from "rdx";

import { Header, Footer, GetQuoteModal, UtilityBillModal, SuccessModal } from "components/HomepageComponents";
import ScrollToTop from "containers/router/scrollToTop";
import SolarIntro from "./components/Intro";
import PowurStories from "./components/PowurStories";
import Process from "./components/Process";
import EstimateSavings from "./components/EstimateSavings";
import SolarChart from "./components/SolarChart";
import styles from "./Solar.module.less";

const { Content } = Layout;

const Solar = (props) => {
  const { user } = props;
  const { t } = useTranslation(["whysolar", "home"]);
  const dispatch = useDispatch();
  const alertMessage = useSelector(selectors.selectAlertMessage);

  const closeAlertMessage = () => {
    dispatch(actions.clearAlertMessage());
  };

  const stories = [
    [
      {
        imgClassName: styles.juan,
        title: t("testimonials.juan.title"),
        videoSrc: t("testimonials.juan.url"),
        content: t("testimonials.juan.quote"),
      },
      {
        imgClassName: styles.pujaAmish,
        title: t("testimonials.puja_amish.title"),
        videoSrc: t("testimonials.puja_amish.url"),
        content: t("testimonials.puja_amish.quote"),
      },
    ],
    [
      {
        imgClassName: styles.john,
        title: t("testimonials.john.title"),
        videoSrc: t("testimonials.john.url"),
        content: t("testimonials.john.quote"),
      },
      {
        imgClassName: styles.kristy,
        title: t("testimonials.kristy.title"),
        videoSrc: t("testimonials.kristy.url"),
        content: t("testimonials.kristy.quote"),
      },
    ],
  ];

  const description =
    "Powur can help save you thousands on your electric bill. Ready to see if solar power is the right choice for your pocket book and our planet? Let us prepare a custom quote so you can see the difference. Request A Quote Today!";

  return (
    <Layout className={styles.pageLayout}>
      <Snackbar
        open={!!alertMessage.visible && !!alertMessage.message}
        autoHideDuration={alertMessage.duration}
        resumeHideDuration
        onClose={closeAlertMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={closeAlertMessage} severity={alertMessage.severity}>
          {alertMessage.message}
        </Alert>
      </Snackbar>
      <ScrollToTop />
      <Helmet>
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
        <title>How it Works</title>
        <meta name="title" content="How it Works | Powur Energy" />
        <meta
          name="description"
          content="From consultation to installation, our tech-driven approach simplifies the process of going solar."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://powur.com/whysolar" />
        <meta property="og:title" content="Why go solar now? Here are the most important reasons | Powur Energy" />
        <meta
          property="og:description"
          content="By going solar you reduce your monthly utility bill and save more money. You also lower your carbon footprint and help our environment. Get a free quote today."
        />
        <meta property="og:image" content="/img/powur_fb_01.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://powur.com/whysolar" />
        <meta property="twitter:title" content="Why go solar now? Here are the most important reasons | Powur Energy" />
        <meta
          property="twitter:description"
          content="By going solar you reduce your monthly utility bill and save more money. You also lower your carbon footprint and help our environment. Get a free quote today."
        />
        <meta property="twitter:image" content="/img/powur_fb_01.jpg" />
      </Helmet>
      <Header user={user} />
      <Content className={styles.content}>
        <SolarIntro />
        <PowurStories
          stories={stories[0]}
          headline={t("testimonials.meet_our_customers")}
          subTitle={t("testimonials.these_are_our_powur_stories")}
          showTopBorder
        />
        <SolarChart />
        <EstimateSavings theme="light" />
        <PowurStories
          stories={stories[1]}
          headline={t("home:mission.millions_of_reasons")}
          subTitle={t("home:mission.every_homeowner")}
          showTopBorder={false}
        />
        <Process />
      </Content>
      <Footer />
      <GetQuoteModal />
      <UtilityBillModal />
      <SuccessModal />
    </Layout>
  );
};

export default Solar;
