import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const EscalatedIcon = ({ width = "16", height = "16", fill = cvar("white") }: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16">
      <path
        d="M7.88867 14.2033C11.6932 14.2033 14.7773 11.2477 14.7773 7.60167C14.7773 3.95567 11.6932 1 7.88867 1C4.08416 1 1 3.95567 1 7.60167C1 11.2477 4.08416 14.2033 7.88867 14.2033Z"
        fill="transparent"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.76221 10.1016L6.39857 7.82884L8.58039 8.73793L10.7622 5.10156"
        fill="transparent"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EscalatedIcon;
