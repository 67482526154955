import React from "react";

import SeatRequestModal from "components/Modals/SeatRequestModal";
import MentorRatingModal from "components/Modals/MentorRatingModal";
import DashboardUpdateModal from "components/Modals/DashboardUpdateModal";
import ActivateAccountModal from "components/Modals/ActivateAccountModal";
import PaymentModal from "components/Modals/PaymentModal";
import IntegrityTrainingModal from "components/mui-styled-components/Modals/IntegrityTrainingModal";

import CloserDispositionModal from "components/Modals/CloserDispositionModal";
import InterstitialNotifications from "./InterstitialNotifications";

const PlatformModals = () => {
  return (
    <>
      <InterstitialNotifications />
      <SeatRequestModal />
      <CloserDispositionModal />
      <MentorRatingModal />
      <DashboardUpdateModal />
      <ActivateAccountModal />
      <PaymentModal />
      <IntegrityTrainingModal />
    </>
  );
};

export default PlatformModals;
