import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "rdx";

import { styled } from "@mui/material/styles";

import { makeMap } from "lib/helpers/makeMap";

import SessionClient from "util/SessionClient";

import ImpersonationBanner from "components/PlatformLayout/components/Banners/ImpersonationBanner";
import CertificationBanner from "components/PlatformLayout/components/Banners/CertificationBanner";
import PastDueBanner from "./PastDueBanner";
import IntegrityViolationBanner from "./IntegrityViolationBanner";

const Banners = () => {
  const session = new SessionClient();
  const { banners } = session;
  const elementRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const bannersHeight = elementRef.current?.clientHeight;
    dispatch(actions.setBannersHeight(bannersHeight));
  }, [elementRef, dispatch]);

  const bannerReference = (banner) => {
    const list = makeMap({
      past_due: <PastDueBanner key="past_due" />,
      impersonating: <ImpersonationBanner key="impersonating" />,
      certification: <CertificationBanner key="certification" />,
      incomplete_violation: <IntegrityViolationBanner bannerProps={banner.props || {}} key="violation" />,
    });

    return list.get(banner.label);
  };

  const Container = styled("div")({
    position: "fixed",
    zIndex: "1005",
    width: "100%",
  });

  return (
    <Container ref={elementRef}>
      {banners?.map((banner) => {
        return bannerReference(banner);
      })}
    </Container>
  );
};

export default Banners;
