import { useLocation } from "react-router-dom";

enum Paths {
  ADMIN = "/admin-",
  ORG = "/org-",
  PROJECTS = "/projects",
  PRE_SALES = "/pre-sales",
  SALES_PROJECTS_PRE_SALES = "/projects/pre-sales",
  ORG_PROJECTS = "/org-projects",
  ADMIN_PROJECTS = "/admin-projects",
}

type RouteMatchResult = {
  isAdmin: boolean;
  isProjects: boolean;
  isEnterprise: boolean;
  isPreSales: boolean;
  isSalesProjectsPreSales: boolean;
  isDetailView: boolean;
};

const projectDetailRegexes = {
  default: new RegExp(`${Paths.PROJECTS}/\\d+`),
  admin: new RegExp(`${Paths.ADMIN_PROJECTS}/\\d+`),
  org: new RegExp(`${Paths.ORG_PROJECTS}/\\d+`),
};

export default function useRouteMatcher(): RouteMatchResult {
  const location = useLocation();
  const currentPath = location.pathname;

  const isDetailView = () => {
    return (
      projectDetailRegexes.default.test(currentPath) ||
      projectDetailRegexes.admin.test(currentPath) ||
      projectDetailRegexes.org.test(currentPath)
    );
  };

  return {
    isAdmin: currentPath.includes(Paths.ADMIN),
    isEnterprise: currentPath.includes(Paths.ORG),
    isProjects: currentPath.includes(Paths.PROJECTS),
    isPreSales: currentPath.includes(Paths.PRE_SALES),
    isSalesProjectsPreSales: currentPath.includes(Paths.SALES_PROJECTS_PRE_SALES),
    isDetailView: isDetailView(),
  };
}
