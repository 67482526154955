import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/auth/messages";

import SessionClient from "util/SessionClient";

function* login(action) {
  const session = new SessionClient();
  session.clear();

  const { auth_format = "password" } = action.payload;
  let loginPayload = {};

  if (auth_format === "password") {
    loginPayload = {
      username: action.payload.username,
      password: action.payload.password,
      remember: action.payload.remember,
      auth_format,
    };
  } else if (auth_format === "facebook") {
    loginPayload = {
      token: action.payload.token,
      auth_format,
    };
  } else {
    loginPayload = {
      auth_code: action.payload.auth_code,
      auth_format,
    };
  }

  const { success, data, error } = yield* makeRequest.post("/jwt", loginPayload);

  if (success && data) {
    if (loginPayload.remember) {
      session.username = loginPayload.username;
    } else {
      session.clearUsername();
    }
    session.setSession(data);
    session.uiState = {};
    session.user.initStore(data);
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.loginMessage(data.body?.user?.full_name),
          payload: {
            event: messages.LOGIN_SUCCESS_EVENT,
          },
        }),
      ),
      put(actions.clearAlertMessage()),
    ]);
  } else if (error) {
    yield put(
      actions.setLoginErrors({
        [error.field]: {
          message: error.message,
        },
      }),
    );
    return getErrorActions({
      error: {
        ...error,
        event: messages.LOGIN_ERROR_EVENT,
      },
    });
  }

  return null;
}

export default login;
