import React from "react";
import PropTypes from "prop-types";

const TotalClosed = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="12.5742"
        y="7.12109"
        width="0.957461"
        height="10.2129"
        rx="0.478731"
        transform="rotate(45 12.5742 7.12109)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.494227"
      />
      <rect
        x="13.251"
        y="14.3438"
        width="0.957461"
        height="10.2129"
        rx="0.478731"
        transform="rotate(135 13.251 14.3438)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.494227"
      />
      <circle cx="9.30072" cy="11.0703" r="4.69134" stroke={fill} strokeWidth="1.3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9543 10.2259V9.19912H25.5839V23.951C25.5839 27.1479 24.243 29.0117 21.0212 29.0117C17.982 29.0117 16.4585 27.2509 16.4585 23.951L16.4585 1.89401H2.02682V23.9184C2.02682 26.7687 3.18082 27.9849 6.10578 27.9849C8.0132 27.9849 14.109 27.9849 17.8921 27.9849C18.0077 27.9849 18.4169 28.5013 19.2059 28.7423C19.9949 28.9832 21.0212 29.0117 21.0212 29.0117C20.8585 29.0117 8.9208 29.0117 6.10578 29.0117C2.60131 29.0117 1 27.324 1 23.9184V0.867188H17.4853V23.951C17.4853 26.7142 18.5848 27.9849 21.0212 27.9849C23.5893 27.9849 24.557 26.6398 24.557 23.951L24.557 10.2259"
        fill={fill}
      />
      <path
        d="M17.9543 10.2259V9.19912H25.5839V23.951C25.5839 27.1478 24.243 29.0117 21.0212 29.0117C17.982 29.0117 16.4585 27.2509 16.4585 23.951L16.4585 1.89401H2.02682L2.02682 23.9184C2.02682 26.7687 3.18082 27.9849 6.10578 27.9849C8.0132 27.9849 14.109 27.9849 17.8921 27.9849C18.0077 27.9849 18.4169 28.5013 19.2059 28.7423C19.9949 28.9832 21.0212 29.0117 21.0212 29.0117C20.8585 29.0117 8.9208 29.0117 6.10578 29.0117C2.60131 29.0117 1 27.324 1 23.9184V0.867188H17.4853V23.951C17.4853 26.7142 18.5848 27.9849 21.0212 27.9849C23.5893 27.9849 24.557 26.6398 24.557 23.951L24.557 10.2259"
        stroke={fill}
        strokeWidth="0.3"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.57422 21.4214H15.0298V20.3945H3.57422V21.4214Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6633 14.2299V13.2031H18.002V14.2299H23.6633Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6633 17.312V16.2852H18.002V17.312H23.6633Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6633 20.394V19.3672H18.002V20.394H23.6633Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6633 23.476V22.4492H18.002V23.476H23.6633Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0298 24.5034V23.4766H3.57422V24.5034H15.0298Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
        strokeLinejoin="round"
      />
    </svg>
  );
};

TotalClosed.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

TotalClosed.defaultProps = {
  fill: "var(--eggplant)",
  width: "26",
  height: "30",
};

export default TotalClosed;
