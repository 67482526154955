import React from "react";
import { Grid } from "@mui/material";
import CloseButton from "components/Buttons/CloseButton";
import { PrimaryButton } from "components/mui-styled-components";
import GradientButton from "components/Buttons/GradientButton";
import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";
import { cvar } from "styles";

const ActionBarComponent = (props: PickersActionBarProps) => {
  const { onCancel, onAccept, onClear, actions } = props;
  const isClearable = Array.isArray(actions) && actions.includes("clear");
  return (
    <Grid
      style={{ background: cvar("athens-gray"), borderRadius: "12px" }}
      container
      justifyContent="center"
      alignItems="center"
      height="60px"
    >
      <Grid item xs={1} paddingRight={6}>
        <CloseButton buttonTypeStyle="gradient" action={onCancel} />
      </Grid>
      {isClearable && (
        <Grid item xs={4}>
          <PrimaryButton gradientOutlined width="94px" onClick={onClear}>
            Clear
          </PrimaryButton>
        </Grid>
      )}
      <Grid item xs={4} paddingRight={1}>
        <GradientButton style={{ width: "100px" }} onClick={onAccept}>
          Save
        </GradientButton>
      </Grid>
    </Grid>
  );
};

export default ActionBarComponent;
