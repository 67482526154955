/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import { faker } from "@faker-js/faker";
import { useSelector } from "react-redux";
import { selectors } from "rdx";

import { useEffectOnce } from "react-use";

import usePermissions, { permissionTypes } from "hooks/usePermissions";

import GradientButton from "components/Buttons/GradientButton";

type FastFillT = {
  setValue: any;
  fastFillData: Record<string, unknown>;
};

const FastFill = ({ setValue, fastFillData }: FastFillT) => {
  const randomAddress = useSelector(selectors.selectRandomAddress);

  const { DEVELOPER } = usePermissions({
    permissionRequests: [permissionTypes.DEVELOPER],
  });

  useEffectOnce(() => {
    if (DEVELOPER) {
      // dispatch(actions.getRandomAddress({}));
    }
  });

  if (!DEVELOPER || process.env.REACT_APP_ENV === "production") return null;

  const randomValues = {
    ...randomAddress,
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    email: `${faker.name.firstName()}.${faker.name.lastName()}@yahoo.com`,
  };

  const fillForm = () => {
    Object.entries(fastFillData).forEach(([name, value]) => {
      setValue(name, randomValues[name] || value, { shouldDirty: true });
    });
  };

  return (
    <GradientButton css={{ position: "fixed", bottom: "65px", right: "40px", zIndex: 1000 }} onClick={() => fillForm()}>
      Fast Fill
    </GradientButton>
  );
};

export default FastFill;
