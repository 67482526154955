// import { permissionTypes } from "hooks/usePermissions";

// const { PLATFORM_CONFIGURATION } = permissionTypes;

const commonTabs = [
  {
    key: "marketing",
    label: "Marketing",
  },
  {
    key: "facebook-pixel",
    label: "Facebook Pixel",
  },
];

const adminTabs = [];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
