export enum UserSubscriptionStatusEnum {
  BANNED = "banned",
  NEVER_SUBSCRIBED = "never_subscribed",
  UNCERTIFIED_CANCELING = "uncertified_canceling",
  NEEDS_CERTIFICATION = "needs_certification",
  INTEGRITY_RESTRICTED = "integrity_restricted",
  ONLY_SEATED = "only_seated",
  PAST_DUE = "past_due",
  ACTIVE = "active",
  CANCELED = "canceled",
  CANCELING = "canceling",
}
