import React from "react";
import { cvar } from "styles/colorVariables";

interface ClockIconT {
  fill?: string;
  height?: string | number;
  width?: string | number;
}

const ClockIcon = ({ fill = cvar("dark-blue"), height = "15", width = "16" }: ClockIconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1524 13.9747C13.3669 14.2941 13.2596 14.6974 12.9199 14.8992C12.7946 14.9664 12.6696 15 12.5443 15C12.3118 15 12.0615 14.8823 11.9364 14.6806L11.1854 13.5378C11.6146 13.3361 12.0258 13.1008 12.4012 12.8319L13.1524 13.9747ZM4.78427 1.4027C4.74848 1.03293 4.60542 0.696783 4.33726 0.427863C3.6221 -0.244378 2.28106 -0.110038 1.38704 0.730338L1.01148 1.08336C0.0996077 1.94051 -0.0434363 3.1843 0.689697 3.85654C0.957854 4.1086 1.2976 4.24308 1.67316 4.27673C2.3705 3.04981 3.46121 2.04133 4.7843 1.4027H4.78427ZM15.0656 1.08333L14.6901 0.730308C13.7961 -0.110068 12.4551 -0.244528 11.7399 0.427833C11.4718 0.679898 11.3108 1.0329 11.2929 1.40267C12.634 2.04139 13.7247 3.04978 14.422 4.29356C14.7976 4.25992 15.1372 4.12544 15.4055 3.87338C16.1206 3.18424 15.9596 1.94045 15.0656 1.0833V1.08333ZM3.67568 12.8318L2.9247 13.9746C2.71017 14.294 2.81743 14.6973 3.15718 14.8991C3.28241 14.9663 3.4075 14.9999 3.53274 14.9999C3.76522 14.9999 4.01554 14.8822 4.14064 14.6805L4.89163 13.5377C4.46246 13.336 4.05124 13.1007 3.67569 12.8318L3.67568 12.8318ZM8.03853 2.34389C5.05248 2.34389 2.62073 4.62971 2.62073 7.43657C2.62073 10.2434 5.05248 12.5293 8.03853 12.5293C11.0246 12.5293 13.4563 10.2434 13.4563 7.43657C13.4563 4.62971 11.0246 2.34389 8.03853 2.34389ZM8.03853 0.999259C11.8113 0.999259 14.8868 3.89015 14.8868 7.43654C14.8868 10.9829 11.8113 13.8738 8.03853 13.8738C4.26574 13.8738 1.19029 10.9997 1.19029 7.43654C1.19029 3.87341 4.26574 0.999259 8.03853 0.999259ZM8.48554 8.26011V4.34396C8.48554 4.10866 8.28885 3.92377 8.03853 3.92377C7.78821 3.92377 7.59151 4.10866 7.59151 4.34396V7.83992L5.66042 7.83981C5.4101 7.83981 5.2134 8.02469 5.2134 8.25999C5.2134 8.49529 5.4101 8.68018 5.66042 8.68018H8.03853C8.28885 8.68018 8.48554 8.49541 8.48554 8.25999V8.26011Z"
        fill={fill}
      />
    </svg>
  );
};

export default ClockIcon;
