export default {
  selectRoofingProposals: (state) => state.roofingProposals.roofingProposals.list,
  selectRoofingProposalsPaging: (state) => state.roofingProposals.roofingProposals.paging,
  selectRoofingProposalDetailsById: (state) => state.roofingProposals.details.id,
  selectAdminRoofingProposalDetails: (state) => state.roofingProposals.detail,
  selectRoofingActionDetails: (state) => state.roofingProposals.selectedRoofingAction,
  selectRoofingUsersAutocomplete: (state) => state.roofingProposals.roofingUsersAutocomplete,
  selectRoofingCsv: (state) => state.roofingProposals.roofingCsv,
  selectRoofingProposalAssets: (state) => state.roofingProposals.roofingS3Assets,
};
