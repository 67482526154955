import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import _ from "lodash";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* banUser(action) {
  const { userId: id } = action.payload;

  const { success, data, error } = yield* makeRequest.post(`/users/${id}/ban`);
  if (success && data) {
    const user = keysToCamelCase(_.clone(data.body));
    yield put(
      actions.newSuccessEvent({
        message: messages.BAN_USER_SUCCESS,
      }),
    );
    yield put(actions.setUserDetails(user));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_BANNING_USER,
    });
  }

  return null;
}

export default banUser;
