import z from "zod";
import ZodSchemaUtil from "util/schema-helpers/ZodSchemaUtil";
import { EnrollmentSchema, EnrollmentServiceSchema } from "../../enrollment/schemas";
import { ProjectEligibilities } from "../types/enums";

export const AddressSchema = z.object({
  street: z.string().nullable(),
  unit: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  zip: z.string().nullable(),
  country: z.string().nullable(),
  status: ZodSchemaUtil.makeStringLiteralUnion(["unverified", "verified", "lookup_error", "locked"]),
  lat: z.union([z.number(), z.string()]).nullable(),
  long: z.union([z.number(), z.string()]).nullable(),
  googleMapsUrl: z.string().nullable(),
  geocodePrecision: z.string().nullable(),
  geocodeLat: z.union([z.number(), z.string()]).nullable(),
  geocodeLong: z.union([z.number(), z.string()]).nullable(),
});

export const AvatarSchema = z.object({
  thumb: z.string().nullable(),
  preview: z.string().nullable(),
  large: z.string().nullable(),
  retina: z.string().nullable(),
});

export const MarginBonusSchema = z.object({ endDate: z.string(), percentage: z.string(), id: z.number() });

export const CreateMarginBonusSchema = z.object({
  userId: z.number(),
  params: MarginBonusSchema.omit({ id: true }),
});

export const EndMarginBonusSchema = z.object({
  userId: z.number(),
  id: z.number(),
});

export const UserLiteSchema = z.object({
  id: z.number(),
  fullName: z.string(),
  email: z.string(),
  phone: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  vanityName: z.string(),
  locale: z.string(),
  spokenLanguages: z.array(z.string()),
  terminatedAt: z.string().nullable(),
  bio: z.string().nullable(),
  confReg: z.string().nullable(),
  proposalBio: z.string().nullable(),
  attendedPowurOn: z.boolean(),
  avatar: AvatarSchema,
  sellerAvatar: AvatarSchema,
  seatId: z.number().nullable(),
  enterpriseId: z.number().nullable(),
  enterpriseSellerPercentage: z.string().nullish(),
  enterpriseSellerInvitesAllowed: z.boolean().nullish(),
  enterpriseName: z.string().nullish(),
  contractCount: z.number().nullish(),
  proposalCount: z.number().nullish(),
  conversionRate: z.string().nullish(),
  cancellationRate: z.string().nullish(),
  isOrg: z.boolean().nullish(),
  marginBonusPercentage: MarginBonusSchema.nullish(),
});

export const UserItemSchema = z
  .object({
    metrics: z.array(z.record(z.number())),
    mentorRatings: z
      .object({
        contractCount: z.number(),
        installCount: z.number(),
        proposalCount: z.number(),
        totalCount: z.number(),
        contractAvg: z.string(),
        installAvg: z.string(),
        proposalAvg: z.string(),
        meanOfAvgs: z.string(),
      })
      .nullish(),
    rank: z.number(),
    maritalStatus: ZodSchemaUtil.makeStringLiteralUnion(["married", "single"]).nullable(),
    dob: z.string().nullable(),
    defaultAdvance: z.boolean(),
    defaultAdvanceSet: z.boolean(),
    gender: ZodSchemaUtil.makeStringLiteralUnion(["male", "female", "non_binary", "prefer_not_to_say"]).nullable(),
    areasServiced: z.array(z.string()),
    sponsors: z.array(z.number()),
    timezone: z.string().nullable(),
    qualifiedUntil: z.string().nullable(),
    lastQualifyingActivityAt: z.string().nullable(),
    subscriptionStatus: ZodSchemaUtil.makeStringLiteralUnion([
      "active",
      "canceled",
      "past_due",
      "canceling",
      "setter",
      "needs_certification",
    ]),
    authFormat: ZodSchemaUtil.makeStringLiteralUnion(["any_auth", "password", "facebook", "google"]),
    orgName: z.string().nullable(),
    marginVisibility: z.boolean(),
    commissionVisibility: z.boolean(),
    sellerCalendlyUrl: z.string().nullable(),
    homeownerCalendlyUrl: z.string().nullable(),
    enterprise: z
      .object({
        external: z.boolean(),
        isOwner: z.boolean(),
        recognitionEmails: z.boolean(),
        owner: z.object({ id: z.number() }),
      })
      .nullish(),
    displayRoles: z.array(z.array(z.string())),
    roles: z.array(z.string()),
    sponsor: UserLiteSchema.nullish(),
    ewalletUsername: z.string().nullish(),
    ipayoutAdress: z.string().nullish(),
    manager: UserLiteSchema.nullish(),
    relatedLevel: z.number(),
    certified: z.boolean(),
    integrityModules: z.array(z.string()).nullable(),
    projectRequirementMet: z.boolean(),
    projectRequirement: z.number(),
    tierThreeProjectRequirementMet: z.boolean(),
    tier2OrEnterpriseCertified: z.boolean(),
    certifications: z.array(z.string()),
    enrollment: z.lazy(() => EnrollmentSchema.nullish()),
    emailChangeRequest: z
      .object({
        newEmail: z.string(),
        expiresAt: z.string(),
      })
      .nullish(),
    payEligibilities: z.array(z.nativeEnum(ProjectEligibilities)).refine((els) => new Set(els).size === els.length, {
      message: "Duplicate values are not allowed",
    }),
    address: AddressSchema.nullish(),
    seat: z
      .object({
        id: z.number(),
        startDay: z.string(),
        paymentStatus: z.literal("seated"),
      })
      .nullish(),
  })
  .merge(UserLiteSchema);

export const UserSchema = z
  .object({
    stripeOauthId: z.number().nullable(),
    metaData: z.object({}),
    ambassadorEarnings: z.number().nullish(),
    dashboardPhase: z.number(),
    kind: ZodSchemaUtil.makeStringLiteralUnion([
      "consultant",
      "ambassador",
      "installer",
      "enterprise",
      "enterprise_pro",
    ]).nullable(),
    manualKindSelected: z.boolean(),
    inboxCount: z.number(),
    myProjectsCount: z.number(),
    tooltipViewedOn: z.record(z.string()).nullable(),
    createdAt: z.string(),
    emailHash: z.string(),
    enterpriseType: z.string().nullable(),
    seatAssignments: z.array(z.unknown()),
    acceptedLatestTos: z.boolean(),
  })
  .merge(UserItemSchema);

export const CoordinatorSchema = UserLiteSchema.pick({
  id: true,
  fullName: true,
  email: true,
  phone: true,
  avatar: true,
}).merge(z.object({ sellerCalendlyUrl: z.string() }));

export const AmbassadorSchema = UserLiteSchema.pick({
  id: true,
  fullName: true,
  email: true,
  phone: true,
  avatar: true,
});

export const AgentSchema = CoordinatorSchema.omit({ sellerCalendlyUrl: true }).merge(
  z.object({ siteSurveyor: z.boolean() }),
);

export const MentorSchema = AgentSchema.merge(
  z.object({
    metrics: z.array(z.record(z.number())),
    mentorRatings: z.record(z.union([z.string(), z.number()])),
    locale: z.string(),
    spokenLanguages: z.array(z.string()),
    sellerCalendlyUrl: z.string().nullable(),
    homeownerCalendlyUrl: z.string().nullable(),
    address: z.object({
      city: z.string(),
      state: z.string(),
    }),
  }),
).omit({ siteSurveyor: true });

const PendingSeatSchema = z.object({
  id: z.number(),
  registrationId: z.number(),
  serviceIds: z.array(z.number()),
  discardedAt: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
  enterpriseId: z.number(),
  userId: z.number().nullable(),
  enterpriseSettingId: z.number().nullable(),
});

const SeatSettingsSchema = z.object({
  accountAndTeamEmails: z.boolean(),
  autoOfferAcceptance: z.boolean(),
  cobrandingActivated: z.boolean(),
  compensationEmails: z.boolean(),
  eventEmails: z.boolean(),
  installerAssignment: z.string().nullable(),
  installerAssignmentEmail: z.string().nullable(),
  leadGeneratorAmount: z.number().nullable(),
  leadGeneratorPercentage: z.string().nullable(),
  managerCommission: z.number().nullable(),
  managerMarginAdjustment: z.boolean(),
  managerMinimumMargin: z.string(),
  marginFixed: z.boolean(),
  mentorshipRequirement: z.number(),
  minimumMargin: z.number().nullable(),
  newsletterEmails: z.boolean(),
  offerPercentage: z.string().nullable(),
  offerSplitPercentage: z.string().nullable(),
  payForProposals: z.boolean(),
  platformEmails: z.boolean(),
  promotionEmails: z.boolean(),
  proposalMargin: z.string().nullable(),
  recognitionEmails: z.boolean(),
  selfGenSplitPercentage: z.string().nullable(),
  sellerCommissionVisibility: z.boolean(),
  sellerInvitesAllowed: z.boolean(),
  sellerMarginVisibility: z.boolean(),
  sellerPercentage: z.string().nullable(),
  supportCommission: z.number().nullable(),
  title: z.string().nullable(),
  trainingEmails: z.boolean(),
  updatesLeadGenerators: z.boolean(),
  updatesManagers: z.boolean(),
  updatesSellers: z.boolean(),
  updatesSupport: z.boolean(),
});

export const EnterpriseUserSchema = z
  .object({
    seated: z.boolean(),
    salesPro: z.boolean(),
    orgMentor: z.boolean(),
    staffServices: z.array(EnrollmentServiceSchema),
    currentStaffServicesRoleId: z.number().nullable(),
    pendingSeat: PendingSeatSchema.nullish(),
    seatAssignments: z.array(
      z.object({
        role: z.string(),
        status: z.string(),
        start: z.string(),
        end: z.string(),
        org: z.string().nullish(),
      }),
    ),
    ewallet: z.object({
      ewalletUsername: z.string(),
      ipayoutAddress: z.string().nullish(),
    }),
    settings: SeatSettingsSchema.nullish(),
    enterpriseSettings: SeatSettingsSchema.nullish(),
  })
  .merge(UserItemSchema);
