import { put } from "redux-saga/effects";
import { keysToCamelCase } from "lib/helpers";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* getAssets(action) {
  const { leadId } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/provider_leads/${leadId}/assets`, {});
  if (success && data) {
    yield put(actions.setAssets(data.body.assets.map(keysToCamelCase)));
    yield put(actions.setOpsDropAssetsUrl(data.body.ops_drop_assets_url));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROJECT_ASSETS,
    });
  }
  return null;
}

export default getAssets;
