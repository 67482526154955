const commonTabs = [
  {
    key: "consultant-report",
    label: "Seller Report",
  },
];

const adminTabs = [];

export { commonTabs, adminTabs };

export default [...commonTabs, ...adminTabs];
