import React, { useMemo, ReactNode } from "react";
import { FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import useWindowResize from "hooks/useWindowResize";
import { OpenInNewWindowIcon } from "components/Icons";

import * as S from "./styles";

// create type for applicationAgreement
export type ApplicationAgreementT = {
  documentPath: string;
  message: string;
  version: string;
};

// create type for handleChecked
export type CheckedHandlerT = (isChecked: boolean) => void;

const TermsAndConditions = ({
  handleChecked,
  applicationAgreement,
}: {
  handleChecked: CheckedHandlerT;
  applicationAgreement: ApplicationAgreementT;
}) => {
  const { t } = useTranslation(["join", "form_labels"]);

  const { width } = useWindowResize();
  const mobile = useMemo(() => {
    return width <= 768;
  }, [width]);

  const handleOpenTerms = () => {
    window.open(applicationAgreement.documentPath, "_blank");
  };

  const TermContainer = mobile ? S.MobileTermsContainer : S.SelectTermsContainer;
  const TermsUpdates = mobile ? S.MobileTermsUpdates : S.TermsUpdates;
  const FontSpan = mobile ? S.MobileFontSpan : S.FontSpan;

  return (
    <S.TermsAndConditionsContainer data-test-id="terms-and-conditions-form">
      <S.TermsAndConditionsHeader>
        <S.VerificationLabel>{t("join_form.terms") as ReactNode}</S.VerificationLabel>
        <S.OpenNewWindow>
          <FontSpan>{t("join_form.new_window") as ReactNode}</FontSpan>
          <S.NewWindowLink
            tabIndex={0}
            role="button"
            onClick={() => handleOpenTerms()}
            onKeyDown={() => handleOpenTerms()}
          >
            <OpenInNewWindowIcon />
          </S.NewWindowLink>
        </S.OpenNewWindow>
      </S.TermsAndConditionsHeader>
      <TermsUpdates>{applicationAgreement.message}</TermsUpdates>
      <S.TermsText>
        <S.TermsPdf
          src={applicationAgreement.documentPath}
          allowFullScreen
          title="Terms and Conditions"
          height="100%"
          width="100%"
        />
      </S.TermsText>
      <TermContainer>
        <FormControlLabel
          label={t("join_form.accept_terms") as ReactNode}
          control={<S.TermsCheckbox onChange={(e) => handleChecked(e.target.checked)} />}
        />
      </TermContainer>
    </S.TermsAndConditionsContainer>
  );
};

export default TermsAndConditions;
