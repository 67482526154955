import React from "react";
import { useTranslation } from "react-i18next";
import UserCard from "./userCard";
import JBCard from "./JBCard";
import styles from "../../Team.module.less";

const Leadership = () => {
  const { t } = useTranslation("team");

  const userData = [
    {
      name: "Ethan Miller",
      title: t("body.ethanMiller"),
    },
    {
      name: "Mike Lindmark",
      title: t("body.mikeLindmark"),
    },
    {
      name: "Cami Boehme",
      title: t("body.camiBoehme"),
    },
    {
      name: "Dean Rosenberg",
      title: t("body.deanRosenberg"),
    },
    {
      name: "Bobby Smith",
      title: t("body.bobbySmith"),
    },
    {
      name: "Nick Phillips",
      title: t("body.nickPhillips"),
    },
    {
      name: "Kevin Smoker",
      title: t("body.kevinSmoker"),
    },
    {
      name: "Chris Bunch",
      title: t("body.chrisBunch"),
    },
    {
      name: "Kristin Dennis",
      title: t("body.kristinDennis"),
    },
    {
      name: "Daniel DiFiore",
      title: t("body.danielDiFiore"),
    },
    {
      name: "Dallas Bradshaw",
      title: t("body.dallasBradshaw"),
    },
    {
      name: "Shannon Joesting",
      title: t("body.shannonJoesting"),
    },
    {
      name: "Noah Baieve",
      title: t("body.noahBaieve"),
    },
    {
      name: "Paul Kitchen",
      title: t("body.paulKitchen"),
    },
    {
      name: "Raichail McDonald",
      title: t("body.raichailMcDonald"),
    },
    {
      name: "Beth Robinson",
      title: t("body.bethRobinson"),
    },
    {
      name: "Maria DeGuzman",
      title: t("body.mariaDeGuzman"),
    },
    {
      name: "Daniel Howson",
      title: t("body.danielHowson"),
    },
    {
      name: "Justin Brooks",
      title: t("body.justinBrooks"),
    },
    {
      name: "Monty Campbell",
      title: t("body.montyCampbell"),
    },
    {
      name: "Brady Anderson",
      title: t("body.bradyAnderson"),
    },
  ];
  return (
    <div className={styles.leadershipContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.leadBlurbContainer}>
          <p className={styles.leadBlurb}>{t("body.lead_blurb")}</p>
        </div>
        <JBCard />
        <div className={styles.userCardContainer}>
          {userData.map((u) => {
            return <UserCard key={u.name} {...u} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Leadership;
