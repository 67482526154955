import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getEnterpriseCobranding() {
  const { success, data, error } = yield* makeRequest.get("/enterprise/cobranding_info");
  if (success && data) {
    yield put(actions.setEnterpriseCobranding(camelizeKeys(data.body)));
    yield put(actions.setEnterpriseAssets(data.body.assets));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ENTERPRISE_COBRANDING,
    });
  }
  return null;
}

export default getEnterpriseCobranding;
