import PropTypes from "prop-types";

export const closeReasonSchema = PropTypes.shape({
  id: PropTypes.number,
  description: PropTypes.string,
  order: PropTypes.number,
});

export const closeReasonDefaults = {
  id: 0,
  description: "",
  order: 0,
};

export const closeReasonsSchema = PropTypes.arrayOf(
  PropTypes.shape({
    class: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      order: PropTypes.number,
    }),
  }),
);

export const closeReasonsDefaults = [];
