import React from "react";
import { Typography, Link, Grid, styled } from "@mui/material";
import { cvar } from "styles";
import * as S from "./styles";

export default function AddOnCompatibilityDisclaimer() {
  return (
    <>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "17px",
          textAlign: "left",
          color: `${cvar("congress-blue")}`,
        }}
      >
        Add On Compatibility:
      </Typography>
      <S.MessageText>
        <Grid container spacing={2}>
          <Grid
            item
            sx={{
              fontWeight: 600,
            }}
          >
            The ability to add system capacity is determined by the new equipment compatibility with the existing
            equipment. A few examples of configurations that would not qualify for a system add on are:
          </Grid>
          <Grid item sx={{ fontStyle: "normal" }}>
            <ul>
              <li>If existing storage is present, we cannot add more storage of a different manufacturer</li>
              <li>
                New systems could impact the existing utility rate and agreement. Preserving existing utility rate is a
                priority in markets like CA where homeowners have NEM 1 and want to avoid Nem 3.
              </li>
            </ul>
          </Grid>
          <Grid item>
            For more information on system add ons, resources can be found in our &nbsp;
            <StyledLink
              href="https://help.powur.com/hc/en-us/articles/17486596655131-Powur-Scope-of-Work"
              target="_blank"
              rel="noopener noreferrer"
              underline="always"
            >
              SOW Policy
            </StyledLink>
            &nbsp; or in market spotlights (ex.
            <StyledLink
              href="https://help.powur.com/hc/en-us/articles/9502240364955-Market-Spotlight-California"
              target="_blank"
              rel="noopener noreferrer"
              underline="always"
            >
              CA guidance
            </StyledLink>
            ).
          </Grid>
        </Grid>
      </S.MessageText>
    </>
  );
}

const StyledLink = styled(Link)(() => ({
  fontWeight: 700,
  color: `${cvar("congress-blue")}`,
}));
