import React from "react";

interface PmNotesIconPropsI {
  height?: string | number;
  width?: string | number;
  fill?: string;
}

const PmNotesIcon = ({ height = "13", width = "14", fill = "white" }: PmNotesIconPropsI) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13 12" fill="none">
      <path
        d="M9.33919 8.03076C9.30052 8.03076 9.26342 8.04503 9.23607 8.07042C9.20872 8.09582 9.19336 8.13026 9.19336 8.16618V11.0099C9.2693 10.9585 9.34141 10.9024 9.40919 10.842L12.2209 8.23118C12.2859 8.16824 12.3464 8.10128 12.4017 8.03076H9.33919Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.31901 8.16618C8.32054 7.91521 8.42859 7.67493 8.6197 7.49747C8.81081 7.32001 9.06958 7.21968 9.33984 7.21826H12.6882V7.17493V2.20785C12.6882 1.81372 12.52 1.43566 12.2204 1.15646C11.9208 0.877265 11.5143 0.719695 11.0898 0.718262H1.75651C1.33106 0.718262 0.923033 0.8752 0.622193 1.15455C0.321354 1.4339 0.152344 1.81278 0.152344 2.20785V9.79118C0.152344 10.1862 0.321354 10.5651 0.622193 10.8445C0.923033 11.1238 1.33106 11.2808 1.75651 11.2808H8.31901V8.16618ZM5.18174 5.7698V2.5708L1.98242 5.77012H5.18143V8.9709L8.38252 5.7698H5.18174Z"
        fill="white"
      />
    </svg>
  );
};
export default PmNotesIcon;
