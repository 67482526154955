import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/financials/types";
import getPayoutsList from "./getPayoutsList";
import deletePayout from "./deletePayout";
import distributePayouts from "./distributePayouts";
import topUpPayouts from "./topUpPayouts";
import createPayout from "./createPayout";
import payoutToPowur from "./payoutToPowur";
import getPaymentMilestones from "./getPaymentMilestones";
// IMPORT_PT (for script, do not remove)

function* watchFinancialsSagas() {
  yield trackRequests(takeEvery, types.GET_PAYOUTS_LIST_REQUEST, getPayoutsList);
  yield trackRequests(takeEvery, types.DELETE_PAYOUT_REQUEST, deletePayout);
  yield trackRequests(takeEvery, types.DISTRIBUTE_PAYOUTS_REQUEST, distributePayouts);
  yield trackRequests(takeEvery, types.TOP_UP_PAYOUTS_REQUEST, topUpPayouts);
  yield trackRequests(takeEvery, types.CREATE_PAYOUT_REQUEST, createPayout);
  yield trackRequests(takeEvery, types.PAYOUT_TO_POWUR_REQUEST, payoutToPowur);
  yield trackRequests(takeEvery, types.GET_PAYMENT_MILESTONES_REQUEST, getPaymentMilestones);
  // INSERTION_PT (for script, do not remove)
}

export default watchFinancialsSagas;
