import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/settings/messages";
import { camelizeKeys } from "lib/helpers";

function* getPowurPairWeights(action = { payload: {} }) {
  const { success, data, error } = yield* makeRequest.get("/powur_pair_weights", action.payload);

  if (success && data) {
    const powurPairWeights = camelizeKeys(data.body.powur_pair_weights);
    yield put(actions.setPowurPairWeights(powurPairWeights));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_POWUR_PAIR_WEIGHTS,
    });
  }

  return null;
}

export default getPowurPairWeights;
