import { put, all } from "redux-saga/effects";
// import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* deleteMentorStatusChange(action) {
  const mentor_status_id = action.payload;

  yield true;
  const { success, data, error } = yield* makeRequest.delete("/profile/delete_mentor_break", {
    mentor_status_id,
  });
  if (success && data) {
    yield all([
      put(actions.setProfile(keysToCamelCase(data.body))),
      put(
        actions.newSuccessEvent({
          message: messages.DELETE_MENTOR_STATUS_CHANGE_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_MENTOR_STATUS_CHANGE,
    });
  }
  return null;
}

export default deleteMentorStatusChange;
