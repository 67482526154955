import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "../types";
import getSmsList from "./getSmsList";
import createSms from "./createSms";
import getSmsDetails from "./getSmsDetails";
import deleteSms from "./deleteSms";
import updateSmsDetail from "./updateSmsDetail";

function* watchSmsSagas() {
  yield trackRequests(takeEvery, types.GET_SMS_LIST_REQUEST, getSmsList);
  yield trackRequests(takeEvery, types.CREATE_SMS_REQUEST, createSms);
  yield trackRequests(takeEvery, types.DELETE_SMS, deleteSms);
  yield trackRequests(takeEvery, types.GET_SMS_DETAILS_REQUEST, getSmsDetails);
  yield trackRequests(takeEvery, types.UPDATE_SMS_DETAIL, updateSmsDetail);

  // INSERTION_PT (for script, do not remove)
}

export default watchSmsSagas;
