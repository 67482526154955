import { put, select, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import { keysToCamelCase } from "lib/helpers";
import _ from "lodash";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/workflows/selectors";
import messages from "../../messages";

function* updateMilestone(action) {
  const { id, title, required, prerequisiteId, editableBy, dataGroups, confirm, oldField } = action.payload;

  const { success, data, error } = yield* makeRequest.patch(`/milestones/${id}`, {
    title,
    required,
    prerequisite_id: prerequisiteId,
    editable_by: editableBy,
    data_groups: dataGroups,
    confirm,
    old_field: oldField,
  });
  if (success && data) {
    if (data.warning) {
      yield put(
        actions.newMessageEvent({
          message: messages.WARNING_REASSIGN_DATA_GROUP_TO_NEW_MILESTONE,
          warning: data.warning,
          payload: action.payload,
        }),
      );
    } else {
      const newMilestone = keysToCamelCase(data.body.milestone);
      const workflow = yield select(selectors.getWorkflowDetails);
      const newWorkflow = _.clone(workflow);
      const newPhases = newWorkflow.phases.map((p) =>
        p.milestones.find((ms) => ms.id === parseInt(id, 10))
          ? {
              ...p,
              milestones: p.milestones.map((ms) => (ms.id === parseInt(id, 10) ? newMilestone : ms)),
            }
          : p,
      );
      yield all([
        yield put(actions.setWorkflowDetails({ ...workflow, phases: newPhases })),
        yield put(
          actions.newSuccessEvent({
            message: messages.MILESTONE_UPDATE_SUCCESS,
            id,
          }),
        ),
      ]);
    }
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_MILESTONE,
    });
  }
  return null;
}

export default updateMilestone;
