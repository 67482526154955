import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styles from "../../EnterpriseJoin.module.less";

const TextPanel = (props) => {
  const { section } = props;
  const { t } = useTranslation(["enterprise_join"]);

  return (
    <div className={styles.panel}>
      <div className={styles.angledAccent} />
      <div className={styles.innerContainer}>
        <div className={styles.title}>{t(`${section}.section_title`)}</div>
        <div className={styles.line} />
        <div className={styles.subheading}>{t(`${section}.title`)}</div>
        <div className={styles.text}>{t(`${section}.text`)}</div>
      </div>
    </div>
  );
};

TextPanel.propTypes = {
  section: PropTypes.string,
};

export default TextPanel;
