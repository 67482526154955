import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getLatestTermsOfService() {
  const { success, data, error } = yield* makeRequest.get("/application_agreement", { kind: "auto" });

  if (success && data) {
    yield put(actions.setLatestTermsOfService(keysToCamelCase(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_LATEST_TOS,
    });
  }

  return null;
}

export default getLatestTermsOfService;
