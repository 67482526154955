import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  incidents: [],
  incidentCount: 0,
  issues: [],
  paging: {
    itemCount: 0,
    pageNumber: 1,
    pageSize: 3,
  },
};

export default {
  complianceIssues: createReducer(initialState, {
    [types.SET_COMPLIANCE_ISSUES](state, action) {
      return {
        ...state,
        incidents: action.payload.incidents || state.incidents,
        issues: action.payload.issues || state.issues,
        paging: action.payload.paging || state.paging,
        incidentCount: action.payload.incidentCount || state.incidentCount,
      };
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};
