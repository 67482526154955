import React from "react";

const LeadGeneratorIcon = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="71" cy="71" r="71" fill="var(--lightest-grey)" />
      <mask
        id="mask0_4228_3068"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="142"
      >
        <circle cx="71" cy="71" r="71" fill="var(--lightest-grey)" />
      </mask>
      <g mask="url(#mask0_4228_3068)">
        <path
          d="M59.166 77.5391C61.6722 79.5149 66.0237 80.831 71.001 80.831C75.9783 80.831 80.3298 79.5149 82.836 77.5391"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M45.9815 63.8154C45.9815 63.8154 37.0891 67.2869 48.6419 78.7839C48.6419 78.7839 54.0513 91.641 71.0021 91.641C87.9529 91.641 92.3013 78.7839 92.3013 78.7839C103.593 70.6086 96.5141 64.7275 96.5141 64.7275C96.5141 64.7275 103.446 53.0521 99.7978 39.0051C99.7978 39.0051 98.457 34.7144 94.5238 33.6418C90.0616 32.4248 85.6956 30.9069 81.9256 28.2276C77.2397 24.8974 69.6162 22.1616 58.3692 25.5594C58.3692 25.5594 30.1102 35.7214 45.9815 63.8154Z"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M98.0478 101.133L70.9999 128.181L43.952 101.133"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M42.9594 57.3463L53.9592 60.5215C53.9592 60.5215 52.739 54.3811 57.9564 48.9023C57.9564 48.9023 62.1891 59.6643 82.3312 61.8931C82.3312 61.8931 82.3312 53.6381 88.3573 50.9965C88.3573 50.9965 89.2608 62.5268 97.6088 62.5268"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path d="M26.6522 128.348H50.1305" stroke="var(--royal-peacock-blue)" strokeWidth="3" strokeMiterlimit="10" />
        <path
          d="M60.5545 89.7142C60.313 95.1454 56.0067 99.5131 50.5306 99.9212C50.3727 99.933 50.2384 99.9451 50.1315 99.9577C28.097 102.539 11 121.274 11 144.002V144.002H131V144.002C131 121.274 113.903 102.539 91.8685 99.9577C91.7616 99.9451 91.6273 99.933 91.4694 99.9212C85.9712 99.5114 81.6523 95.1101 81.4428 89.6484"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
};

export default LeadGeneratorIcon;
