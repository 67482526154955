import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/earnings/messages";
import { camelizeKeys } from "lib/helpers";

function* getEarningsHistoryRequest(action) {
  const params = action.payload;

  const { success, data, error } = yield* makeRequest.get("/earnings/ledger", params);

  const { kpi, paging, payments } = camelizeKeys(data.body);

  const mutatedPayments = payments.map((payment) => {
    if (payment.mentor) {
      const withTierSeller = {
        ...payment,
        tierThreeSellerPro: {
          ...payment.mentor,
        },
      };
      delete withTierSeller.mentor;
      return withTierSeller;
    }
    return payment;
  });

  if (success && data) {
    yield put(
      actions.setEarningsHistory({
        kpi,
        paging,
        payments: mutatedPayments,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_EARNINGS_HISTORY,
    });
  }

  return null;
}

export default getEarningsHistoryRequest;
