import React from "react";
import { cvar } from "styles";

type HomeownerNotesIconT = {
  width?: number | string;
  height?: number | string;
  backgroundFill?: string;
  houseFill?: string;
};
const HomeownerNotesIcon = ({
  width = "13",
  height = "11",
  backgroundFill = cvar("white"),
  houseFill = cvar("adamantine-blue"),
}: HomeownerNotesIconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13 11">
      <path
        d="M8.611 7.75651C8.61253 7.50555 8.72058 7.26526 8.91169 7.0878C9.1028 6.91034 9.36157 6.81002 9.63184 6.80859H12.9802C12.9802 6.80859 12.9802 6.78151 12.9802 6.76526V1.79818C12.9802 1.40405 12.812 1.02599 12.5124 0.746793C12.2128 0.467597 11.8063 0.310027 11.3818 0.308594H2.0485C1.62305 0.308594 1.21503 0.465532 0.914186 0.744883C0.613346 1.02423 0.444336 1.40312 0.444336 1.79818V9.38151C0.444336 9.77657 0.613346 10.1555 0.914186 10.4348C1.21503 10.7142 1.62305 10.8711 2.0485 10.8711H8.611V7.75651Z"
        fill={backgroundFill}
      />
      <path
        d="M9.63216 7.62109C9.59348 7.62109 9.55639 7.63536 9.52904 7.66076C9.50169 7.68615 9.48633 7.7206 9.48633 7.75651V10.6003C9.56227 10.5489 9.63438 10.4928 9.70216 10.4323L12.5138 7.82151C12.5789 7.75857 12.6393 7.69161 12.6947 7.62109H9.63216Z"
        fill={backgroundFill}
      />
      <path
        d="M5.50551 2.85015L7.75845 5.09409C7.82901 5.1644 7.86807 5.25795 7.86807 5.35672C7.86827 5.4056 7.85873 5.45403 7.84002 5.49921C7.8213 5.54439 7.79378 5.58542 7.75904 5.61993C7.68849 5.69023 7.59461 5.72916 7.49491 5.72916H7.24186V7.42158C7.24186 7.53204 7.15232 7.62158 7.04186 7.62158H3.40826C3.2978 7.62158 3.20826 7.53204 3.20826 7.42158V5.72916H2.96046C2.75522 5.72916 2.58439 5.56589 2.58206 5.36137C2.58149 5.31178 2.5909 5.26258 2.60972 5.21668C2.62855 5.17077 2.65641 5.12909 2.69167 5.09409L5.09562 2.69966C5.13003 2.6656 5.17656 2.64648 5.22506 2.64648C5.27356 2.64648 5.32009 2.6656 5.3545 2.69966L5.50551 2.85015Z"
        fill={houseFill}
      />
      <path
        d="M4.52344 6.25273C4.52344 6.14228 4.61298 6.05273 4.72344 6.05273H5.72578C5.83624 6.05273 5.92578 6.14228 5.92578 6.25273V7.62109H4.52344V6.25273Z"
        fill={backgroundFill}
      />
      <path d="M4.89844 6.44238H5.55078V7.62109H4.89844V6.44238Z" fill={houseFill} />
    </svg>
  );
};

export default HomeownerNotesIcon;
