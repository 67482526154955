import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enterprisePersonnel/messages";
import { camelizeKeys } from "@helpers";

function* getPendingSeat(action) {
  const { pendingSeatId } = action.payload;

  const { success, data, error } = yield* makeRequest.get(`/pending_seats/${pendingSeatId}`, {});

  if (success && data) {
    yield put(actions.setPendingSeat(camelizeKeys(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PENDING_SEAT,
    });
  }

  return null;
}

export default getPendingSeat;
