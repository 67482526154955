import React, { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import queryString from "query-string";
import { Button } from "antd";
import StorageClient from "util/StorageClient";
import SidebarFilters from "components/PlatformLayout/components/Sidebar/Filters";
import SidebarSearch from "components/PlatformLayout/components/Sidebar/Search";
import SidebarAutoComplete from "components/PlatformLayout/components/Sidebar/AutoComplete";
import DateRangeNavigate from "components/Pickers/DateRange";
import Dropdown from "components/Tickets/components/Dropdown";
import styles from "./sidebarStyles.module.less";

const InboxSidebar = (props) => {
  const {
    navigate,
    search,
    filters: { filters, sorts },
    suggestions,
    filterToTeamOptions,
    setInboxAutoCompleteSuggestions: setSuggestions,
    setInboxAutoCompleteSuggestions: resetSuggestions,
  } = props;

  const history = useHistory();
  const storage = new StorageClient();
  const [observedTeamMember, setObservedTeamMember] = useState(
    <>
      <div style={{ fontSize: "12px", color: "var(--royal-peacock-blue)" }}>
        <span style={{ fontWeight: 400 }}>Me</span>
      </div>
    </>,
  );

  const selects = useMemo(() => {
    const out = _.filter(filters, (f) => f && (_.isArray(f.values) || _.isObject(f.values)));
    if (sorts && !_.isEmpty(sorts)) {
      out.push({ name: "sort", values: sorts, default: sorts[0] });
    }
    return out;
  }, [filters, sorts]);

  const lookups = useMemo(() => {
    const fields = _.filter(filters, (f) => f && _.isString(f.values) && f.values.includes("/json_web"));
    return fields.map((f) => (
      <InboxAutoComplete
        key={f.name}
        navigate={navigate}
        lookupField={f}
        suggestions={suggestions[f.name]}
        setSuggestions={setSuggestions}
        resetSuggestions={resetSuggestions}
        minCharacters={f.name === "assignee_id" ? 1 : 2}
        search={search}
      />
    ));
  }, [filters, suggestions, search]); // eslint-disable-line react-hooks/exhaustive-deps
  const filterKeys = useMemo(() => selects.map((s) => s.name), [selects]);

  const filterMenus = useMemo(() => {
    const label = (s, i) => {
      if (s.label) return s.label;

      return filterKeys[i].includes("_id") ? _.startCase(filterKeys[i].replace("_id", "")) : _.startCase(filterKeys[i]);
    };

    return selects.map((s, i) => {
      const returnOptions = () => {
        const optsArr = _.isArray(s.values) ? s.values : Object.keys(s.values);
        return optsArr.map((v) => ({
          key: v,
          label: _.isArray(s.values) ? v : s.values[v],
          value: v,
          parentKey: filterKeys[i],
          default: s.default === v,
          index: i,
        }));
      };
      const out = {
        key: filterKeys[i],
        label: label(s, i),
        default: s.default,
        options: returnOptions(),
      };

      if (!out.default) {
        out.options.unshift({
          key: `${filterKeys[i]}-any`,
          label: "All",
          parentKey: filterKeys[i],
          default: true,
          index: i,
        });
      }

      return out;
    });
  }, [selects, filterKeys]);

  const onSelect = (key, value) => {
    let storedFilters = { ...storage.inboxFilters.tickets };
    if (value) {
      storedFilters = { ...storedFilters, [key]: value };
    } else {
      delete storedFilters[key];
    }
    storage.setStorage({ tickets: storedFilters }, "inboxFilters");
  };

  const onResetFilters = () => {
    const { observed } = queryString.parse(search);
    const currentObserver = observed ? `observed=${observed}` : "";
    // navigate
    history.replace(`/tickets/inbox?${currentObserver}`);
    // reset storage
    storage.setStorage({ tickets: {} }, "inboxFilters");
  };

  const onTeamMemberSelect = (option, value) => {
    const selectionKey = value.key;
    let storedFilters = { ...storage.inboxFilters.tickets };
    setObservedTeamMember(
      <>
        <div style={{ fontSize: "12px", color: "var(--royal-peacock-blue)" }}>
          <span style={{ fontWeight: 400 }}>{value.data.fullName || value.data.label}</span>
        </div>
      </>,
    );
    if (selectionKey === "Me") {
      delete storedFilters.observed;
      storage.setStorage({ tickets: storedFilters }, "inboxFilters");
      navigate({ observed: null });
    } else {
      const assignedTeamId = selectionKey.split("-")[0];
      const assignedUserId = selectionKey.split("-")[1];
      const onTeamMemberFilter = assignedUserId ? `assignedUserId${assignedUserId}` : `assignedTeamId${assignedTeamId}`;
      delete storedFilters.observed;
      storedFilters = { ...storedFilters, observed: onTeamMemberFilter };
      storage.setStorage({ tickets: storedFilters }, "inboxFilters");
      navigate({ observed: onTeamMemberFilter });
    }
  };

  const filteredObservedUsers = filters?.observed?.users;
  const storageFiltersObserved = storage?.inboxFilters?.tickets?.observed;

  useEffect(() => {
    const out = [];
    if (!_.isEmpty(filteredObservedUsers) && !_.isEmpty(storageFiltersObserved)) {
      for (const u of filteredObservedUsers) {
        const isTeam = !!u.users;
        const o = { [`assignedTeamId${u.id}`]: u.fullName || u.label };
        out.push(o);

        if (isTeam) {
          for (const m of u.users) {
            const s = { [`assignedUserId${m.id}`]: m.fullName };
            out.push(s);
          }
        }
      }
      const observingUser = _.valuesIn(out.find((obj) => obj[storageFiltersObserved]))[0];

      const result = () => {
        if (_.isEmpty(observingUser)) {
          return (
            <>
              <div style={{ fontSize: "12px", color: "var(--royal-peacock-blue)" }}>
                <span style={{ fontWeight: 400 }}>Me</span>
              </div>
            </>
          );
        }
        return (
          <>
            <div style={{ fontSize: "12px", color: "var(--royal-peacock-blue)" }}>
              <span
              // style={{ fontWeight: 700 }}
              >
                {observingUser}
              </span>
            </div>
          </>
        );
      };
      setObservedTeamMember(result());
    }
  }, [filteredObservedUsers, storageFiltersObserved]);

  const showResetFilters = () => {
    const currentSearch = queryString.parse(search);
    if (currentSearch?.observed) {
      delete currentSearch.observed;
    }
    const currentSavedFilters = storage.inboxFilters.tickets;
    if (currentSavedFilters?.observed) {
      delete currentSavedFilters.observed;
    }
    return !_.isEmpty(currentSearch) || !_.isEmpty(currentSavedFilters);
  };

  return (
    <>
      <SidebarSearch navigate={navigate} searchKey="ticket_id" label="Ticket ID" />
      <SidebarFilters menus={filterMenus} navigate={navigate} onSelect={onSelect} />
      {lookups}
      <DateRangeNavigate navigate={navigate} onSelect={onSelect} />
      {filters?.defaultFrom && (
        <div className={styles.defaultFromText}>
          <span>Default start date: {filters.defaultFrom}</span>
        </div>
      )}
      {showResetFilters() && (
        <div className={styles.resetFiltersContainer}>
          <Button type="primary" shape="round" size={16} className={styles.resetFiltersButton} onClick={onResetFilters}>
            Reset Filters & Sort
          </Button>
        </div>
      )}
      {filters?.observed?.users && (
        <div className={styles.teamMemberFilter}>
          <br />
          <div className={styles.label}>Team Member</div>
          <Dropdown
            users={filters?.observed?.users}
            onSelect={onTeamMemberSelect}
            selectProps={{
              placeholder: observedTeamMember,
              popupClassName: "teamMemberDropdown",
              value: observedTeamMember,
            }}
          />
          {filterToTeamOptions.length > 0 && (
            <div className={styles.notice}>
              You currently have few tickets in this view, would you like to see
              {filterToTeamOptions.map((team) => (
                <span key={team.id}>
                  <button
                    type="button"
                    onClick={() =>
                      onTeamMemberSelect("", {
                        key: team.id.toString(),
                        data: { label: team.label },
                      })
                    }
                  >
                    - {team.ticketCount} unassigned {team.ticketCount === 1 ? "ticket" : "tickets"} from the{" "}
                    {team.label} team?
                  </button>
                </span>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const InboxAutoComplete = ({
  navigate,
  lookupField,
  suggestions,
  resetSuggestions,
  setSuggestions,
  minCharacters,
  search,
}) => {
  const { name, values, label } = lookupField;

  const users = useMemo(() => {
    if (suggestions) {
      return _.uniqBy(
        suggestions.map((u) => ({
          displayValue: u.fullName,
          key: u.id,
          data: u,
        })),
        "key",
      );
    }
    return [];
  }, [suggestions]);

  const generateLabel = () => {
    if (label) return label;
    return name.includes("_id") ? _.startCase(name.replace("_id", "")) : _.startCase(name);
  };

  return (
    <SidebarAutoComplete
      key={name}
      fieldKey={name}
      dataKey="id"
      label={generateLabel()}
      requestUrl={values.replace("/json_web", "")}
      suggestions={users}
      successActions={[setSuggestions]}
      resetSuggestions={() => resetSuggestions}
      navigate={navigate}
      minCharacters={minCharacters}
      usersDisplay
      search={search}
    />
  );
};

InboxSidebar.propTypes = {
  navigate: PropTypes.func,
  search: PropTypes.string,
  setInboxAutoCompleteSuggestions: PropTypes.func,
  // TODO: enumerate properties
  filters: PropTypes.shape({
    filters: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    sorts: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  }),
  suggestions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  filterToTeamOptions: PropTypes.array, // eslint-disable-line react/forbid-prop-types
};

export default connect(
  getRdxSelectionMapper({
    filters: "getInboxFilters",
    suggestions: "getInboxAutoComplete",
    filterToTeamOptions: "selectFilterToTeamOptions",
  }),
  getRdxActionMapper(["setInboxAutoCompleteSuggestions"]),
)(InboxSidebar);
