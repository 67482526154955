import React from "react";
import PropTypes from "prop-types";

const InProgressIcon = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8587 3.72796C13.8358 3.72338 13.8131 3.71813 13.7906 3.71222C13.3252 3.58962 12.9697 3.18513 12.9697 2.69367V2.53971C12.9697 1.98742 13.4182 1.53538 13.9686 1.58135C15.455 1.7055 16.9082 2.10595 18.2526 2.76516C19.897 3.57143 21.3351 4.74338 22.4566 6.19123C23.5781 7.63909 24.3533 9.32444 24.7229 11.1182C25.0924 12.9119 25.0465 14.7664 24.5887 16.5397C24.1308 18.313 23.2732 19.9579 22.0814 21.3485C20.8897 22.7391 19.3954 23.8385 17.7132 24.5624C16.0309 25.2863 14.2053 25.6156 12.3761 25.525C10.8806 25.4509 9.41511 25.0978 8.05444 24.4868C7.55061 24.2606 7.37702 23.648 7.6444 23.1647L7.71894 23.03C7.95686 22.6 8.46368 22.4181 8.9303 22.5362C8.9528 22.5419 8.97521 22.5483 8.99749 22.5553C9.0038 22.5574 9.01011 22.5594 9.0164 22.5615C9.0449 22.5712 9.07316 22.5819 9.10113 22.5939C10.1708 23.0509 11.3156 23.3159 12.4827 23.3737C13.9835 23.448 15.4814 23.1778 16.8617 22.5839C18.242 21.9899 19.4681 21.0878 20.4459 19.9468C21.4237 18.8058 22.1274 17.4562 22.5031 16.0012C22.8788 14.5462 22.9164 13.0246 22.6132 11.5528C22.31 10.0811 21.6739 8.69824 20.7537 7.51026C19.8335 6.32229 18.6536 5.36071 17.3044 4.69916C16.2552 4.18473 15.1253 3.86235 13.968 3.74441C13.9378 3.74133 13.9078 3.73707 13.8782 3.73169C13.8717 3.7305 13.8652 3.72926 13.8587 3.72796Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.87126 20.3784C5.86724 20.3732 5.86317 20.368 5.85905 20.3629C5.84014 20.3394 5.82021 20.3164 5.79928 20.2942C4.83923 19.2743 4.10769 18.0611 3.65382 16.7361C3.64392 16.7072 3.63294 16.6789 3.62094 16.6511C3.61833 16.6451 3.61567 16.6391 3.61296 16.6331C3.60333 16.6119 3.5931 16.591 3.58229 16.5706C3.35747 16.145 2.883 15.8903 2.4043 16.0016L2.2543 16.0365C1.71637 16.1616 1.37764 16.7008 1.54705 17.2265C2.11596 18.9918 3.08658 20.6015 4.38226 21.9285C4.76809 22.3237 5.40308 22.2758 5.76476 21.8584L5.86562 21.742C6.18747 21.3706 6.18374 20.8322 5.91226 20.4347C5.89921 20.4156 5.88554 20.3968 5.87126 20.3784Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.13418 12.9618C3.13526 12.9553 3.13629 12.9489 3.13727 12.9424C3.14176 12.9125 3.14509 12.8823 3.14724 12.8518C3.24569 11.4547 3.64121 10.0943 4.30722 8.86212C4.32175 8.83525 4.33512 8.80798 4.34734 8.78037C4.35001 8.77435 4.35262 8.76833 4.35517 8.76228C4.36425 8.74081 4.37264 8.71914 4.38035 8.69731C4.54068 8.24347 4.40603 7.7221 3.9996 7.44577L3.87225 7.35919C3.41553 7.04867 2.78952 7.16541 2.51809 7.64639C1.60657 9.26163 1.08179 11.0666 0.985181 12.9188C0.956413 13.4703 1.42224 13.9045 1.97425 13.8872L2.12818 13.8824C2.6194 13.8671 3.0126 13.4992 3.1206 13.0301C3.12579 13.0076 3.13033 12.9848 3.13418 12.9618Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.57462 6.04909C6.58003 6.04539 6.58542 6.04164 6.59078 6.03784C6.61543 6.02038 6.63952 6.00186 6.66298 5.9823C7.7386 5.08519 8.99354 4.42777 10.3434 4.05424C10.3729 4.0461 10.4018 4.03684 10.4302 4.02653C10.4364 4.02428 10.4425 4.02198 10.4487 4.01964C10.4704 4.0113 10.4919 4.00234 10.5129 3.99278C10.9513 3.79391 11.2339 3.33557 11.1515 2.85106L11.1257 2.69924C11.0332 2.15477 10.5152 1.78428 9.98035 1.92184C8.18412 2.38379 6.51905 3.25605 5.11663 4.46975C4.69902 4.83116 4.70872 5.46789 5.10364 5.85397L5.21376 5.96162C5.56518 6.30519 6.1029 6.33378 6.51594 6.08665C6.5358 6.07476 6.55537 6.06224 6.57462 6.04909Z"
        fill={fill}
      />
      <path
        d="M8.70059 12.4461C8.88752 12.2429 9.20383 12.2297 9.40708 12.4166L13.1976 15.9029C13.4009 16.0898 13.4141 16.4061 13.2272 16.6094L12.2388 17.684C12.0519 17.8872 11.7356 17.9005 11.5323 17.7135L7.74178 14.2272C7.53853 14.0403 7.52531 13.724 7.71224 13.5207L8.70059 12.4461Z"
        fill={fill}
      />
      <path
        d="M18.1935 10.4584C18.3968 10.6453 18.41 10.9616 18.223 11.1649L12.2388 17.6714C12.0519 17.8746 11.7356 17.8879 11.5323 17.7009L10.4577 16.7126C10.2545 16.5257 10.2412 16.2094 10.4282 16.0061L16.4124 9.49959C16.5993 9.29634 16.9157 9.28312 17.1189 9.47005L18.1935 10.4584Z"
        fill={fill}
      />
    </svg>
  );
};

InProgressIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

InProgressIcon.defaultProps = {
  fill: "var(--eggplant)",
  width: "25",
  height: "26",
};

export default InProgressIcon;
