import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/compensation/types";
import getPeriods from "./getPeriods";
import getPayments from "./getPayments";
import getLedger from "./getLedger";
import getLedgerCsv from "./getLedgerCsv";
import getEnterpriseLedgerCsv from "./getEnterpriseLedgerCsv";
import getEarningsCsv from "./getEarningsCsv";
import getLedgerCsvReport from "./getLedgerCsvReport";
import addPayment from "./addPayment";
import clawbackPayment from "./clawbackPayment";
import deletePayment from "./deletePayment";
import editPayment from "./editPayment";
import recalculatePayment from "./recalculatePayment";
import distributePayment from "./distributePayment";
import getUserOrLeadAutoComplete from "./getUserOrLeadAutoComplete";
import getDistributionsRequest from "./getDistributionsRequest";
import getProjectsCompensationList from "./getProjectsCompensationList";
import getProjectRevenueSplit from "./getProjectRevenueSplit";
// IMPORT_PT (for script, do not remove)

function* watchEarningsSagas() {
  yield trackRequests(takeEvery, types.GET_PERIODS_REQUEST, getPeriods);
  yield trackRequests(takeEvery, types.GET_PAYMENTS_REQUEST, getPayments);
  yield trackRequests(takeEvery, types.GET_LEDGER_REQUEST, getLedger);
  yield trackRequests(takeEvery, types.GET_LEDGER_CSV_REQUEST, getLedgerCsv);
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_LEDGER_CSV_REQUEST, getEnterpriseLedgerCsv);
  yield trackRequests(takeEvery, types.GET_EARNINGS_CSV_REQUEST, getEarningsCsv);
  yield trackRequests(takeEvery, types.GET_LEDGER_CSV_REPORT_REQUEST, getLedgerCsvReport);
  yield trackRequests(takeEvery, types.ADD_PAYMENT_REQUEST, addPayment);
  yield trackRequests(takeEvery, types.CLAWBACK_PAYMENT_REQUEST, clawbackPayment);
  yield trackRequests(takeEvery, types.DELETE_PAYMENT_REQUEST, deletePayment);
  yield trackRequests(takeEvery, types.EDIT_PAYMENT_AMOUNT, editPayment);
  yield trackRequests(takeEvery, types.RECALCULATE_PAYMENT_REQUEST, recalculatePayment);
  yield trackRequests(takeEvery, types.DISTRIBUTE_PAYMENT_REQUEST, distributePayment);
  yield trackRequests(takeEvery, types.GET_USER_OR_LEAD_AUTO_COMPLETE, getUserOrLeadAutoComplete);
  yield trackRequests(takeEvery, types.GET_DISTRIBUTIONS_REQUEST, getDistributionsRequest);
  yield trackRequests(takeEvery, types.GET_PROJECTS_COMPENSATION_LIST, getProjectsCompensationList);
  yield trackRequests(takeEvery, types.GET_PROJECT_REVENUE_SPLIT, getProjectRevenueSplit);
  // INSERTION_PT (for script, do not remove)
}

export default watchEarningsSagas;
