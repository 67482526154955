export default {
  GET_PROFILE_REQUEST: "[profile] GET Profile Request",
  UPDATE_PROFILE_REQUEST: "[profile] Update Profile Request",
  SET_PROFILE: "[profile] SET Profile",
  UPDATE_USER_PASSWORD_REQUEST: "[profile] Update User Password Request",
  CREATE_LICENSE_REQUEST: "[profile] Create License Request",
  UPDATE_LICENSE_REQUEST: "[profile] Update License Request",
  DELETE_LICENSE_REQUEST: "[profile] Delete License Request",
  CREATE_MENTOR_STATUS_CHANGE_REQUEST: "[profile] Create Mentor Status Change Request",
  DELETE_MENTOR_STATUS_CHANGE_REQUEST: "[profile] Delete Mentor Status Change Request",
  CREATE_EMAIL_UPDATE_REQUEST: "[profile] Create Email Update Request",
  DELETE_EMAIL_UPDATE_REQUEST: "[profile] Delete Email Update Request",
  VERIFY_EMAIL_UPDATE_REQUEST: "[profile] Verify Email Update Request",
  RESEND_EMAIL_UPDATE_REQUEST: "[profile] Resend Email Update Request",
  UPDATE_MENTOR_PREFERENCES_REQUEST: "[profile] Update Mentor Preferences",
  // INSERTION_PT (for script, do not remove)
};
