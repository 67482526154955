import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

type PreferredViewT = {
  viewName: string;
  filters: Record<string, unknown>;
  allowUpdate: boolean;
  isTouched: boolean;
};

type CounterT = {
  total: number;
  show: number;
};

type initStateT = {
  filterSets: unknown[];
  preferredView: PreferredViewT;
  counter: CounterT;
};

const initialState: initStateT = {
  filterSets: [],
  preferredView: {
    viewName: "",
    filters: {},
    allowUpdate: false,
    isTouched: false,
  },
  counter: {
    total: 0,
    show: 0,
  },
};

type actionT = {
  id: string;
  payload: Record<string, unknown>;
  type: string;
};

export default {
  filterSets: createReducer(initialState, {
    [types.SET_FILTER_SETS](state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    [types.UPDATE_PREFERRED_VIEW_BULK](state, action: actionT) {
      return {
        ...state,
        preferredView: {
          ...state.preferredView,
          filters: {
            ...state.preferredView.filters,
            ...action.payload,
          },
        },
      };
    },
    [types.UPDATE_PREFERRED_VIEW](state, action: actionT) {
      const currentSelectedFilterOptions = (state.preferredView.filters[action.payload.key as string] ||
        []) as Array<unknown>;
      return {
        ...state,
        preferredView: {
          view: action.payload.view,
          filters: {
            ...state.preferredView.filters,
            ...(action.payload.multiSelect
              ? {
                  [action.payload.key as string]: [...currentSelectedFilterOptions, action.payload.value],
                }
              : { [action.payload.key as string]: action.payload.value }),
          },
          allowUpdate: action.payload.allowUpdate,
          isTouched: true,
        },
      };
    },
    [types.DELETE_PREFERRED_VIEW_FILTER](state, action: actionT) {
      const selectedFilter = state.preferredView.filters[action.payload.key as string];
      const filterOptions = Array.isArray(selectedFilter)
        ? selectedFilter?.filter((option) => option.id !== action.payload.id)
        : [];
      return {
        ...state,
        preferredView: {
          view: "",
          filters: {
            ...state.preferredView.filters,
            [action.payload.key as string]: filterOptions?.length > 0 ? filterOptions : null,
          },
          allowUpdate: true,
          isTouched: true,
        },
      };
    },
    [types.SET_CLEAR_PREFERRED_VIEW](state) {
      return {
        ...state,
        preferredView: {
          view: "",
          filters: {},
          allowUpdate: true,
          isTouched: false,
        },
      };
    },
    [types.SET_PREFERRED_VIEW_ALLOW_UPDATE](state, action: actionT) {
      return {
        ...state,
        preferredView: {
          ...state.preferredView,
          allowUpdate: action.payload,
        },
      };
    },
    [types.SET_PREFERRED_VIEW_IS_TOUCHED](state, action: actionT) {
      return {
        ...state,
        preferredView: {
          ...state.preferredView,
          isTouched: action.payload,
        },
      };
    },
    [types.SET_PREFERRED_VIEW](state, action) {
      return {
        ...state,
        preferredView: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
