import makeRequest from "rdx/utils/makeRequest";
import { all, put } from "redux-saga/effects";
import _ from "lodash";
import getErrorActions from "rdx/utils/getErrorActions";
import { keysToSnakeCase, camelizeKeys } from "lib/helpers";
import actions from "rdx/actions";
import messages from "../messages";

function* updateRoofingProposal(action) {
  const { proposalId, body } = action.payload;
  const { success, data, error } = yield* makeRequest.patch(`/admin_roofing_proposals/${proposalId}`, {
    ...keysToSnakeCase(body),
  });

  if (success && data) {
    const dataFormatted = camelizeKeys(_.clone(data.body));

    yield all([
      put(actions.setAdminRoofingProposalDetails(dataFormatted)),
      put(actions.setRoofingProposalDetails(dataFormatted.formData)),
      put(actions.setRoofingProposalData({ proposal: dataFormatted })),
      put(
        actions.newSuccessEvent({
          message: messages.SUCCESS_UPDATING_ROOFING_PROPOSAL,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_ROOFING_PROPOSAL,
    });
  }

  return null;
}

export default updateRoofingProposal;
