import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "rdx/modules/consultantDashboard/messages";

function* getDashboardSlice(action) {
  const payload = {
    user_id: action.payload.userId,
    time_span: action.payload.timeSpan,
  };
  const { success, data, error } = yield* makeRequest.get(`/consultant_dashboard/${action.payload.endpoint}`, payload);

  if (success && data) {
    yield all([put(actions.setSellerDashboard(camelizeKeys(data.body)))]);
  } else if (error) {
    const errorMessage = messages.ERROR_GETTING_DASHBOARD_SLICE;
    return getErrorActions({
      error,
      message: errorMessage,
    });
  }

  return null;
}

export default getDashboardSlice;
