import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

import { theme } from "styles/themes";
import { mq } from "styles";

export const AssignedPartnerContainer = styled(Grid)(() => ({
  [mq.tabletUp]: {
    marginTop: "24px",
  },
}));

export const AssignedPartnerCard = styled(Grid)(() => ({
  width: "100%",
  background: theme.colors.white,
  borderRadius: "0px 0px 12px 12px",
  padding: "22px",
  marginBottom: "16px",
}));

export const UserAvatarContainer = styled(Grid)(() => ({
  marginRight: "32px",
  marginBottom: "16px",
}));

export const PartnerName = styled("div")(() => ({
  color: theme.colors["darkest-blue"],
  fontSize: "16px",
  fontFamily: "Poppins",
  fontWeight: 700,
}));

export const PartnerLocation = styled("div")(() => ({
  color: theme.colors["queen-blue"],
  fontWeight: 500,
  margin: "4px 0px",
}));

export const PartnerLanguages = styled("div")(() => ({
  color: theme.colors["darkest-blue"],
  fontWeight: 400,
  margin: "4px 0px",
}));

export const PartnerContactLabel = styled("div")(() => ({
  color: theme.colors["nepal-blue"],
  fontSize: "12px",
  textTransform: "uppercase",
  fontWeight: 600,
}));

export const PartnerEmail = styled("div")(() => ({
  fontFamily: "Poppins",
  color: theme.colors["blue-ribbon"],
  fontSize: "14px",
  fontWeight: 600,
  marginBottom: "16px",
  cursor: "pointer",
  position: "relative",
  zIndex: 1000,
}));

export const PartnerContact = styled("div")(() => ({
  fontFamily: "Poppins",
  color: theme.colors["blue-ribbon"],
  fontSize: "14px",
  fontWeight: 600,
  marginBottom: "16px",
}));
