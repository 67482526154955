import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";

import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* requestProposal(action) {
  const { id, realTime, enterprise, isKanban, proposalNotes, proposalType } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/provider_leads/${id}/request_proposal`, {
    proposal_notes: proposalNotes,
    real_time: realTime,
    enterprise,
    tpo: proposalType,
  });

  if (success && data) {
    const response = camelizeKeys(data.body);
    const enterpriseProjects = yield select(selectors.getEnterpriseProjectsList);
    const unchangedEnterpriseProjects = enterpriseProjects.projects.filter((p) => p.id !== id);

    const adminListProjects = yield select(selectors.getAdminProjectsList);
    const { projects: adminProjects } = adminListProjects;

    const updatedAdminProject = adminProjects.find((project) => project.id === id);
    const unchangedAdminProjects = adminProjects.filter((project) => project.id !== id);

    if (updatedAdminProject) {
      updatedAdminProject.canRequestProposal = data.body.can_request_proposal;
      updatedAdminProject.proposalNonrequestableReason = data.body.proposal_nonrequestable_reason;
    }
    const l2pProjectId = yield select(selectors.selectLeadToProposalDrawerProjectId);
    const existingProjectDetails = yield select(selectors.getProjectDetails);
    const projectDetailsId = existingProjectDetails?.providerLead?.id;

    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.REQUEST_PROPOSAL_SUCCESS,
        }),
      ),
      ...(l2pProjectId === id ? [put(actions.setLeadToProposalDrawerProjectDetails(response))] : []),
      ...(projectDetailsId === id ? [put(actions.setProjectDetails({ providerLead: response }))] : []),
      put(actions.setLeadToProposalDrawerProposalRequested()),
      put(actions.setAdminProjectsList({ ...adminProjects, projects: unchangedAdminProjects })),
      put(actions.setEnterpriseProjectsList({ ...enterpriseProjects, unchangedEnterpriseProjects })),
      put(actions.updatePreSalesProject(formatPreSalesProject(response))),
      put(
        actions.setAlertMessageVisible({
          message: messages.REQUEST_PROPOSAL_SUCCESS,
          severity: "success",
        }),
      ),
      ...(isKanban ? [put(actions.setProviderLeadStageInKanban({ providerLead: response }))] : []),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_REQUESTING_PROPOSAL,
    });
  }
  return null;
}

const formatPreSalesProject = (projectDetails) => {
  const { id, upcomingStageNames, projectProgress, soloProposalNonrequestableReason, milestone } = projectDetails;
  const milestoneDisplayName = projectProgress.at(-1);

  return {
    projectId: id,
    upcomingStageNames,
    passedStageNames: projectProgress,
    soloProposalNonrequestableReason,
    milestoneDisplayName,
    milestone,
  };
};

export default requestProposal;
