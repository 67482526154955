import React, { CSSProperties } from "react";

import { TextareaAutosizeProps } from "@mui/base/TextareaAutosize";

import { useController } from "react-hook-form";

import { useSmartFormContext } from "components/SmartForm";
import HelperText from "../HelperText";

import * as S from "./styles";

export type SmartFormTextareaProps = {
  mainLabel?: string;
  subLabel?: string | React.ReactElement;
  name: string;
  helperText?: string | undefined;
  error?: boolean;
  disabled?: boolean;
  mainLabelStyle?: CSSProperties;
  subLabelStyle?: CSSProperties;
  height?: number;
  customWidth?: string;
  customHeight?: string;
} & TextareaAutosizeProps;

const SmartFormTextarea = ({
  mainLabel,
  subLabel,
  name,
  helperText,
  error,
  disabled = false,
  mainLabelStyle = {},
  subLabelStyle = {},
  height = 76,
  ...rest
}: SmartFormTextareaProps) => {
  const { controllerProps } = useSmartFormContext();
  const { field } = useController({ ...controllerProps, name });

  return (
    <>
      <S.LabelGroupWrapper>
        <S.StyledFormLabel style={mainLabelStyle}>{mainLabel}</S.StyledFormLabel>
        <S.SubLabel style={subLabelStyle}>{subLabel}</S.SubLabel>
      </S.LabelGroupWrapper>
      <S.StyledTextArea
        disabled={disabled}
        minRows={3}
        {...rest}
        style={{
          width: rest.customWidth || "100%",
          height: rest.customHeight || `${height}px`,
          maxHeight: rest.customHeight || `${height}px`,
        }}
        defaultValue={rest.defaultValue}
        {...field}
      />
      <HelperText error={error}>{helperText}</HelperText>
    </>
  );
};

export default SmartFormTextarea;
