import React from "react";
import PropTypes from "prop-types";
import styles from "../../EnterpriseJoin.module.less";

const ImgPanel = (props) => {
  const { imgUrl } = props;

  return (
    <div className={[styles.imageContainer, styles.bgDarkenZoomOnHover].join(" ")}>
      <div
        className={styles.image}
        style={{
          backgroundImage: `${imgUrl}`,
        }}
      />
    </div>
  );
};

ImgPanel.propTypes = {
  imgUrl: PropTypes.string,
};

export default ImgPanel;
