import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const SilhouetteDoubleIcon = ({ width = "15", height = "12", fill = cvar("white") }: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 15 12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5323 8.35122C13.8061 8.62573 14.0544 8.92441 14.2745 9.24362L14.2747 9.24353C14.4098 9.44704 14.4664 9.69274 14.434 9.93485C14.4015 10.177 14.2823 10.3991 14.0984 10.5599C13.9144 10.7208 13.6784 10.8092 13.4341 10.8091H11.9393C11.9115 10.5651 11.8243 10.3316 11.6855 10.129C11.4454 9.78308 11.1745 9.4596 10.876 9.16242C10.2458 8.52978 9.49909 8.02506 8.67703 7.67623C9.23639 7.03665 9.54321 6.21491 9.54011 5.36514V4.17071C9.53876 3.4132 9.29348 2.67626 8.8405 2.06908C8.38752 1.4619 7.75096 1.01678 7.02516 0.799797C7.59391 0.269213 8.34776 -0.017582 9.12529 0.000835196C9.90283 0.0191585 10.6423 0.341221 11.1853 0.897993C11.7284 1.45479 12.0318 2.20201 12.0308 2.9798V4.1759C12.0303 5.19553 11.5096 6.14445 10.6499 6.69252C11.7408 6.97965 12.7358 7.55217 13.5323 8.35122ZM10.4962 9.54031C10.7699 9.81482 11.0184 10.1135 11.2384 10.4327L11.2385 10.4326C11.3746 10.6367 11.4316 10.8834 11.3989 11.1265C11.3662 11.3695 11.2459 11.5923 11.0607 11.7531C10.8755 11.9139 10.638 12.0016 10.3927 11.9999H1.64854C1.39891 11.9994 1.15831 11.9066 0.972802 11.7396C0.787378 11.5725 0.670096 11.3429 0.643474 11.0948C0.616853 10.8465 0.682819 10.5972 0.828649 10.3947C1.04221 10.0897 1.28189 9.80381 1.545 9.54027C2.34387 8.74013 3.34199 8.16804 4.43623 7.88333C3.56871 7.33939 3.04038 6.38889 3.03637 5.36499V4.17057C3.03637 3.10477 3.60503 2.11979 4.52814 1.58687C5.45106 1.05395 6.58841 1.05395 7.51152 1.58687C8.43455 2.11975 9.00322 3.10473 9.00322 4.17057V5.36499C9.00013 6.38699 8.47465 7.33639 7.6103 7.88161C8.70252 8.16825 9.69871 8.74075 10.4962 9.54031Z"
        fill={fill}
      />
    </svg>
  );
};

export default SilhouetteDoubleIcon;
