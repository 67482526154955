import types from "rdx/modules/enterpriseEarnings/types";
import createAction from "rdx/utils/createAction";

export default {
  getEnterpriseEarningsList: (payload) => createAction(types.GET_ENTERPRISE_EARNINGS_LIST, payload),
  setEnterpriseEarningsList: (payload) => createAction(types.SET_ENTERPRISE_EARNINGS_LIST, payload),
  setEnterpriseEarningsFilters: (payload) => createAction(types.SET_ENTERPRISE_EARNINGS_FILTERS, payload),
  getEnterpriseCompPlan: (payload) => createAction(types.GET_ENTERPRISE_COMP_PLAN, payload),
  setEnterpriseCompPlan: (payload) => createAction(types.SET_ENTERPRISE_COMP_PLAN, payload),
  // INSERTION_PT (for script, do not remove)
};
