import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "antd";
import Counter from "./Counter/Counter";
import styles from "./CounterRow.module.less";

const CounterRow = () => {
  const { t } = useTranslation("ambassador");
  return (
    <Row className={styles.section}>
      <div className={styles.counters}>
        <h5 className={styles.heading}>{t("impact.everyone_wins")}</h5>
        <h2 className={styles.subheading}>{t("impact.every_time_you_refer_a_homeowner")}</h2>
        <div className={styles.countersContainer}>
          <Counter />
          <h3 className={styles.counterSubtext}>{t("impact.co2_sequestered")}</h3>
        </div>
      </div>
      <div className={styles.treeDiv} />
    </Row>
  );
};

export default CounterRow;
