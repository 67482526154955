// import content from "containers/Sales/team";
import sidebar from "containers/Sales/team/components/Sidebar";
import tabs from "containers/Sales/team/tabs";
import { TeamIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";

const content = l(() => import("containers/Sales/team"));
// const sidebar = l(() => import("containers/Sales/team/components/Sidebar"));

const { TEAM_VIEW, PREVIEW_USER } = permissionTypes;

export default {
  key: "team",
  title: "Team",
  path: "/team/:tab?/:userId?/:drawerTab?",
  linkTarget: "/team",
  linkTitle: "Team",
  linkCategory: "Sales",
  icon: TeamIcon,
  basePath: "team",
  requiredParams: ["tab"],
  optionalParams: [],
  tabs,
  content,
  sidebar,
  permission: [TEAM_VIEW, PREVIEW_USER],
};
