import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";

function* getAdminDashboardDetails(action) {
  const { success, data, error } = yield* makeRequest.get("/dashboard", action.payload);

  if (success && data) {
    yield put(actions.setAdminDashboardDetails(keysToCamelCase(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: "There was an error getting dashboard details",
    });
  }

  return null;
}

export default getAdminDashboardDetails;
