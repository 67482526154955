import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { $TSFixMe } from "types";
import { enrollmentApi } from "./endpoints";
import { Enrollment, GetEnrollmentResponseBody, Service } from "./types";
import { ServiceSlugs } from "./schemas";

type EnrollmentState = {
  enrollment: Enrollment | null;
  selectedService: Service | null;
  downgradeTo: ServiceSlugs | null;
  availableServices: Service[];
  upgradePreview: $TSFixMe;
  monthlyTotal: GetEnrollmentResponseBody["monthlyTotal"];
  balance: GetEnrollmentResponseBody["balance"];
  cardInfo: GetEnrollmentResponseBody["cardInfo"];
  seatService: GetEnrollmentResponseBody["seatService"];
};

export const enrollmentInitialState = {
  enrollment: null,
  availableServices: [],
  monthlyTotal: 0,
  balance: 0,
  cardInfo: {},
  seatService: null,
};

const initialState: EnrollmentState = {
  selectedService: null,
  downgradeTo: null,
  upgradePreview: {},
  ...enrollmentInitialState,
};

function formatCardInfo(cardInfo: GetEnrollmentResponseBody["cardInfo"]) {
  return cardInfo.expMonth && cardInfo.expYear
    ? {
        expMonth: cardInfo.expMonth,
        expYear: cardInfo.expYear,
        last4: cardInfo.last4,
      }
    : {};
}

const isEnrollmentChange =
  enrollmentApi.endpoints.upgradeEnrollment.matchFulfilled ||
  enrollmentApi.endpoints.downgradeEnrollment.matchFulfilled;

const enrollmentSlice = createSlice({
  name: "enrollment",
  initialState,
  reducers: {
    setEnrollment(state, action: PayloadAction<Enrollment>) {
      state.enrollment = action.payload;
    },
    setAvailableServices(state, action: PayloadAction<Service[]>) {
      state.availableServices = action.payload;
    },
    setMonthlyTotal(state, action: PayloadAction<GetEnrollmentResponseBody["monthlyTotal"]>) {
      state.monthlyTotal = action.payload;
    },
    setBalance(state, action: PayloadAction<number>) {
      state.balance = action.payload;
    },
    setCardInfo(state, action: PayloadAction<Record<string, $TSFixMe>>) {
      state.cardInfo = action.payload;
    },
    setSelectedService(state, action: PayloadAction<$TSFixMe | null>) {
      state.selectedService = action.payload;
    },
    setDowngradeTo(state, action: PayloadAction<$TSFixMe | null>) {
      state.downgradeTo = action.payload;
    },
  },
  selectors: {
    selectEnrollment: (state) => state.enrollment,
    selectServices: (state) => state.availableServices,
    selectEnrollmentMonthlyTotal: (state) => state.monthlyTotal,
    selectEnrollmentBalance: (state) => state.balance,
    selectCardInfo: (state) => state.cardInfo,
    selectSelectedService: (state) => state.selectedService,
    selectDowngradeTo: (state) => state.downgradeTo,
  },
  extraReducers: (b) => {
    b.addMatcher(enrollmentApi.endpoints.getEnrollment.matchFulfilled, (state, { payload }) => {
      const { enrollment, availableServices, balance, cardInfo, monthlyTotal, seatService } = payload;
      state.enrollment = enrollment;
      state.availableServices = availableServices;
      state.monthlyTotal = monthlyTotal;
      state.balance = balance < 0 ? Math.abs(balance) : 0;
      state.cardInfo = formatCardInfo(cardInfo);
      state.seatService = seatService;
    });
    b.addMatcher(isEnrollmentChange, (state, { payload }) => {
      state.enrollment = payload.enrollment;
    });
    b.addMatcher(enrollmentApi.endpoints.updateEnrollment.matchFulfilled, (state, { payload }) => {
      state.enrollment = payload.enrollment;
      state.cardInfo = formatCardInfo(payload.cardInfo);
    });
  },
});

export const {
  setEnrollment,
  setAvailableServices,
  setMonthlyTotal,
  setBalance,
  setCardInfo,
  setSelectedService,
  setDowngradeTo,
} = enrollmentSlice.actions;

export const {
  selectCardInfo,
  selectDowngradeTo,
  selectEnrollment,
  selectEnrollmentBalance,
  selectEnrollmentMonthlyTotal,
  selectSelectedService,
  selectServices,
} = enrollmentSlice.selectors;

export default enrollmentSlice;
