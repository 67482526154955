import { put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "rdx/modules/compensation/messages";
import { camelizeKeys } from "lib/helpers";

function* addPayment(action) {
  const { payload } = action;
  const params = {
    amount: payload.amount,
    user_ids: payload.userIds,
    bonus_id: payload.bonusId,
    ...(payload.description ? { description: payload.description } : {}),
    ...(payload.projectId ? { project_id: payload.projectId } : {}),
    ...(payload.paymentMilestoneId ? { payment_milestone_id: payload.paymentMilestoneId } : {}),
  };

  const { success, data, error } = yield* makeRequest.post(
    `/compensation/pay_periods/${payload.id}/add_payment`,
    params,
  );

  if (success && data) {
    const payments = yield select(selectors.getPayPeriods);

    const updatedPeriods = payments.payPeriods.map((p) => (p.id === data.body.id ? camelizeKeys(data.body) : p));

    const out = { ...payments, payPeriods: updatedPeriods };

    yield put(actions.setPeriods(out));
    yield put(
      actions.newSuccessEvent({
        message: messages.ADD_PAYMENT_SUCCESS,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_ADDING_PAYMENT,
    });
  }

  return null;
}

export default addPayment;
