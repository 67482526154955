import leadsSelectors from "rdx/modules/leads/selectors";
import activeRequestsSelectors from "rdx/modules/activeRequests/selectors";
import appSelectors from "rdx/modules/app/selectors";
import messagesSelectors from "rdx/modules/messages/selectors";
import projectsSelectors from "rdx/modules/projects/selectors";
import inboxSelectors from "rdx/modules/tickets/selectors";
import usersSelectors from "rdx/modules/users/selectors";
import teamSelectors from "rdx/modules/team/selectors";
import rbacSelectors from "rdx/modules/rbac/selectors";
import adminProjectsSelectors from "rdx/modules/adminProjects/selectors";
import workflowsSelectors from "rdx/modules/workflows/selectors";
import ambassadorSelectors from "rdx/modules/ambassador/selectors";
import profileSelectors from "rdx/modules/profile/selectors";
import reportsSelectors from "rdx/modules/reports/selectors";
import admindashboardSelectors from "rdx/modules/adminDashboard/selectors";
import consultantDashboardSelectors from "rdx/modules/consultantDashboard/selectors";
import settingsSelectors from "rdx/modules/settings/selectors";
import adminNoticesSelectors from "rdx/modules/adminNotices/selectors";
import adminSmsSelectors from "rdx/modules/adminSms/selectors";
import earningsSelectors from "rdx/modules/earnings/selectors";
import registrationSelectors from "rdx/modules/registration/selectors";
import financialsSelectors from "rdx/modules/financials/selectors";
import messagingmetricsSelectors from "rdx/modules/adminReports/selectors";
import adminProposalsSelectors from "rdx/modules/adminProposals/selectors";
import mentorratingsSelectors from "rdx/modules/mentorRatings/selectors";
import workSchedulesSelectors from "rdx/modules/workSchedules/selectors";
import bypassPaywallSelectors from "rdx/modules/bypassPaywall/selectors";
import careerListingsSelectors from "rdx/modules/careerListings/selectors";
import stateLicensesSelectors from "rdx/modules/stateLicenses/selectors";
import authSelectors from "rdx/modules/auth/selectors";
import filterSetsSelectors from "rdx/modules/filterSets/selectors";
import compensationSelectors from "rdx/modules/compensation/selectors";
import platformSelectors from "rdx/modules/platform/selectors";
import enterpriseProjectsSelectors from "rdx/modules/enterpriseProjects/selectors";
import enterpriseOrgSelectors from "rdx/modules/enterpriseOrg/selectors";
import enterpriseLeadsSelectors from "rdx/modules/enterpriseLeads/selectors";
import enterprisePersonnelSelectors from "rdx/modules/enterprisePersonnel/selectors";
import enterpriseEarningsSelectors from "rdx/modules/enterpriseEarnings/selectors";
import enterpriseApplicationsSelectors from "rdx/modules/enterpriseApplications/selectors";
import buildPartnerApplicationsSelectors from "rdx/modules/buildPartnerApplications/selectors";
import complianceIssuesSelectors from "rdx/modules/complianceIssues/selectors";
import adminLeadsSelectors from "rdx/modules/adminLeads/selectors";
import dashboardPromotionsSelectors from "rdx/modules/dashboardPromotions/selectors";
import dashboardUpdatesSelectors from "rdx/modules/dashboardUpdates/selectors";
import seatapprovalsSelectors from "rdx/modules/seatApprovals/selectors";
import enterpriseProLaborFormSelectors from "rdx/modules/enterpriseProLaborForm/selectors";
import notificationsSelectors from "rdx/modules/notifications/selectors";
import viewedAssetsSelectors from "rdx/modules/viewedAssets/selectors";
import activateAcctBtnClicked from "rdx/modules/activateAcctBtnClicked/selectors";
import preferredpartnersSelectors from "rdx/modules/preferredPartners/selectors";
import platformModulesSelectors from "rdx/modules/platformModules/selectors";
import roofingProposlasSelectors from "rdx/modules/roofing/selectors";
import crmActivitiesSelectors from "rdx/modules/crmActivities/selectors";
import salesTeamsSelectors from "rdx/modules/salesTeams/selectors";
import sellerIntegritySelectors from "rdx/modules/sellerIntegrity/selectors";
import publicApiProxySelectors from "rdx/modules/publicApiProxy/selectors";
import projectpresalesSelectors from "rdx/modules/projectPreSales/selectors";
import fundingProposalsSelectors from "rdx/modules/fundingProposals/selectors";
import fileuploadSelectors from "rdx/modules/fileUpload/selectors";
import customFieldsSelectors from "rdx/modules/customFields/selectors";

// IMPORT_PT (for script, do not remove)

const selectors = {
  ...leadsSelectors,
  ...activeRequestsSelectors,
  ...appSelectors,
  ...messagesSelectors,
  ...projectsSelectors,
  ...inboxSelectors,
  ...usersSelectors,
  ...teamSelectors,
  ...rbacSelectors,
  ...adminProjectsSelectors,
  ...workflowsSelectors,
  ...ambassadorSelectors,
  ...profileSelectors,
  ...reportsSelectors,
  ...admindashboardSelectors,
  ...consultantDashboardSelectors,
  ...settingsSelectors,
  ...adminNoticesSelectors,
  ...adminSmsSelectors,
  ...earningsSelectors,
  ...registrationSelectors,
  ...financialsSelectors,
  ...messagingmetricsSelectors,
  ...adminProposalsSelectors,
  ...mentorratingsSelectors,
  ...workSchedulesSelectors,
  ...bypassPaywallSelectors,
  ...careerListingsSelectors,
  ...stateLicensesSelectors,
  ...authSelectors,
  ...filterSetsSelectors,
  ...compensationSelectors,
  ...platformSelectors,
  ...enterpriseProjectsSelectors,
  ...enterpriseOrgSelectors,
  ...enterpriseLeadsSelectors,
  ...enterprisePersonnelSelectors,
  ...enterpriseEarningsSelectors,
  ...enterpriseApplicationsSelectors,
  ...buildPartnerApplicationsSelectors,
  ...complianceIssuesSelectors,
  ...adminLeadsSelectors,
  ...dashboardPromotionsSelectors,
  ...dashboardUpdatesSelectors,
  ...seatapprovalsSelectors,
  ...enterpriseProLaborFormSelectors,
  ...notificationsSelectors,
  ...viewedAssetsSelectors,
  ...activateAcctBtnClicked,
  ...preferredpartnersSelectors,
  ...platformModulesSelectors,
  ...roofingProposlasSelectors,
  ...crmActivitiesSelectors,
  ...salesTeamsSelectors,
  ...sellerIntegritySelectors,
  ...publicApiProxySelectors,
  ...projectpresalesSelectors,
  ...fundingProposalsSelectors,
  ...fileuploadSelectors,
  ...customFieldsSelectors,
  // INSERTION_PT (for script, do not remove)
};

export default selectors;
