import { put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { cloneDeep } from "lodash";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "rdx/modules/compensation/messages";

function* editPayment(action) {
  const { id, amount } = action.payload;

  const { success, data, error } = yield* makeRequest.patch("/compensation/update_payment_amount/", {
    payment_item_id: id,
    amount,
  });

  if (success && data) {
    const ledger = yield select(selectors.getLedger);
    const ledgerCopy = cloneDeep(ledger);

    const newPayments = ledgerCopy.payments.map((pmnt) => {
      // update the selected payment
      if (pmnt.payment.paymentItemId === id) {
        return {
          ...pmnt,
          payment: { ...pmnt.payment, amount },
        };
      }
      return pmnt;
    });

    yield put(actions.setLedger({ ...ledgerCopy, payments: newPayments }));
    yield put(
      actions.newSuccessEvent({
        message: messages.UPDATE_PAYMENT_SUCCESS,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_PAYMENT,
    });
  }

  return null;
}

export default editPayment;
