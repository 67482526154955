import React from "react";

interface TrashcanIconI {
  width?: string | number;
  height?: string | number;
  fill?: string;
}

const TrashcanIcon = ({ width = 16, height = 16, fill = "var(--white)" }: TrashcanIconI) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5926 5.19664L12.8494 14.7919C12.7954 15.4899 12.4769 16 12.0953 16H3.79792C3.41622 16 3.09786 15.4897 3.0438 14.7919L2.30064 5.19664H13.5926ZM10.3717 1.43395V0.742552C10.3717 0.33422 10.0181 0.00013087 9.58583 0.00013087L6.41364 0C5.98136 0 5.62767 0.333983 5.62767 0.742421V1.43381L10.3717 1.43395ZM1.92638 2.25111H14.0727C14.6925 2.25111 15.1995 2.73011 15.1995 3.31549C15.1995 3.90089 14.6925 4.37988 14.0727 4.37988L1.92638 4.37975C1.30665 4.37975 0.799561 3.90076 0.799561 3.31536C0.799561 2.72984 1.30665 2.25112 1.92638 2.25112V2.25111ZM10.6384 6.70731L10.1719 13.6209C10.1569 13.8456 10.3379 14.0393 10.5755 14.0535C10.8134 14.0676 11.0184 13.8967 11.0334 13.6723L11.5001 6.75827C11.5151 6.53357 11.3342 6.33989 11.0965 6.32575C10.8584 6.31148 10.6535 6.48227 10.6384 6.70711V6.70731ZM7.51372 6.7272V13.6519C7.51372 13.8773 7.7074 14.0603 7.94611 14.0603C8.18483 14.0603 8.3785 13.8773 8.3785 13.6519V6.7272C8.3785 6.50172 8.18481 6.31877 7.94611 6.31877C7.70725 6.31877 7.51372 6.50173 7.51372 6.7272ZM5.72049 13.6211L5.25374 6.70711C5.23877 6.4824 5.03387 6.31173 4.79584 6.32588C4.55796 6.34001 4.377 6.53356 4.39225 6.7584L4.85901 13.6724C4.87397 13.8971 5.07888 14.068 5.31691 14.0536C5.55451 14.0393 5.73532 13.8456 5.72049 13.6211Z"
        fill={fill}
      />
    </svg>
  );
};

export default TrashcanIcon;
