import React from "react";
import PropTypes from "prop-types";
import styles from "./BackdropChevron.module.less";

const BackdropChevron = (props) => {
  const { fill, height, width, outerFill, rotate } = props;

  return (
    <div
      className={styles.backdropChevron}
      style={{
        backgroundColor: outerFill,
        height,
        width,
      }}
    >
      <svg
        className={rotate ? styles.rotatedTriangle : styles.triangle}
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
      >
        <path
          d="M4.21956 6.4382C4.41913 6.71473 4.83087 6.71473 5.03044 6.4382L8.92438 1.0426C9.16303 0.711921 8.92674 0.25 8.51894 0.25L0.73106 0.25C0.323256 0.25 0.0869697 0.711921 0.325619 1.0426L4.21956 6.4382Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

BackdropChevron.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  outerFill: PropTypes.string,
  rotate: PropTypes.bool,
};

BackdropChevron.defaultProps = {
  fill: "var(--white)",
  outerFill: "var(--dark-blue)",
  width: "25px",
  height: "25px",
  rotate: false,
};

export default BackdropChevron;
