export default {
  // Details
  getEnterpriseProjectDetails: (state) => state.enterpriseProjects.enterpriseProjectDetails,

  // List
  getEnterpriseProjectsList: (state) => state.enterpriseProjects.enterpriseProjectsList,

  // Filters
  getEnterpriseProjectsListFilters: (state) => state.enterpriseProjects.enterpriseProjectsFilters,
  getEnterpriseProjectsAutoComplete: (state) => state.enterpriseProjects.projectsListAutoComplete,

  selectEnterpriseProjectLookupOptions: (state) => state.enterpriseProjects.projectLookupOptions,

  // Seller Search
  getOrgSellers: (state) => state.enterpriseProjects.orgSellers,

  // Drawers
  getOfferLeadDrawerVisible: (state) => state.enterpriseProjects.offerLeadDrawerVisible,

  getOfferLeadConfirmationDetails: (state) => state.enterpriseProjects.offerLeadConfirmation,

  getEnterpriseOfferLeadGeocode: (state) => state.enterpriseProjects.offerLeadGeocode,

  getEnterpriseLeadOfferDetails: (state) => state.enterpriseProjects.currentOfferDetails,

  getEnterpriseLeadOfferResponse: (state) => state.enterpriseProjects.currentOfferResponse,

  // CSV Reports
  getContractsReport: (state) => state.enterpriseProjects.contractsReport,
  getLeadGenPreSalesProjects: (state) => state.enterpriseProjects.leadGenPreSalesProjects,
};
