import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";
import types from "rdx/modules/dashboardPromotions/types";

import getDashboardPromotions from "./getDashboardPromotions";
import getDashboardCarouselPromotions from "./getDashboardCarouselPromotions";
import getSelectedDashboardPromotion from "./getSelectedDashboardPromotion";
import createDashboardPromotion from "./createDashboardPromotion";
import updateDashboardPromotion from "./updateDashboardPromotion";
import deleteDashboardPromotion from "./deleteDashboardPromotion";

// IMPORT_PT (for script, do not remove)

function* watchDashboardPromotionsSagas() {
  yield trackRequests(takeEvery, types.GET_DASHBOARD_PROMOTIONS, getDashboardPromotions);
  yield trackRequests(takeEvery, types.GET_DASHBOARD_CAROUSEL_PROMOTIONS, getDashboardCarouselPromotions);

  yield trackRequests(takeEvery, types.CREATE_DASHBOARD_PROMOTION, createDashboardPromotion);

  yield trackRequests(takeEvery, types.GET_SELECTED_DASHBOARD_PROMOTION, getSelectedDashboardPromotion);

  yield trackRequests(takeEvery, types.UPDATE_SELECTED_DASHBOARD_PROMOTION, updateDashboardPromotion);

  yield trackRequests(takeEvery, types.DELETE_SELECTED_DASHBOARD_PROMOTION, deleteDashboardPromotion);

  // INSERTION_PT (for script, do not remove)
}

export default watchDashboardPromotionsSagas;
