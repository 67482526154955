import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";

function* getLitmosLoginLink(action) {
  const { userId: user_id, path, locale } = action.payload;

  const { success, data, error } = yield* makeRequest.post("/litmos_connect", {
    user_id,
    path,
    locale,
  });
  if (success && data) {
    yield put(actions.setLitmosLoginLink(data.login_link));
  } else {
    return getErrorActions({
      error,
    });
  }

  return null;
}

export default getLitmosLoginLink;
