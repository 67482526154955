import React from "react";
import useWindowResize from "hooks/useWindowResize";
import { CheckWithShadowIcon } from "components/Icons";
import * as S from "./styles";

type TimelineDataT = {
  content: React.ReactNode;
  label: string;
  requiresAttention?: boolean;
};

type HorizontalTimelinePropsT = {
  timelineData: TimelineDataT[];
  currentTab: number;
  setCurrentTab: (tabIdx: number) => void;
};

const HorizontalTimeline = ({ timelineData, currentTab, setCurrentTab }: HorizontalTimelinePropsT) => {
  const { isMobileWidth } = useWindowResize();
  const handleTimelineDotClick = (dotIdx: number) => {
    if (dotIdx < currentTab) setCurrentTab(dotIdx);
  };

  const iconContents = (active: boolean, reqAttention: boolean) => {
    if (active) return <S.CircleWithShadow />;
    if (reqAttention) return <S.NeedsAttentionIconContainer>!</S.NeedsAttentionIconContainer>;
    return <CheckWithShadowIcon />;
  };
  const stepIconDisplay = (idx: number, requiresAttention?: boolean) => {
    const isCompleted = idx < currentTab;
    const isActive = idx === currentTab;

    if (isCompleted || isActive) {
      return (
        <S.IconContainer>
          <S.StyledStepIcon onClick={() => handleTimelineDotClick(idx)} isCompleted={isCompleted} isActive={isActive}>
            {iconContents(isActive, !!requiresAttention)}
          </S.StyledStepIcon>
        </S.IconContainer>
      );
    }

    return (
      <S.IconContainer>
        <S.StyledStepIcon />
      </S.IconContainer>
    );
  };

  return (
    <>
      {isMobileWidth && <S.MobileLabel>{timelineData[currentTab].label}</S.MobileLabel>}
      <S.StyledStepper nonLinear activeStep={currentTab} connector={<S.StyledStepConnector />}>
        {timelineData.map((data, index) => (
          <S.StyledStep key={data.label} completed={index < currentTab}>
            <S.StyledStepLabel
              onClick={() => handleTimelineDotClick(index)}
              icon={stepIconDisplay(index, data.requiresAttention)}
            >
              {!isMobileWidth && <S.Label>{data.label}</S.Label>}
            </S.StyledStepLabel>
          </S.StyledStep>
        ))}
      </S.StyledStepper>
    </>
  );
};

export default HorizontalTimeline;
