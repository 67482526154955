import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import { css, styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { cvar } from "styles/colorVariables";
import { filterStyledProps } from "lib/helpers/filterStyledProps";
import { PrimaryButton } from "../../Buttons/styles";

type StyledDataGridProT = {
  addLoadingSpace?: boolean;
  isMobile?: boolean;
  hideColumnHeaders?: boolean;
  hideBottomPadding?: boolean;
  noXScroll?: boolean;
  columnLeftRightPadding?: `${number}px`;
  transparentMobileRows?: boolean;
  treeDataControlColWidth?: number;
};

type SearchFilterContainerT = {
  isMobile?: boolean;
};

type ContainerT = {
  isMobile?: boolean;
};

type LoadingOverlayPropsT = {
  overlayEntireTable?: boolean;
};

type CSVButtonPropsT = {
  isActive?: boolean;
};

const treeDataControlColWidthParams = (total: number, pxAdjustment: number) => {
  if (!total) return {};
  return {
    minWidth: `calc(${total}px - ${pxAdjustment}px) !important`,
    maxWidth: `calc(${total}px - ${pxAdjustment}px) !important`,
  };
};

export const StyledDataGridPro = styled(DataGridPro)(
  ({
      addLoadingSpace = false,
      isMobile = false,
      hideColumnHeaders = false,
      noXScroll = false,
      hideBottomPadding = false,
      columnLeftRightPadding = "8px",
      transparentMobileRows = false,
      treeDataControlColWidth = 0,
    }: StyledDataGridProT) =>
    ({ theme }) => ({
      border: "none",
      position: "relative",

      scrollbarGutter: "stable",
      paddingBottom: hideBottomPadding ? "0px" : "80px",
      height: "100%",

      "& ::-webkit-scrollbar": {
        width: "12px",
        height: "12px",
      },

      "& ::-webkit-scrollbar-track": {
        background: "transparent",
      },

      "& ::-webkit-scrollbar-thumb:vertical": {
        background: cvar("primary-color-a15"),
        borderTop: "12px solid transparent",
        borderBottom: "24px solid transparent",
        borderLeft: "6px solid transparent",
        backgroundClip: "padding-box",
        height: "280px",
      },

      "& ::-webkit-scrollbar-thumb:horizontal": {
        background: cvar("primary-color-a15"),
        borderBottom: "12px solid transparent",
        borderTop: "200px solid transparent",
        borderRight: "6px solid transparent",
        backgroundClip: "border-box",
        height: "280px",
      },

      "& ::-webkit-scrollbar-corner": {
        display: "none",
      },

      "& .tree-data-indentation--1": {
        "& .MuiDataGrid-cell:first-of-type": {
          marginLeft: "20px",
          ...treeDataControlColWidthParams(treeDataControlColWidth, 20),
        },
      },
      "& .tree-data-indentation--2": {
        "& .MuiDataGrid-cell:first-of-type": {
          marginLeft: "40px",
          ...treeDataControlColWidthParams(treeDataControlColWidth, 40),
        },
      },
      "& .tree-data-indentation--3": {
        "& .MuiDataGrid-cell:first-of-type": {
          marginLeft: "60px",
          ...treeDataControlColWidthParams(treeDataControlColWidth, 60),
        },
      },
      "& .tree-data-indentation--4": {
        "& .MuiDataGrid-cell:first-of-type": {
          marginLeft: "80px",
          ...treeDataControlColWidthParams(treeDataControlColWidth, 80),
        },
      },
      "& .tree-data-indentation--5": {
        "& .MuiDataGrid-cell:first-of-type": {
          marginLeft: "100px",
          ...treeDataControlColWidthParams(treeDataControlColWidth, 100),
        },
      },
      "& .MuiDataGrid-row:hover": {
        backgroundColor: "inherit",
      },

      "& .MuiDataGrid-rowCount": {
        display: "none",
      },

      "& .MuiDataGrid-virtualScroller": {
        overflow: "scroll",
        overflowX: noXScroll ? "hidden" : "scroll",
      },

      "& .MuiDataGrid-virtualScrollerRenderZone": {
        paddingBottom: "60px",
        ...(isMobile ? { width: "100%" } : {}),
      },

      "& .MuiDataGrid-footerContainer": {
        borderTop: "none",
      },

      "& .MuiDataGrid-iconButtonContainer": {
        visibility: "visible !important",
        width: "auto !important",
        marginTop: "1px",
      },

      [`& .${gridClasses.columnHeaders}`]: {
        backgroundColor: "white",
        borderRadius: "0px 0px 12px 12px",
        maxWidth: "calc(100% - 12px)",
        maxHeight: "38px !important",
        minHeight: "38px !important",
        filter: "drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.03))",
        borderBottom: "none",
        ...((isMobile || hideColumnHeaders) && { display: "none" }),
      },

      [`& .${gridClasses.columnHeader}:focus-within`]: {
        outline: "none",
        height: "38px",
      },

      [`& .${gridClasses.columnHeader}`]: {
        maxHeight: "38px !important",
        minHeight: "38px !important",
        paddingLeft: columnLeftRightPadding,
        paddingRight: columnLeftRightPadding,
        ":last-of-type": {
          paddingLeft: "15px",
        },
      },

      [`& .${gridClasses["columnHeader--sorted"]} .${gridClasses.columnHeaderTitle}`]: {
        color: cvar("blue-ribbon"),
      },

      [`& .${gridClasses.columnHeaderTitle}`]: {
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "9px",
        color: cvar("nepal-blue"),
        textTransform: "uppercase",
      },

      [`& .${gridClasses.columnHeaderDraggableContainer}`]: {
        maxHeight: "38px !important",
        minHeight: "38px !important",
      },

      [`& .${gridClasses.columnSeparator}`]: {
        display: "none",
      },

      "& .MuiDataGrid-row": {
        marginBottom: "4px",
        borderRadius: "12px",
        width: "100%",
        cursor: "pointer",
        filter: "drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.03))",
        backgroundColor: "transparent !important",
        "&:last-child": {
          marginBottom: addLoadingSpace ? "-12px" : "0px",
        },
      },

      "& .MuiDataGrid-cell": {
        ...(isMobile && { padding: 0 }),
        background: transparentMobileRows ? "transparent" : theme.colors.white,
        borderBottom: "none",
        paddingLeft: columnLeftRightPadding,
        paddingRight: columnLeftRightPadding,

        ":first-of-type": {
          borderRadius: "12px 0 0 12px",
          ...(isMobile ? { width: "100%", maxWidth: "100% !important" } : {}),
        },

        ":last-of-type:not(:first-of-type)": {
          borderRadius: "0 12px 12px 0",
        },
      },

      "& .MuiDataGrid-cell:focus": {
        outline: "none",
      },

      "& .MuiDataGrid-cell:focus-within": {
        outline: "none",
      },

      "& .MuiDataGrid-selectedRowCount ": {
        display: "none",
      },

      "& .MuiDataGrid-pinnedColumns--left": {
        marginTop: "8px",
        borderRadius: "12px 0 0 12px",
        background: "transparent",

        "& .MuiDataGrid-cell": {
          ":first-of-type": {
            borderRadius: "12px 0 0 12px",
          },
        },
      },

      "& .MuiDataGrid-pinnedColumnHeaders--right": {
        paddingRight: "0 !important",
      },

      "& .MuiDataGrid-pinnedColumns--right": {
        marginTop: "8px",
        borderRadius: "0 12px 12px 0",
        background: "transparent",
        paddingRight: "0 !important",
        ":last-of-type": {
          borderRadius: "0 12px 12px 0",
        },
      },
    }),
);

export const Container = styled("div")(({ isMobile = false }: ContainerT) => ({
  paddingLeft: "12px",
  ...(isMobile ? { paddingTop: "18px" } : { paddingTop: "24px" }),
  height: "80vh",
  maxHeight: "100vh",
  zIndex: "3",
}));

export const SearchFilterContainer = styled("div")(({ isMobile = false }: SearchFilterContainerT) => ({
  height: "auto",
  width: "calc(100% - 12px)",
  minHeight: "52px",
  padding: "12px 16px",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  background: "#ffffff",
  marginBottom: "3px",
  filter: "drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.03))",
  ...(isMobile
    ? {
        borderRadius: "12px",
        flexWrap: "wrap",
      }
    : { borderRadius: "12px 12px 0px 0px" }),
}));

export const Header = styled("div")(() => ({
  width: "calc(100% - 10px)",
  height: "38px",
  background: "#ffffff",
  borderRadius: "0px 0px 12px 12px",
  display: "flex",
  marginBottom: "3px",
  filter: "drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.03))",
}));

export const CSVButton = styled(Button, { shouldForwardProp: filterStyledProps(["isActive"]) })(
  ({ isActive }: CSVButtonPropsT) =>
    ({ theme }) => ({
      background: isActive ? theme.colors["blue-ribbon"] : theme.colors["cotton-boll"],
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "19px",
      color: isActive ? theme.colors.white : theme.colors["blue-ribbon"],
      padding: "0 14px",
      height: "28px",
      borderRadius: "6px",
      whiteSpace: "nowrap",
      textTransform: "capitalize",
      boxShadow: "none",

      "&:disabled": {
        background: isActive ? theme.colors["blue-ribbon"] : theme.colors["cotton-boll"],
        color: isActive ? theme.colors.white : theme.colors["blue-ribbon"],
      },

      "&:hover": {
        background: isActive ? theme.colors["blue-ribbon-highlighted"] : theme.colors["cotton-boll-highlighted"],
        boxShadow: "none",
      },
    }),
);

export const ConnectGmailButton = styled(CSVButton)(() => () => ({
  marginRight: "10px",
}));

export const Results = styled("span")(() => ({
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "19px",
  color: cvar("dark-blue"),
  marginRight: "12px",
}));

export const AlignCenter = styled("span")(() => ({
  display: "flex",
  alignItems: "center",
  paddingTop: "2px",
}));

export const LoadingOverlay = styled("div")(({ overlayEntireTable = false }: LoadingOverlayPropsT) => ({
  position: "absolute",
  width: "100%",
  display: "flex",
  alignSelf: "center",
  alignItems: "center",
  justifyContent: "center",
  ...(overlayEntireTable ? { zIndex: 4, top: 0, height: "100%" } : { bottom: "6px", height: "40px" }),
}));

export const StyledContentContainer = styled("span")({
  height: "100%",
  width: "100%",
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
});

export const MobileFixedFooter = styled("div")(({ theme }) => ({
  display: "flex",
  position: "fixed",
  alignItems: "center",
  justifyContent: "center",
  bottom: 0,
  left: 0,
  height: "71px",
  width: "100%",
  background: theme.colors["white-a65"],
  backdropFilter: "blur(10px)",
}));

export const StyledMobPrimaryButton = styled(PrimaryButton)(({ theme }) => ({
  width: "292px",
  height: "32px",
  fontSize: "12px",
  color: theme.colors.white,
}));
export const loadingCsv = css`
  margin-left: 6px;
  letter-spacing: 0px;
  animation: pulse 2s infinite;
  @keyframes pulse {
    0% {
      opacity: 0.5;
    }

    70% {
      opacity: 1;
    }

    100% {
      opacity: 0.5;
    }
  }
`;
export const csvText = css`
  margin-left: 6px;
  letter-spacing: 0px;
`;
