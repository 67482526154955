import { put, all } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import makeRequest from "rdx/utils/makeRequest";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* closeProject(action) {
  const { id, closeReasonId, closeNote } = action.payload;

  const params = {
    close_reason_id: closeReasonId,
    close_note: closeNote,
  };

  const { success, data, error } = yield* makeRequest.post(`/provider_leads/${id}/close`, params);
  if (success && data) {
    yield all([
      put(
        actions.setProjectDetails({
          providerLead: keysToCamelCase(data.body),
          lead: keysToCamelCase(data.body.lead),
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.CLOSE_PROJECT_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CLOSING_PROJECT,
    });
  }
  return null;
}

export default closeProject;
