import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const ChatIcon = ({ fill = cvar("picton-blue"), height = 24, width = 24 }: IconT) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 40 39">
    <path
      fill={fill}
      d="M1.695 38.953l9.036-2.426A19.794 19.794 0 0020.322 39a19.713 19.713 0 0013.914-5.71 19.422 19.422 0 004.216-6.199A19.209 19.209 0 0040 19.5c0-2.632-.52-5.186-1.547-7.591a19.42 19.42 0 00-4.216-6.198A19.711 19.711 0 0020.323 0 19.712 19.712 0 006.409 5.71a19.421 19.421 0 00-4.216 6.198A19.236 19.236 0 00.647 19.5c0 3.077.739 6.125 2.141 8.851l-2.73 8.93a1.324 1.324 0 00.322 1.314c.34.348.844.485 1.316.358h-.001zm8.794-5.122l-7.155 1.921 2.162-7.07a1.321 1.321 0 00-.08-1.105 16.728 16.728 0 01-2.081-8.076c0-4.497 1.767-8.725 4.976-11.904 3.209-3.18 7.475-4.93 12.012-4.93 4.537 0 8.804 1.751 12.012 4.93 3.209 3.18 4.976 7.407 4.976 11.904 0 4.496-1.767 8.724-4.976 11.904-3.21 3.18-7.475 4.93-12.012 4.93-3.075 0-6.089-.825-8.716-2.384a1.35 1.35 0 00-1.118-.12z"
    />
  </svg>
);

export default ChatIcon;
