// import content from "containers/Admin/compensation";
import tabs from "containers/Admin/compensation/tabs";
import { CompensationIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";

const content = l(() => import("containers/Admin/compensation"));

const { FINANCE_MANAGEMENT, FINANCE_MANAGEMENT_READ_ONLY } = permissionTypes;
export default {
  key: "compensation",
  title: "Compensation",
  path: "/compensation/:tab?",
  linkTarget: "/compensation",
  linkTitle: "Compensation",
  linkCategory: "Admin",
  basePath: "compensation",
  permission: [FINANCE_MANAGEMENT, FINANCE_MANAGEMENT_READ_ONLY],
  icon: CompensationIcon,
  tabs,
  content,
  sidebar: null,
};
