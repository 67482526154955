export default {
  // Details
  GET_ENTERPRISE_PROJECT_DETAILS_REQUEST: "[enterpriseProjects] GET Enterprise Project Details Request",
  SET_ENTERPRISE_PROJECT_DETAILS: "[enterpriseProjects] SET Enterprise Project Details",
  RESET_ENTERPRISE_PROJECT_DETAILS: "[enterpriseProjects] RESET Enterprise Project Details",
  TOGGLE_M1_ADVANCE: "[enterpriseProjects] TOGGLE M1 ADVANCE",

  // List
  GET_ENTERPRISE_PROJECTS_LIST_REQUEST: "[enterpriseProjects] GET Enterprise Projects List Request",
  SET_ENTERPRISE_PROJECTS_LIST: "[enterpriseProjects] SET Enterprise Projects",
  RESET_ENTERPRISE_PROJECTS_LIST: "[enterpriseProjects] RESET Enterprise Projects",

  // Filters
  SET_ENTERPRISE_PROJECTS_FILTERS: "[enterpriseProjects] SET Enterprise Projects List Filters",
  SET_ENTERPRISE_PROJECTS_AUTOCOMPLETE_SUGGESTIONS:
    "[enterpriseProjects] SET Enterprise Projects Autocomplete Suggestions",

  // Seller Search
  GET_ORG_CONSULTANTS_REQUEST: "[enterpriseProjects] GET Org Sellers List",
  SET_ORG_CONSULTANTS: "[enterpriseProjects] SET Org Sellers List",
  RESET_ORG_CONSULTANTS: "[enterpriseProjects] RESET Org Sellers",

  // Drawers
  SET_OFFER_LEAD_DRAWER_VISIBLE: "[enterpriseProjects] SET Offer Lead Drawer Visible",
  SET_OFFER_LEAD_CONFIRMATION: "[enterpriseProjects] SET Offer Lead Confirmation",
  RESET_OFFER_LEAD_CONFIRMATION: "[enterpriseProjects] Reset Offer Lead Confirmation",
  SUBMIT_ENTERPRISE_LEAD_OFFER_TO_CONSULTANT: "[enterpriseProjects] Submit Enterprise Lead Offer To Seller",

  // Offers
  GET_ENTERPRISE_OFFER_LEAD_GEOCODE: "[enterpriseProjects] GET Enterprise Offer Lead Geocode",
  SET_ENTERPRISE_OFFER_LEAD_GEOCODE: "[enterpriseProjects] SET Enterprise Offer Lead Geocode",
  GET_ENTERPRISE_LEAD_OFFER_DETAILS: "[enterpriseProjects] GET Enterprise Lead Offer Details",
  SET_ENTERPRISE_LEAD_OFFER_DETAILS: "[enterpriseProjects] SET Enterprise Lead Offer Details",
  RESET_ENTERPRISE_LEAD_OFFER_DETAILS: "[enterpriseProjects] Reset Enterprise Lead Offer Details",
  SUBMIT_CONSULTANT_OFFER_LEAD_DECISION: "[enterpriseProjects] Submit Seller Offer Lead Decision",
  RESCIND_LEAD_OFFER_REQUEST: "[enterpriseProjects] Rescind lead offer",
  GET_LEAD_OFFER_RESPONSE_DETAILS: "[enterpriseProjects] GET Lead Offer Response Details",
  SET_LEAD_OFFER_RESPONSE_DETAILS: "[enterpriseProjects] SET Lead Offer Response Details",
  RESET_LEAD_OFFER_RESPONSE_DETAILS: "[enterpriseProjects] Reset Lead Offer Response Details",

  // CSV reports
  START_CONTRACTS_REPORT_REQUEST: "[enterpriseProjects] Start Contracts Report",
  GET_CONTRACTS_REPORT_REQUEST: "[enterpriseProjects] GET Contracts Report",
  SET_CONTRACTS_REPORT: "[enterpriseProjects] SET Contracts Report",
  CANCEL_LEAD_OFFER: "[enterpriseProjects] Cancel Lead Offer",
  GET_ENTERPRISE_PROJECT_LOOKUP_OPTIONS: "[enterpriseProjects] GET Enterprise Project Lookup Options",
  SET_ENTERPRISE_PROJECT_LOOKUP_OPTIONS: "[enterpriseProjects] SET Enterprise Project Lookup Options",

  GET_LEAD_GEN_PROJECTS_PRE_SALES: "[enterpriseProjects] GET Lead Gen Pre Sales Projects",
  SET_LEAD_GEN_PROJECTS_PRE_SALES: "[enterpriseProjects] SET Lead Gen Pre Sales Projects",

  GET_LEAD_GEN_PROJECTS_POST_SALES: "[enterpriseProjects] GET Lead Gen Projects Post Sales",
  SET_LEAD_GEN_PROJECTS_POST_SALES: "[enterpriseProjects] SET Lead Gen Projects Post Sales",
  // INSERTION_PT (for script, do not remove)
};
