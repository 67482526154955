import { styled } from "@mui/material/styles";
import { filterStyledProps } from "lib/helpers/filterStyledProps";

export const HeaderInfoSectionContainer = styled("div", { shouldForwardProp: filterStyledProps(["status"]) })<{
  isMiniHeaderActive: boolean;
}>(({ theme, isMiniHeaderActive }) => ({
  boxSizing: "border-box",
  width: "100%",
  background: theme.colors["table-edit-mode"],
  transition: "all .5s ease",
  ...(isMiniHeaderActive
    ? { height: "84px" }
    : {
        height: "180px",
        padding: "36px 40px 16px 40px",
        [theme.breakpoints.down("md")]: {
          paddingLeft: "13px",
          paddingRight: "13px",
          height: "214px",
        },
      }),
}));
