import React from "react";

import * as S from "./styles";

const DesignTeamNotes = () => {
  return (
    <S.NoteContainer>
      <S.NoteSemiBoldFont>
        You have selected for our design team to create your proposal! This option takes up to an hour, often times much
        less. Design edits can be performed by the seller after the initial design is completed.
      </S.NoteSemiBoldFont>
      <S.NoteBoldFont>Expectations for Turnaround Time:</S.NoteBoldFont>
      <S.NoteList>
        <li>Vision “Expedited” Requests: Estimated response time of 30 minutes.</li>
        <li>Vision “Next Day” (Standard) Requests: Anticipate a response within 24 hours.</li>
        <li>Sunnova Requests: Responses may take between 1 and 4 hours.</li>
      </S.NoteList>
      <S.NoteBoldFont>Operating Hours:</S.NoteBoldFont>
      <S.NoteList>
        <li>Monday - Friday: 5 AM PST - 8 PM PST</li>
        <li>Saturday - Sunday: 9 AM PST - 8 PM PST</li>
      </S.NoteList>
      <S.NoteItalicFont>
        Please note that if you submit requests at the end or outside of operating hours, they will be attended to first
        thing the following day.
      </S.NoteItalicFont>
    </S.NoteContainer>
  );
};

export default DesignTeamNotes;
