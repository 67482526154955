import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  newRegistrationInfo: {},
  applicationAgreement: {},
  leads: [],
  zipLookup: {},
  stripe: {},
};

export default {
  ambassador: createReducer(initialState, {
    [types.SET_AMBASSADOR_NEW_REGISTRATION_INFO](state, action) {
      return {
        ...state,
        newRegistrationInfo: action.payload,
      };
    },
    [types.SET_AMBASSADOR_APPLICATION_AGREEMENT](state, action) {
      return {
        ...state,
        applicationAgreement: action.payload,
      };
    },
    [types.SET_AMBASSADOR_LEADS](state, action) {
      return {
        ...state,
        leads: action.payload,
      };
    },
    [types.SET_STRIPE_ACCOUNT_INFO](state, action) {
      return {
        ...state,
        stripe: action.payload,
      };
    },
    [types.SET_AMBASSADOR_LEAD_ZIP_LOOKUP](state, action) {
      return {
        ...state,
        zipLookup: action.payload,
      };
    },
    [types.RESET_AMBASSADOR_LEAD_ZIP_LOOKUP](state) {
      return {
        ...state,
        zipLookup: initialState.zipLookup,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
