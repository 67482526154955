import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  services: {},
  userDetails: {},
  seatDetails: {},
  enterpriseSeatChangePreview: {},
  pendingSeat: {
    orgName: "",
    sellerPercentage: 0,
  },
};

export default {
  enterprisePersonnel: createReducer(initialState, {
    [types.SET_LIST_OF_ASSIGNABLE_SERVICES](state, action) {
      return {
        ...state,
        services: action.payload,
      };
    },

    [types.SET_ENTERPRISE_USER_DETAILS](state, action) {
      return {
        ...state,
        userDetails: action.payload,
      };
    },

    [types.SET_PENDING_SEAT](state, action) {
      return {
        ...state,
        pendingSeat: action.payload,
      };
    },

    [types.RESET_ENTERPRISE_USER_DETAILS](state) {
      return {
        ...state,
        userDetails: initialState.userDetails,
        seatDetails: initialState.seatDetails,
      };
    },

    [types.SET_ENTERPRISE_SERVICES_SEAT_CHANGE_PREVIEW](state, action) {
      return {
        ...state,
        enterpriseSeatChangePreview: action.payload,
      };
    },

    [types.SET_ENTERPRISE_SEAT_DETAILS](state, action) {
      return {
        ...state,
        seatDetails: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
