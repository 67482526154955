import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const ArchiveIcon = (props) => {
  const { fill, height, width, containerStyles, active, hovered } = props;

  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      {...containerStyles}
    >
      <svg width={width} height={height} viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.974 1.5242L20.2285 9.97909H14.0797C13.8321 9.97909 13.619 10.1541 13.5708 10.397C13.5486 10.5052 12.9884 13.0857 10.3294 13.0857C7.67047 13.0857 7.11023 10.5052 7.08771 10.3954C7.03817 10.1541 6.82547 9.97914 6.57914 9.97914H0.430336L3.68471 1.5242C3.8378 1.12655 4.22695 0.859375 4.65303 0.859375H16.0057C16.4318 0.859323 16.8209 1.12655 16.974 1.5242ZM13.864 3.53249H6.79475C6.50828 3.53249 6.27596 3.76481 6.27596 4.05128C6.27596 4.33776 6.50828 4.57007 6.79475 4.57007H13.864C14.1505 4.57007 14.3828 4.33776 14.3828 4.05128C14.3828 3.76481 14.1505 3.53249 13.864 3.53249ZM15.0873 6.54833H5.5715C5.28502 6.54833 5.05271 6.78064 5.05271 7.06712C5.05271 7.35359 5.28502 7.58591 5.5715 7.58591H15.0873C15.3737 7.58591 15.606 7.35359 15.606 7.06712C15.606 6.78064 15.3737 6.54833 15.0873 6.54833Z"
          fill={fill}
        />
        <path
          d="M6.88899 12.3406C7.45764 13.1498 8.52209 14.1144 10.3641 14.1144C12.206 14.1144 13.2705 13.1498 13.8391 12.3406C14.1887 11.8431 14.3943 11.345 14.5067 11.0079H20.4805V16.4893C20.4805 17.6335 19.5496 18.5645 18.4053 18.5645H2.32281C1.17857 18.5645 0.24765 17.6335 0.24765 16.4893V11.0078H6.22142C6.33373 11.345 6.53933 11.8431 6.88899 12.3406Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

ArchiveIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

ArchiveIcon.defaultProps = {
  fill: "var(--royal-peacock-blue)",
  height: "21",
  width: "19",
};

export default ArchiveIcon;
