import React from "react";
import PropTypes from "prop-types";

const StarIcon = ({ mainColor = "var(--royal-peacock-blue)", height = "12", width = "12", containerStyles }) => {
  return (
    <span style={{ paddingTop: "1px", ...containerStyles }}>
      <svg width={width} height={height} viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M23.0979 2.3541C23.6966 0.511482 26.3034 0.51148 26.9021 2.3541L31.2864 15.8475C31.5541 16.6716 32.3221 17.2295 33.1885 17.2295H47.3763C49.3138 17.2295 50.1193 19.7087 48.5519 20.8475L37.0737 29.1869C36.3727 29.6962 36.0794 30.5989 36.3472 31.423L40.7314 44.9164C41.3302 46.759 39.2212 48.2913 37.6538 47.1525L26.1756 38.8131C25.4746 38.3038 24.5254 38.3038 23.8244 38.8131L12.3462 47.1525C10.7788 48.2913 8.66985 46.759 9.26855 44.9164L13.6528 31.423C13.9206 30.5989 13.6273 29.6962 12.9263 29.1869L1.4481 20.8475C-0.119326 19.7087 0.686224 17.2295 2.62367 17.2295H16.8115C17.6779 17.2295 18.4459 16.6716 18.7136 15.8475L23.0979 2.3541Z"
          fill={mainColor}
        />
      </svg>
    </span>
  );
};

StarIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  mainColor: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

export default StarIcon;
