import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions, selectors } from "rdx";
import { sellerIntegrityModules } from "lib/misc/dropdownOptions";
import SessionClient from "util/SessionClient";
import { ModuleKeyT } from "containers/Admin/users/components/UserDetailDrawer/types";
import { isUndefined } from "types/guards";
import ConfirmModal from "../ConfirmModal";

import * as S from "./styles";

const IntegrityTrainingModal = () => {
  const dispatch = useDispatch();
  const session = new SessionClient();
  const { user } = session;
  const modalVisible = useSelector(selectors.selectIntegrityTrainingModalVisible);
  const integrityModules: ModuleKeyT[] | undefined = user?.requiredIntegrityModules();

  const moduleList = isUndefined(integrityModules)
    ? []
    : sellerIntegrityModules.filter((module) => integrityModules.includes(module.key));

  const handleCloseModal = () => {
    dispatch(actions.setIntegrityTrainingModalVisible(false));
  };
  return (
    <ConfirmModal
      open={modalVisible}
      title="Access Restricted"
      handleConfirm={() => user?.getLitmosLoginLink()}
      justifyFooterContent="center"
      confirmButtonText="Go to Training"
      maxWidth={491}
      handleCancel={handleCloseModal}
    >
      <S.ModalContent>
        Your access to this feature has been restricted due to a violation reported on your account. You must complete
        the following modules to regain access:
        {moduleList.map((module) => (
          <S.RequiredModule>{module.displayName}</S.RequiredModule>
        ))}
      </S.ModalContent>
    </ConfirmModal>
  );
};

export default IntegrityTrainingModal;
