/**
 * addressLookup makes an api call to smarty streets and returns a Promise of arrays
 * @param {string} value - address input
 * @returns {Promise<Array<{street_line: string, city: string, state: string, zipcode: string}>> | Promise<[]>}
 */

const addressLookup = async (value = "") => {
  let suggestions = [];

  const testJson = (input) => {
    try {
      const json = JSON.parse(input);
      if (typeof json === "object") {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  if (value.length <= 3 || testJson(value)) {
    // prevent request if value in address field is too short or if AutoComplete onChange cb fires on autocomplete select
    return suggestions;
  }

  const requestUrl = new URL("https://us-autocomplete-pro.api.smartystreets.com/lookup");
  requestUrl.searchParams.append("auth-id", process.env.REACT_APP_SMARTYSTREETS_AUTH_ID);
  requestUrl.searchParams.append("search", value);

  try {
    const response = await fetch(requestUrl, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const data = await response.json();
    suggestions = data.suggestions;

    return suggestions;
  } catch (error) {
    return suggestions;
  }
};

export default addressLookup;
