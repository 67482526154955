import { put, select, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/consultantDashboard/messages";
import selectors from "rdx/modules/consultantDashboard/selectors";
import { camelizeKeys } from "lib/helpers";

function* getReferralsLeaderboard(action) {
  const { leaderboardTimeSpan, teamOnly, userId } = action.payload;
  const { success, data, error } = yield* makeRequest.get("/consultant_dashboard/referrals_rank", {
    leaderboard_time_span: leaderboardTimeSpan,
    team_only: teamOnly,
    user_id: userId,
  });

  if (success && data) {
    const current = yield select(selectors.getSellerDashboard);
    const next = { ...current, ...camelizeKeys(data.body) };
    yield all([put(actions.setSellerDashboard(next))]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_REFERRALS_LEADERBOARD,
    });
  }

  return null;
}

export default getReferralsLeaderboard;
