const commonTabs = [
  {
    key: "events-and-promos",
    label: "Events & Promos",
  },
  {
    key: "powur-updates",
    label: "Powur Updates",
  },
];

export { commonTabs };

export default [...commonTabs];
