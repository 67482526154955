import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import actions from "rdx/actions";
import messages from "../messages";

function* updateProposalSettings(action) {
  const { proposalSettings, id } = action.payload;

  const params = {
    proposalSettings,
  };
  const { success, data, error } = yield* makeRequest.patch(`/provider_leads/${id}/proposal_settings`, params);
  if (success && data) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_PROVIDER_LEAD_SUCCESS,
        }),
      ),
    ]);
  } else {
    return yield put(actions.setFormErrors({ error }));
  }
  return null;
}

export default updateProposalSettings;
