import type { CallByDotNotationFuncTypes } from "util/SessionClient";

const keys = ["subscriptions", "payment-info"];

type TabsType = {
  key: string;
  label: string;
  hideIf?: CallByDotNotationFuncTypes;
};

const commonTabs: TabsType[] = [
  {
    key: keys[0],
    label: "Subscriptions",
  },
  {
    key: keys[1],
    label: "Payment Info",
    hideIf: "isSeatedNonOwnerUser",
  },
];

const adminTabs = [];

export { commonTabs, adminTabs, keys };

export default [...commonTabs, ...adminTabs];
