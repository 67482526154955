import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  platformSettings: [],
  serviceAreas: [],
  enterpriseAdminSettings: {},
  powurPairWeights: [],
  payAdvanceSettings: [],
};

export default {
  settings: createReducer(initialState, {
    [types.SET_ADMIN_PLATFORM_SETTINGS](state, action) {
      return {
        ...state,
        platformSettings: action.payload,
      };
    },

    [types.SET_ADMIN_SERVICE_AREAS](state, action) {
      return {
        ...state,
        serviceAreas: action.payload,
      };
    },
    [types.SET_POWUR_PAIR_WEIGHTS](state, action) {
      return {
        ...state,
        powurPairWeights: action.payload,
      };
    },
    [types.SET_ADMIN_VOLUME_TIERS_SETTINGS](state, action) {
      return {
        ...state,
        enterpriseAdminSettings: {
          ...state.enterpriseAdminSettings,
          volumeTiers: action.payload,
        },
      };
    },
    [types.SET_ADMIN_ADVANCE_SETTINGS](state, action) {
      return {
        ...state,
        payAdvanceSettings: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
