import z from "zod";
import {
  EnrollmentSchema,
  EnrollmentServiceSchema,
  EnterpriseMemberSchema,
  FlatEnrollmentSchema,
  GetEnrollmentResponseBodySchema,
  GetEnrollmentResponseSchema,
  ServiceSchema,
  UpdateEnrollmentResponseSchema,
  UpgradePreviewResponseSchema,
} from "../schemas";

export type Enrollment = z.infer<typeof EnrollmentSchema>;
export type GetEnrollmentResponse = z.infer<typeof GetEnrollmentResponseSchema>;
export type Service = z.infer<typeof ServiceSchema>;
export type GetEnrollmentResponseBody = z.infer<typeof GetEnrollmentResponseBodySchema>;
export type UpdateEnrollmentResponse = z.infer<typeof UpdateEnrollmentResponseSchema>;
export type FlatEnrollment = z.infer<typeof FlatEnrollmentSchema>;
export type EnterpriseMember = z.infer<typeof EnterpriseMemberSchema>;
export type EnrollmentService = z.infer<typeof EnrollmentServiceSchema>;
export type UpgradePreviewResponse = z.infer<typeof UpgradePreviewResponseSchema>;

export enum PaymentStatuses {
  ACTIVE = "active",
  CANCELED = "canceled",
  CANCELING = "canceling",
  SEATED = "seated",
}
