/**
 * Represents the configuration options for a Tab component.
 *
 * @property {string} [conditionalRenderSelector] - The state selector that determines whether the Tab should be rendered. It is optional and defaults to showing the Tab.
 * @property {string} [breadcrumbLabel] - The label to be used for the breadcrumb navigation when the Tab is active. It is optional and defaults to no label.
 * @property {string[]} [permission] - An array of permission strings that define the required permissions to access the Tab. It is optional and defaults to no specific permissions required.
 */
type TabOptions = {
  conditionalRenderSelector?: string;
  breadcrumbLabel?: string;
  permission?: string[];
};

/**
 * Represents a tab element.
 *
 * @property {string} key - The key of the tab.
 * @property {string} label - The label of the tab.
 * @property {TabOptions} [tabOptions] - Optional configuration options for the tab.
 */
type Tab = {
  key: string;
  label: string;
  tabOptions?: TabOptions;
};

/**
 * Retrieves an array of keys from a given tabs object.
 *
 * @param {Record<string, { key: string }>} tabs - The tabs object containing keys.
 * @returns {string[]} - An array of keys extracted from the tabs object.
 */
const getTabKeys = (tabs: Record<string, { key: string }>): string[] => Object.values(tabs).map((tab) => tab.key);

/**
 * Creates a new Tab object with the given key, label, and optional tab options.
 *
 * @param {string} key - The key of the tab.
 * @param {string} label - The label of the tab.
 * @param {TabOptions} [tabOptions] - Optional tab options.
 * @returns {Tab} The created Tab object.
 */
const createTab = (key: string, label: string, tabOptions?: TabOptions): Tab => ({
  key,
  label,
  ...tabOptions,
});

/**
 * Merges an array of objects containing tabs into a single array of tabs.
 *
 * @template T - The type of the object containing tabs.
 * @param {T[]} tabObjects - An array of objects containing tabs to merge.
 * @returns {Tab[]} - The merged array of tabs.
 */
const mergeTabObjects = <T extends Record<string, Tab>>(tabObjects: T[]): Tab[] => {
  return tabObjects.flatMap(Object.values);
};

export { createTab, getTabKeys, mergeTabObjects };
