import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getProposalSettings(action) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/provider_leads/${id}/proposal_settings`);
  if (data)
    if (success && data) {
      yield put(actions.setProposalSettings(keysToCamelCase(data.body.proposal_settings)));
    } else {
      return getErrorActions({
        error,
        message: messages.ERROR_GETTING_PRPOSAL_SETTINGS,
      });
    }

  return null;
}

export default getProposalSettings;
