import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/platform/types";
import getReminderNotifications from "./getReminderNotifications";
import getReminderNotificationsCount from "./getReminderNotificationsCount";
import toggleReminderNotificationViewed from "./toggleReminderNotificationViewed";
import getPlatformServices from "./getPlatformServices";
import getInboxNotifications from "./getInboxNotifications";

// IMPORT_PT (for script, do not remove)

function* watchPlatformSagas() {
  yield trackRequests(takeEvery, types.GET_REMINDER_NOTIFICATIONS, getReminderNotifications);

  yield trackRequests(takeEvery, types.GET_REMINDER_NOTIFICATIONS_COUNT, getReminderNotificationsCount);

  yield trackRequests(takeEvery, types.TOGGLE_REMINDER_NOTIFICATION_VIEWED_REQUEST, toggleReminderNotificationViewed);

  yield trackRequests(takeEvery, types.GET_PLATFORM_SERVICES, getPlatformServices);

  yield trackRequests(takeEvery, types.GET_INBOX_NOTIFICATIONS, getInboxNotifications);

  // INSERTION_PT (for script, do not remove)
}

export default watchPlatformSagas;
