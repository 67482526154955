import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "lib/helpers";
import _ from "lodash";
import Payout from "models/Payout";
import styles from "../../AmbassadorDashboard.module.less";

const ReferralStatus = (props) => {
  const {
    lead: { firstName, lastName, progress, updatedAt, payouts },
  } = props;
  const TIMESTAMPS = {
    submit_at: "submitted",
    proposal_at: "proposal",
    contract_at: "contract",
    permit_submit_at: "permit",
    install_at: "installed",
  };

  const statusLabel = () => {
    if (payouts?.length) {
      if (payouts[0].paid_at) return `Bonus received on: ${formatDate(payouts[0].paid_at)}`;
      return `Last updated: ${formatDate(updatedAt)} - Payout Pending`;
    }
    return `Last updated: ${formatDate(updatedAt)}`;
  };

  return (
    <div className={styles.referralStatus}>
      <div className={styles.name}>{`${firstName} ${lastName}`}</div>
      <div className={styles.statusBar}>
        {progress.map(({ timestamp, past }, idx) => {
          const classNames = [
            past ? styles.done : styles.notDone,
            idx === 0 ? styles.firstItem : styles.parallelogram,
          ].join(" ");
          return (
            <div className={classNames} key={`$referral-{name}-status-${timestamp}`}>
              <span className={styles.display}>{_.startCase(TIMESTAMPS[timestamp])}</span>
            </div>
          );
        })}
      </div>
      <div className={[styles.updatedAt, payouts?.[0]?.paid_at ? styles.complete : ""].join(" ")}>{statusLabel()}</div>
    </div>
  );
};

ReferralStatus.propTypes = {
  lead: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    progress: PropTypes.arrayOf(
      PropTypes.shape({
        timestamp: PropTypes.string,
        value: PropTypes.string,
        past: PropTypes.bool,
      }),
    ),
    updatedAt: PropTypes.string,
    payouts: PropTypes.arrayOf(Payout.types()),
  }),
};

export default ReferralStatus;
