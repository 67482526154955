import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const EnterprisePersonnelIcon = (props) => {
  const { fill, height, width, active, hovered } = props;

  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      style={{
        paddingTop: "1px",
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.enterpriseIcon}
      >
        <circle cx="10.4844" cy="10.7383" r="10" fill="var(--royal-peacock-blue)" />
        <path
          d="M7.48068 7.77746C8.14728 7.77746 8.68767 7.2344 8.68767 6.56451C8.68767 5.89462 8.14728 5.35156 7.48068 5.35156C6.81407 5.35156 6.27368 5.89462 6.27368 6.56451C6.27368 7.2344 6.81407 7.77746 7.48068 7.77746Z"
          fill={fill}
        />
        <path
          d="M10.4537 7.77769C11.1204 7.77769 11.6608 7.23458 11.6608 6.56463C11.6608 5.89467 11.1204 5.35156 10.4537 5.35156C9.78702 5.35156 9.24658 5.89467 9.24658 6.56463C9.24658 7.23458 9.78702 7.77769 10.4537 7.77769Z"
          fill={fill}
        />
        <path
          d="M13.4132 7.77769C14.0798 7.77769 14.6203 7.23458 14.6203 6.56463C14.6203 5.89467 14.0798 5.35156 13.4132 5.35156C12.7465 5.35156 12.2061 5.89467 12.2061 6.56463C12.2061 7.23458 12.7465 7.77769 13.4132 7.77769Z"
          fill={fill}
        />
        <path
          d="M12.1842 8.22266H8.74204C8.44789 8.22266 8.20947 8.46225 8.20947 8.75761V11.8158C8.20947 12.1115 8.44789 12.3508 8.74204 12.3508H8.90587V16.1329H11.9575V12.3508H12.1842C12.4783 12.3508 12.7165 12.1114 12.7165 11.8158V8.75761C12.7164 8.46236 12.4783 8.22266 12.1842 8.22266Z"
          fill={fill}
        />
        <path
          d="M7.45669 11.8002V8.74199C7.45669 8.55073 7.50098 8.37063 7.57556 8.20703H5.79796C5.50404 8.20703 5.26562 8.44662 5.26562 8.74199V11.8002C5.26562 12.0959 5.50404 12.3352 5.79796 12.3352H5.96191V16.1173H8.15286V12.947C7.73974 12.7328 7.45669 12.299 7.45669 11.8002Z"
          fill={fill}
        />
        <path
          d="M15.1702 8.20703H13.3458C13.4204 8.37063 13.4648 8.55085 13.4648 8.74199V11.8002C13.4648 12.324 13.1526 12.7753 12.7056 12.9773V16.1173H14.9438V12.3352H15.1701C15.4641 12.3352 15.7026 12.0958 15.7026 11.8002V8.74199C15.7028 8.44674 15.4641 8.20703 15.1702 8.20703Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

EnterprisePersonnelIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

EnterprisePersonnelIcon.defaultProps = {
  fill: "white",
  height: "19",
  width: "26",
};

export default EnterprisePersonnelIcon;
