import React, { CSSProperties } from "react";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import { Controller, useFormContext } from "react-hook-form";
import { SxProps, Theme } from "@mui/material";

import * as S from "./styles";

type RadioOptionsT = {
  label: string;
  value: unknown;
  labelSubtext?: string;
  textFieldEl?: React.ReactNode;
  showTextFieldEl?: boolean;
  boxSxProps?: SxProps<Theme>;
}[];

type FormRadioGroupProps = {
  mainLabel: string;
  subLabel?: string | React.ReactElement;
  radioOptions: RadioOptionsT;
  name: string;
  helperText?: string | undefined;
  error: boolean;
  row?: boolean;
  disabled?: boolean;
  mainLabelStyle?: CSSProperties;
} & RadioGroupProps;

const FormRadioGroup = ({
  mainLabel,
  subLabel,
  radioOptions,
  name,
  helperText,
  error,
  row = false,
  disabled = false,
  mainLabelStyle = {},
  ...rest
}: FormRadioGroupProps) => {
  const { control } = useFormContext();

  return (
    <>
      <S.LabelGroupWrapper>
        <S.StyledFormLabel style={mainLabelStyle}>{mainLabel}</S.StyledFormLabel>
        <S.SubLabel>{subLabel}</S.SubLabel>
      </S.LabelGroupWrapper>
      <Controller
        name={name}
        control={control}
        defaultValue={rest.defaultValue || ""}
        render={({ field }) => (
          <RadioGroup row={row} style={rest.style || {}} {...field}>
            {radioOptions.map(({ label, labelSubtext, value, textFieldEl, showTextFieldEl, boxSxProps }) => (
              <Box sx={{ display: "flex", ...boxSxProps }}>
                <FormControlLabel
                  key={label}
                  defaultValue={rest.defaultValue}
                  disabled={disabled}
                  value={value}
                  control={
                    <S.StyledRadio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 28,
                          "& path": {
                            stroke: "white",
                            strokeWidth: 1,
                          },
                        },
                      }}
                    />
                  }
                  label={
                    <S.RadioLabel>
                      {label}&nbsp;
                      {labelSubtext && <span>{labelSubtext}</span>}
                    </S.RadioLabel>
                  }
                />
                {showTextFieldEl && textFieldEl}
              </Box>
            ))}
          </RadioGroup>
        )}
      />
      <S.HelperText error={error}>{helperText}</S.HelperText>
    </>
  );
};

export type { RadioOptionsT };

export default FormRadioGroup;
