import { all } from "redux-saga/effects";

import watchAppSagas from "rdx/modules/app/sagas";
import watchLeadsSagas from "rdx/modules/leads/sagas";
import watchProjectsSagas from "rdx/modules/projects/sagas";
import watchProjectNoticesSagas from "rdx/modules/tickets/sagas";
import watchUsersSagas from "rdx/modules/users/sagas";
import watchTeamSagas from "rdx/modules/team/sagas";
import watchAdminProjectsSagas from "rdx/modules/adminProjects/sagas";
import watchWorkflowsSagas from "rdx/modules/workflows/sagas";
import watchAmbassadorSagas from "rdx/modules/ambassador/sagas";
import watchProfileSagas from "rdx/modules/profile/sagas";
import watchReportsSagas from "rdx/modules/reports/sagas";
import watchAdmindashboardSagas from "rdx/modules/adminDashboard/sagas";
import watchSellerDashboardSagas from "rdx/modules/consultantDashboard/sagas";
import watchHelpjuiceSagas from "rdx/modules/helpjuice/sagas";
import watchSettingsSagas from "rdx/modules/settings/sagas";
import watchRbacSagas from "rdx/modules/rbac/sagas";
import watchAdminNoticesSagas from "rdx/modules/adminNotices/sagas";
import watchSmsSagas from "rdx/modules/adminSms/sagas";
import watchEarningsSagas from "rdx/modules/earnings/sagas";
import watchRegistrationSagas from "rdx/modules/registration/sagas";
import watchFinancialsSagas from "rdx/modules/financials/sagas";
import watchMessagingmetricsSagas from "rdx/modules/adminReports/sagas";
import watchAdminProposalsSagas from "rdx/modules/adminProposals/sagas";
import watchMentorratingsSagas from "rdx/modules/mentorRatings/sagas";
import watchWorkSchedulesSagas from "rdx/modules/workSchedules/sagas";
import watchCareerListingsSagas from "rdx/modules/careerListings/sagas";
import watchStateLicensesSagas from "rdx/modules/stateLicenses/sagas";
import watchAuthSagas from "rdx/modules/auth/sagas";
import watchFilterSetsSagas from "rdx/modules/filterSets/sagas";
import watchCompensationSagas from "rdx/modules/compensation/sagas";
import watchEnterpriseProjectsSagas from "rdx/modules/enterpriseProjects/sagas";
import watchEnterpriseOrgSagas from "rdx/modules/enterpriseOrg/sagas";
import watchEnterpriseLeadsSagas from "rdx/modules/enterpriseLeads/sagas";
import watchEnterprisePersonnelSagas from "rdx/modules/enterprisePersonnel/sagas";
import watchEnterpriseEarningsSagas from "rdx/modules/enterpriseEarnings/sagas";
import watchEnrollmentSagas from "rdx/modules/enrollment/sagas";
import watchEnterpriseApplicationsSagas from "rdx/modules/enterpriseApplications/sagas";
import watchBuildPartnerApplicationsSagas from "rdx/modules/buildPartnerApplications/sagas";
import watchPlatformSagas from "rdx/modules/platform/sagas";
import watchComplianceIssuesSagas from "rdx/modules/complianceIssues/sagas";
import watchAdminLeadsSagas from "rdx/modules/adminLeads/sagas";
import watchDashboardPromotionsSagas from "rdx/modules/dashboardPromotions/sagas";
import watchDashboardUpdatesSagas from "rdx/modules/dashboardUpdates/sagas";
import watchSeatapprovalsSagas from "rdx/modules/seatApprovals/sagas";
import watchEnterpriseProLaborFormSagas from "rdx/modules/enterpriseProLaborForm/sagas";
import watchNotificationsSagas from "rdx/modules/notifications/sagas";
import watchViewedAssetsSagas from "rdx/modules/viewedAssets/sagas";
import watchPreferredpartnersSagas from "rdx/modules/preferredPartners/sagas";
import watchPlatformModulesSagas from "rdx/modules/platformModules/sagas";
import watchRoofingSagas from "rdx/modules/roofing/sagas";
import watchHomeownerSagas from "rdx/modules/homeowner/sagas";
import watchCrmActivitySagas from "rdx/modules/crmActivities/sagas";
import watchSalesTeamsSagas from "rdx/modules/salesTeams/sagas";
import watchSellerIntegritySagas from "rdx/modules/sellerIntegrity/sagas";
import watchPublicApiProxySagas from "rdx/modules/publicApiProxy/sagas";
import watchProjectpresalesSagas from "rdx/modules/projectPreSales/sagas";
import watchFundingProposalsSagas from "rdx/modules/fundingProposals/sagas";
import watchFileuploadSagas from "rdx/modules/fileUpload/sagas";
import watchCustomFieldsSagas from "./modules/customFields/sagas";
// IMPORT_PT (for script, do not remove

function* rootSaga() {
  yield all([
    watchAppSagas(),
    watchLeadsSagas(),
    watchProjectsSagas(),
    watchProjectNoticesSagas(),
    watchUsersSagas(),
    watchTeamSagas(),
    watchAdminProjectsSagas(),
    watchWorkflowsSagas(),
    watchAmbassadorSagas(),
    watchProfileSagas(),
    watchReportsSagas(),
    watchAdmindashboardSagas(),
    watchSellerDashboardSagas(),
    watchHelpjuiceSagas(),
    watchSettingsSagas(),
    watchRbacSagas(),
    watchAdminNoticesSagas(),
    watchSmsSagas(),
    watchEarningsSagas(),
    watchRegistrationSagas(),
    watchFinancialsSagas(),
    watchMessagingmetricsSagas(),
    watchAdminProposalsSagas(),
    watchMentorratingsSagas(),
    watchWorkSchedulesSagas(),
    watchCareerListingsSagas(),
    watchStateLicensesSagas(),
    watchAuthSagas(),
    watchFilterSetsSagas(),
    watchCompensationSagas(),
    watchEnterpriseProjectsSagas(),
    watchEnterpriseOrgSagas(),
    watchEnterpriseLeadsSagas(),
    watchEnterprisePersonnelSagas(),
    watchEnterpriseEarningsSagas(),
    watchEnrollmentSagas(),
    watchEnterpriseApplicationsSagas(),
    watchBuildPartnerApplicationsSagas(),
    watchPlatformSagas(),
    watchComplianceIssuesSagas(),
    watchAdminLeadsSagas(),
    watchDashboardPromotionsSagas(),
    watchDashboardUpdatesSagas(),
    watchSeatapprovalsSagas(),
    watchEnterpriseProLaborFormSagas(),
    watchNotificationsSagas(),
    watchViewedAssetsSagas(),
    watchPreferredpartnersSagas(),
    watchPlatformModulesSagas(),
    watchRoofingSagas(),
    watchHomeownerSagas(),
    watchCrmActivitySagas(),
    watchSalesTeamsSagas(),
    watchSellerIntegritySagas(),
    watchPublicApiProxySagas(),
    watchProjectpresalesSagas(),
    watchFundingProposalsSagas(),
    watchFileuploadSagas(),
    watchCustomFieldsSagas(),
    // INSERTION_PT (for script, do not remove)
  ]);
}

export default rootSaga;
