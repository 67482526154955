import React from "react";
import PropTypes from "prop-types";

const TripleUpChevron = (props) => {
  const { fill, height, width, containerStyles } = props;

  return (
    <div style={{ ...containerStyles }}>
      <svg width={width} height={height} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.7525 0.654103C11.1703 0.292852 11.8476 0.292852 12.2653 0.654104C12.6831 1.01536 12.6831 1.60106 12.2653 1.96231L2.26921 10.6065C1.85146 10.9677 1.17415 10.9677 0.756401 10.6065C0.33865 10.2452 0.33865 9.65953 0.756401 9.29828L10.7525 0.654103Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
        <path
          d="M12.2475 0.654103C11.8297 0.292852 11.1524 0.292852 10.7347 0.654104C10.3169 1.01536 10.3169 1.60106 10.7347 1.96231L20.7308 10.6065C21.1485 10.9677 21.8258 10.9677 22.2436 10.6065C22.6614 10.2452 22.6613 9.65953 22.2436 9.29828L12.2475 0.654103Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
        <path
          d="M10.7525 6.6541C11.1703 6.29285 11.8476 6.29285 12.2653 6.6541C12.6831 7.01536 12.6831 7.60106 12.2653 7.96231L2.26921 16.6065C1.85146 16.9677 1.17415 16.9677 0.756401 16.6065C0.33865 16.2452 0.33865 15.6595 0.756401 15.2983L10.7525 6.6541Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
        <path
          d="M12.2475 6.6541C11.8297 6.29285 11.1524 6.29285 10.7347 6.6541C10.3169 7.01536 10.3169 7.60106 10.7347 7.96231L20.7308 16.6065C21.1485 16.9677 21.8258 16.9677 22.2436 16.6065C22.6614 16.2452 22.6613 15.6595 22.2436 15.2983L12.2475 6.6541Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
        <path
          d="M10.7525 12.6541C11.1703 12.2929 11.8476 12.2929 12.2653 12.6541C12.6831 13.0154 12.6831 13.6011 12.2653 13.9623L2.26921 22.6065C1.85146 22.9677 1.17415 22.9677 0.756401 22.6065C0.33865 22.2452 0.33865 21.6595 0.756401 21.2983L10.7525 12.6541Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
        <path
          d="M12.2475 12.6541C11.8297 12.2929 11.1524 12.2929 10.7347 12.6541C10.3169 13.0154 10.3169 13.6011 10.7347 13.9623L20.7308 22.6065C21.1485 22.9677 21.8258 22.9677 22.2436 22.6065C22.6614 22.2452 22.6613 21.6595 22.2436 21.2983L12.2475 12.6541Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
      </svg>
    </div>
  );
};

TripleUpChevron.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

TripleUpChevron.defaultProps = {
  height: "24px",
  width: "23px",
  fill: "var(--picton-blue)",
};

export default TripleUpChevron;
