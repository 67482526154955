import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, connect, useSelector } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import types from "rdx/modules/projects/types";
import selectors from "rdx/selectors";
import { actions } from "rdx";
import messages from "rdx/modules/projects/messages";
import { useEffectOnce } from "react-use";
import { sortBy, isEmpty, isNull } from "lodash";

import Lead from "models/Lead";
import ProviderLead from "models/ProviderLead";
import Project from "models/Project";
import MessageEvent from "models/MessageEvent";

import useLoading from "hooks/useLoading";
import usePermissions, { permissionTypes } from "hooks/usePermissions";
import useModalControls from "hooks/useModalControls";
import useRsmRequirementsMet from "hooks/useRsmRequirementsMet";
import FEATURE_FLAGS from "@flags";

import { Menu, Dropdown, Button } from "antd";

import CloseProjectModal from "@modals/CloseProjectModal";
import { AlertCopy } from "components/Typography";
import DownCaret from "components/Icons/Chevrons/DownCaretFilled";
import { AlertModal } from "components/Modals/AlertModal/AlertModal";
import SellerChangeOrderConfirm from "@mui-styled-components/Modals/SellerChangeOrderConfirm";
import styles from "./ActionsDropdown.module.less";
import MenuItem from "./menuItem";
import { modalState } from "./modalStates";

const ActionsDropdown = ({
  projectDetails,
  adminProjectDetails,
  roofingProposals,
  fundingProposals,
  latestMessage,
}) => {
  const sunnovaProposals = fundingProposals?.filter((proposal) => proposal.fundingCompanySlug === "sunnova");
  const auroraProposals = fundingProposals?.filter((proposal) => proposal.fundingCompanySlug === "aurora");

  const isAdmin = !isEmpty(adminProjectDetails);

  const providerLead = new ProviderLead(projectDetails.providerLead);
  const adminProject = new Project(adminProjectDetails);

  const details = isAdmin ? adminProject : providerLead;
  const projectId = details.props.id;
  const { lead } = details.props;
  const leadAddress = details.props.lead?.address;
  const leadId = lead?.id;

  const reason = details.props.proposalNonrequestableReason;
  const soloReason = details.props.soloProposalNonrequestableReason;
  const lightreachReason = details.props.lightreachProposalNonrequestableReason;
  const { roofingNonRequestableReason, canRequestTpoPricing, canRequestRoofingProposal, isValidServiceArea } =
    details.props;
  const hasRoofingProposals = roofingProposals?.length > 0;
  const hasSunnovaProposals = !!sunnovaProposals?.length;
  const hasAuroraProposals = Boolean(auroraProposals?.length);
  const requestOrRevision = hasRoofingProposals ? "revision" : "request";
  const latestRoofingProposal = useSelector(selectors.selectLatestRoofingProposal);
  const isTpoWorksheetRequested = useSelector(selectors.selectIsTpoPricingWorksheetRequested);
  const latestSunnovaProposal = sunnovaProposals?.find((proposal) => proposal.isLatestVersion);
  const latestAuroraProposal = auroraProposals?.find((proposal) => proposal.isLatestVersion);
  const [closeProjectVisible, setCloseProjectVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(
    <AlertCopy>
      <></>
    </AlertCopy>,
  );
  const [confirmCOModalOpen, setConfirmCOModalOpen] = useState(false);

  const rsmRequirementsPassed = useRsmRequirementsMet(details.props.requiresRsm);

  const dispatch = useDispatch();
  const { visible, open, close } = useModalControls();

  const loading = useLoading({
    watchRequests: [types.REQUEST_VISION_PROPOSAL],
  });

  useEffect(() => {
    if (latestMessage?.message === messages.ERROR_CREATING_TPO_WORKSHEET) {
      dispatch(actions.setIsTpoPricingWorksheetRequested(false));
    }
  }, [dispatch, latestMessage]);

  const handleOpenRequestDrawer = (requestType, proposalId = null) => {
    dispatch(actions.setProposalRequestDrawerVisible({ requestType, projectId }));

    if (proposalId) {
      dispatch(actions.setProposalRequestDrawerId(proposalId));
    }
  };

  const handleOpenLeadDrawer = (proposalType) => {
    dispatch(
      actions.setLeadToProposalDrawerOpen({
        leadId,
        projectId,
        proposalType,
        visible: true,
      }),
    );
  };

  const handleClose = () => {
    setCloseProjectVisible(true);
  };

  const handleReopenClick = () => {
    if (isAdmin) {
      adminProject.reopen();
    } else {
      providerLead.reopen();
    }
  };

  const launchVisionLite = () => {
    const params = {
      address: leadAddress.street,
      city: leadAddress.city,
      zipcode: leadAddress.zip,
      state: leadAddress.state,
      name: lead.fullName,
      number: projectId,
    };

    const queryString = new URLSearchParams(params).toString();

    const url = `${process.env.REACT_APP_VISION_LITE_URL}/?${queryString}`;

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleRoofingClick = (type) => {
    if (!canRequestRoofingProposal) {
      const { title, content } = modalState(roofingNonRequestableReason);
      setModalTitle(title);
      setModalContent(content);
      open();
    } else {
      dispatch(
        actions.setRoofingActionDetails({
          selectedProjectId: details.props.id,
          selectedProposalId: latestRoofingProposal?.id,
          selectedViewType: "seller",
          type,
        }),
      );
    }
  };

  const visionNonRequestableReason = () => {
    if (!rsmRequirementsPassed) {
      return "rsm_only";
    }

    return details.props.visionProposalNonrequestableReason || null;
  };

  const canRequestVisionProposal = () => {
    const requestReason = visionNonRequestableReason();

    if (!requestReason) {
      return true;
    }

    return requestReason === "qualify_incomplete";
  };

  const { HAS_VISION_ACCESS, SUNNOVA_CERTIFIED, AURORA_SUBMIT } = usePermissions({
    permissionRequests: [
      permissionTypes.HAS_VISION_ACCESS,
      permissionTypes.SUNNOVA_CERTIFIED,
      permissionTypes.AURORA_SUBMIT,
    ],
  });

  const userCanRequestTpoPricing =
    HAS_VISION_ACCESS &&
    canRequestTpoPricing &&
    !isTpoWorksheetRequested &&
    FEATURE_FLAGS.includes("tpoPricingWorksheet");

  const isClosedByPowurOS = details?.props?.closeReason?.description === "Closed by PowurOS";

  const { closeReason } = details.props;
  const closeReasons = sortBy(details.props.closeReasons, "class.order");
  const osCloseReason = closeReasons ? closeReasons.find((r) => r.class.description === "Closed by PowurOS") : null;

  const determineReasonCode = (actionReason) => {
    if (!rsmRequirementsPassed) {
      return "rsm_only";
    }

    switch (actionReason) {
      case "sunnova":
        return details.props.sunnovaNonRequestableReason || null;
      case "soloProposal":
        return soloReason || null;
      case "lightreachProposal":
        return lightreachReason || null;
      case "visionProposal":
        return visionNonRequestableReason();
      case "change_order":
        return details.props.changeOrderNonrequestableReason || null;
      case "roofing":
        return details.props.canSubmitOrReviseRoofing ? null : "roofing_in_progress";
      case "aurora":
        return details.props.auroraNonrequestableReason || null;
      default:
        return null;
    }
  };

  const canRequestStatuses = {
    sunnova: details.props.canRequestSunnovaProposal,
    aurora: details.props.canRequestAuroraProposal,
    solo: details.props.canRequestProposal,
    roofing: details.props.canSubmitOrReviseRoofing,
    change_order: details.props.canRequestChangeOrder,
    vision: canRequestVisionProposal(),
  };

  const canRequest = (proposalType) => {
    if (!rsmRequirementsPassed) {
      return false;
    }

    switch (proposalType) {
      case "solo":
        return canRequestSoloProposal();
      case "lightreach":
        return canRequestLightReachProposal();
      default:
        return canRequestStatuses[proposalType];
    }
  };

  const reopenOption = () => {
    let option = null;
    const notOsClosed = closeReason && closeReason.description !== osCloseReason;
    if (isAdmin || notOsClosed) {
      if (details?.props?.closeAt) {
        option = (
          <MenuItem
            handleClick={handleReopenClick}
            reason={isClosedByPowurOS ? "Closed by PowurOS" : reason}
            text="Reopen Project"
            statusAction={!isClosedByPowurOS}
          />
        );
      }
    }
    return option;
  };

  const canRequestSoloProposal = () => {
    if (canRequestLightReachProposal()) {
      return false;
    }

    return isNull(soloReason) || soloReason === "qualify_incomplete";
  };

  const canRequestLightReachProposal = () => isNull(lightreachReason) || lightreachReason === "qualify_incomplete";

  const menu = (
    <Menu style={{ width: 200, borderRadius: 6, background: "white" }} data-test-id="project-actions-dropdown">
      {HAS_VISION_ACCESS && (
        <MenuItem
          reason={determineReasonCode("visionProposal")}
          handleClick={() => handleOpenLeadDrawer("vision")}
          canRequest={canRequest("vision") && !loading}
          text="Vision Proposal Request"
        />
      )}
      {canRequest("solo") && (
        <MenuItem
          handleClick={() => handleOpenLeadDrawer("solo")}
          reason={determineReasonCode("soloProposal")}
          text="Solo Proposal Request"
          canRequest={canRequest("solo") && !loading}
        />
      )}
      {canRequest("lightreach") && (
        <MenuItem
          handleClick={() => handleOpenLeadDrawer("lightreach")}
          reason={determineReasonCode("lightreachProposal")}
          text="LightReach Proposal Request"
          canRequest={canRequest("lightreach") && !loading}
        />
      )}
      {SUNNOVA_CERTIFIED && canRequest("sunnova") && (
        <MenuItem
          handleClick={() => handleOpenRequestDrawer("sunnova", latestSunnovaProposal?.id)}
          reason={determineReasonCode("sunnova")}
          canRequest={canRequest("sunnova") && !loading}
          text={hasSunnovaProposals ? "Sunnova Proposal Revision" : "Sunnova Proposal Request"}
        />
      )}
      {AURORA_SUBMIT && canRequest("aurora") && (
        <MenuItem
          handleClick={() => handleOpenRequestDrawer("aurora", latestAuroraProposal?.id)}
          reason={determineReasonCode("aurora")}
          canRequest={!loading}
          text={hasAuroraProposals ? "Aurora Proposal Revision" : "Aurora Proposal Request"}
        />
      )}
      <MenuItem
        handleClick={() => {
          handleRoofingClick(requestOrRevision);
        }}
        reason={determineReasonCode("roofing")}
        canRequest={canRequest("roofing")}
        text={hasRoofingProposals ? "Roofing Proposal Revision" : "Roofing Proposal Request"}
      />
      {userCanRequestTpoPricing && (
        <MenuItem
          reason={determineReasonCode("visionTpoPricing")}
          handleClick={launchVisionLite}
          canRequest={!loading}
          text="Launch Vision Lite"
        />
      )}
      {details.props.enableInitiateChangeOrder && (
        <MenuItem
          canRequest={canRequest("change_order")}
          reason={determineReasonCode("change_order")}
          text="Initiate Change Order"
          handleClick={() => {
            setConfirmCOModalOpen(true);
          }}
        />
      )}
      {!details?.props?.closeAt && !details?.props?.contractAt && (
        <MenuItem
          handleClick={handleClose}
          reason={reason}
          canRequest={!loading}
          text="Close Project"
          danger
          statusAction
        />
      )}
      {reopenOption()}
    </Menu>
  );

  useEffectOnce(() => {
    dispatch(actions.getProfile());
    return () => dispatch(actions.clearLatestMessage());
  });

  return (
    <>
      <div className={styles.dropdownContainer}>
        <Dropdown disabled={!isValidServiceArea} dropdownRender={() => menu}>
          <Button
            type="primary"
            className={styles.dropdownButton}
            loading={loading}
            data-test-id="project-actions-button"
          >
            Project Actions
            <DownCaret fill="var(--white)" />
          </Button>
        </Dropdown>
      </div>
      <CloseProjectModal
        providerLead={details}
        visible={closeProjectVisible}
        setVisible={setCloseProjectVisible}
        closeReasons={closeReasons}
      />
      <AlertModal title={modalTitle} visible={visible} onClose={close} defaultIcon>
        <div className={styles.alertModalContent}>{modalContent}</div>
      </AlertModal>
      <SellerChangeOrderConfirm
        open={confirmCOModalOpen}
        handleCancel={() => setConfirmCOModalOpen(false)}
        setConfirmCOModalOpen={setConfirmCOModalOpen}
      />
    </>
  );
};

ActionsDropdown.propTypes = {
  adminProjectDetails: Project.types(),
  projectDetails: PropTypes.shape({
    lead: Lead.types(),
    providerLead: ProviderLead.types(),
  }),
  latestMessage: MessageEvent.types(),
  roofingProposals: PropTypes.arrayOf(PropTypes.shape({})),
  fundingProposals: PropTypes.arrayOf(PropTypes.shape({})),
};

export default connect(
  getRdxSelectionMapper({
    projectDetails: "getProjectDetails",
    adminProjectDetails: "getAdminProjectDetails",
    latestMessage: "getLatestMessageEvt",
    activeRequests: "getActiveRequests",
    roofingProposals: "getRoofingProposals",
    fundingProposals: "selectFundingProposals",
  }),
  getRdxActionMapper([]),
)(ActionsDropdown);
