import React from "react";
import Grid from "@mui/material/Grid";

import { SmartFormRadioGroup } from "components/mui-styled-components/SmartFormElements";
import { PrimaryButton } from "components/mui-styled-components";
import SubHeader from "components/PlatformLayout/components/SubHeader";
import { cvar } from "styles/colorVariables";
import NevadaProjectNote from "../NevadaProjectNote";
import CloserBanner from "../CloserBanner";
import { radioButtonContent } from "./powurPairFormData";
import * as S from "./styles";

type PowurPairFormPropsT = {
  onSubmit: (e?: React.BaseSyntheticEvent<object> | undefined) => Promise<void>;
  previousResults: () => void;
  powurPairs: boolean;
  powurPairSubmitRef?: React.RefObject<HTMLButtonElement>;
  hideActionButtons?: boolean;
  rsmRequirementsPassed?: boolean;
};

const PowurPairForm = ({
  onSubmit,
  powurPairs,
  previousResults,
  powurPairSubmitRef,
  hideActionButtons = false,
  rsmRequirementsPassed = true,
}: PowurPairFormPropsT) => {
  return (
    <>
      {!rsmRequirementsPassed && <NevadaProjectNote />}
      <CloserBanner bannerText="Find Your Closer" />
      <SubHeader title="What's Important to You?" />
      <S.Text item xs={12} sm={6.5}>
        Below are a few questions to understand what your needs are when looking for a Closer for your project. This
        will help us find your best Powur pair.
      </S.Text>
      <S.FormCardContainer>
        {radioButtonContent.map((content) => (
          <S.FormCard container justifyContent="space-between" key={content.question}>
            <Grid item xs={12} sm={4}>
              <S.FormQuestion>{content.question}</S.FormQuestion>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SmartFormRadioGroup
                name={content.name}
                radioOptions={content.answersLong}
                radioLabelStyle={{
                  display: "flex",
                  flexDirection: "column",
                  fontFamily: "Poppins",
                  color: cvar("black"),
                  marginLeft: "8px",
                }}
                radioBoxPadding={1}
              />
            </Grid>
          </S.FormCard>
        ))}
      </S.FormCardContainer>
      {hideActionButtons ? (
        <button ref={powurPairSubmitRef} style={{ display: "none" }} type="button" onClick={onSubmit} />
      ) : (
        <S.FormButtons>
          {!!powurPairs && (
            <PrimaryButton
              onClick={previousResults}
              gradientOutlined
              style={{ width: "210px", height: "48px", fontSize: "18px", marginRight: "30px" }}
            >
              Previous Results
            </PrimaryButton>
          )}
          <PrimaryButton
            gradient
            onClick={onSubmit}
            style={{ width: "356px", height: "48px", fontSize: "20px", fontFamily: "Poppins" }}
          >
            Pair Me
          </PrimaryButton>
        </S.FormButtons>
      )}
    </>
  );
};

export default PowurPairForm;
