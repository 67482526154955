export default {
  getEnterpriseOrg: (state) => state.enterpriseOrg.org,
  getEnterpriseOrgSeat: (state) => state.enterpriseOrg.seat,
  getEnterpriseOrgSeats: (state) => state.enterpriseOrg.seats,
  selectEnterprisesList: (state) => state.enterpriseOrg.enterprisesList,
  selectSelectedVolumeTiers: (state) => state.enterpriseOrg.selectedVolumeTiers,
  selectEnterpriseCobranding: (state) => state.enterpriseCobranding.cobrandingDetails,
  selectEnterpriseCustomFields: (state) => state.enterpriseCustomFields.customFields,
  selectEnterpriseAssets: (state) => state.enterpriseAssets.assets,
};
