import React from "react";

interface TrashCanFileI {
  width?: string | number;
  height?: string | number;
}

const TrashCanFile = ({ width = 18, height = 19 }: TrashCanFileI) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3333 4.49996V3.83329C12.3333 2.89987 12.3333 2.43316 12.1517 2.07664C11.9919 1.76304 11.7369 1.50807 11.4233 1.34828C11.0668 1.16663 10.6001 1.16663 9.66667 1.16663H8.33333C7.39991 1.16663 6.9332 1.16663 6.57668 1.34828C6.26308 1.50807 6.00811 1.76304 5.84832 2.07664C5.66667 2.43316 5.66667 2.89987 5.66667 3.83329V4.49996M1.5 4.49996H16.5M14.8333 4.49996V13.8333C14.8333 15.2334 14.8333 15.9335 14.5608 16.4683C14.3212 16.9387 13.9387 17.3211 13.4683 17.5608C12.9335 17.8333 12.2335 17.8333 10.8333 17.8333H7.16667C5.76654 17.8333 5.06647 17.8333 4.53169 17.5608C4.06129 17.3211 3.67883 16.9387 3.43915 16.4683C3.16667 15.9335 3.16667 15.2334 3.16667 13.8333V4.49996"
        stroke="#8499B7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashCanFile;
