import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  periodsData: {
    payPeriods: [],
    paging: {},
    lineItems: [],
    weeks: {},
  },
  paymentsData: {
    payments: [],
    paging: {},
  },
  projectsCompensation: {
    projects: [],
    paging: {},
    weeks: {},
  },
  projectRevenueSplit: {},
  ledgerData: {
    payments: [],
    paging: {},
    weeks: {},
    searchedUser: {},
    searchedProviderLead: {},
    sum: null,
  },
  distributionsData: {
    distributions: [],
    paging: {},
    weeks: {},
  },
  ledgerCsvData: {},
  userOrLeadAutoComplete: [],
};

export default {
  compensation: createReducer(initialState, {
    [types.SET_PERIODS](state, action) {
      return {
        ...state,
        periodsData: action.payload,
      };
    },
    [types.SET_PAYMENTS](state, action) {
      return {
        ...state,
        paymentsData: action.payload,
      };
    },
    [types.SET_LEDGER](state, action) {
      return {
        ...state,
        ledgerData: action.payload,
      };
    },
    [types.SET_LEDGER_CSV](state, action) {
      return {
        ...state,
        ledgerCsvData: action.payload,
      };
    },
    [types.SET_USER_OR_LEAD_AUTO_COMPLETE](state, action) {
      return {
        ...state,
        userOrLeadAutoComplete: action.payload,
      };
    },

    [types.SET_DISTRIBUTIONS](state, action) {
      return {
        ...state,
        distributionsData: action.payload,
      };
    },

    [types.SET_PROJECTS_COMPENSATION_LIST](state, action) {
      return {
        ...state,
        projectsCompensation: action.payload,
      };
    },

    [types.SET_PROJECT_REVENUE_SPLIT](state, action) {
      return {
        ...state,
        projectRevenueSplit: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
