import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const ArchiveIcon = (props) => {
  const { fill, height, width, containerStyles, active, hovered } = props;

  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      style={{ paddingBottom: "1px" }}
      {...containerStyles}
    >
      <svg width={width} height={height} viewBox="0 0 16 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3.94336" y="3.98047" width="8.1757" height="3.38913" fill={fill} />
        <path
          d="M14.8494 24.1501H1.15039V5.89648H14.8494V24.1501ZM2.05187 23.2486H13.9482V6.79769H2.05187V23.2486Z"
          fill={fill}
        />
        <path
          d="M12.7322 6.79811H3.26758V3.53027H12.7322V6.79811ZM4.16851 5.89691H11.8307V4.43176H4.16851V5.89691Z"
          fill={fill}
        />
        <path d="M8.45059 0.600586H7.54883V3.98094H8.45059V0.600586Z" fill={fill} />
        <path d="M16 23.249H0V24.1508H16V23.249Z" fill={fill} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.60156 6.51758H14.3647V23.6999H1.60156V6.51758ZM10.7611 23.249H5.24001V17.5586H10.7611V23.249ZM6.14149 22.3476H9.85966V20.7733H6.14149V22.3476ZM6.14149 19.8721V18.4604H9.85966V19.8721H6.14149ZM4.72624 8.2666H3.16016V9.16836H4.72624V8.2666ZM5.86523 8.2666H7.43159V9.16836H5.86523V8.2666ZM10.1344 8.2666H8.56836V9.16836H10.1344V8.2666ZM11.2715 8.2666H12.8376V9.16836H11.2715V8.2666ZM4.72624 10.0693H3.16016V10.9708H4.72624V10.0693ZM5.86523 10.0693H7.43159V10.9708H5.86523V10.0693ZM10.1344 10.0693H8.56836V10.9708H10.1344V10.0693ZM11.2715 10.0693H12.8376V10.9708H11.2715V10.0693ZM4.72624 11.8721H3.16016V12.7733H4.72624V11.8721ZM5.86523 11.8721H7.43159V12.7733H5.86523V11.8721ZM10.1344 11.8721H8.56836V12.7733H10.1344V11.8721ZM11.2715 11.8721H12.8376V12.7733H11.2715V11.8721ZM4.72624 13.6738H3.16016V14.5756H4.72624V13.6738ZM5.86523 13.6738H7.43159V14.5756H5.86523V13.6738ZM10.1344 13.6738H8.56836V14.5756H10.1344V13.6738ZM11.2715 13.6738H12.8376V14.5756H11.2715V13.6738ZM4.72624 15.4775H3.16016V16.379H4.72624V15.4775ZM5.86523 15.4775H7.43159V16.379H5.86523V15.4775ZM10.1344 15.4775H8.56836V16.379H10.1344V15.4775ZM11.2715 15.4775H12.8376V16.379H11.2715V15.4775Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

ArchiveIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

ArchiveIcon.defaultProps = {
  // accentColor: "white",
  fill: "white",
  height: "24",
  width: "16",
};

export default ArchiveIcon;
