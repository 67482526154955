// import content from "containers/Sales/projects";
import tabs from "containers/Sales/NewProjects/tabs";
import { ProjectsIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import breadCrumbs from "components/PlatformLayout/components/PlatformPageHeader/Breadcrumbs/ProjectBreadCrumbs";
import l from "@loadable/component";

const content = l(() => import("containers/Sales/NewProjects"));

const { PROJECTS_VIEW, PREVIEW_USER } = permissionTypes;
export default {
  key: "projects",
  title: breadCrumbs,
  path: "/projects/:tab?",
  exact: true,
  basePath: "projects",
  requiredParams: ["tab"],
  optionalParmas: ["leadId"],
  linkTarget: "/projects",
  linkTitle: "Projects",
  linkCategory: "Sales",
  icon: ProjectsIcon,
  tabs,
  content,
  permission: [PROJECTS_VIEW, PREVIEW_USER],
};
