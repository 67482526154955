import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import useWindowResize from "hooks/useWindowResize";
import ReactPlayer from "react-player";
import styles from "./VideoModal.module.less";

const VideoModal = (props) => {
  const { visible, close, title, videoSourceUrl, modalProps, lightMode } = props;

  const handleClose = () => {
    close();
  };

  const { width, height } = useWindowResize();

  const [videoSize, setVideoSize] = useState({
    width: "0px",
    height: "0px",
  });
  const [aspectRatio, setAspectRatio] = useState(16 / 9);

  const handleSetVideoDimensions = (e) => {
    setAspectRatio(e.target.videoWidth / e.target.videoHeight);
  };

  useEffect(() => {
    let videoWidth = width * 0.85;
    let videoHeight = Math.round(videoWidth / aspectRatio);
    // correct sizing if videoHeight above + modal header heights
    // are larger than viewport height
    if (height * 0.95 - videoHeight - 64 < 0) {
      videoHeight = height * 0.95 - 64;
      videoWidth = videoHeight * aspectRatio;
    }
    setVideoSize({ width: `${videoWidth}px`, height: `${videoHeight}px` });
  }, [width, height, aspectRatio]);

  return (
    <Modal
      open={visible}
      footer={null}
      mask
      maskClosable
      centered
      keyboard
      destroyOnClose
      onCancel={handleClose}
      onClose={handleClose}
      wrapClassName={lightMode ? styles.wrapperLight : styles.wrapper}
      width={videoSize.width}
      {...modalProps}
    >
      <div className={styles.modalContainer}>
        <div className={lightMode ? styles.headerLight : styles.header}>{title}</div>
        <div className={styles.body}>
          <div style={videoSize} className={styles.videoContainer}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <ReactPlayer
              className={styles.video}
              url={videoSourceUrl}
              width={videoSize.width}
              height={videoSize.height}
              controls
              playing
              onBuffer={handleSetVideoDimensions}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

VideoModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string,
  videoSourceUrl: PropTypes.string.isRequired,
  // pass thru props to antd modal component
  modalProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  lightMode: PropTypes.bool,
};

VideoModal.defaultProps = {
  title: "",
  modalProps: {},
  lightMode: false,
};

export default VideoModal;
