import { camelizeKeys, keysToSnakeCase } from "lib/helpers";
import { api } from "rdx/services/api";
import SchemaValidator from "util/schema-helpers/SchemaValidator";
import {
  GetProjectBlockerQueryParamsT,
  GetProjectBlockerResponseT,
  GetProjectBlockersQueryParamsT,
  GetProjectBlockersResponseT,
  CreateMessageMutationParamsT,
  CreateAssetMutationParamsT,
} from "./types";
import { GetProjectBlockerResponseSchema, GetProjectBlockersResponseSchema } from "./schemas";
import { PROJECT_BLOCKER_TAGS } from "./tags";

export const projectBlockersApi = api
  .enhanceEndpoints({ addTagTypes: [...Object.values(PROJECT_BLOCKER_TAGS)] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getProjectBlockers: builder.query<GetProjectBlockersResponseT["body"], GetProjectBlockersQueryParamsT>({
        query: ({ projectId, isEnterprise = false }) => ({
          url: `projects/${projectId}/blockers`,
          params: { enterprise: isEnterprise },
        }),
        transformResponse: (res: GetProjectBlockersResponseT) => {
          SchemaValidator.validate<typeof GetProjectBlockersResponseSchema>({
            schema: GetProjectBlockersResponseSchema,
            schemaName: "GetProjectBlockersResponseSchema",
            dto: camelizeKeys(res),
          });
          return camelizeKeys(res.body);
        },
        providesTags: [PROJECT_BLOCKER_TAGS.PROJECT_BLOCKERS],
      }),
      getProjectBlocker: builder.query<GetProjectBlockerResponseT["body"], GetProjectBlockerQueryParamsT>({
        query: ({ projectId, blockerId }) => `projects/${projectId}/blockers/${blockerId}`,
        transformResponse: (res: GetProjectBlockerResponseT) => {
          SchemaValidator.validate<typeof GetProjectBlockerResponseSchema>({
            schema: GetProjectBlockerResponseSchema,
            schemaName: "GetProjectBlockerResponseSchema",
            dto: camelizeKeys(res),
          });
          return camelizeKeys(res.body);
        },
        providesTags: [PROJECT_BLOCKER_TAGS.PROJECT_BLOCKER],
      }),
      createProjectBlockerMessage: builder.mutation<GetProjectBlockerResponseT["body"], CreateMessageMutationParamsT>({
        query: ({ blockerId, projectId, params }) => ({
          url: `projects/${projectId}/blockers/${blockerId}/message`,
          method: "POST",
          body: keysToSnakeCase(params),
        }),
        transformResponse: (res: GetProjectBlockerResponseT) => {
          SchemaValidator.validate<typeof GetProjectBlockerResponseSchema>({
            schema: GetProjectBlockerResponseSchema,
            schemaName: "GetProjectBlockerResponseSchema",
            dto: camelizeKeys(res),
          });
          return camelizeKeys(res.body);
        },
        onQueryStarted: ({ projectId, blockerId }, { dispatch, queryFulfilled }) => {
          queryFulfilled.then(({ data }) => {
            dispatch(projectBlockersApi.util.upsertQueryData("getProjectBlocker", { projectId, blockerId }, data));
          });
        },
      }),
      createProjectBlockerAsset: builder.mutation<GetProjectBlockerResponseT["body"], CreateAssetMutationParamsT>({
        query: ({ projectId, params }) => ({
          url: `provider_leads/${projectId}/assets/blocker`,
          method: "POST",
          body: keysToSnakeCase(params),
        }),
        transformResponse: (res: GetProjectBlockerResponseT) => {
          SchemaValidator.validate<typeof GetProjectBlockerResponseSchema>({
            schema: GetProjectBlockerResponseSchema,
            schemaName: "GetProjectBlockerResponseSchema",
            dto: camelizeKeys(res),
          });
          return camelizeKeys(res.body);
        },
        onQueryStarted: ({ projectId, params: { blockerId } }, { dispatch, queryFulfilled }) => {
          queryFulfilled.then(({ data }) => {
            dispatch(projectBlockersApi.util.upsertQueryData("getProjectBlocker", { projectId, blockerId }, data));
          });
        },
      }),
    }),
  });

export const {
  useGetProjectBlockersQuery,
  useGetProjectBlockerQuery,
  useCreateProjectBlockerMessageMutation,
  useCreateProjectBlockerAssetMutation,
  usePrefetch: usePrefetchProjectBlockers,
} = projectBlockersApi;
