import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

export const TimeStampLabelWrapper = styled("span")(() => ({
  display: "block",
  fontSize: "10px",
  color: "var(--nepal-blue)",
  fontWeight: "700",
  textTransform: "uppercase",
  textAlign: "center",
  paddingBottom: "14px",
}));

export const TimeStampWrapper = styled("span")(() => ({
  display: "block",
  fontSize: "14px",
  color: "var(--luxe-blue)",
  fontWeight: "500",
  textAlign: "center",
}));

export const StyledFooterWrapper = styled("div")(() => ({
  height: "60px",
  width: "100%",
  background: "var(--ghost-white)",
}));

export const StyledGradientButtonBackground = styled("div")(() => ({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: "1px",
  left: "1px",
  background: "white",
  width: "calc(100% - 2px)",
  height: "calc(100% - 2px)",
  borderRadius: "6px",
  color: "#20C2F1",
}));

const formHeaderStyles = css`
  color: var(--blue-ribbon);
  font-family: "Poppins";
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  margin-left: -4px;
  margin-bottom: 4px;
`;

export const StyledHeader = styled(FormLabel)`
  ${formHeaderStyles};
`;

export const StyledSubHeader = styled("p")(() => ({
  color: "#8499B7",
  fontFamily: "Barlow",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "20px",
  marginBottom: "5px",
}));

export const StyledDragDropLabel = styled("p")(() => ({
  color: "var(--darkest-blue)",
  fontFamily: "Barlow",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "17px",
}));

export const FormValueText = styled("p")(() => ({
  color: "var(--queen-blue)",
  fontfamily: "Barlow",
  fontsize: "16px",
  fontstyle: "normal",
  fontweight: "600",
  lineheight: "17px",
  marginLeft: "-4px",
  marginTop: "8px",
}));
