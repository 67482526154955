import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToSnakeCase } from "lib/helpers";
import messages from "../messages";

function* updateRegistration(action) {
  const { slug } = action.payload;
  const params = { ...keysToSnakeCase(action.payload) };

  const { success, data, error } = yield* makeRequest.patch(`/registrations/${slug}`, params);
  if (success && data) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.REGISTRATION_UPDATE_SUCCESS,
        }),
      ),
      put(actions.getRegistration({ code: slug })),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_REGISTRATION,
    });
  }
  return null;
}

export default updateRegistration;
