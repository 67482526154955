import React from "react";
import { theme } from "styles/themes";

type KanbanIconPropsT = {
  width?: string | number;
  height?: string | number;
  fill?: string;
  backgroundFill?: string;
};

const KanbanIcon = ({
  width = "48",
  height = "24",
  fill = theme.colors["graham-grey"],
  backgroundFill = "transparent",
}: KanbanIconPropsT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} rx="4" fill={backgroundFill} />
      <rect x="17" y="8.93164" width="2" height="3" rx="1" transform="rotate(90 17 8.93164)" fill={fill} />
      <rect x="21" y="8.93164" width="2" height="3" rx="1" transform="rotate(90 21 8.93164)" fill={fill} />
      <rect x="21" y="13" width="2" height="3" rx="1" transform="rotate(90 21 13)" fill={fill} />
      <rect x="25" y="8.93164" width="2" height="3" rx="1" transform="rotate(90 25 8.93164)" fill={fill} />
      <rect x="33" y="8.93164" width="2" height="3" rx="1" transform="rotate(90 33 8.93164)" fill={fill} />
      <rect x="29" y="8.93164" width="2" height="3" rx="1" transform="rotate(90 29 8.93164)" fill={fill} />
      <rect x="17" y="4.79492" width="2" height="3" rx="1" transform="rotate(90 17 4.79492)" fill={fill} />
      <rect x="21" y="4.79492" width="2" height="3" rx="1" transform="rotate(90 21 4.79492)" fill={fill} />
      <rect x="25" y="4.79492" width="2" height="3" rx="1" transform="rotate(90 25 4.79492)" fill={fill} />
      <rect x="33" y="4.79492" width="2" height="3" rx="1" transform="rotate(90 33 4.79492)" fill={fill} />
      <rect x="29" y="4.79492" width="2" height="3" rx="1" transform="rotate(90 29 4.79492)" fill={fill} />
      <rect x="17" y="13.0684" width="2" height="3" rx="1" transform="rotate(90 17 13.0684)" fill={fill} />
      <rect x="25" y="13.0684" width="2" height="3" rx="1" transform="rotate(90 25 13.0684)" fill={fill} />
      <rect x="33" y="13.0684" width="2" height="3" rx="1" transform="rotate(90 33 13.0684)" fill={fill} />
      <rect x="17" y="17.2051" width="2" height="3" rx="1" transform="rotate(90 17 17.2051)" fill={fill} />
    </svg>
  );
};

export default KanbanIcon;
