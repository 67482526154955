import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* acceptedLatestTermsOfService() {
  const { success, data, error } = yield* makeRequest.post("/application_agreement", {});

  if (success && data) {
    yield put(
      actions.newSuccessEvent({
        message: messages.ACCEPTED_LATEST_TOS_SUCCESS,
        payload: {
          event: messages.ACCEPTED_LATEST_TOS_SUCCESS_EVENT,
        },
      }),
    );
  } else {
    return getErrorActions({
      error,
      message: messages.ACCEPTED_LATEST_TOS_ERROR_EVENT,
    });
  }
  return null;
}

export default acceptedLatestTermsOfService;
