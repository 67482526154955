import { put, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/modules/adminProjects/actions";
import { camelizeKeys } from "lib/helpers";
import selectors from "rdx/selectors";
import { cloneDeep } from "lodash";
import messages from "../messages";

function* getAdminProjectsList(action) {
  const { requestFreshData = true } = action.payload || {};
  const existingList = yield select(selectors.getAdminProjectsList);

  if (requestFreshData) {
    yield put(actions.resetAdminProjectsList());
  }

  const params = {
    // breaks updated api endpoint
    // sort: "submit",
    ...action.payload,
    limit: 20,
  };

  const options = {
    signal: params.signal,
  };

  if (!requestFreshData) {
    params.p = existingList.paging.pageNumber + 1 || existingList.paging.page_size + 1;
  }

  const { success, data, error } = yield* makeRequest.get("/projects", params, options);
  if (success && data) {
    // populate projects list (for case when req. did not provide a filters param)
    if (data.body?.projects) {
      const list = cloneDeep(existingList.projects).concat(data.body.projects.map((entry) => camelizeKeys(entry)));
      const paging = camelizeKeys(data.body.paging);
      const projects = requestFreshData ? data.body.projects.map((entry) => camelizeKeys(entry)) : list;
      yield put(actions.setAdminProjectsList({ ...data.body, paging, projects }));
    }

    // populate project filters (for case when req. gave param filters=1)
    if (data.body?.filters) {
      yield put(actions.setAdminProjectsListFilters(data.body));
    }
  } else {
    if (params.signal?.aborted) return null;
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ADMIN_PROJECTS_LIST,
    });
  }
  return null;
}

export default getAdminProjectsList;
