import types from "rdx/modules/dashboardPromotions/types";
import createAction from "rdx/utils/createAction";

export default {
  getDashboardPromotions: (payload) => createAction(types.GET_DASHBOARD_PROMOTIONS, payload),

  setDashboardPromotions: (payload) => createAction(types.SET_DASHBOARD_PROMOTIONS, payload),

  getDashboardCarouselPromotions: (payload) => createAction(types.GET_DASHBOARD_CAROUSEL_PROMOTIONS, payload),

  setDashboardCarouselPromotions: (payload) => createAction(types.SET_DASHBOARD_CAROUSEL_PROMOTIONS, payload),

  createEventPromo: (payload) => createAction(types.CREATE_DASHBOARD_PROMOTION, payload),

  getSelectedDashboardPromotion: (payload) => createAction(types.GET_SELECTED_DASHBOARD_PROMOTION, payload),

  setSelectedDashboardPromotion: (payload) => createAction(types.SET_SELECTED_DASHBOARD_PROMOTION, payload),

  updateDashboardPromotion: (payload) => createAction(types.UPDATE_SELECTED_DASHBOARD_PROMOTION, payload),

  deleteDashboardPromotion: (payload) => createAction(types.DELETE_SELECTED_DASHBOARD_PROMOTION, payload),

  // INSERTION_PT (for script, do not remove)
};
