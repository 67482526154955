export default {
  GET_DASHBOARD_SLICE: "[consultantDashboard] GET Dashboard Slice",
  GET_PROGRESS_OVER_TIME: "[consultantDashboard] GET Progress Over Time",
  GET_TEAM_ACTIVITY: "[consultantDashboard] GET Team Activity",
  GET_LEAD_ORIGIN: "[consultantDashboard] GET Lead Origin",
  GET_EARNINGS_BREAKDOWN: "[consultantDashboard] GET Earnings Breakdown",
  GET_CONSULTANT_DASHBOARD: "[consultantDashboard] GET Seller Dashboard",
  SET_CONSULTANT_DASHBOARD: "[consultantDashboard] SET Seller Dashboard",
  GET_SALES_LEADERBOARD: "[consultantDashboard] GET Sales Leaderboard",
  GET_REFERRALS_LEADERBOARD: "[consultantDashboard] GET Referrals Leaderboard",
  GET_MENTORS_LEADERBOARD: "[consultantDashboard] GET Mentors Leaderboard",
  GET_DASHBOARD_PROJECT_ACTIVITY: "[consultantDashboard] GET Dashboard Project Activity",
  GET_MORE_DASHBOARD_PROJECT_ACTIVITY: "[consultantDashboard] GET More Dashboard Project Activity",
  SET_MORE_DASHBOARD_PROJECT_ACTIVITY: "[consultantDashboard] SET More Dashboard Project Activity",

  CLEAR_CONSULTANT_DASHBOARD: "[consultantDashboard] Clear Seller Dashboard",
  // INSERTION_PT (for script, do not remove)
};
