import { put, select, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import _ from "lodash";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/workflows/selectors";
import messages from "../../messages";

function* deleteWorkflow(action) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/workflows/${id}`, {});

  if (success && data) {
    const workflows = yield select(selectors.getWorkflows);
    const newWorkflows = _.clone(workflows);
    newWorkflows.splice(workflows.indexOf(workflows.find((flow) => flow.id === id)), 1);
    yield all([put(actions.setWorkflows(newWorkflows)), put(actions.setWorkflowDetails({}))]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_WORKFLOW,
    });
  }
  return null;
}

export default deleteWorkflow;
