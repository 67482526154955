import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getUsersProjectsAutoComplete(action) {
  const params = {
    q: action.payload.search,
  };

  const { success, data, error } = yield* makeRequest.get("/projects/autocomplete", params);
  if (success && data) {
    const projects = camelizeKeys(data.body.projects);
    const users = camelizeKeys(data.body.users);
    yield put(actions.setUsersProjectsAutoComplete({ projects, users }));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_USERS_PROJECTS_AUTOCOMPLETE,
    });
  }
  return null;
}

export default getUsersProjectsAutoComplete;
