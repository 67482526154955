export default {
  POST_SALES_TEAMS: "[salesTeams] POST Create Sales Team",
  POST_TEAM_ASSIGNMENT: "[salesTeams] POST Create Team Assignment",

  GET_TEAMS: "[salesTeams] GET Teams",
  SET_TEAMS: "[salesTeams] SET Teams",

  GET_AVAILABLE_MANAGERS: "[salesTeams] GET Available Managers",
  SET_AVAILABLE_MANAGERS: "[salesTeams] SET Available Managers",

  GET_SELLERS: "[salesTeams] GET Available Sellers",
  SET_SELLERS: "[salesTeams] SET Available Sellers",

  GET_AVAILABLE_SUPPORT: "[salesTeams] GET Available Support",
  SET_AVAILABLE_SUPPORT: "[salesTeams] SET Available Support",

  UPDATE_SALES_TEAM: "[salesTeams] UPDATE Sales Team",
  DELETE_SALES_TEAM: "[salesTeams] DELETE Sales Team",

  UNASSIGN_USER: "[salesTeams] UNASSIGN User",

  // INSERTION_PT (for script, do not remove)
};
