import styled from "@emotion/styled";
import FormHelperText from "@mui/material/FormHelperText";
import { filterStyledProps } from "lib/helpers/filterStyledProps";
import DownCaretFilled from "components/Icons/Chevrons/DownCaretFilled";
import Select from "@mui/material/Select";
import { theme } from "styles/themes";

export const LabelGroupWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
}));

export const IconContainer = styled("div")(() => ({
  position: "absolute",
  right: "10px",
  paddingLeft: "10px",
  borderLeft: `1px solid ${theme.colors["dark-blue"]}`,
  pointerEvents: "none",
}));

export const PrefixContainer = styled("div")(() => ({
  borderRight: `1px solid ${theme.colors["botticelli-blue"]}`,
  paddingRight: "7px",
  height: "32px",
  width: "25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledDownCaretFilled = styled(DownCaretFilled)(() => ({
  position: "relative",
  bottom: "3px",
}));

export const Placeholder = styled("span")(() => ({
  color: theme.colors["graham-grey"],
  fontFamily: "Barlow",
  fontSize: "14px",
  fontWeight: 500,
}));

export const StyledHelperText = styled(FormHelperText)<{ error?: boolean }>(() => ({
  lineHeight: "10px",
  marginTop: "-10px",
  color: `${({ error }) => (error ? "red" : "black")}`,
}));

export const StyledFormLabel = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "9px",
  marginBottom: "8px",
  color: theme.colors["nepal-blue"],
  textTransform: "uppercase",
}));

export const StyledRequiredLabel = styled("p")(() => ({
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "9px",
  color: theme.colors["commission-clawback"],
  width: "10px",
  margin: 0,
  textAlign: "center",
}));

export const StyledDropdown = styled(Select, {
  shouldForwardProp: filterStyledProps(["redHighlight"]),
})(({ redHighlight }: { redHighlight: boolean }) => ({
  marginBottom: "12px",
  color: `${theme.colors["dark-blue"]} !important`,
  fontSize: "14px !important",
  fontWeight: "500 !important",
  cursor: "pointer",
  "&:not(.Mui-disabled)": {
    background: `${redHighlight ? theme.colors.bridesmaid : theme.colors.white} !important`,
  },
  "& .Mui-disabled": {
    background: `${theme.colors["cotton-boll"]} !important`,
  },
  "& .MuiSelect-select": {
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingRight: "40px",
  },
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { borderColor: redHighlight ? theme.colors.danger : theme.colors["botticelli-blue"] },
    "&.Mui-focused fieldset": {
      borderColor: redHighlight ? theme.colors.danger : theme.colors["botticelli-blue"],
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `${redHighlight ? theme.colors.danger : theme.colors["botticelli-blue"]} !important`,
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: theme.colors["queen-blue"],
  },
}));
