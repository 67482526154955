import React from "react";

type MagnifyingGlassPropsT = {
  width?: string | number;
  height?: string | number;
  fill?: string;
};

const MagnifyingGlassIcon = ({ width = "13", height = "13", fill = "var(--dark-blue)" }: MagnifyingGlassPropsT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2276 11.6375L8.33214 7.74197C8.93663 6.96047 9.26363 6.00498 9.26363 4.99998C9.26363 3.79699 8.79413 2.66899 7.94514 1.81849C7.09614 0.967998 5.96515 0.5 4.76365 0.5C3.56216 0.5 2.43116 0.969498 1.58217 1.81849C0.73167 2.66749 0.263672 3.79699 0.263672 4.99998C0.263672 6.20148 0.73317 7.33247 1.58217 8.18147C2.43116 9.03196 3.56066 9.49996 4.76365 9.49996C5.76865 9.49996 6.72264 9.17296 7.50414 8.56996L11.3996 12.4639C11.411 12.4754 11.4246 12.4844 11.4395 12.4906C11.4545 12.4968 11.4705 12.5 11.4866 12.5C11.5028 12.5 11.5188 12.4968 11.5337 12.4906C11.5486 12.4844 11.5622 12.4754 11.5736 12.4639L12.2276 11.8115C12.239 11.8 12.2481 11.7865 12.2543 11.7715C12.2605 11.7566 12.2637 11.7406 12.2637 11.7245C12.2637 11.7083 12.2605 11.6923 12.2543 11.6774C12.2481 11.6624 12.239 11.6489 12.2276 11.6375ZM7.13964 7.37597C6.50364 8.01047 5.66065 8.35997 4.76365 8.35997C3.86666 8.35997 3.02366 8.01047 2.38766 7.37597C1.75317 6.73997 1.40367 5.89698 1.40367 4.99998C1.40367 4.10298 1.75317 3.25849 2.38766 2.62399C3.02366 1.98949 3.86666 1.63999 4.76365 1.63999C5.66065 1.63999 6.50515 1.98799 7.13964 2.62399C7.77414 3.25999 8.12364 4.10298 8.12364 4.99998C8.12364 5.89698 7.77414 6.74147 7.13964 7.37597Z"
        fill={fill}
      />
    </svg>
  );
};

export default MagnifyingGlassIcon;
