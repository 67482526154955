export default {
  BATCH_ACTIONS: "[app] Batch Actions",
  FETCH_AUTOCOMPLETE_SUGGESTIONS: "[app] Fetch Autocomplete Suggestions",
  TOGGLE_PROJECT_PEOPLE_DRAWER: "[app] Toggle Project People Drawer",
  GET_ZIP_LOOKUP_ADDRESS: "[app] GET Zip Lookup Address",
  SET_ZIP_LOOKUP_ADDRESS: "[app] SET Zip Lookup Address",
  GET_PAGE_REFERRER: "[app] GET page referrer",
  SET_PAGE_REFERRER: "[app] SET page referrer",
  SET_DISPLAY_ZENDESK_WIDGET: "[app] SET display zendesk widget",

  GET_RANDOM_ADDRESS: "[app] GET Random Address",
  SET_RANDOM_ADDRESS: "[app] SET Random Address",
  SET_SHOW_DEFAULT_M1_OPTION_MODAL: "[app] SET show default m1 option modal",
  // INSERTION_PT (for script, do not remove)
};
