import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const PAGING_DEFAULT = {
  page_size: 20,
  item_count: 0,
  page_number: 1,
};

const initialState = {
  pending: {
    list: {
      projects: [],
      paging: PAGING_DEFAULT,
    },
    filters: {
      filters: {},
      sorts: [],
    },
  },
  inProgress: {
    list: {
      projects: [],
      paging: PAGING_DEFAULT,
    },
    filters: {
      filters: {},
      sorts: [],
    },
  },
  completed: {
    list: {
      projects: [],
      paging: PAGING_DEFAULT,
    },
    filters: {
      filters: {},
      sorts: [],
    },
  },
  counts: {
    pending: 0,
    inProgress: 0,
    completed: 0,
  },
  proposalDetails: {},
  proposalsAutoComplete: {},
};

export default {
  adminProposals: createReducer(initialState, {
    [types.SET_PENDING_ADMIN_PROPOSALS_LIST](state, action) {
      return {
        ...state,
        pending: {
          ...state.pending,
          list: {
            ...state.pending.list,
            ...action.payload,
          },
        },
      };
    },
    [types.SET_PENDING_ADMIN_PROPOSALS_FILTERS](state, action) {
      return {
        ...state,
        pending: {
          ...state.pending,
          filters: {
            ...state.pending.filters,
            ...action.payload,
          },
        },
      };
    },

    [types.SET_IN_PROGRESS_ADMIN_PROPOSALS_LIST](state, action) {
      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          list: {
            ...state.inProgress.list,
            ...action.payload,
          },
        },
      };
    },
    [types.SET_IN_PROGRESS_ADMIN_PROPOSALS_FILTERS](state, action) {
      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          filters: {
            ...state.inProgress.filters,
            ...action.payload,
          },
        },
      };
    },

    [types.SET_COMPLETED_ADMIN_PROPOSALS_LIST](state, action) {
      return {
        ...state,
        completed: {
          ...state.completed,
          list: {
            ...state.completed.list,
            ...action.payload,
          },
        },
      };
    },
    [types.SET_COMPLETED_ADMIN_PROPOSALS_FILTERS](state, action) {
      return {
        ...state,
        completed: {
          ...state.completed,
          filters: {
            ...state.completed.filters,
            ...action.payload,
          },
        },
      };
    },

    [types.SET_ADMIN_PROPOSAL_DETAILS](state, action) {
      return {
        ...state,
        proposalDetails: action.payload,
      };
    },
    [types.SET_PROPOSALS_AUTOCOMPLETE_SUGGESTIONS](state, action) {
      const { key, suggestions } = action.payload;
      if (key) {
        return {
          ...state,
          proposalsAutoComplete: {
            ...state.proposalsAutoComplete,
            [key]: suggestions || [],
          },
        };
      }
      return state;
    },

    [types.SET_ADMIN_PROPOSALS_COUNTS](state, action) {
      return {
        ...state,
        counts: {
          ...state.counts,
          ...action.payload,
        },
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
