import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  dashboardPromotions: {
    paging: {
      pageSize: 0,
      pageNumber: 1,
      itemCount: 0,
    },
    promotions: [],
    filters: {},
  },
  selectedDashboardPromotion: {},
};

export default {
  dashboardPromotions: createReducer(initialState, {
    [types.SET_DASHBOARD_PROMOTIONS](state, action) {
      return {
        ...state,
        dashboardPromotions: {
          ...state.dashboardPromotions,
          ...action.payload,
        },
      };
    },
    [types.SET_DASHBOARD_CAROUSEL_PROMOTIONS](state, action) {
      return {
        ...state,
        dashboardPromotions: {
          ...state.dashboardPromotions,
          ...action.payload,
        },
      };
    },

    [types.GET_SELECTED_DASHBOARD_PROMOTION](state, action) {
      return { ...state, selectedDashboardPromotion: action.payload };
    },
    //   INSERTION_PT (for script, do not remove)
  }),
};
