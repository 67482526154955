import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enterprisePersonnel/messages";

function* updateEnterpriseUserRoles(action) {
  const { salesPro, orgMentor, userId } = action.payload;

  const params = {
    sales_pro: salesPro,
    org_mentor: orgMentor,
  };

  const { success, error } = yield* makeRequest.patch(`/users/${userId}/roles/update_enterprise_seller_roles`, params);
  if (success) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_ENTERPRISE_USER_ROLES_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      messages: messages.ERROR_UPDATING_ENTERPRISE_USER_ROLES,
    });
  }

  return null;
}

export default updateEnterpriseUserRoles;
