import React, { useEffect } from "react";

import { UseNavigation } from "hooks/useNavigation";
import useWindowResize from "hooks/useWindowResize";

import LandingPage from "components/Drawers/SelectT3PartnerDrawer/components/LandingPage";
import SearchT3PartnerContainer from "components/Drawers/SelectT3PartnerDrawer/components/SearchT3PartnerContainer";
import PowurPairContainer from "components/Drawers/SelectT3PartnerDrawer/components/PowurPairContainer";
import CloserBanner from "components/Drawers/SelectT3PartnerDrawer/components/CloserBanner";
import NevadaProjectNote from "components/Drawers/SelectT3PartnerDrawer/components/NevadaProjectNote";
import SettingsTuneIcon from "components/Icons/SettingsTuneIcon";
import { cvar } from "styles/colorVariables";
import { T3PartnerT } from "components/Drawers/SelectT3PartnerDrawer/types";
import AssignedT3Partner from "../AssignedT3Partner";

import * as S from "./styles";

type SelectT3PartnerPropsT = {
  powurPairSubmitRef: React.RefObject<HTMLButtonElement>;
  homeownerApptSubmitRef: React.RefObject<HTMLButtonElement>;
  navigate: ReturnType<UseNavigation>["navigate"];
  override: ReturnType<UseNavigation>["override"];
  selectedT3Partner: T3PartnerT | null;
  setSelectedT3Partner: (t3Partner: T3PartnerT | null, isPowurPair: boolean) => void;
  setOpenT3Footer: (bool: boolean) => void;
  isPowurPairView: boolean;
  setIsPowurPairView: (bool: boolean) => void;
  isMentorSearchView: boolean;
  setIsMentorSearchView: (bool: boolean) => void;
  isPowurPairFormView: boolean;
  setIsPowurPairFormView: (bool: boolean) => void;
  resetLandingPage: () => void;
  resetT3UrlParams: (additionalParams?: Record<string, string>) => void;
  rsmRequirementsPassed: boolean;
  currentTab: number;
  setCurrentTab: (tabIdx: number) => void;
  assignedPartner?: T3PartnerT;
  setIsCreateApptDisabled: (disabled: boolean) => void;
  closeDrawer: () => void;
  drawerContentRef: React.RefObject<HTMLDivElement>;
};

const SelectT3Partner = ({
  powurPairSubmitRef,
  homeownerApptSubmitRef,
  navigate,
  override,
  selectedT3Partner,
  setSelectedT3Partner,
  setOpenT3Footer,
  isPowurPairView,
  setIsPowurPairView,
  isMentorSearchView,
  setIsMentorSearchView,
  isPowurPairFormView,
  setIsPowurPairFormView,
  resetLandingPage,
  assignedPartner,
  resetT3UrlParams,
  rsmRequirementsPassed,
  currentTab,
  setCurrentTab,
  setIsCreateApptDisabled,
  closeDrawer,
  drawerContentRef,
}: SelectT3PartnerPropsT) => {
  const { isMobileWidth } = useWindowResize();

  useEffect(() => {
    if (drawerContentRef?.current) {
      drawerContentRef.current.scrollTo(0, 0);
    }
  }, [assignedPartner, isPowurPairView, isMentorSearchView, drawerContentRef]);

  const handleFindMentor = () => {
    setIsMentorSearchView(true);
  };

  const handlePowurPair = () => {
    setIsPowurPairView(true);
  };

  if (assignedPartner) {
    return (
      <AssignedT3Partner
        assignedPartner={assignedPartner}
        rsmRequirementsPassed={rsmRequirementsPassed}
        homeownerApptSubmitRef={homeownerApptSubmitRef}
        setIsCreateApptDisabled={setIsCreateApptDisabled}
        closeDrawer={closeDrawer}
      />
    );
  }
  return (
    <S.SelectT3Container>
      {!isPowurPairView && !isMentorSearchView && (
        <>
          {!isMobileWidth && <S.LabelText>Select a Closer for your project</S.LabelText>}
          <LandingPage
            findMentor={handleFindMentor}
            powurPair={handlePowurPair}
            cardSize={12}
            spacing={3}
            closeMyOwn={() => setCurrentTab(currentTab + 1)}
            rsmRequirementsPassed={rsmRequirementsPassed}
          />
        </>
      )}
      {isPowurPairView && (
        <PowurPairContainer
          powurPairSubmitRef={powurPairSubmitRef}
          selectedT3Partner={selectedT3Partner}
          setSelectedT3Partner={(mentor) => setSelectedT3Partner(mentor, true)}
          setOpenFooter={setOpenT3Footer}
          powurPairFormView={isPowurPairFormView}
          setPowurPairFormView={setIsPowurPairFormView}
          handleChangeSubtitle={resetLandingPage}
          hideActionButtons
          rsmRequirementsPassed={rsmRequirementsPassed}
        />
      )}
      {isMentorSearchView && (
        <>
          {!rsmRequirementsPassed && <NevadaProjectNote />}
          {!isMobileWidth && (
            <CloserBanner
              bannerText="Select Closer"
              icon={
                <S.BannerIconContainer>
                  <SettingsTuneIcon width="23" height="23" mainColor={cvar("blue-ribbon")} />
                </S.BannerIconContainer>
              }
            />
          )}
          <SearchT3PartnerContainer
            resetFields={resetT3UrlParams}
            navigate={navigate}
            override={override}
            setSelectedT3Partner={(mentor) => setSelectedT3Partner(mentor, false)}
            setOpenFooter={setOpenT3Footer}
            rsmRequirementsPassed={rsmRequirementsPassed}
          />
        </>
      )}
    </S.SelectT3Container>
  );
};

export default SelectT3Partner;
