import React from "react";

import type { GridColDef } from "@mui/x-data-grid-pro";
import { T3PartnerTableDataT, T3PartnerT } from "components/Drawers/SelectT3PartnerDrawer/types";
import MobileColContent from "./MobileColContent";

type MobileColPropsT = {
  setSelectedT3Partner: (closer: T3PartnerT) => void;
  setOpenFooter: (bool: boolean) => void;
};

export const MobileCol = ({
  setSelectedT3Partner,
  setOpenFooter,
}: MobileColPropsT): GridColDef<T3PartnerTableDataT> => ({
  field: "closer",
  minWidth: 275,
  maxWidth: 348,
  flex: 2,
  renderCell: ({ row }) => {
    return <MobileColContent setSelectedT3Partner={setSelectedT3Partner} setOpenFooter={setOpenFooter} closer={row} />;
  },
});
