import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";

import DateRangeNavigate from "components/Pickers/DateRange";

const M2sSidebar = (props) => {
  const { navigate } = props;

  return <DateRangeNavigate navigate={navigate} beginKey="start_day" endKey="end_day" />;
};

M2sSidebar.propTypes = {
  navigate: PropTypes.func,
};

export default connect(getRdxSelectionMapper({}), getRdxActionMapper([]))(M2sSidebar);
