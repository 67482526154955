import React from "react";
import PropTypes from "prop-types";

const HeaderLogoLarge = (props) => {
  const { width, height } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 146 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.58461 12.3942V5.70409H12.2748L5.58461 12.3942Z" fill="white" />
      <path d="M5.58461 21.8564L0.853638 17.1254L5.58461 12.3944V21.8564Z" fill="white" />
      <path d="M12.2748 5.70412L17.0057 0.973145L21.7367 5.70412H12.2748Z" fill="white" />
      <path d="M21.7367 28.55L17.0057 33.281L12.2748 28.55H21.7367Z" fill="white" />
      <path d="M17.0057 23.8192V17.1254H23.6996L17.0057 23.8192Z" fill="white" />
      <path d="M17.0057 10.4351V17.1252H10.3156L17.0057 10.4351Z" fill="white" />
      <path d="M12.2748 28.5502H5.58461V21.8563L12.2748 28.5502Z" fill="white" />
      <path d="M28.4306 21.8563V28.5502H21.7367L28.4306 21.8563Z" fill="white" />
      <path d="M28.4306 12.3944L33.1615 17.1254L28.4306 21.8564V12.3944Z" fill="white" />
      <path d="M21.7367 5.70409H28.4306V12.3942L21.7367 5.70409Z" fill="white" />
      <path
        d="M42.0446 45.6334V22.0936H50.449C56.1039 22.0936 58.8534 24.5871 58.8534 29.7151V33.3069C58.8534 37.3774 56.1039 39.3588 50.449 39.3588H45.0464V45.6371H42.0446V45.6334ZM45.0464 36.6687H50.4527C54.0371 36.6687 55.859 35.537 55.859 33.3032V28.1455C55.859 25.9118 54.0408 24.78 50.4527 24.78H45.0464V36.6687Z"
        fill="white"
      />
      <path
        d="M69.781 39.3589C64.1261 39.3589 61.3766 37.3811 61.3766 33.3069V28.1455C61.3766 24.0713 64.1261 22.0936 69.781 22.0936C75.4359 22.0936 78.1855 24.0713 78.1855 28.1455V33.3069C78.1855 37.3774 75.4359 39.3589 69.781 39.3589ZM69.781 24.78C66.1966 24.78 64.3747 25.9118 64.3747 28.1455V33.3069C64.3747 35.5407 66.1929 36.6724 69.781 36.6724C73.3654 36.6724 75.1873 35.5407 75.1873 33.3069V28.1455C75.1836 25.9118 73.3654 24.78 69.781 24.78Z"
        fill="white"
      />
      <path
        d="M99.3208 38.9062L93.9813 24.3533L88.6455 38.9062H85.5027L78.7792 22.0936H81.7476L87.076 35.0175L92.4081 22.0936H95.5583L100.887 35.0175L106.219 22.0936H109.187L102.464 38.9062H99.3208Z"
        fill="white"
      />
      <path
        d="M119.046 39.3589C113.391 39.3589 110.642 37.3811 110.642 33.3069V22.0936H113.644V33.3069C113.644 35.5407 115.462 36.6724 119.05 36.6724C122.634 36.6724 124.456 35.5407 124.456 33.3069V22.0936H127.458V33.3069C127.451 37.3774 124.705 39.3589 119.046 39.3589Z"
        fill="white"
      />
      <path
        d="M130.337 38.9062V22.0936H138.742C144.293 22.0936 147.042 24.0008 147.146 27.9192H144.141C144.018 25.8376 142.208 24.78 138.742 24.78H133.336V38.9062H130.337Z"
        fill="white"
      />
    </svg>
  );
};

HeaderLogoLarge.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

HeaderLogoLarge.defaultProps = {
  width: "146",
  height: "45",
};

export default HeaderLogoLarge;
