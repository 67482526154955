import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const InspectionPassedIcon = ({ height = 18, width = 18, fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2043_13615)">
        <path
          d="M13.2012 9.63281C10.8943 9.63281 9.01758 11.5096 9.01758 13.8164C9.01758 16.1233 10.8943 18 13.2012 18C15.5274 18 17.4199 16.1233 17.4199 13.8164C17.4199 11.5096 15.5274 9.63281 13.2012 9.63281ZM14.4852 13.3103L13.0789 14.9978C12.9781 15.1187 12.8293 15.1875 12.6739 15.1875C12.6536 15.1875 12.6334 15.1863 12.613 15.184C12.4362 15.1635 12.2817 15.0552 12.2021 14.896L11.6396 13.771C11.5094 13.5105 11.615 13.1937 11.8755 13.0635C12.1359 12.9332 12.4527 13.0388 12.583 13.2993L12.7849 13.7031L13.6749 12.6351C13.8614 12.4114 14.1939 12.3812 14.4176 12.5675C14.6414 12.754 14.6717 13.0865 14.4852 13.3103Z"
          fill={fill}
        />
        <path
          d="M12.9739 3.00421L10.8342 0.791508C10.8332 0.790453 10.8321 0.789363 10.8311 0.788309C10.3385 0.287332 9.65296 0 8.95043 0H2.16211C1.28978 0 0.580078 0.709699 0.580078 1.58203V15.2578C0.580078 16.1301 1.28978 16.8398 2.16211 16.8398H8.92582C8.31962 15.9851 7.96289 14.9417 7.96289 13.8164C7.96289 10.928 10.3128 8.57812 13.2012 8.57812C13.3791 8.57812 13.555 8.58705 13.7285 8.60425V4.85086C13.7285 4.15586 13.4605 3.50016 12.9739 3.00421ZM6.16992 11.7773H3.35742C3.06619 11.7773 2.83008 11.5412 2.83008 11.25C2.83008 10.9588 3.06619 10.7227 3.35742 10.7227H6.16992C6.46116 10.7227 6.69727 10.9588 6.69727 11.25C6.69727 11.5412 6.46116 11.7773 6.16992 11.7773ZM6.16992 8.96484H3.35742C3.06619 8.96484 2.83008 8.72873 2.83008 8.4375C2.83008 8.14627 3.06619 7.91016 3.35742 7.91016H6.16992C6.46116 7.91016 6.69727 8.14627 6.69727 8.4375C6.69727 8.72873 6.46116 8.96484 6.16992 8.96484ZM6.16992 6.15234H3.35742C3.06619 6.15234 2.83008 5.91623 2.83008 5.625C2.83008 5.33377 3.06619 5.09766 3.35742 5.09766H6.16992C6.46116 5.09766 6.69727 5.33377 6.69727 5.625C6.69727 5.91623 6.46116 6.15234 6.16992 6.15234ZM9.54492 8.96484H8.98242C8.69119 8.96484 8.45508 8.72873 8.45508 8.4375C8.45508 8.14627 8.69119 7.91016 8.98242 7.91016H9.54492C9.83616 7.91016 10.0723 8.14627 10.0723 8.4375C10.0723 8.72873 9.83616 8.96484 9.54492 8.96484ZM9.54492 6.15234H8.98242C8.69119 6.15234 8.45508 5.91623 8.45508 5.625C8.45508 5.33377 8.69119 5.09766 8.98242 5.09766H9.54492C9.83616 5.09766 10.0723 5.33377 10.0723 5.625C10.0723 5.91623 9.83616 6.15234 9.54492 6.15234Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2043_13615">
          <rect width={width} height={height} fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InspectionPassedIcon;
