import types from "rdx/modules/auth/types";
import createAction from "rdx/utils/createAction";

export default {
  login: (payload) => createAction(types.LOGIN, payload),
  oauth: (payload) => createAction(types.OAUTH, payload),
  setAuthUri: (payload) => createAction(types.SET_AUTH_URI, payload),
  logout: (payload) => createAction(types.LOGOUT, payload),
  setLoginErrors: (payload) => createAction(types.SET_LOGIN_ERRORS, payload),
  setLogoutErrors: (payload) => createAction(types.SET_LOGIN_ERRORS, payload),
  clearLoginErrors: (payload) => createAction(types.CLEAR_LOGIN_ERRORS, payload),
  passwordResetRequest: (payload) => createAction(types.PASSWORD_RESET_REQUEST, payload),
  setResetPasswordErrors: (payload) => createAction(types.SET_RESET_PASSWORD_ERRORS, payload),
  clearResetPasswordErrors: (payload) => createAction(types.CLEAR_RESET_PASSWORD_ERRORS, payload),
  createNewPasswordRequest: (payload) => createAction(types.CREATE_NEW_PASSWORD_REQUEST, payload),
  setNewPasswordErrors: (payload) => createAction(types.SET_NEW_PASSWORD_ERRORS, payload),
  clearNewPasswordErrors: (payload) => createAction(types.CLEAR_NEW_PASSWORD_ERRORS, payload),

  setTermsOfServiceModalVisible: (payload) => createAction(types.SET_TERMS_OF_SERVICE_MODAL_VISIBLE, payload),
  acceptedLatestTermsOfServiceRequest: (payload) => createAction(types.ACCEPTED_LATEST_TOS_REQUEST, payload),
  getLatestTermsOfServiceRequest: (payload) => createAction(types.GET_LATEST_TOS_REQUEST, payload),
  setLatestTermsOfService: (payload) => createAction(types.SET_LATEST_TOS, payload),
  // INSERTION_PT (for script, do not remove)
};
