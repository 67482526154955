import { put, all } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* deleteSms(action) {
  const smsId = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/smss/${smsId}`, {});
  if (success && data) {
    const out = keysToCamelCase(_.clone(data.body));
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.SUCCESS_DELETING_NEW_SMS,
          payload: data.body,
        }),
      ),
      put(actions.setSmsDetails(out)),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_NEW_SMS,
    });
  }
  return null;
}

export default deleteSms;
