import types from "rdx/modules/team/types";
import createAction from "rdx/utils/createAction";

export default {
  getTeamLeadsList: (payload) => createAction(types.GET_TEAM_LEADS_LIST_REQUEST, payload),
  setTeamLeadsList: (payload) => createAction(types.SET_TEAM_LEADS_LIST, payload),
  getAmbassadorList: (payload) => createAction(types.GET_AMBASSADOR_LIST_REQUEST, payload),
  setAmbassadorList: (payload) => createAction(types.SET_AMBASSADOR_LIST, payload),
  getTeamUplineList: (payload) => createAction(types.GET_TEAM_UPLINE_LIST_REQUEST, payload),
  setTeamUplineList: (payload) => createAction(types.SET_TEAM_UPLINE_LIST, payload),
  getTeamUserDetails: (payload) => createAction(types.GET_TEAM_USER_DETAILS_REQUEST, payload),
  getTeamUserRecruits: (payload) => createAction(types.GET_TEAM_USER_RECRUITS_REQUEST, payload),
  setTeamUserDetails: (payload) => createAction(types.SET_TEAM_USER_DETAILS, payload),
  resetTeamUserDetails: (payload) => createAction(types.RESET_TEAM_USER_DETAILS, payload),
  inviteNewTeamLead: (payload) => createAction(types.INVITE_NEW_TEAM_LEAD_REQUEST, payload),
  setSellersAutocompleteSuggestions: (payload) => createAction(types.SET_CONSULTANTS_AUTOCOMPLETE_SUGGESTIONS, payload),
  archiveTeamLead: (payload) => createAction(types.ARCHIVE_TEAM_LEAD_REQUEST, payload),
  unarchiveTeamLead: (payload) => createAction(types.UNARCHIVE_TEAM_LEAD_REQUEST, payload),
  setEditInviteId: (payload) => createAction(types.SET_EDIT_INVITE_ID, payload),
  getInviteDetails: (payload) => createAction(types.GET_INVITE_DETAILS, payload),
  setInviteDetails: (payload) => createAction(types.SET_INVITE_DETAILS, payload),
  updatePendingInvite: (payload) => createAction(types.UPDATE_PENDING_INVITE, payload),
  deleteInvite: (payload) => createAction(types.DELETE_INVITE, payload),
  // INSERTION_PT (for script, do not remove)
};
