import React from "react";
import { IconT } from "types/utils";
import { theme } from "styles/themes";

const CheckmarkHouseIcon = ({ height = "51", width = "51", fill = theme.colors.white }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.0812 21.3457L22.2288 30.9685L18.9623 27.5103C18.3819 26.8957 17.4139 26.8681 16.7994 27.4485C16.1848 28.0289 16.1573 28.997 16.7377 29.6109L22.1411 35.3326L34.2196 23.5351C34.8239 22.9445 34.8357 21.9755 34.2452 21.3712C33.6545 20.7669 32.6855 20.7561 32.0812 21.3457Z"
        fill={fill}
      />
      <path
        d="M42.115 17.2262L27.6535 5.39465C27.0655 4.91419 26.301 4.64941 25.5003 4.64941C24.6996 4.64941 23.9351 4.91404 23.3471 5.39448L8.88603 17.2261C7.87431 18.0538 7.14038 19.6033 7.14038 20.9098V45.3898H43.8604V20.9098C43.8604 19.6033 43.1264 18.0538 42.1152 17.2261L42.115 17.2262ZM40.8001 42.3301H10.2001V20.9101C10.2001 20.5261 10.5265 19.8381 10.8233 19.5953L25.2844 7.76369C25.311 7.74177 25.3905 7.71006 25.5001 7.71006C25.6096 7.71006 25.6893 7.74227 25.7152 7.76369L40.1767 19.5953C40.4735 19.8381 40.7999 20.5261 40.7999 20.9101L40.8001 42.3301Z"
        fill={fill}
      />
    </svg>
  );
};
export default CheckmarkHouseIcon;
