import React from "react";
import PropTypes from "prop-types";

const DownCaret = (props) => {
  const { fill, height, width, containerStyles } = props;

  return (
    <div
      style={{
        paddingTop: "2px",
        paddingRight: "1px",
        position: "absolute",
        right: 0,
        pointerEvents: "none",
        ...containerStyles,
      }}
    >
      <svg width={width} height={height} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="5.08252"
          y="5.49902"
          width="8.13927"
          height="1.35655"
          rx="0.678273"
          transform="rotate(-42.5 5.08252 5.49902)"
          fill={fill}
        />
        <rect
          width="8.13927"
          height="1.35655"
          rx="0.678273"
          transform="matrix(-0.737277 -0.67559 -0.67559 0.737277 6.91748 5.49902)"
          fill={fill}
        />
      </svg>
    </div>
  );
};

DownCaret.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

DownCaret.defaultProps = {
  fill: "var(--hit-gray)",
  width: "12",
  height: "7",
};

export default DownCaret;
