import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ZipButton, ZipInput } from "components/HomepageComponents";
import styles from "../../Solar.module.less";

const EstimateSavings = (props) => {
  const { theme } = props;
  const { t } = useTranslation(["whysolar", "form_labels"]);
  const light = theme === "light";
  const [zip, setZip] = useState("");

  return (
    <div className={light ? styles.savingsLight : styles.savings}>
      <div className={styles.innerContainer}>
        <h3 className={styles.title}>{t("savings.how_much_you_can_save")}</h3>
        <h5 className={styles.subheading}>{t("savings.enter_your_zipcode_below")}</h5>
        <div className={styles.buttonContainer}>
          <ZipInput zip={zip} setZip={setZip} className={styles.input} />
          <ZipButton className={styles.zipButton} type="primary" zip={zip}>
            {t("form_labels:check_savings")}
          </ZipButton>
        </div>
      </div>
    </div>
  );
};

EstimateSavings.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]),
};

EstimateSavings.defaultProps = {
  theme: "dark",
};

export default EstimateSavings;
