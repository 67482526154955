import React from "react";
import Helmet from "react-helmet";
import ScrollToTopOnMount from "containers/router/scrollToTop";
import InjectFacebookPixel from "components/Injectors/InjectFacebookPixel";
import yesText from "./yesText";
import PageFooter from "../components/pageFooter";
import styles from "../Education.module.less";

const SubmitRedirectYes = (props) => {
  const { user } = props;
  const displayText = yesText;
  const quoteText =
    "We put 18 solar panels on our roof and we’re saving about $100 per month, which will equate to about $30,000 in savings over the life of the system. We couldn’t be happier with how everything turned out.";
  const helmetText = "Congratulations, Your Home Qualifies To Save With Solar! – POWUR | Solar Energy for your Home";

  return (
    <div className={styles.submitRedirectPageContainer}>
      <InjectFacebookPixel facebookPixelId={user?.facebook_pixel_id} />
      <Helmet>
        <title>{helmetText}</title>
      </Helmet>
      <ScrollToTopOnMount />
      <div className={styles.backgroundContainer}>
        <div className={styles.row}>
          <div className={styles.powurLogo} />
        </div>
        <div className={styles.row}>
          <span className={styles.header}>{displayText.header}</span>
          <span className={styles.subHeader}>{displayText.subHeader}</span>
        </div>
        <div className={styles.contentContainer}>
          {displayText.content.map((item) => (
            <p key={item.text} className={styles[`paragraph${item.style}`]}>
              {item.text}
            </p>
          ))}
        </div>
        <div className={styles.quoteContainer}>
          <div className={styles.quoteIcon} />
          <span className={styles.quoteText}>{quoteText}</span>
          <div className={styles.nameContainer}>
            <span>- Daniel Erickson</span>
          </div>
          <div className={styles.avatarContainer}>
            <div className={styles.avatar} />
          </div>
        </div>
      </div>
      <PageFooter user={user} />
    </div>
  );
};

export default SubmitRedirectYes;
