import SessionClient from "util/SessionClient";

const texts = {
  offer: {
    standard: "offer",
    changeIf: (session) => session.isExternal,
    changeTo: "assignment",
  },
};

export default function swapText(key) {
  const session = new SessionClient();
  const text = texts[key];

  return text.changeIf(session) ? text.changeTo : text.standard;
}
