import { put, all, select } from "redux-saga/effects";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { keysToCamelCase } from "lib/helpers";

const updateList = (list, note) => {
  return list.map((l) => {
    if (l.id === note.id) {
      return note;
    }
    return l;
  });
};

export default function* setMessageLists(note) {
  const newNote = keysToCamelCase(note);
  const inboxList = yield select(selectors.getInboxList);
  const projectsList = yield select(selectors.getProjectMessageList);
  const newInboxList = updateList(inboxList, newNote);
  const newProjectList = updateList(projectsList, newNote);
  yield all([
    put(actions.setSelectedMessageDetails(newNote)),
    put(actions.setInboxList({ list: newInboxList })),
    put(actions.setProjectMessageList({ list: newProjectList })),
  ]);
}
