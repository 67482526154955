import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import selectors from "rdx/selectors";
import actions from "rdx/actions";
import { keysToSnakeCase, keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* saveAndSubmitLead(action) {
  const { leadId, lead, address, siteInfo, enterprise, existingSystem, isLeadGen = false } = action.payload;
  const params = {
    lead: keysToSnakeCase(lead),
    address: keysToSnakeCase(address),
    site_info: siteInfo && keysToSnakeCase(siteInfo),
    existing_system: existingSystem && keysToSnakeCase(existingSystem),
    enterprise,
  };

  const { success, data, error } = yield* makeRequest.patch(`/leads/${leadId}`, params);
  if (success && data) {
    const response = keysToCamelCase(data.body);

    const existingLeadDetails = yield select(selectors.getLeadDetails);
    const l2pDrawerLeadId = yield select(selectors.selectLeadToProposalDrawerLeadId);

    yield all([
      ...(l2pDrawerLeadId === leadId
        ? [
            put(actions.setLeadToProposalDrawerLeadDetails(response)),
            put(actions.setLeadToProposalDrawerProjectId({ id: response.projectId })),
          ]
        : []),
      ...(existingLeadDetails?.id === leadId ? [put(actions.setLeadDetails(response))] : []),
      put(actions.submitLeadToProvider({ leadId, enterprise, isLeadGen })),
      put(actions.getLeads()),
    ]);
  } else {
    return getErrorActions({ error, message: messages.ERROR_UPDATING_LEAD });
  }
  return null;
}

export default saveAndSubmitLead;
