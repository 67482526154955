import makeRequest from "rdx/utils/makeRequest";
import { all, put } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* updateSalesTeam(action) {
  const { name, teamId } = action.payload;
  const { success, data, error } = yield* makeRequest.patch(`/sales_teams/${teamId}`, {
    name,
  });
  if (success && data) {
    yield all([put(actions.getTeams())]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_AVAILABLE_MANAGERS,
    });
  }

  return null;
}

export default updateSalesTeam;
