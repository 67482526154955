import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "rdx";
import { useSearchParam } from "react-use";
import constants from "components/GoogleOAuth/constants";

const GoogleCallback = () => {
  const dispatch = useDispatch();

  const code = useSearchParam("code");

  const googleUser = useSelector(selectors.getGoogleUser);

  useEffect(() => {
    const redirect_uri = window.location.origin + constants.GOOGLE_OAUTH_CALLBACK_PATH;

    // exchange code for access token via api
    dispatch(actions.exchangeGoogleCallback({ code, redirect_uri }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // close popup window and refresh status
    // send message to window
    const onSuccess = (user) => {
      const data = {
        type: "oauth_success",
        data: { message: "OAuth success data here", user },
      };
      window.opener.postMessage(data, "*");
    };

    if (googleUser) {
      onSuccess(googleUser?.user);
      window.close();
    }
  }, [googleUser]);

  return (
    <>
      <div>{googleUser ? `Connected with ${googleUser?.user?.name} account.` : "Not connected."}</div>
      <div>
        <button type="button" onClick={() => window.close()}>
          Close
        </button>
      </div>
    </>
  );
};

export default GoogleCallback;
