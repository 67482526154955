import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/complianceIssues/selectors";
import messages from "rdx/modules/complianceIssues/messages";
import { camelizeKeys } from "lib/helpers";

function* createComplianceIssue(action) {
  const { userId, occurredAt, description, complianceIssueId } = action.payload;

  const { success, data, error } = yield* makeRequest.post("/compliance_incidents", {
    user_id: userId,
    occurred_at: occurredAt,
    description,
    compliance_issue_id: complianceIssueId,
  });

  if (success && data) {
    const list = yield select(selectors.selectComplianceIncidents);
    const incidentCount = yield select(selectors.selectComplianceIncidentCount);

    const clone = [...list];
    clone.unshift(camelizeKeys(data.body));

    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.CREATE_COMPLIANCE_ISSUE_SUCCESS,
        }),
      ),
      put(
        actions.setComplianceIssues({
          incidents: clone,
          incidentCount: incidentCount + 1,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_COMPLIANCE_ISSUE,
    });
  }

  return null;
}

export default createComplianceIssue;
