import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "antd";
import { getBlackOrWhiteContrast } from "lib/helpers";

const TeamAvatar = (props) => {
  const { size, shape, color, shortName, className } = props;
  const fontColor = color ? getBlackOrWhiteContrast(color) : "#000";

  return (
    <Avatar
      size={size}
      shape={shape}
      className={className}
      style={{ backgroundColor: color || "FFF", color: fontColor }}
      icon={shortName}
    />
  );
};

TeamAvatar.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shape: PropTypes.string,
  color: PropTypes.string,
  shortName: PropTypes.string,
  className: PropTypes.string,
};

TeamAvatar.defaultProps = {
  size: "large",
  shape: "circle",
  color: "#000",
  shortName: "",
  className: "",
};

export default TeamAvatar;
