import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { Tooltip } from "components/mui-styled-components/Tooltip";
import styles from "../FormElements.module.less";

type AvatarT = {
  thumb: string;
};

type DataT = {
  avatar: AvatarT;
};

type UserT = {
  data: DataT;
  disabled: boolean;
  displayValue: string;
  key: number;
};

type UsersDisplayT = {
  user: UserT;
};

export const UsersDisplay = ({ user }: UsersDisplayT) => {
  const userCard = (
    <div className={styles.autoCompleteDropdownUserDisplay}>
      <div className={styles.avatarContainer}>
        {user?.data?.avatar?.thumb ? (
          <Avatar src={user?.data.avatar.thumb} size={24} />
        ) : (
          <Avatar icon={<UserOutlined />} size={24} />
        )}
      </div>
      <div className={styles.nameContainer}>
        <span className={styles.name}>{user.displayValue}</span>
      </div>
    </div>
  );

  const alertMessage = "This user cannot be selected";

  return user.disabled ? (
    <Tooltip placement="top" title={alertMessage}>
      {userCard}
    </Tooltip>
  ) : (
    userCard
  );
};
