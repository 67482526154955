import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const ClosedProposalsIcon = ({
  width = "26",
  height = "26",
  startColor = cvar("picton-blue"),
  stopColor = cvar("blue-ribbon"),
}: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.62357 24.0931C2.92582 24.2726 3.27925 24.375 3.65625 24.375H22.3438C22.7208 24.375 23.0742 24.2726 23.3764 24.0931L15.6999 16.9967L13.9636 17.8726C13.3551 18.1797 12.6449 18.1797 12.0364 17.8726L10.3001 16.9967L2.62357 24.0931ZM24.375 12.623L16.5864 16.5498L24.0484 23.4487C24.2548 23.1303 24.375 22.7508 24.375 22.3438V12.623ZM9.41362 16.5498L1.625 12.623V22.3438C1.625 22.7508 1.74524 23.1303 1.95162 23.4487L9.41362 16.5498ZM6.5 14.0481L12.4215 17.0341C12.7871 17.2185 13.2129 17.2185 13.5785 17.0341L19.5 14.0481V3.70662C19.5 3.36862 19.3708 3.04362 19.1384 2.80475C18.9109 2.56994 18.603 2.4375 18.2812 2.4375H7.71875C7.397 2.4375 7.08907 2.56994 6.86157 2.80475C6.62919 3.04362 6.5 3.36862 6.5 3.70662V14.0481ZM5.6875 7.92756L2.49519 10.1506C2.01094 10.4878 1.70056 11.0167 1.63719 11.596L5.6875 13.6386V7.92756ZM20.3125 7.91375V13.6386L24.3628 11.596C24.3003 11.0175 23.9915 10.4886 23.5089 10.1506L20.3125 7.91375ZM13 4.0625C10.7575 4.0625 8.9375 5.8825 8.9375 8.125C8.9375 10.3675 10.7575 12.1875 13 12.1875C15.2425 12.1875 17.0625 10.3675 17.0625 8.125C17.0625 5.8825 15.2425 4.0625 13 4.0625ZM13 4.875C14.794 4.875 16.25 6.331 16.25 8.125C16.25 9.919 14.794 11.375 13 11.375C11.206 11.375 9.75 9.919 9.75 8.125C9.75 6.331 11.206 4.875 13 4.875ZM13 7.55056L12.0689 6.61862L11.4936 7.19388L12.4256 8.125L11.4936 9.05612L12.0689 9.63138L13 8.69944L13.9311 9.63138L14.5064 9.05612L13.5744 8.125L14.5064 7.19388L13.9311 6.61862L13 7.55056Z"
        fill="url(#paint0_linear_167_89)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_167_89"
          x1="5.61623"
          y1="-6.70313"
          x2="25.6866"
          y2="-4.62409"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={stopColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ClosedProposalsIcon;
