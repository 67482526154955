import types from "rdx/modules/workflows/types";
import createAction from "rdx/utils/createAction";

export default {
  createDataGroup: (payload) => createAction(types.CREATE_DATA_GROUP_REQUEST, payload),
  updateDataGroup: (payload) => createAction(types.UPDATE_DATA_GROUP_REQUEST, payload),
  getDataGroups: (payload) => createAction(types.GET_DATA_GROUPS_REQUEST, payload),
  deleteDataGroup: (payload) => createAction(types.DELETE_DATA_GROUP_REQUEST, payload),
  setDataGroups: (payload) => createAction(types.SET_DATA_GROUPS, payload),
  setMilestoneOptions: (payload) => createAction(types.SET_MILESTONE_OPTIONS, payload),

  createMetaField: (payload) => createAction(types.CREATE_META_FIELD_REQUEST, payload),
  updateMetaField: (payload) => createAction(types.UPDATE_META_FIELD_REQUEST, payload),
  deleteMetaField: (payload) => createAction(types.DELETE_META_FIELD_REQUEST, payload),

  createWorkflow: (payload) => createAction(types.CREATE_WORKFLOW_REQUEST, payload),
  updateWorkflow: (payload) => createAction(types.UPDATE_WORKFLOW_REQUEST, payload),
  getWorkflowsList: (payload) => createAction(types.GET_WORKFLOWS_LIST_REQUEST, payload),
  getActiveWorkflow: (payload) => createAction(types.GET_ACTIVE_WORKFLOW_REQUEST, payload),
  getWorkflow: (payload) => createAction(types.GET_WORKFLOW_REQUEST, payload),
  deleteWorkflow: (payload) => createAction(types.DELETE_WORKFLOW_REQUEST, payload),
  copyWorkflow: (payload) => createAction(types.COPY_WORKFLOW_REQUEST, payload),
  setWorkflows: (payload) => createAction(types.SET_WORKFLOWS, payload),
  setWorkflowDetails: (payload) => createAction(types.SET_WORKFLOW_DETAILS, payload),

  createPhase: (payload) => createAction(types.CREATE_PHASE_REQUEST, payload),
  updatePhase: (payload) => createAction(types.UPDATE_PHASES_REQUEST, payload),
  updatePhaseRanks: (payload) => createAction(types.UPDATE_PHASE_RANKS_REQUEST, payload),
  getWorkflowPhases: (payload) => createAction(types.GET_WORKFLOW_PHASES_REQUEST, payload),
  removeWorkflowPhase: (payload) => createAction(types.REMOVE_WORKFLOW_PHASE_REQUEST, payload),
  setPhases: (payload) => createAction(types.SET_PHASES, payload),

  createMilestone: (payload) => createAction(types.CREATE_MILESTONE_REQUEST, payload),
  updateMilestone: (payload) => createAction(types.UPDATE_MILESTONE_REQUEST, payload),
  updateMilestoneRanks: (payload) => createAction(types.UPDATE_MILESTONE_RANKS_REQUEST, payload),
  reorderMilestoneDataGroups: (payload) => createAction(types.REORDER_MILESTONE_DATA_GROUPS_REQUEST, payload),
  removePhaseMilestone: (payload) => createAction(types.REMOVE_PHASE_MILESTONE_REQUEST, payload),
  getPhaseMilestones: (payload) => createAction(types.GET_PHASE_MILESTONES_REQUEST, payload),
  // INSERTION_PT (for script, do not remove)
};
