import { put } from "redux-saga/effects";
import { camelizeKeys } from "lib/helpers";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* getDashboardNotificationsWidget() {
  const { success, data, error } = yield* makeRequest.get("/crm_notifications/widget/");

  if (success && data) {
    const body = camelizeKeys(data.body);
    yield put(actions.setDashboardNotifications(body));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_NOTIFICATIONS,
    });
  }
  return null;
}

export default getDashboardNotificationsWidget;
