import React from "react";

const PadlockIcon = ({ width = 33, height = 33, fill = "none", ...rest }) => (
  <svg width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M25.7812 12.1172H23.4586V6.81721C23.4586 3.05817 20.3359 0 16.4976 0C12.6594 0 9.53668 3.05817 9.53668 6.81721V12.1172H7.21875C5.08638 12.1172 3.35156 13.852 3.35156 15.9844V29.1328C3.35156 31.2652 5.08638 33 7.21875 33H25.7812C27.9136 33 29.6484 31.2652 29.6484 29.1328V15.9844C29.6484 13.852 27.9136 12.1172 25.7812 12.1172ZM12.1148 6.81721C12.1148 4.47975 14.0809 2.57812 16.4976 2.57812C18.9143 2.57812 20.8804 4.47975 20.8804 6.81721V12.1172H12.1148V6.81721ZM27.0703 29.1328C27.0703 29.8436 26.492 30.4219 25.7812 30.4219H7.21875C6.50796 30.4219 5.92969 29.8436 5.92969 29.1328V15.9844C5.92969 15.2736 6.50796 14.6953 7.21875 14.6953H25.7812C26.492 14.6953 27.0703 15.2736 27.0703 15.9844V29.1328Z"
      fill="#0070F4"
    />
    <path
      d="M16.5 18.4336C15.1829 18.4336 14.1152 19.5013 14.1152 20.8184C14.1152 21.6593 14.5509 22.3981 15.2086 22.8227V25.6523C15.2086 26.3642 15.7857 26.9414 16.4976 26.9414C17.2095 26.9414 17.7867 26.3642 17.7867 25.6523V22.8258C18.4469 22.4016 18.8848 21.6614 18.8848 20.8184C18.8848 19.5013 17.8171 18.4336 16.5 18.4336Z"
      fill="#0070F4"
    />
  </svg>
);
export default PadlockIcon;
