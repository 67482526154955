import React from "react";
import PropTypes from "prop-types";

const OpenInNewWindowIcon = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.29282 9.70698C4.68384 10.098 5.31587 10.098 5.70686 9.70698L11.9999 3.41399V6.99999C11.9999 7.55194 12.4468 7.99999 12.9999 7.99999C13.5529 7.99999 13.9998 7.55194 13.9998 6.99999V0.999998C13.9998 0.448049 13.5529 0 12.9999 0H6.99986C6.44684 0 5.99986 0.448049 5.99986 0.999998C5.99986 1.55195 6.44684 2 6.99986 2H10.5859L4.29287 8.29299C3.90184 8.68401 3.90184 9.31603 4.29287 9.70703L4.29282 9.70698ZM13.0098 10C12.4629 10 12.0189 10.447 12.0189 11V11.599C12.0189 11.988 11.5739 12 11.5229 12H2.47695C2.42597 12 1.98202 11.988 1.98202 11.599V2.50005C1.98202 2.22505 2.20302 2.00005 2.47695 2.00005H3.00994C3.55702 2.00005 3.99999 1.55307 3.99999 1.00005C3.99999 0.447024 3.55702 4.99999e-05 3.00994 4.99999e-05H2.47695C1.11102 4.99999e-05 0 1.12105 0 2.50005V11.599C0 12.945 1.088 14 2.47695 14H11.5229C12.9119 14 13.9999 12.945 13.9999 11.599V11C13.9999 10.447 13.5569 10 13.0098 10"
        fill={fill}
      />
    </svg>
  );
};

OpenInNewWindowIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

OpenInNewWindowIcon.defaultProps = {
  fill: "var(--dark-blue)",
  height: "14",
  width: "14",
};

export default OpenInNewWindowIcon;
