import makeRequest from "rdx/utils/makeRequest";
import { put } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import { camelizeKeys } from "lib/helpers";
import actions from "rdx/actions";
import messages from "../messages";

function* getAdminRoofingProposal(action) {
  const { proposalId } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/admin_roofing_proposals/${proposalId}`);

  if (success && data) {
    const dataFormated = camelizeKeys(data.body);
    yield put(actions.setAdminRoofingProposalDetails(dataFormated));
    yield put(actions.setRoofingProposalDetails(dataFormated.formData));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ROOFING_PROPOSAL,
    });
  }

  return null;
}

export default getAdminRoofingProposal;
