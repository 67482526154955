import { put, select } from "redux-saga/effects";
import selectors from "rdx/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";

function* deleteCareerListing(action) {
  const { success, data, error } = yield* makeRequest.delete(`/career_listings/${action.payload}`, {});

  if (success && data) {
    const rawListings = yield select(selectors.getCareerListings);
    const listings = rawListings.filter((f) => f.id !== action.payload);
    yield put(actions.setCareerListings(listings));
  } else if (error) {
    return getErrorActions({
      error,
      message: "There was an error getting career listings",
    });
  }

  return null;
}

export default deleteCareerListing;
