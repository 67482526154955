// import content from "containers/Enterprise/enterprisePersonnel";
import sidebar from "containers/Enterprise/enterprisePersonnel/components/EnterprisePersonnelSidebar";
import tabs from "containers/Enterprise/enterprisePersonnel/tabs";
import { TeamIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";
import SessionClient from "util/SessionClient";

const content = l(() => import("containers/Enterprise/enterprisePersonnel"));

const { ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN } = permissionTypes;

const session = new SessionClient();

export default {
  key: "org-team",
  title: `${session?.user?.props?.enterpriseName || ""} Team`,
  path: "/org-team/:tab?/:userId?",
  basePath: "org-team",
  requiredParams: ["tab"],
  optionalParams: ["userId"],
  linkTarget: "/org-team",
  linkTitle: "Team",
  linkCategory: "Enterprise",
  icon: TeamIcon,
  permission: [ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN],
  content,
  sidebar,
  tabs,
};
