import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../../messages";
import { formatDataGroups } from "./util";

function* createDataGroup(action) {
  const { label, description, rank, milestoneId: milestone_id } = action.payload;
  const { success, data, error } = yield* makeRequest.post("/data_groups", {
    label,
    description,
    rank,
    milestone_id,
  });
  if (success && data) {
    yield all([
      put(
        actions.newMessageEvent({
          message: messages.DATA_GROUP_CREATE_SUCCESS,
        }),
      ),
      put(actions.setDataGroups(formatDataGroups(data))),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_DATA_GROUP,
    });
  }
  return null;
}

export default createDataGroup;
