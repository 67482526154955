import { put, select, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/enterpriseProjects/selectors";
import projectSelectors from "rdx/modules/projects/selectors";
import adminProjectsSelectors from "rdx/modules/adminProjects/selectors";

import messages from "rdx/modules/enterpriseProjects/messages";

import { camelizeKeys } from "@helpers";

function* submitEnterpriseLeadToSeller(action) {
  const { provider_lead_id, receiver_id, flat_amount, percentage } = action.payload;

  const offer = { receiver_id };
  if (flat_amount) {
    offer.flat_amount = flat_amount;
  } else if (percentage) {
    offer.percentage = percentage;
  }

  const { success, data, error } = yield* makeRequest.post(`/provider_leads/${provider_lead_id}/project_offers`, offer);

  if (success && data) {
    const enterpriseProjects = yield select(selectors.getEnterpriseProjectsList);
    const enterpriseProjectDetail = yield select(projectSelectors.getProjectDetails);

    const adminListProjects = yield select(adminProjectsSelectors.getAdminProjectsList);
    const { projects: adminProjects } = adminListProjects;
    const updatedProject = adminProjects.find((project) => project.id === data.body.project.id);

    if (updatedProject) {
      updatedProject.offer = {
        receiver: { ...data.body.receiver },
        rescindedAt: data.body.rescinded_at,
        responseId: null,
      };
    }
    const projects = enterpriseProjects.projects.map((ep) => {
      const proj = ep;
      if (proj.id === provider_lead_id) {
        proj.offer = camelizeKeys(data.body);
      }
      return proj;
    });

    yield all([
      yield put(actions.setEnterpriseProjectsList({ ...enterpriseProjects, projects })),
      yield put(
        actions.setProjectDetails({
          ...enterpriseProjectDetail,
          providerLead: {
            ...enterpriseProjectDetail.providerLead,
            offer: camelizeKeys(data.body),
          },
        }),
      ),
      yield put(actions.setAdminProjectsList({ ...adminProjects })),
      put(
        actions.newSuccessEvent({
          message: messages.ENTERPRISE_LEAD_OFFER_SUCCESS,
          payload: camelizeKeys(data.body),
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_SUBMITTING_ENTERPRISE_LEAD_OFFER,
      // payload: {},
    });
  }

  return null;
}

export default submitEnterpriseLeadToSeller;
