import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";

function* getCareerListings() {
  const { success, data, error } = yield* makeRequest.get("/career_listings", {});

  if (success && data) {
    yield put(actions.setCareerListings(camelizeKeys(data.body.career_listings)));
  } else if (error) {
    return getErrorActions({
      error,
      message: "There was an error getting career listings",
    });
  }

  return null;
}

export default getCareerListings;
