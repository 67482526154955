import React from "react";
import { IconT } from "types/utils";

const PlugIcon = ({ width = 34, height = 35, fill = "none", ...rest }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 35" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect y="0.5" width="34" height="35" rx="17" fill="#F0B349" fillOpacity="0.12" />
      <g clipPath="url(#clip0_731_2382)">
        <path
          d="M25 18.6767C25 18.2226 24.6309 17.8535 24.1768 17.8535H21.7562C19.7276 17.8535 18.0845 19.4933 18.0747 21.5186C18.0715 22.5738 17.3234 23.5113 16.2781 23.642C15.0237 23.7988 13.9522 22.8188 13.9522 21.597V19.7547C15.8338 19.3725 17.2515 17.7097 17.2515 15.7138V13.6232C17.2515 13.3259 17.0131 13.0874 16.7158 13.0874H15.6933V10.5035C15.6933 10.0985 15.3993 9.73914 14.9976 9.69994C14.537 9.65421 14.1482 10.0168 14.1482 10.4709V13.0874H12.1098V10.5035C12.1098 10.0985 11.8158 9.73914 11.4108 9.69994C10.9502 9.65748 10.5615 10.0168 10.5615 10.4709V13.0874H9.53573C9.23846 13.0874 9 13.3259 9 13.6232V15.7138C9 17.7097 10.4177 19.3725 12.2993 19.7547V21.5023C12.2993 23.5113 13.8575 25.2263 15.8665 25.3014C17.9767 25.3798 19.7211 23.6877 19.7211 21.5938V21.5644C19.7211 20.4243 20.6456 19.5031 21.7824 19.5031H24.1735C24.6309 19.4999 25 19.1307 25 18.6767Z"
          fill="url(#paint0_linear_731_2382)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_731_2382"
          x1="4.47721"
          y1="9.69606"
          x2="30.0619"
          y2="10.0071"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25355" stopColor="#EFB047" />
        </linearGradient>
        <clipPath id="clip0_731_2382">
          <rect width="16" height="16" fill="white" transform="translate(9 9.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlugIcon;
