import React from "react";
import type { IconT } from "types/utils";

const ContractIcon = (props: IconT) => (
  <svg width={29} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.546 2.331h2.898a3.049 3.049 0 0 1 5.927 0h2.898c.574 0 1.043.468 1.043 1.043v2.578c0 .574-.468 1.042-1.043 1.042H8.546a1.045 1.045 0 0 1-1.043-1.042V3.374c0-.575.47-1.043 1.043-1.043zM0 5.208v32.999C0 39.194.806 40 1.793 40h25.229c.987 0 1.793-.806 1.793-1.793V5.208c0-.987-.805-1.793-1.793-1.793h-3.624V5.95a3.132 3.132 0 0 1-3.129 3.128H8.546A3.132 3.132 0 0 1 5.417 5.95V3.414H1.794A1.798 1.798 0 0 0 0 5.209zm4.138 7.228c0-.62.508-1.127 1.127-1.127H8.68c.62 0 1.128.508 1.128 1.127v3.417A1.13 1.13 0 0 1 8.68 16.98H5.265a1.13 1.13 0 0 1-1.127-1.127v-3.417zm1.127 8.226H8.68c.62 0 1.128.507 1.128 1.127v3.416a1.13 1.13 0 0 1-1.128 1.127H5.265a1.13 1.13 0 0 1-1.127-1.127V21.79c0-.62.507-1.127 1.127-1.127zm0 9.352H8.68c.62 0 1.128.508 1.128 1.127v3.416a1.13 1.13 0 0 1-1.128 1.128H5.265a1.13 1.13 0 0 1-1.127-1.128v-3.416c0-.62.507-1.127 1.127-1.127zm7.5 1.042c0-.576.467-1.043 1.043-1.043h10.545a1.043 1.043 0 1 1 0 2.086H13.807a1.043 1.043 0 0 1-1.041-1.043zm0 3.587c0-.576.467-1.043 1.043-1.043h10.545a1.043 1.043 0 1 1 0 2.086H13.807a1.043 1.043 0 0 1-1.041-1.043zm1.042-21.25a1.043 1.043 0 1 1 0-2.085h10.545a1.043 1.043 0 1 1 0 2.085H13.807zm0 3.587a1.043 1.043 0 1 1 0-2.086h10.545a1.043 1.043 0 1 1 0 2.086H13.807zm0 5.766a1.043 1.043 0 1 1 0-2.086h10.545a1.043 1.043 0 1 1 0 2.086H13.807zm0 3.587a1.043 1.043 0 1 1 0-2.086h10.545a1.043 1.043 0 1 1 0 2.085H13.807zm.6-24.642a.807.807 0 1 0 .001 1.614.807.807 0 0 0 0-1.614z"
      fill="#002160"
    />
  </svg>
);

export default ContractIcon;
