import { put } from "redux-saga/effects";
import actions from "rdx/actions";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import messages from "rdx/modules/homeowner/messages";

function* deleteHomeownerAvailabilities(action) {
  const { availabilityIds } = action.payload;
  const params = { ids: availabilityIds };
  const { success, data, error } = yield* makeRequest.delete("/homeowner_availabilities", params);

  if (success && data) {
    yield put(actions.newSuccessEvent({ message: messages.HOMEOWNER_AVAILABILITY_DELETE_SUCCESS }));
  } else if (error) {
    const errorMessage = messages.ERROR_DELETING_HOMEOWNER_AVAILABILITY;
    return getErrorActions({
      error,
      message: errorMessage,
    });
  }
  return null;
}

export default deleteHomeownerAvailabilities;
