import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import { camelizeKeys } from "lib/helpers";
import actions from "rdx/actions";

function* oauth() {
  const { success, data, error } = yield* makeRequest.get("/jwt/authorize");

  if (success && data) {
    yield all([put(actions.setAuthUri(camelizeKeys(data)))]);
  }
  if (error) {
    return error;
  }

  return null;
}

export default oauth;
