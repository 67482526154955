import React from "react";

const videoList = [
  {
    number: "1",
    src: "https://player.vimeo.com/video/461497349",
    title: "Why Should I Switch to Solar?",
    description:
      "From saving money to withstanding power outages, solar energy offers big advantages for you. In this video, we will break down the top reasons why you should switch to clean energy.",
    previewPrimary: "/img/education_ws_video1watched.jpg",
    previewNowWatching: "/img/education_ws_video1playing.jpg",
    previewWatched: "/img/education_ws_video1watched.jpg",
    previewHoverWatch: "/img/education_ws_video1replay.jpg",
    previewHoverReplay: "/img/education_ws_video1replay.jpg",
  },
  {
    number: "2",
    src: "https://player.vimeo.com/video/461497509?autoplay=1",
    title: "Why Should I Go Solar With Powur?",
    description:
      "Powur is like no other solar energy company in the industry, our sole mission is to accelerate the adoption of clean sustainable energy worldwide. In this video, we will list the top reasons why you can trust Powur to get the job done for you.",
    previewPrimary: "/img/education_ws_video2primary.jpg",
    previewNowWatching: "/img/education_ws_video2playing.jpg",
    previewWatched: "/img/education_ws_video2watched.jpg",
    previewHoverWatch: "/img/education_ws_video2watch.jpg",
    previewHoverReplay: "/img/education_ws_video2replay.jpg",
  },
  {
    number: "3",
    src: "https://player.vimeo.com/video/461497747?autoplay=1",
    title: "Why Should I Go Solar Now?",
    description: (
      <>
        If you want to get the most out of the current Investment Tax Credit (ITC) then you better act now. In this
        video, find out about the ITC and why you need to go solar <span style={{ fontWeight: "800" }}>NOW</span> in
        order to cash in on this amazing tax credit.
      </>
    ),
    previewPrimary: "/img/education_ws_video3primary.jpg",
    previewNowWatching: "/img/education_ws_video3playing.jpg",
    previewWatched: "/img/education_ws_video3primary.jpg",
    previewHoverWatch: "/img/education_ws_video3watch.jpg",
    previewHoverReplay: "/img/education_ws_video3playing.jpg",
  },
];

export default videoList;
