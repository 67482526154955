import { styled } from "@mui/material/styles";
import { Typography, TypographyProps } from "@mui/material";

type TypographyPropsT = {
  font?: string;
  size?: string;
  weight?: string | number;
  color?: string;
  textTransform?: string | unknown;
  component?: React.ElementType;
} & TypographyProps;

export const AppText = styled(Typography)<TypographyPropsT>((props) => ({
  fontFamily: props.font,
  fontSize: props.size,
  fontWeight: props.weight,
  color: props.color,
  margin: 0,
}));

export const Paragraph = AppText.withComponent("p");
export const Label = AppText.withComponent("label");
