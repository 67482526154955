import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getVisionProposalCount(action) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/provider_leads/${id}/vision_proposal_count`);

  if (success && data) {
    const count = camelizeKeys(data.body)?.count;
    yield put(actions.setVisionProposalCount(count));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_VISION_PROPOSAL_COUNT,
    });
  }
  return null;
}

export default getVisionProposalCount;
