import createAction from "rdx/utils/createAction";
import types from "./types";

export default {
  getStateLicenses: (payload) => createAction(types.GET_STATE_LICENSES_REQUEST, payload),
  updateStateLicense: (payload) => createAction(types.UPDATE_STATE_LICENSE_REQUEST, payload),
  deleteStateLicense: (payload) => createAction(types.DELETE_STATE_LICENSE_REQUEST, payload),
  createStateLicense: (payload) => createAction(types.CREATE_STATE_LICENSE_REQUEST, payload),
  setStateLicenses: (payload) => createAction(types.SET_STATE_LICENSES, payload),
  // INSERTION_PT (for script, do not remove)
};
