import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useModalControls from "hooks/useModalControls";
import { buttonProps } from "lib/props";
import VideoModal from "../../Modals/VideoModal";
import styles from "./VideoTestimonials.module.less";

const VideoTestimonials = (props) => {
  const { backgroundColor, cardInfo, testimonialIntro } = props;
  const { t } = useTranslation("home");

  return (
    <div
      className={styles.videoTestimonials}
      style={{
        backgroundColor,
      }}
    >
      <div className={styles.innerContainer}>
        <h5 className={styles.header}>{t("mission.millions_of_reasons")}</h5>
        <h3 className={styles.subheading}>{testimonialIntro}</h3>
        <div className={styles.cardsContainer}>
          {cardInfo.map(({ title, text, videoSource, imageSource }) => {
            return (
              <Card
                key={`video-testimonials-${title}`}
                title={title}
                text={text}
                imageSource={imageSource}
                videoSource={videoSource}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Card = ({ title, text, imageSource, videoSource }) => {
  const { visible, open, close } = useModalControls();

  const handleOpen = () => {
    open();
  };

  return (
    <>
      <VideoModal visible={visible} close={close} title={title} videoSourceUrl={videoSource} />
      <div className={styles.testimonialCard} {...buttonProps(handleOpen)}>
        <div className={[styles.topCard, styles.bgDarkenZoomOnHover].join(" ")}>
          <div className={styles.bg} style={{ backgroundImage: `url(${imageSource})` }} />
        </div>
        <div className={styles.bottomCard}>
          <div className={styles.hoverBg} />
          <h4 className={[styles.title, styles.centered].join(" ")}>{title}</h4>
          <p className={styles.text}>{text}</p>
        </div>
      </div>
    </>
  );
};

VideoTestimonials.defaultProps = {
  backgroundColor: "var(--white)",
};

VideoTestimonials.propTypes = {
  backgroundColor: PropTypes.string,
  testimonialIntro: PropTypes.string,
  cardInfo: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      imageSource: PropTypes.string,
      videoSource: PropTypes.string,
    }),
  ).isRequired,
};

export default VideoTestimonials;
