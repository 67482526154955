import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/adminReports/messages";
import { camelizeKeys } from "lib/helpers";

function* getTicketList(action) {
  const params = { ...action.payload };
  const { success, data, error } = yield* makeRequest.get("/messages/admin", params);
  if (success && data) {
    yield put(actions.setTicketList(camelizeKeys(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_MESSAGING_METRICS,
    });
  }
  return null;
}

export default getTicketList;
