import { put, all } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase, camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getAdminProjectDetails(action) {
  const { projectId, filters } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/projects/${projectId}`, filters);
  if (success && data) {
    const project = camelizeKeys(_.clone(data.body));
    project.milestones = data.body.milestones.map((ms) => keysToCamelCase(ms));
    project.dataGroups = data.body.data_groups.map((d) => keysToCamelCase(d));
    project.dataFields = data.body.data_fields.map((d) => keysToCamelCase(d));
    yield all([put(actions.setAdminProjectDetails(project))]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ADMIN_PROJECT_DETAILS,
    });
  }
  return null;
}

export default getAdminProjectDetails;
