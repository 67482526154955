export default {
  // general
  UPDATE_DASHBOARD_PROMOTION_SUCCESS: "You have successfully updated this event/promo.",

  DELETE_DASHBOARD_PROMOTION_SUCCESS: "You have successfully deleted this event/promo.",

  CREATE_EVENT_PROMO_SUCCESS: "You have successfully created this event/promo.",

  // errors
  GET_EVENTS_AND_PROMOS_ERROR: "There was an error getting events and promos.",

  CREATE_EVENT_PROMO_ERROR: "There was an error getting events and promos.",

  GET_SELECTED_DASHBOARD_PROMOTION_ERROR: "There was an error getting this event/promo.",

  UPDATE_SELECTED_DASHBOARD_PROMOTION_ERROR: "There was an error updating this event/promo.",

  DELETE_SELECTED_DASHBOARD_PROMOTION_ERROR: "There was an error deleting this event/promo.",
};
