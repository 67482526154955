import React from "react";
import PropTypes from "prop-types";

const WarningOutlineInCircleIcon = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.5" cy="12.0459" r="11.5" fill={fill} />
      <path
        d="M11.9009 13.878C11.8913 13.8515 11.8786 13.8264 11.8632 13.8029C11.8465 13.7795 11.8282 13.7573 11.8084 13.7364C11.7061 13.6347 11.56 13.5903 11.4183 13.6179C11.3897 13.624 11.3617 13.6327 11.3346 13.6439C11.3091 13.6536 11.2849 13.6662 11.2623 13.6816C11.2384 13.6976 11.2162 13.7159 11.1959 13.7364C11.1552 13.7769 11.1228 13.825 11.1007 13.878C11.0913 13.9044 11.0836 13.9314 11.0776 13.9589C11.0717 13.9864 11.0688 14.0145 11.0688 14.0427C11.0696 14.1877 11.142 14.3229 11.2624 14.4038C11.2849 14.419 11.3092 14.4316 11.3346 14.4413C11.3617 14.4525 11.3897 14.4612 11.4183 14.4672C11.4736 14.4789 11.5307 14.4789 11.5859 14.4672C11.6138 14.4616 11.6409 14.4529 11.6669 14.4413C11.6933 14.4316 11.7184 14.419 11.7419 14.4038C11.7653 14.387 11.7875 14.3687 11.8084 14.3489C11.8897 14.2677 11.9354 14.1576 11.9354 14.0427C11.9355 13.9859 11.9237 13.9298 11.9009 13.8779L11.9009 13.878Z"
        fill="white"
      />
      <path
        d="M11.4295 10.2241C11.2767 10.2427 11.1375 10.3212 11.0425 10.4423C10.9476 10.5635 10.9046 10.7174 10.9232 10.8702L11.1875 13.0515C11.1999 13.154 11.2619 13.2439 11.3533 13.2919C11.4448 13.3398 11.554 13.3398 11.6454 13.2919C11.7368 13.2439 11.7988 13.154 11.8112 13.0515L12.0756 10.8702C12.0809 10.8238 12.0809 10.777 12.0756 10.7306C12.057 10.5778 11.9786 10.4385 11.8574 10.3436C11.7363 10.2487 11.5824 10.2057 11.4295 10.2242L11.4295 10.2241Z"
        fill="white"
      />
      <path
        d="M16.2871 14.6788L12.3782 7.78067C12.2604 7.57275 12.0727 7.4134 11.8483 7.3311C11.624 7.24874 11.3777 7.24865 11.1534 7.33102C10.929 7.4133 10.7413 7.57266 10.6234 7.7805L6.71453 14.6788C6.53765 14.991 6.54 15.3736 6.72066 15.6836C6.90132 15.9936 7.23311 16.1843 7.59192 16.1843H15.4099C15.7687 16.1843 16.1005 15.9936 16.2811 15.6836C16.4618 15.3736 16.4641 14.991 16.2873 14.6788L16.2871 14.6788ZM15.5342 15.2483C15.5091 15.2934 15.4613 15.3209 15.4097 15.32H7.59173C7.54048 15.32 7.4931 15.2927 7.46726 15.2485C7.44143 15.2042 7.44109 15.1496 7.46634 15.105L11.3754 8.20669C11.401 8.16148 11.4489 8.13363 11.5007 8.13363C11.5526 8.13372 11.6005 8.16165 11.626 8.20677L15.5349 15.1051H15.535C15.5611 15.1494 15.5608 15.2045 15.5341 15.2484L15.5342 15.2483Z"
        fill="white"
      />
    </svg>
  );
};

WarningOutlineInCircleIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

WarningOutlineInCircleIcon.defaultProps = {
  fill: "var(--safety-orange)",
  height: "20",
  width: "20",
};

export default WarningOutlineInCircleIcon;
