import { put, select, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/modules/tickets/selectors";
import messages from "rdx/modules/tickets/messages";
import { keysToCamelCase } from "lib/helpers";

function* getAllRepliesForIssue(action) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/messages/replies?parent_ids[]=${id}`, {});

  if (success && data) {
    const replies = { ...(yield select(selectors.getProjectMessageReplies)) };
    replies[id] = data.body.messages.map((e) => keysToCamelCase(e));
    yield all([
      put(actions.setProjectMessageReplies({ replies })),
      put(actions.markMessageAndRepliesAsViewed({ messageId: id })),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROJECT_REPLIES_PREVIEW,
    });
  }

  return null;
}

export default getAllRepliesForIssue;
