export default {
  GET_PENDING_ADMIN_PROPOSALS_LIST: "[adminProposals] GET Pending Admin Proposals List",
  SET_PENDING_ADMIN_PROPOSALS_LIST: "[adminProposals] SET Pending Admin Proposals List",
  SET_PENDING_ADMIN_PROPOSALS_FILTERS: "[adminProposals] SET Pending Admin Proposals Filters",

  GET_IN_PROGRESS_ADMIN_PROPOSALS_LIST: "[adminProposals] GET In Progress Admin Proposals List",
  SET_IN_PROGRESS_ADMIN_PROPOSALS_LIST: "[adminProposals] SET In Progress Admin Proposals List",
  SET_IN_PROGRESS_ADMIN_PROPOSALS_FILTERS: "[adminProposals] SET In Progress Admin Proposals Filters",
  SET_PROPOSALS_AUTOCOMPLETE_SUGGESTIONS: "[adminProposals] SET Proposals Autocomplete Suggestions",

  GET_COMPLETED_ADMIN_PROPOSALS_LIST: "[adminProposals] GET Completed Admin Proposals List",
  SET_COMPLETED_ADMIN_PROPOSALS_LIST: "[adminProposals] SET Completed Admin Proposals List",
  SET_COMPLETED_ADMIN_PROPOSALS_FILTERS: "[adminProposals] SET Completed Admin Proposals Filters",

  SET_ADMIN_PROPOSALS_COUNTS: "[adminProposals] SET Admin Proposals Counts",

  GET_ADMIN_PROPOSAL_DETAILS: "[adminProposals] GET Admin Proposal Details",
  SET_ADMIN_PROPOSAL_DETAILS: "[adminProposals] SET Admin Proposal Details",

  CREATE_PROPOSAL: "[adminProposals] Create Proposal",
  UPDATE_PROPOSAL: "[adminProposals] Update Proposal",
  MARK_PROPOSAL_AS_IN_PROGRESS: "[adminProposals] Mark Proposal As In Progress",
  MARK_PROPOSAL_AS_REJECTED: "[adminProposals] Mark Proposal As Rejected",
  // INSERTION_PT (for script, do not remove)
};
