import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const LinkIcon2 = ({ height = "16", width = "16", fill = cvar("graham-grey") }: IconT) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_25_48)">
      <path
        d="M6.66663 8.66672C6.95293 9.04948 7.3182 9.36618 7.73766 9.59535C8.15712 9.82452 8.62096 9.9608 9.09773 9.99495C9.57449 10.0291 10.053 9.9603 10.5009 9.79325C10.9487 9.62619 11.3554 9.36477 11.6933 9.02672L13.6933 7.02672C14.3005 6.39805 14.6365 5.55604 14.6289 4.68205C14.6213 3.80806 14.2707 2.97202 13.6527 2.354C13.0347 1.73597 12.1986 1.38541 11.3246 1.37781C10.4506 1.37022 9.60863 1.7062 8.97996 2.31339L7.83329 3.45339"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33334 7.33332C9.04704 6.95057 8.68177 6.63387 8.26231 6.40469C7.84285 6.17552 7.37901 6.03924 6.90224 6.0051C6.42548 5.97095 5.94695 6.03974 5.49911 6.2068C5.05127 6.37386 4.6446 6.63527 4.30668 6.97332L2.30668 8.97332C1.69948 9.60199 1.3635 10.444 1.3711 11.318C1.37869 12.192 1.72926 13.028 2.34728 13.646C2.96531 14.2641 3.80135 14.6146 4.67534 14.6222C5.54933 14.6298 6.39134 14.2938 7.02001 13.6867L8.16001 12.5467"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_25_48">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LinkIcon2;
