import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const PowurPlayIcon = (props) => {
  const { height, width, active, hovered, fill } = props;

  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      style={{ padding: "1px" }}
    >
      <svg width={width} height={height} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.625 18.9063H3.375C2.50504 18.9053 1.67098 18.5593 1.05583 17.9442C0.440676 17.329 0.0946765 16.495 0.09375 15.625V6.875C0.0946765 6.00504 0.440676 5.17098 1.05583 4.55583C1.67098 3.94068 2.50504 3.59468 3.375 3.59375H15.625C16.4949 3.59491 17.3288 3.94098 17.9439 4.55608C18.559 5.17119 18.9051 6.00511 18.9062 6.875V15.625C18.9051 16.4949 18.559 17.3288 17.9439 17.9439C17.3288 18.559 16.4949 18.9051 15.625 18.9063ZM3.375 4.90625C2.853 4.90671 2.35251 5.11428 1.9834 5.4834C1.61428 5.85251 1.40671 6.353 1.40625 6.875V15.625C1.40671 16.147 1.61428 16.6475 1.9834 17.0166C2.35251 17.3857 2.853 17.5933 3.375 17.5938H15.625C16.1469 17.5931 16.6473 17.3854 17.0163 17.0163C17.3854 16.6473 17.5931 16.1469 17.5938 15.625V6.875C17.5931 6.35307 17.3854 5.85271 17.0163 5.48365C16.6473 5.11459 16.1469 4.90694 15.625 4.90625H3.375Z"
          fill={fill}
        />
        <path
          d="M8.5655 14.4193C8.14978 14.4188 7.75122 14.2534 7.45727 13.9595C7.16331 13.6655 6.99796 13.267 6.9975 12.8513V9.64876C6.99771 9.38165 7.06618 9.11902 7.19643 8.88582C7.32668 8.65262 7.51437 8.45658 7.74169 8.31632C7.96901 8.17605 8.2284 8.09622 8.49525 8.08439C8.7621 8.07257 9.02754 8.12915 9.26637 8.24876L12.0104 9.62076C12.2559 9.74352 12.465 9.92861 12.6165 10.1575C12.7681 10.3864 12.8569 10.6511 12.8741 10.9251C12.8913 11.1991 12.8363 11.4728 12.7145 11.7188C12.5927 11.9649 12.4084 12.1746 12.1801 12.3271L9.43437 14.155C9.17738 14.3274 8.87493 14.4193 8.5655 14.4193ZM8.5655 9.39238C8.51814 9.39319 8.47188 9.40679 8.43162 9.43176C8.39429 9.45423 8.3635 9.48608 8.34231 9.52416C8.32113 9.56223 8.31029 9.60519 8.31087 9.64876V12.8513C8.31112 12.8973 8.32381 12.9424 8.3476 12.9818C8.37138 13.0213 8.40537 13.0535 8.44598 13.0752C8.48659 13.0969 8.5323 13.1072 8.57829 13.1051C8.62428 13.1029 8.66884 13.0884 8.70725 13.063L11.4521 11.2334C11.4899 11.2094 11.5204 11.1755 11.5403 11.1354C11.5602 11.0953 11.5688 11.0505 11.565 11.0059C11.5631 10.9609 11.549 10.9172 11.5241 10.8796C11.4993 10.842 11.4648 10.8119 11.4241 10.7924L8.67925 9.42126C8.64391 9.40259 8.60459 9.39268 8.56462 9.39238H8.5655Z"
          fill={fill}
        />
        <path
          d="M9.49999 4.90625C9.41379 4.90646 9.32842 4.88956 9.24881 4.85651C9.1692 4.82347 9.09694 4.77494 9.03624 4.71375L5.53624 1.21375C5.42032 1.08935 5.35721 0.924809 5.36021 0.754796C5.36321 0.584782 5.43208 0.42257 5.55232 0.302334C5.67255 0.182098 5.83477 0.113225 6.00478 0.110225C6.17479 0.107225 6.33933 0.170333 6.46374 0.286253L9.49999 3.32163L12.5362 0.286253C12.6606 0.170333 12.8252 0.107225 12.9952 0.110225C13.1652 0.113225 13.3274 0.182098 13.4477 0.302334C13.5679 0.42257 13.6368 0.584782 13.6398 0.754796C13.6428 0.924809 13.5797 1.08935 13.4637 1.21375L9.96374 4.71375C9.90303 4.77494 9.83078 4.82347 9.75117 4.85651C9.67156 4.88956 9.58618 4.90646 9.49999 4.90625Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

PowurPlayIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

PowurPlayIcon.defaultProps = {
  height: "20",
  width: "22",
  fill: "var(--royal-peacock-blue)",
};

export default PowurPlayIcon;
