import React from "react";

const EnterpriseJoinBanner = () => {
  return (
    <div
      style={{
        display: "inline-block",
        position: "relative",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        height="150"
        width="520"
        viewBox="210 178 520 101"
        style={{ position: "relative", width: "100%", bottom: 0 }}
      >
        <g filter="url(#filter0_d_4228_2926)" y="100">
          <path
            d="M263.87 269.069V283.735H210V216.637H262.624V231.303H228.788V242.613H258.598V256.8H228.788V269.069H263.87Z"
            fill="white"
          />
          <path
            d="M307.376 230.919C313.831 230.919 319.007 232.836 322.905 236.67C326.867 240.505 328.848 246.288 328.848 254.02V283.735H310.635V256.992C310.635 249.898 307.792 246.352 302.104 246.352C298.973 246.352 296.449 247.374 294.532 249.419C292.679 251.464 291.752 254.531 291.752 258.621V283.735H273.54V231.782H290.889V237.437C292.934 235.328 295.363 233.731 298.174 232.645C300.986 231.494 304.053 230.919 307.376 230.919Z"
            fill="white"
          />
          <path
            d="M376.132 281.626C374.598 282.585 372.713 283.32 370.476 283.831C368.304 284.342 365.971 284.598 363.479 284.598C356.705 284.598 351.497 282.936 347.855 279.613C344.212 276.29 342.391 271.338 342.391 264.756V247.31H334.818V233.699H342.391V220.183H360.603V233.699H372.489V247.31H360.603V264.564C360.603 266.417 361.083 267.887 362.041 268.973C363.064 269.996 364.406 270.507 366.067 270.507C368.176 270.507 370.029 269.964 371.627 268.878L376.132 281.626Z"
            fill="white"
          />
          <path
            d="M434.76 257.854C434.76 257.982 434.664 259.484 434.473 262.359H396.706C397.473 265.043 398.943 267.12 401.115 268.59C403.352 269.996 406.132 270.699 409.455 270.699C411.947 270.699 414.088 270.347 415.877 269.644C417.73 268.942 419.583 267.791 421.437 266.194L431.022 276.163C425.974 281.786 418.593 284.598 408.88 284.598C402.809 284.598 397.473 283.448 392.872 281.147C388.271 278.846 384.692 275.651 382.136 271.562C379.644 267.472 378.398 262.839 378.398 257.663C378.398 252.55 379.612 247.981 382.04 243.955C384.532 239.866 387.951 236.67 392.297 234.37C396.642 232.069 401.531 230.919 406.962 230.919C412.139 230.919 416.836 232.006 421.053 234.178C425.271 236.287 428.594 239.386 431.022 243.476C433.514 247.502 434.76 252.295 434.76 257.854ZM407.058 243.86C404.247 243.86 401.882 244.658 399.965 246.256C398.112 247.853 396.93 250.026 396.418 252.774H417.698C417.187 250.026 415.973 247.853 414.056 246.256C412.203 244.658 409.87 243.86 407.058 243.86Z"
            fill="white"
          />
          <path
            d="M460.507 238.012C462.36 235.648 464.757 233.891 467.696 232.74C470.7 231.526 474.118 230.919 477.953 230.919V247.31C476.291 247.119 474.917 247.023 473.831 247.023C469.933 247.023 466.865 248.077 464.629 250.186C462.456 252.295 461.37 255.522 461.37 259.867V283.735H443.157V231.782H460.507V238.012Z"
            fill="white"
          />
          <path
            d="M518.155 230.919C522.883 230.919 527.165 232.037 530.999 234.274C534.897 236.447 537.965 239.578 540.201 243.668C542.502 247.694 543.652 252.391 543.652 257.758C543.652 263.126 542.502 267.855 540.201 271.945C537.965 275.971 534.897 279.102 530.999 281.339C527.165 283.511 522.883 284.598 518.155 284.598C512.084 284.598 507.259 282.777 503.681 279.134V302.331H485.468V231.782H502.818V236.958C506.205 232.932 511.317 230.919 518.155 230.919ZM514.32 270.124C517.516 270.124 520.136 269.037 522.18 266.865C524.225 264.628 525.248 261.593 525.248 257.758C525.248 253.924 524.225 250.921 522.18 248.748C520.136 246.512 517.516 245.393 514.32 245.393C511.125 245.393 508.505 246.512 506.46 248.748C504.415 250.921 503.393 253.924 503.393 257.758C503.393 261.593 504.415 264.628 506.46 266.865C508.505 269.037 511.125 270.124 514.32 270.124Z"
            fill="white"
          />
          <path
            d="M569.467 238.012C571.32 235.648 573.716 233.891 576.656 232.74C579.659 231.526 583.078 230.919 586.912 230.919V247.31C585.251 247.119 583.877 247.023 582.791 247.023C578.892 247.023 575.825 248.077 573.588 250.186C571.416 252.295 570.329 255.522 570.329 259.867V283.735H552.117V231.782H569.467V238.012Z"
            fill="white"
          />
          <path
            d="M594.428 231.782H612.64V283.735H594.428V231.782ZM603.534 226.031C600.211 226.031 597.527 225.104 595.482 223.251C593.437 221.398 592.415 219.097 592.415 216.349C592.415 213.601 593.437 211.301 595.482 209.448C597.527 207.595 600.211 206.668 603.534 206.668C606.857 206.668 609.541 207.563 611.586 209.352C613.631 211.077 614.653 213.314 614.653 216.062C614.653 218.937 613.631 221.334 611.586 223.251C609.541 225.104 606.857 226.031 603.534 226.031Z"
            fill="white"
          />
          <path
            d="M643.359 284.598C639.014 284.598 634.732 284.118 630.515 283.16C626.297 282.137 622.91 280.827 620.354 279.23L625.818 266.769C628.182 268.239 630.994 269.421 634.253 270.315C637.512 271.146 640.739 271.562 643.935 271.562C646.874 271.562 648.951 271.274 650.165 270.699C651.443 270.06 652.082 269.165 652.082 268.015C652.082 266.865 651.315 266.066 649.782 265.619C648.312 265.107 645.947 264.628 642.688 264.181C638.535 263.67 634.988 262.999 632.049 262.168C629.173 261.337 626.681 259.803 624.572 257.567C622.463 255.33 621.409 252.199 621.409 248.173C621.409 244.85 622.399 241.911 624.38 239.354C626.361 236.734 629.237 234.689 633.007 233.22C636.841 231.686 641.41 230.919 646.714 230.919C650.485 230.919 654.223 231.303 657.929 232.069C661.636 232.836 664.735 233.923 667.227 235.328L661.763 247.694C657.162 245.138 652.178 243.86 646.81 243.86C643.935 243.86 641.826 244.211 640.484 244.914C639.142 245.553 638.471 246.416 638.471 247.502C638.471 248.716 639.206 249.579 640.675 250.09C642.145 250.537 644.574 251.017 647.96 251.528C652.242 252.167 655.789 252.902 658.6 253.733C661.412 254.563 663.84 256.097 665.885 258.334C667.994 260.506 669.048 263.574 669.048 267.536C669.048 270.795 668.058 273.734 666.077 276.354C664.096 278.91 661.156 280.923 657.258 282.393C653.424 283.863 648.791 284.598 643.359 284.598Z"
            fill="white"
          />
          <path
            d="M730 257.854C730 257.982 729.904 259.484 729.712 262.359H691.946C692.713 265.043 694.182 267.12 696.355 268.59C698.592 269.996 701.371 270.699 704.694 270.699C707.187 270.699 709.327 270.347 711.117 269.644C712.97 268.942 714.823 267.791 716.676 266.194L726.262 276.163C721.213 281.786 713.832 284.598 704.119 284.598C698.048 284.598 692.713 283.448 688.112 281.147C683.51 278.846 679.932 275.651 677.376 271.562C674.884 267.472 673.637 262.839 673.637 257.663C673.637 252.55 674.852 247.981 677.28 243.955C679.772 239.866 683.191 236.67 687.536 234.37C691.882 232.069 696.77 230.919 702.202 230.919C707.378 230.919 712.075 232.006 716.293 234.178C720.51 236.287 723.833 239.386 726.262 243.476C728.754 247.502 730 252.295 730 257.854ZM702.298 243.86C699.486 243.86 697.122 244.658 695.205 246.256C693.352 247.853 692.169 250.026 691.658 252.774H712.938C712.427 250.026 711.212 247.853 709.295 246.256C707.442 244.658 705.11 243.86 702.298 243.86Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter1_d_4228_2926)">
          <circle cx="603.63" cy="214.117" r="13.1169" fill="var(--canary-yellow)" />
        </g>
        <defs>
          <filter
            id="filter0_d_4228_2926"
            x="0"
            y="0.667969"
            width="940"
            height="515.664"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="105" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.983333 0 0 0 0 0.833333 0 0 0 0.45 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4228_2926" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4228_2926" result="shape" />
          </filter>
          <filter
            id="filter1_d_4228_2926"
            x="556.513"
            y="167"
            width="94.2339"
            height="94.2344"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="17" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.985802 0 0 0 0 1 0 0 0 0 0.808333 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4228_2926" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4228_2926" result="shape" />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default EnterpriseJoinBanner;
