import React from "react";
import styles from "../../Solar.module.less";

const Card = ({ title, imgSrc }) => {
  return (
    <div className={[styles.card, styles.bgDarkenZoomOnHover].join(" ")}>
      <div className={styles.bg} style={{ backgroundImage: `url(${imgSrc})` }}>
        <div className={styles.overlay} />
      </div>
      <h4 className={styles.cardTitle}>{title}</h4>
    </div>
  );
};

export default Card;
