import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getLeadDetails(action) {
  const { leadId, enterprise, admin, isLeadDrawer } = action.payload;
  const setterAction = isLeadDrawer ? actions.setLeadToProposalDrawerLeadDetails : actions.setLeadDetails;
  if (!leadId) {
    throw new Error(
      `Specified leadId ${leadId} is invalid, check the payload provided with the getLeadDetails action.`,
    );
  }
  const { success, data, error } = yield* makeRequest.get(`/leads/${leadId}`, {
    enterprise,
    admin,
  });
  if (success && data) {
    yield put(setterAction(camelizeKeys(data.body)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_LEAD_DETAILS,
    });
  }
  return null;
}

export default getLeadDetails;
