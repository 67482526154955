import SCREEN_SIZES from "../util/screenSizes";

const customMinMediaQuery = (minWidth: number) => `@media only screen and (min-width: ${minWidth}px)`;

const customMaxMediaQuery = (maxWidth: number) => `@media only screen and (max-width: ${maxWidth}px)`;

export const mq = {
  phoneOnly: customMinMediaQuery(SCREEN_SIZES.xs),
  tabletUp: customMinMediaQuery(SCREEN_SIZES.sm),
  tabletLandUp: customMinMediaQuery(SCREEN_SIZES.md),
  desktopUp: customMinMediaQuery(SCREEN_SIZES.lg),
  bigDesktopUp: customMinMediaQuery(SCREEN_SIZES.xl),
  superWideScreen: customMinMediaQuery(SCREEN_SIZES.xxl),
  smallPhoneDown: customMaxMediaQuery(SCREEN_SIZES.sml),
  tabletDown: customMaxMediaQuery(SCREEN_SIZES.sm),
};
