import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";

import {
  Avatar,
  // Button,
  notification,
} from "antd";
import User from "models/User";
import PowurLiveModal from "containers/Admin/platformAlerts/components/Modals/PowurLiveModal";
import ConferenceModal from "containers/Admin/platformAlerts/components/Modals/ConferenceModal";
import styles from "../PlatformPageHeader/PlatformPageHeader.module.less";

const InterstitialNotifications = (props) => {
  const { currentUser, setNoticeViewedByUser, adminNoticePreview, setAdminNoticePreview } = props;

  const [modalOpen, setModalOpen] = useState(true);

  // PREVIEW EFFECT:
  useEffect(() => {
    if (adminNoticePreview.notice_react_component) return;
    if (adminNoticePreview?.notice_type) {
      const { notice_body, notice_custom_icon, notice_title, notice_type } = adminNoticePreview;

      let noticeCustomIcon;
      if (notice_custom_icon) {
        noticeCustomIcon = <Avatar className={styles.avatar} shape="square" src={notice_custom_icon} />;
      } else {
        noticeCustomIcon = null;
      }
      const createBodyHTML = () => {
        return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notice_body) }} />;
      };
      openNotificationWithIcon(
        "preview",
        notice_type,
        <>
          <h3>{notice_title}</h3>
        </>,
        createBodyHTML(),
        noticeCustomIcon,
      );
    }
  });
  // }, [adminNoticePreview]);

  // NOTICES EFFECT:
  useEffect(() => {
    if (currentUser.notices) {
      currentUser.notices.forEach((element) => {
        if (element.notice_react_component) return;
        let noticeCustomIcon;
        if (element.notice_custom_icon) {
          noticeCustomIcon = <Avatar className={styles.avatar} shape="square" src={element.notice_custom_icon} />;
        } else {
          noticeCustomIcon = null;
        }

        const createBodyHTML = () => {
          return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element.notice_body) }} />;
        };

        openNotificationWithIcon(
          element.id,
          element.notice_type,
          <>
            <h3>{element.notice_title}</h3>
            <hr />
          </>,
          createBodyHTML(),
          noticeCustomIcon,
        );
      });
    }
  });
  // }, [currentUser.notices]);

  // NOTICE OPENING:
  const openNotificationWithIcon = (noticeId, noticeType, noticeTitle, noticeBody, noticeCustomIcon) => {
    const key = noticeId;
    // add click listener
    document.addEventListener("click", (e) => {
      const iNotificationContainer = document.getElementsByClassName("interstitialNotification");
      if (iNotificationContainer) {
        if (!iNotificationContainer[0].contains(e.target)) {
          closeNotification(key);
        }
      }
    });
    notification[noticeType]({
      // message text should probably be limited to about 65 characters for cell size.
      message: noticeTitle,
      // description text should probably be limited to about 1130 characters for cell size.
      description: noticeBody,
      className: "interstitialNotification",
      // btn,
      key,
      duration: 0,
      icon: noticeCustomIcon || null,
      onClose: () => closeNotification(key),
      style: {
        position: "fixed",
        width: "98vw",
        maxWidth: "862px",
        // height: "563px",
        // maxHeight: "763px",
        maxHeight: "100vh",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        boxShadow: "0px 2px 48px rgba(0, 0, 0, 0.2)",
        borderRadius: "12px",
        backgroundColor: "rgba(255, 255, 255, .99)",
        overflow: "auto",
      },
    });
  };

  // CLOSE NOTICE HANDLER:
  const closeNotification = (key) => {
    if (key === "preview") {
      notification.close(key);
      setAdminNoticePreview({});
    } else {
      const { id } = currentUser;
      const payload = {
        key,
        id,
      };
      notification.close(key);
      setNoticeViewedByUser(payload);
    }
  };

  // CUSTOM COMPONENT
  const components = ["powurLiveModal", "conferenceModal"];
  const currentComponent = currentUser?.notices?.find((element) => element.notice_react_component);

  const isValidComponent =
    (adminNoticePreview?.notice_react_component && components.includes(adminNoticePreview.notice_react_component)) ||
    (currentComponent && components.includes(currentComponent.notice_react_component));

  if (isValidComponent) {
    if (adminNoticePreview?.notice_react_component === "powurLiveModal") {
      return (
        <PowurLiveModal
          title={adminNoticePreview?.notice_title || currentComponent?.notice_title}
          user={currentUser}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          onClose={
            adminNoticePreview?.notice_react_component ? () => null : () => closeNotification(currentComponent.id)
          }
        />
      );
    }

    if (currentComponent?.notice_react_component === "conferenceModal") {
      return (
        <ConferenceModal
          title={adminNoticePreview?.notice_title || currentComponent?.notice_title}
          user={currentUser}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          onClose={
            adminNoticePreview?.notice_react_component ? () => null : () => closeNotification(currentComponent.id)
          }
        />
      );
    }
  }

  return null;
};

InterstitialNotifications.propTypes = {
  currentUser: User.types(),
  setNoticeViewedByUser: PropTypes.func,
  // TODO: enumerate adminNoticePreview properties
  adminNoticePreview: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  setAdminNoticePreview: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    currentUser: "getCurrentUser",
    adminNoticePreview: "getAdminNoticePreview",
  }),
  getRdxActionMapper(["setNoticeViewedByUser", "setAdminNoticePreview"]),
)(InterstitialNotifications);
