import { put } from "redux-saga/effects";
import { camelizeKeys } from "lib/helpers";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/filterSets/messages";

function* getSelectedLookupFilters(action) {
  const params = action.payload;
  const { success, data, error } = yield* makeRequest.post("/filter_sets/selected_lookup_filters", params);

  if (success && data) {
    const formattedData = {
      people_ids:
        data.body?.people?.length > 0
          ? camelizeKeys(data.body?.people.map((el) => ({ id: el.id, label: el.full_name, value: el })))
          : null,
      lead_source_ids:
        data.body?.people?.length > 0
          ? camelizeKeys(data.body?.people.map((el) => ({ id: el.id, label: el.full_name, value: el })))
          : null,
      lead_owner_ids:
        data.body?.people?.length > 0
          ? camelizeKeys(data.body?.people.map((el) => ({ id: el.id, label: el.full_name, value: el })))
          : null,
      mentor_ids:
        data.body?.people?.length > 0
          ? camelizeKeys(data.body?.people.map((el) => ({ id: el.id, label: el.full_name, value: el })))
          : null,
      utility:
        data.body?.utilities?.length > 0
          ? camelizeKeys(
              data.body?.utilities.map((el) => ({
                id: el.display_name,
                label: el.display_name,
                value: { id: el.display_name, name: el.display_name },
              })),
            )
          : null,
      installer_ids:
        data.body?.installers?.length > 0
          ? camelizeKeys(
              data.body?.installers.map((el) => ({
                id: el.id,
                label: el.name,
                value: el,
              })),
            )
          : null,
      team:
        data.body?.teams?.length > 0
          ? camelizeKeys(
              data.body?.teams.map((el) => ({
                ...el,
                value: { id: el.id, name: el.label },
              })),
            )
          : null,
      city_name:
        data.body?.cities?.length > 0
          ? camelizeKeys(
              data.body?.cities.map((el) => ({
                id: el?.id,
                label: el?.id,
                value: el,
              })),
            )
          : null,
    };

    if (hasNonNullProperties(formattedData)) {
      yield put(actions.updatePreferredViewBulk(formattedData));
    }
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GET_LOOKUP_FILTERS,
    });
  }
  return null;
}

function hasNonNullProperties(formattedData) {
  return Object.values(formattedData).some((value) => value !== null);
}

export default getSelectedLookupFilters;
