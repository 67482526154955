import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "rdx/modules/users/messages";

function* certifyUser(action) {
  const { userId, seatId } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/users/${userId}/certify`, { seat_id: seatId });

  if (success && data) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.ACTIVATE_USER_ENROLLMENT_SUCCESS,
        }),
      ),
      put(
        actions.setDetailInUserDetails({
          certified: keysToCamelCase(data.body.certified),
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_ACTIVATING_USER_ENROLLMENT,
    });
  }

  return null;
}

export default certifyUser;
