import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/earnings/types";
import getEarningsHistory from "./getEarnings";
import getSellerOrLeadAutoComplete from "./getSellerOrLeadAutoComplete";
import getThisMonthEarnings from "./getThisMonthEarnings";
import getMonthlyBreakdown12Mos from "./getMonthlyBreakdown12Mos";
import getMonthlyBreakdown from "./getMonthlyBreakdown";
// IMPORT_PT (for script, do not remove)

function* watchEarningsSagas() {
  yield trackRequests(takeEvery, types.GET_EARNINGS_HISTORY_REQUEST, getEarningsHistory);
  yield trackRequests(takeEvery, types.GET_CONSULTANT_OR_LEAD_AUTO_COMPLETE, getSellerOrLeadAutoComplete);
  yield trackRequests(takeEvery, types.GET_THIS_MONTH_EARNINGS, getThisMonthEarnings);
  yield trackRequests(takeEvery, types.GET_MONTHLY_BREAKDOWN_12_MOS, getMonthlyBreakdown12Mos);
  yield trackRequests(takeEvery, types.GET_MONTHLY_BREAKDOWN, getMonthlyBreakdown);
  // INSERTION_PT (for script, do not remove)
}

export default watchEarningsSagas;
