import React from "react";
import { z } from "zod";

import * as S from "./styles";

export const radioButtonContent = [
  {
    question: "When meeting with you and your homeowner which do you prefer?",
    title: "Homeowner Meet",
    name: "apptLocation",
    answersLong: [
      {
        value: "",
        label: <S.RadioButtonLabel>No Preference</S.RadioButtonLabel>,
        labelSubtext: (
          <S.RadioButtonSubLabel>
            My Closer can be remote or in person. I want the one that&apos;s right for me.
          </S.RadioButtonSubLabel>
        ),
      },
      {
        value: "in_person",
        label: <S.RadioButtonLabel>In Person</S.RadioButtonLabel>,
        labelSubtext: <S.RadioButtonSubLabel>On-site with you and the homeowner.</S.RadioButtonSubLabel>,
      },
      {
        value: "online",
        label: <S.RadioButtonLabel>Virtual</S.RadioButtonLabel>,
        labelSubtext: (
          <S.RadioButtonSubLabel>You and/or your homeowner prefer a “no contact” presentation.</S.RadioButtonSubLabel>
        ),
      },
    ],
    answersShort: [
      {
        value: "",
        label: "No Preference",
      },
      {
        value: "in_person",
        label: "In Person",
      },
      {
        value: "online",
        label: "Virtual",
      },
    ],
  },
  {
    question: "Your Closer's primary language?",
    title: "Language",
    name: "spokenLanguages",
    answersLong: [
      {
        value: "",
        label: <S.RadioButtonLabel>No Preference</S.RadioButtonLabel>,
      },
      {
        value: "en",
        label: <S.RadioButtonLabel>English</S.RadioButtonLabel>,
      },
      {
        value: "es",
        label: <S.RadioButtonLabel>Spanish</S.RadioButtonLabel>,
      },
    ],
    answersShort: [
      {
        value: "",
        label: "No Preference",
      },
      {
        value: "en",
        label: "English",
      },
      {
        value: "es",
        label: "Spanish",
      },
    ],
  },
  {
    question: "How wide do you want to cast your net?",
    title: "User Search",
    name: "teamSearch",
    answersLong: [
      {
        value: "",
        label: <S.RadioButtonLabel>Search All Closers</S.RadioButtonLabel>,
        labelSubtext: (
          <S.RadioButtonSubLabel>
            Find my best match by searching all Powur&apos;s Closers on the platform.
          </S.RadioButtonSubLabel>
        ),
      },
      {
        value: "upline",
        label: <S.RadioButtonLabel>My Leadership Team</S.RadioButtonLabel>,
        labelSubtext: (
          <S.RadioButtonSubLabel>
            Restrict my search results to only Closers from within my leadership team.
          </S.RadioButtonSubLabel>
        ),
      },
      {
        value: "downline",
        label: <S.RadioButtonLabel>My Selling Team</S.RadioButtonLabel>,
        labelSubtext: (
          <S.RadioButtonSubLabel>
            Restrict my search to only show eligible, non-leadership sellers on my team.
          </S.RadioButtonSubLabel>
        ),
      },
      {
        value: "both",
        label: <S.RadioButtonLabel>My Whole Team</S.RadioButtonLabel>,
        labelSubtext: (
          <S.RadioButtonSubLabel>
            Restrict my search to only show all eligible sellers and leaders on my team.
          </S.RadioButtonSubLabel>
        ),
      },
    ],
    answersShort: [
      {
        value: "",
        label: "Search All Closers",
      },
      {
        value: "upline",
        label: "My Leadership Team",
      },
      {
        value: "downline",
        label: "My Selling Team",
      },
      {
        value: "both",
        label: "My Whole Team",
      },
    ],
  },
];

export const schema = z.object({
  apptLocation: z.string(),
  spokenLanguages: z.string(),
  teamSearch: z.string(),
});

export type PowurPairFormSchemaT = z.infer<typeof schema>;

export const blankFormData = {
  apptLocation: "",
  spokenLanguages: "",
  teamSearch: "",
};
