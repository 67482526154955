import { styled } from "@mui/material/styles";

import { theme } from "styles/themes";
import { mq } from "styles";

export const HeaderBanner = styled("div")(() => ({
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  fontFamily: "Poppins",
  fontWeight: 800,
  fontSize: "30px",
  justifyContent: "center",
  alignItems: "center",
  color: theme.colors.white,
  backgroundImage: `url("/img/powur_pair_lines.png"), linear-gradient(107deg, ${theme.colors["congress-blue"]} 2.67%, ${theme.colors["blue-ribbon"]} 46.33%, rgba(81, 242, 203, 0.83) 107.46%)`,
  backgroundRepeat: "no-repeat",
  marginBottom: "24px",
  padding: "16px",
  margin: "5px",
}));

export const BannerIconContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "39px",
  height: "39px",
  borderRadius: "50%",
  background: theme.colors.white,
}));

export const BannerText = styled("div")(() => ({
  textAlign: "center",
  lineHeight: "30px",
  margin: "12px",
  [mq.phoneOnly]: {
    marginTop: "8px",
  },
}));
