import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "rdx/modules/compensation/messages";

function* getProjectRevenueSplit(action) {
  const { success, data, error } = yield* makeRequest.get("/compensation/payment_split", {
    provider_lead_id: action.payload.id,
  });

  if (success && data) {
    yield put(actions.setProjectRevenueSplit(camelizeKeys(data.body)));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROJECT_REVENUE_SPLIT,
    });
  }

  return null;
}

export default getProjectRevenueSplit;
