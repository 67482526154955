import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const RightCaretIcon = ({ width = 15, height = 15, fill = cvar("blue-ribbon") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.625 3.23828L9.375 6.98828L5.625 10.7383"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RightCaretIcon;
