import React, { useEffect, useContext, useMemo } from "react";
// import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { buttonProps } from "lib/props";
import { actions } from "rdx";
import msgs from "rdx/modules/ambassador/messages";
import { AmbassadorDashboardContext as context } from "containers/Ambassador/dashboard";
import StripeIcon from "components/Icons/StripeIcon";
import BankingTable from "./bankingTable";

const PayoutsTab = () => {
  const { navigate, tabKeys, user, leads, redirecting, getStripeSignupUrl, latestMessage, styles } =
    useContext(context);
  const dispatch = useDispatch();

  useEffect(() => {
    if (redirecting) {
      dispatch(
        actions.setAlertMessageVisible({
          message: "Redirecting to Stripe. . .",
          severity: "info",
          duration: 6000,
        }),
      );
    } else {
      dispatch(actions.clearAlertMessage());
    }
  }, [redirecting]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      latestMessage &&
      (latestMessage.message === msgs.GET_STRIPE_SIGNUP_URL_SUCCESS ||
        latestMessage.message === msgs.GET_STRIPE_LOGIN_URL_SUCCESS)
    ) {
      window.location.href = latestMessage.payload.url;
    }
  }, [latestMessage]);

  const handleStripeClick = () => {
    getStripeSignupUrl();
  };

  const installs = useMemo(() => leads.filter((lead) => lead.progress[4].past).length, [leads]);

  return (
    <div className={styles.payoutsTab}>
      {!!installs && !user.props?.stripeOauthId && (
        <div className={styles.prompt}>
          <span>
            {" "}
            <ExclamationCircleOutlined /> You have payouts available!
          </span>{" "}
          <br />
          <span>Set up your stripe account now to recieve payouts.</span>
        </div>
      )}
      <div className={styles.banner}>Payment Information</div>
      {user.props?.stripeOauthId ? (
        <>
          <div className={styles.instructions}>
            Here are the details for the account you provided. All future payouts will be made to the associated
            account.
          </div>
          <BankingTable />
        </>
      ) : (
        <>
          <div className={styles.instructions}>We use Stripe Connect in order to distribute your earnings to you.</div>
          <Button className={styles.stripeButton} type="primary" {...buttonProps(handleStripeClick)}>
            Set up your account
          </Button>
          <div className={styles.stripe}>
            <div className={styles.text}>Powered by</div>
            <StripeIcon />
          </div>
        </>
      )}
      <div className={styles.back} {...buttonProps(() => navigate({ tab: tabKeys[0] }))}>
        Back to Dashboard
      </div>
    </div>
  );
};

export default PayoutsTab;
