import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Spin } from "antd";
import RoundBadge from "./components/RoundBadge";
import Header from "./components/Header";

import styles from "./CreateAccount.module.less";

const DesktopCard = ({ dataLoaded, tabData, handlePreviousTabClick, activeIndex }) => {
  if (!dataLoaded) {
    return (
      <div className={styles.joinPowurDesktopContainer}>
        <Row className={styles.joinPowurDesktopCard}>
          <div className={styles.registrationLoading}>
            <Spin />
          </div>
        </Row>
      </div>
    );
  }
  return (
    <div className={styles.joinPowurDesktopContainer}>
      <Row className={styles.joinPowurDesktopCard}>
        {tabData[activeIndex].displayTabImg ? (
          <>
            <Col span={13} className={styles.createAccountContent}>
              <Header activeIndex={activeIndex} data={tabData} handlePreviousTabClick={handlePreviousTabClick} />
              {tabData[activeIndex].component}
            </Col>

            <Col span={11} className={styles.photoContainer}>
              <img
                className={styles.photo}
                style={tabData[activeIndex].tabImgStyle}
                src={tabData[activeIndex].tabImg}
                alt={tabData[activeIndex].tabImgAlt}
              />
            </Col>
          </>
        ) : (
          <div className={styles.joinPowurTermsContainer}>
            <Header activeIndex={activeIndex} data={tabData} handlePreviousTabClick={handlePreviousTabClick} />
            {tabData[activeIndex].component}
          </div>
        )}
      </Row>
      {tabData[activeIndex].displayBadgeImg && (
        <div className={styles.joinPowurDesktopBadge}>
          <RoundBadge
            imgSource={tabData[activeIndex].badgeImg}
            imgAlt={tabData[activeIndex].badgeImgAlt}
            imgStyle={tabData[activeIndex].badgeImgStyle}
          />
        </div>
      )}
    </div>
  );
};

DesktopCard.propTypes = {
  tabData: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.string,
      title: PropTypes.string,
      tabImg: PropTypes.string,
      tabImgAlt: PropTypes.string,
      tabImgStyle: PropTypes.shape({
        objectPosition: PropTypes.string,
      }),
      displayTabImg: PropTypes.bool,
      badgeImg: PropTypes.string,
      badgeImgStyle: PropTypes.shape({
        objectPosition: PropTypes.string,
      }),
      badgeImgAlt: PropTypes.string,
      displayBadgeImg: PropTypes.bool,
      component: PropTypes.node,
    }),
  ),
  handlePreviousTabClick: PropTypes.func,
  activeIndex: PropTypes.number,
  dataLoaded: PropTypes.bool,
};

export default DesktopCard;
