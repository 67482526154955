// import content from "containers/Admin/dashboard";
import { DashboardIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";

const content = l(() => import("containers/Admin/dashboard"));

const { ADMIN_DASHBOARD_VIEW } = permissionTypes;

export default {
  key: "admin-dashboard",
  title: "Admin Dashboard",
  path: "/admin-dashboard/:tab?",
  linkTarget: "/admin-dashboard",
  linkTitle: "Dashboard",
  linkCategory: "Admin",
  permission: [ADMIN_DASHBOARD_VIEW],
  icon: DashboardIcon,
  basePath: "admin-dashboard",
  requiredParams: [],
  optionalParams: [],
  tabs: null,
  content,
  sidebar: null,
};
