import makeRequest from "rdx/utils/makeRequest";
import { put } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* deleteEnterpriseAsset(action) {
  const { assetId, ownerId } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/enterprise_assets/${assetId}`, {
    enterprise_id: ownerId,
  });

  if (success && data) {
    yield put(actions.setEnterpriseAssets(data.body.assets));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETE_ENTERPRISE_ASSET,
    });
  }

  return null;
}

export default deleteEnterpriseAsset;
