import { put, all } from "redux-saga/effects";

import actions from "rdx/actions";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getSelectedMessage(action) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.get(`/messages/${id}`);

  if (success && data) {
    const message = camelizeKeys(data.body);
    yield all([
      put(actions.setSelectedMessageDetails(message)),
      !message.viewed &&
        put(
          actions.markMessageAndRepliesAsViewed({
            messageId: message.id,
            // To determine correct inbox badge to update, adding archive status to payload: ~~NH
            archived: message.archived,
          }),
        ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_MESSAGE_DETAILS,
    });
  }
  return null;
}

export default getSelectedMessage;
