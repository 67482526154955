// Jan 1  12:30 PM
import userTimezone from "./userTimezone";

export default function formatDateTimeLong(dateString) {
  const timeZone = userTimezone();

  const date = new Date(dateString);
  const rawDay = new Date(dateString).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    timeZone,
  });

  const today = new Date().toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    timeZone,
  });

  const yesterday = new Date(Date.now() - 86400000).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    timeZone,
  });

  const day = () => {
    if (rawDay === today) return "Today";
    return rawDay === yesterday ? "Yesterday" : rawDay;
  };

  const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();

  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

  const meridiem = date.getHours() >= 12 ? "PM" : "AM";

  return `${day()}${"\xa0\xa0"}${hours}:${minutes} ${meridiem}`;
}
