import React, { useMemo, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import selectors from "rdx/selectors";

import { Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";

import SessionClient from "util/SessionClient";

import usePathMatcher from "hooks/usePathMatcher";
import useRouteMatcher from "hooks/useRouteMatcher";
import useGetCurrentRouteInfo from "hooks/useGetCurrentRouteInfo";

import styles from "components/PlatformLayout/components/PlatformPageHeader/PlatformPageHeader.module.less";

const { Title } = Typography;

const ProjectBreadCrumbs = () => {
  const projectDetails = useSelector(selectors.getProjectDetails);
  const adminDetails = useSelector(selectors.getAdminProjectDetails);

  const params = useParams();
  const history = useHistory();
  const { isEnterprise, isAdmin, isProjects } = useRouteMatcher();
  const { projectPath } = usePathMatcher();

  const session = new SessionClient();

  const { currentRoute } = useGetCurrentRouteInfo();
  const currentTab = currentRoute?.tabs?.find((tab) => tab.key === params.tab) || null;

  const suffix = useCallback(() => {
    if (isEnterprise) {
      return `/${projectDetails.providerLead?.tab}`;
    }
    if (isAdmin) {
      if (adminDetails.closeAt) return "/closed";
      if (adminDetails.projectType) return `/${adminDetails.projectType.replace(/_/g, "-")}`;
    }
    return "";
  }, [isEnterprise, projectDetails, adminDetails, isAdmin]);

  const projectType = () => {
    if (isEnterprise) {
      return session?.user?.props?.enterpriseName;
    }
    return isAdmin ? "Admin" : "";
  };

  const handleBaseNavigate = useCallback(() => {
    history.push(`${projectPath}${suffix()}`);
  }, [history, suffix, projectPath]);

  const handleDetailNavigate = useCallback(() => {
    history.push(`${projectPath}/${isAdmin ? params.projectId : params.leadId}/summary`);
  }, [isAdmin, history, params, projectPath]);

  const projectInfo = useMemo(() => {
    if (params.leadId || params.projectId) {
      if (isEnterprise && projectDetails?.lead?.fullName) {
        return `${projectDetails.lead.fullName} - ${params.leadId}`;
      }
      if (isAdmin && adminDetails?.lead?.fullName) {
        return `${adminDetails.lead.fullName} - ${params.projectId}`;
      }
      if (isProjects && projectDetails?.lead?.fullName) {
        return `${projectDetails?.lead?.fullName} - ${params.leadId}`;
      }
    }
    return null;
  }, [params.leadId, params.projectId, isProjects, isAdmin, isEnterprise, projectDetails, adminDetails]);

  const tabTitle = () => {
    if (params.tab === "list") return "Projects";
    if (params.tab === "installs") return "Installed";
    return params.tab;
  };

  const textStyle = {
    cursor: "pointer",
    textTransform: "capitalize",
    fontWeight: 600,
  };

  return (
    <>
      <Title className={styles.title} style={textStyle} onClick={handleBaseNavigate}>
        {projectType()} Projects
      </Title>
      {!params.leadId && !params.projectId && (
        <>
          <RightOutlined className={styles.titleCaret} style={{ margin: "0 10px" }} />
          <Title className={styles.title} style={textStyle} onClick={handleBaseNavigate}>
            {tabTitle()}
          </Title>
        </>
      )}
      {projectInfo && (
        <>
          <RightOutlined className={styles.title} style={{ margin: "0 10px" }} />
          <Title
            data-test-id="ProjectBreadCrumbs-name"
            className={styles.title}
            style={textStyle}
            onClick={handleDetailNavigate}
          >
            {projectInfo}
          </Title>
          <RightOutlined className={styles.title} style={{ margin: "0 10px" }} />
          <Title
            data-test-id="ProjectBreadCrumbs-tab"
            className={styles.title}
            style={{ ...textStyle, cursor: "default" }}
          >
            {currentTab?.breadcrumbLabel || currentTab?.label}
          </Title>
        </>
      )}
    </>
  );
};

export default ProjectBreadCrumbs;
