import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Tooltip, Avatar, Grid } from "@mui/material";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { selectors } from "rdx";
import { Tooltip as CustomTooltip } from "components/mui-styled-components/Tooltip";
import UserAvatar from "components/UserCards/UserAvatar";
import { PrimaryButton } from "components/mui-styled-components";
import { CheckWithShadowIcon } from "components/Icons";

import MentorStars from "components/UserCards/Mentor/MentorStars";
import { cvar } from "styles";
import { PowurPairT, T3PartnerT } from "../../types";
import * as S from "./styles";

type PowurPairCardPropsT = {
  mentor: PowurPairT;
  selectedT3Partner: T3PartnerT | null;
  setSelectedT3Partner: (t3Partner: PowurPairT | null) => void;
  setOpenFooter: (bool: boolean) => void;
};

type MatchMetricsDataT = {
  data: string;
  detail: string;
};

const PowurPairCard = ({ mentor, selectedT3Partner, setSelectedT3Partner, setOpenFooter }: PowurPairCardPropsT) => {
  const [relationship, setRelationship] = useState<string | null>(null);
  const [languages, setLanguages] = useState<string | null>(null);
  const [matchMetrics, setMatchMetrics] = useState<MatchMetricsDataT[]>([]);
  const currentUser = useSelector(selectors.getCurrentUser);
  const isThisPartnerSelected = selectedT3Partner && selectedT3Partner.id === mentor.id;
  const { sponsor } = currentUser;

  useEffect(() => {
    if (sponsor?.id === mentor.id) {
      setRelationship("Your Sponsor");
    } else if (currentUser.sponsors.includes(mentor.id)) {
      setRelationship("Your Leadership Team");
    }
  }, [currentUser, mentor]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const mentorLanguages = mentor.spokenLanguages.map((lang) => {
      return lang === "en" ? "English" : "Spanish";
    });
    setLanguages(mentorLanguages.join(", "));
    if (mentor?.powurPairData) {
      const matchMetricData = Object.fromEntries(mentor?.powurPairData?.map((item) => [item.slug, item.value]));
      createMetricsContent(matchMetricData, mentor);
    }
  }, [mentor]); // eslint-disable-line react-hooks/exhaustive-deps

  const metricsContent = useMemo(() => {
    return (
      <>
        <S.TooltipTitle>Matching Metrics</S.TooltipTitle>
        {matchMetrics.map((metric, index) => (
          <S.MetricTooltipRow container evenRow={index % 2 === 0} key={metric.data}>
            <S.MetricTooltipData
              item
              xs={2}
              negativeStyle={metric.detail === "Mentored Cancellation Rate (6mos.)" && metric.data !== "0%"}
            >
              {metric.data.replace("~", "")}
            </S.MetricTooltipData>
            <S.MetricTooltipDetail xs={10}>{metric.detail}</S.MetricTooltipDetail>
          </S.MetricTooltipRow>
        ))}
      </>
    );
  }, [matchMetrics]);

  const formatMetricToPercent = (metric: string | null, negativeValue = false): string => {
    if (negativeValue && metric) {
      return (1 - parseFloat(metric)).toLocaleString("en", {
        style: "percent",
      });
    }
    if (metric) {
      return parseFloat(metric).toLocaleString("en", {
        style: "percent",
      });
    }
    return "-";
  };

  const createMetricsContent = (metrics: Record<string, string | number>, user: PowurPairT): void => {
    setMatchMetrics([
      {
        data: metrics.last_6m_personal_conversion_rate
          ? formatMetricToPercent(metrics.last_6m_personal_conversion_rate.toString())
          : "-",
        detail: "Conversion Rate (6mos.)",
      },
      {
        data: metrics.last_6m_mentored_conversion_rate
          ? formatMetricToPercent(metrics.last_6m_mentored_conversion_rate.toString())
          : "-",
        detail: "Sales Partner Conversion Rate (6mos.)",
      },
      {
        data: metrics.last_6m_mentor_non_cancel_rate
          ? formatMetricToPercent(metrics.last_6m_mentor_non_cancel_rate.toString(), true)
          : "-",
        detail: "Sales Partner Cancellation Rate (6mos.)",
      },
      {
        data: metrics.utility_count ? metrics.utility_count.toString() : "-",
        detail: "Projects with Utility Company",
      },
      {
        data: metrics.utility_conversion_rate ? formatMetricToPercent(metrics.utility_conversion_rate.toString()) : "-",
        detail: "Utility Success Rate",
      },
      {
        data: metrics.state_count ? metrics.state_count.toString() : "-",
        detail: "Projects in Homeowner's State",
      },
      {
        data: metrics.state_conversion_rate ? formatMetricToPercent(metrics.state_conversion_rate.toString()) : "-",
        detail: "Homeowner's State Success Rate",
      },
      {
        data: user.mentorRatings?.meanOfAvgs ? parseFloat(user.mentorRatings.meanOfAvgs).toFixed(1) : "-",
        detail: "Sales Partner Rating",
      },
    ]);
  };

  const handleT3PartnerSelect = () => {
    setSelectedT3Partner(mentor);
    setOpenFooter(true);
  };

  const handleT3PartnerDeselect = () => {
    setSelectedT3Partner(null);
    setOpenFooter(false);
  };

  if (!mentor) return null;
  return (
    <S.PowurPairCard>
      <S.PowurPairCardHeader>
        <Tooltip
          placement="bottom-end"
          title={metricsContent}
          enterTouchDelay={100}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: cvar("white"),
                padding: "0px",
                boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.10)",
              },
            },
          }}
        >
          <S.TooltipIconContainer>
            <ExclamationCircleOutlined />
          </S.TooltipIconContainer>
        </Tooltip>
        {mentor.roofingCertifed && (
          <CustomTooltip title="Roofing Certified" placement="left">
            <Avatar
              sx={{ width: "21px", height: "21px" }}
              src="/img/achievements/roofing_certified_no_shadow.svg"
              variant="square"
            />
          </CustomTooltip>
        )}
      </S.PowurPairCardHeader>
      <Grid container>
        <S.MatchRelationship item xs={12}>
          {relationship}
        </S.MatchRelationship>
        <S.MatchImage item xs={6} md={12}>
          <UserAvatar user={mentor} size={108} />
        </S.MatchImage>
        <S.MatchDetailsContainer item xs={6} md={12}>
          <S.MatchName>{mentor.fullName}</S.MatchName>
          <S.MatchLocation>
            {mentor.address.city}, {mentor.address.state}
          </S.MatchLocation>
          <S.MentorStarsContainer>
            <MentorStars average={mentor.mentorRatings?.meanOfAvgs} count={mentor.mentorRatings?.totalCount} />
          </S.MentorStarsContainer>
          <S.LanguageLabel>Language</S.LanguageLabel>
          <S.MatchLang>{languages}</S.MatchLang>
        </S.MatchDetailsContainer>
        <S.ButtonContainer item xs={12}>
          {isThisPartnerSelected ? (
            <PrimaryButton
              gradient
              onClick={handleT3PartnerDeselect}
              style={{ fontSize: "12px", width: "180px", display: "flex" }}
            >
              <S.IconContainer>
                <CheckWithShadowIcon width="12" height="12" />
              </S.IconContainer>
              <div>Selected</div>
            </PrimaryButton>
          ) : (
            <PrimaryButton
              onClick={handleT3PartnerSelect}
              gradientOutlined
              style={{ fontSize: "12px", width: "180px" }}
            >
              Select this Closer
            </PrimaryButton>
          )}
        </S.ButtonContainer>
      </Grid>
    </S.PowurPairCard>
  );
};

export default PowurPairCard;
