import type { CrmActivityT, PreSalesActivityT } from "types/Project/crmActivity";

const formatPreSalesCrmActivity = (activity: CrmActivityT): PreSalesActivityT => {
  return {
    archivedAt: activity.archivedAt,
    completeType: activity.completeType,
    completedAt: activity.completedAt,
    createdAt: activity.createdAt,
    due: activity.due,
    email: activity.email,
    id: activity.id,
    kind: activity.kind,
    leadId: activity.leadInfo?.id,
    location: activity.location,
    meetingInfo: activity.meetingInfo,
    meetingType: activity.meetingType,
    notes: activity.notes,
    reminder: activity.reminder,
    reminderType: activity.reminderType,
    title: activity.title,
    updatedAt: activity.updatedAt,
  };
};

export default formatPreSalesCrmActivity;
