import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  leadsList: {
    leads: [],
    paging: {
      page_size: 0,
      page_number: 1,
      item_count: 0,
    },
    event_counts: [],
    kpi: [],
  },
  leadDetails: {},
};

export default {
  enterpriseLeads: createReducer(initialState, {
    [types.SET_ENTERPRISE_LEADS](state, action) {
      return {
        ...state,
        leadsList: {
          ...state.leads,
          ...action.payload,
        },
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
