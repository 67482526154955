import { select, put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import selectors from "rdx/selectors";
import actions from "rdx/actions";
import messages from "../messages";

function* deleteDashboardUpdate(action) {
  const { id } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/dashboard_updates/${id}`, {});

  if (success && data) {
    const list = yield select(selectors.selectDashboardUpdates);
    const out = list.updates.filter((item) => {
      return item.id !== id;
    });

    yield all([
      put(actions.setDashboardUpdates({ ...list, updates: out })),

      put(
        actions.newSuccessEvent({
          message: messages.DELETE_DASHBOARD_UPDATE_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.GET_DASHBOARD_UPDATE_ERROR,
    });
  }
  return null;
}

export default deleteDashboardUpdate;
