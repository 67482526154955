export default {
  // general
  NEW_TEAM_MEMBER_INVITE_SUCCESS: "NEW_TEAM_MEMBER_INVITE_SUCCESS",
  generateTeamMemberInviteMsg: (email) => `Powur Enterprise invitation sent to ${email}`,
  UPDATE_ENTERPRISE_USER_SETTINGS_SUCCESS: "Enterprise user settings updated.",
  UPDATE_ENTERPRISE_SEAT_PREVIEW_SUCCESS: "Enterprise user seat change preview retrieved.",
  ENTERPRISE_SEAT_CHANGE_SUCCESS: "Enterprise user roles and services updated.",
  REJECT_SEAT_INVITE_SUCCESS: "Seat invite rejected.",
  ACCEPT_SEAT_INVITE_SUCCESS: "Seat invite accepted.",
  UPDATE_ENTERPRISE_USER_ROLES_SUCCESS: "Enterprise user roles updated.",

  // error
  ERROR_GETTING_ORG_TEAM_USERS: "There was an error retrieving users list",
  ERROR_GETTING_ORG_USER_DOWNLINE: "There was an error retrieving users' level ones",
  ERROR_GETTING_ASSIGNABLE_SERVICES: "There was an error retrieving Enterprise services",
  ERROR_SENDING_NEW_TEAM_MEMBER_INVITE: "There was an error sending team member invite",
  ERROR_GETTING_ENTERPRISE_USER_DETAILS: "There was an error fetching user information",
  ERROR_GETTING_PENDING_SEAT: "There was an error fetching pending seat",
  ERROR_UPDATING_ENTERPRISE_USER_SETTINGS: "There was an error updating user settings",
  ERROR_UPDATING_ENTERPRISE_USER_SERVICES: "There was an error changing user services/roles",
  ERROR_FETCHING_ENTERPRISE_INVITES: "There was an error fetching invites",
  ERROR_REJECTING_SEAT_INVITE: "There was an error rejecting this invite",
  ERROR_ACCEPTING_SEAT_INVITE: "There was an error accepting this invite",
  ERROR_UPDATING_ENTERPRISE_USER_ROLES: "There was an error changing user roles",
};
