import createAction from "rdx/utils/createAction";
import types from "./types";

export default {
  getCareerListings: (payload) => createAction(types.GET_CAREER_LISTINGS_REQUEST, payload),
  updateCareerListing: (payload) => createAction(types.UPDATE_CAREER_LISTING_REQUEST, payload),
  deleteCareerListing: (payload) => createAction(types.DELETE_CAREER_LISTING_REQUEST, payload),
  createCareerListing: (payload) => createAction(types.CREATE_CAREER_LISTING_REQUEST, payload),
  setCareerListings: (payload) => createAction(types.SET_CAREER_LISTINGS, payload),
  // INSERTION_PT (for script, do not remove)
};
