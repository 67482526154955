import { all, put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import _ from "lodash";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getAdminProposalDetails(action) {
  const { id } = action.payload;

  const { success, data, error } = yield* makeRequest.get(`/proposal_flows/${id}`);
  if (success && data) {
    const project = camelizeKeys(_.clone(data.body));
    yield all([put(actions.setAdminProposalDetails(project))]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ADMIN_PROPOSAL_DETAILS,
    });
  }
  return null;
}

export default getAdminProposalDetails;
