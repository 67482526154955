import React from "react";
import PropTypes from "prop-types";

const ImageIcon = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.92205 0C0.867193 0 0 0.865961 0 1.92082V14.0792C0 15.134 0.867193 16 1.92205 16H18.9991C20.0539 16 20.9259 15.134 20.9259 14.0792V1.92082C20.9259 0.865961 20.0539 0 18.9991 0H1.92205ZM1.92205 1.23087H18.9991C19.3933 1.23087 19.695 1.52657 19.695 1.92082V14.0792C19.695 14.4734 19.3933 14.7691 18.9991 14.7691H1.92205C1.52778 14.7691 1.2321 14.4734 1.2321 14.0792V1.92082C1.2321 1.52655 1.5278 1.23087 1.92205 1.23087ZM8.96571 2.88485C8.04752 2.90855 7.29612 3.67441 7.29612 4.59774C7.29612 5.53584 8.07091 6.31063 9.00901 6.31063C9.9471 6.31063 10.7171 5.53584 10.7171 4.59774C10.7171 3.65964 9.94706 2.88485 9.00901 2.88485C8.99441 2.88485 8.98033 2.88451 8.96573 2.88485H8.96571ZM9.00898 4.11572C9.28184 4.11572 9.491 4.32488 9.491 4.59774C9.491 4.8706 9.28185 5.07857 9.00898 5.07857C8.73611 5.07857 8.52696 4.87062 8.52696 4.59774C8.52696 4.32488 8.73611 4.11572 9.00898 4.11572ZM12.7929 5.90071C12.5977 5.90088 12.4138 5.99361 12.2977 6.15073L9.77586 9.60191L7.76008 7.34573C7.638 7.20921 7.4613 7.13434 7.27807 7.14138C7.09484 7.14654 6.9233 7.23343 6.81047 7.37801L3.27172 11.9793V11.9794C3.06359 12.2477 3.11202 12.6339 3.37989 12.8423C3.50937 12.9419 3.67302 12.9859 3.83497 12.9648C3.99672 12.9435 4.14354 12.8588 4.24296 12.7295L7.33698 8.71836L9.03422 10.6164L7.92837 12.1297C7.72952 12.4043 7.79031 12.7881 8.06419 12.9879C8.33774 13.1878 8.72117 13.1293 8.92242 12.8569L12.7977 7.55978L16.6682 12.8569C16.8697 13.13 17.2541 13.1887 17.5277 12.9879C17.8018 12.7881 17.8625 12.4043 17.6635 12.1297L13.293 6.15077C13.176 5.99227 12.9901 5.89937 12.7929 5.90075L12.7929 5.90071Z"
        fill={fill}
      />
    </svg>
  );
};

ImageIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

ImageIcon.defaultProps = {
  height: "16px",
  width: "16px",
  fill: "var(--royal-peacock-blue)",
};

export default ImageIcon;
