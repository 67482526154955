import component from "containers/StaticPages/solar";

export default {
  key: "solar",
  title: "solar",
  path: "/:user?/solar",
  pageName: "solar",
  linkTarget: "/solar",
  component,
};
