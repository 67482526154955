import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/financials/messages";
import { keysToCamelCase } from "lib/helpers";

function* getPaymentMilestones(action) {
  const { success, data, error } = yield* makeRequest.get("/milestones", action.payload);

  if (success && data) {
    const list = data.body.milestones.map((m) => keysToCamelCase(m));
    const paging = keysToCamelCase(data.body.paging);
    yield put(actions.setPaymentMilestones({ list, paging }));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_MILESTONES_LIST,
    });
  }

  return null;
}

export default getPaymentMilestones;
