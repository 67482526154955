import React from "react";
import PropTypes from "prop-types";
import useWindowResize from "hooks/useWindowResize";
import SidebarFilters from "components/PlatformLayout/components/Sidebar/Filters";
import SessionClient from "util/SessionClient";

const LeadsFilters = (props) => {
  const { navigate, override, isEnterprise } = props;
  const { width } = useWindowResize();

  const session = new SessionClient();
  const { isEnterpriseStaff } = session;

  const sort = {
    key: "sort",
    label: "Sort By",
    options: [
      {
        key: "newest",
        label: "Sort By Newest",
        default: true,
        index: 0,
        parentKey: "sort",
      },
      {
        key: "name",
        label: "Sort By Name",
        default: false,
        index: 0,
        parentKey: "sort",
      },
    ],
  };

  const menus = [
    ...(width < 1000 ? [sort] : []),
    {
      key: "status",
      label: "Status",
      options: [
        {
          key: "any-status",
          label: "All",
          default: true,
          index: 1,
          parentKey: "status",
        },
        {
          key: "confirmed",
          label: "Verified",
          default: false,
          index: 1,
          parentKey: "status",
        },
        {
          key: "unconfirmed",
          label: "Unverified",
          default: false,
          index: 1,
          parentKey: "status",
        },
        {
          key: "archived",
          label: "Archived",
          default: false,
          index: 1,
          parentKey: "status",
        },
      ],
    },
    {
      key: "role_tag",
      label: "Role",
      options: [
        {
          key: "any-role",
          label: "All",
          default: true,
          index: 2,
          parentKey: "role_tag",
        },
        {
          key: "consultant",
          label: "Seller",
          default: false,
          index: 2,
          parentKey: "role_tag",
        },
        {
          key: "ambassador",
          label: "Ambassador",
          default: false,
          index: 2,
          parentKey: "role_tag",
        },
        ...(isEnterpriseStaff && isEnterprise
          ? [
              {
                key: "org_manager",
                label: "Manager",
                default: false,
                index: 2,
                parentKey: "role_tag",
              },
              {
                key: "org_support",
                label: "Support",
                default: false,
                index: 2,
                parentKey: "role_tag",
              },
              {
                key: "lead_generator",
                label: "Lead Generator",
                default: false,
                index: 2,
                parentKey: "role_tag",
              },
            ]
          : []),
      ],
    },
  ];

  return <SidebarFilters menus={menus} navigate={navigate} override={override} />;
};

LeadsFilters.propTypes = {
  navigate: PropTypes.func.isRequired,
  override: PropTypes.func.isRequired,
  isEnterprise: PropTypes.bool,
};

export default LeadsFilters;
