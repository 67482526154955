import tabs from "containers/Admin/EnterpriseApplications/tabs";
import { EnterpriseApplications } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import breadcrumbs from "components/PlatformLayout/components/PlatformPageHeader/Breadcrumbs/EnterpriseApplicationBreadCrumbs";
import l from "@loadable/component";

const content = l(() => import("containers/Admin/EnterpriseApplications"));

const { ENTERPRISES_SUPER_ADMIN } = permissionTypes;

export default {
  key: "admin-enterprise",
  title: breadcrumbs,
  path: "/admin-enterprise/:tab?",
  exact: true,
  requiredParams: ["tab"],
  linkTarget: "/admin-enterprise",
  linkTitle: "Enterprise",
  basePath: "admin-enterprise",
  linkCategory: "Admin",
  permission: ENTERPRISES_SUPER_ADMIN,
  icon: EnterpriseApplications,
  tabs,
  content,
};
