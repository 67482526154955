import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import AlertIcon from "components/Icons/AlertIcon";
import ModalContainer, { ModalContainerPropsT } from "@mui-styled-components/Modals/ModalContainer";
import { cvar } from "styles";
import actions from "rdx/actions";

import * as S from "./styles";

type SellerChangeOrderConfirmPropsT = Omit<ModalContainerPropsT, "handleSave"> & {
  setConfirmCOModalOpen: (value: boolean) => void;
};

type ParamsT = {
  projectId?: string;
  leadId?: string;
};

export default function SellerChangeOrderConfirm({ setConfirmCOModalOpen, ...rest }: SellerChangeOrderConfirmPropsT) {
  const dispatch = useDispatch();
  const changeOrderFormUrl = process.env.REACT_APP_CHANGE_ORDER_FORM_URL;
  const params = useParams<ParamsT>();
  const projectId = params.projectId ?? params.leadId;

  const handleSave = () => {
    dispatch(
      actions.postSellerChangeOrder({
        projectId,
      }),
    );
    setConfirmCOModalOpen(false);
    window.open(changeOrderFormUrl, "_blank", "noreferrer");
  };

  return (
    <ModalContainer handleSave={handleSave} submitButtonText="Confirm" {...rest}>
      <S.Title>Move Project to On Hold -Pending Change Order</S.Title>
      <S.DialogContainer>
        <AlertIcon width="3rem" fill={cvar("dark-blue")} />
        <S.DialogText>
          Your project will be placed <span>on hold until</span> the change order is completed. Are you sure you want to
          continue?
        </S.DialogText>
      </S.DialogContainer>
    </ModalContainer>
  );
}
