import component from "containers/StaticPages/faq";
// import l from "@loadable/component";

// const component = l(() => import("containers/StaticPages/faq"));

export default {
  key: "powur-faq",
  title: "FAQ",
  path: "/:user?/faq",
  pageName: "FAQ",
  linkTarget: "/faq",
  component,
};
