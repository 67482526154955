import { Input, styled, Theme } from "@mui/material";

type MonthTabPropsT = {
  isCurrent?: boolean;
};

export const DateRangeInputsWrapper = styled("div")(({ theme }) => ({
  minWidth: "320px",
  maxWidth: "320px",
  width: "100%",
  height: "32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: theme.colors["ma-white"],
}));

export const DateRangeInput = styled(Input)(({ theme }) => ({
  width: "40%",
  display: "inline-block",
  height: "100%",
  border: "none",
  textAlign: "center",
  background: "transparent",
  color: theme.colors["dark-blue"],
  fontWeight: "600",
  fontSize: "14px",
  "::placeholder": {
    color: theme.colors["dark-blue"],
    fontWeight: "600",
    fontSize: "14px",
  },
  "& .MuiInput-input": {
    textAlign: "center",
  },
}));

export const MonthTab = styled("span")(({ isCurrent = false }: MonthTabPropsT) => ({ theme }) => ({
  display: "inline-block",
  width: "100%",
  fontSize: "14px",
  fontWeight: "500",
  padding: "10px 12px",
  margin: "8px 0px",
  borderRadius: "18px",
  color: isCurrent ? theme.colors.white : theme.colors["prussian-blue"],
  background: isCurrent
    ? `linear-gradient(270.54deg, ${theme.colors["picton-blue"]} 49.88%, ${theme.colors["blue-ribbon"]} 135.73%) !important`
    : "transparent",
}));

export const customStyles = (theme: Theme) => ({
  "& .MuiPaper-root": {
    borderRadius: "12px",
  },
  "& .MuiPickersToolbar-penIconButton": {
    display: "none",
  },
  "& .MuiDateRangePickerToolbar-container": {
    "& span": {
      color: theme.colors["prussian-blue"],
      fontWeight: "500",
    },
  },
  "& .MuiPickersArrowSwitcher-root": {
    "& button:first-of-type": {
      order: 2,
      width: "10%",
    },
    "& button:last-child": {
      order: 3,
      width: "10%",
    },
    "& span": {
      order: 1,
      width: "80%",
      color: theme.colors["prussian-blue"],
      fontWeight: "600",
      fontSize: "14px",
    },
  },
  "& .MuiDayPicker-header": {
    "& span": {
      color: theme.colors["prussian-blue"],
      fontWeight: "600",
      fontSize: "14px",
    },
  },
  "& .MuiDateRangePickerDay-rangeIntervalPreview": {
    "& button": {
      color: theme.colors["queen-blue"],
      fontWeight: "500",
      fontSize: "12px",
    },
    "& .Mui-selected": {
      color: theme.colors.white,
      background: `linear-gradient(270.54deg, ${theme.colors["picton-blue"]} 49.88%, ${theme.colors["blue-ribbon"]} 135.73%) !important`,
      border: "none",
    },
    "& .MuiPickersDay-today": {
      border: "none",
      background: theme.colors["cotton-boll"],
    },
    "& .Mui-disabled": {
      border: "none",
      color: theme.colors["botticelli-blue"],
    },
  },
  "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
    background: theme.colors["cotton-boll"],
  },
});
