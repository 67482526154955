import React from "react";
import PropTypes from "prop-types";
import styles from "./MainNav.module.less";

const EnterpriseEarningsIcon = (props) => {
  const { fill, height, width, active, hovered } = props;

  return (
    <div
      className={[active ? styles.navIconContainerActive : styles.navIconContainer, hovered ? styles.hovered : []].join(
        " ",
      )}
      style={{
        paddingTop: "1px",
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.enterpriseIcon}
      >
        <rect
          x="0.834375"
          y="0.81875"
          width="19.3"
          height="19.3"
          rx="9.65"
          fill="var(--royal-peacock-blue)"
          stroke="var(--royal-peacock-blue)"
          strokeWidth="0.7"
        />
        <path d="M10.0454 8.45312H8.72656V12.8491H10.0454V8.45312Z" fill={fill} />
        <path d="M14.4404 8.45312H13.1216V12.8491H14.4404V8.45312Z" fill={fill} />
        <path d="M7.84711 8.45312H6.52832V12.8491H7.84711V8.45312Z" fill={fill} />
        <path d="M12.2426 8.45312H10.9238V12.8491H12.2426V8.45312Z" fill={fill} />
        <path
          d="M15.1004 14.3711H5.86887H5.25343C5.20947 14.459 5.20947 14.525 5.20947 14.6129V15.4921H15.7598V14.6129C15.7598 14.525 15.7378 14.459 15.7159 14.3931H15.1004V14.3711Z"
          fill={fill}
        />
        <path
          d="M5.86792 13.5216V13.9612H15.0995V13.5216C15.0995 13.4337 15.0775 13.3457 15.0555 13.2578H5.91188C5.8899 13.3457 5.86792 13.4337 5.86792 13.5216Z"
          fill={fill}
        />
        <path
          d="M15.6499 7.4724L10.5726 4.92273C10.5066 4.90076 10.4407 4.90076 10.3747 4.92273L5.31937 7.45042C5.25343 7.49438 5.20947 7.5823 5.20947 7.67022V8.06586H15.7598V7.6922C15.7598 7.5823 15.7159 7.51636 15.6499 7.4724ZM10.4846 7.8021C10.0011 7.8021 9.60545 7.40646 9.60545 6.9229C9.60545 6.43935 10.0011 6.04371 10.4846 6.04371C10.9682 6.04371 11.3638 6.43935 11.3638 6.9229C11.3638 7.40646 10.9682 7.8021 10.4846 7.8021Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

EnterpriseEarningsIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  active: PropTypes.bool,
  hovered: PropTypes.bool,
};

EnterpriseEarningsIcon.defaultProps = {
  fill: "white",
  height: "19",
  width: "26",
};

export default EnterpriseEarningsIcon;
