import React from "react";
import PropTypes from "prop-types";
import styles from "../../Team.module.less";

const NavItem = (props) => {
  const { title, sectionLink } = props;

  function scrollToSection() {
    const element = document.getElementById(sectionLink);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  return (
    <div className={styles.link}>
      <span
        onClick={scrollToSection}
        onKeyDown={scrollToSection}
        role="button"
        tabIndex={0}
        className={styles.navButton}
      >
        {title}
      </span>
      <div className={styles.underline} />
    </div>
  );
};

NavItem.propTypes = {
  title: PropTypes.string,
  sectionLink: PropTypes.string,
};

export default NavItem;
