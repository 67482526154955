import { styled } from "@mui/material/styles";
import { filterStyledProps } from "lib/helpers/filterStyledProps";
import { Grid } from "@mui/material";
import { theme } from "styles/themes";
import { mq } from "styles";

type SubheadingTextPropsT = {
  fontFamily?: "Barlow" | "Sora";
  fontSize?: `${number}px`;
  lineHeight?: `${number}px`;
};

export const GetSignedUpContainer = styled("div")(() => ({
  background: theme.colors["blue-ribbon"],
  width: "100%",
  backgroundImage: "url(/img/setterJoin/join_PowurLogoBackground.png)",
  backgroundRepeat: "no-repeat",
  display: "flex",
  justifyContent: "center",
  [mq.phoneOnly]: {
    padding: "75px 50px",
    backgroundSize: "350px",
    backgroundPosition: "bottom left -70px",
  },
  [mq.tabletUp]: {
    backgroundPosition: "bottom 10px top 40px",
    padding: "80px 120px",
  },
  [mq.tabletLandUp]: {
    padding: "150px 120px",
    backgroundSize: "500px",
  },
}));

export const MaxWidthContainer = styled(Grid)(() => ({
  maxWidth: "1440px",
}));

export const HeaderText = styled(Grid, {
  shouldForwardProp: filterStyledProps(["centerContent"]),
})(({ centerContent = false }: { centerContent: boolean }) => ({
  display: "flex",
  justifyContent: "center",
  fontFamily: "Sora",
  fontSize: "48px",
  lineHeight: "60px",
  color: theme.colors.white,
  fontWeight: 400,
  textTransform: "capitalize",
  [mq.phoneOnly]: {
    alignItems: "flex-start",
    textAlign: "left",
  },
  [mq.tabletUp]: {
    alignItems: "center",
    textAlign: "center",
    maxWidth: "500px",
  },
  [mq.desktopUp]: {
    alignItems: centerContent ? "center" : "flex-start",
    textAlign: centerContent ? "center" : "left",
    maxWidth: "none",
  },
}));

export const ButtonColumn = styled(Grid, {
  shouldForwardProp: filterStyledProps(["centerContent"]),
})(({ centerContent = false }: { centerContent: boolean }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "24px",
  justifyContent: "center",
  [mq.phoneOnly]: {
    rowGap: "24px",
    alignItems: "flex-start",
  },
  [mq.tabletUp]: {
    alignItems: "center",
    textAlign: "center",
    maxWidth: "500px",
  },
  [mq.desktopUp]: {
    maxWidth: "none",
    alignItems: centerContent ? "center" : "flex-end",
    textAlign: centerContent ? "center" : "right",
    rowGap: centerContent ? "60px" : "24px",
  },
}));

export const SubheadingText = styled("div", {
  shouldForwardProp: filterStyledProps(["fontFamily", "fontSize", "lineHeight"]),
})(({ fontFamily = "Barlow", fontSize = "18px", lineHeight = "22px" }: SubheadingTextPropsT) => ({
  fontWeight: 400,
  [mq.phoneOnly]: {
    fontFamily: "Barlow",
    fontSize: "26px",
    lineHeight: "35px",
    width: "215px",
    color: theme.colors["white-a65"],
  },
  [mq.tabletUp]: {
    fontFamily,
    fontSize,
    lineHeight,
    width: "100%",
    color: theme.colors.white,
  },
}));

export const DetailText = styled("div")(() => ({
  fontSize: "24px",
  lineHeight: "32px",
  color: theme.colors.white,
  fontWight: 400,
  maxWidth: "665px",
  textAlign: "center",
  [mq.phoneOnly]: {
    fontSize: "24px",
  },
  [mq.tabletUp]: {
    fontSize: "21px",
    marginBottom: "20px",
  },
  [mq.desktopUp]: {
    fontSize: "24px",
    marginBottom: "40px",
  },
}));
