import { configureStore } from "@reduxjs/toolkit";

import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { setupListeners } from "@reduxjs/toolkit/query";

import { api } from "./services/api";

import createRootReducer from "./reducers";
import rootSaga from "./sagas";

const browserHistory = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware({
  onError: (e) => {
    console.error("Saga Error: ", e); // eslint-disable-line no-console
    browserHistory.replace({
      search: "?'Error(Saga): Please refresh page'",
    });
  },
});

const store = configureStore({
  reducer: createRootReducer(browserHistory),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sagaMiddleware as any,
      )
      .concat(api.middleware),
  devTools: {
    trace: (process.env.REACT_APP_ENABLE_REDUX_DEVTOOLS_TRACE as unknown as boolean) || false,
    name: "powur energy",
  },
});

sagaMiddleware.run(rootSaga);

setupListeners(store.dispatch);

export { store, browserHistory };
