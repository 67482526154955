import tabs from "containers/Admin/ProjectDetail/Tabs";
import { permissionTypes } from "hooks/usePermissions";
import breadCrumbs from "components/PlatformLayout/components/PlatformPageHeader/Breadcrumbs/ProjectBreadCrumbs";
import l from "@loadable/component";

const content = l(() => import("containers/Admin/ProjectDetail"));

const { PROJECT_MANAGEMENT } = permissionTypes;

export default {
  key: "admin-project-details",
  title: breadCrumbs,
  path: "/admin-projects/:projectId?/:tab?/:milestoneId?",
  basePath: "admin-projects/:projectId",
  requiredParams: ["projectId", "tab"],
  optionalParams: ["milestoneId"],
  linkTarget: "/admin-projects",
  linkTitle: null,
  linkCategory: null,
  permission: PROJECT_MANAGEMENT,
  icon: null,
  content,
  tabs,
  newHeader: true,
};
