import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import StarIcon from "components/Icons/starIcon";
import styles from "../UserCards.module.less";

const MentorStars = (props) => {
  const { average, count, numberDisplay } = props;
  const floatAvg = parseFloat(average)?.toFixed(1);

  // if (!_.isEmpty(average)) {
  if (average) {
    const stars = _.times(5, (i) => (
      // eslint-disable-next-line react/no-array-index-key
      <span key={i} className={styles.star}>
        <StarIcon mainColor="var(--goldenrod)" />
      </span>
    ));

    const percentage = Math.round((average / 5) * 100);

    if (average > 0) {
      const counts = <span className={styles.totalCount}>({count})</span>;

      return (
        <span className={styles.rating}>
          <span className={styles.score}>{numberDisplay && floatAvg} </span>
          <div className={styles.stars}>
            {stars}
            <div className={styles.overlay} style={{ width: `${100 - percentage}%` }} />
          </div>
          {count && counts}
        </span>
      );
    }
  }

  return <span className={styles.ratedText}>Not yet rated</span>;
};

MentorStars.propTypes = {
  count: PropTypes.number,
  average: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  numberDisplay: PropTypes.bool,
};

export default MentorStars;
