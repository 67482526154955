import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/adminDashboard/types";
import getAdminDashboardDetails from "./getAdminDashboardDetails";
// IMPORT_PT (for script, do not remove)

function* watchAdminDashboardSagas() {
  yield trackRequests(takeEvery, types.GET_ADMIN_DASHBOARD_DETAILS_REQUEST, getAdminDashboardDetails);
  // INSERTION_PT (for script, do not remove)
}

export default watchAdminDashboardSagas;
