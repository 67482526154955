import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";

import messages from "../messages";

function* inviteToMyPowur(action) {
  const { id, inviteKind } = action.payload;

  const params = {
    invite_kind: inviteKind,
  };

  const { success, error } = yield* makeRequest.post(`/provider_leads/${id}/invite_to_my_powur`, params);
  if (success) {
    yield put(actions.newSuccessEvent({ message: messages.INVITE_TO_MY_POWUR_SUCCESS }));
  } else {
    return getErrorActions({ error, message: messages.INVITE_TO_POWUR_SITE_ERROR });
  }

  return null;
}

export default inviteToMyPowur;
