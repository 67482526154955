import { put, select } from "redux-saga/effects";
import selectors from "rdx/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* deleteStateLicense(action) {
  const { success, data, error } = yield* makeRequest.delete(`/state_licenses/${action.payload}`, {});

  if (success && data) {
    const rawLicenses = yield select(selectors.getStateLicenses);
    const stateLicenses = rawLicenses.filter((f) => f.id !== action.payload);

    yield put(actions.setStateLicenses({ stateLicenses }));
    yield put(
      actions.newSuccessEvent({
        message: messages.STATE_LICENSE_DELETED_SUCCESS,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: "There was an error getting career listings",
    });
  }

  return null;
}

export default deleteStateLicense;
