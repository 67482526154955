import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Button } from "antd";
import styles from "../../EnterpriseJoin.module.less";

const JoinSection = ({ org, openModal }) => {
  return (
    <div className={styles.joinSection}>
      <Row style={{ width: "100%", minHeight: "289px", height: "100%" }}>
        <Col
          xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div style={{ display: "inline-block" }}>
            <div style={{ color: "white" }} className={styles.title}>
              Be on the winning team
            </div>
            <div className={styles.line} />
            <div style={{ color: "white" }} className={styles.subheading}>
              Join the Energy Revolution
            </div>
          </div>
        </Col>
        <Col
          xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button className={styles.bannerButton} style={{ marginTop: "0" }} type="primary" onClick={openModal}>
            Apply to Join {org}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

JoinSection.propTypes = {
  org: PropTypes.string,
  openModal: PropTypes.func,
};

export default JoinSection;
