// import content from "containers/Admin/settings";
import tabs from "containers/Admin/settings/tabs";
import { permissionTypes } from "hooks/usePermissions";
import { SettingsIcon } from "@icons";
import l from "@loadable/component";

const content = l(() => import("containers/Admin/settings"));

const { PLATFORM_CONFIGURATION, SERVICE_AREA_UPDATES } = permissionTypes;

export default {
  key: "admin-settings",
  title: "Admin Settings",
  path: "/admin-settings/:tab?",
  linkTarget: "/admin-settings",
  linkTitle: "Settings",
  linkCategory: "Admin",
  permission: [PLATFORM_CONFIGURATION, SERVICE_AREA_UPDATES],
  icon: SettingsIcon,
  basePath: "admin-settings",
  requiredParams: ["tab"],
  optionalParams: [],
  tabs,
  content,
  sidebar: null,
};
