import React from "react";
import PropTypes from "prop-types";

const MessageIcon = (props) => {
  const { fillMain, fill } = props;

  return (
    <svg
      style={{ display: "inline-block" }}
      width="22"
      height="18"
      viewBox="0 0 25 21"
      fill={fillMain}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5921 17.1181C1.06689 17.1181 0.517792 16.9116 0.244755 16.4284C-0.0703131 15.8612 0.62351 15.0369 1.04366 14.5538C1.65283 13.8395 2.19916 12.9572 2.59833 12.2427C1.58995 11.0243 1.06474 9.63765 1.06474 8.16709C1.06474 3.98638 5.45553 0.604004 10.8337 0.604004C16.2119 0.604004 20.6027 4.00735 20.6027 8.16709C20.6027 12.3268 16.2119 15.7302 10.8337 15.7302C9.72027 15.7302 8.62778 15.583 7.57736 15.31C6.27486 15.9192 4.4051 16.6755 2.43026 17.0328C2.3671 17.0538 1.69703 17.1181 1.5921 17.1181Z"
        fill="white"
      />
      <circle cx="18.6587" cy="14.274" r="6.26686" fill={fill} />
      <path
        d="M18.6749 12.9767C18.4935 13.158 18.1995 13.158 18.0181 12.9767C17.8368 12.7953 17.8368 12.5013 18.0181 12.3199L19.3317 11.0063C20.0572 10.2808 21.2333 10.2808 21.9588 11.0063C22.6842 11.7318 22.6842 12.9079 21.9588 13.6333L20.6453 14.9469C20.4639 15.1282 20.1699 15.1282 19.9885 14.9469C19.8071 14.7655 19.8071 14.4715 19.9885 14.2901L21.3021 12.9766C21.6648 12.6138 21.6648 12.0257 21.3021 11.663C20.9393 11.3003 20.3512 11.3003 19.9885 11.663L18.6749 12.9767ZM18.675 15.6036C18.8563 15.4223 19.1504 15.4223 19.3317 15.6036C19.5131 15.785 19.5131 16.079 19.3317 16.2604L18.0181 17.574C17.2927 18.2995 16.1165 18.2995 15.3911 17.574C14.6656 16.8486 14.6656 15.6724 15.3911 14.9469L16.7046 13.6334C16.886 13.4521 17.18 13.4521 17.3614 13.6334C17.5428 13.8148 17.5428 14.1089 17.3614 14.2902L16.0479 15.6037C15.6851 15.9664 15.6851 16.5545 16.0479 16.9173C16.4106 17.28 16.9987 17.28 17.3614 16.9173L18.675 15.6036ZM19.9885 12.9765C20.1699 13.1579 20.1699 13.4519 19.9885 13.6333L18.0181 15.6035C17.8368 15.7849 17.5427 15.7849 17.3614 15.6035C17.18 15.4222 17.18 15.1281 17.3614 14.9468L19.3316 12.9765C19.513 12.7951 19.8071 12.7951 19.9885 12.9765H19.9885Z"
        fill="white"
      />
    </svg>
  );
};

MessageIcon.propTypes = {
  fillMain: PropTypes.string,
  fill: PropTypes.string,
};

MessageIcon.defaultProps = {
  fillMain: "var(--dark-blue)",
  fill: "var(--goldenrod)",
};

export default MessageIcon;
