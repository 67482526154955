import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";

function* uploadCoordinators(action) {
  const { options } = action.payload;
  const { success, data, error } = yield* makeRequest.post("/projects/coordinator_upload", {}, options);
  if (success && data) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: `Sucessfully uploaded ${data.success || 0} Coordinators, ${data.errors?.length || 0} failed`,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      // message: messages.ERROR_UPLOADING_COORDINATOR_CSV,
    });
  }
  return null;
}

export default uploadCoordinators;
