import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";

import styles from "../CreateAccount.module.less";

const Header = ({ activeIndex, data, handlePreviousTabClick }) => {
  const onTabClick = (tabIdx) => {
    if (tabIdx < activeIndex && (data.length < 4 || tabIdx !== 0)) {
      handlePreviousTabClick(tabIdx);
    }
  };

  return (
    <div className={styles.joinPowurHeader}>
      <div className={styles.titleText}>
        <div className={styles.titleNumber}>{activeIndex < 10 ? `0${activeIndex + 1}` : activeIndex + 1}</div>
        <div className={styles.titleLabel}>{data[activeIndex].title}</div>
      </div>
      <ul className={styles.headerProgressBar}>
        {data.map((step, i) => (
          <li className={styles.progressItemContainer} key={step.title}>
            {i < activeIndex ? (
              <Tooltip
                placement="bottom"
                color="var(--dark-blue)"
                title={step.title}
                overlayInnerStyle={{ fontWeight: "600", borderRadius: "4px" }}
              >
                <button type="button" onClick={() => onTabClick(i)} className={styles.progressButtonComplete} />
              </Tooltip>
            ) : (
              <button
                type="button"
                onClick={() => onTabClick(i)}
                className={i <= activeIndex ? styles.progressButtonComplete : styles.progressButtonIncomplete}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

Header.propTypes = {
  activeIndex: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  handlePreviousTabClick: PropTypes.func,
};

export default Header;
