import component from "containers/StaticPages/education/introduction";

// import l from "@loadable/component";

// const component = l(() => import("containers/education/introduction"));

export default {
  key: "education-introduction",
  title: "Education: Introduction",
  path: "/:user?/education/",
  pageName: "education",
  linkTarget: "/education",
  exact: true,
  component,
};
