export default {
  // EXAMPLE_ACTION_TYPE: "EXAMPLE_ACTION_TYPE",
  GET_SMS_LIST_REQUEST: "GET_SMS_LIST_REQUEST",
  DELETE_SMS_REQUEST: "DELETE_SMS_REQUEST",
  DELETE_SMS: "DELETE_SMS",
  CREATE_SMS_REQUEST: "CREATE_SMS_REQUEST",
  // SET_SMS_LIST_FILTERS: "SET_SMS_LIST_FILTERS",
  SET_SMS_LIST: "SET_SMS_LIST",
  SET_SMS_LANGUAGES: "SET_SMS_LANGUAGES",
  SET_SMS_RECIPIENTS: "SET_SMS_RECIPIENTS",
  GET_SMS_DETAILS_REQUEST: "GET_SMS_DETAILS_REQUEST",
  SET_SMS_DETAILS: "SET_SMS_DETAILS",
  UPDATE_SMS_REQUEST: "UPDATE_SMS_REQUEST",
  UPDATE_SMS_DETAIL: "UPDATE_SMS_DETAIL",
  // INSERTION_PT (for script, do not remove)
};
