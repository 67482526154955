export default {
  GET_CUSTOM_FIELDS: "[customField] GET custom field",

  CREATE_CUSTOM_FIELD: "[customField] POST new event custom field",

  SET_CUSTOM_FIELDS: "[customField] SET custom field",

  UPDATE_CUSTOM_FIELD: "[customField] UPDATE selected custom field",

  DELETE_CUSTOM_FIELD: "[customField] DELETE selected dashboard promotion",

  // INSERTION_PT (for script, do not remove)
};
