import component from "containers/StaticPages/education/toptenquestions";
// import l from "@loadable/component";

// const component = l(() => import("containers/education/toptenquestions"));

export default {
  key: "education-top-10-questions",
  title: "Education: Top 10 Questions",
  path: "/:user?/education/top-10-questions",
  pageName: "top_10_questions",
  linkTarget: "/education/top-10-questions",
  component,
};
