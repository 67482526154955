export default {
  ERROR_FETCHING_MESSAGING_METRICS: "There was an error getting ticket metrics",
  ERROR_FETCHING_CONSULTANT_REPORT: "There was an error getting consultant report",
  ERROR_FETCHING_CONSULTANTS_SUGGESTIONS: "There was an error searching consultants",
  ERROR_GETTING_ACTIVITY_LOG: "There was an error getting the activity log",
  ERROR_GETTING_USERS_PROJECTS_AUTOCOMPLETE: "There was an error getting the autocomplete suggestions",
  ERROR_GETTING_ACTIVITY_LOG_CSV: "There was an error getting the autocomplete suggestions",
  ERROR_GETTING_PREFERRED_PARTNER_CSV: "There was an error getting the preferred partner CSV",
  ERROR_GETTING_PREFERRED_PARTNER_CSV_REPORT: "There was an error getting the preferred partner CSV report",
};
