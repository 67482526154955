// sources for getting color contrasts:
// https://stackoverflow.com/a/35970186
// https://stackoverflow.com/a/3943023

export function hexToRgb(hex) {
  const hexRegEx = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;
  if (!hexRegEx.test(hex)) {
    throw new Error(
      `${hex} is not in a valid hexadecimal color syntax, expecting format #XXX or #XXXXXX where X is a hexadecimal integer`,
    );
  }

  let input = hex.slice(1);
  if (input.length === 3) {
    input = input[0] + input[0] + input[1] + input[1] + input[2] + input[2];
  }

  return [parseInt(input.slice(0, 2), 16), parseInt(input.slice(2, 4), 16), parseInt(input.slice(4, 6), 16)];
}

export function invertRgb([r, g, b]) {
  return [255 - r, 255 - g, 255 - b];
}

export function rgbToHex([r, g, b]) {
  return `#${padZero(r.toString(16))}${padZero(g.toString(16))}${padZero(b.toString(16))}`;
}

export function getContrastingColor(hex) {
  const invert = invertRgb(hexToRgb(hex));
  return rgbToHex(invert);
}

export function getBlackOrWhiteContrast(hex) {
  const rgb = hexToRgb(hex);
  const colorweights = [0.299, 0.587, 0.114];
  const threshold = 150;
  const luminance = rgb.reduce((sum, c, idx) => sum + c * colorweights[idx], 0);
  return luminance > threshold ? "#000" : "#fff";
}

function padZero(str, len) {
  const length = len || 2;
  const zeroes = new Array(length).join("0");
  return (zeroes + str).slice(-length);
}
