import types from "rdx/modules/profile/types";
import createAction from "rdx/utils/createAction";

export default {
  getProfile: (payload) => createAction(types.GET_PROFILE_REQUEST, payload),
  updateProfile: (payload) => createAction(types.UPDATE_PROFILE_REQUEST, payload),
  setProfile: (payload) => createAction(types.SET_PROFILE, payload),
  updatePassword: (payload) => createAction(types.UPDATE_USER_PASSWORD_REQUEST, payload),
  createLicense: (payload) => createAction(types.CREATE_LICENSE_REQUEST, payload),
  updateLicense: (payload) => createAction(types.UPDATE_LICENSE_REQUEST, payload),
  deleteLicense: (payload) => createAction(types.DELETE_LICENSE_REQUEST, payload),
  createMentorStatusChange: (payload) => createAction(types.CREATE_MENTOR_STATUS_CHANGE_REQUEST, payload),
  deleteMentorStatusChange: (payload) => createAction(types.DELETE_MENTOR_STATUS_CHANGE_REQUEST, payload),
  createEmailUpdate: (payload) => createAction(types.CREATE_EMAIL_UPDATE_REQUEST, payload),
  deleteEmailUpdate: (payload) => createAction(types.DELETE_EMAIL_UPDATE_REQUEST, payload),
  verifyEmailUpdate: (payload) => createAction(types.VERIFY_EMAIL_UPDATE_REQUEST, payload),
  resendEmailUpdate: (payload) => createAction(types.RESEND_EMAIL_UPDATE_REQUEST, payload),
  updateMentorPreferences: (payload) => createAction(types.UPDATE_MENTOR_PREFERENCES_REQUEST, payload),
  // INSERTION_PT (for script, do not remove)
};
