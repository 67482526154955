import { styled } from "@mui/material";
import { Input } from "antd";

import { filterStyledProps } from "lib/helpers/filterStyledProps";

type StyledSearchInputPropsT = {
  newSearchBar?: boolean;
  notEmpty?: boolean;
};

export const StyledSearchInput = styled(Input, { shouldForwardProp: filterStyledProps(["newSearchBar", "notEmpty"]) })(
  ({ newSearchBar, notEmpty }: StyledSearchInputPropsT) =>
    ({ theme }) => ({
      position: "relative",
      width: "148px",
      height: "28px",
      backgroundColor: newSearchBar && notEmpty ? theme.colors["banner-blue"] : theme.colors["cotton-boll"],
      borderRadius: "3px",
      marginRight: "12px",
      boxShadow: "none",
      border: "none",
      paddingLeft: "8px",
      boxSizing: "border-box",

      "&:focus-within": {
        border: `1px solid ${theme.colors["cotton-boll-highlighted"]}`,
        backgroundColor: theme.colors["cotton-boll"],
      },

      "&:hover:not(:focus-within)": {
        border: "none",
        backgroundColor:
          newSearchBar && notEmpty ? theme.colors["banner-blue-highlighted"] : theme.colors["cotton-boll-highlighted"],
      },

      "&>input": {
        boxSizing: "border-box",
        background: "transparent!important",
        color: newSearchBar && notEmpty ? theme.colors.white : theme.colors["dark-blue"],

        "&:focus": {
          color: theme.colors["dark-blue"],
        },

        "&:focus::placeholder": {
          color: "transparent",
        },

        "&::placeholder": {
          color: `${newSearchBar ? theme.colors["banner-blue"] : theme.colors["dark-blue"]}`,
        },
      },
    }),
);

export const StyledLineContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

export const StyledLine = styled("div")(({ theme }) => ({
  background: theme.colors["font-color-eastern-blue"],
  height: "20px",
  width: "1px",
  marginRight: "8px",
  marginLeft: "7px",
  borderRadius: "2px",
}));
