import React from "react";
import PropTypes from "prop-types";

const LaborPartnerIcon = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9629 5.03799C15.7037 3.40181 14.6537 2.03094 13.2164 1.32316L13.43 3.85516C13.4487 4.07381 13.2861 4.26481 13.0686 4.28304C13.0573 4.28461 13.0458 4.28461 13.035 4.28461C12.8311 4.28461 12.6583 4.12868 12.6406 3.92182L12.3515 0.499332C11.4425 0.295144 10.5586 0.295144 9.64895 0.499332L9.36098 3.92198C9.34228 4.13984 9.15003 4.30662 8.93279 4.2832C8.71492 4.26497 8.55286 4.07367 8.57156 3.85533L8.78471 1.32332C7.34769 2.03115 6.29737 3.40198 6.03847 5.03815L4.98389 5.03799V6.62781C6.97281 6.79239 8.99565 6.87774 11.0006 6.87774C13.0052 6.87774 15.0287 6.79239 17.0174 6.62781V5.03799L15.9629 5.03799Z"
        fill={fill}
      />
      <path
        d="M11.0003 7.97157C9.4779 7.97157 7.93444 7.92237 6.39355 7.82617C6.39576 8.00898 6.40173 8.18676 6.41336 8.35513C6.52449 9.90861 7.54577 12.2026 8.33955 13.4733C9.14987 14.7698 10.9968 14.7544 11 14.7544C11.0031 14.7544 12.8498 14.7696 13.6598 13.4733C14.454 12.2028 15.4758 9.90873 15.5869 8.35513C15.5987 8.18678 15.6045 8.009 15.6067 7.82617C14.0663 7.92253 12.5228 7.97157 11 7.97157H11.0003Z"
        fill={fill}
      />
      <path
        d="M3.93495 14.8063C3.83718 14.8384 3.74114 14.8681 3.64227 14.9019C2.4984 15.2911 1.6455 16.3273 1.22346 17.4865C0.938163 18.2698 0.782224 19.0141 0.695466 19.5998C0.679432 19.7117 0.665442 19.8172 0.653809 19.9167C0.67613 20.409 1.07947 20.8028 1.57793 20.8028H3.93481L3.93465 14.8062L3.93495 14.8063Z"
        fill={fill}
      />
      <path
        d="M15.9684 14.2295C15.4659 14.1162 15.0138 14.0305 14.6374 13.9688C14.6212 13.9944 14.6037 14.0275 14.588 14.0519C13.4976 15.7972 11.2556 15.8476 11.0035 15.8476H11.0003H10.9972C10.7451 15.8476 8.50309 15.7972 7.41266 14.0519C7.3971 14.0275 7.37949 13.9944 7.36346 13.9688C6.98683 14.0305 6.53491 14.1162 6.03238 14.2295L6.03223 20.8024H15.9684L15.9684 14.2295Z"
        fill={fill}
      />
      <path
        d="M21.3049 19.5996C21.2182 19.0139 21.0624 18.2696 20.7772 17.4864C20.3552 16.327 19.5018 15.2908 18.3581 14.9017C18.2594 14.8681 18.1634 14.8382 18.0654 14.8062V20.8024H20.4225C20.9208 20.8024 21.3241 20.4088 21.3466 19.9163C21.335 19.8169 21.321 19.7115 21.3049 19.5995L21.3049 19.5996Z"
        fill={fill}
      />
    </svg>
  );
};

LaborPartnerIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

LaborPartnerIcon.defaultProps = {
  fill: "var(--primary-lite)",
  height: "22",
  width: "22",
};

export default LaborPartnerIcon;
