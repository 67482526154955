import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { cvar } from "styles";
import * as S from "./styles";

const CloseReasonBanner = ({ leadDetails }) => {
  const { closeReasonId, leadCloseReasons } = leadDetails;
  const matchingReason = leadCloseReasons && leadCloseReasons.find((reason) => reason.id === closeReasonId);
  return (
    <S.ReasonBanner>
      <S.ReasonIcon>
        <ExclamationCircleOutlined style={{ color: cvar("pumpkin"), fontSize: "20px" }} />
      </S.ReasonIcon>
      <S.ReasonBody>
        This lead is closed. Reason: <S.ReasonSpan>{matchingReason.description}</S.ReasonSpan>
      </S.ReasonBody>
    </S.ReasonBanner>
  );
};

export default CloseReasonBanner;
