import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  adminLeadsList: {
    leads: [],
    paging: {
      page_size: 50,
      item_count: 0,
      page_number: 1,
    },
  },
  adminLeadsListFilters: {
    filters: {},
    sorts: [],
  },
  adminLeadsListAutoComplete: {},
  adminLeadDetails: {},
};

export default {
  adminLeads: createReducer(initialState, {
    [types.SET_ADMIN_LEADS_LIST](state, action) {
      return {
        ...state,
        adminLeadsList: {
          ...state.leads,
          ...action.payload,
        },
      };
    },

    [types.SET_ADMIN_LEADS_LIST_FILTERS](state) {
      //
      // set and return new redux store here
      //
      return state;
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
