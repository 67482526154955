import React from "react";

const GoogleIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.476 7.36401H9V10.845H13.844C13.7438 11.3956 13.5329 11.9202 13.2242 12.387C12.9155 12.8538 12.5154 13.2532 12.048 13.561V15.82H14.956C16.658 14.253 17.64 11.945 17.64 9.20501C17.64 8.56601 17.583 7.95301 17.476 7.36401Z"
        fill="var(--blueberry-blue)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.956 15.82L12.048 13.561C11.242 14.101 10.211 14.421 8.99997 14.421C6.65597 14.421 4.67197 12.837 3.96397 10.71H0.95697V13.042C1.70602 14.5329 2.85491 15.7862 4.27526 16.6617C5.6956 17.5373 7.33144 18.0006 8.99997 18C11.43 18 13.467 17.194 14.956 15.82Z"
        fill="var(--chateau-green)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.682 9.00001C3.682 8.40701 3.784 7.83001 3.964 7.29001V4.95801H0.957001C0.327056 6.21224 -0.00067966 7.59647 1.05824e-06 9.00001C1.05824e-06 10.452 0.348001 11.827 0.957001 13.042L3.964 10.71C3.77872 10.1589 3.6835 9.58146 3.682 9.00001Z"
        fill="var(--selective-yellow)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.44 4.925L15.022 2.345C13.463 0.891001 11.426 7.07085e-07 8.99997 7.07085e-07C7.33144 -0.000660682 5.6956 0.462675 4.27526 1.33823C2.85491 2.21378 1.70602 3.46706 0.95697 4.958L3.96397 7.29C4.67197 5.163 6.65597 3.58 8.99997 3.58C10.321 3.58 11.508 4.034 12.44 4.925Z"
        fill="var(--cinnabar)"
      />
    </svg>
  );
};

export default GoogleIcon;
