import React from "react";
import SubHeaderFilled from "components/PlatformLayout/components/SubHeaderFilled";

import * as S from "./styles";

export type PowurPlayVideoT = {
  key: string;
  label: string;
  image: string;
  url: string;
};

type PowurPlayVideosPropsT = {
  videoData: PowurPlayVideoT[];
};
const PowurPlayVideos = ({ videoData }: PowurPlayVideosPropsT) => {
  const handleOpenLink = (url: string): void => {
    window.open(url, "_blank");
  };

  return (
    <S.PowurPlayVideosContainer>
      <SubHeaderFilled title="Powur Play Videos" />
      <S.VideoLinkContainer>
        {videoData.map((data) => (
          <S.VideoDetail key={data.key}>
            <img src={data.image} alt={data.label} />
            <S.VideoLabel onClick={() => handleOpenLink(data.url)}>{data.label}</S.VideoLabel>
          </S.VideoDetail>
        ))}
      </S.VideoLinkContainer>
    </S.PowurPlayVideosContainer>
  );
};

export default PowurPlayVideos;
