import React from "react";
import { IconT } from "types/utils";

const PowurTileIcon = ({ width = 34, height = 35, fill = "none", ...rest }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 35" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect y="0.5" width="34" height="34" rx="17" fill="#20C2F1" fillOpacity="0.08" />
      <path d="M11.3434 15.1559V11.8428H14.6565L11.3434 15.1559Z" fill="#20C2F1" />
      <path d="M11.3432 19.8413L9.00026 17.4984L11.3432 15.1555V19.8413Z" fill="#20C2F1" />
      <path d="M14.6563 11.8429L16.9992 9.5L19.3421 11.8429H14.6563Z" fill="#20C2F1" />
      <path d="M19.3421 23.1572L16.9992 25.5001L14.6563 23.1572H19.3421Z" fill="#20C2F1" />
      <path d="M16.999 20.8135V17.4985H20.314L16.999 20.8135Z" fill="#20C2F1" />
      <path d="M16.9992 14.1855V17.4987H13.686L16.9992 14.1855Z" fill="#20C2F1" />
      <path d="M14.6565 23.157H11.3434V19.842L14.6565 23.157Z" fill="#20C2F1" />
      <path d="M22.657 19.842V23.157H19.342L22.657 19.842Z" fill="#20C2F1" />
      <path d="M22.6568 15.1555L24.9997 17.4984L22.6568 19.8413V15.1555Z" fill="#20C2F1" />
      <path d="M19.342 11.8425H22.657V15.1557L19.342 11.8425Z" fill="#20C2F1" />
    </svg>
  );
};

export default PowurTileIcon;
