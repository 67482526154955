import types from "rdx/modules/crmActivities/types";
import createAction from "rdx/utils/createAction";
import {
  CrmActivityFormDataT,
  CrmActivityT,
  ActivityDrawerVisibilityT,
  CrmActivityIdT,
  CrmActivityDeletePayloadT,
  CrmActivityUpdatePayloadT,
  GetCrmTaskListPayloadT,
} from "types/Project/crmActivity";

export default {
  setCrmActivityDrawerVisible: (payload: ActivityDrawerVisibilityT) =>
    createAction(types.SET_CRM_ACTIVITY_DRAWER_VISIBLE, payload),
  setCrmActivityLeadId: (payload: ActivityDrawerVisibilityT) => createAction(types.SET_CRM_ACTIVITY_LEAD_ID, payload),
  setCrmActivityId: (payload: number) => createAction(types.SET_CRM_ACTIVITY_ID, payload),
  clearCrmActivityLeadId: () => createAction(types.CLEAR_CRM_ACTIVITY_LEAD_ID),
  setCrmActivityDetails: (payload: CrmActivityT) => createAction(types.SET_CRM_ACTIVITY_DETAILS, payload),
  clearCrmActivityDetails: () => createAction(types.CLEAR_CRM_ACTIVITY_DETAILS),
  createNewCrmActivity: (payload: CrmActivityFormDataT) => createAction(types.CREATE_CRM_ACTIVITY, payload),
  updateCrmActivity: (payload: CrmActivityUpdatePayloadT) => createAction(types.UPDATE_CRM_ACTIVITY, payload),
  getCrmActivity: (payload: CrmActivityIdT) => createAction(types.GET_CRM_ACTIVITY, payload),
  deleteCrmActivity: (payload: CrmActivityDeletePayloadT) => createAction(types.DELETE_CRM_ACTIVITY, payload),
  getCrmTaskList: (payload: GetCrmTaskListPayloadT) => createAction(types.GET_CRM_TASK_LIST, payload),
  setCrmTaskList: (payload: CrmActivityT[]) => createAction(types.SET_CRM_TASK_LIST, payload),
  // INSERTION_PT (for script, do not remove)
};
