import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import { FacebookFilled, TwitterOutlined, LinkOutlined } from "@ant-design/icons";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import copy from "copy-to-clipboard";
import { buttonProps } from "lib/props";
import styles from "../../AmbassadorDashboard.module.less";

const SocialButtons = (props) => {
  const { shareUrl, facebookShareText, twitterShareText, size, round } = props;
  const [linkTooltipVisible, setLinkTooltipVisible] = useState(false);
  const handleLinkClick = () => {
    copy(shareUrl);
    setLinkTooltipVisible(true);
    setTimeout(() => setLinkTooltipVisible(false), 4000);
  };

  return (
    <div className={size === "big" ? styles.socialIconsBig : styles.socialIcons}>
      <FacebookShareButton url={shareUrl} quote={facebookShareText}>
        <div className={[styles.facebookContainer, round ? styles.roundIcon : ""].join(" ")}>
          <FacebookFilled className={styles.icon} />
        </div>
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} title={twitterShareText}>
        <div className={[styles.twitterContainer, round ? styles.roundIcon : ""].join(" ")}>
          <TwitterOutlined className={styles.icon} />
        </div>
      </TwitterShareButton>
      <Tooltip
        title="Your referral link has been copied to clipboard. Share this link with potential Powur referrals."
        placement="top"
        trigger="click"
        open={linkTooltipVisible}
      >
        <div
          className={[styles.linkContainer, round ? styles.roundIcon : ""].join(" ")}
          {...buttonProps(handleLinkClick)}
        >
          <LinkOutlined className={styles.icon} />
        </div>
      </Tooltip>
    </div>
  );
};

SocialButtons.propTypes = {
  shareUrl: PropTypes.instanceOf(URL).isRequired,
  facebookShareText: PropTypes.string,
  twitterShareText: PropTypes.string,
  size: PropTypes.oneOf(["big", "small"]),
  round: PropTypes.bool,
};

SocialButtons.defaultProps = {
  facebookShareText: undefined,
  twitterShareText: undefined,
  size: "small",
  round: false,
};

export default SocialButtons;
