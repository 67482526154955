import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../../projects/messages";

function* getActivityLog(action) {
  const params = action.payload;
  const { success, data, error } = yield* makeRequest.get("/logged_actions", params);

  if (success && data) {
    const out = {};
    const { logged_actions, paging, filters } = data.body;
    if (filters) {
      out.filters = keysToCamelCase(filters);
    }
    if (logged_actions) {
      out.loggedActions = logged_actions.map((act) => keysToCamelCase(act));
    }
    if (paging) {
      out.paging = keysToCamelCase(data.body.paging);
    }
    yield put(actions.setActivityLog(out));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ACTIVITY_LOG,
    });
  }
  return null;
}

export default getActivityLog;
