import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/publicApiProxy/messages";

function* createAppClientToken(action) {
  const { code, redirect_uri } = action.payload;

  const params = {
    code,
    redirect_uri,
  };

  const { success, data, error } = yield* makeRequest.post("/gmail_oauth/authorize_callback", params);

  if (success && data) {
    yield all([
      put(actions.setGoogleUser({ user: data.user })),
      put(
        actions.newSuccessEvent({
          message: messages.EXCHANGE_GOOGLE_CALLBACK_SUCCESS,
        }),
      ),
    ]);
  } else {
    const errorMessage = messages.ERROR_EXCHANGING_GOOGLE_CALLBACK;
    return getErrorActions({
      error,
      message: errorMessage,
    });
  }

  return null;
}

export default createAppClientToken;
