import React, { useState } from "react";
import { sortBy } from "lodash";
import PropTypes from "prop-types";
import { Select, Input } from "antd";
import Lead from "models/Lead";
import Project from "models/Project";
import ModalContainer from "components/Modals/ModalContainer";
import styles from "./CloseLead.module.less";

const { Option } = Select;

const CloseLeadModal = (props) => {
  const { leadDetails, visible, setVisible, closeReasons } = props;
  const [selectedReason, setSelectedReason] = useState("");
  const [selectError, setSelectError] = useState(false);
  const [closeNote, setCloseNote] = useState("");
  const lead = new Lead(leadDetails);
  const { TextArea } = Input;

  const createCloseOptions = (arr) => {
    if (!arr) return [];

    const reasons = sortBy(arr, "order");
    return reasons.map((reason) => {
      const { id, description } = reason;
      return (
        <Option key={id} value={id}>
          {description}
        </Option>
      );
    });
  };

  const handleCancel = () => {
    setSelectError(false);
    setSelectedReason("");
    setCloseNote("");
    setVisible(false);
  };

  const handleSelect = (option) => {
    setSelectedReason(option);
    if (option) {
      setSelectError(false);
    }
  };

  const handleSubmit = () => {
    if (!selectedReason) {
      setSelectError(true);
    } else {
      lead.close(selectedReason, closeNote);
      setVisible(false);
    }
  };

  return (
    <ModalContainer
      visible={visible}
      title="Close Lead"
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      submitText="Save"
      zIndex={2000}
    >
      <div className={styles.closeleadModal}>
        <div className={styles.itemContainer}>
          <div className={styles.label}>Please select a reason for closing this project</div>
          <div className={styles.selectContainer}>
            <Select className={styles.select} value={selectedReason} onSelect={handleSelect}>
              {createCloseOptions(closeReasons)}
            </Select>
          </div>
          {selectError && (
            <div className={styles.error}>Please select a reason from the dropdown above before clicking confirm</div>
          )}
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.label}>
            Provide any additional details about why this project is being closed (optional)
          </div>
          <div className={styles.selectContainer}>
            <TextArea
              className={styles.textArea}
              value={closeNote}
              placeholder="(max 300 characters)"
              onChange={(e) => {
                setCloseNote(e.target.value);
              }}
              autoSize={{ minRows: 3 }}
              maxLength={300}
            />
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

CloseLeadModal.propTypes = {
  leadDetails: PropTypes.oneOfType([PropTypes.instanceOf(Project), PropTypes.instanceOf(Lead)]),
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  closeReasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      order: PropTypes.number,
    }),
  ),
};

export default CloseLeadModal;
