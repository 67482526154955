import React from "react";
import { IconT } from "types/utils";

const ProjectAlert = ({ height = "18px", width = "18px" }: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18" fill="none">
      <circle cx="9" cy="9" r="9" fill="var(--safety-orange)" />
      <path
        d="M9.31326 10.4519C9.30576 10.4311 9.29582 10.4114 9.28377 10.3929C9.27074 10.3745 9.2564 10.357 9.24086 10.3407C9.16084 10.2607 9.04645 10.2258 8.93563 10.2476C8.91319 10.2523 8.89127 10.2591 8.87008 10.268C8.85014 10.2756 8.83118 10.2855 8.81355 10.2976C8.79479 10.3101 8.77741 10.3245 8.76155 10.3407C8.7297 10.3724 8.70436 10.4102 8.68705 10.4519C8.67968 10.4726 8.67363 10.4939 8.66896 10.5154C8.66435 10.5371 8.66204 10.5592 8.66211 10.5813C8.6627 10.6952 8.71937 10.8015 8.81355 10.865C8.83118 10.877 8.8502 10.8869 8.87008 10.8945C8.89127 10.9033 8.91319 10.9101 8.93563 10.9148C8.97887 10.924 9.02356 10.924 9.06679 10.9148C9.08858 10.9105 9.10984 10.9036 9.13017 10.8945C9.15077 10.8869 9.17045 10.877 9.18888 10.865C9.20717 10.8519 9.22455 10.8375 9.24087 10.8219C9.30451 10.7581 9.34031 10.6715 9.34031 10.5813C9.34038 10.5367 9.33116 10.4926 9.31326 10.4518L9.31326 10.4519Z"
        fill="white"
      />
      <path
        d="M8.94449 7.58287C8.82489 7.59743 8.71599 7.65907 8.64163 7.75416C8.56733 7.84932 8.53372 7.97016 8.54823 8.09016L8.75512 9.80288C8.76483 9.88335 8.81334 9.95395 8.88488 9.99162C8.95643 10.0293 9.0419 10.0293 9.11345 9.99162C9.185 9.95395 9.2335 9.88335 9.24322 9.80288L9.45011 8.09016C9.45431 8.05374 9.45431 8.01693 9.45011 7.98051C9.4356 7.86052 9.37423 7.75119 9.27938 7.67666C9.1846 7.60211 9.06414 7.56839 8.94448 7.58288L8.94449 7.58287Z"
        fill="white"
      />
      <path
        d="M12.5941 10.8179L9.53498 5.40153C9.44282 5.23827 9.29586 5.11316 9.12026 5.04854C8.94475 4.98387 8.75196 4.9838 8.57645 5.04847C8.40086 5.11307 8.2539 5.2382 8.16166 5.4014L5.10255 10.8179C4.96412 11.063 4.96596 11.3634 5.10734 11.6068C5.24873 11.8503 5.5084 12 5.7892 12H11.9076C12.1884 12 12.4481 11.8502 12.5895 11.6068C12.7308 11.3634 12.7327 11.063 12.5942 10.8179L12.5941 10.8179ZM12.0049 11.2651C11.9852 11.3004 11.9478 11.322 11.9074 11.3213H5.78905C5.74895 11.3213 5.71186 11.2999 5.69164 11.2652C5.67143 11.2304 5.67116 11.1876 5.69092 11.1525L8.7502 5.73604C8.77022 5.70054 8.8077 5.67868 8.84827 5.67868C8.8889 5.67874 8.92638 5.70067 8.94633 5.7361L12.0054 11.1526H12.0055C12.026 11.1874 12.0257 11.2306 12.0049 11.2651L12.0049 11.2651Z"
        fill="white"
      />
    </svg>
  );
};

export default ProjectAlert;
