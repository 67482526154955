import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form, Row, Col } from "antd";
import { useEffectOnce } from "react-use";
import Registration from "models/Registration";
import SelectInput from "components/FormElements/SelectInput";
import GradientButton from "components/Buttons/GradientButton";
import getDays from "lib/helpers/getDays";
import { getMonths } from "lib/helpers/getMonths";
import getLastOneHundredYears from "lib/helpers/getLastOneHundredYears";
import styles from "./ContactInfo.module.less";

const DateOfBirthForm = ({ continueToNextStep, regInfo }) => {
  const { t } = useTranslation(["join", "form_labels"]);
  const [form] = Form.useForm();
  const [days, setDays] = useState([]);
  const [ageError, setAgeError] = useState(false);
  const minimumAge = 18;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();

  useEffectOnce(() => {
    setDays(getDays(form.getFieldValue("year"), form.getFieldValue("month")));

    if (regInfo?.dob) {
      const dobArray = regInfo.dob.split("-");
      form.setFieldsValue({
        year: dobArray[0],
        month: dobArray[1],
        day: dobArray[2],
      });
    }
  });

  const validateMinimumAge = () => {
    const values = form.getFieldsValue();
    const { month, day, year } = values;

    if (ageError) setAgeError(false);

    if (currentYear - year < minimumAge) {
      setAgeError(true);
    }

    if (currentYear - year === minimumAge) {
      if (currentMonth < month) {
        setAgeError(true);
      }
      if (currentMonth === month) {
        if (currentDay < day) {
          setAgeError(true);
        }
      }
    }
  };

  const handleChange = () => {
    setDays(getDays(form.getFieldValue("year"), form.getFieldValue("month")));
    validateMinimumAge();
  };

  const onContinue = () => {
    const values = form.getFieldsValue();
    continueToNextStep(values);
  };

  return (
    <div className={styles.dateOfBirthFormContainer} data-test-id="dob-form">
      <Form form={form} className={styles.dateOfBirth}>
        <div>
          <div className={styles.label}>{t("join_form.date_of_birth")}</div>
          <Row gutter={8}>
            <Col span={22}>
              <SelectInput itemName="month" placeholder="Month" onChange={handleChange} choices={getMonths()} />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={11}>
              <SelectInput itemName="day" placeholder="Day" choices={days} onChange={handleChange} />
            </Col>
            <Col span={11}>
              <SelectInput
                itemName="year"
                placeholder="Year"
                choices={getLastOneHundredYears}
                onChange={handleChange}
                status={ageError ? "error" : ""}
              />
            </Col>
          </Row>
        </div>
        {ageError && (
          <div className={styles.error}>
            You do not meet the age requirements. The Powur Platform is for users 18 and older.
          </div>
        )}
        <div>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsValue }) => {
              const { month, day, year } = getFieldsValue();
              const formIsComplete = !!month && !!day && !!year;
              return (
                <GradientButton
                  type="primary"
                  onClick={onContinue}
                  className={styles.contactInfoSubmit}
                  disabled={!formIsComplete || ageError}
                >
                  {t("join_form.continue")}
                </GradientButton>
              );
            }}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

DateOfBirthForm.propTypes = {
  continueToNextStep: PropTypes.func,
  regInfo: Registration.types(),
};

export default DateOfBirthForm;
