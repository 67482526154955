type CaseType = "snake" | "camel";

export default function humanizeText(str: string, textCase?: CaseType) {
  if (textCase === "camel") {
    return str.replace(/([A-Z])/g, " $1").replace(/^./, (char) => char.toUpperCase());
  }
  return str
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .replace(/^[a-z]/, (m) => {
      return m.toUpperCase();
    });
}
