import {
  FundingProposalsReducerStateT,
  FundingProposalListItemT,
  FundingProposalPagingT,
} from "types/Project/fundingProposal";

type DefaultStateT = {
  fundingProposals: FundingProposalsReducerStateT;
};

export default {
  selectFundingProposalsList: (state: DefaultStateT): FundingProposalListItemT[] =>
    state.fundingProposals.adminList.list,
  selectFundingProposalsPaging: (state: DefaultStateT): FundingProposalPagingT =>
    state.fundingProposals.adminList.paging,
};
