import { all, put, select } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { keysToCamelCase, camelizeKeys } from "lib/helpers";
import SchemaValidator from "util/schema-helpers/SchemaValidator";
import FEATURE_FLAGS from "util/featureFlags";
import messages from "../messages";
import { ProjectProviderLeadResponse } from "../schemas/projects/post-sales/index";

function* getProviderLeadDetails(action) {
  const { providerLeadId, enterprise, filters } = action.payload;
  const { success, data, error } = yield* makeRequest.get(
    `/provider_leads/${providerLeadId}`,
    Object.assign(enterprise ? { enterprise: true, ...filters } : { ...filters }),
  );
  const l2pProjectId = yield select(selectors.selectLeadToProposalDrawerProjectId);
  const projectDetails = yield select(selectors.getProjectDetails);
  const projectDetailsId = projectDetails?.providerLead?.id;

  if (FEATURE_FLAGS.includes("providerLeadSchema")) {
    SchemaValidator.validate({
      schema: ProjectProviderLeadResponse,
      schemaName: "ProjectProviderLeadResponse",
      dto: camelizeKeys(_.clone(data.body)),
    });
  }

  if (success && data) {
    const providerLead = camelizeKeys(_.clone(data.body));
    const preSalesList = yield select(selectors.getProjectPreSales);
    yield all([
      ...(l2pProjectId === providerLeadId
        ? [put(actions.setLeadToProposalDrawerProjectDetails(camelizeKeys(data.body)))]
        : []),
      ...(!projectDetailsId || projectDetailsId === providerLeadId
        ? [
            put(
              actions.setProjectDetails({
                providerLead: {
                  ...providerLead,

                  preferredInstallers: camelizeKeys(_.clone(providerLead.preferredInstallers)),
                  projectAtGlance: camelizeKeys({
                    ...providerLead.projectAtGlance,
                    projectNotifications: camelizeKeys(providerLead.projectAtGlance.projectNotifications),
                  }),
                },
                lead: keysToCamelCase(data.body.lead),
              }),
            ),
          ]
        : []),
      // Update the project in pre-sales without the need to refresh
      ...(preSalesList.projects.length
        ? [
            put(
              actions.setProjectsPreSales({
                ...preSalesList,
                projects: updateProjectPreSale(preSalesList, providerLead, providerLeadId),
              }),
            ),
          ]
        : []),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROJECT_DETAILS,
    });
  }
  return null;
}

const updateProjectPreSale = (preSaleProjects, projectDetails, id) => {
  const { upcomingStageNames, projectProgress, visionProposalNonrequestableReason } = projectDetails;
  const milestoneDisplayName = projectProgress.at(-1);

  return preSaleProjects.projects.map((project) =>
    project.projectId === id
      ? {
          ...project,
          upcomingStageNames,
          passedStageNames: projectProgress,
          visionProposalNonrequestableReason,
          milestoneDisplayName,
        }
      : project,
  );
};

export default getProviderLeadDetails;
