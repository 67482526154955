import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/settings/types";
import getSettings from "./getSettings";
import updateSetting from "./updateSetting";
import getAdminServiceAreas from "./getAdminServiceAreas";
import updateAdminServiceArea from "./updateAdminServiceArea";
import removeAdminServiceArea from "./removeAdminServiceArea";
import createAdminServiceArea from "./createAdminServiceArea";
import getPowurPairWeights from "./getPowurPairWeights";
import updatePowurPairWeights from "./updatePowurPairWeights";
import getAdminVolumeTiersSettings from "./getAdminVolumeTiersSettings";
import updateAdminVolumeTiersSettings from "./updateAdminVolumeTiersSettings";
import getAdminAdvanceSettings from "./getAdminAdvanceSettings";
import createAdminAdvanceSetting from "./createAdminAdvanceSetting";
import updateAdminAdvanceSetting from "./updateAdminAdvanceSetting";
import removeAdminAdvanceSetting from "./removeAdminAdvanceSetting";
// IMPORT_PT (for script, do not remove)

function* watchSettingsSagas() {
  yield trackRequests(takeEvery, types.GET_ADMIN_PLATFORM_SETTINGS_REQUEST, getSettings);
  yield trackRequests(takeEvery, types.UPDATE_ADMIN_PLATFORM_SETTING_REQUEST, updateSetting);
  yield trackRequests(takeEvery, types.GET_ADMIN_SERVICE_AREAS_REQUEST, getAdminServiceAreas);
  yield trackRequests(takeEvery, types.UPDATE_ADMIN_SERVICE_AREA_REQUEST, updateAdminServiceArea);
  yield trackRequests(takeEvery, types.REMOVE_ADMIN_SERVICE_AREA_REQUEST, removeAdminServiceArea);
  yield trackRequests(takeEvery, types.CREATE_ADMIN_SERVICE_AREA_REQUEST, createAdminServiceArea);
  yield trackRequests(takeEvery, types.GET_POWUR_PAIR_WEIGHTS, getPowurPairWeights);
  yield trackRequests(takeEvery, types.UPDATE_POWUR_PAIR_WEIGHTS, updatePowurPairWeights);
  yield trackRequests(takeEvery, types.GET_ADMIN_VOLUME_TIERS_SETTINGS, getAdminVolumeTiersSettings);
  yield trackRequests(takeEvery, types.UPDATE_ADMIN_VOLUME_TIERS_SETTINGS, updateAdminVolumeTiersSettings);
  yield trackRequests(takeEvery, types.GET_ADMIN_ADVANCE_SETTINGS, getAdminAdvanceSettings);
  yield trackRequests(takeEvery, types.CREATE_ADMIN_ADVANCE_SETTING, createAdminAdvanceSetting);
  yield trackRequests(takeEvery, types.UPDATE_ADMIN_ADVANCE_SETTING, updateAdminAdvanceSetting);
  yield trackRequests(takeEvery, types.REMOVE_ADMIN_ADVANCE_SETTING, removeAdminAdvanceSetting);
  // INSERTION_PT (for script, do not remove)
}

export default watchSettingsSagas;
