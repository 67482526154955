import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import { Layout } from "antd";
import { Helmet } from "react-helmet";
import { Header, Footer, GetQuoteModal, UtilityBillModal, JoinModal } from "components/HomepageComponents";
import ScrollToTop from "containers/router/scrollToTop";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { parse } from "query-string";
import VideoBanner from "./components/VideoBanner";
import InfoPanels from "./components/InfoPanels";
import JoinSection from "./components/joinBanner";
import styles from "./EnterpriseJoin.module.less";

const { Content } = Layout;

const EnterpriseSellerJoin = (props) => {
  const { selectPageReferrer, getPageReferrer } = props;
  const history = useHistory();
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const location = useLocation();
  const { ref_id } = parse(location.search);
  const { user: slug } = useParams();
  useEffect(() => {
    if (ref_id || slug) {
      getPageReferrer({
        ref_id,
        slug,
        pageName: "home",
        history,
      });
    }
  }, [getPageReferrer, ref_id, slug, history]);
  // ENDS call with "[app] SET page referrer"

  const panels = [
    [
      {
        section: "innovate_tech",
        imgUrl: "url(/img/enterprise/seller/innovate_tech_section.png)",
      },
      {
        section: "represent_the_best",
        imgUrl: "url(/img/enterprise/seller/img_block2.png)",
      },
      {
        section: "knowledge_is_powur",
        imgUrl: "url(/img/enterprise/seller/img_block3.png)",
      },
      {
        section: "advance_your_career",
        imgUrl: "url(/img/enterprise/seller/img_block4.png)",
      },
    ],
  ];

  return (
    <Layout className={styles.pageLayout}>
      <Helmet>
        <title>Powur: The best solar organizations grow faster with Powur</title>
        <meta name="title" content="Powur: The Best Solar Organizations grow faster with Powur" />
        <meta
          name="description"
          content="Powur’s revolutionary software gives you control over how you and your team earns money, bonuses and incentives."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://powur.com/join" />
        <meta property="og:title" content="Powur: The Best Solar Organizations grow faster with Powur" />
        <meta
          property="og:description"
          content="Powur’s revolutionary software gives you control over how you and your team earns money, bonuses and incentives."
        />
        <meta property="og:image" content="/img/powur_fb_01.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://powur.com/join" />
        <meta property="twitter:title" content="Powur: The Best Solar Organizations grow faster with Powur" />
        <meta
          property="twitter:description"
          content="Powur’s revolutionary software gives you control over how you and your team earns money, bonuses and incentives."
        />
        <meta property="twitter:image" content="/img/powur_fb_01.jpg" />
      </Helmet>
      <ScrollToTop />
      <Header user={selectPageReferrer} />
      <Content className={styles.content}>
        <VideoBanner openModal={setRegisterModalVisible} org={selectPageReferrer?.org.name} />
        <InfoPanels panels={panels[0]} />
        <JoinSection openModal={setRegisterModalVisible} org={selectPageReferrer?.org.name} />
        <JoinModal
          visible={registerModalVisible}
          setVisible={setRegisterModalVisible}
          org={selectPageReferrer?.org.name}
        />
      </Content>
      <Footer />
      <GetQuoteModal />
      <UtilityBillModal />
    </Layout>
  );
};

export default connect(
  getRdxSelectionMapper({
    selectPageReferrer: "selectPageReferrer",
  }),
  getRdxActionMapper(["getPageReferrer"]),
)(EnterpriseSellerJoin);
