import { put, all } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* updateAdminNoticeDetail(action) {
  const { notice_id, column_value, notice_column } = action.payload;

  const { success, data, error } = yield* makeRequest.patch(`/notices/${notice_id}`, {
    [notice_column]: column_value,
  });
  if (success && data) {
    const out = keysToCamelCase(_.clone(data.body));
    yield all([
      put(actions.setAdminNoticeDetails(out)),
      put(
        actions.newSuccessEvent({
          message: messages.SUCCESS_UPDATING_ADMIN_NOTICE_DETAIL,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_ADMIN_NOTICE_DETAIL,
    });
  }
  return null;
}

export default updateAdminNoticeDetail;
