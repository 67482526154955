import { put, all, select } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import selectors from "rdx/modules/adminProposals/selectors";
import actions from "rdx/actions";
import messages from "rdx/modules/adminProposals/messages";

function* markProposalAsRejected(action) {
  const { id, reason } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/proposal_flows/${id}/deny_revision_request`, {
    reason,
  });

  if (success && data) {
    const { projects } = yield select(selectors.getInProgressProposalsList);
    const nextProjects = _.clone(projects).filter((p) => p.project.id !== id);
    yield all([
      put(actions.setInProgressAdminProposals({ projects: nextProjects })),
      put(actions.newSuccessEvent({ message: messages.PROPOSAL_REJECTED })),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_MARKING_REJECTED,
    });
  }

  return null;
}

export default markProposalAsRejected;
