import { put, select } from "redux-saga/effects";
import selectors from "rdx/modules/filterSets/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/filterSets/messages";

function* deleteFilterSet(action) {
  const { id } = action.payload;

  const { success, data, error } = yield* makeRequest.delete(`/filter_sets/${id}`, {});

  if (success && data) {
    const current = yield select(selectors.getFilterSets);
    const filterSets = current.filter((f) => f.id !== id);

    yield put(
      actions.newSuccessEvent({
        message: messages.FILTER_SET_DELETE_SUCCESS,
      }),
    );
    yield put(actions.setFilterSets({ filterSets }));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_FILTER_SET,
    });
  }
  return null;
}

export default deleteFilterSet;
