import usePermissions, { permissionTypes } from "hooks/usePermissions";

/**
 * Returns a boolean to inform if the RSM (Regional Sales Manager)
 * requirements have been met at a project and user level.
 * If the hook returns true, it means that
 * the user can take action on RSM (Regional Sales Manager) actions.
 * @param {boolean} projectRequiresRsm Does the project require an RSM (Regional Sales Manager)
 * @returns {boolean} Whether or not the RSM (Regional Sales Manager) requirements have been met
 */
const useRsmRequirementsMet = (projectRequiresRsm: boolean): boolean => {
  const { NEVADA_SELLER: hasNevadaSellerPerm, RSM_AUTHORITY: hasRsmAuthority } = usePermissions({
    permissionRequests: [permissionTypes.NEVADA_SELLER, permissionTypes.RSM_AUTHORITY],
  });

  if (!projectRequiresRsm) return true;

  return hasNevadaSellerPerm || hasRsmAuthority;
};

export default useRsmRequirementsMet;
