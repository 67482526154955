import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToSnakeCase } from "lib/helpers";
import messages from "../messages";

function* createNewAmbassadorUser(action) {
  const { success, data, error } = yield* makeRequest.post("/users", {
    ...keysToSnakeCase(action.payload),
    kind: "ambassador",
    service_id: "powur_energy",
  });

  if (success && data) {
    yield put(
      actions.newSuccessEvent({
        message: messages.CREATE_NEW_AMBASSADOR_USER_SUCCESS,
        payload: data,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_NEW_AMBASSADOR_USER,
    });
  }

  return null;
}

export default createNewAmbassadorUser;
