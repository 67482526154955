import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import { filterStyledProps } from "lib/helpers/filterStyledProps";
import { cvar } from "styles";
import { theme } from "styles/themes";

type AdornmentBorderT = {
  border?: boolean;
};

type TextFieldPropsT = {
  hasPrefix: boolean;
  hasSuffix: boolean;
  isLargeText: boolean;
  noMarginBlockEnd: boolean;
  redHighlight: boolean;
};

export const LabelGroupWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
}));

export const StyledFormLabel = styled("div")(() => ({
  fontFamily: "Barlow",
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "9px",
  marginBottom: "8px",
  color: cvar("nepal-blue"),
  textTransform: "uppercase",
}));

export const PrefixContainer = styled("div")(() => ({
  borderRight: `1px solid ${cvar("botticelli-blue")}`,
  paddingRight: "7px",
  marginLeft: "-5px",
  height: "32px",
  width: "25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledRequiredLabel = styled("p")(() => ({
  fontFamily: "Barlow",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "9px",
  color: cvar("commission-clawback"),
  width: "10px",
  margin: 0,
  textAlign: "center",
}));

export const StyledTextField = styled(TextField, {
  shouldForwardProp: filterStyledProps(["hasPrefix", "hasSuffix", "isLargeText", "noMarginBlockEnd", "redHighlight"]),
})(({ hasPrefix, hasSuffix, isLargeText, noMarginBlockEnd, redHighlight }: TextFieldPropsT) => ({
  marginBottom: noMarginBlockEnd ? 0 : "12px",
  "& .MuiOutlinedInput-root": {
    "& input": {
      color: cvar("dark-blue"),
      fontSize: isLargeText ? "24px" : "14px",
      fontWeight: isLargeText ? 600 : 500,
      padding: "7px 14px 8px",
      ...(hasPrefix && {
        paddingLeft: "0px",
      }),
      ...(hasSuffix && {
        paddingRight: "0px",
      }),
      lineHeight: "17px",
      height: "17px",
    },
    "& > fieldset": {
      borderColor: redHighlight ? theme.colors.danger : theme.colors["botticelli-blue"],
    },
    "&.Mui-focused fieldset": {
      borderColor: redHighlight ? theme.colors.danger : theme.colors["botticelli-blue"],
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `${redHighlight ? theme.colors.danger : theme.colors["botticelli-blue"]} !important`,
  },
  "& .Mui-disabled": {
    background: theme.colors["cotton-boll"],
    borderRadius: "3px",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: cvar("queen-blue"),
  },
  "& .MuiOutlinedInput-root input": {
    borderRadius: "6px",
    "&:not(:disabled)": {
      background: redHighlight ? theme.colors.bridesmaid : theme.colors.white,
    },
  },
}));

export const AdornmentWrapper = {
  color: cvar("dark-blue"),
  fontFamily: "Barlow",
  fontWeight: 500,
  height: "32px",
  display: "flex",
  alignItems: "center",
};

export const PrefixWrapper = styled("span")(({ border }: AdornmentBorderT) => ({
  ...AdornmentWrapper,
  ...(border && {
    borderRight: `1px solid ${cvar("botticelli-blue")}`,
    paddingRight: "10px",
  }),
  marginLeft: "-2px",
}));

export const SuffixWrapper = styled("span")(({ border }: AdornmentBorderT) => ({
  ...AdornmentWrapper,
  ...(border && {
    borderLeft: `1px solid ${cvar("botticelli-blue")}`,
    paddingLeft: "10px",
  }),
  marginRight: "-2px",
}));

export const TooltipText = styled("div")(() => ({
  fontSize: "16px",
  padding: "3px",
}));

export const TooltipIcon = styled("div")(() => ({
  transform: "matrix(1, 0, 0, -1, 0, 0)",
  fontSize: "10px",
  lineHeight: "10px",
  fontWeight: 500,
  color: theme.colors["graham-grey"],
  border: `1px solid ${theme.colors["graham-grey"]}`,
  width: "14px",
  height: "14px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "8px",
  marginTop: "-2px",
}));
