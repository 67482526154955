import React from "react";

const CheckMarkBullet = ({ width, height, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 12"
      style={{ minWidth: width, minHeight: height }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6167 0.305494C11.9732 -0.176766 11.0368 -0.0771733 10.5247 0.527407L4.7148 8.75185L2.17878 6.367C1.65699 5.87483 0.837857 5.85003 0.349012 6.30915C-0.138954 6.76785 -0.112162 7.53856 0.410063 8.03032L4.2343 11.6293C4.68142 12.0492 5.33321 12.1058 5.82249 11.8326C6.03331 11.7442 6.22657 11.6148 6.38073 11.4334L12.8517 2.27255C13.3647 1.66838 13.2579 0.788166 12.6167 0.305494Z"
        fill={fill}
      />
    </svg>
  );
};

CheckMarkBullet.defaultProps = {
  width: "14",
  height: "12",
  fill: "var(--primary-lite)",
};

export default CheckMarkBullet;
