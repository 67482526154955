import types from "rdx/modules/customFields/actionTypes";
import createAction from "rdx/utils/createAction";

export default {
  getCustomFields: (payload) => createAction(types.GET_CUSTOM_FIELDS, payload),

  setCustomFields: (payload) => createAction(types.SET_CUSTOM_FIELDS, payload),

  createCustomField: (payload) => createAction(types.CREATE_CUSTOM_FIELD, payload),

  updateCustomField: (payload) => createAction(types.UPDATE_CUSTOM_FIELD, payload),

  deleteCustomField: (payload) => createAction(types.DELETE_CUSTOM_FIELD, payload),

  // INSERTION_PT (for script, do not remove)
};
