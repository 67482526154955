import { put, all, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "rdx/modules/sellerIntegrity/messages";
import { camelizeKeys, keysToSnakeCase, formatIntegrityModules } from "lib/helpers";
import { SellerIntegrityViolationDataT, ViolationT } from "containers/Admin/users/components/UserDetailDrawer/types";

type CreateIntegrityViolationPropsT = {
  payload: SellerIntegrityViolationDataT;
};

function* createIntegrityViolation(action: CreateIntegrityViolationPropsT) {
  const params = keysToSnakeCase(action.payload);
  const { success, data, error } = yield* makeRequest.post("/seller_integrity_violations", params);

  if (success && data) {
    const violations: ViolationT[] = yield select(selectors.selectIntegrityViolations);
    const violationCount: number = yield select(selectors.selectIntegrityViolationCount);
    const newViolation = camelizeKeys(data.body);
    newViolation.violationModules = formatIntegrityModules(newViolation.violationModules);
    const clone: ViolationT[] = [...violations];
    clone.unshift(newViolation);

    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.CREATE_INTEGRITY_VIOLATION_SUCCESS,
        }),
      ),
      put(
        actions.setIntegrityViolations({
          violations: clone,
          violationCount: violationCount + 1,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_INTEGRITY_VIOLATION,
    });
  }

  return null;
}

export default createIntegrityViolation;
