import types from "rdx/modules/publicApiProxy/types";
import createAction from "rdx/utils/createAction";

export default {
  // public api proxy
  createAppClientToken: (payload) => createAction(types.CREATE_APPCLIENT_TOKEN_REQUEST, payload),
  getAppClientToken: (payload) => createAction(types.GET_APPCLIENT_TOKEN_REQUEST, payload),
  setAppClientToken: (payload) => createAction(types.SET_APPCLIENT_TOKEN, payload),

  // google oauth
  getGoogleAuthorizeUrl: (payload) => createAction(types.GET_GOOGLE_AUTHORIZE_URL_REQUEST, payload),
  setGoogleAuthorizeUrl: (payload) => createAction(types.SET_GOOGLE_AUTHORIZE_URL, payload),

  exchangeGoogleCallback: (payload) => createAction(types.EXCHANGE_GOOGLE_CALLBACK_REQUEST, payload),

  getGoogleAccessStatus: (payload) => createAction(types.GET_GOOGLE_ACCESS_STATUS_REQUEST, payload),
  setGoogleAccessStatus: (payload) => createAction(types.SET_GOOGLE_ACCESS_STATUS, payload),

  // crm
  getGoogleUser: (payload) => createAction(types.GET_GOOGLE_USER_REQUEST, payload),
  setGoogleUser: (payload) => createAction(types.SET_GOOGLE_USER, payload),

  setUserEmailIntegrationType: (payload) => createAction(types.SET_USER_EMAIL_INTEGRATION_TYPE_REQUEST, payload),

  sendGmail: (payload) => createAction(types.SEND_GMAIL_REQUEST, payload),
};
