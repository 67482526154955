import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  messagingMetrics: {
    teams: [],
    hierarchicalRoles: [],
    filters: {},
  },
  consultantReport: {
    consultants: [],
    topLevelReport: {},
    initialDate: null,
    autocompleteSuggestions: [],
  },
  activityLog: {
    loggedActions: [],
    paging: {},
    filters: {},
    autocompleteSuggestions: {},
  },
  activityLogCsvData: {},
  preferredPartnerCsv: {},
  ticketList: {
    messages: [],
    paging: {},
    observingAccount: {},
  },
};

export default {
  adminReports: createReducer(initialState, {
    [types.SET_MESSAGING_METRICS](state, action) {
      return {
        ...state,
        messagingMetrics: {
          ...state.messagingMetrics,
          teams: action.payload,
        },
      };
    },
    [types.SET_MESSAGING_METRICS_ROLES](state, action) {
      return {
        ...state,
        messagingMetrics: {
          ...state.messagingMetrics,
          hierarchicalRoles: action.payload,
        },
      };
    },
    [types.SET_MESSAGING_METRICS_FILTERS](state, action) {
      return {
        ...state,
        messagingMetrics: {
          ...state.messagingMetrics,
          filters: action.payload,
        },
      };
    },
    [types.SET_CONSULTANT_REPORT](state, action) {
      return {
        ...state,
        consultantReport: {
          ...state.consultantReport,
          ...action.payload,
        },
      };
    },
    [types.SET_CONSULTANT_REPORT_USER_LOOKUPS](state, action) {
      return {
        ...state,
        consultantReport: {
          ...state.consultantReport,
          autocompleteSuggestions: action.payload.suggestions,
        },
      };
    },
    [types.RESET_CONSULTANT_REPORT_USER_LOOKUPS](state) {
      return {
        ...state,
        consultantReport: {
          ...state.consultantReport,
          autocompleteSuggestions: [],
        },
      };
    },
    [types.SET_ACTIVITY_LOG](state, action) {
      return {
        ...state,
        activityLog: {
          ...state.activityLog,
          ...action.payload,
          autocompleteSuggestions: {},
        },
      };
    },
    [types.SET_USERS_PROJECTS_AUTOCOMPLETE](state, action) {
      return {
        ...state,
        activityLog: {
          ...state.activityLog,
          autocompleteSuggestions: {
            ...state.activityLog.autocompleteSuggestions,
            projects: action.payload.projects,
            users: action.payload.users,
          },
        },
      };
    },
    [types.SET_ACTIVITY_LOG_CSV](state, action) {
      return {
        ...state,
        activityLogCsvData: action.payload,
      };
    },
    [types.SET_PREFERRED_PARTNER_CSV](state, action) {
      return {
        ...state,
        preferredPartnerCsv: action.payload,
      };
    },
    [types.SET_TICKET_LIST](state, action) {
      return {
        ...state,
        ticketList: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
