import React from "react";
import { IconT } from "types/utils";

const ApprovedCheckIcon = (props: IconT) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={24} height={24} rx={12} fill="url(#approved-check-icon_svg__a)" />
    <path
      d="M17.424 6.093a1.376 1.376 0 0 0-1.977.23l-5.49 8.533-2.396-2.474c-.493-.511-1.267-.537-1.729-.06-.46.476-.435 1.275.058 1.785l3.614 3.734a1.2 1.2 0 0 0 1.5.211c.2-.092.382-.226.528-.414l6.114-9.504a1.485 1.485 0 0 0-.222-2.041z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="approved-check-icon_svg__a"
        x1={-5.263}
        y1={2.939}
        x2={28.432}
        y2={13.739}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#07469A" />
        <stop offset={0.417} stopColor="#0070F4" />
        <stop offset={1} stopColor="#51F2CB" stopOpacity={0.83} />
      </linearGradient>
    </defs>
  </svg>
);

export default ApprovedCheckIcon;
