import { DashboardIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";
import SessionClient from "util/SessionClient";

const content = l(() => import("containers/Enterprise/enterpriseDashboard"));

const { ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN } = permissionTypes;

const session = new SessionClient();

export default {
  key: "org-dashboard",
  title: `${session?.user?.props?.enterpriseName || ""} Dashboard`,
  path: "/org-dashboard",
  exact: true,
  linkTarget: "/org-dashboard",
  linkTitle: "Dashboard",
  linkCategory: "Enterprise",
  icon: DashboardIcon,
  permission: [ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN],
  basePath: "org-dashboard",
  requiredParams: [],
  optionalParams: [],
  tabs: null,
  content,
  sidebar: null,
};
