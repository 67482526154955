import { useContext } from "react";
import { WindowDimensionContext } from "containers/router/WindowDimensionProvider";

/**
 * Use window resize detects changes in viewports size
 * @returns {{ width: number, height: number, TABLET_BREAK_POINT: number, MOBILE_BREAK_POINT: number, isTabletWidth: bool, isMobileWidth: bool }}
 * width - window.innerWidth
 * height - window.innerHeight
 * TABLET_BREAK_POINT - tablet breakpoint (768)
 * MOBILE_BREAK_POINT - mobile breakpoint (500)
 * isTabletWidth - window.innerWidth < TABLET_BREAK_POINT
 * isMobileWidth - window.innerWidth < MOBILE_BREAK_POINT
 */

const useWindowResize = () => {
  const dim = useContext(WindowDimensionContext);
  return dim;
};

export default useWindowResize;
