// import content from "containers/Enterprise/enterpriseProjects";
import tabs from "containers/Enterprise/enterpriseProjects/tabs";
import sidebar from "containers/Enterprise/enterpriseProjects/Sidebar";
import { ProjectsIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import breadCrumbs from "components/PlatformLayout/components/PlatformPageHeader/Breadcrumbs/ProjectBreadCrumbs";
import l from "@loadable/component";

const content = l(() => import("containers/Enterprise/enterpriseProjects"));
// const sidebar = l(() =>
//   import("containers/Enterprise/enterpriseProjects/Sidebar"),
// );

const { ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN, ENTERPRISE_LEAD_GENERATOR } = permissionTypes;

export default {
  key: "org-projects",
  title: breadCrumbs,
  path: "/org-projects/:tab?",
  exact: true,
  basePath: "org-projects",
  requiredParams: ["tab"],
  optionalParams: ["leadId"],
  linkTarget: "/org-projects",
  linkTitle: "Projects",
  linkCategory: "Enterprise",
  icon: ProjectsIcon,
  permission: [ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN, ENTERPRISE_LEAD_GENERATOR],
  tabs,
  content,
  sidebar,
};
