import { Nullable } from "types/helpers";
import type { CrmActivityTypeT, CrmDrawerActivityTypeT } from "rdx/modules/projects/types/projects/pre-sales";
import { ProjectBlockerSlug } from "rdx/modules/projectBlockers/enums";

export type HomeownerAvailabilityT = {
  id: number;
  days: string[];
  startTime: string;
  endTime: string;
};

export type ActivityLeadAddressT = {
  city: string;
  state: string;
};

export type ActivityLeadInfoT = {
  id: number;
  address: ActivityLeadAddressT;
  email: string;
  firstName: string;
  lastName: string;
};

export enum CrmActivityTypeEnum {
  CALL = "call",
  EMAIL = "email",
  MEETING = "meeting",
  REMINDER = "reminder",
  BLOCKER = "blocker",
}

export type CrmActivityFormDataT = {
  leadId: number;
  kind: CrmDrawerActivityTypeT;
  title: string;
  meetingType?: string | null;
  meetingInfo: string;
  location: string;
  email: string;
  due: string;
  reminder: string;
  reminderType: string[];
  notes: string;
  canDelete?: boolean;
};

export type CrmActivityUpdatePayloadT = {
  activityId: number;
  leadId: number;
  title?: string;
  kind?: string;
  due?: string;
  reminder?: string;
  email?: string;
  notes?: string;
  location?: string;
  reminderType?: string[];
  meetingType?: string | null;
  meetingInfo?: string;
  completeType?: string;
  archivedAt?: string;
  completedAt?: string;
};

export type GetCrmTaskListPayloadT = {
  projectType?: "pre_sales" | "post_sales";
  filters?: Record<string, unknown>;
};

export enum CrmIconTypeEnum {
  NEW = "new",
  SCHEDULED = "scheduled",
  WARNING = "warning",
  PROMPT = "prompt",
  ALERT = "alert",
}

export type CrmIconTypeT =
  | CrmIconTypeEnum.NEW
  | CrmIconTypeEnum.SCHEDULED
  | CrmIconTypeEnum.WARNING
  | CrmIconTypeEnum.PROMPT
  | CrmIconTypeEnum.ALERT;

export type CrmActivityDrawerT = {
  visible: boolean;
  setVisible: (bool: boolean) => void;
  activityType: CrmDrawerActivityTypeT;
};

export type CrmActivityT = {
  id: number;
  leadId: number;
  title: string;
  kind: CrmActivityTypeT;
  meetingType: string;
  meetingInfo: string;
  location: string;
  email: string;
  due: string;
  reminder: string;
  reminderType: string[];
  notes: string;
  completeType?: Nullable<string>;
  completedAt: Nullable<string>;
  createdAt: string;
  updatedAt: Nullable<string>;
  homeownerAvailabilities?: HomeownerAvailabilityT[];
  archivedAt?: Nullable<string>;
  leadInfo?: ActivityLeadInfoT;
  canDelete?: boolean;
  slug?: ProjectBlockerSlug;
};

export type PreSalesCrmActivityT = {
  id: number;
  leadId: number;
  title: string;
  kind: CrmActivityTypeT;
  meetingType: string;
  meetingInfo: string;
  location: string;
  email: string;
  due: string;
  reminder: string;
  reminderType: string[];
  notes: string;
};

export type OrderType = {
  [T: number]: string;
};

export type ActivityTaskLeadInfoT = {
  id: number;
  address: ActivityLeadAddressT;
  firstName: string;
  lastName: string;
  milestone: string;
};

export type CrmActivityTaskT = {
  id: number;
  title: string;
  kind: CrmActivityTypeT;
  meetingInfo: string;
  location: string;
  email: string;
  due: string;
  notes: string;
  projectId: number;
  leadInfo: ActivityTaskLeadInfoT;
  canDelete: boolean;
};

export type ActivityLogCrmT = {
  id: number;
  kind: CrmActivityTypeT;
  title: string;
  due: string;
  notes: string;
  milestone: string;
};

export type CrmStuckProjectReasonT = {
  id: number;
  statusName: string;
  statusDisplay: string;
  reasonCode: string;
  displayName: string;
  actionRequired: boolean;
};

export type CrmStuckProjectTaskT = {
  id: number;
  leadInfo: ActivityTaskLeadInfoT;
  stuckReasons: CrmStuckProjectReasonT[];
};

export type CrmTaskListT = {
  activities: CrmActivityTaskT[];
  stuckProjects: CrmStuckProjectTaskT[];
  count: number;
};

export type MilestoneT = {
  milestong: string;
  dateTime: string;
};

export type CrmActivitiesReduxStateT = {
  crmActivityDrawer: {
    visible: boolean;
    activityType: CrmDrawerActivityTypeT;
    leadId: number;
    activityId?: number;
  };
  crmActivityDetails: CrmActivityT | Record<string, unknown>;
  crmTaskList: CrmTaskListT;
};

export type ActivityDrawerVisibilityT = {
  visible: boolean;
  activityType?: CrmDrawerActivityTypeT;
  leadId?: number;
  activityId?: number;
};

export type UpdatedCrmResponseBodyT = {
  id: number;
  archivedAt: string | null;
  canDelete: boolean;
  completeType: string | null;
  completedAt: string | null;
  createdAt: string;
  due: string;
  email: string;
  homeownerAvailabilities?: HomeownerAvailabilityT[];
  kind: CrmDrawerActivityTypeT;
  leadInfo: ActivityLeadInfoT;
  location: string;
  meetingType: string;
  meetingInfo: string;
  notes: string;
  reminder: string;
  title: string;
  reminderType: string[];
  updatedAt: string;
};

export type CrmActivityDeletePayloadT = { activityId: number; leadId: number };

export type CrmActivityIdT = { activityId: number };

export type UsersCrmActivitiesT = { leadId: number; activities: CrmActivityT[] };

export type PreSalesActivityT = {
  archivedAt?: Nullable<string>;
  completeType?: string | null;
  completedAt: string | null;
  createdAt: string;
  due: string;
  email: string;
  id: number;
  kind: string;
  leadId?: number;
  location: string;
  meetingInfo: string;
  meetingType: string | null;
  notes: string;
  projectStageId?: number;
  reminder: string;
  reminderType: string[];
  title: string;
  updatedAt: Nullable<string>;
};

export type MarkCompleteMenuItemT = {
  key: string;
  label: string;
  disabled?: boolean;
  disabledReason?: string;
};

export type CrmActivityDrawerContentMapT = {
  formFields: JSX.Element;
  key: string;
  headerText: string;
  outcomeMenuHeaderText: string;
  footerButtonText: string;
  menuItems: MarkCompleteMenuItemT[];
};

export { CrmActivityTypeT, CrmDrawerActivityTypeT };
