import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const ProjectKickoffIcon = ({ height = 16, width = 16, fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2043_13536)">
        <path
          d="M4.06994 4.3208L2.68377 4.42789C2.29805 4.45771 1.95452 4.65986 1.74121 4.98251L0.142569 7.40037C-0.0192108 7.64506 -0.0449293 7.95127 0.073726 8.21952C0.192413 8.48777 0.436317 8.6747 0.726158 8.71961L1.99537 8.91617C2.29227 7.34056 3.00302 5.76882 4.06994 4.3208Z"
          fill={fill}
        />
        <path
          d="M7.08398 14.0048L7.28058 15.274C7.32548 15.5638 7.51242 15.8077 7.78064 15.9264C7.89223 15.9757 8.01035 16.0001 8.12788 16.0001C8.29297 16.0001 8.45691 15.952 8.59982 15.8575L11.0177 14.2589C11.3404 14.0456 11.5425 13.702 11.5723 13.3164L11.6794 11.9302C10.2313 12.9971 8.65963 13.7079 7.08398 14.0048Z"
          fill={fill}
        />
        <path
          d="M6.60632 13.1251C6.65038 13.1251 6.69469 13.1215 6.73891 13.1141C7.39903 13.0037 8.03527 12.8178 8.64221 12.5744L3.42568 7.35791C3.18231 7.96481 2.99638 8.60106 2.886 9.26121C2.8425 9.52142 2.92978 9.78652 3.11631 9.97308L6.02704 12.8838C6.18191 13.0386 6.39094 13.1251 6.60632 13.1251Z"
          fill={fill}
        />
        <path
          d="M14.725 7.09379C16.0015 4.62587 16.0488 2.01789 15.9821 0.794775C15.9592 0.375685 15.6251 0.0415622 15.206 0.0187186C15.0068 0.00784369 14.7708 0 14.5048 0C13.1371 0 10.973 0.207155 8.90698 1.2758C7.26505 2.12508 5.11204 3.99509 3.8418 6.45264C3.8568 6.46435 3.87145 6.47679 3.88527 6.4906L9.51022 12.1156C9.52403 12.1294 9.53644 12.144 9.54816 12.159C12.0057 10.8887 13.8757 8.73571 14.725 7.09379ZM9.30378 3.38247C10.2176 2.4687 11.7045 2.46861 12.6183 3.38247C13.061 3.82513 13.3048 4.41371 13.3048 5.03974C13.3048 5.66577 13.061 6.25436 12.6183 6.69701C12.1614 7.15385 11.5611 7.38232 10.9611 7.38238C10.3608 7.38241 9.76075 7.15397 9.30378 6.69701C8.8611 6.25436 8.61729 5.66577 8.61729 5.03974C8.61729 4.41371 8.8611 3.82513 9.30378 3.38247Z"
          fill={fill}
        />
        <path
          d="M9.96656 6.03421C10.5148 6.5825 11.407 6.58253 11.9553 6.03421C12.2209 5.76859 12.3672 5.41546 12.3672 5.03984C12.3672 4.66421 12.2209 4.31109 11.9553 4.0455C11.6812 3.77134 11.3211 3.63428 10.9609 3.63428C10.6008 3.63428 10.2407 3.77134 9.96659 4.0455C9.701 4.31109 9.55469 4.66421 9.55469 5.03984C9.55469 5.41546 9.70097 5.76862 9.96656 6.03421Z"
          fill={fill}
        />
        <path
          d="M0.478531 13.1857C0.5985 13.1857 0.718469 13.1399 0.809969 13.0484L2.34038 11.518C2.52344 11.3349 2.52344 11.0381 2.34038 10.8551C2.15734 10.672 1.86053 10.672 1.67747 10.8551L0.147062 12.3855C-0.036 12.5685 -0.036 12.8653 0.147062 13.0484C0.238594 13.1399 0.358562 13.1857 0.478531 13.1857Z"
          fill={fill}
        />
        <path
          d="M3.74255 12.2574C3.55952 12.0744 3.2627 12.0744 3.07964 12.2574L0.137297 15.1998C-0.0457656 15.3828 -0.0457656 15.6796 0.137297 15.8627C0.228828 15.9542 0.348797 15.9999 0.468766 15.9999C0.588734 15.9999 0.708703 15.9542 0.800203 15.8626L3.74252 12.9203C3.92561 12.7373 3.92561 12.4405 3.74255 12.2574Z"
          fill={fill}
        />
        <path
          d="M4.48213 13.6598L2.95175 15.1902C2.76869 15.3732 2.76869 15.67 2.95175 15.8531C3.04328 15.9446 3.16325 15.9904 3.28319 15.9904C3.40313 15.9904 3.52312 15.9446 3.61462 15.8531L5.14503 14.3227C5.32809 14.1396 5.32809 13.8428 5.14503 13.6598C4.962 13.4767 4.66519 13.4767 4.48213 13.6598Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2043_13536">
          <rect width={width} height={height} fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProjectKickoffIcon;
