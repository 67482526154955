import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/users/messages";

function* createPermissionsOperation(action) {
  const { success, data, error } = yield* makeRequest.post("/operations", {
    name: action.payload.name,
    description: action.payload.description,
    actions: action.payload.actions,
  });

  if (success && data) {
    yield put(actions.setRolesList({ operations: data.body.operations }));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_NEW_PERMISSIONS_OPERATION,
    });
  }

  return null;
}

export default createPermissionsOperation;
