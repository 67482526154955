import { styled } from "@mui/material";
import { filterStyledProps } from "lib/helpers/filterStyledProps";

type StyledOptionT = {
  isActive?: boolean;
  isDisabled?: boolean;
};

type StyledTabPropsT = {
  isSelected?: boolean;
  isDisabled?: boolean;
};

type StyledTabLabelPropsT = {
  isDisabled?: boolean;
  isCount?: boolean;
  isThin?: boolean;
};

type StyledItemPropsT = {
  isHidden?: boolean;
  isReverse?: boolean;
};

export const TabContainer = styled("div", { shouldForwardProp: filterStyledProps(["isSelected", "isDisabled"]) })(
  ({ isSelected = false, isDisabled = false }: StyledTabPropsT) =>
    ({ theme }) => ({
      height: "48px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderTop: "2px solid white",
      borderBottom: " 2px solid white",
      padding: "0 24px",
      cursor: isDisabled ? "not-allowed" : "pointer",
      background: theme.colors["ma-white"],
      ...(isSelected && { background: theme.colors["athens-gray"] }),
      ...(isDisabled && { background: theme.colors.grey }),
    }),
);

export const ItemContainer = styled("div")(
  ({ isHidden = false, isReverse = false }: StyledItemPropsT) =>
    ({ theme }) => ({
      display: "flex",
      flexDirection: "column",
      width: "100%",
      minWidth: "300px",
      maxHeight: "50vh",
      backgroundColor: theme.colors.white,
      ...(isHidden && { display: "none" }),
      ...(isReverse && { flexDirection: "column-reverse" }),
    }),
);

export const ItemSubheader = styled("div")(({ theme }) => ({
  fontSize: "10px",
  fontWeight: "700",
  lineHeight: "9px",
  color: theme.colors["gothic-blue"],
  textTransform: "uppercase",
  marginBottom: "10px",
}));

export const TabLabel = styled("span")(
  ({ isCount = false, isDisabled = false, isThin = false }: StyledTabLabelPropsT) =>
    ({ theme }) => ({
      fontSize: "14px",
      fontWeight: isThin ? "400" : "600",
      lineHeight: isCount ? "17px" : "21px",
      color: isDisabled ? theme.colors["old-grey"] : theme.colors["darkest-blue"],
    }),
);

export const CountContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

export const FilterDividerLine = styled("div")(({ theme }) => ({
  display: "inline-block",
  width: "100%",
  border: `.1px solid ${theme.colors["nepal-blue"]}`,
  height: ".1px",
}));

export const FilterDivideText = styled("div")(({ theme }) => ({
  display: "inline-block",
  width: "100%",
  paddingTop: "4px",
  color: theme.colors["nepal-blue"],
  textAlign: "center",
  fontWeight: "500",
  fontSize: "12px",
}));

export const OptionContainer = styled("div")(
  ({ isActive = false, isDisabled = false }: StyledOptionT) =>
    ({ theme }) => ({
      display: "flex",
      alignItems: "center",
      paddingLeft: "9px",
      width: "320px",
      height: "28px",
      background: isActive ? theme.colors["dark-blue"] : theme.colors["athens-gray"],
      borderRadius: "3px",
      color: isActive ? theme.colors.white : theme.colors["font-color-eastern-blue"],
      ...(isDisabled && { color: theme.colors["graham-grey"] }),
      fontWeight: "600",
      lineHeight: "31px",
      marginBottom: "3px",
      cursor: "pointer",
      textTransform: "capitalize",

      " &:hover": {
        background: isActive ? theme.colors["dark-blue"] : theme.colors["cotton-boll-highlighted"],
      },
    }),
);

export const FilterActionButton = styled("button")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "24px",
  padding: "4px 12px",
  background: theme.colors["botticelli-blue"],
  borderRadius: "18px",
  color: theme.colors["dark-blue"],
  marginLeft: "9px",
  fontSize: "12px",
  fontWeight: "600",
  border: "none",
  cursor: "pointer",
  " &:hover": {
    background: theme.colors["cotton-boll-highlighted"],
  },
}));
