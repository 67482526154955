import React from "react";
import { createRoot } from "react-dom/client";
import { hijackEffects } from "stop-runaway-react-effects";
import Start from "./start";

if (process.env.NODE_ENV !== "production") {
  hijackEffects({ callCount: 200, timeLimit: 1000 });
}

const App = () => <Start />;

const container = document.getElementById("root");
const root = createRoot(container!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
root.render(<App />);
