import makeRequest from "rdx/utils/makeRequest";
import { all, put } from "redux-saga/effects";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* deleteSalesTeam(action) {
  const { teamId } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/sales_teams/${teamId}`);

  if (success && data) {
    yield all([put(actions.getTeams())]);
    yield all([put(actions.getSellers())]);
    yield all([put(actions.getAvailableManagers())]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_SALES_TEAM,
    });
  }

  return null;
}

export default deleteSalesTeam;
