import React from "react";
import PropTypes from "prop-types";
import LaborPartnerIcon from "components/Icons/LaborPartnerIcon";
import RightArrow from "components/Icons/Arrows/rightArrow";
import { useTranslation } from "react-i18next";
import styles from "./EnterpriseProSignupModal.module.less";

const LaborPartnerCard = ({ laborPartnerApply, toLaborForm }) => {
  const { t } = useTranslation(["ent_join_form_labels"]);

  const handleClick = () => {
    toLaborForm();
  };

  return laborPartnerApply ? (
    <div className={styles.continueCard}>
      <div className={styles.iconContainerWhite}>
        <LaborPartnerIcon width="34" height="34" />
      </div>
      <div className={styles.continueDetails}>
        <div className={styles.detailsTitle}>{t("labor_partner_app")}</div>
        <div
          className={styles.detailsLink}
          role="button"
          tabIndex={0}
          onClick={() => handleClick()}
          onKeyDown={() => handleClick()}
        >
          {t("labor_partner_click_here_cont")}
          <div className={styles.arrow}>
            <RightArrow fill="white" width="25" height="20" />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.promptCard}>
      <div className={styles.iconContainerBlue}>
        <LaborPartnerIcon fill="white" />
      </div>
      <div className={styles.laborPartnerPrompt}>{t("labor_partner_prompt")}</div>
      <div
        className={styles.laborPartnerLink}
        role="button"
        tabIndex={0}
        onClick={() => handleClick()}
        onKeyDown={() => handleClick()}
      >
        {t("labor_partner_click_here")}
      </div>
    </div>
  );
};

LaborPartnerCard.propTypes = {
  laborPartnerApply: PropTypes.bool,
  toLaborForm: PropTypes.func,
};

export default LaborPartnerCard;
