import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxActionMapper } from "rdx/utils/propsMapping";
import { useTranslation } from "react-i18next";
import { Input } from "antd";

const ZipInput = (props) => {
  const { zip, setZip, getZipLookupAddress, forwardRef, isEmpty, ...rest } = props;

  const { t } = useTranslation("form_labels");

  const handleSubmit = () => {
    if (zip && zip.length === 5) {
      getZipLookupAddress({ zip });
    }
  };

  return (
    <Input
      value={zip}
      style={{ borderColor: isEmpty && "red" }}
      onChange={(e) => setZip(e.target.value)}
      onPressEnter={handleSubmit}
      placeholder={t("enter_zip_code")}
      maxLength={5}
      ref={(node) => {
        if (forwardRef) {
          forwardRef.current = node;
        }
      }}
      {...rest}
    />
  );
};

ZipInput.propTypes = {
  zip: PropTypes.string.isRequired,
  setZip: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool,
  forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  getZipLookupAddress: PropTypes.func,
};

export default connect(undefined, getRdxActionMapper(["getZipLookupAddress"]))(ZipInput);
