import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getAmbassadorLeads() {
  const { success, data, error } = yield* makeRequest.get("/leads/ambassador", {});

  if (success && data) {
    const leads = data.body.leads.map((l) => {
      const out = keysToCamelCase(l);
      out.progress = l.progress.map((p) => keysToCamelCase(p));
      return out;
    });
    yield put(actions.setAmbassadorLeads(leads));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_AMBASSADOR_LEADS,
    });
  }

  return null;
}

export default getAmbassadorLeads;
