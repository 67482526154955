export default {
  // success messages
  SUCCESS_CREATING_NEW_ADMIN_NOTICE: "New admin notice created",
  SUCCESS_UPDATING_ADMIN_NOTICE_DETAIL: "Admin notice updated.",
  SUCCESS_DELETING_NEW_ADMIN_NOTICE: "Admin notice deleted",

  // error messages
  ERROR_GETTING_ADMIN_NOTICES_LIST: "There was an error retrieving the admin notices list",
  ERROR_GETTING_ADMIN_NOTICE_DETAILS: "There was an error retrieving admin notice details",
  ERROR_CREATING_NEW_ADMIN_NOTICE: "There was an error creating a new admin notice.",
  ERROR_UPDATING_ADMIN_NOTICE_DETAIL: "There was an error updating this admin notice.",
  ERROR_DELETING_NEW_ADMIN_NOTICE: "There was an error deleting this admin notice.",
};
