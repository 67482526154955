import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import dayjs from "dayjs";
import actions from "rdx/actions";
import messages from "rdx/modules/earnings/messages";
import { camelizeKeys } from "lib/helpers";

function* getThisMonthEarnings() {
  const params = {
    from: dayjs().startOf("month").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  };

  const { success, data, error } = yield* makeRequest.get("/dashboard_earnings", params);

  if (success && data) {
    yield put(actions.setThisMonthEarnings(camelizeKeys(data.body.monthly_breakdown[0] || {})));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_EARNINGS_HISTORY,
    });
  }

  return null;
}

export default getThisMonthEarnings;
