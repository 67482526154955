import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/profile/types";
import getProfile from "./getProfile";
import updateProfile from "./updateProfile";
import updatePassword from "./updatePassword";
import createLicense from "./createLicense";
import deleteLicense from "./deleteLicense";
import updateLicense from "./updateLicense";
import createMentorStatusChange from "./createMentorStatusChange";
import deleteMentorStatusChange from "./deleteMentorStatusChange";
import createUpdateEmail from "./createEmailUpdate";
import deleteUpdateEmail from "./deleteEmailUpdate";
import verifyEmailUpdate from "./verifyEmailUpdate";
import resendEmailUpdate from "./resendEmailUpdate";
import updateMentorPreferences from "./updateMentorPreferences";
// IMPORT_PT (for script, do not remove)

function* watchProfileSagas() {
  yield trackRequests(takeEvery, types.GET_PROFILE_REQUEST, getProfile);
  yield trackRequests(takeEvery, types.UPDATE_PROFILE_REQUEST, updateProfile);
  yield trackRequests(takeEvery, types.UPDATE_USER_PASSWORD_REQUEST, updatePassword);
  yield trackRequests(takeEvery, types.CREATE_LICENSE_REQUEST, createLicense);
  yield trackRequests(takeEvery, types.DELETE_LICENSE_REQUEST, deleteLicense);
  yield trackRequests(takeEvery, types.UPDATE_LICENSE_REQUEST, updateLicense);
  yield trackRequests(takeEvery, types.CREATE_MENTOR_STATUS_CHANGE_REQUEST, createMentorStatusChange);
  yield trackRequests(takeEvery, types.DELETE_MENTOR_STATUS_CHANGE_REQUEST, deleteMentorStatusChange);
  yield trackRequests(takeEvery, types.CREATE_EMAIL_UPDATE_REQUEST, createUpdateEmail);
  yield trackRequests(takeEvery, types.DELETE_EMAIL_UPDATE_REQUEST, deleteUpdateEmail);
  yield trackRequests(takeEvery, types.VERIFY_EMAIL_UPDATE_REQUEST, verifyEmailUpdate);
  yield trackRequests(takeEvery, types.RESEND_EMAIL_UPDATE_REQUEST, resendEmailUpdate);
  yield trackRequests(takeEvery, types.UPDATE_MENTOR_PREFERENCES_REQUEST, updateMentorPreferences);
  // INSERTION_PT (for script, do not remove)
}

export default watchProfileSagas;
