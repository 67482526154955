import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const PtoAppSubmittedIcon = ({ height = 19, width = 19, fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6549 2.38477H15.9399V16.0583C15.9399 16.9791 15.1908 17.7283 14.27 17.7283H4.17383V18.4432C4.17383 18.7506 4.42305 18.9999 4.73047 18.9999H16.6549C16.9623 18.9999 17.2116 18.7506 17.2116 18.4432V2.94141C17.2116 2.63396 16.9623 2.38477 16.6549 2.38477Z"
        fill={fill}
      />
      <path
        d="M14.8268 16.0585V0.556641C14.8268 0.249227 14.5776 0 14.2702 0H2.3457C2.03829 0 1.78906 0.249227 1.78906 0.556641V16.0585C1.78906 16.3659 2.03829 16.6151 2.3457 16.6151H14.2702C14.5776 16.6151 14.8268 16.3659 14.8268 16.0585ZM6.91286 12.8747L5.72043 14.0672C5.51314 14.2827 5.14048 14.2828 4.93323 14.0672L4.33703 13.4709C3.8349 12.9304 4.58336 12.1814 5.12427 12.6837L5.32685 12.8863L6.1257 12.0875C6.66646 11.5852 7.41507 12.3341 6.91286 12.8747ZM6.91286 9.59548L5.72043 10.7879C5.51314 11.0035 5.14048 11.0036 4.93323 10.7879L4.33703 10.1917C3.8349 9.65111 4.58336 8.9022 5.12427 9.40448L5.32685 9.6071L6.1257 8.80828C6.66646 8.30601 7.41507 9.05491 6.91286 9.59548ZM6.91286 6.31628L5.72043 7.50871C5.51314 7.72428 5.14048 7.72435 4.93323 7.50871L4.33703 6.91247C3.8349 6.3719 4.58336 5.623 5.12427 6.12527L5.32685 6.32789L6.1257 5.52907C6.66646 5.02676 7.41507 5.77567 6.91286 6.31628ZM6.91286 3.03703L5.72043 4.22947C5.51314 4.44507 5.14048 4.44511 4.93323 4.22947L4.33703 3.63323C3.8349 3.09266 4.58336 2.34375 5.12427 2.84603L5.32685 3.04865L6.1257 2.24983C6.66646 1.74752 7.41507 2.49642 6.91286 3.03703ZM11.8853 13.9321H8.90419C8.16742 13.9052 8.16609 12.8463 8.90419 12.8188H11.8853C12.1927 12.8188 12.4419 13.068 12.4419 13.3754C12.4419 13.6829 12.1927 13.9321 11.8853 13.9321ZM11.8853 10.6529H8.90419C8.16742 10.626 8.16609 9.56706 8.90419 9.5396H11.8853C12.1927 9.5396 12.4419 9.78882 12.4419 10.0962C12.4419 10.4037 12.1927 10.6529 11.8853 10.6529ZM11.8853 7.37363H8.90419C8.16742 7.34673 8.16609 6.28781 8.90419 6.26035H11.8853C12.1927 6.26035 12.4419 6.50958 12.4419 6.81699C12.4419 7.12441 12.1927 7.37363 11.8853 7.37363ZM11.8853 4.09439H8.90419C8.16742 4.06748 8.16609 3.00857 8.90419 2.98111H11.8853C12.1927 2.98111 12.4419 3.23033 12.4419 3.53775C12.4419 3.84516 12.1927 4.09439 11.8853 4.09439Z"
        fill={fill}
      />
    </svg>
  );
};

export default PtoAppSubmittedIcon;
