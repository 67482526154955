import React from "react";
import EyeIconOff from "components/Icons/EyeIconOff";
import { Tooltip } from "components/mui-styled-components/Tooltip";
import { CustomFieldT } from "types/Project/interfaces";
import * as S from "./styles";

type EnterpriseCustomFieldsT = {
  customFields: CustomFieldT[];
};

export default function EnterpriseCustomFields({ customFields }: EnterpriseCustomFieldsT) {
  return (
    <S.FieldsContainer>
      {customFields.map((field) => (
        <S.CustomField key={field.id}>
          <S.LabelRow>
            <S.FieldLabel>{field.label}</S.FieldLabel>
            {field.hidden && (
              <Tooltip
                placement="top"
                title="This field is hidden and
                  can only be seen by owner
                  or manager roles."
              >
                <S.IconContainer>
                  <EyeIconOff />
                </S.IconContainer>
              </Tooltip>
            )}
          </S.LabelRow>
          <S.FieldValue>{field.value}</S.FieldValue>
        </S.CustomField>
      ))}
    </S.FieldsContainer>
  );
}
