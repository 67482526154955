import { styled } from "@mui/material/styles";

export const ModalContent = styled("div")(({ theme }) => ({
  fontSize: "16px",
  color: theme.colors["darkest-blue"],
  marginBottom: "8px",
  fontFamily: "Barlow",
}));

export const RequiredModule = styled("li")(() => ({
  marginTop: "16px",
  fontWeight: 600,
}));
