import z from "zod";
import { TeamSellerSchema, TeamFilterParamsSchema, ParsedQueryParamSchema } from "rdx/modules/team/schemas";

export const OrgTeamUserSchema = TeamSellerSchema; // currently the same as the TeamSellerSchema, importing and assigning here so that the name aligns with where it's used, and if OrgTeamUserSchema deviates we can update assignment to TeamSellerSchema.extend()

export const OrgTeamFilterParamsSchema = TeamFilterParamsSchema.extend({
  role: ParsedQueryParamSchema,
});

export const GetOrgUsersParamsSchema = z.object({
  filterParams: OrgTeamFilterParamsSchema,
  withDownlineExpansion: z.boolean(),
  searchedUserId: z.union([z.number(), z.null()]),
});

export const GetMoreOrgUsersParamsSchema = z.object({
  filterParams: OrgTeamFilterParamsSchema,
  withDownlineExpansion: z.boolean(),
  p: z.number(),
});

export const GetOrgUsersResponseSchema = z.object({
  status: z.number(),
  body: z.object({
    paging: z.object({
      itemCount: z.number(),
      pageNumber: z.number(),
      pageSize: z.number(),
      hasMore: z.boolean(),
    }),
    users: z.array(OrgTeamUserSchema),
  }),
});

export const GetOrgUsersDownlineParamsSchema = z.object({
  filterParams: OrgTeamFilterParamsSchema,
  userId: z.number(),
  searchedUserId: z.union([z.number(), z.null()]),
});

export const GetOrgUserDownlineResponseSchema = z.object({
  status: z.number(),
  body: z.object({
    users: z.array(OrgTeamUserSchema),
  }),
});
