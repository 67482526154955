export default {
  ENTERPRISE_LEAD_OFFER_SUCCESS: "Lead and terms sent to consultant",
  ENTERPRISE_RESCIND_OFFER_SUCCESS: "This offer has been cancelled",
  ENTERPRISE_CANCEL_OFFER_SUCCESS: "You have removed yourself from this offer",

  // error messages
  ERROR_GETTING_ENTERPRISE_PROJECTS: "There was an error retrieving projects",
  ERROR_SUBMITTING_ENTERPRISE_LEAD_OFFER: "An error occurred attempting to offer this lead",
  ERROR_GETTING_ENTERPRISE_LEAD_OFFER_DETAILS: "There was an error retrieving offer details",
  ERROR_SUBMITTING_ENTERPRISE_LEAD_OFFER_DECISION: "There was an error sending lead offer response",
  ERROR_SUBMITTING_ENTERPRISE_RESCIND_OFFER: "There was an error cancelling lead offer",
  ERROR_STARTING_CONTRACTS_REPORT: "There was an error starting the contracts csv",
  ERROR_GETTING_CONTRACTS_REPORT: "There was an error getting the contracts csv",
  ERROR_GETTING_ENTERPRISE_LEAD_OFFER_RESPONSE: "There was an error retrieving lead offer response",
  ERROR_TOGGLE_M1_ADVANCE: "There was an error setting the M1 advance",
  ERROR_GETTING_LEAD_GENERATOR_PROJECTS: "There was an error retrieving projects",
};
