import React from "react";
import PropTypes from "prop-types";
import useWindowResize from "hooks/useWindowResize";
import styles from "../../EnterpriseJoin.module.less";
import TextPanel from "./textPanel";
import ImgPanel from "./imgPanel";

const InfoPanels = ({ panels }) => {
  const { width } = useWindowResize();

  return (
    <div className={styles.infoPanels}>
      {panels.map((p, index) => {
        const align = index % 2 === 0 ? "row" : "row-reverse";
        return (
          <div
            key={p.section}
            className={styles.infoPanel}
            style={{
              display: "flex",
              flexDirection: `${width > 900 ? align : "column"}`,
            }}
          >
            <TextPanel section={p.section} />
            <ImgPanel imgUrl={p.imgUrl} />
          </div>
        );
      })}
    </div>
  );
};

InfoPanels.propTypes = {
  panels: PropTypes.arrayOf(PropTypes.shape({ section: PropTypes.string, imgUrl: PropTypes.string })),
};

export default InfoPanels;
