import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/rbac/types";
import setNoticeViewedByUser from "./setNoticeViewedByUser";
// import getStripePaymentInfo from "./getStripePaymentInfo";
// IMPORT_PT (for script, do not remove)

function* watchRbacSagas() {
  yield trackRequests(takeEvery, types.SET_NOTICE_VIEWED_BY_USER, setNoticeViewedByUser);
  // INSERTION_PT (for script, do not remove)
}

export default watchRbacSagas;
