import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/sellerIntegrity/messages";
import { camelizeKeys, keysToSnakeCase, formatIntegrityModules } from "lib/helpers";
import { SelleryIntegrityResponseDataT } from "containers/Admin/users/components/UserDetailDrawer/types";

type IntegrityViolationPropsT = {
  payload: {
    userId: number;
  };
};

function* getIntegrityViolations(action: IntegrityViolationPropsT) {
  const params = keysToSnakeCase(action.payload);

  const { success, data, error } = yield* makeRequest.get("/seller_integrity_violations", params);

  if (success && data) {
    const violationData: SelleryIntegrityResponseDataT = camelizeKeys(data.body);
    const clone = { ...violationData };
    clone.violations.forEach((violation, idx) => {
      clone.violations[idx].violationModules = formatIntegrityModules(violation.violationModules);
    });

    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.GET_INTEGRITY_VIOLATION_SUCCESS,
        }),
      ),
      put(actions.setIntegrityViolations({ ...clone, userId: params.userId })),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_INTEGRITY_VIOLATION,
    });
  }

  return null;
}

export default getIntegrityViolations;
