import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* getStripeLoginUrl() {
  const { success, data, error } = yield* makeRequest.get("/stripe_connect/login_link", {});

  if (success && data) {
    yield put(
      actions.newSuccessEvent({
        message: messages.GET_STRIPE_LOGIN_URL_SUCCESS,
        payload: data.body,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_STRIPE_LOGIN_URL,
    });
  }

  return null;
}

export default getStripeLoginUrl;
