import tabs from "containers/Admin/ProjectsList/tabs";
import { ProjectsIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import breadCrumbs from "components/PlatformLayout/components/PlatformPageHeader/Breadcrumbs/ProjectBreadCrumbs";
import l from "@loadable/component";

const content = l(() => import("containers/Admin/ProjectsList"));

const { PROJECT_MANAGEMENT } = permissionTypes;

export default {
  key: "admin-projects-list",
  title: breadCrumbs,
  path: "/admin-projects/:tab?",
  exact: true,
  basePath: "admin-projects",
  requiredParams: ["tab"],
  optionalParams: ["leadId"],
  linkTarget: "/admin-projects/leads",
  linkTitle: "Projects",
  linkCategory: "Admin",
  icon: ProjectsIcon,
  tabs,
  content,
  permission: [PROJECT_MANAGEMENT],
};
