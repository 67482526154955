import { Typography, styled } from "@mui/material";
import { cvar } from "styles/colorVariables";

export const TextContainer = styled("div")(() => ({
  display: "flex",
  marginLeft: "20px",
}));

export const BoldText = styled(Typography)(() => ({
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: 700,
  color: cvar("blue-ribbon"),
}));

export const Text = styled(Typography)(() => ({
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: 500,
  color: cvar("blue-ribbon"),
}));
