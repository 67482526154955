import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const ClockIconOutline = ({ width = "10", height = "10", fill = cvar("nepal-blue") }: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10 10">
      <path
        d="M5 0C4.01109 0 3.0444 0.293245 2.22215 0.842651C1.39991 1.39206 0.759043 2.17295 0.380605 3.08658C0.00216644 4.00021 -0.0968502 5.00554 0.0960758 5.97545C0.289002 6.94535 0.765206 7.83627 1.46447 8.53553C2.16373 9.23479 3.05465 9.71099 4.02455 9.90392C4.99445 10.0968 5.99979 9.99783 6.91342 9.61939C7.82705 9.24095 8.60794 8.60009 9.15735 7.77785C9.70676 6.9556 10 5.9889 10 5C9.99844 3.6744 9.47115 2.40353 8.53381 1.46619C7.59646 0.52885 6.3256 0.00156385 5 0ZM5 9.0909C4.1909 9.0909 3.39996 8.85098 2.72721 8.40146C2.05447 7.95195 1.53013 7.31303 1.22049 6.56552C0.910864 5.818 0.82985 4.99546 0.987699 4.2019C1.14555 3.40834 1.53517 2.67941 2.10729 2.10729C2.67942 1.53517 3.40835 1.14554 4.2019 0.987696C4.99546 0.829847 5.81801 0.910861 6.56552 1.22049C7.31304 1.53012 7.95195 2.05446 8.40147 2.72721C8.85098 3.39996 9.09091 4.19089 9.09091 5C9.08959 6.08457 8.65816 7.12434 7.89125 7.89124C7.12434 8.65815 6.08457 9.08958 5 9.0909Z"
        fill={fill}
      />
      <path
        d="M5.4545 4.81175V2.27266C5.4545 2.15211 5.40661 2.03649 5.32137 1.95125C5.23612 1.866 5.12051 1.81812 4.99996 1.81812C4.8794 1.81812 4.76379 1.866 4.67854 1.95125C4.5933 2.03649 4.54541 2.15211 4.54541 2.27266V4.99994C4.54544 5.12048 4.59334 5.23608 4.67859 5.3213L6.04223 6.68494C6.12796 6.76774 6.24278 6.81355 6.36196 6.81252C6.48114 6.81148 6.59514 6.76368 6.67942 6.6794C6.76369 6.59512 6.8115 6.48112 6.81253 6.36194C6.81357 6.24276 6.76775 6.12794 6.68495 6.04221L5.4545 4.81175Z"
        fill={fill}
      />
    </svg>
  );
};

export default ClockIconOutline;
