// import content from "containers/Sales/projects/components/ProjectDetails";
import tabs from "containers/Sales/projects/components/ProjectDetails/tabs";
import breadCrumbs from "components/PlatformLayout/components/PlatformPageHeader/Breadcrumbs/ProjectBreadCrumbs";
import l from "@loadable/component";

const content = l(() => import("containers/Sales/projects/components/ProjectDetails"));

// const breadCrumbs = l(() =>
//   import(
//     "components/PlatformLayout/components/PlatformPageHeader/ProjectBreadCrumbs"
//   ),
// );

export default {
  key: "project-details",
  title: breadCrumbs,
  path: "/projects/:leadId?/:tab?/:subDrawer?",
  basePath: "projects/:leadId",
  requiredParams: ["leadId", "tab"],
  optionalParmas: ["subDrawer"],
  linkTarget: null,
  linkTitle: null,
  linkCategory: null,
  icon: null,
  tabs,
  content,
  sidebar: null,
  newHeader: true,
};
