import { select, put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { camelizeKeys } from "lib/helpers";
import selectors from "rdx/selectors";
import actions from "rdx/actions";
import messages from "../messages";

function* deleteCustomField(action) {
  const { enterpriseId, id } = action.payload;

  const { success, data, error } = yield* makeRequest.delete(
    `/enterprises/${enterpriseId}/lead_custom_fields/${id}`,
    {},
  );

  if (success && data) {
    const customFields = yield select(selectors.selectCustomFields);
    const updatedCustomFields = customFields.filter((item) => {
      return item.id !== id;
    });

    yield all([
      yield put(actions.setCustomFields(camelizeKeys(updatedCustomFields))),
      put(
        actions.newSuccessEvent({
          message: messages.DELETE_CUSTOM_FIELD_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.DELETE_CUSTOM_FIELD_ERROR,
    });
  }
  return null;
}

export default deleteCustomField;
