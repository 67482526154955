import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";

import { Button } from "antd";
import { useHistory } from "react-router-dom";
import SessionClient from "util/SessionClient";

import { RightArrowLong } from "components/Icons";
import CloseButton from "components/Buttons/CloseButton";
import ModalContainer from "../ModalContainer";

import styles from "./ActivateAccountModal.module.less";

const ActivateAccountModal = (props) => {
  const { activateAccountModalVisible, setActivateAccountModalVisible } = props;
  const session = new SessionClient();
  const { backOfficeAccess, isEnterpriseProOwner, isEnterpriseProSeatedUser } = session;
  const history = useHistory();

  const certificationType = () => {
    if (isEnterpriseProOwner) {
      return "enterprise_owner";
    }
    if (isEnterpriseProSeatedUser) {
      return "enterprise_seated_user";
    }
    return "tier_1";
  };

  const options = {
    needs_certification: {
      titleText: "Get Certified",
      bodyText: "To access this feature you must complete the Powur University certification.",
      buttonText: "Powur University",
      onClick: () => session.user.getLitmosLoginLink(certificationType()),
    },
    needs_activation: {
      titleText: "Please Activate Your Account",
      bodyText: "To access this feature you must first activate your account.",
      buttonText: "Choose Your Account Type",
      onClick: () => history.push("/getting-started/account-types"),
    },
  };

  const handleCancel = () => {
    setActivateAccountModalVisible(false);
  };

  const handleChooseClick = () => {
    setActivateAccountModalVisible(false);
    options[backOfficeAccess].onClick();
  };

  return (
    <ModalContainer
      modalClassName={styles.activateAccountModal}
      title={null}
      visible={activateAccountModalVisible}
      noFooter
      noHeader
      width={680}
      onClose={handleCancel}
      closable={false}
    >
      <div className={styles.container}>
        <div className={styles.title}>{options[backOfficeAccess]?.titleText}</div>
        <div className={styles.text}>{options[backOfficeAccess]?.bodyText}</div>
        <div className={styles.footer}>
          <div className={styles.closeButtonContainer}>
            <CloseButton buttonTypeStyle="gradient" height={56} width={56} xSize={18} action={handleCancel} />
          </div>
          <Button className={styles.submit} type="primary" ghost onClick={handleChooseClick}>
            <span>{options[backOfficeAccess]?.buttonText}</span>
            <div className={styles.arrowContainer}>
              <RightArrowLong />
            </div>
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};

ActivateAccountModal.propTypes = {
  activateAccountModalVisible: PropTypes.bool,
  setActivateAccountModalVisible: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    activateAccountModalVisible: "selectActivateAccountModalVisible",
  }),
  getRdxActionMapper(["setActivateAccountModalVisible"]),
)(ActivateAccountModal);
