import z from "zod";
import { UserItemSchema } from "rdx/modules/users/schemas/users";
import { PaymentStatuses } from "../types";

export enum ServiceSlugs {
  ENTERPRISE_MANAGER = "enterprise_manager",
  ENTERPRISE_SUPPORT = "enterprise_support",
  ENTERPRISE_LEAD_GENERATOR = "enterprise_lead_generator",
  POWUR_ENTERPRISE = "powur_enterprise",
  POWUR_ENTERPRISE_PRO = "powur_enterprise_pro",
  POWUR_CLOSER = "powur_closer",
  ENTERPRISE_CLOSER = "enterprise_closer",
  POWUR_ENERGY = "powur_energy",
  ENTERPRISE_CONSULTANT = "enterprise_consultant",
}

export const ServiceSchema = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.nativeEnum(ServiceSlugs),
  fee: z.number(),
  monthly: z.number(),
  groupData: z.object({ enterpriseCategory: z.string() }).or(z.string()),
  displayData: z.object({ enterpriseDisplayName: z.string().nullish() }),
  feeRequired: z.boolean(),
});

export const EnrollmentServiceSchema = ServiceSchema.omit({ displayData: true, feeRequired: true }).merge(
  z.object({
    description: z.string().nullable(),
    currency: z.string(),
    enabled: z.boolean(),
    createdAt: z.string(),
    updatedAt: z.string(),
    startAt: z.string().nullable(),
    endAt: z.string().nullable(),
    requiresEducation: z.boolean(),
    parentId: z.number().nullable(),
    unique: z.boolean(),
    tags: z.array(z.string()),
    replaceServiceId: z.number().nullable(),
    assignableServiceIds: z.array(z.number()),
  }),
);

export const EnterpriseMemberSchema = z.lazy(() =>
  UserItemSchema.merge(
    z.object({
      service: EnrollmentServiceSchema,
      endDay: z.string().nullable(),
    }),
  ),
);

export const EnrollmentSchema = z.object({
  id: z.number(),
  startDay: z.string(),
  endDay: z.string().nullable(),
  startedAt: z.string(),
  paymentStatus: z.nativeEnum(PaymentStatuses),
  service: EnrollmentServiceSchema,
  downgradeDay: z.string().nullable(),
  inactive: z.boolean().nullable(),
  activeUntil: z.string().nullable(),
  currentPeriodStart: z.string().nullish(),
  enterpriseMembers: z
    .array(
      z.object({
        user: EnterpriseMemberSchema,
      }),
    )
    .nullish(),
  enterpriseLiveMembers: z.array(z.lazy(() => EnterpriseMemberSchema)).nullish(),
  comped: z.boolean(),
});

export const FlatEnrollmentSchema = EnrollmentSchema.omit({ service: true })
  .merge(EnrollmentServiceSchema)
  .merge(z.object({ role: z.string().nullish() }));

export const CardInfoSchema = z.object({
  last4: z.string(),
  expYear: z.number(),
  expMonth: z.number(),
});

export const GetEnrollmentResponseBodySchema = z.object({
  monthlyTotal: z.number(),
  enrollment: EnrollmentSchema,
  availableServices: z.array(ServiceSchema),
  balance: z.number(),
  cardInfo: CardInfoSchema.partial(),
  seatService: EnrollmentServiceSchema.nullable(),
});

export const GetEnrollmentResponseSchema = z.object({
  status: z.number(),
  body: GetEnrollmentResponseBodySchema,
});

export const UpdateEnrollmentResponseBodySchema = GetEnrollmentResponseBodySchema.pick({
  enrollment: true,
  cardInfo: true,
});

export const UpdateEnrollmentResponseSchema = z.object({
  status: z.number(),
  body: UpdateEnrollmentResponseBodySchema,
});

export const UpgradePreviewResponseSchema = z.object({
  accumulatedChanges: z.number(),
  nextPeriodTotal: z.number(),
  theseChanges: z.number(),
  total: z.number(),
  data: z.object({
    value: z.number(),
    expires: z.number(),
    prorationKey: z.string(),
  }),
});
