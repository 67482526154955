import { put, all, select } from "redux-saga/effects";
import selectors from "rdx/modules/users/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* deleteTeam(action) {
  const { teamId } = action.payload;
  const { success, data, error } = yield* makeRequest.delete(`/teams/${teamId}`, {});
  if (success && data) {
    const teams = yield select(selectors.getTeamsList);
    const out = teams.filter((team) => team.id !== data.body.id);
    yield all([
      put(actions.setTeamsList(out)),
      put(
        actions.newSuccessEvent({
          message: messages.DELETE_TEAM_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({ error, message: messages.ERROR_DELETING_TEAM });
  }
  return null;
}

export default deleteTeam;
