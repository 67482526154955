import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { isEmpty } from "lodash";

import { actions, selectors } from "rdx";
import useRestrictAccessModal from "hooks/useRestrictAccessModal";
import { useSmartForm } from "hooks/useSmartForm";
import useWindowResize from "hooks/useWindowResize";
import usePermissions, { permissionTypes } from "hooks/usePermissions";
import useRouteMatcher from "hooks/useRouteMatcher";
import { boolToString } from "lib/helpers";
import { CustomFieldT } from "types/Project/interfaces";

import { mountingOptions } from "lib/misc/dropdownOptions";
import Banners from "components/Banners";
import { createBannerDetails } from "components/Banners/helpers";
import SmartForm from "components/SmartForm";
import { RightCaretFilled, DownCaretFilled } from "components/Icons";
import MapDisplay from "components/MapDisplay";
import SingleFileDragAndDrop from "components/FileUploader/SingleFileDragAndDrop";
import SmartFormTextField from "components/mui-styled-components/SmartFormElements/SmartFormTextField";
import SmartFormDropdown from "components/mui-styled-components/SmartFormElements/SmartFormDropdown";
import { cvar } from "styles";
import EnterpriseCustomFields from "containers/Sales/projects/components/EnterpriseCustomFields";
import { SmartFormRadioGroup } from "@mui-styled-components/SmartFormElements";
import { hasExistingSystemOptions } from "components/Tabs/Project/SiteInfoTab/components/SiteInfoForm";
import { isNull, isUndefined } from "types/guards";
import { StyledSectionLabel } from "../LeadSummaryForm/styles";
import { SiteInfoFormT, schema, blankFormData } from "./SiteInfoFormData";

import * as S from "./styles";

type SiteInfoFormPropsT = {
  latestMessage: { message: string; type: string };
  submitRef: React.LegacyRef<HTMLButtonElement>;
  saveRef: React.LegacyRef<HTMLButtonElement>;
  currentTab: number;
  customFields: CustomFieldT[];
  powurFields: CustomFieldT[];
  enterpriseName?: string;
  setCurrentTab: (tabIdx: number) => void;
  setMissingQualifyingSiteInfo: (bool: boolean) => void;
  setMissingProposalSiteInfo: (bool: boolean) => void;
  setIsFormSubmitting: (bool: boolean) => void;
  setIsFormTouched: (bool: boolean) => void;
  closeDrawer: () => void;
};

type UtilityCompanyT = {
  id: number;
  displayName: string;
  externalName: string;
  state: string;
};

const SiteInfoForm = ({
  latestMessage,
  submitRef,
  saveRef,
  currentTab,
  setCurrentTab,
  setMissingQualifyingSiteInfo,
  setMissingProposalSiteInfo,
  setIsFormSubmitting,
  setIsFormTouched,
  closeDrawer,
  customFields,
  powurFields,
  enterpriseName,
}: SiteInfoFormPropsT) => {
  const { restrictAccessModal, isRestrictedUser } = useRestrictAccessModal();

  const dispatch = useDispatch();
  const utilities = useSelector(selectors.selectLeadToProposalUtilities);
  const leadDetails = useSelector(selectors.selectLeadToProposalLeadDetails);
  const { siteInfo, showThdBanner, isQualifiable, isQualified } = leadDetails;
  const { isEnterprise, isAdmin } = useRouteMatcher();
  const { isMobileWidth } = useWindowResize();

  const [isImageUploadOpen, setIsImageUploadOpen] = useState<boolean>(false);
  const { ENTERPRISE_LEAD_GENERATOR } = usePermissions({
    permissionRequests: [permissionTypes.ENTERPRISE_LEAD_GENERATOR],
  });

  const { methods, getSmartFormProps } = useSmartForm<SiteInfoFormT>({
    schema,
    useFormProps: { defaultValues: blankFormData },
  });

  const {
    watch,
    reset,
    trigger,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = methods;

  const values = watch();
  const kwhConsumptionReqd = isQualified && !values.kwhConsumption;

  const utilityChoices = useMemo(
    () =>
      utilities.map((u: UtilityCompanyT) => ({
        value: u.externalName,
        label: u.displayName,
        key: u.id,
      })),
    [utilities],
  );

  useEffect(() => {
    if (!isEmpty(siteInfo) && utilityChoices.length) {
      reset({
        utilityBillUrl: siteInfo.utilityBillUrl || "",
        utilityBillSize: siteInfo.utilityBillInfo.fileSize || 0,
        utilityCompany: siteInfo.utilityCompany || "",
        kwhConsumption: siteInfo.kwhConsumption || "",
        mounting: siteInfo.mounting || "roof",
        hasExistingSystem: !isUndefined(leadDetails?.existingSystem?.hasExistingSystem)
          ? boolToString(leadDetails?.existingSystem?.hasExistingSystem)
          : null,
      });
    }
  }, [leadDetails.siteInfo, utilityChoices]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsFormTouched(isDirty);
  }, [isDirty]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const missingInfoForQualification =
      !values.utilityCompany || !values.hasExistingSystem || !leadDetails?.isAddressConfirmed;
    setMissingQualifyingSiteInfo(missingInfoForQualification);
    setMissingProposalSiteInfo(missingInfoForQualification || !values.kwhConsumption);
  }, [
    values.utilityCompany,
    values.hasExistingSystem,
    leadDetails?.isAddressConfirmed,
    values.kwhConsumption,
    setMissingQualifyingSiteInfo,
    setMissingProposalSiteInfo,
  ]);

  const formatFormValues = () => {
    return {
      leadId: leadDetails.id,
      lead: {
        firstName: leadDetails.firstName,
        lastName: leadDetails.lastName,
        email: leadDetails.email,
        phone: leadDetails.phone,
        locale: leadDetails.locale,
        leadSource: leadDetails.leadSource,
        category: leadDetails.category,
        copilot: leadDetails.copilot,
        notes: leadDetails.notes,
      },
      address: leadDetails.address,
      siteInfoValues: {
        utilityBillUrl: values.utilityBillUrl || null,
        utilityBillSize: values.utilityBillSize || null,
        utilityCompany: values.utilityCompany || null,
        mounting: values.mounting,
        kwhConsumption: values.kwhConsumption || null,
      },
    };
  };

  const handleQualifyLead = () => {
    if (isRestrictedUser) {
      restrictAccessModal();
    } else if (isQualifiable) {
      trigger().then((validForm) => {
        if (validForm) {
          const { leadId, lead, siteInfoValues, address } = formatFormValues();
          setIsFormSubmitting(true);
          dispatch(
            actions.setAlertMessageVisible({
              message: "Submitting. . .",
              severity: "info",
              duration: 6000,
            }),
          );
          if (isDirty) {
            dispatch(
              actions.saveAndSubmitLead({
                leadId,
                lead,
                siteInfo: siteInfoValues,
                address,
                enterprise: isEnterprise,
                existingSystem: !isNull(values.hasExistingSystem) && {
                  hasExistingSystem: values.hasExistingSystem,
                },
                isLeadGen: ENTERPRISE_LEAD_GENERATOR,
              }),
            );
          } else {
            dispatch(
              actions.submitLeadToProvider({
                leadId,
                enterprise: isEnterprise,
                admin: isAdmin,
                isLeadGen: ENTERPRISE_LEAD_GENERATOR,
              }),
            );
          }
        }
      });
      if (latestMessage.type !== "ERROR") {
        if (ENTERPRISE_LEAD_GENERATOR) {
          closeDrawer();
        }
      }
    }
  };

  const handleUpdateLead = () => {
    const { leadId, lead, siteInfoValues, address } = formatFormValues();
    setIsFormSubmitting(true);
    dispatch(
      actions.setAlertMessageVisible({
        message: "Submitting. . .",
        severity: "info",
        duration: 6000,
      }),
    );
    dispatch(
      actions.updateLead({
        leadId,
        lead,
        siteInfo: siteInfoValues,
        existingSystem: !isNull(values.hasExistingSystem) && {
          hasExistingSystem: values.hasExistingSystem,
        },
        address,
        enterprise: isEnterprise,
        admin: isAdmin,
      }),
    );
  };

  const handleSiteImageUpload = (fileUrl: string): void => {
    if (fileUrl) {
      dispatch(
        actions.createLeadAsset({
          leadId: leadDetails.id,
          fileUrl,
          assetType: "custom_design",
        }),
      );
    }
    setValue("customSiteImage", fileUrl);
  };

  const onSubmit = (): void => {
    if (!isQualified) {
      handleQualifyLead();
    } else if (isDirty) {
      handleUpdateLead();
    } else {
      setCurrentTab(currentTab + 1);
    }
  };

  const onError = (e) => {
    dispatch(
      actions.setAlertMessageVisible({
        message: `Invalid Fields: ${Object.keys(e).join(", ")}`,
        severity: "warning",
      }),
    );
  };

  return (
    <SmartForm {...getSmartFormProps({})}>
      <Banners bannerDetails={createBannerDetails(showThdBanner)} />
      {powurFields.length > 0 && (
        <S.CustomFieldsContainer>
          <StyledSectionLabel>Custom Fields</StyledSectionLabel>
          <EnterpriseCustomFields customFields={powurFields} />
          <S.StyledDivider />
        </S.CustomFieldsContainer>
      )}
      {customFields.length > 0 && (
        <S.CustomFieldsContainer>
          <StyledSectionLabel>{enterpriseName} Fields</StyledSectionLabel>
          <EnterpriseCustomFields customFields={customFields} />
          <S.StyledDivider />
        </S.CustomFieldsContainer>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <S.StyledSectionLabel>
            Confirm Location {!leadDetails.isAddressConfirmed && <S.Red>*</S.Red>}
          </S.StyledSectionLabel>
          {!leadDetails.isAddressConfirmed && isMobileWidth && (
            <S.DarkBlueNote>Move pin to exact location</S.DarkBlueNote>
          )}
        </Grid>
        <Grid item xs={12}>
          <MapDisplay
            lead={leadDetails}
            getGeocode={actions.getLeadToProposalDrawerGeocode}
            setGeocode={actions.setLeadToProposalDrawerGeocode}
            latestMessage={latestMessage}
          />
        </Grid>
        <S.ImageUploadNote>
          New build or map imagery missing?
          <S.ImageUploadClickable
            tabIndex={0}
            role="button"
            onKeyDown={() => setIsImageUploadOpen(!isImageUploadOpen)}
            onClick={() => setIsImageUploadOpen(!isImageUploadOpen)}
          >
            Upload custom images
            <S.ClickableCaret>
              {isImageUploadOpen ? (
                <DownCaretFilled fill={cvar("picton-blue")} height="15" width="15" />
              ) : (
                <RightCaretFilled fill={cvar("picton-blue")} height="15" width="15" />
              )}
            </S.ClickableCaret>
          </S.ImageUploadClickable>
        </S.ImageUploadNote>
        {isImageUploadOpen && (
          <Grid item xs={12}>
            <SingleFileDragAndDrop
              apiMode="lead_asset"
              fileUrl={getValues("customSiteImage")}
              setFileUrl={handleSiteImageUpload}
              fileSize={getValues("customSiteImageSize")}
              setFileSize={(bytes) => setValue("customSiteImageSize", bytes)}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <SmartFormDropdown
            name="mounting"
            mainLabel="Mounting Preference"
            dropdownOptions={mountingOptions}
            error={!!errors.mounting}
            helperText={errors.mounting?.message}
          />
        </Grid>
      </Grid>
      <S.StyledDivider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <S.StyledSectionLabel>Utility Bill Information</S.StyledSectionLabel>
          {kwhConsumptionReqd && (
            <S.NoteUrgent>kWh Consumption is required to complete a proposal request</S.NoteUrgent>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <SmartFormDropdown
            name="utilityCompany"
            mainLabel="Utility Company"
            dropdownOptions={utilityChoices}
            error={!!errors.utilityCompany}
            helperText={errors.utilityCompany?.message}
            required
            placeholder="Select..."
            disabled={!utilities.length}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SmartFormTextField
            label="Annual kWh consumption"
            name="kwhConsumption"
            placeholder="kWh"
            required={isQualified}
            error={!!errors.kwhConsumption}
            helperText={errors.kwhConsumption?.message}
            hasRedHighlight={kwhConsumptionReqd}
          />
        </Grid>
        <Grid item xs={12}>
          <S.FormFieldLabel>Utility Bill</S.FormFieldLabel>
          <SingleFileDragAndDrop
            apiMode="lead_utility_bill"
            fileUrl={getValues("utilityBillUrl")}
            setFileUrl={(fileUrl) => setValue("utilityBillUrl", fileUrl, { shouldDirty: true })}
            fileSize={getValues("utilityBillSize")}
            setFileSize={(bytes) => setValue("utilityBillSize", bytes, { shouldDirty: true })}
          />
        </Grid>
      </Grid>
      <S.StyledDivider />
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12}>
          <S.StyledSectionLabel>Existing System</S.StyledSectionLabel>
          <S.ExistingSystemLabel>
            Does this property have an existing solar system? <S.Red>*</S.Red>
          </S.ExistingSystemLabel>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "-24px" }}>
          <SmartFormRadioGroup
            name="hasExistingSystem"
            radioOptions={hasExistingSystemOptions}
            row
            radioLabelStyle={{ marginLeft: "-2px", marginRight: "14px" }}
          />
        </Grid>
      </Grid>
      <button ref={submitRef} style={{ display: "none" }} type="button" onClick={handleSubmit(onSubmit, onError)} />
      <button ref={saveRef} style={{ display: "none" }} type="button" onClick={handleUpdateLead} />
    </SmartForm>
  );
};

export default SiteInfoForm;
