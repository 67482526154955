import types from "rdx/modules/platformModules/types";
import createAction from "rdx/utils/createAction";

export default {
  getPlatformModules: (payload) => createAction(types.GET_PLATFORM_MODULES, payload),
  setPlatformModules: (payload) => createAction(types.SET_PLATFORM_MODULES, payload),
  getEnterpriseModules: (payload) => createAction(types.GET_ENTERPRISE_MODULES, payload),

  toggleModuleActive: (payload) => createAction(types.TOGGLE_MODULE_ACTIVE, payload),

  getModuleDetail: (payload) => createAction(types.GET_MODULE_DETAIL, payload),
  setModuleDetail: (payload) => createAction(types.SET_MODULE_DETAIL, payload),
  // INSERTION_PT (for script, do not remove)
};
