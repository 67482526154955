import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { theme } from "styles/themes";
import { mq } from "styles";

export const GetSignedUpContainer = styled("div")(() => ({
  background: theme.colors["blue-ribbon"],
  width: "100%",
  backgroundImage: "url(/img/setterJoin/join_PowurLogoBackground.png)",
  backgroundRepeat: "no-repeat",
  display: "flex",
  justifyContent: "center",
  [mq.phoneOnly]: {
    padding: "75px 50px",
    backgroundSize: "350px",
    backgroundPosition: "bottom left -70px",
  },
  [mq.tabletUp]: {
    backgroundPosition: "bottom 10px top 40px",
    padding: "80px 120px",
  },
  [mq.tabletLandUp]: {
    padding: "150px 120px",
    backgroundSize: "500px",
  },
}));

export const MaxWidthContainer = styled(Grid)(() => ({
  maxWidth: "1440px",
}));

export const HeaderText = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  fontFamily: "Sora",
  fontSize: "48px",
  lineHeight: "60px",
  color: theme.colors.white,
  fontWeight: 400,
  textTransform: "capitalize",
  [mq.phoneOnly]: {
    alignItems: "flex-start",
    textAlign: "left",
  },
  [mq.tabletUp]: {
    alignItems: "center",
    textAlign: "center",
    maxWidth: "500px",
  },
  [mq.desktopUp]: {
    alignItems: "flex-start",
    textAlign: "left",
    maxWidth: "none",
  },
}));

export const ButtonColumn = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "24px",
  justifyContent: "center",
  [mq.phoneOnly]: {
    alignItems: "flex-start",
  },
  [mq.tabletUp]: {
    alignItems: "center",
  },
  [mq.desktopUp]: {
    alignItems: "flex-end",
  },
}));
