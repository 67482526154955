import { Button, styled } from "@mui/material";

import { filterStyledProps } from "lib/helpers/filterStyledProps";

type MobilePropsT = {
  isMobile?: boolean;
  isActive?: boolean;
};

export const FilterByDateButton = styled(Button, { shouldForwardProp: filterStyledProps(["isMobile", "isActive"]) })(
  ({ isMobile, isActive }: MobilePropsT) =>
    ({ theme }) => ({
      height: "28px",
      minWidth: isMobile ? "40px" : "148px",
      width: isMobile ? "40px" : "fit-content",
      borderRadius: "4px",
      alignItems: "center",
      justifyContent: "left",
      boxShadow: "none",
      padding: "0px 9px",
      marginLeft: "6px",
      background: isActive ? theme.colors["banner-blue"] : theme.colors["cotton-boll"],
      textTransform: "none",
      color: isActive ? theme.colors.white : theme.colors["banner-blue"],
      ...(isMobile && { display: "flex", justifyContent: "center" }),
      "&:hover": {
        background: isActive ? theme.colors["banner-blue-highlighted"] : theme.colors["cotton-boll-highlighted"],
        boxShadow: "none",
      },
      "&:focus": {
        boxShadow: "none",
      },
      "& .MuiTouchRipple-root": {
        display: "none",
      },
    }),
);
export const IconContainer = styled("div", { shouldForwardProp: filterStyledProps(["isMobile"]) })(
  ({ isMobile }: { isMobile: boolean }) => ({
    display: "flex",
    ...(!isMobile && { paddingRight: "6px" }),
  }),
);
