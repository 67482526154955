import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getAdminProjectProposals(action) {
  const { id } = action.payload;

  const { success, data, error } = yield* makeRequest.get(`/projects/${id}/proposals`, { project_id: id });

  if (success && data) {
    const proposals = camelizeKeys(data.body);
    yield put(
      actions.setAdminProjectProposals({
        goSoloProposals: proposals.soloProposals,
        lightreachProposals: proposals.lightreachProposals,
        visionProposals: proposals.visionProposals,
        visionProposal: proposals.visionProposal,
      }),
    );
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_ADMIN_PROJECT_PROPOSALS,
    });
  }
  return null;
}

export default getAdminProjectProposals;
