export default {
  START_USERS_REPORT_REQUEST: "[reports] Start Users Report Request",
  GET_USERS_REPORT_REQUEST: "[reports] GET Users Report Request",
  SET_USERS_REPORT: "[reports] SET Users Report",
  START_USERS_CONTEST_REPORT_REQUEST: "[reports] Start Users Contest Report Request",
  GET_USERS_CONTEST_REPORT_REQUEST: "[reports] GET Users Contest Report Request",
  SET_USERS_CONTEST_REPORT: "[reports] SET Users Contest Report",
  START_USER_PROJECTS_REPORT_REQUEST: "[reports] Start User Projects Report Request",
  GET_USER_PROJECTS_REPORT_REQUEST: "[reports] GET User Projects Report Request",
  SET_USER_PROJECTS_REPORT: "[reports] SET User Projects Report",
};
