import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const CheckMarkIcon = ({ height = "13", width = "18", fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 1L6 12L1 7" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default CheckMarkIcon;
