// React/Redux
import React, { useState, useLayoutEffect, useRef, ComponentType } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import selectors from "rdx/selectors";
import actions from "rdx/actions";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { Layout } from "antd";
import { useSessionStorage } from "react-use";
import ReactIs from "react-is";

import SessionClient from "util/SessionClient";

import useWindowResize from "hooks/useWindowResize";
import useGetCurrentRouteInfo from "hooks/useGetCurrentRouteInfo";

import TermsOfServiceModal from "containers/login/components/TermsOfService/TermsOfServiceModal";

import MainNav from "./components/MainNav";
import PlatformPageHeader from "./components/PlatformPageHeader";
import TabsControl from "./components/TabsControl";
import Sidebar from "./components/Sidebar";
import ClosedProjectBanner from "./components/Banners/ClosedProjectBanner";

import styles from "./PlatformLayout.module.less";
import HeaderInfoSection from "./components/HeaderInfoSection";

const { Sider } = Layout;

const LayoutContext = React.createContext({});

const PlatformLayout = () => {
  const [mainNavCollapsed, setMainNavCollapsed] = useState(true);
  const [menuWidth, setMenuWidth] = useState<string | number>(48);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { currentRoute, platformRoutes: routes } = useGetCurrentRouteInfo();
  const { width } = useWindowResize();
  const mobile = width <= 500;

  const CONTENT_WINDOW_ID = "main-content-window";
  const LAYOUT_ID = "powur-energy-platform-layout";

  const session = new SessionClient();
  const { backOfficeAccess, impersonating } = session;

  const [lastGettingStartedRedirect, setLastGettingStartedRedirect] = useSessionStorage(
    "lastGettingStartedRedirect",
    new Date(Date.now() - 10 * 60 * 1000),
  );

  // Initial load on PlatformLayout redirects some users to alternative paths:
  useLayoutEffect(() => {
    const sboa = backOfficeAccess;
    const lastRedirect = new Date(lastGettingStartedRedirect);
    const now = new Date(Date.now());
    const redirectDiff = Math.floor((now.getTime() - lastRedirect.getTime()) / (1000 * 60));

    // Handle banned users:
    if (sboa === false && !impersonating) {
      history.push("/");
      console.error("Error handling this request for back office access.");
    }
    if (
      (sboa === "needs_activation" || sboa === "needs_certification") &&
      redirectDiff > 9 &&
      session?.user?.props.kind !== "enterprise_pro" &&
      !session?.user?.props?.seatId
    ) {
      setLastGettingStartedRedirect(new Date(Date.now()));
      history.push("/getting-started");
    }
  }, [
    backOfficeAccess,
    history,
    impersonating,
    lastGettingStartedRedirect,
    setLastGettingStartedRedirect,
    session?.user?.props.kind,
    session?.user?.props?.seatId,
  ]);

  const backgroundLinesUrl = useSelector(selectors.selectBackgroundLinesUrl);
  const containerOverFlow = useSelector(selectors.selectcontainerOverFlow);
  const bannersHeight = useSelector(selectors.selectBannersHeight);
  const alertMessage = useSelector(selectors.selectAlertMessage);
  const isModalVisible = useSelector(selectors.selectTermsOfServiceModalVisible);
  const isProjectMiniHeaderActive = useSelector(selectors.selectProjectMiniHeaderActive);
  const latestErrorUID = useSelector(selectors.getLatestErrorUID);

  const contentRef = useRef(null);
  const containerRef = useRef(null);

  const openMainNav = (e) => {
    if (e) {
      e.stopPropagation();
      setMainNavCollapsed(false);
    }
  };

  const closeMainNav = () => {
    if (containerRef?.current?.scrollTop) {
      containerRef.current.scrollTop = 0;
    }
    setMainNavCollapsed(true);
  };

  const innerLayoutPadding = () => {
    const basePadding = currentRoute?.newHeader ? 48 : 101;
    const padding = basePadding + bannersHeight;

    if (!currentRoute.tabs) {
      return `${padding - 54}px`;
    }

    return `${padding}px`;
  };

  useLayoutEffect(() => {
    if (mobile) {
      setMenuWidth(mainNavCollapsed ? 0 : "90%");
    } else {
      setMenuWidth(mainNavCollapsed ? 48 : 200);
    }
  }, [mainNavCollapsed, width]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    const content = document.querySelector(`.${styles.content}`);
    if (content) {
      content.scrollTo(0, 0);
    }
  }, [location]);

  const closeAlertMessage = () => {
    dispatch(actions.clearAlertMessage());
  };

  const handleScroll = (event: React.UIEvent): void => {
    if (currentRoute.newHeader) {
      const { scrollTop, scrollHeight } = event.target as HTMLElement;

      const heightOfPageHeaders = 256;
      const differenceOfPageHeaders = 90;
      const scrollablePageHeight = window.innerHeight - heightOfPageHeaders;
      const enoughContentToTriggerShrinking = scrollHeight - scrollablePageHeight > differenceOfPageHeaders;
      const scrollPointTrigger = scrollTop > 20;
      const shouldShrinkProjectHeader = scrollPointTrigger && enoughContentToTriggerShrinking;

      if (shouldShrinkProjectHeader !== isProjectMiniHeaderActive) {
        dispatch(actions.setProjectMiniHeaderActive(shouldShrinkProjectHeader));
      }
    } else if (isProjectMiniHeaderActive) {
      dispatch(actions.setProjectMiniHeaderActive(false));
    }
  };

  return (
    <LayoutContext.Provider
      value={{
        CONTENT_WINDOW_ID,
        LAYOUT_ID,
        contentRef,
      }}
    >
      <Layout id={LAYOUT_ID} className={styles.pageContainer}>
        <PlatformPageHeader
          mainNavCollapsed={mainNavCollapsed}
          toggleMenu={() => setMainNavCollapsed(!mainNavCollapsed)}
        />
        <Snackbar
          open={!!alertMessage.visible && !!alertMessage.message}
          autoHideDuration={alertMessage.duration}
          onClose={closeAlertMessage}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={closeAlertMessage} severity={alertMessage.severity}>
            {alertMessage.message}
            {latestErrorUID && (
              <>
                <br />
                Error ID: <strong>{latestErrorUID}</strong>
              </>
            )}
          </Alert>
        </Snackbar>
        <Layout
          className={styles.outerLayout}
          onClick={() => !mainNavCollapsed && setMainNavCollapsed(!mainNavCollapsed)}
        >
          <Sider
            className={styles.topLevelSider}
            collapsible
            collapsed={false}
            collapsedWidth={0}
            trigger={null}
            width={menuWidth}
          >
            <MainNav
              mainNavCollapsed={mainNavCollapsed}
              expanded
              currentRoute={currentRoute}
              openMainNav={openMainNav}
              closeMainNav={closeMainNav}
              containerRef={containerRef}
            />
          </Sider>
          <Layout
            className={styles.innerLayout}
            id={CONTENT_WINDOW_ID}
            style={{
              paddingTop: innerLayoutPadding(),
            }}
          >
            <ClosedProjectBanner />
            <>{currentRoute?.newHeader && <HeaderInfoSection />}</>
            <TabsControl />
            <Layout className={styles.contentLayout}>
              <Sidebar />
              <div
                className={[
                  backgroundLinesUrl ? styles.contentWithBackground : styles.content,
                  currentRoute?.newHeader && styles.onlyForProject,
                ].join(" ")}
                ref={contentRef}
                style={{
                  backgroundImage: `url(${backgroundLinesUrl})`,
                  overflow: containerOverFlow,
                  ...(currentRoute?.newHeader ? { display: "flex", justifyContent: "center" } : {}),
                }}
                onScroll={handleScroll}
              >
                <Switch>
                  {routes &&
                    routes.map((r) => {
                      return (
                        <Route
                          {...r}
                          component={
                            ReactIs.isValidElementType(r.content)
                              ? (r.content as ComponentType<unknown>)
                              : ((<></>) as unknown as ComponentType<unknown>)
                          }
                        />
                      );
                    })}
                </Switch>
              </div>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
      <TermsOfServiceModal modalVisible={isModalVisible} />
    </LayoutContext.Provider>
  );
};

function useLayoutContext() {
  const context = React.useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useLayoutContext must be used within a LayoutContext");
  }
  return context;
}

export { LayoutContext, useLayoutContext };
export default PlatformLayout;
