import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, AutoComplete } from "antd";
import FormErrorMessage from "components/FormElements/FormErrorMessage";
import { addressLookup } from "lib/helpers";
import styles from "../../AmbassadorDashboard.module.less";

const { Option } = AutoComplete;

const AddressLookupField = (props) => {
  const { handleAutocompleteSelect, onAutocompleteSearch } = props;
  const [suggestions, setSuggestions] = useState([]);

  const fetchAutocompleteSuggestions = async (value) => {
    const addresses = await addressLookup(value);

    setSuggestions(
      addresses.map((address, idx) => {
        return (
          <Option
            className={styles.input}
            // eslint-disable-next-line react/no-array-index-key
            key={`${address.street_line} ${address.city} ${idx}`}
            value={`${address.street_line} ${address.city}, ${address.state} ${address.zipcode}`}
            data={address}
          >
            {address.street_line} {address.city} {address.state}, {address.zipcode}
          </Option>
        );
      }),
    );

    onAutocompleteSearch(value);
  };

  return (
    <Form.Item
      name={["street"]}
      className={styles.formItem}
      rules={[
        {
          required: true,
          message: <FormErrorMessage message="Referral's street address is required." />,
        },
      ]}
    >
      <AutoComplete
        className={styles.autoCompleteInput}
        onSearch={fetchAutocompleteSuggestions}
        onSelect={handleAutocompleteSelect}
        size="large"
        placeholder="STREET ADDRESS"
      >
        {suggestions}
      </AutoComplete>
    </Form.Item>
  );
};

AddressLookupField.propTypes = {
  handleAutocompleteSelect: PropTypes.func,
  onAutocompleteSearch: PropTypes.func,
  zipInfo: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
  }),
};

export default AddressLookupField;
