import React from "react";

const SiteSurvey = () => {
  return (
    <svg width={33} height={33} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16.9316" cy="16" r="16" fill="var(--dark-blue)" />
      <path
        d="M13.6849 11.2449H20.178C20.3008 11.2449 20.401 11.148 20.401 11.0284C20.401 10.9088 20.3008 10.8086 20.178 10.8086H13.6849C13.5621 10.8086 13.4619 10.9056 13.4619 11.0284C13.4619 11.148 13.5621 11.2449 13.6849 11.2449Z"
        fill="white"
      />
      <path
        d="M22.0369 9.93431C22.1759 9.93431 22.289 10.0474 22.289 10.1832V11.8606H11.5749V10.1832C11.5749 10.0474 11.688 9.93431 11.827 9.93431H22.0369ZM16.9304 8.43466C17.5606 8.43466 18.0971 8.88714 18.1941 9.49799H15.6699C15.7701 8.89037 16.3033 8.43789 16.9304 8.43466ZM22.289 23.3148C22.289 23.4506 22.1759 23.5637 22.0369 23.5637H11.827C11.688 23.5637 11.5749 23.4538 11.5749 23.3148V12.3001H22.2923L22.289 23.3148ZM11.1289 10.1832V23.3148C11.1289 23.693 11.4424 24 11.827 24H22.0369C22.4215 24 22.735 23.693 22.735 23.3148V10.1832C22.735 9.80503 22.4215 9.49799 22.0369 9.49799H18.6466C18.5334 8.56717 17.677 7.90461 16.73 8.01126C15.9381 8.10176 15.3111 8.71907 15.2174 9.49799H11.827C11.4424 9.49799 11.1289 9.80503 11.1289 10.1832Z"
        fill="white"
      />
      <path
        d="M15.6244 14.7137H20.8893C21.0121 14.7137 21.1123 14.6167 21.1123 14.4971C21.1123 14.3743 21.0121 14.2773 20.8893 14.2773H15.6244C15.5016 14.2773 15.4014 14.3743 15.4014 14.4971C15.4046 14.6167 15.5016 14.7137 15.6244 14.7137Z"
        fill="white"
      />
      <path
        d="M15.6244 16.9864H20.8893C21.0121 16.9864 21.1123 16.8895 21.1123 16.7667C21.1123 16.6471 21.0121 16.5469 20.8893 16.5469H15.6244C15.5016 16.5469 15.4014 16.6438 15.4014 16.7667C15.4046 16.8895 15.5016 16.9864 15.6244 16.9864Z"
        fill="white"
      />
      <path
        d="M15.6244 19.2716H20.8893C21.0121 19.2716 21.1123 19.1746 21.1123 19.0518C21.1123 18.9322 21.0121 18.832 20.8893 18.832H15.6244C15.5016 18.832 15.4014 18.929 15.4014 19.0518C15.4046 19.1714 15.5016 19.2716 15.6244 19.2716Z"
        fill="white"
      />
      <path
        d="M15.6244 21.5496H20.8893C21.0121 21.5496 21.1123 21.4526 21.1123 21.3298C21.1123 21.2102 21.0121 21.1133 20.8893 21.1133H15.6244C15.5016 21.1133 15.4014 21.2102 15.4014 21.3298C15.4046 21.4494 15.5016 21.5496 15.6244 21.5496Z"
        fill="white"
      />
      <path
        d="M13.4046 14.8282C13.4402 14.8767 13.4919 14.909 13.5533 14.9187H13.5856C13.6341 14.9187 13.6826 14.9026 13.7213 14.8735L15.1402 13.7843C15.2469 13.7229 15.2824 13.5904 15.221 13.4837C15.1596 13.3803 15.0238 13.3447 14.9172 13.4029C14.8978 13.4126 14.8816 13.4255 14.8655 13.4417L13.6309 14.3887L13.4143 14.0881C13.3432 13.9879 13.2042 13.9653 13.104 14.0332C13.0038 14.101 12.978 14.24 13.0491 14.337L13.4046 14.8282Z"
        fill="white"
      />
      <path
        d="M13.4038 17.0828C13.4393 17.1313 13.4911 17.1636 13.5525 17.1733H13.5848C13.6333 17.1733 13.6817 17.1571 13.7205 17.1248L15.1394 16.0356C15.2396 15.9645 15.259 15.8288 15.1879 15.7286C15.1135 15.6316 14.9745 15.6122 14.8776 15.6833C14.8744 15.6866 14.8711 15.6898 14.8679 15.6898L13.6365 16.64L13.42 16.3394C13.365 16.2328 13.2325 16.1875 13.1226 16.2425C13.0127 16.2974 12.9675 16.4267 13.0224 16.5366C13.0321 16.5528 13.0418 16.5689 13.0547 16.5851L13.4038 17.0828Z"
        fill="white"
      />
      <path
        d="M13.403 19.3335C13.4386 19.382 13.4903 19.4143 13.5517 19.424H13.584C13.6325 19.424 13.681 19.4078 13.7198 19.3787L15.1386 18.2895C15.2388 18.2184 15.2582 18.0795 15.1871 17.9825C15.1128 17.8855 14.9738 17.8661 14.8768 17.9373C14.8736 17.9405 14.8704 17.9437 14.8671 17.9437L13.6325 18.8907L13.4192 18.5901C13.3481 18.4899 13.2091 18.4673 13.1089 18.5352C13.0087 18.6063 12.9829 18.742 13.054 18.839L13.403 19.3335Z"
        fill="white"
      />
      <path
        d="M13.4043 21.6107C13.4399 21.6592 13.4916 21.6915 13.553 21.7012H13.5853C13.6338 21.7012 13.6823 21.6851 13.721 21.656L15.1399 20.5668C15.2433 20.5021 15.2756 20.3664 15.211 20.2662C15.1464 20.1628 15.0074 20.1337 14.904 20.1983C14.891 20.2048 14.8781 20.2145 14.8684 20.2242L13.6338 21.1712L13.4172 20.8706C13.3461 20.7704 13.2072 20.7478 13.107 20.8157C13.0068 20.8835 12.9809 21.0193 13.052 21.1195L13.4043 21.6107Z"
        fill="white"
      />
    </svg>
  );
};

export default SiteSurvey;
