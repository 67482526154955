import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const SquareIcon = ({ width = "7", height = "7", fill = cvar("darkest-blue") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="7" height="7" rx="2" fill={fill} />
    </svg>
  );
};

export default SquareIcon;
