import dayjs from "dayjs";

export default function formatTimeAmPm(dateString) {
  const timeZone = new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2];

  const conversionStrings = {
    PST: "PT",
    EST: "ET",
  };

  return `${dayjs(dateString).format("h:mmA")} ${conversionStrings[timeZone] || timeZone}`;
}
