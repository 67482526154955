import createReducer from "rdx/utils/createReducer";
import types from "rdx/types";

const initialState = {
  dataGroups: [],
  milestoneOptions: [],
  workflows: [],
  workflowDetails: {},
  phases: [],
};

export default {
  workflows: createReducer(initialState, {
    [types.SET_DATA_GROUPS](state, action) {
      return {
        ...state,
        dataGroups: action.payload,
      };
    },
    [types.SET_MILESTONE_OPTIONS](state, action) {
      return {
        ...state,
        milestoneOptions: action.payload,
      };
    },
    [types.SET_WORKFLOW_DETAILS](state, action) {
      return {
        ...state,
        workflowDetails: action.payload,
      };
    },
    [types.SET_WORKFLOWS](state, action) {
      return {
        ...state,
        workflows: action.payload,
      };
    },
    [types.SET_PHASES](state, action) {
      return {
        ...state,
        phases: action.payload,
      };
    },
    // INSERTION_PT (for script, do not remove)
  }),
};
