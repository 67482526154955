import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToSnakeCase } from "lib/helpers";
import messages from "../messages";

function* getEnterpriseProLaborForm(action) {
  const params = {
    ...action.payload,
  };
  const paramsToSnakeCase = keysToSnakeCase(params);

  const { success, data, error } = yield* makeRequest.post("/labor_partner_applications", paramsToSnakeCase);
  if (success && data) {
    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.ENTERPRISE_PRO_LABOR_FORM_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ENTERPRISE_PRO_LABOR_FORM_FAIL,
    });
  }
  return null;
}

export default getEnterpriseProLaborForm;
