import React from "react";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { CloseIcon } from "components/Icons";
import GradientGhostButton from "components/Buttons/GradientGhostButton";
import { PrimaryButton } from "components/mui-styled-components/Buttons";
import { Tooltip } from "components/mui-styled-components/Tooltip";

import { cvar } from "styles";

type SimpleModalPropsT = {
  open: boolean;
  title: string | React.ReactNode;
  handleConfirm: () => void;
  handleCancel?: () => void;
  onClose?: () => void;
  children?: React.ReactNode;
  titleStyles?: React.CSSProperties;
  cancelButton?: boolean;
  confirmButtonText?: string;
  buttonHeight?: number;
  justifyFooterContent?: "flex-start" | "center" | "flex-end";
  maxWidth?: number;
  width?: number;
  confirmDisabled?: boolean;
  disabledReason?: string;
};

const SimpleModal = ({
  onClose,
  open,
  children,
  handleConfirm,
  title,
  titleStyles = {},
  handleCancel = () => null,
  cancelButton = true,
  confirmButtonText = "Accept",
  buttonHeight = 32,
  justifyFooterContent = "flex-end",
  maxWidth,
  width,
  confirmDisabled = false,
  disabledReason,
}: SimpleModalPropsT) => {
  const onCancel = () => {
    handleCancel();
    if (onClose) onClose();
  };
  const onConfirm = () => {
    handleConfirm();
  };

  return (
    <Dialog
      onClose={onCancel}
      open={open}
      maxWidth="lg"
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "12px",
          maxHeight: "96%",
          ...(maxWidth ? { maxWidth } : {}),
          ...(width ? { width } : {}),
        },
      }}
    >
      <DialogTitle
        sx={{
          background: cvar("white"),
          color: cvar("blue-ribbon"),
          fontFamily: "Poppins",
          fontWeight: "700",
          fontSize: "18px",
          lineHeight: "21px",
          textTransform: "capitalize",
          ...titleStyles,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: "24px" }}>{children}</DialogContent>
      <DialogActions
        sx={{
          justifyContent: justifyFooterContent,
          padding: "14px 22px",
          background: cvar("dark-blue-a07"),
        }}
      >
        {cancelButton && (
          <GradientGhostButton onClick={onCancel} style={{ width: buttonHeight, height: buttonHeight }}>
            <CloseIcon startFill={cvar("picton-blue")} stopFill={cvar("blue-ribbon")} />
          </GradientGhostButton>
        )}
        {confirmDisabled ? (
          <Tooltip title={disabledReason} placement="top">
            <div>
              <PrimaryButton
                gradient
                style={{ minWidth: "164px", marginLeft: "10px", height: buttonHeight, padding: "8px 24px" }}
                disabled
              >
                {confirmButtonText}
              </PrimaryButton>
            </div>
          </Tooltip>
        ) : (
          <PrimaryButton
            onClick={onConfirm}
            gradient
            style={{ minWidth: "164px", height: buttonHeight, padding: "8px 24px" }}
          >
            {confirmButtonText}
          </PrimaryButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SimpleModal;
