import React from "react";
import PropTypes from "prop-types";
import styles from "./GetQuoteModal.module.less";

const CircledTextFilled = ({ text }) => {
  return (
    <div className={styles.outerCircle}>
      <span className={styles.text}>{text}</span>
    </div>
  );
};

CircledTextFilled.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CircledTextFilled;
