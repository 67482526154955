import actions from "rdx/modules/enrollment/actions";
import PropTypes from "prop-types";
import { store } from "rdx/configureStore";

const { renewEnrollment, cancelEnrollment, subscribeEnrollment } = actions;
export class Enrollment {
  constructor(props) {
    this.props = props;
  }

  static sources = {
    card: "credit_card",
  };

  static defaultService = "powur_energy";

  static displayNames = {
    powur_energy: "Powur Energy",
    powur_live: "Powur Live",
    powur_enterprise: "Powur Enterprise",
  };

  static post(tokenResponse = {}, tos = null, service = this.defaultService) {
    const { id: token, type: source } = tokenResponse;

    store.dispatch(
      subscribeEnrollment({
        service,
        token,
        tos,
        sourceType: Enrollment.sources[source],
      }),
    );
  }

  cancel(service = Enrollment.defaultService) {
    store.dispatch(
      cancelEnrollment({
        service,
      }),
    );
  }

  renew(service = Enrollment.defaultService) {
    store.dispatch(
      renewEnrollment({
        service,
      }),
    );
  }

  static enrollmentTypes = {};

  static types() {
    return PropTypes.shape(this.enrollmentTypes);
  }
}

export default Enrollment;
