import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enterprisePersonnel/messages";
import { camelizeKeys } from "lib/helpers";

function* updateEnterpriseUserSettings(action) {
  const {
    seatId,
    offerPercentage,
    sellerPercentage,
    mentorshipRequirement,
    sellerInvitesAllowed,
    leadGeneratorUserAmount,
    leadGeneratorUserPercentage,
    title,
    managerCommission,
    supportCommission,
  } = action.payload;
  const params = {
    offer_percentage: offerPercentage,
    seller_percentage: sellerPercentage,
    mentorship_requirement: mentorshipRequirement,
    seller_invites_allowed: sellerInvitesAllowed,
    lead_generator_amount: leadGeneratorUserAmount,
    lead_generator_percentage: leadGeneratorUserPercentage,
    title,
    manager_commission: managerCommission,
    support_commission: supportCommission,
  };

  const { success, data, error } = yield* makeRequest.post(`/seats/${seatId}/settings`, params);
  if (success && data) {
    yield all([
      put(actions.setEnterpriseSeatDetails(camelizeKeys(data.body))),
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_ENTERPRISE_USER_SETTINGS_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_ENTERPRISE_USER_SETTINGS,
    });
  }

  return null;
}

export default updateEnterpriseUserSettings;
