import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/users/messages";

function* getPermissionsEntities() {
  const { success, data, error } = yield* makeRequest.get("/entities", {});

  if (success && data) {
    yield all([
      put(actions.setRolesList({ entities: data.body.entities })),
      put(actions.newSuccessEvent({ message: messages.FETCH_ENTITIES_SUCCESS })),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PERMISSIONS_ENTITIES,
    });
  }

  return null;
}

export default getPermissionsEntities;
