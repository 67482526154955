import React, { FC } from "react";

import { defaults } from "models/User";

import BadgeAvatar from "components/Avatars/BadgeAvatar";
import { OrgSellerStar } from "components/Icons";
import ConferenceIcon from "components/Icons/ConferenceIcon";
import EvolutionTooltipContents from "components/Avatars/BadgeAvatar/components/EvolutionTooltipContent";

type iconSizeT = {
  width: string;
  height: string;
};

type ConferenceBadgeAvatarT = {
  user?: typeof defaults & { confReg?: boolean; isOrg?: boolean };
  size: number;
  iconSize: iconSizeT;
};

const ConferenceBadgeAvatar: FC<ConferenceBadgeAvatarT> = ({ user, size, iconSize }) => {
  const { confReg, isOrg } = user || {};
  const { width = "23px", height = "23px" } = iconSize || {};

  return (
    <BadgeAvatar
      size={size}
      thumb={user?.avatar?.thumb}
      showBadge={!!isOrg || !!confReg}
      badgeContent={confReg ? <ConferenceIcon shade="dark" height={height} width={width} /> : <OrgSellerStar />}
      tooltipContent={confReg ? <EvolutionTooltipContents /> : null}
    />
  );
};

export default ConferenceBadgeAvatar;
