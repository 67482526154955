import { select, put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { keysToSnakeCase, camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* createDashboardPromotion(action) {
  const { success, data, error } = yield* makeRequest.post("/dashboard_promotions", keysToSnakeCase(action.payload));

  if (success && data) {
    const list = yield select(selectors.selectDashboardPromotions);

    const out = {
      ...list,
      promotions: [camelizeKeys(data.body), ...list.promotions],
    };

    yield all([
      put(actions.setDashboardPromotions(out)),
      put(
        actions.newSuccessEvent({
          message: messages.CREATE_EVENT_PROMO_SUCCESS,
        }),
      ),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.CREATE_EVENT_PROMO_ERROR,
    });
  }
  return null;
}

export default createDashboardPromotion;
