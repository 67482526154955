import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/consultantDashboard/messages";
import { camelizeKeys } from "lib/helpers";

function* getDashboardProjectActivity(action) {
  const { tab, page, activeFilters, sort, isOrgDashboard } = action.payload;
  const endpoint = isOrgDashboard ? "/enterprise_dashboard" : "/consultant_dashboard";

  const { success, data, error } = yield* makeRequest.get(`${endpoint}/project_activity`, {
    tab,
    page_scroll: page,
    active_filters: activeFilters,
    offset: 20 * (page - 1),
    limit: 20,
    sort,
  });

  if (success && data) {
    yield all([put(actions.setSellerDashboard(camelizeKeys(data.body)))]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROJECT_ACTIVITY,
    });
  }

  return null;
}

export default getDashboardProjectActivity;
