import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* createAmbassadorLead(action) {
  const {
    firstName: first_name,
    lastName: last_name,
    email,
    phone,
    utilityBill,
    street,
    city,
    state,
    zip,
  } = action.payload;

  const site_info = {
    utility_bill_url: utilityBill,
  };

  const { success, data, error } = yield* makeRequest.post("/leads/ambassador", {
    site_info,
    first_name,
    last_name,
    email,
    phone,
    street,
    city,
    state,
    zip,
  });

  if (success && data) {
    yield put(
      actions.newSuccessEvent({
        message: messages.CREATE_NEW_AMBASSADOR_LEAD_SUCCESS,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_AMBASSADOR_LEAD,
    });
  }

  return null;
}

export default createAmbassadorLead;
