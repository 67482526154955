import React from "react";
import { cvar } from "styles";

type PencilEditIconT = {
  height?: string | number;
  width?: string | number;
  fill?: string | null;
};

const PencilEditIcon = ({ height = "12", width = "12", fill = null }: PencilEditIconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 11" fill="none">
      <path
        d="M2.12793 7.37617L6.90937 2.59473L9.35677 5.04213L4.57533 9.82357L2.12793 7.37617Z"
        fill={fill || cvar("white")}
      />
      <path
        d="M1.00204 10.0707C0.809788 10.7437 1.19429 11.1443 1.88339 10.9521L4.07864 10.3271L1.62698 7.85938L1.00204 10.0707Z"
        fill={fill || cvar("white")}
      />
      <path
        d="M10.7606 2.28296L9.67103 1.19338C9.3025 0.824862 8.69357 0.824862 8.30893 1.19338L7.39551 2.10669L9.84718 4.55836L10.7606 3.64494C11.1452 3.26042 11.1452 2.65148 10.7606 2.28296Z"
        fill={fill || cvar("white")}
      />
    </svg>
  );
};

export default PencilEditIcon;
