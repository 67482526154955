import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const UncheckedRadioButton = ({
  height = "24",
  width = "24",
  fill = cvar("white"),
  stroke = cvar("nepal-blue"),
}: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11.5" fill={fill} stroke={stroke} />
    </svg>
  );
};
export default UncheckedRadioButton;
