export default {
  // EXAMPLE_ACTION_TYPE: "[workSchedules] Example Action Type",

  GET_SCHEDULES_BY_TEAM: "[workSchedules] GET Schedules By Team",
  SET_SCHEDULES_BY_TEAM: "[workSchedules] SET Schedules By Team",
  UPDATE_WORK_SCHEDULES: "[workSchedules] Update Work Schedules",
  SET_WORK_SCHEDULES: "[workSchedules] SET Work Schedules",
  RESET_WORK_SCHEDULES: "[workSchedules] Reset Work Schedules",

  // INSERTION_PT (for script, do not remove)
};
