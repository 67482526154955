import React from "react";
import PropTypes from "prop-types";

const StripeIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.424 22.848H82.528C82.832 25.936 85.088 26.928 87.648 26.928C90.272 26.928 92.384 26.336 94.128 25.408V30.72C91.9148 31.9934 89.3824 32.6043 86.832 32.48C80.416 32.48 75.904 28.48 75.904 20.512C75.904 13.808 79.728 8.48 85.984 8.48C92.256 8.48 95.52 13.728 95.52 20.48C95.52 21.12 95.456 22.496 95.424 22.848ZM85.952 13.856C84.304 13.856 82.48 15.024 82.48 17.984H89.28C89.28 15.024 87.568 13.856 85.952 13.856ZM65.52 32.48C63.216 32.48 61.808 31.52 60.88 30.816L60.848 38.224L54.256 39.616V8.912H60.272L60.4 10.544C61.7954 9.22046 63.6448 8.48184 65.568 8.48C70.208 8.48 74.56 12.64 74.56 20.32C74.56 28.688 70.24 32.48 65.52 32.48ZM64 14.32C62.48 14.32 61.536 14.864 60.848 15.616L60.88 25.408C61.52 26.112 62.448 26.656 64 26.656C66.432 26.656 68.064 24.016 68.064 20.464C68.064 17.024 66.4 14.32 64 14.32ZM45.184 8.912H51.792V32.016H45.184V8.912ZM45.184 1.392L51.792 0V5.376L45.184 6.784V1.408V1.392ZM38.272 16.352V32.016H31.68V8.912H37.6L37.792 10.864C39.392 8.032 42.704 8.608 43.584 8.912V14.976C42.752 14.704 39.92 14.288 38.272 16.352ZM24.592 23.904C24.592 27.792 28.752 26.592 29.584 26.24V31.616C28.704 32.096 27.12 32.48 24.96 32.48C24.0597 32.5063 23.1636 32.3491 22.326 32.0181C21.4884 31.6871 20.7269 31.1891 20.0878 30.5545C19.4487 29.9199 18.9454 29.162 18.6085 28.3267C18.2716 27.4915 18.1081 26.5964 18.128 25.696L18.144 4.624L24.576 3.248V8.912H29.6V14.56H24.592V23.92V23.904ZM16.736 25.024C16.736 29.776 13.04 32.48 7.568 32.48C5.11251 32.4781 2.6836 31.9717 0.432 30.992V24.704C2.64 25.904 5.392 26.8 7.568 26.8C9.04 26.8 10.016 26.416 10.016 25.2C10.016 22.032 0 23.216 0 15.92C0 11.264 3.648 8.48 8.992 8.48C11.168 8.48 13.344 8.8 15.536 9.68V15.888C13.5103 14.8219 11.2646 14.2413 8.976 14.192C7.6 14.192 6.672 14.592 6.672 15.632C6.672 18.592 16.736 17.184 16.736 25.04V25.024Z"
        fill={fill}
      />
    </svg>
  );
};

StripeIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

StripeIcon.defaultProps = {
  width: "96",
  height: "40",
  fill: "var(--dark-blue)",
};

export default StripeIcon;
