import component from "containers/StaticPages/enterpriseSellerJoin";

export default {
  key: "enterprise-join",
  title: "Enterprise",
  path: "/:user?/enterprise-seller-join/:signup?",
  pageName: "enterprise-seller-join",
  linkTarget: "/enterprise-seller-join",
  component,
};
