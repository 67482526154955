import _ from "lodash";
import { parse } from "query-string";
import SessionClient from "./SessionClient";

class ApiClient {
  static defaults = {
    host: process.env.REACT_APP_POWUR_API_URL,
    baseUrl: "/json_web",
    urls: {
      jwt: "/jwt",
      sso: "/saml/auth",
    },
  };

  constructor(options) {
    this.options = { ...ApiClient.defaults, ...options };
    this.session = new SessionClient();
  }

  getHeaders(options = {}) {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      Accept: options.accept || "*/*",
    };
    if (options.type === "csvUpload") delete headers["Content-Type"];
    const token = this.session.jwt;
    if (token) {
      headers.Authorization = this.authorizationHeader(token);
    }
    return headers;
  }

  authorizationHeader(token) {
    return `Bearer ${token}`;
  }

  url(key) {
    return this.options.urls[key];
  }

  isJson = (response) => {
    const contentType = response.headers.get("content-type");
    return contentType && contentType.includes("application/json");
  };

  status = (options) => (response) => {
    if (response.error) {
      this.handleError(response, options);
      return Promise.reject(response);
    }

    if (response.redirect) {
      window.location.href = response.redirect;
    }

    return Promise.resolve(response);
  };

  normalizeBody = (response) => {
    if (this.isJson(response)) {
      return response.json();
    }

    if (response.status >= 200 && response.status < 300) {
      response.text();
    }

    return {
      error: {
        status: response.status,
        message: response.statusText,
        response,
      },
    };
  };

  handleError(data, options) {
    if (data.error.status === 401 || data.error.status === "unauthorized") {
      this.session.clear();
      if (!options.no401redirect) {
        window.location.href = "/login?alr=/projects";
      }
    }
    return data;
  }

  invoke(url, params, options) {
    const uri = new URL(`${this.options.host}${this.options.baseUrl}${url}`);

    const reqOptions = {
      headers: this.getHeaders(options),
      method: "GET",
      ...options,
    };

    if (this.session.jwt) {
      uri.searchParams.append("t_id", this.session.user.props.id);
    }

    if (reqOptions.method === "GET") {
      Object.keys(params).forEach((key) => {
        uri.searchParams.append(key, params[key]);
      });
    } else if (!reqOptions.body) {
      reqOptions.body = options.type === "csvUpload" ? params.file : JSON.stringify(params);
    }

    const errOptions = _.pick(options, ["no401redirect"]);
    for (const key of Object.keys(errOptions)) {
      delete reqOptions[key];
    }

    return fetch(uri, reqOptions).then(this.normalizeBody).then(this.status(errOptions));
  }

  get(url, params, options) {
    return this.invoke(url, params, options);
  }

  post(url, params, options) {
    return this.invoke(url, params, { ...options, method: "POST" });
  }

  patch(url, params, options) {
    return this.invoke(url, params, { ...options, method: "PATCH" });
  }

  put(url, params, options) {
    return this.invoke(url, params, { ...options, method: "PUT" });
  }

  delete(url, params, options) {
    return this.invoke(url, params, { ...options, method: "DELETE" });
  }

  login(params) {
    const result = this.post(this.url("jwt"), params, { no401redirect: true });
    return result;
  }

  ssoLogin(route = null, relay = null) {
    document.getElementById("sso_jwt").value = this.authorizationHeader(this.session.jwt);

    if (route) {
      document.getElementById("sso_route").value = route;
    }

    if (relay) {
      document.getElementById("sso_relay_state").value = relay;
    }

    return document.getElementById("sso_form").submit();
  }

  isSso = () => {
    return parse(window.location.search).sso;
  };
}

export default ApiClient;
