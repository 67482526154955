import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import types from "rdx/modules/adminProposals/types";
import actions from "rdx/modules/adminProposals/actions";
import { useParams } from "react-router-dom";

import useLoading from "hooks/useLoading";
import useNavigation from "hooks/useNavigation";

import proposalsTabs from "containers/Admin/SolarProposals/tabs";
import ProposalsSidebar from "./proposalSidebar";

import styles from "./Sidebar.module.less";

const Sidebar = (props) => {
  const { filters, search, setProposalsAutoComplete, suggestions } = props;

  const { navigate } = useNavigation("admin-solar-proposals", ["tab"], ["projectId"]);

  const loading = useLoading({
    watchRequests: [
      types.GET_PENDING_ADMIN_PROPOSALS_LIST,
      types.GET_IN_PROGRESS_ADMIN_PROPOSALS_LIST,
      types.GET_COMPLETED_ADMIN_PROPOSALS_LIST,
    ],
  });

  const { tab } = useParams();
  const tabKeys = proposalsTabs.map(({ key }) => key);

  const filtersForTab = useMemo(() => {
    const idx = tabKeys.indexOf(tab);
    if (idx > -1) {
      return filters[idx];
    }
    return { filters: {}, sorts: [] };
  }, [filters, tab, tabKeys]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.sidebar}>
      {tab === "funding" ? null : (
        <ProposalsSidebar
          navigate={navigate}
          filters={filtersForTab}
          search={search}
          suggestions={suggestions}
          setSuggestions={actions.setProposalsAutoComplete}
          resetSuggestions={setProposalsAutoComplete}
          tab={tab}
          loading={loading}
        />
      )}
    </div>
  );
};

Sidebar.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      filters: PropTypes.shape({
        consultants: PropTypes.shape({
          name: PropTypes.string,
          // values objects are a single key-value pair consisting of
          // a consultant's id as key and name as value
          values: PropTypes.string,
        }),
        generatedType: PropTypes.shape({
          name: PropTypes.string,
          values: PropTypes.arrayOf(PropTypes.string),
        }),
        version: PropTypes.shape({
          name: PropTypes.string,
          values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        }),
      }),
      sorts: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  search: PropTypes.string,
  suggestions: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        fullName: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        vanityName: PropTypes.string,
        subscriptionStatus: PropTypes.string,
        locale: PropTypes.string,
        avatar: PropTypes.shape({
          large: PropTypes.string,
          thumb: PropTypes.string,
          retina: PropTypes.string,
          preview: PropTypes.string,
        }),
      }),
    ),
  ),
  setProposalsAutoComplete: PropTypes.func.isRequired,
};

export default connect(
  getRdxSelectionMapper({
    filters: "getAllProposalsFilters",
    suggestions: "getProposalsAutoComplete",
  }),
  getRdxActionMapper(["setProposalsAutoComplete"]),
)(Sidebar);
