import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

import { theme } from "styles/themes";

export const SearchT3PartnerContainer = styled("div")(() => ({
  height: "100%",
  width: "100%",
}));

export const ReverseSortContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "22px 0px 16px 0px",
}));

export const SortButtonContent = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

export const ReverseSortButton = styled(Button)(() => ({
  borderRadius: "15px",
  backgroundColor: theme.colors.white,
  height: "28px",
  width: "125px",
  boxShadow: "0px 0.5px 2px rgba(0, 0, 0, 0.15)",
}));

export const SortButtonText = styled("div")(() => ({
  fontSize: "12px",
  color: theme.colors["dark-blue"],
  marginLeft: "14px",
  fontWeight: 400,
  textTransform: "capitalize",
}));

export const Results = styled("div")(() => ({
  display: "flex",
  background: theme.colors.white,
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "67px",
  padding: "0px 10px",
  height: "28px",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "19px",
  color: theme.colors["dark-blue"],
  marginRight: "12px",
  boxShadow: "0px 0.5px 2px rgba(0,0,0,0.15)",
}));
