import React from "react";
import PropTypes from "prop-types";

const HomeIcon = (props) => {
  const { mainColor, height, width, containerStyles } = props;

  return (
    <div style={{ paddingTop: "3px", ...containerStyles }}>
      <svg width={width} height={height} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.4148 4.56771V2.34561H10.4817C10.5507 2.34561 10.6068 2.2899 10.6068 2.2209V1.70491C10.6068 1.63591 10.5507 1.5802 10.4817 1.5802H10.4148H8.50171H8.43485C8.36628 1.5802 8.31014 1.63591 8.31014 1.70491V2.2209C8.31014 2.2899 8.36628 2.34561 8.43485 2.34561H8.50171V2.70518L6.39275 0.652355C6.18404 0.449644 5.85233 0.449216 5.64276 0.651069L0.156715 5.96655C-0.111994 6.22711 -0.0267102 6.43968 0.347854 6.44139H1.58298C1.58298 6.44139 1.58212 10.6722 1.58169 12.0016C1.58169 12.2767 1.8054 12.5 2.08054 12.5H9.91983C10.1962 12.5 10.4195 12.2767 10.4195 12.0012V6.43754L11.6491 6.44054C12.0241 6.44011 12.1119 6.2254 11.8466 5.96097L10.4148 4.56771ZM7.49973 10.1575C7.49973 10.2286 7.4423 10.286 7.37116 10.286H4.62835C4.55721 10.286 4.49978 10.2286 4.49978 10.1575V7.41466C4.49978 7.34352 4.55721 7.28609 4.62835 7.28609H7.37116C7.4423 7.28609 7.49973 7.34352 7.49973 7.41466V10.1575Z"
          fill={mainColor}
        />
      </svg>
    </div>
  );
};

HomeIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  mainColor: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

HomeIcon.defaultProps = {
  mainColor: "white",
  height: "14",
  width: "14",
  containerStyles: {},
};

export default HomeIcon;
