const facebookText = `Hey Facebook friends,

I recently became a Solar Ambassador with Powur after learning firsthand how beneficial solar energy can be for your home. 

If you are a homeowner who's looking to save a substantial amount of money on your electric bill, increase your home value, and have real security in the face of the next crisis… I invite you to take the time to learn more about Powur and the solutions we provide. 

Common misconceptions people have about solar:

It's expensive - This is just flat out wrong.  Solar is cheaper than buying energy directly from a utility, and it costs $0 out of pocket to go solar.  Get rid of your monthly payment to your electric company and replace it with a CHEAPER monthly payment for your solar system, and generate your own clean electricity.

It's a hassle - This is untrue.  Solar is so easy.  Working with the right company you literally don't have to lift a finger. 

Technology is changing - also untrue.  Solar panels have been slowly and steadily improving for 40 years! But the fundamental technology has not changed, and many people are losing thousands of dollars per year because they put off going solar thinking it will be better in the future.  It won't! The best time is right now, especially with the federal incentives expiring. 

The takeaway is solar could be the right solution for your home, and to save you money, but you won't know until you look!  Who's interested? Shoot me a PM or follow the link below to get a custom savings proposals for your home.`;

const twitterText = "Powur is offering the BEST $0 down solar options on the market. Homeowners check this out!! ";

const topTenQuestionsCopy = {
  facebookText: `Welcome to the Powur Solar Insights Series a solar knowledge center that addresses the most Frequently Asked Questions regarding solar energy, all in one place. 
  No more searching the sea of information that is the internet, sit back and enjoy this 6 minute series of videos and rest assured that going solar is the RIGHT choice for you.`,

  twitterTitle:
    "Welcome to the Powur Solar Insights Series. Sit back and enjoy this 6 minute series of videos and rest assured that going solar is the RIGHT choice for you.",

  linkedInText: `Welcome to the Powur Solar Insights Series a solar knowledge center that addresses the most Frequently Asked Questions regarding solar energy, all in one place. 
  No more searching the sea of information that is the internet, sit back and enjoy this 6 minute series of videos and rest assured that going solar is the RIGHT choice for you.`,
};

const educationMarketingCopy = {
  facebookText: `Free Education Video Series For Homeowners: The Powur Solar Insights Series
  Learn Why Now Is The Best Time To Go Solar
  In just 6 minutes, we will show you how to put up to $22k in your pocket while adding up to $50K to the value of your home just by going solar! The only question you will have left is: where do I sign?`,

  twitterTitle: `Free Education Video Series For Homeowners: Powur Solar Insights -
  "Why Now Is The Best Time To Go Solar" — In just 6 minutes, we will show you how to put up to $22k in your pocket while adding up to $50K to the value of your home just by going solar!`,

  linkedInText:
    "Free Education Video Series For Homeowners: Powur Solar Insights – Learn Why Now Is The Best Time To Go Solar",
};

const whySolarEducationCopy = {
  facebookText: `Why Should I Switch to Solar?
  From saving money to withstanding power outages, solar energy offers big advantages for you.
  In this video, we will break down the top reasons why you should switch to clean energy.`,

  twitterTitle: `The Powur Solar Insights Series - "Why Should I Switch to Solar?" — From saving money to withstanding power outages, solar energy offers big advantages for you.
  In this video, we will break down the top reasons why you should switch to clean energy.`,

  linkedInText: "Free Education Video Series For Homeowners: Powur Solar Insights – Why Should I Switch to Solar?",
};

export { facebookText, twitterText, topTenQuestionsCopy, educationMarketingCopy, whySolarEducationCopy };
