export default {
  getAdminProjectsList: (state) => state.adminProjects.projectsList,
  getAdminProjectsListFilters: (state) => state.adminProjects.projectsListFilters,
  getAdminProjectsInstallers: (state) => {
    if (state.adminProjects.projectsListFilters.filters.installers) {
      return state.adminProjects.projectsListFilters.filters.installers.values;
    }
    return [];
  },
  getAdminProjectsAutoComplete: (state) => state.adminProjects.projectsListAutoComplete,
  getAdminProjectDetails: (state) => state.adminProjects.projectDetails,
  getProjectMilestones: (state) => state.adminProjects.projectMilestones,
  getAdminProjectNotes: (state) => state.adminProjects.projectNotes,
  getAdminProjectProposals: (state) => state.adminProjects.proposals,
  selectAdminProjectVisionProposals: (state) => state.adminProjects.proposals.visionProposals,
  selectAdminVisionProposalCreated: (state) => state.projects.proposals.isCreateVisionReqSuccessful,
  selectAdminProposalSettings: (state) => state.adminProjects.proposalSettings,

  getUsersAutoCompleteLookup: (state) => state.adminProjects.projectsListFilters.autocompletes.users,
  getLocationsAutoCompleteLookup: (state) => state.adminProjects.projectsListFilters.autocompletes.locations,
  getInstallersAutoCompleteLookup: (state) => state.adminProjects.projectsListFilters.autocompletes.installers,
  getTeamsAutoCompleteLookup: (state) => state.adminProjects.projectsListFilters.autocompletes.teams,
  getUtilitiesAutoCompleteLookup: (state) => state.adminProjects.projectsListFilters.autocompletes.utilities,
  selectAdminProjectLookupOptions: (state) => state.adminProjects.projectLookupOptions,
  getSelectedProjectDetail: (state) => state.adminProjects.selectedProjectDetail,
  selectAutocompleteSelectedOption: (state) => state.adminProjects.projectsListFilters.autocompletes.selectedOption,
  selectAutocompleteSelectedOptions: (state) => state.adminProjects.projectsListFilters.autocompletes.selectedOptions,
};
