import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";

import styles from "components/PlatformLayout/components/PlatformPageHeader/PlatformPageHeader.module.less";

const { Title } = Typography;

const TicketsBreadCrumbs = () => {
  const [tabTitle, setTabTitle] = useState(null);
  const history = useHistory();
  const params = useParams();

  const handleBaseNavigate = () => {
    history.push("/tickets");
    setTabTitle(null);
  };

  useEffect(() => {
    if (params.tab) setTabTitle(params.tab.replace(/-/g, " "));
  }, [params]);

  const textStyle = {
    cursor: "pointer",
    textTransform: "capitalize",
    fontWeight: 600,
  };

  return (
    <>
      <Title className={styles.title} style={textStyle} onClick={handleBaseNavigate}>
        Tickets Inbox
      </Title>
      {tabTitle && (
        <>
          <RightOutlined className={styles.title} style={{ margin: "0 10px" }} />
          <Title className={styles.title} style={textStyle}>
            {tabTitle}
          </Title>
        </>
      )}
    </>
  );
};

export default TicketsBreadCrumbs;
