import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";
import types from "rdx/modules/app/types";

import fetchAutoCompleteSuggestions from "./fetchAutoCompleteSuggestions";
import getZipAddress from "./getZipAddress";
import getPageReferrer from "./getPageReferrer";
import getRandomAddress from "./getRandomAddress";
// IMPORT_PT (for script, do not remove)

function* watchAppSagas() {
  yield trackRequests(takeEvery, types.FETCH_AUTOCOMPLETE_SUGGESTIONS, fetchAutoCompleteSuggestions);
  yield trackRequests(takeEvery, types.GET_ZIP_LOOKUP_ADDRESS, getZipAddress);
  yield trackRequests(takeEvery, types.GET_PAGE_REFERRER, getPageReferrer);
  yield trackRequests(takeEvery, types.GET_RANDOM_ADDRESS, getRandomAddress);
  // INSERTION_PT (for script, do not remove)
}

export default watchAppSagas;
