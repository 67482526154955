import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form, Input, Select, Row, Col, Checkbox } from "antd";
import { states } from "lib/misc/states";
import { MailOutlined } from "@ant-design/icons";
import PhoneIcon from "components/Icons/PhoneIcons/phoneIcon";
import DownCaret from "components/Icons/Chevrons/DownCaretSimple";
import StreetAddressLookup from "./StreetAddressLookup";
import styles from "./EnterpriseProSignupModal.module.less";

const PersonalForm = (props) => {
  const { form, preFillForm } = props;
  const { Option } = Select;
  const { t, i18n } = useTranslation(["ent_join_form_labels"]);

  const languages = [
    { value: "en", label: t("english") },
    { value: "es", label: t("spanish") },
  ];

  const createStateOptions = () => {
    const out = states.map((state) => (
      <Option key={state.short} value={state.short}>
        {state.long}
      </Option>
    ));
    return out;
  };

  const handleLanguageChange = (value) => {
    form.setFieldsValue({ spokenLanguages: [value] });
  };

  return (
    <Form
      form={form}
      className={styles.formContainer}
      initialValues={{
        locale: i18n.language || "en",
        spokenLanguages: [i18n.language] || ["en"],
      }}
      requiredMark={false}
      colon={false}
      validateTrigger="onSubmit"
    >
      <div className={styles.subHeaderContainer}>
        <div className={styles.subHeader}>{t("your_info")}</div>
        <div className={styles.underline} />
      </div>
      <span className={styles.inputLabel}>{t("first_name")}</span>
      <Form.Item
        name="ownerFirstName"
        validateTrigger={["onBlur", "onSubmit"]}
        rules={[
          {
            required: true,
            message: t("first_name_required"),
          },
        ]}
      >
        <Input size="large" className={styles.input} name="first_name" disabled={preFillForm} />
      </Form.Item>
      <span className={styles.inputLabel}>{t("last_name")}</span>
      <Form.Item
        name="ownerLastName"
        validateTrigger={["onBlur", "onSubmit"]}
        rules={[
          {
            required: true,
            message: t("last_name_required"),
          },
        ]}
        message={null}
      >
        <Input size="large" className={styles.input} name="last_name" disabled={preFillForm} />
      </Form.Item>

      <span className={styles.inputLabel}>{t("email")}</span>
      <Form.Item
        name="ownerEmail"
        validateTrigger={["onBlur", "onSubmit"]}
        rules={[
          {
            required: true,
            message: t("valid_email"),
          },
          {
            type: "email",
            message: t("valid_email"),
          },
        ]}
      >
        <Input
          size="large"
          className={styles.iconInput}
          name="email"
          addonBefore={
            <div className={styles.inputPrefix}>
              <MailOutlined
                style={{
                  color: "var(--nepal-blue)",
                  fontSize: "17px",
                }}
              />
            </div>
          }
          disabled={preFillForm}
        />
      </Form.Item>

      <span className={styles.inputLabel}>{t("phone")}</span>
      <Form.Item
        name="ownerPhone"
        validateTrigger={["onBlur", "onSubmit"]}
        rules={[
          {
            pattern: /^[0-9.\-+\s]+$/,
            message: t("valid_phone"),
          },
          {
            required: true,
            message: t("valid_phone"),
          },
          {
            min: 7,
            message: t("valid_phone"),
          },
        ]}
      >
        <Input
          size="large"
          className={styles.iconInput}
          name="phone"
          addonBefore={
            <div className={styles.inputPrefix}>
              <PhoneIcon fill="var(--nepal-blue)" />
            </div>
          }
          disabled={preFillForm}
        />
      </Form.Item>

      <span className={styles.inputLabel}>{t("language_preference")}</span>
      <Form.Item name="locale" rules={[{ required: true }]}>
        <Select
          className={styles.select}
          size="large"
          suffixIcon={<DownCaret />}
          onChange={handleLanguageChange}
          disabled={preFillForm}
        >
          {languages.map((lang) => (
            <Option key={lang.value} value={lang.value}>
              {lang.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <span className={styles.inputLabel}>{t("languages_spoken")}</span>
      <Form.Item name="spokenLanguages" rules={[{ required: true, message: t("languages_spoken_required") }]}>
        <Checkbox.Group options={languages} />
      </Form.Item>
      <div className={styles.subHeaderContainer}>
        <div className={styles.subHeader}>{t("your_org")}</div>
        <div className={styles.underline} />
      </div>
      <span className={styles.inputLabel}>{t("business_name")}</span>
      <Form.Item
        name="businessName"
        validateTrigger={["onBlur", "onSubmit"]}
        rules={[
          {
            required: true,
            message: t("business_name_required"),
          },
        ]}
      >
        <Input size="large" className={styles.input} name="org_name" />
      </Form.Item>

      <span className={styles.inputLabel}>{t("business_address")}</span>
      <StreetAddressLookup form={form} />
      <Row>
        <Col className={styles.city} span={10}>
          <span className={styles.inputLabel}>{t("city")}</span>
          <Form.Item
            validateTrigger={["onBlur", "onSubmit"]}
            name="businessCity"
            rules={[
              {
                required: true,
                message: t("city_required"),
              },
            ]}
          >
            <Input size="large" className={styles.input} name="city" />
          </Form.Item>
        </Col>
        <Col className={styles.state} span={7}>
          <span className={styles.inputLabel}>{t("state")}</span>
          <Form.Item
            name="businessState"
            validateTrigger={["onBlur", "onSubmit"]}
            rules={[
              {
                required: true,
                message: t("state_required"),
              },
            ]}
          >
            <Select
              size="large"
              optionFilterProp="children"
              className={styles.select}
              dropdownStyle={{ minWidth: "150px" }}
            >
              {createStateOptions()}
            </Select>
          </Form.Item>
        </Col>
        <Col className={styles.zip} span={7}>
          <span className={styles.inputLabel}>{t("zipcode")}</span>
          <Form.Item
            name="businessZip"
            validateTrigger={["onBlur", "onSubmit"]}
            rules={[
              {
                required: true,
                message: t("zipcode_required"),
              },
              {
                pattern: /^(\d{5}(-\d{4})?|[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] ?\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/i,
                message: "Please enter a valid US or Canadian postal code.",
              },
            ]}
          >
            <Input className={styles.input} size="large" maxLength={10} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

PersonalForm.propTypes = {
  // antd form instance
  form: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  preFillForm: PropTypes.bool,
};

export default PersonalForm;
