import React, { useState, useEffect, useCallback } from "react";
import { Switch, Route, useParams } from "react-router-dom";
import { Layout, Button } from "antd";
import { ControlOutlined, RightCircleOutlined } from "@ant-design/icons";
import useWindowResize from "hooks/useWindowResize";
import useGetCurrentRouteInfo from "hooks/useGetCurrentRouteInfo";
import { buttonProps } from "lib/props";
import ChevronVertical from "components/Icons/Chevrons/ChevronVertical";
import usePermissions, { permissionTypes } from "hooks/usePermissions";
import styles from "./Sidebar.module.less";

const { Sider } = Layout;

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const { tab } = useParams();

  const { currentRoute, platformRoutes: routes } = useGetCurrentRouteInfo();
  const { isTabletWidth, width } = useWindowResize();

  const { ENTERPRISE_LEAD_GENERATOR } = usePermissions({
    permissionRequests: [permissionTypes.ENTERPRISE_LEAD_GENERATOR],
  });

  const { sidebar: currentRouteSidebar } = currentRoute;

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  const calculateSidebarWidth = useCallback(() => {
    setSidebarWidth(width <= 475 ? width - 50 : 250);
  }, [width]);

  useEffect(() => {
    calculateSidebarWidth();
    setIsCollapsed(isTabletWidth);
  }, [isTabletWidth, calculateSidebarWidth]);

  useEffect(() => {
    if (currentRouteSidebar) {
      setIsHidden(false);
      calculateSidebarWidth();
    } else {
      setIsHidden(true);
      setSidebarWidth(0);
    }
  }, [currentRouteSidebar]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    /* Running into the issue of having empty sidebar still expanded when we have
     * a sidebar component listed in a route but individual tabs have no sidebar
     * content to show. Somewhat awkward solution offered here; check the render
     * of the route's sidebar component, which should be the only child of
     * .ant-layout-sider-children that is not the collapse toggle button. If
     * that component is rendering children, then we know the sidebar content
     * should not be hidden and collapsed, and if it is just an empty element
     * with no children (such as a sidebar component rendering nothing for
     * some tabs), then hide and shrink.
     */
    const getSidebarChild = () => {
      if (!tab || !currentRouteSidebar) {
        return null;
      }

      const sidebarChildrenContainer = document.querySelector(`.${styles.sideBar} > .ant-layout-sider-children`);

      if (!sidebarChildrenContainer || !sidebarChildrenContainer.children) {
        return null;
      }

      for (const child of sidebarChildrenContainer.children) {
        if (!child.classList.contains(styles.collapse)) {
          return child;
        }
      }

      return null;
    };

    const checkSidebarGrandchildren = () => {
      const sidebarChild = getSidebarChild();

      if (!sidebarChild || !sidebarChild.hasChildNodes()) {
        setIsHidden(true);
        setSidebarWidth(0);
      } else {
        setIsHidden(false);
        calculateSidebarWidth();
      }

      return null;
    };

    checkSidebarGrandchildren();
  }, [tab, currentRouteSidebar, calculateSidebarWidth]);

  if (currentRoute.key === "org-projects" && ENTERPRISE_LEAD_GENERATOR) {
    return null;
  }

  return (
    <div className={styles.sideBarContainer}>
      {!isHidden && !isTabletWidth && (
        /* want to show controls only if the current view should have a sidebar
         * and width is desktop view. if we want to show the sidebar controls
         * but are in mobile, show mobile control button below */
        <div className={isCollapsed ? styles.closedCollapse : styles.collapse} {...buttonProps(toggleCollapsed)}>
          <ChevronVertical rotate={!isCollapsed} />
        </div>
      )}
      <Sider
        className={styles.sideBar}
        style={{
          padding: isCollapsed || isHidden ? "42px 0" : "42px 25px 42px 32px",
        }}
        collapsible
        collapsedWidth={0}
        collapsed={isCollapsed || isHidden}
        width={sidebarWidth}
        trigger={null}
      >
        <Switch>
          {routes.map((r) => (
            <Route
              key={r.key}
              path={r.path}
              exact
              render={() => {
                if (r.sidebar) {
                  return (
                    <r.sidebar
                      route={r}
                      isCollapsed={isCollapsed}
                      setIsCollapsed={setIsCollapsed}
                      isHidden={isHidden}
                      setIsHidden={setIsHidden}
                    />
                  );
                }
                return null;
              }}
            />
          ))}
        </Switch>
      </Sider>
      {!isHidden && isTabletWidth && (
        <Button className={styles.collapseSmallScreen} type="primary" htmlType="button" onClick={toggleCollapsed}>
          {isCollapsed ? <ControlOutlined className={styles.icon} /> : <RightCircleOutlined className={styles.icon} />}
        </Button>
      )}
    </div>
  );
};

export default Sidebar;
