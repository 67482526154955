import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";
import useWindowResize from "hooks/useWindowResize";
import { OpenInNewWindowIcon } from "components/Icons";
import GradientButton from "components/Buttons/GradientButton";

import styles from "./TermsAndConditions.module.less";

const TermsAndConditions = ({ handleCreateAccount, applicationAgreement, loading }) => {
  const { t } = useTranslation(["join", "form_labels"]);
  const [termsChecked, setTermsChecked] = useState(false);

  const { width } = useWindowResize();
  const mobile = useMemo(() => {
    return width <= 768;
  }, [width]);

  const handleOpenTerms = () => {
    window.open(applicationAgreement.documentPath, "_blank");
  };
  return (
    <div className={styles.termsAndConditionsContainer} data-test-id="terms-and-conditions-form">
      <div className={styles.termsAndConditionsHeader}>
        <div className={styles.verificationLabel}>{t("join_form.terms")}</div>
        <div className={styles.openNewWindow}>
          <span>{t("join_form.new_window")} </span>
          <span
            className={styles.newWindowLink}
            tabIndex={0}
            role="button"
            onClick={() => handleOpenTerms()}
            onKeyDown={() => handleOpenTerms()}
          >
            <OpenInNewWindowIcon />
          </span>
        </div>
      </div>
      <div>{applicationAgreement.message}</div>
      <div className={styles.termsText}>
        <iframe
          src={applicationAgreement.documentPath}
          allowFullScreen=""
          title="Terms and Conditions"
          height="100%"
          width="100%"
          className={styles.termsPdf}
          frameBorder="0"
        />
      </div>
      <div className={mobile ? styles.mobileTermsContainer : styles.selectTermsContainer}>
        <Checkbox className={styles.termsCheckbox} onChange={(e) => setTermsChecked(e.target.checked)}>
          {t("join_form.accept_terms")}
        </Checkbox>
        <GradientButton
          className={styles.createAccountButton}
          onClick={() => handleCreateAccount()}
          disabled={!termsChecked}
          loading={loading}
        >
          {t("join_form.create_account")}
        </GradientButton>
      </div>
    </div>
  );
};

TermsAndConditions.propTypes = {
  handleCreateAccount: PropTypes.func,
  applicationAgreement: PropTypes.shape({
    documentPath: PropTypes.string,
    message: PropTypes.string,
    version: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

export default TermsAndConditions;
