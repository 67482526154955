import { permissionTypes } from "hooks/usePermissions";

const { ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN, ENTERPRISE_ADMIN_PRO, ENTERPRISE_LEAD_GENERATOR } = permissionTypes;

const commonTabs = [
  {
    key: "leads",
    label: "Leads",
  },
];

const leadGenTabs = [
  {
    key: "pre-sales?status=active",
    label: "Pre-Sales",
    permission: [ENTERPRISE_LEAD_GENERATOR],
  },
  {
    key: "post-sales?status=active",
    label: "Post-Sales",
    permission: [ENTERPRISE_LEAD_GENERATOR],
  },
  {
    key: "completed",
    label: "Completed",
    permission: [ENTERPRISE_LEAD_GENERATOR],
  },
];

const adminTabs = [
  {
    key: "qualified",
    label: "Qualified",
    permission: [ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN, ENTERPRISE_ADMIN_PRO],
  },
  {
    key: "proposals",
    label: "Proposals",
    permission: [ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN, ENTERPRISE_ADMIN_PRO],
  },
  {
    key: "contracts",
    label: "Contracts",
    permission: [ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN, ENTERPRISE_ADMIN_PRO],
  },
  {
    key: "installs",
    label: "Installed",
    permission: [ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN, ENTERPRISE_ADMIN_PRO],
  },
  {
    key: "closed",
    label: "Closed",
    permission: [ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN, ENTERPRISE_ADMIN_PRO],
  },
];

export { commonTabs, adminTabs, leadGenTabs };

export default [...commonTabs, ...adminTabs, ...leadGenTabs];
