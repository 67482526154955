// React
import React, { useState } from "react";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import useWindowResize from "hooks/useWindowResize";
import { parse } from "query-string";

import { Menu, Popconfirm } from "antd";

import { cvar } from "styles";
import CloseIcon from "components/Icons/CancelIconFilled";
import { Button } from "@mui/material";
import { DownCaret } from "components/Icons";
import FilterMenuItem from "./FilterMenuItem";
import FilterMenuTab from "./FilterMenuTab";
import FilterMenuTabMobile from "./FilterMenuTabMobile";

import styles from "./FilterMenu.module.less";
import { FilterActionButton } from "./styles";

const FilterMenu = (props) => {
  const { sortItems, filterSetModalOpen, filterSets, deleteFilterSet, updateFilters, navigate, override, closeDrawer } =
    props;

  const [selectedItem, setSelectedItem] = useState(sortItems[0]);

  const { width } = useWindowResize();
  const mobile = width < 681;

  const { search: urlSearch } = useLocation();
  const queryParams = parse(urlSearch);

  const emptyFilters = () => {
    const params = Object.keys(queryParams);
    const filtersOnly = params.filter(
      (f) =>
        f !== "q" && f !== "sort" && f !== "p" && f !== "user_id" && f !== "consultant_id" && f !== "provider_lead_id",
    );

    return filtersOnly.reduce(
      (acc, current) => ({
        ...acc,
        [current]: null,
      }),
      {},
    );
  };

  const applyFilterSet = (savedSet) => {
    const parsedSet = parse(savedSet.queryUrl);
    const existing = emptyFilters();
    const out = { ...existing, ...parsedSet };
    // maintain existing sort and search while applying filter set
    navigate(out);
  };

  const clearFilters = () => {
    const out = emptyFilters();
    navigate(out);
  };

  const saveFilterSet = () => {
    filterSetModalOpen();
  };

  const deleteSet = (e, id) => {
    e.stopPropagation();
    deleteFilterSet({ id });
  };

  return (
    <Menu className={styles.filtersDropdownMenu} style={mobile ? { width: "100%", maxWidth: "inherit" } : {}}>
      <div
        className={styles.header}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        tabIndex={0}
        role="button"
      >
        <span className={styles.headerText}>
          {mobile && (
            <Button onClick={closeDrawer} style={{ minWidth: "20px", width: "20px", height: "24px" }}>
              <DownCaret width="10px" fill="#295CA0" containerStyles={{ transform: "rotateZ(90deg)" }} />
            </Button>
          )}
          Filters
        </span>
        <div className={styles.left}>
          <FilterActionButton onClick={clearFilters}>Clear</FilterActionButton>
        </div>
      </div>
      <div
        className={styles.bodyContainer}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        tabIndex={0}
        role="button"
      >
        <div className={styles.tabs}>
          {sortItems.map((item) => {
            return mobile ? (
              <FilterMenuTabMobile
                item={item}
                key={item.label}
                setSelectedItem={setSelectedItem}
                queryParams={queryParams}
                selectedItem={selectedItem}
                updateFilters={updateFilters}
                override={override}
              />
            ) : (
              <FilterMenuTab
                item={item}
                key={item.label}
                setSelectedItem={setSelectedItem}
                queryParams={queryParams}
                selectedItem={selectedItem}
              />
            );
          })}
        </div>
        {!mobile && (
          <div className={styles.content}>
            {sortItems.map((item) => {
              return (
                <FilterMenuItem
                  override={override}
                  selectedItem={selectedItem}
                  queryParams={queryParams}
                  updateFilters={updateFilters}
                  item={item}
                  key={item.label}
                />
              );
            })}
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.top}>
          <span className={styles.headerText}>Filter Sets</span>
          <FilterActionButton onClick={saveFilterSet}> Save as Filter Set</FilterActionButton>
        </div>
        <div className={styles.filterSet}>
          {filterSets.map((filter) => (
            <div
              key={filter.id}
              className={styles.filterTag}
              tabIndex="0"
              role="button"
              onClick={() => applyFilterSet(filter)}
              onKeyDown={() => applyFilterSet(filter)}
            >
              <span>{filter.name}</span>
              <Popconfirm
                title="Are you sure to delete this filter set?"
                onConfirm={(e) => deleteSet(e, filter.id)}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <div className={styles.closeIcon}>
                  <CloseIcon backfill={cvar("dark-blue")} />
                </div>
              </Popconfirm>
            </div>
          ))}
        </div>
      </div>
    </Menu>
  );
};

FilterMenu.propTypes = {
  sortItems: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      label: PropTypes.string,
      options: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    }),
  ).isRequired,
  navigate: PropTypes.func.isRequired,
  override: PropTypes.func,
  closeDrawer: PropTypes.func,

  deleteFilterSet: PropTypes.func,
  // TODO: enumerate filterSets and ledgerCsv properties
  filterSets: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  filterSetModalOpen: PropTypes.func,
  updateFilters: PropTypes.func,
};

export default connect(getRdxSelectionMapper({}), getRdxActionMapper(["deleteFilterSet"]))(FilterMenu);
