export default {
  CREATE_APPCLIENT_TOKEN_REQUEST: "[publicApiProxy] CREATE AppClient Token Request",
  GET_APPCLIENT_TOKEN_REQUEST: "[publicApiProxy] GET AppClient Token",
  SET_APPCLIENT_TOKEN: "[publicApiProxy] SET AppClient Token",

  GET_GOOGLE_AUTHORIZE_URL_REQUEST: "[publicApiProxy] GET Google Authorize Url Request",
  SET_GOOGLE_AUTHORIZE_URL: "[publicApiProxy] SET Google Authorize Url",

  EXCHANGE_GOOGLE_CALLBACK_REQUEST: "[publicApiProxy] EXCHANGE Google Callback Request",

  GET_GOOGLE_ACCESS_STATUS_REQUEST: "[publicApiProxy] GET Google Access Status Request",
  SET_GOOGLE_ACCESS_STATUS: "[publicApiProxy] SET Google Access Status",

  GET_GOOGLE_USER_REQUEST: "[publicApiProxy] GET Google User Request",
  SET_GOOGLE_USER: "[publicApiProxy] SET GoogleUser",

  GET_USER_EMAIL_INTEGRATION_TYPE_REQUEST: "[publicApiProxy] GET User Email Integration Type Request",
  SET_USER_EMAIL_INTEGRATION_TYPE_REQUEST: "[publicApiProxy] SET User Email Integration Type Request",

  SEND_GMAIL_REQUEST: "[publicApiProxy] Send Gmail Request",
};
