import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "../messages";

function* updateLeadUtilityBill(action) {
  const { leadId, utility_bill_url } = action.payload;
  if (!leadId) {
    throw new Error(`Invalid lead ID = ${leadId} specified. Please check this parameter.`);
  }
  const params = {
    utility_bill_url,
  };
  const { success, data, error } = yield* makeRequest.patch(`/leads/${leadId}/bill`, params);
  if (success && data) {
    yield all([put(actions.newSuccessEvent({ message: messages.LEAD_UPDATE_SUCCESS }))]);
  } else {
    return getErrorActions({ error, message: messages.ERROR_UPDATING_LEAD });
  }
  return null;
}

export default updateLeadUtilityBill;
