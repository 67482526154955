import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "../types";
import getHelpjuiceToken from "./getHelpjuiceToken";
// IMPORT_PT (for script, do not remove)

function* watchHelpjuiceSagas() {
  yield trackRequests(takeEvery, types.GET_HELPJUICE_TOKEN, getHelpjuiceToken);
  // INSERTION_PT (for script, do not remove)
}

export default watchHelpjuiceSagas;
