import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../FAQ.module.less";

const FAQ = () => {
  const { t } = useTranslation("faq");
  const questions = t("faq", { returnObjects: true });
  return (
    <div className={styles.faqContainer}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>The Ultimate Solar FAQ</div>
      </div>
      <div className={styles.inner}>
        {questions.map(({ title, text }) => (
          <Question key={title} question={title} text={text} /> // eslint-disable-line react/no-array-index-key
        ))}
      </div>
    </div>
  );
};

const Question = ({ question, text }) => {
  return (
    <>
      <div className={styles.question}>{question}</div>
      <div className={styles.text}>
        {text.map((t) => (
          <p className={styles.faqParagraph} key={t}>
            {t}
          </p>
        ))}
      </div>
    </>
  );
};

export default FAQ;
