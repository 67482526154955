import React, { useState } from "react";
import { CheckCircleTwoTone, CloseOutlined, FileImageFilled, FilePdfFilled } from "@ant-design/icons";
// import PropTypes from "prop-types";
import { Row, Progress, Tooltip, Input } from "antd";
import { CSSTransition, Transition } from "react-transition-group";
import types from "../types";
import styles from "../styles.module.less";

const PreviewComponent = ({ fileWithMeta, meta }) => {
  const { id, name, percent, status, type, previewUrl } = meta;
  const [filenameInputValue, setFilenameInputValue] = useState(name);

  const setStatus = () => {
    const errors = [
      "rejected_file_type",
      "rejected_max_files",
      "error_file_size",
      "error_validation",
      "error_upload_params",
      "exception_upload",
      "aborted",
      "removed",
      "error_upload",
    ];
    if (errors.includes(status)) return "exception";
    if (status === "done") return "success";
    return "active";
  };

  const renderThumb = () => {
    if (previewUrl) {
      // for image files
      return (
        <div className={styles.thumb}>
          <img alt={name} src={previewUrl} className={styles.img} />
        </div>
      );
    }
    // for all other files
    const icon =
      type === "application/pdf" ? (
        <FilePdfFilled className={styles.icon} />
      ) : (
        <FileImageFilled className={styles.icon} />
      );
    return <div className={styles.thumb}>{icon}</div>;
  };

  const renderIcons = () => (
    <div className={styles.icons}>
      <Tooltip title="Remove file">
        {status === "done" ? (
          <CheckCircleTwoTone className={styles.icon} twoToneColor="var(--kelly-green)" />
        ) : (
          <CloseOutlined className={styles.icon} onClick={() => fileWithMeta.remove()} />
        )}
      </Tooltip>
    </div>
  );

  const renderProgress = () => (
    <Progress
      className={styles.progress}
      percent={percent}
      type="line"
      showInfo={false}
      strokeColor={{
        "0%": "rgba(207, 231, 250, 1)",
        "100%": "rgba(38, 165, 210, 1)",
      }}
      status={setStatus()}
    />
  );

  const renderFilenameInput = () => (
    <div className={styles.filenameInputContainer}>
      <div className={styles.filenameInputLabel}>File name</div>
      <Input
        id={`${id}-filename-input`}
        className={styles.filenameInput}
        value={filenameInputValue}
        onChange={(e) => setFilenameInputValue(e.target.value)}
      />
    </div>
  );

  return (
    <Row className={styles.previewRow}>
      {renderThumb()}
      <Transition timeout={300}>
        {() => (
          <div className={styles.progressCol}>
            <CSSTransition
              in={status === "ready"}
              key={`${id}-preview-filename-input`}
              timeout={300}
              classNames="file-uploader-filename-input-progress-switch-animations-U7VV2"
              unmountOnExit
            >
              {renderFilenameInput()}
            </CSSTransition>
            <CSSTransition
              in={status !== "ready"}
              key={`${id}-preview-progress-bar`}
              timeout={300}
              classNames="file-uploader-filename-input-progress-switch-animations-U7VV2"
              unmountOnExit
            >
              {renderProgress()}
            </CSSTransition>
          </div>
        )}
      </Transition>
      {renderIcons()}
    </Row>
  );
};

PreviewComponent.propTypes = {
  // canCancel: PropTypes.bool,
  // canRemove: PropTypes.bool,
  // canRestart: PropTypes.bool,
  // className: PropTypes.string,
  // extra: types.extra,
  fileWithMeta: types.fileWithMeta,
  // files: PropTypes.arrayOf(types.fileWithMeta),
  // imageClassName: PropTypes.string,
  // imageStyle: PropTypes.object,
  // isUpload: PropTypes.bool,
  // style: PropTypes.object,
  meta: types.meta,
};

export default PreviewComponent;
