// Jan 1, 2021
import userTimezone from "./userTimezone";
import formatTimeAmPm from "./formatTimeAmPm";

export default function formatDateShortMonth(dateString, withoutTime = false) {
  if (!dateString) {
    console.warn("No date string provided!");
    return "";
  }

  const timeZone = userTimezone();

  const sameDay = (d1, d2) => {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
  };
  const today = new Date();
  const targetDate = new Date(dateString);

  if (sameDay(today, targetDate)) {
    if (withoutTime) {
      return "Today";
    }
    return `Today ${formatTimeAmPm(targetDate)}`;
  }

  return targetDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone,
  });
}
