import React from "react";

const AwaitingPermit = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16.9316" cy="16" r="16" fill="var(--botticelli-blue)" />
      <path
        d="M22.9227 15.0526C23.0528 15.0526 23.1576 14.9478 23.1576 14.8145V11.101C23.1576 10.8026 22.9132 10.5582 22.6148 10.5582H21.6912V9.82503C21.6912 9.52351 21.45 9.28229 21.1484 9.28229H20.1931V8.5491C20.1931 8.24757 19.9487 8.00317 19.6503 8.00317H13.15C13.0199 8.00317 12.9151 8.10792 12.9151 8.24122C12.9151 8.37136 13.0199 8.47927 13.15 8.47927H19.6535C19.6916 8.47927 19.7233 8.51101 19.7233 8.5491V20.9022C19.7233 20.9403 19.6916 20.972 19.6535 20.972H11.2488C11.2107 20.972 11.179 20.9403 11.179 20.9022V8.54592C11.179 8.50784 11.2107 8.4761 11.2488 8.4761H12.2073C12.3375 8.4761 12.4422 8.37135 12.4422 8.23805C12.4422 8.10792 12.3375 8 12.2073 8H11.2488C10.9505 8 10.7061 8.2444 10.7061 8.54592V20.899C10.7061 21.1974 10.9505 21.4418 11.2488 21.4418H12.2042V22.175C12.2042 22.4765 12.4454 22.7209 12.7469 22.7209H13.6705V23.4541C13.6705 23.7524 13.9149 24 14.2133 24H22.6148C22.9132 24 23.1576 23.7556 23.1576 23.4541V15.7635C23.1576 15.6334 23.0528 15.5255 22.9227 15.5255C22.7925 15.5255 22.6878 15.6302 22.6878 15.7635V23.4541C22.6878 23.4922 22.6561 23.5239 22.618 23.5239H14.2133C14.1752 23.5239 14.1435 23.4922 14.1435 23.4541V22.7209H21.1516C21.45 22.7209 21.6944 22.4765 21.6944 22.175V18.9026C21.6944 18.7725 21.5896 18.6645 21.4595 18.6645C21.3293 18.6645 21.2246 18.7693 21.2246 18.9026V22.175C21.2246 22.2131 21.1929 22.2448 21.1548 22.2448H12.7469C12.7088 22.2448 12.6771 22.2131 12.6771 22.175V21.4418H19.6535C19.9518 21.4418 20.1962 21.1974 20.1962 20.899V9.75521H21.1516C21.1897 9.75521 21.2214 9.78695 21.2214 9.82503V11.0311V17.9568C21.2214 18.0869 21.3262 18.1948 21.4563 18.1948C21.5864 18.1948 21.6912 18.0901 21.6912 17.9568V11.0311H22.6148C22.6529 11.0311 22.6846 11.0629 22.6846 11.101V14.8145C22.6846 14.9478 22.7925 15.0526 22.9227 15.0526Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M14.594 10.3441C14.594 9.86796 14.9781 9.48073 15.451 9.48073C15.9239 9.48073 16.308 9.86796 16.308 10.3441C16.308 10.8202 15.9239 11.2074 15.451 11.2074C14.9781 11.2074 14.594 10.8202 14.594 10.3441ZM16.7809 10.3441C16.7809 9.60769 16.1842 9.00781 15.451 9.00781C14.7178 9.00781 14.1211 9.60769 14.1211 10.3441C14.1211 11.0804 14.7178 11.6803 15.451 11.6803C16.1842 11.6803 16.7809 11.0804 16.7809 10.3441Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M18.6666 10.5855C18.7967 10.5855 18.9014 10.4807 18.9014 10.3474C18.9014 10.2173 18.7967 10.1094 18.6666 10.1094H17.562C17.4319 10.1094 17.3271 10.2141 17.3271 10.3474C17.3271 10.4776 17.4319 10.5855 17.562 10.5855H18.6666Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M13.3404 10.1094H12.2359C12.1057 10.1094 12.001 10.2141 12.001 10.3474C12.001 10.4776 12.1057 10.5855 12.2359 10.5855H13.3404C13.4705 10.5855 13.5753 10.4807 13.5753 10.3474C13.5784 10.2173 13.4705 10.1094 13.3404 10.1094Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M12.5469 12.5935C12.5469 12.7236 12.6516 12.8316 12.7817 12.8316H18.1204C18.2505 12.8316 18.3552 12.7268 18.3552 12.5935C18.3552 12.4634 18.2505 12.3555 18.1204 12.3555H12.7817C12.6516 12.3555 12.5469 12.4634 12.5469 12.5935Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M18.1204 13.5039H12.7817C12.6516 13.5039 12.5469 13.6086 12.5469 13.742C12.5469 13.8721 12.6516 13.98 12.7817 13.98H18.1204C18.2505 13.98 18.3552 13.8753 18.3552 13.742C18.3584 13.6086 18.2505 13.5039 18.1204 13.5039Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M18.1204 14.6484H12.7817C12.6516 14.6484 12.5469 14.7532 12.5469 14.8865C12.5469 15.0166 12.6516 15.1245 12.7817 15.1245H18.1204C18.2505 15.1245 18.3552 15.0198 18.3552 14.8865C18.3584 14.7564 18.2505 14.6484 18.1204 14.6484Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M18.1204 15.7969H12.7817C12.6516 15.7969 12.5469 15.9016 12.5469 16.0349C12.5469 16.1651 12.6516 16.273 12.7817 16.273H18.1204C18.2505 16.273 18.3552 16.1682 18.3552 16.0349C18.3584 15.9016 18.2505 15.7969 18.1204 15.7969Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M18.1204 16.9492H12.7817C12.6516 16.9492 12.5469 17.054 12.5469 17.1873C12.5469 17.3206 12.6516 17.4253 12.7817 17.4253H18.1204C18.2505 17.4253 18.3552 17.3206 18.3552 17.1873C18.3552 17.054 18.2505 16.9492 18.1204 16.9492Z"
        fill="var(--dark-blue)"
      />
      <path
        d="M18.2248 18.1469C18.1232 18.0644 17.9741 18.0802 17.8915 18.1786L16.7679 19.5561L16.2474 19.042C16.1554 18.9499 16.0062 18.9531 15.9141 19.0451C15.8221 19.1372 15.8253 19.2895 15.9173 19.3784L16.5013 19.9529C16.6664 20.1148 16.933 20.0989 17.0758 19.9211L18.2565 18.4738C18.3391 18.3786 18.3232 18.2294 18.2248 18.1469Z"
        fill="var(--dark-blue)"
      />
    </svg>
  );
};

export default AwaitingPermit;
