import React from "react";

import styled from "@emotion/styled";
import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { cvar } from "styles/colorVariables";

export const AdminTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: cvar("darkest-blue"),
  },
  [`& .${tooltipClasses.tooltip}`]: {
    background: cvar("darkest-blue"),
    fontSize: 12,
    color: cvar("white"),
    fontFamily: "Barlow",
    fontWeight: 500,
    padding: "9px",
    borderRadius: "12px",
    filter: "drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15))",
  },
}));
