import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const PowurOnIcon = ({ height = 16, width = 16, fill = cvar("white") }: IconT) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2682_13222)">
        <path
          d="M3.5145 9.03174C3.33766 9.03174 3.17587 9.1313 3.09613 9.28914L1.70312 12.047H7.53059V9.03174H3.5145Z"
          fill={fill}
        />
        <path
          d="M12.9032 9.28914C12.8234 9.1313 12.6617 9.03174 12.4848 9.03174H8.46875V12.047H14.2962L12.9032 9.28914Z"
          fill={fill}
        />
        <path
          d="M15.9492 15.3201L14.7698 12.9849H8.46875V16.0001H15.5308C15.8794 16.0001 16.1066 15.6317 15.9492 15.3201Z"
          fill={fill}
        />
        <path
          d="M1.23027 12.9849L0.050801 15.3201C-0.106324 15.6312 0.12002 16.0001 0.469145 16.0001H7.53124V12.9849H1.23027Z"
          fill={fill}
        />
        <path
          d="M7.99991 2.40674C7.05222 2.40674 6.28125 3.17777 6.28125 4.12549C6.28125 5.07321 7.05225 5.84424 7.99991 5.84424C8.94756 5.84424 9.71856 5.07321 9.71856 4.12549C9.71856 3.17777 8.94759 2.40674 7.99991 2.40674Z"
          fill={fill}
        />
        <path
          d="M8 0C7.74112 0 7.53125 0.209875 7.53125 0.46875V1.50022C7.53125 1.75909 7.74112 1.96897 8 1.96897C8.25888 1.96897 8.46875 1.75909 8.46875 1.50022V0.46875C8.46875 0.209875 8.25888 0 8 0Z"
          fill={fill}
        />
        <path
          d="M8 6.28174C7.74112 6.28174 7.53125 6.49161 7.53125 6.75049V7.78196C7.53125 8.04083 7.74112 8.25071 8 8.25071C8.25888 8.25071 8.46875 8.04083 8.46875 7.78196V6.75049C8.46875 6.49161 8.25888 6.28174 8 6.28174Z"
          fill={fill}
        />
        <path
          d="M6.47482 1.93744L5.74553 1.2081C5.56247 1.02504 5.26569 1.02504 5.0826 1.2081C4.89957 1.39113 4.89954 1.68794 5.0826 1.871L5.81188 2.60035C5.995 2.78344 6.29182 2.78338 6.47482 2.60035C6.65785 2.41729 6.65788 2.1205 6.47482 1.93744Z"
          fill={fill}
        />
        <path
          d="M10.9162 6.37934L10.1869 5.64999C10.0039 5.46693 9.7071 5.46693 9.524 5.64999C9.34097 5.83302 9.34094 6.12984 9.524 6.3129L10.2533 7.04224C10.4364 7.22534 10.7332 7.22524 10.9162 7.04224C11.0993 6.85918 11.0993 6.5624 10.9162 6.37934Z"
          fill={fill}
        />
        <path
          d="M5.37516 3.65674H4.34375C4.08487 3.65674 3.875 3.86661 3.875 4.12549C3.875 4.38436 4.08487 4.59424 4.34375 4.59424H5.37516C5.63403 4.59424 5.84391 4.38436 5.84391 4.12549C5.84391 3.86661 5.63403 3.65674 5.37516 3.65674Z"
          fill={fill}
        />
        <path
          d="M11.6564 3.65674H10.625C10.3661 3.65674 10.1562 3.86661 10.1562 4.12549C10.1562 4.38436 10.3661 4.59424 10.625 4.59424H11.6564C11.9153 4.59424 12.1252 4.38436 12.1252 4.12549C12.1252 3.86661 11.9153 3.65674 11.6564 3.65674Z"
          fill={fill}
        />
        <path
          d="M6.47482 5.64998C6.29172 5.46692 5.995 5.46695 5.81188 5.64998L5.0826 6.37932C4.89954 6.56239 4.89957 6.85917 5.0826 7.04223C5.26572 7.22529 5.56244 7.22526 5.74553 7.04223L6.47482 6.31289C6.65788 6.12982 6.65785 5.83301 6.47482 5.64998Z"
          fill={fill}
        />
        <path
          d="M10.9162 1.20807C10.7331 1.02504 10.4364 1.02504 10.2533 1.20807L9.524 1.93742C9.34094 2.12048 9.34097 2.41726 9.524 2.60032C9.70713 2.78339 10.0038 2.78336 10.1869 2.60032L10.9162 1.87098C11.0993 1.68792 11.0993 1.39111 10.9162 1.20807Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2682_13222">
          <rect width={width} height={height} fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PowurOnIcon;
