import { put, all, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/users/messages";
import selectors from "rdx/modules/users/selectors";
import _ from "lodash";

function* updateAdminUsersMentorRating(action) {
  const { id, notes, rating } = action.payload;
  const { success, data, error } = yield* makeRequest.patch(`/mentor_ratings/${id}/admin_update`, action.payload);

  if (success && data) {
    const successMessage = messages.UPDATE_MENTOR_RATING_SUCCESS;
    const mentorRatings = yield select(selectors.getMentorRatings);
    const { paging, list } = _.clone(mentorRatings);
    const nextList = list.map((r) => {
      if (r.id === id) {
        return { ...r, notes, rating };
      }
      return r;
    });

    yield all([
      put(actions.setMentorRatings({ paging, list: nextList })),
      put(actions.newSuccessEvent({ message: successMessage })),
    ]);
  } else if (error) {
    const errorMessage = messages.ERROR_UPDATING_MENTOR_RATING;
    return getErrorActions({
      error,
      message: errorMessage,
    });
  }

  return null;
}

export default updateAdminUsersMentorRating;
