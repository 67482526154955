import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../Team.module.less";

const JBCard = () => {
  const { t } = useTranslation("team");

  return (
    <div className={styles.JBCardContainer}>
      <img className={styles.JBImage} src="/img/powurLeadership/JonathanBudd.jpg" alt="Jonathan Budd" />
      <div className={styles.sideText}>
        <p className={styles.boldName}>Jonathan Budd</p>
        <p className={styles.boldTitle}>{t("body.ceo.title")}</p>
        <p className={styles.paragraph}>{t("body.ceo.description.0")}</p>
        <p className={styles.paragraph}>{t("body.ceo.description.1")}</p>
      </div>
    </div>
  );
};

export default JBCard;
