import { styled } from "@mui/material/styles";
import { filterStyledProps } from "lib/helpers/filterStyledProps";
import { theme } from "styles/themes";
import { mq } from "styles";

type DetailContainerPropsT = {
  backgroundColor: string | null;
};

type ContentRowContainerPropsT = {
  imagePath: string;
};

type RightAlignmentT = {
  isRightAligned: boolean;
};

export const ContentRowContainer = styled("div", { shouldForwardProp: filterStyledProps(["imagePath"]) })(
  ({ imagePath }: ContentRowContainerPropsT) => ({
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    backgroundImage: `url(${imagePath})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    padding: "calc(100vw * 0.05)",
    minHeight: "max-content",
  }),
);

export const MaxWidthContainer = styled("div", { shouldForwardProp: filterStyledProps(["isRightAligned"]) })(
  ({ isRightAligned }: RightAlignmentT) => ({
    width: "100%",
    maxWidth: "1440px",
    display: "flex",
    justifyContent: "space-between",
    gap: "32px",
    flexWrap: "wrap",
    minHeight: "max-content",
    [mq.tabletUp]: {
      flexDirection: "column",
      alignItems: isRightAligned ? "flex-end" : "flex-start",
    },
    [mq.desktopUp]: {
      flexDirection: isRightAligned ? "row-reverse" : "row",
      alignItems: "normal",
    },
  }),
);

export const SecondaryImageContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  minWidth: "300px",
  maxWidth: "625px",
}));

export const ContentContainer = styled("div", { shouldForwardProp: filterStyledProps(["isRightAligned"]) })(
  ({ isRightAligned }: RightAlignmentT) => ({
    width: "450px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: isRightAligned ? "flex-end" : "flex-start",
    rowGap: "36px",
    [mq.tabletUp]: {
      flex: 1,
    },
    [mq.desktopUp]: {
      flex: "unset",
    },
  }),
);

export const HeaderContainer = styled("div", { shouldForwardProp: filterStyledProps(["isRightAligned"]) })(
  ({ isRightAligned }: RightAlignmentT) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: isRightAligned ? "flex-end" : "flex-start",
    rowGap: "36px",
    textAlign: isRightAligned ? "right" : "left",
  }),
);

export const WhiteHeader = styled("div")(() => ({
  fontWeight: 600,
  fontFamily: "Sora",
  color: theme.colors.white,
  [mq.phoneOnly]: {
    fontSize: "40px",
    lineHeight: "44px",
  },
  [mq.tabletUp]: {
    fontSize: "48px",
    lineHeight: "55px",
  },
  [mq.desktopUp]: {
    fontSize: "65px",
    lineHeight: "75px",
  },
}));

export const WhiteDetailText = styled("div")(() => ({
  fontSize: "24px",
  lineHeight: "32px",
  fontWeight: 400,
  color: theme.colors.white,
  [mq.phoneOnly]: {
    fontSize: "20px",
  },
  [mq.tabletUp]: {
    fontSize: "22px",
  },
  [mq.desktopUp]: {
    fontSize: "24px",
  },
}));

export const DetailsContainer = styled("div", {
  shouldForwardProp: filterStyledProps(["backgroundColor"]),
})(({ backgroundColor }: DetailContainerPropsT) => ({
  ...(backgroundColor
    ? { background: backgroundColor }
    : {
        background: "linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.13) 68.53%)",
        backdropFilter: "blur(24px)",
      }),
  borderRadius: "16px",
  [mq.tabletDown]: {
    width: "100%",
    maxWidth: "380px",
  },
  [mq.phoneOnly]: {
    padding: "30px",
  },
  [mq.tabletUp]: {
    maxWidth: "412px",
    padding: "44px",
  },
}));

export const DetailsHeader = styled("div", { shouldForwardProp: filterStyledProps(["isInlineSize", "highlightText"]) })(
  ({ isInlineSize, highlightText }: { isInlineSize: boolean; highlightText: boolean }) => ({
    ...(isInlineSize && { contain: "inline-size" }),
    fontWeight: 400,
    fontFamily: "Sora",
    color: highlightText ? theme.colors["tango-orange"] : theme.colors.white,
    marginBottom: "20px",
    minWidth: "calc(100% + 3px)",
    lineHeight: "32px",
    [mq.phoneOnly]: {
      fontSize: "26px",
    },
    [mq.tabletUp]: {
      fontSize: "32px",
    },
  }),
);

export const DetailsImage = styled("img")(() => ({
  width: "100%",
}));

export const DetailsSubheader = styled("div", { shouldForwardProp: filterStyledProps(["isInlineSize"]) })(
  ({ isInlineSize }: { isInlineSize: boolean }) => ({
    ...(isInlineSize && { contain: "inline-size" }),
    lineHeight: "32px",
    fontWeight: 300,
    color: theme.colors.white,
    marginTop: "20px",
    [mq.phoneOnly]: {
      fontSize: "18px",
    },
    [mq.tabletUp]: {
      fontSize: "24px",
    },
  }),
);

export const MobileContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const MobileImage = styled("img")(() => ({
  width: "100%",
  margin: "-1px 0px",
}));

export const MobileRowContainer = styled("div")(() => ({
  width: "100%",
  padding: "35px",
  display: "flex",
  justifyContent: "space-between",
  gap: "32px",
  flexWrap: "wrap",
  background: theme.colors["midnight-black"],
}));
