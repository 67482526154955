import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/projects/messages";

function* inviteToPowurSite(action) {
  const { providerLeadId } = action.payload;

  const { success, error } = yield* makeRequest.post(`/provider_leads/${providerLeadId}/invite_to_powur_site`);

  if (success) {
    yield put([actions.newSuccessEvent({ message: messages.INVITE_TO_POWUR_SITE_SUCCESS })]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.INVITE_TO_POWUR_SITE_ERROR,
    });
  }

  return null;
}

export default inviteToPowurSite;
