import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import StorageClient from "util/StorageClient";

const ResetFilters: FC = () => {
  const history = useHistory();
  const { location } = history;
  const storage = new StorageClient();

  const reset = () => {
    if (storage?.projectFilters.projects) {
      storage.clear("projectFilters");
    }

    history.push({ pathname: location.pathname, search: "" });
  };

  const ResetFiltersButton = styled(Button)`
    background: white;
    color: var(--font-color-eastern-blue);
    height: 32px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    text-transform: none;
    font-size: 12px;
    font-weight: 600;
    border-radius: 25px;
    line-height: 18px;
    white-space: nowrap;
    letter-spacing: 0;
    visibility: ${location.search ? "visible" : "hidden"};

    &:hover {
      background: white;
      color: #003b4f;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    },
  `;

  return (
    <ResetFiltersButton onClick={reset} variant="contained">
      Reset Filters &amp; Sorts
    </ResetFiltersButton>
  );
};

export default ResetFilters;
