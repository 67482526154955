import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/reports/types";
import startUsersReport from "./startUsersReport";
import getUsersReport from "./getUsersReport";
import startUsersContestReport from "./startUsersContestReport";
import getUsersContestReport from "./getUsersContestReport";
import startUserProjectsReport from "./startUserProjectsReport";
import getUserProjectsReport from "./getUserProjectsReport";

function* watchReportsSagas() {
  yield trackRequests(takeEvery, types.START_USERS_REPORT_REQUEST, startUsersReport);
  yield trackRequests(takeEvery, types.GET_USERS_REPORT_REQUEST, getUsersReport);
  yield trackRequests(takeEvery, types.START_USERS_CONTEST_REPORT_REQUEST, startUsersContestReport);
  yield trackRequests(takeEvery, types.GET_USERS_CONTEST_REPORT_REQUEST, getUsersContestReport);
  yield trackRequests(takeEvery, types.START_USER_PROJECTS_REPORT_REQUEST, startUserProjectsReport);
  yield trackRequests(takeEvery, types.GET_USER_PROJECTS_REPORT_REQUEST, getUserProjectsReport);
}

export default watchReportsSagas;
