import React from "react";

interface CloseIconPropsI {
  startFill?: string;
  stopFill?: string;
  height?: string | number;
  width?: string | number;
}

const CloseIcon = ({
  startFill = "var(--picton-blue)",
  stopFill = "var(--blue-ribbon)",
  height = "12",
  width = "12",
}: CloseIconPropsI) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.148 0.880947L11.1485 0.881307L11.148 0.880949L6.98835 5.83929C6.91021 5.93243 6.91021 6.06822 6.98835 6.16136L11.148 11.1197C11.2197 11.2044 11.1594 11.3331 11.0487 11.3331H9.74314C9.66478 11.3331 9.59131 11.2988 9.54234 11.24L6.19196 7.24589C6.09182 7.12652 5.90817 7.12652 5.80803 7.24589L2.45765 11.24C2.40704 11.2988 2.33357 11.3331 2.25684 11.3331H0.951323C0.926536 11.3332 0.902233 11.3263 0.881297 11.313C0.860361 11.2997 0.843671 11.2807 0.833208 11.2583C0.822746 11.2358 0.818951 11.2108 0.822274 11.1862C0.825596 11.1617 0.835897 11.1386 0.851953 11.1197L0.851955 11.1197L5.01164 6.16136C5.08977 6.06822 5.08977 5.93243 5.01164 5.83929L0.851955 0.880949L0.851953 0.880947C0.780279 0.796241 0.840546 0.66755 0.951323 0.66755H2.25684C2.33521 0.66755 2.40867 0.701835 2.45764 0.760601L2.45764 0.760602L5.80803 4.75476C5.90817 4.87413 6.09182 4.87413 6.19196 4.75476L9.54234 0.760608C9.59295 0.701832 9.66642 0.66755 9.74314 0.66755H11.0487C11.1594 0.66755 11.2197 0.796241 11.148 0.880947Z"
        fill="url(#paint0_linear_2283_8886)"
        stroke="url(#paint1_linear_2283_8886)"
        strokeWidth="0.00111607"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2283_8886"
          x1="2.63774"
          y1="-3.77745"
          x2="11.7889"
          y2="-2.8897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startFill} />
          <stop offset="1" stopColor={stopFill} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2283_8886"
          x1="2.63774"
          y1="-3.77745"
          x2="11.7889"
          y2="-2.8897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startFill} />
          <stop offset="1" stopColor={stopFill} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CloseIcon;
