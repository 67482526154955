import PropTypes from "prop-types";
import { store } from "rdx/configureStore";
import actions from "rdx/actions";
import { Base } from "./Base";

const {
  getPayoutsList,
  deletePayout,
  distributePayouts,
  topUpPayouts,
  payoutToPowur,
  createPayout,
  getPaymentMilestones,
} = actions;

export default class Payout extends Base {
  static getList = (params) => {
    store.dispatch(getPayoutsList(params));
  };

  static getMilestonesNeedingPayout = (params) => {
    store.dispatch(getPaymentMilestones(params));
  };

  delete = () => {
    store.dispatch(deletePayout({ id: this.props.id }));
  };

  distribute = () => {
    store.dispatch(distributePayouts());
  };

  topup = () => {
    store.dispatch(topUpPayouts());
  };

  payoutToPowur = () => {
    store.dispatch(payoutToPowur());
  };

  create = ({ startDay, endDay }) => {
    store.dispatch(createPayout({ startDay, endDay }));
  };

  static types() {
    return PropTypes.shape({
      amount: PropTypes.string,
      consultant: PropTypes.shape({
        avatar: PropTypes.shape({
          large: PropTypes.string,
          preview: PropTypes.string,
          retina: PropTypes.string,
          thumb: PropTypes.string,
        }),
        email: PropTypes.string,
        firstName: PropTypes.string,
        fullName: PropTypes.string,
        id: PropTypes.number,
        lastName: PropTypes.string,
        locale: PropTypes.string,
        phone: PropTypes.string,
        vanityName: PropTypes.string,
      }),
      homeowner: PropTypes.shape({
        avatar: PropTypes.shape({
          large: PropTypes.string,
          preview: PropTypes.string,
          retina: PropTypes.string,
          thumb: PropTypes.string,
        }),
        email: PropTypes.string,
        firstName: PropTypes.string,
        fullName: PropTypes.string,
        id: PropTypes.number,
        lastName: PropTypes.string,
        locale: PropTypes.string,
        phone: PropTypes.string,
        vanityName: PropTypes.string,
      }),
      id: PropTypes.number,
      m2At: PropTypes.string,
      notifyAt: PropTypes.string,
      paidAt: PropTypes.string,
      sourceUid: PropTypes.string,
      stripeUserId: PropTypes.string,
      topupUid: PropTypes.string,
      user: PropTypes.shape({
        avatar: PropTypes.shape({
          large: PropTypes.string,
          preview: PropTypes.string,
          retina: PropTypes.string,
          thumb: PropTypes.string,
        }),
        email: PropTypes.string,
        firstName: PropTypes.string,
        fullName: PropTypes.string,
        id: PropTypes.number,
        lastName: PropTypes.string,
        locale: PropTypes.string,
        phone: PropTypes.string,
        vanityName: PropTypes.string,
      }),
    });
  }

  static filterTypes() {
    return PropTypes.shape({
      filters: PropTypes.shape({
        stripe_linked: PropTypes.shape({
          name: PropTypes.oneOf(["stripe_linked"]),
          default: PropTypes.string,
          values: PropTypes.arrayOf(PropTypes.string),
        }),
      }),
      sorts: PropTypes.arrayOf(PropTypes.string),
    });
  }
}
