import { ActivityCenter } from "@icons";
import tabs from "containers/Sales/ActivityCenter/tabs";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";
import breadCrumbs from "components/PlatformLayout/components/PlatformPageHeader/Breadcrumbs/ActivityCenterBreadCrumbs";

const content = l(() => import("containers/Sales/ActivityCenter"));
const { ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN } = permissionTypes;

export default {
  key: "org-activity-center",
  title: breadCrumbs,
  path: "/org-activity-center/:tab?",
  linkTarget: "/org-activity-center/notifications",
  linkTitle: "Activity Center",
  linkCategory: "Enterprise",
  icon: ActivityCenter,
  basePath: "org-activity-center",
  requiredParams: ["tab"],
  optionalParams: [],
  tabs,
  content,
  sidebar: null,
  permission: [ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN],
};
