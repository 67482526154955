export default {
  // general
  SUCCESS_DELETING_PAYOUT: "Payout deleted.",
  SUCCESS_DISTRIBUTING_PAYOUTS: "Payouts distributed.",
  TOPUP_SUCCESS: "Topup success.",
  SUCCESS_CREATING_PAYOUT: "Payout created.",
  SUCCESS_PAYING_OUT_TO_POWUR: "Payout to Powur successful.",

  // errors
  ERROR_FETCHING_PAYOUTS_LIST: "There was an error retrieving payouts list",
  ERROR_DELETING_PAYOUT: "There was an error deleting this payout",
  ERROR_DISTRIBUTING_PAYOUTS: "There was an error distributing payouts",
  TOPUP_ERROR: "There was an error creating topup",
  ERROR_CREATING_PAYOUT: "There was an error creating payout",
  ERROR_PAYING_OUT_TO_POWUR: "There was an error making the Powur payout request",
  ERROR_FETCHING_MILESTONES_LIST: "There was an error getting M2 milestones list",
};
