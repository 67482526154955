export default {
  // general

  // errors
  ERROR_STARTING_USERS_REPORT: "There was an error starting users report",
  ERROR_FETCHING_USERS_REPORT: "There was an error fetching the users report",
  ERROR_STARTING_USERS_CONTEST_REPORT: "There was an error starting users contest report",
  ERROR_FETCHING_USERS_CONTEST_REPORT: "There was an error fetching the users contest report",
  ERROR_STARTING_USER_PROJECTS_REPORT: "There was an error starting this projects report",
  ERROR_FETCHING_USER_PROJECTS_REPORT: "There was an error fetching this projects report",
};
