import { put, all } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/publicApiProxy/messages";
import { camelizeKeys, keysToSnakeCase } from "lib/helpers";

function* getGoogleAuthorizeUrl(action) {
  // GetGoogleAuthorizeUrlActionParams
  const params = keysToSnakeCase(action.payload);

  const { success, data, error } = yield* makeRequest.get("/gmail_oauth/authorize_url", params);

  if (success && data) {
    const result = camelizeKeys(data);

    yield all([
      put(
        actions.newSuccessEvent({
          message: messages.GET_GOOGLE_AUTHORIZE_URL_SUCCESS,
        }),
      ),
      put(actions.setGoogleAuthorizeUrl({ googleAuthorizeUrl: result.authorizeUrl })),
    ]);
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_GOOGLE_AUTHORIZE_URL,
    });
  }

  return null;
}

export default getGoogleAuthorizeUrl;
