import { put, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import { cloneDeep } from "lodash";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import messages from "rdx/modules/compensation/messages";

function* clawbackPayment(action) {
  const { id, feeName, feeCost } = action.payload;

  const { success, data, error } = yield* makeRequest.post("/compensation/clawback", {
    payment_item_id: id,
    fee_name: feeName,
    fee_cost: feeCost,
  });

  if (success && data) {
    const ledger = yield select(selectors.getLedger);
    const ledgerCopy = cloneDeep(ledger);

    const newPayments = ledgerCopy.payments.map((pmnt) => {
      return pmnt.payment.paymentItemId === id
        ? {
            ...pmnt,
            payment: {
              ...pmnt.payment,
              clawbackable: false,
              status: "clawback",
            },
          }
        : pmnt;
    });

    yield put(actions.setLedger({ ...ledgerCopy, payments: newPayments }));
    yield put(
      actions.newSuccessEvent({
        message: messages.CLAWBACK_PAYMENT_SUCCESS,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CLAWING_BACK_PAYMENT,
    });
  }

  return null;
}

export default clawbackPayment;
