import React from "react";
import PropTypes from "prop-types";

const CheckIcon2 = (props) => {
  const { height, width, containerStyles } = props;

  return (
    <div className={containerStyles}>
      <svg width={height} height={width} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9233 2.94697C16.9546 2.98085 16.9856 3.01499 17.0162 3.04935L18.3156 1.6335C18.7316 1.15345 19.4577 1.10003 19.9391 1.51517C20.384 1.89901 20.4642 2.54849 20.1468 3.02547L20.1439 3.02985L20.0614 3.13855L9.69182 14.5302L4.33951 9.99255C3.85398 9.58094 3.79401 8.8537 4.20558 8.36811C4.58557 7.91971 5.23441 7.83431 5.71401 8.14763L5.71846 8.15054L5.82592 8.23077L9.44503 11.2989L15.5239 4.67532L15.4179 4.5799C15.3559 4.52406 15.292 4.46655 15.2261 4.4078C14.9047 4.12118 14.5237 3.79391 14.0474 3.455C12.8415 2.70705 11.4514 2.30507 9.99892 2.30507C5.74968 2.30507 2.30485 5.75024 2.30485 9.99998C2.30485 14.2497 5.74968 17.6949 9.99892 17.6949C14.2482 17.6949 17.693 14.2497 17.693 9.99998C17.693 9.40136 17.6248 8.81261 17.4913 8.24124C17.3466 7.62145 17.7315 7.00161 18.3513 6.85671C18.9711 6.71184 19.5911 7.09693 19.7358 7.71682C19.9095 8.45997 19.9979 9.22472 19.9979 10C19.9979 15.5228 15.5212 20 9.99894 20C4.47665 20 0 15.5228 0 10C0 4.47717 4.47689 0 9.99917 0C11.886 0 13.6984 0.524586 15.268 1.49953C15.5138 1.65219 16.2977 2.2694 16.9233 2.94697Z"
          fill="var(--blue-ribbon)"
        />
      </svg>
    </div>
  );
};

CheckIcon2.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  containerStyles: PropTypes.objectOf(PropTypes.string),
};

CheckIcon2.defaultProps = {
  height: "21",
  width: "20",
};

export default CheckIcon2;
