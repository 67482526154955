const formatNumberWithCommas = (num: string | number) => {
  if (typeof num === "string") {
    const out = num.replace(/,/g, "");
    return out.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (typeof num === "number") {
    return num.toLocaleString();
  }

  console.warn("This function returned null because it was not passed a string or number"); // eslint-disable-line no-console
  return null;
};

export default formatNumberWithCommas;
