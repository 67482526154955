import React from "react";
import { IconT } from "types/utils";
import { cvar } from "styles";

const InfoIconOutlined = ({
  width = "24",
  height = "24",
  stroke = cvar("darkest-blue"),
  fill = cvar("darkest-blue"),
}: IconT) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="11.5" stroke={stroke} />
      <path
        d="M10.3055 17.9995C9.66411 17.9995 9.19355 17.5829 9.64327 15.7495L10.3812 12.4994C10.5087 11.978 10.5304 11.7698 10.3812 11.7698C10.1884 11.7698 9.35403 12.1305 8.86179 12.4839L8.5415 11.9221C10.1045 10.5261 11.8995 9.70837 12.6696 9.70837C13.3121 9.70837 13.4189 10.5209 13.0976 11.7709L12.254 15.1879C12.1048 15.791 12.169 15.9994 12.3172 15.9994C12.51 15.9994 13.1412 15.7485 13.762 15.2273L14.1258 15.7487C12.6064 17.3737 10.9482 17.9997 10.3055 17.9997L10.3055 17.9995Z"
        fill={fill}
      />
      <circle cx="12.4045" cy="7.5324" r="1.5324" fill={fill} />
    </svg>
  );
};

export default InfoIconOutlined;
