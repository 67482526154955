import React from "react";
import PropTypes from "prop-types";

const LogbackIcon = (props) => {
  const { height, width } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.5474 27.0316L48.2526 25.0737C45.7895 27.0948 42.7579 28.2948 39.5368 28.2948C36.3158 28.2948 33.2842 27.0948 30.821 25.0737L26.5895 27.0316C22.8 28.7369 20.1474 32.2106 19.3895 36.2527C21.4105 37.579 23.1158 39.4106 24.3158 41.5579H43.6421C47.8105 41.5579 51.1579 44.9053 51.1579 49.0737H55.3263C57.8526 49.0737 59.8737 46.9895 59.8737 44.4632V38.4C59.9368 33.5369 57.0316 29.0527 52.5474 27.0316Z"
        fill="var(--royal-peacock-blue)"
      />
      <path
        d="M39.5368 23.7474C45.1876 23.7474 49.7684 18.4313 49.7684 11.8737C49.7684 5.31603 45.1876 0 39.5368 0C33.8861 0 29.3052 5.31603 29.3052 11.8737C29.3052 18.4313 33.8861 23.7474 39.5368 23.7474Z"
        fill="var(--royal-peacock-blue)"
      />
      <path
        d="M43.6421 46.0422H21.4105C20.0842 41.4948 15.9158 38.1475 10.9263 38.1475C4.92632 38.1475 0 43.0738 0 49.0738C0 55.0738 4.92632 60.0001 10.9263 60.0001C15.9158 60.0001 20.0842 56.6527 21.4105 52.1054H32.7789V55.6422C32.7789 56.6527 33.6 57.4738 34.6105 57.4738H40.6105C41.6211 57.4738 42.4421 56.6527 42.4421 55.6422V52.1054H43.6421C45.2842 52.1054 46.6737 50.779 46.6737 49.0738C46.6737 47.4317 45.3474 46.0422 43.6421 46.0422ZM10.8632 53.9369C8.14737 53.9369 6 51.7264 6 49.0738C6 46.358 8.21053 44.2106 10.8632 44.2106C13.5789 44.2106 15.7263 46.4211 15.7263 49.0738C15.7895 51.7896 13.5789 53.9369 10.8632 53.9369Z"
        fill="var(--royal-peacock-blue)"
      />
    </svg>
  );
};

LogbackIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

LogbackIcon.defaultProps = {
  height: "60",
  width: "60",
};

export default LogbackIcon;
