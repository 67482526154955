import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/buildPartnerApplications/messages";
import { camelizeKeys } from "lib/helpers";

function* getBuildPartnerApplications(action) {
  const { success, data, error } = yield* makeRequest.get("/labor_partner_applications", action.payload);

  if (success && data) {
    const list = camelizeKeys(data.body.labor_partner_applications);
    const paging = camelizeKeys(data.body.paging);
    yield put(actions.setBuildPartnerApplications({ list, paging }));
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_BUILD_PARTNER_APPLICATIONS,
    });
  }

  return null;
}

export default getBuildPartnerApplications;
