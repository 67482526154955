import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToSnakeCase, keysToCamelCase } from "lib/helpers";

import messages from "../messages";

function* updateLeadInfo(action) {
  const { leadId, lead, address, enterprise, siteInfo, admin } = action.payload;

  const params = {
    lead: keysToSnakeCase(lead),
    address: keysToSnakeCase(address),
    enterprise,
    site_info: keysToSnakeCase(siteInfo),
    admin,
  };

  const { success, data, error } = yield* makeRequest.patch(`/leads/${leadId}`, params);
  if (success && data) {
    yield all([
      put(actions.setProjectDetails({ lead: keysToCamelCase(data.body) })),
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_PROJECT_LEAD_INFORMATION_SUCCESS,
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_UPDATING_PROJECT_LEAD_INFORMATION,
    });
  }
  return null;
}

export default updateLeadInfo;
