import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
// import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
// import messages from "../messages";

function* ambassadorZipLookup(action) {
  const { zip } = action.payload;

  const { success, data } = yield* makeRequest.get(`/zips/${zip}`, {});

  if (success && data) {
    yield put(actions.setAmbassadorZipLookup(data.body));
  }
  // else if (error) {
  //   return getErrorActions({
  //     error,
  //     message: messages.ERROR_GETTING_AMBASSADOR_USER_SPONSOR,
  //   });
  // }

  return null;
}

export default ambassadorZipLookup;
