import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/enterpriseApplications/messages";

function* createTierOneUserRegistration(action) {
  const { success, data, error } = yield* makeRequest.post("/registrations", action.payload.params);

  if (success && data) {
    yield put(
      actions.newSuccessEvent({
        message: messages.CREATE_TIER_ONE_ENTERPRISE_USER_SUCCESS,
      }),
    );
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_CREATING_TIER_ONE_USER_REGISTRATION,
    });
  }

  return null;
}

export default createTierOneUserRegistration;
