import component from "containers/StaticPages/join";
// import l from "@loadable/component";

// const component = l(() => import("containers/StaticPages/join"));

export default {
  key: "consultant-join",
  title: "Join",
  path: "/:user?/join/:slug?",
  pageName: "join",
  linkTarget: "/join",
  component,
};
