const SCREEN_SIZES = {
  xs: 0,
  sml: 400,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
  xxl: 1620,
};

export default SCREEN_SIZES;
