import { styled } from "@mui/material/styles";
import { PrimaryButton } from "components/mui-styled-components/Buttons/styles";
import { mq } from "styles";

export const Header = styled("div")(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const HeaderSection = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

export const HeaderContent = styled("div")(() => ({
  display: "flex",
  flexGrow: 2,
  [mq.phoneOnly]: {
    justifyContent: "center",
  },
  [mq.tabletUp]: {
    justifyContent: "flex-start",
  },
}));

export const HeaderSubText = styled("div")(() => ({
  fontWeight: "500",
  fontSize: "16px",
  marginLeft: "5px",
  paddingTop: "2px",
}));

export const Footer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row-reverse",
  [mq.tabletUp]: {
    paddingRight: "120px",
  },
}));

export const T3Footer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  [mq.tabletUp]: {
    paddingRight: "120px",
  },
}));

export const StyledButton = styled(PrimaryButton)(() => ({
  minWidth: "0px",
  fontSize: "12px",
  padding: "0 10px",
}));
