import { put, all, select } from "redux-saga/effects";
import selectors from "rdx/modules/tickets/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/tickets/messages";
import { keysToCamelCase } from "lib/helpers";

function* unarchiveMessages(action) {
  const { success, data, error } = yield* makeRequest.post("/messages/unarchive", {
    ...action.payload,
  });

  if (success && data) {
    if (data.body.messages) {
      const newSelectedMessage = {
        ...(yield select(selectors.getSelectedMessage)),
        archived: false,
      };

      const currentPMList = yield select(selectors.getProjectMessageList);
      currentPMList.forEach((item) => {
        const out = item;
        if (action.payload.message_ids.includes(item.id)) {
          out.archived = false;
          if (action.payload.resolve) {
            out.resolved = true;
          }
        }
        return out;
      });

      if (data.body.taggable_roles || data.body.taggable_users) {
        yield put(
          actions.setInboxTags({
            roleTags: data.body.taggable_roles?.map((r) => ({
              ...keysToCamelCase(r),
              type: "role",
              text: r.description,
            })),
            userTags: data.body.taggable_users?.map((r) => ({
              ...keysToCamelCase(r),
              type: "user",
              text: r.full_name,
            })),
          }),
        );
      }

      yield all([
        put(
          actions.setInboxList({
            paging: data.body.paging,
            list: data.body.messages.map((entry) => keysToCamelCase(entry)),
          }),
        ),
        put(
          actions.setProjectMessageList({
            paging: data.body.paging,
            list: currentPMList,
          }),
        ),
        put(actions.setSelectedMessageDetails(newSelectedMessage)),
        put(
          actions.newSuccessEvent({
            message: messages.UNARCHIVE_MESSAGES_SUCCESS,
          }),
        ),
      ]);
    }
    if (data.body.filters) {
      yield all([put(actions.setInboxFilters(data.body)), put(actions.updateInboxCount(data.body.unread_counts))]);
    }
  } else if (error) {
    return getErrorActions({
      error,
      message: messages.ERROR_UNARCHIVING_MESSAGES,
    });
  }

  return null;
}

export default unarchiveMessages;
