import React from "react";

const ContactConfirmation = () => {
  return (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_3_489"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="68"
        height="68"
      >
        <path d="M0 7.62939e-06H68V68H0V7.62939e-06Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3_489)">
        <path
          d="M58.8681 21.1049L66.6719 27.485V58.6209C66.6719 60.1334 65.4459 61.3594 63.9334 61.3594H16.2852C14.7729 61.3594 13.5469 60.1334 13.5469 58.6209V27.485L21.3514 21.1049"
          stroke="#002160"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5469 27.677L40.1094 47.8728L66.6719 27.677"
          stroke="#002160"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.4219 6.64063H56.1291C57.6414 6.64063 58.8674 7.86662 58.8674 9.37909V33.6538"
          stroke="#002160"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3513 33.6537V9.37904C21.3513 7.86671 22.5773 6.64058 24.0898 6.64058H34.7969"
          stroke="#002160"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.24219 43.5575H13.5469"
          stroke="#002160"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.32812 52.0575H8.5"
          stroke="#002160"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.5569 24.5694C49.5569 29.787 45.327 34.0169 40.1094 34.0169C34.8917 34.0169 30.6619 29.787 30.6619 24.5694C30.6619 19.3517 34.8917 15.1219 40.1094 15.1219C45.327 15.1219 49.5569 19.3517 49.5569 24.5694Z"
          stroke="#002160"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.5597 22.5125L39.4459 26.6263L37.1015 24.2818"
          stroke="#002160"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4751 60.2955L32.5009 42.2697"
          stroke="#002160"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M65.7436 60.2955L47.7179 42.2697"
          stroke="#002160"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ContactConfirmation;
