import SchemaValidator from "util/schema-helpers/SchemaValidator";
import { camelizeKeys, keysToSnakeCase } from "lib/helpers";
import { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import actions from "rdx/actions";
import { Token } from "@stripe/stripe-js";
import { GetEnrollmentResponse, UpdateEnrollmentResponse, UpgradePreviewResponse } from "./types";
import {
  GetEnrollmentResponseBodySchema,
  ServiceSlugs,
  UpdateEnrollmentResponseBodySchema,
  UpgradePreviewResponseSchema,
} from "./schemas";
import { api } from "../../services/api";

export const ENROLLMENT_TAGS = {
  ENROLLMENTS: "Enrollments",
} as const;

const getEnrollmentResponse = (res: GetEnrollmentResponse) => {
  const result = SchemaValidator.validate<typeof GetEnrollmentResponseBodySchema>({
    schema: GetEnrollmentResponseBodySchema,
    schemaName: "GetEnrollmentResponseBodySchema: getEnrollment",
    dto: camelizeKeys(res.body),
  });

  if (!result.success) {
    throw new Error("Schema validation failed for response data: GetEnrollmentResponseBodySchema");
  }

  return camelizeKeys(result.data);
};

const alertMessages = async (
  error: unknown,
  dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
  msg?: string,
) => {
  const fetchError = error as { error: { data: { error: { message: string } } } };

  dispatch(
    actions.setAlertMessageVisible({
      message: msg ?? fetchError.error.data.error.message,
      severity: "error",
    }),
  );
};

export const enrollmentApi = api
  .enhanceEndpoints({ addTagTypes: [...Object.values(ENROLLMENT_TAGS)] })
  .injectEndpoints({
    endpoints: (b) => ({
      getEnrollment: b.query<GetEnrollmentResponse["body"], void>({
        providesTags: [ENROLLMENT_TAGS.ENROLLMENTS],
        query: () => "/enrollment",
        transformResponse: getEnrollmentResponse,
        onQueryStarted: async (_arg, { queryFulfilled, dispatch }) => {
          try {
            await queryFulfilled;
          } catch (error) {
            await alertMessages(error, dispatch);
          }
        },
      }),
      upgradePreview: b.mutation<UpgradePreviewResponse, { currentService: ServiceSlugs; toService: ServiceSlugs }>({
        query: ({ currentService, toService }) => ({
          url: `/services/${currentService}/enrollment/upgrade`,
          method: "POST",
          body: keysToSnakeCase({ preview: true, to_service: toService }),
        }),
        transformResponse: (res: UpgradePreviewResponse) => {
          SchemaValidator.validate<typeof UpgradePreviewResponseSchema>({
            schema: UpgradePreviewResponseSchema,
            schemaName: "UpgradePreviewResponseSchema",
            dto: camelizeKeys(res),
          });
          return camelizeKeys(res);
        },
        onQueryStarted: async (_arg, { queryFulfilled, dispatch }) => {
          try {
            await queryFulfilled;
          } catch (error) {
            await alertMessages(error, dispatch, "There was an error fetching upgrade details.");
          }
        },
      }),
      upgradeEnrollment: b.mutation<
        GetEnrollmentResponse["body"],
        { service: ServiceSlugs; params: { prorationKey?: string; toService: ServiceSlugs } }
      >({
        query: ({ service, params }) => ({
          url: `/services/${service}/enrollment/upgrade`,
          method: "POST",
          body: keysToSnakeCase(params),
        }),
        transformResponse: getEnrollmentResponse,
        onQueryStarted: async (_arg, { queryFulfilled, dispatch }) => {
          try {
            dispatch(
              actions.setAlertMessageVisible({
                message: "Submitting. . .",
                severity: "info",
                duration: 6000,
              }),
            );
            const { data } = await queryFulfilled;
            dispatch(
              enrollmentApi.util.updateQueryData("getEnrollment", undefined, (state) => {
                Object.assign(state, data);
              }),
            );
          } catch (error: unknown) {
            await alertMessages(error, dispatch);
          }
        },
      }),
      downgradeEnrollment: b.mutation<
        GetEnrollmentResponse["body"],
        { service?: ServiceSlugs; params: { toService: ServiceSlugs } }
      >({
        query: ({ service, params }) => ({
          url: `/services/${service}/enrollment/downgrade`,
          method: "POST",
          body: keysToSnakeCase(params),
        }),
        transformResponse: getEnrollmentResponse,
        onQueryStarted: async (_arg, { queryFulfilled, dispatch }) => {
          try {
            dispatch(
              actions.setAlertMessageVisible({
                message: "Submitting. . .",
                severity: "info",
                duration: 6000,
              }),
            );
            const { data } = await queryFulfilled;
            dispatch(
              enrollmentApi.util.updateQueryData("getEnrollment", undefined, (state) => {
                Object.assign(state, data);
              }),
            );
            dispatch(actions.setEnterpriseOrgSeats({ seats: [] }));
            dispatch(actions.setEnterpriseOrgSeat({ settings: {} }));
            dispatch(actions.setEnterpriseOrg({ settings: {} }));
            dispatch(
              actions.setAlertMessageVisible({
                message: "You have successfully downgraded your enrollment.",
                severity: "success",
              }),
            );
          } catch (error: unknown) {
            await alertMessages(error, dispatch);
          }
        },
      }),
      updateEnrollment: b.mutation<UpdateEnrollmentResponse["body"], { tokenId: Token["id"]; tos: boolean }>({
        query: ({ tokenId, tos }) => ({
          url: "/enrollment",
          method: "PATCH",
          body: keysToSnakeCase({ token: tokenId, tos, sourceType: "credit_card" }),
        }),
        transformResponse: (res: UpdateEnrollmentResponse) => {
          const result = SchemaValidator.validate<typeof UpdateEnrollmentResponseBodySchema>({
            schema: UpdateEnrollmentResponseBodySchema,
            schemaName: "UpdateEnrollmentResponseBodySchema: updateEnrollment",
            dto: camelizeKeys(res.body),
          });

          if (!result.success) {
            throw new Error("Schema validation failed for response data: UpdateEnrollmentResponseBodySchema");
          }

          return camelizeKeys(result.data);
        },
        onQueryStarted: async (_arg, { queryFulfilled, dispatch }) => {
          try {
            dispatch(
              actions.setAlertMessageVisible({
                message: "Submitting. . .",
                severity: "info",
                duration: 6000,
              }),
            );
            const { data } = await queryFulfilled;
            dispatch(
              enrollmentApi.util.updateQueryData("getEnrollment", undefined, (state) => {
                Object.assign(state.enrollment, data.enrollment);
                Object.assign(state.cardInfo, data.cardInfo);
              }),
            );
          } catch (error) {
            await alertMessages(error, dispatch);
          }
        },
      }),
    }),
  });

export const {
  useGetEnrollmentQuery,
  useUpgradeEnrollmentMutation,
  useUpdateEnrollmentMutation,
  useDowngradeEnrollmentMutation,
  useUpgradePreviewMutation,
} = enrollmentApi;
