import { put, all, select } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* getProfile() {
  const { success, data, error } = yield* makeRequest.get("/profile", {});
  if (success && data) {
    const userPermissions = yield select(selectors.getUserPermissions);
    const mentor = userPermissions.some((x) => x.name === "mentorship_assignment");
    yield all([
      put(actions.setProfileMentorshipTabVisible(!!mentor)),
      put(actions.setProfileOrganizationTabVisible(!!data.body.seat_id)),
      put(actions.setProfile(keysToCamelCase(data.body))),
      put(actions.newSuccessEvent({ message: messages.GET_PROFILE_SUCCESS })),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROFILE,
    });
  }
  return null;
}

export default getProfile;
