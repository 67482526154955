import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/adminProposals/types";
import getPendingAdminProposals from "rdx/modules/adminProposals/sagas/getPendingAdminProposals";
import getInProgressAdminProposals from "rdx/modules/adminProposals/sagas/getInProgressAdminProposals";
import getCompletedAdminProposals from "rdx/modules/adminProposals/sagas/getCompletedAdminProposals";
import getAdminProposalDetails from "rdx/modules/adminProposals/sagas/getAdminProposalDetails";
import createProposal from "rdx/modules/adminProposals/sagas/createProposal";
import updateProposal from "rdx/modules/adminProposals/sagas/updateProposal";
import markInProgress from "rdx/modules/adminProposals/sagas/markInProgress";
import markProposalAsRejected from "./markProposalAsRejected";
// IMPORT_PT (for script, do not remove)

function* watchAdminProposalsSagas() {
  yield trackRequests(takeEvery, types.GET_PENDING_ADMIN_PROPOSALS_LIST, getPendingAdminProposals);
  yield trackRequests(takeEvery, types.GET_IN_PROGRESS_ADMIN_PROPOSALS_LIST, getInProgressAdminProposals);
  yield trackRequests(takeEvery, types.GET_COMPLETED_ADMIN_PROPOSALS_LIST, getCompletedAdminProposals);
  yield trackRequests(takeEvery, types.GET_ADMIN_PROPOSAL_DETAILS, getAdminProposalDetails);
  yield trackRequests(takeEvery, types.CREATE_PROPOSAL, createProposal);
  yield trackRequests(takeEvery, types.UPDATE_PROPOSAL, updateProposal);
  yield trackRequests(takeEvery, types.MARK_PROPOSAL_AS_IN_PROGRESS, markInProgress);
  yield trackRequests(takeEvery, types.MARK_PROPOSAL_AS_REJECTED, markProposalAsRejected);
  // INSERTION_PT (for script, do not remove)
}

export default watchAdminProposalsSagas;
