import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import { useParams } from "react-router-dom";

import Workflow from "models/Workflow";
import styles from "../../AdminWorkflows.module.less";
import workflowsTabs from "../../tabs";
import CreateDataGroupModal from "../DataGroups/createDataGroup";
import WorkflowSelect from "./workflowSelect";

const Sidebar = (props) => {
  const { workflows, workflowDetails, setWorkflowDetails } = props;

  const { tab } = useParams();
  const tabKeys = workflowsTabs.map(({ key }) => key);

  const workflow = new Workflow(workflowDetails);

  const handleSelectChange = (value) => {
    const current = workflows.find((flow) => flow.id === value);
    setWorkflowDetails(current);
  };

  return (
    <div className={styles.sidebar}>
      {(() => {
        if (tab === tabKeys[0]) {
          return <WorkflowSelect workflows={workflows} workflowDetails={workflow} handleSelect={handleSelectChange} />;
        }
        if (tab === tabKeys[1]) {
          return <CreateDataGroupModal />;
        }
        return null;
      })()}
    </div>
  );
};

Sidebar.propTypes = {
  // TODO: enumerate workflow properties
  workflows: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  workflowDetails: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  setWorkflowDetails: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    workflows: "getWorkflows",
    workflowDetails: "getWorkflowDetails",
  }),
  getRdxActionMapper(["setWorkflowDetails"]),
)(Sidebar);
