export default {
  loginMessage: (name) => {
    if (name) {
      return `Login successful. Welcome back, ${name}.`;
    }
    return "Login successful. Welcome back.";
  },
  LOGIN_SUCCESS_EVENT: "LOGIN_SUCCESS_EVENT",
  OAUTH_SUCCESS_EVENT: "OAUTH_SUCCESS_EVENT",
  LOGIN_ERROR_EVENT: "LOGIN_ERROR_EVENT",

  RESET_PASSWORD_SUCCESS: "A link to reset your password has been sent to your email address.",
  RESET_PASSWORD_SUCCESS_EVENT: "RESET_PASSWORD_SUCCESS_EVENT",
  RESET_PASSWORD_ERROR_EVENT: "RESET_PASSWORD_ERROR_EVENT",

  CREATE_NEW_PASSWORD_SUCCESS: "Your password has been reset, and you may now log in with your new password.",
  CREATE_NEW_PASSWORD_SUCCESS_EVENT: "CREATE_NEW_PASSWORD_SUCCESS_EVENT",
  CREATE_NEW_PASSWORD_ERROR_EVENT: "RESET_PASSWORD_ERROR_EVENT",

  ACCEPTED_LATEST_TOS_SUCCESS: "You have accepted the latest Terms of Service.",
  ACCEPTED_LATEST_TOS_SUCCESS_EVENT: "ACCEPTED_LATEST_TOS_SUCCESS_EVENT",
  ACCEPTED_LATEST_TOS_ERROR_EVENT: "ACCEPTED_LATEST_TOS_ERROR_EVENT",

  ERROR_GETTING_LATEST_TOS: "There was an error getting the latest Terms of Service.",
};
