import React from "react";
import { useParams } from "react-router-dom";

import useNavigation from "@navigation";
import SidebarSearch from "components/PlatformLayout/components/Sidebar/Search";
import ResetFiltersButton from "@buttons/ResetFilters";
import LeadsFilters from "@sales/team/components/Sidebar/leadsfilters";
import styles from "@enterprise/enterprisePersonnel/EnterprisePersonnel.module.less";

const EnterprisePersonnelSidebar = () => {
  const { tab } = useParams();
  const isInvites = tab === "invites";
  const { navigate, override } = useNavigation("org-team", ["tab"], ["userId"]);

  const enterpriseUserRoles = [
    "Enterprise Owner",
    "Org Manager",
    "Org Support",
    "Lead Generator",
    "Org Seller",
    "Sales Consultant",
  ];

  const menus = [
    {
      key: "user_role",
      label: "User Role",
      options: [
        {
          key: "any-role",
          label: "All",
          default: true,
          index: 0,
          parentKey: "user_role",
        },
      ],
    },
  ];

  menus[0].options.push(
    ...enterpriseUserRoles.map((r) => ({
      key: r,
      label: r,
      default: false,
      index: 0,
      parentKey: "user_role",
    })),
  );

  return (
    isInvites && (
      <div className={styles.enterprisePersonnelSidebar}>
        <SidebarSearch navigate={navigate} />
        <LeadsFilters navigate={navigate} override={override} isEnterprise />
        <ResetFiltersButton />
      </div>
    )
  );
};

export default EnterprisePersonnelSidebar;
