import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import styles from "../../Installerjoin.module.less";

const Success = () => {
  const { t } = useTranslation(["join", "translation"]);
  const history = useHistory();

  const handleClose = () => {
    // return to homepage
    history.push("/");
  };

  return (
    <div className={styles.success}>
      <div className={styles.headerContainer}>
        <span className={styles.title}>{t("translation:congratulations")}</span>
      </div>
      <div className={styles.paragraphContainer}>{t("join:installer.success")}</div>
      <div className={styles.successFooter}>
        <Button type="primary" className={styles.button} onClick={handleClose}>
          {t("translation:close")}
        </Button>
      </div>
    </div>
  );
};

Success.propTypes = {};

export default Success;
