import dayjs from "dayjs";

const getDays = (year, month) => {
  const formatDay = (number) => {
    return number < 10 ? `0${number}` : number;
  };

  let daysInMonth = 31;
  if (month) {
    daysInMonth = dayjs(year ? `${year}-${month}` : `${dayjs().year()}-${month}`).daysInMonth();
  }
  const daysArr = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  const dayOptionArray = daysArr.map((day) => ({
    key: day,
    label: day.toString(),
    value: formatDay(day),
  }));

  return dayOptionArray;
};

export default getDays;
