import React from "react";
import { Button, ButtonProps } from "antd";
import styles from "./GradientButton.module.less";

// This component can be used just like an Antd Button, and Antd Button props can be passed directly to it
interface GradientButtonPropsI extends ButtonProps {
  className?: string;
  children?: string | React.ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const GradientButton = ({ className, children, style, disabled, ...rest }: GradientButtonPropsI) => {
  return (
    <Button style={style} disabled={disabled} className={[className, styles.gradientButton].join(" ")} {...rest}>
      {children}
    </Button>
  );
};

export default GradientButton;
