import { permissionTypes } from "hooks/usePermissions";
import { createTab, mergeTabObjects } from "util/tabUtils";

const { ENTERPRISE_ADMIN_PRO, ENTERPRISE_ADMIN } = permissionTypes;

const additionalPostSalesTabs = {
  dashboard: createTab("dashboard", "Dashboard", { conditionalRenderSelector: "getDashboardTabVisible" }),
} as const;

const commonTabs = {
  summary: createTab("summary", "Summary"),
  siteInfo: createTab("site-info", "Site Info"),
  proposals: createTab("proposals", "Proposals"),
  contracts: createTab("contracts", "Contracts", { conditionalRenderSelector: "getContractTabVisible" }),
  siteSurvey: createTab("site-survey", "Site Survey"),
  assets: createTab("assets", "Assets"),
  milestones: createTab("milestones", "Milestones"),
  commissions: createTab("commission", "Commission", { permission: [ENTERPRISE_ADMIN_PRO, ENTERPRISE_ADMIN] }),
  tickets: createTab("tickets", "Tickets"),
  activityLog: createTab("activity-log", "Activity Log", {
    conditionalRenderSelector: "selectActivityLogTabVisible",
    breadcrumbLabel: "Project Activities",
  }),
} as const;

export { commonTabs, additionalPostSalesTabs };

export default mergeTabObjects([additionalPostSalesTabs, commonTabs]);
