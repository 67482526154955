import React from "react";
import { IconT } from "types/utils";

const UpCaretFilled = ({
  width = 8,
  height = 6,
  fill = "var(--dark-blue)",
  preserveAspectRatio = undefined,
  className = "",
}: IconT) => {
  return (
    <svg
      preserveAspectRatio={preserveAspectRatio}
      width={width}
      height={height}
      className={className}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.59457 0.5618C3.79414 0.285268 4.20589 0.285268 4.40546 0.5618L7.75813 5.2074C7.99678 5.53808 7.76049 6 7.35269 6H0.647341C0.239537 6 0.00324935 5.53808 0.241899 5.2074L3.59457 0.5618Z"
        fill={fill}
      />
    </svg>
  );
};

export default UpCaretFilled;
