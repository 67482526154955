import React, { CSSProperties } from "react";
import { TextFieldProps } from "@mui/material/TextField";
import { countryCodes } from "lib/misc/dropdownOptions";
import Grid from "@mui/material/Grid";
import SmartFormTextField from "components/mui-styled-components/SmartFormElements/SmartFormTextField";
import SmartFormDropdown from "components/mui-styled-components/SmartFormElements/SmartFormDropdown";
import HelperText from "../HelperText";

type SmartFormPhoneT = {
  label?: string;
  name: string;
  helperText?: string | undefined;
  error?: boolean;
  disabled?: boolean;
  labelStyle?: CSSProperties;
  required?: boolean;
  fullWidth?: boolean;
  customPrefixWidth?: string;
} & TextFieldProps;

const SmartFormPhone = ({
  label = "Phone",
  name,
  helperText,
  error,
  disabled = false,
  labelStyle = {},
  required,
  fullWidth,
  customPrefixWidth,
}: SmartFormPhoneT) => {
  return (
    <>
      <Grid container spacing={2} sx={{ paddingTop: "0px" }}>
        <Grid item md={3} xs={4} sx={{ minWidth: "90px" }}>
          <SmartFormDropdown
            fullWidth={fullWidth}
            disabled={disabled}
            mainLabel={label}
            mainLabelStyle={{ ...labelStyle, whiteSpace: "nowrap" }}
            name={`${name}.phonePrefix`}
            dropdownOptions={countryCodes}
            required={required}
            width={customPrefixWidth}
          />
        </Grid>
        <Grid item flex={2}>
          <SmartFormTextField disabled={disabled} name={`${name}.phoneNumber`} labelStyle={{ height: "9px" }} />
        </Grid>
      </Grid>
      <HelperText error={error}>{helperText}</HelperText>
    </>
  );
};

export default SmartFormPhone;
