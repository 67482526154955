import React from "react";
import { useParams } from "react-router-dom";

import { RightOutlined } from "@ant-design/icons";

import useGetCurrentRouteInfo from "hooks/useGetCurrentRouteInfo";
import styles from "components/PlatformLayout/components/PlatformPageHeader/PlatformPageHeader.module.less";

const ActivityCenterBreadCrumbs = () => {
  const params = useParams();

  const { currentRoute } = useGetCurrentRouteInfo();
  const currentTab = currentRoute?.tabs?.find((tab) => tab.key === params.tab) || null;

  const textStyle = {
    textTransform: "capitalize",
    fontWeight: 600,
  };

  return (
    <>
      <div className={styles.title} style={textStyle}>
        {currentRoute.linkTitle}
      </div>
      {currentTab && (
        <>
          <RightOutlined className={styles.title} style={{ margin: "0 10px" }} />
          <div className={styles.title} style={textStyle}>
            {currentTab.breadcrumbLabel || currentTab.label}
          </div>
        </>
      )}
    </>
  );
};

export default ActivityCenterBreadCrumbs;
