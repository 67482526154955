import createAction from "rdx/utils/createAction";
import types from "./types";

export default {
  getRegistration: (payload) => createAction(types.GET_REGISTRATION_INFO, payload),

  setRegistrationInfo: (payload) => createAction(types.SET_REGISTRATION_INFO, payload),

  getSeatedSellerRegistrationInfo: (payload) => createAction(types.GET_SEATED_SELLER_REGISTRATION_INFO, payload),

  setSeatedSellerRegistrationInfo: (payload) => createAction(types.SET_SEATED_SELLER_REGISTRATION_INFO, payload),

  createNewUser: (payload) => createAction(types.CREATE_NEW_USER, payload),

  registerNewUser: (payload) => createAction(types.REGISTER_NEW_USER, payload),

  recruitRejectPendingSeat: (payload) => createAction(types.RECRUIT_REJECT_PENDING_SEAT, payload),

  updateRegistration: (payload) => createAction(types.UPDATE_REGISTRATION, payload),
  // INSERTION_PT (for script, do not remove)
};
