import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import PropTypes from "prop-types";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { Modal, Form, Tabs, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import EnterpriseApplication from "models/EnterpriseApplication/EnterpriseApplication";
import MessageEvent from "models/MessageEvent";
import User from "models/User";
import useModalControls from "hooks/useModalControls";
import useLoading from "hooks/useLoading";
import types from "@rdxmodules/enterpriseProLaborForm/types";
import { useTranslation } from "react-i18next";
import SECTIONS from "components/HomepageComponents/EnterpriseProSignupModal/sections";
import Header from "components/HomepageComponents/EnterpriseProSignupModal/Header";
import PersonalForm from "components/HomepageComponents/EnterpriseProSignupModal/PersonalForm";
import BusinessForm from "components/HomepageComponents/EnterpriseProSignupModal/BusinessForm";
import OfficersForm from "components/HomepageComponents/EnterpriseProSignupModal/OfficersForm";
import LaborPartnerCard from "components/HomepageComponents/EnterpriseProSignupModal/LaborPartnerCard";
import LaborPartnerForm from "components/HomepageComponents/EnterpriseProSignupModal/LaborPartnerForm";
import sharedClasses from "components/HomepageComponents/JoinModal/JoinModal.module.less";
import enterpriseMessages from "rdx/modules/enterpriseApplications/messages";
import laborMessages from "rdx/modules/enterpriseProLaborForm/messages";
import { useEffectOnce } from "react-use";
import FEATURE_FLAGS from "@flags";
import styles from "./EnterpriseProSignupModal.module.less";

const { TabPane } = Tabs;

const KEYS = Object.values(SECTIONS).map((s) => s.key);

const EnterpriseProForm = ({
  pageReferrer,
  latestMessage,
  getEnterpriseProModalLaborFormSubmit,
  selectEnterpriseApplicationDetailId,
  clearLatestMessage,
  currentUser,
  getUserLaborApplicationStatus,
  userStatus,
  setAlertMessageVisible,
}) => {
  const { t } = useTranslation(["ent_join_form_labels"]);
  const { signup, user, slug } = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const { open, close, visible } = useModalControls(false);
  const [activeTabIdx, setActiveTabIdx] = useState(0);
  const [laborPartnerApply, setLaborPartnerApply] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const [preFillForm, setPreFillForm] = useState(false);

  const formValues = useRef({});
  const [personalForm] = Form.useForm();
  const [businessForm] = Form.useForm();
  const [officersForm] = Form.useForm();
  const [laborPartnerForm] = Form.useForm();
  const FORMS = [personalForm, businessForm, officersForm, null, laborPartnerForm];
  const currentForm = FORMS[activeTabIdx]; // eslint-disable-line no-unused-vars
  const currentSection = SECTIONS[KEYS[activeTabIdx]];

  const isSubmitting = useLoading({
    watchRequests: [EnterpriseApplication.actionTypes.create, types.GET_ENTERPRISE_PRO_LABOR_MODAL_FORM_SUBMIT],
  });

  const handleActiveButtonClick = () => {
    if (!currentSection || currentSection.close) {
      handleClose();
    }
    if (currentSection.advance) {
      currentForm
        .validateFields()
        .then((values) => {
          formValues.current = {
            ...formValues.current,
            ...values,
          };
          setActiveTabIdx(activeTabIdx + 1);
        })
        .catch((e) => console.warn(e));
    } else if (currentSection.submit) {
      currentForm
        .validateFields()
        .then((values) => {
          formValues.current = {
            ...formValues.current,
            ...values,
          };
          handleSubmit(formValues.current);
        })
        .catch((e) => console.warn(e));
    } else if (currentSection.laborPartnerForm) {
      currentForm.validateFields().then((values) => {
        handleLaborPartnerSubmit(values);
      });
    }
  };

  const handleClose = () => {
    history.push(`${user ? `/${user}` : ""}/enterprise${search}`);
    setTimeout(() => {
      setLaborPartnerApply(false);
      setActiveTabIdx(0);
      clearLatestMessage();
    }, [500]);
  };

  const handleLaborCheckbox = (value) => {
    setLaborPartnerApply(value);
  };

  const handleSubmit = (values) => {
    const params = values;
    if (pageReferrer) {
      params.vanityName = pageReferrer.vanityName;
    }
    EnterpriseApplication.create(params);
  };

  const handleLaborPartnerSubmit = (values) => {
    const params = {
      enterpriseApplicationId: selectEnterpriseApplicationDetailId,
      ...values,
    };
    if (!params.otherServices) {
      params.otherServices = "";
    }
    getEnterpriseProModalLaborFormSubmit(params);
  };

  useEffectOnce(() => {
    if (signup === "build-partner-signup" && slug) getUserLaborApplicationStatus(slug);
  });

  useEffect(() => {
    if (!signup || signup === "signup") return close();
    return open();
  }, [signup, open, close]);

  useEffect(() => {
    if (signup !== "build-partner-signup") return setActiveTabIdx(0);
    if (!userStatus) return setActiveTabIdx(4);
    if (userStatus?.completed_labor_app) return setActiveTabIdx(5);
    setActiveTabIdx(4);
    return laborPartnerForm.setFieldsValue({
      firstName: userStatus.ownerFirstName,
      lastName: userStatus.ownerLastName,
      email: userStatus.ownerEmail,
      phone: userStatus.ownerPhone,
    });
  }, [userStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(currentUser)) {
      setPreFillForm(true);
      personalForm.setFieldsValue({
        ownerFirstName: currentUser.firstName,
        ownerLastName: currentUser.lastName,
        ownerEmail: currentUser.email,
        ownerPhone: currentUser.phone,
        locale: currentUser.locale,
        spokenLanguages: currentUser.spokenLanguages,
      });
    }
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      latestMessage.message === enterpriseMessages.CREATE_ENTERPRISE_APPLICATIONS_SUCCESS ||
      latestMessage.message === laborMessages.ENTERPRISE_PRO_LABOR_FORM_SUCCESS
    ) {
      setActiveTabIdx(activeTabIdx + 1);
    } else if (
      latestMessage.message === enterpriseMessages.ERROR_CREATING_ENTERPRISE_APPLICATIONS ||
      latestMessage.message === laborMessages.ENTERPRISE_PRO_LABOR_FORM_FAIL
    ) {
      let msg;
      if (latestMessage.message) {
        if (latestMessage.error.status >= 500) {
          msg = `${latestMessage.message}: something went wrong.`;
        } else {
          msg = `${latestMessage.message}: ${latestMessage.error.message || "something is wrong with your request."}`;
        }
      } else {
        msg = "Error: something went wrong.";
      }
      setAlertMessageVisible({
        message: msg,
        severity: "error",
      });
    }
  }, [latestMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (laborPartnerApply && activeTabIdx === 3) {
      setHideButton(true);
    } else {
      setHideButton(false);
    }
  }, [activeTabIdx, laborPartnerApply]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      open={visible}
      className={sharedClasses.joinModal}
      title={<Header title={t(currentSection?.title)} subtitle={t(currentSection?.subtitle)} />}
      footer={null}
      closable={!hideButton}
      maskClosable={!hideButton}
      onCancel={handleClose}
      closeIcon={<CloseOutlined style={{ color: "white" }} />}
    >
      <Tabs
        className={styles.modalBody}
        activeKey={KEYS[activeTabIdx]}
        defaultActiveKey={KEYS[0]}
        renderTabBar={() => null}
        destroyInactiveTabPane
      >
        <TabPane key={KEYS[0]} className={styles.tab} tab={<></>}>
          <PersonalForm form={personalForm} preFillForm={preFillForm} />
        </TabPane>
        <TabPane key={KEYS[1]} className={styles.tab} tab={<></>}>
          <BusinessForm form={businessForm} />
        </TabPane>
        <TabPane key={KEYS[2]} className={styles.tab} tab={<></>}>
          <OfficersForm form={officersForm} formValues={formValues} laborPartnerCheck={handleLaborCheckbox} />
        </TabPane>
        <TabPane key={KEYS[3]} className={styles.tab} tab={<></>}>
          <div className={styles.confirmationText}>
            <div>
              {laborPartnerApply ? t("success_labor_partner") : <span className={styles.boldText}>{t("success")}</span>}
            </div>
            <div className={styles.confirmEmail}>
              We will reach out to you at <span className={styles.boldText}>{formValues.current.ownerEmail}</span> for
              next steps.
            </div>
            {FEATURE_FLAGS.includes("laborPartnerApplication") && (
              <LaborPartnerCard
                laborPartnerApply={laborPartnerApply}
                toLaborForm={() => setActiveTabIdx(activeTabIdx + 1)}
              />
            )}
          </div>
        </TabPane>
        <TabPane key={KEYS[4]} className={styles.tab} tab={<></>}>
          {FEATURE_FLAGS.includes("laborPartnerApplication") && <LaborPartnerForm form={laborPartnerForm} />}{" "}
        </TabPane>
        <TabPane key={KEYS[5]} className={styles.tab} tab={<></>}>
          {FEATURE_FLAGS.includes("laborPartnerApplication") && (
            <div className={styles.laborAppConfirm}>
              <div>{t("labor_app_received")}</div>
              <div className={styles.details}>{t("labor_app_review")}</div>
              <div>{t("thank_you")}</div>
              <div>{t("powur_team")}</div>
            </div>
          )}
        </TabPane>
      </Tabs>
      <div className={sharedClasses.buttonContainer}>
        {!hideButton && (
          <Button onClick={handleActiveButtonClick} className={sharedClasses.joinButton} loading={!!isSubmitting}>
            {t(currentSection?.buttonTxt)}
          </Button>
        )}
        {activeTabIdx < 3 && (
          <Button onClick={handleClose} className={styles.cancelButton} type="link">
            {t("cancel")}
          </Button>
        )}
      </div>
    </Modal>
  );
};

EnterpriseProForm.propTypes = {
  // TODO: enumerate pageReferrer properties
  pageReferrer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  latestMessage: MessageEvent.types(),
  getEnterpriseProModalLaborFormSubmit: PropTypes.func,
  selectEnterpriseApplicationDetailId: PropTypes.number,
  clearLatestMessage: PropTypes.func,
  currentUser: User.types(),
  getUserLaborApplicationStatus: PropTypes.func,
  userStatus: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  setAlertMessageVisible: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({
    pageReferrer: "selectPageReferrer",
    latestMessage: "getLatestMessageEvt",
    requests: "getActiveRequests",
    selectEnterpriseApplicationDetailId: "selectEnterpriseApplicationDetailId",
    currentUser: "getCurrentUser",
    userStatus: "selectUserLaborApplicationStatus",
  }),
  getRdxActionMapper([
    "getEnterpriseProModalLaborFormSubmit",
    "clearLatestMessage",
    "getUserLaborApplicationStatus",
    "setAlertMessageVisible",
  ]),
)(EnterpriseProForm);
