// import content from "containers/Sales/marketing";
import tabs from "containers/Sales/marketing/tabs";
import { OnlineMarketingIcon } from "@icons";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";

const content = l(() => import("containers/Sales/marketing"));

const { MARKETING_VIEW, PREVIEW_USER } = permissionTypes;

export default {
  key: "marketing",
  title: "Marketing",
  path: "/marketing/:tab?",
  linkTarget: "/marketing",
  linkTitle: "Marketing",
  linkCategory: "Sales",
  icon: OnlineMarketingIcon,
  basePath: "marketing",
  requiredParams: ["tab"],
  optionalParams: [],
  tabs,
  content,
  sidebar: null,
  permission: [MARKETING_VIEW, PREVIEW_USER],
};
