export default {
  // general
  SETTING_UPDATE_SUCCESS: "Setting updated successfully.",
  SERVICE_AREA_UPDATE_SUCCESS: "Service area updated successfully.",
  SERVICE_AREA_REMOVE_SUCCESS: "Service area removed successfully.",
  SERVICE_AREA_CREATE_SUCCESS: "Service area created successfully.",
  ENTERPRISE_ADMIN_SETTINGS_UPDATE_SUCCESS: "Enterprise settings updated successfully.",
  POWUR_PAIR_WEIGHTS_UPDATE_SUCCESS: "Powur Pair weights updated successfully.",
  ADMIN_ADVANCE_SETTINGS_UPDATE_SUCCESS: "Pay advance settings updated successfully.",

  // errors
  ERROR_GETTING_SETTINGS_LIST: "There was an error retrieving settings",
  ERROR_UPDATING_SETTING: "There was an error updating this setting",
  ERROR_GETTING_SERVICE_AREAS_LIST: "There was an error retrieving service areas",
  ERROR_UPDATING_SERVICE_AREA: "There was an error updating service area",
  ERROR_REMOVING_SERVICE_AREA: "There was an error removing this service area",
  ERROR_CREATING_SERVICE_AREA: "There was an error creating new service area",
  ERROR_UPDATING_ENTERPRISE_ADMIN_SETTINGS: "There was an error updating enterprise admin settings",
  ERROR_GETTING_POWUR_PAIR_WEIGHTS: "There was an error retrieving Powur Pair weights",
  ERROR_UPDATING_POWUR_PAIR_WEIGHTS: "There was an error updating Powur Pair weights",
  ERROR_GETTING_ADMIN_VOLUME_TIER_SETTINGS: "There was an error getting enterprise volume tiers",
  ERROR_UPDATING_ADMIN_VOLUME_TIER_SETTINGS: "There was an error updating enterprise volume tiers",
  ERROR_GETTING_ADMIN_ADVANCE_SETTINGS: "There was an error retrieving pay advance settings",
  ERROR_CREATING_ADMIN_ADVANCE_SETTING: "There was an error creating pay advance setting",
  ERROR_UPDATING_ADMIN_ADVANCE_SETTING: "There was an error updating pay advance setting",
  ERROR_REMOVING_ADMIN_ADVANCE_SETTNGL: "There was an error deleting an existing advance setting",
};
