import React from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { HomeOwnerGradientIcon } from "components/Icons";
import CopyToClipBoard from "@mui-styled-components/CopyToClipboard";
import { ProjectBasicInfoWrapper, LeadTextWrapper, ProjectDetailText, linkStyles } from "./styles";

type ProjectBasicInfo = {
  projectId: number;
  leadName: string;
  address?: string | null;
  linkText?: string | null;
};

const ProjectBasicInfoComponent = ({ projectId, leadName, address = null, linkText = null }: ProjectBasicInfo) => {
  return (
    <ProjectBasicInfoWrapper>
      <Grid container wrap="nowrap">
        <Grid item paddingTop={1} paddingRight={0.75} paddingLeft={0.75}>
          <HomeOwnerGradientIcon />
        </Grid>
        <Grid item padding={1}>
          <LeadTextWrapper>
            {leadName} ({projectId})
          </LeadTextWrapper>
          {address && (
            <ProjectDetailText>
              {address} <CopyToClipBoard valueToCopy={address} />
            </ProjectDetailText>
          )}
          <ProjectDetailText>
            <Link to={{ pathname: `/admin-projects/${projectId}/summary` }} target="_blank" css={linkStyles}>
              {linkText}
            </Link>
          </ProjectDetailText>
        </Grid>
      </Grid>
    </ProjectBasicInfoWrapper>
  );
};

export default ProjectBasicInfoComponent;
