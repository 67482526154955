import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper, getRdxActionMapper } from "rdx/utils/propsMapping";
import _ from "lodash";
import Payout from "models/Payout";
import DateRangeNavigate from "components/Pickers/DateRange";
import SidebarFilters from "components/PlatformLayout/components/Sidebar/Filters";
import styles from "../../AdminFinancials.module.less";

const AmbassadorSidebar = (props) => {
  const {
    filters: { filters, sorts },
    navigate,
  } = props;

  const selects = useMemo(() => {
    const out = _.filter(filters, (f) => _.isArray(f.values));
    if (sorts && !_.isEmpty(sorts)) {
      out.push({ name: "sort", values: sorts, default: sorts[0] });
    }
    return out;
  }, [filters, sorts]);

  const filterKeys = useMemo(() => selects.map((s) => s.name), [selects]);
  const filterMenus = useMemo(() => {
    const label = (s, i) => {
      if (s.label) return s.label;
      return filterKeys[i].includes("_id") ? _.startCase(filterKeys[i].replace("_id", "")) : _.startCase(filterKeys[i]);
    };

    return selects.map((s, i) => {
      const out = {
        key: filterKeys[i],
        label: label(s, i),
        options: s.values.map((v) => ({
          key: _.isPlainObject(v) ? _.keys(v)[0] : v,
          label: _.isPlainObject(v) ? _.values(v)[0] : _.startCase(v).replace("M 2", "M2"), // special case, _.startCase converts "m2_date" to "M 2 Date"
          parentKey: filterKeys[i],
          default: s.default === v,
          index: i,
        })),
      };

      if (!s.default) {
        out.options.unshift({
          key: `${filterKeys[i]}-any`,
          label: "All",
          parentKey: filterKeys[i],
          default: true,
          index: i,
        });
      }

      return out;
    });
  }, [selects, filterKeys]);

  return (
    <>
      <DateRangeNavigate navigate={navigate} beginKey="start_day" endKey="end_day" label="Date Range" />
      <div className={styles.filters}>
        <SidebarFilters menus={filterMenus} navigate={navigate} />
      </div>
    </>
  );
};

AmbassadorSidebar.propTypes = {
  filters: Payout.filterTypes(),
  navigate: PropTypes.func,
};

export default connect(
  getRdxSelectionMapper({ filters: "getPayoutFilters" }),
  getRdxActionMapper([]),
)(AmbassadorSidebar);
