import { styled, CSSObject } from "@mui/material/styles";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { ToggleButtonGroup } from "@mui/material";
import Grid from "@mui/material/Grid";

import { cvar, mq } from "styles";

export const StyledToolbar = styled("div")(() => ({
  color: cvar("darkest-blue"),
  fontFamily: "Poppins",
  fontSize: "40px",
  lineHeight: "40px",
  fontWeight: 500,
  paddingBottom: "20px",
}));

export const StyledDatePicker = styled(StaticDatePicker)(() => ({
  "& .MuiCalendarPicker-root": {
    [mq.phoneOnly]: {
      width: "280px",
    },
    [mq.tabletUp]: {
      width: "320px",
    },
  },
  "& .MuiPickerStaticWrapper-content": {
    [mq.phoneOnly]: {
      minWidth: "280px",
    },
    [mq.tabletUp]: {
      minWidth: "320px",
    },
  },
  "& .MuiPickersCalendarHeader-root": {
    "& .MuiPickersCalendarHeader-labelContainer": {
      "& .MuiPickersCalendarHeader-label": {
        fontFamily: "Barlow",
        color: cvar("darkest-blue"),
        fontWeight: 600,
        fontSize: "14px",
      },
    },
  },
  "& .MuiCalendarPicker-viewTransitionContainer": {
    "& .MuiDayPicker-header": {
      "& span": {
        fontFamily: "Barlow",
        color: cvar("darkest-blue"),
        fontWeight: 600,
        fontSize: "14px",
      },
    },
  },
  "& .PrivatePickersSlideTransition-root": {
    minHeight: "230px",
  },
  "& .MuiDayPicker-monthContainer": {
    "& .MuiButtonBase-root": {
      fontFamily: "Barlow",
      color: cvar("blue-bayoux"),
      fontWeight: 500,
      fontSize: "14px",

      "&:hover": {
        background: cvar("cotton-boll"),
        color: cvar("darkest-blue"),
      },
    },
  },
  "& .MuiYearPicker-root": {
    "&:hover": {
      background: cvar("white"),
    },
    "& .PrivatePickersYear-root": {
      fontFamily: "Barlow",
      color: cvar("blue-bayoux"),
      fontWeight: 500,
      fontSize: "14px",

      "& button:hover": {
        background: cvar("cotton-boll"),
        color: cvar("darkest-blue"),
      },
    },
  },
  "& .Mui-selected": {
    background: `linear-gradient(270.54deg, ${cvar("picton-blue")} 49.88%, ${cvar("blue-ribbon")} 135.73%)`,
    color: `${cvar("white")} !important`,
    "&:hover": {
      color: `${cvar("darkest-blue")} !important`,
    },
  },
}));

const baseTimeStyles: CSSObject = {
  fontSize: "40px",
  fontWeight: 500,
  fontFamily: "Poppins",
  color: cvar("prussian-blue"),
};

export const ToolbarContainer = styled(Grid)(() => ({
  margin: "24px 30px 0px",
  [mq.phoneOnly]: {
    width: "250px",
  },
  [mq.tabletUp]: {
    width: "280px",
  },
}));

export const StyledInputWrapper = styled("span")(() => ({
  display: "inline-block",
  float: "left",
  [mq.phoneOnly]: {
    width: "80px",
  },
  [mq.tabletUp]: {
    width: "96px",
  },
}));

export const StyledInputLabel = styled("span")(() => ({
  textTransform: "uppercase",
  fontWeight: "700",
  color: cvar("nepal-blue"),
  fontSize: "11px",
  letterSpacing: "0.4px",
}));

export const StyledInputTime = styled("input")(() => ({
  ...baseTimeStyles,
  height: "60px",
  background: cvar("cotton-boll"),
  fontWeight: "500",
  lineHeight: "52px",
  textAlign: "center",
  borderRadius: "8px",
  border: `1px solid ${cvar("botticelli-blue")}`,
  "::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "&:focus": {
    background: `linear-gradient(white, white) padding-box, linear-gradient(270.54deg, ${cvar(
      "picton-blue",
    )} 49.88%, ${cvar("blue-ribbon")} 135.73%) border-box`,
    border: "2px solid transparent",
  },
  [mq.phoneOnly]: {
    width: "80px",
  },
  [mq.tabletUp]: {
    width: "96px",
  },
}));

export const StyledInputTimeColon = styled("span")(() => ({
  ...baseTimeStyles,
  height: "60px",
  width: "24px",
  fontWeight: "500",
  lineHeight: "52px",
  textAlign: "center",
  float: "left",
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  "& .MuiToggleButton-root": {
    height: "30px",
    width: "52px",
    background: cvar("cotton-boll"),
    color: cvar("nepal-blue"),
    borderRadius: "8px",
    border: `1px solid ${cvar("botticelli-blue")}`,
    fontFamily: "Poppins",
    fontSize: "16px",
  },
  "& .Mui-selected": {
    background: `linear-gradient(270.54deg, ${cvar("picton-blue")} 49.88%, ${cvar("blue-ribbon")} 135.73%)`,
    color: cvar("white"),
  },
}));
