import types from "rdx/modules/users/actionTypes";
import createAction from "rdx/utils/createAction";

export default {
  getUsersList: (payload) => createAction(types.GET_USERS_LIST_REQUEST, payload),
  setUsersList: (payload) => createAction(types.SET_USERS_LIST, payload),
  setDetailInUsersList: (payload) => createAction(types.SET_DETAIL_IN_USERS_LIST, payload),
  getRolesList: (payload) => createAction(types.GET_USER_ROLES_LIST_REQUEST, payload),
  setRolesList: (payload) => createAction(types.SET_USER_ROLES_LIST, payload),
  getUserDetails: (payload) => createAction(types.GET_USER_DETAILS_REQUEST, payload),
  setUserDetails: (payload) => createAction(types.SET_USER_DETAILS, payload),
  getMentorRatings: (payload) => createAction(types.GET_MENTOR_RATINGS_REQUEST, payload),
  getAdminMentorRatings: (payload) => createAction(types.GET_ADMIN_MENTOR_RATINGS_REQUEST, payload),
  setMentorRatings: (payload) => createAction(types.SET_MENTOR_RATINGS, payload),
  getUserName: (payload) => createAction(types.GET_USER_NAME_REQUEST, payload),
  setUserName: (payload) => createAction(types.SET_USER_NAME, payload),
  setDetailInUserDetails: (payload) => createAction(types.SET_DETAIL_IN_USER_DETAILS, payload),
  addPermissionToRole: (payload) => createAction(types.ADD_PERMISSION_TO_ROLE_REQUEST, payload),
  removePermissionFromRole: (payload) => createAction(types.REMOVE_PERMISSION_FROM_ROLE_REQUEST, payload),
  impersonateUser: (payload) => createAction(types.IMPERSONATE_USER_REQUEST, payload),
  logbackUser: (payload) => createAction(types.LOGBACK_USER_REQUEST, payload),
  updateUser: (payload) => createAction(types.UPDATE_USER_REQUEST, payload),
  addRoleToUser: (payload) => createAction(types.ADD_ROLE_TO_USER_REQUEST, payload),
  removeRoleFromUser: (payload) => createAction(types.REMOVE_ROLE_FROM_USER_REQUEST, payload),
  getUsersAutoComplete: (payload) => createAction(types.GET_USERS_AUTOCOMPLETE_REQUEST, payload),
  getTeamUsersAutoComplete: (payload) => createAction(types.GET_TEAM_USERS_AUTOCOMPLETE_REQUEST, payload),
  setUsersAutoComplete: (payload) => createAction(types.SET_USERS_AUTOCOMPLETE, payload),
  changeUserSponsor: (payload) => createAction(types.CHANGE_USER_SPONSOR_REQUEST, payload),
  changeUserManager: (payload) => createAction(types.CHANGE_USER_MANAGER_REQUEST, payload),
  changeUserCertificationDate: (payload) => createAction(types.CHANGE_USER_CERTIFICATION_DATE_REQUEST, payload),
  setUserAsComped: (payload) => createAction(types.SET_USER_AS_COMPED_REQUEST, payload),
  activateUserEnrollment: (payload) => createAction(types.ACTIVATE_USER_ENROLLMENT_REQUEST, payload),
  addNewRole: (payload) => createAction(types.ADD_NEW_ROLE_REQUEST, payload),
  updateRole: (payload) => createAction(types.UPDATE_ROLE_REQUEST, payload),
  deleteRole: (payload) => createAction(types.DELETE_ROLE_REQUEST, payload),
  readdUserToLms: (payload) => createAction(types.READD_USER_TO_LMS, payload),
  cancelSubscriptionByAdmin: (payload) => createAction(types.CANCEL_SUBSCRIPTION_BY_ADMIN, payload),
  banUser: (payload) => createAction(types.BAN_USER_REQUEST, payload),
  unbanUser: (payload) => createAction(types.UNBAN_USER_REQUEST, payload),
  renewSubscriptionByAdmin: (payload) => createAction(types.RENEW_SUBSCRIPTION_BY_ADMIN, payload),
  destroySubscriptionByAdmin: (payload) => createAction(types.DESTROY_SUBSCRIPTION_BY_ADMIN, payload),
  getLitmosLoginLink: (payload) => createAction(types.GET_LITMOS_LOGIN_LINK, payload),
  setLitmosLoginLink: (payload) => createAction(types.SET_LITMOS_LOGIN_LINK, payload),
  addNewPermission: (payload) => createAction(types.ADD_NEW_PERMISSION_REQUEST, payload),
  deletePermission: (payload) => createAction(types.DELETE_PERMISSION_REQUEST, payload),
  getPermissionsEntities: (payload) => createAction(types.GET_PERMISSIONS_ENTITIES_REQUEST, payload),
  createPermissionsEntity: (payload) => createAction(types.CREATE_PERMISSIONS_ENTITY_REQUEST, payload),
  getPermissionsOperations: (payload) => createAction(types.GET_PERMISSIONS_OPERATIONS_REQUEST, payload),
  createPermissionsOperation: (payload) => createAction(types.CREATE_PERMISSIONS_OPERATION_REQUEST, payload),
  updatePermission: (payload) => createAction(types.UPDATE_PERMISSION_REQUEST, payload),
  deletePermissionsEntity: (payload) => createAction(types.DELETE_PERMISSIONS_ENTITY_REQUEST, payload),
  deletePermissionsOperation: (payload) => createAction(types.DELETE_PERMISSIONS_OPERATION_REQUEST, payload),
  updatePermissionsEntity: (payload) => createAction(types.UPDATE_PERMISSIONS_ENTITY_REQUEST, payload),
  updatePermissionsOperation: (payload) => createAction(types.UPDATE_PERMISSIONS_OPERATION_REQUEST, payload),
  markTooltipViewed: (payload) => createAction(types.MARK_TOOLTIP_VIEWED_REQUEST, payload),
  getTeamsList: (payload) => createAction(types.GET_TEAMS_LIST_REQUEST, payload),
  setTeamsList: (payload) => createAction(types.SET_TEAMS_LIST, payload),
  createTeam: (payload) => createAction(types.CREATE_TEAM_REQUEST, payload),
  updateTeam: (payload) => createAction(types.UPDATE_TEAM_REQUEST, payload),
  deleteTeam: (payload) => createAction(types.DELETE_TEAM_REQUEST, payload),
  updateAdminUsersMentorRating: (payload) => createAction(types.UPDATE_ADMIN_USERS_MENTOR_RATING, payload),
  setMentorUserLookup: (payload) => createAction(types.SET_MENTOR_USER_LOOKUPS, payload),
  resetMentorUserLookup: (payload) => createAction(types.RESET_MENTOR_USER_LOOKUPS, payload),
  setUnsavedMentorRatings: (payload) => createAction(types.SET_UNSAVED_MENTOR_RATINGS, payload),
  certifyUser: (payload) => createAction(types.CERTIFY_USER, payload),
  getSellerApplicationAgreement: (payload) => createAction(types.GET_SELLER_APPLICATION_AGREEMENT_REQUEST, payload),
  setSellerApplicationAgreement: (payload) => createAction(types.SET_SELLER_APPLICATION_AGREEMENT, payload),
  getMarketingToken: (payload) => createAction(types.GET_MARKETING_TOKEN, payload),
  setMarketingToken: (payload) => createAction(types.SET_MARKETING_TOKEN, payload),
  upgradeAmbassador: (payload) => createAction(types.UPGRADE_AMBASSADOR_REQUEST, payload),
  updateDefaultAdvance: (payload) => createAction(types.UPDATE_DEFAULT_ADVANCE, payload),
  setUserPastDueHomeownerAppointment: (payload) => createAction(types.SET_USER_PAST_DUE_HOMEOWNER_APPOINTMENT, payload),
  // INSERTION_PT (for script, do not remove)
};
