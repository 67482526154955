import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./HeaderLogo.module.less";

const PowurLogo = (props) => {
  const { width, height, fill, hoverFill } = props;

  const [currentFill, setCurrentFill] = useState(fill);

  return (
    <div
      className={styles.container}
      onMouseOver={() => setCurrentFill(hoverFill)}
      onFocus={() => setCurrentFill(hoverFill)}
      onMouseOut={() => setCurrentFill(fill)}
      onBlur={() => setCurrentFill(fill)}
    >
      <svg
        className={styles.svg}
        width={width}
        height={height}
        viewBox="0 0 112 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path className={styles.path} d="M3.80713 9.20532V3.8125H9.19092L3.80713 9.20532Z" fill={currentFill} />
        <path className={styles.path} d="M3.80717 16.8342L0 13.0206L3.80717 9.20703V16.8342Z" fill={currentFill} />
        <path className={styles.path} d="M9.19092 3.81356L12.9981 0L16.8053 3.81356H9.19092Z" fill={currentFill} />
        <path
          className={styles.path}
          d="M15.8717 20.9961L12.2761 24.5978L8.68042 20.9961H15.8717Z"
          fill={currentFill}
        />
        <path className={styles.path} d="M12.998 18.4153V13.0195H18.3848L12.998 18.4153Z" fill={currentFill} />
        <path className={styles.path} d="M12.998 7.62891V13.0217H7.61426L12.998 7.62891Z" fill={currentFill} />
        <path className={styles.path} d="M9.19092 22.2278H3.80713V16.832L9.19092 22.2278Z" fill={currentFill} />
        <path className={styles.path} d="M22.1919 16.832V22.2278H16.8052L22.1919 16.832Z" fill={currentFill} />
        <path
          className={styles.path}
          d="M22.1921 9.20703L25.9993 13.0206L22.1921 16.8342V9.20703Z"
          fill={currentFill}
        />
        <path className={styles.path} d="M16.8052 3.8125H22.1919V9.20532L16.8052 3.8125Z" fill={currentFill} />
        <path
          className={styles.path}
          d="M31.3062 33.999V16.0781H37.6937C41.9916 16.0781 44.0813 17.9764 44.0813 21.8804V24.6148C44.0813 27.7137 41.9916 29.2222 37.6937 29.2222H33.5876V34.0019H31.3062V33.999ZM33.5876 27.1742H37.6966C40.4208 27.1742 41.8055 26.3126 41.8055 24.612V20.6855C41.8055 18.9849 40.4236 18.1233 37.6966 18.1233H33.5876V27.1742Z"
          fill={currentFill}
        />
        <path
          className={styles.path}
          d="M52.3866 29.2222C48.0887 29.2222 45.999 27.7165 45.999 24.6148V20.6855C45.999 17.5838 48.0887 16.0781 52.3866 16.0781C56.6845 16.0781 58.7742 17.5838 58.7742 20.6855V24.6148C58.7742 27.7137 56.6845 29.2222 52.3866 29.2222ZM52.3866 18.1233C49.6624 18.1233 48.2777 18.9849 48.2777 20.6855V24.6148C48.2777 26.3154 49.6596 27.177 52.3866 27.177C55.1109 27.177 56.4956 26.3154 56.4956 24.6148V20.6855C56.4927 18.9849 55.1109 18.1233 52.3866 18.1233Z"
          fill={currentFill}
        />
        <path
          className={styles.path}
          d="M74.8376 28.8776L70.7794 17.7985L66.7241 28.8776H64.3354L59.2253 16.0781H61.4814L65.5311 25.9171L69.5837 16.0781H71.978L76.0277 25.9171L80.0802 16.0781H82.3363L77.2262 28.8776H74.8376Z"
          fill={currentFill}
        />
        <path
          className={styles.path}
          d="M89.8295 29.2222C85.5316 29.2222 83.4419 27.7165 83.4419 24.6148V16.0781H85.7234V24.6148C85.7234 26.3154 87.1052 27.177 89.8323 27.177C92.5565 27.177 93.9412 26.3154 93.9412 24.6148V16.0781H96.2227V24.6148C96.2171 27.7137 94.1302 29.2222 89.8295 29.2222Z"
          fill={currentFill}
        />
        <path
          className={styles.path}
          d="M98.4111 28.8776V16.0781H104.799C109.018 16.0781 111.107 17.5301 111.186 20.5132H108.902C108.809 18.9284 107.433 18.1233 104.799 18.1233H100.69V28.8776H98.4111Z"
          fill={currentFill}
        />
      </svg>
    </div>
  );
};

PowurLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  hoverFill: PropTypes.string,
};

PowurLogo.defaultProps = {
  width: "117",
  height: "36",
  fill: "white",
  hoverFill: "var(--primary-lite)",
};

export default PowurLogo;
