import styled from "@emotion/styled";
import FormHelperText from "@mui/material/FormHelperText";
import { filterStyledProps } from "lib/helpers/filterStyledProps";
import { cvar } from "styles";

type HelperTextT = { error?: boolean };

export const HelperText = styled(FormHelperText, { shouldForwardProp: filterStyledProps(["error"]) })(
  ({ error = false }: HelperTextT) =>
    () => ({
      color: error ? cvar("persian-red") : cvar("black"),
      lineHeight: "10px",
    }),
);
