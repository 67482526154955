import React from "react";
import PropTypes from "prop-types";

const RightArrowLong = (props) => {
  const { fill, height, width } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 32 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 6.66406C0.447715 6.66406 -3.77205e-08 7.11178 0 7.66406C3.77205e-08 8.21635 0.447715 8.66406 1 8.66406L1 6.66406ZM30.9783 8.37117C31.3688 7.98064 31.3688 7.34748 30.9783 6.95695L24.6144 0.592992C24.2238 0.202468 23.5907 0.202468 23.2001 0.592992C22.8096 0.983517 22.8096 1.61668 23.2001 2.00721L28.857 7.66406L23.2001 13.3209C22.8096 13.7114 22.8096 14.3446 23.2001 14.7351C23.5907 15.1257 24.2238 15.1257 24.6144 14.7351L30.9783 8.37117ZM1 8.66406L30.2712 8.66406L30.2712 6.66406L1 6.66406L1 8.66406Z"
        fill={fill}
      />
    </svg>
  );
};

RightArrowLong.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

RightArrowLong.defaultProps = {
  fill: "white",
  height: "16",
  width: "32",
};

export default RightArrowLong;
