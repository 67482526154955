import component from "containers/StaticPages/education/submitRedirectPage/YesPage";
// import l from "@loadable/component";

// const component = l(() =>
//   import("containers/education/submitRedirectPage/YesPage"),
// );

export default {
  key: "education-qualify-yes",
  title: "Education: Qualify Yes",
  path: "/:user?/education/qualify-yes",
  pageName: "qualify_yes",
  linkTarget: "/education/qualify-yes",
  component,
};
