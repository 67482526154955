import { Button, Chip, styled, TextField } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

import { filterStyledProps } from "lib/helpers/filterStyledProps";

type FiltersButtonPropsT = {
  isContained?: boolean;
  isMobile?: boolean;
};

type NavigateButtonPropsT = {
  way?: "left" | "right";
};

type MobilePropsT = {
  isMobile?: boolean;
  isActive?: boolean;
};
type FilterOptionPropsT = {
  isSelected?: boolean;
  isMobile?: boolean;
};

export const DefaultFilterButton = styled(Button, { shouldForwardProp: filterStyledProps(["isMobile", "isActive"]) })(
  ({ isMobile, isActive }: MobilePropsT) =>
    ({ theme }) => ({
      height: "28px",
      width: isMobile ? "40px" : "148px",
      borderRadius: "4px",
      alignItems: "center",
      justifyContent: "left",
      boxShadow: "none",
      padding: 0,
      background: isActive ? theme.colors["banner-blue"] : theme.colors["cotton-boll"],
      ...(isMobile && { minWidth: "40px" }),
      "& span:first-of-type": {
        display: "flex",
        alignItems: "center",
        width: isMobile ? "40px" : "118px",
        height: "20px",
        color: isActive ? theme.colors.white : theme.colors["banner-blue"],
        textTransform: "capitalize",
        ...(isMobile && { justifyContent: "center" }),
        ...(!isMobile && { paddingLeft: "9px" }),
        fontWeight: "500",
      },
      "& span:nth-of-type(2)": {
        width: "30px",
        display: isMobile ? "none" : "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "&:hover": {
        background: isActive ? theme.colors["banner-blue-highlighted"] : theme.colors["cotton-boll-highlighted"],
        boxShadow: "none",
      },
      "&:focus": {
        boxShadow: "none",
      },
      "& .MuiTouchRipple-root": {
        display: "none",
      },
    }),
);

export const PopOverPaperStyles: SystemStyleObject = {
  width: "874px",
  height: "461px",
  borderRadius: "10px",
  boxShadow: "0px 2px 48px rgba(0, 0, 0, 0.2)",
  marginTop: "12px",
  marginLeft: "-40px",
};

export const LoadingOverlay = styled("div")(() => ({
  position: "absolute",
  width: "100%",
  pointerEvents: "none",
  display: "flex",
  alignSelf: "center",
  alignItems: "center",
  justifyContent: "center",
  bottom: "6px",
  height: "100%",
}));

export const FiltersHeaderTitle = styled("span")(({ theme }) => ({
  color: theme.colors["blue-ribbon"],
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "600",
  marginRight: "19px",
}));

export const FiltersHeaderStatistics = styled("span")(({ theme }) => ({
  color: theme.colors["darkest-blue"],
  fontSize: "12px",
}));

export const FiltersButton = styled(Button, { shouldForwardProp: filterStyledProps(["isContained", "isMobile"]) })(
  ({ isContained = false, isMobile }: FiltersButtonPropsT) =>
    ({ theme }) => ({
      height: "32px",
      textTransform: "capitalize",
      ...(isMobile && { border: `1px solid ${theme.colors["blue-ribbon"]}` }),
      color: isContained ? theme.colors.white : theme.colors["blue-ribbon"],
      fontSize: "12px",
      fontWeight: "600",
      background: isContained
        ? `linear-gradient(95.7deg, ${theme.colors["picton-blue"]} 12.17%, ${theme.colors["blue-ribbon"]} 93.24%);`
        : theme.colors.white,
      "&:disabled": {
        background: "#CDD5E1", // add this color to the color variables
        borderColor: "#CDD5E1",
        color: theme.colors.white,
      },
      "& .MuiTouchRipple-root": {
        display: "none",
      },
    }),
);

// shouldForwardProp exluding way prop from going down to the dom

export const NavigateButton = styled(Button, { shouldForwardProp: filterStyledProps(["way"]) })(
  ({ way = "left" }: NavigateButtonPropsT) =>
    ({ theme }) => ({
      minWidth: "26px",
      width: "26px",
      height: "100%",
      background: theme.colors["white-a65"],
      backdropFilter: "blur(2px)",
      position: "absolute",
      top: 0,
      ...(way === "left" ? { left: 0 } : { right: 0 }),
    }),
);

export const FilterTitle = styled("span")(({ theme }) => ({
  fontWeight: "600",
  color: theme.colors["darkest-blue"],
  textTransform: "uppercase",
}));

export const AppliedFilterChip = styled(Chip)(({ theme }) => ({
  height: "28px",
  borderColor: theme.colors["blue-ribbon"],
  fontFamily: "Barlow",
  fontWeight: "600",
  color: theme.colors["blue-ribbon"],
  fontSize: "14px",
  marginRight: "16px",
  marginTop: "16px",
  "& svg": {
    width: "14px",
    height: "14px",
    fill: theme.colors["blue-ribbon"],
  },
}));
export const FilterSet = styled(Chip)(({ theme }) => ({
  height: "28px",
  borderRadius: "6px",
  borderColor: theme.colors["blue-ribbon"],
  background: theme.colors["cotton-boll"],
  color: theme.colors["blue-ribbon"],
  fontSize: "14px",
  fontWeight: "600",
  marginRight: "16px",
  "& svg": {
    width: "12px",
    height: "12px",
    "& > path": {
      fill: theme.colors["blue-ribbon"],
    },
  },
  "&:hover": {
    background: theme.colors["cotton-boll"],
  },
}));

export const FiltersWrapper = styled("div")(({ isMobile }: MobilePropsT) => ({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  overflowX: "auto",
  overflowY: "hidden",
  ...(isMobile && { flexDirection: "column", maxHeight: "610px", overflowY: "auto", display: "block" }),
  width: "100%",
  ...(!isMobile && { maxWidth: "874px", height: "calc(100% - 10px)" }),
  "&::-webkit-scrollbar": {
    height: "5px",
    ...(isMobile && { display: "none" }),
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.colors["graham-grey"],
    borderRadius: "15px !important",
  },
}));

export const FilterWrapper = styled("div")(({ isMobile }: MobilePropsT) => () => ({
  display: "inline-block",
  ...(!isMobile && { minWidth: "178px", marginLeft: "25px" }),
  width: isMobile ? "100%" : "178px",
  height: isMobile ? "auto" : "calc(100% - 14px)",
  overflowY: "hidden",
  "&:last-child": {
    ...(!isMobile && { marginRight: "40px" }),
  },
}));

export const FilterOptions = styled("div")(({ isMobile }: MobilePropsT) => ({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  height: isMobile ? "auto" : "180px",
  ...(!isMobile && { paddingBottom: "16px" }),
  width: "100%",
  "&::-webkit-scrollbar": {
    width: "5px!important",
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.colors["graham-grey"],
    borderRadius: "15px !important",
  },
}));

export const FilterOption = styled("span")(({ isSelected = false, isMobile }: FilterOptionPropsT) => ({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  width: isMobile ? "100%" : "169px",
  minHeight: isMobile ? "40px" : "32px",
  fontWeight: 600,
  paddingLeft: "11px",
  ...(!isMobile && { borderRadius: "3px", marginBottom: "6px" }),
  color: isSelected ? theme.colors.white : theme.colors["blue-ribbon"],
  background: isSelected ? theme.colors["blue-ribbon"] : theme.colors["ma-white"],
}));

export const EllipsisText = styled("span")({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
});

export const MobileFooter = styled("div")({
  position: "absolute",
  bottom: 0,
  left: 0,
  height: "250px",
  width: "100%",
  background: "rgba(255, 255, 255, 0.78)",
  backdropFilter: "blur(10px)",
});

export const StyledNameField = styled(TextField)(({ theme }) => ({
  height: "32px",
  "& .MuiInputBase-root": {
    height: "100%",
    "& > fieldset": {
      borderColor: theme.colors["botticelli-blue"],
    },
    "& > input": {
      color: theme.colors["blue-ribbon"],
      "&::placeholder": {
        color: theme.colors["graham-grey"],
      },
    },
  },
}));

export const StyledFilterSetModalHeaderText = styled("span")(({ theme }) => ({
  display: "inline-block",
  color: theme.colors["blue-ribbon"],
  fontWeight: "700",
  fontSize: "18px",
}));
