import React from "react";

const SchedulingInstall = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16.9316" cy="16" r="16" fill="var(--dark-blue)" />
      <path
        d="M22.7623 22.068C21.8318 22.9051 20.8183 22.5108 20.4136 21.9712L17.1034 18.2078L18.1376 16.9626L17.5323 16.3365L18.3002 15.5409L22.6758 19.6398C23.4056 20.3109 23.3814 21.5112 22.7623 22.068ZM13.4023 21.9643C12.5271 22.9086 11.5033 22.5177 11.0605 22.0611C10.7423 21.7325 10.1301 20.6983 11.1539 19.6433L15.9757 15.3715L17.5392 16.9868L13.4023 21.9643ZM13.3296 13.9118C13.3296 13.9118 12.1328 14.2715 11.2196 13.3272C10.8011 12.8948 10.5763 12.3137 10.5797 11.7153L11.7731 12.9502L13.3919 12.3933L13.9315 10.7226L12.7381 9.49118C13.3123 9.4808 13.8796 9.71601 14.3016 10.1518C15.2321 11.11 14.8689 12.331 14.8689 12.331L17.3351 14.6416L16.6122 15.3887L16.0069 14.7627L14.9208 15.7243L13.3296 13.9118ZM21.7592 10.6984L22.067 11.0166L17.2244 16.0183L16.9166 15.7001L21.7592 10.6984ZM22.2261 9.91663L23.0978 9.52922L23.1981 9.62953L22.8245 10.5289L22.4198 10.7399L22.0255 10.3317L22.2261 9.91663ZM22.9629 19.3043L18.608 15.2262L22.5063 11.2034L23.1635 10.8644L23.7135 9.53268L23.1946 9L21.9079 9.57073L21.5793 10.2487L17.6395 14.3165L15.3393 12.1615C15.4707 11.3071 15.2044 10.4424 14.6094 9.8267C13.9799 9.17987 13.046 8.82705 11.825 9.17987L13.4368 10.8436L13.0529 12.037L11.8976 12.4348L10.2857 10.771C10.2857 10.771 9.71152 12.4071 10.9118 13.6454C11.5067 14.2611 12.3473 14.5378 13.174 14.3995L14.5887 16.0114L10.8772 19.2974C9.73228 20.4147 10.175 21.7706 10.7596 22.3759C11.7973 23.4482 13.2535 22.9293 13.7412 22.2548L16.8197 18.5468L20.085 22.2652C21.2299 23.5104 22.5617 22.9086 23.0701 22.3828C24.0317 21.3901 23.7135 19.9719 22.9629 19.3043Z"
        fill="white"
      />
    </svg>
  );
};

export default SchedulingInstall;
