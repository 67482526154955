import { SettingsIcon } from "@icons";
import l from "@loadable/component";
import { permissionTypes } from "hooks/usePermissions";
import SessionClient from "util/SessionClient";

const content = l(() => import("containers/Modules"));

const { ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN } = permissionTypes;
const session = new SessionClient();

export default {
  key: "modules",
  title: `${session?.user?.props?.enterpriseName || ""} Settings`,
  path: "/modules/:tab?",
  exact: true,
  linkTarget: "/modules",
  linkTitle: "Settings",
  linkCategory: "Enterprise",
  icon: SettingsIcon,
  basePath: "modules",
  requiredParams: [],
  optionalParams: [],
  tabs: null,
  permission: [ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN],
  content,
  sidebar: null,
};
