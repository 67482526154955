import React from "react";
import { Box, styled, SxProps, Theme } from "@mui/material";
import BlockerIcon from "./BlockerIcon";

type FilledBlockerIconProps = {
  sx: SxProps<Theme>;
  size?: number;
};

const DEFAULT_CONTAINER_SIZE = 24;
const ICON_RATIO = 2 / 3;

export default function FilledBlockerIcon({ sx, size = DEFAULT_CONTAINER_SIZE }: FilledBlockerIconProps) {
  const iconSize = (size * ICON_RATIO).toString();

  return (
    <Container sx={{ width: `${size}px`, height: `${size}px`, ...sx }}>
      <BlockerIcon width={iconSize} height={iconSize} />
    </Container>
  );
}

const Container = styled(Box)(({ theme }) => ({
  background: theme.colors.danger,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
})) as typeof Box;
