import PropTypes from "prop-types";
import { store } from "rdx/configureStore";
import actions from "rdx/actions";
import { Base } from "./Base";

const { updateMentorRating } = actions;

export default class MentorRating extends Base {
  updateRating = (payload) => {
    store.dispatch(updateMentorRating({ id: this.props.id, ...payload }));
  };

  // Must send bump type that matches one in the System Settings (next_rating_delay, inital_delay, user_delay) for rdx message to work properly. Failure to send a match will default on the BE to next_rating_delay.
  deferRating = (bump) => {
    store.dispatch(updateMentorRating({ id: this.props.id, bump }));
  };

  closeRating = () => {
    store.dispatch(updateMentorRating({ id: this.props.id, active: false }));
  };

  static types() {
    return PropTypes.shape({
      id: PropTypes.number,
      rating: PropTypes.number,
      mentorId: PropTypes.number,
      menteeId: PropTypes.number,
      projectId: PropTypes.number,
      eventType: PropTypes.oneOf(["proposal", "contract", "install"]),
      notes: PropTypes.string,
      delayUntil: PropTypes.string,
      active: PropTypes.bool,
      project: PropTypes.shape({
        id: PropTypes.number,
        city: PropTypes.string,
        state: PropTypes.string,
        lead: PropTypes.shape({
          id: PropTypes.number,
          fullName: PropTypes.string,
          email: PropTypes.string,
          phone: PropTypes.string,
        }),
      }),
      mentor: PropTypes.shape({
        id: PropTypes.number,
        fullName: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        avatar: PropTypes.shape({
          large: PropTypes.string,
          thumb: PropTypes.string,
          retina: PropTypes.string,
          preview: PropTypes.string,
        }),
        totalRatingCount: PropTypes.number,
      }),
    });
  }
}
