import React from "react";
import PropTypes from "prop-types";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSession } from "hooks/useSession";

import User from "models/User";
import AlertButton from "components/Buttons/AlertButton";

import styles from "./PlatformPageHeader.module.less";

enum Certification {
  ENTERPRISE_OWNER = "enterprise_owner",
  ENTERPRISE_SEATED_USER = "enterprise_seated_user",
  SETTER = "tier_1",
  SALES_PRO = "tier_2",
  CLOSER = "tier_3",
}

const ActivateButton = ({ updateActivateAcctBtnClicked, currentUser }) => {
  const history = useHistory();

  const { session, needsCloserCertification } = useSession();
  const { backOfficeAccess, isEnterpriseProOwner, isEnterpriseProSeatedUser } = session;

  const certificationType = (): Certification => {
    if (needsCloserCertification) {
      return Certification.CLOSER;
    }

    if (isEnterpriseProOwner) {
      return Certification.ENTERPRISE_OWNER;
    }

    if (isEnterpriseProSeatedUser) {
      return Certification.ENTERPRISE_SEATED_USER;
    }

    return Certification.SETTER;
  };

  const getButton = () => {
    if (options[backOfficeAccess]) {
      return options[backOfficeAccess];
    }

    if (needsCloserCertification) {
      return options.needs_closer_certification;
    }

    return null;
  };

  const options = {
    needs_certification: {
      text: "Get Started",
      onClick: () => session.user?.getLitmosLoginLink(certificationType()),
    },
    needs_closer_certification: {
      text: "Get Certified",
      onClick: () => session.user?.getLitmosLoginLink(certificationType()),
    },
    needs_activation: {
      text: "Activate Your Account",
      onClick: () => {
        if (currentUser.manualKindSelected) {
          updateActivateAcctBtnClicked();
        }
        history.push(`/getting-started/account-types${currentUser.manualKindSelected ? "/payment" : ""}`);
      },
    },
  };

  const button = getButton();
  if (!button) return null;

  return (
    <div className={styles.activateButtonContainer}>
      <AlertButton width={230} buttonText={button.text} handleClick={button.onClick} />
    </div>
  );
};

ActivateButton.propTypes = {
  updateActivateAcctBtnClicked: PropTypes.func,
  currentUser: User.types(),
};

export default connect(
  getRdxSelectionMapper({
    currentUser: "getCurrentUser",
  }),
  getRdxActionMapper(["updateActivateAcctBtnClicked"]),
)(ActivateButton);
