type ProjectPreSalesT = {
  GET_PROJECTS_PRE_SALES: "[projectPreSales] GET Pre Sales Projects";
  GET_SELECTED_PRE_SALES_PROJECT: "[projectPreSales] GET Selected Pre Sales Project";
  SET_PROJECTS_PRE_SALES: "[projectPreSales] SET Pre Sales Projects";
  SET_SELECTED_PRE_SALES_PROJECT: "[projectPreSales] SET Selected Pre Sales Project";
  UPDATE_PRE_SALES_PROJECT: "[projectPreSales] UPDATE Pre Sales Project";
  RESET_PROJECTS_PRE_SALES: "[projectPreSales] RESET Pre Sales Projects";
  UPDATE_CRM_ACTIVITY_IN_PRE_SALES_LIST: "[projectPreSales] UPDATE Crm Activity in Pre Sales Project List";
};

const projectPreSales: ProjectPreSalesT = {
  GET_PROJECTS_PRE_SALES: "[projectPreSales] GET Pre Sales Projects",
  GET_SELECTED_PRE_SALES_PROJECT: "[projectPreSales] GET Selected Pre Sales Project",
  SET_PROJECTS_PRE_SALES: "[projectPreSales] SET Pre Sales Projects",
  SET_SELECTED_PRE_SALES_PROJECT: "[projectPreSales] SET Selected Pre Sales Project",
  UPDATE_PRE_SALES_PROJECT: "[projectPreSales] UPDATE Pre Sales Project",
  RESET_PROJECTS_PRE_SALES: "[projectPreSales] RESET Pre Sales Projects",
  UPDATE_CRM_ACTIVITY_IN_PRE_SALES_LIST: "[projectPreSales] UPDATE Crm Activity in Pre Sales Project List",
  // INSERTION_PT (for script, do not remove)
};

export default projectPreSales;
