import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";
import useWindowResize from "hooks/useWindowResize";
// ICONS:
import OwnerIcon from "components/Icons/RoleIcons/OwnerIcon";
import ManagerIcon from "components/Icons/RoleIcons/ManagerIcon";
import SupportIcon from "components/Icons/RoleIcons/SupportIcon";
import LeadGeneratorIcon from "components/Icons/RoleIcons/LeadGeneratorIcon";

import styles from "./BuildYourTeam.module.less";

const BuildYourTeam = () => {
  const { t } = useTranslation(["enterprise_join"]);
  const { width } = useWindowResize();

  const colSpan = width > 900 ? 6 : 12;

  const roles = [
    {
      role: t("build_your_team.roles.owner"),
      icon: <OwnerIcon />,
    },
    {
      role: t("build_your_team.roles.manager"),
      icon: <ManagerIcon />,
    },
    {
      role: t("build_your_team.roles.support"),
      icon: <SupportIcon />,
    },
    {
      role: t("build_your_team.roles.lead_generator"),
      icon: <LeadGeneratorIcon />,
    },
  ];

  return (
    <div className={styles.buildTeamCard}>
      <div className={styles.headerContainer}>
        <div className={styles.sectionTitle}>{t("build_your_team.section_title")}</div>
        <div className={styles.line} />
        <div className={styles.description}>{t("build_your_team.title")}</div>
      </div>
      <div>
        <Row gutter={[16, 16]} className={styles.rolesContainer}>
          {roles.map((r) => {
            return (
              <Col key={r.role} span={colSpan}>
                <div className={styles.role}>
                  <div className={styles.imgContainer}>{r.icon}</div>
                  <div className={styles.text}>{r.role}</div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default BuildYourTeam;
