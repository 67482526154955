import { all, put, select } from "redux-saga/effects";
import selectors from "rdx/modules/tickets/selectors";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import messages from "rdx/modules/tickets/messages";
import * as _ from "lodash";

function* deleteMessage(action) {
  const params = {
    ...action.payload,
  };
  const { success, data, error } = yield* makeRequest.delete(`/messages/${params.id}`, params);

  if (success && data) {
    const replies = { ...(yield select(selectors.getProjectMessageReplies)) };
    const currentMessage = params.parent_id ? yield select(selectors.getSelectedMessage) : {};

    const currentPMList = yield select(selectors.getProjectMessageList);
    const newPMList = currentPMList.filter((msg) => msg.id !== params.id);

    const currentIBList = yield select(selectors.getInboxList);
    const newIBList = currentIBList.filter((msg) => msg.id !== params.id);

    if (params.parent_id) {
      replies[params.parent_id] = replies[params.parent_id].filter((r) => r.id !== params.id);

      newPMList.forEach((msg) => {
        const newMsg = msg;
        if (msg.id === params.parent_id) {
          newMsg.childCount -= 1;
          return newMsg;
        }
        return msg;
      });
      currentMessage.childCount -= 1;

      yield all([put(actions.setProjectMessageReplies({ replies }))]);
    }

    yield all([
      put(actions.setSelectedMessageDetails(currentMessage)),
      put(
        actions.setProjectMessageList({
          list: newPMList,
        }),
      ),
      put(
        actions.setInboxList({
          list: newIBList,
        }),
      ),
      put(
        actions.newSuccessEvent({
          message: messages.DELETE_PROJECT_MESSAGE_SUCCESS,
          // the payload here is to trigger closing the drawer if the deleted message has no parentId
          payload: {
            closeDrawer: _.isEmpty(currentMessage),
          },
        }),
      ),
    ]);
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_DELETING_PROJECT_MESSAGE,
    });
  }
  return null;
}

export default deleteMessage;
