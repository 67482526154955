import PropTypes from "prop-types";
import { store } from "rdx/configureStore";
import actions from "rdx/actions";
import { Base } from "./Base";

export default class Phase extends Base {
  update = ({ title, active }) => {
    const { updatePhase } = actions;
    store.dispatch(
      updatePhase({
        id: this.props.id,
        title,
        active,
      }),
    );
  };

  removeMilestone = ({ milestoneId }) => {
    const { removePhaseMilestone } = actions;
    store.dispatch(
      removePhaseMilestone({
        phaseId: this.props.id,
        milestoneId,
      }),
    );
  };

  updateMilestoneRanks = (milestones) => {
    const { updateMilestoneRanks } = actions;
    store.dispatch(
      updateMilestoneRanks({
        phaseId: this.props.id,
        milestones,
      }),
    );
  };

  static types() {
    return PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      stages: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          rank: PropTypes.number,
          milestones: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              at: PropTypes.string,
              data: PropTypes.arrayOf(
                PropTypes.shape({
                  id: PropTypes.string,
                  name: PropTypes.string,
                  type: PropTypes.string,
                  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                }),
              ),
            }),
          ),
        }),
      ),
    });
  }
}
