/* eslint-disable jsx-a11y/mouse-events-have-key-events */
// React
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import { useLocation } from "react-router-dom";

import { parse } from "query-string";
import { clone, isEmpty, isArray } from "lodash";
import { Button, Input, Form, Dropdown } from "antd";
import { Drawer, Button as MUIButton } from "@mui/material";
import dayjs from "dayjs";
import { states } from "lib/misc/states";
import ApiClient from "util/ApiClient";

import useModalControls from "hooks/useModalControls";
import useDebounce from "hooks/useDebounce";
import useWindowResize from "hooks/useWindowResize";
import { cvar } from "styles";

import CloseIcon from "components/Icons/CancelIconFilled";
import { MagnifyingGlassIcon, FilterIcon, DownCaretFilled } from "components/Icons";
import GeneralSearch from "../GeneralSearch";
import FilterMenu from "./components/FilterMenu";
import FilterByDateButton from "./components/FilterByDateButton";
import SaveFilterSetModal from "./components/SaveFilterSetModal";
import styles from "./SearchFilterCSV.module.less";
import roleTypes from "./roleTypes";
import Filters from "../NewFilters";
import { newFiltersPaths } from "./tempListForNewFilters";

const SearchFilterCSV = (props) => {
  const {
    sortItems,
    navigate,
    override,
    getFilterSetsByView,
    filterSets,
    ledgerCsv,
    getLedgerCsv,
    getEarningsCsv,
    getEnterpriseLedgerCsv,
    getLedgerCsvReport,
    setLedgerCsv,
    getActivityLogCsv,
    getPreferredPartnerCsv,
    activityLogCsv,
    noSearch,
    noCSV,
    activityLog,
    preferredPartner,
    customSearch,
    enterprise,
    additionalButtonContent,
    searchPlaceholder,
    toggleFilter,
    setMenuExpanded,
    isLoading,
    showDateFilterButton,
  } = props;

  const { width } = useWindowResize();

  const tablet = width <= 815;
  const mobile = width <= 561;

  const { search: urlSearch, pathname } = useLocation();
  const queryParams = parse(urlSearch);
  const api = new ApiClient();

  const location = useLocation();
  const consultantView = location.pathname.includes("/earnings");
  const viewWithFilterSets = !location.pathname.includes("/admin-solar-proposals/funding");

  const [filterCount, setFilterCount] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [pending, setPending] = useState(false);
  const [search, setSearch] = useState(queryParams?.q);
  const debouncedSearch = useDebounce(search, 1500);
  const preventNextSearch = useRef(false);
  const [smallSearchOpen, setSmallSearchOpen] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [mobileFilterToggle, setMobileFilterToggle] = useState(false);
  const [hovered, setHovered] = useState(false);

  const getStyles = () => {
    const filterStyles = [styles.filter];

    if (hovered && filterCount < 1) {
      filterStyles.push(styles.filterHover);
    }
    if (!hovered && filterCount >= 1) {
      filterStyles.push(styles.filterActive);
    }
    if (hovered && filterCount >= 1) {
      filterStyles.push(styles.filterHoverActive);
    }
    return filterStyles.join(" ");
  };

  const getIconFill = () => {
    if ((hovered && filterCount < 1) || (!hovered && filterCount < 1)) {
      return cvar("dark-blue");
    }
    return "white";
  };

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (!preventNextSearch.current) {
      handleSearch(debouncedSearch);
    }
    preventNextSearch.current = false;
  }, [debouncedSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (viewWithFilterSets) {
      getFilterSetsByView({ view: pathname });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (setMenuExpanded) setMenuExpanded(activeFilters.length > 0);
  }, [activeFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  // Include any filter types related to users, where we should display the user's name instead of id.
  const userFilterTypes = [
    "lead_owner_id",
    "referrer_id",
    "user_id",
    "consultant_id",
    "mentor_id",
    "actor_id",
    "user_in_action_id",
    "object_id",
    "assignee_id",
  ];

  // Include any filter types with two options that should toggle, works when toggleFilter prop is set to true
  const toggleFilterTypes = ["status", "funding_partner"];

  const findAndSetFilters = () => {
    // sets filter count and active filters
    const out = clone(queryParams);
    let totalCount = 0;
    const dateReg = /^\d{4}[./-]\d{2}[./-]\d{2}$/;

    const getType = (currentValue) => {
      const isDate = !isArray(out[currentValue]) && out[currentValue].match(dateReg);

      if (isDate) {
        return [
          new Date(out[currentValue]).toLocaleDateString("en-US", {
            timeZone: "UTC",
          }),
        ];
      }
      if (currentValue === "role_type") {
        const roles = out[currentValue].split(",").map((role) => roleTypes[role]);
        return roles;
      }
      return isArray(out[currentValue]) ? out[currentValue] : out[currentValue].split(",");
    };

    const active = Object.keys(out).reduce((accumulator, currentValue) => {
      if (
        currentValue !== "q" &&
        currentValue !== "s_q" &&
        currentValue !== "consultant_type" &&
        currentValue !== "p" &&
        currentValue !== "sort" &&
        currentValue !== "provider_lead_id" &&
        out[currentValue]
      ) {
        const values = getType(currentValue);

        totalCount += values.length;

        const filters = {
          type: currentValue,
          values: isArray(out[currentValue]) ? out[currentValue] : [out[currentValue]],
          labels: userFilterTypes.includes(currentValue) ? [] : values,
        };
        const newAcc = [...clone(accumulator), filters];
        return newAcc;
      }

      return accumulator;
    }, []);

    setActiveFilters(active);
    setFilterCount(totalCount);

    const usersToFetch = active.filter((a) => userFilterTypes.includes(a.type)).map((act) => act.values);

    if (!isEmpty(usersToFetch)) {
      api.get("/users/super_lite_index", { users: usersToFetch }).then((res) => {
        const newActiveFilters = active.map((af) => {
          const afClone = clone(af);
          res.body.users.forEach((r) => {
            if (afClone.values.includes(r.id.toString())) {
              afClone.labels = [...afClone.labels, r.full_name];
            }
          });
          return afClone;
        });

        setActiveFilters(newActiveFilters);
      });
    }
  };

  useEffect(() => {
    findAndSetFilters();
  }, [urlSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const getReport = (interval, reportId) => {
    setIntervalId(
      setInterval(() => {
        if (reportId) getLedgerCsvReport({ reportId });
      }, interval),
    );
  };

  useEffect(() => {
    if (ledgerCsv?.url && pending) {
      setPending(false);
      clearInterval(intervalId);
      window.open(ledgerCsv.url, "_blank");
    } else if (ledgerCsv?.id && !intervalId) {
      getReport(2000, ledgerCsv.id);
    }
  }, [ledgerCsv]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activityLogCsv?.url && pending) {
      setPending(false);
      window.open(activityLogCsv.url, "_blank");
    }
  }, [activityLogCsv]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = () => {
    if (debouncedSearch) {
      navigate({ q: debouncedSearch });
    } else {
      clearSearch();
    }
  };

  const getCsv = (params) => {
    if (enterprise) return getEnterpriseLedgerCsv(params);
    if (consultantView) return getEarningsCsv(params);
    if (activityLog) return getActivityLogCsv(params);
    if (preferredPartner) return getPreferredPartnerCsv(params);
    return getLedgerCsv(params);
  };

  const clearSearch = () => {
    navigate({ q: null });
  };

  const updateFilters = (type, filterOption) => {
    const dateReg = /^\d{4}[./-]\d{2}[./-]\d{2}$/;
    const isDate = queryParams[type]?.match(dateReg);

    const tempFilter = isDate
      ? [
          new Date(queryParams[type]).toLocaleDateString("en-US", {
            timeZone: "UTC",
          }),
        ]
      : queryParams[type]?.split(",");
    const out = clone(queryParams);

    if (tempFilter) {
      if (!filterOption) {
        out[type] = null;
      } else if (tempFilter.includes(filterOption.toString())) {
        out[type] = tempFilter.filter((f) => f !== filterOption.toString()).join(",");
        if (!out[type]) {
          out[type] = null;
        }
      } else if (toggleFilter && toggleFilterTypes.includes(type)) {
        out[type] = filterOption;
      } else {
        out[type] = out[type].concat(`,${filterOption}`);
      }
    } else {
      out[type] = filterOption;
    }
    navigate(out);
  };

  const createCsv = () => {
    setLedgerCsv({});
    getCsv(queryParams);
    setPending(true);
  };

  const { visible: filterSetModalVisible, open: filterSetModalOpen, close: filterSetModalClose } = useModalControls();

  const handleCloseFilterMobileDrawer = () => setMobileFilterToggle((prev) => !prev);

  const menu = (
    <FilterMenu
      navigate={navigate}
      override={override}
      sortItems={sortItems}
      filterSets={filterSets}
      filterSetModalOpen={filterSetModalOpen}
      updateFilters={updateFilters}
      closeDrawer={handleCloseFilterMobileDrawer}
    />
  );

  const tagDisplayValue = (tagValue, activeFilter) => {
    if (activeFilter.type === "credits") {
      return activeFilter.type;
    }
    if (activeFilter.type === "state") {
      const filteredList = states.filter((s) => s.short === tagValue);
      return filteredList[0].long;
    }
    if (activeFilter.type === "change_id") {
      const changeTypes = sortItems.find((el) => el.type === "change_id");
      try {
        const changeType = changeTypes.options.find((el) => el[tagValue]);
        return changeType[tagValue];
      } catch (error) {
        return "loading...";
      }
    }
    if (activeFilter.type === "to") {
      const toLabel = activeFilter.values.find((v) => v === tagValue);
      const displayToLabel = dayjs(toLabel).format("MM-DD-YYYY");
      return displayToLabel;
    }
    if (activeFilter.type === "from") {
      const fromLabel = activeFilter.values.find((v) => v === tagValue);
      const displayFromLabel = dayjs(fromLabel).format("MM-DD-YYYY");
      return displayFromLabel;
    }
    if (activeFilter.type === "account_type") {
      const accountTypes = sortItems.find((el) => el.type === "account_type");
      const changeType = accountTypes.options.find((el) => el[tagValue]);
      return changeType[tagValue];
    }
    return tagValue;
  };

  const csvPreText = () => {
    if (tablet) return "";

    return pending ? "Downloading" : "Create";
  };

  return (
    <div className={styles.searchFilterCSV}>
      <Form initialValues={{ search }}>
        <div className={styles.mainRow}>
          {additionalButtonContent}
          {!noSearch && !customSearch && (
            <GeneralSearch
              setSmallSearchOpen={setSmallSearchOpen}
              mobile={mobile}
              search={search}
              newSearchBar={newFiltersPaths.includes(location.pathname)}
              smallSearchOpen={smallSearchOpen}
              setSearch={setSearch}
              placeholder={searchPlaceholder}
              debouncedSearch={debouncedSearch}
            />
          )}
          {customSearch && <div className={styles.customSearch}>{customSearch}</div>}
          {!newFiltersPaths.includes(location.pathname) && sortItems && !mobile && (
            <Dropdown dropdownRender={() => menu} placement="bottomLeft" trigger={["click"]}>
              <div
                className={getStyles()}
                onMouseEnter={() => {
                  setHovered(true);
                }}
                onMouseLeave={() => {
                  setHovered(false);
                }}
              >
                <div className={styles.left}>
                  <div className={styles.icon}>
                    <FilterIcon fill={getIconFill()} />
                  </div>
                </div>
                <div className={styles.right}>
                  {tablet && filterCount > 0 && <div className={styles.number}>{filterCount}</div>}
                  {!tablet && (
                    <>
                      <div className={styles.line} />
                      <div className={styles.downCaret}>
                        <DownCaretFilled fill={getIconFill()} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Dropdown>
          )}
          {!newFiltersPaths.includes(location.pathname) && sortItems && mobile && (
            <>
              <MUIButton
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "28px",
                  height: "28px",
                  background: "var(--table-edit-mode)",
                  boxShadow: "0px 0.5px 2px rgb(0 0 0 / 15%)",
                  borderRadius: "3px",
                  transition: " 0.2s all",
                  marginTight: "6px",
                }}
                onClick={handleCloseFilterMobileDrawer}
              >
                <FilterIcon />
                {!tablet && <span>Filters</span>}
              </MUIButton>
              <Drawer elevation={18} hideBackdrop open={mobileFilterToggle} anchor="bottom">
                {menu}
              </Drawer>
            </>
          )}
          {newFiltersPaths.includes(location.pathname) && sortItems && (
            <Filters sortItems={sortItems} isLoading={isLoading} />
          )}
          {showDateFilterButton && <FilterByDateButton />}
          {!noCSV && (
            <Button className={styles.csvButton} onClick={createCsv} disabled={pending}>
              {csvPreText} CSV
            </Button>
          )}
        </div>
        {mobile && smallSearchOpen && (
          <div className={styles.smallSearchContainer}>
            <Form.Item name="search">
              <Input
                allowClear
                className={debouncedSearch ? styles.searchActive : styles.search}
                placeholder={searchPlaceholder}
                onChange={(e) => {
                  const out = e.target.value.replace(/[^a-z0-9]/gi, " ");
                  setSearch(out);
                }}
                prefix={<MagnifyingGlassIcon />}
              />
            </Form.Item>
          </div>
        )}
      </Form>
      {!newFiltersPaths.includes(location.pathname) && !tablet && !!filterCount && (
        <div className={styles.filterSetRow}>
          {activeFilters.map((activeFilter) => {
            return activeFilter.labels.map((label) => {
              return (
                <div key={label} className={styles.filterTag}>
                  <span>{tagDisplayValue(label, activeFilter).replaceAll("_", " ")}</span>
                  <div
                    className={styles.closeIcon}
                    tabIndex="0"
                    role="button"
                    onClick={() => updateFilters(activeFilter.type, null)}
                    onKeyDown={() => updateFilters(activeFilter.type, null)}
                  >
                    <CloseIcon backfill={cvar("dark-blue")} />
                  </div>
                </div>
              );
            });
          })}
        </div>
      )}

      <SaveFilterSetModal
        query_url={urlSearch}
        pathname={pathname}
        filterSetModalVisible={filterSetModalVisible}
        filterSetModalClose={filterSetModalClose}
      />
    </div>
  );
};

SearchFilterCSV.propTypes = {
  sortItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      options: PropTypes.arrayOf(
        PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])),
      ),
    }),
  ),
  navigate: PropTypes.func.isRequired,
  override: PropTypes.func,
  getFilterSetsByView: PropTypes.func,
  getLedgerCsv: PropTypes.func,
  getEnterpriseLedgerCsv: PropTypes.func,
  getEarningsCsv: PropTypes.func,
  getLedgerCsvReport: PropTypes.func,
  setLedgerCsv: PropTypes.func,
  getActivityLogCsv: PropTypes.func,
  getPreferredPartnerCsv: PropTypes.func,
  // TODO: enumerate filterSets and ledgerCsv properties
  filterSets: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  ledgerCsv: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  activityLogCsv: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  noSearch: PropTypes.bool,
  noCSV: PropTypes.bool,
  activityLog: PropTypes.bool,
  preferredPartner: PropTypes.bool,
  customSearch: PropTypes.node,
  enterprise: PropTypes.bool,
  additionalButtonContent: PropTypes.node,
  searchPlaceholder: PropTypes.string,
  toggleFilter: PropTypes.bool,
  setMenuExpanded: PropTypes.func,
  isLoading: PropTypes.bool,
  showDateFilterButton: PropTypes.bool,
};

SearchFilterCSV.defaultProps = {
  searchPlaceholder: "Search",
  isLoading: false,
  showDateFilterButton: false,
};

export default connect(
  getRdxSelectionMapper({
    latestMessage: "getLatestMessageEvt",
    activeRequests: "getActiveRequests",
    filterSets: "getFilterSets",
    ledgerCsv: "getLedgerCsv",
    activityLogCsv: "selectActivityLogCsv",
    preferredPartnerCsv: "getPreferredPartnerCsv",
  }),
  getRdxActionMapper([
    "getFilterSetsByView",
    "getLedgerCsv",
    "getEarningsCsv",
    "getEnterpriseLedgerCsv",
    "setLedgerCsv",
    "getLedgerCsvReport",
    "getActivityLogCsv",
    "getPreferredPartnerCsv",
  ]),
)(SearchFilterCSV);
