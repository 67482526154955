import { put } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getUtilityCompanies(action) {
  const { state, isLeadDrawer } = action.payload || {};

  const { success, data, error } = yield* makeRequest.get("/leads/utilities_list", { state });

  const setterAction = isLeadDrawer ? actions.setLeadToProposalDrawerUtilities : actions.setUtilityCompanies;

  if (success && data) {
    yield put(setterAction(camelizeKeys(data.body)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_FETCHING_UTILITY_COMPANIES,
    });
  }
  return null;
}

export default getUtilityCompanies;
